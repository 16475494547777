import { serviceUtils } from '../../../service/serviceUtils';
import { dynamicSplitEffects } from './dynamicSplitEffects';

const REQUEST_URL = '/split-view/favorites';

const fetchHandle = (actions, payload) => {
  serviceUtils.HttpService({
    url: REQUEST_URL,
    errorActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_FAILED],
    successActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_SUCCEEDED]
  });
};

const postLinkAction = (actions, payload) => {
  const { url, name } = payload;
  
  const API = {
    'url': url,
    'name': name,
  };
  console.log("API: ", API);
  const options = { actions, url: REQUEST_URL, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_FAILED],
    successActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_SUCCEEDED],
    ...options
  });
};

const putLinkAction = (actions, payload) => {
  const { url, id } = payload;
  const API = {
    'widgets': [
      {
        'url': url,
        'id': id
      }
    ]
  };
  const options = { actions, url: REQUEST_URL, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'PUT',
    data: options.API,
    errorActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_FAILED],
    successActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_SUCCEEDED],
    ...options
  });
};

const deleteLinkAction = (actions, id) => {
  const API = {
    'id': id,
  };
  const options = { actions, url: REQUEST_URL, API };

  serviceUtils.HttpService({
    url: options.url,
    method: 'DELETE',
    data: options.API,
    errorActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_FAILED],
    successActionType: actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_SUCCEEDED],
    ...options
  });
};

export const dynamicSplitFactory = {
  fetchHandle,
  postLinkAction,
  putLinkAction,
  deleteLinkAction
};
