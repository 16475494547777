import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/poolsPage/actions/uiActions';
import { constants } from '../pages/poolsPage/constants';
import { poolsEffects } from '../pages/poolsPage/actions/poolsEffects';

import { action, computed  } from 'easy-peasy';

const { SORT_SELECT,PROTOCOLS_SELECT } = constants;

const POOLS_MODEL = {
    protocolSelector: {
        list: PROTOCOLS_SELECT,
        value: PROTOCOLS_SELECT[0],
        isLoading: false,
        isDisabled: false
    },
    currentTxStatus: 0,
    tab: 'live',
    selector: {
        list: SORT_SELECT,
        value: SORT_SELECT[0],
        isDisabled: false
    },
    modal: {
        show: false,
        name: '',
        data: {}
    },
    currentBlock: 0,
    unstakeAmount: 0,
    stakeAmount: 0,
    stakedBalance: 0,
    tableData: {
        pagination: {
            page: 0,
            hasMore: false,
            pageSize: 20
        },
        toggled: [],
        data: [],
        pancakeAllData:[],
        traderJoeAllData: [],
        quickAllData: [],
        allData: [],
        filteredData: [],
        syrupPools: [],
        pancakeSwapData: [],
        quickSwapData: [],
        traderJoeSwapData: []
    },
    loader: false,
    ...uiActions.actionHandlers,
    ...poolsEffects.actionHandlers,


  
};

export const PoolsState = createContextStore(POOLS_MODEL);
