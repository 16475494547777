import React from 'react';
import './styles.scss';
import { DonatChart } from '../donat-chart/view';
import { ChangesTable } from './templates/tableTemplate';
import { dataHelper } from './dataHelper';
import { constants } from '../../../../constants/constants';
import { renderControlPanel } from './templates/controlPanelTemplate';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { AddButton } from '../../../../components/utility/add-button/view';
import { tableWidgetEffects } from './tableEffects';
import { Loader } from '../../../../components/utility/loader/view';


const { DONAT_CHART, TABLE_WIDGET } = constants;

const addButtonContent = () => {
    return <>
        <div className="text-uppercase font-size-16 font-weight-medium">
            <i className="icon-plus icon-bordered me-3"></i>{TABLE_WIDGET.addExchange.label}</div>
    </>;
};

const renderTable = (tableData, currency) => {
    return <ChangesTable
        data={tableData}
        currency={currency}
        size={'md'}
    />;
};

const renderAddExchangeButton = () => {
    return <div className="d-flex flex-column justify-content-center h-100 align-items-center">
        <AddButton
            size="llg"
            content={addButtonContent()}
            actionName={tableWidgetEffects.actionTypes.TABLE_WIDGET_UI_ACTIONS_ADD_EXCHANGE_CLICKED}
            appendToPayload={{ hi: 'Hide from large button' }}
        />
    </div>;
};

const renderContent = (state, exchangesList, chartData, totals, tableData, currency) => {
    return <div className="content-container">
        <div className="d-flex flex-column justify-content-center position-relative donat-content h-100">
            <DonatChart
                data={chartData}
                limit={DONAT_CHART.LIMIT}
                currency={currency}
                totals={totals}
            />
        </div>
        <div className="d-flex flex-column justify-content-start position-relative table-content">
            {isEmpty(exchangesList) ? renderAddExchangeButton() : renderTable(tableData, currency)}
        </div>
    </div>;
};

const render = (state, actions, exchangesList, chartData, totals, tableData, currency, loading) => {
    return <>
        <div className="table-widget-controls">
            { renderControlPanel(state, actions, exchangesList, loading) }
        </div>
        <div className="table-widget-container">
            <div className="table-widget-container-main-row position-relative w-100">
                {loading ?
                    <Loader size={'6x'} /> :
                    renderContent(state, exchangesList, chartData, totals, tableData, currency)               
                }
            </div>
        </div>
    </>;
};


export const TableWidget = () => {
    const coinData = useStoreState(state => state.coinData);
    const state = useStoreState(state => state.tableWidget);
    const loaders = useStoreState(state => state.loaders);
    const actions = useStoreActions(actions => actions);
    const exchanges = coinData.exchangesWithPrices;
    const currency = state.currency;
    const exchangesList = dataHelper.listCreate(exchanges);
    const currentExchangeId = exchangesList.length === 1 ? exchangesList[0].id : state.exchange.id;

    const parsedData = dataHelper.tableDataParser(exchanges, currency);
    const { tableData, chartData } = isEmpty(exchangesList) || isEmpty(parsedData) ? { chartData: [], tableData: {} } : parsedData[currentExchangeId];
    const totals = isEmpty(exchanges) ?
        {
            BTC: 0,
            USDT: 0,
            old_BTC: 0,
            old_USDT: 0
        } :
        {
            BTC: exchanges[currentExchangeId]['TOTAL-BALANCE-EXCHANGE-BTC-N'],
            USDT: exchanges[currentExchangeId]['TOTAL-BALANCE-EXCHANGE-USDT-N'],
            old_BTC: exchanges[currentExchangeId]['OLD-TOTAL-BALANCE-EXCHANGE-BTC'],
            old_USDT: exchanges[currentExchangeId]['OLD-TOTAL-BALANCE-EXCHANGE-USDT'],
        };

    const isLoading = loaders.table;
    const data = isEmpty(tableData) ? tableData : dataHelper.tableDataFormatting(tableData);
    const dataFiltered = isEmpty(data) ? data : dataHelper.amountVolume(data, state.volume);
    return <div className="table-widget-element ps-3 pe-3 pt-3">
        { render(state, actions, exchangesList, chartData, totals, dataFiltered, currency, isLoading) }
    </div>;
};

