import { createContextStore } from 'easy-peasy';
import { constants } from '../pages/web3BalancesPage/constants';
import { uiActions } from '../pages/web3BalancesPage/actions/uiActions';
import { balancesEffects } from '../pages/web3BalancesPage/actions/balancesEffects';

const { DROPDOWN_LIST } = constants;

const WEB3_BALANCES_MODEL = {
    selector: {
        value: DROPDOWN_LIST[0],
        list: DROPDOWN_LIST
    },
    search: {
        value: ''
    },
    tableData: {
        pagination: {
            hasMore: true,
            page: 0
        },
        data: [],
        filteredData: [],
        totalBalance: 0
    },
    blackList: {
        modal: false,
        tempData: [],
        transportData: [],
        data: [],
        filteredData: []
    },
    walletBalance: {
        currency: 'BNB',
        balance: 0.16
    },
    requestBody: null,
    searchRequest: null,
    address: '',
    loader: false,
    typeOfFetch: 'set',
    ...uiActions.actionHandlers,
    ...balancesEffects.actionHandlers
};

export const Web3BalancesState = createContextStore(WEB3_BALANCES_MODEL);