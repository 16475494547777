import React from 'react';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { utils } from '../../../utils/utils';
import Big from 'big.js';

const { WALLET_CONTROLS_HEALTH: { ACCOUNT, HEALTH, TO_REPAY, TO_SEIZE, MAX_REPAY, MAX_SEIZE } } = constants;

export const LeftWindow = props => {
    const { state, actions } = props;
    const healthColor = health => health >= 1 ? 'positive-color' : 'negative-color';
    const onChangeRepayCoinsHandle = value => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_REPAY_COINS_SELECT]({ value });
    const onChangeSeizeCoinsHandle = value => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SEIZE_COINS_SELECT]({ value });
    const usdColor = usd => usd > 0 ? 'positive-color' : 'negative-color';

    const tokens = state.pickedAccount ? state.pickedAccount.tokens : [];

    return <div className='wallet-controls-health me-3'>
        <div className='mb-3 account-grid align-items-center'>
            <div className='font-size-16'>{ACCOUNT}</div>
            <div className='font-size-14 account-id'>
                {state.pickedAccount && `${state.pickedAccount.id}`}
            </div>
        </div>
        <div className='grid-row-health mb-3'>
            <div className='key-health'>{HEALTH}</div>
            <div
                className={`health-value justify-content-end ${healthColor(state.pickedAccount && state.pickedAccount.health)}`}>
                {state.pickedAccount && `${utils.health2Pers(+state.pickedAccount.health)}%`}
            </div>
        </div>
        <div className='grid-row-health mb-3'>
            <div className='key-health'>{TO_REPAY}</div>
            {state.pickedAccount &&
                <div className='m-selector-wrapper justify-content-end'>
                    <MultiplyCustomSelector
                        options={utils.getBorrowedTokens(tokens)}
                        closeMenuOnSelect={true}
                        isDisabled={state.selector.isDisabled}
                        isMulti={false}
                        isLoading={state.selector.isLoading}
                        value={state.assetsToRepay}
                        onChangeHandle={onChangeRepayCoinsHandle}
                    />
                </div>
            }
        </div>
        <div className='grid-row-health mb-3'>
            <div className='key-health'>{TO_SEIZE}</div>
            {state.pickedAccount &&
                <div className='m-selector-wrapper justify-content-end'>
                    <MultiplyCustomSelector
                        options={utils.getSuppliedTokens(tokens)}
                        closeMenuOnSelect={true}
                        isDisabled={state.selector.isDisabled}
                        isMulti={false}
                        isLoading={state.selector.isLoading}
                        value={state.assetsToSeize}
                        onChangeHandle={onChangeSeizeCoinsHandle}
                    />
                </div>
            }
        </div>
        <div className='grid-row-health mb-3'>
            <div className='key-health'>{MAX_REPAY}</div>
            {state.pickedAccount &&
                <div className='health-value d-column-flex align-items-center justify-content-end font-size-14'>
                    <div className='font-size-14'>
                        {Big(+state.seizeToken.maxRepayAmount).toFixed(10)}{' '}
                        {state.assetsToRepay.value.substring(1)}
                    </div>
                    <div className={`font-size-12 price-usd ${usdColor(Big(+state.seizeToken.maxRepayAmountInUSD).toFixed(10))}`}>
                        ${' '}
                        {Big(+state.seizeToken.maxRepayAmountInUSD).toFixed(10)}
                    </div>
                </div>
            }
        </div>
        <div className='grid-row-health'>
            <div>{MAX_SEIZE}</div>
            {state.pickedAccount &&
                <div className='health-value d-column-flex align-items-center justify-content-end font-size-14'>
                    <div className='font-size-14'>
                        {Big(+state.seizeToken.maxSeizeAmountWithIncentive).toFixed(10)}{' '}
                        {state.seizeToken.seizeSymbol.substring(1)}
                    </div>
                    <div className={`font-size-12 price-usd ${usdColor(Big(+state.seizeToken.maxSeizeAmountWithIncentiveInUSD).toFixed(10))}`}>
                        ${' '}
                        {Big(+state.seizeToken.maxSeizeAmountWithIncentiveInUSD).toFixed(10)}
                    </div>
                </div>
            }
        </div>
    </div>;
};
