import React, { useEffect, useRef } from 'react';
import { MultiplyCustomSelector } from '../../components/utility/multiply-selector/view';
import { InputGroup, FormControl } from 'react-bootstrap';
import { uiActions } from './actions/uiActions';
import { Web3BalancesState } from '../../models/web3-balances-model';
import { TableTemplate } from './templates/tableTemplate';
import { debug, useStoreState } from 'easy-peasy';
import { ButtonCustom } from '../../components/utility/custom-buttons/view';
import { BlackListModal } from './templates/modalTemplate';
import CustomScroll from 'react-custom-scroll';
import { constants } from './constants';
import { isEmpty } from 'lodash';
import './styles.scss';

const { BLACK_LIST: { LABEL, ADD_TO_BLACK_LIST } } = constants;

export const Web3BalancesLayout = () => {
    const state = Web3BalancesState.useStoreState(state => state);
    const actions = Web3BalancesState.useStoreActions(actions => actions);
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const ref = useRef();
    const onChangeHandle = value => actions[uiActions.actionTypes.WEB3_BALANCES_MULTISELECT_SET]({ userWallet, value, state });
    const onSearchHandle = e => actions[uiActions.actionTypes.WEB3_BALANCES_SEARCH_SET]({ userWallet, value: e.target.value });
    const searchInvoked = () => actions[uiActions.actionTypes.WEB3_BALANCES_SEARCH_INVOKED]({ userWallet, state });
    const keyUpHandle = e => e.which === 13 ? actions[uiActions.actionTypes.WEB3_BALANCES_SEARCH_INVOKED]({ userWallet, state }) : '';

    const clickModalHandle = () => actions[uiActions.actionTypes.WEB3_BALANCES_MODAL_TOGGLED]({ type: 'open' });

    const clickAddHandle = () => actions[uiActions.actionTypes.WEB3_BALANCES_BLACKLIST_ADD]({ state });
    
    useEffect(() => {
        actions[uiActions.actionTypes.WEB3_BALANCES_REQUESTED]({ userWallet, state });
    }, [state.searchRequest]);

    return <div ref={ref} className="page-container">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
            <div className="web3-balances-layout content ps-3 pe-3">
                <div className="web3-balances-layout controls">
                    <div className="multiple-selector me-3">
                        <MultiplyCustomSelector
                            options={state.selector.list}
                            onChangeHandle={onChangeHandle}
                            closeMenuOnSelect={true}
                            isDisabled={state.selector.isDisabled}
                            isMulti={false}
                            isLoading={state.selector.isLoading}
                            value={state.selector.value}
                        />
                    </div>
                    <div className='black-list-button d-flex'>
                        <div className='me-3'>
                            <ButtonCustom onClick={clickModalHandle} size="sm" variant="default" label={LABEL}/>
                        </div>
                        <div>
                            {isEmpty(state.blackList.transportData) ? null : <ButtonCustom onClick={clickAddHandle} size="sm" variant="primary" label={ADD_TO_BLACK_LIST}/>}
                        </div>
                    </div>
                    <div className="search-input">
                        <InputGroup onKeyUp={keyUpHandle}>
                            <InputGroup.Text className="clear">
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </InputGroup.Text>
                            <FormControl
                                onChange={onSearchHandle}
                                placeholder={'Wallet Address...'}/>
                            <InputGroup.Text className='custom-btn-search' onClick={searchInvoked}>Address</InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
                <div className="web3-balances-layout table-content">
                    <TableTemplate userWallet={userWallet}/>
                </div>
            </div>
            <BlackListModal userWallet={userWallet} ref={ref.current}/>
        </CustomScroll>
    </div>;
};

export const Web3BalancesPage = () => <Web3BalancesState.Provider>
    <Web3BalancesLayout/>
</Web3BalancesState.Provider>;