export const mockData = {
  cards: [
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 1
    },
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 2
    },
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 3
    },
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 4
    },
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 5
    },
    {
      name: 'Mei Tauros Bot',
      percent: '3.37',
      coins: [
        '../coins-full-library/eth.svg',
        '../coins-full-library/mcs.svg',
        '../coins-full-library/sol.svg',
        '../coins-full-library/usdt.svg',
      ],
      id: 6
    }
  ],
  bestSinglePair: [
    {
      id: 1,
      pair: 'SFPUSDT',
      main: 'USDT',
      completedDeals: 261,
    },
    {
      id: 2,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 256,
    },
    {
      id: 3,
      pair: 'SFPUSDT',
      main: 'USDT',
      completedDeals: 261,
    },
    {
      id: 4,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 256,
    },
    {
      id: 5,
      pair: 'SFPUSDT',
      main: 'USDT',
      completedDeals: 261,
    },
    {
      id: 6,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 256,
    },
    {
      id: 7,
      pair: 'SFPUSDT',
      main: 'USDT',
      completedDeals: 261,
    },
    {
      id: 8,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 256,
    },
  ],
  bestPairs:  [
    {
      id: 1,
      pair: 'AAVE',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 2,
      pair: 'BTC',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 3,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 4,
      pair: 'XRPUSD',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 5,
      pair: 'SFPUSDT',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 6,
      pair: 'BTC',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 7,
      pair: 'TWT',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
    {
      id: 8,
      pair: 'XRPUSD',
      main: 'USDT',
      completedDeals: 2908,
      botsCount: 698,
    },
  ],
  overallStats: {
    dayDeals: 250529,
    weekDeals: 2644753,
    monthDeals: 9461315,
    totalDeals: 1943115796,
    todayNewBots: 4157,
    totalBots: 10076019,
    arr: [
      {
        id: 1,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 2,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 3,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 4,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 5,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 6,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 7,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 8,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 9,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 10,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 11,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 12,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 13,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 14,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 15,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 16,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 17,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 18,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 19,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 20,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 21,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 22,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 23,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 24,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 25,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 26,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 27,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
      {
        id: 28,
        pair: 'BIT',
        main: 'BIT',
        value: 765.79044372,
      },
      {
        id: 29,
        pair: 'XRP',
        main: 'XRP',
        value: 525.86928955,
      },
      {
        id: 30,
        pair: 'AAVE',
        main: 'BIT',
        value: 22.62000236,
      },
    ]
  },
  bestMultiPair:  [
    {
      id: 1,
      name: 'Route 66 Resistance Ski Speeder Bot #311',
      quoteCurrency: 'USDT',
      numberOfPairs: 239,
      activeDealsLimit: 199,
      completedDeals: 3991,
      exchange: 'Paper trading account',
    },
    {
      id: 2,
      name: 'Symmetra Hitmonlee Bot #74',
      quoteCurrency: 'USDT',
      numberOfPairs: 239,
      activeDealsLimit: 199,
      completedDeals: 3991,
      exchange: 'Paper trading account',
    },
    {
      id: 3,
      name: 'Dathomirian MTT Bot #545',
      quoteCurrency: 'USDT',
      numberOfPairs: 239,
      activeDealsLimit: 199,
      completedDeals: 3991,
      exchange: 'Paper trading account',
    },
    {
      id: 4,
      name: 'Accio Staryu Bot #126',
      quoteCurrency: 'USDT',
      numberOfPairs: 239,
      activeDealsLimit: 199,
      completedDeals: 3991,
      exchange: 'Paper trading account',
    },
    {
      id: 5,
      name: 'Junkrat One-Eye Bot #317',
      quoteCurrency: 'USDT',
      numberOfPairs: 239,
      activeDealsLimit: 199,
      completedDeals: 3991,
      exchange: 'Paper trading account',
    }
  ],
  botsStats: {
    id: "BTC",
    mainCoin: "USDT",
    isSinglePair: true,
    selectedPack: {
      mainCoin: "USDT",
      weight: false
    },
    exchangeData: {
      value: {
        label: "Binance",
        value: {
          accountId: "56988c21-b4c1-5ff3-9f1c-0394da7a2d92",
          name: "Binance",
          api: "binance_prod",
          type: "SPOT"
        }
      }
    }
  },
  myBots: [
    {
      id: 1,
      name: 'Spot ATA Short',
      deals: 57,
      takeProfit: 35.00,
      avgDailyProfit: 12.00,
      exchange: {
        version: 'BinanceV2',
        name: 'Binance Spot',
      },
      pair: 'ATA',
      main: 'USDT',
      activeDeals: '1 / 1',
      status: true
    },
    {
      id: 2,
      name: 'Spot ATA Short',
      deals: 67,
      takeProfit: 30.00,
      avgDailyProfit: 11.00,
      exchange: {
        version: 'BinanceV2',
        name: 'Binance Spot',
      },
      pair: 'ATA',
      main: 'USDT',
      activeDeals: '1 / 1',
      status: true
    },
    {
      id: 3,
      name: 'Spot ATA Short',
      deals: 56,
      takeProfit: 10.00,
      avgDailyProfit: 55.00,
      exchange: {
        version: 'BinanceV2',
        name: 'Binance Spot',
      },
      pair: 'ATA',
      main: 'USDT',
      activeDeals: '1 / 1',
      status: true
    },
    {
      id: 4,
      name: 'Spot ATA Short',
      deals: 70,
      takeProfit: 100.00,
      avgDailyProfit: 13.00,
      exchange: {
        version: 'BinanceV2',
        name: 'Binance Spot',
      },
      pair: 'ATA',
      main: 'USDT',
      activeDeals: '1 / 1',
      status: false
    }
  ],
  myDeals: [
    {
      id: 1,
      name: 'Futures Long ATA',
      pair: 'XRP',
      main: 'USDT',
      network: 'BinanceV2 Futures',
      volume: 0.46072,
      status: 'Bought',
      completed: 1,
      active: 1,
      timestamp: 1672235711000,
      profit: 2
    },
    {
      id: 2,
      name: 'Futures Long ATA',
      pair: 'ATA',
      main: 'USDT',
      network: 'BinanceV2 Futures',
      volume: 0.552864,
      status: 'Bought',
      completed: 1,
      active: 1,
      timestamp: 1672235771000,
      profit: 3
    },
    {
      id: 3,
      name: 'Futures Long ATA',
      pair: 'BTC',
      main: 'USDT',
      network: 'BinanceV2 Futures',
      volume: 0.66072,
      status: 'Bought',
      completed: 1,
      active: 1,
      timestamp: 1672322171000,
      profit: 1
    },
  ],
  priceLineData: {
    "mainCoin": "USDT",
    "pair": "BNB",
    "start": "27/12/22 14:09",
    "timestamp": 1672146593000,
    "volume": "0.46072",
    "status": "ERROR",
    "pnl": {
      "change": 0,
      "balance": "0"
    },
    "botId": "6ef52ab0-70be-4676-97bf-219d23119bba",
    "exchange": "binance-futures",
    "id": "smart_trade",
    "fullModel": {
      "botId": "6ef52ab0-70be-4676-97bf-219d23119bba",
      "startDate": 1672146593000,
      "exchange": "binance-futures",
      "id": "smart_trade",
      "label": "SMART TRADE",
      "pairLabel": "BNB/USDT",
      "pairId": "bnb",
      "balance": "0",
      "balance30d": "0.46072",
      "status": "ERROR",
      "change": 0,
      "model": {
        "model": "SmartTrade",
        "createdAt": 1672146593000,
        "originalModel": {
          "useFallbackOnStopPriceWouldTriggerImmediatelyError": true,
          "data": {
            "symbol": "BNBUSDT",
            "leverage": 1,
            "formType": "LONG",
            "pack": null,
            "takeProfit": null,
            "packId": null,
            "isSinglePair": true,
            "typeOfExchange": "FUTURES",
            "marginType": "ISOLATED",
            "stopLoss": null,
            "pairCoin": "BNB",
            "isLeveraged": true,
            "mainCoin": "USDT",
            "order": {
              "conditionOrderPriceMarket": false,
              "side": "buy",
              "trailing": "0",
              "quantity": "0.46072",
              "base": {
                "quantityBase": "0.00189052113254000821",
                "priceBase": "243.7"
              },
              "conditionOrderPriceLimit": null
            }
          },
          "botType": "SMART_TRADE",
          "keyId": "dff9f4fa-6d9d-5bdc-b6cd-788b72a95b81",
          "exchange": "binance-futures"
        },
        "status": "ERROR",
        "balance": "0",
        "baseAssetProfit": "0",
        "baseAssetQty": "0",
        "quoteAssetQty": "0",
        "balance24h": "0",
        "balance30d": "0.46072"
      }
    }
  }
}