import { action, debug, thunk } from 'easy-peasy';
import { constants } from '../constants';
import { packsFactory } from './packsFactory';
import { packHelper } from './dataHelpers/packHelper';
import { dataHelper } from './dataHelpers/dataHelper';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';

const { CONTROLS: { LABEL_CANCEL, LABEL_EDIT, LABEL_SAVE, LABEL_WEIGHT_EQUAL, LABEL_ADD_PAIR } } = constants;

const actionTypes = {
    SMART_TRADE_PAGE_PACK_SELECTED: 'SMART_TRADE_PAGE_PACK#SELECTED',
    SMART_TRADE_PAGE_PACK_EDIT_MODIFIED: 'SMART_TRADE_PAGE_PACK_EDIT#MODIFIED',
    SMART_TRADE_PAGE_PACK_SAVE_INVOKED: 'SMART_TRADE_PAGE_PACK_SAVE#INVOKED',
    SMART_TRADE_PAGE_PACK_SAVE_SUCCEEDED: 'SMART_TRADE_PAGE_PACK_SAVE#SUCCEEDED',

    SMART_TRADE_PAGE_PACK_SAVE_FAILED: 'SMART_TRADE_PAGE_PACK_SAVE#FAILED',
    SMART_TRADE_PAGE_STATE_UPDATED: 'SMART_TRADE_PAGE_STATE#UPDATED',
};

const packStateUpdateHandle = (state, payload) => {
    const { id, allPackData } = payload;
    switch (id) {
    case 'load_packs':
        state.packs.allPackData = allPackData;
        state.packs.packsData = [...packHelper.populatePacksList(allPackData, state.packs.exchangeId)];
        break;
    case 'exchange_update':
        state.packs.packsData = [...packHelper.populatePacksList(state.packs.allPackData, state.packs.exchangeId)];
        break;
    case 'toggle':
        state.packs.show = !state.packs.show;
        break;
    case 'add':
        state.packs.addMode = true;
        state.packs.selectedId = null;
        state.packs.addPackMode = true;
        break;
    case 'close':
        state.packs.addPackMode = false;
        state.packs.addPairMode = false;
        state.packs.createPairsData = {};
        state.packs.createPackData = {};
        break;
    case 'save':
        state.packs.addPackMode = false;
        state.packs.addPairMode = false;
        state.packs.createPackData = {};
        break;
    case 'save_pairs':
        state.packs.addPackMode = false;
        state.packs.addPairMode = false;
        state.packs.createPairsData = null;
        break;
    case 'pack_main_coin_value':
        state.packs.createPackData.mainCoin = payload.value.value;
        state.packs.pairCoinList = dataHelper.generatePairCoinList(payload.value.value, state.packs.coinData);
        break;
    case 'pack_pairs_value':
        state.packs.createPackData.pairs = payload.value;
        break;
    case 'pack_name_value':
        state.packs.createPackData.name = payload.value;
        state.packs.selectedPair = {};
        break;
    case 'cancel_select_pack':
        state.packs.selectedId = null;
        state.packs.selectedPack = null;
        state.packs.editPackMode = false;
        state.packs.addPairMode = false;
        state.packs.selectedPair = {};
        break;
    case 'pairs_add_value':
        state.packs.createPairsData = payload.value;
        break;
    case 'select_pair':
        state.packs.selectedPair = packHelper.createSelectedPair(payload);
        break;
    case 'select_pack':
        state.packs.selectedId = payload.data.id;
        state.packs.selectedPack = payload.data;
        state.packs.editPackMode = false;
        state.packs.addPairMode = false;
        state.packs.selectedPair = {};
        state.packs.pairCoinList = dataHelper.generatePairCoinList(payload.data?.mainCoin, state.packs.coinData);
        break;
    case 'pair-amount':
        state.smartTradeBot.balance_total =
            state.smartTradeBot.balance_total_leveraged = dataHelper.getAmountValue(payload);
        break;
    default: return;
    }
};

const selectPackHandle = (actions, payload) => {
    const { id } = payload;
    console.log('payload: ', payload)
    switch (id) {
    case 'save':
        actions[actionTypes.SMART_TRADE_PAGE_STATE_UPDATED](payload);
        const requestData = packHelper.packAddRequest(payload);
        actions[actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...requestData });
        break;
    case 'save_pairs':
        actions[actionTypes.SMART_TRADE_PAGE_STATE_UPDATED](payload);
        const requestAddPairsData = packHelper.pairsAddRequest(payload);
        actions[actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...requestAddPairsData });
        break;
    case 'delete_pack':
        const requestDeletedData = packHelper.packDeleteRequest(payload);
        actions[actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...requestDeletedData });
        break;
    case 'delete_pair':
        const requestDeletedPairsData = packHelper.pairsDeleteRequest(payload);
        actions[actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...requestDeletedPairsData });
        break;
    case 'fetch-pair-price':
        const priceRequest =  packHelper.fetchPrices(payload);
        actions[actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...priceRequest });
        break;
    default:
        actions[actionTypes.SMART_TRADE_PAGE_STATE_UPDATED](payload);
        break;
    }
};

const editStateUpdateHandle = (state, payload) => {
    const { actionName } = payload;
    switch (actionName) {
    case LABEL_CANCEL:
        state.packs.editPackMode = false;
        break;
    case LABEL_EDIT:
        state.packs.editPackMode = true;
        break;
    case LABEL_SAVE:
        state.packs.editPackMode = false;
        state.packs.addPairMode = false;
        break;
    case LABEL_WEIGHT_EQUAL:
        break;
    case LABEL_ADD_PAIR:
        state.packs.addPairMode = true;
        break;
    }
};

const successHandle = (state, payload) => {
    const { response, options: { operation, data: { packId } } } = payload;
    let notification;
    switch (operation) {
    case 'add_pack':
        state.packs.allPackData = response;
        state.packs.packsData = packHelper.populatePacksList(response, state.packs.exchangeId);
        notification = { type: 'success', text: 'Pack added' };
        notifyEffects.notifyAction(notification);
        break;
    case 'delete_pack':
        state.packs.allPackData = response;
        state.packs.packsData = packHelper.populatePacksList(response, state.packs.exchangeId);
        state.packs.selectedId = null;
        state.packs.selectedPack = null;
        state.packs.editPackMode = false;
        state.packs.addPairMode = false;
        notification = { type: 'success', text: 'Pack deleted' };
        notifyEffects.notifyAction(notification);
        break;
    case 'delete_pair':
        state.packs.allPackData = response;
        state.packs.packsData = packHelper.populatePacksList(response, state.packs.exchangeId);
        state.packs.selectedPack = state.packs.packsData.find(p => p.id === packId);
        notification = { type: 'success', text: 'Pair deleted' };
        notifyEffects.notifyAction(notification);
        break;
    case 'save_pairs':
        state.packs.allPackData = response;
        state.packs.packsData = packHelper.populatePacksList(response, state.packs.exchangeId);
        state.packs.selectedPack = state.packs.packsData.find(p => p.id === packId);
        notification = { type: 'success', text: 'Pair saved' };
        notifyEffects.notifyAction(notification);
        break;
    case 'fetch-pair-price':
        state.smartTradeBot.input_price_value =
            state.smartTradeBot.input_profit_value =
                state.smartTradeBot.input_stop_loss_value =
                    state.smartTradeBot.price_value = payload?.response?.content?.price;

        break;
    case 'bot-create':
        console.info(payload);
        notification = { type: 'success', text: 'Bot created' };
        notifyEffects.notifyAction(notification);
        break;
    }
};

const errorHandle = (state, payload) => {
    const {options: {operation}} = payload;
    switch(operation) {
    case 'fetch-pair-price':
        state.smartTradeBot.input_price_value = 0;
        break;
    default:
        state.smartTradeBot.errorInTrade = true
        console.info(payload);
        const notification = { type: 'error', text: 'Not successful' };
        notifyEffects.notifyAction(notification);
        break;
    }
};

const actionHandlers = {
    [actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]: thunk((actions, payload) => selectPackHandle(actions, payload)),
    [actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]: thunk(async (actions, payload) => packsFactory.saveInvokeHandle(actions, payload)),
    [actionTypes.SMART_TRADE_PAGE_PACK_SAVE_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.SMART_TRADE_PAGE_PACK_SAVE_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.SMART_TRADE_PAGE_STATE_UPDATED]: action((state, payload) => packStateUpdateHandle(state, payload)),
    [actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]: action((state, payload) => editStateUpdateHandle(state, payload)),
};

export const packsEffects = {
    actionTypes,
    actionHandlers
};