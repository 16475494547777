import React, { useRef, useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import { reactLocalStorage } from 'reactjs-localstorage';
import Toggle from 'react-toggle';
import { NftGeneratorState } from '../../models/nft-generator-model';
import './styles.scss';
import { constants } from './constants';
import { nftGeneratorEffects } from './actions/nftGeneratorEffects';
import { LeftSideContent } from './templates/leftSideContent';
import { MiddleContent } from './templates/middleContent';
import { RightSideContent } from './templates/rightSideContent';
import { Loader } from '../../components/utility/loader/view';

const { CONTROLS, HEADER } = constants;

export const NftGeneratorLayout = () => {
  const ref = useRef();
  const state = NftGeneratorState.useStoreState(state => state);
  const actions = NftGeneratorState.useStoreActions(actions => actions);
  const plansSubscriptionStorage = reactLocalStorage.get('plans_subscription');
  const plansSubscriptionObj = plansSubscriptionStorage ? JSON.parse(plansSubscriptionStorage) : '';
  const plansSubscriptionName = plansSubscriptionStorage ? plansSubscriptionObj.name : '';

  const toggleOnChange = e => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_ELEMENTS_CHANGED]({name: e?.target?.name, value: e?.target?.checked});

  useEffect(() => {
    actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_ITEMS_INIT](plansSubscriptionName);

    return () => {
      reactLocalStorage.remove('plans_subscription');
    };
  }, []);

  return !state.loader ? <div ref={ref} className="page-container">
    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
      <div className="nft-generator-layout">
        <div className="nft-generator-layout-header mb-4">
          <div className="font-weight-medium me-5">{HEADER}</div>
          <label className="d-flex align-items-center customized-toggle cursor-pointer">
            <div className="font-size-14 me-3">{CONTROLS.FEMALE}</div>
            <Toggle
              name="pick_your_avatars"
              icons={false}
              checked={state.togglePickYourAvatars}
              onChange={toggleOnChange}
            />
            <div className="font-size-14 ms-3">{CONTROLS.MALE}</div>
          </label>
        </div>
        <div className="nft-generator-layout-body">
          <LeftSideContent state={state} actions={actions} name={plansSubscriptionName} />
          <MiddleContent state={state} actions={actions} />
          <RightSideContent state={state} actions={actions} name={plansSubscriptionName} />
        </div>
      </div>
    </CustomScroll>
  </div> : <Loader size={"6x"} />
};

export const NftGeneratorPage = () => <NftGeneratorState.Provider>
  <NftGeneratorLayout/>
</NftGeneratorState.Provider>;
