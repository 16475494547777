import { action, thunk } from 'easy-peasy';
import { dataHelper } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { remakeDashboardFactory } from './remakeDashboardFactory';

const actionTypes = {
    DASHBOARD_DATA_REQUESTED: 'DASHBOARD_DATA#REQUESTED',
    DASHBOARD_SIZES_UPDATED: 'DASHBOARD_SIZES#UPDATED',
    DASHBOARD_MODAL_ADD_EXCHANGE_OPENED: 'DASHBOARD_MODAL_ADD_EXCHANGE#OPENED',
    DASHBOARD_MODAL_ADD_EXCHANGE_CLOSED: 'DASHBOARD_MODAL_ADD_EXCHANGE#CLOSED',
    DASHBOARD_CEFI_DATA_UPDATED: 'DASHBOARD_CEFI_DATA#UPDATED',
};

const dataRequest = (actions, payload) => {
    const { name, userWallet, cefiData } = payload;
    const { updateState } = actions;

    switch(name) {
    case 'defi': 
        updateState({ path: 'loaders.defi', value: true });
        actions[remakeDashboardFactory.actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCHED]({ userWallet });
        break;
    case 'cefi':
        updateState({ path: 'loaders.cefi', value: true });
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_CEFI_DATA_UPDATED]({ cefiData });
        break;
    case 'vaults':
        updateState({ path: 'loaders.vaults', value: true });
        actions[remakeDashboardFactory.actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCHED]({ userWallet });
        break;
    case 'yields':
        updateState({ path: 'loaders.yields', value: true });
        actions[remakeDashboardFactory.actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCHED]({ userWallet });
        break;
    case 'lending':
        updateState({ path: 'loaders.lending', value: true });
        actions[remakeDashboardFactory.actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCHED]({ userWallet });
        break;
    }
};

const sizeUpdate = (state, payload) => {
    const { height } = payload;
    state.imageHeight = height;
};

const modalHandle = (state, payload) => {
    state.isModalOpen = payload;
};

const cefiHandle = (state, payload) => {
    const { cefiData } = payload;

    state.cefi.balance = cefiData ? cefiData['TOTAL-BALANCE-EXCHANGE-USDT-N'] : 0;
    state.cefi.totalBTC = cefiData ? cefiData['TOTAL-BALANCE-EXCHANGE-BTC-N'] : 0;
    state.btcPrice = state.cefi.balance / state.cefi.totalBTC;
    const { available, invested } = dataHelper.investedAvailableCalculation(cefiData);
    state.cefi.available = available;
    state.cefi.invested = invested;
    state.cefi.change = cefiData ? dataHelper.changeCalculation(cefiData) : 0;
    state.cefi.top4assets = cefiData ? dataHelper.getTop4Assets(cefiData.balances) : [];
    state.availableAssets = cefiData ? dataHelper.getRowData(cefiData.balances) : [];
    state.defi.balance = Number(state.defi.invested) + Number(state.walletAssets.totalUSD);
    state.defi.available = Number(state.defi.balance) - Number(state.defi.invested);
    state.total = dataHelper.balancesCalculation(state);
    state.defi.top4assets = dataHelper.getDefiTop4Assets(state.walletAssets.assets, state.defi.balance);
    state.loaders.cefi = false;
    state.walletAssets.totalBTC = state.walletAssets.totalUSD / state.btcPrice;
};

const actionHandlers = {
    [actionTypes.DASHBOARD_DATA_REQUESTED]: thunk((actions, payload) => dataRequest(actions, payload)),
    [actionTypes.DASHBOARD_SIZES_UPDATED]: action((state, payload) => sizeUpdate(state, payload)),
    [actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED]: action((state, payload) => modalHandle(state, payload)),
    [actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_CLOSED]: action((state, payload) => modalHandle(state, payload)),
    [actionTypes.DASHBOARD_CEFI_DATA_UPDATED]: action((state, payload) => cefiHandle(state, payload)),
    
    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const remakeDashboardEffects = {
    actionTypes,
    actionHandlers
};