import React, { useRef, Fragment, useState } from 'react';
import './styles.scss';
import { routing } from '../../routing/routing-mapping';
import { LinkContainer } from 'react-router-bootstrap';
import { useStoreState } from 'easy-peasy';
import { Badge } from 'react-bootstrap';
import { constants } from '../../constants/constants';
import { Messages } from '../messages/view';
import { ThemeButton } from '../utility/theme-button/view';
import { HorizontalNavigationSelector } from '../utility/horizontal-navigation-selector/view';
import { VerticalNavigationSelector } from '../utility/vertical-navigation-selector/view';
import { LanguageSelector } from '../utility/languageSelector/view';
import CustomScroll from 'react-custom-scroll';


import BugReportModal from '../bugReportModal/view'
import SuccsessMessage from '../bugReportModal/succsessMessage'
import ErrorMessage from '../bugReportModal/errorMessage'
import BugReportBtn from '../utility/bug-report-btn/BugReportBtn'
import SupportButton from '../utility/support-btn/supportBtn';
import { NewsPage } from '../../pages/newsPage/view';

const { NAVIGATION_MAPPING, LOG_OUT } = routing;
const { THEME } = constants;


const renderNavSelector = (selector, index) => {
    const props = NAVIGATION_MAPPING[selector];
    return <HorizontalNavigationSelector key={index} {...props}/>;
};

const linksPopulate = links => {
    const selectors = Object.keys(links);
    return selectors.map((s, i) => renderNavSelector(s, i));
};

const renderNavigationLinks = links => {
    
    return <>
        <div className="navigation-container-left-part d-flex flex-row justify-content-between">
            { linksPopulate(links) }
        </div>
    </>;
};


export const NavigationBar = () => {

    const state = useStoreState(state => state.root);
    const size = useStoreState(state => state.sizes.values);
    const { badge, user } = useStoreState(state => state.authentication.userData);
    const { screenWidth } = size;
    const isDayThemeSelected = state.theme === THEME.DAY ? true : false;
    const ref = useRef();
    const [verticalMenuVisibility, setState] = useState(false);
    const visibilityClass = verticalMenuVisibility ? 'show' : '';
    const menuType = screenWidth > 1200 ? true : false; // Horizontal - true, Vertical - false;

    

    const showBugReportModal = useStoreState(state => state.report.showBugReportModal)
    const showBugReportSuccsess = useStoreState(state => state.report.showReportSuccses)
    const showReportError = useStoreState(state => state.report.showReportError)



    return <Fragment>
        { !menuType ? <div className={`navigation-vertical-bar ${visibilityClass}`}>

            <div className="navigation-vertical-logo-container d-flex align-items-center justify-content-between">
                <LinkContainer to="/">
                    <div className="navigation-vertical-logo-icon"></div>
                </LinkContainer>
                <a className="xmark-fa-icon" onClick={() => setState(false)}></a>
            </div>

            <CustomScroll heightRelativeToParent="calc(100% - 100px)" className="custom-scroll-bar">
                <div className="selectors-container">
                    { verticalMenuVisibility ? <VerticalNavigationSelector/> : null }
                </div>
            </CustomScroll>

        </div> : null }
        <div className="navigation-horizontal-bar d-flex justify-content-center">
            <div className="navigation-bar-container">
                <div className="navigation-horizontal-logo d-none d-xl-flex align-items-center">
                    <LinkContainer to="portfolio?elem=dashboard">
                        <div className="navigation-horizontal-logo-icon"></div>
                    </LinkContainer>
                </div>

                { menuType ?
                    <div className="navigation-container d-flex align-items-center ps-3 pe-2">
                        { renderNavigationLinks(NAVIGATION_MAPPING) }
                    </div> :
                    <div className="navigation-container">
                        <div className="menu-bars-container d-flex align-items-center justify-content-center">
                            <a className="menu-bars-fa-icon" onClick={() => setState(true)}></a>
                        </div>
                    </div>
                }

                <div ref={ref} className="icon-wrapper">
                    <div>
                    <i className="fa-thin fa-circle-question"></i>
                    </div>
                    <div>
                        <LanguageSelector active={isDayThemeSelected}  />
                    </div>
                    <div >
                        <Messages ref={ref}/>
                    </div>
                    <div >
                        <SupportButton active={isDayThemeSelected} /> 
                    </div>
                    <div >
                        <BugReportBtn active={isDayThemeSelected} />
                    </div>
                    <div>
                        <ThemeButton active={isDayThemeSelected}  />
                    </div>
                </div>
                <div className="navigation-profile d-none d-md-flex align-items-center pe-3">
                    <Badge pill bg="primary">{badge}</Badge>
                    <div className="font-size-15 ps-2">{user}</div>
                </div>
                <div className="navigation-horizontal-log-out d-flex flex-row align-items-center ps-4 pe-4">
                    <LinkContainer to={`/${LOG_OUT.id}?elem=${LOG_OUT.parentId}`}>
                        <div className="log-out">
                            <a className="logout-fa-icon"></a>
                        </div>
                    </LinkContainer>
                </div>
            </div>
        </div>

        { showBugReportModal && <BugReportModal active={isDayThemeSelected} /> }
        { showBugReportSuccsess && <SuccsessMessage active={isDayThemeSelected} /> }
        { showReportError && <ErrorMessage active={isDayThemeSelected} /> }
    </Fragment>;
};