import styles from './select-vars.module.scss';

const { borderSelectColor, selectBackgroundColor, selectFontColor, iconColor, fontColorActive, fontColor, transitionOption,
    buttonRemoveHoverColor, buttonRemoveColor } = styles;

export const baseStyles = {
    control: (base) => ({
      ...base,
      cursor: 'pointer',
      boxShadow: 'none',
      borderColor: borderSelectColor,
      background: selectBackgroundColor,
      color: selectFontColor,
      '&:hover': {
          borderColor: borderSelectColor
      }
    }),
    indicatorSeparator: () => ({
        color: 'none'
    }),
    dropdownIndicator: base => ({
        ...base,
        color: iconColor,
        paddingRight: 12,
        '&:hover': {
            color: iconColor
        }
    }),
    menu: base => ({
      ...base,
      borderRadius: 4,
      background: 'white',
      marginTop: 4,
      paddingTop: 8,
      paddingBottom: 8,
      color: fontColor,
      zIndex: 1000
    }),
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        color: state.isSelected ? fontColorActive : fontColor,
        fontSize: 12,
        paddingLeft: '1.5rem',
        transition: transitionOption,
        opacity: state.isDisabled ? 0.4 : 1,
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:hover': {
            background: 'none',
            color: state.isDisabled ? fontColor : fontColorActive
        },
        '&:active, &.active': {
            background: 'none',
            color: fontColorActive,
        },
        '&::before': state.isSelected ? {
            position: 'absolute',
            content: '" "',
            height: 8,
            width: 8,
            borderRadius: '100%',
            backgroundColor: fontColorActive,
            top: 12,
            left: 9
        } : null
    }),
    theme: theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: 'none',
            primary: 'none'
        }
    }),
    menuList: base => ({
      ...base,
      borderRadius: 4,
      padding: 0,
      background: 'white',
      color: fontColor,
      display: 'flex',
      maxHeight: 300
    }),
    singleValue: base => ({
        ...base,
        padding: 0,
        fontSize: 14,
        color: selectFontColor,
        '&:active, &.active': {
            background: 'none',
            color: fontColorActive
        },
    }),
    multiValueContainer: base => ({
        ...base,
    }),
    multiValueRemove: base => ({
        ...base,
        color: buttonRemoveColor,
        '&:hover': {
            backgroundColor: 'inherit'
        }
    }),
    multiValue: base => ({
        ...base,
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingLeft: state.isMulti && state.hasValue ? 2 : 8
    }),
};