import { action, thunk } from 'easy-peasy';
import { dashboardFactory } from './dashboardFactory';
import { dataHelper } from './dataHelper';

const actionTypes = {
    DASHBOARD_DATA_INVOKED: 'DASHBOARD_DATA#INVOKED',
    DASHBOARD_DATA_FETCH_SUCCEEDED: 'DASHBOARD_DATA_FETCH#SUCCEEDED',
    DASHBOARD_DATA_FETCH_FAILED: 'DASHBOARD_DATA_FETCH#FAILED',
    DASHBOARD_SIZES_UPDATED: 'DASHBOARD_SIZES#UPDATED',
    DASHBOARD_CEFI_DATA_UPDATED: 'DASHBOARD_CEFI_DATA#UPDATED',
    DASHBOARD_MODAL_ADD_EXCHANGE_OPENED: 'DASHBOARD_MODAL_ADD_EXCHANGE#OPENED',
    DASHBOARD_MODAL_ADD_EXCHANGE_CLOSED: 'DASHBOARD_MODAL_ADD_EXCHANGE#CLOSED'
};

const successHandle = (state, payload) => {
    const {response} = payload;
    const keys = Object.keys(response).filter(k => k !== 'cefi' && k !== 'availableAssets' && k !== 'total' );
    keys.forEach(k => {
        state[k] = response[k];
    });
    state.isLoaded = true;
};

const errorHandle = (state, payload) => {

};

const sizeUpdate = (state, payload) => {
    const {height} = payload;
    state.imageHeight = height;
};

const cefiHandle = (state, payload) => {
    state.cefi.balance = payload ? payload['TOTAL-BALANCE-EXCHANGE-USDT-N'] : 0;
    const {available, invested} = dataHelper.investedAvailableCalculation(payload);
    state.cefi.available = available;
    state.cefi.invested = invested;
    state.cefi.change = payload ? dataHelper.changeCalculation(payload) : 0;
    state.cefi.top4assets = payload ? dataHelper.getTop4Assets(payload.balances) : [];
    state.availableAssets = payload ? dataHelper.getRowData(payload.balances) : [];
    state.total = dataHelper.balancesCalculation(state);
};

const modalHandle = (state, payload) => {
    state.isModalOpen = payload;
};

const actionHandlers = {
    [actionTypes.DASHBOARD_DATA_INVOKED]: thunk(async (actions, payload) => dashboardFactory.invokeHandle(actions, payload)),
    [actionTypes.DASHBOARD_DATA_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.DASHBOARD_DATA_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.DASHBOARD_SIZES_UPDATED]: action((state, payload) => sizeUpdate(state, payload)),
    [actionTypes.DASHBOARD_CEFI_DATA_UPDATED]: action((state, payload) => cefiHandle(state, payload)),
    [actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED]: action((state, payload) => modalHandle(state, payload)),
    [actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_CLOSED]: action((state, payload) => modalHandle(state, payload)),
};

export const dashboardEffects = {
    actionTypes,
    actionHandlers
};