import React, {useEffect} from 'react';
import { useStoreState } from 'easy-peasy';
import { Button, Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { nftGeneratorEffects } from '../actions/nftGeneratorEffects';
import { dataHelper } from '../dataHelpers/dataHelper';

const { MODALS } = constants;

export const SelectChainModal = props => {
  const { actions, show, state, name } = props;

  const user = useStoreState(state => state.authentication.userData);

  const handleSubmit = async () => await dataHelper.enableNFT(state.image, user, actions, name);
  const handleClose = () => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_MODAL_TOGGLED]({modal: 'select-chain', name: 'close-modal'});
  const activeClass = active => state.selectChain.selectedChain === active ? 'active' : '';
  const onItemToggle = item => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_SELECTED_CHAIN_TOGGLE](item);

  useEffect(() => {
    actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_CHAIN_INITIALIZE]();
  }, []);

  return <Modal
    show={show}
    size="md"
    centered
    className="select-chain"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
  >
    <Modal.Header>
      <div className="font-weight-medium">{MODALS.SELECT_CHAIN}</div>
    </Modal.Header>
    <Modal.Body>
      <div className="font-inter font-size-14 font-weight-medium modal-body-title">{MODALS.SELECT_CHAINS}</div>
      <div className="modal-body-items">
        {state.chains.map((item, index) =>
          <div
            key={index}
            className={`item ${activeClass(item.label)}`}
            onClick={() => onItemToggle(item)}>
            <img className="me-1" width={16} height={16} src={`../dashboard-library/nets/${item.symbol}.svg`}/>
            <div className="font-size-14">{item.label}</div>
          </div>
        )}
      </div>
      <div className="modal-body-button font-inter">
        <Button
          type="button"
          size="lg"
          className="font-size-14 font-weight-medium"
          // disabled={!state.selectChain.selectedChain}
          onClick={handleSubmit}>
          {MODALS.SUBMIT}
        </Button>
      </div>
    </Modal.Body>
  </Modal>;
};
