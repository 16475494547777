export const constants = {
  TABS: {
    DISCOVER_BOT: 'Discover a bot',
    MY_BOTS: 'My Bots',
    MY_DEALS: 'My Deals',
    BOT_ANALYTICS: 'Bot Analytics',
    GORDON: 'Gordon',
    WIZARD: 'Wizard',
    ADVANCED: 'Advanced',
    MAIN_SETTINGS: 'Main settings',
    PAIRS: 'Pairs',
    STRATEGY: 'Strategy',
    DEAL_START_CONDITION: 'Deal start condition',
    SAFETY_ORDERS: 'Safety orders',
    STOP_LOSS: 'Stop Loss',
    TAKE_PROFIT: 'Take profit',
    ADVANCED_SETTINGS: 'Advanced settings',
    FORM_TABS: {
      PERCENTAGE_10: '10%',
      PERCENTAGE_25: '25%',
      PERCENTAGE_50: '50%',
      PERCENTAGE_75: '75%',
      PERCENTAGE_100: '100%',
      SAFE_SLOW: {
        VALUE: 'Safe & Slow',
        TITLE: 'Conservative strategy (Safe & Slow)',
        SUBTITLE: '',
        TEXT: 'Waits for the most opportune time to start a new deal'
      },
      MODERATE: {
        VALUE: 'Moderate',
        TITLE: 'Moderate',
        SUBTITLE: '',
        TEXT: 'Starts new deals at a normal, fairly aggressive pace'
      },
      RISKIER_FAST: {
        VALUE: 'Riskier & Fast',
        TITLE: 'Aggressive (Riskier & Fast)',
        SUBTITLE: '',
        TEXT: 'Open deals at a faster rate for quick scalps and smaller profits'
      },
      GORDON: {
        VALUE: 'Gordon Tab',
        TITLE: 'Gordon',
        SUBTITLE: 'Ready-made strategy, minimal settings',
        TEXT: 'This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises. This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises.'
      },
      WIZARD: {
        VALUE: 'Wizard Tab',
        TITLE: 'Wizard',
        SUBTITLE: 'For newcomers',
        TEXT: 'This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises. This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises.'
      },
      ADVANCED: {
        VALUE: 'Advanced Tab',
        TITLE: 'Advanced',
        SUBTITLE: 'For advanced users',
        TEXT: 'This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises. This bot waits for the price to fall below the price band to open a position and then closes the position when the price rises.'
      },
      SINGLE_PAIR: 'Single-pair',
      MULTI_PAIR: 'Multi-pair',
      LONG: 'Long',
      SHORT: 'Short',
      QUOTE: 'Quote',
      BASE: 'Base',
      MARKET: 'Market',
      LIMIT: 'Limit',
      PERCENT: 'Percent %',
      CONDITIONS: 'Conditions',
      ALL: 'All',
      ALLL: 'Alll',
      MY_BOTS: ['All', '7 days', '30 days', '3 months', '6 months'],
      SUMMARY_PROFIT: 'Summary Profit',
      PROFIT_BY_DAY: 'Profit by day',
      PROFIT_BY_PAIR: 'Profit by pair',
    },
    ASSISTANT: 'Assistant',
    CHART: 'Chart',
    TABLE: 'Table',
  },
  HOW_IT_WORKS: 'How it works',
  MORE_ABOUT_TRADING_BOTS: 'More about trading bots',
  CONTROLS: {
    LABEL_EXCHANGE: 'Exchange',
    LABEL_QUOTE_CURRENCY: 'Quote currency',
    LABEL_AMOUNT: 'Amount',
    LABEL_DONT_SHOW_AGAIN: 'Don’t show again',
    LABEL_NAME: 'Name',
    LABEL_BOT_TYPE: 'Bot type',
    LABEL_PAIRS: 'Pairs',
    LABEL_MOST_TRADED_PAIRS: 'Most traded pairs last 24 hours',
    LABEL_STRATEGY: 'Strategy',
    LABEL_PROFIT_CURRENCY: 'Profit currency',
    LABEL_BASE_ORDER_SIZE: 'Base order size',
    LABEL_START_ORDER_TYPE: 'Start order type',
    LABEL_CONDITION: 'Condition',
    LABEL_TIMEFRAME: 'Timeframe',
    LABEL_SIGNAL_VALUE: 'Signal Value',
    LABEL_SAFETY_ORDER_SIZE: 'Safety order size',
    LABEL_PRICE_DEVIATION: 'Price deviation to open safety orders (% from initial order)',
    LABEL_SAFETY_ORDERS_COUNT: 'Max safety orders count',
    LABEL_ACTIVE_SAFETY_ORDERS_COUNT: 'Max active safety orders count',
    LABEL_SAFETY_ORDER_VOLUME_SCALE: 'Safety order volume scale',
    LABEL_SAFETY_ORDER_STEP_SCALE: 'Safety order step scale',
    LABEL_STOP_LOSS: 'Stop Loss (%)',
    LABEL_STOP_LOSS_ACTION: 'Stop Loss action',
    LABEL_STOP_LOSS_TIMEOUT: 'Stop Loss timeout',
    LABEL_DONT_START_DEALS: 'Don\'t start deal(s) if the daily volume is less than',
    LABEL_MINIMUM_PRICE_DEAL: 'Minimum price to open deal',
    LABEL_MAXIMUM_PRICE_DEAL: 'Maximum price to open deal',
    LABEL_COOLDOWN_BETWEEN_DEALS: 'Cooldown between deals',
    LABEL_OPEN_DEALS_STOP: 'Open х deals & stop',
    LABEL_DEALS_TO_SMART_TRADE: 'Autoconvert new deals to SmartTrade',
    LABEL_CLOSE_DEAL_AFTER_TIMEOUT: 'Close deal after timeout',
    LABEL_TIME_DEAL_CLOSED: 'The time after which, deal will be closed automatically',
    LABEL_TARGET_PROFIT: 'Target profit (%)',
    LABEL_TAKE_PROFIT_TYPE: 'Take profit type',
    LABEL_TRAILING: 'Trailing',
    LABEL_TRAILING_DEVIATION: 'Trailing deviation',
    LABEL_MINIMUM_PROFIT: 'Minimum profit (%)',
    LABEL_MINIMUM_PROFIT_TYPE: 'Minimum profit type',
    LABEL_PRESETS: 'Presets',
    LABEL_RSI_PERIOD: 'RSI Period',
    LABEL_MARKET: 'Market',
    LABEL_TRADING_PAIR: 'Trading Pair',
    LABEL_DEAL_START_CONDITION: 'Deal start condition',
    LABEL_STRATEGY_TYPE: 'Strategy type',
    LABEL_DAYS_RUNNING: 'Days running',
    LABEL_MAX_AMOUNT_BOT_USAGE: 'Max amount for bot usage (Based on current rate)',
  },
  SELECTORS: {
    EXCHANGE_LIST: [
      { value: 'Account Name', label: 'Account Name', name: 'Exchange' },
    ],
    QUOTE_CURRENCY_LIST: [
      { value: 'USDT', label: 'USDT', name: 'Quote currency' },
    ],
    MAIN_SETTINGS_EXCHANGE_LIST: [
      { value: 'Account Name', label: 'Account Name', name: 'exchange_selected' },
    ],
    PAIRS_LIST: [
      { value: 'BTC / USDT', label: 'BTC / USDT', name: 'Pairs' },
    ],
    BASE_ORDER_SIZE_LIST: [
      { value: 'USDT', label: 'USDT', name: 'base_order_size' }
    ],
    CONDITION_LIST: [
      { value: 'QFL', label: 'QFL', name: 'condition' },
      { value: 'CQS Scalping', label: 'CQS Scalping', name: 'condition' },
      { value: 'Manually / API (Bot won’t open new trades automatically)', label: 'Manually / API (Bot won’t open new trades automatically)', name: 'condition' },
      { value: 'Trading View custom signal', label: 'Trading View custom signal', name: 'condition' },
      { value: 'RSI', label: 'RSI', name: 'condition' },
      { value: 'ULT-7-14-28', label: 'ULT-7-14-28', name: 'condition' },
      { value: 'BB%-20-1', label: 'BB%-20-1', name: 'condition' },
      { value: 'BB%-20-2', label: 'BB%-20-2', name: 'condition' },
      { value: 'TA Presets', label: 'TA Presets', name: 'condition' },
      { value: 'Trading View', label: 'Trading View', name: 'condition' },
      { value: 'Open new trade asap', label: 'Open new trade asap', name: 'condition' }
    ],
    TIMEFRAME_LIST: [
      { value: 'Original', label: 'Original', name: 'timeframe' },
      { value: 'Day Trading', label: 'Day Trading', name: 'timeframe' },
      { value: 'Conservative trader', label: 'Conservative trader', name: 'timeframe' },
      { value: 'Position trader', label: 'Position trader', name: 'timeframe' }
    ],
    SAFETY_ORDERS_SIZE_LIST: [
      { value: 'USDT', label: 'USDT', name: 'safety_orders_size' }
    ],
    STOP_LOSS_ACTION_LIST: [
      { value: 'Close deal', label: 'Close deal', name: 'stop_loss_action' },
      { value: 'Close deal & stop bot', label: 'Close deal & stop bot', name: 'stop_loss_action' }
    ],
    CLOSE_DEAL_AFTER_TIMEOUT_LIST: [
      { value: 'Min', label: 'Min', name: 'close_deal_after_timeout' },
      { value: 'Hrs', label: 'Hrs', name: 'close_deal_after_timeout' },
      { value: 'Days', label: 'Days', name: 'close_deal_after_timeout' }
    ],
    PROFIT_TYPE_LIST: [
      { value: 'Percentage from base order', label: 'Percentage from base order', name: 'profit_type' },
      { value: 'Percentage from total volume', label: 'Percentage from total volume', name: 'profit_type' }
    ],
    MINIMUM_PROFIT_TYPE_LIST: [
      { value: 'Percentage from base order', label: 'Percentage from base order', name: 'minimum_profit_type' },
      { value: 'Percentage from total volume', label: 'Percentage from total volume', name: 'minimum_profit_type' }
    ],
    CONDITIONS_CLOSING_BOT_LIST: [
      { value: 'Trading View', label: 'Trading View', name: 'conditions_closing_bot' },
      { value: 'RSI', label: 'RSI', name: 'conditions_closing_bot' },
      { value: 'ULT-7-14-28', label: 'ULT-7-14-28', name: 'conditions_closing_bot' },
      { value: 'BB%-20-1', label: 'BB%-20-1', name: 'conditions_closing_bot' },
      { value: 'BB%-20-2', label: 'BB%-20-2', name: 'conditions_closing_bot' },
      { value: 'TA Presets', label: 'TA Presets', name: 'conditions_closing_bot' },
    ],
    CONDITIONS_LIST: [
      { value: 'Less Than', label: 'Less Than', name: 'conditions' },
      { value: 'Greater Than', label: 'Greater Than', name: 'conditions' },
      { value: 'Grossing Down', label: 'Grossing Down', name: 'conditions' },
      { value: 'Grossing Up', label: 'Grossing Up', name: 'conditions' },
    ],
    SIGNAL_VALUE_LIST: [
      { value: 'Sell', label: 'Sell', name: 'signal_value' },
      { value: 'Strong Sell', label: 'Strong Sell', name: 'signal_value' },
      { value: 'Buy', label: 'Buy', name: 'signal_value' },
      { value: 'Strong Buy', label: 'Strong Buy', name: 'signal_value' },
    ],
    CONDITIONS_TIMEFRAME_LIST: [
      { value: '1 minute', label: '1 minute', name: 'conditions_timeframe' },
      { value: '5 minutes', label: '5 minutes', name: 'conditions_timeframe' },
      { value: '15 minutes', label: '15 minutes', name: 'conditions_timeframe' },
      { value: '1 hour', label: '1 hour', name: 'conditions_timeframe' },
      { value: '4 hours', label: '4 hours', name: 'conditions_timeframe' },
      { value: '1 day', label: '1 day', name: 'conditions_timeframe' },
      { value: '1 week', label: '1 week', name: 'conditions_timeframe' },
      { value: '1 month', label: '1 month', name: 'conditions_timeframe' },
      { value: 'Cumulative', label: 'Cumulative', name: 'conditions_timeframe' },
    ],
    PRESETS_LIST: [
      { value: 'BB-20-1-UB', label: 'BB-20-1-UB', name: 'presets' },
      { value: 'BB-20-2-UB', label: 'BB-20-2-UB', name: 'presets' },
      { value: 'MFI-14 crosses 65', label: 'MFI-14 crosses 65', name: 'presets' },
      { value: 'CCI-40 crosses 0', label: 'CCI-40 crosses 0', name: 'presets' },
      { value: 'CCI-40 crosses 100', label: 'CCI-40 crosses 100', name: 'presets' },
      { value: 'MACD cross 12-26-9', label: 'MACD cross 12-26-9', name: 'presets' },
      { value: 'Parabolic SAR 0.02-0.02-0.2', label: 'Parabolic SAR 0.02-0.02-0.2', name: 'presets' },
      { value: 'SMA 20 crosses 50', label: 'SMA 20 crosses 50', name: 'presets' },
      { value: 'SMA 50 crosses 100', label: 'SMA 50 crosses 100', name: 'presets' },
      { value: 'SMA 50 crosses 200', label: 'SMA 50 crosses 200', name: 'presets' },
      { value: 'SMA 100 crosses 200', label: 'SMA 100 crosses 200', name: 'presets' },
      { value: 'Heikin Ashi 1 candle', label: 'Heikin Ashi 1 candle', name: 'presets' },
      { value: 'Heikin Ashi 3 candles', label: 'Heikin Ashi 3 candles', name: 'presets' },
      { value: 'Heikin Ashi 5 candles', label: 'Heikin Ashi 5 candles', name: 'presets' },
    ],
    MARKET_LIST: [
      { value: 'AUD', label: 'AUD', name: 'market' }
    ],
    TRADING_PAIR_LIST: [
      { value: 'ADA / AUD', label: 'ADA / AUD', name: 'trading_pair' },
    ],
    PAIRS_BLACK_LIST: [
      { value: 'Placeholder', label: 'Placeholder', name: 'pairs_black_list' },
    ],
    FILTERS_LIST: [
      { value: 'Filters', label: 'Filters', name: 'filters' },
    ],
  },
  MORE_INFO: {
    EXCHANGE: 'This is the exchange account the bot will use for any deals it creates.',
    NAME: 'Please give this bot a unique name so reporting and management is easier',
    PAIRS: 'Please select the Trading Pair(s) this bot can use.',
    MOST_TRADED_PAIRS: 'These are popular assets with the most successful trades completed by 3Commas users and bots within the last 24 hours.',
    STRATEGY: 'Long bots profit when asset prices rise, Short bots profit when asset prices fall. Please note: to run a Short bot on a spot exchange account, you need to own the asset you want to trade. The bot will sell the asset at the current chart price and buy it back at a lower price - the profit made is actually trapped equity released from an asset you own that is declining in value.',
    BASE_ORDER_SIZE: 'The Base Order is the first order the bot will create when starting a new deal.',
    START_ORDER_TYPE: '"Market" allows your bot to execute a Market Order to open a new trade immediately, but may be subject to slippage or spread if trading large amounts of funds or trading low liquidity assets. "Limit" allows your bot to place a Limit Order to start a new trade, this may mean the bot deal may take slightly longer to open but will prevent issues with slippage or spread, also your exchange may also charge a smaller fee for using Limit Orders.',
    DEAL_START_CONDITION: 'Combining standalone indicators and various TA Presets is possible. All conditions link with logical AND, so ALL of them should be TRUE to let the bot open a new deal. All these conditions evaluate their values only when the candle is closed on the chart within a specified time frame.',
    SAFETY_ORDERS: 'Safety Orders (also known as Dollar Cost Averaging orders) allows a trader to buy more of an asset as the price declines, thus lowering the overall cost of the total amount of the asset held in the portfolio, this allows a trader to sell the asset for a profit at a lower price when the decline reverses, for example.',
    SAFETY_ORDERS_SIZE: 'Enter the amount of funds your safety orders will use to average the cost of the asset being traded. This can help your bot to close deals faster with more profit. Safety orders are also known as Dollar Cost Averaging and help when prices move in the opposite direction to your bot\'s take profit target.',
    PRICE_DEVIATION: 'Enter the percentage difference in price to create the first Safety Order. All Safety Orders are calculated from the price the initial Base Order was filled on the exchange account.',
    SAFETY_ORDERS_COUNT: 'This is the total number of Safety Orders the bot is allowed to use per deal that is opened. All Safety Orders created by the bot are placed as Limit Orders on the exchange\'s order book.',
    ACTIVE_SAFETY_ORDERS_COUNT: 'This is the amount of Safety Orders the bot is allowed to place in advance on the exchange\'s order book for the asset being traded. A high number of Active Safety Orders will lock and reserve more funds for a running bot deal. Using a higher number for this setting can be useful if you experience "Insufficient Funds placing Safety Order" messages, or accidentally over-allocate funds this bot will require to complete deals successfully on other trades for this exchange account',
    SAFETY_ORDER_VOLUME_SCALE: 'The Safety Order Volume Scale is used to multiply the amount of funds used by the last Safety Order that was created. Using a larger amount of funds for Safety Orders allows your bot to be more aggressive at Dollar Cost Averaging the price of the asset being traded.',
    SAFETY_ORDER_STEP_SCALE: 'The Safety Order Step Scale is used to multiply the Price Deviation percentage used by the last Safety Order placed on the exchange account.Using a larger value here will reduce the amount of Safety Orders your bot will require to cover a larger move in price in the opposite direction to the active deal\'s take profit target.',
    STOP_LOSS: 'This is the percentage that price needs to move in the opposite direction to your take profit target, at which point the bot will execute a Market Order on the exchange account to close the deal for a smaller loss than keeping the deal open. Please note, the Stop Loss is calculated from the price the initial Base Order was filled at on the exchange account and not the Dollar Cost Average price',
    STOP_LOSS_ACTION: 'This is the action the bot should perform if it closed a deal with the Stop Loss setting, for example, if you are trading highly volatile assets with this bot, you may wish the bot to disable itself to prevent further trades being created until you review the market conditions and re-enable the bot manually or by an automated signal. Please note, this setting will only take effect on the next deal the bot opens after the bot settings have been saved. If the bot has already started active deals before this setting was altered, you will need to edit the individual trade on the "My Deals" page.',
    STOP_LOSS_TIMEOUT: 'This will prevent the bot from immediately executing the Stop Loss order and can be useful to prevent the bot from closing a deal due to erroneous candle wicks or Stop Loss "hunts". The timer is activated when price reaches or exceeds the Stop Loss % you configure; if price has not recovered before the timer expires, then the bot will close the active deal at the current chart price. The time is specified in seconds.',
    DONT_START_DEALS: 'Configure this setting if you wish to avoid trading assets with low or poor liquidity. The value entered must be the trading volume measured in BTC for the last 24 hours; if a signal to start a deal for an asset with less trading volume than the value entered, then the bot will not open a deal. You can find the BTC trading volume for specific assets on websites like coinmarketcap.com',
    MINIMUM_PRICE_DEAL: 'The bot will not start a new deal for an asset if the price is below the value you enter here. The value used is the actual price of the asset being traded.',
    MAXIMUM_PRICE_DEAL: 'The bot will not start a new deal for an asset if the price is above the value you enter here. The value used is the actual price of the asset being traded.',
    COOLDOWN_BETWEEN_DEALS: 'This is the time in seconds that the bot will wait after a deal is closed before it will accept a signal to start a new deal for the same asset pair. The bot will use a different timer for each asset pair the bot is allowed to trade.',
    OPEN_DEALS_STOP: 'The bot will disable itself after completing the number of trades configured with this setting. This will prevent the bot from creating new deals until it is re-enabled manually or by an automated signal you configure. Please note: the counter will only start from the next deal the bot opens after you configure and save this setting. Any active deals running before this setting is configured will not be counted',
    TIME_DEAL_CLOSED: 'Some traders like to open deals for a specific amount of time, for example at 13:30 each day, but they want the deal to close automatically, whether in profit or loss, if it hasn\'t reached the Take Profit target configured. This can be useful if you have an automated signal to start new bot deals during times of peak trading volume, such as New York session times, for example.The time can be specified in minutes, hours or days and the counter starts as soon as a new deal has started.',
    TARGET_PROFIT: 'Configure the percentage Take Profit target the bot will use to close successful trades, the bot will automatically account for exchange fees.',
    TAKE_PROFIT_TYPE: 'If you configure the "percentage from base order" setting, this will close trades faster when Safety Orders are executed but the value of the profit will remain the same and will be calculated from the initial Base Order size, for example: Base Order $10, Take Profit 10% means that the profit will be $1 regardless of the amount of Safety Orders executed. Alternatively, to calculate the Take Profit target percentage using the Dollar Cost Average price of the asset being traded, use the "percentage from total volume" setting, this will increase the value of the amount of profit taken if the deal uses multiple safety orders.',
    BALANCE: 'Total: 9990 USDT\n' +
      'Locked in Limit Orders: 0 USDT\n' +
      'Locked in Smart Trades: 0 USDT',
    MAX_AMOUNT_BOT_USAGE: 'This is an estimate of the total amount of funds the bot requires with the settings it currently has configured. The value shown here is calculated if the bot is running the Maximum Active Deals allowed and with all configured Safety Orders filled. Please note, whilst the amount displayed is usually very accurate, there can be a small variance when live trading as the calculation assumes there is no slippage or other factors that may affect the prices orders are filled on the exchange.Don\'t forget to check the other tabs on the Bot Assistant, like the extremely useful "table" page to help visualise your Safety Orders'
  },
  MODALS: {
    CREATE_BOT: 'Create a new DCA Bot',
    CREATE_BOT_SUBTITLE: 'DCA bots open trades based on signals, create safety orders if necessary, and closes positions when the goal is reached.',
    WARNING: 'Warning',
    BOT_CREATE_SMART_TRADE: 'If you enable this option, every time the «Deal start condition» is triggered, bot will create SmartTrade.',
    UNTIL_NO_FUNDS_LEFT: 'Bot can potentially create unlimited SmartTrades until you have no funds left.',
    AS_SAFEGUARD: 'As a safeguard, we recommend you configure a value for the',
    OPEN_DEALS_STOP: '«Open х deals & stop»',
    SETTING_BOT: 'setting for this bot.',
    USED_AMOUNT_STEP: 'Used amount for each step',
    BOT_USED_PAPER_ACCOUNT: 'This bot was used for the Paper account and might not properly work with your Real account.',
    SWITCH_ACCOUNT_PAPER: 'Please switch the account to the Paper to run this bot.',
  },
  BUTTONS: {
    CREATE_BOT: 'Create Bot',
    CREATE_NEW_DCA_BOT: 'Create a new DCA Bot',
    NEXT_STEP: 'Next step',
    PREVIOUS_STEP: 'Previous step',
    ADD_CONDITION: 'Add condition (works on the "AND" condition)',
    UNDERSTAND_CONTINUE: 'I understand, continue',
    CANCEL: 'Cancel',
    COPY_BOT: 'Copy Bot',
    CLEAR_FILTERS: 'Clear filters',
    CREATE_BOT_MANUALLY: 'Create a bot manually',
    CHOOSE_FROM_PRESETS: 'Choose from presets',
    CREATE_DCA_BOT: 'Create DCA Bot',
    CLEAR_ALL: 'Clear all',
    START: 'Start',
    CONNECT_EXCHANGE: 'Connect an exchange',
    IGNORE_COPY_BOT: 'Ignore and copy bot',
    SWITCH_TO_PAPER: 'Switch to Paper',
    DEALS_HISTORY: 'Deals history',
  },
  TITLES: {
    MAIN_SETTINGS: 'MAIN SETTINGS',
    PAIRS: 'PAIRS',
    STRATEGY: 'STRATEGY',
    DEAL_START_CONDITION: 'DEAL START CONDITION',
    SAFETY_ORDERS: 'SAFETY ORDERS',
    STOP_LOSS: 'STOP LOSS',
    ADVANCED_SETTINGS: 'ADVANCED SETTINGS',
    TAKE_PROFIT: 'TAKE PROFIT',
    CLOSE_DEAL_AFTER_TIMEOUT: 'CLOSE DEAL AFTER TIMEOUT',
    FILTERS: 'FILTERS',
    COPY_BEST_BOTS: 'COPY BEST PERFORMING BOTS',
    MY_BOTS: 'MY BOTS',
    PAIRS_BLACK_LIST: 'PAIRS “BLACK LIST”',
    BOT_STATS: 'BOT STATS',
    APPLY_BOT_CONFIGURATION: 'Apply bot configuration',
  },
  TEXT: {
    RIGHT_CONDITIONS: 'Find the right conditions for starting a trade for your bot in our',
    SIGNAL_MARKET: 'signal market',
    RIGHT_CONDITIONS_CLOSING_BOT: 'Select the right conditions for closing a trade for your bot',
    BALANCE: 'Balance',
    MAX_AMOUNT_BOT_USAGE: 'Max amount for bot usage (Based on current rate)',
    MAX_SAFETY_ORDER_PRICE_DEVIATION: 'Max safety order price deviation',
    PERCENT_BALANCE_USED_BOT: '% of total balance to be used by the bot',
    WARNING_BOT_USE_AMOUNT_GREATER: 'Warning! Bot will use amount greater than you have on exchange',
    USED_AMOUNT: 'Used amount',
    SAFETY_ORDER_NUMBER: 'Safety order number',
    NO_NEED_TO_LEARN_HARD_WAY: 'No need to learn the hard way. Create your first bot from presets to start off on the right foot.',
    COINS: 'Coins',
    DCA_LONG_STRATEGY: 'DCA Long Strategy',
    PROFIT_LAST_MONTH: 'Profit last month',
    NO_RESULTS_FOUND: 'No results were found for your search. Please, change your search parameters.',
    NO_BOTS_CREATED: 'You don’t have any bots created',
    CREATE_BOT_YOURSELF: 'Copy best performing bots from the Marketplace or create a bot by yourself',
    TOTAL_PROFIT: 'Total profit',
    AVERAGE_DAILY_PROFIT: 'Average daily profit',
    CLOSED_DEALS: 'Closed deals',
    ACTIVE_DEALS: 'Active deals',
    BEST_PAIRS: 'Best pairs',
    MOST_PROFITABLE_BOT: 'Most profitable bot',
    FUTURES_LONG_ATA: 'FUTURES Long ATA',
    EXCHANGE: 'Exchange',
    EDIT_SETTINGS: 'Edit settings',
    OVERALL_STATS: 'OVERALL STATS',
    TODAY_PROFIT: 'Today profit',
    TOTAL: 'Total',
    FUNDS_LOCKED_DCA_DEALS: 'Funds locked in DCA bot deals',
    U_PNL_ACTIVE_DEALS: 'uPNL of active Bot Deals',
    COMPLETED_DEALS_PROFIT: 'COMPLETED DEALS PROFIT',
    BALANCES: 'BALANCES',
    RESERVED: 'Reserved',
    AVAILABLE: 'Available',
  },
  TABLES: {
    BEST_SINGLE_PAIRS: 'Today\'s best Single-pair bots',
    PAIR: 'Pair',
    COMPLETED_DEALS: 'Completed deals',
    BEST_PAIRS: 'Today\'s best pairs',
    BOTS_COUNT: 'Bots count',
    OVERALL_STATS: 'Overall stats',
    DAYS_DEALS: '24h deals',
    WEEK_DEALS: 'week deals',
    MONTH_DEALS: 'month deals',
    TOTAL_DEALS: 'Total deals',
    TODAY_NEW_BOTS: 'Today new bots',
    TOTAL_BOTS: 'Total bots',
    BEST_MULTI_PAIR: 'Today\'s best Multi-pair bots',
    NAME: 'Name',
    QUOTE_CURRENCY: 'Quote currency',
    NUMBER_OF_PAIRS: 'Number of pairs',
    ACTIVE_DEALS_LIMIT: 'Bot max active deals limit',
    NUMBER_OF_COMPLETED_DEALS: 'Number of completed deals',
    EXCHANGE: 'Exchange',
    BOTS: 'BOTS',
    DEALS: 'Deals',
    TAKE_PROFIT: 'Take profit',
    AVG_DAILY_PROFIT: 'Avg. daily profit',
    ACTIVE_DEALS: 'Active Deals',
    STATUS: 'Status',
    DETAILS: 'Details',
    SHORT: 'Short',
    NONSTOP: 'Nonstop',
    BOT: 'Bot',
    PROFIT: 'Profit',
    VOLUME: 'Volume',
    SO: 'SO',
    CREATED: 'Created',
    COMPLETED: 'Completed',
    ACTIVE: 'Active',
    MAX: 'Max',
    ID: 'ID',
    START: 'Start',
  },
  STANDARD_OPTIONS_SCHEMA: {
    dropdownClass: 'custom-dropdown',
    classes: '',
    list: [
      { id: 'add-funds', label: 'Add Funds', icon: 'fa-plus' },
      { id: 'reverse-bot', label: 'Reverse bot', icon: 'fa-exchange' },
      { id: 'close', label: 'Close at Market Price', icon: 'fa-times' },
      { id: 'edit', label: 'Edit', icon: 'fa-pen' },
      { id: 'convert', label: 'SmartTrade Convert', icon: 'fa-chart-simple' },
      { id: 'refresh', label: 'Refresh', icon: 'fa-refresh' },
      { id: 'cancel', label: 'Cancel', icon: 'fa-hand' }
    ],
    menuAlign: 'end',
    value: {},
    title: '',
    actionName: '',
    appendToPayload: '',
  },
};