import React from 'react';
import './style.scss';
import { headerEffects } from '../../header/headerEffects';
import { useStoreActions, useStoreState } from 'easy-peasy';

export const ThemeButton = props => {
    const { active } = props;
    const state = useStoreState(state => state.root);
    const actions = useStoreActions(actions => actions);
    const value = active ? { left: 'active', right: '' } : { left: '', right: 'active' };
    return active === undefined ? null : <div
        className="theme-group-button d-flex justify-content-center align-items-center"
        onClick={() => actions[headerEffects.actionTypes.HEADER_SETTINGS_CHANGED]({name: 'theme', value: state.theme})}>
        <div className="d-flex flex-column justify-content-center align-items-center">
            <i className={`icon-sun  ${value.left}`}></i>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
            <i className={`icon-moon  ${value.right}`}></i>
        </div>
    </div>;
};