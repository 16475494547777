import React from 'react';
import { Button } from 'react-bootstrap';
import { useStoreActions } from 'easy-peasy';
import styles from './styles.scss';
import { addButtonEffects } from './addButtonEffect';


export const AddButton = props => {
    const {
        iconClass,
        label,
        title,
        content,
        actionName,
        size,
        variant,
        appendToPayload,
        buttonClass,
        labelClass } = props;
    const actions = useStoreActions(actions => actions);
    return <div className={`add-button-action ${buttonClass}`}
        onClick={() => addButtonEffects.uiAction(actionName, appendToPayload, actions) }>
        <div className="d-flex justify-content-start align-items-center">
            <Button
                variant={variant || 'primary'}
                size={size || 'sm'}
                title={title || label || ''}
            ><i className={iconClass || ''}></i>{content || ''}</Button>
            <div className={`add-button-label ${labelClass || ''}`}>{label || ''}</div>
        </div>
    </div>;
};

