import React from 'react';
import { constants } from '../constants';
import { packsEffects } from '../actions/packsEffects';
import { Asset } from '../../../components/utility/asset-component/view';

const { CONTROLS: { LABEL_ADD_PAIR } } = constants;

const renderAddPair = actions => {
    const clickHandle = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]({ actionName: LABEL_ADD_PAIR });
    return <div key={LABEL_ADD_PAIR} className="pair-item -add-pair font-size-14" onClick={clickHandle}>{LABEL_ADD_PAIR}</div>;
};

const renderPair = (pair, actions, state) => {
    const { selectedPack: { mainCoin, pairSelectedId }, editPackMode } = state;
    const deleteHandle = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({id: 'delete_pair', pair, ...state});
    return <div className={`pair-content d-flex`}>
        <Asset asset={pair}/>
        <i className="fa-light fa-slash-forward font-size-10 pe-1 ps-1"></i>
        <Asset asset={mainCoin}/>
        {editPackMode ? <i className="fa-light fa-times font-size-16 ps-3 cursor-pointer negative-color" onClick={deleteHandle}></i> : null}
    </div>;
};

const renderPairs = (state, actions) => {
    const { selectedPack: { pairs }, editPackMode, selectedPair } = state;
    const editClass = editPackMode ? '-edit' : '';
    const pairsList = pairs.map((pair, key) => {
        const clickHandle = () => !editPackMode ? actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({id: 'select_pair', pair, ...state}) : '';
        const isPairSelected = pair === selectedPair?.id ? 'active' : '';
        return <div key={key} className={`pair-item ${editClass} ${isPairSelected}`} onClick={clickHandle}>
            { renderPair(pair, actions, state) }
        </div>;
    });
    return editPackMode ? [renderAddPair(actions), ...pairsList] : pairsList;
};


export const pairs = {
    renderPairs
};