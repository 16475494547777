import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { dataHelper } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { isEmpty } from 'lodash';

const REQUEST_URL = '/web3/getbalance';

const actionTypes = {
    WEB3_BALANCES_FETCHED: 'WEB3_BALANCES#FETCHED',
    WEB3_BALANCES_BLACKLIST_POST: 'WEB3_BALANCES_BLACKLIST#POST',
    WEB3_BALANCES_BLACKLIST_POST_SUCCEEDED: 'WEB3_BALANCES_BLACKLIST_POST#SUCCEEDED',
    WEB3_BALANCES_BLACKLIST_POST_FAILED: 'WEB3_BALANCES_BLACKLIST_POST#FAILED',
    WEB3_BALANCES_FETCH_SUCCEEDED: 'WEB3_BALANCES_FETCH#SUCCEEDED',
    WEB3_BALANCES_FETCH_FAILED: 'WEB3_BALANCES_FETCH#FAILED',
    WEB3_BALANCES_REVOKED: 'WEB3_BALANCES#REVOKED'
};

const fetchHandle = (actions, payload) => {
    serviceUtils.HttpService({
        url: `${REQUEST_URL}?address=${payload.address}${utils.getChain(payload.chain)}`,
        data: null,
        errorActionType: actions[actionTypes.WEB3_BALANCES_FETCH_FAILED],
        successActionType: actions[actionTypes.WEB3_BALANCES_FETCH_SUCCEEDED]
    });
};

const postBlackList = (actions, payload) => {
    serviceUtils.HttpService({
        url: REQUEST_URL,
        method: 'post',
        data: payload,
        errorActionType: actions[actionTypes.WEB3_BALANCES_BLACKLIST_POST_FAILED],
        successActionType: actions[actionTypes.WEB3_BALANCES_BLACKLIST_POST_SUCCEEDED]
    });
};

const successPostHandle = (actions, payload) => {
    const { response } = payload;
    // if(response) actions[actionTypes.WEB3_BALANCES_FETCHED];
};

const successHandle = (state, payload) => {
    const { response: { balArray , blackList: { blackListTokens } } } = payload;
    const fixedAddressesArray = utils.nullAddressHandler(balArray);

    state.loader = false;
    if (state.typeOfFetch === 'push') {
        state.tableData.data = [...state.tableData.data, ...fixedAddressesArray];
        state.blackList.data = blackListTokens ? [...state.blackList.data, ...blackListTokens] : [];
    }
    else {
        state.tableData.data = fixedAddressesArray;
        state.blackList.data = blackListTokens || [];
    }


    if(isEmpty(state.blackList.data)) state.tableData.filteredData = state.tableData.data;
    else state.tableData.filteredData = utils.filteredData(state.tableData.data, state.blackList.data);

    if(!state.selector.value.value) state.blackList.filteredData = state.blackList.data;
    else state.blackList.filteredData = state.blackList.data.filter(token => token.blockchainName === state.selector.value.value);

    
    state.tableData.totalBalance = dataHelper.getTotalBalance(fixedAddressesArray);
    state.tableData.pagination.hasMore = fixedAddressesArray.length < 50 ? false : true;
};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.tableData.data = state.typeOfFetch === 'push' ? state.tableData.data : [];
    state.tableData.pagination.hasMore = true;
};

const revokeHandle = (actions, payload) => {
    console.info(payload);
};

const actionHandlers = {
    [actionTypes.WEB3_BALANCES_BLACKLIST_POST]: thunk (async (actions, payload) => postBlackList(actions, payload)),
    [actionTypes.WEB3_BALANCES_BLACKLIST_POST_SUCCEEDED]: thunk((actions, payload) => successPostHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_FETCHED]: thunk( async (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.WEB3_BALANCES_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.WEB3_BALANCES_REVOKED]: thunk((actions, payload) => revokeHandle(actions, payload))
};

export const balancesEffects = {
    actionTypes,
    actionHandlers
};