import { useStoreActions } from 'easy-peasy'
import React from 'react'
import {Link} from 'react-router-dom'


import '../styles.scss'

const CloseIcon = ({fileIndex}) => {

  const removeFile = useStoreActions(actions => actions.REMOVE_FILE)

  const handleRemove = (e, fileIndex) => {
    e.preventDefault()

    removeFile(fileIndex);
  }

  return (
    <Link to="" className="closeIcon" onClick={(e) => handleRemove(e, fileIndex)}>
        <i className="fa-regular fa-xmark"></i>
    </Link>
  )
}

export default CloseIcon