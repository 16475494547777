import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Modal } from 'react-bootstrap';
import { TableModalTemplate } from './tables/tableModalTemplate';

export const AnalyticsModal = props => {
    const { show, actions, data } = props;
    const handleClose = () => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ name: 'close-modal' });

    return <Modal
        show={show}
        size="lg"
        centered
        className="add-exchange analytics-modal"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="font-weight-medium text-start modal-main-header">{data.heading}</div>
            <div className="modal-main-content">
                <TableModalTemplate />
            </div>
        </Modal.Body>
    </Modal>;
};