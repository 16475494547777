import React from 'react';

export const POSITION_IMG = <>
    <path d="M350.19 4.19617e-05H0.690186L25 24.6947L374.5 24.6947L350.19 4.19617e-05Z" fill="url(#paint0_linear_3795_88376)"/>
    <defs>
        <linearGradient id="paint0_linear_3795_88376" x1="173" y1="15.6947" x2="279" y2="15.1947" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1F55FE"/>
            <stop offset="1" stopColor="#460D7E"/>
        </linearGradient>
    </defs>
</>;