import { exchangesDataHelper } from './dataHelpers/exchangesDataHelper';
import { utils } from '../../utils/utils';


const savePrice = (state, buffer) => {
    state.loaders.table = false;
    state.coinData.timeStamp = utils.getNowTimeStamp();
    state.coinData.buffer = JSON.parse(JSON.stringify([...buffer.entries()])); // we can't save Map as it is in state
    state.coinData.exchangesWithPrices = exchangesDataHelper.exchangesMutation(state, buffer);
};

export const priceEffects = {
    savePrice
};