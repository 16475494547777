import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { CareersState } from '../../../../../models/careers-model';
import { careersEffects } from '../actions/careersEffects';
import { ApplyForm } from './applyForm';

const { MODAL: { APPLY, WE_RECEIVED_CV, CLOSE } } = constants;

export const ApplyModal = props => {
  const { show } = props;
  const actions = CareersState.useStoreActions(actions => actions);
  const successfullyApplied = CareersState.useStoreState(state => state.successfullyApplied);

  const handleClose = () => actions[careersEffects.actionTypes.CAREERS_MODAL_APPLY_CLOSED](false);

  return <Modal
    show={show}
    size="md"
    centered
    className="careers-apply"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
  >
    <Modal.Header>
      <div className="font-size-16 font-weight-medium form-title">{APPLY}</div>
      { !successfullyApplied &&
        <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
          <i className="icon-cancel"></i>
        </div>
      }
    </Modal.Header>
    <Modal.Body>
      <div className="d-flex flex-column justify-content-center">
        {
          !successfullyApplied ? <ApplyForm handleClose={handleClose} actions={actions} />
          : <div className="successfully-applied">
              <img className='img-fluid' src='../dashboard-library/home-page-images/letterChecked.svg' alt="Checked" />
              <p>{WE_RECEIVED_CV}</p>
              <div className="submit-button">
                <Button
                type="button"
                size="lg"
                variant='primary'
                className="font-size-14 font-weight-medium btn-custom w-100"
                onClick={handleClose}>{CLOSE}</Button>
              </div>
            </div>
        }
      </div>
    </Modal.Body>
  </Modal>;
};
