import React from 'react'
import {constants} from '../constants'

const {BALANCES: {TITLE,MESSAGE}} = constants;

const Balanaces = () => {
  return (
    <>
        <h3>{TITLE}</h3>

        <div className="empty-search-state-icon"></div>
        <p className="message">{MESSAGE}</p>
    </>
  )
}

export default Balanaces
