import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { constants } from '../../../constants';
import { customBotsEffects } from '../../../actions/customBotsEffects';
import {customBotsActions} from "../../../actions/uiActions";

const {
  MODALS,
  BUTTONS: { CANCEL, UNDERSTAND_CONTINUE }
} = constants;

export const SmartTradeWarningModal = props => {
  const { show, actions } = props;

  const handleClose = (value) => {
    actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]({name: 'toggle_deals_to_smart_trade', value: value });
    actions[customBotsActions.actionTypes.CUSTOM_BOTS_MODAL_INVOKED]({modal: 'modalWarning', name: 'close-modal'});
  }

  return <Modal
    show={show}
    size="md"
    centered
    className="create-bot"
    backdrop="static"
    container={document.querySelector('.wrapper')}
  >
    <Modal.Body>
      <div className="font-inter text-center">
        <img className='img-fluid mb-3 mt-5' src='../dashboard-library/warning.svg' alt="Warning" />
        <p className="font-size-24 font-weight-bold">{MODALS.WARNING}</p>
        <div>
          <p>{MODALS.BOT_CREATE_SMART_TRADE}</p>
          <p>{MODALS.UNTIL_NO_FUNDS_LEFT}</p>
          <p>
            {MODALS.AS_SAFEGUARD}
            <span className="font-weight-bold"> {MODALS.OPEN_DEALS_STOP} </span>
            {MODALS.SETTING_BOT}
          </p>
        </div>
        <div className="mb-2 mt-4 submit-button d-flex justify-content-between">
          <Button
            type="button"
            size="lg"
            className="font-size-14 font-weight-medium btn-transparent"
            onClick={() => handleClose(false)}>{CANCEL}</Button>
          <Button
            type="button"
            size="lg"
            className="font-size-14 font-weight-medium btn-default"
            onClick={() => handleClose(true)}
          >{UNDERSTAND_CONTINUE}</Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>;
};
