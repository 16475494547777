import React, { useEffect, useRef } from 'react';
import { HodlBotControls, HodlBotTradePacks } from './packsTemplates';
import { packsEffects } from '../../actions/packsEffects';
import { isEmpty } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { selectorsEffects } from '../../actions/selectorsEffects';

import { SelectorsSetTemplate } from './choiseSelectorsTemplate'
import { AddNewPackModal } from './modalTemplates';
import { constants } from '../../constants';

const {
  PACKS: { PACKS }
} = constants;

export const SinglePairPacks = props => {
  const { actions, state } = props;
  const allPackData = useStoreState(state => state.packs);
  // const assets = useStoreState(state => state.availableExchangesList);
  const exchanges = useStoreState(state => state.coinData.exchanges);
  const ref = useRef();

  useEffect(() => {
    actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'exchange_update' });
  }, [state.packs.exchangeId]);

  useEffect(() => {
    !isEmpty(allPackData) ? actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'load_packs', allPackData }) : '';
  }, [allPackData]);

  // useEffect(() => {
  //   actions[selectorsEffects.actionTypes.HODL_BOT_SELECTORS_DATA_UPDATED](assets);
  // }, [assets]);

  return <div ref={ref} className="smart-trade smart-trade-wrapper">
    <div className="smart-trade-controls-container">
      <HodlBotControls/>
    </div>
    <div className="smart-trade-choice-container">
      { !isEmpty(exchanges) ? <SelectorsSetTemplate exchanges={exchanges} mode={state.controls.packs}/> : <div></div> }
    </div>
    { state.controls.packs && <div className="font-weight-medium ps-3 pt-2">{PACKS}</div> }
    <div className="smart-trade-packs-container">
      { state.controls.packs ? <HodlBotTradePacks/> : null }
    </div>
    { state.controls.packs && ref.current ? <AddNewPackModal ref={ref.current}/> : null }
  </div>
};
