export const constants = {
    TOP_CONTENT: {
        HEADER: ['Portfolio', 'History', 'Analytics'],
        CHAINS: {
            ALL: 'All',
        }
    },
    VENUS: {
        HEADING: 'LENDING',
        GET_COVERED: 'Get covered',
        TABLE_VENUS_KEYS: ['Token', 'Borrow', 'Supply', 'Value'],
    },
    COMPOUND: {
        HEADING: 'COMPOUND',
        GET_COVERED: 'Get covered',
        TABLE_COMPOUND_KEYS: ['Token', 'Borrow', 'Supply', 'Value'],
    },
    POOLS: {
        CHAIN_NAME:"BNB",
        PROTOCOL:"PancakeSwap",
        HEADING: 'POOLS',
        GET_COVERED: 'Get covered',
        TABLE_POOLS_KEYS: ['','Protocol','Pool', 'Deposited', 'Rewards', 'Value'],
    },
    FARMS: {
        CHAIN_NAME:"BNB",
        PROTOCOL:"PancakeSwap",
        HEADING: 'FARMS',
        GET_COVERED: 'Get covered',
        TABLE_FARMS_KEYS: ['','Protocol','Pool', 'Rewards', 'APR', 'Value'],
    },
    COLORS: ['#1F55FE', '#F7931A', '#00A859', '#ED32B5', '#05CAFE', '#7828AD'],
    TABLE_HEADER_KEYS: ['TOKEN', 'VALUE', '', 'RATIO'],
    TABLE_WALELT_KEYS: ['', 'Token', 'Balance', 'Price', 'Value', 'Info','Scam', 'Add to Blacklist'],
    ANALYTICS: {
        SHOW_ALL: 'Show All',
        TOKEN: {
            HEADING: 'TOKEN ALLOCATION',
        },
        PROTOCOL: {
            HEADING: 'Pools/Vaults allocation',
        },
        CHAIN: {
            HEADING: 'CHAIN ALLOCATION',
        },
        
    },
    ACCOUNT: {
        HEADING: 'ACCOUNT',
        SELECT_ACCOUNT: 'Select account',
        NET_APY: 'Net APY',
        DAILY_SUMMARY: 'Daily earnings',
        INVESTED_BALANCE: 'Invested balance',
        CUMULATIVE_APY: 'Cumulative apy',
        FARM_COLLECTION: 'to collect from 1 farm',
        HARVEST_BUTTON_DESCRIPTION: 'Harvest all',

    },
    WALLET: {
        WALLET: 'WALLET',
        ADD_TOKEN: 'Add Token',
        BLACK_LIST: 'Blocked Assets',
        ADD_TO_WALLET: 'Add to wallet',
        MODAL_DESCRIPTION: 'Manually add missing token',
        PLACEHOLDER: 'Token Address',
        ADD_TO_BLACK_LIST: 'Add to Black list'
    },
    BLACK_LIST: {
        LABEL: 'Black list',
        BLACK_LIST_EMPTY: 'Black list is empty...',
        BLACK_LIST_MODAL_TABLE: ['Chain', 'Symbol', 'Address'],
        ADD_TO_BLACK_LIST: 'Add tokens to black list'
    },
    WALLET_MODAL_TABS: ['Details', 'Price Chart'],
    EXPLORER: {
        TITLE: 'Explorer',
        LIST: [
            {
                name: 'MoonScan',
                link: 'https://moonscan.io/address/',
            },

            { 
                name: 'BscScan',
                link: 'https://bscscan.com/address/',
            },
            {
                name: 'EtherScan',
                link: 'https://etherscan.io/address/',
            },
            {
                name: 'SnowTrace',
                link: 'https://snowtrace.io/address/',
            },
            {
                name: 'FtmScan',
                link: 'https://ftmscan.com/address/',
            },
            {
                name: 'PolygonScan',
                link: 'https://polygonscan.com/address/',
            },
        ]
    },
};
