/* eslint-disable max-len */
import React from 'react';

export const USE_PART = <>
    <g clipPath="url(#clip0_111_1226)">
        <path d="M74.0552 11.8926L42.3967 26.7276L41.376 28.6582L55.3507 40.2005L59.5141 41.5516L66.9651 38.249L89.8379 28.0967L90.4863 23.6695L77.48 13.004L74.0552 11.8926Z" stroke="url(#paint0_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M50.7173 24.6048L67.5911 38.1949L74.1738 35.2746L56.657 20.2791L61.888 17.8297L79.6382 32.8472L85.0972 30.4299L68.5444 14.7119" stroke="url(#paint1_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M91.1696 26.7217L59.514 41.5514L58.4932 43.4821L72.4719 55.0316L76.6352 56.3826L84.089 53.0749L106.959 42.9277L107.607 38.5007L94.601 27.8352L91.1696 26.7217Z" stroke="url(#paint2_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M67.2061 39.4875L84.0892 53.0746L90.6626 50.1574L73.1523 35.164L78.3833 32.7146L96.1335 47.7321L101.589 45.3075L85.0331 29.5947" stroke="url(#paint3_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M123.544 56.4414L93.1708 70.6703L92.1904 72.5243L105.599 83.6017L109.599 84.8998L116.745 81.7253L138.692 71.9909L139.316 67.7412L126.831 57.5083L123.544 56.4414Z" stroke="url(#paint4_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M101.13 68.8967L117.329 81.9386L123.644 79.1348L106.836 64.7524L111.854 62.4016L128.886 76.8087L134.121 74.4806L118.24 59.4053" stroke="url(#paint5_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M138.032 72.6328L110.855 84.4941L110.016 86.0826L122.455 95.8939L126.109 97.0795L132.511 94.4419L152.152 86.3467L152.61 82.6715L141.039 73.6085L138.032 72.6328Z" stroke="url(#paint6_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M118.302 82.3875L132.88 93.7901L138.442 91.4365L123.298 78.8625L127.713 76.8844L143.06 89.4821L147.67 87.5256L133.332 74.3691" stroke="url(#paint7_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M153.363 86.709L126.097 97.9851L125.279 99.5327L138.077 109.305L141.803 110.514L148.226 108.009L167.95 100.323L168.339 96.7332L156.428 87.7037L153.363 86.709Z" stroke="url(#paint8_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M132.144 95.178L148.878 107.759L155.067 105.309L137.675 91.455L142.585 89.3926L160.21 103.274L165.342 101.241L148.84 86.7607" stroke="url(#paint9_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M168.441 100.333L141.177 111.609L140.358 113.157L153.155 122.929L156.881 124.138L163.306 121.633L183.028 113.947L183.411 110.355L171.507 101.328L168.441 100.333Z" stroke="url(#paint10_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M149.213 109.443L164.252 121.479L170.081 118.913L154.479 105.64L159.118 103.483L174.929 116.782L179.768 114.654L165.024 100.744" stroke="url(#paint11_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M108.11 41.7637L77.7373 55.9925L76.7571 57.8466L90.166 68.9238L94.1594 70.2198L101.312 67.0476L123.253 57.311L123.883 53.0635L111.398 42.8304L108.11 41.7637Z" stroke="url(#paint12_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M85.1167 54.0131L101.312 67.0477L107.623 64.2489L90.8189 49.8616L95.8369 47.5107L112.868 61.9179L118.108 59.597L102.22 44.5195" stroke="url(#paint13_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M1.76978 110.994L37.4391 83.7344L88.7867 105.416L54.2927 134.844L1.76978 110.994Z" stroke="url(#paint14_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M62.3457 140.369L101.075 109.29L156.823 134.015L119.376 167.568L62.3457 140.369Z" stroke="url(#paint15_linear_111_1226)" strokeWidth="0.863889" strokeMiterlimit="10"/>
        <path d="M31.6401 88.1641L81.8221 111.359" stroke="url(#paint16_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M23.053 94.7314L75.1971 117.014L84.7944 122.737" stroke="url(#paint17_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M26.8513 91.8244L23.0532 87.8672L14.709 94.9825L19.0705 97.7755L71.2341 120.392L80.1319 125.537L84.7946 122.736L140.154 148.527" stroke="url(#paint18_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M71.7778 134.715L127.137 160.506" stroke="url(#paint19_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M93.8965 116.055L149.255 141.853" stroke="url(#paint20_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M15.0295 117.014L49.1253 88.1641" stroke="url(#paint21_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M28.0364 122.92L62.3458 94.2529" stroke="url(#paint22_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M40.9265 128.779L75.3914 99.7617" stroke="url(#paint23_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M75.3914 145.863L113.363 114.267" stroke="url(#paint24_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M89.875 152.325L128.099 120.934" stroke="url(#paint25_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M104.241 158.739L142.631 126.968" stroke="url(#paint26_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M85.0469 150.734L123.436 118.962" stroke="url(#paint27_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M8.95825 101.2L11.581 104.754L62.3457 127.972L71.7779 132.805L80.1318 125.537L135.491 151.328" stroke="url(#paint28_linear_111_1226)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_111_1226" x1="84.4434" y1="49.6416" x2="32.8663" y2="16.3241" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_111_1226" x1="82.5431" y1="33.8557" x2="59.1749" y2="14.391" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_111_1226" x1="101.006" y1="50.3487" x2="72.0626" y2="26.2298" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint3_linear_111_1226" x1="99.0318" y1="48.7384" x2="75.6745" y2="29.272" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_111_1226" x1="133.518" y1="92.6617" x2="84.0245" y2="60.689" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint5_linear_111_1226" x1="131.68" y1="77.7812" x2="109.253" y2="59.0951" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint6_linear_111_1226" x1="147.988" y1="104.179" x2="102.949" y2="74.7265" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint7_linear_111_1226" x1="145.548" y1="90.4391" x2="125.475" y2="73.7231" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint8_linear_111_1226" x1="164.101" y1="117.75" x2="118.379" y2="87.5704" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint9_linear_111_1226" x1="162.967" y1="104.474" x2="140.179" y2="85.4893" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint10_linear_111_1226" x1="179.179" y1="131.374" x2="133.458" y2="101.194" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint11_linear_111_1226" x1="177.532" y1="117.711" x2="156.728" y2="100.377" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint12_linear_111_1226" x1="118.077" y1="77.9815" x2="68.5912" y2="46.0148" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint13_linear_111_1226" x1="115.662" y1="62.8903" x2="93.2355" y2="44.2043" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint14_linear_111_1226" x1="3.28514" y1="103.049" x2="85.0617" y2="122.595" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint15_linear_111_1226" x1="50.582" y1="109.29" x2="187.734" y2="124.671" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint16_linear_111_1226" x1="29.7362" y1="95.7969" x2="82.6084" y2="108.435" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint17_linear_111_1226" x1="20.7896" y1="103.863" x2="85.7031" y2="119.379" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint18_linear_111_1226" x1="11.2897" y1="107.821" x2="142.476" y2="139.18" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint19_linear_111_1226" x1="69.6602" y1="143.238" x2="128.021" y2="157.187" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint20_linear_111_1226" x1="91.7691" y1="124.577" x2="150.137" y2="138.539" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint21_linear_111_1226" x1="10.7842" y1="88.1641" x2="60.5706" y2="92.2342" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint22_linear_111_1226" x1="23.7644" y1="94.2529" x2="73.8543" y2="98.3998" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint23_linear_111_1226" x1="36.6351" y1="99.7617" x2="86.9571" y2="103.896" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint24_linear_111_1226" x1="70.6634" y1="114.267" x2="126.097" y2="118.875" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint25_linear_111_1226" x1="85.1156" y1="120.934" x2="140.908" y2="125.633" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint26_linear_111_1226" x1="99.4616" y1="126.968" x2="155.5" y2="131.651" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint27_linear_111_1226" x1="80.2669" y1="118.962" x2="136.306" y2="123.646" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint28_linear_111_1226" x1="5.13098" y1="116.451" x2="136.414" y2="147.823" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <clipPath id="clip0_111_1226">
            <rect width="204.865" height="167.841" fill="white" transform="translate(0.535645 0.959961)"/>
        </clipPath>
    </defs>
</>;