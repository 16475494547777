import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toBlob } from 'html-to-image';
import { saveAs } from 'file-saver';

import '../styles/share_order_result_styles.scss';
import { shareOrderResultEffects } from '../actions/shareOrderResultEffects';
import { constants } from '../constants';

const {
  SHARE_ORDER_RESULT: {
    MONOCEROS,
    FUTURES,
    SHORT,
    LONG,
    LEVERAGE,
    ENTRY_PRICE,
    LAST_PRICE,
    NET_PROFIT,
    MONOCEROS_URL,
    CANCEL,
    SHARE,
    DOWNLOAD,
  },
} = constants;

export const ShareOrderResultModal = (props) => {
  const { show, actions, positive } = props;
  const imageRef = useRef(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () =>
    actions[shareOrderResultEffects.actionTypes.SHARE_ORDER_RESULT_MODAL_INVOKED]({
      name: 'close-modal',
    });

  const handleShare = async () => {
    setIsLoading(true);
    const newFile = await toBlob(imageRef.current);
    const data = {
      files: [
        new File([newFile], 'image.png', {
          type: newFile.type,
        }),
      ],
      title: 'Image',
      text: 'image',
    };

    try {
      if (!navigator.canShare(data)) {
        console.error("Can't share");
        downloadImage(newFile);
        setIsLoading(false);
      }
      await navigator.share(data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      console.log('catch error');
      downloadImage(newFile);
      setIsLoading(false);
    }
  };

  const downloadImage = (blob) => {
    if (window.saveAs) {
      window.saveAs(blob, 'image.png');
    } else {
      saveAs(blob, 'image.png');
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    const newFile = await toBlob(imageRef.current);
    downloadImage(newFile);
    setIsLoading(false);
  };

  return (
    <Modal
      show={show}
      size="xl"
      centered
      className="share-order-result"
      backdrop="static"
      container={document.querySelector('.wrapper')}
      onHide={handleClose}
    >
      <Modal.Body
        ref={imageRef}
        className={`p-0 ${positive ? 'main-positive-bg' : 'main-negative-bg'}`}
      >
        <div className="modal-body-heading">
          <img src="../dashboard-library/home-page-images/nav_logo.svg" />
          <div>
            <p className="font-protosans monoceros">{MONOCEROS}</p>
            <p
              className={`font-unbounded futures ${positive ? 'positive-color' : 'negative-color'}`}
            >
              {FUTURES}
            </p>
          </div>
        </div>
        <div className="modal-body-content font-opensans">
          <div className="left-half">
            <div className="left-half-statistic">
              <p className={`font-weight-bold ${positive ? 'positive-color' : 'negative-color'}`}>
                {SHORT}
              </p>
              <span className="circle"></span>
              <p className="">20x</p>
              <span className="circle"></span>
              <p>REEFUSDT Perpetual</p>
            </div>
            <div className={`left-half-percentage ${positive ? 'positive-bg' : 'negative-bg'}`}>
              <img
                src={`../dashboard-library/share-order-result/arrow-${
                  positive ? 'positive' : 'negative'
                }.svg`}
                className="me-4"
              />
              <p className="font-unbounded">
                <span>{positive ? '+' : '-'}</span>6.25<span>%</span>
              </p>
            </div>
            <div className="left-half-price">
              <div className="entry-price">
                <p className="font-size-28 font-weight-bold">0.005136</p>
                <p className="font-size-12">{ENTRY_PRICE}</p>
              </div>
              <div className="last-price">
                <p className="font-size-28 font-weight-bold">0.005005</p>
                <p className="font-size-12">{LAST_PRICE}</p>
              </div>
            </div>
          </div>
          <div className="right-half">
            <div>
              <img
                src={`../dashboard-library/share-order-result/logo-${
                  positive ? 'positive' : 'negative'
                }.svg`}
                className="logo-img"
              />
            </div>
            <div className="right-half-qr">
              <div className="link-container">
                <p className="font-size-18 font-weight-bold">{NET_PROFIT}</p>
                <p className="url">{MONOCEROS_URL}</p>
              </div>
              <div>
                <img src="../dashboard-library/share-order-result/monocerosQR.svg" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="submit-button pt-3">
        <Button
          onClick={handleClose}
          type="button"
          size="lg"
          className="font-size-14 font-weight-medium close"
        >
          {CANCEL}
        </Button>
        {isMobile ? (
          <Button
            onClick={handleShare}
            type="submit"
            size="lg"
            className={`font-size-14 font-weight-medium share position-relative ${
              isLoading ? 'is-loading-btn' : ''
            }`}
          >
            {SHARE}
          </Button>
        ) : (
          <Button
            onClick={handleDownload}
            type="submit"
            size="lg"
            className={`font-size-14 font-weight-medium share position-relative ${
              isLoading ? 'is-loading-btn' : ''
            }`}
          >
            {DOWNLOAD}
          </Button>
        )}
      </div>
    </Modal>
  );
};
