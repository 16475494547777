import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { balancesEffects } from './balancesEffects';

const actionTypes = {
    WEB3_BALANCES_MULTISELECT_SET: 'WEB3_BALANCES_MULTISELECT#SET',
    WEB3_BALANCES_SEARCH_SET: 'WEB3_BALANCES_SEARCH#SET',
    WEB3_BALANCES_SEARCH_INVOKED: 'WEB3_BALANCES_SEARCH#INVOKED',
    WEB3_BALANCES_LOAD_MORE_LOADING: 'WEB3_BALANCES_LOAD_MORE#LOADING',
    WEB3_BALANCES_REQUESTED: 'WEB3_BALANCES#REQUESTED',
    WEB3_BALANCES_CHECKBOX_CHECKED: 'WEB3_BALANCES_CHECKBOX#CHECKED',
    WEB3_BALANCES_MODAL_TOGGLED: 'WEB3_BALANCES_MODAL#TOGGLED',
    WEB3_BALANCES_BLACKLIST_ADD: 'WEB3_BALANCES_BLACKLIST#ADD',
    WEB3_BALANCES_BLACKLIST_DELETE: 'WEB3_BALANCES_BLACKLIST#DELETE'
};

const multiSelectHandle = (actions, payload) => {
    const { updateState } = actions;
    const { userWallet, value, state: { search, tableData, blackList } } = payload;

    switch(value.value) {
    case '': updateState({
        path: 'searchRequest',
        value: {
            address: search.value || userWallet,
            page: tableData.pagination.page,
            blackListTokens: blackList.data,
            pageSize: tableData.pagination.pageSize
        }
    }); break;
    default: updateState({
        path: 'searchRequest',
        value: {
            chain: value.value,
            address: search.value || userWallet,
            page: tableData.pagination.page,
            blackListTokens: blackList.data,
            pageSize: tableData.pagination.pageSize
        }
    }); break;
    }
    
    updateState([
        {
            path: 'selector.value',
            value
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        },
        {
            path: 'blackList.transportData',
            value: [],
        },
        {
            path: 'blackList.tempData',
            value: [],
        }
    ]);
};

const searchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value } = payload;
    updateState({
        path: 'search.value',
        value
    });
};

const searchInvokeHandle = (actions, payload) => {
    const { updateState } = actions;
    const { userWallet, state: { blackList, search, tableData, selector } } = payload;
    const requestBody = {
        chain: selector.value.value,
        address: search.value || userWallet,
        page: tableData.pagination.page,
        blackListTokens: blackList.data
    };
    updateState([
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        },
        {
            path: 'blackList.transportData',
            value: [],
        },
        {
            path: 'blackList.tempData',
            value: [],
        }
    ]);
};

const loadMoreHandle = (actions, payload) => {
    const { updateState } = actions;
    const { page, userWallet, state: { blackList, search, selector, tableData } } = payload;

    if (!tableData.pagination.hasMore)
        return;

    const requestBody = {
        chain: selector.value.value,
        address: search.value || userWallet,
        // filter: search.value || '',
        page,
        blackListTokens: blackList.data
    };

    updateState([
        {
            path: 'tableData.pagination.page',
            value: page
        },
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'push'
        },
        {
            path: 'tableData.pagination.hasMore',
            value: false
        }
    ]);
};

const fetchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { userWallet, state: { blackList, searchRequest, address, search, selector, tableData, typeOfFetch } } = payload;

    updateState({
        path: 'loader',
        value: typeOfFetch === 'set' ? true : false
    });

    if (searchRequest) {
        actions[balancesEffects.actionTypes.WEB3_BALANCES_FETCHED](searchRequest);
        return;
    }

    updateState({
        path: 'address',
        value: address || userWallet
    });

    const requestBody = {
        chain: selector.value.value,
        address: search.value || userWallet,
        page: tableData.pagination.page,
        blackListTokens: blackList.data
    };
    actions[balancesEffects.actionTypes.WEB3_BALANCES_FETCHED](requestBody);
};

const checkboxChangeHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value, state, userWallet } = payload;
    state.blackList.transportData = [...state.blackList.tempData, value];

    const { blockchainName, contractAddress } = value;

    if (state.blackList.tempData.some( token => token.contractAddress === contractAddress ))
        state.blackList.transportData = state.blackList.transportData.filter(token => token.contractAddress !== contractAddress);

    updateState([{
        path: 'blackList.transportData',
        value: state.blackList.transportData
    },
    {
        path: 'blackList.tempData',
        value: state.blackList.transportData
    }]);

    const requestBody = {
        chain: state.selector.value.value,
        address: userWallet,
        page: state.tableData.pagination.page,
        blackListTokens: [...state.blackList.data, ...state.blackList.transportData]
    };

    updateState({
        path: 'requestBody',
        value: requestBody
    });
};

const addToBlackList = (actions, payload) => {
    const { updateState } = actions;
    const { state } = payload;

    updateState([{
        path: 'blackList.data',
        value: state.requestBody.blackListTokens
    },
    {
        path: 'blackList.transportData',
        value: []
    },
    {
        path: 'blackList.tempData',
        value: []
    }]);
    makeRequests(actions, state.requestBody);
};

const deleteFromBlackList = (actions, payload) => {
    const { updateState } = actions;
    const { value: { contractAddress }, state, userWallet } = payload;

    state.blackList.transportData = state.blackList.data.filter(token => token.contractAddress !== contractAddress);

    updateState([{
        path: 'blackList.transportData',
        value: state.blackList.transportData
    },
    {
        path: 'blackList.data',
        value: state.blackList.transportData
    }]);

    const requestBody = {
        chain: state.selector.value.value,
        address: userWallet,
        page: state.tableData.pagination.page,
        blackListTokens: state.blackList.transportData
    };

    makeRequests(actions, requestBody);

    updateState({
        path: 'blackList.transportData',
        value: []
    });
};

const makeRequests = (actions, requestBody) => {
    actions[balancesEffects.actionTypes.WEB3_BALANCES_BLACKLIST_POST](requestBody);
    setTimeout(() => actions[balancesEffects.actionTypes.WEB3_BALANCES_FETCHED](requestBody), 500);
};

const modalToggle = (state, payload) => {
    const { type } = payload;
    switch(type) {
        case 'open': state.blackList.modal = true; break;
        case 'close': state.blackList.modal = false; break;
    }
};

const actionHandlers = {
    [actionTypes.WEB3_BALANCES_MULTISELECT_SET]: thunk((actions, payload) => multiSelectHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_SEARCH_SET]: thunk((actions, payload) => searchHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_SEARCH_INVOKED]: thunk((actions, payload) => searchInvokeHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_LOAD_MORE_LOADING]: thunk((actions, payload) => loadMoreHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_REQUESTED]: thunk( (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_CHECKBOX_CHECKED]: thunk( (actions, payload) => checkboxChangeHandle(actions, payload)),
    [actionTypes.WEB3_BALANCES_MODAL_TOGGLED]: action( (state, payload) => modalToggle(state, payload)),
    [actionTypes.WEB3_BALANCES_BLACKLIST_ADD]: thunk( (actions, payload) => addToBlackList(actions, payload)),
    [actionTypes.WEB3_BALANCES_BLACKLIST_DELETE]: thunk( (actions, payload) => deleteFromBlackList(actions, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};