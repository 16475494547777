import React from 'react';

export const TABLE_IMG = <>
    <g clipPath="url(#clip0_111_1257)">
        <path d="M1.23413 23.4482V285.083" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M285.083 1.23439L23.4485 1.23438" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M308.532 23.4482V285.083" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M285.083 308.531H23.4485" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M615.829 23.4482V285.083" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M899.679 1.23439L638.044 1.23438" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M923.127 23.4482V285.083" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M899.679 308.531H638.044" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M592.381 308.531H330.746" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M1206.98 308.531H945.341" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M1231.66 23.4482V285.083" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M1206.98 1.23438H945.341" stroke="#727B82" strokeWidth="2.46825"/>
        <path d="M592.381 0H330.746" stroke="#727B82" strokeWidth="2.46825"/>
    </g>
    <defs>
        <clipPath id="clip0_111_1257">
            <rect width="1231.66" height="311" fill="white"/>
        </clipPath>
    </defs>
</>;