import { isEmpty } from 'lodash';
import numeral from 'numeral';
import Big from 'big.js';

numeral.zeroFormat('N/A');


const getTotals = data => {
    const totals = {
        totalBTC: 0,
        totalUSDT: 0,
        difBTC: 'N/A',
        difUSDT: 'N/A'
    };
    const ex = data.exchangesWithPrices;

    if (isEmpty(ex))
        return { ...totals };
    
    const formatBTC = ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-BTC-N'] >= 1000 ? '0,0' : '0,0.[00]';
    const formatUSDT = ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-USDT-N'] >= 1000 ? '0,0' : '0,0.[00]';
    
    totals.totalBTC = numeral(ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-BTC-N']).format(formatBTC);
    totals.totalUSDT = numeral(ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-USDT-N']).format(formatUSDT);
    const difBTC = Big(ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-BTC']).minus(Big(ex.all_exchanges['OLD-TOTAL-BALANCE-EXCHANGE-BTC'])).valueOf();
    const difUSDT = Big(ex.all_exchanges['TOTAL-BALANCE-EXCHANGE-USDT']).minus(Big(ex.all_exchanges['OLD-TOTAL-BALANCE-EXCHANGE-USDT'])).valueOf();
    const formatDifBTC = Math.abs(difBTC) >= 1000 ? '0,0' : '0,0.[00]';
    const formatDifUSDT = Math.abs(difUSDT) >= 1000 ? '0,0' : '0,0.[00]';
    totals.difBTC = `${numeral(difBTC).format(formatDifBTC)} BTC`;
    totals.difUSDT = `${numeral(difUSDT).format(formatDifUSDT)} USDT`;
    return { ...totals };
};

const getFavoritesData = state => {
    const coins = state.favorites;
    const prices = new Map(state.buffer);
    return (coins || []).reduce((acc, coin) => {
        const currentData = prices.get(`${coin}USDT`) || {};
        const { P, c } = currentData;
        const o = {
            label: coin,
            id: coin.toLowerCase(),
            price: c,
            change: P
        };
        return [...acc, o];
    }, []);
};

export const dataHelper = {
    getTotals,
    getFavoritesData
};