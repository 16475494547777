export const constants = {
  FAVORITES: {
    FAVORITES: 'FAVORITES',
    NO_FAVORITES: 'You don’t have any favorites in your list. Please add any link by the button above',
    ADD_FAVORITE: 'Add favorite'
  },
  PLACEHOLDERS: {
    TYPE_URL: 'Type url',
    FAVORITE_NAME: 'Name',
    FAVORITES: 'Favorites',
  },
  STANDARD_OPTIONS_SCHEMA: {
    dropdownClass: 'custom-dropdown',
    classes: '',
    list: [{ id: 'edit', label: 'Edit' }, { id: 'delete', label: 'Delete' }, { id: 'first', label: 'Open in 1' },{ id: 'second', label: 'Open in 2' },{ id: 'third', label: 'Open in 3' },{ id: 'fourth', label: 'Open in 4' }],
    menuAlign: 'end',
    value: {},
    title: '',
    actionName: '',
    appendToPayload: '',
  },
  MODALS: {
    CHANGE_LINK: 'Change link',
    CHANGE: 'Change',
    CANCEL: 'Cancel'
  }
};
