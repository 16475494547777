import React from 'react';
import { StandardOptions } from '../../../../../components/utility/standard-options/view';
import moment from 'moment';
import numeral from 'numeral';
import { utils } from '../../../../../utils/utils';
import Big from 'big.js';


const renderBotCard = bot => {
    const date = moment(bot.startDate).format('DD/MM/YY');
    const change = Big(bot.balance30d).eq(0) ? 100 : Big(bot.balance).eq(0) ? -100 : Big(bot.balance).minus(Big(bot.balance30d)).div(Big(bot.balance30d)).times(Big(100)).valueOf();
    const changeClass = change > 0 ? 'positive' : change ? '' : 'negative';
    const changeSign = changeClass === 'positive' ? '+' : changeClass === '' ? '' : '-';
    const changeN = numeral(Math.abs(change)).format('0.00');
    const balance = numeral(bot.balance).format('0,00.00');
    return <div className="bot-card pt-1">
        <div className="d-flex flex-row justify-content-between bot-label ps-2 pe-2 pt-2">
            <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-start">
                    <div className="d-flex flex-column bot-img me-2">
                        <img className="img-fluid rounded-start" src={`../bots-library/${bot.id}_bot.svg`} alt={''}/>
                    </div>
                    <div className="flex-column bot-label-value font-size-14 font-weight-medium">
                        {bot.label}
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-start mt-2">
                    <div className="d-flex flex-column bot-img me-2">
                        <img className="img-fluid rounded-start"
								 src={`../coins-library/${bot.pairId}.svg`}
								 onError={utils.imgErrorHandle}
								 alt={''}/>
                    </div>
                    <div className="d-flex flex-column bot-label-value font-size-14 font-weight-medium">{bot.pairLabel}</div>
                </div>
            </div>
            <div className="d-flex flex-column">
                <div className="bot-controls">
                    <StandardOptions
                        more={{ type: 'more' }}
                        expand={{ type: 'expand' }}
                    />
                </div>
            </div>
        </div>
        <div className="d-flex flex-row bot-horizontal-divider mt-2 mb-2"></div>
        <div className="d-flex flex-row justify-content-between bot-info ps-2 pe-2 pb-2">
            <div className="d-flex flex-column justify-content-between">
                <div className="bot-info-profit-label font-size-12">Monthly profit</div>
                <div className={`bot-profit-value ${changeClass}`}>
                    <div className="font-size-18 font-weight-medium">
                        <i className="icon bot-profit-sign-icon me-2"></i>
                        <span>{`${changeSign}${changeN}%`}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between">
                <div className="bot-info-start-date">
                    <div className="font-size-10">{`Start date: ${date}`}</div>
                </div>
                <div className="bot-info-change-value align-self-end">
                    <div className="font-size-16 font-weight-medium">{`${balance}$`}</div>
                </div>
            </div>

        </div>
    </div>;
};


export const cardTemplate = {
    renderBotCard
};