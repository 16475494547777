import * as d3 from 'd3';


export const drawFrame = (ref, label, font, fontWeight, frameColor, labelColor, radius) => {

    const margin = { top: font / 2, bottom: 0, left: 0, right: 0 };
    const shift = font;
    const { width, height } = ref.current.getBoundingClientRect();
    const svg = d3.select(ref.current).html('');
    const path = d3.path();

    const draw = context => {
        const textBBox = svg.select('text.decorative-frame-label').node().getBBox();
        const move = textBBox.width + 2 * shift;

        context.moveTo(margin.left + move, margin.top);
        context.arcTo(width - margin.right, margin.top, width - margin.right, height + margin.top, radius);
        context.arcTo(width - margin.right, height - margin.bottom, margin.left, height - margin.bottom, radius);
        context.arcTo(margin.left, height - margin.bottom, margin.left, margin.top, radius);
        context.arcTo(margin.left, margin.top, margin.left + shift, margin.top, radius);
        return context;
    };

    svg
        .append('g')
        .append('text')
        .attr('class', 'text-uppercase decorative-frame-label')
        .attr('fill', labelColor || '')
        .attr('text-anchor', 'center')
        .attr('x', margin.left + shift)
        .attr('y', margin.top + shift / 4)
        .attr('font-size', font)
        .attr('font-weight', fontWeight)
        .text(label);


    svg
        .selectAll('g')
        .append('path')
        .attr('class', 'decorative-frame')
        .attr('stroke', frameColor || '')
        .attr('fill-opacity', 0)
        .attr('d', draw(path));
};