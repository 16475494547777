import { action, thunk } from "easy-peasy";
import { utils } from "../../../utils/utils";
import { ethers } from "ethers";
import { dataHelper } from "../dataHelpers/dataHelper";
import { serviceUtils } from "../../../service/serviceUtils";
import { factory } from "./factory";
import { lendingConstants } from '../constants';

const { BSC_NETWORK } = lendingConstants;
const actionTypes = {
  LENDING_MODAL_FORM_VALID: "LENDING_MODAL_FORM#VALID",
  LENDING_MODAL_ENABLE_INVOKED: "LENDING_MODAL_ENABLE#INVOKED",
  LENDING_TX_SUCCEEDED: "FARMS_TX#SUCCEEDED",
  LENDING_MARKET_ENTERED: "LENDING_MARKET#ENTERED",
  LENDING_MARKET_LEFT: "LENDING_MARKET#LEFT",
  LENDING_TX_SENT: "LENDING_TX#SENT",
  LENDING_COLLECT_INVOKED: "LENDING_COLLECT#INVOKED",
};

const modalFormValid = (state, payload) => {
  const { value, usedLimit, amount, walletBalance } = payload;

  switch (state.modal.tab) {
    case "borrow":
      state.modal.form.valid =
        Number(value) > Number(usedLimit) && Number(value) <= 100;
      break;
    case "repay":
      state.modal.form.valid =
        Number(amount) <= Number(walletBalance) &&
        Number(value) < Number(usedLimit) &&
        value >= 0;
      break;
    case "supply":
      state.modal.form.valid =
        Number(amount) <= Number(walletBalance) && Number(amount) > 0;
      break;
    case "withdraw":
      state.modal.form.valid =
        Number(amount) <= Number(walletBalance) && Number(amount) > 0;
      break;
  }
};
const collectHandle = async (actions, payload) => {
  const { updateState } = actions;
  const { venusValue } = payload;
  const {
    data: { chainName, rpcUrls, blockExplorerUrls, nativeCurrency, chainId },
  } = BSC_NETWORK;
  const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };
  
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${chainId.toString(16)}` }],
    });
    const result = await venusValue.claimVenus();
    actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });

    updateState({ path: "currentTxStatus", value: 1 });
  } catch (switchError) {
    console.log("switchError: ", switchError);
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              ...options,
            },
          ],
        });

        const result = await venus.claimVenus();
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: "currentTxStatus", value: 1 });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors*/
  }
};
const txSuccess = async (actions, payload) => {
  const { hash, from, protocol } = payload;
  const { updateState } = actions;

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const status = await provider.waitForTransaction(hash);
  if (status.status) {
    updateState({ path: "modal.currentTxStatus", value: 0 });
    actions[factory.actionTypes.LENDING_MARKET_FETCHED]({
      userWallet: from,
      protocol,
    });
    actions[factory.actionTypes.LENDING_USER_FETCHED]({
      userWallet: from,
      protocol,
    });
  }
};

const doApprove = async (actions, payload) => {
  const { from, to, cTokenAddress, protocol } = payload;

  const { updateState } = actions;

  const params = dataHelper.getApproveParams(from, to, cTokenAddress);

  const result = await window.ethereum.request({
    method: "eth_sendTransaction",
    params: [params],
  });
  actions[actionTypes.LENDING_TX_SUCCEEDED]({ hash: result, from, protocol });
  updateState({ path: "modal.currentTxStatus", value: 1 });
};

const doEnterMarket = async (actions, payload) => {
  const { userWallet, comptroller, cTokenAddress, protocol } = payload;
  const { updateState } = actions;

  const params = dataHelper.getCollateralParams(
    userWallet,
    comptroller,
    cTokenAddress
  );

  const result = await window.ethereum.request({
    method: "eth_sendTransaction",
    params: [params],
  });
  actions[actionTypes.LENDING_TX_SUCCEEDED]({
    hash: result,
    from: userWallet,
    protocol,
  });
  updateState({ path: "modal.currentTxStatus", value: 1 });
};

const doLeaveMarket = async (actions, payload) => {
  const { userWallet, comptroller, cTokenAddress, protocol } = payload;

  const { updateState } = actions;

  const params = dataHelper.getExitMarketParams(
    userWallet,
    comptroller,
    cTokenAddress
  );

  const result = await window.ethereum.request({
    method: "eth_sendTransaction",
    params: [params],
  });
  actions[actionTypes.LENDING_TX_SUCCEEDED]({
    hash: result,
    from: userWallet,
    protocol,
  });
  updateState({ path: "modal.currentTxStatus", value: 1 });
};

const doSendTx = async (actions, payload) => {
  const { from, amount, to, protocol, method, isNative } = payload;

  const { updateState } = actions;

  const params = dataHelper.getTxParams(from, to, amount, method, isNative);

  const result = await window.ethereum.request({
    method: "eth_sendTransaction",
    params: [params],
  });
  actions[actionTypes.LENDING_TX_SUCCEEDED]({ hash: result, from, protocol });
  updateState({ path: "modal.currentTxStatus", value: 1 });
};

const approveInvoked = (actions, payload) => {
  serviceUtils.metamaskSwitchEthereumChain(payload.chain).then((res) => {
    res.result
      ? doApprove(actions, payload)
      : console.log("metamask user", res);
  });
};

const enterMarket = (actions, payload) => {
  serviceUtils.metamaskSwitchEthereumChain(payload.chain).then((res) => {
    res.result
      ? doEnterMarket(actions, payload)
      : console.log("metamask user", res);
  });
};

const leaveMarket = (actions, payload) => {
  serviceUtils.metamaskSwitchEthereumChain(payload.chain).then((res) => {
    res.result
      ? doLeaveMarket(actions, payload)
      : console.log("metamask user", res);
  });
};

const sendTx = (actions, payload) => {
  serviceUtils.metamaskSwitchEthereumChain(payload.chain).then((res) => {
    res.result ? doSendTx(actions, payload) : console.log("metamask user", res);
  });
};

const actionHandlers = {
  [actionTypes.LENDING_MARKET_LEFT]: thunk((actions, payload) =>
    leaveMarket(actions, payload)
  ),
  [actionTypes.LENDING_MARKET_ENTERED]: thunk((actions, payload) =>
    enterMarket(actions, payload)
  ),
  [actionTypes.LENDING_TX_SENT]: thunk((actions, payload) =>
    sendTx(actions, payload)
  ),
  [actionTypes.LENDING_TX_SUCCEEDED]: thunk(async (actions, payload) =>
    txSuccess(actions, payload)
  ),
  [actionTypes.LENDING_MODAL_ENABLE_INVOKED]: thunk((state, payload) =>
    approveInvoked(state, payload)
  ),
  [actionTypes.LENDING_MODAL_FORM_VALID]: action((state, payload) =>
    modalFormValid(state, payload)
  ),
  [actionTypes.LENDING_COLLECT_INVOKED]: thunk((actions, payload) =>
    collectHandle(actions, payload)
  ),
  updateState: action((state, payload) => utils.stateHelper(state, payload)),
};

export const lendingEffects = {
  actionTypes,
  actionHandlers,
};
