import { thunk, action } from 'easy-peasy';
import axios from 'axios';
import {constants} from '../constants';

const { API, APPROVE_API } = constants;

const actionTypes = {
    BUY_SOLD_DATA_FETCHED: 'BUY_SOLD_DATA#FETCHED',
    BUY_SOLD_DATA_SUCCEEDED: 'BUY_SOLD_DATA#SUCCEEDED',
    BUY_SOLD_DATA_FAILED: 'BUY_SOLD_DATA#FAILED',
    BUY_APPROVE_FETCHED: 'BUY_APPROVE#FETCHED',
    BUY_APPROVE_SUCCEEDED: 'BUY_APPROVE#SUCCEEDED',
    BUY_APPROVE_FAILED: 'BUY_APPROVE#FAILED'
};

const initialHandle = async (actions, payload) => {
    await axios({ ...API })
        .catch(value => actions[actionTypes.BUY_SOLD_DATA_FAILED]({name: 'error', value}))
        .then( value => actions[actionTypes.BUY_SOLD_DATA_SUCCEEDED]({name: 'success', value}));
};

const approveFetch = async (actions, payload) => {
    const { address } = payload;
    const { method, url } = APPROVE_API;
    const options = {
        method,
        url: `${url}${address}`
    };

    await axios({ ...options })
        .catch(value => actions[actionTypes.BUY_APPROVE_FAILED]({name: 'error', value}))
        .then( value => actions[actionTypes.BUY_APPROVE_SUCCEEDED]({name: 'success', value}));
};

const approveSuccess = (state, payload) => {
    state.buy.approved = payload.value.data;
};

const approveFailed = (state, payload) => {};

const successHandle = (state, payload) => {
    const {value: {data}} = payload;
    const {maxSupply, totalAvaible} = data;
    state.buy.maxSupply = maxSupply;
    state.buy.totalAvailable = totalAvaible;
    state.buy.sold = state.buy.maxSupply - state.buy.totalAvailable;
    state.buy.range = (state.buy.maxSupply - state.buy.totalAvailable) / state.buy.maxSupply * 100;
};

const errorHandle = (state, payload) => {
    console.info(payload)
};

const actionHandlers = {
    [actionTypes.BUY_SOLD_DATA_FETCHED]: thunk(async (action, payload) => initialHandle(action, payload)),
    [actionTypes.BUY_SOLD_DATA_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.BUY_SOLD_DATA_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.BUY_APPROVE_FETCHED]: thunk(async (action, payload) => approveFetch(action, payload)),
    [actionTypes.BUY_APPROVE_SUCCEEDED]: action((state, payload) => approveSuccess(state, payload)),
    [actionTypes.BUY_APPROVE_FAILED]: action((state, payload) => approveFailed(state, payload)),
};

export const fetchEffects = {
  actionTypes,
  actionHandlers
};