import React, { useRef } from 'react';
import './styles.scss';
import { useStoreState } from 'easy-peasy';
import { AddExchangeModal } from '../../components/utility/add-exchange-modal/view';
import { template } from './template';
import CustomScroll from 'react-custom-scroll';

export const Dashboard = () => {
    const toggleAddExchangeModal = useStoreState(state => state.tableWidget);
    const sizes = useStoreState(state => state.sizes);
    const width = sizes.values.screenWidth;
    const ref = useRef();
    const gridType = width >= 1200 ? '-grid' : '-column';
    return <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
        <div ref={ref} className={`dashboard-content ${gridType}`}>
            { template.renderGrid(width) }
            <AddExchangeModal show={toggleAddExchangeModal.toggleAddExchangeModal}/>
        </div>
    </CustomScroll>
};