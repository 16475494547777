export const constants = {
  TITLES: {
    CREATE_HODL_BOT: 'CREATE HODL BOT',
    HODL_BOT: 'HODL BOT',
    HODL_BOT_NUMBER: 'HODL BOT NUMBER IS'
  },
  BOT_SETTINGS: {
    BOT_SETTINGS: 'Bot settings',
    BOT_WILL_BUY: 'Bot will buy BTC for 100 USDT every 7 days at 10 am by your device local time on Binance Spot exchange, next buy will occur at 12/31/2022'
  },
  CONTROLS: {
    LABEL_EXCHANGE: 'Exchange',
    LABEL_BOT_WILL_BUY: 'Bot will buy',
    LABEL_FOR: 'For',
    LABEL_EVERY: 'Every',
    LABEL_AT: 'At',
    LABEL_NEXT_BUY_DATE: 'Next Buy date',
    LABEL_SINGLE_PAIR: 'Single Pair',
    LABEL_PACKS: 'Packs',
    LABEL_MAIN_COIN: 'main coin',
    LABEL_PAIRS_SIZE: 'number of pairs',
    LABEL_ADD_PAIR: 'Add pair',
    LABEL_SIGNALS: 'Signals',
    LABEL_FAVORITES: 'Favorites',
    LABEL_EXCHANGES: 'Account/exchange',
    LABEL_COIN_MAIN: 'Main coin',
    LABEL_COIN_PAIR: 'Coin pair',
    LABEL_ADD_NEW_PACK: 'Add new pack',
    LABEL_CANCEL: 'Cancel',
    LABEL_PACK_NAME: 'Pack name',
    LABEL_PAIR_COINS: 'Pair coins',
    LABEL_PACK_NAME_PLACEHOLDER: 'Type pack name',
  },
  HOW_IT_WORKS: {
    HOW_IT_WORKS: 'HOW IT WORKS',
    TEXT: [
      'This bot is designed for long-term investments. It follows the conventional DCA (Dollar Cosr Averaging) investment strategy.',
      'The strategy is to keep buying equal shares of an asset at regular intervals, regadless of the asset’s current price.',
      'Investing this way does not require a big sum as a one-time payment, and an increase in the value of an asset will bring profit over time.'
    ],
    EXAMPLE: 'Example',
    EXAMPLE_TEXT: 'Imaging you were buying $100 worth of Bitcoin once a week for the past 100 weeks (~2 years), your investments would amount to $100x100 = $10000, and the market price of the asset would be $26000 (as a winter ‘21-22), i.e. + 160% profit.',
  },
  SELECTORS: {
    TIME_LIST: [
      { value: '12AM', label: '12AM', name: 'At' },
      { value: '1AM', label: '1AM', name: 'At' },
      { value: '2AM', label: '2AM', name: 'At' },
      { value: '3AM', label: '3AM', name: 'At' },
      { value: '4AM', label: '4AM', name: 'At' },
      { value: '5AM', label: '5AM', name: 'At' },
      { value: '6AM', label: '6AM', name: 'At' },
      { value: '7AM', label: '7AM', name: 'At' },
      { value: '8AM', label: '8AM', name: 'At' },
      { value: '9AM', label: '9AM', name: 'At' },
      { value: '10AM', label: '10AM', name: 'At' },
      { value: '11AM', label: '11AM', name: 'At' },
      { value: '12PM', label: '12PM', name: 'At' },
      { value: '1PM', label: '1PM', name: 'At' },
      { value: '2PM', label: '2PM', name: 'At' },
      { value: '3PM', label: '3PM', name: 'At' },
      { value: '4PM', label: '4PM', name: 'At' },
      { value: '5PM', label: '5PM', name: 'At' },
      { value: '6PM', label: '6PM', name: 'At' },
      { value: '7PM', label: '7PM', name: 'At' },
      { value: '8PM', label: '8PM', name: 'At' },
      { value: '9PM', label: '9PM', name: 'At' },
      { value: '10PM', label: '10PM', name: 'At' },
      { value: '11PM', label: '11PM', name: 'At' },
    ],
    EXCHANGE_LIST: [
      { value: 'Account Name', label: 'Account Name', name: 'Exchange' },
    ],
    BOT_WILL_BUY_LIST: [
      { value: 'USDT', label: 'USDT', name: 'Bot will buy' },
    ],
    FOR_LIST: [
      { value: 'USDT', label: 'USDT', name: 'For' },
    ]
  },
  BUTTONS: {
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    CREATE_BOT: 'Create bot',
    START: 'Start',
    NOT_NOW: 'Now now',
    STOP_BOT: 'Stop the bot',
    MANAGE: 'Manage',
    SAVE: 'Save',
    DELETE_BOT: 'Delete the bot'
  },
  MODALS: {
    BOT_CREATED: 'Bot successfully created',
    START_BOT: 'Start bot'
  },
  TEXT: {
    RUNNING: 'Running',
    ID: 'ID',
    HODL_BOT: 'HODL Bot',
    TOTAL_PROFIT: 'Total Profit',
    NO_BTC_BOUGHT: 'No BTC bought',
    HEALTHCHECK: 'Healthcheck',
    BUY_EVERY: 'Buy every',
    DAYS: 'days',
    NEXT_BUY_DATE: 'Next buy date',
    AVERAGE_BUY_PRICE: 'Average buy price',
    CURRENT_MARKET_VALUE: 'Current market value',
    BOUGHT_AMOUNT: 'Bought amount',
    ALERT: 'BTC amount on the Binance balance is lower than the amount of BTC bought by this HODL Bot. It can mean that you or a bot have sold this coin. Please consider that “Current market value” is a calculated value and therefore does not reflect actual BTC value anymore'
  },
  TRADING_VIEW: 'TRADING VIEW',
  EVENTS: 'EVENTS',
  NO_DATA: 'No data',
  PACKS: {
    ADD_LABEL: 'Add pack',
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
    PACKS_LIMIT: 6,
    PACKS_FACTORY: {
      PACK_ADD: {
        url: '/pack',
        method: 'put'
      }
    },
    PACKS: 'PACKS'
  }
};
