export const constants_allowance = {
    DROPDOWN_LIST: [
        { value: 'BSC', label: 'BSC' },
        { value: 'MATIC', label: 'MATIC' },
        { value: 'FTM', label: 'FTM' },
        { value: 'ETH', label: 'ETH' },
        { value: 'ARBITRUM', label: 'ARBITRUM' },
        { value: 'AVAXc', label: 'AVAXc' },
    ],
    HEADER_KEYS: ['Asset Symbol', 'Asset Name', 'Transaction Address', 'Contract Address', 'Allowance Block', 'Allowance Amount', ''],
    REQUEST_URL: '/web3/approvetnx',

};