import React, { useEffect, useRef } from 'react';
import { templates } from './tableTemplate';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { constants } from '../../../constants';

const { BUILD_CONTENT: { LIQUID_LAUNCH } } = constants;

export const ImagesGenerator = () => {
    const bgRef = useRef();
    const ref = useRef();
    useEffect(() => {
        drawHelper.drawBg(d3.select(bgRef.current), LIQUID_LAUNCH);
    }, );
    return <div ref={ref} className="images-background-grid">
        <div ref={ref}>
            <svg ref={bgRef} viewBox="-215 0 1500 823" fill="none" xmlns="http://www.w3.org/2000/svg">
                { templates.liquidBg() }
            </svg>
        </div>
    </div>;
};
