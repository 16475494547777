import React, { useEffect } from 'react';
import moment from 'moment';
import { Form, InputGroup } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';
import { constants } from '../constants';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { hodlBotEffects } from '../actions/hodlBotEffects';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { dataHelper } from '../actions/dataHelpers/dataHelper';
import { selectorsEffects } from '../actions/selectorsEffects';

const {
  BOT_SETTINGS: { BOT_SETTINGS },
  CONTROLS: { LABEL_EXCHANGE, LABEL_BOT_WILL_BUY, LABEL_FOR, LABEL_EVERY, LABEL_AT, LABEL_NEXT_BUY_DATE },
  BUTTONS: { TODAY, TOMORROW, CREATE_BOT, SAVE }
} = constants;

export const BotSettings = props => {
  const { actions, state } = props;
  const action = actions[hodlBotEffects.actionTypes.HODL_BOT_FORM_ELEMENTS_CHANGED];
  const theme = useStoreState(state => state.root.theme);
  const exchanges = useStoreState(state => state.coinData.exchanges);
  const exchangesList = dataHelper.parseExchanges(exchanges);
  const assets = useStoreState(state => state.availableExchangesList);
  const isSubmitButtonDisabled = dataHelper.submitButtonState(state);

  const changeHandle = (value) => action({...value});
  const formControlChange = e => action({name: e?.target?.name, value: e?.target?.value ? parseFloat(e?.target?.value) : 0});
  const dateChange = e => action({name: e?.target?.name, value: e?.target?.value});
  const handleSubmit = () => actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_INVOKED]({
    values: {
      exchange: state.exchangeSelector.value.value.api,
      willBuy: state.botWillBuySelector,
      forCount: state.inputForValue,
      forBuy: state.forSelector.value,
      reccuringDays: state.inputEveryValue,
      reccuringTime: state.atSelector.value.value,
      botFirstPurchaseDate: state.inputNextBuyDateValue,
      ...(state.manageBot && { botId: state.botId })
    },
    method: !state.manageBot ? 'create-bot' : 'update-bot'
  })

  useEffect(() => {
    actions[selectorsEffects.actionTypes.HODL_BOT_SELECTORS_DATA_UPDATED](assets);
  }, [assets]);

  useEffect(() => {
    actions[hodlBotEffects.actionTypes.HODL_BOT_EXCHANGE_INIT](exchangesList);
  }, []);

  return <div className="bot-settings-content">
    <div className="font-size-16 font-weight-medium">{BOT_SETTINGS.toUpperCase()}</div>
    <div className="font-inter pt-3">
      <div className="form-item pb-3">
        <div className="font-size-14 pb-1 font-weight-medium">{LABEL_EXCHANGE}</div>
        <MultiplyCustomSelector
          onChangeHandle={(e) => changeHandle({...e, name: LABEL_EXCHANGE})}
          isMulti={false}
          closeMenuOnSelect={true}
          options={state.exchangeSelector.list}
          value={state.exchangeSelector.value}/>
      </div>
      <div className="form-item pb-3">
        <div className="font-size-14 pb-1 font-weight-medium">{LABEL_BOT_WILL_BUY}</div>
        <MultiplyCustomSelector
          onChangeHandle={(e) => changeHandle({...e, name: LABEL_BOT_WILL_BUY})}
          isMulti={false}
          closeMenuOnSelect={true}
          options={state.botWillBuyList}/>
      </div>
      <div className="form-item inline pb-3">
        <div className="form-item-first">
          <div className="font-size-14 pb-1 font-weight-medium">{LABEL_FOR}</div>
          <InputGroup>
            <Form.Control
              name="input_for_value"
              value={state.inputForValue}
              onChange={formControlChange}
              type="number"/>
            <InputGroup.Text className='right-input-button'>{state.botWillBuySelector}</InputGroup.Text>
          </InputGroup>
        </div>
        <div>
          <div className="font-size-14 pb-1 font-weight-medium">{LABEL_FOR}</div>
          <MultiplyCustomSelector
            onChangeHandle={changeHandle}
            isMulti={false}
            closeMenuOnSelect={true}
            options={state.forSelector.list}/>
        </div>
      </div>
      <div className="form-item inline pb-3">
        <div className="form-item-first">
          <div className="font-size-14 pb-1 font-weight-medium">{LABEL_EVERY}</div>
          <InputGroup className="with-left-button">
            <InputGroup.Text className='left-input-button'>
              <i className="fa-light fa-calendar"></i>
            </InputGroup.Text>
            <Form.Control
              name="input_every_value"
              value={state.inputEveryValue}
              onChange={formControlChange}
              type="number"/>
            <InputGroup.Text className='right-input-button'>Days</InputGroup.Text>
          </InputGroup>
        </div>
        <div>
          <div className="font-size-14 pb-1 font-weight-medium">{LABEL_AT}</div>
          <MultiplyCustomSelector
            options={state.atSelector.list}
            onChangeHandle={changeHandle}
            closeMenuOnSelect={true}
            isMulti={false}
            value={state.atSelector.value}/>
        </div>
      </div>
      <div className="form-item inline-3 pb-3">
        <div>
          <div className="font-size-14 pb-1 font-weight-medium">{LABEL_NEXT_BUY_DATE}</div>
          <InputGroup className="with-left-button">
            <InputGroup.Text className='left-input-button'>
              <i className="fa-light fa-calendar"></i>
            </InputGroup.Text>
            <Form.Control
              name="input_next_buy_date_value"
              style={theme === 'day-theme' ? { colorScheme: 'white' } : { colorScheme: 'dark' } }
              value={state.inputNextBuyDateValue}
              onChange={dateChange}
              type="date"/>
            <InputGroup.Text
              onClick={e => action({name: 'input_next_buy_date_value', value: ''})}
              className='right-input-button cursor-pointer'>
              <i className="icon-cancel"></i>
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className="calendar-buttons">
          <div className="label">{LABEL_NEXT_BUY_DATE}</div>
          <ButtonCustom
            onClick={e => action({name: 'input_next_buy_date_value', value: new Date()})}
            label={TODAY}
            variant="default"
            className="today"
            size="sm"/>
        </div>
        <div className="calendar-buttons">
          <div className="label">{LABEL_NEXT_BUY_DATE}</div>
          <ButtonCustom
            onClick={e => action({name: 'input_next_buy_date_value', value: new Date().setDate(new Date().getDate() + 1)})}
            label={TOMORROW}
            variant="default"
            className="tomorrow"
            size="sm"/>
        </div>
      </div>
      { !isSubmitButtonDisabled ?
        <div className="alert-item mb-3">
          <div className="d-flex">
            <img src="../dashboard-library/circle-check.svg" alt="Graph profit" />
            <p className="m-0 font-size-14 font-weight-bold ps-2">{BOT_SETTINGS}</p>
          </div>
          <p className="font-size-14 ps-4">
            Bot will buy {state.botWillBuySelector} for {state.inputForValue} USDT every {state.inputEveryValue} days
            at {state.atSelector.value.value} by your device local time on Binance Spot exchange,
            next buy will occur at {moment(state.inputNextBuyDateValue).format('MM/DD/YYYY')}
          </p>
        </div> : null
      }
      <div className="form-item">
        <ButtonCustom
          label={!state.manageBot ? CREATE_BOT : SAVE}
          variant="success"
          className="w-100"
          size="sm"
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmit}/>
      </div>
    </div>
  </div>;
};
