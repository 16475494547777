export const constants = {
  TITLES: {
    CREATE_NEW_PROFILE: 'Create new profile',
  },

  ACTIONS: {
    POPUP_TITLE: 'NEW PROFILE',
    POPUP_TYPE_NAME: 'Type profile name',
    POPUP_CREATE_PROFILE: 'Save',
    POPUP_DELETE_PROFILE: 'Delete',
    DELETE_MESSAGE: 'Do you realy want to delete this wallet?',
  },

  PROFILE_CARD: {
    PROFILE_NAME: 'Profile 01',
    ADD_WALLET: 'Add wallet',
    WALLET_ADDRESS: '0xBSAP32SHSLL24555SGKJGHDAGKAGAGAKLSG',
    WALLETS: 'Wallets',
    WALLETS_NUMBER: '1',
    PROFILE_STATUS: 'Current profile',
    CURRENT_WALLET: 'Wallet',
    CURRENT_WALLET_NETWORK: '(BNB)',
    COIN_TITLE: 'Bitcoin',
  },
  WALLET_DATA: {
    MODAL_TITLE: 'ADD WALLET',
    FORM_TEXT: 'Enter your wallet address',
    SELECT_CHAINS: 'Select chains',
    BUTTON_TITLE: 'Add wallet',
  },

  STANDARD_OPTIONS_SCHEMA: {
    dropdownClass: 'custom-dropdown',
    classes: '',
    list: [
      { id: 'view', label: 'View Profile' },
      { id: 'edit', label: 'Edit Profile' },
      { id: 'delete', label: 'Delete Profile' },
    ],
    menuAlign: 'end',
    value: {},
    title: '',
    actionName: '',
    appendToPayload: '',
  },
};
