import { useStoreState, useStoreActions} from 'easy-peasy';
import React from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import { constants } from './constants';

import BugReportForm from './bugReportForm'

const { BUG_REPORT_MODAL: { TITLE } } = constants;


const BugReportModal = ({active}) => {
    
  const state = useStoreState(state => state)
  const actions = useStoreActions(actions => actions)

 
  return (
      <Modal
        show={state.report.showBugReportModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName= { active ? 'DayModal' : 'NightModal' }
        centered
      >
        <Modal.Header>
          <Modal.Title className="fs-6">{TITLE}</Modal.Title>
          {
            !active ? 
               <CloseButton variant='white' className="fs-6" onClick={ actions.TOGGLE_BUG_REPORT_MODAL }  />
             : <CloseButton  className="fs-6" onClick={ actions.TOGGLE_BUG_REPORT_MODAL }  />
          }
        </Modal.Header>

        <Modal.Body>

            <BugReportForm active = {active} />

        </Modal.Body>
      </Modal>
  );
}

export default BugReportModal

