import React from 'react';
import '../styles.scss';
import Button from './Button';
import { AcademyState } from '../../../../../models/academy-model';
import { constants } from '../constants';

const { buttons } = constants.ADMIN_ARTICLES;

const Article = ({ article }) => {
  const togglePublishArticle = AcademyState.useStoreActions(
    (actions) => actions.TOGGLE_PUBLISH_ARTICLE
  );
  const editArticle = AcademyState.useStoreActions((actions) => actions.EDIT_ARTICLE);
  const deleteArticle = AcademyState.useStoreActions((actions) => actions.DELETE_ARTICLE);
  const setFullArticle = AcademyState.useStoreActions((actions) => actions.SET_FULL_ARTICLE);
  const getArticlesByTag = AcademyState.useStoreActions((actions) => actions.GET_ARTICLES_BY_TAG);

  let location = window.location.search;
  let isPublic = location === '?elem=academy' ? true : false;

  const {
    articleId,
    image,
    signal,
    title,
    tags,
    excerpt,
    date,
    time,
    type,
    category,
    isPublished,
  } = article;

  const getWordStr = (str) => {
    let numOfWords = str.split(' ').length;

    if (numOfWords > 30) {
      return str.split(' ').slice(0, 30).join(' ') + ' ...';
    }
    return str;
  };

  return (
    <article>
      <div className="article-img">
        <img src={image} alt="article_image" />
      </div>
      <div className="article-text">
        <div>
          <div onClick={() => setFullArticle(article)} className="title">
            <div className="signal" style={{ backgroundColor: signal }}></div>
            <h2>{title}</h2>
          </div>
          <div className="tags">
            {tags.map((tag, index) => (
              <span onClick={() => getArticlesByTag(tag)} key={index} className="tag">
                {tag}
              </span>
            ))}
          </div>
          <p className="excerpt">{getWordStr(excerpt)}</p>
        </div>
        <div className="data-and-buttons">
          <div className="date-time">
            <div className="date">
              <span>
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.67692 0.833344C4.99202 0.833344 5.24984 1.08987 5.24984 1.40626V3.12501H12.1248V1.40626C12.1248 1.08987 12.3827 0.833344 12.6978 0.833344C13.0129 0.833344 13.2707 1.08987 13.2707 1.40626V3.12501H14.4165C15.6805 3.12501 16.7082 4.15089 16.7082 5.41668V16.875C16.7082 18.139 15.6805 19.1667 14.4165 19.1667H2.95817C1.69238 19.1667 0.666504 18.139 0.666504 16.875V5.41668C0.666504 4.15089 1.69238 3.12501 2.95817 3.12501H4.104V1.40626C4.104 1.08987 4.36182 0.833344 4.67692 0.833344ZM15.5623 7.70834H1.81234V16.875C1.81234 17.5088 2.32546 18.0208 2.95817 18.0208H14.4165C15.0503 18.0208 15.5623 17.5088 15.5623 16.875V7.70834ZM14.4165 4.27084H2.95817C2.32546 4.27084 1.81234 4.78289 1.81234 5.41668V6.56251H15.5623V5.41668C15.5623 4.78289 15.0503 4.27084 14.4165 4.27084Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div>{date}</div>
            </div>
            <div className="time">
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.42725 4.84374C9.42725 4.52864 9.68506 4.27083 10.0002 4.27083C10.3153 4.27083 10.5731 4.52864 10.5731 4.84374V9.69205L13.7563 11.8154C14.0177 11.9909 14.0894 12.3454 13.9139 12.6103C13.7384 12.8717 13.384 12.9434 13.119 12.7679L9.68148 10.4762C9.52393 10.3688 9.39502 10.1898 9.39502 9.99999L9.42725 4.84374ZM10.0002 0.833328C15.0633 0.833328 19.1668 4.93684 19.1668 9.99999C19.1668 15.0631 15.0633 19.1667 10.0002 19.1667C4.93701 19.1667 0.833496 15.0631 0.833496 9.99999C0.833496 4.93684 4.93701 0.833328 10.0002 0.833328ZM1.97933 9.99999C1.97933 14.4294 5.5708 18.0208 10.0002 18.0208C14.4295 18.0208 18.021 14.4294 18.021 9.99999C18.021 5.57063 14.4295 1.97916 10.0002 1.97916C5.5708 1.97916 1.97933 5.57063 1.97933 9.99999Z"
                    fill="white"
                  />
                </svg>
              </span>
              <div className="me-3">{time}</div>
            </div>
          </div>
          <div className="article-buttons" style={{ display: !isPublic ? 'flex' : 'none' }}>
            <Button onClick={() => editArticle(articleId)} className="btn btn-success">
              {buttons.updateBtn}
            </Button>
            <Button onClick={() => deleteArticle(articleId)} className="btn btn-danger">
              {buttons.deleteBtn}
            </Button>
            <Button onClick={() => togglePublishArticle(articleId)} className="btn btn-warning">
              {isPublished ? buttons.unpublish : buttons.publish}
            </Button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Article;
