
const createSelectedSinglePair = payload => {
    const { pair, mainCoin, exchangeData} = payload;
    return {id: pair, mainCoin, isSinglePair: true, selectedPack: {mainCoin, weight: false}, exchangeData};
};

const chartOptions = (pairsData, theme, Themes, style) => {
    const { exchangeData: { value: {value} }, mainCoin, id } = pairsData;
    const exchange = value.api.startsWith('binance') ? 'BINANCE' : value.api.toUpperCase();
    const symbol = `${exchange}:${id}${mainCoin}`;
    const widgetTheme = theme === 'day-theme' ? Themes.LIGHT : Themes.DARK;
    return {
        symbol,
        locale: 'en',
        autosize: true,
        theme: widgetTheme,
        timezone: 'Etc/UTC',
        style: style.toString(),
        range: 'all',
        save_image: false,
        allow_symbol_change: true,
        enable_publishing: false,
        withdateranges: true,
    };
};



export const packHelper = {
    chartOptions,
    createSelectedSinglePair,
};