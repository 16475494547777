import React, { useEffect, useRef } from 'react';
import { HomePageContent } from './homePageContent/view';
import { useLocation, useParams } from 'react-router-dom';
import { TradePage } from './tradePage/view';
import { routing } from '../../../routing/routing-mapping';
import { isEmpty } from 'lodash';
import { constants } from '../constants';


const getContent = (id, search) => {
  const {STATIC_MAPPING} = routing;
  const {FOOTER} = constants
  const menuId = search.replace('?elem=', '');
  const { content } = menuId && id && (STATIC_MAPPING?.list || FOOTER.LINKS) && id === 'home' ?
      STATIC_MAPPING.list.find(el => el.id === menuId) || FOOTER.LINKS.find(el=>el.link===menuId) : {};
 
  // return isEmpty(content) ? <HomePageContent/> : content;
  return isEmpty(content) ? <TradePage/> : content;
};

export const HomePagePlaceholder = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.parentNode.parentNode.scrollTop = 0;
    }
  });
  return <div ref={ref}>
    { getContent(id, search) }
  </div>
};