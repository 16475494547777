import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { useStoreActions } from 'easy-peasy';
import { profilePageActions } from '../actions/uiActions';
import { AddWalletModal } from '../modals/addWalletsModal';
import { ProfileState } from '../../../models/profile-model';
import { profileEffects } from '../actions/profileEffects';
import { forEach } from 'lodash';


const { ACTIONS: { POPUP_TYPE_NAME } } = constants;

export const EditModal = (props) => {
  const { actions, show, idx, index } = props;
  const actionsGlobal = useStoreActions((actions) => actions);
  const state = ProfileState.useStoreState((state) => state);
  const handleClose = () =>
    actions[profilePageActions.actionTypes.PROFILE_EDIT_INVOKED]({
      name: 'close-edit-modal',
      modal: 'EDIT',
    });
  const openModalWallet = () => {
    actions[profilePageActions.actionTypes.WALLET_MODAL_INVOKED]({
      name: 'open-modal',
      modal: 'ADD WALLET',
      id: state.cardsList[state.counter].id,
    });
    const open = true;
    return (
      <AddWalletModal
        show={state.isWalletModalOpen}
        actions={actions}
        cardId={state.counter}
        isOpenFromEdit={open}
      />
    );
  };
  const editName = () => {
    const name = document.querySelectorAll("#Name")
   
    actions[profileEffects.actionTypes.PROFILE_NEW_PROFILE_INVOKED]({values: {title: name[name.length-1].value, id: state.counter ? state.counter : 0}, method: 'editName'})
  
    handleClose()
  }
    
  return <div className="backGroundWhite">
  <Modal
  
    size="lg"
    show={show}
    contentClassName = "fitContent"
    centered={true}
    className="favorites"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
    keepMounted 
    
  >
    
    <Modal.Body>
  
  <div onClick={handleClose} class="backButton">
  <i  class="m-1 fa-light fa-chevron-left settings-form-go-back-chevron-left"></i>
  {"Back"}</div>
  <div class="flex-container-edit-name">
    <div class="profile-name-text">Profile Name</div>
   
    
    <input type="text" class="profile-edit-box" id='Name' defaultValue={state.cardsList[state.counter]?.title}/>
        <input type="button" onClick={editName} class="profile-save-button" value="Save" />
  </div>
    <div class="wallets-counter">{`Wallets (${state?.walletData.find((wallet)=> wallet.index == state.counter) ?  state?.walletData.find((wallet)=> wallet.index == state.counter).walletAdrese.length : 0})`}</div>
    <div class="flex-container-add-wallet">
    <div class="add-wallet-edit" onClick={openModalWallet}>+ Add Wallet</div>
  </div>
  
  <div class="flex-container-wallet-info">
    {state?.walletData.find((wallet) => wallet.index == state.counter)?.walletAdrese.map((data, key) =>(<div>
    <div class="wallet-status">{`Wallet ${key+1}`} 
    <div class="binance-color">(Binance)</div></div>
    <div class="wallet-address"><img src="../coins-full-library/bnb.svg" height="15"></img> {data.walletAddress}
    <div type="button" class="trash-icon" className="fa-regular fa-trash-can fa-lg" onClick={() => {actions[profilePageActions.actionTypes.PROFILE_MODAL_INVOKED]({name: 'open-modal', modal: 'DELETE', id: data.id, cardId: index})}} ></div>
    </div>

    
                  
                  <div className="icon-wallet">
                    <img src="../coins-full-library/eth.svg" height="20"></img>Ethereum
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
  </div>
}

