export const constants = {
  HEADER: 'CAREERS',
  TITLE: 'Join our team of industry experts, working within the leading DeFi security project as we expand and grow.',
  GOOD_TO_HAVE: 'Good to have',
  APPLY_NOW: 'Apply now',
  POSITIONS: {
    list: [
      {
        positionName: 'Senior Full Stack',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          '3+ years of development experience',
          'Experience in leading development teams',
          'Knowledge of TypeScript',
          'Knowledge JavaScript frameworks (e.g. Node.js, React)'
        ],
        goodToHave: []
      },
      {
        positionName: 'Senior Backend Developer',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Work experience with Node.js, Express.js, TypeScript backend',
          'Hands-on experience with relational databases, NoSQL databases',
          'Experience message brokers (RabbitMQ/Kafka)',
          'Good to know web3 lib, the Graph',
          'Experience in blockchain projects is a plus'
        ],
        goodToHave: []
      },
      {
        positionName: 'Senior Frontend Developer',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Work experience as a Front-end developer for more than 4 years',
          'Hands-on experience with React, Redux, and JavaScript',
          'Experience Typescript, Semantic UI', 'Strong UI / UX skills',
          'Familiarity with browser testing and debugging',
          'Web3 lib knowledge is a plus',
          'Experience in blockchain projects is a plus'
        ],
        goodToHave: []
      },
      {
        positionName: 'Senior Frontend Developer',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          '1-3 years of experience with Solidity'
        ],
        goodToHave: [
          'Experience with JavaScript',
          'Experience integrating with other DeFi Protocols',
          'Experience with truffle, OpenZeppelin',
          'Experience with web3.js / Ethers, The Graph (Subgraph)'
        ]
      },
      {
        positionName: 'Data Scientist',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Experience as a Data Scientist or Data Analyst more than 2 years',
          'Knowledge of SQL and Python',
          'Experience in data mining',
          'Understanding of machine-learning and operations research'
        ],
        goodToHave: []
      },
      {
        positionName: 'DevOps',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Experience with Python or Bash scripting',
          'Continuous-integration, version control systems support experience',
          'Web servers support/deployment experience',
          'Configuration management tools experience',
          'Monitoring systems knowledge',
          'Knowledge of docker & k8s'
        ],
        goodToHave: []
      },
      {
        positionName: 'Middle Node.js Developer (Backend)',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Work experience with Node.js, Express.js, TypeScript backend',
          'Hands-on experience with relational databases, NoSQL databases',
          'Experience with message brokers (RabbitMQ/Kafka)',
          'Knowledge of web3 lib and the Graph',
          'Experience in blockchain projects is a plus'
        ],
        goodToHave: []
      },
      {
        positionName: 'Manual QA (Middle level)',
        applyNow: 'Apply now',
        requirements: 'Requirements:',
        descriptions: [
          'Work experience with Node.js, Express.js, TypeScript backend',
          'Hands-on experience with relational databases, NoSQL databases',
          'Experience with message brokers (RabbitMQ/Kafka)',
          'Knowledge of web3 lib and the Graph',
          'Experience in blockchain projects is a plus'
        ],
        goodToHave: []
      },
    ]
  },
  MODAL: {
    APPLY: 'APPLY',
    NAME: 'Type your name',
    EMAIL: 'Type your email',
    UPLOAD_CV: 'Upload CV',
    CHOOSE_FILE: 'Choose a file or drag it here',
    RELEASE_TO_DROP: 'Release to drop the file here',
    SEND: 'Send',
    CLOSE: 'Close',
    WE_RECEIVED_CV: 'We have received your CV. Our HR will contact you soon'
  }
};
