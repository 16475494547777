import React, { useEffect, useRef } from 'react';
import { imagesTemplate } from './templates';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { RemakeDashboardState } from '../../../../../models/remake-dashboard-model';
// import { SMALL_PARTS } from './images/smallParts';
import { useStoreState } from 'easy-peasy';
// import { dashboardEffects } from '../actions/dashboardEffects';
import { isEmpty } from 'lodash';
import { remakeDashboardEffects } from '../../../actions/remakeDashboardEffects';

export const ImagesGeneratorDeFi = props => {
    const defiRef = useRef();
    const ref = useRef();
    const state = RemakeDashboardState.useStoreState(state => state);
    const sizes = useStoreState(state => state.sizes.values);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);

    useEffect(() => {
        drawHelper.drawDeFiBalance(d3.select(defiRef.current), state);
    }, );

    useEffect(() => {
        const { height } = ref?.current?.getBoundingClientRect();
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_SIZES_UPDATED]({ height: height });
    }, [sizes]);

    return <div ref={ref}>
        <svg ref={defiRef} viewBox="0 0 361 362" fill="none" xmlns="http://www.w3.org/2000/svg" className="image-totals-part">
            { imagesTemplate.defiImg() }
        </svg>
    </div>;
};
