/* eslint-disable max-len */
import React from 'react';

export const TRADE_CELLS = <>
    <path d="M90.6624 29.5924C96.3733 25.9204 99.8607 21.3241 98.4516 19.3262C97.0425 17.3283 91.2704 18.6852 85.5595 22.3572C79.8485 26.0292 76.3609 30.6259 77.77 32.6238C79.1791 34.6217 84.9514 33.2644 90.6624 29.5924Z" fill="url(#paint0_linear_107_2634)"/>
    <path d="M79.0471 41.7204C79.1907 41.9655 79.2765 42.2376 79.2983 42.5169C79.3202 42.7962 79.2775 43.076 79.1734 43.3363C78.8794 44.2251 78.4242 45.0557 77.8286 45.7903C74.1147 50.1901 69.1028 53.4119 63.436 55.0423C62.5 55.2939 61.5234 55.3847 60.5509 55.3104C60.2554 55.3279 59.9593 55.2705 59.6925 55.1438C59.4258 55.0172 59.198 54.8258 59.0316 54.589C58.8652 54.3521 58.7663 54.078 58.7448 53.7944C58.7233 53.5108 58.7797 53.2276 58.9089 52.9731C59.2028 52.0868 59.6577 51.2586 60.2527 50.5266C63.9638 46.1217 68.9768 42.8969 74.6463 41.2672C75.583 41.0212 76.5587 40.933 77.5303 41.0067C77.821 41.0128 78.1076 41.08 78.3697 41.2033C78.6317 41.3266 78.8627 41.503 79.0471 41.7204Z" stroke="#B8B6B6" strokeWidth="2"/>
    <path d="M42.1366 54.7156C47.8476 51.0437 51.3349 46.4472 49.9258 44.4492C48.5167 42.4513 42.7447 43.8084 37.0337 47.4804C31.3228 51.1524 27.8354 55.7488 29.2445 57.7467C30.6536 59.7446 36.4257 58.3876 42.1366 54.7156Z" fill="url(#paint1_linear_107_2634)"/>
    <path d="M62.923 68.386C63.0636 68.6321 63.1471 68.9041 63.1675 69.183C63.1879 69.4619 63.1447 69.7409 63.0411 70.001C62.7492 70.8894 62.297 71.7202 61.7045 72.4559C57.99 76.8584 52.9748 80.0803 47.3041 81.7069C46.3683 81.9594 45.3913 82.0499 44.4187 81.9748C44.1232 81.9923 43.8275 81.9349 43.5608 81.8083C43.294 81.6817 43.0658 81.4905 42.8994 81.2536C42.733 81.0167 42.6341 80.7427 42.6126 80.459C42.5911 80.1754 42.6479 79.8922 42.7771 79.6378C43.071 78.7515 43.5259 77.9233 44.1209 77.1913C47.832 72.7863 52.8446 69.5615 58.5141 67.9318C59.4502 67.6828 60.4268 67.5947 61.3985 67.6713C61.6904 67.6776 61.978 67.7448 62.2413 67.8682C62.5046 67.9917 62.7371 68.1683 62.923 68.386Z" stroke="#B8B6B6" strokeWidth="2"/>
    <path d="M87.4247 76.4391C93.1357 72.7671 96.6231 68.1708 95.214 66.1729C93.8049 64.1749 88.0329 65.5321 82.3219 69.204C76.611 72.876 73.1236 77.4724 74.5327 79.4703C75.9418 81.4682 81.7138 80.1111 87.4247 76.4391Z" fill="url(#paint2_linear_107_2634)"/>
    <path d="M107.663 41.9416C107.802 42.1881 107.885 42.4597 107.907 42.7384C107.929 43.017 107.889 43.2963 107.789 43.5575C107.493 44.4454 107.038 45.2756 106.444 46.0115C104.636 48.2337 102.452 50.1478 99.9807 51.6757C97.5697 53.3036 94.8853 54.5265 92.04 55.2926C91.1068 55.5445 90.1326 55.6356 89.1626 55.5617C88.8673 55.5802 88.571 55.5238 88.304 55.3981C88.0369 55.2725 87.8083 55.082 87.6411 54.8458C87.4738 54.6097 87.3737 54.3362 87.351 54.0527C87.3282 53.7693 87.3841 53.4859 87.512 53.2311C87.8047 52.3418 88.2597 51.5109 88.8564 50.7771C92.5703 46.3772 97.5822 43.1555 103.249 41.5251C104.185 41.2742 105.162 41.1836 106.134 41.2572C106.424 41.2591 106.711 41.3211 106.975 41.4393C107.239 41.5575 107.473 41.7289 107.663 41.9416Z" stroke="#B8B6B6" strokeWidth="2"/>
    <path d="M97.3031 8.0625C97.3031 8.0625 70.6443 13.6185 71.0043 32.5406C71.0043 32.5406 74.3076 37.5714 86.5198 38.7607C98.732 39.9499 72.7903 58.5189 70.0529 62.467C70.0529 62.467 52.4514 58.4615 44.7566 63.571C37.0618 68.6804 28.6744 77.7028 30.9409 84.9109" stroke="#B8B6B6" strokeMiterlimit="10" strokeDasharray="10 10"/>
    <path d="M22.3914 72.4857C22.5102 72.6541 22.6046 72.984 22.429 73.6039C22.2547 74.2188 21.8456 74.9884 21.1808 75.8647C19.8565 77.6102 17.6516 79.6037 14.8671 81.3941C12.0825 83.1845 9.31884 84.3856 7.12693 84.9009C6.02652 85.1596 5.12585 85.2322 4.46352 85.1552C3.79588 85.0775 3.51127 84.8699 3.39248 84.7015C3.2737 84.5331 3.17928 84.2032 3.35496 83.5834C3.52924 82.9685 3.93832 82.1989 4.60317 81.3226C5.92749 79.5772 8.13239 77.5837 10.9169 75.7933C13.7014 74.0029 16.4652 72.8017 18.6571 72.2864C19.7574 72.0276 20.6581 71.9551 21.3204 72.0321C21.988 72.1097 22.2726 72.3173 22.3914 72.4857Z" stroke="#B8B6B6" strokeWidth="2"/>
    <defs>
        <linearGradient id="paint0_linear_107_2634" x1="78.6515" y1="29.682" x2="105.904" y2="17.8117" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1876FF"/>
            <stop offset="0.29" stopColor="#0BAFFF"/>
            <stop offset="0.6" stopColor="#05CDF9"/>
            <stop offset="0.94" stopColor="#01E9BB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_107_2634" x1="30.1261" y1="54.8051" x2="57.3714" y2="42.9393" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1876FF"/>
            <stop offset="0.29" stopColor="#0BAFFF"/>
            <stop offset="0.6" stopColor="#05CDF9"/>
            <stop offset="0.94" stopColor="#01E9BB"/>
        </linearGradient>
        <linearGradient id="paint2_linear_107_2634" x1="75.4076" y1="76.5329" x2="102.664" y2="64.6692" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1876FF"/>
            <stop offset="0.29" stopColor="#0BAFFF"/>
            <stop offset="0.6" stopColor="#05CDF9"/>
            <stop offset="0.94" stopColor="#01E9BB"/>
        </linearGradient>
    </defs>
</>;