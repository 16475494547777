import { action, debug, thunk } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { dataHelper } from './dataHelpers/dataHelper';
import Amplify, { Auth } from 'aws-amplify';
import { metaMaskConfiguration } from '../../../service/metaMaskConfiguration';
import { reactLocalStorage } from "reactjs-localstorage";
import {constants} from '../../../constants/constants';


const { config } = metaMaskConfiguration;
const { AUTH_ERRORS: {COUNTER_ERROR, ACCOUNT_ERROR}} = constants;

const actionTypes = {
    AUTHORIZE_SIGN_IN_INVOKED: 'AUTHORIZE_SIGN_IN#INVOKED',
    AUTHORIZE_SIGN_UP_INVOKED: 'AUTHORIZE_SIGN_UP#INVOKED',
    AUTHORIZE_SIGN_IN_SUCCEED: 'AUTHORIZE_SIGN_IN#SUCCEED',
    AUTHORIZE_UI_ACTIONS_INVOKED: 'AUTHORIZE_UI_ACTIONS#INVOKED',
    AUTHORIZE_LOG_OUT_INVOKED: 'AUTHORIZE_LOG_OUT#INVOKED',
    AUTHORIZE_CHECK_USER_AUTHORIZED: 'AUTHORIZE_CHECK_USER#AUTHORIZED',
    AUTHORIZE_COGNITO_CONFIGURED: 'AUTHORIZE_COGNITO#CONFIGURED',
    AUTHORIZE_TOKEN_EXPIRATION_SAVED: 'AUTHORIZE_TOKEN_EXPIRATION#SAVED',
    AUTHORIZE_VISIBLE_USER_DATA_SAVED: 'AUTHORIZE_VISIBLE_USER_DATA#SAVED'
};

const signInInvoke = async (actions, payload) => {
    const {counter} = payload;
    const updatedCounter = counter - 1;
    actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({ counter: updatedCounter, error: '', loaderMetamask: true, authenticationMethod: 'metaMask'});

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        .catch(err => console.info(err.message));

    if (!updatedCounter || isEmpty(accounts)) {
        const message = !updatedCounter ? COUNTER_ERROR : ACCOUNT_ERROR;
        actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({error: message});
        return;
    }

    const address = dataHelper.createAddress(accounts[0]);

    await Auth.signIn(address)
        .then(cognitoUser => cognitoUser ?
            actions[actionTypes.AUTHORIZE_SIGN_IN_SUCCEED]({cognitoUser, address }) :
            actions[actionTypes.AUTHORIZE_SIGN_UP_INVOKED]({address, counter: updatedCounter})
        )
        .catch(error => actions[actionTypes.AUTHORIZE_SIGN_UP_INVOKED]({error, address, counter: updatedCounter}));
};

const signUpInvoke = async (actions, payload) => {
    //If signIn is failed we will try to signUp
    const {address, counter} = payload;
    const data = dataHelper.createSignUpData(address);
    await Auth.signUp(data)
        .then(() => actions[actionTypes.AUTHORIZE_SIGN_IN_INVOKED]({counter}))
        .catch(a => console.info(a.message)); //TODO add handler
};

const signInSuccessHandle = async (actions, payload) => {
    const {cognitoUser, address} = payload;
    const messageToSign = cognitoUser.challengeParam.message;
    actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({connectionButton: false});
    const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [address.account, messageToSign],
    })
        .catch(() => {
            actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({connectionButton: true, loaderMetamask: false});
        });

    if (!signature)
      return;

    actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({loaderLogin: true});
    await Auth.sendCustomChallengeAnswer(cognitoUser, signature)
        .then(user => {
            actions[actionTypes.AUTHORIZE_TOKEN_EXPIRATION_SAVED]({user});
            actions[actionTypes.AUTHORIZE_VISIBLE_USER_DATA_SAVED]({userInfo: user});
            actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({user});
        })
        .catch(() => actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({connectionButton: true, loaderLogin: false}));

    const userInfo = await Auth.currentUserInfo();
    actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({userInfo, loaderLogin: false});
};

const logOutHandle = async (actions, payload) => {
    const {reload} = payload;
   actions ? actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({loader: true}) : '';
    await Auth.signOut()
        .finally(() => reload ? location.reload() : '');
};

const uiActionsHandle = (state, payload) => {
  const keys = Object.keys(payload);
  keys.forEach(k => state.authentication[k] = payload[k]);
};

const authorizationHandle = async (actions) => {
    // actions[actionTypes.AUTHORIZE_TOKEN_EXPIRATION_SAVED]({loader: true})
    const userInfo = await Auth.currentUserInfo()
        .catch(error => console.info(error.message));


    if (isEmpty(userInfo)) {
        actions[actionTypes.AUTHORIZE_LOG_OUT_INVOKED]({reload: false}); //If user not exist clean all data in local storage
        actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({user: null, userInfo: {}, loader: false});
        return;
    }

    actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({userInfo});
    actions[actionTypes.AUTHORIZE_VISIBLE_USER_DATA_SAVED]({userInfo});

    await Auth.currentAuthenticatedUser()
        .then(response => {
            actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({user: response, loader: false});
            actions[actionTypes.AUTHORIZE_TOKEN_EXPIRATION_SAVED]({user: response});
        })
        .catch(() => actions[actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]({user: null}));
};

const configurationHandle = (actions, payload) => {
    reactLocalStorage.remove('token_expired_time');
    Amplify.configure({ Auth: {...config} });
    actions[actionTypes.AUTHORIZE_CHECK_USER_AUTHORIZED]();
};

const tokenHandle = payload => {
  const {user} = payload;
  const expired_time = user?.signInUserSession?.idToken?.payload?.exp || 0;
  reactLocalStorage.set('token_expired_time', expired_time * 1000);
};

const createUserVisibleData = (state, payload) => {
    const {userInfo: {attributes}} = payload;
    const key = attributes['custom:web3address'];
    state.authentication.userData = dataHelper.createUserData(key || '');
};

const userInfo = async () => await Auth.currentUserInfo();

const actionHandlers = {
    [actionTypes.AUTHORIZE_SIGN_IN_INVOKED]: thunk(async (action, payload) => signInInvoke(action, payload)),
    [actionTypes.AUTHORIZE_SIGN_UP_INVOKED]: thunk(async (action, payload) => signUpInvoke(action, payload)),
    [actionTypes.AUTHORIZE_SIGN_IN_SUCCEED]: thunk(async (action, payload) => signInSuccessHandle(action, payload)),
    [actionTypes.AUTHORIZE_LOG_OUT_INVOKED]: thunk(async (action, payload) => logOutHandle(action, payload)),
    [actionTypes.AUTHORIZE_CHECK_USER_AUTHORIZED]: thunk(async (action, payload) => authorizationHandle(action, payload)),
    [actionTypes.AUTHORIZE_COGNITO_CONFIGURED]: thunk((action) => configurationHandle(action)),

    [actionTypes.AUTHORIZE_UI_ACTIONS_INVOKED]: action((state, payload) => uiActionsHandle(state, payload)),
    [actionTypes.AUTHORIZE_TOKEN_EXPIRATION_SAVED]: action((state, payload) => tokenHandle(payload)),
    [actionTypes.AUTHORIZE_VISIBLE_USER_DATA_SAVED]: action((state, payload) => createUserVisibleData(state, payload)),

};

export const authorizationEffect = {
    actionTypes,
    actionHandlers,
    logOutHandle,
    userInfo
};