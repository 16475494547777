import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Modal } from 'react-bootstrap';
import { ChangeLinkForm } from './changeLinkForm';
import { constants } from '../constants';
import { useStoreActions } from 'easy-peasy';

const { MODALS: { CHANGE_LINK } } = constants;

export const ChangeLinkModal = props => {
  const { actions, show, widget } = props;
  const actionsGlobal = useStoreActions(actions => actions);

  const handleClose = () => actionsGlobal[uiActions.actionTypes.DYNAMIC_SPLIT_LINK_MODAL_TOGGLED]({ name: 'close-modal', modal: 'change-link' });

  return <Modal
    show={show}
    size="md"
    centered
    className="favorites"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
  >
    <Modal.Header>
      <div className="font-size-16 font-weight-medium form-title">{CHANGE_LINK.toUpperCase()}</div>
    </Modal.Header>
    <Modal.Body>
      <div className="d-flex flex-column justify-content-center">
        <ChangeLinkForm handleClose={handleClose} actions={actions} widget={widget} />
      </div>
    </Modal.Body>
  </Modal>;
};
