import { NIL, v5 as uuidv5 } from 'uuid';
import { constants } from '../../../../constants/constants';
import createHash from 'hash-generator';
const {PROJECT_NAME} = constants;

const createAddress = wallet => {
    const namespace = uuidv5(PROJECT_NAME, NIL);
    return {
        "username": uuidv5(wallet, namespace),
        "validationData": {
            "custom:web3address": wallet
        },
        "attributes": {
            "custom:web3address": wallet,
        },
        account: wallet,
    };
};

const createSignUpData = address => {
  return {...address, password: createHash(30)};
};

const createUserData = key => {
  const badge = key.slice(2).slice(0, 1);
  const user = `${key.slice(0, 7)}...${key.slice(-7)}`;
  return {badge, user, userId: key};
};

export const dataHelper = {
    createAddress,
    createSignUpData,
    createUserData
};