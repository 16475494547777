import { ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import Toggle from 'react-toggle';
import Slider from 'rc-slider';
import React, { Fragment, useState } from 'react';
import { constants } from '../constants';
import { isEmpty } from 'lodash';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { GridBotTemplate } from './gridBotTemplate';
import { CostAverage } from './dcaBotTemplate';

const {
  MARKS,
  LEVERAGE_LIST,
  FORM_LABELS: {
    AMOUNT,
    BALANCE,
    PRICE,
    TRAILING_BUY,
    TOTAL,
    SIZE_FROM_AVAILABLE_AMOUNT,
    SMALL_TABLE,
    VOLUME,
    TAKE_PROFIT,
    FOLLOW_MAX_PRICE_WITH_DEVIATION,
    STOP_LOSS,
    TRAILING_TAKE_PROFIT,
    STOP_LOSS_TIMEOUT,
    TRAILING_STOP_LOSS,
    MOVE_TO_BREAKEVEN,
  },
} = constants;

const renderBuyForm = (
  state,
  pairData,
  formControlChange,
  toggleOnChange,
  sliderOnChange,
  onClickHandler, 
  
) => {
  const {
    id: pairCoin,
    mainCoin,
    isSinglePair,
    exchangeData: {
      value: { value },
    },
  } = pairData;
  const { type } = value;
  const isLeveraged = type === 'FUTURES' ? true : false;
  const [sliderValue, setSliderValue] = useState(0);
  const [flag, setFlag] = useState(true);
  const onBeforeHandle = () => setFlag(false);
  const onAfterChangeHandle = (value) => {
    sliderOnChange(value);
    setFlag(true);
  };
  const onAfterChangeHandleFirstSlider = (value) => {
    setSliderValue(value);
    setFlag(true);
  };
  const handleInputChange = (event) => {
    setSliderValue(event.target.value);
  };

  const isShort = Boolean(
    state.formType === 'form_state_pro_short' ||
      (state.formType === 'form_state_buy_sell' && state.buySellFormType === 'sell')
  );
  const settings = {
    slider_total_value: flag ? { value: state.slider_total_value || 0 } : {},
    slider_trailingBy_percent: flag ? { value: state.slider_trailingBy_percent } : {},
    slider_leverage: flag ? { value: sliderValue } : {},
  };
  return (
    <Form.Group className="left-form">
      {state.form_state_buy_sell ? (
        <div className="form-item">
          <ButtonGroup className="w-100 button-100">
            <ButtonCustom
              label="Buy"
              variant="default"
              size="sm"
              active={state.buySellFormType === 'buy'}
              name="button_buy_sell_buy"
              onClick={onClickHandler}
            />
            <ButtonCustom
              label="Sell"
              variant="default"
              size="sm"
              active={state.buySellFormType === 'sell'}
              name="button_buy_sell_sell"
              onClick={onClickHandler}
            />
          </ButtonGroup>
        </div>
      ) : null}
      <div className="form-item">
        <Form.Label className="font-weight-medium font-size-16">
          {AMOUNT}
          <div className="font-weight-regular font-size-14">
            <span className="balance-text">{BALANCE}</span>
            <span className="balance-value font-weight-medium">{`${state.balance_total} ${mainCoin}`}</span>
          </div>
        </Form.Label>
        {isSinglePair ? (
          <InputGroup>
            <InputGroup.Text className="right-input-button">{pairCoin}</InputGroup.Text>
          </InputGroup>
        ) : null}
      </div>
      {isLeveraged ? (
        <div className="form-item">
          <Form.Label className="font-weight-medium font-size-16">Leverage</Form.Label>
          <div className="pb-3">
            <MultiplyCustomSelector
              isMulti={false}
              initialValue={LEVERAGE_LIST[0]}
              onChangeHandle={sliderOnChange}
              closeMenuOnSelect={true}
              options={LEVERAGE_LIST}
            />
          </div>
          <div className="pb-3">
            <div className="pb-1 font-weight-medium font-size-16 text-end">{`${sliderValue}x`}</div>
            <InputGroup className="pb-3">
              <Form.Control
                className="font-size-14 font-weight-regular"
                name="input_slider_value"
                min={0}
                value={sliderValue}
                onChange={handleInputChange}
                type="number"
              />
            </InputGroup>
            <Slider
              className="slider -primary"
              marks={MARKS.LEVERAGE}
              {...settings.slider_leverage}
              onBeforeChange={onBeforeHandle}
              onAfterChange={(value) => onAfterChangeHandleFirstSlider(value)}
            />
          </div>
        </div>
      ) : null}
      <div className="form-divider"></div>
      {
        <Fragment>
          <div className="form-item">
            <Form.Label className="font-weight-medium font-size-16 price-label">
              {PRICE.toUpperCase()}
            </Form.Label>
            <InputGroup className="d-flex justify-content-center w-100">
              <ButtonGroup className="w-100 button-100">
                <ButtonCustom
                  label="Limit"
                  variant="default"
                  size="sm"
                  active={state.button_buy_limit}
                  name="button_buy_limit"
                  onClick={onClickHandler}
                />
                <ButtonCustom
                  label="Market"
                  variant="default"
                  size="sm"
                  active={state.button_buy_market}
                  name="button_buy_market"
                  onClick={onClickHandler}
                />
                <ButtonCustom
                  label="Cond"
                  variant="default"
                  size="sm"
                  active={state.button_buy_cond}
                  name="button_buy_cond"
                  onClick={onClickHandler}
                />
              </ButtonGroup>
            </InputGroup>
          </div>
          <div className="form-item">
            <InputGroup>
              <Form.Control
                className="font-size-14 font-weight-regular"
                name="input_price_value"
                min={0}
                value={state.input_price_value}
                onChange={formControlChange}
                type="number"
                readOnly={state.button_buy_market}
              />
              <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
            </InputGroup>
          </div>
        </Fragment>
      }
      {state.button_buy_cond ? (
        <div className="form-item">
          <InputGroup className="d-flex justify-content-center w-100">
            <ButtonGroup className="w-100 button-100">
              <ButtonCustom
                label="Cond. Limit Order"
                variant="default"
                active={state.button_buy_cond_limit}
                name="button_buy_cond_limit"
                size="sm"
                onClick={onClickHandler}
              />
              <ButtonCustom
                label="Cond. Market Order"
                variant="default"
                active={state.button_buy_cond_market}
                name="button_buy_cond_market"
                size="sm"
                onClick={onClickHandler}
              />
            </ButtonGroup>
          </InputGroup>
          {state.button_buy_cond_limit ? (
            <div className="form-item pt-3">
              <div>Order price</div>
              <InputGroup className="pb-3 pt-3">
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  name="input_buy_cond_limit"
                  value={state.input_buy_cond_limit}
                  onChange={formControlChange}
                  min={0}
                  type="number"
                />
                <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
              </InputGroup>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="form-item w-100">
        <label className="customized-toggle cursor-pointer trailing d-flex flex-row align-items-center">
          <span className="trailing-span font-weight-regular font-size-14">
            {!isShort ? TRAILING_BUY : 'Trailing sell'}
          </span>
          <Toggle
            icons={false}
            checked={state.toggle_trailing_by}
            onChange={toggleOnChange}
            name="toggle_trailing_by"
          />
        </label>
        {state.toggle_trailing_by ? (
          <div className="d-flex w-100 align-items-center deviation-input">
            <Slider
              className="slider -primary"
              {...settings.slider_trailingBy_percent}
              onBeforeChange={onBeforeHandle}
              onAfterChange={(value) =>
                onAfterChangeHandle({ name: 'slider_trailingBy_percent', value })
              }
            />
            <InputGroup className="ms-3">
              <Form.Control
                className="font-size-14 font-weight-regular"
                name="input_trailingBy_percent"
                value={state.input_trailingBy_percent}
                onChange={formControlChange}
                max={100}
                min={0}
                type="number"
              />
              <InputGroup.Text className="right-input-button">
                <i className="fa-solid fa-percent"></i>
              </InputGroup.Text>
            </InputGroup>
          </div>
        ) : null}
      </div>
      <div className={!state.toggle_trailing_by ? 'form-divider hidden' : 'form-divider'}></div>
      <div className="form-item">
        <Form.Label className="font-weight-medium font-size-16 total">{TOTAL}</Form.Label>
        <InputGroup>
          <Form.Control
            className="font-size-14 font-weight-regular"
            name="input_total_value"
            min={0}
            max={state.balance_total}
            value={state.input_total_value}
            onChange={formControlChange}
            type="number"
          />
          <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
        </InputGroup>
      </div>
      <div className="form-item ps-1 pe-1 pb-3">
        <div className="font-weight-regular font-size-14 size-amount">
          {SIZE_FROM_AVAILABLE_AMOUNT}
        </div>
        <Slider
          className="slider -primary"
          marks={MARKS.LONG}
          {...settings.slider_total_value}
          onBeforeChange={onBeforeHandle}
          onAfterChange={(value) => onAfterChangeHandle({ name: 'slider_total_value', value })}
        />
      </div>
    </Form.Group>
  );
};

const renderTTPForm = (
  state,
  pairData,
  formControlChange,
  toggleOnChange,
  sliderOnChange,
  onClickHandler
) => {
  const { mainCoin, isSinglePair } = pairData;
  const [flag, setFlag] = useState(true);
  const onBeforeHandle = () => setFlag(false);
  const onAfterChangeHandle = (value) => {
    sliderOnChange(value);
    setFlag(true);
  };
  const isShort = Boolean(state.formType === 'form_state_pro_short');
  const settings = {
    slider_profit_max_price_percent: flag
      ? { value: state.input_profit_max_price_percent || 0 }
      : {},
    slider_profit_percent: flag ? { value: state.input_profit_percent / 3 || 0 } : {},
    slider_profit_short_percent: flag ? { value: -state.input_profit_short_percent || 0 } : {},
    slider_profit_volume_percent: flag ? { value: state.input_profit_volume_percent || 0 } : {},
  };
  return !state.form_state_buy_sell ? (
    <Form.Group className="middle-form">
      <div className="form-item d-flex">
        <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center">
          <span className="font-weight-medium font-size-16 me-3">{TAKE_PROFIT}</span>
          <Toggle
            icons={false}
            checked={state.toggle_take_profit}
            onChange={toggleOnChange}
            name="toggle_take_profit"
          />
        </label>
      </div>
      {state.toggle_take_profit ? (
        <Fragment>
          {isSinglePair ? (
            <div className="form-item">
              <InputGroup className="d-flex justify-content-center w-100">
                <ButtonGroup className="w-100 button-100">
                  <ButtonCustom
                    label="Limit"
                    variant="default"
                    active={state.button_ttp_limit}
                    name="button_ttp_limit"
                    size="sm"
                    onClick={onClickHandler}
                  />
                  <ButtonCustom
                    label="Market"
                    variant="default"
                    active={state.button_ttp_market}
                    name="button_ttp_market"
                    size="sm"
                    onClick={onClickHandler}
                  />
                </ButtonGroup>
              </InputGroup>
            </div>
          ) : null}
          {!isEmpty(state.tableData) && isSinglePair ? (
            <div className="form-item">
              <div className="table-row-wrapper">
                <div className="chart">Chart...</div>
                <div className="table">
                  <div className="small-custom-table">
                    <div className="small-custom-table-row table-header">
                      <div className="custom-table-header-cell font-size-14 font-weight-medium">{`${SMALL_TABLE.PRICE} ${mainCoin}`}</div>
                      <div className="custom-table-header-cell font-size-14 font-weight-medium">
                        {SMALL_TABLE.TP}
                      </div>
                      <div className="custom-table-header-cell font-size-14 font-weight-medium">
                        {SMALL_TABLE.QUANTITY}
                      </div>
                    </div>
                    {state.tableData.map((el, i) => (
                      <div
                        key={i}
                        className="small-custom-table-row small-table-body font-size-14 font-weight-regular"
                      >
                        <div className="custom-table-body-cell">{`${el.price}`}</div>
                        <div className="custom-table-body-cell">{`${el.tp} %`}</div>
                        <div className="custom-table-body-cell">{`${el.quantity} %`}</div>
                        <div className="custom-table-body-cell">
                          <i
                            className="fa-light fa-xmark negative-color btn-close-cell"
                            name="delete-row"
                            uuid={el.uuid}
                            onClick={onClickHandler}
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {isSinglePair ? (
            <div className="form-item pb-2">
              <InputGroup>
                <ButtonCustom label="Last" />
                <Form.Control
                  className="ms-3 font-size-14 font-weight-regular"
                  name="input_profit_value"
                  min={state.input_price_value}
                  onChange={formControlChange}
                  value={state.input_profit_value}
                  type="number"
                  readOnly={state.button_ttp_market}
                />
                <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
              </InputGroup>
            </div>
          ) : null}
          {!isShort ? (
            <div className="form-item d-flex w-100 align-items-center">
              <Slider
                className="slider -primary"
                {...settings.slider_profit_percent}
                onBeforeChange={onBeforeHandle}
                onAfterChange={(value) =>
                  onAfterChangeHandle({ name: 'slider_profit_percent', value })
                }
              />
              <InputGroup className="ms-3">
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  name="input_profit_percent"
                  value={state.input_profit_percent}
                  onChange={formControlChange}
                  max={300}
                  min={0}
                  type="number"
                />
                <InputGroup.Text className="right-input-button">
                  <i className="fa-solid fa-percent"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          ) : (
            <div className="form-item d-flex w-100 align-items-center">
              <Slider
                className="slider -danger"
                reverse={true}
                {...settings.slider_profit_short_percent}
                onBeforeChange={onBeforeHandle}
                onAfterChange={(value) =>
                  onAfterChangeHandle({ name: 'slider_profit_short_percent', value })
                }
              />
              <InputGroup className="ms-3">
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  name="input_profit_short_percent"
                  value={state.input_profit_short_percent}
                  onChange={formControlChange}
                  max={0}
                  min={-100}
                  type="number"
                />
                <InputGroup.Text className="right-input-button">
                  <i className="fa-solid fa-percent"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          )}

          {state.profit_form ? (
            <>
              <div className="form-item d-flex justify-content-between w-100">
                <Form.Label className="font-weight-regular font-size-14 p-0 m-0">
                  {VOLUME}
                </Form.Label>
                <Form.Label className="font-size-14 font-weight-regular p-0 m-0">{`${state.priceOfQuantity} ${mainCoin}`}</Form.Label>
              </div>
              <div className="form-item d-flex w-100 align-items-center pb-2">
                <Slider
                  className="slider -danger"
                  reverse={true}
                  {...settings.slider_profit_volume_percent}
                  onBeforeChange={onBeforeHandle}
                  onAfterChange={(value) =>
                    onAfterChangeHandle({ name: 'slider_profit_volume_percent', value })
                  }
                />
                <InputGroup className="ms-3">
                  <Form.Control
                    className="font-size-14 font-weight-regular"
                    name="input_profit_volume_percent"
                    value={state.input_profit_volume_percent}
                    onChange={formControlChange}
                    max={100}
                    min={0}
                    type="number"
                  />
                  <InputGroup.Text className="right-input-button">
                    <i className="fa-solid fa-percent"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div className="form-item d-flex w-100 controls-buttons">
                <div className="w-100 button-100">
                  <ButtonCustom
                    label="Ad TP target"
                    name="add-tp-action"
                    onClick={onClickHandler}
                  />
                </div>
                {!isEmpty(state.tableData) ? (
                  <div className="ms-2">
                    <ButtonCustom
                      label="Cancel"
                      name="cancel-action"
                      variant="danger"
                      action="button-click"
                      onClick={onClickHandler}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {!state.profit_form && isSinglePair ? (
            <div className="form-item w-100 button-100 pt-3 pb-3">
              <ButtonCustom
                variant="default"
                name="split-action"
                label="Split"
                onClick={onClickHandler}
                disabled={!state.input_amount_value}
              />
            </div>
          ) : null}
          <div className="form-item">
            <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center">
              <span className="font-weight-regular font-size-14 me-3">{TRAILING_TAKE_PROFIT}</span>
              <Toggle
                name="toggle_trailing_take_profit"
                checked={state.toggle_trailing_take_profit}
                icons={false}
                onChange={toggleOnChange}
              />
            </label>
          </div>
          {state.toggle_trailing_take_profit ? (
            <div className="form-item pb-2">
              <Form.Label className="font-weight-regular font-size-14">
                {FOLLOW_MAX_PRICE_WITH_DEVIATION}
              </Form.Label>
              <div className="d-flex w-100 align-items-center deviation-input">
                <Slider
                  className="slider -primary"
                  {...settings.slider_profit_max_price_percent}
                  onBeforeChange={onBeforeHandle}
                  onAfterChange={(value) =>
                    onAfterChangeHandle({ name: 'slider_profit_max_price_percent', value })
                  }
                />
                <InputGroup className="ms-3">
                  <Form.Control
                    className="font-size-14 font-weight-regular"
                    name="input_profit_max_price_percent"
                    value={state.input_profit_max_price_percent}
                    onChange={formControlChange}
                    max={100}
                    min={0}
                    type="number"
                  />
                  <InputGroup.Text className="right-input-button">
                    <i className="fa-solid fa-percent"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div>
          ) : null}
        <GridBotTemplate
        state={state}
        pairData={pairData}
        formControlChange={formControlChange}
        toggleOnChange={toggleOnChange}
        onClickHandler={onClickHandler}
      />
        </Fragment>
      ) : null}
    </Form.Group>
  ) : (
    <div />
  );
};

const renderSLossForm = (
  state,
  pairData,
  formControlChange,
  toggleOnChange,
  sliderOnChange,
  onClickHandler,
  theme,
  action,
 
  
) => {
  const { mainCoin, isSinglePair } = pairData;
  const [flag, setFlag] = useState(true);
  const onBeforeHandle = () => setFlag(false);
  const onAfterChangeHandle = (value) => {
    sliderOnChange(value);
    setFlag(true);
  };
  const isShort = Boolean(state.formType === 'form_state_pro_short');
  const settings = {
    slider_stop_loss_value_percent: flag ? { value: -state.input_stop_loss_value_percent } : {},
    slider_stop_loss_short_value_percent: flag
      ? { value: state.slider_stop_loss_short_value_percent }
      : {},
    slider_stop_loss_timeout_sec: flag ? { value: state.input_stop_loss_timeout_sec / 60 } : {},
  };
  return !state.form_state_buy_sell ? (
    <Form.Group className="right-form">
      <div className="form-item d-flex">
        <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center">
          <span className="font-weight-medium font-size-16  me-3">{STOP_LOSS}</span>
          <Toggle
            name="toggle_stop_loss"
            icons={false}
            checked={state.toggle_stop_loss}
            onChange={toggleOnChange}
            disabled={!state.toggle_take_profit}
          />
        </label>
      </div>
      {state.toggle_stop_loss ? (
        <Fragment>
          {isSinglePair ? (
            <>
              <div className="form-item">
                <InputGroup className="d-flex justify-content-center w-100">
                  <ButtonGroup className="w-100 button-100">
                    <ButtonCustom
                      label="Cond. Limit Order"
                      variant="default"
                      active={state.button_sloss_limit}
                      name="button_sloss_limit"
                      size="sm"
                      onClick={onClickHandler}
                    />
                    <ButtonCustom
                      label="Cond. Market Order"
                      variant="default"
                      active={state.button_sloss_market}
                      name="button_sloss_market"
                      size="sm"
                      onClick={onClickHandler}
                    />
                  </ButtonGroup>
                </InputGroup>
              </div>
              <div className="form-item">
                <InputGroup>
                  <ButtonCustom label="Last" />
                  <Form.Control
                    value={state.input_stop_loss_value}
                    name="input_stop_loss_value"
                    min={0}
                    max={state.input_price_value}
                    onChange={formControlChange}
                    type="number"
                    className="ms-3 font-size-14 font-weight-regular"
                  />
                  <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
                </InputGroup>
              </div>
            </>
          ) : null}
          {state.button_sloss_limit ? (
            <div className="form-item pt-3">
              <div>Order price</div>
              <InputGroup className="pb-3 pt-3">
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  name="input_sloss_cond_limit"
                  value={state.input_sloss_cond_limit}
                  onChange={formControlChange}
                  min={0}
                  type="number"
                />
                <InputGroup.Text className="right-input-button">{mainCoin}</InputGroup.Text>
              </InputGroup>
            </div>
          ) : null}

          <div className="form-item d-flex w-100 align-items-center stop-loss-range-input">
            {!isShort ? (
              <Fragment>
                <Slider
                  className="slider -danger"
                  reverse={true}
                  {...settings.slider_stop_loss_value_percent}
                  marks={MARKS.SHORT}
                  onBeforeChange={onBeforeHandle}
                  onAfterChange={(value) =>
                    onAfterChangeHandle({ name: 'slider_stop_loss_value_percent', value: value })
                  }
                />
                <InputGroup className="ms-3">
                  <Form.Control
                    className="font-size-14 font-weight-regular"
                    value={state.input_stop_loss_value_percent}
                    name="input_stop_loss_value_percent"
                    onChange={formControlChange}
                    max={0}
                    min={-100}
                    type="number"
                  />
                  <InputGroup.Text className="right-input-button">
                    <i className="fa-solid fa-percent"></i>
                  </InputGroup.Text>
                </InputGroup>
              </Fragment>
            ) : (
              <Fragment>
                <Slider
                  className="slider -primary"
                  reverse={false}
                  {...settings.slider_stop_loss_short_value_percent}
                  onBeforeChange={onBeforeHandle}
                  onAfterChange={(value) =>
                    onAfterChangeHandle({
                      name: 'slider_stop_loss_short_value_percent',
                      value: value,
                    })
                  }
                />
                <InputGroup className="ms-3">
                  <Form.Control
                    className="font-size-14 font-weight-regular"
                    value={state.input_stop_loss_short_value_percent}
                    name="input_stop_loss_short_value_percent"
                    onChange={formControlChange}
                    max={100}
                    min={0}
                    type="number"
                  />
                  <InputGroup.Text className="right-input-button">
                    <i className="fa-solid fa-percent"></i>
                  </InputGroup.Text>
                </InputGroup>
              </Fragment>
            )}
          </div>
          <div className="form-item">
            <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center justify-content-between w-50 stop-loss-label">
              <div className="me-3 font-size-14 font-weight-regular">{STOP_LOSS_TIMEOUT}</div>
              <Toggle
                name="toggle_stop_loss_timeout"
                icons={false}
                checked={state.toggle_stop_loss_timeout}
                onChange={toggleOnChange}
              />
            </label>
            {state.toggle_stop_loss_timeout ? (
              <div className="d-flex w-100 align-items-center stop-loss-delay">
                <Slider
                  className="slider -primary"
                  {...settings.slider_stop_loss_timeout_sec}
                  marks={MARKS.DELAY}
                  onBeforeChange={onBeforeHandle}
                  onAfterChange={(value) =>
                    onAfterChangeHandle({ name: 'slider_stop_loss_timeout_sec', value: value })
                  }
                />
                <InputGroup className="ms-3">
                  <Form.Control
                    className="font-size-14 font-weight-regular"
                    name="input_stop_loss_timeout_sec"
                    value={state.input_stop_loss_timeout_sec}
                    onChange={formControlChange}
                    type="number"
                  />
                  <InputGroup.Text className="right-input-button">SEC</InputGroup.Text>
                </InputGroup>
              </div>
            ) : null}
          </div>
          <div className="form-item">
            <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center justify-content-between w-50 trailing-stop-loss">
              <div className="me-3 font-size-14 font-weight-regular">{TRAILING_STOP_LOSS}</div>
              <Toggle
                name="toggle_trailing_stop_loss"
                icons={false}
                checked={state.toggle_trailing_stop_loss}
                onChange={toggleOnChange}
              />
            </label>
          </div>
          <div className="form-item">
            <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center justify-content-between w-50">
              <div className="me-3 font-size-14 font-weight-regular">{MOVE_TO_BREAKEVEN}</div>
              <Toggle
                name="toggle_move_breakeven"
                icons={false}
                checked={state.toggle_move_breakeven}
                onChange={toggleOnChange}
              />
            </label>
          </div>
          <CostAverage  state={state} pairData={pairData} formControlChange={formControlChange} toggleOnChange={toggleOnChange} onClickHandler={onClickHandler} theme={theme} action={action}  />
        </Fragment>
      ) : null}
    </Form.Group>
  ) : (
    <div />
  );
};

export const botSettingsTemplates = {
  renderSLossForm,
  renderBuyForm,
  renderTTPForm,
};
