import { action } from 'easy-peasy';
import { notifyEffects } from '../utility/notifications/notifyEffects';
import { isEmpty } from 'lodash';
import { priceEffects } from './priceEffects';


let BUFFER = new Map();


const actionTypes = {
    WEBSOCKET_MESSAGE_RECEIVED: 'WEBSOCKET_MESSAGE#RECEIVED',
    WEBSOCKET_CONNECTION_OPENED: 'WEBSOCKET_CONNECTION#OPENED',
    WEBSOCKET_CONNECTION_CLOSED: 'WEBSOCKET_CONNECTION#CLOSED',
    NOTIFICATION_SUCCESS_TRADE: 'NOTIFICATION#SUCCESS_TRADE',
    NOTIFICATION_FAILED_TRADE: 'NOTIFICATION#FAILED_TRADE'
};

const messageReceived = (state, payload) => {
    const { data } = payload;

    if (isEmpty(data)) // if is error and we got empty data
        return;

    const stream = JSON.parse(data);

    if (!stream.stream && stream.id === 200) // code of unsubscribe to stream is 200
        priceEffects.savePrice(state, BUFFER);
    else if (!stream.stream && stream.id === 100) // code 100 means that we subscribe to stream
        return;
    else if (stream.data) {
        const bufferedValue = stream.data;
        BUFFER.set(bufferedValue.s, bufferedValue);
    }

};

const connectionOpened = () => {
    const notification = { type: 'info', text: 'Websocket opened' };
    notifyEffects.notifyAction(notification);
};

const connectionClosed = () => {
    const notification = { type: 'warning', text: 'Websocket closed' };
    notifyEffects.notifyAction(notification);
};

const successfullTrade = () =>{
    const notification = { type: 'info', text: 'Successful trade'}
    notifyEffects.notifyAction(notification)
}

const unsuccessfullTrade = () =>{
    const notification = { type: 'warning', text: 'Unsuccessful trade'}
    notifyEffects.notifyAction(notification)
}


const actionHandlers = {
    [actionTypes.WEBSOCKET_MESSAGE_RECEIVED]: action((state, payload) => messageReceived(state, payload)),
    [actionTypes.WEBSOCKET_CONNECTION_OPENED]: action((state, payload) => connectionOpened(state, payload)),
    [actionTypes.WEBSOCKET_CONNECTION_CLOSED]: action((state, payload) => connectionClosed(state, payload)),
    [actionTypes.NOTIFICATION_SUCCESS_TRADE]: action((state, payload) => successfullTrade(state, payload)),
    [actionTypes.NOTIFICATION_FAILED_TRADE]: action((state, payload) => unsuccessfullTrade(state, payload)),
};

export const websocketEffect = {
    actionTypes,
    actionHandlers,
};