import React from 'react';
import './styles.scss';
import { Modal } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { AddExchangeForm } from './templates';


export const AddExchangeModal = props => {
    const state = useStoreState(state => state);
    const updateState = useStoreActions(state => state.updateState);
    const {show, action} = props;
    const handleClose = () => {
        updateState({
            path: 'tableWidget.toggleAddExchangeModal',
            value: false,
        });
        action ? action(false) : '';
    };
    const container = document.querySelector('.wrapper');
    return <Modal
        size="md"
        show={state.tableWidget.toggleAddExchangeModal || show}
        centered
        container={container}
        backdrop="static"
        className="add-exchange"
        onHide={handleClose}
        autoFocus={false}
        enforceFocus={false}>
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="d-flex flex-column justify-content-center">
                <div className="font-size-16 font-weight-medium text-center form-title mb-2">Add Exchange</div>
                <AddExchangeForm handleClose={handleClose}/>
            </div>
        </Modal.Body>
    </Modal>;
};
