import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';


const actionTypes = {
    TOGGLE_BUG_REPORT_MODAL: 'TOGGLE_BUG_REPORT_MODAL',
    SEND_DATA_FORM: 'SEND_DATA_FORM',
    TOGGLE_REPORT_SUCCSES: 'TOGGLE_REPORT_SUCCSES',
    TOGGLE_REPORT_ERROR: 'TOGGLE_REPORT_ERROR',
    HANDLE_FILE_CHANGE: 'HANDLE_FILE_CHANGE',
    CONVERT_TO_BASE_64: 'CONVERT_TO_BASE_64',
    SET_FILES: 'SET_FILES',
    REMOVE_FILE: 'REMOVE_FILE',
    SUBMIT_FORM: 'SUBMIT_FORM'
};


const toggleReportModal = (state) => {

    if(state.report.files.length > 0){
      state.report.files = [];
    }

    state.report.showBugReportModal = !state.report.showBugReportModal
}


const toggleReportSuccses = (state) => {
    state.report.showReportSuccses = !state.report.showReportSuccses;
}

const toggleReportError = (state) => {
    state.report.showReportError = !state.report.showReportError;
}

const handlefileChange =  (actions, payload) => {

    Promise.all(payload.map(async (file) => {
      const base64 = await actions.CONVERT_TO_BASE_64(file);

      let fileObject = {fileName: file.name, base64: base64};
      
      actions.SET_FILES(fileObject);
      
    }));

}

const convertToBase64 = (actions, payload) =>{

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(payload);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    
}

const setFiles = (state, payload) => {
    state.report.files.push(payload);
}

const removeFile = (state, payload) => {
    state.report.files = state.report.files.filter((file,index) => index !== payload);
}

const submitForm = (actions, payload) => {
    actions.SEND_DATA_FORM(payload)
}

const sendDataForm = (actions, payload) => {

    serviceUtils.HttpService({
        url: `/feedback`,
        method: 'post',
        data: payload,
        errorActionType: actions[actionTypes.TOGGLE_REPORT_ERROR],
        successActionType: actions[actionTypes.TOGGLE_REPORT_SUCCSES]
    });
};

const actionHandlers = {
    [actionTypes.TOGGLE_BUG_REPORT_MODAL]: action((state) => toggleReportModal(state)),
    [actionTypes.SEND_DATA_FORM]: thunk(async (actions, payload) => sendDataForm(actions, payload)),
    [actionTypes.TOGGLE_REPORT_SUCCSES]: action((state) => toggleReportSuccses(state)),
    [actionTypes.TOGGLE_REPORT_ERROR]: action((state) => toggleReportError(state)),
    [actionTypes.HANDLE_FILE_CHANGE]: thunk(async (actions, payload) => handlefileChange(actions, payload)),
    [actionTypes.CONVERT_TO_BASE_64]: thunk((actions, payload) => convertToBase64(actions, payload)),
    [actionTypes.SET_FILES]: action((state, payload) => setFiles(state, payload)),
    [actionTypes.REMOVE_FILE]: action((state, payload) => removeFile(state, payload)),
    [actionTypes.SUBMIT_FORM]: thunk(async (actions, payload) => submitForm(actions, payload)),
};

export const bugReportActions = {
    actionTypes,
    actionHandlers
};