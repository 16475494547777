import React, { useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import Toggle from 'react-toggle';
import styles from './styles';
import { SwapState } from '../../models/swap-model';
import { uiActions } from './actions/uiActions';
import { MultiplyCustomSelector } from '../../components/utility/multiply-selector/view';
import { ButtonCustom } from '../../components/utility/custom-buttons/view';
import { PopoverLayout } from '../../components/utility/popover/view';
import { FormControl, InputGroup } from 'react-bootstrap';
import { debug, useStoreState } from 'easy-peasy';
import { constants_swap } from "./constants";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SwapLayout = () => {
    const { INIT_DATA, HEADERS: {LIMIT, P2P, SWAP}  } = constants_swap;

    const actions = SwapState.useStoreActions(actions => actions);
    const state = SwapState.useStoreState(state => state);
    const userId = useStoreState(state => state.authentication.userData.userId);

    // tab
    const tabSwapClicked = () => actions[uiActions.actionTypes.TAB_SELECT_SWAP]();
    const tabSettingsClicked = () => actions[uiActions.actionTypes.TAB_SELECT_SETTINGS]();

    // swap form
    const swapChainChanged = value => actions[uiActions.actionTypes.SWAP_CHAIN_CHANGED]({ value, state });
    const swapSellValueChanged = elem => actions[uiActions.actionTypes.SWAP_SELL_VALUE_CHANGED]({ value: elem.target.value, state });
    const swapSellMax = () => actions[uiActions.actionTypes.SWAP_SELL_MAX]({ state });
    const swapSelectSellToken = () => actions[uiActions.actionTypes.SWAP_SELECT_SELL_TOKEN]();
    const swapSelectBuyToken = () => actions[uiActions.actionTypes.SWAP_SELECT_BUY_TOKEN]();
    const swapSwap = () => actions[uiActions.actionTypes.SWAP_PREPARE]({ userId, state });

    // tokens form
    const tokensGoBack = () => actions[uiActions.actionTypes.TOKENS_GO_BACK]({ state });
    const tokensSearch = () => actions[uiActions.actionTypes.TOKENS_SEARCH_DO]();
    const tokensSearchKeyUp = e => e.which === 13 ? actions[uiActions.actionTypes.TOKENS_SEARCH_DO]() : '';
    const tokensSearchChange = e => actions[uiActions.actionTypes.TOKENS_SEARCH_SET]({ value: e.target.value });
    const tokensAddTokenToMetamask = key => actions[uiActions.actionTypes.TOKENS_ADD_TOKEN_TO_METAMASK]({ state, key });
    const tokensSelectToken = symbol => actions[uiActions.actionTypes.TOKENS_SELECT_TOKEN]({ state, symbol });

    // settings form
    const settingsGoBack = () => actions[uiActions.actionTypes.SETTINGS_GO_BACK]();
    const settingsGasCustomChange = e => actions[uiActions.actionTypes.SETTINGS_SET_GAS_CUSTOM]({ value: e.target.value });
    const settingsGasCustomClick = () => actions[uiActions.actionTypes.SETTINGS_SET_GAS_CUSTOM]();
    const settingsSelectGas = value => actions[uiActions.actionTypes.SETTINGS_SELECT_GAS]({ value });
    const settingsSlippageCustomChange = e => actions[uiActions.actionTypes.SETTINGS_SET_SLIPPAGE_CUSTOM]({ value: e.target.value });
    const settingsSlippageCustomClick = () => actions[uiActions.actionTypes.SETTINGS_SET_SLIPPAGE_CUSTOM]();
    const settingsSelectSlippage = value => actions[uiActions.actionTypes.SETTINGS_SELECT_SLIPPAGE]({ value });
    const settingsToggleAllowPartialFill = e => actions[uiActions.actionTypes.SETTINGS_TOGGLE_ALLOW_PARTIAL_FILL]({ value: e.target.value });
    const settingsToggleRoutingPreset = e => actions[uiActions.actionTypes.SETTINGS_TOGGLE_ROUTING_PRESET]({ value: e.target.value });
    const settingsToggleCompatibilityMode = e => actions[uiActions.actionTypes.SETTINGS_TOGGLE_COMPATIBILITY_MODE]({ value: e.target.value });

    // effects
    useEffect(() => {
        actions[uiActions.actionTypes.SWAP_INITIALIZE]({ userId, state });
    }, [state.initializeStep]);

    useEffect(() => {
        actions[uiActions.actionTypes.SWAP_FETCH_QUOTE]({ state });
    }, [state.swap.sell.value]);

    useEffect(() => {
        actions[uiActions.actionTypes.SWAP_STATUS]();
    }, [state.swap.status]);

    useEffect(() => {
        actions[uiActions.actionTypes.TOKENS_ADDRESS_FOR_IMPORT]({ userId, state });
    }, [state.tokens.addressForImport]);

    useEffect(() => {
        actions[uiActions.actionTypes.TOKENS_IMPORT_DO]();
    }, [state.tokens.import]);

    useEffect(() => {
        actions[uiActions.actionTypes.SWAP_SEND_TRANSACTION]({ userId, state });
    }, [state.approve.transaction]);

    useEffect(() => {
        actions[uiActions.actionTypes.SWAP_SEND]({ userId, state });
    }, [state.swap.do]);
    // design
    switch (state.formActive) {
        case 'swap':
            return  <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
            <div className="swap-layout content ps-3 pe-3">
                <div className="swap-layout controls">
                    <div>
                        <div className="chains-selector">
                            <MultiplyCustomSelector
                                options={state.chain.list}
                                onChangeHandle={swapChainChanged}
                                closeMenuOnSelect={true}
                                isDisabled={false}
                                isMulti={false}
                                isLoading={false}
                                value={state.chain.value}
                            />
                        </div>
                        <div className="tabs-selector d-inline-flex cbox-wrapper align-items-center me-5">
                            <div className="tab-swap tabs-selector-item tabs-selector-item active" onClick={tabSwapClicked}>{SWAP}</div>
                            <div className="tab-limit tabs-selector-item">{LIMIT}</div>
                            <div className="tab-p2p tabs-selector-item">{P2P}</div>
                            <div className="tabs-selector-clear"></div>
                            <div className="tab-refresh tabs-selector-item"><i className="fa-light fa-arrows-rotate fa-lg"></i></div>
                            <div className="tab-settings tabs-selector-item"><i className="fa-light fa-sliders fa-lg" onClick={tabSettingsClicked}></i></div>
                        </div>
                        <div className="swap-form">
                            <div className="swap-form-sell">
                                <div className="swap-form-sell-captions font-inter d-inline-flex">
                                    <div>You sell</div>
                                    <div className="swap-form-sell-captions-balance d-inline-flex justify-content-end">Balance: <div className="swap-form-sell-captions-balance-value">{state.swap.sell.balance}</div></div>
                                    <div className="swap-form-sell-captions-max">
                                        <ButtonCustom
                                            className="swap-form-sell-captions-max-button"
                                            onClick={swapSellMax}
                                            variant="primary"
                                            size="sm"
                                            label="Max">
                                        </ButtonCustom>
                                    </div>
                                </div>
                                <div className="swap-form-sell-input d-flex align-items-center justify-content-center">
                                    <div className="p-1 d-flex align-items-center justify-content-center swap-form-sell-input-token" onClick={swapSelectSellToken}><PopoverLayout icon={state.swap.sell.token.symbol} message={state.swap.sell.token.symbol} /><i className="m-2 fa-sharp fa-light fa-chevron-down"></i></div>
                                    <div className="w-100 d-flex justify-content-end">
                                        <FormControl
                                            placeholder="Enter amount..."
                                            type="number"
                                            min="0"
                                            value={state.swap.sell.value}
                                            className="input-control"
                                            onChange={swapSellValueChanged}
                                        />
                                    </div>
                                </div>
                                <div className="swap-form-sell-exchange font-inter d-inline-flex">
                                    <div className="swap-form-sell-exchange-token">{state.swap.sell.token.name}</div>
                                    <div className="swap-form-sell-exchange-balance d-inline-flex justify-content-end" title={`~$${state.swap.sell.valueUSD}`}>{`~$${state.swap.sell.valueUSD.toFixed(2)}`}</div>
                                </div>
                            </div>

                            <div className="swap-form-buy">
                                <div className="swap-form-buy-captions font-inter d-inline-flex">
                                    <div>You buy</div>
                                    <div className="swap-form-buy-captions-balance d-inline-flex justify-content-end">Balance: <div className="swap-form-buy-captions-balance-value">{state.swap.buy.balance}</div></div>
                                </div>
                                <div className="swap-form-buy-input d-flex align-items-center justify-content-center">
                                    <div className="p-1 d-flex align-items-center justify-content-center swap-form-buy-input-token" onClick={swapSelectBuyToken}><PopoverLayout icon={state.swap.buy.token.symbol} message={state.swap.buy.token.symbol} /><i className="m-2 fa-sharp fa-light fa-chevron-down"></i></div>
                                    <div className="w-100 d-flex justify-content-end">
                                        <FormControl
                                            placeholder="Enter amount..."
                                            type="number"
                                            min="0"
                                            title={state.swap.buy.value}
                                            value={state.swap.buy.value.toFixed(4)}
                                            className="input-control"
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="swap-form-buy-exchange font-inter d-inline-flex">
                                    <div className="swap-form-buy-exchange-token">{state.swap.buy.token.name}</div>
                                    <div className="swap-form-buy-exchange-balance d-inline-flex justify-content-end" title={`~$${state.swap.buy.valueUSD}`}>{`~$${state.swap.buy.valueUSD.toFixed(2)}`}</div>
                                </div>
                            </div>

                            <div className="swap-form-button">
                                <ButtonCustom
                                    onClick={swapSwap}
                                    className="swap-form-button-do w-100 flex-fill"
                                    variant="primary"
                                    disabled={!state.swap.enabled}
                                    size="sm"
                                    label={state.swap.statusLabel}>
                                </ButtonCustom>
                            </div>
                        </div>
                        <div className="swap-more">
                            <div className="swap-more-rate font-inter d-inline-flex w-100">
                                <div>{state.swap.buy.token.symbol} buy price <i className="fa-sharp fa-light fa-chevron-down"></i></div>
                                <div className="swap-more-rate-value d-inline-flex justify-content-end">{state.swap.buy.value > 0 ? `${(state.swap.sell.value / state.swap.buy.value).toFixed(8)} ${state.swap.sell.token.symbol} ~$${(state.swap.sell.value / state.swap.buy.value * state.swap.sell.rateUSD).toFixed(4)}` : ``}</div>
                                <div className="swap-more-rate-show"><i className="fa-regular fa-circle-info"></i></div>
                            </div>
                            <div className="swap-more-route font-inter d-inline-flex w-100">
                                <div>Route</div>
                                <div className="swap-more-route-value d-inline-flex justify-content-end">{state.swap.sell.token.symbol} &gt; {state.swap.buy.token.symbol}</div>
                                <div className="swap-more-route-show"><i className="fa-regular fa-arrow-up-right-and-arrow-down-left-from-center"></i></div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div></div>
                </CustomScroll> 
            break;

        case 'tokens':
            return <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
            <div className="tokens-layout content ps-3 pe-3">
                <div className="tokens-layout controls">
                    <div>
                        <div className="tokens-form">
                            <div className="tokens-form-go-back d-inline-flex">
                                <i className="m-1 fa-light fa-chevron-left tokens-form-go-back-chevron-left" onClick={tokensGoBack}></i><span className="tokens-form-go-back-caption">Select a token</span>
                            </div>
                            <div className="search-input">
                                <InputGroup onKeyUp={tokensSearchKeyUp}>
                                    <InputGroup.Text className="clear">
                                        <i className="fa-thin fa-magnifying-glass"></i>
                                    </InputGroup.Text>
                                    <FormControl
                                        onChange={tokensSearchChange}
                                        placeholder={'Search by name or paste address'} />
                                    <InputGroup.Text className='custom-btn-search' onClick={tokensSearch}>Search</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="selected-tokens">
                                <Container className="selected-tokens-container"><Row>
                                    {INIT_DATA.find(t => t.chain === state.chain.value.value).tokens.map((key) => <Col md={3}><div className="selected-tokens-container-item d-inline-flex"><PopoverLayout icon={key} message={key} onClick={() => tokensSelectToken(key)}/></div></Col>)}
                                </Row></Container>
                            </div>
                            <div className="divider"><div className="line"></div></div>
                            <div className="all-tokens">
                                <div className="scroll-container">
                                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                                        <Container className="all-tokens-container">
                                            {state.tokens.list.length === 0 || state.tokens.filtered.length === 0 ?
                                                <Row className="all-tokens-container-row">
                                                    <Col md={12}><div className="all-tokens-container-row-item">No tokens found..</div></Col>
                                                </Row> : state.tokens.filtered.map((tk) => <Row className="all-tokens-container-row">
                                                    <Col md={6}>
                                                        <div className="all-tokens-container-row-item"><PopoverLayout icon={tk.symbol} message={tk.name} onClick={() => tokensSelectToken(tk.symbol)}/></div>
                                                        <div className="all-tokens-container-row-balance">{tk.balance ? tk.balance.balance : '0'} {tk.symbol}</div>
                                                    </Col>
                                                    <Col md={5} className="d-inline-flex align-items-center justify-content-end all-tokens-container-row-balance-usd">{tk.balance && tk.balance.balanceUsd ? `$${parseFloat(tk.balance.balanceUsd).toFixed(2)}` : `$0.00`}</Col>
                                                    <Col md={1} className="d-inline-flex align-items-center justify-content-center"><i className="fa-light fa-browser m-1 all-tokens-container-row-add-token" title={`Add ${tk.symbol} to Metamask`} onClick={() => tokensAddTokenToMetamask(tk.address)}></i><i className="fa-regular fa-thumbtack m-1"></i></Col>
                                                </Row>)}
                                        </Container>
                                    </CustomScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div></div>
                </CustomScroll>
            break;

        case 'settings':
            return  <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
            <div className="settings-layout content ps-3 pe-3">
                <div className="settings-layout controls">
                    <div>
                        <div className="settings-form">
                            <div className="settings-form-go-back d-inline-flex">
                                <i className="m-1 fa-light fa-chevron-left settings-form-go-back-chevron-left" onClick={settingsGoBack}></i><span className="settings-form-go-back-caption">Swap settings</span>
                            </div>
                            <div className="settings-form-gas-price">
                                <div className="settings-form-gas-price-info d-flex align-items-center">
                                    <div className="settings-form-gas-price-info-icon"><i className="fa-regular fa-gas-pump"></i></div>
                                    <div className="settings-form-gas-price-info-caption">Gas price</div>
                                    <div className="settings-form-gas-price-info-selected d-flex align-items-center justify-content-end w-100">{`${state.settings.gasPrice}`}{state.settings.gasPriceValue > 0 ? ` (${state.settings.gasPriceValue} Gwei)` : ``}</div>
                                </div>
                                <div className="settings-form-gas-price-select d-flex align-items-center">
                                    <div className={`settings-form-gas-price-select-item d-flex align-items-center justify-content-center ${state.settings.gasPrice === 'low' ? 'active' : ''}`} onClick={() => settingsSelectGas('low')}>Low (6)</div>
                                    <div className={`settings-form-gas-price-select-item d-flex align-items-center justify-content-center ${state.settings.gasPrice === 'market' ? 'active' : ''}`} onClick={() => settingsSelectGas('market')}>Market (15)</div>
                                    <div className={`settings-form-gas-price-select-item d-flex align-items-center justify-content-center ${state.settings.gasPrice === 'high' ? 'active' : ''}`} onClick={() => settingsSelectGas('high')}>High (20)</div>
                                    <div className={`settings-form-gas-price-select-custom ${state.settings.gasPrice === 'custom' ? 'active' : ''}`}>
                                        <FormControl
                                            type="number"
                                            min="0"
                                            value={state.settings.gasPrice === 'custom' ? state.settings.gasPriceValue : ''}
                                            className="settings-form-gas-price-select-custom-input"
                                            onChange={settingsGasCustomChange}
                                            onClick={settingsGasCustomClick}
                                            placeholder={'Custom'} />
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-slippage">
                                <div className="settings-form-slippage-info d-flex align-items-center">
                                    <div className="settings-form-slippage-info-icon"><i className="fa-light fa-wave-square"></i></div>
                                    <div className="settings-form-slippage-info-caption">Slippage tolerance</div>
                                    <div className="settings-form-slippage-info-selected d-flex align-items-center justify-content-end w-100">{`${state.settings.slippage}`}{state.settings.slippage === 'custom' && state.settings.slippageValue > 0 ? ` (${state.settings.slippageValue}%)` : ``}</div>
                                </div>
                                <div className="settings-form-slippage-select d-flex align-items-center">
                                    <div className={`settings-form-slippage-select-item d-flex align-items-center justify-content-center ${state.settings.slippage === '0.1%' ? 'active' : ''}`} onClick={() => settingsSelectSlippage('0.1%')}>0.1%</div>
                                    <div className={`settings-form-slippage-select-item d-flex align-items-center justify-content-center ${state.settings.slippage === '0.5%' ? 'active' : ''}`} onClick={() => settingsSelectSlippage('0.5%')}>0.5%</div>
                                    <div className={`settings-form-slippage-select-item d-flex align-items-center justify-content-center ${state.settings.slippage === '1%' ? 'active' : ''}`} onClick={() => settingsSelectSlippage('1%')}>1%</div>
                                    <div className={`settings-form-slippage-select-item d-flex align-items-center justify-content-center ${state.settings.slippage === '3%' ? 'active' : ''}`} onClick={() => settingsSelectSlippage('3%')}>3%</div>
                                    <div className={`settings-form-slippage-select-custom ${state.settings.slippage === 'custom' ? 'active' : ''}`}>
                                        <FormControl
                                            type="number"
                                            min="0"
                                            max="50"
                                            value={state.settings.slippage === 'custom' ? state.settings.slippageValue : ''}
                                            className="settings-form-slippage-select-custom-input"
                                            onChange={settingsSlippageCustomChange}
                                            onClick={settingsSlippageCustomClick}
                                            placeholder={'Custom'} />
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-partial">
                                <div className="settings-form-partial-info d-flex align-items-center">
                                    <div className="settings-form-partial-info-icon"><i className="fa-light fa-glass-half"></i></div>
                                    <div className="settings-form-partial-info-caption">Partial fill</div>
                                    <div className="settings-form-partial-info-toggle d-flex align-items-center justify-content-end w-100">
                                        <Toggle
                                            icons={false}
                                            checked={state.settings.allowPartialFill}
                                            onChange={settingsToggleAllowPartialFill}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-routing">
                                <div className="settings-form-routing-info d-flex align-items-center">
                                    <div className="settings-form-routing-info-icon"><i className="fa-light fa-list-check"></i></div>
                                    <div className="settings-form-routing-info-caption">Routing preset</div>
                                    <div className="settings-form-routing-info-toggle d-flex align-items-center justify-content-end w-100">
                                        <Toggle
                                            icons={false}
                                            checked={state.settings.routingPreset}
                                            onChange={settingsToggleRoutingPreset}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-compatibility">
                                <div className="settings-form-compatibility-info d-flex align-items-center">
                                    <div className="settings-form-compatibility-info-icon"><i className="fa-light fa-arrow-right-arrow-left"></i></div>
                                    <div className="settings-form-compatibility-info-caption">Compatibility mode</div>
                                    <div className="settings-form-compatibility-info-toggle d-flex align-items-center justify-content-end w-100">
                                        <Toggle
                                            icons={false}
                                            checked={state.settings.compatibilityMode}
                                            onChange={settingsToggleCompatibilityMode}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="settings-form-liquidity">
                                <div className="settings-form-liquidity-info d-flex align-items-center">
                                    <div className="settings-form-liquidity-info-icon"><i className="fa-light fa-chart-simple"></i></div>
                                    <div className="settings-form-liquidity-info-caption">Liquidity sources</div>
                                    <div className="settings-form-liquidity-info-qty d-flex align-items-center justify-content-end w-100">{state.settings.liquiditySources.length}</div>
                                    <div className="settings-form-liquidity-info-more"><i className="fa-light fa-chevron-right"></i></div>
                                </div>
                            </div>
                            <div className="settings-form-custom">
                                <div className="settings-form-custom-info d-flex align-items-center">
                                    <div className="settings-form-custom-info-icon"><i className="fa-light fa-coin-front"></i></div>
                                    <div className="settings-form-custom-info-caption">Custom tokens</div>
                                    <div className="settings-form-custom-info-qty d-flex align-items-center justify-content-end w-100">{state.settings.customTokens.length}</div>
                                    <div className="settings-form-custom-info-more"><i className="fa-light fa-chevron-right"></i></div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div></div>
                    <div></div>
                </div></div>
                </CustomScroll>
            break;
    }
};

export const SwapPage = () => <SwapState.Provider>
    <SwapLayout />
</SwapState.Provider>;