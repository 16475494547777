import React from 'react';
import { EventsState } from '../../../models/events-model';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faQuestionCircle,
  faCalendarAlt,
  faRing,
  faBell,
  faThumbsUp,
  faThumbsDown,
  faCheckCircle,
  faArrowToTop,
  faTint,
} from '@fortawesome/pro-solid-svg-icons';
import '../styles/highlights-styles';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { getColorByCardType } from '../constants/color-update';


export const HighlightsLayout = () => {
  const state = EventsState.useStoreState((state) => state.highlights_cards);

  return (
    <div className="highlights-body">
      {!isEmpty(state) ? (
        state.map((card, index) => {
          return (
            <div className="highlights-card" key={index}>
              <div className="highlights-card-type"  style={{ backgroundColor: getColorByCardType(card.cardType) }}>
                <div className="arrow-icons">
                  <FontAwesomeIcon icon={faArrowUp} />
                </div>
                <div className="type-title">{card.cardType}</div>
                <div className="question-icon">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </div>
              </div>
              <div className="horizontal-divider"></div>
              {card.coinsList.map((coin, i) => (
                <div className="highlights-card-data" key={i}>
                  <div key={index} className="highlights-card-body">
                    <img
                      src={coin.cardData.coinIcon}
                      className="coin-icon img-fluid rounded-start pt-30"
                    />
                    <div className="title-coin">{coin.cardData.titleCoin}</div>
                    <div className="date-coin"> {coin.cardData.date} </div>
                    <div className="notif-icons">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      <FontAwesomeIcon icon={faRing} />
                      <FontAwesomeIcon icon={faBell} />
                    </div>
                    <div className="views-wrapper">{coin.cardData.views}</div>
                    <div className="views-description">
                      Views
                      <div>( 24 hours )</div>
                    </div>
                    <div className="votes-wrapper">{coin.cardData.votes}</div>
                    <div className="votes-description">
                      Votes
                      <div>( 24 hours )</div>
                    </div>
                    <div className="icons-like d-flex">
                      <FontAwesomeIcon icon={faThumbsUp} width={22} height={20} />
                      <div className="like-description">Real</div>
                    </div>
                    <div className="icons-unlike d-flex">
                      <FontAwesomeIcon icon={faThumbsDown} width={22} height={20} />
                      <div className="unlike-description">Fake</div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="card-text pl-20">{coin.cardData.cardText}</div>
                    <div className="description-icons">
                      <FontAwesomeIcon icon={faCheckCircle} color="green" />
                      <FontAwesomeIcon icon={faArrowToTop} color="red" />
                      <FontAwesomeIcon icon={faTint} color="orange" />
                    </div>
                  </div>
                  <div className="horizontal-divider"></div>
                </div>
              ))}
            </div>
          );
        })
      ) : (
        <EmptySearchState />
      )}
    </div>
  );
};

export const HighlightsPage = () => (
  <EventsState.Provider>
    <HighlightsLayout />
  </EventsState.Provider>
);
