import Big from 'big.js';
import { isEmpty, groupBy } from 'lodash';

const getCorrectedValue = price => {
    const priceBTC = price.get('BTCUSDT');
    if (!priceBTC || Big(priceBTC.c).eq(0))
        return 0;
    return Big(1).div(Big(priceBTC.c)).valueOf();
};

const _updateBalanceOldTotal = balance => {
    return balance.map(el => {
        el['OLD-TOTAL-USDT'] = Big(el.oldPriceUSDT).times(Big(el.oldAmt)).valueOf();
        el['OLD-TOTAL-BTC'] = Big(el.oldPriceBTC).times(Big(el.oldAmt)).valueOf();
        el['OLD-TOTAL-USDT-N'] = Number(el['OLD-TOTAL-USDT']);
        el['OLD-TOTAL-BTC-N'] = Number(el['OLD-TOTAL-BTC']);
        return el;
    });
};

const getOldTotals = exchanges => {
    const keys = Object.keys(exchanges);
    keys.map(key => {
        const balance = exchanges[key].balances;
        exchanges[key].balances = _updateBalanceOldTotal(balance);
        return key;
    });
    return exchanges;
};

const getCoinPrice = (coin, price) => {
    const dataUSDT = price.get(`${coin}USDT`);
    const dataBTC = price.get(`${coin}BTC`);
    const priceUSDT = dataUSDT ? dataUSDT.c : 0;
    const changeUSDT = dataUSDT ? dataUSDT.P : 0;
    const priceBTC = dataBTC ? dataBTC.c : coin !== 'USDT' ? 0 : getCorrectedValue(price);
    const changeBTC = dataBTC ? dataBTC.P : 0;
    return { priceBTC, priceUSDT, changeBTC, changeUSDT };
};

/**
 *
 Summing Balances used flag if it's true not summing otherwise summing
 */
const sumBalances = data => {
    return data.reduce((acc, v) => {
        acc.coin = v.coin;
        acc.amt = !v.flag ? Big(acc.amt).plus(Big(v.amt)).valueOf() : acc.amt;
        acc.free = !v.flag ? Big(acc.free).plus(Big(v.free)).valueOf() : acc.free;
        acc.locked = !v.flag ? Big(acc.locked).plus(Big(v.locked)).valueOf() : acc.locked;
        acc['OLD-TOTAL-USDT'] = !v.flag ? Big(acc['OLD-TOTAL-USDT']).plus(Big(v['OLD-TOTAL-USDT'])).valueOf() : acc['OLD-TOTAL-USDT'];
        acc['OLD-TOTAL-BTC'] = !v.flag ? Big(acc['OLD-TOTAL-BTC']).plus(Big(v['OLD-TOTAL-BTC'])).valueOf() : acc['OLD-TOTAL-BTC'];
        acc['OLD-TOTAL-USDT-N'] = Number(acc['OLD-TOTAL-USDT']);
        acc['OLD-TOTAL-BTC-N'] = Number(acc['OLD-TOTAL-BTC']);
        return acc;
    }, {
        amt: '0',
        free: '0',
        locked: '0',
        'OLD-TOTAL-USDT': '0',
        'OLD-TOTAL-BTC': '0',
        'OLD-TOTAL-USDT-N': 0,
        'OLD-TOTAL-BTC-N': 0,
    });
};

const summingExchanges = (keys, exchanges) => {
    return keys.reduce((acc, key) => {
        acc.accountId = 'all_exchanges';
        acc.name = 'All exchanges';
        acc.balances = [...acc.balances, ...exchanges[key].balances];
        acc.history = {};
        return acc;
    }, {
        balances: [],
        history: {},
    });
};

const updateExchanges = exchanges => {
    const keys = Object.keys(exchanges);

    if (isEmpty(keys))
        return { updatedExchanges: {}, usedCoins: [] };

    const sumExchange = summingExchanges(keys, exchanges);

    exchanges[sumExchange.accountId] = sumExchange;

    const group = groupBy(sumExchange.balances, 'coin');
    const usedCoins = Object.keys(group);
    const sumB = usedCoins.reduce((acc, key) => {
        const res = group[key].length > 1 ? [sumBalances(group[key])] : group[key];
        return [...acc, ...res];
    }, []);
    sumExchange.balances = sumB;
    exchanges[sumExchange.accountId] = sumExchange;
    return { updatedExchanges: exchanges, usedCoins };

};

const getExchangeWithPrices = (exchange, prices) => {
    exchange.balances = exchange.balances.map(b => {
        const { priceBTC, priceUSDT, changeBTC, changeUSDT } = getCoinPrice(b.coin, prices);
        b.COIN = b.coin;
        b['AMT'] = b.amt;
        b['PRICE-USDT'] = b.coin === 'USDT' ? 1 : priceUSDT;
        b['PRICE-BTC'] = b.coin === 'BTC' ? 1 : priceBTC;
        b['CHANGE-USDT'] = b.coin === 'USDT' ? 0 : changeUSDT;
        b['CHANGE-BTC'] = b.coin === 'BTC' ? 0 : changeBTC;
        b['TOTAL-USDT'] = Big(b.amt).times(Big(b['PRICE-USDT'])).valueOf();
        b['TOTAL-BTC'] = Big(b.amt).times(Big(b['PRICE-BTC'])).valueOf();
        b['AMT-N'] = Number(b.amt);
        b['PRICE-USDT-N'] = Number(b['PRICE-USDT']);
        b['PRICE-BTC-N'] = Number(b['PRICE-BTC']);
        b['CHANGE-USDT-N'] = Number(b['CHANGE-USDT']);
        b['CHANGE-BTC-N'] = Number(b['CHANGE-BTC']);
        b['TOTAL-USDT-N'] = Number(b['TOTAL-USDT']);
        b['TOTAL-BTC-N'] = Number(b['TOTAL-BTC']);
        return b;
    });

    return exchange;
};

const getExchangeWithTotals = exchange => {
    return exchange.balances.reduce((acc, b) => {
        acc['USDT'] = Big(acc['USDT']).plus(Big(b['TOTAL-USDT'])).valueOf();
        acc['BTC'] = Big(acc['BTC']).plus(Big(b['TOTAL-BTC'])).valueOf();
        acc['OLD-USDT'] = Big(acc['OLD-USDT']).plus(Big(b['OLD-TOTAL-USDT'])).valueOf();
        acc['OLD-BTC'] = Big(acc['OLD-BTC']).plus(Big(b['OLD-TOTAL-BTC'])).valueOf();
        return acc;
    }, {
        USDT: '0',
        BTC: '0',
        'OLD-USDT': '0',
        'OLD-BTC': '0'
    });
};

const addShareValues = exchange => {
    const balances = exchange.balances;
    const totals = {
        USDT: exchange['TOTAL-BALANCE-EXCHANGE-USDT'],
        BTC: exchange['TOTAL-BALANCE-EXCHANGE-BTC'],
    };

    exchange.balances = balances.map(b => {
        b['SHARE-USDT'] = !Big(totals.USDT).eq(0) ? Big(b['TOTAL-USDT']).div(Big(totals.USDT)).times(100).valueOf() : 0;
        b['SHARE-BTC'] = !Big(totals.BTC).eq(0) ? Big(b['TOTAL-BTC']).div(Big(totals.BTC)).times(100).valueOf() : 0;
        b['SHARE-USDT-N'] = Number(b['SHARE-USDT']);
        b['SHARE-BTC-N'] = Number(b['SHARE-BTC']);
        return b;
    });
    return exchange;
};

const exchangesMutation = (state, buffer) => {
    const exchanges = state.coinData.exchanges;
    const prices = buffer;
    const keys = Object.keys(exchanges);

    const exchWPrices = keys.reduce((acc, key) => {
        acc[key] = getExchangeWithPrices(exchanges[key], prices);
        return acc;
    }, {});

    const exchWTotals = keys.reduce((acc, key) => {
        const total = getExchangeWithTotals(exchWPrices[key]);
        acc[key]['TOTAL-BALANCE-EXCHANGE-USDT'] = total.USDT;
        acc[key]['TOTAL-BALANCE-EXCHANGE-BTC'] = total.BTC;
        acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-USDT'] = total['OLD-USDT'];
        acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-BTC'] = total['OLD-BTC'];
        acc[key]['TOTAL-BALANCE-EXCHANGE-USDT-N'] = Number(acc[key]['TOTAL-BALANCE-EXCHANGE-USDT']);
        acc[key]['TOTAL-BALANCE-EXCHANGE-BTC-N'] = Number(acc[key]['TOTAL-BALANCE-EXCHANGE-BTC']);
        acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-USDT-N'] = Number(acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-USDT']);
        acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-BTC-N'] = Number(acc[key]['OLD-TOTAL-BALANCE-EXCHANGE-BTC']);
        return acc;
    }, exchWPrices);


    const exchWShare = keys.reduce((acc, key) => {
        acc[key] = addShareValues(exchWTotals[key]);
        return acc;
    }, {});
    return exchWShare;
};

const filteringExchanges = exchanges => {
    const keys = Object.keys(exchanges);
    return keys.reduce((acc, key) => {
        const exchange = exchanges[key];
        acc[key] = exchange;
        acc[key].balances = exchange.balances.filter(b => !(Big(b.amt).eq(0) && b.coin !== 'BTC' && b.coin !== 'USDT' && b.coin !== 'ETH'));
        return acc;
    }, {});
};

export const exchangesDataHelper = {
    updateExchanges,
    exchangesMutation,
    filteringExchanges,
    getOldTotals
};