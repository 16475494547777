import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { dynamicSplitEffects } from "../actions/dynamicSplitEffects";
import { constants } from "../constants";

const { FAVORITES: { ADD_FAVORITE } , PLACEHOLDERS: { TYPE_URL, FAVORITE_NAME } } = constants;
const schema = yup.object().shape({
  url: yup.string().url().required(),
  name: yup.string().required()
});

export const AddFavoriteForm = props => {
  const { actions } = props;
 
  return <Formik
    validationSchema={schema}
   
    initialValues={{
      url: '',
      name:'',
        }}
    onSubmit={(values, {resetForm}) => {
      actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]({values: values, method: 'post'});
      resetForm({})
    }}
  >{({
       handleSubmit,
       handleChange,
       values,
       errors
     }) =>
    <Form
      noValidate
      onSubmit={handleSubmit}
      className="font-inter">
      <Form.Group className="pb-3" controlId="controlSelect">
        <Form.Control
          type="text"
          name="url"
          placeholder={TYPE_URL}
          value={values.url}
          isInvalid={!!errors.url}
          onChange={handleChange}
          required>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="pb-3">
        <Form.Control
          type="text"
          name="name"
          placeholder={FAVORITE_NAME}
          value={values.name}
          isInvalid={!!errors.name}
          onChange={handleChange}
          required>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <div className="submit-button">
          <Button
            type="submit"
            size="lg"
            variant='primary'
            className="font-size-14 font-weight-medium btn-custom">{ADD_FAVORITE}</Button>
        </div>
      </Form.Group>
    </Form>
  }
  </Formik>;
};
