import React, { useRef } from "react";
import { constants } from "../../constants";
import { DefiMultiChainDashboardState } from "../../../../models/defi-multichain-dashboard-model";
import { isEmpty } from "lodash";
import { EmptySearchState } from "../../../../components/utility/empty-search-state/view";
import { Loader } from "../../../../components/utility/loader/view";
import CustomScroll from "react-custom-scroll";
import InfiniteScroll from "react-infinite-scroller";
import { PopoverLayout } from "../../../../components/utility/popover/view";
import { uiActions } from "../../actions/uiActions";
import numeral from "numeral";
import "./styles.scss";
import { dataHelper } from "../../dataHelpers/dataHelper";

const {
  POOLS: { TABLE_POOLS_KEYS, CHAIN_NAME, PROTOCOL },
} = constants;
const SCROLL_CONTAINER = ".custom-scroll-bar .rcs-inner-container";

const renderTableHeader = () => {
  return (
    <div className="custom-table-row ">
      {TABLE_POOLS_KEYS.map((key, i) => (
        <div
          className="custom-header-pools custom-table-header-cell font-size-14 font-weight-medium"
          key={`header-${i}`}
        >
          {key}
        </div>
      ))}
    </div>
  );
};

const renderRow = (data, actions, state, index) => {
  const {
    pool: { reward_token, staked_token },
    reward_amount,
    reward_price,
    staked_amount,
    staked_price,
  } = data;

  const sumValue = Number(staked_price) + Number(reward_price);

  return (
    <div
      className="custom-scroll-bar custom-table-row margin-right table-body font-size-14 font-weight-regular"
    >
      <div className="custom-table-body-cell d-flex align-items-center">
        <PopoverLayout icon={CHAIN_NAME} message={CHAIN_NAME} title={false} />
       
      </div>
      <div className="custom-table-body-cell d-flex align-items-center">
        <div className="token">
          <div>{PROTOCOL}</div>
        </div>
       

      </div>
      <div className="custom-table-body-cell d-flex align-items-center">
       
        <PopoverLayout
          icon={reward_token.symbol}
          message={reward_token.symbol}
          title={false}
        />

        <div className="token">
          <div>{reward_token.symbol}</div>
        </div>
      </div>

      <div className="custom-table-body-cell d-flex align-items-center">
        <PopoverLayout
          icon={staked_token.symbol}
          message={staked_token.symbol}
          title={false}
        />
        <div className="token">
          <div>
            {staked_amount} {staked_token.symbol}
          </div>
          <div className="address-wrapped">${staked_price}</div>
        </div>
      </div>
      <div className="custom-table-body-cell d-flex align-items-center">
        <PopoverLayout
          icon={reward_token.symbol}
          message={reward_token.symbol}
          title={false}
        />
        <div className="token">
          <div>
            {reward_amount} {reward_token.symbol}
          </div>
          <div className="address-wrapped">${reward_price}</div>
        </div>
      </div>
      <div className="custom-table-body-cell d-flex align-items-center">
        <div className="token">
          <div className="address-wrapped">${sumValue.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
};

const renderLoader = () => {
  return (
    <div className="loader text-center" key={0}>
      <span>
        <i className="fa fa-refresh fa-spin"></i>
        <span className="ms-3">Loading...</span>
      </span>
    </div>
  );
};

const renderTableBody = (data, actions, state) =>
  data.map((d, i) => renderRow(d, actions, state, i));

export const TablePoolsTemplate = (props) => {
  const actions = DefiMultiChainDashboardState.useStoreActions(
    (actions) => actions
  );
  const state = DefiMultiChainDashboardState.useStoreState((state) => state);
  const data = dataHelper.formatArrayObject(
    state.pools.data,
    "staked_price",
    "reward_price"
  );
  const tableData = dataHelper.sortByValue(data, "sumValue");
  const ref = useRef();
  const loadFunc = (value) =>
    actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING]({
      page: value,
      state,
    });

  return (
    <div className="custom-table-wrapper">
      <div className="custom-table h-100" ref={ref}>
        {renderTableHeader()}
        <div className="scroll-container">
          {state.wallet.loader ? (
            <Loader size={"6x"} />
          ) : !isEmpty(tableData) ? (
            <CustomScroll
              heightRelativeToParent="100%"
              className="custom-scroll-bar"
            >
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadFunc}
                hasMore={state.pools.pagination.hasMore}
                loader={renderLoader()}
                useWindow={false}
                getScrollParent={() =>
                  ref.current?.querySelector(SCROLL_CONTAINER)
                }
              >
                {renderTableBody(tableData, actions, state)}
              </InfiniteScroll>
            </CustomScroll>
          ) : (
            <EmptySearchState />
          )}
        </div>
      </div>
    </div>
  );
};
