import React, { useEffect } from 'react';
import { AcademyState } from '../../../../models/academy-model';
import { Loader } from '../../../../components/utility/loader/view';
import Articles from './template/Articles';
import Sort from './template/Sort';
import NumArticlesAndTags from './template/NumArticlesAndTags';
import Header from './template/Header';
import Filter from './template/Filter';
import FullArticle from './template/FullArticle';
import './styles.scss';

const AcademyLayout = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  const isSetFullArticle = state.fullArticle;
  const selectedCategory = state.categories.selectedCategory;

  const isLoading = state.loader;

  useEffect(() => {
    actions.GET_PUBLIC_ARTICLES();
  }, [selectedCategory]);

  useEffect(() => {
    actions.GET_FILTERS();
  }, []);

  const showArticles = () => {
    return (
      <>
        <header>
          <div className="header-top">
            <Header />
            <Filter />
          </div>

          <div className="header-bottom">
            <NumArticlesAndTags />
            <Sort />
          </div>
        </header>
        <main>
          <Articles />
        </main>
      </>
    );
  };

  const showLoader = () => {
    return <Loader size="4x" />;
  };

  let articles;

  if (!isSetFullArticle) {
    articles = showArticles();
  } else {
    articles = <FullArticle />;
  }

  return (
    <div className={`academy-page ${isLoading ? 'isLoading' : null}`}>
      {/* {window.open("https://monoceros.gitbook.io/monoceros-academy/")}
        {window.location = '/'} */}

      {isLoading ? showLoader() : articles}
    </div>
  );
};

export const AcademyPage = () => {
  return (
    <AcademyState.Provider>
      <AcademyLayout />
    </AcademyState.Provider>
  );
};
