import { action } from 'easy-peasy';

const actionTypes = {
    NEWS_DATA_INVOKED: 'NEWS_DATA#INVOKED'
};

const dataHandle = (state, payload) => {
    state.data = payload;
    console.log('payload: ', payload);
};

const actionHandlers = {
    [actionTypes.NEWS_DATA_INVOKED]: action((state, payload) => dataHandle(state, payload))
};

export const newsPageEffects = {
    actionTypes,
    actionHandlers
};