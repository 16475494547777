import manifest from 'cryptocurrency-icons/manifest.json';

export const constants = {
  CONTROLS: {
    LABEL_SINGLE_PAIR: 'Single Pair',
    LABEL_PACKS: 'Packs',
    LABEL_SIGNALS: 'Signals',
    LABEL_FAVORITES: 'Favorites',
    LABEL_WEIGHT_EQUAL: 'Equal',
    LABEL_WEIGHT_CUSTOM: 'Custom weight',
    LABEL_EDIT: 'Edit',
    LABEL_CANCEL: 'Cancel',
    LABEL_SAVE: 'Save',
    LABEL_ADD_NEW_PACK: 'Add new pack',
    LABEL_ADD_PAIR: 'Add pair',
    LABEL_MAIN_COIN: 'main coin',
    LABEL_PAIRS_SIZE: 'number of pairs',
    LABEL_EXCHANGES: 'Account/exchange',
    LABEL_COIN_PAIR: 'Coin pair',
    LABEL_COIN_MAIN: 'Main coin',
    LABEL_MULTI_CHART: 'Multichart',
  },
  PACKS: {
    ADD_LABEL: 'Add pack',
    SHOW_MORE: 'Show more',
    SHOW_LESS: 'Show less',
    PACKS_LIMIT: 6,
    PACKS_FACTORY: {
      PACK_ADD: {
        url: '/pack',
        method: 'put',
      },
      PACK_DELETE: {
        url: '/pack',
        method: 'DELETE',
      },
      PAIR_UPDATE: {
        url: '/pack_update',
        method: 'PUT',
      },
      PAIR_DELETE: {
        url: '/pack_update',
        method: 'DELETE',
      },
      BOT_CREATE: {
        url: '/binance_prod/stqa',
        method: 'POST',
      },
      TICKER: {
        url: '/binance/ticker',
        method: 'POST',
      },
    },
  },
  LIST_ICONS: manifest,
  INPUT_LABELS: {
    PACK_NAME: 'Pack name',
    PACK_NAME_PLACEHOLDER: 'Type pack name',
    MAIN_COIN: 'Main coin',
    PAIR_COINS: 'Pair coins',
  },
  TABLE_HEADERS_KEYS: ['Pair', 'Start', 'Volume', 'Profit', 'Status', 'PNL'],
  TABLE_HEADERS_ACTIVE_KEYS: ['', 'Pair', 'Creation Date', 'Volume', 'Status', 'Profit/Loss'],
  // TABLE_HISTORY_KEYS: ['', 'Tx', 'Interactions', 'Transfer', 'Gas price', 'Gas fee'],
  TABLE_HISTORY_KEYS: [
    'Coin',
    'Side',
    'Size',
    'Order Type',
    'Order Price',
    'Triger Price',
    'Order Option',
    'Creation date',
  ],
  OPEN_ORDERS_DATA: {
    NO_ORDERS_TITLE: 'There is no open orders',
    BUTTON_OPEN_ORDER: 'Open orders',
    BUTTON_OPEN_POSITIONS: 'Open positions',
  },
  TABLE_ORDERS_DATA: [
    'Pair ↑↓',
    'Volume ↑↓',
    'Side ↑↓',
    'Type',
    'Source',
    'Creation Date',
    'Actions',
  ],
  TABLE_POSITIONS_DATA: ['Pair ↑↓', 'Side', 'Leverage ↑↓', 'Size', 'Source', 'Actions'],
  TABLE_HEADERS_KEYS: ['', 'Pair', 'Start', 'Volume', 'Status', 'PNL'],
  TABLE_HEADERS: {
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add-funds', label: 'Add Funds', icon: 'fa-plus' },
        { id: 'reduce-funds', label: 'Reduce Funds', icon: 'fa-minus' },
        { id: 'close', label: 'Close at Market Price', icon: 'fa-times' },
        { id: 'cancel', label: 'Cancel', icon: 'fa-hand' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
      icon: '',
    },
  },
  TABLE_PARAMS_AND_PROFITS: {
    GRID_PARAMS_TITLE: 'Grid Params',
    DCA_PARAMS_TITLE: 'DCA Params',
    PROFITS_TITLE: 'Profits',
    UPPER_LIMIT_PRICE: 'Upper Limit price',
    LOWER_LIMIT_PRICE: 'Lower Limit price',
    QUANTITY_PER_GRID: 'Quantity per grid',
    GRID_WIDTH: 'Grid width',
    ACRIVE_ORDERS: 'Active Orders',
    INVESTMENT: 'Investment',
    OPTIONS: 'Options:',
    CONDITION: 'Condition',
    BTN_RECENT_TRANSACTION: 'Recent transactions',
  },
  TABLE_HISTORY: {
    FILTERS: {
      MAIN_TITLE: 'FILTERS',
      BTN_FILTERS: 'Clear Filters',
      LABEL_CREATED: 'Created on',
      LABEL_CLOSED: 'Closed on',
      LABEL_ACCOUNT: 'Account',
      LABEL_PAIR: 'Pair',
      THUMB_CANCELED: 'Hide Canceled',
      THUMB_FINSHHED: 'Hide Finished',
      THUMB_FAILED: 'Show Failed',
      THUMB_SIMPLETRADE: 'Show SimpleTrade',
      BTN_EXPORT: 'Export',
    },
    TABLE: {
      LABEL_FILLED: 'Filled',
      LABEL_TIMEOUT: 'Timeout',
      LABEL_TRAILING: 'Trailing',
      LABEL_TIME: 'Today',
      LABEL_ID: 'ID',
    },
  },
  LEVERAGE_LIST: [
    {
      value: 'ISOLATED',
      label: 'ISOLATED',
      name: 'leverage_selected',
    },
    {
      value: 'CROSSED',
      label: 'CROSSED',
      name: 'leverage_selected',
    },
  ],
  MARKS: {
    LONG: {
      0: '0',
      5: '5%',
      10: '10%',
      25: '25%',
      50: '50%',
      100: '100%',
    },
    SHORT: {
      50: '-50%',
    },
    DELAY: {
      0: '0 sec',
      50: '3 000 sec',
    },
    LEVERAGE: {
      0: '1x',
      10: '10x',
      20: '20x',
      30: '30x',
      40: '40x',
      50: '50x',
      60: '60x',
      70: '70x',
      80: '80x',
      90: '90x',
      100: '100x',
    },
  },
  FORM_LABELS: {
    AMOUNT: 'AMOUNT',
    BALANCE: 'balance',
    PRICE: 'Price',
    TRAILING_BUY: 'Trailing buy',
    TOTAL: 'TOTAL',
    SIZE_FROM_AVAILABLE_AMOUNT: 'Size from available amount',
    TAKE_PROFIT: 'TAKE PROFIT',
    SMALL_TABLE: {
      PRICE: 'Price',
      QUANTITY: 'Quantity',
      TP: 'TP',
    },
    VOLUME: 'Volume',
    TRAILING_TAKE_PROFIT: 'Trailing Take Profit',
    FOLLOW_MAX_PRICE_WITH_DEVIATION: 'Follow max price with deviation',
    STOP_LOSS: 'STOP LOSS',
    STOP_LOSS_TIMEOUT: 'Stop Loss timeout',
    TRAILING_STOP_LOSS: 'Trailing Stop Loss',
    MOVE_TO_BREAKEVEN: 'Move to Breakeven',
    AVAILABLE_AMOUNT: 'Available Amount',
    WITH_LEVERAGE: 'With Leverage',
    CURRENT_LEVERAGE: 'Current Leverage',
    TOTAL_QUOTE_CURRENCY: 'Total quote currency',
    TOTAL_BASE_CURRENCY: 'Total base currency',
    TRADE_NOT_MEET_REQUIREMENTS: 'Trade does not meet minimum requirements',
  },
  TABLE_OPTIONS: {
    LABEL: 'table option',
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Element #1' },
        { id: 'update', label: 'Element #2' },
        { id: 'edit', label: 'Element #3' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  TABLE_COLLAPSE_CONTENT: {
    TRIGGER: 'Trigger price',
    INITIAL_POSITION: 'Initial position',
    TTP: 'TTP Condition',
    STOP_LOSS: 'Trailing Stop Loss Condition',
    STEPS: {
      BUY_STEPS_LABEL: 'Buy steps',
      SELL_STEPS_LABEL: 'Sell steps',
      STEPS_PRICE: 'Price',
      STEPS_VOLUME: 'Volume',
      STEPS_STATUS: 'Status',
      STEPS_ACTIONS: 'Actions',
      STATUS_FINISHED: 'Finished',
    },
  },
  DEFAULT_PAIR: {
    id: 'BTC',
    mainCoin: 'USDT',
    isSinglePair: true,
    selectedPack: {
      mainCoin: 'USDT',
      weight: false,
    },
    exchangeData: {
      value: {
        label: 'Binance',
        value: {
          accountId: '56988c21-b4c1-5ff3-9f1c-0394da7a2d92',
          name: 'Binance',
          api: 'binance_prod',
          type: 'SPOT',
        },
      },
    },
  },

  MODALS: {
    SURE_TO_CANCEL_TRADE: 'Are you sure you want to cancel the trade',
    THIS_ACTION_WILL: 'This action will',
    CANCEL_TRADE: 'Cancel the trade',
    CANCEL_UNFILLED_ORDERS: 'Cancel unfilled orders',
    BUYS_SELLS_ALREADY_FILLED_ORDERS:
      'Buys and sells for already filled orders will be saved on your exchange account',
    CONFIRM: 'Confirm',
    GO_BACK: 'Go Back',
    CLOSE_CURRENT_POSITION: 'Close the current position at market price',
    PROFIT: 'Profit',
    DONT_SHOW_AGAIN: 'Don’t show this message again',
    SURE_TO_CLOSE_TRADE: 'Are you sure you want to close the trade at Market Price',
    ADD_FUNDS: 'ADD FUNDS',
    REDUCE_FUNDS: 'REDUCE FUNDS',
    DISCARD: 'Discard',
    SAVE: 'Save',
  },
  PRICE_LIST: [
    {
      value: 'Limit',
      label: 'Limit',
      name: 'price_selected',
    },
    {
      value: 'Market',
      label: 'Market',
      name: 'price_selected',
    },
  ],
  ORDER_BOOK: {
    askPrices: [
      { price: '34841.25', total: '102.5', amount: '0.25', width: '75%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '70%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '65%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '65%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '60%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '55%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '55%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '50%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '45%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '40%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '30%' },
    ],
    bidPrices: [
      { price: '34841.25', total: '102.5', amount: '0.25', width: '40%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '45%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '50%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '60%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '60%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '65%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '65%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '75%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '78%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '80%' },
      { price: '34841.25', total: '102.5', amount: '0.25', width: '85%' },
    ],
    LENGTH_SELECT: [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
    ],
    PRICE: 'PRICE',
    TOTAL: 'TOTAL',
    AMOUNT: 'AMOUNT',
  },

  GRID_BOT: {
    GRID_PROFIT: 'GRID PROFIT',
    UPPER_LIMIT_PRICE: 'Upper limit price',
    UPPER_STOP_LOSE: 'Upper stop lose',
    LOWER_LIMIT_PRICE: 'Lower limit price',
    LOWER_STOP_LOSE: 'Lower stop lose',
    GRID_QUANTITY: 'Grid quantity',
    ACTIVE_ORDERS: 'Active Orders',
    QUANTITY_PER_GRID: 'Quantity per grid',
    AMOUNT_FOR_BOT_USAGE: 'Amount for bot usage',
  },

  DCA_BOT: {
    COST_AVEREAGE: 'COST AVERAGE',
    TYPE: 'Type',
    BOTT_WILL_BUY: 'Bot will buy',
    FOR: 'For',
    EVERY: 'Every',
    AT: 'At',
    NEXT_BUY_DATE: 'Next buy date',
    BY: 'By',
    BY_TYPES: [
      {
        title: 'test1',
      },
      {
        title: 'Test2',
      },
    ],
  },
  SHARE_ORDER_RESULT: {
    MONOCEROS: 'Monoceros',
    FUTURES: 'FUTURES',
    LONG: 'Long',
    SHORT: 'Short',
    LEVERAGE: 'Leverage',
    ENTRY_PRICE: 'Entry Price',
    LAST_PRICE: 'Last Price',
    NET_PROFIT: 'Net profit',
    MONOCEROS_URL: 'www.monoceros.app',
    CANCEL: 'Cancel',
    SHARE: 'Share',
    DOWNLOAD: 'Download',
  },
};
