import React from 'react';
import { Button } from 'react-bootstrap';

export const renderEventsControlContent = (state, onClickHandler) => {
  return (
    <div className="content-controls" style={{ paddingLeft: '5%', marginBottom: '20px' }}>
      <Button
        className="btn-link"
        variant="link"
        size="sm"
        name="button_events_highlights"
        active={state.viewType.highlights}
        onClick={onClickHandler}
        style={
          state.viewType.highlights ? { textDecoration: 'underline' } : { textDecoration: 'none' }
        }
      >
        Highlights
      </Button>

      <Button
        className="btn-link"
        variant="link"
        size="sm"
        name="button_events_upcoming"
        active={state.viewType.upcoming_events}
        onClick={onClickHandler}
        style={
          state.viewType.upcoming_events
            ? { textDecoration: 'underline' }
            : { textDecoration: 'none' }
        }
      >
        Upcoming Events
      </Button>

      <Button
        className="btn-link"
        variant="link"
        size="sm"
        name="button_events_past"
        active={state.viewType.past_events}
        onClick={onClickHandler}
        style={
          state.viewType.past_events ? { textDecoration: 'underline' } : { textDecoration: 'none' }
        }
      >
        Past Events
      </Button>
    </div>
  );
};
