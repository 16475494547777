import React from 'react';

export const TRADE_DICTUM = <>
    <path d="M47.6803 79.0394L90.6718 1.22559H2L47.6803 79.0394Z" stroke="url(#paint0_linear_107_2644)" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M45.0557 1.10938L8.02026 78.9232H90.736L45.0557 1.10938Z" stroke="#B8B6B6" strokeMiterlimit="10"/>
    <path d="M45.2806 0.70715L6.57666 39.4111L48 79.5L84.6507 40.0772L45.2806 0.70715Z" stroke="#B8B6B6" strokeDasharray="15 15"/>
    <defs>
        <linearGradient id="paint0_linear_107_2644" x1="81.8987" y1="91.2272" x2="-26.4032" y2="85.4214" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1876FF"/>
            <stop offset="0.286458" stopColor="#0BAFFF"/>
            <stop offset="1" stopColor="#05CDF9"/>
        </linearGradient>
    </defs>
</>;