import React from 'react'
import { useStoreActions } from 'easy-peasy';
import CustomScroll from 'react-custom-scroll';
import { BorderedButton } from '../utility/bordered-button/view'

import './styles.scss'
import {constants} from './constants'

const {TEXT, BACK} = constants.TERMS_OF_USE;

const termsView = () => {
    const toggleTerms = useStoreActions(actions => actions.LOGIN_TERMS_TOGGLE);

    const handleBack = (e) => {
        e.preventDefault();

        toggleTerms();
    }

    return (
        <div className="terms">
            <CustomScroll heightRelativeToParent="100%" >
                <div className="text">
                    {TEXT}
                </div>
            </CustomScroll>
            <div className="footer">
                <a onClick={handleBack} href="" >
                    <BorderedButton >
                        {BACK}
                    </BorderedButton>
                </a>
            </div>
        </div> 
    )
}

export default termsView