import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { Button, Modal } from 'react-bootstrap';
import { tableEffects } from '../../actions/tableEffects';
import { constants } from '../../constants';

const {
    MODALS: {
        SURE_TO_CANCEL_TRADE,
        THIS_ACTION_WILL,
        CANCEL_TRADE,
        CANCEL_UNFILLED_ORDERS,
        BUYS_SELLS_ALREADY_FILLED_ORDERS,
        CONFIRM,
        GO_BACK
    }
} = constants;

export const CancelTradeModal = props => {
    const { show } = props;
    const actions = useStoreActions(actions => actions);

    const handleClose = () => actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'close-modal', modal: 'cancel' });

    return <Modal
        show={show}
        size="md"
        centered
        className="trade-funds"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Header>
            <div className="cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel fa-lg"></i>
            </div>
        </Modal.Header>
        <Modal.Body>
            <p className="title">{SURE_TO_CANCEL_TRADE}?</p>
            <div className="font-inter">
                <div>
                    <p className="m-0">{THIS_ACTION_WILL}:</p>
                    <ul>
                        <li>{CANCEL_TRADE}</li>
                        <li>{CANCEL_UNFILLED_ORDERS}</li>
                        <li>{BUYS_SELLS_ALREADY_FILLED_ORDERS}!</li>
                    </ul>
                </div>
                <div className="submit-button pt-3">
                    <Button
                        type="button"
                        size="lg"
                        className="font-size-14 font-weight-medium confirm">{CONFIRM}</Button>
                    <Button
                        onClick={handleClose}
                        type="button"
                        size="lg"
                        className="font-size-14 font-weight-medium back">{GO_BACK}</Button>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
};
