import React from 'react';

export const RIGHT_TOP_CHART = <><path d="M80.5876 41.1116C82.3421 41.1116 83.7644 39.6893 83.7644 37.9348C83.7644 36.1803 82.3421 34.7581 80.5876 34.7581C78.8332
 34.7581 77.4109 36.1803 77.4109 37.9348C77.4109 39.6893 78.8332 41.1116 80.5876 41.1116Z" fill="url(#paint0_radial_107_2243)"/>
<path d="M185.874 13.8823C187.628 13.8823 189.051 12.46 189.051 10.7056C189.051 8.95109 187.628
 7.52881 185.874 7.52881C184.12 7.52881 182.697 8.95109 182.697 10.7056C182.697 12.46 184.12 13.8823 185.874 13.8823Z" fill="url(#paint1_radial_107_2243)"/>
<path d="M291.161 30.2197C292.915 30.2197 294.338 28.7974 294.338 27.043C294.338 25.2885 292.915
 23.8662 291.161 23.8662C289.407 23.8662 287.984 25.2885 287.984 27.043C287.984 28.7974 289.407 30.2197 291.161 30.2197Z" fill="url(#paint2_radial_107_2243)"/>
<path d="M338.358 6.62108C340.113 6.62108 341.535 5.1988 341.535 3.44433C341.535 1.68986 340.113
 0.267578 338.358 0.267578C336.604 0.267578 335.181 1.68986 335.181 3.44433C335.181 5.1988 336.604 6.62108 338.358 6.62108Z" fill="url(#paint3_radial_107_2243)"/>
<path d="M31.5748 66.5256C35.8357 66.5256 39.2898 63.0715 39.2898 58.8107C39.2898 54.5498 35.8357
 51.0957 31.5748 51.0957C27.314 51.0957 23.8599 54.5498 23.8599 58.8107C23.8599 63.0715 27.314 66.5256 31.5748 66.5256Z" fill="url(#paint4_radial_107_2243)"/>
<path d="M143.215 79.2324C147.476 79.2324 150.93 75.7783 150.93 71.5175C150.93 67.2566 147.476
 63.8025 143.215 63.8025C138.954 63.8025 135.5 67.2566 135.5 71.5175C135.5 75.7783 138.954 79.2324 143.215 79.2324Z" fill="url(#paint5_radial_107_2243)"/>
<path d="M392.817 25.6816C397.078 25.6816 400.532 22.2275 400.532 17.9667C400.532 13.7058 397.078
 10.2517 392.817 10.2517C388.556 10.2517 385.102 13.7058 385.102 17.9667C385.102 22.2275 388.556 25.6816 392.817 25.6816Z" fill="url(#paint6_radial_107_2243)"/>
<path d="M0.5 51L31.5798 58.4L79.6807 38.4533L143.21 72L185.866 10.3467L291.143 27.5733L337.429 4L392.79 18.5067H557" stroke="url(#paint7_linear_107_2243)" strokeWidth="2"/>
<defs>
<radialGradient id="paint0_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(80.5876 37.9348) rotate(90) scale(3.17675)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint1_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(185.874 10.7056) rotate(90) scale(3.17675)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(291.161 27.043) rotate(90) scale(3.17675 3.17674)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(338.358 3.44433) rotate(90) scale(3.17675 3.17676)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint4_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.5748 58.8107) rotate(90) scale(7.71497 7.71497)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint5_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(143.215 71.5175) rotate(90) scale(7.71497 7.71497)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint6_radial_107_2243" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(392.817 17.9667) rotate(90) scale(7.71497 7.71498)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<linearGradient id="paint7_linear_107_2243" x1="72.7677" y1="-6.65061" x2="572.308" y2="294.964" gradientUnits="userSpaceOnUse">
<stop stopColor="#1876FF"/>
<stop offset="0.286458" stopColor="#0BAFFF"/>
<stop offset="0.604167" stopColor="#05CDF9"/>
<stop offset="0.942708" stopColor="#01E9BB"/>
</linearGradient>
</defs>
</>;