import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Frame } from '../utility/frame/view';
import { constants } from '../../constants/constants';
import { utils } from '../../utils/utils';
import { StandardOptions } from '../utility/standard-options/view';
import { Collapse, Button } from 'react-bootstrap';
import { dataHelper } from './dataHelper';
import { favoritesEffects } from './favoritesEffects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { AddFavoritesModal } from './addFavoritesModalTemplate';
import { CryptoIcon } from '../utility/crypto-icon/view';


const { FAVORITES } = constants;

const renderControlButtons = (actions, state, limit) => {
    const isEditMode = state.isEditMode;
    const isDisabled = !state.deletedBuffer.length;
    const rowClass = limit > 1 ? 'flex-row justify-content-end' : 'flex-column justify-items-center ms-3';

    return <>
        { !isEditMode ? <div className={`d-flex ${rowClass} not-edit-mode`}>
            <Button size="sm" className="favorites-action-button mb-3 me-3" variant="light" onClick={() => actions[favoritesEffects.actionTypes.FAVORITES_ACTION_MORE_CHANGED]({ value: FAVORITES.STANDARD_OPTIONS_SCHEMA.list[2] })}>Edit</Button>
            <Button size="sm" className="favorites-action-button mb-3 me-3" variant="light" onClick={() => actions[favoritesEffects.actionTypes.FAVORITES_ACTION_MODAL_OPENED]({ value: FAVORITES.STANDARD_OPTIONS_SCHEMA.list[0] })}>Add</Button>
            <Button size="sm" className="favorites-action-button me-3 mb-3" variant="light" onClick={() => actions[favoritesEffects.actionTypes.FAVORITES_ACTION_MORE_CHANGED]({ value : FAVORITES.STANDARD_OPTIONS_SCHEMA.list[1] })}>Update</Button>
        </div> : <div className={'d-flex flex-row justify-content-end edit-mode'}>
            <Button size="sm" className="favorites-action-button ms-2 me-3 mb-3" variant="light" onClick={() => actions[favoritesEffects.actionTypes.FAVORITES_ACTION_EDIT_CANCELED]()}>Cancel</Button>
            <Button size="sm" className="favorites-action-button me-2 mb-3" variant="light"
                disabled={isDisabled}
                onClick={() => favoritesEffects.uiAction(actions, 'save', state.deletedBuffer)}
            >Save</Button>
        </div>
        }
    </>;
};

const renderRemoveSign = (actions, v, editModeClass, buffer) => {
    const isMark = buffer.some(b => b.toLowerCase() === v.id);
    const markClass = isMark ? 'mark-for-delete' : '';
    return v.id === 'btc' || v.id === 'eth' || editModeClass !== 'edit-mode' ?
        null :
        <div className={`remove-sign  d-flex justify-content-center cursor-pointer ${editModeClass} ${markClass}`}
            onClick={() => !isMark ?
                actions[favoritesEffects.actionTypes.FAVORITES_ACTION_ASSET_MARKED](v.id.toUpperCase()) :
                actions[favoritesEffects.actionTypes.FAVORITES_ACTION_ASSET_UNMARKED](v.id.toUpperCase())}>
            <FontAwesomeIcon fixedWidth icon={faTimes} size='1x'/>
        </div>;
};

const renderCard = (favoritesState, favorites, limit, actions, buffer) => {
    return favorites.map((v, i, s) => {
        const changesClass = v.change ? v.change > 0 ? 'positive' : v.change < 0 ? 'negative' : '' : '';
        const tickerPrice = v.id === 'usdt' ? '1.00 $' : v.price;
        const editModeClass = favoritesState.value.id === 'edit' ? 'edit-mode' : '';
        return <div key={i} className="d-flex flex-column justify-content-center favorites-cell position-relative">
            <div className="remove-sign-container">
                { renderRemoveSign(actions, v, editModeClass, buffer) }
            </div>
            <div className="d-flex flex-row justify-content-between position-relative favorites-card-body">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-start">
                        <div className="d-flex flex-column justify-content-center favorites-column favorites-img">
                            <CryptoIcon id={v.id} width={32} height={32}/>
                        </div>
                        <div className="d-flex flex-column justify-content-start favorites-column">
                            <div className="d-flex font-size-12 font-weight-medium">{tickerPrice}</div>
                            <div className="d-flex">
                                <span className="font-size-9 me-1 opacity-80">{`${v.label}`}</span>
                                <span className={`changes font-size-9 ${changesClass}`}>{`${v.change} %`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${s.length - 1 === i || i >= limit - 1 ? '' : 'd-flex flex-column vertical-divider'}`}></div>
            </div>

        </div>;
    }).splice(0, limit);
};

const renderInvisibleCards = (favoritesState, favorites, actions, buffer, limit) => {
    const editModeClass = favoritesState.value.id === 'edit' ? 'edit-mode' : '';
    return favorites.map((v, i) => {
        const iterator = (i + 1) % limit;
        const changesClass = v.change ? v.change > 0 ? 'positive' : v.change < 0 ? 'negative' : '' : '';
        const borderClass = iterator || !i ? '' : 'border-right-none';
        return <div key={i} className={`favorites-grid-element position-relative ${borderClass}`}>
            <div className="d-flex flex-column justify-content-center favorites-cell pt-2 pb-2">
                <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row justify-content-start">
                            <div className="d-flex flex-column justify-content-center favorites-column favorites-img">
                                <img src={`../coins-library/${v.id}.svg`}
                                    alt=""
                                    onError={utils.imgErrorHandle}
                                    className="img-fluid rounded-start"/>
                            </div>
                            <div className="d-flex flex-column justify-content-start favorites-column">
                                <div className="d-flex font-size-12 font-weight-medium">{`${v.price}`}</div>
                                <div className="d-flex">
                                    <span className="font-size-9 me-1 opacity-80">{`${v.label}`}</span>
                                    <span className={`changes font-size-9 ${changesClass}`}>{`${v.change} %`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { renderRemoveSign(actions, v, editModeClass, buffer) }
        </div>;
    });
};

const renderCollapse = (favoritesState, collapsiblePart, actions, open, buffer, limit) => {
    return <Collapse in={open}>
        <div className="collapse-content">
            <div className="favorites-grid-container favorites-card">
                {renderInvisibleCards(favoritesState, collapsiblePart, actions, buffer, limit)}
            </div>
            <div className="control-bar pt-3">
                {renderControlButtons(actions, favoritesState, limit)}
            </div>
        </div>
    </Collapse>;
};

const renderToggleButton = (collapsiblePart, open, actions) => {
    const icon = open ? faChevronUp : faChevronDown;
    return <div className="font-size-10 cursor-pointer text-center favorites-toggle-button"
        onClick={() => favoritesEffects.uiAction(actions, 'collapse_toggle', open)}>
        <span className="favorites-close-collapse">
            <FontAwesomeIcon icon={icon}/>
        </span>
    </div>;
};

const FavoritesContent = props => {
    const { favorites, limit, collapsiblePart } = props;
    const favoritesState = useStoreState(state => state.favoritesMenu);
    const actions = useStoreActions(actions => actions);
    const open = favoritesState.collapseState;
    const buffer = favoritesState.deletedBuffer;
    return <>
        <div className="d-flex flex-row justify-content-start favorites-card pt-1">
            <div className="d-flex flex-row">
                { renderCard(favoritesState, favorites, limit, actions, buffer) }
            </div>
        </div>
        { renderCollapse(favoritesState, collapsiblePart, actions, open, buffer, limit) }
    </>;
};



export const Favorites = () => {
    const state = useStoreState(state => state.coinData);
    const sizes = useStoreState(state => state.sizes);
    const favoritesState = useStoreState(state => state.favoritesMenu);
    const actions = useStoreActions(actions => actions);
    const width = sizes.headerWidth;
    const limit = Math.max(Math.round((width - 410) / 128) - 1, 1); //TODO made this calculation smarter
    const coins = dataHelper.getFavoritesData(state);
    const coinsVisiblePart = coins.slice(0, limit);
    const collapsiblePart = coins.slice(limit, coins.length);
    const open = favoritesState.collapseState;
    const ref = useRef();

    useEffect(() => {
        const container = ref.current;
        const target = document.getElementById('root');
        const listener = e => !container.contains(e.target) ?
            actions[favoritesEffects.actionTypes.FAVORITES_ACTION_COLLAPSE_TOGGLED](true) : '';

        favoritesState.collapseState ? target.addEventListener('click', listener, false) : '';

        return () => {
            target.removeEventListener('click', listener, false);
        };
    }, [favoritesState.collapseState]);

    return <div className="d-flex flex-row favorites justify-content-between justify-content-sm-start w-100" ref={ref}>
        <Frame
            content={<FavoritesContent favorites={coinsVisiblePart} collapsiblePart={collapsiblePart} limit={limit} />}
            label={FAVORITES.LABEL}
            fontSize={12}
            fontWeight={500}
            borderRadius={4}
        />
        <div className="d-flex flex-column justify-content-between align-items-center favorites-controls">
            <div className="standard-options-content">
                <StandardOptions
                    more={{ ...FAVORITES.STANDARD_OPTIONS_SCHEMA, value: favoritesState.value, actionName: favoritesEffects.actionTypes.FAVORITES_ACTION_MORE_CHANGED }}
                />
            </div>
            { renderToggleButton(collapsiblePart, open, actions) }
        </div>
        <AddFavoritesModal/>
    </div>;
};
