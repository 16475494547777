import React from 'react';
import './styles.scss';
import { routing } from '../../routing/routing-mapping';
import { LogOut } from '../../pages/logOutPage/view';
import { Page404 } from '../../pages/404/view';
import { useLocation, useParams } from 'react-router-dom';


const { NAVIGATION_MAPPING } = routing;
const PAGE_404 = () => <Page404/>;

const getContent = (id, menuId) => {
    const { content } = menuId && id && NAVIGATION_MAPPING[id] && NAVIGATION_MAPPING[id].list ?
        NAVIGATION_MAPPING[id].list.find(el => el.id === menuId) || { content: PAGE_404 } : { content: PAGE_404 };
    return content();
};

export const Placeholder = () => {
    const { search } = useLocation();
    const { id } = useParams();
    const menuId = search.replace('?elem=', '');
    switch (id) {
    case 'logout': return <LogOut/>;
    default: return getContent(id, menuId);
    }
};