import { textwrap } from 'd3-textwrap';

const drawTableContent = (node, content) => {
    const { CREATE, LAUNCH, USE, GET } = content;
    
    node
        .selectAll('g.trade-table')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'trade-table');

    node
        .select('g.trade-table')
        .append('text')
        .attr('y', 280)
        .attr('x', 153)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '25px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${CREATE}`);

    node
        .select('g.trade-table')
        .append('text')
        .attr('y', 280)
        .attr('x', 460)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '25px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${LAUNCH}`);

    node
        .select('g.trade-table')
        .append('text')
        .attr('y', 280)
        .attr('x', 767)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '25px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${USE}`);

    node
        .select('g.trade-table')
        .append('text')
        .attr('y', 280)
        .attr('x', 1075)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '25px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${GET}`);

};

const drawBg = (node, content) => {
    const { DESCRIPTION } = content;
    
    node
        .selectAll('g.trade-table')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'trade-table');

    node
        .select('g.trade-table')
        .append('text')
        .attr('y', 0)
        .attr('x', 285)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '20px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${DESCRIPTION}`)
        .call(wrap);

};

const wrap = textwrap().bounds({ height: 100, width: 500 });

export const drawHelper = {
    drawTableContent,
    drawBg
};

