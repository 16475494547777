import { constants } from '../../../constants/constants';
import { dataHelper } from '../../../pages/dashboard/widgets/bots-widget/dataHelper';
import { exchangesDataHelper } from './exchangesDataHelper';
import { priceDataHelper } from './priceDataHelper';
import { uniq } from 'lodash';
import { pollerEffects } from '../pollerEffects';

const { REFRESH_API, WEBSOCKET_PARAMS_LIMIT, FAVORITES: { preinstalledCoins } } = constants;

const getRequestParams = payload => {
    const { type } = payload;
    if (!REFRESH_API[type])
        return null;
    return { ...REFRESH_API[type], ...payload };
};

const tableHelper = (state, response) => {
    const favoriteAssets = state.coinData.favorites;
    const filteredExchanges = exchangesDataHelper.filteringExchanges(response.exchanges); //Remove coins with amount 0 exept BTC and USDT
    const exchangesWithOldTotals = exchangesDataHelper.getOldTotals(filteredExchanges);
    const { updatedExchanges, usedCoins } = exchangesDataHelper.updateExchanges({ ...exchangesWithOldTotals });
    const limitedExchangesUsedCoins = usedCoins.splice(0, WEBSOCKET_PARAMS_LIMIT);
    const coins = uniq([...favoriteAssets, ...limitedExchangesUsedCoins]); //Array
    const params = priceDataHelper.getPairsForRequest(coins);
    state.coinData.exchanges = updatedExchanges; //Object
    state.coinData.exchangesAllUsedCoins = usedCoins;
    state.coinData.limitedExchangeUsedCoins = limitedExchangesUsedCoins;
    state.coinData.requestParams = params;
    const { sendJsonMessage } = state.socket.methods;
    pollerEffects.pollerStart(params, sendJsonMessage); // reinitiate poller for websocket
};

const favoritesHelper = (state, response) => {
    const limitedExchangesUsedCoins = state.coinData.limitedExchangeUsedCoins;
    const favoriteAssets = uniq([...preinstalledCoins, ...response.favoriteAssets]);
    const coins = uniq([...favoriteAssets, ...limitedExchangesUsedCoins]);
    const params = priceDataHelper.getPairsForRequest(coins);
    state.coinData.requestParams = params;
    state.coinData.favorites = favoriteAssets;
    const { sendJsonMessage } = state.socket.methods;
    pollerEffects.pollerStart(params, sendJsonMessage); // re initiate poller for websocket
};

const messageHelper = (state, response) => {
    state.messages.messageData = response.messages;
};

const refreshHandle = (state, response, options) => {
    const { type } = options;
    switch (type) {
    case 'bots':
        state.botsWidget.originalData = dataHelper.parseData(response.bots);
        state.botsWidget.mutableData = state.botsWidget.originalData;
        break;
    case 'feeds':
        state.feed.data = response.feeds;
        break;
    case 'signals':
        state.signal.data = response.signals;
        break;
    case 'table':
        tableHelper(state, response);
        break;
    case 'history':
        state.coinData.history = response.exchanges;
        break;
    case 'favorites':
        favoritesHelper(state, response);
        break;
    case 'messages':
        messageHelper(state, response);
        break;
    }
};



export const refreshHelper = {
    getRequestParams,
    refreshHandle,
};