import { constants } from '../../../../constants/constants';
import { orderBy, cloneDeep, isEmpty } from 'lodash';
import numeral from 'numeral';
import { utils } from '../../../../utils/utils';
import Big from 'big.js';

numeral.zeroFormat('N/A');
const { DONAT_CHART, TABLE_WIDGET } = constants;

const { COLORS, LIMIT } = DONAT_CHART;

const addColorData = data => {
    const { column, dir } = TABLE_WIDGET.initialSort;
    const size = COLORS.length;
    return orderBy(data, `${column}-N`, dir)
        .map((r, i) => {
            r.COLOR = i >= size ? COLORS[size - 1] : COLORS[i];
            return r;
        });
};

const calculateColumns = (data, currency) => {
    const updatedData = data.map(row => {
        row.PRICE = row[`PRICE-${currency}`];
        row['PRICE-N'] = row[`PRICE-${currency}-N`];
        row.TOTAL = row[`TOTAL-${currency}`];
        row['TOTAL-N'] = row[`TOTAL-${currency}-N`];
        row.SHARE = row[`SHARE-${currency}`];
        row['SHARE-N'] = row[`SHARE-${currency}-N`];
        return row;
    });
    return addColorData(updatedData);
};

const getValueOthers = (data, currency) => {

    const totalString = `TOTAL-${currency}`;
    const values = cloneDeep(data)
        .splice(0, LIMIT)
        .filter(e => !Big(e[totalString]).eq(0));

    if (isEmpty(values))
        return 0;

    return 100 - values.reduce((acc, v) => {
        return acc + v[`SHARE-${currency}-N`] || 0;
    }, 0);
};

const createChartData = (data, currency) => {
    const chartDataMask = Array(LIMIT).fill({});
    const valueOthers = getValueOthers(data, currency);
    return chartDataMask.reduce((acc, e, i) => {
        return [...acc, {
            name: i < LIMIT - 1 ? data[i] ? data[i].COIN : 'EMPTY' : 'OTHERS',
            value: i < LIMIT - 1 ? data[i] ? data[i][`SHARE-${currency}-N`] || 1 : 1 : valueOthers || 1,
            color: DONAT_CHART.COLORS[i],
        }];
    },[]);
};

const tableDataParser = (data, currency) => {
    const keys = Object.keys(data) || [];
    const filteredKeys = keys.length > 2 ? keys : keys.filter(k => k !== 'all_exchanges');
    const labels = [{ id: 'empty', name: '' } , ...TABLE_WIDGET.columns];
    return filteredKeys.reduce((acc, key) => {
        const dataset = orderBy(data[key].balances, [`SHARE-${currency}-N`], 'desc');
        const calculatedData = calculateColumns(dataset, currency);
        acc[key] = data[key];
        acc[key].chartData = createChartData(dataset, currency);
        acc[key].tableData = { rows: calculatedData, labels: labels };
        return acc;
    }, {});
};

const listCreate = exchanges => {
    const keys = Object.keys(exchanges);
    const filteredKeys = keys.length > 2 ? keys : keys.filter(k => k !== 'all_exchanges');
    const list = filteredKeys.map(key => {
        const exchange = exchanges[key];
        return { id: exchange.accountId, label: exchange.name };
    });
    return orderBy(list, 'label', 'asc');
};

const specialFormat = value => {
    const index = utils.howSmall(value);
    const multi = Math.pow(10, index + 1);
    return `0.0...0${numeral(value * multi).format('0')}`;

};

const parseSmallValues = value => {
    return value > 1 / 10000 || !value ? numeral(value).format('0,0.[0000]') : specialFormat(value);
};

const getTotalChange = (coin, currency) => { //TODO check calculation % of changes
    const oldTotalKey = `OLD-TOTAL-${currency}`;
    const totalKey = `TOTAL-${currency}`;
    const totalCurrent = coin[totalKey];
    const oldTotal = coin[oldTotalKey];
    const difference = Big(totalCurrent).minus(Big(oldTotal)).valueOf();
    const valueString = !Big(oldTotal).eq(0) ? Big(difference).div(Big(oldTotal)).valueOf() : '0';
    const valueNumber = Number(valueString);
    const sign = valueNumber === 0 ? '' : valueNumber > 0 ? '+' : '-';
    const formatString = `${sign}0.[00]%`;
    return {
        number: valueString,
        value: valueNumber,
        difference: difference,
        sign: sign,
        signClass: !sign ? '' : sign === '+' ? 'positive' : 'negative',
        display: numeral(valueNumber).format(formatString)
    };
};

const tableDataFormatting = tableData => {
    const { labels, rows } = tableData;
    const formattedRows = rows
        .filter(r => r['AMT-N']) //remove zero amount at all
        .map(r => {
            r['COIN-USDT-F'] = r['COIN'];
            r['COIN-BTC-F'] = r['COIN'];
            r['AMT-USDT-F'] = numeral(r['AMT-N']).format('0,0.[0000]');
            r['AMT-BTC-F'] = numeral(r['AMT-N']).format('0,0.[0000]');
            r['SHARE-USDT-F'] = numeral(r['SHARE-USDT-N'] / 100).format('+0.[00]%');
            r['SHARE-BTC-F'] = numeral(r['SHARE-BTC-N'] / 100).format('+0.[00]%');
            r['PRICE-USDT-F'] = parseSmallValues(r['PRICE-USDT-N']);
            r['PRICE-BTC-F'] = parseSmallValues(r['PRICE-BTC-N']);
            r['TOTAL-USDT-F'] = parseSmallValues(r['TOTAL-USDT-N']);
            r['TOTAL-BTC-F'] = parseSmallValues(r['TOTAL-BTC-N']);
            r['CHANGE-TOTAL-USDT'] = getTotalChange(r, 'USDT');
            r['CHANGE-TOTAL-BTC'] = getTotalChange(r, 'BTC');
            return r;
        });

    return { rows: formattedRows, labels: labels };
};

const amountVolume = (data, volume) => {
    const { rows, labels } = data;
    const filteredRows = volume ? rows.filter(r => Big(r['AMT-N']).times(Big(r['PRICE-USDT-N'])).gt(1)) : rows;
    return { rows: filteredRows, labels };
};

export const dataHelper = {
    tableDataParser,
    listCreate,
    tableDataFormatting,
    amountVolume
};