import React from 'react';
import Toggle from 'react-toggle';
import { controlsEffects } from '../actions/controlsEffect';
import { constants } from '../constants';

const {
  CONTROLS: { LABEL_MULTI_CHART },
} = constants;

export const MultichartControls = (props) => {
  const { state, actions } = props;
  return (
    <div className="smart-trade-controls-container-content ">
      <label className="customized-toggle d-flex flex-row">
        <span
          className="me-4 me-md-1 font-size-13 font-weight-500"
          style={{ fontFamily: 'Inter' }}
        >
          { LABEL_MULTI_CHART }
        </span>

        <Toggle
          icons={false}
          checked={state.controls.isMultichart}
          onChange={() =>
            actions[controlsEffects.actionTypes.SMART_TRADE_PAGE_CONTROLS_TOGGLED]({
              name: LABEL_MULTI_CHART,
            })
          }
        />
      </label>
    </div>
  );
};
