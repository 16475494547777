import React, { useEffect, useState } from 'react';
import { AcademyState } from '../../../../../models/academy-model';

import { constants } from '../constants';

const {
  ADMIN_ARTICLES: {
    updateFilters: { alert, btnDelete, btnUpdate },
  },
} = constants;

const UpdateFilters = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  let { tags, times, types } = state.filters;
  let { list: categories } = state.categories;

  const [isShowForm, setShowForm] = useState(false);
  const [editFilter, setEditFilter] = useState('');
  const [oldFilter, setOldFilter] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const isTag = showMessage && editFilter.type === 'tag';
  const isTime = showMessage && editFilter.type === 'time';
  const isCategory = showMessage && editFilter.type === 'category';
  const isType = showMessage && editFilter.type === 'type';

  useEffect(() => {
    actions.GET_FILTERS();
  }, []);

  const handleEditFilter = (filter) => {
    setEditFilter(filter.value);
    setOldFilter(filter);
    setShowForm(true);
  };

  const handleChange = (e) => {
    setEditFilter(e.target.value);
  };

  const handleUpdateFilter = (e) => {
    e.preventDefault();

    actions.UPDATE_FILTER({ oldValue: oldFilter, newValue: editFilter });
  };

  const showDeleteAlert = (filter) => {
    setEditFilter(filter);
    setShowMessage(true);
  };

  const handleDeleteFilter = () => {
    setShowMessage(false);
    actions.DELETE_FILTER(editFilter);
  };

  const handleTogglePublish = (filter, index) => {
    actions.UNPUBLISH_FILTER(filter, index);
  };

  const DeleteMesage = () => {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>{alert.text}</strong>
        <button
          onClick={() => setShowMessage(false)}
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
        <button onClick={handleDeleteFilter} className="btn btn-danger d-block mt-1">
          {btnDelete}
        </button>
      </div>
    );
  };

  const filterItem = (filter, index) => {
    return (
      <li key={index}>
        <span>{filter.label}</span>
        <div onClick={() => handleEditFilter(filter)} className="btn btn-outline-primary">
          {btnUpdate}
        </div>
        <div onClick={() => handleTogglePublish(filter)} className="btn btn-outline-warning">
          {filter.isPublished ? 'Unpublish' : 'Publish'}
        </div>
        <div onClick={() => showDeleteAlert(filter)} className="btn btn-outline-danger">
          {btnDelete}
        </div>
      </li>
    );
  };

  const updateFilterForm = () => {
    return (
      <form onSubmit={handleUpdateFilter}>
        <div>
          <label style={{ display: 'block' }}>Filter:</label>
          <input type="text" value={editFilter} onChange={handleChange} />
        </div>
        <div className="mt-2">
          <input type="submit" value="Update" className="btn btn-primary" />
        </div>
        <span onClick={() => setShowForm(false)} className="close-btn">
          X
        </span>
      </form>
    );
  };

  return (
    <div className="update-filters">
      {!isShowForm && (
        <div className="filter-list">
          <div className="tags">
            <h3>Tags</h3>

            <ul>{tags.map((tag, index) => filterItem(tag, index))}</ul>

            {isTag && DeleteMesage()}
          </div>

          <div className="types mt-5">
            <h3>Types</h3>

            <ul>{types.map((type, index) => filterItem(type, index))}</ul>

            {isType && DeleteMesage()}
          </div>

          <div className="times mt-5">
            <h3>Times</h3>

            <ul>{times.map((time, index) => filterItem(time, index))}</ul>

            {isTime && DeleteMesage()}
          </div>

          <div className="categories mt-5">
            <h3>Categories</h3>

            <ul>
              {categories.map(
                (category, index) => category.value !== '' && filterItem(category, index)
              )}
            </ul>

            {isCategory && DeleteMesage()}
          </div>
        </div>
      )}

      {isShowForm && updateFilterForm()}
    </div>
  );
};

export default UpdateFilters;
