import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
);

const options = {
    maintainAspectRatio: false,
    responsive: true,
};

export const LineChartTemaplte = props => {
    const { data } = props;

    const newData = data.map(e => {
        const y = e.USDT;
        const x = e.category.split('/')[1];

        return {
            x,
            y
        };
    });

    return (
        <Line
            options={options}
            height={180}
            data={{
                datasets: [
                    {
                        label: 'USDT',
                        data: newData,
                        fill: true,
                        borderColor: 'rgba(255, 255, 255, 1)',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        tension: 0
                    },
                ],
            }}
        />
    );
};
