import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { utils } from '../../../utils/utils';
import './styles.scss';

const showTitle = title => title === undefined;

export const PopoverLayout = props => {
    const { message, icon, title, component, onClick, width } = props;
    return <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
        <Popover id="popover-basic">
            <Popover.Body>
                <div className="message-wrapper display-linebreak" style={{width: width ? width : 247 + 'px'}}>{message}</div>
            </Popover.Body>
        </Popover>
    }>
        <div className='d-inline-flex valign-middle popover-overflow' onClick={onClick ? onClick : null}>
            {icon ? <img className='popover-icon-img' src={`../coins-full-library/${icon.toLowerCase()}.svg`} width={24} height={24} onError={utils.imgErrorHandle} /> : ''}
            {component ? <div className="component">{component}</div> : null}
            {showTitle(title) ? message : ''}
        </div>
    </OverlayTrigger>;
};