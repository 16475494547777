import { orderBy, isEmpty } from 'lodash';

const _getHistoryList = history => {
    const keys = Object.keys(history);
    const list = keys.map(k => ({ id: k, label: k.toUpperCase() }));
    const orderedList = orderBy(list, 'label', 'asc');
    return orderedList;
};

const parseData = exchanges => {
    const keys = Object.keys(exchanges)
        .filter(k => k !== 'all_exchanges');

    if (isEmpty(keys))
        return { exchangesList: [], typeList: {}, currentExchange: {}, currentType: {}, data: {} };

    const exchangesList = keys
        .map(k => ({ id: k, label: exchanges[k].name }));
    const orderedExchangesList = orderBy(exchangesList, 'label', 'asc');
    const currentExchange = orderedExchangesList[0];

    const typeList = keys.reduce((acc, k) => {
        acc[k] = _getHistoryList(exchanges[k].history);
        return acc;
    }, {});

    const currentType = typeList[currentExchange.id][0];

    const data = keys.reduce((acc, k) => {
        acc[k] = exchanges[k].history;
        return acc;
    }, {});

    return { exchangesList: orderedExchangesList, typeList, currentExchange, currentType, data };
};

export const barChartDataHelper = {
    parseData
};