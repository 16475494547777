import numeral from 'numeral';
import Big from 'big.js';
import { orderBy } from 'lodash';

const getSupplied = array => array.filter(item => +item.supply_amount > 0);

const getBorrowed = array => array.filter(item => +item.borrow_amount > 0);

const getSupplyBalance = supplied => +supplied.reduce((accumulator, object) => Number(accumulator) + Number(object.supply_price), 0).toFixed(2);

const getBorrowBalance = borrowed => +borrowed.reduce((accumulator, object) => Number(accumulator) + Number(object.borrow_price), 0).toFixed(2);

const sortAssets = (assets, attr='usdValue') => {
    const key = attr;
    const newArr = assets.map(item => {
        item[key] = Number(item[attr]);

        return {
            ...item,
        };
    });

    const result = orderBy(newArr, attr, 'desc');
    
    return result;
};

const getDefiTop4Assets = (assets, sum) => {
    const top = 4;
    const orderedTop = orderBy(assets, 'usdValue', 'desc').slice(0, top);

    return orderedTop.reduce((acc, b) => {
        return [
            ...acc,
            {
                asset: b.valueSymbol,
                fraction: b.usdValue / sum * 100,
            },
        ];
    }, []);
};

const filterAssets = (assets, blackList) =>
    assets.filter(asset => asset.valueSymbol !== blackList.valueSymbol);

const getCeFiSum = assets => assets.reduce((accumulator, asset) => {
    return Number(accumulator) + Number(asset.usdValue);
}, 0);

const getBalancesSum = assets => assets.reduce((accumulator, asset) => {
    return Number(accumulator) + Number(asset.usdValue);
}, 0);

const getTop4Assets = data => {
    const top = 4;
    const orderedTop = orderBy(data, 'TOTAL-USDT-N', 'desc').slice(0, top);
    return orderedTop.reduce((acc, b) => {
        return [
            ...acc,
            {
                asset: b.COIN,
                fraction: b['AMT-N'],
            },
        ];
    }, []);
};

const changeCalculation = (data) => {
    const change = Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT']).eq('0')
        ? '100'
        : Big(data['TOTAL-BALANCE-EXCHANGE-USDT'])
            .minus(Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT']))
            .div(Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT']))
            .times(100);
    return numeral(change).format('0.00');
};

const getRowData = (data) => {
    const assets = data.reduce((acc, b) => {
        return [
            ...acc,
            {
                valueSymbol: b.COIN,
                valueHuman: b.AMT,
                change: b['CHANGE-USDT'],
                usdValue: b['TOTAL-USDT'],
                order: b['TOTAL-N'],
                exchange: 'Exchange'
            },
        ];
    }, []);
    return orderBy(assets, 'order', 'desc');
};

const investedAvailableCalculation = (data) => {
    const { balances = [] } = data || {};
    return balances.reduce(
        (acc, b) => {
            acc.invested = Big(acc.invested)
                .plus(Big(b.locked).times(Big(b['PRICE-USDT'])))
                .valueOf();
            acc.available = Big(acc.available)
                .plus(Big(b.free).times(Big(b['PRICE-USDT'])))
                .valueOf();
            return acc;
        },
        {
            available: 0,
            invested: 0,
        }
    );
};

const balancesCalculation = (data) => {
    const { cefi, defi } = data;
    return {
        invested: Big(cefi.invested)
            .plus(Big(defi.invested || 0))
            .valueOf(),
        available: Big(cefi.available)
            .plus(Big(defi.available || 0))
            .valueOf(),
        total: Big(cefi.invested || 0)
            .plus(Big(defi.invested || 0))
            .plus(Big(cefi.available || 0))
            .plus(Big(defi.available || 0))
            .valueOf(),
    };
};

const mergeHistories = histories => {
    const arrOfArrs = [];
    
    for(let i = 0; i < histories[0].length; i++) {
        const array = [];
        for(let j = 0; j < histories.length; j++) {
            array.push(histories[j][i].USDT);
        }
        arrOfArrs.push(array);
    }
    
    const arrOfUSDT = arrOfArrs.map(el => el.reduce((sum, nextNum) => sum + nextNum, 0));

    const newHistories = histories[0].map((el, i) => {
        return {
            category: el.category,
            USDT: Number(arrOfUSDT[i].toFixed(2))
        };
    });

    return newHistories;
};

export const dataHelper = {
    mergeHistories,
    getDefiTop4Assets,
    sortAssets,
    getCeFiSum,
    filterAssets,
    getBalancesSum,
    changeCalculation,
    getRowData,
    investedAvailableCalculation,
    balancesCalculation,
    getTop4Assets,
    getSupplyBalance,
    getBorrowBalance,
    getSupplied,
    getBorrowed,
};
