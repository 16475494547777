import React, { useState } from 'react';
import { constants } from '../constants';
import Toggle from 'react-toggle';
import '../styles/grid_bot_styles.scss';
import { Form, InputGroup } from 'react-bootstrap';
import { Button, Dropdown } from 'react-bootstrap';

export const GridBotTemplate = ({
  state,
  pairData,
  formControlChange,
  toggleOnChange,
  onClickHandler,
}) => {
  const {
    GRID_BOT: {
      GRID_PROFIT,
      UPPER_LIMIT_PRICE,
      UPPER_STOP_LOSE,
      LOWER_LIMIT_PRICE,
      LOWER_STOP_LOSE,
      ACTIVE_ORDERS,
      AMOUNT_FOR_BOT_USAGE,
      GRID_QUANTITY,
      QUANTITY_PER_GRID,
    },
  } = constants;

  const options = [
    { id: 1, label: 'BTC' },
    { id: 2, label: 'USDT' },
    { id: 3, label: 'BNB' },
  ];

  return (
    <div className="grid-bot-content">
      <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center">
        <span className="font-weight-medium font-size-16 me-3">{GRID_PROFIT}</span>
        <Toggle
          name="toggle_grid_profit"
          icons={false}
          checked={state.toggle_grid_profit}
          onChange={toggleOnChange}
        />
      </label>
      {state.toggle_grid_profit ? (
        <div className="grid-profit-body">
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="grid-bot-left-title">{UPPER_LIMIT_PRICE}</span>
            <span className="grid-bot-right-title">{UPPER_STOP_LOSE}</span>
            <div className="toggle-icon">
              <Toggle
                icons={false}
                name="toggle_upper_stop_lose"
                checked={state.toggle_upper_stop_lose}
                onChange={toggleOnChange}
              />
            </div>
          </div>
          {state.toggle_upper_stop_lose ? (
            <div className="d-flex">
              {renderFormInput(
                'input_upper_limit_price',
                state.input_upper_limit_price,
                formControlChange,
                'shorter'
              )}
              {renderFormInput(
                'input_upper_stop_lose',
                state.input_upper_stop_lose,
                formControlChange,
                'shorter'
              )}
            </div>
          ) : (
            <div className="d-flex">
              {renderFormInput(
                'input_upper_limit_price',
                state.input_upper_limit_price,
                formControlChange,
                'shorter'
              )}
            </div>
          )}

          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="grid-bot-left-title">{LOWER_LIMIT_PRICE}</span>
            <span className="grid-bot-right-title">{LOWER_STOP_LOSE}</span>
            <div className="toggle-icon">
              <Toggle
                icons={false}
                name="toggle_lower_stop_lose"
                checked={state.toggle_lower_stop_lose}
                onChange={toggleOnChange}
              />
            </div>
          </div>
          {state.toggle_lower_stop_lose ? (
            <div className="d-flex">
              {renderFormInput(
                'input_lower_limit_price',
                state.input_lower_limit_price,
                formControlChange,
                'shorter'
              )}
              {renderFormInput(
                'input_lower_stop_lose',
                state.input_lower_stop_lose,
                formControlChange,
                'shorter'
              )}
            </div>
          ) : (
            <div className="d-flex">
              {' '}
              {renderFormInput(
                'input_lower_limit_price',
                state.input_lower_limit_price,
                formControlChange,
                'shorter'
              )}
            </div>
          )}
          <span className="grid-bot-left-title">{GRID_QUANTITY}</span>
          {renderFormInput(
            'input_grid_quantity',
            state.input_grid_quantity,
            formControlChange,
            'longer'
          )}
          <span className="grid-bot-left-title">{ACTIVE_ORDERS}</span>
          {renderFormInput(
            'input_active_orders',
            state.input_active_orders,
            formControlChange,
            'longer'
          )}
          <span className="grid-bot-left-title">{QUANTITY_PER_GRID}</span>
          <div className="quantity-part d-flex">
            <DropDownButton />
            {renderFormInput(
              'input_quantity_per_grid',
              state.input_quantity_per_grid,
              formControlChange,
              'longer'
            )}
          </div>
          <span className="grid-bot-left-title">{AMOUNT_FOR_BOT_USAGE}</span>
          {renderFormInput(
            'input_amount_for_bot_usage',
            state.input_amount_for_bot_usage,
            formControlChange,
            'longer'
          )}
        </div>
      ) : null}
    </div>
  );
};

const renderFormInput = (name, value, onChange, className) => (
  <div className={`form-input-${className} form-item pb-2`}>
    <InputGroup>
      <Form.Control
        name={name}
        value={value}
        onChange={onChange}
        className="ms-3 font-size-14 font-weight-regular"
        type="number"
      />
      <InputGroup.Text className="right-input-button">USDT</InputGroup.Text>
    </InputGroup>
  </div>
);

const DropDownButton = () => {
  const [selectedItem, setSelectedItem] = useState('BTC');

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };

  return (
    <div className="drop-down-button">
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {selectedItem}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="BNB">BNB</Dropdown.Item>
          <Dropdown.Item eventKey="USDT">USDT</Dropdown.Item>
          <Dropdown.Item eventKey="KAVA">KAVA</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
