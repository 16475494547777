export const dashboardConstants = {
    DATA_DEFI: [
        { value: '', x: 246, y: 386, fraction: '' },
        { value: '', x: 318, y: 455, fraction: '' },
        { value: '', x: 415, y: 454, fraction: '' },
        { value: '', x: 485, y: 386, fraction: '' },
    ],
    DATA_DEFI_MOBILE: [
        { value: '', x: 68, y: 173, fraction: '' },
        { value: '', x: 127, y: 230, fraction: '' },
        { value: '', x: 206, y: 229, fraction: '' },
        { value: '', x: 263, y: 173, fraction: '' },
    ],
    DATA_CEFI: [
        { value: '', x: 118, y: 360, fraction: '' },
        { value: '', x: 178, y: 360, fraction: '' },
        { value: '', x: 240, y: 360, fraction: '' },
        { value: '', x: 300, y: 360, fraction: '' },
    ],
    DATA_CEFI_MOBILE: [
        { value: '', x: 86, y: 192, fraction: '' },
        { value: '', x: 133, y: 192, fraction: '' },
        { value: '', x: 180, y: 192, fraction: '' },
        { value: '', x: 226, y: 192, fraction: '' },
    ],
    BOTTOM_CONTENT: {
        VAULTS: 'Pools/Vaults',
        YIELDS: 'Farms/Yields',
        ORDERS: 'Open Orders',
    },
    FORMAT: '0,00.00',
    INTERVAL: 3600000,
    HARVEST_ALL: 'Harvest All',
    DEFI_BALANCES: {
        TITLE: 'Wallet balances',
    },
    DEFI_LENDING: {
        TITLE: 'Lending',
        SUPPLIED: 'Supplied',
        BORROWED: 'Borrowed'
    },
    CEFI_BALANCES: {
        TITLE: 'Available assets CeFi'
    },
    URLS: {
        BALANCES: '/web3/getbalance',
        VAULTS: '/web3/cake/syrup',
        YIELDS: '/web3/pancake/farms',
        VENUS_LENDING: '/web3/venus'
    }
};
