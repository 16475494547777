export const constants = {
    DROPDOWN_LIST: [
        { value: 'compound', label: 'Compound' },
        { value: 'venus', label: 'Venus' },
    ],
    HEADER_KEYS: [
        'Health',
        'Account',
        'Asset to Repay',
        'Max Repay Amount',
        'Asset to Seize',
        'Max Seize Amount',
    ],
    TABS: {
        WALLET: 'Wallet',
        PROTOCOLS: 'Protocols',
    },
    WALLET_CONTROLS_HEALTH: {
        ACCOUNT: 'Account',
        HEALTH: 'Account Health',
        TO_REPAY: 'Assets to Repay',
        TO_SEIZE: 'Assets to Seize',
        MAX_REPAY: 'Max Repay Amount',
        MAX_SEIZE: 'Max Seize Amount',
    },
    WALLET_CONTROLS_LIQUIDATOR: {
        NO_SELECTED_ACCOUNT: 'Select an account you want to view',
        REPAY_IN: 'Amount you want to repay in',
        WALLET_BALANCE: 'Wallet balance',
        BUTTON: 'liquidate',
        UPDATE: 'Account health is updated every 2 seconds',
    },
    ERC20_ABI: [
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_spender', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_from', type: 'address' },
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transferFrom',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [{ name: '', type: 'uint8' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [{ name: '_owner', type: 'address' }],
            name: 'balanceOf',
            outputs: [{ name: 'balance', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [{ name: '', type: 'string' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                { name: '_to', type: 'address' },
                { name: '_value', type: 'uint256' },
            ],
            name: 'transfer',
            outputs: [{ name: '', type: 'bool' }],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                { name: '_owner', type: 'address' },
                { name: '_spender', type: 'address' },
            ],
            name: 'allowance',
            outputs: [{ name: '', type: 'uint256' }],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        { payable: true, stateMutability: 'payable', type: 'fallback' },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: 'owner', type: 'address' },
                { indexed: true, name: 'spender', type: 'address' },
                { indexed: false, name: 'value', type: 'uint256' },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: 'from', type: 'address' },
                { indexed: true, name: 'to', type: 'address' },
                { indexed: false, name: 'value', type: 'uint256' },
            ],
            name: 'Transfer',
            type: 'event',
        },
    ],
};
