import React from 'react';
import { IMAGE_MINT_TRIAL } from './images/mintTrial';
import { IMAGE_BEGINNER } from './images/beginner';
import { IMAGE_ADVANCED } from './images/advanced';
import { IMAGE_EXPERT } from './images/expert';
import {IMAGE_LEGENDARY} from "./images/legendary";

const mintTrialImage = () => {
  return IMAGE_MINT_TRIAL;
};

const beginnerImage = () => {
  return IMAGE_BEGINNER;
};

const advancedImage = () => {
  return IMAGE_ADVANCED;
};

const expertImage = () => {
  return IMAGE_EXPERT;
};

const legendaryImage = () => {
  return IMAGE_LEGENDARY;
};

export const imagesTemplate = {
  mintTrialImage,
  beginnerImage,
  advancedImage,
  expertImage,
  legendaryImage
};
