export function getColorByCardType(cardType) {
  switch (cardType.toLowerCase()) {
    case 'tranding':
      return '#F93939';
    case 'significent':
      return '#FFCB11';
    case 'hot':
      return '#F7931A';
    default:
      return '#F93939';
  }
}
