import React from 'react';
import { Asset } from '../../../../components/utility/asset-component/view';
import { CustomTooltip } from '../../../../components/utility/custom-tooltip/view';
import { dataHelpers } from '../../dataHelpers/dataHelper';

export const ActiveTradeItem = ({
  label,
  pairIcon1,
  string1,
  pairIcon2,
  string2,
  horizontal
}) => {
  return (
    <div
      className={`active-trade-item${horizontal ? " horizontal" : " vertical"}`}
    >
      <div className="d-flex align-items-center">
        {label}:
        <span className="pe-3 ps-1"></span>
        {pairIcon1 && <Asset asset={pairIcon1} ticker={true} className="pair-icon" />}
        <CustomTooltip
          trigger={
            <span className="value">{string1}</span>
          }
          content={dataHelpers.formatValueByChain(string1)}
        />
        {pairIcon1 && pairIcon2 &&<span className="ps-1">{pairIcon1}</span>}
        <span className="pe-3 ps-1"></span>
        {pairIcon2 && <Asset asset={pairIcon2} ticker={true} className="main-icon" />}
        {string2 &&
          <CustomTooltip
            trigger={
              <span className="value">{string2}</span>
            }
            content={dataHelpers.formatValueByChain(string2)}
          />
        }
        {pairIcon1 && pairIcon2 &&<span className="ps-1">{pairIcon2}</span>}
      </div>
    </div>
  );
};
