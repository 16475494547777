import React, { Fragment, useEffect, useState } from 'react';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import '../styles/bot_settings.scss';
import { botSettingsTemplates } from './botSettingsTemplates';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { smartTradeBotSettingsEffects } from '../actions/smartTradeBotSettingsEffects';
import { Button, ButtonGroup } from 'react-bootstrap';
import { debug, useStoreState } from 'easy-peasy';
import { packHelper } from '../actions/dataHelpers/packHelper';
import { packsEffects } from '../actions/packsEffects';
import { Notify } from '../../../components/utility/notifications/view';

const renderControlsContent = (state, actions) => {
    const clickAction = actions[smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CLICKED];
    const onClickHandler = e => clickAction({name: e.target.name, target: e.target, state});
    return <div className="content-controls">
        <Button variant="link" className='btn-link' size="sm" name="form_state_pro_trade" active={state.form_state_pro_trade} onClick={onClickHandler}>Pro Trade</Button>
        <Button variant="link" className='btn-link' size="sm" name="form_state_buy_sell" active={state.form_state_buy_sell} onClick={onClickHandler}>Buy / Sell</Button>
        <Button variant="link" className='btn-link' size="sm" name="form_state_pro_short" active={state.form_state_pro_short} onClick={onClickHandler}>Pro Short</Button>
    </div>;
};

const renderContent = (state, actions, pairData, selectedPack) => {
    const [show, setShow] = useState(false)
    const action = actions[smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED];
    const clickAction = actions[smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CLICKED];
    
    const formControlChange = e => action({name: e?.target?.name, value: parseFloat(e?.target?.value)});
    const toggleOnChange = e => action({name: e.target.name, value: e.target.checked});
    const sliderOnChange = value => action({...value});
    const onClickHandler = e => {
        clickAction({name: e.target.name, target: e.target, state, pairData, selectedPack})
    
    e.target.disabled=true
    setTimeout(function(){
        e.target.disabled=false
        setShow(true)
    }, 5000)

};  
    const isSubmitButtonDisabled = packHelper.submitButtonState(state);
    const actionButtonLabel = !state.form_state_buy_sell ? 'Create trade' : state.buySellFormType === 'buy' ? 'Buy' : 'Sell';

    return <div className="content-body">
        {!state.form_state_buy_sell ?
            <Fragment>
                <div className="left-part">{botSettingsTemplates.renderBuyForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler)}</div>
                <div className="middle-part">{botSettingsTemplates.renderTTPForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler)}</div>
                <div className="right-part">{botSettingsTemplates.renderSLossForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler)}</div>
            </Fragment> :
            <Fragment>
                <div className="middle-part">{botSettingsTemplates.renderTTPForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler)}</div>
                <div className="left-part">
                    { botSettingsTemplates.renderBuyForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler) }
                </div>
                <div className="right-part">{botSettingsTemplates.renderSLossForm(state, pairData, formControlChange, toggleOnChange, sliderOnChange, onClickHandler)}</div>
            </Fragment>
        }
        <div className="submit-content"></div>
        {show?(<div className="submit-content">
            <ButtonCustom variant="success" size="sm" label={actionButtonLabel} name="submit-action" onClick={onClickHandler} disabled={isSubmitButtonDisabled}/>
       
            {!state.errorInTrade ? <Notify/> : <Notify/>}
                {/* {!state.errorInTrade ? <div class="mt-3 alert alert-success">
                <strong> Successfully created trade!</strong> 
            </div> :
            <div class="mt-3 alert alert-warning ">
                <strong> Unsuccessfully created trade!</strong> 
                </div>} */}
                
            
        </div>) : (<div className="submit-content">
            <ButtonCustom variant="success" size="sm" label={actionButtonLabel} name="submit-action" onClick={onClickHandler} disabled={isSubmitButtonDisabled}/>
                </div>)}
        {/* <div className="submit-content"></div> */}
    </div> ;
};

export const TradeBotTemplate = () => {
    const singlePair = SmartTradeState.useStoreState(state => state.singlePair);
    const {isSingle} = singlePair;
    const state = SmartTradeState.useStoreState(state => state.smartTradeBot);
    const selectedPack = SmartTradeState.useStoreState(state => state.packs.selectedPack);
    const pairData = !isSingle ?
        SmartTradeState.useStoreState(state => state.packs.selectedPair) :
        singlePair.pairData;
    const actions = SmartTradeState.useStoreActions(actions => actions);
    useEffect(() => {
        actions[smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED]({name: 'cancel-action'});
        actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({id: 'fetch-pair-price', data: pairData});
        actions[packsEffects.actionTypes.SMART_TRADE_PAGE_STATE_UPDATED]({id: 'pair-amount', pairData});
    }, [pairData]);
    return <div className="smart-trade-bot-settings-content">
        { renderControlsContent(state, actions) }
        { renderContent(state, actions, pairData, selectedPack) }
    </div>
};