import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import { constants } from '../../constants';


const {CONTACTS_CONTENT: {TITLE,CONTACTS}} = constants;


export const ContactsPage = () => {
    return <div className='contacts-wrapper'>
            <div className="start-content">
                <h2 className="heading">
                    {TITLE}
                </h2>

                <div className="contacts">
                    {CONTACTS.map((contact, index) =>  
                        <a href={`${contact.LINK}`}  key={index}  className="contact" target={contact.TARGET}>
                            <i className={`${contact.ICONS}`}></i>

                            <div>
                                <p>{contact.TITLE}</p>
                                { contact.SML_TITLE.length > 0 && <p className='smlTitle'>{contact.SML_TITLE}</p>}
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </div>
};