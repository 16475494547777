import { action } from 'easy-peasy';

const actionTypes = {
    BAR_CHART_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED: 'BAR_CHART_WIDGET_UI_ACTIONS_EXCHANGE#CHANGED',
    BAR_CHART_WIDGET_UI_ACTIONS_TYPE_CHANGED: 'BAR_CHART_WIDGET_UI_ACTIONS_YEAR#CHANGED',
};

const actionHandlers = {
    [actionTypes.BAR_CHART_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED]: action((state, payload) => {
        const { value } = payload;
        state.barChartWidget.currentExchange = value;
    }),
    [actionTypes.BAR_CHART_WIDGET_UI_ACTIONS_TYPE_CHANGED]: action((state, payload) => {
        const { value } = payload;
        state.barChartWidget.currentType = value;
    }),

};

export const barChartEffects = {
    actionTypes,
    actionHandlers
};