import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { constants } from '../../../../constants';
import { MultiplyCustomSelector } from '../../../../../../components/utility/multiply-selector/view';
import { customBotsEffects } from '../../../../actions/customBotsEffects';

const {
  CONTROLS,
  BUTTONS
} = constants;

export const ConditionsForm = (props) => {
  const { state, actions } = props
  const action = actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]

  const changeHandle = (value, index) => action({...value, index});
  const formControlChange = (e, index) => action({name: e?.target?.name, value: e?.target?.value ? parseFloat(e?.target?.value) : 0, index});
  const toggleOnChange = e => action({name: e?.target?.name, value: e?.target?.checked});
  const fieldHandle = (index) => actions[customBotsEffects.actionTypes.CUSTOM_BOTS_DYNAMIC_FIELDS_CHANGED]({index, 'deal_start': false});
  const conditionFields = state.forms.takeProfit.conditionFields;

  return <>
    { conditionFields.map((item, i) =>
      <div
        key={item.id}
        className={`grid-rows ${conditionFields.length === 1 ? 'grid-template-columns-33-33-33' : 'grid-template-columns-32-32-32-2'}`}>
        <div className="form-item pb-3">
          <div className="font-size-14 pb-1 font-weight-medium me-1">{`${CONTROLS.LABEL_CONDITION} ${i + 1}`}</div>
          <MultiplyCustomSelector
            onChangeHandle={(e) => changeHandle(e, i)}
            isMulti={false}
            closeMenuOnSelect={true}
            value={item.conditionsClosingBotSelector.value}
            options={item.conditionsClosingBotSelector.list}/>
        </div>
        <div className="form-item pb-3">
          <div className={item?.conditionsClosingBotSelector?.value?.value === 'RSI' ? 'd-flex' : ''}>
            { item?.conditionsClosingBotSelector?.value?.value === 'RSI' &&
              <div className="w-50 me-3">
                <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_RSI_PERIOD}</div>
                <InputGroup>
                  <Form.Control
                    name="rsi_period_amount"
                    value={item.rsiPeriodAmount}
                    onChange={(e) => formControlChange(e, i)}
                    type="number"/>
                </InputGroup>
              </div>
            }
            <div className={item?.conditionsClosingBotSelector?.value?.value === 'RSI' ? 'w-50' : ''}>
              <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_TIMEFRAME}</div>
              <MultiplyCustomSelector
                onChangeHandle={(e) => changeHandle(e, i)}
                isMulti={false}
                closeMenuOnSelect={true}
                value={item.timeframeSelector.value}
                options={item.timeframeSelector.list}/>
            </div>
          </div>
        </div>
        <div className="form-item pb-3">
          <div className={item?.conditionsClosingBotSelector?.value?.value === 'RSI' ? 'd-flex' : ''}>
            { item?.conditionsClosingBotSelector?.value?.value === 'RSI' &&
              <div className="w-50 me-3">
                <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_CONDITION}</div>
                <MultiplyCustomSelector
                  onChangeHandle={(e) => changeHandle(e, i)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  value={item.conditionSelector.value}
                  options={item.conditionSelector.list}/>
              </div>
            }
            <div className={item?.conditionsClosingBotSelector?.value?.value === 'RSI' ? 'w-50' : ''}>
              <div className="font-size-14 pb-1 font-weight-medium me-1">
                { item?.conditionsClosingBotSelector?.value?.value === 'TA Presets' ? CONTROLS.LABEL_PRESETS : CONTROLS.LABEL_SIGNAL_VALUE}
              </div>
              { item?.conditionsClosingBotSelector?.value?.value === 'Trading View' &&
                <MultiplyCustomSelector
                  onChangeHandle={(e) => changeHandle(e, i)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  value={item.signalValueSelector.value}
                  options={item.signalValueSelector.list}/> ||
                item?.conditionsClosingBotSelector?.value?.value === 'TA Presets' &&
                <MultiplyCustomSelector
                  onChangeHandle={(e) => changeHandle(e, i)}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  value={item.presetsSelector.value}
                  options={item.presetsSelector.list}/> ||
                <InputGroup>
                  <Form.Control
                    name="signal_value_amount"
                    value={item.signalValueAmount}
                    onChange={(e) => formControlChange(e, i)}
                    type="number"/>
                </InputGroup>
              }
            </div>
          </div>
        </div>
        { conditionFields.length !== 1 &&
          <div className="form-item pb-3 d-flex flex-column align-items-center justify-content-center">
            <div className="invisible">{CONTROLS.LABEL_NAME}</div>
            <i onClick={() => fieldHandle(i)} className="fal fa-trash-alt font-size-19 global-negative-color cursor-pointer"></i>
          </div>
        }
      </div>
    )}
    <div className="form-item pb-3 text-center">
      <Button
        type="button"
        size="lg"
        className="font-size-14 font-weight-medium btn-default btn-steps-lg"
        onClick={() => fieldHandle()}>
        {BUTTONS.ADD_CONDITION}
      </Button>
    </div>

    <div className="form-item pb-3">
      <label className="d-flex align-items-center customized-toggle cursor-pointer">
        <div className="font-size-14 me-2">{CONTROLS.LABEL_TRAILING}</div>
        <Toggle
          name="toggle_trailing_conditions"
          icons={false}
          defaultChecked={state.forms.takeProfit.toggleTrailingConditions}
          onChange={toggleOnChange}
        />
      </label>
    </div>
    { state.forms.takeProfit.toggleTrailingConditions &&
      <div className="grid-rows grid-template-columns-50-50">
        <div className="form-item pb-3">
          <div className="font-size-14 pb-1 font-weight-medium me-1 global-colors-link-default">
            {CONTROLS.LABEL_MINIMUM_PROFIT}
          </div>
            <InputGroup>
              <Form.Control
                name="minimum_profit_percentage"
                className="border-right-0"
                value={state.forms.takeProfit.minimumProfitPercentage}
                onChange={formControlChange}
                type="number"/>
              <InputGroup.Text className='right-input-button'>
                <i className="fa-solid fa-percent"></ i>
              </InputGroup.Text>
            </InputGroup>
        </div>
        <div className="form-item pb-3">
          <div className="font-size-14 pb-1 font-weight-medium me-1 global-colors-link-default">
            {CONTROLS.LABEL_MINIMUM_PROFIT_TYPE}
          </div>
          <MultiplyCustomSelector
            onChangeHandle={changeHandle}
            isMulti={false}
            closeMenuOnSelect={true}
            value={state.forms.takeProfit.minimumProfitTypeSelector.value}
            options={state.forms.takeProfit.minimumProfitTypeSelector.list}/>
        </div>
      </div>
    }
  </>
};