import React from 'react';

export const constants = {
    PROTOCOLS_SELECT: [{ label: 'Pancake Swap', value: 'cake' }, { label: 'Quick Swap', value: 'quick' }, { label: 'Select All', value: 'all' }],
    BSC_NETWORK: {
        symbol: 'BSC',
        id: 'Binance',
        jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
            <img src="../dashboard-library/nets/bsc.svg"/><div className="ms-2">Binance</div></div>,
        data: {
            'address': '0xbdaf439D9CCdDed8de7F13De8e89d9d9bB294163',
            'USDT_ADDRESS': '0x55d398326f99059ff775485246999027b3197955',
            'chainId': 56,
            'chainName': 'Binance Smart Chain',
            'rpcUrls': ['https://bsc-dataseed.binance.org/'],
            'blockExplorerUrls': ['https://bscscan.com'],
            'nativeCurrency': {
                'symbol': 'BNB',
                'decimals': 18
            }
        }
    },
    SORT_SELECT: [{ value: 'Hot', label: 'Hot' },
        { value: 'APR', label: 'APR' },
        { value: 'Earned', label: 'Earned' },
        { value: 'Total staked', label: 'Total staked' },
        { value: 'Latest', label: 'Latest' }
    ],
    SCAN_TOKEN_LINK: 'https://bscscan.com/address/',
    TIPS: {
        REWARDS_TIP: 'Rewards are distributed and included into your staking balance automatically. There’s no need to manually compound your rewards.'
    },
    DIGIT_FORMAT: '0,00.00',
    UNSTAKE_MODAL: {

        HEADER: 'Unstake CAKE tokens',
        UNSTAKE: 'Unstake',
        BALANCE: 'Balance',
    },
    STAKE_MODAL: {

        HEADER: 'Stake CAKE tokens',
        STAKE: 'Stake',
        BALANCE: 'Balance',
    },
    CANCEL_BTN: 'Cancel',
    CONFIRM_BTN: 'Confirm',
    ABI: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
    ],
};