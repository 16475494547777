import React from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import Button from 'react-bootstrap/Button';
import { constants } from '../constants';

const {
  ARTICLES: {
    fullArticle: { btnBack },
  },
} = constants;

const FullArticle = () => {
  const fullArticle = AcademyState.useStoreState((state) => state.fullArticle);
  const backToPublicArticles = AcademyState.useStoreActions(
    (actions) => actions.BACK_TO_PUBLIC_ARTICLES
  );

  const content = fullArticle.content;
  const theObj = { __html: content };

  return (
    <div className="full-article">
      <main>
        <header>
          <Button onClick={backToPublicArticles} className="btn-back" variant="warning">
            {btnBack}
          </Button>

          <h2>{fullArticle.title}</h2>
          <p>Created: {fullArticle.date}</p>
        </header>

        <div>
          <div dangerouslySetInnerHTML={theObj} />
        </div>
      </main>
    </div>
  );
};

export default FullArticle;
