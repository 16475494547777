import React from 'react';
import { Modal } from 'react-bootstrap';
import { DefiMultiChainDashboardState } from '../../../models/defi-multichain-dashboard-model';
import { uiActions } from '../actions/uiActions';
import { Asset } from '../../../components/utility/asset-component/view';
import { isEmpty } from 'lodash';
import { constants } from '../constants';
import { utils } from '../../../utils/utils';


const { BLACK_LIST: { LABEL, BLACK_LIST_EMPTY, BLACK_LIST_MODAL_TABLE } } = constants;

const renderBlackListLayout = (list, actions, state, userWallet) => {
    const onClickHandle = (blockchainName, valueSymbol, contractAddress) => 
        actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_DELETE]({ value: { blockchainName, valueSymbol, contractAddress }, userWallet, state, list });

    return list.map(token => {
        const { blockchainName, contractAddress, valueSymbol } = token;
        return <div key={contractAddress} className="small-custom-table-row small-table-body font-size-14 font-weight-regular">
            <div className="custom-table-body-cell">{blockchainName}</div>
            <div className="custom-table-body-cell img-fluid rounded-start h-100 pe-1 d-flex align-items-center">
                <img onError={utils.imgErrorHandle} src={`../coins-full-library/${valueSymbol.toLowerCase()}.svg`} className="asset-icon"/>{valueSymbol}</div>
            <div className="custom-table-body-cell">{contractAddress}</div>
            <div className="custom-table-body-cell justify-content-end d-flex">
                <i onClick={() => onClickHandle(blockchainName, valueSymbol, contractAddress)} className="fa-light fa-xmark negative-color btn-close-cell"></i>
            </div>
        </div>;
    });
};

export const BlackListModal = ({ userWallet }) => {
    const state = DefiMultiChainDashboardState.useStoreState(state => state);
    const actions = DefiMultiChainDashboardState.useStoreActions(actions => actions);
    const handleClose = () => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ modal: 'modalBlackList', name: 'close-modal', userWallet });
    return <Modal
        size="lg"
        show={state.modalBlackList.show}
        centered
        className="add-exchange blacklist defi-blacklist"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="font-weight-medium text-start modal-main-header">{LABEL}</div>
            {!isEmpty(state.wallet.blackList.filteredData) ? <div className="modal-main-content">
                <div className="small-custom-table-row table-header">
                    {BLACK_LIST_MODAL_TABLE.map(heading => <div key={heading} className="custom-table-header-cell font-size-14 font-weight-medium">{heading}</div>)}
                </div>
                { renderBlackListLayout(state.wallet.blackList.filteredData, actions, state, userWallet) }
            </div> : <h2>{BLACK_LIST_EMPTY}</h2>}

        </Modal.Body>
    </Modal>;
};