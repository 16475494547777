import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { PopoverLayout } from '../../../components/utility/popover/view';
import '../styles.scss';
import { Collapse } from 'react-bootstrap';
import { uiActions } from '../actions/uiActions';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { constants } from '../constants';
import numeral from 'numeral';
import { poolsEffects } from '../actions/poolsEffects';
import { StakeModal } from './stakeModal';
import { useStoreState } from 'easy-peasy';
import { LockedPool } from './lockedPool';
import { utils } from '../../../utils/utils';
import { EnableContent } from './enableContent';

const { TIPS: { REWARDS_TIP }, DIGIT_FORMAT, SCAN_TOKEN_LINK } = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderRow = (data, index, state, toggleDetails, onModalInvoked, onHarvest) => {
    const { reward_token, staked_token, address, end_block, enabled, reward_amount, reward_price, staked_amount, staked_price } = data;
    const onClick = id => toggleDetails({ id });

    const isLocked = reward_token.symbol === 'CAKE(L)';
    const endsInBlock = end_block - state.currentBlock;

    const chevronPosition = id => state.tableData.toggled.includes(id) ? 
        { label: 'Hide Details', html: <i className="fa fa-chevron-up ms-2"></i> } : { label: 'Show Details', html: <i className="fa fa-chevron-down ms-2"></i> };

    return <div className='custom-table-row table-value-row table-body font-size-14 font-weight-regular' key={`body-${index}-${reward_token.address}-${end_block}`}>
        {!isLocked ? <><div className="collapse-header">
            <div className='custom-table-body-cell d-inline-flex align-items-center d-grid'>
                <div className='custom-table-body-cell d-inline-flex align-items-center'>
                    <div className="me-3 d-flex align-items-center"><div className="asset-icons-pair"><img onError={utils.imgErrorHandle} 
                        src={`../coins-full-library/${staked_token.symbol.toLowerCase()}.svg`} className="asset-icon"/>
                    <img onError={utils.imgErrorHandle} 
                        src={`../coins-full-library/${reward_token.symbol.toLowerCase()}.svg`} className="asset-icon"/></div></div>
                </div>
                <div className="d-column-flex align-items-center">
                    <div className="text-orange warning-color">STAKED</div>
                    <div className="font-weight-medium font-inter">EARN {reward_token.symbol}</div>
                    <div className="text-orange">STAKE {staked_token.symbol}</div>
                </div>
            </div>
            <div className='custom-table-body-cell d-column-flex align-items-center'>
                <div className="text-orange">{reward_token.symbol} EARNED</div>
                <div className="font-weight-medium font-inter link-color">{reward_amount}</div>
                <div className="text-orange font-size-12 link-color">~ ${reward_price}</div>
            </div>
            <div className='custom-table-body-cell d-column-flex align-items-center'>
                <div className="text-orange">Total Staked</div>
                <div className="font-weight-medium font-inter link-color">{ staked_amount ? numeral(staked_amount).format(DIGIT_FORMAT) : 0} {staked_token.symbol}</div>
            </div>
            <div className='custom-table-body-cell d-column-flex align-items-center'>
                <div className="text-orange">APR</div>
                <div className="font-weight-medium font-inter link-color">-%</div>
            </div>
            <div className='custom-table-body-cell d-column-flex align-items-center'>
                <div className="text-orange">Ends in</div>
                <div className="font-weight-medium font-inter link-color">{endsInBlock >= 0 ? endsInBlock : 0} blocks</div>
            </div>
            <div className='custom-table-body-cell d-column-flex align-items-center details'>
                <div className="d-inline-flex align-items-center details" onClick={() => onClick(index)}><div className="details-button">
                    {chevronPosition(index).label} {chevronPosition(index).html}</div></div>
            </div>
        </div>
        <div className="collapse-row">
            <Collapse in={state.tableData.toggled.includes(index)}>
                <div className="collapse-content font-size-14 font-weight-regular">
                    <div className="collapse-content-wrapper">
                        <div className="content-column">
                            <div className="stats">
                                <div className="fee-text mb-2">Performance Fee:</div>
                                {/* <div>{fee}%</div> */}
                                <div className="link mb-2"><a rel='noreferrer' target='_blank' href={`${SCAN_TOKEN_LINK}${reward_token.address}`}>See Token Info</a></div>
                                <div></div>
                                <div className="link mb-2"><a rel='noreferrer' target='_blank' href={address ? `${SCAN_TOKEN_LINK}${address}` : SCAN_TOKEN_LINK}>View Contract</a></div>
                                <div></div>
                                <div className="link mb-2">Add to METAMASK <img width={26} height={26} src="../dashboard-library/home-page-images/metamask-fox.svg" /></div>
                                <div></div>
                                <div className="d-inline-flex align-items-center">
                                    <div className='auto-refresh font-size-12 me-4'><i className="fa-light fa-refresh"></i> Auto</div>
                                    <div className="more-info"><PopoverLayout title={false} width={247} message={REWARDS_TIP} component={<i className="fa-light fa-circle-question"></i>}/></div>
                                </div>
                            </div>
                        </div>
                        {enabled || staked_amount > 0 ? <> <div className="content-column me-5 d-flex-column">
                            <div className="position-card p-3 harvest-block align-items-start">
                                <div className="profit">
                                    <div className="mb-3"><span className="warning-color">{reward_token.symbol}</span> Earned</div>
                                </div>
                                <div></div>
                                <div className="profit">
                                    <div className="font-weight-medium">{reward_amount} {reward_token.symbol}</div>
                                    <div className="font-weight-medium">~ ${reward_price}</div>
                                </div>
                                <div className="boost d-flex align-items-start">
                                    <div className="mb-3"></div>
                                    <div className="font-weight-medium"></div>
                                    <div className='w-100 d-grid'><ButtonCustom disabled={reward_amount <= 0} onClick={() => onHarvest(address)} variant="primary" label='Harvest' /></div>
                                </div>
                            </div>
                        </div>
                        <div className="content-column d-flex-column">
                            <div className="position-card p-3 harvest-block align-items-start">
                                <div className="profit">
                                    <div className="mb-3"><span className="warning-color">{staked_token.symbol}</span> Staked</div>
                                </div>
                                <div></div>
                                <div className="profit">
                                    <div className="font-weight-medium">{numeral(staked_amount).format('0,00.000000')} {staked_token.symbol}</div>
                                    <div className="font-weight-medium">~ ${numeral(staked_price).format(DIGIT_FORMAT)}</div>
                                </div>
                                <div className="boost d-flex align-items-start">
                                    <div className="mb-3"></div>
                                    <div className="font-weight-medium"></div>
                                    <div className='w-100 d-grid pe-2'><ButtonCustom 
                                        onClick={() => onModalInvoked('unstake-modal', 'open-modal', 
                                            { to: address, unstakeAmount: staked_amount, token: reward_token.symbol })}
                                        variant="primary" label={<i className="fa-light fa-minus"></i>} /></div>
                                    <div className='w-100 d-grid'><ButtonCustom disabled={state.currentBlock > end_block}
                                        onClick={() => onModalInvoked('stake-modal', 'open-modal',
                                            { to: address, stakeAmount: state.stakedBalance, token: staked_token.symbol, cakeAddress: staked_token.address })}
                                        variant="primary" label={<i className="fa-light fa-plus"></i>} /></div>
                                </div>
                            </div>
                        </div></> : <EnableContent 
                            poolAddress={address} 
                            address={address}
                            stakedTokenAddress={staked_token.address} 
                            rewardAmount={reward_amount} 
                            rewardPrice={reward_price} 
                            rewardToken={reward_token} />}
                    </div>
                </div>
            </Collapse>
        </div></> : <LockedPool 
            harvest={onHarvest}
            rewardAmount={reward_amount}
            rewardToken={reward_token} 
            stakedToken={staked_token} 
            clickHandle={toggleDetails} 
            state={state} 
            index={index} 
            end={end_block} 
            token={staked_token} 
            stakedAmount={staked_amount} 
            stakedPrice={staked_price} />}
    </div>;
};

const renderTableBody = (data, state, toggleDetails, onModalInvoked, onHarvest, searchData) => data.filter((item) => {
    return searchData === '' ? item : item?.address?.includes(searchData)
}).map((d, index) => 
    renderRow(d, index, state, toggleDetails, onModalInvoked, onHarvest));

const renderLoader = () => {
    return <div className="loader text-center" key={0}>
        <span>
            <i className="fa fa-refresh fa-spin"></i>
            <span className="ms-3">Loading...</span>
        </span>
    </div>;
};

export const CustomCollapsibleTableTemplate = (props) => {
    const { state, actions, toggleDetails, searchData } = props;
    const tableData = state.tableData;
    const isLoading = state.loader;
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const onHarvest = address => actions[poolsEffects.actionTypes.POOLS_REWARD_HARVEST_INVOKED]({ from: userWallet, to: address });
   
    const ref = useRef();
    const onModalInvoked = (name, modal, data) => actions[uiActions.actionTypes.POOLS_MODAL_INVOKED]({ name, modal, data });
    const loadFunc = value => actions[uiActions.actionTypes.POOLS_TABLE_LOAD_MORE_LOADING]({ page: value, state });
     
    return <div className="table-collapsible font-inter" ref={ref}>
        <div className="custom-table h-100">
            <div className="scroll-container">
                { isLoading ?
                    <Loader size={'6x'}/>
                    :
                    !isEmpty(tableData.filteredData) ?
                        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                            <InfiniteScroll
                                pageStart={0}
                                initialLoad={false}
                                loadMore={loadFunc}
                                hasMore={tableData.pagination.hasMore}
                                loader={renderLoader()}
                                useWindow={false}
                                getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
                                { renderTableBody(tableData.filteredData, state, toggleDetails, onModalInvoked, onHarvest, searchData) }
                            </InfiniteScroll>
                        </CustomScroll>
                        :
                        <EmptySearchState/> 
                }
            </div>
        </div>
        <StakeModal state={state} show={state.modal.show} actions={actions} />
    </div>;
};