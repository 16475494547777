import { action } from 'easy-peasy';

const actionTypes = {
  HODL_BOT_TABLE_COLLAPSED: 'HODL_BOT_TABLE#COLLAPSED',
  HODL_BOT_MODAL_INVOKED: 'HODL_BOT_MODAL#INVOKED',
  HODL_BOT_RUNNING_BOT_INVOKED: 'HODL_BOT_RUNNING_BOT#INVOKED',
  HODL_BOT_MANAGE_BOT_INVOKED: 'HODL_BOT_MANAGE_BOT#INVOKED'
};

const tableCollapse = (state, payload) => {
  switch (payload) {
    case 'howItWorks':
      state.howItWorks.open = !state.howItWorks.open;
      break;
    case 'events':
      state.events.open = !state.events.open
      break;
  }
};

const modalHandle = (state, payload) => {
  state.isModalOpen = payload;
};

const runningBotHandle = (state, payload) => {
  state.isBotRunning = payload;
  state.isModalOpen = false;
  if (!state.isBotRunning) {
    state.manageBot = false;
  }
};

const manageBotHandle = (state, payload) => {
  state.manageBot = payload;
};

const actionHandlers = {
  [actionTypes.HODL_BOT_TABLE_COLLAPSED]: action((state, payload) => tableCollapse(state, payload)),
  [actionTypes.HODL_BOT_MODAL_INVOKED]: action((state, payload) => modalHandle(state, payload)),
  [actionTypes.HODL_BOT_RUNNING_BOT_INVOKED]: action((state, payload) => runningBotHandle(state, payload)),
  [actionTypes.HODL_BOT_MANAGE_BOT_INVOKED]: action((state, payload) => manageBotHandle(state, payload))
};

export const uiActions = {
  actionTypes,
  actionHandlers
};
