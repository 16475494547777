import React from 'react';
import { Asset } from '../../../../components/utility/asset-component/view';
import { constants } from '../../constants';

const {
  TABLE_COLLAPSE_CONTENT: {
    STEPS: {
      STATUS_FINISHED
    }
  }
} = constants;

export const BuySellStepItem = ({item}) => {
  const trade = item.trade.trade

  return (
    <div className="sell-steps align-items-center">
      <div className="step-item">
        <Asset asset={item.mainCoin} ticker={true} />
        <span>{trade.price}</span>
      </div>

      <div className="step-item">
        <span className="pe-1">{trade.qty}</span>
        {item.pairCoin}
        <span className="pe-1 ps-1">/</span>
        <span className="pe-1">{Number(trade.qty) * Number(trade.price)}</span>
        {item.mainCoin}
      </div>

      <div className="step-item">
        <span>{STATUS_FINISHED}</span>
      </div>
    </div>
  );
};
