import { serviceUtils } from '../../../service/serviceUtils';
import { serviceEffects } from './serviceEffects';

const REQUEST_URL = '/custom-bots/';

const createDCABotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}create-dca`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_FAILED],
    successActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_SUCCEEDED],
    ...options
  });
};

const createGordonBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}create-gordon`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_FAILED],
    successActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_SUCCEEDED],
    ...options
  });
};

const createWizardBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}create-wizard`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_FAILED],
    successActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_SUCCEEDED],
    ...options
  });
};

const createAdvancedBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}create-advanced`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_FAILED],
    successActionType: actions[serviceEffects.actionTypes.CUSTOM_BOTS_REQUESTS_SUCCEEDED],
    ...options
  });
};

export const serviceFactory = {
  createDCABotAction,
  createGordonBotAction,
  createWizardBotAction,
  createAdvancedBotAction
};
