import React, { useEffect } from 'react';
import { AcademyState } from '../../../../../models/academy-model';

const Message = () => {
  const message = AcademyState.useStoreState((state) => state.admin.message.text);
  const actions = AcademyState.useStoreActions((actions) => actions);

  useEffect(() => {
    setTimeout(() => {
      actions.SET_MESSAGE('');
    }, 4000);
  }, [message]);

  return (
    <div className={`alert ${message ? 'alert-success' : null} mb-6`} role="alert">
      {message}
    </div>
  );
};

export default Message;
