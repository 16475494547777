import React from 'react';
import { constants } from '../constants';
import { Button } from 'react-bootstrap';
import { CustomTooltip } from '../../../components/utility/custom-tooltip/view';
import { uiActions } from '../actions/uiActions';
import {hodlBotEffects} from "../actions/hodlBotEffects";

const {
  TEXT: {
    RUNNING,
    ID,
    HODL_BOT,
    TOTAL_PROFIT,
    NO_BTC_BOUGHT,
    HEALTHCHECK,
    BUY_EVERY,
    DAYS,
    NEXT_BUY_DATE,
    AVERAGE_BUY_PRICE,
    CURRENT_MARKET_VALUE,
    ALERT
  },
  BUTTONS: {
    STOP_BOT,
    MANAGE
  }
} = constants;

export const BotSettingsRunning = props => {
  const { actions, state } = props;

  const handleStopBot = () => actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_INVOKED]({
    values: { botId: state.botId },
    method: 'stop-bot'
  })

  return <div className="bot-settings-content  bot-running">
    <div className="pb-3 d-flex justify-content-between">
      <div className="font-size-14 running positive-font-color">
        <span className="circle"></span>
        {RUNNING}
      </div>
      <div className="id">{ID} 16555</div>
    </div>
    <div className="pb-3">
      <div className="font-size-16">
        {HODL_BOT} #16555
      </div>
    </div>
    <div className="font-size-18 d-flex font-weight-medium">
      <div>
        <img src="../coins-full-library/usdt.svg" width={20} height={20} alt="USDT"/>
        <span className="ps-1">15 USDT</span>
      </div>
      <div className="ps-3 pe-3">
        <i className="fa-regular fa-arrow-right"></i>
      </div>
      <div>
        <img src="../coins-full-library/btc.svg" width={20} height={20} alt="BTC"/>
        <span className="ps-2">BTC</span>
      </div>
    </div>
    <div className="pb-3">
      <img src="../dashboard-library/binance-horizontal.svg" alt="binance"/>
    </div>
    <div className="alert-item mb-4 text-center font-size-18 p-4">
      <p className="mb-2">{TOTAL_PROFIT}</p>
      {/*<p className="font-weight-medium">{NO_BTC_BOUGHT}</p>*/}
      <p className="font-weight-medium positive-font-color mb-2">
        +0.1584355 <span>USDT</span>
      </p>
      <p className="positive-font-color font-size-12">
        +1.05% <span className="ms-1"><i className="fa-regular fa-arrow-up"></i></span>
      </p>
    </div>

    <div className="font-inter">
      <div className="pb-3 d-flex justify-content-between font-size-14">
        <div className="light-font-color">{HEALTHCHECK}</div>
        <div>Normal</div>
      </div>
      <div className="pb-3 d-flex justify-content-between font-size-14">
        <div className="light-font-color">{BUY_EVERY}</div>
        <div>7 {DAYS}</div>
      </div>
      <div className="pb-3 d-flex justify-content-between font-size-14">
        <div className="light-font-color">{NEXT_BUY_DATE}</div>
        <div>10/28/22</div>
      </div>
      <div className="pb-3 d-flex justify-content-between font-size-14">
        <div className="light-font-color">{AVERAGE_BUY_PRICE}</div>
        <div>0 USDT</div>
      </div>
      <div className="pb-5 d-flex justify-content-between font-size-14">
        <div className="light-font-color">{CURRENT_MARKET_VALUE}</div>
        <div className="warning-wrapper">
          <CustomTooltip
            trigger={
              <span className="me-2 cursor-pointer">
                <i className="fa-light fa-triangle-exclamation"></i>
              </span>
            }
            content={ALERT}
          />
          <span>15.15</span>
          <span>USDT</span>
        </div>
        {/*<div>0 USDT</div>*/}
      </div>
      <div className="submit-button mb-2">
        <Button
          type="button"
          size="lg"
          variant="warning"
          className="font-size-14 font-weight-medium warning-btn"
          onClick={handleStopBot}
          >{STOP_BOT}</Button>
        <Button
          type="button"
          size="lg"
          className="font-size-14 font-weight-medium manage"
          onClick={() => { actions[uiActions.actionTypes.HODL_BOT_MANAGE_BOT_INVOKED](true) }}
        >{MANAGE}</Button>
      </div>
    </div>
  </div>;
};
