import { action, thunk } from 'easy-peasy';
import { isEmpty, uniq } from 'lodash';
import { refreshEffects } from '../priceFetcher/refreshEffects';
import { serviceUtils } from '../../service/serviceUtils';
import { notifyEffects } from '../utility/notifications/notifyEffects';
import { priceDataHelper } from '../priceFetcher/dataHelpers/priceDataHelper';
import { pollerEffects } from '../priceFetcher/pollerEffects';
import { constants } from '../../constants/constants';

const URL = '/favorites';
const { FAVORITES: { preinstalledCoins } } = constants;

const actionTypes = {
    FAVORITES_ACTION_MORE_CHANGED: 'FAVORITES_ACTION_MORE#CHANGED',
    FAVORITES_ACTION_UPDATED: 'FAVORITES_ACTION#UPDATED',
    FAVORITES_ACTION_EDITED: 'FAVORITES_ACTION#EDITED',
    FAVORITES_ACTION_MODAL_OPENED: 'FAVORITES_ACTION_MODAL#OPENED',
    FAVORITES_ACTION_MODAL_CLOSED: 'FAVORITES_ACTION_MODAL#CLOSED',
    FAVORITES_ACTION_EDIT_CANCELED: 'FAVORITES_ACTION_EDIT#CANCELED',
    FAVORITES_ACTION_ASSET_MARKED: 'FAVORITES_ACTION_ASSET#MARKED',
    FAVORITES_ACTION_ASSET_UNMARKED: 'FAVORITES_ACTION_ASSET#UNMARKED',
    FAVORITES_ACTION_MARKED_ASSETS_REMOVED: 'FAVORITES_ACTION_MARKED_ASSETS#REMOVED',
    FAVORITES_ACTION_ASSET_ADDED: 'FAVORITES_ACTION_ASSET#ADDED',
    FAVORITES_ACTION_COLLAPSE_TOGGLED: 'FAVORITES_ACTION_COLLAPSE#TOGGLED',
    FAVORITES_ACTION_SAVE_CLICKED: 'FAVORITES_ACTION_SAVE#CLICKED',
    FAVORITES_ACTION_INVOKED: 'FAVORITES_ACTION#INVOKED',
    FAVORITES_ACTION_REQUEST_FAILED: 'FAVORITES_ACTION_REQUEST#FAILED',
    FAVORITES_ACTION_REQUEST_SUCCEED: 'FAVORITES_ACTION_REQUEST#SUCCEED'

};

const favoritesMenuHandle = (actions, payload) => {
    const { value } = payload;

    if (isEmpty(value))
        return;

    switch (value.id) {
    case 'update':
        actions[refreshEffects.actionTypes.REFRESH_DATA_INVOKED]({ type: 'favorites' });
        actions[actionTypes.FAVORITES_ACTION_UPDATED](payload);
        break;
    case 'edit':
        actions[actionTypes.FAVORITES_ACTION_EDITED](payload);
        break;
    case 'add':
        actions[actionTypes.FAVORITES_ACTION_MODAL_OPENED](payload);
        break;
    }

};

const uiAction = (actions, type, value) => {
    switch (type) {
    case 'save':
        actions[actionTypes.FAVORITES_ACTION_INVOKED]({ type: 'favorites_delete', method: 'DELETE', actions, data: { 'asset': value } });
        actions[actionTypes.FAVORITES_ACTION_SAVE_CLICKED]();
        break;
    case 'collapse_toggle':
        actions[actionTypes.FAVORITES_ACTION_COLLAPSE_TOGGLED](value);
        break;
    }
};

const updateAction = (state) => {
    state.favoritesMenu.value = {};
};

const editAction = (state, payload) => {
    const { value } = payload;
    state.favoritesMenu.isEditMode = true;
    state.favoritesMenu.value = value;
    state.favoritesMenu.collapseState = true;
};

const openModalAction = (state, payload) => {
    const { value } = payload;
    state.favoritesMenu.value = value;
    state.favoritesMenu.openAddModal = true;
    state.favoritesMenu.isEditMode = false;
};

const closeModalAction = state => {
    state.favoritesMenu.value = {};
    state.favoritesMenu.openAddModal = false;
    state.favoritesMenu.addValue = '';
};

const editCanceled = state => {
    state.favoritesMenu.value = {};
    state.favoritesMenu.isEditMode = false;
    state.favoritesMenu.collapseState = false;
    state.favoritesMenu.deletedBuffer = [];
};

const markForRemoved = (state, payload) => {
    state.favoritesMenu.deletedBuffer = [...state.favoritesMenu.deletedBuffer, payload];
};

const unMarkAction = (state, payload) => {
    const buffer = state.favoritesMenu.deletedBuffer;
    state.favoritesMenu.deletedBuffer = buffer.filter(e => e !== payload );
};

const saveAddedAction = (actions, payload) => {
    actions[actionTypes.FAVORITES_ACTION_INVOKED]({ type: 'favorites_add', method: 'PUT', actions, data: { 'asset': [payload] } });
};

const toggleCollapseAction = (state, payload) => {
    state.favoritesMenu.collapseState = !payload;

    if (!payload)
        return;

    state.favoritesMenu.isEditMode = false;
    state.favoritesMenu.value = {};
    state.favoritesMenu.isEditMode = false;
    state.favoritesMenu.deletedBuffer = [];
};

const saveAction = state => {
    state.favoritesMenu.isEditMode = false;
    state.favoritesMenu.deletedBuffer = [];
    state.favoritesMenu.value = {};
    state.favoritesMenu.collapseState = false;
};

const invokeAction = (actions, payload) => {
    serviceUtils.HttpService({
        url: URL,
        method: payload.method,
        errorActionType: actions[actionTypes.FAVORITES_ACTION_REQUEST_FAILED],
        successActionType: actions[actionTypes.FAVORITES_ACTION_REQUEST_SUCCEED],
        ...payload
    });
};

const errorAction = (state, payload) => {
    const { options, error } = payload;
    const notification = { type: 'danger', text: `Operation for ${options.type.toUpperCase()} failed, ${error}` };
    notifyEffects.notifyAction(notification);
};

const successAction = (state, payload) => {
    const { options, response } = payload;
    const limitedExchangesUsedCoins = state.coinData.limitedExchangeUsedCoins;
    const favoriteAssets = uniq([...preinstalledCoins, ...response]);
    const coins = uniq([...favoriteAssets, ...limitedExchangesUsedCoins]);
    const params = priceDataHelper.getPairsForRequest(coins);
    state.coinData.requestParams = params;
    state.coinData.favorites = favoriteAssets;
    const { sendJsonMessage } = state.socket.methods;
    pollerEffects.pollerStart(params, sendJsonMessage); // re initiate poller for websocket
    const notification = { type: 'success', text: `Operation is successfully for ${options.type.toUpperCase()}` };
    notifyEffects.notifyAction(notification);
};

const actionHandlers = {
    [actionTypes.FAVORITES_ACTION_MORE_CHANGED]: thunk((actions, payload) => favoritesMenuHandle(actions, payload)),
    [actionTypes.FAVORITES_ACTION_UPDATED]: action((state, payload) => updateAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_EDITED]: action((state, payload) => editAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_MODAL_OPENED]: action((state, payload) => openModalAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_MODAL_CLOSED]: action((state, payload) => closeModalAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_EDIT_CANCELED]: action((state, payload) => editCanceled(state, payload)),
    [actionTypes.FAVORITES_ACTION_ASSET_MARKED]: action((state, payload) => markForRemoved(state, payload)),
    [actionTypes.FAVORITES_ACTION_ASSET_ADDED]: thunk((actions, payload) => saveAddedAction(actions, payload)),
    [actionTypes.FAVORITES_ACTION_COLLAPSE_TOGGLED]: action((state, payload) => toggleCollapseAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_SAVE_CLICKED]: action((state, payload) => saveAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_ASSET_UNMARKED]: action((state, payload) => unMarkAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_REQUEST_SUCCEED]: action((state, payload) => successAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_REQUEST_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.FAVORITES_ACTION_INVOKED]: thunk((actions, payload) => invokeAction(actions, payload)),
};

export const favoritesEffects = {
    actionTypes,
    actionHandlers,
    uiAction
};