import React from 'react';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { dashboardConstants } from '../dashboardConstants';
import { ButtonGroup } from 'react-bootstrap';
import { LineChartTemaplte } from './lineChartTemplate';
import numeral from 'numeral';
import { availableAssetsContent } from './tables/walletAssetsTable';
import { LendingImagesGenerator } from '../imagesGenerator/LendingImagesGenerator';
import Slider from 'rc-slider';
import { LinkContainer } from 'react-router-bootstrap';

const { HARVEST_ALL, DATA_TEMP, DEFI_BALANCES, FORMAT, DEFI_LENDING, CEFI_BALANCES } = dashboardConstants;

export const middleContentTemplate = (state, theme, history, hasExchange) => {
    const { availableAssets, lending } = state;
    
    return <div className="remake-dashboard-visual-content-middle w-100 pt-3 d-none d-lg-block">
        <div className="harvest-chart-grid mb-4">
            <div></div>
            <div className="chart-controls-grid">
                <div>
                    <ButtonGroup className="chart-control-group">
                        <ButtonCustom label="D" active={true} variant="default" size="sm" name="button_day"/>
                        <ButtonCustom label="W" variant="default" size="sm" name="button_week"/>
                        <ButtonCustom label="M" variant="default" size="sm" name="button_month"/>
                    </ButtonGroup>
                </div>
                <div className="text-end">
                    <ButtonGroup className="chart-control-group">
                        <ButtonCustom label="All" variant="default" size="sm" name="button_all"/>
                        <ButtonCustom label="DeFi" variant="default" size="sm" name="button_defi"/>
                        <ButtonCustom label="CeFi" active={true} variant="default" size="sm" name="button_cefi"/>
                    </ButtonGroup>
                </div>
            </div>
            {hasExchange &&
            <div className="chart">
                <LineChartTemaplte data={history}/>
            </div>}
        </div>
        <div className="wallets-info">
            <div className="middle-block defi-wallet-balances">
                <div className="defi-wallet-balances-header">
                    <div className="title-row">
                        <div className="title">{DEFI_BALANCES.TITLE}</div>
                        <LinkContainer to="DeFi?elem=defidashboard"><i className="resize-icon fa-regular fa-up-right-and-down-left-from-center"></i></LinkContainer>
                    </div>
                    <div className="usd-balance">$ {numeral(state.walletAssets.totalUSD).format(FORMAT)}</div>
                    <div className="btc-balance">{Number(state.walletAssets.totalBTC).toFixed(6)} BTC</div>
                </div>
                <div className="defi-wallet-balances-table">{availableAssetsContent(state.walletAssets.assets, state, 'defi')}</div>
            </div>
            <div className="defi-lending">
                <div className="middle-block defi-lending-limit">
                    <div className="d-flex justify-content-center title">{DEFI_LENDING.TITLE}</div>
                    <div className="font-size-18 font-weight-regular justify-content-start">Available credit</div>
                    <div className="lending-inner-grid">
                        <div className="font-size-16 font-weight-light justify-content-start">Borrow limit</div>
                        <div className="text-end">100%</div>
                    </div>
                    <div className="slider">
                        <Slider className="slider -primary" defaultValue={0} min={0} value={lending.borrowLimitUsed} max={100}/>
                    </div>
                </div>
                <div className="middle-block defi-lending-borrow-supply">
                    <div className="lending-headers">
                        <div className="title">{DEFI_LENDING.SUPPLIED}</div>
                        <div></div>
                        <div className="title">{DEFI_LENDING.BORROWED}</div>
                    </div>
                    <LendingImagesGenerator />
                </div>
            </div>
            {hasExchange && <div className="middle-block defi-wallet-balances">
                <div className="defi-wallet-balances-header">
                    <div className="title">{CEFI_BALANCES.TITLE}</div>
                    <div className="usd-balance">$ {numeral(state.cefi.balance).format(FORMAT)}</div>
                    <div className="btc-balance">{state.cefi.totalBTC.toFixed(6)} BTC</div>
                </div>
                <div className="defi-wallet-balances-table">{availableAssetsContent(availableAssets, state, 'cefi')}</div>
            </div>}
        </div>
    </div>;
};