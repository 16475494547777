import { action, state, thunk } from 'easy-peasy';


const actionTypes = {
    LOGIN_TERMS_TOGGLE: 'LOGIN_TERMS_TOGGLE',
    LOGIN_MODAL_TOGGLE: 'LOGIN_MODAL_TOGGLE',
    APPROVE_THERMS_OF_USE: 'APPROVE_THERMS_OF_USE',
    SET_TERM_COOKIE: 'SET_TERM_COOKIE',
    GET_TERM_COOKIE: 'GET_TERM_COOKIE'
};

const showLoginTerms = (state) => {
    state.loginModal.showTerms = !state.loginModal.showTerms;
}

const loginModalToggle = (state) => {
    state.loginModal.showModal = !state.loginModal.showModal;
}

const approveThermsOfUse = (state) => {
    state.loginModal.approveTerms = true;
   
}

const setTermCookie = (state) => {
    // document.cookie = 'acceptedTAC=' + true;
    document.cookie = "acceptedTAC=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure";
    state.loginModal.approveTerms = true;
}

const getCookie = (payload) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${payload}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const actionHandlers = {
    [actionTypes.LOGIN_TERMS_TOGGLE]: action((state, payload) => showLoginTerms(state)),
    [actionTypes.LOGIN_MODAL_TOGGLE]: action((state, payload) => loginModalToggle(state)),
    [actionTypes.APPROVE_THERMS_OF_USE]: action((state, payload) => approveThermsOfUse(state)),
    [actionTypes.SET_TERM_COOKIE]: action((state, payload) => setTermCookie(state)),
    [actionTypes.GET_TERM_COOKIE]: thunk((state, payload) => getCookie(payload)),
};

export const loginModalActions = {
    actionTypes,
    actionHandlers
};