import React from 'react';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { investmentEffects } from '../actions/investmentEffects';
import { InvestmentState } from '../../../models/investment-model';
import { useStoreState } from 'easy-peasy';

export const EnableContent = (props) => {
  const { rewardAmount, rewardPrice, lpTokenAddress, chain, cheff } = props;
  const state = InvestmentState.useStoreState((state) => state);
  const userWallet = useStoreState((state) => state.authentication.userData.userId);
  const actions = InvestmentState.useStoreActions((actions) => actions);

  const onEnableHandle = () =>
    actions[investmentEffects.actionTypes.FARMS_APPROVE_INVOKED]({
      from: userWallet,
      to: lpTokenAddress,
      chain,
      cheff,
    });

  return (
    <>
      <div className="content-column me-5 d-flex-column">
        <div className="position-card p-3 harvest-block align-items-start">
          <div className="profit">
            <div className="mb-3">
              <span className="warning-color">CAKE</span> Earned
            </div>
          </div>
          <div></div>
          <div className="profit">
            <div className="font-weight-medium">{rewardAmount}</div>
            <div className="font-weight-medium">~ ${rewardPrice}</div>
          </div>
          <div className="boost d-flex align-items-start">
            <div className="mb-3"></div>
            <div className="font-weight-medium"></div>
            <div className="w-100 d-grid">
              <ButtonCustom disabled={rewardAmount <= 0} variant="primary" label="Harvest" />
            </div>
          </div>
        </div>
      </div>
      <div className="content-column d-flex-column">
        <div className="position-card p-3 harvest-block align-items-start">
          <div className="profit">
            <div className="mb-3">Enable Farm</div>
          </div>
          <div></div>
          <div className="boost d-flex align-items-start">
            <div className="mb-3"></div>
            <div className="font-weight-medium"></div>
            <ButtonCustom
              disabled={state.currentTxStatus}
              onClick={() => onEnableHandle()}
              variant="primary"
              label="Enable"
            />
          </div>
        </div>
      </div>
    </>
  );
};
