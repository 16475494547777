import React from 'react';

const Button = ({ children, className, onClick, padding }) => {
  const location = window.location.search;

  return (
    <span
      onClick={onClick}
      className={className}
      style={{
        padding: padding,
        display: location === '?elem=academy/admin' ? 'flex' : 'none',
      }}
    >
      {children}
    </span>
  );
};

export default Button;
