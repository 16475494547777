const SUBSCRIPTION_PERIOD = 5000;
const POLLING_PERIOD = 30000;

let POLLING_ID, TIMEOUT_ID;

const pollerJob = (params, sendJsonMessage) => {
    const message = {};
    message.method = 'SUBSCRIBE';
    message.params = params;
    message.id = 100;
    sendJsonMessage(message);
    TIMEOUT_ID = setTimeout(() => {
        message.method = 'UNSUBSCRIBE';
        message.params = params;
        message.id = 200;
        sendJsonMessage(message);
        clearTimeout(TIMEOUT_ID);
    }, SUBSCRIPTION_PERIOD);
};

const poller = (message, sendJsonMessage) => {
    pollerJob(message, sendJsonMessage);
    POLLING_ID = setTimeout(() => {
        poller(message, sendJsonMessage);
        clearTimeout(POLLING_ID);
    }, POLLING_PERIOD);
};

const pollerReset = () => {
    clearTimeout(POLLING_ID);
    clearTimeout(TIMEOUT_ID);
};

const pollerStart = (message, sendJsonMessage) => {
    pollerReset();
    poller(message, sendJsonMessage);
};

export const pollerEffects = {
    pollerReset,
    pollerStart
};