import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../service/serviceUtils';
import { notifyEffects } from '../utility/notifications/notifyEffects';

const URL = '/predictions';

const actionTypes = {
    PREDICTIONS_DATA_INVOKED: 'PREDICTIONS_DATA#INVOKED',
    PREDICTIONS_DATA_FETCHED_SUCCEED: 'PREDICTIONS_DATA_FETCHED#SUCCEED',
    PREDICTIONS_DATA_FETCHED_FAILED: 'PREDICTIONS_DATA_FETCHED#FAILED',
};


const fetchHandler = (action, payload) => {
    serviceUtils.HttpService({
        url: URL,
        method: payload.method,
        errorActionType: action[actionTypes.PREDICTIONS_DATA_FETCHED_FAILED],
        successActionType: action[actionTypes.PREDICTIONS_DATA_FETCHED_SUCCEED],
        ...payload
    });
};

const successHandle = (state, payload) => {
    const { response } = payload;
    state.predictions = response.predictions;
    state.minutesAgo = response.minutesAgo;
    state.percSuccessPrediction = response.percSuccessPrediction;
    state.percSuccessPredictionHour = response.percSuccessPredictionHour;
    state.percSuccessPredictionMinutes = response.percSuccessPredictionMinutes;
    state.percUp = response.percUp;
    state.percDown = response.percDown;
    state.percUpHour = response.percUpHour;
    state.percDownHour = response.percDownHour;
    state.percUpMinutes = response.percUpMinutes;
    state.percDownMinutes = response.percDownMinutes;
    state.totalSumCommon = response.totalSumCommon;
    state.totalSumCommonHour = response.totalSumCommonHour;
    state.totalSumCommonMinutes = response.totalSumCommonMinutes;
    state.totalSumPredicted = response.totalSumPredicted;
    state.totalSumPredictedHour = response.totalSumPredictedHour;
    state.totalSumPredictedMinutes = response.totalSumPredictedMinutes;
    state.byPairUp = response.byPairUp;
    state.byPairDown = response.byPairDown;
    state.rowsUnpredicted = response.rowsUnpredicted;

    const notification = { type: 'success', text: 'Predictions request succeed' };
    notifyEffects.notifyAction(notification);
};

const errorHandle = (state, payload) => {
    const { error } = payload;
    const notification = { type: 'danger', text: `Prediction request failed, ${error}` };
    notifyEffects.notifyAction(notification);
};


const actionHandlers = {
    [actionTypes.PREDICTIONS_DATA_INVOKED]: thunk((actions, payload) => fetchHandler(actions, payload)),
    [actionTypes.PREDICTIONS_DATA_FETCHED_SUCCEED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.PREDICTIONS_DATA_FETCHED_FAILED]: action((state, payload) => errorHandle(state, payload))
};

export const predictionsEffect = {
    actionTypes,
    actionHandlers
};