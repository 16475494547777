import React from 'react';
import { toast } from 'react-toastify';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
follow documentation https://github.com/fkhadra/react-toastify
 */

const notifyAction = payload => {
    const { type, text } = payload;
    const options = {
        className: `moneyr-notify-toast notify-class-${type}`,
        bodyClassName: 'my-body-class'
    };
    const text_content = <div>
            {/* <FontAwesomeIcon icon={faExclamationTriangle}/> */}
        <span className="ms-3">{text}</span>
    </div>;
    toast(text_content, options);
};

export const notifyEffects = {
    notifyAction
};