import React, { Fragment, useEffect } from "react";
import { ButtonCustom } from "../../../components/utility/custom-buttons/view";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ConnectedPlatformsModel } from "../../../models/connected-platforms-model";
import { exchangeEffects } from "../actions/exchangeEffects";
import CustomScroll from "react-custom-scroll";
import { Loader } from "../../../components/utility/loader/view";
import { AddExchangeModal } from "../../../components/utility/add-exchange-modal/view";
import { refreshEffects } from "../../../components/priceFetcher/refreshEffects";

const HEADER_KEYS = ["EXCHANGE", "API", "TYPE", ""];

const renderTableHeader = () => {
  return (
    <div className="custom-table-row table-header">
      {HEADER_KEYS.map((key, i) => (
        <div
          className="custom-table-header-cell font-size-14 font-weight-medium"
          key={`header-${i}`}
        >
          {key}
        </div>
      ))}
    </div>
  );
};

const renderRow = (data, index, clickHandle, loader) => {
  const { name, api, type, accountId } = data;
  const label = loader[accountId] ? <Loader /> : "Delete";
  return (
    <div
      className="custom-table-row table-body font-size-14 font-weight-regular"
      key={`body-${index}`}
    >
      <div className="custom-table-body-cell">{name}</div>
      <div className="custom-table-body-cell">{api}</div>
      <div className="custom-table-body-cell">{type}</div>
      <div className="custom-table-body-cell">
        <ButtonCustom
          label={label}
          variant="danger"
          name={accountId}
          value={api}
          onClick={clickHandle}
          disabled={loader[accountId]}
        />
      </div>
    </div>
  );
};

const renderTableBody = (data, clickHandle, loader) =>
  data.map((d, i) => renderRow(d, i, clickHandle, loader));

export const MyExchanges = () => {
  const exchanges = useStoreState((state) => state.coinData.exchanges);
  const state = ConnectedPlatformsModel.useStoreState((state) => state);
  const actions = ConnectedPlatformsModel.useStoreActions((actions) => actions);
  const mainActions = useStoreActions((actions) => actions);
  const clickHandle = (e) =>
    actions[exchangeEffects.actionTypes.MY_PROFILE_EXCHANGE_REMOVED]({
      keyId: e.target.name,
      api: e.target.value,
    });
  const exchangeHandle = () =>
    actions[exchangeEffects.actionTypes.MY_PROFILE_EXCHANGE_ADDED](true);
  useEffect(() => {
    actions[exchangeEffects.actionTypes.MY_PROFILE_EXCHANGES_LIST_UPDATED](
      exchanges
    );
  }, [exchanges]);
  useEffect(() => {
    mainActions[refreshEffects.actionTypes.REFRESH_DATA_INVOKED]({
      action: "refresh",
      loading: false,
      type: "table",
    });
  }, [state.exchangeSynchronisation]);
  return (
    <Fragment>
      <div className="title font-size-20 font-weight-medium">My Exchanges</div>
      <div className="action-button text-end">
        <ButtonCustom
          label="Add exchange"
          variant="primary"
          onClick={exchangeHandle}
        />
      </div>
      <div className="exchanges-table custom-table">
        {renderTableHeader()}
        <CustomScroll
          heightRelativeToParent="100%"
          className="custom-scroll-bar"
        >
          {renderTableBody(state.exchangesList, clickHandle, state.loader)}
        </CustomScroll>
        <AddExchangeModal
          show={state.isModalOpen}
          action={
            actions[exchangeEffects.actionTypes.MY_PROFILE_EXCHANGE_ADDED]
          }
        />
      </div>
    </Fragment>
  );
};
