import React from 'react';
import Select, { components } from 'react-select';
import CustomScroll from 'react-custom-scroll';
import { baseStyles } from './styleHelper';

const DropdownIndicator = props => {
    const {selectProps: {menuIsOpen}} = props;
    const chevron = menuIsOpen ? 'up' : 'down';
    return (
        <components.DropdownIndicator {...props}>
            <i className={`fa fa-chevron-${chevron}`}></i>
        </components.DropdownIndicator>
    );
};

const MenuList = props => {
   return <components.MenuList {...props}>
       <CustomScroll flex="1" className="custom-scroll-bar-multi-select">
           {props.children}
       </CustomScroll>
        </components.MenuList>
};

export const MultiplyCustomSelector = props => {
    const {options, initialValue, onChangeHandle, isLoading, isDisabled, value, isMulti, closeMenuOnSelect, placeholder} = props;
    return <Select
        styles={baseStyles}
        theme={baseStyles.theme}
        components={{ DropdownIndicator, MenuList }}
        defaultValue={initialValue}
        closeMenuOnSelect={closeMenuOnSelect}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onChange={onChangeHandle}
        options={options}
        value={value}
        isMulti={isMulti}
        placeholder={placeholder} // New
    />;
};
