import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { BorderedButton } from '../../../../components/utility/bordered-button/view';
import { constants } from '../../constants';
import { ImagesGenerator } from './imagesGenerator/imagesGenerator';
import './styles.scss';

const { INVEST_CONTENT: { START: { TOPIC, POST_TOPIC, DESCRIPTION, BUTTON }, HOLD, STATISTICS: { STATISTICS_TOPIC } } } = constants;

export const InvestPage = () => {
    return (
        <>
            <div className='invest-page'>
                <div className='invest-start-content'>
                    <div className='left-column font-inter'>
                        <p className='heading font-weight-bold'>
                            {TOPIC}
                        </p>
                        <div className='font-size-27 font-weight-bold'>{POST_TOPIC}</div>
                        <p className='description font-size-20'>
                            {DESCRIPTION}
                        </p>
                        <div className='d-inline-flex'>
                            <LinkContainer to='/login'>
                                <a>
                                    <BorderedButton>
                                        {BUTTON}
                                    </BorderedButton>
                                </a>
                            </LinkContainer>
                        </div>
                    </div>
                    <div className='spaceship-img d-flex justify-content-end'>
                        <img src='../dashboard-library/home-page-images/spaceship.svg' />
                    </div>
                    <div className='empty-bg'></div>
                </div>
                <div className='hold font-inter'>
                    <p className='font-size-20 d-flex justify-content-center'>{HOLD.LABEL}</p>
                    <p className='hold-heading font-weight-bold d-flex justify-content-center'>
                        {HOLD.TOPIC}
                    </p>
                    <div className='font-size-20 d-flex justify-content-center'>
                        <p className='hold-description'>{HOLD.TEXT}</p>
                    </div>
                </div>
                <div className='statistics font-inter'>
                    <p className='statistics-heading d-flex justify-content-center font-weight-bold'>
                        {STATISTICS_TOPIC}
                    </p>
                    <div className='statistics-content'>
                        <ImagesGenerator />
                    </div>
                </div>
            </div>
        </>
    );
};
