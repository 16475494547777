import { thunk, action } from 'easy-peasy';
import { dataHelper } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { serviceUtils } from '../../../service/serviceUtils';
import { vestingConstants } from '../constants';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';

const { USDT_ADDRESSES } = vestingConstants;
const URL = '/web3/get-safe-buy-accs';

const actionTypes = {
    VEST_MULTISELECT_SET: 'VEST_MULTISELECT#SET',
    VEST_BALANCE_FETCHED: 'VEST_BALANCE#FETCHED',
    VEST_MODAL_INVOKED: 'VEST_MODAL#INVOKED',
    VEST_PERIOD_TAB_TOGGLED: 'VEST_PERIOD_TAB#TOGGLED',
    VEST_USDT_AMOUNT_ENTERED: 'VEST_USDT_AMOUNT#ENTERED',
    VEST_FORM_VALIDATION: 'VEST_FORM#VALIDATION',
    VEST_VESTING_STARTED: 'VEST_VESTING#STARTED',
    VEST_VESTING_EARLY_EXIT: 'VEST_VESTING_EARLY#EXIT',
    VEST_VESTING_WITHDRAW: 'VEST_VESTING#WITHDRAW',
    VEST_ENABLE_INVOKED: 'VEST_ENABLE#INVOKED',
    VEST_DATA_FETCHED: 'VEST_DATA#FETCHED',
    VEST_DATA_FETCH_SUCCEEDED: 'VEST_DATA_FETCH#SUCCEEDED',
    VEST_DATA_FETCH_FAILED: 'VEST_DATA_FETCH#FAILED'
};

const fetchVesting = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${URL}?address=${userWallet}`,
        method: 'get',
        errorActionType: actions[actionTypes.VEST_DATA_FETCH_FAILED],
        successActionType: actions[actionTypes.VEST_DATA_FETCH_SUCCEEDED]
    });
};

const fetchSafeBuySuccess = (state, payload) => {
    const { response } = payload;
    state.vesting.invested.data = response;
    state.vesting.invested.chainObject = response['BSC'];
    state.vesting.invested.chainObject.amountToClaim = response['BSC']?.amountToClaim;
    state.vesting.invested.isInvested = response['BSC'].lockTime === '0' && response['BSC'].startlock === '0' ? false : true;
};

const fetchSafeBuyFail = (state, payload) => {};

const multiSelectHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value, invested } = payload;
    const chainObject = invested[value.value];

    serviceUtils.metamaskSwitchEthereumChain(value.value).then(res => { res.result ? updateState([
        {
            path: 'vesting.invested.isInvested',
            value: chainObject.lockTime === '0' && chainObject.startlock === '0' ? false : true
        },
        {
            path: 'vesting.selector.value',
            value
        },
        {
            path: 'vesting.chain',
            value: value.value
        },
        {
            path: 'buy.loader',
            value: false,
        },
        {
            path: 'buy.buyFormInvoked',
            value: false
        },
        {
            path: 'vesting.invested.chainObject',
            value: chainObject
        }
    ]) : console.log('metamask user', res); });
};

const modalInvoked = (state, payload) => {
    const { name } = payload;

    switch(name) {
    case 'close-modal': state.vesting.cancel.show = false; break;
    case 'open-modal': state.vesting.cancel.show = true; break;
    }
};

const periodTabToggled = (state, payload) => {
    const { period } = payload;

    state.vesting.tab = period;

    state.vesting.startDate = dataHelper.countFutureDate(0);
    state.vesting.endDate = dataHelper.countFutureDate(+period);
};

const usdtAmountHandle = (state, payload) => {
    const { usdtAmount, days } = payload;

    const mcsAmount = dataHelper.getExpectedMCSAmount(usdtAmount, +days);
    state.vesting.form.mcsAmount = mcsAmount;
};

const fetchBalance = async (actions, payload) => {
    serviceUtils.metamaskSwitchEthereumChain(payload.currentChain).then(res => { res.result ? doBalanceRequest(actions, payload) : console.log('metamask user', res); });
};

const doBalanceRequest = async (actions, payload) => {
    const { updateState } = actions;
    const { currentChain } = payload;

    const address = USDT_ADDRESSES.find(chain => chain.chain === currentChain).usdtAddress;

    const balance = await dataHelper.getUsdtBalance(address);
    updateState({ path: 'vesting.balance', value: balance });
};

const formValidate = (state, payload) => {
    const { value } = payload;

    state.vesting.form.validity = Number(value) >= 100 && Number(value) <= state.vesting.balance ? true : false;
};

const startVesting = (actions, payload) => {
    const { chain } = payload;
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doVesting(actions, payload) : console.log('Error'); });
};

const doVesting = async (actions, payload) => {
    const { userWallet, contractAddress, days, mcsAmount } = payload;

    const transactionParameters = dataHelper.startSafeBuy(userWallet, contractAddress, days, mcsAmount);

    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const earlyExitVesting = (actions, payload) => {
    const { chain } = payload;
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doExitVesting(actions, payload) : console.log('Error'); });
};

const doExitVesting = async (actions, payload) => {
    const { userWallet, contractAddress } = payload;

    const transactionParameters = dataHelper.earlyExitSafeBuy(userWallet, contractAddress);

    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const withdrawMCS = (actions, payload) => {
    const { chain } = payload;
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doWithdrawMCS(actions, payload) : console.log('Error'); });
};

const doWithdrawMCS = async (actions, payload) => {
    const { userWallet, contractAddress } = payload;

    const transactionParameters = dataHelper.withdrawAvailable(userWallet, contractAddress);

    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const enableInvoked = (actions, payload) => {
    const { chain } = payload;
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doEnable(actions, payload) : console.log('Error'); });
};

const doEnable = async (actions, payload) => {
    const { userWallet, usdtAddress, contractAddress } = payload;

    const transactionParameters = dataHelper.getApproveParameters(userWallet, usdtAddress, contractAddress);

    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const actionHandlers = {
    [actionTypes.VEST_MULTISELECT_SET]: thunk((actions, payload) => multiSelectHandle(actions, payload)),
    [actionTypes.VEST_BALANCE_FETCHED]: thunk(async (actions, payload) => fetchBalance(actions, payload)),
    [actionTypes.VEST_MODAL_INVOKED]: action((state, payload) => modalInvoked(state, payload)),
    [actionTypes.VEST_PERIOD_TAB_TOGGLED]: action((state, payload) => periodTabToggled(state, payload)),
    [actionTypes.VEST_USDT_AMOUNT_ENTERED]: action((state, payload) => usdtAmountHandle(state, payload)),
    [actionTypes.VEST_FORM_VALIDATION]: action((state, payload) => formValidate(state, payload)),
    [actionTypes.VEST_VESTING_STARTED]: thunk(async (actions, payload) => startVesting(actions, payload)),
    [actionTypes.VEST_VESTING_EARLY_EXIT]: thunk(async (actions, payload) => earlyExitVesting(actions, payload)),
    [actionTypes.VEST_VESTING_WITHDRAW]: thunk(async (actions, payload) => withdrawMCS(actions, payload)),
    [actionTypes.VEST_ENABLE_INVOKED]: thunk(async (actions, payload) => enableInvoked(actions, payload)),
    [actionTypes.VEST_DATA_FETCHED]: thunk(async (actions, payload) => fetchVesting(actions, payload)),
    [actionTypes.VEST_DATA_FETCH_SUCCEEDED]: action((state, payload) => fetchSafeBuySuccess(state, payload)),
    [actionTypes.VEST_DATA_FETCH_FAILED]: action((state, payload) => fetchSafeBuyFail(state, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const vestingEffects = {
    actionTypes,
    actionHandlers
};