import React, { useEffect, useState } from 'react';
import './styles.scss';

/* eslint-disable */

import {
    Button,
    Form,
    FormGroup,
} from 'react-bootstrap';
import axios from 'axios';
import { serviceUtils } from '../../service/serviceUtils';

const View = ({ exchanges, predictions, getInfo, getServerTime, binanceTicker24h, addFavoriteAsset, deleteFavoriteAsset, binanceMarketTrade, binanceSmartTrade, binanceSmartTradeQA, binanceLimitTrade, binanceGetOrder, binanceCancelOrder, binanceGetAccount, deleteUserExchange, getExchanges, getUserExchanges, initUserUpdate, binanceGetDashboard, addExchange, addUserMessage, updateUserMessage, deleteUserMessage, getPredictions }) => {
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [lastClicked, setLastClicked] = useState(null);

    console.info("inside view");
    console.info("inside view", predictions);
    return (
        <div className="MoneyRPlatform feed-content h-100 p-3 pb-3">
            <div className="mb-3">
                <div className="mb-3">
                    <Form>
                        <Button onClick={initUserUpdate} color="primary" className="ml-1">initUserUpdate</Button>
                        <FormGroup>
                            <label hidden>Exchange</label>
                            <input type="text" name="exchange" id="usrExchangeinput" placeholder="Binance" disabled value="binance" />
                            <label hidden>API Key</label>
                            <input type="text" name="usrKey" id="usrKeyinput" placeholder="Key" />
                            <label hidden>API Secret</label>
                            <input type="text" name="usrSecret" id="usrSecretinput" placeholder="Secret" />
                        </FormGroup>
                        <Button onClick={addExchange} color="primary" className="mt-3">addExchange</Button>
                        <Button onClick={getExchanges} color="primary" className="ml-1">GetExchanges</Button>
                        <Button onClick={getUserExchanges} color="primary" className="ml-1">GetUserExchanges</Button>
                        <Button onClick={deleteUserExchange} color="primary" className="ml-1">DeleteUserExchange</Button>
                    </Form>
                </div>
            </div>
            <div className="mb-3">
                <Form>
                    <div className="mb-3">
                        <FormGroup>
                            <label hidden>symbol to check</label>
                            <input type="text" name="symbol" id="symbolinput" placeholder="symbol for example BTCUSDT" />
                        </FormGroup>
                    </div>
                    <Button onClick={binanceTicker24h} color="primary" className="ml-1">GetTicker24</Button>
                    <Button onClick={addFavoriteAsset} color="primary" className="ml-1">addFavoriteAsset</Button>
                    <Button onClick={deleteFavoriteAsset} color="primary" className="ml-1">deleteFavoriteAsset</Button>
                    {/* <Button onClick={binanceGetAccount} color="primary" className="ml-1">GetAccount</Button> */}
                    <Button onClick={binanceGetDashboard} color="primary" className="ml-1">GetDashboard</Button>
                    <Button onClick={getInfo} color="primary" className="ml-1">GetBinanceInfo</Button>
                    <Button onClick={getServerTime} color="primary" className="ml-1">GetBinanceServerTime</Button>
                </Form>
            </div>
            <div className="mb-3">
                <Form>
                    <div className="mb-3">
                        <FormGroup>
                            <label hidden>orderIdinput to check</label>
                            <input type="text" name="orderId" id="orderIdinput" placeholder="order Id" />
                        </FormGroup>
                    </div>
                    <Button onClick={binanceGetOrder} color="primary" className="ml-1">GetOrderById</Button>
                    <Button onClick={binanceCancelOrder} color="primary" className="ml-1">CancelOrderById</Button>
                </Form>
            </div>
            <div className="mb-3">
                <p>Smart: {'{"exchange":"binance","userName":"eda2bfe9-238d-4580-8074-a7bf6227371a","keyId":"e92feaf3-ac14-5fdf-ab2a-c9e196c8c933","data":{"symbol":"BNBUSDT","buy":{"trailing":0,"quantity":"3"},"takeProfit":[{"priceMultiplier":"1.003","trailing":0.01,"quantityPercentage":0.3},{"priceMultiplier":"1.004","trailing":0.01,"quantityPercentage":0.3},{"priceMultiplier":"1.005","trailing":0.01,"quantityPercentage":0.2},{"priceMultiplier":"1.006","trailing":0.01,"quantityPercentage":0.2}],"stopLoss":{"delta":0.01,"delay":20,"trailing":true,"parameter":"l"}},"botType":"SMART_TRADE"}'} </p>
                <p>Market: {'{""keyId":"e92feaf3-ac14-5fdf-ab2a-c9e196c8c933","exchange":"binance","symbol":"BTCUSDT","side":"BUY","type":"MARKET","quantity":"0.0004"}'} </p>
                <p>Limit: {'{""keyId":"e92feaf3-ac14-5fdf-ab2a-c9e196c8c933","symbol":"BTCUSDT","side":"BUY","type":"MARKET","quantity":"0.0004","price":"44000","timeInForce":"GTC"}'} </p>
                <Form>
                    <div className="mb-3">
                        <FormGroup>
                            <label hidden>stParamsinput to place</label>
                            <input type="text" name="stParams" id="stParamsinput" placeholder="SmartTrade JSON here" />
                        </FormGroup>
                    </div>
                    <Button onClick={binanceSmartTrade} color="primary" className="ml-1">PlaceSmartTrade</Button>
                    <Button onClick={binanceSmartTradeQA} color="primary" className="ml-1">PlaceSmartTradeQA QQQAAA</Button>
                    <Button onClick={binanceMarketTrade} color="primary" className="ml-1">MarketTrade</Button>
                    <Button onClick={binanceLimitTrade} color="primary" className="ml-1">LimitTrade</Button>
                </Form>
            </div>
            <div className="mb-3">
                <p>Add message to self:</p>
                <Form>
                    <div className="mb-3">
                        <FormGroup>
                            <label hidden>Title</label>
                            <input type="text" name="title" id="titleInput" placeholder="Message Title here" />
                            <label hidden>Content</label>
                            <input type="text" name="content" id="contentInput" placeholder="Message Text here" />
                        </FormGroup>
                    </div>
                    <Button onClick={addUserMessage} color="primary" className="ml-1">addUserMessage</Button>
                    <Button onClick={updateUserMessage} color="primary" className="ml-1">updateUserMessage</Button>
                    <Button onClick={deleteUserMessage} color="primary" className="ml-1">deleteUserMessage</Button>
                </Form>
            </div>

            <div className="mb-3">
                <p>Predictions:</p>
                <Form>
                    <Button onClick={getPredictions} color="primary" className="ml-1">getPredictions</Button>
                </Form>
                {predictions}
            </div>
            <div className="mb-3">
                <p>AAAAA:</p>
                <div>{predictions && predictions.predictions ? predictions.predictions : 'nothing'}</div>
            </div>
        </div>
    );
};


export const Platform = () => {
    const [alert, setAlert] = useState();
    const [alertStyle, setAlertStyle] = useState('info');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertDismissable, setAlertDismissable] = useState(false);
    const [exchanges, setExchanges] = useState([]);
    const [predictions, setPredictions] = useState([]);

    const onDismiss = () => {
        setAlertVisible(false);
    };

    const getInfo = async () => {
        const symbolinput = document.getElementById('symbolinput');
        const symbol = symbolinput.value;
        console.log(symbol);
        let getInfoPayload = {}
        if ( typeof symbol != "undefined" && symbol !== '')
        {
            getInfoPayload = {
                'symbol': symbol
            };
        }

        const result = await axios({
            url: '/binance_prod/i',
            params:getInfoPayload,
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };


    const updateAlert = ({ alert, style, visible, dismissable }) => {
        setAlert(alert ? alert : '');
        setAlertStyle(style ? style : 'info');
        setAlertVisible(visible);
        setAlertDismissable(dismissable ? dismissable : null);
    };

    const getServerTime = async () => {
        const result = await axios({
            url: '/binance/time/',

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceGetAccount = async () => {
        const result = await axios({
            url: '/binance/account/',

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const getExchanges = async () => {
        const result = await axios({
            url: '/exs/',
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            console.log(result.data);
        }
    };

    const getUserExchanges = async () => {
        const result = await axios({
            url: '/uexs/',

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            console.log(result.data);
        }
    };

    const initUserUpdate = async () => {
        const result = await axios({
            url: '/initupdate/',
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            console.log(result.data);
        }
    };

    const deleteUserExchange = async () => {
        const usrKeyinput = document.getElementById('usrKeyinput');
        const keyId = usrKeyinput.value;
        console.log(keyId);
        if (!keyId || keyId === '') return;

        const keyIdData = {
            'keyId': keyId,
            'api': 'binance'
        };

        const result = await axios({
            method: 'DELETE',
            url: '/uex',
            data: keyIdData

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceGetDashboard = async () => {
        const result = await axios({
            url: '/dashboard',

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };


    const binanceTicker24h = async () => {
        const symbolinput = document.getElementById('symbolinput');
        const symbol = symbolinput.value;
        console.log(symbol);
        if (!symbol || symbol === '') return;

        const tickerSymbol = {
            'symbol': symbol
        };

        const result = await axios({
            method: 'POST',
            url: '/binance_prod/tic24',
            data: tickerSymbol

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const deleteFavoriteAsset = async () => {
        const assetinput = document.getElementById('symbolinput');
        const asset = assetinput.value;
        console.log(asset);
        if (!asset || asset === '') return;

        const favAss = {
            'asset': asset
        };

        const result = await axios({
            method: 'DELETE',
            url: '/favorites',
            data: favAss

        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const addFavoriteAsset = async () => {
        const assetinput = document.getElementById('symbolinput');
        const asset = assetinput.value;
        console.log(asset);
        if (!asset || asset === '') return;

        const favAss = {
            'asset': asset
        };

        const result = await axios({
            method: 'PUT',
            url: '/favorites',
            data: favAss
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };
    // addFavoriteAsset, deleteFavoriteAsset

    const binanceSmartTrade = async () => {
        const stParamsinput = document.getElementById('stParamsinput');
        const stParams = JSON.parse(stParamsinput.value);
        console.log(stParams);
        if (!stParams || stParams === '') return;


        const result = await axios({
            method: 'POST',
            url: '/binance/st/',
            data:stParams
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceSmartTradeQA = async () => {
        const stParamsinput = document.getElementById('stParamsinput');
        const stParams = JSON.parse(stParamsinput.value);
        console.log(stParams);
        if (!stParams || stParams === '') return;

        const result = await axios({
            method: 'POST',
            url: '/binance_prod/stqa/',
            data:stParams
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceMarketTrade = async () => {
        const stParamsinput = document.getElementById('stParamsinput');
        const stParams = JSON.parse(stParamsinput.value);
        console.log(stParams);
        if (!stParams || stParams === '') return;


        const result = await axios({
            method: 'POST',
            url: '/binance/order',

            data:stParams
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceLimitTrade = async () => {
        const stParamsinput = document.getElementById('stParamsinput');
        const stParams = JSON.parse(stParamsinput.value);
        console.log(stParams);
        if (!stParams || stParams === '') return;


        const result = await axios({
            method: 'POST',
            url: '/binance/limit_order',

            data:stParams
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const addUserMessage = async () => {
        const titleInput = document.getElementById('titleInput');
        const contentInput = document.getElementById('contentInput');
        const title = titleInput.value;
        const content = contentInput.value;
        console.log(title);
        if (!title || title === '') return;
        console.log(content);
        if (!content || content === '') return;

        const userMessage = {
            'title': title,
            'content': content
        };

        const result = await axios({
            method: 'PUT',
            url: '/messages',
            data: { messages: [userMessage]}
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const updateUserMessage = async () => {
        const titleInput = document.getElementById('titleInput');
        const contentInput = document.getElementById('contentInput');
        const title = titleInput.value;
        const content = contentInput.value;
        console.log(title);
        if (!title || title === '') return;
        console.log(content);
        if (!content || content === '') return;

        const userMessage = {
            'id': title,
            'status': content
        };

        const result = await axios({
            method: 'POST',
            url: '/messages',
            data: { messages: [userMessage]}
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const deleteUserMessage = async () => {
        const titleInput = document.getElementById('titleInput');
        // const contentInput = document.getElementById('contentInput');
        const title = titleInput.value;
        const content = contentInput.value;
        console.log(title);
        if (!title || title === '') return;
        // console.log(content);
        // if (!content || content === '') return;

        const userMessage = {
            'id': title
            // ,
            // 'status': content
        };

        const result = await axios({
            method: 'DELETE',
            url: '/messages',
            data: { messages: [userMessage]}
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceGetOrder = async () => {
        const symbol = document.getElementById('symbolinput').value;
        const orderId = document.getElementById('orderIdinput').value;
        console.log(symbol);
        if (!symbol || symbol === '') return;
        console.log(orderId);
        if (!orderId || orderId === '') return;

        const getOrderPayload = {
            'symbol': symbol,
            'orderId': orderId
        };

        const result = await axios({
            method: 'GET',
            url: '/binance/order/',
            data:getOrderPayload,
            params:getOrderPayload,
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const binanceCancelOrder = async () => {
        const symbol = document.getElementById('symbolinput').value;
        const orderId = document.getElementById('orderIdinput').value;
        console.log(symbol);
        if (!symbol || symbol === '') return;
        console.log(orderId);
        if (!orderId || orderId === '') return;

        const cancelOrderPayload = {
            'symbol': symbol,
            'orderId': orderId
        };

        const result = await axios({
            method: 'DELETE',
            url: '/binance/order/',
            data:cancelOrderPayload
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            // console.log(result.data.Items);
        }
    };

    const addExchange = async (event) => {
        const usrKeyinput = document.getElementById('usrKeyinput');
        const usrSecretinput = document.getElementById('usrSecretinput');
        const usrExchangeinput = document.getElementById('usrExchangeinput');

        const usrKey = usrKeyinput.value;
        const usrSecret = usrSecretinput.value;
        const usrExchange = usrExchangeinput.value;

        console.log(usrKey);
        if (!usrKey || usrKey === '') return;
        console.log(usrSecret);
        if (!usrSecret || usrSecret === '') return;
        console.log(usrExchange);
        // if (!usrExchange || usrExchange === '') return;


        const apiSetupData = {
            'api': 'binance',
            'key': usrKey,
            'secret': usrSecret
        };

        const result = await axios({
            method: 'POST',
            url: '/uex',
            data: apiSetupData
        });

        if (result && result.status === 200) {
            // getInitialData();
            usrKeyinput.value = '';
            usrSecretinput.value = '';
            // getInitialData();
        }
    };

    const getInitialData = async () => {
        // const result = await axios({
        //     url: '/uexs'
        // }).catch(error => {
        //     console.log(error);
        // });

        // console.info(result);

        // if (result && result.status === 200) {
        //     setExchanges(result.data.Items);
        //     // console.log(result.data.Items);
        //     // setDisplayData(result.data.Items);
        // }
    };

    const getPredictions = async () => {
        const result = await axios({
            url: '/predictions',
        }).catch(error => {
            console.log(error);
        });
        console.log(result);
        if (result && result.status === 200) {
            console.log(result.data);
            setPredictions(result.data);
        }
    };

    useEffect(() => {
        getInitialData();
    }, ['predictions']);

    return <>
        <View
            updateAlert={updateAlert}
            exchanges={exchanges}
            predictions={predictions}
            getInfo={getInfo}
            getServerTime={getServerTime}
            binanceTicker24h={binanceTicker24h}
            addFavoriteAsset={addFavoriteAsset}
            deleteFavoriteAsset={deleteFavoriteAsset}
            binanceSmartTrade={binanceSmartTrade}
            binanceSmartTradeQA={binanceSmartTradeQA}
            binanceMarketTrade={binanceMarketTrade}
            binanceLimitTrade={binanceLimitTrade}
            binanceGetOrder={binanceGetOrder}
            binanceCancelOrder={binanceCancelOrder}
            binanceGetAccount={binanceGetAccount}
            getExchanges={getExchanges}
            getUserExchanges={getUserExchanges}
            initUserUpdate={initUserUpdate}
            deleteUserExchange={deleteUserExchange}
            binanceGetDashboard={binanceGetDashboard}
            addExchange={addExchange}
            addUserMessage={addUserMessage}
            updateUserMessage={updateUserMessage}
            deleteUserMessage={deleteUserMessage}
            getPredictions={getPredictions}
        />
    </>;
};
