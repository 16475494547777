import React, { Fragment } from 'react';
import CustomScroll from 'react-custom-scroll';
import { Loader } from '../../../../components/utility/loader/view';
import { EmptySearchState } from '../../../../components/utility/empty-search-state/view';
import { PopoverLayout } from '../../../../components/utility/popover/view';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import { dataHelper } from '../../dataHelpers/dataHelper';

const assetsRow = (assetData, type) => {
    const { blockchainName, exchange, valueSymbol, valueHuman, usdValue } = assetData;
    return (
        <div className='assets-item-grid font-size-12'>
            {type === 'defi' ? <div className='d-flex align-items-center chain value-decoration'>
                <PopoverLayout title={false} icon={blockchainName === 'AVAXc' ? blockchainName.slice(0, -1).toLowerCase() : blockchainName.toLowerCase()} message={blockchainName} />
                <div>{blockchainName}</div>
            </div> :
                <div className='d-flex align-items-center chain value-decoration'>
                    <div>{exchange}</div>
                </div>}
            <div className='d-flex align-items-center asset value-decoration'>
                <PopoverLayout title={false} icon={valueSymbol.toLowerCase()} message={valueSymbol} />
                <div>{valueSymbol}</div>
            </div>
            <div className='d-flex align-items-center amount value-decoration'>{`${Number(valueHuman).toFixed(2)} ${valueSymbol.toUpperCase()}`}</div>
            <div className='d-flex align-items-center sum value-decoration'>{`$ ${Number(usdValue).toFixed(2)}`}</div>
        </div>
    );
};

const assetsHeader = type => {
    return (
        <div className='assets-header-grid'>
            {type === 'defi' ? <div>Chain</div> : <div>Exchange</div>}
            <div>Asset</div>
            <div>Amount</div>
            <div>Sum</div>
        </div>
    );
};

export const availableAssetsContent = (availableAssets, state, type) => {
    const data = dataHelper.sortAssets(availableAssets);

    return (
        <div className='available-assets'>
            {!state.loaders[type] ? !isEmpty(data) ? <><div className='assets-header'>{assetsHeader(type)}</div>
                <CustomScroll
                    heightRelativeToParent='calc(100% - 70px)'
                    className='custom-scroll-bar pb-5'
                >
                    <div className='assets-grid'>
                        {(data || new Array(10).fill(null)).map(
                            (el, key) => <div className="assets-row" key={key}>{assetsRow(el, type)}</div>
                        )}
                    </div>
                </CustomScroll></> : <EmptySearchState /> : <Loader size={'6x'} />}
        </div>
    );
};
