import React, { useEffect, useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import { DefiMultiChainDashboardState } from '../../models/defi-multichain-dashboard-model';
import CustomScroll from 'react-custom-scroll';
import { TopContent } from './templates/topContent';
import { AnalyticsContent } from './templates/analyticsContent';
import { WalletContent } from './templates/walletContent';
import { defiMultichainEffects } from './actions/defiMultichainEffects';
import { VenusContent } from './templates/venusContent';
import { CompoundContent } from './templates/compoundContent';
import { PoolsContent } from './templates/poolsContent';
import { FarmsContent } from './templates/farmsContent';
import { mockData } from '../poolsPage/mockData';
import './styles.scss';

export const DefiMultiChainDashboardLayout = () => {
    const state = DefiMultiChainDashboardState.useStoreState(state => state);
    const actions = DefiMultiChainDashboardState.useStoreActions(actions => actions);
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const ref = useRef();
   
   useEffect(() => {
    
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_ALL_FETCHED]();
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_VENUS_FETCHED]({ userWallet });
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_COMPOUND_FETCHED]({ userWallet });
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_POOLS_FETCHED]({ userWallet });
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_FARMS_FETCHED]({ userWallet });
    setTimeout(() => (
        actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED]({ userWallet }), 2500));
 
}, []);


    return <div ref={ref} className="page-container">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
            <div className="defi-multichain-dashboard-layout font-inter content" >  
                <TopContent actions={actions} state={state} />
                <AnalyticsContent actions={actions} state={state} mockData = {mockData}/>
                <WalletContent actions={actions} state={state} />
                <VenusContent actions={actions} state={state} />
            {/*<CompoundContent actions={actions} state={state} />*/} 
                <PoolsContent actions={actions} state={state} />
                <FarmsContent actions={actions} state={state} />
            </div>
        </CustomScroll>
    </div>;
};

export const DefiMultiChainDashboardPage = () => <DefiMultiChainDashboardState.Provider>
    <DefiMultiChainDashboardLayout/>
</DefiMultiChainDashboardState.Provider>;