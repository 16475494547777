import React from 'react';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';
import { useStoreState } from 'easy-peasy';
import numeral from 'numeral';

const { TOP_CONTENT: { HEADER, CHAINS } } = constants;

export const TopContent = props => {
    const { actions, state } = props;
    const userWallet = useStoreState(state => state.authentication.userData.userId);

    const tabToggleHandle = (heading, tabPlace) => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TAB_TOGGLED]({ tab: heading, tabPlace });
    const protocolsToggleHandle = chain => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_PROTOCOL_TOGGLED]({ chain, userWallet, state });
    const activeTabClass = tab => tab === state.tab ? 'active' : '';
    const activeChainClass = picked => picked === state.chains.picked ? 'active' : '';
    const totalBalance = state.wallet.allTotalBalance;

    return <div className="top-content-layout">
        <div className="top-content-header d-inline-flex">
            {
                HEADER.map((heading, index) => <div className={`heading-link me-5 ${activeTabClass(heading)}`} key={index} onClick={() => tabToggleHandle(heading, 'top-content')}>{heading}</div>)
            }
        </div>
        <div className="top-content-chains d-inline-flex">
            <div className={`chain-item me-3 ${activeChainClass('All')}`} onClick={() => protocolsToggleHandle('All')}>{CHAINS.ALL}</div>
            {
                state.chains.list.map(chain => <div className={`chain-item me-3 ${activeChainClass(chain.request)}`} key={chain.symbol} onClick={() => protocolsToggleHandle(chain.request)}>
                    <img className="me-1" width={16} height={16} src={`../dashboard-library/nets/${chain.symbol}.svg`}/>
                    <div>{chain.chain}</div>
                    <i className="fa-regular fa-plus ms-1"></i>
                </div>)
            }
            <div className={`chain-item ${activeChainClass('Others')}`} onClick={() => protocolsToggleHandle('Others')}>+20</div>
        </div>
        <div className="top-content-numbers">
            <div className="number-item"><div className="number">${numeral(totalBalance).format('0,00.00')}</div><div className="title">Balance</div></div>
            <div className="number-item"><div className="number">${numeral(state.wallet.available).format('0,00.00')}</div><div className="title">Available</div></div>
            <div className="number-item"><div className="number">{numeral(state.wallet.change).format('0,00.00')}%</div><div className="title">Change</div></div>
            <div className="number-item"><div className="number">${numeral(state.wallet.invested).format('0,00.00')}</div><div className="title">Invested</div></div>
        </div>
    </div>;
};