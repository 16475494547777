import React from 'react';
import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { notifyEffects } from '../notifications/notifyEffects';
import { mainEffects } from '../../priceFetcher/mainEffects';
import { delay } from 'lodash';
import { dataHelper } from '../../../pages/smartTradePage/actions/dataHelpers/dataHelper';
import { constants } from '../../../pages/smartTradePage/constants';
import { isEmpty } from 'lodash';
import { Asset } from '../asset-component/view';
import { utils } from '../../../utils/utils';

const REQUEST_URL = '/uex';
const {CONTROLS: {LABEL_COIN_MAIN}} = constants;
const actionTypes = {
    TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_INVOKED: 'TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE#INVOKED',
    TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_SUCCEED: 'TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE#SUCCEED',
    TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_FAILED: 'TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE#FAILED',
    SMART_TRADE_SELECTORS_CHANGED: ' SMART_TRADE_SELECTORS#CHANGED'
};

const refreshDashboardHandler = (state, actions) => {
    state.loaders.signals = true;
    state.loaders.bots = true;
    state.loaders.feeds = true;
    state.loaders.history = true;
    state.loaders.table = true;
    delay(actions[mainEffects.actionTypes.DASHBOARD_DATA_REQUESTED], 500); //Fetch main data
};

const fetchAction = (actions, payload) => {
    const { type, exchange, key, secret, sumFlag } = payload;
    const API = {
        'api': type,
        'name': exchange,
        'key': key,
        'secret': secret,
        'sumFlag': sumFlag
    };
    const options = { actions, url: REQUEST_URL, API, payload };


    serviceUtils.HttpService({
        url: options.url,
        method: 'POST',
        data: options.API,
        errorActionType: actions[actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_FAILED],
        successActionType: actions[actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_SUCCEED],
        ...options
    });
};

const selectorHandle = (state, payload) => {
    const {exchange} = payload;

            state.exchangeCoins = exchange.map(k => (
              {
                    value: k.label,
                    label: <div className="icons-select d-flex align-items-center">
                    <img width={24} height={24} src={`../dashboard-library/home-page-images/${k.value.icon?.toLowerCase()}.svg`}
                    alt=""
                    onError={utils.imgErrorHandle}
                    className="img-fluid rounded-start h-100 pe-1 "/>
               {<span className="font-size-14">{k.value.title}</span> }
                    </div>
                }));
         
        
};

const successAction = (state, payload) => {
    const notification = { type: 'success', text: 'You successfully connected to API' };
    const { options: { actions } } = payload;
    refreshDashboardHandler(state, actions);
    notifyEffects.notifyAction(notification);

};

const errorAction = () => {
    const notification = { type: 'danger', text: 'Something goes wrong' };
    notifyEffects.notifyAction(notification);
};

const actionHandlers = {
    [actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_INVOKED]: thunk( async (actions, payload) => fetchAction(actions, payload)),
    [actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_SUCCEED]: action((state, payload) => successAction(state, payload)),
    [actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.SMART_TRADE_SELECTORS_CHANGED]: action((state, payload) => selectorHandle(state, payload)),
};

export const exchangeModalEffects = {
    actionHandlers,
    actionTypes
};