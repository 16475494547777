import { createContextStore } from 'easy-peasy';
import { uiActions } from "../pages/dynamicSplitPage/actions/uiActions";
import { dynamicSplitEffects } from "../pages/dynamicSplitPage/actions/dynamicSplitEffects";

const DYNAMIC_SPLIT_MODEL = {
  widgets: [],
  clickedFavorites: '',
  favoritesSelector: { list: [] },
  ...uiActions.actionHandlers,
  ...dynamicSplitEffects.actionHandlers,
};

export const DynamicSplitState = createContextStore(DYNAMIC_SPLIT_MODEL);
