import { createContextStore } from 'easy-peasy';
import { careersEffects } from '../pages/homePage/templates/careersPage/actions/careersEffects';

const CAREERS_MODEL = {
  isModalOpen: false,
  successfullyApplied: false,
  recaptchaTokenValid: false,

  ...careersEffects.actionHandlers,
};

export const CareersState = createContextStore(CAREERS_MODEL);
