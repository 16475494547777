import { action, thunk } from 'easy-peasy';
import { dataHelper } from './dataHelpers/dataHelper';
import { packHelper } from './dataHelpers/packHelper';

const actionTypes = {
    GRID_BOT_TOGGLE_BALANCES: 'GRID_BOT_TOGGLE_BALANCES',
    GRID_BOT_TOGGLE_TRADING_VIEW: 'GRID_BOT_TOGGLE_TRADING_VIEW',
    GRID_BOT_TOGGLE_STRATEGIES: 'GRID_BOT_TOGGLE_STRATEGIES',
    GRID_BOT_TOGGLE_BTC: 'GRID_BOT_TOGGLE_BTC',
    GRID_BOT_SELECTORS_DATA_UPDATED: 'GRID_BOT_SELECTORS_DATA_UPDATED',
    GRID_BOT_SELECTORS_CHANGED: 'GRID_BOT_SELECTORS_CHANGED',
};

const toggleBalances = (state) => {
    state.showBalances = !state.showBalances;
}

const toggleTradingView = (state) => {
    state.tradingView = !state.tradingView;
}

const toggleStrategies = (state) => {
    state.allStrategy = !state.allStrategy;
    state.manuelStrategy = !state.manuelStrategy;
}

const toggleBTC = (state) => {
    state.baseBTC = !state.baseBTC;
    state.quoteBTC = !state.quoteBTC;
}

const assetsDataHandle = (state, payload) => {
    state.assetsData = payload;
}

const selectorHandle = (state, payload) => {
    const {name} = payload;
    let listData;
    switch(name) {
    case 'init_main_coin_list':
        const {exchange: {value: {api}}} = payload;
        state.exchangeData = {value: payload?.exchange};
        listData = dataHelper.generateMainCoinList(state.assetsData, api);
        state.mainCoinList = listData.list;
        state.coinData = listData.pairs;
        break;
    case 'Coin pair':
        state.singlePair.pairCoin = payload?.value?.value;
        state.singlePair.pairData = packHelper.createSelectedSinglePair({mainCoin: state.singlePair.mainCoin, pair: state.singlePair.pairCoin, exchangeData: state.exchangeData});
        state.singlePair.pairCoinValue = payload?.value;
        break;
    case 'Change Coin':
        const {value: {value}} = payload;
        state.pairCoinList = dataHelper.generatePairCoinList(value, state.coinData);
        state.singlePair.mainCoin = value;
        state.singlePair.pairCoin = null;
        state.singlePair.pairData = {};
        state.singlePair.mainCoinValue = payload?.value;
        state.singlePair.pairCoinValue = null;
        break;
    case 'Change Account':
        state.exchangeData = payload;
        listData = dataHelper.generateMainCoinList(state.assetsData, payload?.value?.value?.api);
        state.mainCoinList = listData.list;
        state.coinData = listData.pairs;
        state.pairCoinList = [];
        state.singlePair.pairData = {};
        state.singlePair.mainCoinValue = null;
        state.singlePair.pairCoinValue = null;
        break;
    }
}

const actionHandlers = {
    [actionTypes.GRID_BOT_TOGGLE_BALANCES]: action(state => toggleBalances(state)),
    [actionTypes.GRID_BOT_TOGGLE_TRADING_VIEW]: action(state => toggleTradingView(state)),
    [actionTypes.GRID_BOT_TOGGLE_STRATEGIES]: action(state => toggleStrategies(state)),
    [actionTypes.GRID_BOT_TOGGLE_BTC]: action(state => toggleBTC(state)),
    [actionTypes.GRID_BOT_SELECTORS_DATA_UPDATED]: action((state, payload) => assetsDataHandle(state, payload)),
    [actionTypes.GRID_BOT_SELECTORS_CHANGED]: action((state, payload) => selectorHandle(state, payload))
};

export const gridBotUiActions = {
    actionTypes,
    actionHandlers
};
