import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

export const DoughnutChart = props => {
    const { data } = props;
    const values = data.map(value => value.fraction);
    const colors = ['#1F55FE', '#F7931A', '#00A859', '#ED32B5', '#05CAFE', '#7828AD', '#E71717', '#E2E3EF'];
    const labels = data.map(label => label.asset);

    return <Doughnut
        datasetIdKey="id"
        updateMode="normal"
        options={{
            cutout: 75
        }}
        data={{
            labels: [...labels],
            datasets: [
                {
                    id: 1,
                    label: '',
                    data: [...values],
                    backgroundColor: [...colors],
                    borderColor: [...colors]
                },
            ],
        }}
    />;
};