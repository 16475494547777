import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./styles.scss";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { CustomTooltip } from "../custom-tooltip/view";

const renderNavigationMenu = (list, menuId) => {
  return list.map((el, key) => {
    const isDisabled = el.disabled === true;
    const isActive = el.id === menuId ? "active" : "";
    return (
      <Dropdown.Item
        key={key}
        className={`navigation-menu-item d-flex align-items-center ${isActive}`}
        eventKey={el.parentId}
      >
        {!isDisabled ? (
          <LinkContainer key={key} to={`/${el.parentId}?elem=${el.id}`}>
            <div className="d-flex flex-row align-items-center w-100">
              <div className="icon-container d-flex align-items-center justify-content-center">
                <i className={`fa-light ${list[key].icon} font-size-20`}></i>
              </div>
              <div className="text-capitalize font-size-15 ps-2">
                {list[key].label}
              </div>
            </div>
          </LinkContainer>
        ) : (
          <div className="d-flex flex-row align-items-center w-100">
            <div className="icon-container d-flex align-items-center justify-content-center">
              <i className={`fa-light ${list[key].icon} font-size-20`}></i>
            </div>
            <div>
              <CustomTooltip
                trigger={
                  <i className="text-capitalize font-size-15 ps-2">
                    {list[key].label}
                  </i>
                }
                content={"Coming Soon"}
              />
            </div>
          </div>
        )}
      </Dropdown.Item>
    ) ;
  } );
};

const renderNavigationTitle = (
  id,
  icon,
  label,
  iconDirection,
  selectorId,
  size
) => {
  const { screenWidth } = size;
  const signIcon = !iconDirection ? (
    <i className="fa-solid fa-caret-down"></i>
  ) : (
    <i className="fa-solid fa-caret-up"></i>
  );
  const selectorActivity = iconDirection || id === selectorId ? "active" : "";

  return (
    <div
      className={`d-flex flex-row align-items-center justify-content-center navigation-selector-item ${selectorActivity}`}
    >
      {screenWidth < 1366 ? null : (
        <i className={`fa-light ${icon} font-size-20`}></i>
      )}
      <div className="text-capitalize font-size-15 pe-2 ps-2">{label}</div>
      {signIcon}
    </div>
  );
};

const renderNavigationSelector = (props) => {
  const { id, icon, label, list, menuId, selectorId, size } = props;
  const [iconDirection, setState] = useState(false);
  //  const obj = list.find(obj => obj.id === menuId);
  return (
    <div className={"dropdown-navigation-selector"}>
      { id !== 'news' ?
      <Dropdown onToggle={(isOpen) => setState(isOpen)}>
        <Dropdown.Toggle as="div" size="sm" className={"no-sign"}>
          {renderNavigationTitle(
            id,
            icon,
            label,
            iconDirection,
            selectorId,
            size
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>{renderNavigationMenu(list, menuId)}</Dropdown.Menu>
      </Dropdown> :    <LinkContainer to={`/${id}?elem=${id}`}>
            <div className="news-button">
              <div className="icon-container d-flex align-items-center justify-content-center">
                <i className={`fa-light ${icon}  font-size-20`}></i>
              </div>
              <div className="text-capitalize  font-size-15 ps-2">
                {label}
              </div>
            </div>
          </LinkContainer> } 
    </div> 
  );
};

// d-flex flex-row justify-content-center  align-items-center w-100

export const HorizontalNavigationSelector = (props) => {
  const { pathname, search } = useLocation();

  const size = useStoreState((state) => state.sizes.values);
  const menuId = search.replace("?elem=", "");

  const selectorId = pathname.replace("/", "");
  return props.id !== 'nft_generator' ? renderNavigationSelector({ ...props, menuId, selectorId, size }) : null;
  // return renderNavigationSelector({ ...props, menuId, selectorId, size });
};
