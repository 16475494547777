import React, { useRef } from 'react';
import { constants } from '../../constants';
import { DefiMultiChainDashboardState } from '../../../../models/defi-multichain-dashboard-model';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../../components/utility/empty-search-state/view';
import { ProgressBar } from 'react-bootstrap';
import { uiActions } from '../../actions/uiActions';
import { Loader } from '../../../../components/utility/loader/view';
import InfiniteScroll from 'react-infinite-scroller';
import CustomScroll from 'react-custom-scroll';
import { dataHelper } from '../../dataHelpers/dataHelper';
import './styles.scss';

const { TABLE_HEADER_KEYS } = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderTableHeader = () => {
    return <div className="custom-table-row table-header">
        { TABLE_HEADER_KEYS.map((key, i) => <div className="custom-table-header-cell font-size-14 font-weight-medium" key={`header-${i}`}>{key}</div>) }
    </div>;
};

const renderRow = (data, index) => {
    const { asset, fraction, value } = data;

    return <div className="custom-table-row table-body font-size-14 font-weight-regular" key={`body-${index}`}>
        {/* <div className="custom-table-body-cell d-flex align-items-center"><img className="me-1" width={16} height={16}
            src={`../dashboard-library/protocols/${asset.toLowerCase()}.svg`}/><div>{asset}</div></div> */}
        <div>{asset}</div>
        <div className="custom-table-body-cell">${value}</div>
        <div className="custom-table-body-cell"><ProgressBar now={fraction}/></div>
        <div className="custom-table-body-cell">{fraction}%</div>
    </div>;
};

const renderLoader = () => {
    return <div className="loader text-center" key={0}>
        <span>
            <i className="fa fa-refresh fa-spin"></i>
            <span className="ms-3">Loading...</span>
        </span>
    </div>;
};

const renderTableBody = data => data.map((d, i) => renderRow(d, i));

export const TableModalTemplate = props => {
    const actions = DefiMultiChainDashboardState.useStoreActions(actions => actions);
    const state = DefiMultiChainDashboardState.useStoreState(state => state);
    const tableData = state.analytics.analyticsData.data;
    const ref = useRef();
    const loadFunc = value => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING]({ page: value, state });

    return <div className="custom-table-wrapper"><div className="custom-table h-100" ref={ref}>
        { renderTableHeader() }
        <div className="scroll-container">
            { state.wallet.loader ?

                <Loader size={'6x'}/>
                :
                !isEmpty(tableData) ?

                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={loadFunc}
                            hasMore={state.wallet.pagination.hasMore}
                            loader={renderLoader()}
                            useWindow={false}
                            getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
                            { renderTableBody(tableData, actions, props) }
                        </InfiniteScroll>
                    </CustomScroll>
                    :
                    <EmptySearchState/> }
        </div>
    </div></div>;
};