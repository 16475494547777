import Toggle from 'react-toggle';
import React from 'react';
import { HodlBotState } from '../../../../models/hodl-bot-model';
import { constants } from '../../constants';
import { controlsEffects } from '../../actions/controlsEffects';
import { packsEffects } from '../../actions/packsEffects';
import { Asset } from '../../../../components/utility/asset-component/view';

const {
  CONTROLS: { LABEL_SINGLE_PAIR, LABEL_PACKS, LABEL_MAIN_COIN, LABEL_PAIRS_SIZE },
  PACKS: { SHOW_LESS, SHOW_MORE, ADD_LABEL, PACKS_LIMIT }
} = constants;

const renderPacksToggle = (flag, value, actions) => {
  const clickHandle = () => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'toggle' });
  return <div key="toggle" className="pack-item -toggle font-size-14 align-items-center" onClick={clickHandle}>
    <div className="link-text d-flex">{flag ? SHOW_LESS : `${SHOW_MORE} (${value})`}</div>
  </div>;
};

const renderAddPack = actions => {
  const clickHandle = () => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'add' });
  return <div key="add" className="pack-item -add" onClick={clickHandle}>
    <div className="link-text d-flex flex-row font-size-14 align-items-center">
      <i className="fa-light fa-plus"></i>
      <span className="ps-2">{ADD_LABEL}</span>
    </div>
  </div>;
};

const renderPacksList = (state, actions) => {
  const { packsData, selectedId, show } = state;
  const packsSize = packsData.length;

  const packs = show ? packsData : packsData.slice(0, PACKS_LIMIT);
  const hiddenValue = packsSize - PACKS_LIMIT;
  const packsElements = packs.map((el, key) => {
    const isActive = el.id && el.id === selectedId;
    const {mainCoin, name, pairs} = el;
    const pairsSize = pairs.length;
    const clickHandle = () => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({id: 'select_pack', data: el});
    return <div className={`pack-item ${isActive ? 'active' : ''}`} key={key} onClick={clickHandle}>
      <div className="pack-item-content">
        <div className="pack-item-name font-size-14">{name}</div>
        <div className="pack-item-grid">
          <div className="pack-item-main-coin d-flex align-items-center">
            <Asset asset={mainCoin}/>
          </div>
          <div className="pack-item-size text-center font-weight-medium">{pairsSize}</div>
          <div className="pack-item-title font-size-12">{LABEL_MAIN_COIN}</div>
          <div className="pack-item-title font-size-12 text-center">{LABEL_PAIRS_SIZE}</div>
        </div>
      </div>
    </div>;
  });
  return [renderAddPack(actions), ...packsElements, packsSize > PACKS_LIMIT ? renderPacksToggle(show, hiddenValue, actions) : null];
};

export const HodlBotControls = () => {
  const state = HodlBotState.useStoreState(state => state.controls);
  const action = HodlBotState.useStoreActions(action => action);

  return <div className="smart-trade-controls-container-content">
    <label className="customized-toggle d-flex flex-row">
      <span className="me-4 me-md-1 font-size-12">{LABEL_SINGLE_PAIR}</span>
      <Toggle
        icons={false}
        defaultChecked={state.packs}
        onChange={() => action[controlsEffects.actionTypes.HODL_BOT_CONTROLS_TOGGLED]()}/>
      <span className="ms-1 font-size-12">{LABEL_PACKS}</span>
    </label>
  </div>;
};

export const HodlBotTradePacks = () => {
  const state = HodlBotState.useStoreState(state => state.packs);
  const actions = HodlBotState.useStoreActions(actions => actions);
  return renderPacksList(state, actions);
};
