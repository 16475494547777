import React from 'react';
import { EventsState } from '../../../models/events-model';
import '../styles/upcoming-events-styles';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faQuestionCircle,
  faCalendarAlt,
  faRing,
  faBell,
  faThumbsUp,
  faThumbsDown,
  faCheckCircle,
  faArrowToTop,
  faTint,
} from '@fortawesome/pro-solid-svg-icons';

import { ChoiceSelector } from '../templates/choiceSelectorTemplate';
import Button from 'react-bootstrap/Button';
import { ProgressBar } from 'react-bootstrap';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { NewsPage } from '../templates/newsTemplate';
import { getColorByCardType } from '../constants/color-update';

const UpcomingEventsLayout = () => {
  const stateCardsTitle = EventsState.useStoreState((state) => state.upcoming_cards_title);
  const upcomingEventsCards = EventsState.useStoreState((state) => state.upcoming_events_cards);
  return (
    <div className="upcoming-events-body">
      <ChoiceSelector />
      <div className="body-wrapper">
        {!isEmpty(stateCardsTitle) ? (
          stateCardsTitle.map((card, i) => {
            return (
              <div className="card" key={i}>
                <div
                  className="card-type d-flex"
                  style={{ backgroundColor: getColorByCardType(card.cardType) }}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                  <div className="type-title">{card.cardType}</div>
                  <div className="question-icon">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </div>
                </div>
                <div className="date-card d-flex">
                  <img
                    src="../coins-library/btc.svg"
                    className="coin-icon img-fluid rounded-start pt-30"
                  />
                  <div className="date-title"> {card.date}</div>
                </div>
                <div className="card-description">
                  <div className="title"> {card.name} </div>
                </div>
                <div className="votes-counter">
                  <div className="votes-title">
                    {card.views} Views | {card.votes} Votes
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            {' '}
            <EmptySearchState />{' '}
          </div>
        )}
      </div>
      <div className="card-wrapper ">
        {!isEmpty(upcomingEventsCards) ? (
          upcomingEventsCards.map((eventsData, index) => {
            return (
              <div className="card-body" key={index}>
                <div className="card-layout">
                  <div className="title-icons d-flex">
                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                    <FontAwesomeIcon icon={faArrowToTop} color="red" />
                    <FontAwesomeIcon icon={faTint} color="orange" />
                  </div>
                  <div className="title-data">M</div>
                  <div className="title-icons d-flex">
                    <FontAwesomeIcon icon={faCalendarAlt} color="green" />
                    <FontAwesomeIcon icon={faBell} color="red" />
                    <FontAwesomeIcon icon={faRing} color="orange" />
                  </div>
                </div>
                <div className="network-data">
                  <div className="network-subtitle">{eventsData.networkName}</div>
                  <div className="date">{eventsData.cardDate}</div>
                  <div className="network-title">{eventsData.cardTitle}</div>
                  <div className="network-desciption">{eventsData.cardDescription}</div>
                </div>
                <div className="card-buttons">
                  <div>
                    <Button variant="secondary" size="md">
                      Proof
                    </Button>
                  </div>
                  <div>
                    <Button variant="primary" size="md">
                      Source
                    </Button>
                  </div>
                </div>
                <div className="votes-data">
                  <div className="votes-precent">98 %</div>s
                  <div className="votes-count">242 Votes</div>
                </div>
                <div className="progres-bar">
                  <ProgressBar style={{ height: '5px' }} now={98} />
                </div>
                <div className="like-unlike-buttons">
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faThumbsUp} width={22} height={20} />
                    <div className="like-description">Real</div>
                  </div>
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faThumbsDown} width={22} height={20} />
                    <div className="unlike-description">Fake</div>
                  </div>
                </div>
                <div className="added-wrapper">
                  <div className="added-date">{eventsData.addedDate}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <EmptySearchState />
          </div>
        )}
      </div>
      <NewsPage />
    </div>
  );
};

export const UpcomingEventsPage = () => (
  <EventsState.Provider>
    <UpcomingEventsLayout />
  </EventsState.Provider>
);
