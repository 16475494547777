import { action } from 'easy-peasy';

const actionTypes = {
  HODL_BOT_CONTROLS_TOGGLED: 'HODL_BOT_CONTROLS#TOGGLED'
};

const doUiAction = (state, payload) => {
    state.controls.packs = !state.controls.packs;
    state.packs.selectedId = null;
    state.packs.selectedPack = null;
    state.packs.selectedPair = {};
    state.singlePair = {};
    // state.smartTradeBot.isSingle =
    //   state.packs.isSingle =
    state.singlePair.isSingle = !state.controls.packs;
};

const actionHandlers = {
  [actionTypes.HODL_BOT_CONTROLS_TOGGLED]: action((state, payload) => doUiAction(state, payload))
};

export const controlsEffects = {
  actionHandlers,
  actionTypes
};
