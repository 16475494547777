import { createContextStore } from 'easy-peasy';
import { connectionWalletEffects } from '../pages/homePage/templates/buyPage/actions/connectWalletEffects';
import { trustConnectEffects } from '../pages/homePage/templates/buyPage/actions/trustConnectEffects';
import { fetchEffects } from '../pages/homePage/templates/buyPage/actions/fetchEffects';

const BUY_MODEL = {
    buy: {
        address: '',
        user: null,
        transactionData: {},
        loader: false,
        counter: 5,
        isWalletLogged: false,
        walletName: '',
        isNetworkChoose: false,
        network: '',
        error: '',
        showModal: false,
        buying: '',
        selling: '',
        totalAvailable: 0,
        maxSupply: 0,
        range: 0,
        sold: 0,
        approved: []
    },
    ...connectionWalletEffects.actionHandlers,
    ...fetchEffects.actionHandlers,
    ...trustConnectEffects.actionHandlers,
};

export const BuyState = createContextStore(BUY_MODEL);