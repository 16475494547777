const parseChains = data => {
    const currentAccount = data.find(el => el['smart_contract']?.chain?.chainId === 56);

    if (!currentAccount)
        return {};

    const {chain: {blockExplorerUrls, rpcUrls, chainName, chainId, nativeCurrency}} = currentAccount['smart_contract'] || {};
    const urls = blockExplorerUrls.reduce((acc, b) => {
       const {url} = b;
       return [...acc, url];
    }, []);
    return {chainId: `0x${chainId.toString(16)}`, chainName, rpcUrls, blockExplorerUrls: urls, nativeCurrency};
};

const transactionData = data => {
  return data;
};

const getAddress = data => {
  return data[0];
};

export const dataHelper = {
  parseChains,
  transactionData,
  getAddress
};


// const chainId = `0x${id.toString(16)}`;
// const rez = await window.ethereum.request({
//     method: 'wallet_addEthereumChain',
//     params: [
//         {
//             chainId,
//             chainName: 'TEST',
//             rpcUrls: ['https://bsc-dataseed.binance.org/'],
//             blockExplorerUrls: ['https://bscscan.com'],
//             nativeCurrency: {
//                 symbol: 'BNB',
//                 decimals: 18
//             }
//         }
//     ]
// })


// console.info(rez);
//
// const swi = await window.ethereum.request({
//     method: 'wallet_switchEthereumChain',
//     params: [{ chainId }],
// })
//     .finally(() => actions[actionTypes.TEST]({ name: 'stop-loader' }));
//
// // start contract
//
// console.info(swi);
// actions[actionTypes.TEST]({ name: 'stop-loader' });