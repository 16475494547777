import numeral from "numeral";
import Big from "big.js";
import { isEmpty } from "lodash";
import { orderBy } from "lodash";

const formatArrayObject = (array, attr1, attr2) => {
  return array.map((item) => {
    return {
      ...item,
      sumValue: Number(item[attr1]) + Number(item[attr2]),
    };
  });
};

const sortByValue = (arr, attr) => {
  const key = attr;
  const newArr = arr.map((item) => {
    item[key] = Number(item[attr]);

    return {
      ...item,
    };
  });

  const result = orderBy(newArr, attr, "desc");

  return result;
};

const getTokenById = (id, tokens) =>
  tokens.find((token) => token.contractAddress === id);

const getOthersFraction = (array) => {
  const allocation = array.map((item) => item.fraction);
  const fractionSum = allocation.reduce(
    (previousValue, currentValue) =>
      Number(previousValue) + Number(currentValue),
    0
  );
  const othersFraction = 100 - fractionSum;
  return othersFraction.toString();
};

const getChainsFraction = (tokens) => {
  const tokensAmount = tokens.length;
  const onePercent = tokensAmount / 100;

  const ethAmount = tokens.filter((token) => token.blockchainName === "ETH");
  const bscAmount = tokens.filter((token) => token.blockchainName === "BSC");
  const maticAmount = tokens.filter(
    (token) => token.blockchainName === "MATIC"
  );
  const avaxAmount = tokens.filter((token) => token.blockchainName === "AVAXc");
  const ftmAmount = tokens.filter((token) => token.blockchainName === "FTM");

  const ethChainSum = numeral(
    ethAmount
      .map((token) => token.usdValue)
      .reduce(
        (previousValue, currentValue) =>
          Big(previousValue).plus(Big(currentValue)).valueOf(),
        0
      )
  ).format("0,00.00");
  const bscChainSum = numeral(
    bscAmount
      .map((token) => token.usdValue)
      .reduce(
        (previousValue, currentValue) =>
          Big(previousValue).plus(Big(currentValue)).valueOf(),
        0
      )
  ).format("0,00.00");
  const maticChainSum = numeral(
    maticAmount
      .map((token) => token.usdValue)
      .reduce(
        (previousValue, currentValue) =>
          Big(previousValue).plus(Big(currentValue)).valueOf(),
        0
      )
  ).format("0,00.00");
  const avaxChainSum = numeral(
    avaxAmount
      .map((token) => token.usdValue)
      .reduce(
        (previousValue, currentValue) =>
          Big(previousValue).plus(Big(currentValue)).valueOf(),
        0
      )
  ).format("0,00.00");
  const ftmChainSum = numeral(
    ftmAmount
      .map((token) => token.usdValue)
      .reduce(
        (previousValue, currentValue) =>
          Big(previousValue).plus(Big(currentValue)).valueOf(),
        0
      )
  ).format("0,00.00");

  return [
    {
      asset: "ETH",
      fraction:
        ethAmount.length / onePercent !== 0
          ? numeral(ethAmount.length / onePercent).format("0,00")
          : 0,
      value: ethAmount.length !== 0 ? ethChainSum : 0,
    },
    {
      asset: "BSC",
      fraction:
        bscAmount.length / onePercent !== 0
          ? numeral(bscAmount.length / onePercent).format("0,00")
          : 0,
      value: bscAmount.length !== 0 ? bscChainSum : 0,
    },
    {
      asset: "MATIC",
      fraction:
        maticAmount.length / onePercent !== 0
          ? numeral(maticAmount.length / onePercent).format("0,00")
          : 0,
      value: maticAmount.length !== 0 ? maticChainSum : 0,
    },
    {
      asset: "AVAXc",
      fraction:
        avaxAmount.length / onePercent !== 0
          ? numeral(avaxAmount.length / onePercent).format("0,00")
          : 0,
      value: avaxAmount.length !== 0 ? avaxChainSum : 0,
    },
    {
      asset: "FTM",
      fraction:
        ftmAmount.length / onePercent !== 0
          ? numeral(ftmAmount.length / onePercent).format("0,00")
          : 0,
      value: ftmAmount.length !== 0 ? ftmChainSum : 0,
    },
  ];
};

const getTotalBalance = (data) => {
  const balances = [];
  for (const balance of data) {
    balances.push(Big(+balance.usdValue));
  }

  return !isEmpty(balances)
    ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf())
    : 0;
};

const getTokensFraction = (tokens) => {
  const allTokens = tokens.map((token) => token.valueSymbol);
  const onePercent = getTotalBalance(tokens) / 100;

  const fractionsArray = tokens.map((token, index) => {
    if (Number(token.usdValue) === 0) {
      return {
        asset: allTokens[index],
        fraction: 0,
        value: 0,
      };
    } else {
      return {
        asset: allTokens[index],
        fraction: numeral(
          tokens.map((t) => Number(t.usdValue))[index] / onePercent
        ).format("0,00"),
        value: numeral(Number(token.usdValue)).format("0,00.00"),
      };
    }
  });
  return fractionsArray;
};
const getTotalBorrowTokenBalance = (data) => {
  const balances = [];
  for (const balance of data) {
    balances.push(Big(+balance.staked_price));
  }

  return !isEmpty(balances)
    ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf())
    : 0;
};
const getPoolsFraction = (tokens) => {
    
  const onePercent = getTotalBorrowTokenBalance(tokens) / 100;

  const fractionsArray = tokens.map((token, index) => {
    if (Number(token.staked_price) === 0) {
      return {
        asset: tokens[index].pool.reward_token.symbol,
        fraction: 0,
        value: 0,
      };
    } else {
      return {
        asset: tokens[index].pool.reward_token.symbol,
        fraction: numeral(
          tokens.map((t) => Number(t.staked_price))[index] / onePercent
        ).format("0,00"),
        value: numeral(Number(token.staked_price)).format("0,00.00"),
      };
    }
  });

  return fractionsArray;
};

const getFarmsFraction = (tokens) => {
    
    const onePercent = getTotalBorrowTokenBalance(tokens) / 100;
  
    const fractionsArray = tokens.map((token, index) => {
      if (Number(token.staked_price) === 0) {
        return {
          asset: tokens[index].pool.rewardTokens[index].symbol,
          fraction: 0,
          value: 0,
        };
      } else {
        return {
          asset: tokens[index].pool.rewardTokens[index].symbol,
          fraction: numeral(
            tokens.map((t) => Number(t.staked_price))[index] / onePercent
          ).format("0,00"),
          value: numeral(Number(token.staked_price)).format("0,00.00"),
        };
      }
    });
  
    return fractionsArray;
  };

const getVenusSum = (data) => {
  const arr = [
    ...data.map(
      (token) => Number(token.supply_price) - Number(token.borrow_price)
    ),
  ];
  if (!isEmpty(arr)) {
    const sum = arr.reduce(
      (acc, curr) => Big(acc).plus(Big(curr)).valueOf(),
      0
    );

    return sum;
  } else {
    return 0;
  }
};

const getWalletSum = (data) => {
  const arr = [...data.map((token) => Number(token.usdValue))];
  if (!isEmpty(arr)) {
    const sum = arr.reduce(
      (acc, curr) => Big(acc).plus(Big(curr)).valueOf(),
      0
    );

    return sum;
  } else {
    return 0;
  }
};

const getCompoundSum = (data) => {
   const arr = [...data.map(token => Number(token.borrow_price) + Number(token.supply_price))];
   if(!isEmpty(arr)) {
       const sum = arr.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf(), 0);

       return sum;
   }
   else {
       return 0;
   }
 
};

const getFarmsPoolsSum = (data) => {
  const arr = [
    ...data.map(
      (farm) => Number(farm.reward_price) + Number(farm.staked_price)
    ),
  ];
  if (!isEmpty(arr)) {
    const sum = arr.reduce(
      (acc, curr) => Big(acc).plus(Big(curr)).valueOf(),
      0
    );

    return sum;
  } else {
    return 0;
  }
};
const parseFarmsList = list => {
  const keys = Object
      .keys(list)
     
  return keys.map(key => ({
      valueSymbol: list[key].pool.rewardTokens[key].symbol,
     usdValue: list[key].reward_price + list[key].staked_price,
      blockchainName:"BSC",
      valueDecimals: 0
  }));
};

const parsePoolsList = list => {
  const keys = Object
      .keys(list)
     
  return keys.map(key => ({
      valueSymbol: list[key].pool.staked_token.symbol,
      usdValue: Number (list[key].staked_price) + Number (list[key].reward_price),
      blockchainName:"BSC",
      valueDecimals: 0
      
  }));
};
const parseVenusList = list => {
  const keys = Object
      .keys(list)
     
  return keys.map(key => ({
      valueSymbol: list[key].token.symbol,
      usdValue: Number (list[key].borrow_price) + Number (list[key].supply_price),
      blockchainName:"BSC",
      valueDecimals: 0
      
  }));
};

const filterDataList = list => {
 return list.filter(token => token.valueDecimals !== 0)
};
export const dataHelper = {
  getWalletSum,
  getFarmsPoolsSum,
  getCompoundSum,
  getVenusSum,
  getTotalBalance,
  getTokensFraction,
  getChainsFraction,
  getOthersFraction,
  getTokenById,
  sortByValue,
  formatArrayObject,
  getPoolsFraction,
  getFarmsFraction,
  parseFarmsList,
  parsePoolsList,
  parseVenusList,
  filterDataList
};
