import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { constants } from '../../../constants/constants';
import { StandardOptions } from '../../../components/utility/standard-options/view';
import '../styles/_favorites_packs_tabs.scss';

const { SMART_TRADE_BOT: { FAVORITES, PACKS } } = constants;

const favoritesTab = () => <div className="d-flex flex-row justify-content-start align-items-start">
    <span className="pe-2">{FAVORITES.LABEL}</span>
    <StandardOptions more={{ ...FAVORITES.STANDARD_OPTIONS_SCHEMA }}/>
</div>;

const packsTab = () => <span className="d-flex flex-row justify-content-start align-items-start">
    <span className="pe-2">{PACKS.LABEL}</span>
    <StandardOptions more={{ ...PACKS.STANDARD_OPTIONS_SCHEMA }}/>
</span>;


export const FavoritesPacksTabsWidget = () => {
    return <div className="h-100">
        <Tabs
            defaultActiveKey="favorites"
            transition={false}
        >
            <Tab eventKey="favorites" title={favoritesTab()}></Tab>
            <Tab eventKey="packs" title={packsTab()}></Tab>
        </Tabs>
    </div>;
};