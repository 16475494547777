import { v4 as uuidv4 } from 'uuid';
import { constants } from '../constants';

const { SELECTORS } = constants;

const getConditionsFields = () => {
  return {
    conditionsClosingBotSelector: { list: SELECTORS.CONDITIONS_CLOSING_BOT_LIST, value: null },
    timeframeSelector: { list: SELECTORS.CONDITIONS_TIMEFRAME_LIST, value: SELECTORS.CONDITIONS_TIMEFRAME_LIST[0] },
    signalValueSelector: { list: SELECTORS.SIGNAL_VALUE_LIST, value: SELECTORS.SIGNAL_VALUE_LIST[0] },
    signalValueAmount: 0,
    presetsSelector: { list: SELECTORS.PRESETS_LIST, value: SELECTORS.PRESETS_LIST[0] },
    rsiPeriodAmount: 0,
    conditionSelector: { list: SELECTORS.CONDITIONS_LIST, value: SELECTORS.CONDITIONS_LIST[0] },
    id: uuidv4()
  }
}

const getDealStartConditionFields = () => {
  return {
    conditionsStartingBotSelector: { list: SELECTORS.CONDITION_LIST, value: null },
    timeframeSelector: { list: SELECTORS.TIMEFRAME_LIST, value: SELECTORS.TIMEFRAME_LIST[0] },
    timeframeTimeSelector: { list: SELECTORS.CONDITIONS_TIMEFRAME_LIST, value: SELECTORS.CONDITIONS_TIMEFRAME_LIST[0] },
    signalValueSelector: { list: SELECTORS.SIGNAL_VALUE_LIST, value: SELECTORS.SIGNAL_VALUE_LIST[0] },
    signalValueAmount: 0,
    presetsSelector: { list: SELECTORS.PRESETS_LIST, value: SELECTORS.PRESETS_LIST[0] },
    rsiPeriodAmount: 0,
    conditionSelector: { list: SELECTORS.CONDITIONS_LIST, value: SELECTORS.CONDITIONS_LIST[0] },
    id: uuidv4()
  }
}

export const defaultValueHelper = {
  getConditionsFields,
  getDealStartConditionFields
}