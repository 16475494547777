import { createContextStore } from 'easy-peasy';
import { controlsEffects } from '../pages/smartTradePage/actions/controlsEffect';
import { packsEffects } from '../pages/smartTradePage/actions/packsEffects';
import { pairsEffects } from '../pages/smartTradePage/actions/pairsEffects';
import { selectorsEffects } from '../pages/smartTradePage/actions/selectorsEffects';
import { smartTradeBotSettingsEffects } from '../pages/smartTradePage/actions/smartTradeBotSettingsEffects';
import { tableEffects } from '../pages/smartTradePage/actions/tableEffects';
import { constants } from '../pages/smartTradePage/constants';
import { orderBookEffects } from '../pages/smartTradePage/actions/orderBookEffect';
import { openOrdersPositionsEffects } from '../pages/smartTradePage/actions/openOrdersPositionsEffects';
import { shareOrderResultEffects } from '../pages/smartTradePage/actions/shareOrderResultEffects';

const {
  PRICE_LIST,
  ORDER_BOOK: { askPrices, bidPrices, LENGTH_SELECT },
} = constants;

const SMART_TRADE_PAGE_MODEL = {
  assetsData: {},
  mainCoinList: [],
  pairCoinList: [],
  controls: {
    packs: false,
    signals: false,
    favorites: false,
    isMultichart: false,
  },
  
  packs: {
    selectedId: null,
    selectedPack: null,
    show: false,
    packsData: [],
    addMode: false,
    editPackMode: false,
    addPackMode: false,
    addPairMode: false,
    createPackData: {},
    createPairsData: [],
    selectedPair: {},
    exchangeData: {},
    coinData: {},
    mainCoinList: [],
    pairCoinList: [],
    isSingle: true,
  },
  singlePair: {
    mainCoin: '',
    pairCoin: '',
    pairData: {},
    isSingle: true,
  },
  smartTradeBot: {
    errorInTrade: false,
    percent_limit: 1,
    price_value: 0,
    formType: 'form_state_pro_trade',
    marginType: 'ISOLATED',
    leverage: 1,
    isSingle: true,

    input_price_value: 0,
    balance_total: 0,
    balance_total_leveraged: 0,

    form_state_pro_trade: true,
    form_state_buy_sell: false,
    form_state_pro_short: false,

    buySellFormType: 'buy',

    slider_total_value: 0,
    slider_trailingBy_percent: 0,

    slider_profit_max_price_percent: 0,
    slider_profit_percent: 0,
    slider_profit_volume_percent: 0,
    slider_profit_short_percent: 0,
    slider_stop_loss_value_percent: 0,
    slider_stop_loss_short_value_percent: 0,

    toggle_trailing_by: false,
    toggle_take_profit: false,
    toggle_trailing_take_profit: false,
    toggle_stop_loss: false,
    toggle_stop_loss_timeout: false,
    toggle_trailing_stop_loss: false,
    toggle_move_breakeven: false,
    toggle_grid_profit: false,
    toggle_upper_stop_lose: false,
    toggle_lower_stop_lose: false,
    toggle_cost_average: false,

    input_amount_value: 0,
    input_trailingBy_percent: 0,
    input_buy_cond_limit: 0,

    input_total_value: 0,
    input_profit_value: 0,
    input_profit_percent: 0,
    input_profit_volume_percent: 0,
    input_profit_short_percent: 0,
    input_profit_max_price_percent: 0,
    input_stop_loss_value: 0,
    input_sloss_cond_limit: 0,

    input_stop_loss_value_percent: 0,
    input_stop_loss_short_value_percent: 0,
    input_stop_loss_timeout_sec: 0,

    input_upper_limit_price: 47434.8,
    input_upper_stop_lose: 52778.28,
    input_lower_limit_price: 18465.244,
    input_lower_stop_lose: 1666.678,
    input_grid_quantity: 201,
    input_active_orders: 201,
    input_quantity_per_grid: 0,
    input_amount_for_bot_usage: 0.04111244,

    input_buy_for: 0,
    input_days_number: 0,
    input_pick_date: '',

    profit_form: false,
    takeProfitData: [],
    priceOfQuantity: 0,
    tableData: [],

    button_buy_limit: false,
    button_buy_market: true,
    button_buy_cond: false,
    button_buy_cond_limit: false,
    button_buy_cond_market: true,

    button_ttp_limit: false,
    button_ttp_market: true,

    button_sloss_limit: false,
    button_sloss_market: true,

    cost_average_by_types: [
      { value: 'precent', label: 'By %' },
      { value: 'time', label: 'By time' },
    ],
    cost_average_bot_will_buy: [
      { value: 'Bitcoin', label: 'BTC' },
      { value: 'Binance', label: 'BNB' },
      { value: 'Kava', label: 'Kava' },
    ],
    cost_average_by_for: [
      { value: 'Bitcoin', label: 'BTC' },
      { value: 'Binance', label: 'BNB' },
      { value: 'Kava', label: 'Kava' },
    ],
    cost_dates: [
      { value: 'time', label: '10AM' },
      { value: 'time2', label: '1AM' },
      { value: 'time3', label: '9PM' },
      { value: 'time2', label: '5PM' },
    ],
  },

  tableData: {
    viewType: {
      active: true,
      history: false,
    },
    viewTypeOrders: {
      orders: true,
      positions: false,
    },
    rowsData: [],
    collapsed: [],
    moreOptions: {
      dontShowMessage: false,
    },
    chainId: null,
    input_funds_volume_percent: 0,
    slider_funds_volume_percent: 0,
    input_funds_volume_value: 0,
    input_price_value: 0,
    select_price_value: PRICE_LIST[0],
    isSetOrder: true,
  },
  historyData: [
    {
      date: '09 may 2022, 5.52PM',
      msgSender: '0x9439...579A1',
      msgRecevier: '0x1255..E3B1',
      transferData: '+ 0.2315484 BSC-USDT',
      gasPrice: '7.00 Gwei',
      gasFee: '0.000485 BNB',
      fiatData: '$0.20234',
    },
  ],

  ordersData: [],
  positionsData: [],
  orderBookData: {
    askPrices: [],
    bidPrices: [],
    sortPriceTable: 'All',
    lengthSelector: {
      list: [],
      selectedStep: { label: '', value: '' },
    },
  },

  shareOrderResult: {
    show: false,
  },

  ...controlsEffects.actionHandlers,
  ...packsEffects.actionHandlers,
  ...pairsEffects.actionHandlers,
  ...selectorsEffects.actionHandlers,
  ...smartTradeBotSettingsEffects.actionHandlers,
  ...tableEffects.actionHandlers,
  ...orderBookEffects.actionHandlers,
  ...openOrdersPositionsEffects.actionHandlers,
  ...shareOrderResultEffects.actionHandlers,
};

export const SmartTradeState = createContextStore(SMART_TRADE_PAGE_MODEL);
