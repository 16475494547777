import React from 'react';

export const constants = {
  DISCLAIMER: {
    VALID_FOR_ANNUALLY: 'Valid for annually subscriptions only',
    ANNUALLY_WILL_PARTICIPATE: 'Annually subscribers will participate in the protocols income pools'
  },
  TABLE_CONTENT: {
    PRO_TRADES: 'ProTrades',
    GRID_BOTS: 'GridBots',
    DCA_BOTS: 'DCA Bots',
    PACKS: 'Packs',
    COPY_TRADING_BOTS: 'CopyTrading Bots',
    PAPER_TRADING_ACCOUNT: 'Paper trading account',
    DAYS: 'days',
    UNLIMITED_ACCESS_FEATURES: 'Unlimited access to all features',
    MINT_TRIAL: 'Mint Trial',
    START_TRIAL: 'Start Trial',
    BEGINNER: 'Beginner',
    UNIQUE_NFT_AVATAR: 'Unique NFT avatar',
    UNIQUE_NFT_COLLECTION: 'Unique NFT collection',
    INCOME_POOL: 'Income pool',
    SUBSCRIBE: 'Subscribe',
    ADVANCED: 'Advanced',
    EXPERT: 'Expert',
    UNLIMITED: 'Unlimited',
    CUSTOM_API: 'Custom API',
    LEGENDARY: 'Legendary',
    LIFETIME_SUBSCRIPTION: 'Lifetime Subscription',
    LIMITED_EDITION: 'Limited edition',
    PCS: 'pcs',
    VOTING_AMPLIFIER: 'Voting amplifier',
  },
  HEADER: {
    TITLE: 'Manage your plan',
    MONTHLY: 'Monthly',
    ANNUALLY: 'Annually',
  }
};
