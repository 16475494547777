import React from 'react';
import { constants } from '../constants';
import { InputGroup, FormControl } from 'react-bootstrap';
import { uiActions } from '../actions/uiActions';
import { InvestmentState } from '../../../models/investment-model';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { investmentEffects } from '../actions/investmentEffects';
import { useStoreState } from 'easy-peasy';

const {
  UNSTAKE_MODAL: { HEADER, UNSTAKE, BALANCE },
  CONFIRM_BTN,
  CANCEL_BTN,
} = constants;

export const UnstakeContent = (props) => {
  const { cheff } = props;
  const actions = InvestmentState.useStoreActions((actions) => actions);
  const state = InvestmentState.useStoreState((state) => state);
  const userWallet = useStoreState((state) => state.authentication.userData.userId);

  const onChangeHandle = (e) =>
    actions[uiActions.actionTypes.FARMS_MODAL_INPUT_AMOUNT_SET]({
      amount: e.target.value,
      name: 'unstake',
    });
  const onMaxUnstakeAmount = () =>
    actions[uiActions.actionTypes.FARMS_MODAL_MAX_AMOUNT_SET]({
      max: state.modal.data.unstakeAmount,
      name: 'unstake',
    });
  const handleClose = () =>
    actions[uiActions.actionTypes.FARMS_MODAL_INVOKED]({
      name: 'unstake-modal',
      modal: 'close-modal',
    });

  return (
    <>
      <div className="font-weight-medium text-start modal-main-header">{HEADER}</div>
      <div className="modal-unstake-main-content">
        <div className="input-heading">
          <div>{UNSTAKE}</div>
          <div className="balance">
            {BALANCE}: {state.modal.data.unstakeAmount}
          </div>
        </div>
        <div className="input-content">
          <InputGroup>
            <FormControl
              value={state.unstakeAmount}
              type="number"
              onChange={onChangeHandle}
              placeholder="0"
            />
            <InputGroup.Text className="custom-btn-search" onClick={onMaxUnstakeAmount}>
              MAX {state.modal.data.token0} - {state.modal.data.token1} LP
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className="confirmation-content">
          {
            <ButtonCustom
              disabled={
                state.unstakeAmount > state.modal.data.unstakeAmount || state.unstakeAmount <= 0
              }
              variant="success"
              label={CONFIRM_BTN}
              onClick={() =>
                actions[investmentEffects.actionTypes.FARMS_WITHDROW_INVOKED]({
                  cheff,
                  chain: state.chain,
                  pid: state.modal.data.pid,
                  from: userWallet,
                  amount: state.unstakeAmount,
                })
              }
            />
          }
          <ButtonCustom variant="danger" onClick={handleClose} label={CANCEL_BTN} />
        </div>
      </div>
    </>
  );
};
