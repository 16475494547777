import React from 'react';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { favoritesEffects } from './favoritesEffects';

export const AddFavoritesModal = () => {
    const favoritesState = useStoreState(state => state.favoritesMenu);
    const actions = useStoreActions(state => state);
    const handleClose = () => actions[favoritesEffects.actionTypes.FAVORITES_ACTION_MODAL_CLOSED]();
    const updateValue = e => {
        const value = e.target.value;
        actions.updateState({
            path: 'favoritesMenu.addValue',
            value: value
        });
    };
    const handleAdd = () => {
        const value = favoritesState.addValue;

        if (!value || value.length < 2)
            return;

        actions[favoritesEffects.actionTypes.FAVORITES_ACTION_ASSET_ADDED](value.toUpperCase());
        actions[favoritesEffects.actionTypes.FAVORITES_ACTION_MODAL_CLOSED]();
    };
    const addedValue = favoritesState.addValue;
    const isDisabled = !addedValue || addedValue.length < 2 ? true : false;

    return <Modal
        size="sm"
        show={favoritesState.openAddModal}
        centered
        backdrop="static"
        className="add-fovorites"
        onHide={handleClose}
        autoFocus={true}
        enforceFocus={true}>
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="d-flex flex-column justify-content-center">
                <div className="font-size-16 font-weight-medium text-center form-title mb-2">Add to favorites</div>
                <InputGroup className="favorites-add-asset">
                    <FormControl
                        className="signal-search"
                        onChange={updateValue}
                        placeholder="Type coin name..."
                        value={addedValue}
                        size="sm"/>
                    <InputGroup.Text>
                        <Button
                            disabled={isDisabled}
                            variant="link"
                            size="sm"
                            onClick={handleAdd}
                        >Add</Button>
                    </InputGroup.Text>
                </InputGroup>

            </div>
        </Modal.Body>
    </Modal>;
};