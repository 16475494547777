import React from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

export const CustomCheckbox = (props) => {
    const { id, onChangeHandle } = props;
    return <Form className='custom-checkbox'>
        <Form.Check
            className='checkbox'
            onChange={onChangeHandle}
            aria-label={id}
            id={id}
        />
    </Form>;
};