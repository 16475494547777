import React, { useEffect } from 'react';
import styles from './styles';
import { SmartContractAllowanceState } from '../../models/smart-contract-allowance-model';
import { uiActions } from './actions/uiActions';
import { MultiplyCustomSelector } from '../../components/utility/multiply-selector/view';
import { ButtonCustom } from '../../components/utility/custom-buttons/view';
import { FormControl, InputGroup } from 'react-bootstrap';
import { TableTemplate } from './templates/tableTemplate';
import { debug, useStoreState } from 'easy-peasy';

const SmartContractAllowanceLayout = () => {
    const actions = SmartContractAllowanceState.useStoreActions(actions => actions);
    const state = SmartContractAllowanceState.useStoreState(state => state);
    const userId = useStoreState(state => state.authentication.userData.userId);
    const onChangeHandle = value => actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_MULTISELECT_SET]({ value, state });
    const onSearchHandle = e => actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_SEARCH_SET]({ value: e.target.value });
    const searchInvoked = () => actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_SEARCH_INVOKED]({ state });
    const keyUpHandle = e => e.which === 13 ? actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_SEARCH_INVOKED]({ state }) : '';
    const onClickRevokeAll = () => actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_REVOKE_ALL]({ state, userId });

    useEffect(() => {
        actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_REQUESTED]({ userId, state });
    }, [state.searchRequest]);

    return <div className="smart-contract-allowance-layout content ps-3 pe-3">
        <div className="smart-contract-allowance-layout controls">
            <div className="multiple-selector">
                <MultiplyCustomSelector
                    options={state.selector.list}
                    onChangeHandle={onChangeHandle}
                    closeMenuOnSelect={true}
                    isDisabled={state.selector.isDisabled}
                    isMulti={false}
                    isLoading={state.selector.isLoading}
                    value={state.selector.value}
                />
            </div>
            <div></div>
            <div className="revoke-all-button">
                {state.buttonShowRevokeAll ?
                <ButtonCustom
                    onClick={onClickRevokeAll}
                    className="w-100"
                    variant="primary"
                    size="sm"
                    label="Revoke all" /> : null }
            </div>
            <div></div>
            <div className="search-input">
                <InputGroup onKeyUp={keyUpHandle}>
                    <InputGroup.Text className="clear">
                        <i className="fa-thin fa-magnifying-glass"></i>
                    </InputGroup.Text>
                    <FormControl
                        onChange={onSearchHandle}
                        placeholder={'Search...'} />
                    <InputGroup.Text className='custom-btn-search' onClick={searchInvoked}>Search</InputGroup.Text>
                </InputGroup>
            </div>
        </div>
        <div className="smart-contract-allowance-layout table-content">
            <TableTemplate userId={userId} />
        </div>
    </div>;
};

export const SmartContractAllowancePage = () => <SmartContractAllowanceState.Provider>
    <SmartContractAllowanceLayout />
</SmartContractAllowanceState.Provider>;