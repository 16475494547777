import React from 'react';
import { isEmpty } from 'lodash';
import { Asset } from '../../../../components/utility/asset-component/view';

const parseExchanges = exchanges => {
  const keys = Object
    .keys(exchanges)
    .filter(k => k !== 'all_exchanges');
  return keys.map(key => ({
    label: exchanges[key].name,
    value: exchanges[key]
  }));
};

const generateMainCoinList = (data, api) => {

  if (isEmpty(data) || !api)
    return {
      list: [],
      pairs: {}
    };

  const {baseByQuoteAssetsSpot} = data.find(e => e.api === api) || {};

  if (isEmpty(baseByQuoteAssetsSpot))
    return {
      list: [],
      pairs: {}
    };

  return {
    list: Object.keys(baseByQuoteAssetsSpot).map(k => (
      {
        value: k,
        label: <div className="icons-select d-flex align-items-center">
          <Asset asset={k}/>
        </div>
      })),
    pairs: baseByQuoteAssetsSpot
  };
};

const generatePairCoinList = (asset, data) => {
  if (isEmpty(data) || !asset )
    return [];

  const list = data[asset];

  if (isEmpty(list))
    return [];

  return list.map(k => (
    {
      value: k,
      label: <div className="icons-select d-flex align-items-center">
        <Asset asset={k}/>
      </div>
    }));
};

const submitButtonState = state =>
  !Boolean(state.exchangeSelector) ||
  !Boolean(state.botWillBuySelector) ||
  !Boolean(state.forSelector.value) ||
  !Boolean(state.inputForValue) ||
  !Boolean(state.inputEveryValue) ||
  !Boolean(state.atSelector.value) ||
  !Boolean(state.inputNextBuyDateValue)

export const dataHelper = {
  parseExchanges,
  generateMainCoinList,
  generatePairCoinList,
  submitButtonState
};