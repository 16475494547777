import { cloneDeep } from 'lodash';
import { dashboardConstants } from '../../../dashboardConstants';
import numeral from 'numeral';
import { utils } from '../../../../../utils/utils';
import * as d3 from 'd3';
import { remakeDashboardEffects } from '../../../actions/remakeDashboardEffects';
import { textwrap } from 'd3-textwrap';

const { DATA_CEFI_MOBILE, DATA_DEFI_MOBILE } = dashboardConstants;
const FORMAT = '0, 00';

const dataHelper = (assets, mask) => {
    return assets.reduce((acc, coin, i) => {
        acc[i].value = coin.asset.toLowerCase();
        acc[i].fraction = coin.fraction;
        return acc;
    }, mask).filter(e => e.value);
};

const drawWallets = (node, state, accs, actions) => {
    const accsLeft = accs.slice(0, 3);
    const accsRight = accs.slice(3, 6);

    node
        .selectAll('g.left-titles')
        .remove();

    node
        .selectAll('rect.action-button')
        .on('click', () => actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true));

    node
        .selectAll('path.action-button')
        .on('click', () => actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true));

    const el = node
        .append('g')
        .attr('class', 'left-titles');
        
    el
        .append('g')
        .attr('class', 'wallet-block')
        .selectAll('g.wallet-block')
        .data(accsLeft)
        .enter()
        .append('text')
        .attr('y', (_,i) => 3 + 25*i)
        .attr('x', 20)
        .attr('fill', '#FFFFFF')
        .attr('font-weight', 300)
        .text(d => d || '')
        .call(wrap);

    el
        .append('g')
        .attr('class', 'wallet-block')
        .selectAll('g.wallet-block')
        .data(accsRight)
        .enter()
        .append('text')
        .attr('y', (_,i) => 3 + 25*i)
        .attr('x', 310)
        .attr('fill', '#FFFFFF')
        .attr('font-weight', 300)
        .text(d => d || '')
        .call(wrap);
};

const drawTotalsCircle = (node, state) => {
    const { total, available, invested } = state.total;

    node
        .selectAll('g.left-titles')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'left-titles');

    el
        .append('text')
        .attr('y', 170)
        .attr('x', 307)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '30px')
        .attr('font-weight', 500)
        .text(`$ ${Number(total).toFixed(0)}`);

    el
        .append('text')
        .attr('y', 50)
        .attr('x', 315)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '30px')
        .attr('font-weight', 300)
        .text('Total aggregated balance');
    
    el
        .append('text')
        .attr('y', 325)
        .attr('x', 310)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '30px')
        .attr('font-weight', 500)
        .text(`$ ${Number(available).toFixed(0)}`);

    el
        .append('text')
        .attr('y', 440)
        .attr('x', 310)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '30px')
        .attr('font-weight', 500)
        .text(`$ ${Number(invested).toFixed(0)}`);
};

const drawDeFiBalance = (node, state) => {
    const { balance, top4assets } = state.defi;
    const assets = dataHelper(cloneDeep(top4assets), cloneDeep(DATA_DEFI_MOBILE));

    node
        .selectAll('g.left-titles')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'left-titles');

    el
        .append('text')
        .attr('y', 145)
        .attr('x', 180)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-weight', 500)
        .text(`$ ${Number(balance).toFixed(0)}`);

    el
        .append('text')
        .attr('y', 180)
        .attr('x', 180)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '18px')
        .attr('font-weight', 500)
        .text('25%');

    el
        .append('g')
        .attr('class', 'defi-assets')
        .selectAll('g.defi-assets')
        .data(assets)
        .enter()
        .append('svg:image')
        .attr('class', 'cursor-pointer')
        .attr('y', d => d.y)
        .attr('x', d => d.x )
        .attr('height', 28)
        .attr('width', 28)
        .attr('href', d => `../coins-library/${d.value}.svg`)
        .on('error', function() {
            this.onerror = null;
            this.href.baseVal = '../coins-library/no_img.svg';
        });


    el
        .append('g')
        .attr('class', 'defi-fraction')
        .selectAll('g.defi-fractions')
        .data(assets)
        .enter()
        .append('text')
        .attr('y', d => d.y + 50)
        .attr('x', d => d.x + 15)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '16px')
        .attr('font-weight', 300)
        .text(d => `${Number(d.fraction).toFixed(0)}%`);
};

const drawCeFiData = (node, state, actions) => {
    const { balance, top4assets } = state.cefi;
    const assets = dataHelper(top4assets, DATA_CEFI_MOBILE);

    node
        .selectAll('g.right-titles')
        .remove();

    node
        .append('g')
        .attr('class', 'right-titles');

    node
        .select('g.right-titles')
        .append('text')
        .attr('y', 150)
        .attr('x', 170)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-weight', 500)
        .text(`$ ${Number(balance).toFixed(0)}`);

    node
        .select('g.right-titles')
        .append('text')
        .attr('y', 175)
        .attr('x', 175)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '18px')
        .attr('font-weight', 500)
        .text('100%');

    node
        .select('g.right-titles')
        .append('g')
        .attr('class', 'most-active-assets')
        .selectAll('g.most-active-assets')
        .data(assets)
        .enter()
        .append('svg:image')
        .attr('class', 'cursor-pointer')
        .attr('y', d => d.y )
        .attr('x', d => d.x )
        .attr('height', 35)
        .attr('width', 35)
        .attr('href', d => `../coins-library/${d.value}.svg`)
        .on('error', function() {
            this.onerror = null;
            this.href.baseVal = '../coins-library/no_img.svg';
        });
};

const drawNoCeFiData = (node, actions) => {

    node
        .selectAll('path.action-button')
        .on('click', () => actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true));
};

const drawLending = (node, state) => {
    const { suppliedBalance, borrowedBalance, venusNetApy } = state;

    node
        .selectAll('g.lending-titles')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'lending-titles');

    el
        .append('text')
        .attr('y', 115)
        .attr('x', 110)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '18px')
        .attr('font-weight', 300)
        .text(`$ ${suppliedBalance}`);

    el
        .append('text')
        .attr('y', 225)
        .attr('x', 215)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '16px')
        .attr('font-weight', 300)
        .text(`% ${venusNetApy}`);

    el
        .append('text')
        .attr('y', 450)
        .attr('x', 110)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '18px')
        .attr('font-weight', 300)
        .text(`$ ${borrowedBalance}`);

    el
        .append('text')
        .attr('y', 90)
        .attr('x', 110)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '18px')
        .attr('font-weight', 300)
        .text('Supplied');

    el
        .append('text')
        .attr('y', 425)
        .attr('x', 110)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .attr('font-size', '18px')
        .attr('font-weight', 300)
        .text('Borrowed');
};

const wrap = textwrap().bounds({ height: 18, width: 95 });

export const drawHelper = {
    drawTotalsCircle,
    drawWallets,
    dataHelper,
    drawDeFiBalance,
    drawNoCeFiData,
    drawCeFiData,
    drawLending
};
