import React, { useEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import './styles.scss';
import { RemakeDashboardState } from '../../models/remake-dashboard-model';
import { topContentTemplate } from './templates/topContent';
import { debug, useStoreActions, useStoreState } from 'easy-peasy';
import { dashboardConstants } from './dashboardConstants';
import { AddExchangeModal } from '../../components/utility/add-exchange-modal/view';
import { remakeDashboardEffects } from './actions/remakeDashboardEffects';
import { middleContentTemplate } from './templates/middleContent';
import { remakeDashboardFactory } from './actions/remakeDashboardFactory';
import { bottomContent } from './templates/bottomContent';
import { isEmpty } from 'lodash';
import { mobileTopContentTemplate } from './templates/mobile/topContentMobile';
import { mobileMiddleContentTemplate } from './templates/mobile/middleContentMobile';
import { dataHelper } from './dataHelpers/dataHelper';
import { websocketEffect } from '../../components/priceFetcher/websocketEffects';
const { INTERVAL } = dashboardConstants;

const RemakeDashboardLayout = () => {
    const state = RemakeDashboardState.useStoreState(state => state);
    const theme = useStoreState(state => state.root.theme);
    const bots = useStoreState(state => state.botsWidget.originalData);
    const updateState = useStoreActions(actions => actions.updateState);
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);
    const cefiData = useStoreState(state => state?.coinData?.exchangesWithPrices?.all_exchanges);
    const exchanges = useStoreState(state => state?.coinData);
    let exNames = [];
    for (const [key, value] of Object.entries(exchanges?.history)) {
      exNames.unshift({
        name: value.name,
        icon: value.type === 'FUTURES' || value.type === 'BINANCE' ? 'binance' : 'huobi',
      })
    }
    const accs = [...exNames]
    // const exNames = [...[], exchanges?.history[Object.keys(exchanges?.history)]?.name];
    // const accs = [...exNames, userWallet];
    const hasExchange = !isEmpty(exchanges.exchanges);
    const historyState = useStoreState(state => state?.coinData?.history)
    const arrayEx = Object.values(historyState);
    const histories = hasExchange ? arrayEx.map(el => el.history.daily) : [];
    const history = hasExchange ? dataHelper.mergeHistories(histories) : [];
    const mainState = useStoreState(state => state);

    const total = state.cefi.balance + state.defi.balance;
    mainState.coinData.totalUSDT = total;

    useEffect(() => {
        theme !== 'night-theme' ? updateState({ value: 'night-theme', path: 'root.theme' }) : '';
    }, [theme]);

    useEffect(() => {
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'defi' });
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'yields' });
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'vaults' });
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'lending' });
        
        const INTERVAL_ID = setInterval(() => {
            actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'defi' });
            actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'yields' });
            actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'vaults' });
            actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ userWallet, name: 'lending' });
        }, INTERVAL);
 
        return () => clearInterval(INTERVAL_ID);
    }, []);

    useEffect(() => {
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({ cefiData, name: 'cefi' });
    }, [cefiData]);

    return <div className="page-container">
        {
            // state.isLoaded ?
            <CustomScroll heightRelativeToParent="calc(100% - 16px)" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
                <div className="remake-dashboard-visual-content mb-5">
                    { topContentTemplate(accs) } { mobileTopContentTemplate(accs) }
                    { middleContentTemplate(state, theme, history, hasExchange) } { mobileMiddleContentTemplate(state, actions, theme, history, hasExchange) }
                    { bottomContent(state, bots) }
                </div>
                <AddExchangeModal show={state.isModalOpen} action={actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_CLOSED]}/>
            </CustomScroll>
            // <Loader size="6x"/>
        }
    </div>;
};

export const RemakeDashboardPage = () => {
    return <RemakeDashboardState.Provider>
        <RemakeDashboardLayout/>
    </RemakeDashboardState.Provider>;
};
