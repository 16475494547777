import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { TablePoolsTemplate } from './tables/tablePoolsTemplate';
import { LinkContainer } from 'react-router-bootstrap';
import numeral from 'numeral';
import { dataHelper } from '../dataHelpers/dataHelper';

const { POOLS: { HEADING } } = constants;

export const PoolsContent = props => {
    const { actions, state } = props;

    const totalBalance = dataHelper.getFarmsPoolsSum(state.pools.data);
    
    return <div className="pools-content">
        <div className="pools-section">
            <div className="pools-section-header align-items-center">
                <div className="pools-section-header-left d-flex align-items-center">
                    <div className="font-weight-medium me-4"><LinkContainer to='DeFi?elem=pools'><a className='heading-link'>{HEADING}</a></LinkContainer></div>
                </div>
                <div></div>
                <div className="pools-balance text-end me-3">
                    <div className="balance-value">${numeral(totalBalance).format('0,00.00')}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <i onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'pools' })}
                        className={`table-toggler fa-solid fa-chevron-${ state.pools.open ? 'up' : 'down' }`}></i>
                </div>
            </div>
            <Collapse in={state.pools.open}>
                <div className="collapse-content">
                    <div className="pools-section-content">
                        <TablePoolsTemplate />
                    </div>
                </div>
            </Collapse>
        </div>
    </div>;
};