import React, {useEffect} from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Modal from 'react-bootstrap/Modal';

import TermsView from './termsView'
import {Login} from '../../pages/loginPage/view'

import './styles.scss'


const loginModal = () => {
    const showTerms = useStoreState(state => state.loginModal.showTerms);
    const getCookie = useStoreActions(actions => actions.GET_TERM_COOKIE);
    const approveTerms = useStoreActions(actions => actions.APPROVE_THERMS_OF_USE);
    const isLoading = useStoreState(state => state.authentication.loaderLogin);

    useEffect(() => {

        const cookieValue = Boolean(getCookie('acceptedTAC'));

        if(cookieValue) {
           approveTerms();
        }

    },[])

 

    return (
        <>
           <Modal  
            dialogClassName={showTerms ? 'modal-90w' : ''}
            contentClassName={isLoading ? 'bg-transparent' : ''}
            show={true}
            centered
          >
           
            { !showTerms && <Login />}

            {
              showTerms && <TermsView />
            }
        
          </Modal>  
        </>
      );
}

export default loginModal;