import React from 'react';
import ReactDOM from 'react-dom';
import './styles/_index.scss';
import { createStore, StoreProvider } from 'easy-peasy';
import { BrowserRouter as Router } from 'react-router-dom';
import { mainModel } from './models/main-model';
import { Root } from './components/root/view';
window.Buffer = require('buffer/').Buffer;

const { MAIN_MODEL } = mainModel;
const store = createStore(MAIN_MODEL);

ReactDOM.render(
    <StoreProvider store={store}>
        <Router>
            <Root/>
        </Router>
    </StoreProvider>,
    document.getElementById('root'));

