import React, { useEffect } from "react";
import { useStoreState } from 'easy-peasy';
import CustomScroll from "react-custom-scroll";
import './styles.scss';
import { FeedWidget } from "../dashboard/widgets/feed-widget/view";
import { ShowData } from "./showData";
import { NewsState } from '../../models/news-model';
import { newsPageEffects } from './actions/newsPageEffects';

const NewsPageBody= () => {

    const state = useStoreState(state => state.feed);
    const data = state.data;
    const actions = NewsState.useStoreActions(state => state);


    useEffect(() => {
        actions[newsPageEffects.actionTypes.NEWS_DATA_INVOKED](data[0])
    }, []);

    return <div className="feed-message-wraper">
        <div className = "scroll-widget">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar" >
            <FeedWidget data={data} />
        </CustomScroll>
        </div>
        <ShowData />
    </div>
}

export const NewsPage = () => {
    return <NewsState.Provider>
        <NewsPageBody />
    </NewsState.Provider>
};