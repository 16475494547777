import React, { useEffect, useRef } from 'react';
import './styles.scss';
import * as d3 from 'd3';
import { dataHelper } from './dataHelpers/dataHelper';
import { isEmpty } from 'lodash';

const chartNode = (d, svg, arg) => {
    const data = dataHelper.addBlankDonat(d);
    const { radius, stroke } = arg;
    const markRadius = 5.5;

    const arc = d3.arc()
        .innerRadius(radius - stroke)
        .outerRadius(radius)
        .startAngle(d => d.startAngle + 3.1)
        .endAngle(d => d.endAngle + 3.1)
        .cornerRadius(20);

    const pie = d3.pie()
        .padAngle(0)
        .sort(null)
        .value(d => d.value);


    const arcs = pie(data);

    svg
        .append('g')
        .selectAll('path')
        .data(arcs)
        .join('path')
        .attr('fill', d => d.data.color)
        .attr('class', d => d.data.class)
        .attr('d', arc);

    svg
        .append('g')
        .append('circle')
        .attr('cx', 0)
        .attr('cy', radius - markRadius / 2 )
        .attr('r', markRadius)
        .attr('fill', '#49484D')
        .attr('stroke', d.value ? d.color : 'transparent')
        .attr('stroke-width', 2);
};

const drawLegend = (svg, data) => {
    const { radius, subTitle_0, subTitle_1, subTitle_2, colorClass } = data;

	 svg
        .append('g')
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', radius)
        .attr('fill', 'transparent')
        .attr('stroke', 'transparent');

    svg
        .append('g')
        .attr('class', 'legend')
        .append('g')
        .attr('class', 'title');

    svg.select('g.legend')
        .append('g')
        .attr('class', 'sum')
        .append('text')
        .attr('y', 0)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '18px')
        .attr('font-weight', 500)
        .text(subTitle_0);

    svg.select('g.sum')
        .append('text')
        .attr('y', 18)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '10px')
        .attr('font-weight', 300)
        .text(subTitle_1);


    svg.select('g.sum')
        .append('text')
        .attr('y', 35)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '10px')
        .attr('font-weight', 300)
        .append('tspan')
        .attr('class', colorClass)
        .text(subTitle_2);


};

const drawChart = (ref, data, totals, currency, limit) => {
    const SCALE = 0.95;
    const margin = { top: 5, right: 5, bottom: 7, left: 5 };
    const { width, height } = ref.current.getBoundingClientRect();
    const size = d3.min([width, height]) * SCALE;
    const squareSize = size - margin.bottom;
    const svg = d3.select(ref.current).html('').append('svg');
    const stroke = 6;
    const gap = 10;
    const isEmptyData = isEmpty(data);
    data = isEmptyData ? dataHelper.createEmptyData(limit) : data.splice(0, limit);
    const keys = Object.keys(data);

    const container = svg
        .attr('width', size)
        .attr('height', size)
        .append('g')
        .attr('transform', `translate(${size/2}, ${size/2})`);

    const radiuses = dataHelper.createRadiusSet(keys, squareSize, stroke, gap);
    const textAreaRadius = dataHelper.getTextAreaRadius(radiuses, stroke, gap);

    const arg = {
        radius: textAreaRadius,
        ...dataHelper.parseTotals(totals, currency, isEmptyData)
    };

    drawLegend(container, arg);

    keys.forEach((k, i) => {
        const arg = {
            index: i,
            name: k,
            radius: radiuses[i],
            stroke: stroke,
            legendRadius: textAreaRadius
        };
        chartNode(data[k], container, arg);
    });
};

export const DonatChart = (props) => {
    const { limit, data, totals, currency } = props;
    const svgRef = useRef();
    useEffect(() => drawChart(svgRef, data, totals, currency, limit));
    return <div ref={svgRef} className="donat-chart-container"></div>;
};