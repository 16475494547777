import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { lendingConstants } from '../../constants';
import { ButtonCustom } from '../../../../components/utility/custom-buttons/view';
import { DashboardLendingState } from '../../../../models/dashboard-lending-model';
import { uiActions } from '../../actions/uiActions';
import { ButtonGroup, InputGroup, FormControl } from 'react-bootstrap';
import { utils } from '../../../../utils/utils';
import Slider, { Range } from 'rc-slider';
import { lendingEffects } from '../../actions/lendingEffects';
import { dataHelper } from '../../dataHelpers/dataHelper';
import { constants } from '../../../../constants/constants';

const { CHAINS } = constants;

const { PROTOCOLS_DATA, MODAL: { SUPPLY, WITHDRAW, WITHDRAW_AMOUNT, 
    MAX, SUPPLY_APY, 
    CURRENT, BORROW_LIMIT, DAILY_ERANINGS, 
    SUPPLY_BALANCE, BUTTON_SUPPLY_DISABLED, 
    BUTTON_WITHDRAW_DISABLED, BUTTON_BORROW_DISABLED,
    BUTTON_REPAY_DISABLED, BORROW_APY, BORROW, REPAY,
    WALLET_BALANCE, BORROWABLE_AMOUNT, PLACEHOLDER,
    CURRENTLY_BORROWING, BORROW_BALANCE, BORROW_LIMIT_USED, DISTRIBUTION_APY} } = lendingConstants;

export const ModalTemplate = props => {
    const { show, totalBorrowed, usedLimit, chain, userWallet, protocol, limit,
        asset: { borrow_amount, borrow_price, borrow_apy, supply_amount, supply_price, collateral, supply_apy, price, marketEntered, borrowEnabled, token, cToken, supplyVenusApy, borrowVenusApy } } = props;
    const state = DashboardLendingState.useStoreState(state => state.modal);
    const actions = DashboardLendingState.useStoreActions(actions => actions);
    console.log("token: ", cToken);
    const newMaxAllowed = state.type === 'supply' ? state.tab === 'supply' ? Number(dataHelper.countMaxAllowed(collateral, state.form.amount, price)) + Number(limit)
        : Number(limit) - Number(dataHelper.countMaxAllowed(collateral, state.form.amount, price)) : state.tab === 'borrow' ? Number(limit) : Number(limit);
    const newUsedLimit = dataHelper.newLimit(Number(totalBorrowed), Number(newMaxAllowed));
    const withdrawAmount = dataHelper.getWithdrawAmount(limit, totalBorrowed, collateral, price);
    const colorType = state.type === 'supply' ? state.tab === 'supply' ? 'success-color' : 'danger-color' : state.tab === 'borrow' ? 'danger-color' : 'success-color';
    const validButtonValue = state.type === 'supply' ? state.tab === 'supply' ? SUPPLY : WITHDRAW : state.tab === 'borrow' ? BORROW : REPAY;
    const countPrice = Number(state.form.amount) * Number(price);
    const potentialProgressBar = state.type === 'supply' ?
        state.tab === 'supply' ?
            'success-color' : 'danger-color' : state.tab === 'borrow' ?
            Number(dataHelper.percentOfLimit(Number(countPrice) + Number(totalBorrowed), Number(totalBorrowed), Number(newUsedLimit))) :
            dataHelper.percentOfLimit(Number(totalBorrowed) - Number(countPrice), Number(totalBorrowed), Number(newUsedLimit));
    const borrowableAmount = dataHelper.countBorrowableAmount(limit, totalBorrowed, price);
    const nativeToken = CHAINS.find(c => c.oldchain === chain).nativeTokenSymbol;
    const enabled = token.symbol === nativeToken ? true : borrowEnabled;
    const tokenValue = state.type === 'supply' ? state.tab === 'supply' ? state.token : 
        !supply_amount || supply_amount === 0 ? 0 : withdrawAmount : state.tab === 'borrow' ? borrowableAmount : state.token;

    const handleClose = () => actions[uiActions.actionTypes.LENDING_MODAL_INVOKED]({ name: 'close-modal' });
    const onTabHandler = tab => actions[uiActions.actionTypes.LENDING_MODAL_TAB_SWITCHED]({ tab, userWallet, tokenAddress: token.address, chain, symbol: token.symbol });
    const onAmountChangeHandle = e => actions[uiActions.actionTypes.LENDING_MODAL_FORM_CHANGED]({ value: e.target.value });
    const setMaxAmount = value => actions[uiActions.actionTypes.LENDING_MODAL_FORM_CHANGED]({ value });
    const enableInvoked = () => actions[lendingEffects.actionTypes.LENDING_MODAL_ENABLE_INVOKED]({ protocol, from: userWallet, to: token.address, cTokenAddress: cToken.address, chain });
    const onSendTx = method => actions[lendingEffects.actionTypes.LENDING_TX_SENT]({ protocol, from: userWallet, 
        to: cToken.address, amount: state.form.amount, chain, method, isNative: state.isNative });

    const invalidButtonLabel = state.type === 'supply' ? state.tab === 'supply' ? BUTTON_SUPPLY_DISABLED : 
        BUTTON_WITHDRAW_DISABLED : state.tab === 'borrow' ? BUTTON_BORROW_DISABLED : BUTTON_REPAY_DISABLED;

    useEffect(() => {
        actions[lendingEffects.actionTypes.LENDING_MODAL_FORM_VALID]({ value: potentialProgressBar, usedLimit: newUsedLimit, amount: state.form.amount, walletBalance: tokenValue });
    }, [state.form.amount]);

    console.log("STATE: ", state);
 
    return <Modal
        show={show && state.currentTxStatus === 0}
        size="md"
        centered
        className="add-exchange withdraw-supply-enable-modal"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="font-weight-medium modal-main-header align-items-center">
                <div className="d-inline-flex align-items-center">
                    <img className="token-img me-2" src={`../coins-full-library/${token.symbol.toLowerCase()}.svg`} onError={utils.imgErrorHandle} width={24} height={24} />
                    <div>{token.symbol}</div>
                </div>
                <div className="text-end"><i onClick={handleClose} className="fa-light fa-xmark cancel-button"></i></div>
            </div>
            <div className="modal-main-content">
                <div className="supply-withdraw-tabs">
                    {state.type === 'supply' ? <ButtonGroup className="w-100 button-100">
                        <ButtonCustom label={SUPPLY} variant="default" size="sm" onClick={() => onTabHandler('supply')} active={state.tab === 'supply'} name="button_supply"/>
                        <ButtonCustom label={WITHDRAW} variant="default" size="sm" onClick={() => onTabHandler('withdraw')} active={state.tab === 'withdraw'} name="button_withdraw"/>
                    </ButtonGroup> :
                        <ButtonGroup className="w-100 button-100">
                            <ButtonCustom label={BORROW} variant="default" size="sm" onClick={() => onTabHandler('borrow')} active={state.tab === 'borrow'} name="button_borrow"/>
                            <ButtonCustom label={REPAY} variant="default" size="sm" onClick={() => onTabHandler('repay')} active={state.tab === 'repay'} name="button_repay"/>
                        </ButtonGroup>}
                </div>
                {state.type === 'borrow' && state.tab === 'repay' ? <div className="borrow-limit-section-footer">
                    <div className="title">{CURRENTLY_BORROWING}</div>
                    <div className="text-end">{borrow_amount} {token.symbol}</div>
                </div> : null}
                {enabled ? <div className="withdraw-amount">
                    <InputGroup>
                        <InputGroup.Text className="clear">
                            <img className="token-img" src={`../coins-full-library/${token.symbol.toLowerCase()}.svg`} onError={utils.imgErrorHandle} width={16} height={16} />
                        </InputGroup.Text>
                        <FormControl
                            type="number"
                            step={0.01}
                            value={state.form.amount}
                            min={0}
                            onChange={onAmountChangeHandle}
                            className="d-flex align-items-center"
                            placeholder={PLACEHOLDER} />
                        <InputGroup.Text onClick={() => state.type === 'supply' ? setMaxAmount(tokenValue) : 
                            state.tab === 'borrow' ? setMaxAmount((Number(limit) * 0.8 - Number(totalBorrowed)) / Number(price)) : setMaxAmount(borrow_amount) } 
                        className='custom-btn-search'>{state.type === 'supply' ? MAX : state.tab === 'borrow' ? '80% LIMIT' : MAX }</InputGroup.Text>
                    </InputGroup>
                    <div className="withdraw-amount-value mt-1"><span>{state.type === 'supply' ? state.tab === 'supply' ? WALLET_BALANCE : WITHDRAW_AMOUNT :
                        state.tab === 'borrow' ? BORROWABLE_AMOUNT : WALLET_BALANCE}</span>: {tokenValue} {token.symbol}</div>
                </div> : PROTOCOLS_DATA.PLACEHOLDER}
                <div className="apys-section">
                    <div className="token-apy">
                        <div className="d-inline-flex align-items-center">
                            <img className="token-img me-2" src={`../coins-full-library/${token.symbol.toLowerCase()}.svg`} onError={utils.imgErrorHandle} width={16} height={16} />
                            <div className="font-size-14">{state.type === 'supply' ? SUPPLY_APY : BORROW_APY}</div>
                       
                        </div>
                  
                        
                        <div className="text-end">{state.type === 'supply' ? supply_apy : borrow_apy}%</div>
                    </div>
                    <div className="token-apy">
                   
                        <div className="d-inline-flex align-items-center">
                            <img className="token-img me-2" src={`../dashboard-library/protocols/venus.svg`} onError={utils.imgErrorHandle} width={16} height={16} />
                            <div className="font-size-14">{ DISTRIBUTION_APY}</div>
                        </div>
                        
                        <div className="text-end">{state.type === 'supply' ? supplyVenusApy : borrowVenusApy}%</div>
                    </div>
                </div>
                {enabled ? <><div className="borrow-limit-section">
                    <div className="limit-bar-header">
                        <div className="pb-2">{CURRENT}:<span className="ps-2">${totalBorrowed}</span></div>
                        <div className="text-end">{MAX}:<span className="ps-2">${Number(newMaxAllowed).toFixed(2)}</span></div>
                    </div>
                    <Range
                        trackStyle={[
                            { backgroundColor: state.tab === 'withdraw' ? +newUsedLimit > 80 ? 'rgba(249, 57, 57, 0.5)' : 'rgba(0, 168, 89, 1)' : 'rgba(0, 168, 89, 1)' }, 
                            { backgroundColor: state.tab === 'withdraw' ? 
                                +newUsedLimit > 80 ? 'rgba(249, 57, 57, 0.5)' : 'rgba(0, 168, 89, 1)' : potentialProgressBar > 80 ? 'rgba(249, 57, 57, 0.5)' : 'rgba(0, 168, 89, 0.5)' },
                            { backgroundColor: 'transparent' }, { backgroundColor: 'transparent' }]}
                        handleStyle={[{ backgroundColor: 'none', opacity: 0 }, { backgroundColor: 'none', opacity: 0 }, { backgroundColor: 'none', opacity: 0 },
                            { backgroundColor: 'rgba(70, 116, 182, 1)' }]}
                        disabled={true}
                        count={3} 
                        allowCross={true}
                        defaultValue={[0, +newUsedLimit, +newUsedLimit, 80]}
                        value={[0, +newUsedLimit, potentialProgressBar, 80]}
                        min={0} 
                        max={100} 
                        className="slider -primary"
                    />
                    <div className="borrow-limit-section-footer mt-4">
                        <div className="title">{state.type === 'supply' ? BORROW_LIMIT : BORROW_LIMIT_USED}</div>
                        {state.type === 'borrow' ? <div className="text-end">{usedLimit}%<i className={`ps-2 pe-2 ${colorType} fa-light fa-arrow-right`}></i>{potentialProgressBar}%</div> : 
                            state.tab === 'supply' ? <div className="text-end">${Number(limit).toFixed(2)}
                                <i className={`ps-2 pe-2 ${colorType} fa-light fa-arrow-right`}></i>${Number(newMaxAllowed).toFixed(2)}</div> : 
                                <div className="text-end">${Number(limit).toFixed(2)}
                                    <i className={`ps-2 pe-2 ${colorType} fa-light fa-arrow-right`}></i>${Number(newMaxAllowed).toFixed(2)}</div>}
                    </div>
                    {state.type === 'borrow' ? <div className="borrow-limit-section-footer mt-4">
                        <div className="title">{BORROW_BALANCE}</div>
                        <div className="text-end">${totalBorrowed}<i className={`ps-2 pe-2 ${colorType} fa-light fa-arrow-right`}></i>${(Number(totalBorrowed) + Number(countPrice)).toFixed(2)}</div>
                    </div> : null}
                </div>
                <div className="borrow-limit-section-footer">
                    <div className="title">{DAILY_ERANINGS}</div>
                    {state.type === 'supply' ? state.tab === 'supply' ? <div className="text-end">$0.01</div> : 
                        <div className="text-end">$0.00<i className="ps-2 pe-2 danger-color fa-light fa-arrow-right"></i>$0.01</div> : <div className="text-end">$0.00</div>}
                </div>
                {state.type === 'supply' && <div className="borrow-limit-section-footer">
                    <div className="title">{SUPPLY_BALANCE} ({token.symbol})</div>
                    {state.tab === 'supply' ? <div className="text-end">${supply_amount ? Number(supply_amount).toFixed(5) : 0}
                        <i className="ps-2 pe-2 success-color fa-light fa-arrow-right"></i>
                        ${supply_amount ? (Number(state.form.amount) + Number(supply_amount)).toFixed(5) : 0}</div> : 
                        <div className="text-end">{supply_amount ? Number(supply_amount).toFixed(5) : 0}<i className="ps-2 pe-2 danger-color fa-light fa-arrow-right"></i>
                            {Number((supply_amount ? Number(supply_amount).toFixed(5) : 0) - Number(state.form.amount)).toFixed(5)}</div>}
                </div>}
                <ButtonCustom onClick={() => onSendTx(state.tab)} variant="primary" label={state.form.valid ? validButtonValue : invalidButtonLabel} size="md" disabled={!state.form.valid} />
                </> : <ButtonCustom variant="primary" disabled={state.currentTxStatus === 1} label='Enable' onClick={enableInvoked} size="md" />}
            </div>
        </Modal.Body>
    </Modal>;
};