import { action, thunk } from 'easy-peasy';
import { postPairs } from './openOrdersPositionsFactory';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';

const actionTypes = {
  OPEN_ORDERS_POSITIONS_REQUSETS_INVOKED: 'OPEN_ORDERS_POSITIONS_REQUSETS#INVOKED',
  OPEN_ORDERS_POSITIONS_REQUSETS_FAILED: 'OPEN_ORDERS_POSITIONS_REQUSETS#FAILED',
  OPEN_ORDERS_POSITIONS_REQUESETS_SUCCEEDED: 'OPEN_ORDERS_POSITIONS_REQUSETS#SUCCEEDED',
};

const dataInvoked = (actions, payload) => {
  const { method } = payload;
  switch (method) {
    case 'POST':
      postPairs.createExchanges(actions, payload);
      break;
  }
};

const errorAction = () => {
  const notification = { type: 'danger', text: 'Open orders bad request' };
  notifyEffects.notifyAction(notification);
};

const successAction = (state, payload) => {
  const { response } = payload;
  const { openOrders, openPositions } = response;
  state.ordersData = openOrders;
  state.positionsData = openPositions;
};

const actionHandlers = {
  [actionTypes.OPEN_ORDERS_POSITIONS_REQUSETS_INVOKED]: thunk(async (actions, payload) =>
    dataInvoked(actions, payload)
  ),
  [actionTypes.OPEN_ORDERS_POSITIONS_REQUSETS_FAILED]: action((state, payload) =>
    errorAction(state, payload)
  ),
  [actionTypes.OPEN_ORDERS_POSITIONS_REQUESETS_SUCCEEDED]: action((state, payload) =>
    successAction(state, payload)
  ),
};

export const openOrdersPositionsEffects = {
  actionTypes,
  actionHandlers,
};
