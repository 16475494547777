import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { dataHelpers } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';
import { constants } from '../constants';

const { BSC_NETWORK } = constants;
const REQUEST_USER_URL = '/web3/cake/syrup';
const REQUEST_URL = '/web3/pancakedata?data=pools';

const actionTypes = {
    POOLS_FETCHED: 'POOLS#FETCHED',
    POOLS_FETCH_SUCCEEDED: 'POOLS_FETCH#SUCCEEDED',
    POOLS_USER_FETCHED: 'POOLS_USER#FETCHED',
    POOLS_FETCH_USER_SUCCEEDED: 'POOLS_FETCH_USER#SUCCEEDED',
    POOLS_FETCH_FAILED: 'POOLS_FETCH#FAILED',
    POOLS_REWARD_HARVEST_INVOKED: 'POOLS_REWARD_HARVEST#INVOKED',
    POOLS_WITHDROW_INVOKED: 'POOLS_WITHDROW#INVOKED',
    POOLS_DEPOSIT_INVOKED: 'POOLS_DEPOSIT#INVOKED',
    POOLS_APPROVE_INVOKED: 'POOLS_APPROVE#INVOKED',
    POOLS_TX_SUCCEEDED: 'POOLS_TX#SUCCEEDED',
    ALL_POOLS_FETCHED:'ALL_POOLS#FETCHED',
    PANCAKE_POOLS_SUCCEEDED: 'PANCAKE_POOLS#SUCCEEDED',
    QUICK_SWAP_FARM_SUCCEEDED: 'QUICK_SWAP_POOLS#SUCCEEDED'
    
};

const fetchHandle = (actions, payload) => {
    
    const { userWallet, value } = payload;
    
   
    if(value === "all"){
        
        serviceUtils.HttpService({
            url: `/web3/pancakedata?data=pools&address=${userWallet}`,
            errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
            successActionType: actions[actionTypes.POOLS_FETCH_SUCCEEDED]
        });  
        serviceUtils.HttpService({
            url: `/web3/quickdata?data=pools&address=${userWallet}`,
            errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
            successActionType: actions[actionTypes.POOLS_FETCH_SUCCEEDED]
        });  
        
    }
    else{
    // const url = `/web3/${value}data?data=pools&address=${userWallet}`;
    const url = value === 'cake' ? `/web3/pancakedata?data=pools&address=${userWallet}` : `/web3/quickdata?data=pools&address=${userWallet}`;
    serviceUtils.HttpService({
        url, 
       errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
        successActionType: actions[actionTypes.POOLS_FETCH_SUCCEEDED]
    });
        
    }
    
   
};

const fetchUserHandle = (actions, payload) => {
    const { userWallet, value } = payload;

    if(value === "all"){
        
        serviceUtils.HttpService({
            url: `/web3/cake/syrup?address=${userWallet}`,
            errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
            successActionType: actions[actionTypes.POOLS_FETCH_USER_SUCCEEDED]
        });  
      /*  serviceUtils.HttpService({
            url: `/web3/quickdata?data=pools&address=${userWallet}`,
            errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
            successActionType: actions[actionTypes.POOLS_FETCH_SUCCEEDED]
        });*/ 
       
    }
    else if ( value !== "quick"){
    // const url = `/web3/${value}data?data=pools&address=${userWallet}`;
    const url = `/web3/${value}/syrup?address=${userWallet}`;
    // const url = value === 'cake' ? `/web3/cake/syrup?address=${userWallet}` : `/web3/${value}data?data=farms&address=${userWallet}`;
     serviceUtils.HttpService({
         url,
         errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
         successActionType: actions[actionTypes.POOLS_FETCH_USER_SUCCEEDED]
     });
        
    }
    else {
        console.log("Is empty!");
    }
    
};

const fetchAllProtocols= (actions, payload) => {
    const { userWallet } = payload;
  
  serviceUtils.HttpService({
       url: `/web3/cake/syrup?address=${userWallet}`,
         errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
         successActionType: actions[actionTypes.PANCAKE_POOLS_SUCCEEDED]
     });  
     
     serviceUtils.HttpService({
        url: `/web3/quick/syrup?address=${userWallet}`,
         errorActionType: actions[actionTypes.POOLS_FETCH_FAILED],
         successActionType: actions[actionTypes.QUICK_SWAP_FARM_SUCCEEDED]
     }); 
    

}

const successHandle = (state, payload) => {
    const { response: { syrupPools } } = payload;
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.syrupPools = [...state.tableData.syrupPools, ...syrupPools];
    else
        state.tableData.syrupPools = syrupPools;
    
    state.tableData.pagination.hasMore = syrupPools.length < 50 ? false : true;
};

const successPacakeSwapHandle = (state, payload) => {
    const { response: { pools } } = payload;
    state.loader = false;
    state.tableData.pancakeSwapData = pools;
  
};

const successQuickSwapHandle = (state, payload) => {
    const { response: { pools } } = payload;
    state.loader = false;
    state.tableData.quickSwapData = pools;
  
};


const successUserHandle = (state, payload) => {
    const { response: { pools, currentBlock } } = payload;
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.data = [...state.tableData.data, ...pools];
    else
        state.tableData.data = pools;
    
    state.currentBlock = currentBlock;
    state.tableData.data = dataHelpers.formatArrayObject(state.tableData.data);
    state.tableData.pagination.hasMore = pools.length < 50 ? false : true;
    state.tableData.mergedPools = [...state.tableData.data, ...state.tableData.syrupPools];
    state.tableData.mergedPools = [...dataHelpers.uniquePools(state.tableData.mergedPools)];
    state.tableData.filteredData = state.tableData.mergedPools.filter(pool => pool.end_block > state.currentBlock);
};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.tableData.data = state.typeOfFetch === 'push' ? state.tableData.data : [];
    state.tableData.pagination.hasMore = true;
};

const harvestHandle = async (actions, payload) => {
    const { from, to } = payload;
    const { updateState } = actions;
    const { data: { chainName, rpcUrls, blockExplorerUrls, nativeCurrency, chainId } } = BSC_NETWORK;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelpers.getHarvestParams(from, to);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelpers.getHarvestParams(from, to);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const withdrawHandle = async (actions, payload) => {
    const { updateState } = actions;
    const { from, amount, to } = payload;
    const { data: { chainName, rpcUrls, blockExplorerUrls, nativeCurrency, chainId } } = BSC_NETWORK;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelpers.getUnstakeParams(from, amount, to);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelpers.getUnstakeParams(from, amount, to);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const txSuccess = async (actions, payload) => {
    const { hash, from } = payload;
    const { updateState } = actions;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const status = await provider.waitForTransaction(hash);
    if(status.status) {
        updateState({ path: 'currentTxStatus', value: 0 });
        actions[actionTypes.POOLS_FETCHED]({ userWallet: from });
        actions[actionTypes.POOLS_USER_FETCHED]({ userWallet: from });
    }
};

const depositHandle = async (actions, payload) => {
    const { updateState } = actions;
    const { from, amount, to } = payload;
    const { data: { chainName, rpcUrls, blockExplorerUrls, nativeCurrency, chainId } } = BSC_NETWORK;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelpers.getStakeParams(from, amount, to);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelpers.getStakeParams(from, amount, to);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }

};

const approveInvoked = async (actions, payload) => {
    const { updateState } = actions;
    const { from, poolAddress, to } = payload;
    const { data: { chainName, rpcUrls, blockExplorerUrls, nativeCurrency, chainId } } = BSC_NETWORK;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelpers.getApproveParams(from, to, poolAddress);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelpers.getApproveParams(from, to, poolAddress);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const actionHandlers = {
    [actionTypes.POOLS_TX_SUCCEEDED]: thunk(async (actions, payload) => txSuccess(actions, payload)),
    [actionTypes.POOLS_APPROVE_INVOKED]: thunk(async (actions, payload) => approveInvoked(actions, payload)),
    [actionTypes.POOLS_DEPOSIT_INVOKED]: thunk(async (actions, payload) => depositHandle(actions, payload)),
    [actionTypes.POOLS_WITHDROW_INVOKED]: thunk(async (actions, payload) => withdrawHandle(actions, payload)),
    [actionTypes.POOLS_FETCHED]: thunk( async (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.POOLS_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.POOLS_USER_FETCHED]: thunk( async (actions, payload) => fetchUserHandle(actions, payload)),
    [actionTypes.POOLS_FETCH_USER_SUCCEEDED]: action((state, payload) => successUserHandle(state, payload)),
    [actionTypes.POOLS_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.POOLS_REWARD_HARVEST_INVOKED]: thunk(async (actions, payload) => harvestHandle(actions, payload)),
    [actionTypes.ALL_POOLS_FETCHED]: thunk( async (actions, payload) => fetchAllProtocols(actions, payload)),
    [actionTypes.PANCAKE_POOLS_SUCCEEDED]: action((state, payload) => successPacakeSwapHandle(state, payload)),
    [actionTypes.QUICK_SWAP_FARM_SUCCEEDED]: action((state, payload) => successQuickSwapHandle(state, payload)),
    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const poolsEffects = {
    actionTypes,
    actionHandlers
};