import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { TableFarmsTemplate } from './tables/tableFarmsTemplate';
import { LinkContainer } from 'react-router-bootstrap';
import numeral from 'numeral';
import { dataHelper } from '../dataHelpers/dataHelper';

const { FARMS: { HEADING } } = constants;

export const FarmsContent = props => {
    const { actions, state } = props;

    const totalBalance = dataHelper.getFarmsPoolsSum(state.farms.data);
    
    return <div className="farms-content">
        <div className="farms-section">
            <div className="farms-section-header align-items-center">
                <div className="farms-section-header-left d-flex align-items-center">
                    <div className="font-weight-medium me-4"><LinkContainer to='DeFi?elem=farm'><a className='heading-link'>{HEADING}</a></LinkContainer></div>
                </div>
                <div></div>
                <div className="farms-balance text-end me-3">
                    <div className="balance-value">${numeral(totalBalance).format('0,00.00')}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <i onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'farms' })}
                        className={`table-toggler fa-solid fa-chevron-${ state.farms.open ? 'up' : 'down' }`}></i>
                </div>
            </div>
            <Collapse in={state.farms.open}>
                <div className="collapse-content">
                    <div className="farms-section-content">
                        <TableFarmsTemplate />
                    </div>
                </div>
            </Collapse>
        </div>
    </div>;
};