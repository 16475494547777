import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';

const getTooltipContent = (row, label, currency, index) => {
    return <Tooltip
        id={`tooltip-small-value-${index}`}
        className="small-value">
        <div className="tooltip-title align-middle font-size-13 font-weight-bold">{row['COIN']}</div>
        <div className="tooltip-content font-size-12">{label}: {row[label]} {currency}</div>
    </Tooltip>;
};

const formattedCell = (label, row, formattedLabel, currency, index) => {
    return <OverlayTrigger
        placement={'top'}
        overlay={getTooltipContent(row, label, currency, index)}>
        <span>{row[formattedLabel]}</span>
    </OverlayTrigger>;
};

export const smallValueTooltip = {
    formattedCell
};