import React, { useRef } from 'react';
import styles from './styles';
import MetaMaskOnboarding from '@metamask/onboarding';
import { useStoreActions, useStoreState, debug } from 'easy-peasy';
import { authorizationEffect } from './actions/authorizationEffect';
import { ButtonCustom } from '../utility/custom-buttons/view';

const invokeAuth = (onboarding, actions, counter) => {
  MetaMaskOnboarding.isMetaMaskInstalled() ?
      actions[authorizationEffect.actionTypes.AUTHORIZE_SIGN_IN_INVOKED]({counter}) :
      onboarding.current.startOnboarding();
};

const label = state => {
  return   <span>
      { state?.loaderMetamask ? <i className="fa-solid fa-circle-notch fa-spin"></i> : null }
      <span className="ps-3 pe-3">Connect</span>
  </span>
};

export const MetaAuth = () => {
    const state = useStoreState(state => state.authentication);
    const actions = useStoreActions(actions => actions);
    const onboarding = useRef(null);
    const counter = state.counter;

    return state?.connectionButton && !state?.error ?
        <ButtonCustom onClick={() => invokeAuth(onboarding, actions, counter)} disabled={state?.loader} variant="default" label={label(state)}/> : null;
};