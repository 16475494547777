import { serviceUtils } from "../../../../../service/serviceUtils";
import { careersEffects } from "./careersEffects";
import { config } from "../../../../../service/config";

const REQUEST_ENDPOINT = "/upload-cv";
const RECAPTCHA_ENDPOINT = "/re-captcha";
const BASE_URL = config.api_base_url;

const postHandle = (actions, payload) => {
  const { name, email, file } = payload;

  const API = {
    name: name,
    email: email,
    file: file,
  };
  const options = { actions, url: BASE_URL + REQUEST_ENDPOINT, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: "POST",
    data: options.API,
    errorActionType: actions[careersEffects.actionTypes.CAREERS_APPLY_FAILED],
    successActionType:
      actions[careersEffects.actionTypes.CAREERS_APPLY_SUCCEEDED],
    ...options,
  });
};

const postRecaptcha = (actions, payload) => {
  const API = {
    token: payload,
  };
  const options = { actions, url: BASE_URL + RECAPTCHA_ENDPOINT, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: "POST",
    data: options.API,
    errorActionType: actions[careersEffects.actionTypes.TOKEN_FAILED],
    successActionType: actions[careersEffects.actionTypes.TOKEN_SUCCEEDED],
    ...options,
  });
};

export const careersFactory = {
  postHandle,
  postRecaptcha,
};
