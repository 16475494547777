import { createContextStore } from 'easy-peasy';
import { buyEffects } from '../pages/vestingPage/actions/buyEffects';
import { investEffects } from '../pages/vestingPage/actions/investEffects';
import { vestingEffects } from '../pages/vestingPage/actions/vestingEffects';
import { vestingConstants } from '../pages/vestingPage/constants';
import { dataHelper } from '../pages/vestingPage/dataHelpers/dataHelper';

const { DROPDOWN_LIST, USDT_ADDRESSES } = vestingConstants;

const VESTING_MODEL = {
    investors: {
        startDate: dataHelper.countFutureDate(0),
        endDate: dataHelper.countFutureDate(545),
        buyAmount: 0,
        form: {
            mcsAmount: 0,
            validity: false
        },
        invested: {
            data: {},
            chainObject: {},
            isInvested: false
        },
        balance: 0,
    },
    vesting: {
        selector: {
            list: DROPDOWN_LIST,
            value: DROPDOWN_LIST[0]
        },
        form: {
            mcsAmount: 0,
            validity: false
        },
        chain: DROPDOWN_LIST[0].value,
        startTimestamp: null,
        startDate: dataHelper.countFutureDate(0),
        endTimestamp: null,
        endDate: dataHelper.countFutureDate(90),
        currentUsdtAddress: USDT_ADDRESSES[0].usdtAddress,
        invested: {
            data: {},
            chainObject: {},
            isInvested: false
        },
        tab: '90',
        buyAmount: 0,
        amountToWithdraw: 0,
        leftUSDT: 0,
        percentFromLeftUSDT: 0,
        data: [],
        balance: 0,
        cancel: {
            show: false
        },
    },
    buy: {
        buyFormInvoked: false,
        loader: false,
        approved: [],
        maxSupply: 0,
        totalAvailable: 0,
        sold: 0,
        range: 0,
        showModal: false,
        selling: '',
        buying: '',
        enbaledChainUSDT: false,
    },
    ...buyEffects.actionHandlers,
    ...vestingEffects.actionHandlers,
    ...investEffects.actionHandlers
};

export const VestingState = createContextStore(VESTING_MODEL);