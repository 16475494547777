export const constants = {
    DROPDOWN_LIST: [
        { value: '', label: 'All' },
        { value: 'BSC', label: 'BSC' },
        { value: 'MATIC', label: 'MATIC' },
        { value: 'FTM', label: 'FTM' },
        { value: 'ETH', label: 'ETH' },
        { value: 'ARBITRUM', label: 'ARBITRUM' },
    ],
    HEADER_KEYS: ['Blockchain Name', 'Contract Address', 'Value Symbol', 'Amount', 'USD Value', 'USD Price'],
    BLACK_LIST: {
        LABEL: 'Black list',
        BLACK_LIST_EMPTY: 'Black list is empty...',
        BLACK_LIST_MODAL_TABLE: ['Chain', 'Symbol', 'Address'],
        ADD_TO_BLACK_LIST: 'Add tokens to black list'
    }
};