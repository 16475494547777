import React, { useEffect, useRef,useState } from 'react';
import { DynamicSplitState } from '../../models/dynamic-split-model';
import CustomScroll from 'react-custom-scroll';
import './styles.scss';
import { SideContent } from "./templates/sideContent";
import { IframesContent } from "./templates/iframesContent";
import { dynamicSplitEffects } from "./actions/dynamicSplitEffects";


export const SplitViewLayout = () => {
  const state = DynamicSplitState.useStoreState(state => state);
  const actions = DynamicSplitState.useStoreActions(actions => actions);
  const ref = useRef();

  useEffect(() => {
    actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]({method: 'get'});
    
  }, []);

  return <div ref={ref} className="page-container">
    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
      <div className="dynamic-split-layout font-inter content ps-3 pe-3 d-flex">
        <SideContent state={state} actions={actions}/>
        <IframesContent state={state} actions={actions}/>
      </div>
    </CustomScroll>
  </div>;
};

export const DynamicSplitPage = () => <DynamicSplitState.Provider>
  <SplitViewLayout/>
</DynamicSplitState.Provider>;
