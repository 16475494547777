import React from 'react';
import { StandardOptions } from '../../../../../components/utility/standard-options/view';
import { Selector } from '../../../../../components/utility/dropdown-selector/view';
import { barChartEffects } from '../barChartEffects';
import { constants } from '../../../../../constants/constants';
import { isEmpty } from 'lodash';

const { BAR_CHART, POPPER_DATA } = constants;

const _renderTitle = () => {
    return <div className="bar-chart-widget-title text-uppercase font-size-14 font-weight-medium">
        {BAR_CHART.WIDGET_TITLE}
    </div>;
};

const _renderExchangeSelect = state => {
    const value = state.currentExchange;
    const list = state.exchangesList;
    return <div className="exchange-selector">
        <Selector
            classes=""
            list={list}
            disabled={isEmpty(list)}
            value={value}
            title={'exchange'}
            titleClasses="text-capitalize me-2"
            iconPosition="right"
            menuAlign="end"
            popperData={POPPER_DATA}
            actionName={barChartEffects.actionTypes.BAR_CHART_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED}
        />
    </div>;
};

const _renderTypeSelect = state => {
    const exchangeId = state.currentExchange.id;
    const list = state.typeList[exchangeId];
    const value = state.currentType;
    return <div className="year-selector">
        <Selector
            classes=""
            list={list}
            disabled={isEmpty(list)}
            value={value}
            title={value.id}
            titleClasses="text-capitalize me-2"
            iconPosition="right"
            menuAlign="end"
            popperData={POPPER_DATA}
            actionName={barChartEffects.actionTypes.BAR_CHART_WIDGET_UI_ACTIONS_TYPE_CHANGED}
        />
    </div>;
};

const _renderStandardOptions = loading => {
    return <div className="standard-options-selector">
        <StandardOptions
            refresh = {{ type: 'history' }}
            more={{ ...BAR_CHART.STANDARD_OPTIONS_SCHEMA }}
            expand={{ type: 'expand' }}
            isLoading={loading}
        />
    </div>;
};

export const renderControlPanel = (state, loading) => {
    return <div className="bar-chart-widget-controls-container d-flex justify-content-between align-items-top ps-3 pe-3">
        { _renderTitle() }
        { _renderExchangeSelect(state) }
        { _renderTypeSelect(state) }
        { _renderStandardOptions(loading) }
    </div>;
};