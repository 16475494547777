import { action } from 'easy-peasy';
import { constants } from '../constants';
import { defaultValueHelper } from '../dataHelpers/defaultValueHelper';
import { mockData } from '../mock-data/mock-data';
import { dataHelper } from '../dataHelpers/dataHelper';
import { orderBy } from 'lodash';

const {
  CONTROLS,
  TABS
} = constants;

const actionTypes = {
  CUSTOM_BOTS_FORM_ELEMENTS_CHANGED: 'CUSTOM_BOTS_FORM_ELEMENTS#CHANGED',
  CUSTOM_BOTS_DYNAMIC_FIELDS_CHANGED: 'CUSTOM_BOTS_DYNAMIC_FIELDS#CHANGED',
  CUSTOM_BOTS_FILTERS_FORM_ELEMENTS_CHANGED: 'CUSTOM_BOTS_FILTERS_FORM_ELEMENTS#CHANGED',
  CUSTOM_BOTS_PAIRS_BLACK_LIST_FORM_ELEMENTS_CHANGED: 'CUSTOM_BOTS_PAIRS_BLACK_LIST_FORM_ELEMENTS#CHANGED',
  CUSTOM_BOTS_MY_BOTS_TABLE_FORM_ELEMENTS_CHANGED: 'CUSTOM_BOTS_MY_BOTS_TABLE_FORM_ELEMENTS#CHANGED',
  CUSTOM_BOTS_MY_BOTS_LIST_INVOKED: 'CUSTOM_BOTS_MY_BOTS_LIST#INVOKED',
  CUSTOM_BOTS_SORT_BY_CHANGED: 'CUSTOM_BOTS_SORT_BY#CHANGED',
  CUSTOM_BOTS_APPLY_BOT_CONFIGURATION_FORM_ELEMENTS_CHANGED: 'CUSTOM_BOTS_APPLY_BOT_CONFIGURATION_FORM_ELEMENTS#CHANGED',
  CUSTOM_BOTS_MY_DEALS_LIST_INVOKED: 'CUSTOM_BOTS_MY_DEALS_LIST#INVOKED',
  CUSTOM_BOTS_EXCHANGE_INIT: 'CUSTOM_BOTS_EXCHANGE#INIT',
  CUSTOM_BOTS_SELECTORS_CHANGED: 'CUSTOM_BOTS_SELECTORS#CHANGED',
  CUSTOM_BOTS_SELECTORS_DATA_UPDATED: 'CUSTOM_BOTS_SELECTORS_DATA#UPDATED'
};

const stateHandle = (state, payload) => {
  const { name, value, index } = payload;

  switch (name) {
    case CONTROLS.LABEL_EXCHANGE: state.forms.exchangeSelector.value = payload; break;
    case CONTROLS.LABEL_QUOTE_CURRENCY: state.forms.quoteCurrencySelector.value = payload; break;
    case 'input_for_amount': state.forms.inputForAmount = value; break;
    case TABS.FORM_TABS.SAFE_SLOW.VALUE:
    case TABS.FORM_TABS.MODERATE.VALUE:
    case TABS.FORM_TABS.RISKIER_FAST.VALUE:
      state.forms.radioForType = value; break;
    case TABS.FORM_TABS.GORDON.VALUE:
    case TABS.FORM_TABS.WIZARD.VALUE:
    case TABS.FORM_TABS.ADVANCED.VALUE:
      state.forms.radioForBots = value; break;
    case 'checkboxDontShowAgain': state.forms.checkboxDontShowAgain = value; break;
    case TABS.FORM_TABS.PERCENTAGE_10:
    case TABS.FORM_TABS.PERCENTAGE_25:
    case TABS.FORM_TABS.PERCENTAGE_50:
    case TABS.FORM_TABS.PERCENTAGE_75:
    case TABS.FORM_TABS.PERCENTAGE_100:
      state.forms.radioForPercentage = value; break;

    // MAIN SETTINGS
    case 'main_settings_name': state.forms.mainSettings.name = value; break;
    case TABS.FORM_TABS.SINGLE_PAIR:
    case TABS.FORM_TABS.MULTI_PAIR:
      state.forms.mainSettings.botType = value; break;
    case 'exchange_selected': state.forms.mainSettings.exchangeSelector.value = payload; break;

    // PAIRS
    case 'most_traded_pairs': state.forms.pairs.mostTradedPairs = value; break;
    case 'label_pairs': state.forms.pairs.pairsSelector.value = payload; break;

    // STRATEGY
    case TABS.FORM_TABS.LONG:
    case TABS.FORM_TABS.SHORT:
      state.forms.strategy.strategy = value; break;
    case TABS.FORM_TABS.QUOTE:
    case TABS.FORM_TABS.BASE:
      state.forms.strategy.profitCurrency = value; break;
    case TABS.FORM_TABS.MARKET:
    case TABS.FORM_TABS.LIMIT:
      state.forms.strategy.startOrderType = value; break;
    case 'base_order_size': state.forms.strategy.baseOrderSizeSelector.value = payload; break;
    case 'base_order_size_amount': state.forms.strategy.baseOrderSizeAmount = value; break;

    // DEAL START CONDITION
    case 'condition': state.forms.dealStartCondition.conditionFields[index].conditionsStartingBotSelector.value = payload; break;
    case 'deal_start_rsi_period_amount': state.forms.dealStartCondition.conditionFields[index].rsiPeriodAmount = value; break;
    case 'timeframe': state.forms.dealStartCondition.conditionFields[index].timeframeSelector.value = payload; break;
    case 'deal_start_timeframe': state.forms.dealStartCondition.conditionFields[index].timeframeTimeSelector.value = payload; break;
    case 'deal_start_conditions': state.forms.dealStartCondition.conditionFields[index].conditionSelector.value = payload; break;
    case 'deal_start_signal_value': state.forms.dealStartCondition.conditionFields[index].signalValueSelector.value = payload; break;
    case 'deal_start_presets': state.forms.dealStartCondition.conditionFields[index].presetsSelector.value = payload; break;
    case 'deal_start_signal_value_amount': state.forms.dealStartCondition.conditionFields[index].signalValueAmount = value; break;

    // SAFETY ORDERS
    case 'safety_orders_size': state.forms.safetyOrders.safetyOrdersSizeSelector.value = payload; break;
    case 'safety_orders_size_amount': state.forms.safetyOrders.safetyOrdersSizeAmount = value; break;
    case 'price_deviation_amount':
    case 'slider_price_deviation_amount':
      state.forms.safetyOrders.priceDeviationAmount = value;
      state.forms.safetyOrders.sliderPriceDeviationAmount = value;
      break;
    case 'safety_orders_count_amount':
    case 'slider_safety_orders_count_amount':
      state.forms.safetyOrders.safetyOrdersCountAmount = value;
      state.forms.safetyOrders.sliderSafetyOrdersCountAmount = value;
      break;
    case 'active_safety_orders_count_amount':
    case 'slider_active_safety_orders_count_amount':
      state.forms.safetyOrders.activeSafetyOrdersCountAmount = value;
      state.forms.safetyOrders.sliderActiveSafetyOrdersCountAmount = value;
      break;
    case 'safety_order_volume_scale_amount':
    case 'slider_safety_order_volume_scale_amount':
      state.forms.safetyOrders.safetyOrderVolumeScaleAmount = value;
      state.forms.safetyOrders.sliderSafetyOrderVolumeScaleAmount = value;
      break;
    case 'safety_order_step_scale_amount':
    case 'slider_safety_order_step_scale_amount':
      state.forms.safetyOrders.safetyOrderStepScaleAmount = value;
      state.forms.safetyOrders.sliderSafetyOrderStepScaleAmount = value;
      break;

    // STOP LOSS
    case 'toggle_stop_loss': state.forms.stopLoss.toggleStopLoss = value; break;
    case 'stop_loss_percentage': state.forms.stopLoss.stopLossPercentage = value; break;
    case 'stop_loss_action': state.forms.stopLoss.stopLossActionSelector.value = payload; break;
    case 'toggle_stop_loss_timeout': state.forms.stopLoss.toggleStopLossTimeout = value; break;
    case 'stop_loss_timeout': state.forms.stopLoss.stopLossTimeout = value; break;

    // TAKE PROFIT
    case 'target_profit_percentage': state.forms.takeProfit.targetProfitPercentage = value; break;
    case 'profit_type': state.forms.takeProfit.takeProfitTypeSelector.value = payload; break;
    case 'toggle_trailing_percent': state.forms.takeProfit.toggleTrailingPercent = value; break;
    case 'trailing_deviation_percentage': state.forms.takeProfit.trailingDeviationPercentage = value; break;
    case 'toggle_trailing_conditions': state.forms.takeProfit.toggleTrailingConditions = value; break;
    case 'minimum_profit_percentage': state.forms.takeProfit.minimumProfitPercentage = value; break;
    case 'minimum_profit_type': state.forms.takeProfit.minimumProfitTypeSelector.value = payload; break;
    case 'conditions_closing_bot': state.forms.takeProfit.conditionFields[index].conditionsClosingBotSelector.value = payload; break;
    case 'conditions_timeframe': state.forms.takeProfit.conditionFields[index].timeframeSelector.value = payload; break;
    case 'signal_value': state.forms.takeProfit.conditionFields[index].signalValueSelector.value = payload; break;
    case 'signal_value_amount': state.forms.takeProfit.conditionFields[index].signalValueAmount = value; break;
    case 'presets': state.forms.takeProfit.conditionFields[index].presetsSelector.value = payload; break;
    case 'rsi_period_amount': state.forms.takeProfit.conditionFields[index].rsiPeriodAmount = value; break;
    case 'conditions': state.forms.takeProfit.conditionFields[index].conditionSelector.value = payload; break;

    // ADVANCED SETTINGS
    case 'daily_volume_less': state.forms.advancedSettings.dailyVolumeLess = value; break;
    case 'minimum_price_deal': state.forms.advancedSettings.minimumPriceDeal = value; break;
    case 'maximum_price_deal': state.forms.advancedSettings.maximumPriceDeal = value; break;
    case 'cooldown_between_deals': state.forms.advancedSettings.cooldownBetweenDeals = value; break;
    case 'open_deals_stop': state.forms.advancedSettings.openDealsStop = value; break;
    case 'toggle_deals_to_smart_trade': state.forms.advancedSettings.toggleDealsToSmartTrade = value; break;
    case 'toggle_close_deal_after_timeout': state.forms.advancedSettings.toggleCloseDealAfterTimeout = value; break;
    case 'close_deal_after_timeout': state.forms.advancedSettings.closeDealAfterTimeoutSelector.value = payload; break;
    case 'close_deal_after_timeout_amount': state.forms.advancedSettings.closeDealAfterTimeoutAmount = value; break;
  }
}

const fieldsHandle = (state, payload) => {
  const { index, deal_start } = payload;
  if (deal_start) {
    if (index === undefined) state.forms.dealStartCondition.conditionFields.push(defaultValueHelper.getDealStartConditionFields());
    else state.forms.dealStartCondition.conditionFields.splice(index, 1);
  } else {
    if (index === undefined) state.forms.takeProfit.conditionFields.push(defaultValueHelper.getConditionsFields());
    else state.forms.takeProfit.conditionFields.splice(index, 1);
  }
}

const filtersHandle = (state, payload) => {
  const { name, value } = payload;

  switch (name) {
    case CONTROLS.LABEL_EXCHANGE: state.filters.exchangeSelector.value = value; break;
    case CONTROLS.LABEL_MARKET: state.filters.marketSelector.value = value; break;
    case CONTROLS.LABEL_TRADING_PAIR: state.filters.tradingPairSelector.value = value; break;
    case CONTROLS.LABEL_DEAL_START_CONDITION: state.filters.dealStartConditionSelector.value = value; break;
    case TABS.FORM_TABS.SINGLE_PAIR:
    case TABS.FORM_TABS.MULTI_PAIR:
    case TABS.FORM_TABS.ALL:
      state.filters.botType = value; break;
    case TABS.FORM_TABS.LONG:
    case TABS.FORM_TABS.SHORT:
    case TABS.FORM_TABS.ALLL:
      state.filters.strategyType = value; break;
    case 'slider_days_running':
      state.filters.sliderDaysRunning = value;
      state.filters.daysRunningFrom = value[0];
      state.filters.daysRunningTo = value[1];
      break;
    case 'days_running_from':
      state.filters.sliderDaysRunning = [value, state.filters.sliderDaysRunning[1]];
      state.filters.daysRunningFrom = value;
      break;
    case 'days_running_to':
      state.filters.sliderDaysRunning = [state.filters.sliderDaysRunning[0], value];
      state.filters.daysRunningTo = value;
      break;
  }
}

const pairsBlackListHandle = (state, payload) => {
  const { name, value } = payload;
  switch (name) {
    case 'pairs_black_list': state.pairsBlackList.pairsBlackListSelector.value = value; break;
  }
}

const myBotsTableHandle = (state, payload) => {
  const { name, value, i } = payload;

  switch (name) {
    case 'filters': state.myBotsTable.filtersSelector.value = value; break;
    case 'all-checked':
      state.myBotsTable.list.forEach(item => item.checked = value);
      break;
    case 'checkbox':
      state.myBotsTable.list[i].checked = value;
      break;
  }
};

const myBotsTableSort = (state, payload) => {
  const { name, orders } = payload

  switch (name) {
    case 'name':
      state.myBotsTable.list = orderBy(state.myBotsTable.list, (i) => i.name.toLowerCase(), orders);
      break;
    case 'deals':
      state.myBotsTable.list = dataHelper.sortByValue(state.myBotsTable.list, 'deals', orders);
      break;
    case 'takeProfit':
      state.myBotsTable.list = dataHelper.sortByValue(state.myBotsTable.list, 'takeProfit', orders);
      break;
    case 'avgDailyProfit':
      state.myBotsTable.list = dataHelper.sortByValue(state.myBotsTable.list, 'avgDailyProfit', orders);
      break;
    case 'exchange':
      state.myBotsTable.list = orderBy(state.myBotsTable.list, (i) => i.exchange.name.toLowerCase(), orders);
      break;
    case 'bot':
      state.myDealsTable.list = orderBy(state.myDealsTable.list, (i) => i.name.toLowerCase(), orders);
      break;
    case 'pair':
      state.myDealsTable.list = orderBy(state.myDealsTable.list, (i) => i.pair.toLowerCase(), orders);
      break;
    case 'profit':
      state.myDealsTable.list = dataHelper.sortByValue(state.myDealsTable.list, 'profit', orders);
      break;
    case 'volume':
      state.myDealsTable.list = dataHelper.sortByValue(state.myDealsTable.list, 'volume', orders);
      break;
    case 'status':
      state.myDealsTable.list = orderBy(state.myDealsTable.list, (i) => i.status.toLowerCase(), orders);
      break;
    case 'created':
      state.myDealsTable.list = dataHelper.sortByValue(state.myDealsTable.list, 'timestamp', orders);
      break;
  }
};

const myBotsListInvokeHandle = (state, payload) => {
  const arr = mockData.myBots;
  state.myBotsTable.list = arr.map(obj => ({ ...obj, checked: false }));
};

const applyBotConfigurationStateHandle = (state, payload) => {
  const { name, value } = payload;

  switch (name) {
    case 'apply_bot_configuration_name': state.applyBotConfiguration.name = value; break;
    case 'apply_bot_configuration_max_amount_bot_usage': state.applyBotConfiguration.maxAmountBotUsage = value; break;
  }
};

const myDealsListInvokeHandle = (state, payload) => {
  state.myDealsTable.list = mockData.myDeals;
};

const exchangeHandle = (state, payload) => {
  state.forms.exchangeSelector.list = payload;
  state.forms.mainSettings.exchangeSelector.list = payload;
};

const selectorHandle = (state, payload) => {
  const {name} = payload;
  let listData;
  switch (name) {
    case 'init_exchange':
      const {exchange: {value: {api}}} = payload;
      listData = dataHelper.generateMainCoinList(state.assetsData, api);
      state.coinData = listData.pairs;
      break;
    case 'most_traded_pairs':
      const { value } = payload;
      state.forms.pairs.pairsSelector.list = dataHelper.generatePairCoinList(value, state.coinData);
      break;
  }
};

const assetsDataHandle = (state, payload) => {
  state.assetsData = payload;
};

const actionHandlers = {
  [actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]: action((state, payload) => stateHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_DYNAMIC_FIELDS_CHANGED]: action((state, payload) => fieldsHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_FILTERS_FORM_ELEMENTS_CHANGED]: action((state, payload) => filtersHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_PAIRS_BLACK_LIST_FORM_ELEMENTS_CHANGED]: action((state, payload) => pairsBlackListHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_MY_BOTS_TABLE_FORM_ELEMENTS_CHANGED]: action((state, payload) => myBotsTableHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_MY_BOTS_LIST_INVOKED]: action((state, payload) => myBotsListInvokeHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_SORT_BY_CHANGED]: action((state, payload) => myBotsTableSort(state, payload)),
  [actionTypes.CUSTOM_BOTS_APPLY_BOT_CONFIGURATION_FORM_ELEMENTS_CHANGED]: action((state, payload) => applyBotConfigurationStateHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_MY_DEALS_LIST_INVOKED]: action((state, payload) => myDealsListInvokeHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_EXCHANGE_INIT]: action( (state, payload) => exchangeHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_SELECTORS_CHANGED]: action((state, payload) => selectorHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_SELECTORS_DATA_UPDATED]: action((state, payload) => assetsDataHandle(state, payload))
};

export const customBotsEffects = {
  actionTypes,
  actionHandlers
};
