import React from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import FilterList from './FilterList';
import { MultiplyCustomSelector } from '../../../../../components/utility/multiply-selector/view';
import { constants } from '../constants';

const { filter } = constants.ADMIN_ARTICLES;
const {
  buttons: { btnFilters },
} = constants.ARTICLES;

const Filter = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  let categories = AcademyState.useStoreState((state) => state.categories.list);

  const mode = state.admin.mode;
  const { search } = state.admin.filter;
  const { selectedFilters, showFilters: isShowFilterList } = state.filters;

  const academyUrl = window.location.search;

  const isPublicPage = academyUrl === '?elem=academy';
  const isAdminPage = academyUrl === '?elem=academy/admin';

  const publisOptions = [
    { label: 'Publish', value: true },
    { label: 'Unpublish', value: false },
  ];

  const AdminFilter = () => {
    return (
      <div className="admin">
        <div onClick={actions.UPDATE_FILTERS_TOGGLE} className="update-block btn btn-light">
          Update filters
        </div>
        <div className="admin-filter">
          <div className="filterSearch">
            <label>{filter.search.label}</label>
            <input
              onChange={(e) => actions.FILTER_SEARCH(e.target.value)}
              value={search}
              type="text"
              className="search"
              placeholder={`${filter.search.placeholder}`}
            />
          </div>
          <div className="select-dropdown">
            <label>{filter.publish.label}</label>
            <MultiplyCustomSelector
              options={publisOptions}
              defaultValue={{ label: 'Publish', value: true }}
              onChangeHandle={(val) => actions.FILTER_PUBLISH_UNPUBLISH(val.value)}
              closeMenuOnSelect={true}
              isMulti={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const PublicFilter = () => {
    return (
      <>
        <div className="select-dropdown">
          <MultiplyCustomSelector
            options={categories}
            onChangeHandle={(val) => actions.FILTER_CATEGORY(val.value)}
            initialValue={{ label: 'Academy', value: 'Academy' }}
            closeMenuOnSelect={true}
            isMulti={false}
          />
        </div>
        <button onClick={actions.FILTER_LIST_TOGGLE}>
          <span className="icon">
            {selectedFilters.length > 0 ? (
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.2488 0.979126C1.46712 0.979126 0.833336 1.61291 0.833336 2.39459C0.833336 2.72401 0.948277 3.04306 1.15832 3.29693L7.70834 11.2128V14.1634C7.70834 14.5179 7.87305 14.8509 8.15235 15.0657L10.333 16.7666C10.5479 16.9313 10.8092 17.0208 11.0778 17.0208C11.7474 17.0208 12.2917 16.4765 12.2917 15.8069V11.2128L18.8408 3.29693C19.0521 3.04306 19.1667 2.72401 19.1667 2.39459C19.1667 1.61291 18.5329 0.979126 17.7523 0.979126H2.2488Z"
                  fill="#4674B6"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.833496 2.39459C0.833496 1.61291 1.46729 0.979126 2.24896 0.979126H17.7524C18.533 0.979126 19.1668 1.61291 19.1668 2.39459C19.1668 2.72401 19.0522 3.04306 18.841 3.29693L12.2918 11.2128V15.8069C12.2918 16.4765 11.7476 17.0208 11.078 17.0208C10.8094 17.0208 10.548 16.9313 10.3332 16.7666L8.15251 15.0657C7.87321 14.8509 7.7085 14.5179 7.7085 14.1634V11.2128L1.15848 3.29693C0.948437 3.04306 0.833496 2.72401 0.833496 2.39459ZM2.24896 2.12496C2.1 2.12496 1.97933 2.24563 1.97933 2.39459C1.97933 2.45725 2.00117 2.51812 2.04128 2.56646L8.72184 10.6399C8.80778 10.7438 8.85433 10.8727 8.85433 11.0052V14.1634L11.0063 15.8606C11.0493 15.8714 11.0636 15.875 11.078 15.875C11.1173 15.875 11.146 15.8463 11.146 15.8069V11.0052C11.146 10.8727 11.1925 10.7438 11.2785 10.6399L17.9601 2.56646C17.9995 2.51812 18.021 2.45725 18.021 2.39459C18.021 2.24563 17.8993 2.12496 17.7524 2.12496H2.24896Z"
                  fill="#34353F"
                />
              </svg>
            )}
          </span>

          {btnFilters}
        </button>
      </>
    );
  };

  return (
    <div className="filter">
      {isPublicPage && (
        <>
          {PublicFilter()}

          {isShowFilterList && <FilterList />}
        </>
      )}

      {isAdminPage && mode === '' && AdminFilter()}
    </div>
  );
};

export default Filter;
