import React, { Fragment, useEffect, useState } from 'react';
import Big from 'big.js';
import Toggle from 'react-toggle';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { constants } from '../constants';
import '../styles/table_template.scss';
import { Button } from 'react-bootstrap';
import { Asset } from '../../../components/utility/asset-component/view';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { smartTradeBotSettingsEffects } from '../actions/smartTradeBotSettingsEffects';
import { refreshEffects } from '../../../components/priceFetcher/refreshEffects';
import { Collapse } from 'react-bootstrap';
import { tableEffects } from '../actions/tableEffects';
// import { PriceLineTemplate } from './priceLineTemplate';
import { StandardOptions } from '../../../components/utility/standard-options/view';
import { CancelTradeModal } from './tableModals/cancelTradeModal';
import { CloseTradeModal } from './tableModals/closeTradeModal';
import { ActiveTradesTemplate } from './activeTrades/activeTradesTemplate';
import { BuySellStepsTemplate } from './buySellSteps/buySellStepsTemplate';
import { AddReduceTradeModal } from './tableModals/addReduceFunds';
import { tradeMockData } from '../mock-data/active-trade-mock-data';
import { OpenOrderTemplate } from './openOrderTemplate';
import Select from 'react-select';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';

const {
  TABLE_HEADERS_KEYS,
  TABLE_HISTORY_KEYS,
  TABLE_HEADERS_ACTIVE_KEYS,
  // TABLE_COLLAPSE_CONTENT: {
  //   SELL_STEPS: {
  //     STEPS_LABEL,
  //     STEPS_STATUS
  //   }
  // },
  TABLE_HEADERS: { STANDARD_OPTIONS_SCHEMA },
  TABLE_PARAMS_AND_PROFITS: {
    GRID_PARAMS_TITLE,
    DCA_PARAMS_TITLE,
    PROFITS_TITLE,
    UPPER_LIMIT_PRICE,
    LOWER_LIMIT_PRICE,
    QUANTITY_PER_GRID,
    GRID_WIDTH,
    ACRIVE_ORDERS,
    INVESTMENT,
    OPTIONS,
    CONDITION,
    BTN_RECENT_TRANSACTION,
  },
  TABLE_HISTORY: { FILTERS, TABLE },
} = constants;

const options = {
  action: 'refresh',
  loading: false,
  method: 'GET',
  type: 'bots',
  url: '/bots',
};

const renderControlsContent = (state, onClickHandler) => {
  return (
    <div className="content-controls">
      <Button
        className="btn-link"
        variant="link"
        size="sm"
        name="button_table_active"
        active={state.viewType.active}
        onClick={onClickHandler}
      >
        Active
      </Button>
      <Button
        className="btn-link"
        variant="link"
        size="sm"
        name="button_table_history"
        active={state.viewType.history}
        onClick={onClickHandler}
      >
        History
      </Button>
    </div>
  );
};

const historyFiltersTemplate = (isCollapseFilterHistory, setCollapseFilterHistory, theme) => {
  const options = [{ value: 'Dates range', label: 'Dates range' }];

  const optionsAccount = [{ label: '', value: '' }];

  const optionsPair = [{ label: '', value: '' }];

  const styles = {
    control: (base) => ({
      ...base,
      cursor: 'pointer',
      boxShadow: 'none',
      borderColor: '#4674B6',
      background: '#4674B6',
      color: 'white',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
      fontSize: '14px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  const showCollapseArrow = (isCollapseFilterHistory) => {
    if (isCollapseFilterHistory) {
      return <i className="fa fa-chevron-up ms-2"></i>;
    } else {
      return <i className="fa fa-chevron-down ms-2"></i>;
    }
  };

  return (
    <>
      {
        <div
          className={`history-filters-template ${
            theme === 'day-theme' ? 'history-filters-day-theme' : 'history-filters-night-theme'
          }`}
        >
          <header>
            <div>
              <p>{FILTERS.MAIN_TITLE}</p>
              <span
                onClick={() => setCollapseFilterHistory(!isCollapseFilterHistory)}
                className="arrow"
              >
                {showCollapseArrow(isCollapseFilterHistory)}
              </span>
            </div>
            {isCollapseFilterHistory && <button>{FILTERS.BTN_FILTERS}</button>}
          </header>
          <Collapse in={isCollapseFilterHistory}>
            <main>
              <div className="top">
                <div className="col">
                  <label>{FILTERS.LABEL_CREATED}</label>
                  <div className="datesRange">
                    <div className="datesDropdown">
                      <Select
                        options={options}
                        styles={styles}
                        isClearable={false}
                        defaultValue={options[0]}
                      />
                    </div>
                    <input type="text" />
                  </div>
                </div>
                <div className="col">
                  <label>{FILTERS.LABEL_CLOSED}</label>
                  <div className="datesRange">
                    <div className="datesDropdown">
                      <Select
                        options={options}
                        styles={styles}
                        isClearable={false}
                        defaultValue={options[0]}
                      />
                    </div>
                    <input type="text" />
                  </div>
                </div>
                <div className="col">
                  <label>{FILTERS.LABEL_ACCOUNT}</label>
                  <div className="account">
                    <MultiplyCustomSelector
                      options={optionsAccount}
                      // onChangeHandle={changeHandle}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      // value={state.orderBookData.lengthSelector.value}
                    />
                  </div>
                </div>
                <div className="col">
                  <label>{FILTERS.LABEL_PAIR}</label>
                  <div className="pair">
                    <MultiplyCustomSelector
                      options={optionsPair}
                      // onChangeHandle={changeHandle}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      // value={state.orderBookData.lengthSelector.value}
                    />
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="toggle-buttons">
                  <div>
                    <Toggle icons={false} defaultChecked={true} />
                    {FILTERS.THUMB_CANCELED}
                  </div>
                  <div>
                    <Toggle icons={false} defaultChecked={true} />
                    {FILTERS.THUMB_FINSHHED}
                  </div>
                  <div>
                    <Toggle icons={false} defaultChecked={true} />
                    {FILTERS.THUMB_FAILED}
                  </div>
                  <div>
                    <Toggle icons={false} defaultChecked={true} />
                    {FILTERS.THUMB_SIMPLETRADE}
                  </div>
                </div>
                <button>
                  <span className="icon">
                    <svg
                      width="13"
                      height="18"
                      viewBox="0 0 13 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0583 4.74528L8.59147 1.27848C8.19759 0.88597 7.67025 0.666504 7.11686 0.666504H2.25033C1.09961 0.666504 0.166992 1.59912 0.166992 2.74984L0.167204 15.2498C0.167204 16.4006 1.09982 17.3332 2.25054 17.3332H10.5837C11.7344 17.3332 12.667 16.4006 12.667 15.2498V6.21663C12.667 5.66325 12.4489 5.13591 12.0583 4.74528ZM7.45866 1.77588C7.60439 1.82686 7.74075 1.90107 7.85319 2.01348L11.32 5.48027C11.4333 5.59163 11.5081 5.72835 11.557 5.87484H7.97949C7.69303 5.87484 7.45866 5.64046 7.45866 5.354V1.77588ZM11.6253 15.2498C11.6253 15.8241 11.1579 16.2915 10.5837 16.2915H2.25033C1.67611 16.2915 1.20866 15.8241 1.20866 15.2498V2.74984C1.20866 2.17562 1.67611 1.70817 2.25033 1.70817H6.41699V5.354C6.41699 6.21663 7.11686 6.9165 7.97949 6.9165H11.6253V15.2498ZM6.04915 8.11117L3.44499 10.7153C3.24154 10.9188 3.24154 11.2485 3.44499 11.4517C3.64844 11.6548 3.97819 11.6551 4.18131 11.4517L5.89616 9.73552V13.6873C5.89616 13.9752 6.1291 14.2082 6.41699 14.2082C6.70488 14.2082 6.93783 13.9752 6.93783 13.6873V9.73552L8.65299 11.4507C8.75423 11.5519 8.8877 11.604 9.02116 11.604C9.15462 11.604 9.28769 11.5532 9.38932 11.4514C9.59277 11.248 9.59277 10.9182 9.38932 10.7151L6.78516 8.11091C6.58301 7.90609 6.25098 7.90609 6.04915 8.11117Z"
                        fill="#FAFAFA"
                      />
                    </svg>
                  </span>
                  {FILTERS.BTN_EXPORT}
                </button>
              </div>
            </main>
          </Collapse>
        </div>
      }
    </>
  );
};

const refreshElem = (refreshHandler) => {
  return (
    <div className="text-end">
      <i className="fa-regular fa-refresh cursor-pointer" onClick={refreshHandler}></i>
    </div>
  );
};

const renderTableHeader = (refreshHandler, isSetOrder) => {
  // const headerKeys = [...TABLE_HEADERS_KEYS, refreshElem(refreshHandler)];
  const headerHomeKeys = [...TABLE_HEADERS_KEYS];
  const headerActiveKeys = [...TABLE_HEADERS_ACTIVE_KEYS];

  const headerKeys = isSetOrder ? headerActiveKeys : headerHomeKeys;

  return (
    <div
      className={`custom-table-row table-header ${
        isSetOrder ? 'table-header-order' : 'table-header-home'
      }`}
    >
      {headerKeys.map((key, i) => {
        if (i === 1 || i === 5) {
          return (
            <div
              className="custom-table-header-cell font-size-14 font-weight-medium"
              key={`header-${i}`}
            >
              <span className="me-2">{key}</span>

              {isSetOrder && <i className="fa-regular fa-arrow-up-arrow-down cursor-pointer"></i>}
            </div>
          );
        } else if (i == 2) {
          return (
            <div
              className="custom-table-header-cell font-size-14 font-weight-medium"
              key={`header-${i}`}
            >
              <span className="me-2">{key}</span>

              {isSetOrder && (
                <i
                  className="fa-regular fa-arrow-down-wide-short cursor-pointer"
                  style={{ color: '#4674B6' }}
                ></i>
              )}
            </div>
          );
        } else {
          return (
            <div
              className="custom-table-header-cell font-size-14 font-weight-medium"
              key={`header-${i}`}
            >
              {key}
            </div>
          );
        }
      })}

      <div className="custom-table-header-cell customized-toggle font-size-14 font-weight-medium d-flex flex-row justify-content-end align-items-center">
        <span className="font-size-14 font-weight-medium me-2">Autoupdate</span>
        <Toggle icons={false} defaultChecked={true} />
      </div>
    </div>
  );
};

const renderHistoryHeader = () => {
  const headerKeys = [...TABLE_HISTORY_KEYS];
  return (
    <div className="custom-table-row table-header-history">
      {headerKeys.map((key, i) => (
        <div
          className="custom-table-header-cell font-size-14 font-weight-medium"
          key={`header-${i}`}
        >
          {key}
        </div>
      ))}
      {/*<div className="custom-table-header-cell customized-toggle font-size-14 font-weight-medium d-flex flex-row justify-content-end align-items-center">*/}
      {/*    <span className="font-size-14 font-weight-medium me-3">Autoupdate</span>*/}
      {/*    <Toggle*/}
      {/*        icons={false}*/}
      {/*        defaultChecked={true}*/}
      {/*    />*/}
      {/*</div>*/}
    </div>
  );
};

const extractOrderData = (data) => {
  const result = {};
  const order = data.fullModel.model.originalModel.data.order;
  result.side = order.side;
  result.trailing = order.trailing;
  const tagDataORDER = data.fullModel.model.tagDataORDER;
  if (tagDataORDER) result.dynamic = tagDataORDER;

  return result;
};

const extractTakeProfitData = (data) => {
  const tp = data.fullModel.model.originalModel.data.takeProfit;
  if (!tp) return null;

  const result = {};
  result.originalTakeProfit = tp;
  const tagDataTAKE_PROFIT = data.fullModel.model.tagDataTAKE_PROFIT;
  if (tagDataTAKE_PROFIT) result.dynamicTP = tagDataTAKE_PROFIT;
  return result;
};

const extractStopLossData = (data) => {
  const sl = data.fullModel.model.originalModel.data.stopLoss;
  if (!sl) return null;
  const result = {};
  result.originalStopLoss = sl;
  const tagDataTAKE_PROFIT = data.fullModel.model.tagDataTAKE_PROFIT;
  if (tagDataTAKE_PROFIT) result.dynamicSL = tagDataTAKE_PROFIT;
  return result;
};

const extractPriceLineData = (data) => {
  const points = [];
  const order = data.fullModel.model.originalModel.data.order;
  if (order.base && order.base.priceBase) {
    points.push({
      price: order.base.priceBase,
      label: `${order.base.priceBase} (start)`,
    });
  }

  const tagDataORDER = data.fullModel.model.tagDataORDER;
  if (tagDataORDER) {
    points.push({
      price: tagDataORDER.price,
      label: `${tagDataORDER.price} ${order.side} (order)`,
    });
  }

  const tagDataTAKE_PROFIT = data.fullModel.model.tagDataTAKE_PROFIT;
  if (tagDataTAKE_PROFIT) {
    points.push({
      price: tagDataTAKE_PROFIT.price,
      label: `${tagDataTAKE_PROFIT.price} TP`,
    });
  }

  const tagDataSTOP_LOSS = data.fullModel.model.tagDataSTOP_LOSS;
  if (tagDataSTOP_LOSS) {
    points.push({
      price: tagDataSTOP_LOSS.price,
      label: `${tagDataSTOP_LOSS.price} SL`,
    });
  }

  let takeProfit = null;

  const side = data.fullModel.model.originalModel.data.order.side;

  if (tagDataORDER) {
    const basePrice = new Big(tagDataORDER.price);
    takeProfit = [];
    let calculatePrice;
    if (side === 'BUY') calculatePrice = (multiplier) => basePrice.mul(multiplier).toString();
    else calculatePrice = (multiplier) => basePrice.div(multiplier).toString();

    for (const sp of data.fullModel.model.originalModel.data.takeProfit) {
      let p = calculatePrice(sp.priceMultiplier);

      points.push({
        price: p,
        label: `${p}`,
        isTP: true,
      });
    }
  }

  return {
    firstOperation: tagDataORDER?.price,
    side,
    points,
    takeProfit,
  };
};

const renderHistory = (data, i) => {
  // const { msgSender, date, msgRecevier, transferData, gasPrice, gasFee, fiatData } = data;
  const {
    pair,
    mainCoin,
    side,
    size,
    orderType,
    orderPrice,
    trigerPrice,
    orderOption,
    creationDate,
  } = data;

  return (
    <div className="history-data-wrapper" key={i}>
      {/* <div className="msg-sender-wrapper">
        <div className="title-data">{date}</div>
        <div className="wallet-data">{msgSender}</div>
      </div>
      <div className="msg-recevier-wrapper">
        <div className="title-data">Receive</div>
        <div className="wallet-data">{msgRecevier}</div>
      </div>
      <div className="transfer-data-wrapper">{transferData}</div>
      <div className="gas-price-wrapper">{gasPrice}</div>
      <div className="gas-fee-wrapper">
        {gasFee}
        <div>{fiatData}</div>
      </div> */}

      <div>
        <Asset asset={pair} />
        <i className="fa-light fa-slash-forward font-size-10 pe-1 ps-1"></i>
        <Asset asset={mainCoin} />
      </div>
      <div>
        <span className={`${side === 'Sell' ? 'sell' : 'buy'}`}>{side}</span>
      </div>
      <div>
        {size.low.value} <Asset asset={pair} ticker={true} />
        {pair}
        <br />
        {size.height.value} <Asset asset={mainCoin} ticker={true} />
        {mainCoin}
        <br />
        {TABLE.LABEL_FILLED}: {size.filled.value}{' '}
        <span className="filled-state">{size.filled.state}</span>
      </div>
      <div>
        {orderType.value}
        <br />
        {orderType.state}
      </div>
      <div>{orderPrice}</div>
      <div>{trigerPrice}</div>
      <div>
        {TABLE.LABEL_TIMEOUT}: {orderOption.timeout}
        <br />
        {TABLE.LABEL_TRAILING}: {orderOption.trailing}
      </div>
      <div>
        {TABLE.LABEL_TIME}: {creationDate.today}
        <br />
        {TABLE.LABEL_ID}: {creationDate.id}
      </div>
    </div>
  );
};

const renderRow = (data, actions, state, smartTradeState) => {
  const {
    mainCoin,
    pair,
    start,
    creationDate,
    volume,
    currentPosition,
    profitLose,
    status,
    pnl: { change, balance },
    botId,
    keyId,
    exchange,
  } = data;

  const onClick = (botId) =>
    actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_COLLAPSED]({
      botId,
    });

  const chevronPosition = (botId) =>
    state.collapsed.includes(botId) ? (
      <i className="fa fa-chevron-up ms-2"></i>
    ) : (
      <i className="fa fa-chevron-down ms-2"></i>
    );

  const botTrades = data.fullModel.model.botTrades
    ? data.fullModel.model.botTrades.map((obj) => ({
        ...obj,
        mainCoin: mainCoin,
        pairCoin: pair,
      }))
    : [];

  function flatData(d) {
    if (!d) return [];

    return Object.keys(d).map((p) => ({ p, v: JSON.stringify(d[p]) }));
  }

  const ParamsAndProfits = ({ data }) => {
    const {
      activeOrders,
      condition,
      gridWidth,
      investment,
      lowerLimitPrice,
      options,
      profits,
      quantityPerGrid,
      upperLimitPrice,
    } = data.fullModel.model.paramsAndProfits;

    return (
      <div className="params-and-profits">
        <div>
          <h3>{GRID_PARAMS_TITLE}</h3>

          <div>
            <h4>{UPPER_LIMIT_PRICE}</h4>
            <p className="upper-price">{upperLimitPrice}</p>
          </div>
          <div>
            <h4>{QUANTITY_PER_GRID}</h4>
            <p>{quantityPerGrid}</p>
          </div>
          <div>
            <h4>{ACRIVE_ORDERS}</h4>
            <p>{activeOrders}</p>
          </div>
        </div>
        <div className="second-col">
          <div>
            <h4>{LOWER_LIMIT_PRICE}</h4>
            <p className="lower-price">{lowerLimitPrice}</p>
          </div>
          <div>
            <h4>{GRID_WIDTH}</h4>
            <p>{gridWidth}</p>
          </div>
          <div>
            <h4>{INVESTMENT}</h4>
            <p>{investment}</p>
          </div>
        </div>
        <div>
          <h3>{DCA_PARAMS_TITLE}</h3>

          <div>
            <p>{OPTIONS}</p>
            <p>{options}</p>
          </div>

          <div>
            <p>{CONDITION}</p>
            <p>{condition}</p>
          </div>
        </div>
        <div>
          <h3>{PROFITS_TITLE}</h3>

          {profits.map((profit, i) => (
            <div key={i}>
              <span>{profit.time}</span>
              <span className="usdt">{profit.value}</span>
            </div>
          ))}

          <button>{BTN_RECENT_TRANSACTION}</button>
        </div>
      </div>
    );
  };

  const isSetOrder = state.isSetOrder;
  const tableView = isSetOrder ? 'colapse-header-order' : 'colapse-header-home';

  const isCollapsed = state.collapsed.includes(botId);
  const collapseView = isCollapsed && 'table-collapsed';

  return (
    <Fragment key={botId}>
      <div
        className={`custom-table-row table-body font-size-14 font-weight-regular font-inter ${collapseView}`}
      >
        <div className={`collapse-header ${tableView}`}>
          {isSetOrder && (
            <div className="custom-table-body-cell d-column-flex align-items-center dropdown-arrow">
              <div className="d-inline-flex align-items-center more">
                <div onClick={() => onClick(botId)}>{chevronPosition(botId)}</div>
              </div>

              <div className="text">
                <i className="fa-regular fa-message-dots" style={{ color: '#00A859' }}></i>
              </div>
            </div>
          )}
          <div className="custom-table-body-cell">
            <div className="pair-row d-flex align-items-center">
              <Asset asset={pair} />
              <i className="fa-light fa-slash-forward font-size-10 pe-1 ps-1"></i>
              <Asset asset={mainCoin} />
            </div>
            <div>{exchange}</div>

            {isSetOrder && <div className="note">You can place a note here</div>}
          </div>
          <div className="custom-table-body-cell">
            {!isSetOrder && start}

            {isSetOrder && creationDate.map((el, i) => <div key={i}>{el}</div>)}
          </div>
          <div className="custom-table-body-cell">
            {!isSetOrder && volume}

            {isSetOrder &&
              currentPosition.map((el, i) =>
                i == 0 ? (
                  <div key={i}>{el}</div>
                ) : (
                  <div key={i}>
                    <Asset asset={i === 1 ? pair : mainCoin} ticker={true} /> {el}
                  </div>
                )
              )}
          </div>
          <div className="custom-table-body-cell">
            {/* <PriceLineTemplate data={extractPriceLineData(data)} /> */}
            <PriceLine />
          </div>
          {!isSetOrder && <div className="custom-table-body-cell">{status}</div>}
          <div className="custom-table-body-cell pnl">
            {!isSetOrder && (
              <div>
                {change} % <br />
                {balance}
              </div>
            )}

            {isSetOrder &&
              profitLose.map((el, i) =>
                i === 1 ? (
                  <div key={i} className="profitLose">
                    <Asset asset={pair} ticker={true} />
                    {el}
                  </div>
                ) : (
                  <div
                    key={i}
                    className="profitLose"
                    style={{ marginLeft: i === 2 || i === 3 ? '25px' : null }}
                  >
                    {el}
                  </div>
                )
              )}
          </div>
          {/* <div className="custom-table-body-cell d-flex justify-content-end"></div> */}
          <div className="d-flex justify-content-end">
            <span className="me-4">{refreshElem()}</span>
            <StandardOptions
              more={{
                ...STANDARD_OPTIONS_SCHEMA,
                actionName: tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_MORE_OPTIONS_CHANGED,
                appendToPayload: { id: botId, action: actions },
              }}
            />
            <CancelTradeModal
              botId={botId}
              show={smartTradeState.modalCancelTrade.show === botId}
            />
            <CloseTradeModal
              keyId={keyId}
              botId={botId}
              state={state}
              show={smartTradeState.modalCloseTrade.show === botId}
            />
            <AddReduceTradeModal
              botId={botId}
              state={state}
              show={smartTradeState.modalAddReduceFunds.show === botId}
            />
          </div>
        </div>
        <div className="collapse-row">
          <Collapse in={state.collapsed.includes(botId)}>
            <div className="collapse-content font-size-14 font-weight-regular">
              <div className="collapse-content-wrapper">
                <ActiveTradesTemplate data={data} />

                {botTrades && <BuySellStepsTemplate data={botTrades} />}

                <ParamsAndProfits data={data} />
                {/* <div className="collapse-data">

                {/*{flatData(extractOrderData(data)).map(e=>*/}
                {/*    // eslint-disable-next-line react/jsx-key*/}
                {/*    <div className="d-flex align-items-center pair-row">{e.p}: {e.v}</div>    */}
                {/*)}*/}

                {/*{flatData(extractTakeProfitData(data)).map(e=>*/}
                {/*    // eslint-disable-next-line react/jsx-key*/}
                {/*    <div className="d-flex align-items-center pair-row">{e.p}: {e.v}</div>    */}
                {/*)}*/}

                {/*{flatData(extractStopLossData(data)).map(e=>*/}
                {/*    // eslint-disable-next-line react/jsx-key*/}
                {/*    <div className="d-flex align-items-center pair-row">{e.p}: {e.v}</div>    */}
                {/*)}*/}

                {/* <div className="d-flex align-items-center pair-row">{TRIGGER}: <span className="pe-1 ps-1"></span><Asset asset={pair} /></div>
                <div className="d-flex align-items-center pair-row">{STOP_LOSS}: <span className="pe-1 ps-1">0</span><Asset asset={pair} /></div>
                <div className="d-flex align-items-center pair-row">{INITIAL_POSITION}: <span className="pe-1 ps-1">23.45</span><Asset asset={pair} />
                <i className="fa-light fa-arrow-right font-size-10 pe-1 ps-2"></i> <span className="pe-1 ps-1">52.2365</span><Asset asset={mainCoin} /></div>
                <div></div> 
                <div className="d-flex align-items-center pair-row">{TTP}:<span className="pe-1 ps-1">2.33</span><Asset asset={pair} /></div>
                <div></div>
                <div className="d-flex align-items-center">{STEPS_LABEL}:</div>
                <div></div>
                <div></div>
                {data.fullModel.model.botTrades && data.fullModel.model.botTrades.map(e=>
                   <div key={e.trade.trade.tradeId}>
                       {e.trade.side}: {JSON.stringify(e.trade.trade)}
                   </div>
                )} */}

                {/* </div>
                <div className="sell-steps align-items-center">
                   <div className="step-item">
                       <div>{STEPS_PRICE}</div>
                       <div className="pair-row"><span className="pe-1 ps-1">2.33</span><Asset asset={pair} /></div>
                   </div>
                   <div className="step-item"><div>{STEPS_VOLUME}</div>
                       <div className="pair-row"><Asset asset={pair} />
                           <i className="fa-light fa-slash-forward font-size-10 pe-1 ps-1"></i>
                           <Asset asset={mainCoin} />
                       </div>
                   </div>
                   <div className="step-item"><div>{STEPS_STATUS}</div>
                       <div>{status}</div></div>
                   <div className="step-item"><div>{STEPS_ACTIONS}</div></div>
                </div>
                <div className="step-item"><div>{STEPS_STATUS}</div>
                   <div>{status}</div></div> */}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      {/* <div>
            {JSON.stringify(data.fullModel)}
        </div> */}
    </Fragment>
  );
};

const PriceLine = () => {
  return (
    <div className="price-line">
      {/* <div className="rectangle">
        <div className="gray-rectangle"></div>
        <div className="red-rectangle"></div>
      </div> */}
      {/* <div className="lose">
        <div className="vertical-line"></div>
        <div>- 2% 2.66730000</div>
      </div> */}
      {/* <div className="buy">
        <div className="vertical-line"></div>
        <div><span>Buy</span> 2.77930000</div>
      </div>
      <div className="tp">
        <span className="tp">TP</span>
        <div className="vertical-line"></div>
        4.77930000
      </div> */}
      Price Line
    </div>
  );
};

const paginateHistory = () => {
  return (
    <div className="paginate-history">
      <span className="arrow">
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.96492 12.8318C7.19449 13.0894 7.17433 13.4838 6.91851 13.7154C6.66218 13.9461 6.26656 13.9254 6.03609 13.669L0.410225 7.41775C0.196592 7.17849 0.196592 6.8197 0.410225 6.58025L6.03523 0.3654C6.26593 0.109071 6.6614 0.08829 6.91765 0.318993C7.18789 0.563955 7.18261 0.959229 6.96406 1.20259L1.71608 6.99978L6.96492 12.8318Z"
            fill="#C4C4C4"
          />
        </svg>
      </span>
      <span className="page selected">1</span>
      <span className="page">2</span>
      <span className="page">3</span>
      <span className="arrow">
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.58964 7.41795L1.96425 13.6679C1.73354 13.9243 1.33807 13.9451 1.08182 13.7144C0.81159 13.4694 0.816863 13.0741 1.03542 12.8308L6.28496 6.99998L1.03534 1.16796C0.805652 0.910145 0.825965 0.517177 1.08143 0.285146C1.33807 0.0546775 1.73339 0.0753807 1.96425 0.33163L7.58925 6.54647C7.80449 6.8203 7.80449 7.17967 7.58964 7.41795Z"
            fill="#2B1A3D"
          />
        </svg>
      </span>
    </div>
  );
};

const renderTableBody = (data, actions, state, smartTradeState) =>
  data.map((d, i) => renderRow(d, actions, state, smartTradeState, i));
const renderHistoryBody = (data) => {
  const rows = data.map((value, i) => renderHistory(value, i));
  return (
    <>
      {rows}
      {paginateHistory()}
    </>
  );
};
// const renderHistoryBody = (data) => data.map((value, i) => renderHistory(value, i));

export const TableTemplate = () => {
  const actions = SmartTradeState.useStoreActions((actions) => actions);
  const state = SmartTradeState.useStoreState((state) => state.tableData);
  const smartTradeState = useStoreState((state) => state.smartTrade);
  const historyState = SmartTradeState.useStoreState((state) => state.historyData);
  const botData = useStoreState((state) => state.botsWidget.originalData);
  const rootActions = useStoreActions((actions) => actions);
  const theme = useStoreState((state) => state.root.theme);

  const [isCollapseFilterHistory, setCollapseFilterHistory] = useState(false);

  const clickAction =
    actions[
      smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CLICKED
    ];
  const onClickHandler = (e) => clickAction({ name: e.target.name, target: e.target, state });
  const refreshHandler = () =>
    rootActions[refreshEffects.actionTypes.REFRESH_DATA_INVOKED](options);
  const isLoading = state.loader;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  useEffect(() => {
    actions[
      smartTradeBotSettingsEffects.actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED
    ]({ name: 'table_data_modify', botData });
  }, [botData, state.viewType]);

  useEffect(() => {
    if (!isMobile) {
      actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_CHAIN_TOGGLED]();
      window.ethereum.on('chainChanged', (chainId) => {
        actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_CHAIN_TOGGLED](chainId);
      });
    }
  }, []);

  return (
    <Fragment>
      {OpenOrderTemplate(state, onClickHandler, actions)}
      {renderControlsContent(state, onClickHandler)}
      {!state.viewType.active &&
        historyFiltersTemplate(isCollapseFilterHistory, setCollapseFilterHistory, theme)}
      <div className="table-content">
        <div
          className={`custom-table ${
            theme === 'day-theme' ? 'custom-day-table' : 'custom-night-table'
          } h-100`}
        >
          {state.viewType.active
            ? renderTableHeader(refreshHandler, state.isSetOrder)
            : renderHistoryHeader()}
          {/*{state.viewType.active ? renderTableHeader(refreshHandler) : renderHistoryHeader()}*/}
          {isLoading ? (
            <Loader size={'6x'} />
          ) : state.viewType.active ? (
            !isEmpty(state.rowsData) ? (
              // renderTableBody(state.rowsData, actions, state, smartTradeState)
              renderTableBody(tradeMockData.activeTradeData, actions, state, smartTradeState)
            ) : (
              <EmptySearchState />
            )
          ) : !isEmpty(historyState) ? (
            // renderHistoryBody(historyState)
            renderHistoryBody(tradeMockData.historyTradeData)
          ) : (
            <EmptySearchState />
          )}
        </div>
      </div>
    </Fragment>
  );
};
