import React, { useEffect, useRef } from 'react';
import { templates } from './cardTemplate';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
// import { constants } from '../../../constants';

// const { INVEST_CONTENT: { STATISTICS: { CONTENT } } } = constants;

export const ImagesGenerator = (props) => {
  const { member } = props;
  const tableRef = useRef();
  const ref = useRef();
  useEffect(() => {
    drawHelper.drawTableContent(d3.select(tableRef.current), member);
  });
  return (
    <div ref={ref} className="images-background-grid">
      <div ref={ref}>
        <svg viewBox="0 0 515 187" ref={tableRef}>
          <svg viewBox="0 0 599 187" fill="none" xmlns="http://www.w3.org/2000/svg">
            {templates.contentTemplate()}
          </svg>
          <svg viewBox="-207 100 582 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            {templates.positionTemplate()}
          </svg>
        </svg>
      </div>
    </div>
  );
};
