import React from 'react';
import { MetaAuth } from '../../components/metaMaskAthorization/view';
import { ButtonCustom } from '../../components/utility/custom-buttons/view';
import { WalletConnectAuth } from '../../components/walletConnectAuthorization/view';
import {BinanceAuth} from '../../components/binanceConnectAuthorization/view'

export const constants = {
    HEADER: 'CONNECT WALLET',
    WALLETS: [
        { icon: 'metamask-fox.svg', label: 'Metamask', wallet: 'Metamask', action: <MetaAuth/> },
        { icon: 'wallet-connect.svg', wallet: 'WalletConnect', action: <WalletConnectAuth/> },
        { icon: 'binance.svg', label: 'Binance', wallet: 'Binance' , action: <BinanceAuth /> }
    ],
    TERM_TEXT: {
        CHECKBOX_TEXT_ONE_PART: 'By clicking connect button, you agree to our',
        CHECKBOX_TEXT_SECOND_PART: 'Terms of use'
    }
};