import { createContextStore } from 'easy-peasy';
import { constants } from '../pages/deFiLiquidatorPage/constants';
import { liquidatorEffects } from '../pages/deFiLiquidatorPage/actions/liquidatorEffects';
import { uiActions } from '../pages/deFiLiquidatorPage/actions/uiActions';

const { DROPDOWN_LIST } = constants;

const DEFI_LIQUIDATOR_MODEL = {
    selector: {
        value: DROPDOWN_LIST[0],
        list: DROPDOWN_LIST,
    },
    search: {
        value: '',
    },
    config: {},
    tab: 'wallet',
    pickedAccount: null,
    assetsToRepay: null,
    assetsToSeize: null,
    repayAmount: 0,
    seizeToken: null,
    protocols: [
        {
            checked: 0,
            indeterminate: false,
            label: 'BSC',
            icon: 'bsc',
            protocols: [
                { label: 'Acryptos', icon: 'acryptos', picked: false },
                { label: 'ApeRocket', icon: 'aperocket', picked: false },
                { label: 'ApeSwap', icon: 'apeswap', picked: false },
                { label: 'Abracadabra', icon: 'abracadabra', picked: false },
                { label: 'DODO', icon: 'dodo', picked: false },
                { label: 'Definix', icon: 'definix', picked: false },
                { label: 'Ankr', icon: 'ankr', picked: false }
            ]
        },
        {
            checked: 0,
            indeterminate: false,
            label: 'ETH',
            icon: 'eth',
            protocols: [
                { label: '1Inch', icon: '1inch', picked: false },
                { label: 'Abracadabra', icon: 'abracadabra', picked: false },
                { label: 'Armor.fi', icon: 'armor', picked: false },
                { label: 'Illuvium', icon: 'illuvium', picked: false },
            ]
        },
        {
            checked: 0,
            indeterminate: false,
            label: 'Terra 2.0',
            icon: 'terra',
            protocols: [
                { label: 'Astroport', icon: 'Astroport', picked: false },
                { label: 'Luna Staking', icon: 'luna-staking', picked: false },
                { label: 'Terraswap', icon: 'terraswap', picked: false },
            ]
        },
    ],
    tableData: {
        pagination: {
            hasMore: true,
            page: 0,
            pageSize: 50
        },
        data: [],
        filteredData: [],
    },
    requestBody: null,
    searchRequest: null,
    address: '',
    loader: false,
    typeOfFetch: 'set',
    ...liquidatorEffects.actionHandlers,
    ...uiActions.actionHandlers,
};

export const DeFiLiquidatorState = createContextStore(DEFI_LIQUIDATOR_MODEL);
