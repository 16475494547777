/* eslint-disable max-len */
import React from 'react';

export const IMAGE_TOTALS = <g><circle cx="315" cy="360" r="279" fill="url(#paint_mobile0_linear_1399_81994)"/>
    <path d="M315.646 637.858C469.418 637.858 594.075 513.201 594.075 359.429C594.075 205.657 469.418 81 315.646 81C161.874 81 37.2173 205.657 37.2173 359.429C37.2173 513.201 161.874 637.858 315.646 637.858Z" fill="url(#paint_mobile1_linear_1399_81994)" fillOpacity="0.8"/>
    <path d="M314.429 637.858C468.201 637.858 592.858 513.201 592.858 359.429C592.858 205.657 468.201 81 314.429 81C160.657 81 36 205.657 36 359.429C36 513.201 160.657 637.858 314.429 637.858Z" fill="url(#paint_mobile2_linear_1399_81994)"/>
    <path d="M46.542 396.618H63.4621C63.795 399.117 64.171 401.608 64.5901 404.091C67.923 423.933 73.8487 443.251 82.2151 461.549C90.6597 480.007 101.3 497.38 113.905 513.29C125.562 528.112 138.785 541.633 153.344 553.616C167.75 565.384 183.497 575.408 200.256 583.479C219.389 592.743 239.585 599.627 260.393 603.977V611.891C260.393 611.891 91.1334 585.124 56.4061 405.09L46.542 405.031V396.618Z" fill="#7E1CFF"/>
    <path d="M573.408 396.618H556.494C556.165 399.117 555.791 401.608 555.372 404.091C552.038 423.933 546.112 443.251 537.747 461.549C529.304 480.007 518.666 497.38 506.062 513.29C494.4 528.113 481.171 541.634 466.606 553.616C452.2 565.384 436.453 575.408 419.694 583.479C400.544 592.747 380.33 599.631 359.504 603.977V611.891C359.504 611.891 528.764 585.124 563.485 405.09L573.408 405.008V396.618Z" fill="#7E1CFF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M263.082 108.413V91H265.082V110.143L264.225 110.267C261.771 110.621 259.322 111.025 256.879 111.477C237.23 115.096 218.19 121.477 200.328 130.428C182.002 139.618 164.901 151.068 149.423 164.511L149.42 164.514C134.698 177.194 121.385 191.423 109.712 206.955C98.029 222.61 88.1633 239.542 80.3047 257.424L80.3038 257.427C71.1268 278.186 64.3511 299.925 60.1086 322.222L59.9539 323.035H42.259V321.035H58.2997C62.58 298.873 69.3475 277.264 78.4746 256.618C86.3942 238.597 96.3364 221.534 108.11 205.758L108.112 205.755C119.871 190.109 133.282 175.774 148.112 163C163.716 149.448 180.957 137.905 199.432 128.64C217.463 119.604 236.681 113.164 256.516 109.51C258.7 109.106 260.889 108.74 263.082 108.413Z" fill="url(#paint_mobile3_linear_1399_81994)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M354.568 91H356.568V108.413C358.761 108.74 360.95 109.106 363.134 109.51C382.961 113.166 402.171 119.606 420.195 128.64C438.683 137.903 455.938 149.446 471.555 163C486.385 175.774 499.796 190.108 511.556 205.755L511.558 205.758C523.33 221.536 533.272 238.599 541.194 256.62C550.321 277.265 557.088 298.874 561.368 321.035H577.391V323.035H559.714L559.559 322.222C555.317 299.925 548.541 278.186 539.364 257.427L539.363 257.425C531.502 239.543 521.637 222.612 509.956 206.956C498.283 191.424 484.97 177.194 470.248 164.514L470.245 164.511C454.753 151.067 437.638 139.617 419.299 130.428C401.444 121.479 382.413 115.099 362.771 111.477C360.329 111.025 357.879 110.621 355.425 110.267L354.568 110.143V91Z" fill="url(#paint_mobile4_linear_1399_81994)"/>
    <path d="M216.835 188.931V177.122H405.582V188.931H364.715V180.9H257.232V188.931H216.835Z" fill="url(#paint_mobile5_linear_1399_81994)"/>
    <path d="M404.789 530.892V542.701H310.415H216.042V530.892H256.909V538.923H364.392V530.892H404.789Z" fill="url(#paint_mobile6_linear_1399_81994)"/>
    <g opacity="0.65">
        <path fillRule="evenodd" clipRule="evenodd" d="M168.31 224.318H166.715V221.318H168.31V224.318ZM173.096 224.318H171.501V221.318H173.096V224.318Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M163.798 228.565H162.203V225.565H163.798V228.565ZM168.584 228.565H166.989V225.565H168.584V228.565Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M160.256 232.819H158.661V229.819H160.256V232.819ZM165.041 232.819H163.446V229.819H165.041V232.819Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M156.372 237.072H154.777V234.072H156.372V237.072ZM161.157 237.072H159.562V234.072H161.157V237.072Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M152.817 241.326H151.223V238.326H152.817V241.326ZM157.597 241.326H156.004V238.326H157.597V241.326Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M149.557 245.574H147.962V242.574H149.557V245.574ZM154.343 245.574H152.747V242.574H154.343V245.574Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M146.643 249.827H145.048V246.827H146.643V249.827ZM151.429 249.827H149.833V246.827H151.429V249.827Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M143.389 254.709H141.794V251.709H143.389V254.709ZM148.174 254.709H146.579V251.709H148.174V254.709Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M140.234 259.585H138.639V256.585H140.234V259.585ZM145.019 259.585H143.424V256.585H145.019V259.585Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M137.607 264.468H136.012V261.468H137.607V264.468ZM142.393 264.468H140.798V261.468H142.393V264.468Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M134.692 268.721H133.098V265.721H134.692V268.721ZM139.473 268.721H137.879V265.721H139.473V268.721Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M132.062 273.597H130.466V270.597H132.062V273.597ZM136.847 273.597H135.252V270.597H136.847V273.597Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M130.262 279.079H128.669V276.079H130.262V279.079ZM135.043 279.079H133.449V276.079H135.043V279.079Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M128.313 284.554H126.718V281.554H128.313V284.554ZM133.098 284.554H131.503V281.554H133.098V284.554Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M125.944 290.03H124.35V287.03H125.944V290.03ZM130.725 290.03H129.131V287.03H130.725V290.03Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M123.641 295.511H122.048V292.511H123.641V295.511ZM128.422 295.511H126.828V292.511H128.422V295.511Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M121.933 299.97H120.338V296.97H121.933V299.97ZM126.718 299.97H125.123V296.97H126.718V299.97Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M120.452 305.64H118.857V302.64H120.452V305.64ZM125.238 305.64H123.642V302.64H125.238V305.64Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M118.747 311.309H117.154V308.309H118.747V311.309ZM123.528 311.309H121.934V308.309H123.528V311.309Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M117.262 316.268H115.667V313.268H117.262V316.268ZM122.048 316.268H120.453V313.268H122.048V316.268Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M116.493 321.678H114.898V318.678H116.493V321.678ZM121.278 321.678H119.683V318.678H121.278V321.678Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.328 327.089H113.734V324.089H115.328V327.089ZM120.109 327.089H118.515V324.089H120.109V327.089Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M114.072 332.571H112.477V329.571H114.072V332.571ZM118.857 332.571H117.262V329.571H118.857V332.571Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.307 338.416H111.713V335.416H113.307V338.416ZM118.088 338.416H116.494V335.416H118.088V338.416Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M112.678 344.262H111.085V341.262H112.678V344.262ZM117.459 344.262H115.865V341.262H117.459V344.262Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M112.139 349.403H110.544V346.403H112.139V349.403ZM116.924 349.403H115.329V346.403H116.924V349.403Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M112.409 354.937H110.814V351.937H112.409V354.937ZM117.195 354.937H115.6V351.937H117.195V354.937Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M111.869 366.052H110.274V363.052H111.869V366.052ZM116.654 366.052H115.059V363.052H116.654V366.052Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M112.139 371.193H110.544V368.193H112.139V371.193ZM116.924 371.193H115.329V368.193H116.924V371.193Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M112.678 377.573H111.085V374.573H112.678V377.573ZM117.459 377.573H115.865V374.573H117.459V377.573Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.307 383.061H111.713V380.061H113.307V383.061ZM118.088 383.061H116.494V380.061H118.088V383.061Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M114.072 388.73H112.477V385.73H114.072V388.73ZM118.857 388.73H117.262V385.73H118.857V388.73Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.059 394.399H113.464V391.399H115.059V394.399ZM119.845 394.399H118.249V391.399H119.845V394.399Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.868 399.446H114.275V396.446H115.868V399.446ZM120.649 399.446H119.056V396.446H120.649V399.446Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M118.248 410.268H116.654V407.268H118.248V410.268ZM123.029 410.268H121.435V407.268H123.029V410.268Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M119.683 415.679H118.088V412.679H119.683V415.679ZM124.468 415.679H122.873V412.679H124.468V415.679Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M120.452 421.09H118.857V418.09H120.452V421.09ZM125.238 421.09H123.642V418.09H125.238V421.09Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M122.873 426.501H121.278V423.501H122.873V426.501ZM127.658 426.501H126.063V423.501H127.658V426.501Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M124.465 431.911H122.87V428.911H124.465V431.911ZM129.25 431.911H127.655V428.911H129.25V431.911Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M126.831 436.57H125.238V433.57H126.831V436.57ZM131.612 436.57H130.018V433.57H131.612V436.57Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M129.134 441.858H127.541V438.858H129.134V441.858ZM133.915 441.858H132.321V438.858H133.915V441.858Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M131.257 447.14H129.661V444.14H131.257V447.14ZM136.042 447.14H134.447V444.14H136.042V447.14Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M134.447 451.804H132.852V448.804H134.447V451.804ZM139.232 451.804H137.637V448.804H139.232V451.804Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M136.573 456.475H134.978V453.475H136.573V456.475ZM141.359 456.475H139.764V453.475H141.359V456.475Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M139.764 461.14H138.169V458.14H139.764V461.14ZM144.549 461.14H142.954V458.14H144.549V461.14Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M142.325 466.509H140.73V463.509H142.325V466.509ZM147.11 466.509H145.515V463.509H147.11V466.509Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M145.257 471.121H143.662V468.121H145.257V471.121ZM150.042 471.121H148.447V468.121H150.042V471.121Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M148.445 475.727H146.852V472.727H148.445V475.727ZM153.226 475.727H151.633V472.727H153.226V475.727Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M151.866 480.333H150.271V477.333H151.866V480.333ZM156.651 480.333H155.056V477.333H156.651V480.333Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M155.056 484.939H153.461V481.939H155.056V484.939ZM159.841 484.939H158.246V481.939H159.841V484.939Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M159.134 488.835H157.539V485.835H159.134V488.835ZM163.919 488.835H162.324V485.835H163.919V488.835Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M162.322 492.736H160.729V489.736H162.322V492.736ZM167.103 492.736H165.509V489.736H167.103V492.736Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M166.282 497.518H164.688V494.518H166.282V497.518ZM171.063 497.518H169.469V494.518H171.063V497.518Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M169.468 501.237H167.873V498.237H169.468V501.237ZM174.253 501.237H172.658V498.237H174.253V501.237Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M117.262 404.857H115.667V401.857H117.262V404.857ZM122.048 404.857H120.453V401.857H122.048V404.857Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M111.869 360.078H110.274V357.078H111.869V360.078ZM116.654 360.078H115.059V357.078H116.654V360.078Z" fill="#8BB8FF"/>
    </g>
    <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M173.918 174.527H171.315V171.527H173.918V174.527ZM181.726 174.527H179.123V171.527H181.726V174.527Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M166.552 180.285H163.948V177.285H166.552V180.285ZM174.364 180.285H171.76V177.285H174.364V180.285Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M160.77 186.042H158.167V183.042H160.77V186.042ZM168.578 186.042H165.975V183.042H168.578V186.042Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M154.431 191.806H151.828V188.806H154.431V191.806ZM162.238 191.806H159.636V188.806H162.238V191.806Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M148.62 197.563H146.018V194.563H148.62V197.563ZM156.428 197.563H153.825V194.563H156.428V197.563Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M143.305 203.321H140.701V200.321H143.305V203.321ZM151.117 203.321H148.513V200.321H151.117V203.321Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M138.545 209.078H135.942V206.078H138.545V209.078ZM146.353 209.078H143.75V206.078H146.353V209.078Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M133.229 215.687H130.625V212.687H133.229V215.687ZM141.041 215.687H138.437V212.687H141.041V215.687Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M128.083 222.297H125.479V219.297H128.083V222.297ZM135.895 222.297H133.291V219.297H135.895V222.297Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M123.792 228.906H121.19V225.906H123.792V228.906ZM131.6 228.906H128.998V225.906H131.6V228.906Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M119.034 234.664H116.431V231.664H119.034V234.664ZM126.842 234.664H124.239V231.664H126.842V234.664Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M114.74 241.267H112.136V238.267H114.74V241.267ZM122.553 241.267H119.949V238.267H122.553V241.267Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M111.801 248.687H109.199V245.687H111.801V248.687ZM119.609 248.687H117.007V245.687H119.609V248.687Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M108.619 256.108H106.015V253.108H108.619V256.108ZM116.431 256.108H113.827V253.108H116.431V256.108Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M104.751 263.522H102.149V260.522H104.751V263.522ZM112.559 263.522H109.957V260.522H112.559V263.522Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M100.991 270.942H98.3886V267.942H100.991V270.942ZM108.799 270.942H106.197V267.942H108.799V270.942Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.2067 276.982H95.604V273.982H98.2067V276.982ZM106.015 276.982H103.412V273.982H106.015V276.982Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M95.7861 284.66H93.1834V281.66H95.7861V284.66ZM103.594 284.66H100.991V281.66H103.594V284.66Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M93.0013 292.339H90.3987V289.339H93.0013V292.339ZM100.809 292.339H98.2066V289.339H100.809V292.339Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M90.5765 299.054H87.9724V296.054H90.5765V299.054ZM98.3888 299.054H95.7847V296.054H98.3888V299.054Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M89.3237 306.38H86.721V303.38H89.3237V306.38ZM97.1316 306.38H94.5289V303.38H97.1316V306.38Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M87.4201 313.712H84.8175V310.712H87.4201V313.712ZM95.228 313.712H92.6254V310.712H95.228V313.712Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.3712 321.126H82.7671V318.126H85.3712V321.126ZM93.1835 321.126H90.5794V318.126H93.1835V321.126Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M84.1183 329.046H81.5157V326.046H84.1183V329.046ZM91.9262 329.046H89.3236V326.046H91.9262V329.046Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.0917 336.966H80.4876V333.966H83.0917V336.966ZM90.904 336.966H88.2999V333.966H90.904V336.966Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.2148 343.922H79.6122V340.922H82.2148V343.922ZM90.0227 343.922H87.4201V340.922H90.0227V343.922Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.6555 351.424H80.0528V348.424H82.6555V351.424ZM90.4634 351.424H87.8607V348.424H90.4634V351.424Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M81.7743 366.476H79.1716V363.476H81.7743V366.476ZM89.5822 366.476H86.9795V363.476H89.5822V366.476Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M82.2148 373.432H79.6122V370.432H82.2148V373.432ZM90.0227 373.432H87.4201V370.432H90.0227V373.432Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.0917 382.074H80.4876V379.074H83.0917V382.074ZM90.904 382.074H88.2999V379.074H90.904V382.074Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M84.1183 389.512H81.5157V386.512H84.1183V389.512ZM91.9262 389.512H89.3236V386.512H91.9262V389.512Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M85.3712 397.19H82.7671V394.19H85.3712V397.19ZM93.1835 397.19H90.5794V394.19H93.1835V397.19Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M86.9795 404.869H84.3768V401.869H86.9795V404.869ZM94.7874 404.869H92.1847V401.869H94.7874V404.869Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M88.3013 411.696H85.6987V408.696H88.3013V411.696ZM96.1092 411.696H93.5066V408.696H96.1092V411.696Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M92.1861 426.354H89.582V423.354H92.1861V426.354ZM99.9984 426.354H97.3943V423.354H99.9984V426.354Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M94.5303 433.68H91.9262V430.68H94.5303V433.68ZM102.343 433.68H99.7385V430.68H102.343V433.68Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M95.7861 441.006H93.1834V438.006H95.7861V441.006ZM103.594 441.006H100.991V438.006H103.594V441.006Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M99.7357 448.338H97.1316V445.338H99.7357V448.338ZM107.548 448.338H104.944V445.338H107.548V448.338Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M102.338 455.664H99.7341V452.664H102.338V455.664ZM110.151 455.664H107.546V452.664H110.151V455.664Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M106.197 461.974H103.594V458.974H106.197V461.974ZM114.005 461.974H111.402V458.974H114.005V461.974Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M109.957 469.13H107.354V466.13H109.957V469.13ZM117.765 469.13H115.162V466.13H117.765V469.13Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.429 476.286H110.826V473.286H113.429V476.286ZM121.237 476.286H118.634V473.286H121.237V476.286Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M118.634 482.607H116.031V479.607H118.634V482.607ZM126.442 482.607H123.839V479.607H126.442V482.607Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M122.106 488.923H119.504V485.923H122.106V488.923ZM129.914 488.923H127.312V485.923H129.914V488.923Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M127.311 495.244H124.709V492.244H127.311V495.244ZM135.119 495.244H132.517V492.244H135.119V495.244Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M131.495 502.524H128.892V499.524H131.495V502.524ZM139.302 502.524H136.7V499.524H139.302V502.524Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M136.278 508.763H133.674V505.763H136.278V508.763ZM144.09 508.763H141.486V505.763H144.09V508.763Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M141.484 514.996H138.879V511.996H141.484V514.996ZM149.296 514.996H146.692V511.996H149.296V514.996Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M147.075 521.236H144.473V518.236H147.075V521.236ZM154.883 521.236H152.28V518.236H154.883V521.236Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M152.28 527.475H149.678V524.475H152.28V527.475ZM160.088 527.475H157.486V524.475H160.088V527.475Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M158.932 532.756H156.328V529.756H158.932V532.756ZM166.745 532.756H164.14V529.756H166.745V532.756Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M164.142 538.032H161.539V535.032H164.142V538.032ZM171.95 538.032H169.347V535.032H171.95V538.032Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M170.604 544.512H168.002V541.512H170.604V544.512ZM178.412 544.512H175.81V541.512H178.412V544.512Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M175.81 549.547H173.207V546.547H175.81V549.547ZM183.618 549.547H181.015V546.547H183.618V549.547Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M90.5765 419.022H87.9724V416.022H90.5765V419.022ZM98.3888 419.022H95.7847V416.022H98.3888V419.022Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M81.7743 358.38H79.1716V355.38H81.7743V358.38ZM89.5822 358.38H86.9795V355.38H89.5822V358.38Z" fill="#0068FF"/>
    </g>
    <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M153 98.8784L150.61 98.3035L151.4 95.3451L153.79 95.9201L153 98.8784ZM160.17 100.603L157.78 100.028L158.57 97.0701L160.96 97.645L160.17 100.603Z" fill="url(#paint_mobile7_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M144.547 103.124L142.156 102.549L142.945 99.5909L145.336 100.165L144.547 103.124ZM151.72 104.847L149.329 104.273L150.118 101.314L152.509 101.889L151.72 104.847Z" fill="url(#paint_mobile8_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M137.545 107.84L135.154 107.266L135.943 104.307L138.334 104.882L137.545 107.84ZM144.718 109.564L142.327 108.989L143.116 106.031L145.507 106.605L144.718 109.564Z" fill="url(#paint_mobile9_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M130.036 112.393L127.645 111.819L128.434 108.86L130.825 109.435L130.036 112.393ZM137.21 114.117L134.819 113.542L135.608 110.584L137.999 111.158L137.21 114.117Z" fill="url(#paint_mobile10_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M123.011 117.106L120.62 116.531L121.409 113.573L123.8 114.147L123.011 117.106ZM130.185 118.829L127.794 118.255L128.583 115.296L130.974 115.871L130.185 118.829Z" fill="url(#paint_mobile11_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M116.443 121.963L114.051 121.388L114.84 118.43L117.232 119.004L116.443 121.963ZM123.616 123.686L121.225 123.112L122.014 120.153L124.405 120.728L123.616 123.686Z" fill="url(#paint_mobile12_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M110.382 126.988L107.991 126.414L108.78 123.455L111.171 124.03L110.382 126.988ZM117.556 128.712L115.165 128.137L115.954 125.179L118.345 125.753L117.556 128.712Z" fill="url(#paint_mobile13_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M103.562 132.799L101.173 132.225L101.961 129.266L104.351 129.84L103.562 132.799ZM110.731 134.52L108.341 133.946L109.13 130.987L111.52 131.561L110.731 134.52Z" fill="url(#paint_mobile14_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M96.8975 138.659L94.5063 138.085L95.2953 135.126L97.6865 135.701L96.8975 138.659ZM104.071 140.383L101.68 139.808L102.469 136.85L104.86 137.424L104.071 140.383Z" fill="url(#paint_mobile15_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M91.0188 144.775L88.6276 144.2L89.4166 141.242L91.8078 141.816L91.0188 144.775ZM98.1925 146.498L95.8013 145.924L96.5902 142.965L98.9815 143.54L98.1925 146.498Z" fill="url(#paint_mobile16_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M84.9569 149.8L82.5669 149.225L83.3567 146.267L85.7467 146.842L84.9569 149.8ZM92.1269 151.525L89.7369 150.95L90.5267 147.992L92.9167 148.567L92.1269 151.525Z" fill="url(#paint_mobile17_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M79.0802 155.921L76.6902 155.346L77.48 152.388L79.87 152.963L79.0802 155.921ZM86.2502 157.646L83.8602 157.071L84.65 154.113L87.04 154.688L86.2502 157.646Z" fill="url(#paint_mobile18_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M74.2002 163.349L71.809 162.775L72.598 159.816L74.9892 160.391L74.2002 163.349ZM81.3739 165.073L78.9827 164.498L79.7716 161.54L82.1629 162.114L81.3739 165.073Z" fill="url(#paint_mobile19_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M69.1053 170.709L66.7141 170.135L67.5031 167.176L69.8943 167.751L69.1053 170.709ZM76.279 172.433L73.8878 171.858L74.6768 168.9L77.068 169.474L76.279 172.433Z" fill="url(#paint_mobile20_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M63.3746 177.862L60.9834 177.288L61.7723 174.329L64.1636 174.904L63.3746 177.862ZM70.5483 179.586L68.1571 179.011L68.946 176.052L71.3373 176.627L70.5483 179.586Z" fill="url(#paint_mobile21_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.7465 185.045L55.3553 184.47L56.1443 181.512L58.5355 182.086L57.7465 185.045ZM64.9202 186.768L62.529 186.194L63.318 183.235L65.7092 183.809L64.9202 186.768Z" fill="url(#paint_mobile22_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.4201 190.976L51.0288 190.402L51.8178 187.443L54.209 188.018L53.4201 190.976ZM60.5938 192.7L58.2025 192.125L58.9915 189.167L61.3827 189.741L60.5938 192.7Z" fill="url(#paint_mobile23_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.9444 198.857L46.5532 198.282L47.3422 195.324L49.7334 195.898L48.9444 198.857ZM56.1181 200.58L53.7269 200.006L54.5159 197.047L56.9071 197.622L56.1181 200.58Z" fill="url(#paint_mobile24_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.1346 206.622L41.7446 206.047L42.5344 203.088L44.9244 203.663L44.1346 206.622ZM51.3046 208.347L48.9146 207.772L49.7044 204.813L52.0944 205.388L51.3046 208.347Z" fill="url(#paint_mobile25_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M39.9402 213.427L37.5502 212.852L38.34 209.894L40.73 210.469L39.9402 213.427ZM47.1102 215.152L44.7202 214.577L45.51 211.619L47.9 212.194L47.1102 215.152Z" fill="url(#paint_mobile26_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.6389 221.261L34.2476 220.687L35.0366 217.728L37.4278 218.303L36.6389 221.261ZM43.8125 222.985L41.4213 222.41L42.2103 219.452L44.6015 220.026L43.8125 222.985Z" fill="url(#paint_mobile27_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.7419 228.904L30.3519 228.329L31.1417 225.37L33.5317 225.945L32.7419 228.904ZM39.9119 230.629L37.5219 230.054L38.3117 227.095L40.7017 227.67L39.9119 230.629Z" fill="url(#paint_mobile28_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.6846 236.599L26.2946 236.024L27.0844 233.066L29.4744 233.641L28.6846 236.599ZM35.8546 238.324L33.4646 237.749L34.2544 234.791L36.6444 235.366L35.8546 238.324Z" fill="url(#paint_mobile29_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M25.2121 245.1L22.8209 244.525L23.6098 241.567L26.0011 242.141L25.2121 245.1ZM32.3858 246.823L29.9946 246.249L30.7835 243.29L33.1748 243.865L32.3858 246.823Z" fill="url(#paint_mobile30_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9498 253.664L19.5586 253.09L20.3476 250.131L22.7388 250.706L21.9498 253.664ZM29.1235 255.388L26.7323 254.813L27.5212 251.855L29.9125 252.429L29.1235 255.388Z" fill="url(#paint_mobile31_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.0979 261.198L16.7066 260.624L17.4956 257.665L19.8868 258.24L19.0979 261.198ZM26.2715 262.922L23.8803 262.347L24.6693 259.389L27.0605 259.963L26.2715 262.922Z" fill="url(#paint_mobile32_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.303 269.735L14.9118 269.16L15.7008 266.202L18.092 266.776L17.303 269.735ZM24.4767 271.458L22.0855 270.884L22.8745 267.925L25.2657 268.5L24.4767 271.458Z" fill="url(#paint_mobile33_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0829 286.344L9.69163 285.77L10.4806 282.811L12.8718 283.386L12.0829 286.344ZM19.2565 288.068L16.8653 287.493L17.6543 284.535L20.0455 285.109L19.2565 288.068Z" fill="url(#paint_mobile34_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4456 294.271L8.05435 293.696L8.84332 290.738L11.2345 291.312L10.4456 294.271ZM17.6193 295.994L15.228 295.42L16.017 292.461L18.4082 293.036L17.6193 295.994Z" fill="url(#paint_mobile35_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.71943 304.214L6.3282 303.639L7.11717 300.681L9.5084 301.255L8.71943 304.214ZM15.8931 305.937L13.5019 305.363L14.2909 302.404L16.6821 302.979L15.8931 305.937Z" fill="url(#paint_mobile36_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.47796 312.857L5.08674 312.283L5.87571 309.324L8.26693 309.899L7.47796 312.857ZM14.6516 314.581L12.2604 314.006L13.0494 311.048L15.4406 311.622L14.6516 314.581Z" fill="url(#paint_mobile37_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.38056 321.837L3.98933 321.262L4.7783 318.303L7.16953 318.878L6.38056 321.837ZM13.5542 323.56L11.163 322.986L11.952 320.027L14.3432 320.601L13.5542 323.56Z" fill="url(#paint_mobile38_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.6058 330.923L3.2158 330.348L4.0056 327.39L6.3956 327.965L5.6058 330.923ZM12.7758 332.648L10.3858 332.073L11.1756 329.115L13.5656 329.69L12.7758 332.648Z" fill="url(#paint_mobile39_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.81661 338.975L2.42661 338.4L3.21641 335.442L5.60641 336.017L4.81661 338.975ZM11.9866 340.7L9.59661 340.125L10.3864 337.167L12.7764 337.742L11.9866 340.7Z" fill="url(#paint_mobile40_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.08639 356.564L1.69516 355.99L2.48413 353.031L4.87536 353.606L4.08639 356.564ZM11.2601 358.288L8.86885 357.713L9.65782 354.755L12.049 355.329L11.2601 358.288Z" fill="url(#paint_mobile41_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.09065 365.48L1.69942 364.905L2.48839 361.947L4.87961 362.521L4.09065 365.48ZM11.2643 367.203L8.8731 366.629L9.66207 363.67L12.0533 364.245L11.2643 367.203Z" fill="url(#paint_mobile42_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.09167 374.066L0.700442 373.492L1.48941 370.533L3.88064 371.108L3.09167 374.066ZM10.2654 375.79L7.87413 375.215L8.6631 372.257L11.0543 372.831L10.2654 375.79Z" fill="url(#paint_mobile43_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.57553 383.467L2.1843 382.893L2.97328 379.934L5.3645 380.509L4.57553 383.467ZM11.7492 385.191L9.35799 384.616L10.147 381.658L12.5382 382.232L11.7492 385.191Z" fill="url(#paint_mobile44_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.81816 392.461L2.42693 391.886L3.2159 388.928L5.60713 389.502L4.81816 392.461ZM11.9918 394.184L9.60061 393.61L10.3896 390.651L12.7808 391.226L11.9918 394.184Z" fill="url(#paint_mobile45_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.51058 400.687L4.12102 400.113L4.90962 397.155L7.29919 397.729L6.51058 400.687ZM13.6793 402.408L11.2897 401.835L12.0783 398.876L14.4679 399.45L13.6793 402.408Z" fill="url(#paint_mobile46_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.86323 409.837L5.47201 409.262L6.26097 406.304L8.6522 406.878L7.86323 409.837ZM15.0369 411.56L12.6457 410.986L13.4347 408.027L15.8259 408.602L15.0369 411.56Z" fill="url(#paint_mobile47_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.95081 418.899L6.55958 418.324L7.34855 415.366L9.73978 415.94L8.95081 418.899ZM16.1245 420.622L13.7333 420.048L14.5222 417.089L16.9135 417.663L16.1245 420.622Z" fill="url(#paint_mobile48_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.8814 427.54L9.49137 426.965L10.2812 424.007L12.6712 424.582L11.8814 427.54ZM19.0514 429.265L16.6614 428.69L17.4512 425.732L19.8412 426.307L19.0514 429.265Z" fill="url(#paint_mobile49_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.2166 435.666L10.8254 435.092L11.6144 432.133L14.0056 432.708L13.2166 435.666ZM20.3903 437.39L17.9991 436.815L18.788 433.857L21.1793 434.431L20.3903 437.39Z" fill="url(#paint_mobile50_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1437 444.31L13.7525 443.735L14.5414 440.777L16.9327 441.351L16.1437 444.31ZM23.3174 446.033L20.9261 445.459L21.7151 442.5L24.1063 443.075L23.3174 446.033Z" fill="url(#paint_mobile51_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.8515 453.717L15.4615 453.142L16.2513 450.184L18.6413 450.759L17.8515 453.717ZM25.0215 455.442L22.6315 454.867L23.4213 451.909L25.8113 452.484L25.0215 455.442Z" fill="url(#paint_mobile52_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20.4146 462.145L18.0234 461.57L18.8124 458.612L21.2036 459.186L20.4146 462.145ZM27.5883 463.868L25.1971 463.294L25.986 460.335L28.3773 460.91L27.5883 463.868Z" fill="url(#paint_mobile53_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.3699 470.697L20.9799 470.122L21.7697 467.164L24.1597 467.738L23.3699 470.697ZM30.5399 472.422L28.1499 471.847L28.9397 468.888L31.3297 469.463L30.5399 472.422Z" fill="url(#paint_mobile54_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.6727 479.367L24.2815 478.792L25.0705 475.834L27.4617 476.408L26.6727 479.367ZM33.8464 481.09L31.4552 480.516L32.2442 477.557L34.6354 478.132L33.8464 481.09Z" fill="url(#paint_mobile55_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M29.6249 487.921L27.2337 487.346L28.0227 484.387L30.4139 484.962L29.6249 487.921ZM36.7986 489.644L34.4074 489.069L35.1963 486.111L37.5876 486.685L36.7986 489.644Z" fill="url(#paint_mobile56_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.1884 495.829L31.7972 495.255L32.5862 492.296L34.9774 492.871L34.1884 495.829ZM41.3621 497.553L38.9709 496.978L39.7599 494.02L42.1511 494.594L41.3621 497.553Z" fill="url(#paint_mobile57_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M37.4216 503.308L35.0304 502.733L35.8194 499.775L38.2106 500.349L37.4216 503.308ZM44.5953 505.031L42.2041 504.457L42.9931 501.498L45.3843 502.073L44.5953 505.031Z" fill="url(#paint_mobile58_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M41.4578 512.506L39.0678 511.932L39.8576 508.973L42.2476 509.548L41.4578 512.506ZM48.6278 514.231L46.2378 513.656L47.0276 510.698L49.4176 511.273L48.6278 514.231Z" fill="url(#paint_mobile59_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.7618 519.718L42.3706 519.143L43.1596 516.185L45.5508 516.759L44.7618 519.718ZM51.9355 521.441L49.5443 520.867L50.3333 517.908L52.7245 518.483L51.9355 521.441Z" fill="url(#paint_mobile60_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.75943 347.871L2.36943 347.296L3.15923 344.337L5.54923 344.912L4.75943 347.871ZM11.9294 349.596L9.53944 349.021L10.3292 346.062L12.7192 346.637L11.9294 349.596Z" fill="url(#paint_mobile61_linear_1399_81994)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.4576 277.272L12.0664 276.698L12.8554 273.739L15.2466 274.314L14.4576 277.272ZM21.6313 278.996L19.2401 278.421L20.0291 275.463L22.4203 276.037L21.6313 278.996Z" fill="url(#paint_mobile62_linear_1399_81994)"/>
    </g>
    <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M439.687 543.267H437.084V540.267H439.687V543.267ZM447.495 543.267H444.892V540.267H447.495V543.267Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M447.048 537.509H444.445V534.509H447.048V537.509ZM454.856 537.509H452.253V534.509H454.856V537.509Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M452.835 531.752H450.232V528.752H452.835V531.752ZM460.643 531.752H458.04V528.752H460.643V531.752Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M459.174 525.994H456.571V522.994H459.174V525.994ZM466.982 525.994H464.379V522.994H466.982V525.994Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M464.98 520.237H462.376V517.237H464.98V520.237ZM472.792 520.237H470.188V517.237H472.792V520.237Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M470.297 514.479H467.693V511.479H470.297V514.479ZM478.109 514.479H475.505V511.479H478.109V514.479Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M475.056 508.716H472.452V505.716H475.056V508.716ZM482.868 508.716H480.264V505.716H482.868V508.716Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M480.371 502.112H477.769V499.112H480.371V502.112ZM488.179 502.112H485.576V499.112H488.179V502.112Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M485.518 495.503H482.915V492.503H485.518V495.503ZM493.326 495.503H490.723V492.503H493.326V495.503Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M489.808 488.894H487.204V485.894H489.808V488.894ZM497.62 488.894H495.016V485.894H497.62V488.894Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M494.571 483.136H491.968V480.136H494.571V483.136ZM502.379 483.136H499.776V480.136H502.379V483.136Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M498.86 476.527H496.257V473.527H498.86V476.527ZM506.668 476.527H504.065V473.527H506.668V476.527Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M501.799 469.107H499.195V466.107H501.799V469.107ZM509.611 469.107H507.007V466.107H509.611V469.107Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M504.981 461.692H502.379V458.692H504.981V461.692ZM512.789 461.692H510.187V458.692H512.789V461.692Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M508.853 454.272H506.251V451.272H508.853V454.272ZM516.661 454.272H514.059V451.272H516.661V454.272Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M512.613 446.858H510.011V443.858H512.613V446.858ZM520.421 446.858H517.819V443.858H520.421V446.858Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M515.394 440.818H512.789V437.818H515.394V440.818ZM523.206 440.818H520.602V437.818H523.206V440.818Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M517.819 433.14H515.216V430.14H517.819V433.14ZM525.626 433.14H523.024V430.14H525.626V433.14Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M520.603 425.461H518.001V422.461H520.603V425.461ZM528.411 425.461H525.808V422.461H528.411V425.461Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M523.024 418.74H520.421V415.74H523.024V418.74ZM530.832 418.74H528.229V415.74H530.832V418.74Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M524.277 411.414H521.672V408.414H524.277V411.414ZM532.089 411.414H529.485V408.414H532.089V411.414Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M526.18 404.088H523.576V401.088H526.18V404.088ZM533.992 404.088H531.388V401.088H533.992V404.088Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M528.23 396.668H525.626V393.668H528.23V396.668ZM536.043 396.668H533.439V393.668H536.043V396.668Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M529.482 388.748H526.878V385.748H529.482V388.748ZM537.294 388.748H534.69V385.748H537.294V388.748Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M530.508 380.834H527.906V377.834H530.508V380.834ZM538.316 380.834H535.714V377.834H538.316V380.834Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M531.39 373.872H528.787V370.872H531.39V373.872ZM539.198 373.872H536.595V370.872H539.198V373.872Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M530.949 366.376H528.347V363.376H530.949V366.376ZM538.757 366.376H536.154V363.376H538.757V366.376Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M531.826 351.318H529.222V348.318H531.826V351.318ZM539.638 351.318H537.034V348.318H539.638V351.318Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M531.39 344.362H528.787V341.362H531.39V344.362ZM539.198 344.362H536.595V341.362H539.198V344.362Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M530.508 335.726H527.906V332.726H530.508V335.726ZM538.316 335.726H535.714V332.726H538.316V335.726Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M529.482 328.288H526.878V325.288H529.482V328.288ZM537.294 328.288H534.69V325.288H537.294V328.288Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M528.23 320.61H525.626V317.61H528.23V320.61ZM536.043 320.61H533.439V317.61H536.043V320.61Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M526.621 312.931H524.017V309.931H526.621V312.931ZM534.433 312.931H531.829V309.931H534.433V312.931Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M525.303 306.104H522.701V303.104H525.303V306.104ZM533.111 306.104H530.508V303.104H533.111V306.104Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M521.414 291.446H518.811V288.446H521.414V291.446ZM529.222 291.446H526.619V288.446H529.222V291.446Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M519.07 284.12H516.467V281.12H519.07V284.12ZM526.878 284.12H524.275V281.12H526.878V284.12Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M517.819 276.788H515.216V273.788H517.819V276.788ZM525.626 276.788H523.024V273.788H525.626V276.788Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M513.865 269.462H511.262V266.462H513.865V269.462ZM521.673 269.462H519.07V266.462H521.673V269.462Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M511.262 262.135H508.659V259.135H511.262V262.135ZM519.07 262.135H516.467V259.135H519.07V262.135Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M507.403 255.826H504.799V252.826H507.403V255.826ZM515.216 255.826H512.612V252.826H515.216V255.826Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M503.644 248.67H501.039V245.67H503.644V248.67ZM511.456 248.67H508.852V245.67H511.456V248.67Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M500.176 241.508H497.573V238.508H500.176V241.508ZM507.984 241.508H505.381V238.508H507.984V241.508Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M494.966 235.193H492.362V232.193H494.966V235.193ZM502.778 235.193H500.174V232.193H502.778V235.193Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M491.498 228.871H488.896V225.871H491.498V228.871ZM499.306 228.871H496.704V225.871H499.306V228.871Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M486.289 222.555H483.685V219.555H486.289V222.555ZM494.101 222.555H491.497V219.555H494.101V222.555Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M482.106 215.276H479.501V212.276H482.106V215.276ZM489.918 215.276H487.314V212.276H489.918V215.276Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M477.322 209.037H474.719V206.037H477.322V209.037ZM485.13 209.037H482.527V206.037H485.13V209.037Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M472.117 202.798H469.514V199.798H472.117V202.798ZM479.925 202.798H477.322V199.798H479.925V202.798Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M466.53 196.559H463.927V193.559H466.53V196.559ZM474.337 196.559H471.735V193.559H474.337V196.559Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M461.32 190.325H458.716V187.325H461.32V190.325ZM469.132 190.325H466.528V187.325H469.132V190.325Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M454.668 185.043H452.065V182.043H454.668V185.043ZM462.476 185.043H459.873V182.043H462.476V185.043Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M449.463 179.768H446.86V176.768H449.463V179.768ZM457.27 179.768H454.668V176.768H457.27V179.768Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M443 173.288H440.397V170.288H443V173.288ZM450.808 173.288H448.205V170.288H450.808V173.288Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M437.795 168.247H435.192V165.247H437.795V168.247ZM445.603 168.247H443V165.247H445.603V168.247Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M523.024 298.772H520.421V295.772H523.024V298.772ZM530.832 298.772H528.229V295.772H530.832V298.772Z" fill="#0068FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M531.826 359.414H529.222V356.414H531.826V359.414ZM539.638 359.414H537.034V356.414H539.638V359.414Z" fill="#0068FF"/>
    </g>
    <g opacity="0.65">
        <path fillRule="evenodd" clipRule="evenodd" d="M450.277 500.655H448.682V497.655H450.277V500.655ZM455.062 500.655H453.467V497.655H455.062V500.655Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M454.787 496.401H453.194V493.401H454.787V496.401ZM459.568 496.401H457.974V493.401H459.568V496.401Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M458.331 492.148H456.736V489.148H458.331V492.148ZM463.117 492.148H461.522V489.148H463.117V492.148Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M462.215 487.9H460.62V484.9H462.215V487.9ZM467 487.9H465.405V484.9H467V487.9Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M465.774 483.647H464.18V480.647H465.774V483.647ZM470.554 483.647H468.961V480.647H470.554V483.647Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M469.028 479.393H467.435V476.393H469.028V479.393ZM473.809 479.393H472.216V476.393H473.809V479.393Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M471.944 475.14H470.349V472.14H471.944V475.14ZM476.729 475.14H475.134V472.14H476.729V475.14Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M475.199 470.263H473.604V467.263H475.199V470.263ZM479.984 470.263H478.389V467.263H479.984V470.263Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M478.352 465.381H476.759V462.381H478.352V465.381ZM483.133 465.381H481.539V462.381H483.133V465.381Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M480.979 460.505H479.384V457.505H480.979V460.505ZM485.765 460.505H484.169V457.505H485.765V460.505Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M483.894 456.252H482.299V453.252H483.894V456.252ZM488.679 456.252H487.084V453.252H488.679V456.252Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M486.524 451.369H484.93V448.369H486.524V451.369ZM491.305 451.369H489.711V448.369H491.305V451.369Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M488.323 445.894H486.728V442.894H488.323V445.894ZM493.108 445.894H491.513V442.894H493.108V445.894Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M490.274 440.418H488.679V437.418H490.274V440.418ZM495.059 440.418H493.464V437.418H495.059V440.418Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M492.641 434.937H491.046V431.937H492.641V434.937ZM497.427 434.937H495.832V431.937H497.427V434.937Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M494.944 429.462H493.349V426.462H494.944V429.462ZM499.729 429.462H498.134V426.462H499.729V429.462Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M496.653 425.002H495.059V422.002H496.653V425.002ZM501.434 425.002H499.84V422.002H501.434V425.002Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M498.135 419.333H496.54V416.333H498.135V419.333ZM502.92 419.333H501.325V416.333H502.92V419.333Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M499.843 413.664H498.249V410.664H499.843V413.664ZM504.624 413.664H503.03V410.664H504.624V413.664Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M501.325 408.699H499.73V405.699H501.325V408.699ZM506.11 408.699H504.515V405.699H506.11V408.699Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M502.093 403.288H500.499V400.288H502.093V403.288ZM506.874 403.288H505.28V400.288H506.874V403.288Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M503.258 397.877H501.663V394.877H503.258V397.877ZM508.043 397.877H506.448V394.877H508.043V397.877Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M504.515 392.402H502.92V389.402H504.515V392.402ZM509.3 392.402H507.705V389.402H509.3V392.402Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M505.283 386.556H503.689V383.556H505.283V386.556ZM510.064 386.556H508.47V383.556H510.064V386.556Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M505.907 380.711H504.312V377.711H505.907V380.711ZM510.693 380.711H509.097V377.711H510.693V380.711Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M506.448 375.57H504.853V372.57H506.448V375.57ZM511.233 375.57H509.638V372.57H511.233V375.57Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M506.177 370.03H504.582V367.03H506.177V370.03ZM510.963 370.03H509.367V367.03H510.963V370.03Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M506.718 358.914H505.123V355.914H506.718V358.914ZM511.503 358.914H509.908V355.914H511.503V358.914Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M506.448 353.78H504.853V350.78H506.448V353.78ZM511.233 353.78H509.638V350.78H511.233V353.78Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M505.907 347.399H504.312V344.399H505.907V347.399ZM510.693 347.399H509.097V344.399H510.693V347.399Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M505.283 341.906H503.689V338.906H505.283V341.906ZM510.064 341.906H508.47V338.906H510.064V341.906Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M504.515 336.237H502.92V333.237H504.515V336.237ZM509.3 336.237H507.705V333.237H509.3V336.237Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M503.528 330.567H501.933V327.567H503.528V330.567ZM508.313 330.567H506.718V327.567H508.313V330.567Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M502.722 325.526H501.128V322.526H502.722V325.526ZM507.502 325.526H505.909V322.526H507.502V325.526Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M500.338 314.705H498.743V311.705H500.338V314.705ZM505.123 314.705H503.528V311.705H505.123V314.705Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M498.904 309.294H497.309V306.294H498.904V309.294ZM503.689 309.294H502.094V306.294H503.689V309.294Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M498.135 303.883H496.54V300.883H498.135V303.883ZM502.92 303.883H501.325V300.883H502.92V303.883Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M495.714 298.472H494.119V295.472H495.714V298.472ZM500.499 298.472H498.904V295.472H500.499V298.472Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M494.12 293.061H492.527V290.061H494.12V293.061ZM498.901 293.061H497.308V290.061H498.901V293.061Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M491.759 288.402H490.165V285.402H491.759V288.402ZM496.539 288.402H494.946V285.402H496.539V288.402Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M489.456 283.115H487.862V280.115H489.456V283.115ZM494.236 283.115H492.643V280.115H494.236V283.115Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M487.329 277.827H485.735V274.827H487.329V277.827ZM492.11 277.827H490.516V274.827H492.11V277.827Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M484.139 273.162H482.545V270.162H484.139V273.162ZM488.92 273.162H487.326V270.162H488.92V273.162Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M482.014 268.498H480.418V265.498H482.014V268.498ZM486.799 268.498H485.204V265.498H486.799V268.498Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M478.823 263.833H477.228V260.833H478.823V263.833ZM483.609 263.833H482.013V260.833H483.609V263.833Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M476.262 258.457H474.667V255.457H476.262V258.457ZM481.047 258.457H479.452V255.457H481.047V258.457Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M473.33 253.851H471.735V250.851H473.33V253.851ZM478.115 253.851H476.52V250.851H478.115V253.851Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M470.14 249.245H468.545V246.245H470.14V249.245ZM474.925 249.245H473.33V246.245H474.925V249.245Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M466.719 244.639H465.126V241.639H466.719V244.639ZM471.5 244.639H469.907V241.639H471.5V244.639Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M463.529 240.033H461.936V237.033H463.529V240.033ZM468.31 240.033H466.716V237.033H468.31V240.033Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M459.453 236.132H457.858V233.132H459.453V236.132ZM464.239 236.132H462.644V233.132H464.239V236.132Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M456.264 232.237H454.668V229.237H456.264V232.237ZM461.049 232.237H459.454V229.237H461.049V232.237Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M452.308 227.455H450.715V224.455H452.308V227.455ZM457.089 227.455H455.495V224.455H457.089V227.455Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M449.118 223.73H447.524V220.73H449.118V223.73ZM453.899 223.73H452.305V220.73H453.899V223.73Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M501.325 320.116H499.73V317.116H501.325V320.116ZM506.11 320.116H504.515V317.116H506.11V320.116Z" fill="#8BB8FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M506.718 364.895H505.123V361.895H506.718V364.895ZM511.503 364.895H509.908V361.895H511.503V364.895Z" fill="#8BB8FF"/>
    </g>
    <path opacity="0.65" d="M252.085 210.474C252.085 210.474 242.057 215.327 227.492 224.075C141.658 275.669 127.705 396.407 195.468 470.567C210.743 487.317 233.391 504.184 258.518 510.606H219.209C219.209 510.606 160.552 480.055 140.436 405.966C126.618 355.058 136.189 300.568 166.057 257.093C178.436 239.074 195.274 221.578 217.758 210.486L252.085 210.474Z" fill="url(#paint_mobile63_linear_1399_81994)"/>
    <path opacity="0.65" d="M368.433 509.996C368.433 509.996 378.462 505.143 393.026 496.395C478.86 444.801 492.813 324.063 425.051 249.903C409.776 233.153 387.127 216.286 362 209.864L401.31 209.864C401.31 209.864 459.966 240.414 480.082 314.504C493.9 365.411 484.33 419.902 454.461 463.377C442.082 481.396 425.245 498.892 402.761 509.984L368.433 509.996Z" fill="url(#paint_mobile64_linear_1399_81994)"/>
    <path d="M522.736 147.259C536.597 161.031 549.084 176.121 560.019 192.315C570.96 208.522 580.27 225.773 587.813 243.815C614.548 307.642 618.252 378.78 598.29 445.039C578.329 511.297 535.945 568.551 478.401 606.987C420.858 645.424 351.738 662.652 282.886 655.718C214.035 648.784 149.738 618.119 101.015 568.98C114.876 582.734 130.044 595.105 146.305 605.916C195.381 638.463 252.988 655.764 311.875 655.642C331.363 655.585 350.799 653.649 369.915 649.861C408.226 642.262 444.652 627.151 477.093 605.399C509.505 583.623 537.376 555.754 559.155 523.343C580.905 490.9 596.016 454.471 603.617 416.159C607.408 397.044 609.344 377.607 609.398 358.12C609.59 279.127 578.421 203.287 522.736 147.259Z" fill="white"/>
    <path d="M338.865 490.149C338.865 490.149 427.449 479.521 446.231 382.783L434.881 380.656C434.881 380.656 427.096 457.907 336.38 478.81L338.865 490.149Z" fill="url(#paint_mobile65_linear_1399_81994)"/>
    <path d="M177.784 383.499C177.784 383.499 188.418 472.118 285.15 490.871L287.277 479.527C287.277 479.527 210.032 471.736 189.123 381.02L177.784 383.499Z" fill="url(#paint_mobile66_linear_1399_81994)"/>
    <path d="M283.728 227.288C283.728 227.288 195.139 237.916 176.38 334.643L187.719 336.769C187.719 336.769 195.515 259.519 286.231 238.615L283.728 227.288Z" fill="url(#paint_mobile67_linear_1399_81994)"/>
    <path d="M444.804 333.938C444.804 333.938 434.176 245.342 337.438 226.542L335.311 237.881C335.311 237.881 412.556 245.701 433.465 336.405L444.804 333.938Z" fill="url(#paint_mobile68_linear_1399_81994)"/>
    <path d="M486.112 92.8643C486.112 92.8643 546.454 125.535 577.381 179.08L559.432 191.653C559.432 191.653 525.945 144.294 477 113.104L486.112 92.8643Z" fill="url(#paint_mobile69_linear_1399_81994)"/>
    <path d="M147.289 626.629C147.289 626.629 86.5184 594.804 54.8672 541.651L72.6443 528.838C72.6443 528.838 106.767 575.741 156.134 606.269L147.289 626.629Z" fill="url(#paint_mobile70_linear_1399_81994)"/>
    <g opacity="0.65">
        <path d="M271.737 188.572C272.584 188.572 273.271 187.886 273.271 187.039C273.271 186.192 272.584 185.506 271.737 185.506C270.891 185.506 270.204 186.192 270.204 187.039C270.204 187.886 270.891 188.572 271.737 188.572Z" fill="url(#paint_mobile71_linear_1399_81994)"/>
        <path d="M287.853 188.572C288.7 188.572 289.386 187.886 289.386 187.039C289.386 186.192 288.7 185.506 287.853 185.506C287.006 185.506 286.319 186.192 286.319 187.039C286.319 187.886 287.006 188.572 287.853 188.572Z" fill="url(#paint_mobile72_linear_1399_81994)"/>
        <path d="M304.326 188.731C305.173 188.731 305.86 188.044 305.86 187.198C305.86 186.351 305.173 185.664 304.326 185.664C303.479 185.664 302.793 186.351 302.793 187.198C302.793 188.044 303.479 188.731 304.326 188.731Z" fill="url(#paint_mobile73_linear_1399_81994)"/>
        <path d="M320.436 188.731C321.282 188.731 321.969 188.044 321.969 187.198C321.969 186.351 321.282 185.664 320.436 185.664C319.589 185.664 318.902 186.351 318.902 187.198C318.902 188.044 319.589 188.731 320.436 188.731Z" fill="url(#paint_mobile74_linear_1399_81994)"/>
        <path d="M336.915 188.649C337.762 188.649 338.448 187.962 338.448 187.115C338.448 186.269 337.762 185.582 336.915 185.582C336.068 185.582 335.381 186.269 335.381 187.115C335.381 187.962 336.068 188.649 336.915 188.649Z" fill="url(#paint_mobile75_linear_1399_81994)"/>
        <path d="M350.68 188.807C351.527 188.807 352.213 188.121 352.213 187.274C352.213 186.427 351.527 185.741 350.68 185.741C349.833 185.741 349.147 186.427 349.147 187.274C349.147 188.121 349.833 188.807 350.68 188.807Z" fill="url(#paint_mobile76_linear_1399_81994)"/>
    </g>
    <g opacity="0.65">
        <path d="M271.737 533.7C272.584 533.7 273.271 533.014 273.271 532.167C273.271 531.32 272.584 530.633 271.737 530.633C270.891 530.633 270.204 531.32 270.204 532.167C270.204 533.014 270.891 533.7 271.737 533.7Z" fill="url(#paint_mobile77_linear_1399_81994)"/>
        <path d="M287.853 533.7C288.7 533.7 289.386 533.014 289.386 532.167C289.386 531.32 288.7 530.633 287.853 530.633C287.006 530.633 286.319 531.32 286.319 532.167C286.319 533.014 287.006 533.7 287.853 533.7Z" fill="url(#paint_mobile78_linear_1399_81994)"/>
        <path d="M304.326 533.859C305.173 533.859 305.86 533.172 305.86 532.325C305.86 531.478 305.173 530.792 304.326 530.792C303.479 530.792 302.793 531.478 302.793 532.325C302.793 533.172 303.479 533.859 304.326 533.859Z" fill="url(#paint_mobile79_linear_1399_81994)"/>
        <path d="M320.436 533.859C321.282 533.859 321.969 533.172 321.969 532.325C321.969 531.478 321.282 530.792 320.436 530.792C319.589 530.792 318.902 531.478 318.902 532.325C318.902 533.172 319.589 533.859 320.436 533.859Z" fill="url(#paint_mobile80_linear_1399_81994)"/>
        <path d="M336.915 533.782C337.762 533.782 338.448 533.096 338.448 532.249C338.448 531.402 337.762 530.716 336.915 530.716C336.068 530.716 335.381 531.402 335.381 532.249C335.381 533.096 336.068 533.782 336.915 533.782Z" fill="url(#paint_mobile81_linear_1399_81994)"/>
        <path d="M350.68 533.941C351.527 533.941 352.213 533.254 352.213 532.408C352.213 531.561 351.527 530.874 350.68 530.874C349.833 530.874 349.147 531.561 349.147 532.408C349.147 533.254 349.833 533.941 350.68 533.941Z" fill="url(#paint_mobile82_linear_1399_81994)"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M310.875 585.166L319.887 598.383V635.658H303V597.872L310.875 585.166ZM310.955 588.834L305 598.441V633.658H317.887V599L310.955 588.834Z" fill="#009DFF"/>
    <path d="M282.277 266.041L277.57 279H275.646L281.066 264.781H282.307L282.277 266.041ZM286.223 279L281.506 266.041L281.477 264.781H282.717L288.156 279H286.223ZM285.979 273.736V275.279H277.99V273.736H285.979Z" fill="white"/>
    <path d="M292.512 277.369L295.402 268.434H297.248L293.449 279H292.238L292.512 277.369ZM290.1 268.434L293.078 277.418L293.283 279H292.072L288.244 268.434H290.1Z" fill="white"/>
    <path d="M305.061 277.193V271.754C305.061 271.337 304.976 270.976 304.807 270.67C304.644 270.357 304.396 270.117 304.064 269.947C303.732 269.778 303.322 269.693 302.834 269.693C302.378 269.693 301.978 269.771 301.633 269.928C301.294 270.084 301.027 270.289 300.832 270.543C300.643 270.797 300.549 271.07 300.549 271.363H298.742C298.742 270.986 298.84 270.611 299.035 270.24C299.23 269.869 299.51 269.534 299.875 269.234C300.246 268.928 300.689 268.688 301.203 268.512C301.724 268.329 302.303 268.238 302.941 268.238C303.71 268.238 304.387 268.368 304.973 268.629C305.565 268.889 306.027 269.283 306.359 269.811C306.698 270.331 306.867 270.986 306.867 271.773V276.695C306.867 277.047 306.896 277.421 306.955 277.818C307.02 278.215 307.115 278.557 307.238 278.844V279H305.354C305.262 278.792 305.191 278.515 305.139 278.17C305.087 277.818 305.061 277.493 305.061 277.193ZM305.373 272.594L305.393 273.863H303.566C303.052 273.863 302.593 273.906 302.189 273.99C301.786 274.068 301.447 274.189 301.174 274.352C300.9 274.514 300.692 274.719 300.549 274.967C300.406 275.208 300.334 275.491 300.334 275.816C300.334 276.148 300.409 276.451 300.559 276.725C300.708 276.998 300.933 277.216 301.232 277.379C301.538 277.535 301.913 277.613 302.355 277.613C302.909 277.613 303.397 277.496 303.82 277.262C304.243 277.027 304.579 276.741 304.826 276.402C305.08 276.064 305.217 275.735 305.236 275.416L306.008 276.285C305.962 276.559 305.839 276.861 305.637 277.193C305.435 277.525 305.165 277.844 304.826 278.15C304.494 278.45 304.097 278.701 303.635 278.902C303.179 279.098 302.665 279.195 302.092 279.195C301.376 279.195 300.747 279.055 300.207 278.775C299.673 278.495 299.257 278.121 298.957 277.652C298.664 277.177 298.518 276.646 298.518 276.061C298.518 275.494 298.628 274.996 298.85 274.566C299.071 274.13 299.39 273.769 299.807 273.482C300.223 273.189 300.725 272.968 301.311 272.818C301.896 272.669 302.551 272.594 303.273 272.594H305.373Z" fill="white"/>
    <path d="M311.672 268.434V279H309.855V268.434H311.672ZM309.719 265.631C309.719 265.338 309.807 265.09 309.982 264.889C310.165 264.687 310.432 264.586 310.783 264.586C311.128 264.586 311.392 264.687 311.574 264.889C311.763 265.09 311.857 265.338 311.857 265.631C311.857 265.911 311.763 266.152 311.574 266.354C311.392 266.549 311.128 266.646 310.783 266.646C310.432 266.646 310.165 266.549 309.982 266.354C309.807 266.152 309.719 265.911 309.719 265.631Z" fill="white"/>
    <path d="M316.535 264V279H314.719V264H316.535Z" fill="white"/>
    <path d="M325.666 277.193V271.754C325.666 271.337 325.581 270.976 325.412 270.67C325.249 270.357 325.002 270.117 324.67 269.947C324.338 269.778 323.928 269.693 323.439 269.693C322.984 269.693 322.583 269.771 322.238 269.928C321.9 270.084 321.633 270.289 321.438 270.543C321.249 270.797 321.154 271.07 321.154 271.363H319.348C319.348 270.986 319.445 270.611 319.641 270.24C319.836 269.869 320.116 269.534 320.48 269.234C320.852 268.928 321.294 268.688 321.809 268.512C322.329 268.329 322.909 268.238 323.547 268.238C324.315 268.238 324.992 268.368 325.578 268.629C326.171 268.889 326.633 269.283 326.965 269.811C327.303 270.331 327.473 270.986 327.473 271.773V276.695C327.473 277.047 327.502 277.421 327.561 277.818C327.626 278.215 327.72 278.557 327.844 278.844V279H325.959C325.868 278.792 325.796 278.515 325.744 278.17C325.692 277.818 325.666 277.493 325.666 277.193ZM325.979 272.594L325.998 273.863H324.172C323.658 273.863 323.199 273.906 322.795 273.99C322.391 274.068 322.053 274.189 321.779 274.352C321.506 274.514 321.298 274.719 321.154 274.967C321.011 275.208 320.939 275.491 320.939 275.816C320.939 276.148 321.014 276.451 321.164 276.725C321.314 276.998 321.538 277.216 321.838 277.379C322.144 277.535 322.518 277.613 322.961 277.613C323.514 277.613 324.003 277.496 324.426 277.262C324.849 277.027 325.184 276.741 325.432 276.402C325.686 276.064 325.822 275.735 325.842 275.416L326.613 276.285C326.568 276.559 326.444 276.861 326.242 277.193C326.04 277.525 325.77 277.844 325.432 278.15C325.1 278.45 324.702 278.701 324.24 278.902C323.785 279.098 323.27 279.195 322.697 279.195C321.981 279.195 321.353 279.055 320.812 278.775C320.279 278.495 319.862 278.121 319.562 277.652C319.27 277.177 319.123 276.646 319.123 276.061C319.123 275.494 319.234 274.996 319.455 274.566C319.676 274.13 319.995 273.769 320.412 273.482C320.829 273.189 321.33 272.968 321.916 272.818C322.502 272.669 323.156 272.594 323.879 272.594H325.979Z" fill="white"/>
    <path d="M330.305 264H332.121V276.949L331.965 279H330.305V264ZM339.26 273.629V273.834C339.26 274.602 339.169 275.315 338.986 275.973C338.804 276.624 338.537 277.19 338.186 277.672C337.834 278.154 337.404 278.528 336.896 278.795C336.389 279.062 335.806 279.195 335.148 279.195C334.478 279.195 333.889 279.081 333.381 278.854C332.88 278.619 332.456 278.284 332.111 277.848C331.766 277.411 331.49 276.884 331.281 276.266C331.079 275.647 330.939 274.951 330.861 274.176V273.277C330.939 272.496 331.079 271.796 331.281 271.178C331.49 270.559 331.766 270.032 332.111 269.596C332.456 269.153 332.88 268.818 333.381 268.59C333.882 268.355 334.465 268.238 335.129 268.238C335.793 268.238 336.382 268.368 336.896 268.629C337.411 268.883 337.84 269.247 338.186 269.723C338.537 270.198 338.804 270.768 338.986 271.432C339.169 272.089 339.26 272.822 339.26 273.629ZM337.443 273.834V273.629C337.443 273.102 337.395 272.607 337.297 272.145C337.199 271.676 337.043 271.266 336.828 270.914C336.613 270.556 336.33 270.276 335.979 270.074C335.627 269.866 335.194 269.762 334.68 269.762C334.224 269.762 333.827 269.84 333.488 269.996C333.156 270.152 332.873 270.364 332.639 270.631C332.404 270.891 332.212 271.191 332.062 271.529C331.919 271.861 331.812 272.206 331.74 272.564V274.918C331.844 275.374 332.014 275.813 332.248 276.236C332.489 276.653 332.808 276.995 333.205 277.262C333.609 277.529 334.107 277.662 334.699 277.662C335.188 277.662 335.604 277.564 335.949 277.369C336.301 277.167 336.584 276.891 336.799 276.539C337.02 276.188 337.183 275.781 337.287 275.318C337.391 274.856 337.443 274.361 337.443 273.834Z" fill="white"/>
    <path d="M343.508 264V279H341.691V264H343.508Z" fill="white"/>
    <path d="M350.793 279.195C350.057 279.195 349.39 279.072 348.791 278.824C348.199 278.57 347.688 278.215 347.258 277.76C346.835 277.304 346.509 276.764 346.281 276.139C346.053 275.514 345.939 274.83 345.939 274.088V273.678C345.939 272.818 346.066 272.053 346.32 271.383C346.574 270.706 346.919 270.133 347.355 269.664C347.792 269.195 348.286 268.84 348.84 268.6C349.393 268.359 349.966 268.238 350.559 268.238C351.314 268.238 351.965 268.368 352.512 268.629C353.065 268.889 353.518 269.254 353.869 269.723C354.221 270.185 354.481 270.732 354.65 271.363C354.82 271.988 354.904 272.672 354.904 273.414V274.225H347.014V272.75H353.098V272.613C353.072 272.145 352.974 271.689 352.805 271.246C352.642 270.803 352.382 270.439 352.023 270.152C351.665 269.866 351.177 269.723 350.559 269.723C350.148 269.723 349.771 269.811 349.426 269.986C349.081 270.156 348.785 270.41 348.537 270.748C348.29 271.087 348.098 271.5 347.961 271.988C347.824 272.477 347.756 273.04 347.756 273.678V274.088C347.756 274.589 347.824 275.061 347.961 275.504C348.104 275.94 348.309 276.324 348.576 276.656C348.85 276.988 349.178 277.249 349.562 277.438C349.953 277.626 350.396 277.721 350.891 277.721C351.529 277.721 352.069 277.59 352.512 277.33C352.954 277.07 353.342 276.721 353.674 276.285L354.768 277.154C354.54 277.499 354.25 277.828 353.898 278.141C353.547 278.453 353.114 278.707 352.6 278.902C352.092 279.098 351.49 279.195 350.793 279.195Z" fill="white"/>
    <path d="M281.957 376.781V391H280.072V376.781H281.957Z" fill="white"/>
    <path d="M286.918 382.689V391H285.111V380.434H286.82L286.918 382.689ZM286.488 385.316L285.736 385.287C285.743 384.564 285.85 383.897 286.059 383.285C286.267 382.667 286.56 382.13 286.938 381.674C287.315 381.218 287.764 380.867 288.285 380.619C288.812 380.365 289.395 380.238 290.033 380.238C290.554 380.238 291.023 380.31 291.439 380.453C291.856 380.59 292.211 380.811 292.504 381.117C292.803 381.423 293.031 381.82 293.188 382.309C293.344 382.79 293.422 383.38 293.422 384.076V391H291.605V384.057C291.605 383.503 291.524 383.061 291.361 382.729C291.199 382.39 290.961 382.146 290.648 381.996C290.336 381.84 289.952 381.762 289.496 381.762C289.047 381.762 288.637 381.856 288.266 382.045C287.901 382.234 287.585 382.494 287.318 382.826C287.058 383.158 286.853 383.539 286.703 383.969C286.56 384.392 286.488 384.841 286.488 385.316Z" fill="white"/>
    <path d="M299.379 389.369L302.27 380.434H304.115L300.316 391H299.105L299.379 389.369ZM296.967 380.434L299.945 389.418L300.15 391H298.939L295.111 380.434H296.967Z" fill="white"/>
    <path d="M310.102 391.195C309.366 391.195 308.699 391.072 308.1 390.824C307.507 390.57 306.996 390.215 306.566 389.76C306.143 389.304 305.818 388.764 305.59 388.139C305.362 387.514 305.248 386.83 305.248 386.088V385.678C305.248 384.818 305.375 384.053 305.629 383.383C305.883 382.706 306.228 382.133 306.664 381.664C307.1 381.195 307.595 380.84 308.148 380.6C308.702 380.359 309.275 380.238 309.867 380.238C310.622 380.238 311.273 380.368 311.82 380.629C312.374 380.889 312.826 381.254 313.178 381.723C313.529 382.185 313.79 382.732 313.959 383.363C314.128 383.988 314.213 384.672 314.213 385.414V386.225H306.322V384.75H312.406V384.613C312.38 384.145 312.283 383.689 312.113 383.246C311.951 382.803 311.69 382.439 311.332 382.152C310.974 381.866 310.486 381.723 309.867 381.723C309.457 381.723 309.079 381.811 308.734 381.986C308.389 382.156 308.093 382.41 307.846 382.748C307.598 383.087 307.406 383.5 307.27 383.988C307.133 384.477 307.064 385.04 307.064 385.678V386.088C307.064 386.589 307.133 387.061 307.27 387.504C307.413 387.94 307.618 388.324 307.885 388.656C308.158 388.988 308.487 389.249 308.871 389.438C309.262 389.626 309.704 389.721 310.199 389.721C310.837 389.721 311.378 389.59 311.82 389.33C312.263 389.07 312.65 388.721 312.982 388.285L314.076 389.154C313.848 389.499 313.559 389.828 313.207 390.141C312.855 390.453 312.423 390.707 311.908 390.902C311.4 391.098 310.798 391.195 310.102 391.195Z" fill="white"/>
    <path d="M322.475 388.197C322.475 387.937 322.416 387.696 322.299 387.475C322.188 387.247 321.957 387.042 321.605 386.859C321.26 386.671 320.74 386.508 320.043 386.371C319.457 386.247 318.926 386.101 318.451 385.932C317.982 385.762 317.582 385.557 317.25 385.316C316.924 385.076 316.674 384.792 316.498 384.467C316.322 384.141 316.234 383.76 316.234 383.324C316.234 382.908 316.326 382.514 316.508 382.143C316.697 381.771 316.96 381.443 317.299 381.156C317.644 380.87 318.057 380.645 318.539 380.482C319.021 380.32 319.558 380.238 320.15 380.238C320.997 380.238 321.719 380.388 322.318 380.688C322.917 380.987 323.376 381.387 323.695 381.889C324.014 382.383 324.174 382.934 324.174 383.539H322.367C322.367 383.246 322.279 382.963 322.104 382.689C321.934 382.41 321.684 382.178 321.352 381.996C321.026 381.814 320.626 381.723 320.15 381.723C319.649 381.723 319.242 381.801 318.93 381.957C318.624 382.107 318.399 382.299 318.256 382.533C318.119 382.768 318.051 383.015 318.051 383.275C318.051 383.471 318.083 383.646 318.148 383.803C318.22 383.952 318.344 384.092 318.52 384.223C318.695 384.346 318.943 384.464 319.262 384.574C319.581 384.685 319.988 384.796 320.482 384.906C321.348 385.102 322.061 385.336 322.621 385.609C323.181 385.883 323.598 386.218 323.871 386.615C324.145 387.012 324.281 387.494 324.281 388.061C324.281 388.523 324.184 388.946 323.988 389.33C323.799 389.714 323.523 390.046 323.158 390.326C322.8 390.6 322.37 390.814 321.869 390.971C321.374 391.12 320.818 391.195 320.199 391.195C319.268 391.195 318.48 391.029 317.836 390.697C317.191 390.365 316.703 389.936 316.371 389.408C316.039 388.881 315.873 388.324 315.873 387.738H317.689C317.715 388.233 317.859 388.627 318.119 388.92C318.38 389.206 318.699 389.411 319.076 389.535C319.454 389.652 319.828 389.711 320.199 389.711C320.694 389.711 321.107 389.646 321.439 389.516C321.778 389.385 322.035 389.206 322.211 388.979C322.387 388.751 322.475 388.49 322.475 388.197Z" fill="white"/>
    <path d="M331.078 380.434V381.82H325.365V380.434H331.078ZM327.299 377.865H329.105V388.383C329.105 388.741 329.161 389.011 329.271 389.193C329.382 389.376 329.525 389.496 329.701 389.555C329.877 389.613 330.066 389.643 330.268 389.643C330.417 389.643 330.574 389.63 330.736 389.604C330.906 389.571 331.033 389.545 331.117 389.525L331.127 391C330.984 391.046 330.795 391.088 330.561 391.127C330.333 391.173 330.056 391.195 329.73 391.195C329.288 391.195 328.881 391.107 328.51 390.932C328.139 390.756 327.842 390.463 327.621 390.053C327.406 389.636 327.299 389.076 327.299 388.373V377.865Z" fill="white"/>
    <path d="M337.582 391.195C336.846 391.195 336.179 391.072 335.58 390.824C334.988 390.57 334.477 390.215 334.047 389.76C333.624 389.304 333.298 388.764 333.07 388.139C332.842 387.514 332.729 386.83 332.729 386.088V385.678C332.729 384.818 332.855 384.053 333.109 383.383C333.363 382.706 333.708 382.133 334.145 381.664C334.581 381.195 335.076 380.84 335.629 380.6C336.182 380.359 336.755 380.238 337.348 380.238C338.103 380.238 338.754 380.368 339.301 380.629C339.854 380.889 340.307 381.254 340.658 381.723C341.01 382.185 341.27 382.732 341.439 383.363C341.609 383.988 341.693 384.672 341.693 385.414V386.225H333.803V384.75H339.887V384.613C339.861 384.145 339.763 383.689 339.594 383.246C339.431 382.803 339.171 382.439 338.812 382.152C338.454 381.866 337.966 381.723 337.348 381.723C336.938 381.723 336.56 381.811 336.215 381.986C335.87 382.156 335.574 382.41 335.326 382.748C335.079 383.087 334.887 383.5 334.75 383.988C334.613 384.477 334.545 385.04 334.545 385.678V386.088C334.545 386.589 334.613 387.061 334.75 387.504C334.893 387.94 335.098 388.324 335.365 388.656C335.639 388.988 335.967 389.249 336.352 389.438C336.742 389.626 337.185 389.721 337.68 389.721C338.318 389.721 338.858 389.59 339.301 389.33C339.743 389.07 340.131 388.721 340.463 388.285L341.557 389.154C341.329 389.499 341.039 389.828 340.688 390.141C340.336 390.453 339.903 390.707 339.389 390.902C338.881 391.098 338.279 391.195 337.582 391.195Z" fill="white"/>
    <path d="M350.463 388.949V376H352.279V391H350.619L350.463 388.949ZM343.354 385.834V385.629C343.354 384.822 343.451 384.089 343.646 383.432C343.848 382.768 344.132 382.198 344.496 381.723C344.867 381.247 345.307 380.883 345.814 380.629C346.329 380.368 346.902 380.238 347.533 380.238C348.197 380.238 348.777 380.355 349.271 380.59C349.773 380.818 350.196 381.153 350.541 381.596C350.893 382.032 351.169 382.559 351.371 383.178C351.573 383.796 351.713 384.496 351.791 385.277V386.176C351.719 386.951 351.579 387.647 351.371 388.266C351.169 388.884 350.893 389.411 350.541 389.848C350.196 390.284 349.773 390.619 349.271 390.854C348.77 391.081 348.184 391.195 347.514 391.195C346.895 391.195 346.329 391.062 345.814 390.795C345.307 390.528 344.867 390.154 344.496 389.672C344.132 389.19 343.848 388.624 343.646 387.973C343.451 387.315 343.354 386.602 343.354 385.834ZM345.17 385.629V385.834C345.17 386.361 345.222 386.856 345.326 387.318C345.437 387.781 345.606 388.188 345.834 388.539C346.062 388.891 346.352 389.167 346.703 389.369C347.055 389.564 347.475 389.662 347.963 389.662C348.562 389.662 349.053 389.535 349.438 389.281C349.828 389.027 350.141 388.692 350.375 388.275C350.609 387.859 350.792 387.406 350.922 386.918V384.564C350.844 384.206 350.73 383.861 350.58 383.529C350.437 383.191 350.248 382.891 350.014 382.631C349.786 382.364 349.503 382.152 349.164 381.996C348.832 381.84 348.438 381.762 347.982 381.762C347.488 381.762 347.061 381.866 346.703 382.074C346.352 382.276 346.062 382.556 345.834 382.914C345.606 383.266 345.437 383.676 345.326 384.145C345.222 384.607 345.17 385.102 345.17 385.629Z" fill="white"/>
    <path d="M197 353H423" stroke="#923CFF" strokeWidth="3"/>
    <defs>
        <linearGradient id="paint_mobile0_linear_1399_81994" x1="454.266" y1="253.089" x2="205.745" y2="611.334" gradientUnits="userSpaceOnUse">
            <stop stopColor="#303036"/>
            <stop offset="1" stopColor="#514F5C"/>
        </linearGradient>
        <linearGradient id="paint_mobile1_linear_1399_81994" x1="37.2173" y1="81" x2="151.373" y2="336.598" gradientUnits="userSpaceOnUse">
            <stop stopColor="#726F7E"/>
            <stop offset="1" stopColor="#252529"/>
        </linearGradient>
        <linearGradient id="paint_mobile2_linear_1399_81994" x1="439.129" y1="588.363" x2="36" y2="52.5" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0066FF" stopOpacity="0.41"/>
            <stop offset="0.34375" stopColor="#0021BE" stopOpacity="0.43"/>
            <stop offset="1" stopColor="#88048B"/>
        </linearGradient>
        <linearGradient id="paint_mobile3_linear_1399_81994" x1="591.762" y1="90.8395" x2="467.563" y2="460.347" gradientUnits="userSpaceOnUse">
            <stop offset="0.21875" stopColor="#302F35" stopOpacity="0"/>
            <stop offset="0.671875" stopColor="#81BD5F"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile4_linear_1399_81994" x1="354.957" y1="206.808" x2="577.368" y2="206.808" gradientUnits="userSpaceOnUse">
            <stop offset="0.307292" stopColor="#302F35" stopOpacity="0"/>
            <stop offset="0.526042" stopColor="#9AD79A"/>
            <stop offset="0.770833" stopColor="#0F9AFF"/>
            <stop offset="1" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile5_linear_1399_81994" x1="216.835" y1="183.026" x2="405.582" y2="183.026" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile6_linear_1399_81994" x1="216.042" y1="536.796" x2="404.789" y2="536.796" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="1" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile7_linear_1399_81994" x1="4.57397" y1="298.877" x2="157.249" y2="300.888" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile8_linear_1399_81994" x1="4.67218" y1="299.074" x2="157.189" y2="301.082" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile9_linear_1399_81994" x1="4.64781" y1="298.968" x2="157.251" y2="300.977" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile10_linear_1399_81994" x1="4.64893" y1="298.739" x2="157.166" y2="300.747" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile11_linear_1399_81994" x1="4.6284" y1="298.858" x2="157.232" y2="300.867" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile12_linear_1399_81994" x1="4.60781" y1="298.742" x2="157.211" y2="300.752" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile13_linear_1399_81994" x1="4.55818" y1="298.749" x2="157.162" y2="300.759" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile14_linear_1399_81994" x1="4.60132" y1="298.729" x2="157.205" y2="300.738" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile15_linear_1399_81994" x1="4.58256" y1="298.725" x2="157.186" y2="300.734" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile16_linear_1399_81994" x1="4.60055" y1="298.959" x2="157.118" y2="300.967" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile17_linear_1399_81994" x1="4.55819" y1="298.966" x2="157.233" y2="300.977" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile18_linear_1399_81994" x1="4.57979" y1="298.66" x2="157.169" y2="300.67" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile19_linear_1399_81994" x1="4.6262" y1="299.03" x2="157.23" y2="301.039" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile20_linear_1399_81994" x1="4.59891" y1="298.723" x2="157.203" y2="300.733" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile21_linear_1399_81994" x1="4.64127" y1="298.873" x2="157.159" y2="300.881" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile22_linear_1399_81994" x1="4.64688" y1="298.94" x2="157.164" y2="300.948" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile23_linear_1399_81994" x1="4.5732" y1="298.956" x2="157.177" y2="300.966" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile24_linear_1399_81994" x1="4.62365" y1="298.79" x2="157.141" y2="300.798" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile25_linear_1399_81994" x1="4.55481" y1="298.808" x2="157.23" y2="300.819" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile26_linear_1399_81994" x1="4.61148" y1="298.555" x2="157.2" y2="300.565" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile27_linear_1399_81994" x1="4.62981" y1="298.664" x2="157.147" y2="300.672" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile28_linear_1399_81994" x1="4.54912" y1="298.743" x2="157.224" y2="300.754" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile29_linear_1399_81994" x1="4.54605" y1="298.736" x2="157.221" y2="300.747" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile30_linear_1399_81994" x1="4.53439" y1="298.605" x2="157.138" y2="300.614" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile31_linear_1399_81994" x1="4.53557" y1="298.598" x2="157.139" y2="300.607" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile32_linear_1399_81994" x1="4.57428" y1="298.744" x2="157.178" y2="300.753" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile33_linear_1399_81994" x1="4.57266" y1="298.798" x2="157.176" y2="300.807" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile34_linear_1399_81994" x1="4.56492" y1="298.552" x2="157.169" y2="300.561" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile35_linear_1399_81994" x1="4.56537" y1="298.508" x2="157.169" y2="300.517" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile36_linear_1399_81994" x1="4.54655" y1="298.614" x2="157.15" y2="300.623" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile37_linear_1399_81994" x1="4.54693" y1="298.655" x2="157.151" y2="300.665" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile38_linear_1399_81994" x1="4.57103" y1="298.782" x2="157.088" y2="300.79" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile39_linear_1399_81994" x1="4.47784" y1="298.679" x2="157.153" y2="300.69" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile40_linear_1399_81994" x1="4.50224" y1="298.409" x2="157.177" y2="300.42" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile41_linear_1399_81994" x1="4.57954" y1="298.667" x2="157.097" y2="300.676" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile42_linear_1399_81994" x1="4.52205" y1="298.656" x2="157.126" y2="300.665" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile43_linear_1399_81994" x1="4.51187" y1="298.69" x2="157.115" y2="300.7" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile44_linear_1399_81994" x1="4.56488" y1="298.722" x2="157.082" y2="300.73" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile45_linear_1399_81994" x1="4.5019" y1="298.609" x2="157.105" y2="300.618" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile46_linear_1399_81994" x1="4.46358" y1="298.587" x2="157.154" y2="300.597" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile47_linear_1399_81994" x1="4.52278" y1="298.648" x2="157.126" y2="300.658" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile48_linear_1399_81994" x1="4.51549" y1="298.615" x2="157.119" y2="300.625" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile49_linear_1399_81994" x1="4.51116" y1="298.585" x2="157.1" y2="300.595" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile50_linear_1399_81994" x1="4.50831" y1="298.575" x2="157.112" y2="300.584" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile51_linear_1399_81994" x1="4.58283" y1="298.546" x2="157.1" y2="300.554" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile52_linear_1399_81994" x1="4.50792" y1="298.749" x2="157.097" y2="300.759" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile53_linear_1399_81994" x1="4.51944" y1="298.639" x2="157.123" y2="300.648" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile54_linear_1399_81994" x1="4.47796" y1="298.713" x2="157.067" y2="300.723" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile55_linear_1399_81994" x1="4.54479" y1="298.625" x2="157.062" y2="300.633" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile56_linear_1399_81994" x1="4.49603" y1="298.688" x2="157.1" y2="300.697" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile57_linear_1399_81994" x1="4.45379" y1="298.692" x2="157.057" y2="300.701" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile58_linear_1399_81994" x1="4.49735" y1="298.679" x2="157.015" y2="300.687" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile59_linear_1399_81994" x1="4.45785" y1="298.67" x2="157.133" y2="300.681" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile60_linear_1399_81994" x1="4.47592" y1="298.428" x2="157.079" y2="300.438" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile61_linear_1399_81994" x1="4.55294" y1="298.568" x2="157.142" y2="300.578" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile62_linear_1399_81994" x1="12.6659" y1="277.868" x2="21.9738" y2="275.543" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile63_linear_1399_81994" x1="240" y1="510.864" x2="245" y2="210.864" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#0000FF" stopOpacity="0.3"/>
            <stop offset="0.560913" stopColor="#6E56D1"/>
            <stop offset="1" stopColor="#6D96EE"/>
        </linearGradient>
        <linearGradient id="paint_mobile64_linear_1399_81994" x1="413" y1="209.864" x2="389.5" y2="498.864" gradientUnits="userSpaceOnUse">
            <stop stopColor="#6D96EE"/>
            <stop offset="0.476376" stopColor="#6E56D1"/>
            <stop offset="1" stopColor="#0000FF" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint_mobile65_linear_1399_81994" x1="336.38" y1="435.405" x2="446.231" y2="435.405" gradientUnits="userSpaceOnUse">
            <stop offset="0.0364583" stopColor="#05CAFE"/>
            <stop offset="0.510417" stopColor="#0F9AFF"/>
            <stop offset="0.723958" stopColor="#1D5DFE"/>
            <stop offset="0.989583" stopColor="#1F55FE"/>
        </linearGradient>
        <linearGradient id="paint_mobile66_linear_1399_81994" x1="232.533" y1="381.02" x2="232.533" y2="490.871" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile67_linear_1399_81994" x1="286.202" y1="282.032" x2="176.356" y2="282.032" gradientUnits="userSpaceOnUse">
            <stop offset="0.114583" stopColor="#05CAFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.6875" stopColor="#1D5DFE"/>
            <stop offset="1" stopColor="#1F55FE"/>
        </linearGradient>
        <linearGradient id="paint_mobile68_linear_1399_81994" x1="390.054" y1="336.417" x2="390.054" y2="226.572" gradientUnits="userSpaceOnUse">
            <stop stopColor="#05CAFE"/>
            <stop offset="0.25" stopColor="#0F9AFF"/>
            <stop offset="0.59375" stopColor="#1D5DFE"/>
            <stop offset="0.963542" stopColor="#1F55FE"/>
        </linearGradient>
        <linearGradient id="paint_mobile69_linear_1399_81994" x1="479.133" y1="100.907" x2="573.885" y2="183.105" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile70_linear_1399_81994" x1="154.16" y1="618.499" x2="58.3083" y2="537.58" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile71_linear_1399_81994" x1="335.834" y1="185.803" x2="331.02" y2="202.376" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.47" stopColor="#00B0FF"/>
            <stop offset="0.98" stopColor="#1B64FF"/>
        </linearGradient>
        <linearGradient id="paint_mobile72_linear_1399_81994" x1="270.047" y1="187.156" x2="352.213" y2="187.156" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile73_linear_1399_81994" x1="335.834" y1="185.803" x2="331.02" y2="202.376" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.47" stopColor="#00B0FF"/>
            <stop offset="0.98" stopColor="#1B64FF"/>
        </linearGradient>
        <linearGradient id="paint_mobile74_linear_1399_81994" x1="270.204" y1="187.156" x2="352.213" y2="187.156" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile75_linear_1399_81994" x1="270.204" y1="187.156" x2="352.371" y2="187.156" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile76_linear_1399_81994" x1="349.146" y1="187.274" x2="352.213" y2="187.274" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile77_linear_1399_81994" x1="335.834" y1="530.931" x2="331.004" y2="547.53" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.47" stopColor="#00B0FF"/>
            <stop offset="0.98" stopColor="#1B64FF"/>
        </linearGradient>
        <linearGradient id="paint_mobile78_linear_1399_81994" x1="270.047" y1="532.287" x2="352.213" y2="532.287" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile79_linear_1399_81994" x1="335.834" y1="530.931" x2="331.004" y2="547.53" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.47" stopColor="#00B0FF"/>
            <stop offset="0.98" stopColor="#1B64FF"/>
        </linearGradient>
        <linearGradient id="paint_mobile80_linear_1399_81994" x1="335.834" y1="530.931" x2="331.004" y2="547.53" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.47" stopColor="#00B0FF"/>
            <stop offset="0.98" stopColor="#1B64FF"/>
        </linearGradient>
        <linearGradient id="paint_mobile81_linear_1399_81994" x1="270.204" y1="532.287" x2="352.371" y2="532.287" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint_mobile82_linear_1399_81994" x1="349.146" y1="532.408" x2="352.213" y2="532.408" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
    </defs>
</g>;