import { action, thunk } from 'easy-peasy';
import moment from 'moment';
import { constants } from '../constants';
import { dataHelper } from './dataHelpers/dataHelper';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';
import { hodlBotFactory } from './hodlBotFactory';
import { uiActions } from './uiActions';

const { CONTROLS: { LABEL_EXCHANGE, LABEL_BOT_WILL_BUY, LABEL_FOR, LABEL_AT } } = constants;

const actionTypes = {
  HODL_BOT_FORM_ELEMENTS_CHANGED: 'HODL_BOT_FORM_ELEMENTS#CHANGED',
  HODL_BOT_REQUESTS_INVOKED: 'HODL_BOT_REQUESTS#INVOKED',
  HODL_BOT_REQUESTS_SUCCEEDED: 'HODL_BOT_REQUESTS#SUCCEEDED',
  HODL_BOT_REQUESTS_FAILED: 'HODL_BOT_REQUESTS#FAILED',
  HODL_BOT_EXCHANGE_INIT: 'HODL_BOT_EXCHANGE#INIT'
};

const stateHandle = (state, payload) => {
  const { name, value } = payload

  switch (name) {
    case LABEL_EXCHANGE:
      state.exchangeSelector.value = payload;
      const listData = dataHelper.generateMainCoinList(state.assetsData, payload?.value?.api);
      state.botWillBuyList = listData.list;
      break;
    case LABEL_BOT_WILL_BUY:
      state.botWillBuySelector = value;
      break;
    case LABEL_FOR:
      state.forSelector.value = value;
      break;
    case 'input_for_value':
      state.inputForValue = value;
      break;
    case 'input_every_value':
      state.inputEveryValue = value;
      break;
    case LABEL_AT:
      state.atSelector.value = payload;
      break;
    case 'input_next_buy_date_value':
      state.inputNextBuyDateValue = value ? moment(value).format('YYYY-MM-DD') : '';
      break;
  }
}

const invokeHandle = (actions, payload) => {
  const { method, values } = payload;

  switch (method) {
    case 'create-bot':
    case 'update-bot':
      hodlBotFactory.createUpdateBotAction(actions, values, method)
      break;
    case 'start-bot':
      hodlBotFactory.startBotAction(actions, values)
      break;
    case 'stop-bot':
      hodlBotFactory.stopBotAction(actions, values)
      break;
    case 'delete-bot':
      hodlBotFactory.deleteBotAction(actions, values)
      break;
  }
}

const successHandle = (actions, payload) => {
  const { options, response } = payload;

  switch (options.url) {
    case '/hodl-bot/create':
      actions[uiActions.actionTypes.HODL_BOT_MODAL_INVOKED](true)
      break;
    case '/hodl-bot/start':
      actions[uiActions.actionTypes.HODL_BOT_RUNNING_BOT_INVOKED](true)
      break;
    case '/hodl-bot/stop':
      actions[uiActions.actionTypes.HODL_BOT_RUNNING_BOT_INVOKED](false)
      break;
    case '/hodl-bot/delete':
      actions[uiActions.actionTypes.HODL_BOT_RUNNING_BOT_INVOKED](false)
      break;
  }
}

const errorAction = () => {
  const notification = { type: 'danger', text: 'Something goes wrong' };
  notifyEffects.notifyAction(notification);
};

const exchangeHandle = (state, payload) => {
  state.exchangeSelector.list = payload;
}

const actionHandlers = {
  [actionTypes.HODL_BOT_FORM_ELEMENTS_CHANGED]: action((state, payload) => stateHandle(state, payload)),
  [actionTypes.HODL_BOT_REQUESTS_INVOKED]: thunk( async (actions, payload) => invokeHandle(actions, payload)),
  [actionTypes.HODL_BOT_REQUESTS_SUCCEEDED]: thunk( (actions, payload) => successHandle(actions, payload)),
  [actionTypes.HODL_BOT_REQUESTS_FAILED]: action( (state, payload) => errorAction(state, payload)),
  [actionTypes.HODL_BOT_EXCHANGE_INIT]: action( (state, payload) => exchangeHandle(state, payload)),
};

export const hodlBotEffects = {
  actionTypes,
  actionHandlers
};
