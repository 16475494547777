import { action, thunk } from 'easy-peasy';
import { constants } from '../constants';
import axios from 'axios';
import { dataHelper } from './dataHelper';
import { isEmpty } from 'lodash';
import { notifyEffects } from '../../utility/notifications/notifyEffects';

const { API } = constants;

const actionTypes = {
    TRIAL_NFT_METAMASK_INVOKED: 'TRIAL_NFT_METAMASK#INVOKED',
    TRIAL_NFT_METAMASK_STATE_MUTATION: 'TRIAL_NFT_METAMASK_STATE_MUTATION',
    TRIAL_NFT_METAMASK_STEPS_INVOKED: 'TRIAL_NFT_METAMASK_STEPS_INVOKED'
};

const invokeContractHandle = async (actions, data) => {
    const transactionParameters = dataHelper.transactionData(data);
    await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters]
    })
    .catch(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({ name: 'error', value }))
    .then(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({ name: 'finally-action', value}));
};

const switchHandle = async (actions, chainId) => {
    await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }],
        })
        .catch(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'error', value}))
        .then(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'invoke-contract', value}));
};

const addChainHandle = async (actions, value) => {
    const {data: {nftbalances}} = value;
    const chainData = dataHelper.parseChains(nftbalances);

    if (isEmpty(chainData)) {
        actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'error', value: {message: 'Chain not exists'}});
        return;
    }

    const {chainId, chainName, rpcUrls, blockExplorerUrls, nativeCurrency} = chainData;

    actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({name: 'state-update', value: {chainId, chainName, rpcUrls, blockExplorerUrls, nativeCurrency}});
    await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId,
                    chainName,
                    rpcUrls,
                    blockExplorerUrls,
                    nativeCurrency
                }
            ]
        })
        .catch(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'error', value}))
        .then(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'invoke-wallet-switch', value, chainId}));
};

const fetchChains = async (actions, accountId) => {
    API.data = { address: accountId };
    await axios({ ...API })
        .catch(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'error', value}))
        .then( value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'add-chain', value}));
};

const trialHandle = async actions => {
    actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({ name: 'start-loader' });
    await window.ethereum.request({ method: 'eth_requestAccounts' })
        .catch(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'error', value}))
        .then(value => actions[actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]({name: 'invoke-chains', value}));
};

const stateMutationHandle = (state, payload) => {
    const { name, value } = payload;
    switch (name) {
    case 'start-loader':
        state.loader = true;
        break;
    case 'stop-loader':
        state.loader = false;
        break;
    case 'state-update':
        state.chainData = value;
        break;
    }
};

const invokeHandle = async (actions, payload) => {
  const {name, value, chainId} = payload;
  let notification;
  switch (name) {
  case 'invoke-chains':
      const trialAccount = dataHelper.getAddress(value);
      await fetchChains(actions, trialAccount);
      break;
  case 'add-chain':
      await addChainHandle(actions, value);
      break;
  case 'invoke-wallet-switch':
      actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({name: 'stop-loader'});
     // await  switchHandle(actions, chainId);
      break;
  case 'invoke-contract':
      // await invokeContractHandle(actions, value);
      actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({name: 'stop-loader'});
      notification = { type: 'success', text: '7 days trial started successfully' };
      notifyEffects.notifyAction(notification);
      break;
  case 'finally-action':
      actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({name: 'stop-loader'});
      break;
  case 'error':
      actions[actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]({name: 'stop-loader'});
      notification = { type: 'danger', text: value.message || 'Error!' };
      notifyEffects.notifyAction(notification);
      break;
  }
};

const actionHandlers = {
    [actionTypes.TRIAL_NFT_METAMASK_INVOKED]: thunk(async (actions, payload) => trialHandle(actions, payload)),
    [actionTypes.TRIAL_NFT_METAMASK_STATE_MUTATION]: action((state, payload) => stateMutationHandle(state, payload)),
    [actionTypes.TRIAL_NFT_METAMASK_STEPS_INVOKED]: thunk(async (actions, payload) => invokeHandle(actions, payload)),
};
export const trialNFTEffects = {
    actionTypes,
    actionHandlers
};