import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/nftGeneratorPage/actions/uiActions';
import { nftGeneratorEffects } from '../pages/nftGeneratorPage/actions/nftGeneratorEffects';

const NFT_GENERATOR_MODEL = {
  togglePickYourAvatars: true,
  tabs: 'body',
  items: [],
  item: {},
  images: [],
  image: '',
  activeItem: 1,
  pickColor: '',
  selectChain: {
    show: false,
    selectedChain: ''
  },
  loader: false,
  chains: [],

  ...uiActions.actionHandlers,
  ...nftGeneratorEffects.actionHandlers
};

export const NftGeneratorState = createContextStore(NFT_GENERATOR_MODEL);
