import Big from 'big.js';
import numeral from 'numeral';

const addBlankDonat = data => [data, {
    name: 'Blank',
    value: 100 - data.value,
    color: '#49484D',
    class: 'blank-circle'
}];


const createRadiusSet = (keys = [], areaSize, stroke, gap) =>
    Array(keys.length).fill(null).map((_, i) =>
        areaSize / 2 - (stroke + gap) * (keys.length - i - 1));

const getTextAreaRadius = (radiuses, stroke) => radiuses[0] - stroke;

const createEmptyData = limit => {
    const data = Array(limit).fill({});
    return data.map((el, i) => ({
        name: `emptyValue-${i}`,
        value: 0,
        color: 'transparent'
    }));
};

const parseTotals = (totals, currency, isEmptyData) => {
    if (isEmptyData)
        return {
            colorClass: '',
            subTitle_0: '',
            subTitle_1: '',
            subTitle_2: '',
            subTitle_3: ''
        };
    const currencySign = currency === 'USDT' ? '$' : 'BTC ';
    const format = totals[currency] >= 1000 ? '0,0' : '0,0.[00]';
    const oldTotalsKey = `old_${currency}`;
    const delta = Big(totals[currency]).minus(Big(totals[oldTotalsKey]));
    const change = !Big(totals[oldTotalsKey]).eq(0) ? delta.div(Big(totals[oldTotalsKey])).valueOf() : '0';
    const deltaValue = Number(delta.valueOf());
    const sign = delta.eq(0) ? '' : deltaValue > 0 ? '+' : '-';
    const deltaFormat = `${sign}0.[00]%`;
    const value = numeral(change).format(deltaFormat);
    const deltaStringFormat = deltaValue > 1000 || deltaValue < -1000 ? `${sign}0,0` : `${sign}0,0.[00]`;
    const deltaString = numeral(deltaValue).format(deltaStringFormat);
    const colorClass = !sign || Big(change).eq(0) ? '' : sign === '+' ? 'positive' : 'negative';

    return {
        colorClass,
        subTitle_0: `${currencySign}${numeral(totals[currency]).format(format)}`,
        subTitle_1: 'change (24h)',
        subTitle_2: `${value}`,
        subTitle_3: Big(change).eq(0) ? '' : `${deltaString} ${currencySign}`,
    };
};


export const dataHelper = {
    addBlankDonat,
    createRadiusSet,
    getTextAreaRadius,
    createEmptyData,
    parseTotals
};
