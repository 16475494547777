import React from 'react';
import { availableAssetsContent } from '../tables/walletAssetsTable';
import Slider from 'rc-slider';
import { LineChartTemaplte } from '../lineChartTemplate';
import { ButtonCustom } from '../../../../components/utility/custom-buttons/view';
import { Collapse, ButtonGroup } from 'react-bootstrap';
import { uiActions } from '../../actions/uiActions';
import { ImagesGeneratorDeFi } from './imagesGenerator/imageTemplateDefi';
import { ImagesGeneratorCeFi } from './imagesGenerator/imagesTemaplateCefi';
import { Bots } from '../../../dashboard/widgets/bots-widget/view';
import { ImagesGeneratorLending } from './imagesGenerator/imagesTemplateLending';

export const mobileMiddleContentTemplate = (state, actions, theme, history, hasExchange) => {

    const onToggle = id => actions[uiActions.actionTypes.REMAKE_DASHBOARD_TAB_TOGGLED]({ id });

    const chevronDeFi = state.defi.show ? 'up' : 'down';
    const chevronCeFi = state.cefi.show ? 'up' : 'down';
    const chevronCeFiBalances = state.cefi.cefi_balances ? 'up' : 'down';
    const chevronBots = state.showBots ? 'up' : 'down';
    const chevronLending = state.lending.show ? 'up' : 'down';
    
    return <div className="remake-dashboard-visual-content-middle w-100 pt-3 d-lg-none">
        <div className="collapse-block">
            <div className="defi-collapse-wrapper">
                <div className="collapse-header">
                    <div className="font-weight-medium font-size-24">DeFi</div>
                    <div className="text-end"><span className="pe-3 font-size-24">$ {Number(state.defi.balance).toFixed(0)}</span>
                        <i onClick={() => onToggle('defi')} className={`fa fa-chevron-${chevronDeFi} cursor-pointer`}></i></div>
                </div>
                <Collapse in={state.defi.show}>
                    <div className="collapse-content">
                        <div className="ps-4 pe-4 pt-3 pb-4"><ImagesGeneratorDeFi/></div>
                        <div className="balances-title pt-3 ps-3 font-size-24">Balances</div>
                        <div className="table-assets">{availableAssetsContent(state.walletAssets.assets, state, 'defi')}</div>
                    </div>
                </Collapse>
            </div>
            <div className="cefi-collapse-wrapper">
                <div className="collapse-header">
                    <div className="font-weight-medium font-size-24">CeFi</div>
                    <div className="text-end"><span className="pe-3 font-size-24">$ {Number(state.cefi.balance).toFixed(0)}</span>
                        <i onClick={() => onToggle('cefi')} className={`fa fa-chevron-${chevronCeFi} cursor-pointer`}></i></div>
                </div>
                <Collapse in={state.cefi.show}>
                    <div className="collapse-content inner-collapse">
                        { hasExchange ? <>
                            <div className="ps-4 pe-4 pt-3 pb-4"><ImagesGeneratorCeFi/></div>
                            <div className="cefi-collapse-header pt-3 pb-3 pe-3 ps-3">
                                <div className="font-size-24">Balances</div>
                                <div className="text-end"><i onClick={() => onToggle('cefi_balances')} className={`fa fa-chevron-${chevronCeFiBalances} cursor-pointer`}></i></div>
                            </div>   
                            <Collapse in={state.cefi.cefi_balances}>
                                <div className="cefi-assets-collapse-content">
                                    <div className="table-assets">{availableAssetsContent(state.availableAssets, state, 'cefi')}</div>
                                </div>
                            </Collapse>
                            <div className="cefi-collapse-header pt-3 pb-3 pe-3 ps-3">
                                <div className="font-size-24">Open Orders</div>
                                <div className="text-end"><i onClick={() => onToggle('bots')} className={`fa fa-chevron-${chevronBots} cursor-pointer`}></i></div>
                            </div>
                            <Collapse in={state.showBots}>
                                <div className="cefi-collapse-content">
                                    <div><Bots hideControlPanel={true}/></div>
                                </div>
                            </Collapse>
                            <div className="collapse-header pt-3 pb-3 pe-3 ps-3">
                                <div className="font-size-24">Lending</div>
                                <div className="text-end"><i onClick={() => onToggle('lending')} className={`fa fa-chevron-${chevronLending} cursor-pointer`}></i></div>
                            </div>
                            <Collapse in={state.lending.show}>
                                <div className="cefi-collapse-content">
                                    <div>
                                        <div className="harvest-chart-grid ps-3 pt-3 pe-3 pb-3">
                                            <div></div>
                                            <div className="chart-controls-grid">
                                                <div>
                                                    <ButtonGroup className="chart-control-group">
                                                        <ButtonCustom label="D" active={true} variant="default" size="sm" name="button_day"/>
                                                        <ButtonCustom label="W" variant="default" size="sm" name="button_week"/>
                                                        <ButtonCustom label="M" variant="default" size="sm" name="button_month"/>
                                                    </ButtonGroup>
                                                </div>
                                                <div>
                                                    <ButtonGroup className="chart-control-group">
                                                        <ButtonCustom label="All" active={true} variant="default" size="sm" name="button_day"/>
                                                        <ButtonCustom label="DeFi" variant="default" size="sm" name="button_week"/>
                                                        <ButtonCustom label="CeFi" variant="default" size="sm" name="button_month"/>
                                                    </ButtonGroup>
                                                </div>
                                            </div>
                                            <div className="mobile-chart">
                                                <LineChartTemaplte data={history}/>
                                            </div>
                                            <div className="mobile-lending-info">
                                                <div className="middle-block defi-lending-limit pb-5">
                                                    <div className="d-flex justify-content-center title font-size-24 pb-3">Lending</div>
                                                    <div className="font-size-18 font-weight-regular justify-content-start">Available credit</div>
                                                    <div className="lending-inner-grid">
                                                        <div className="font-size-16 font-weight-light justify-content-start">Borrow limit</div>
                                                        <div className="text-end">100%</div>
                                                    </div>
                                                    <div className="slider">
                                                        <Slider className="slider -primary" defaultValue={0} min={0} value={state.lending.borrowLimitUsed} max={100}/>
                                                    </div>
                                                </div>
                                                <div className="middle-block defi-lending-borrow-supply">
                                                    <ImagesGeneratorLending />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </> :
                            <div className="ps-4 pe-4 pt-3 pb-4"><ImagesGeneratorCeFi/></div>}
                    </div>
                </Collapse>
            </div>
        </div>
    </div>;
};