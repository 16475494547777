import { thunk, action } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { vestingConstants } from '../constants';
import { dataHelper } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';

const { BUY: { API, APPROVE_API } } = vestingConstants;

const actionTypes = {
    BUY_SOLD_DATA_FETCHED: 'BUY_SOLD_DATA#FETCHED',
    BUY_SOLD_DATA_SUCCEEDED: 'BUY_SOLD_DATA#SUCCEEDED',
    BUY_SOLD_DATA_FAILED: 'BUY_SOLD_DATA#FAILED',
    BUY_APPROVE_FETCHED: 'BUY_APPROVE#FETCHED',
    BUY_APPROVE_SUCCEEDED: 'BUY_APPROVE#SUCCEEDED',
    BUY_APPROVE_FAILED: 'BUY_APPROVE#FAILED',
    BUY_ENABLE_INVOKED: 'BUY_ENABLE#INVOKED',
    BUY_TX_SUCCEEDED: 'BUY_TX#SUCCEEDED',
    BUY_STATE_UPDATED: 'BUY_STATE_UPDATED',
    BUY_UI_ACTIONS_INVOKED: 'BUY_UI_ACTIONS#INVOKED',
    BUY_INVOKED: 'BUY#INVOKED'
};

const stateUpdate = (state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach(k => state.buy[k] = payload[k]);
};

const uiHandle = (state, payload) => {
    const { name, value } = payload;
    switch (name) {
    case 'buying':
        state.buy.buying = value ? parseFloat(value) : '';
        state.buy.selling = value ? value / 10 : '';
        break;
    case 'selling':
        state.buy.selling = value ? parseFloat(value) : '';
        state.buy.buying = value ? value * 10 : '';
        break;
    case 'buy-action':
        state.buy.buyFormInvoked = false;
        break;
    case 'cancel-buy':
        state.buy.buyFormInvoked = false;
        break;
    case 'start-buy':
        state.buy.buyFormInvoked = true;
        state.buy.selling = '';
        state.buy.buying = '';
        break;
    default:
        return;
    }
};

const initialHandle = (actions, payload) => {
    const { url, method } = API;

    serviceUtils.HttpService({
        url,
        method,
        errorActionType: actions[actionTypes.BUY_SOLD_DATA_FAILED],
        successActionType: actions[actionTypes.BUY_SOLD_DATA_SUCCEEDED]
    });
};

const approveFetch = (actions, payload) => {
    const { userWallet } = payload;
    const { method, url } = APPROVE_API;

    serviceUtils.HttpService({
        url: `${url}${userWallet}`,
        method,
        errorActionType: actions[actionTypes.BUY_APPROVE_FAILED],
        successActionType: actions[actionTypes.BUY_APPROVE_SUCCEEDED]
    });
};

const approveSuccess = (state, payload) => {
    const { response } = payload;

    state.buy.approved = response;
};

const approveFailed = (state, payload) => {};

const successHandle = (state, payload) => {
    const { response: { maxSupply, totalAvaible } } = payload;
    
    state.buy.maxSupply = maxSupply;
    state.buy.totalAvailable = totalAvaible;
    state.buy.sold = state.buy.maxSupply - state.buy.totalAvailable;
    state.buy.range = (state.buy.maxSupply - state.buy.totalAvailable) / state.buy.maxSupply * 100;
};

const errorHandle = (state, payload) => {
    console.info(payload);
};

const txSuccess = async (actions, payload) => {
    const { hash, userWallet } = payload;
    const { updateState } = actions;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const status = await provider.waitForTransaction(hash);

    if(status.status) {
        updateState({ path: 'buy.loader', value: false });
        actions[buyEffects.actionTypes.BUY_APPROVE_FETCHED]({ userWallet });
        actions[buyEffects.actionTypes.BUY_SOLD_DATA_FETCHED]();
    }
    else {
        updateState({ path: 'buy.loader', value: false });
    }
};

const doEnable = async (actions, payload) => {
    const { userWallet, usdtAddress, contractAddress } = payload;
    const { updateState } = actions;

    const params = dataHelper.getApproveParameters(userWallet, usdtAddress, contractAddress);

    const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
    actions[actionTypes.BUY_TX_SUCCEEDED]({ hash: result, userWallet });
    updateState({ path: 'buy.loader', value: true });
};

const enableInvoked = (actions, payload) => {
    const { chain, usdtEnabled } = payload;

    usdtEnabled ? actions[actionTypes.BUY_UI_ACTIONS_INVOKED]({ name: 'start-buy' }) :
        serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doEnable(actions, payload) : console.log('Error'); });
};

const doBuy = async (actions, payload) => {
    const { userWallet, contractAddress, selling } = payload;

    const transactionParameters = dataHelper.getBuyParameters(userWallet, contractAddress, selling);

    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const buyHandle = (actions, payload) => {
    const { chain } = payload;
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doBuy(actions, payload) : console.log('Error'); });
};

const actionHandlers = {
    [actionTypes.BUY_SOLD_DATA_FETCHED]: thunk(async (action, payload) => initialHandle(action, payload)),
    [actionTypes.BUY_SOLD_DATA_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.BUY_SOLD_DATA_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.BUY_APPROVE_FETCHED]: thunk(async (action, payload) => approveFetch(action, payload)),
    [actionTypes.BUY_APPROVE_SUCCEEDED]: action((state, payload) => approveSuccess(state, payload)),
    [actionTypes.BUY_APPROVE_FAILED]: action((state, payload) => approveFailed(state, payload)),
    [actionTypes.BUY_ENABLE_INVOKED]: thunk((actions, payload) => enableInvoked(actions, payload)),
    [actionTypes.BUY_TX_SUCCEEDED]: thunk(async (actions, payload) => txSuccess(actions, payload)),
    [actionTypes.BUY_UI_ACTIONS_INVOKED]: action((state, payload) => uiHandle(state, payload)),
    [actionTypes.BUY_STATE_UPDATED]: action((state, payload) => stateUpdate(state, payload)),
    [actionTypes.BUY_INVOKED]: thunk(async (action, payload) => buyHandle(action, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const buyEffects = {
    actionTypes,
    actionHandlers
};