import { createContextStore } from 'easy-peasy';
import { remakeDashboardEffects } from '../pages/remakeDashboardPage/actions/remakeDashboardEffects';
import { remakeDashboardFactory } from '../pages/remakeDashboardPage/actions/remakeDashboardFactory';
import { uiActions } from '../pages/remakeDashboardPage/actions/uiActions';

const REMAKE_DASHBOARD_MODEL = {
    btcPrice: 0,
    defi: {
        show: false,
        balance: 0,
        invested: 0,
        available: 0,
        change: '',
        top4assets: [],
    },
    cefi: {
        cefi_balances: false,
        show: false,
        balance: 0,
        invested: 0,
        available: 0,
        totalBTC: 0,
        change: '',
        top4assets: [],
    },
    total: {
        invested: '',
        available: '',
        total: ''
    },
    lending: {
        show: false,
        borrowLimitUsed: '',
        maxAllowed: '',
        netApy: '',
        venusNetApy: '',
        tokens: [],
        supplied: [],
        borrowed: [],
        suppliedBalance: '',
        borrowedBalance: ''
    },
    accounts: [],
    showYields: false,
    showVaults: false,
    showBots: false,
    yields: [],
    vaults: [],
    exchanges: [],
    availableAssets: [],
    walletAssets: {
        totalUSD: '',
        totalBTC: '',
        assets: [],
        loader: false,
    },
    loaders: {
        defi: false,
        cefi: false,
        vaults: false,
        yields: false,
        bots: false,
        lending: false,
    },
    isLoaded: false,
    imageHeight: false,
    isModalOpen: false,
    typeOfFetch: 'set',
    ...remakeDashboardFactory.actionHandlers,
    ...remakeDashboardEffects.actionHandlers,
    ...uiActions.actionHandlers
};

export const RemakeDashboardState = createContextStore(REMAKE_DASHBOARD_MODEL);