import { isEmpty, orderBy } from 'lodash';
import { utils } from '../../../../utils/utils';


const sortingHelper = (data, sortValue) => {
    const botsByid = data.filter(d => d.id === sortValue.id);
    const bots = data.filter(d => d.id !== sortValue.id);
    return [...orderBy(botsByid, ['startDate'], 'desc'),
        ...orderBy(bots, ['startDate'], 'desc')];
};

const sorting = state => {
    const sortValue = state.sortBy;
    const filterValue = state.filterBy;
    const data = state.originalData.filter(d => filterValue.id ? d.id === filterValue.id : true);
    return sortValue.id === 'startDate' ?
        orderBy(data, ['startDate'], 'desc') :
        sortingHelper(data, sortValue);
};

const parseData = data => {

    if (isEmpty(data))
        return [];

    const ids = Object.keys(data);
    return ids.reduce((acc, id) => {
        const d = data[id];
        const m = d.originalModel;
        acc[id] = {};
        acc[id].botId = id;
        acc[id].startDate = d.createdAt;
        acc[id].exchange = m.exchange;
        acc[id].id = m.botType.toLowerCase();
        acc[id].label = m.botType.replace('_', ' ').toUpperCase();
        acc[id].pairLabel = utils.symbolSplit(m.data.symbol);
        acc[id].pairId = (acc[id].pairLabel.split('/')[0] || '').toLowerCase();
        acc[id].balance = d.balance;
        acc[id].balance30d = d.balance30d;
        acc[id].status = d.status;
        acc[id].change = 0;
        acc[id].model = d;
        return [...acc, acc[id]];
    }, []);
};

export const dataHelper = {
    sorting,
    parseData
};