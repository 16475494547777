import { createContextStore } from 'easy-peasy';
import { exchangeEffects } from '../pages/connectedPlatformsPage/actions/exchangeEffects';


const CONNECTED_PLATFORM_MODEL = {
    loader: {},
    exchangesList: [],
    isModalOpen: false,
    exchangeSynchronisation: 0,
    ...exchangeEffects.actionHandlers
};

export const ConnectedPlatformsModel = createContextStore(CONNECTED_PLATFORM_MODEL);