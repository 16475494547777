import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../service/serviceUtils';
import { priceDataHelper } from './dataHelpers/priceDataHelper';
import { exchangesDataHelper } from './dataHelpers/exchangesDataHelper';
import { uniq, size, isEmpty } from 'lodash';
import { constants } from '../../constants/constants';
import { notifyEffects } from '../utility/notifications/notifyEffects';
import { dataHelper } from '../../pages/dashboard/widgets/bots-widget/dataHelper';
import { pollerEffects } from './pollerEffects';
import { barChartDataHelper } from './dataHelpers/barChartDataHelper';

const { FAVORITES: { preinstalledCoins }, WEBSOCKET_PARAMS_LIMIT, THEME, EXCHANGE_ICONS } = constants;

const REQUEST_URL = '/dashboard';
const REQUEST_URL_EXCHANGES = '/aexsapis';

const actionTypes = {
    DASHBOARD_DATA_REQUESTED: 'DASHBOARD_DATA#REQUESTED',
    EXCHANGES_LIST_REQUESTED: 'EXCHANGES_LIST#REQUESTED',
    DASHBOARD_DATA_FETCH_SUCCEED: 'DASHBOARD_DATA_FETCH#SUCCEED',
    DASHBOARD_DATA_FETCH_FAILED: 'DASHBOARD_DATA_FETCH#FAILED',
    EXCHANGES_LIST_FETCH_SUCCEED: 'EXCHANGES_LIST_FETCH#SUCCEED',
    EXCHANGES_LIST_FETCH_FAILED: 'EXCHANGES_LIST_FETCH#FAILED'
};

const fetchAction = action => {
    serviceUtils.HttpService({
        url: REQUEST_URL,
        errorActionType: action[actionTypes.DASHBOARD_DATA_FETCH_FAILED],
        successActionType: action[actionTypes.DASHBOARD_DATA_FETCH_SUCCEED]
    });
};

const fetchExcangesList = action => {
    serviceUtils.HttpService({
        url: REQUEST_URL_EXCHANGES,
        errorActionType: action[actionTypes.EXCHANGES_LIST_FETCH_FAILED],
        successActionType: action[actionTypes.EXCHANGES_LIST_FETCH_SUCCEED]
    });
};

const errorAction = state => {
    state.dashboard.loading = false;
    const notification = { type: 'danger', text: 'Main data fetch failed' };
    notifyEffects.notifyAction(notification);
};

const successAction = (state, payload) => {
    const { response } = payload;

    state.authentication.interceptorIsSet = true;

    const { exchanges, favoriteAssets, bots, feeds, signals, user:{ settings: { theme, language }, packs }, messages } = response;

    const filteredExchanges = exchangesDataHelper.filteringExchanges(exchanges); //Remove coins with amount 0 exept BTC and USDT

    const exchangesWithOldTotals = exchangesDataHelper.getOldTotals(filteredExchanges);

    const { updatedExchanges, usedCoins } = exchangesDataHelper.updateExchanges({ ...exchangesWithOldTotals });

    const limitedExchangesUsedCoins = usedCoins.splice(0, WEBSOCKET_PARAMS_LIMIT);

    const coins = uniq([...preinstalledCoins, ...favoriteAssets, ...limitedExchangesUsedCoins]);
    const params = priceDataHelper.getPairsForRequest(coins);

    /**
     * If we have only one exchange set as default exchange it, and avoid all_exchanges
     * here we save in state the id of this one exchange
     * In widgets we hide in list all_exchanges
     */
    if (size(updatedExchanges) === 2 && size(updatedExchanges) > 0) {
        const keys = Object.keys(updatedExchanges).filter(k => k !== 'all_exchange');
        state.tableWidget.exchange = { id: keys[0], label: updatedExchanges[keys[0]].name };
    }

    state.root.theme = theme || THEME.DAY; // update saving theme
    state.root.language = language || 'fra';
    state.packs = packs;

    state.coinData.exchanges = updatedExchanges; //Object
    state.coinData.exchangesAllUsedCoins = usedCoins;
    state.coinData.limitedExchangeUsedCoins = limitedExchangesUsedCoins;
    state.coinData.requestParams = params;
    state.coinData.history = exchanges;
    state.coinData.favorites = uniq([...preinstalledCoins, ...favoriteAssets]);



    state.feed.data = feeds;

    state.signal.data = signals;

    state.botsWidget.originalData = dataHelper.parseData(bots);
    state.botsWidget.mutableData = state.botsWidget.originalData;

    state.barChartWidget = barChartDataHelper.parseData(exchanges);

    state.messages.messageData = messages;

    state.loaders.main = false;
    state.loaders.signals = false;
    state.loaders.bots = false;
    state.loaders.feeds = false;
    state.loaders.history = false;

    const notification = { type: 'success', text: 'Main data fetched' };
    notifyEffects.notifyAction(notification);

    const { sendJsonMessage } = state.socket.methods;
    pollerEffects.pollerStart(params, sendJsonMessage); // initiate poller for websocket

};

const successExListAction = (state, payload) => {
    const { response } = payload;
   const mergedArr = response.map((item, i) => ({...item, ...EXCHANGE_ICONS[i]}) )
   state.availableExchangesList = mergedArr;

    const notification = { type: 'success', text: 'Fetch of available Exchanges list succeed' };
    notifyEffects.notifyAction(notification);
};

const errorExListAction = () => {
    const notification = { type: 'danger', text: 'Fetch of available Exchanges list failed' };
    notifyEffects.notifyAction(notification);
};

const actionHandlers = {
    [actionTypes.DASHBOARD_DATA_REQUESTED]: thunk( async (action, payload) => fetchAction(action, payload)),
    [actionTypes.DASHBOARD_DATA_FETCH_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.DASHBOARD_DATA_FETCH_SUCCEED]: action((state, payload) => successAction(state, payload)),
    [actionTypes.EXCHANGES_LIST_REQUESTED]: thunk( async (action, payload) => fetchExcangesList(action, payload)),
    [actionTypes.EXCHANGES_LIST_FETCH_SUCCEED]: action((state, payload) => successExListAction(state, payload)),
    [actionTypes.EXCHANGES_LIST_FETCH_FAILED]: action((state, payload) => errorExListAction(state, payload)),
};

export const mainEffects = {
    actionTypes,
    actionHandlers,
};