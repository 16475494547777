import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import '../styles.scss';
import { Collapse } from 'react-bootstrap';
import { uiActions } from '../actions/uiActions';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { constants } from '../constants';
import numeral from 'numeral';
import { dataHelper } from '../dataHelpers/dataHelper';
import { useStoreState } from 'easy-peasy';
import { StakeModal } from './stakeModal';
import { investmentEffects } from '../actions/investmentEffects';
import { InvestmentState } from '../../../models/investment-model';
import { utils } from '../../../utils/utils';
import { EnableContent } from './enableContent';
import { PopoverLayout } from '../../../components/utility/popover/view';

const {
  DIGIT_FORMAT,
  SCAN_TOKEN_LINK,
  TIPS: { LIQUIDITY_TIP, MULTIPLIER_TIP },
} = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderRow = (
  data,
  index,
  state,
  rewardedToken,
  actions,
  toggleDetails,
  onHarvest,
  onModalInvoked
) => {
  const {
    lptoken,
    rewardTokens,
    reward_amount,
    reward_price,
    cheffAddress,
    multiplier,
    apr,
    liquidity,
    pid,
    staked_amount,
    staked_price,
    token0_amount,
    token1_amount,
    enabled,
  } = data;
  const onClick = (index) => {
    toggleDetails({ id: index });
  };

  // const farm = dataHelper.getFarm(state.tableData.lpFarms, lptoken.baseData?.address);

  // const { multiplier, apr, liquidity, pid } = farm;
  const testBroj = 10;
  const chevronPosition = (id) =>
    state.tableData.toggled.includes(id)
      ? {
          label: 'Hide Details',
          html: <i className="fa fa-chevron-up ms-2"></i>,
        }
      : {
          label: 'Show Details',
          html: <i className="fa fa-chevron-down ms-2"></i>,
        };

  return (
    <div
      className="custom-table-row table-value-row table-body font-size-14 font-weight-regular"
      key={`body-${index}`}
    >
      <div className="collapse-header">
        <div className="custom-table-body-cell d-inline-flex align-items-center">
          <div className="me-3 d-flex align-items-center">
            <div className="asset-icons-pair">
              <img
                onError={utils.imgErrorHandle}
                src={`../coins-full-library/${lptoken.token0?.symbol.toLowerCase()}.svg`}
                className="asset-icon"
              />
              <img
                onError={utils.imgErrorHandle}
                src={`../coins-full-library/${lptoken.token1?.symbol.toLowerCase()}.svg`}
                className="asset-icon"
              />
            </div>
            {lptoken.token0?.symbol} - {lptoken.token1?.symbol}
          </div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center">
          <div className="text-orange">CORE</div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center">
          <div className="text-orange">Earned</div>
          <div className="font-weight-medium font-inter link-color">
            {reward_amount ? parseFloat(reward_amount).toFixed(6) : 0}
          </div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center">
          <div className="text-orange">APR</div>
          <div className="font-weight-medium font-inter link-color">{apr || '-'}%</div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center">
          <div className="text-orange">Liquidity</div>
          <div className="font-weight-medium font-inter link-color d-flex align-items-center">
            ${liquidity ? numeral(liquidity).format(DIGIT_FORMAT) : '-'}
            <div className="more-info">
              <PopoverLayout
                title={false}
                width={247}
                message={LIQUIDITY_TIP}
                component={<i className="fa-light fa-circle-question"></i>}
              />
            </div>
          </div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center">
          <div className="text-orange">Multiplier</div>
          <div className="font-weight-medium font-inter link-color d-flex align-items-center">
            {multiplier ? multiplier : '-'}x
            <div className="more-info">
              <PopoverLayout
                title={false}
                width={247}
                message={MULTIPLIER_TIP}
                component={<i className="fa-light fa-circle-question"></i>}
              />
            </div>
          </div>
        </div>
        <div className="custom-table-body-cell d-column-flex align-items-center details">
          <div className="d-inline-flex align-items-center details" onClick={() => onClick(index)}>
            <div className="details-button">
              {chevronPosition(index).label} {chevronPosition(index).html}
            </div>
          </div>
        </div>
      </div>
      <div className="collapse-row">
        <Collapse in={state.tableData.toggled.includes(index)}>
          <div className="collapse-content font-size-14 font-weight-regular">
            <div className="collapse-content-wrapper">
              <div className="content-column">
                <div className="stats">
                  <div className="link">
                    Get {lptoken.token0?.symbol}-{lptoken.token1?.symbol} LP
                  </div>
                  <div></div>
                  <div className="link">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={`${SCAN_TOKEN_LINK}${lptoken.baseData?.address}`}
                    >
                      View Contract
                    </a>
                  </div>
                  <div></div>
                  <div className="link">See Pair Info</div>
                  <div></div>
                </div>
              </div>
              {enabled || staked_amount > 0 ? (
                <>
                  <div className="content-column me-5 d-flex-column">
                    <div className="position-card p-3 harvest-block align-items-start">
                      <div className="profit">
                        {rewardTokens.map((token) => (
                          <div className="mb-3" key={token.symbol}>
                            <span className="warning-color">{token.symbol}</span> Earned
                          </div>
                        ))}
                      </div>
                      <div></div>
                      <div className="profit">
                        {rewardTokens.map((token) => (
                          <div key={token.symbol}>
                            <div className="font-weight-medium">
                              {parseFloat(reward_amount).toFixed(6)}
                              {token.symbol}
                            </div>
                            <div className="font-weight-medium">
                              ~ ${parseFloat(reward_price).toFixed(2)}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="boost d-flex align-items-start">
                        <div className="mb-3"></div>
                        <div className="font-weight-medium"></div>
                        <div className="w-100 d-grid">
                          <ButtonCustom
                            onClick={() => onHarvest(pid, state.chain, cheffAddress)}
                            variant="primary"
                            label="Harvest"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-column d-flex-column">
                    <div className="position-card p-3 harvest-block align-items-start">
                      <div className="profit">
                        <div className="mb-3">
                          <span className="warning-color">
                            {lptoken.token0?.symbol} - {lptoken.token1?.symbol} LP
                          </span>{' '}
                          STAKED
                        </div>
                      </div>
                      <div></div>
                      {staked_amount > 0 ? (
                        <>
                          <div className="profit">
                            <div className="font-weight-medium">
                              {numeral(staked_amount).format('0,00.000000')}
                            </div>
                            <div className="font-weight-medium">
                              ~ ${numeral(staked_price).format(DIGIT_FORMAT)}
                            </div>
                            <div className="font-weight-medium">
                              <span className="pe-2">
                                {numeral(token0_amount).format(DIGIT_FORMAT)}{' '}
                                {lptoken.token0?.symbol}
                              </span>
                              <span>
                                {numeral(token1_amount).format(DIGIT_FORMAT)}{' '}
                                {lptoken.token1?.symbol}
                              </span>
                            </div>
                          </div>
                          <div className="boost d-flex align-items-start">
                            <div className="mb-3"></div>
                            <div className="font-weight-medium"></div>
                            <div className="w-100 d-grid pe-2">
                              <ButtonCustom
                                onClick={() =>
                                  onModalInvoked('unstake-modal', 'open-modal', {
                                    unstakeAmount: staked_amount,
                                    token0: lptoken.token0?.symbol,
                                    token1: lptoken.token1?.symbol,
                                    pid,
                                  })
                                }
                                variant="primary"
                                label={<i className="fa-light fa-minus"></i>}
                              />
                            </div>
                            <div className="w-100 d-grid">
                              <ButtonCustom
                                onClick={() =>
                                  onModalInvoked('stake-modal', 'open-modal', {
                                    stakeAmount: state.stakedBalance,
                                    token0: lptoken.token0?.symbol,
                                    token1: lptoken.token1?.symbol,
                                    pid,
                                    lpAddress: lptoken.baseData?.address,
                                    chain: state.chain,
                                    cheff: cheffAddress,
                                  })
                                }
                                variant="primary"
                                label={<i className="fa-light fa-plus"></i>}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="boost d-flex align-items-start">
                          <div className="mb-3"></div>
                          <div className="font-weight-medium"></div>
                          <div className="w-100 d-grid">
                            <ButtonCustom
                              onClick={() =>
                                onModalInvoked('stake-modal', 'open-modal', {
                                  stakeAmount: state.stakedBalance,
                                  token0: lptoken.token0?.symbol,
                                  token1: lptoken.token1?.symbol,
                                  pid,
                                  lpAddress: lptoken.baseData?.address,
                                  chain: state.chain,
                                  cheff: cheffAddress,
                                })
                              }
                              variant="primary"
                              label="Stake LP Token"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <EnableContent
                  cheff={cheffAddress}
                  chain={state.chain}
                  lpTokenAddress={lptoken.baseData?.address}
                  rewardAmount={reward_amount}
                  rewardPrice={reward_price}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

const renderTableBody = (
  data,
  state,
  rewardedToken,
  actions,
  toggleDetails,
  onHarvest,
  onModalInvoked,
  searchData
) =>
  data
    .filter((item) => {
      return searchData === '' ? item : item?.lptoken?.baseData?.address?.includes(searchData);
    })
    .map((d, index) =>
      renderRow(d, index, state, rewardedToken, actions, toggleDetails, onHarvest, onModalInvoked)
    );

const renderLoader = () => {
  return (
    <div className="loader text-center" key={0}>
      <span>
        <i className="fa fa-refresh fa-spin"></i>
        <span className="ms-3">Loading...</span>
      </span>
    </div>
  );
};

export const CustomCollapsibleTableTemplate = (props) => {
  const { state, toggleDetails, searchData } = props;
  const actions = InvestmentState.useStoreActions((actions) => actions);
  const tableData = state.tableData;
  const rewardedToken = state.tableData.rewardedToken;
  const data = tableData.filteredData;
  const userWallet = useStoreState((state) => state.authentication.userData.userId);

  const onHarvest = (pid, chain, contract) => {
    actions[investmentEffects.actionTypes.FARMS_REWARD_HARVEST_INVOKED]({
      pid,
      from: userWallet,
      chain,
      cheff: contract,
    });
  };

  const onModalInvoked = (name, modal, data) =>
    actions[uiActions.actionTypes.FARMS_MODAL_INVOKED]({ name, modal, data });
  const isLoading = state.loader;
  const loadFunc = (value) =>
    actions[uiActions.actionTypes.FARMS_TABLE_LOAD_MORE_LOADING]({
      page: value,
      state,
    });

  const ref = useRef();
  return (
    <div className="table-collapsible font-inter" ref={ref}>
      <div className="custom-table h-100">
        <div className="scroll-container">
          {isLoading ? (
            <Loader size={'6x'} />
          ) : !isEmpty(tableData.filteredData) ? (
            <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                loadMore={loadFunc}
                hasMore={tableData.pagination.hasMore}
                loader={renderLoader()}
                useWindow={false}
                getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)}
              >
                {renderTableBody(
                  tableData.filteredData,
                  state,
                  rewardedToken,
                  actions,
                  toggleDetails,
                  onHarvest,
                  onModalInvoked,
                  searchData
                )}
              </InfiniteScroll>
            </CustomScroll>
          ) : (
            <EmptySearchState />
          )}
        </div>
      </div>
      <StakeModal state={state} show={state.modal.show} actions={actions} />
    </div>
  );
};
