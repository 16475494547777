import React, { useEffect } from 'react';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import {constants} from '../constants';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { dataHelper } from '../actions/dataHelpers/dataHelper';
import { isEmpty } from 'lodash';
import { selectorsEffects } from '../actions/selectorsEffects';
import { debug } from 'easy-peasy';

const { CONTROLS: {LABEL_EXCHANGES, LABEL_COIN_MAIN, LABEL_COIN_PAIR}} = constants;


const renderSelectors = (exchanges, mode, actions, state, singleData) => {
    const accountHandle = value => actions[selectorsEffects.actionTypes.SMART_TRADE_SELECTORS_CHANGED]({name: LABEL_EXCHANGES, value});
    const mainCoinHandle = value => actions[selectorsEffects.actionTypes.SMART_TRADE_SELECTORS_CHANGED]({name: LABEL_COIN_MAIN, value});
    const pairCoinHandle = value => actions[selectorsEffects.actionTypes.SMART_TRADE_SELECTORS_CHANGED]({name: LABEL_COIN_PAIR, value});
    return <div className="choice-selectors-grid">
        <div className="choice-selectors-item">
            <div className="choice-selectors-label font-size-14 pb-1">{LABEL_EXCHANGES}</div>
            <MultiplyCustomSelector
                onChangeHandle={accountHandle}
                isMulti={false}
                initialValue={exchanges[0]}
                isLoading={isEmpty(exchanges)}
                closeMenuOnSelect={true}
                options={exchanges}/>
        </div>
        { !mode ?
            <div className="choice-selectors-item">
                <div className="choice-selectors-label font-size-14 pb-1">{LABEL_COIN_MAIN}</div>
                <MultiplyCustomSelector
                    onChangeHandle={mainCoinHandle}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    value={singleData.mainCoinValue}
                    options={state.mainCoinList}/>
            </div> : <div></div> }
        { !mode ?
            <div className="choice-selectors-item">
                <div className="choice-selectors-label font-size-14 pb-1">{LABEL_COIN_PAIR}</div>
                <MultiplyCustomSelector
                    onChangeHandle={pairCoinHandle}
                    isDisabled={!singleData.mainCoin}
                    isMulti={false}
                    value={singleData.pairCoinValue}
                    closeMenuOnSelect={true}
                    options={state.pairCoinList}/>
            </div> : <div></div> }
    </div>
};

export const SelectorsSetTemplate = props => {
  const {exchanges, mode} = props;
  const actions = SmartTradeState.useStoreActions(actions => actions);
  const assets = SmartTradeState.useStoreState(state => state.assetsData);
  const state = SmartTradeState.useStoreState(state => state.packs);
  const singleData = SmartTradeState.useStoreState(state => state.singlePair);
  const exchangesList = dataHelper.parseExchanges(exchanges);
  useEffect(() => {
      actions[selectorsEffects.actionTypes.SMART_TRADE_SELECTORS_CHANGED]({name: 'init_exchange', exchange: exchangesList[0]});
  }, [assets]);

  return renderSelectors(exchangesList, mode, actions, state, singleData);
};
         