import React from 'react';
import Card from 'react-bootstrap/Card';
import { constants } from '../../../../constants';

const { PLANS_CONTENT: { EXPERT_CARD: { HEADER: { TOPIC }, CONTENT, FOOTER } } } = constants;

export const ExpertPlanCard = () => {
    return (
        <Card className='expert-plan-card font-inter'>
            <Card.Body>
                <Card.Header className='align-items-center'>
                    <div className='card-header-column'>
                        <p className='card-header-topic font-inter font-size-26'>{TOPIC}</p>
                    </div>
                </Card.Header>
                <div className='expert-price'>
                    <img className='img-fluid' src='../dashboard-library/home-page-images/expertPrice.svg' />
                </div>
                <div className='card-content'>
                    {CONTENT.map((item, index) => <div key={index} className='card-content-row'>
                        <div className='card-content-row-left d-flex align-items-center font-style-italic text-nowrap'>
                            <img className='me-3' src='../dashboard-library/home-page-images/cardIcon.svg'/>{item.label}</div>
                        <div className='card-content-row-right align-items-center'>{item.amount}</div></div>)}
                </div>
                <div className='expert-price'>
                    <img className='img-fluid' src='../dashboard-library/home-page-images/expertAvatar.svg' />
                </div>
                <div className='card-footer font-size-25 font-inter d-flex justify-content-center align-items-center'>
                    {FOOTER}
                </div>
            </Card.Body>
        </Card>
    );
};
