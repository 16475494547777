import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { isEmpty } from 'lodash';
import { profileEffects } from './profileEffects';

const actionTypes = {
  PROFILE_MODAL_INVOKED: 'PROFILE_MODAL#INVOKED',
  WALLET_MODAL_INVOKED: 'WALLET_MODAL#INVOKED',
  ADD_WALLET_INVOKED: 'ADD_WALLET#INVOKED',
  MENU_HANDLE_INVOKED: 'MENU_HANDLE#INVOKED',
  PROFILE_EDIT_INVOKED: 'PROFILE_EDIT#INVOKED',
};

const modalHandle = (actions, payload) => {

    const { name, modal, id, cardId } = payload;
    const { updateState } = actions;
    if(modal === 'NEW PROFILE' || modal === 'DELETE' || modal === 'EDIT') {
      
      
        switch(name) {
        case 'open-modal': updateState({ path: 'isModalOpen', value: true }); break;
        case 'close-modal': updateState({ path: 'isModalOpen', value: false }); break;
        case 'open-edit-modal': updateState({path: 'isEditModalOpen', value: true}); break;
        case 'close-edit-modal': updateState({path: 'isEditModalOpen', value: false}); break;
        }
        switch (modal) {
         case 'NEW PROFILE': updateState({path: 'modalTitle', value: 'NEW PROFILE'}); break;
         case 'DELETE': updateState({path: 'modalTitle', value: 'DELETE', });break;      
         case 'EDIT': updateState({path: 'modalTitle', value: 'EDIT'}); break;
        }
       switch (modal){
        case 'DELETE': updateState({path: 'walletId', value: id})
          updateState({path: 'deleteId', value: cardId})
        // console.log('data.id: ', id)

        break;
    }
    switch (modal) {
      case 'NEW PROFILE':
        updateState({ path: 'modalTitle', value: 'NEW PROFILE' });
        break;
      case 'DELETE':
        updateState({ path: 'modalTitle', value: 'DELETE' });
        break;
      case 'EDIT':
        updateState({ path: 'modalTitle', value: 'EDIT' });
        break;
    }
    switch (modal) {
      case 'DELETE':
        updateState({ path: 'walletId', value: id });
        break;
    }
  }
};

const modalWalletHandle = (actions, payload) => {
  const { name, modal, id } = payload;
  const { updateState } = actions;

  if (modal === 'ADD WALLET') {
    switch (name) {
      case 'open-modal':
        updateState({ path: 'isWalletModalOpen', value: true });
        updateState({ path: 'indexForSubmit', value: id });
        break;
      case 'close-modal':
        updateState({ path: 'isWalletModalOpen', value: false });
        break;
    }
  }
};

// waiting for api
const addWalletHandle = (actions, payload) => {
  const { method, data, id, action, cardId, cardID } = payload;

  switch (method) {
    case 'post':
      action[profileEffects.actionTypes.WALLET_NEW_WALLET_INVOKED]({
        method: 'add',
        values: data,
        id: id,
        cardId: cardId,
        cardID: cardID,
      });
      break;
  }
};

const menuHandle = (actions, payload) => {
  const { value, cardId, action, id } = payload;
  const { updateState } = actions;

  if (isEmpty(value)) {
    return;
  }
  switch (value.id) {
    case 'delete':
      action[profileEffects.actionTypes.PROFILE_NEW_PROFILE_INVOKED]({
        values: { cardId },
        method: 'delete',
      });
      break;
    case 'edit':
      action[profileEffects.actionTypes.PROFILE_EDIT_PROFILE_INVOKED]({
        values: { id },
        method: 'edit',
      });
      break; //updateState({ path: 'isEditModalOpen', value: true }); break;
  }
};

const actionHandlers = {
  [actionTypes.PROFILE_MODAL_INVOKED]: thunk((actions, payload) => modalHandle(actions, payload)),
  [actionTypes.WALLET_MODAL_INVOKED]: thunk((actions, payload) =>
    modalWalletHandle(actions, payload)
  ),
  [actionTypes.ADD_WALLET_INVOKED]: thunk((actions, payload) => addWalletHandle(actions, payload)),
  [actionTypes.MENU_HANDLE_INVOKED]: thunk((actions, payload) => menuHandle(actions, payload)),
  [actionTypes.PROFILE_EDIT_INVOKED]: thunk((actions, payload) => modalHandle(actions, payload)),
  updateState: action((state, payload) => utils.stateHelper(state, payload)),
};

export const profilePageActions = {
  actionTypes,
  actionHandlers,
};
