import { constants } from '../../constants';
import { isEmpty } from 'lodash';

const { PACKS: { PACKS_FACTORY: { PACK_ADD } } } = constants;

const _reduceData = data => data.reduce((acc, p) => {
  const obj = {};
  obj[p.value] = 1;
  return {...acc, ...obj};
},{});

const packAddRequest = payload => {
  const {mainCoin, pairs, name } = payload;
  const assets = _reduceData(pairs);
  return {...PACK_ADD, data: {name, main: mainCoin, assets, exchange: payload?.exchange?.value?.value?.accountId}, operation: 'add_pack'};
};

const populatePacksList = (payload, exchangeId) => {

  if (isEmpty(payload) || isEmpty(exchangeId))
    return [];

  const currentPacks = payload[exchangeId] || {};
  const ids = Object.keys(currentPacks);
  return ids.reduce((acc, id) => {
    const { main, name, assets } = currentPacks[id];
    const obj = {
      id,
      mainCoin: main,
      weight: false,
      pairs: Object.keys(assets || {}),
      name
    };
    return [...acc, obj];
  }, []);
};

const createSelectedSinglePair = payload => {
  const { pair, mainCoin, exchangeData} = payload;
  return {id: pair, mainCoin, isSinglePair: true, selectedPack: {mainCoin, weight: false}, exchangeData};
};

export const packHelper = {
  packAddRequest,
  populatePacksList,
  createSelectedSinglePair
};