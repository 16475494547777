const uiAction = (id, list, _, actionName, appendToPayload) => {
    // console.log('usao u uiaction')
    if (!actionName)
        return;

    const action = _[actionName];
    const value = list.find(e => e.id === id) || {};
    action({ value, actionName, ...appendToPayload });
};

const isOpenAction = (value, _, actionName) => {

    if (!actionName)
        return;

    const action = _[actionName];
    action({isOpen: value, actionName});
};

export const dropDownEffects = {
    uiAction,
    isOpenAction
};