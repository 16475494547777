import { action, thunk } from 'easy-peasy';
import { array } from 'yup';
import { serviceUtils } from '../../../service/serviceUtils';
import { utils } from '../../../utils/utils';
import { constants_allowance } from '../constants'

const { REQUEST_URL } = constants_allowance;

const actionTypes = {
    SMART_CONTRACT_ALLOWANCE_FETCHED: 'SMART_CONTRACT_ALLOWANCE#FETCHED',
    SMART_CONTRACT_ALLOWANCE_FETCH_SUCCEEDED: 'SMART_CONTRACT_ALLOWANCE_FETCH#SUCCEEDED',
    SMART_CONTRACT_ALLOWANCE_FETCH_FAILED: 'SMART_CONTRACT_ALLOWANCE_FETCH#FAILED',
    SMART_CONTRACT_ALLOWANCE_REVOKED: 'SMART_CONTRACT_ALLOWANCE#REVOKED',
    SMART_CONTRACT_ALLOWANCE_REVOKE_ALL: 'SMART_CONTRACT_ALLOWANCE#REVOKE_ALL',
};

const fetchHandle = (actions, payload) => {
    serviceUtils.HttpService({
        url: 'https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/approvetnx',
        method: 'POST',
        data: payload,
        errorActionType: actions[actionTypes.SMART_CONTRACT_ALLOWANCE_FETCH_FAILED],
        successActionType: actions[actionTypes.SMART_CONTRACT_ALLOWANCE_FETCH_SUCCEEDED]
    });
};

const successHandle = (state, payload) => {
    const { response: { approveTransactions } } = payload;
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.data = [...state.tableData.data, ...approveTransactions];
    else
        state.tableData.data = approveTransactions;

    state.tableData.filteredData = state.tableData.data.filter(allowance => +allowance.amount > 0 || allowance.amount === 'Infinity');
    //state.tableData.filteredData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    state.tableData.pagination.hasMore = approveTransactions.length < 50 ? false : true;
    state.buttonShowRevokeAll = state.tableData.data.length > 0;
};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.tableData.data = state.typeOfFetch === 'push' ? state.tableData.data : [];
    state.tableData.pagination.hasMore = true;
};

const getRevokeParams = (fromAddress, assetAddress, allowForAddress) => {
    const method = '0x095ea7b3';
    const data = `${method}${utils.stringCreator('0', 24)}${allowForAddress.slice(2)}${utils.stringCreator('0', 64)}`;

    const params = {
        to: assetAddress,
        from: fromAddress,
        value: '0x0',
        data,
    };

    return params;
};

const doRevoke = async (actions, payload) => {
    const { data: { asset, contract }, userId } = payload;

    const params = getRevokeParams(userId, asset.address, contract.address);
    await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
};

const doRevokeAll = async (actions, payload) => {
    const { state: { tableData: { filteredData } }, userId } = payload;

    filteredData.forEach(rowData => {
        const params = getRevokeParams(userId, rowData.asset.address, rowData.contract.address);
        window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
    });
};

const revokeHandle = async (actions, payload) => {
    const { data: { chain } } = payload;
    
    serviceUtils.metamaskSwitchEthereumChain(chain).then(res => { res.result ? doRevoke(actions, payload) : console.log('metamask user', res.message) });
};

const revokeAllHandle = async (actions, payload) => {
    const { state: { selector: { value: { value } } } } = payload;

    serviceUtils.metamaskSwitchEthereumChain(value).then(res => { res.result ? doRevokeAll(actions, payload) : console.log('metamask user', res.message) });
};

const actionHandlers = {
    [actionTypes.SMART_CONTRACT_ALLOWANCE_FETCHED]: thunk(async (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_REVOKED]: thunk(async (actions, payload) => revokeHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_REVOKE_ALL]: thunk(async (actions, payload) => revokeAllHandle(actions, payload)),
};

export const allowanceEffects = {
    actionTypes,
    actionHandlers
};