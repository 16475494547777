import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { utils } from '../../../utils/utils';

// const REQUEST_URL = '/web3/eth/compound/get-borrowers-by-page';

const actionTypes = {
    DEFI_LIQUIDATOR_FETCHED: 'DEFI_LIQUIDATOR#FETCHED',
    DEFI_LIQUIDATOR_FETCH_SUCCEEDED: 'DEFI_LIQUIDATOR_FETCH#SUCCEEDED',
    DEFI_LIQUIDATOR_FETCH_FAILED: 'DEFI_LIQUIDATOR_FETCH#FAILED',
};

const fetchHandle = (actions, payload) => {
    const { page, pageSize, selector } = payload;
    serviceUtils.HttpService({
        url: `/web3/${utils.getChainLiquidator(selector)}/${selector}/get-borrowers-by-page`,
        method: 'POST',
        data: { page, pageSize },
        errorActionType: actions[actionTypes.DEFI_LIQUIDATOR_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_LIQUIDATOR_FETCH_SUCCEEDED]
    });
};

const successHandle = (state, payload) => {
    const { response: { borrowers, config } } = payload;

    state.loader = false;
    if (state.typeOfFetch === 'push') {
        state.config = config.address.mainnet;
        state.tableData.data = [...state.tableData.data, ...borrowers];
        state.tableData.filteredData = [...state.tableData.filteredData, ...borrowers];
    }
    else {
        state.config = config.address.mainnet;
        state.tableData.data = borrowers;
        state.tableData.filteredData = borrowers;
    }
    
    state.tableData.pagination.hasMore = borrowers.length < 50 ? false : true;
};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.tableData.data = state.typeOfFetch === 'push' ? state.tableData.data : [];
    state.tableData.pagination.hasMore = true;
};

const actionHandlers = {
    [actionTypes.DEFI_LIQUIDATOR_FETCHED]: thunk( async (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
};

export const liquidatorEffects = {
    actionTypes,
    actionHandlers
};