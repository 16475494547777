import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { TableVenusTemplate } from './tables/tableVenusTemplate';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import numeral from 'numeral';
import { dataHelper } from '../dataHelpers/dataHelper';

const { VENUS: { HEADING, GET_COVERED } } = constants;

export const VenusContent = props => {
    const { actions, state } = props;

    const totalBalanceVenus = dataHelper.getVenusSum(state.venus.data);
    const totalBalanceCompaund = dataHelper.getCompoundSum(state.compound.data);
    const totalBalance = totalBalanceVenus + totalBalanceCompaund;

    return <div className="venus-content">
        <div className="venus-section">
            <div className="venus-section-header align-items-center">
                <div className="venus-section-header-left d-flex align-items-center">
                   {/*<img className="me-2" width={22} height={22} src={`../dashboard-library/protocols/${HEADING.toLowerCase()}.svg`} />*/ } 
                    <div className="font-weight-medium me-4">{HEADING}</div>
                    <div></div>
                </div>
                <div></div>
                <div className="venus-balance text-end me-3">
                    <div className="balance-value">${numeral(totalBalance).format('0,00.00')}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <i onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'venus' })}
                        className={`table-toggler fa-solid fa-chevron-${ state.venus.open ? 'up' : 'down' }`}></i>
                </div>
            </div>
            <Collapse in={state.venus.open}>
                <div className="collapse-content">
                    <div className="venus-section-content">
                        <TableVenusTemplate />
                    </div>
                </div>
            </Collapse>
        </div>
    </div>;
};