import React, { useCallback, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { careersEffects } from "../actions/careersEffects";
import { constants } from "../constants";
import { Dropzone } from "./dropzone";
import { CareersState } from "../../../../../models/careers-model";

const {
  MODAL: { NAME, EMAIL, SEND },
} = constants;
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
});

 const RECAPTCHA_SITE_KEY = '6Lf5ynQiAAAAAA0xMp4ZOl4PfMQyt2UAjvPh6DXo';

export const ApplyForm = (props) => {
  const { actions } = props;
  const recaptchaTokenValid = CareersState.useStoreState(
    (state) => state.recaptchaTokenValid
  );

  const submitHandler = (values) => {
    actions[careersEffects.actionTypes.CAREERS_APPLY_INVOKED]({
      ...values,
      file: { base64: images[0].src, filename: images[0].filename },
    });
    props.closeHandler;
  };

  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { src: e.target.result, filename: file.path },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  const handleRemove = () => {
    setImages([]);
  };

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(RECAPTCHA_SITE_KEY, { action: "careersPage" })
        .then((token) => {
          actions[careersEffects.actionTypes.TOKEN_INVOKED](token);
        });
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  return (
    <Formik
    
      validationSchema={schema}
      initialValues={{
        name: "",
        email: "",
      }}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, handleChange, values, isValid, dirty, errors }) => (
        <Form noValidate onSubmit={handleSubmit} className="font-inter ">
          <Form.Group className="pb-4" controlId="controlSelect">
            <Form.Control
              type="text"
              name="name"
              placeholder={NAME}
              value={values.name}
              isInvalid={!!errors.name}
              onChange={handleChange}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="pb-4">
            <Form.Control
              type="email"
              name="email"
              placeholder={EMAIL}
              value={values.email}
              isInvalid={!!errors.email}
              onChange={handleChange}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="pb-4">
            <Dropzone
              onDrop={onDrop}
              images={images}
              handleRemove={handleRemove}
            />
          </Form.Group>

          <Form.Group>
            <div data-sitekey={RECAPTCHA_SITE_KEY} data-size="invisible"></div>
          </Form.Group>

          <Form.Group>
            <div className="submit-button">
              <Button
                type="submit"
                size="lg"
                variant="primary"
                className="font-size-14 font-weight-medium btn-custom w-100"
               
                disabled={
                  !(
                    isValid &&
                    dirty &&
                    images.length !== 0 &&
                    recaptchaTokenValid
                  )
                }
              >
                {SEND}
              </Button>
            </div>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};
