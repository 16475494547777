import React from 'react';
import Toggle from 'react-toggle';
import { constants } from '../../../../../constants/constants';
import { Selector } from '../../../../../components/utility/dropdown-selector/view';
import { StandardOptions } from '../../../../../components/utility/standard-options/view';
import { tableWidgetEffects } from '../tableEffects';
import { AddButton } from '../../../../../components/utility/add-button/view';
import { isEmpty } from 'lodash';

const { TABLE_WIDGET } = constants;
const { currency, addExchange, volume } = TABLE_WIDGET;

const _renderExchangeSelect = (currentValue, list, disabled) => {
    return <div className="exchange-selector dropdown-selector pe-3">
        <div className="title font-size-12 mb-1">{TABLE_WIDGET.select_exchange.title}</div>
        <Selector
            classes=""
            disabled={disabled}
            list={list}
            value={currentValue}
            titleClasses="text-capitalize"
            iconPosition="left"
            actionName={tableWidgetEffects.actionTypes.TABLE_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED}
        />
    </div>;
};

const _renderToggleCurrency = (state, actions) => {
    const currentPosition = Boolean(state.currency !== currency.currencyId[0]);
    return <div className="currency-toggle-selector pe-3">
        <label className="customized-toggle d-flex flex-row">
            <span className="me-1 font-size-12">{currency.labelLeft || ''}</span>
            <Toggle
                icons={false}
                defaultChecked={currentPosition}
                onChange={e => tableWidgetEffects.uiAction('toggle_currency', e.target.checked, actions)}/>
            <span className="ms-1 font-size-12">{currency.labelRight || ''}</span>
        </label>
    </div>;
};

const _renderVolumeToggle = (state, actions) => {
    const currentPosition = Boolean(state.volume !== volume.volumeId[0]);
    return <div className="volume-toggle-selector pe-3">
        <label className="customized-toggle d-flex flex-row">
            <span className="me-1 font-size-12">{volume.labelLeft || ''}</span>
            <Toggle
                icons={false}
                defaultChecked={currentPosition}
                onChange={e => tableWidgetEffects.uiAction('toggle_volume', e.target.checked, actions)}/>
            <span className="ms-1 font-size-12">{volume.labelRight || ''}</span>
        </label>
    </div>;
};

const _renderAddExchange = () => {
    return <AddButton
        iconClass={addExchange.icon}
        size="ssm"
        label={addExchange.label}
        actionName={tableWidgetEffects.actionTypes.TABLE_WIDGET_UI_ACTIONS_ADD_EXCHANGE_CLICKED}
        buttonClass={'add-exchange'}
        labelClass={'ms-2 font-size-12'} />;
};


const _renderControlsOptions = (state, actions, disabled, loading) => {
    return <div className="d-flex flex-row justify-content-end table-standard-options standard-options">
        <div className="me-3">
            { _renderToggleCurrency(state, actions) }
        </div>
        <div className="me-3">
            { _renderVolumeToggle(state, actions) }
        </div>
        <div className="me-3">
            { disabled ? null : _renderAddExchange() }
        </div>
        <StandardOptions
            className="dark"
            refresh={{ type: 'table' }}
            more={{ ...TABLE_WIDGET.STANDARD_OPTIONS_SCHEMA }}
            expand={{ type: 'expand' }}
            isLoading={loading}
        />
    </div>;
};

export const renderControlPanel = (state, actions, exchangeList, loading) => {
    const disabled = isEmpty(exchangeList);
    const currentValue = state.exchange;
    return <div className="table-widget-controls-container d-flex flex-row justify-content-between align-items-top pe-1">
        <div className="d-flex flex-fill donat-chart-controls">
            { _renderExchangeSelect(currentValue, exchangeList, disabled) }
        </div>
        <div className="d-flex flex-row">
            { _renderControlsOptions(state, actions, disabled, loading) }
        </div>
    </div>;
};

