import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { allowanceEffects } from './allowanceEffects';

const actionTypes = {
    SMART_CONTRACT_ALLOWANCE_MULTISELECT_SET: 'SMART_CONTRACT_ALLOWANCE_MULTISELECT#SET',
    SMART_CONTRACT_ALLOWANCE_SEARCH_SET: 'SMART_CONTRACT_ALLOWANCE_SEARCH#SET',
    SMART_CONTRACT_ALLOWANCE_SEARCH_INVOKED: 'SMART_CONTRACT_ALLOWANCE_SEARCH#INVOKED',
    SMART_CONTRACT_ALLOWANCE_LOAD_MORE_LOADING: 'SMART_CONTRACT_ALLOWANCE_LOAD_MORE#LOADING',
    SMART_CONTRACT_ALLOWANCE_REQUESTED: 'SMART_CONTRACT_ALLOWANCE#REQUESTED',
    SMART_CONTRACT_ALLOWANCE_REVOKE_ALL: 'SMART_CONTRACT_ALLOWANCE#REVOKE_ALL'
};

const multiSelectHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value, state: { search, tableData, address } } = payload;
    const requestBody = {
        chain: value.value,
        address,
        filter: search.value || '',
        page: tableData.pagination.page,
        pageSize: tableData.pagination.pageSize
    };
    updateState([
        {
            path: 'selector.value',
            value
        },
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        },
        {
            path: 'tableData.filteredData',
            value: []
        },
        {
            path: 'buttonShowRevokeAll',
            value: false
        },
    ]);
};

const searchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value } = payload;
    updateState({
        path: 'search.value',
        value
    });
};

const searchInvokeHandle = (actions, payload) => {
    const { updateState } = actions;
    const { state: { search, tableData, address, selector } } = payload;
    const requestBody = {
        chain: selector.value.value,
        address,
        filter: search.value || '',
        page: tableData.pagination.page
    };
    updateState([
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        }
    ]);
};

const loadMoreHandle = (actions, payload) => {
    const { updateState } = actions;
    const { page, state: { search, address, selector, tableData } } = payload;

    if (!tableData.pagination.hasMore)
        return;

    const requestBody = {
        chain: selector.value.value,
        address,
        filter: search.value || '',
        page
    };

    updateState([
        {
            path: 'tableData.pagination.page',
            value: page
        },
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'push'
        },
        {
            path: 'tableData.pagination.hasMore',
            value: false
        }
    ]);
};

const fetchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { userId, state: { searchRequest, address, search, selector, tableData, typeOfFetch } } = payload;

    updateState({
        path: 'loader',
        value: typeOfFetch === 'set' ? true : false
    });

    if (searchRequest) {
        actions[allowanceEffects.actionTypes.SMART_CONTRACT_ALLOWANCE_FETCHED](searchRequest);
        return;
    }

    updateState({
        path: 'address',
        value: address || userId
    });

    const requestBody = {
        chain: selector.value.value,
        address: userId,
        filter: search.value || '',
        page: tableData.pagination.page,
        pageSize: tableData.pagination.pageSize
    };
    actions[allowanceEffects.actionTypes.SMART_CONTRACT_ALLOWANCE_FETCHED](requestBody);
};

const actionHandlers = {
    [actionTypes.SMART_CONTRACT_ALLOWANCE_MULTISELECT_SET]: thunk((actions, payload) => multiSelectHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_SEARCH_SET]: thunk((actions, payload) => searchHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_SEARCH_INVOKED]: thunk((actions, payload) => searchInvokeHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_LOAD_MORE_LOADING]: thunk((actions, payload) => loadMoreHandle(actions, payload)),
    [actionTypes.SMART_CONTRACT_ALLOWANCE_REQUESTED]: thunk((actions, payload) => fetchHandle(actions, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};