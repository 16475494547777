import React, {useEffect,useState, useRef} from 'react';
import TradingViewWidget, {Themes} from 'react-tradingview-widget';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { useStoreState } from 'easy-peasy';
import { packHelper } from '../actions/dataHelpers/packHelper';
// import { useLocation } from 'react-router-dom';



export const ChartWidget = ({ defaultPair }) => {
  // const location = useLocation();
  const [active,setActive] = useState(0);
  const ref = useRef();
  const isMultichartActive = SmartTradeState.useStoreState((state) => state.controls.isMultichart);
  // const first = useRef(true);
  const singlePair = SmartTradeState.useStoreState(state => state.singlePair);
  // const isSelected = SmartTradeState.useStoreState(state => state.isSelected);
  const {isSingle} = singlePair;
  const selectedPair = SmartTradeState.useStoreState(state => state.packs.selectedPair);
  const pairsData = defaultPair ? defaultPair : !isSingle ? selectedPair : singlePair.pairData;
  // const pairsData = !isSingle ?
  //     SmartTradeState.useStoreState(state => state.packs.selectedPair) :
  //     singlePair.pairData;
  const theme = useStoreState(state => state.root.theme);
  // const screenHeight = useStoreState(state => state.sizes.values.screenHeight);
  // const isMultiChart = location.search ===  '?elem=multichart';
  const generateOptions = (style) => packHelper.chartOptions(pairsData, theme, Themes, style);
  const optionsStyle = {
    0: 1,
    1: 3,
    2: 5,
    3: 6,
  };

  const initValue = [
    {
      title: 'Chart-1',
      id: 0,
      content: generateOptions(1),
      isSelected: false,
    },
    {
      title: 'Chart-2',
      id: 1,
      content: generateOptions(3),
      isSelected: false,
    },
    {
      title: 'Chart-3',
      id: 2,
      content: generateOptions(5),
      isSelected: false,
    },
    {
      title: 'Chart-4',
      id: 3,
      // content: options4,
      content: generateOptions(6),
      isSelected: false,
    },
  ]

  const [charts, setCharts] = useState(initValue)

  useEffect(() => {
    const _charts = [...charts];
    const item = _charts.find(item => item.id === active)
    // item.content = packHelper.chartOptions(pairsData, theme, Themes, 1);
    item.content = generateOptions(optionsStyle[active]);
    setCharts(_charts);
  }, [pairsData]);

  // useEffect(() => {
  // const height = isMultiChart ? 0 : 300
  //  ref.current.style.height = `${screenHeight - height}px`;
  // }, [screenHeight]);

  return <div ref={ref} className={isMultichartActive ? 'smart-trade-chart-content' : 'smart-trade-chart'} >
    {
    isMultichartActive ? 
      <>
        {
          charts.map((data, i) => {
            return  <div
              key={data.id}
              onClick={() => setActive(data.id)} className={active === i ? 'bg-primary border-inset rounded-top' : 'bg-secondary border-inset rounded-top'}>
            <div className='p-1 cursor-pointer' > {data.title} </div>
             <TradingViewWidget  { ...data.content } />
            </div>    
          })
        }
       </>:
      <TradingViewWidget {...generateOptions(1)} />
    }
  </div>
};