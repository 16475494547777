import { constants } from '../../../constants/constants';
import { SmartTradeState } from '../../../models/smart-trade-model';

const { CHAINS } = constants;

const formatValueByChain = value => {
  const chainId = SmartTradeState.useStoreState(state => state.tableData.chainId);

  for (let i = 0; i < CHAINS.length; i++) {
    if (CHAINS[i].chainIdHex === chainId) {
      return Number(value).toFixed(CHAINS[i].nativeTokenDecimals);
    }
  }
};

export const dataHelpers = {
  formatValueByChain
};
