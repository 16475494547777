/* eslint-disable max-len */
import React from 'react';

export const DEFI_IMG = <>
    <circle cx="180.5" cy="180.676" r="180.5" fill="#1E1F23"/>
    <rect width="2.45023" height="23.6855" transform="matrix(1 0 0 -1 179.684 361.176)" fill="url(#paint0_linear_3565_90887)"/>
    <rect x="179.684" y="0.176392" width="2.45023" height="21.2353" fill="url(#paint1_linear_3565_90887)"/>
    <rect x="66.9131" y="292.94" width="2.45023" height="20.5105" transform="rotate(45 66.9131 292.94)" fill="url(#paint2_linear_3565_90887)"/>
    <rect x="307.675" y="52.1773" width="2.45023" height="22.4915" transform="rotate(45 307.675 52.1773)" fill="url(#paint3_linear_3565_90887)"/>
    <rect y="181.493" width="2.45023" height="21.2353" transform="rotate(-90 0 181.493)" fill="url(#paint4_linear_3565_90887)"/>
    <rect x="361" y="179.86" width="1.63349" height="21.2353" transform="rotate(90 361 179.86)" fill="url(#paint5_linear_3565_90887)"/>
    <rect x="67.7031" y="65.7385" width="2.45023" height="19.1783" transform="rotate(135 67.7031 65.7385)" fill="url(#paint6_linear_3565_90887)"/>
    <rect x="309.407" y="307.443" width="2.45023" height="19.673" transform="rotate(135 309.407 307.443)" fill="url(#paint7_linear_3565_90887)"/>
    <path d="M153.013 74.8266V97.3687H160.723C163.108 97.3687 165.002 96.6826 166.407 95.3105C167.812 93.9384 168.531 91.7495 168.531 88.7439V83.4514C168.531 80.4785 167.812 78.2896 166.407 76.9175C165.002 75.5453 163.108 74.8266 160.723 74.8266H153.013ZM160.755 95.2125H155.43V76.9828H160.755C162.454 76.9828 163.794 77.5055 164.708 78.551C165.623 79.5964 166.113 81.2625 166.113 83.5494V88.8746C166.113 93.1216 164.316 95.2125 160.755 95.2125Z" fill="white"/>
    <path d="M174.839 89.528H185.195V87.5678C185.195 85.2156 184.607 83.4514 183.464 82.308C182.32 81.1645 180.818 80.5765 178.923 80.5765C177.028 80.5765 175.492 81.1645 174.349 82.308C173.173 83.4514 172.618 85.2156 172.618 87.5678V90.4427C172.618 92.893 173.173 94.6898 174.349 95.8332C175.492 97.0093 177.028 97.5647 178.988 97.5647C180.883 97.5647 182.353 97.1073 183.431 96.1599C184.509 95.2452 185.065 94.0037 185.065 92.4356V92.2722H182.909V92.3702C182.909 93.383 182.549 94.1671 181.896 94.7225C181.242 95.3105 180.328 95.5719 179.152 95.5719C177.779 95.5719 176.734 95.2125 175.983 94.4611C175.198 93.7097 174.839 92.4029 174.839 90.5081V89.528ZM175.95 83.7128C176.669 82.994 177.681 82.602 178.923 82.602C180.164 82.602 181.177 82.9614 181.896 83.6474C182.614 84.3662 182.974 85.5749 182.974 87.2738V87.8618H174.839V87.5025C174.839 85.7056 175.198 84.4315 175.95 83.7128Z" fill="white"/>
    <path d="M192.119 76.9828H203.423V74.8266H189.734V97.3687H192.119V87.0451H201.789V84.8889H192.119V76.9828Z" fill="white"/>
    <path d="M205.657 80.8052V97.3687H207.878V80.8052H205.657ZM205.591 75.1533V78.0936H207.943V75.1533H205.591Z" fill="white"/>
    <path d="M88.9897 245.831C88.9897 245.831 109.904 243.322 114.343 220.482L111.661 219.977C111.661 219.977 109.822 238.219 88.4023 243.154L88.9897 245.831Z" fill="url(#paint8_linear_3565_90887)"/>
    <path d="M50.6377 220.443C50.6377 220.443 53.1461 241.357 75.991 245.796L76.4725 243.114C76.4725 243.114 58.2302 241.275 53.2953 219.855L50.6377 220.443Z" fill="url(#paint9_linear_3565_90887)"/>
    <path d="M75.991 183.295C75.991 183.295 55.0719 185.808 50.6377 208.648L53.3146 209.13C53.3146 209.13 55.1537 190.892 76.5736 185.958L75.991 183.295Z" fill="url(#paint10_linear_3565_90887)"/>
    <path d="M114.025 208.48C114.025 208.48 111.512 187.561 88.6719 183.127L88.1904 185.803C88.1904 185.803 106.428 187.647 111.363 209.067L114.025 208.48Z" fill="url(#paint11_linear_3565_90887)"/>
    <path d="M146.168 301.382C146.168 301.382 167.523 298.822 172.049 275.501L169.317 275.009C169.317 275.009 167.439 293.632 145.573 298.669L146.168 301.382Z" fill="url(#paint12_linear_3565_90887)"/>
    <path d="M107.342 275.678C107.342 275.678 109.902 297.028 133.218 301.554L133.71 298.822C133.71 298.822 115.092 296.944 110.055 275.078L107.342 275.678Z" fill="url(#paint13_linear_3565_90887)"/>
    <path d="M132.87 238.02C132.87 238.02 111.52 240.586 106.993 263.902L109.726 264.393C109.726 264.393 111.603 245.776 133.469 240.733L132.87 238.02Z" fill="url(#paint14_linear_3565_90887)"/>
    <path d="M171.696 263.73C171.696 263.73 169.136 242.375 145.815 237.848L145.323 240.581C145.323 240.581 163.946 242.458 168.983 264.325L171.696 263.73Z" fill="url(#paint15_linear_3565_90887)"/>
    <path d="M226.38 301.672C226.38 301.672 247.826 299.096 252.372 275.681L249.623 275.187C249.623 275.187 247.737 293.884 225.778 298.948L226.38 301.672Z" fill="url(#paint16_linear_3565_90887)"/>
    <path d="M187.386 275.834C187.386 275.834 189.957 297.28 213.377 301.825L213.871 299.081C213.871 299.081 195.169 297.191 190.11 275.237L187.386 275.834Z" fill="url(#paint17_linear_3565_90887)"/>
    <path d="M213.023 238.001C213.023 238.001 191.577 240.573 187.031 263.988L189.776 264.482C189.776 264.482 191.666 245.78 213.62 240.721L213.023 238.001Z" fill="url(#paint18_linear_3565_90887)"/>
    <path d="M252.016 263.835C252.016 263.835 249.44 242.394 226.025 237.848L225.531 240.593C225.531 240.593 244.228 242.478 249.292 264.437L252.016 263.835Z" fill="url(#paint19_linear_3565_90887)"/>
    <path d="M282.565 245.345C282.565 245.345 303.478 242.838 307.91 219.999L305.234 219.518C305.234 219.518 303.391 237.755 281.978 242.688L282.565 245.345Z" fill="url(#paint20_linear_3565_90887)"/>
    <path d="M244.542 220.173C244.542 220.173 247.054 241.081 269.888 245.514L270.369 242.838C270.369 242.838 252.137 240.999 247.199 219.586L244.542 220.173Z" fill="url(#paint21_linear_3565_90887)"/>
    <path d="M269.551 183.295C269.551 183.295 248.638 185.807 244.205 208.641L246.881 209.122C246.881 209.122 248.725 190.89 270.138 185.952L269.551 183.295Z" fill="url(#paint22_linear_3565_90887)"/>
    <path d="M307.573 208.472C307.573 208.472 305.061 187.559 282.227 183.127L281.746 185.803C281.746 185.803 299.959 187.641 304.883 209.05L307.573 208.472Z" fill="url(#paint23_linear_3565_90887)"/>
    <path d="M180.5 339.941C268.46 339.941 339.765 268.636 339.765 180.676C339.765 92.7169 268.46 21.4117 180.5 21.4117C92.5406 21.4117 21.2354 92.7169 21.2354 180.676C21.2354 268.636 92.5406 339.941 180.5 339.941Z" stroke="url(#paint24_linear_3565_90887)" strokeWidth="7" strokeMiterlimit="10"/>
    <path d="M180.5 330.957C263.498 330.957 330.781 263.674 330.781 180.676C330.781 97.6787 263.498 30.3958 180.5 30.3958C97.5026 30.3958 30.2197 97.6787 30.2197 180.676C30.2197 263.674 97.5026 330.957 180.5 330.957Z" stroke="url(#paint25_linear_3565_90887)" strokeMiterlimit="10"/>
    <circle cx="81.6747" cy="186.394" r="13.3846" stroke="#0BB1FF"/>
    <circle r="13.3846" transform="matrix(-1 0 0 1 276.875 186.394)" fill="#17191F" stroke="#0BB1FF"/>
    <circle cx="140.478" cy="242.749" r="13.3846" stroke="#0BB1FF"/>
    <circle r="13.3846" transform="matrix(-1 0 0 1 219.704 242.749)" fill="#17191F" stroke="#0BB1FF"/>
    <defs>
        <linearGradient id="paint0_linear_3565_90887" x1="-2.91655" y1="1.19743" x2="3.7263" y2="22.992" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3565_90887" x1="176.767" y1="1.24995" x2="182.197" y2="21.1213" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3565_90887" x1="63.9965" y1="293.977" x2="69.086" y2="313.26" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_3565_90887" x1="304.758" y1="53.3144" x2="310.799" y2="74.1846" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_3565_90887" x1="-2.91655" y1="182.567" x2="2.51356" y2="202.438" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_3565_90887" x1="359.056" y1="180.933" x2="366.55" y2="199.217" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint6_linear_3565_90887" x1="64.7866" y1="66.7081" x2="69.2731" y2="84.8874" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint7_linear_3565_90887" x1="306.491" y1="308.437" x2="311.198" y2="327.03" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint8_linear_3565_90887" x1="101.373" y1="219.977" x2="84.8631" y2="237.014" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1C64FF"/>
            <stop offset="0.682292" stopColor="#1581FF"/>
        </linearGradient>
        <linearGradient id="paint9_linear_3565_90887" x1="63.5551" y1="219.855" x2="46.9867" y2="236.826" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1C64FF"/>
            <stop offset="0.682292" stopColor="#1581FF"/>
        </linearGradient>
        <linearGradient id="paint10_linear_3565_90887" x1="63.6056" y1="183.295" x2="47.1086" y2="200.329" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1C64FF"/>
            <stop offset="0.682292" stopColor="#1581FF"/>
        </linearGradient>
        <linearGradient id="paint11_linear_3565_90887" x1="101.108" y1="183.127" x2="84.5394" y2="200.097" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1C64FF"/>
            <stop offset="0.682292" stopColor="#1581FF"/>
        </linearGradient>
        <linearGradient id="paint12_linear_3565_90887" x1="166.761" y1="277.384" x2="147.165" y2="280.111" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.8125" stopColor="#00B0FF"/>
        </linearGradient>
        <linearGradient id="paint13_linear_3565_90887" x1="128.443" y1="277.463" x2="108.921" y2="280.158" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.8125" stopColor="#00B0FF"/>
        </linearGradient>
        <linearGradient id="paint14_linear_3565_90887" x1="128.181" y1="240.396" x2="108.585" y2="243.123" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.8125" stopColor="#00B0FF"/>
        </linearGradient>
        <linearGradient id="paint15_linear_3565_90887" x1="166.429" y1="240.233" x2="146.903" y2="242.929" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00D0FF"/>
            <stop offset="0.8125" stopColor="#00B0FF"/>
        </linearGradient>
        <linearGradient id="paint16_linear_3565_90887" x1="224.358" y1="276.126" x2="221.783" y2="293.168" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5A31FC"/>
            <stop offset="0.223958" stopColor="#4604FE"/>
            <stop offset="0.973958" stopColor="#770CFF"/>
        </linearGradient>
        <linearGradient id="paint17_linear_3565_90887" x1="185.971" y1="276.179" x2="183.366" y2="293.282" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5A31FC"/>
            <stop offset="0.223958" stopColor="#4604FE"/>
            <stop offset="0.973958" stopColor="#770CFF"/>
        </linearGradient>
        <linearGradient id="paint18_linear_3565_90887" x1="185.611" y1="238.94" x2="183.037" y2="255.979" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5A31FC"/>
            <stop offset="0.223958" stopColor="#4604FE"/>
            <stop offset="0.973958" stopColor="#770CFF"/>
        </linearGradient>
        <linearGradient id="paint19_linear_3565_90887" x1="224.116" y1="238.791" x2="221.512" y2="255.894" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5A31FC"/>
            <stop offset="0.223958" stopColor="#4604FE"/>
            <stop offset="0.973958" stopColor="#770CFF"/>
        </linearGradient>
        <linearGradient id="paint20_linear_3565_90887" x1="267.591" y1="223.544" x2="274.918" y2="251.672" gradientUnits="userSpaceOnUse">
            <stop offset="0.260417" stopColor="#7000FF"/>
            <stop offset="1" stopColor="#791CEF"/>
        </linearGradient>
        <linearGradient id="paint21_linear_3565_90887" x1="230.214" y1="223.627" x2="237.621" y2="251.836" gradientUnits="userSpaceOnUse">
            <stop offset="0.260417" stopColor="#7000FF"/>
            <stop offset="1" stopColor="#791CEF"/>
        </linearGradient>
        <linearGradient id="paint22_linear_3565_90887" x1="229.819" y1="187.321" x2="237.145" y2="215.449" gradientUnits="userSpaceOnUse">
            <stop offset="0.260417" stopColor="#7000FF"/>
            <stop offset="1" stopColor="#791CEF"/>
        </linearGradient>
        <linearGradient id="paint23_linear_3565_90887" x1="267.418" y1="187.167" x2="274.822" y2="215.372" gradientUnits="userSpaceOnUse">
            <stop offset="0.260417" stopColor="#7000FF"/>
            <stop offset="1" stopColor="#791CEF"/>
        </linearGradient>
        <linearGradient id="paint24_linear_3565_90887" x1="19.9581" y1="180.676" x2="341.038" y2="180.676" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#08C3FF"/>
            <stop offset="0.546875" stopColor="#0787FE"/>
            <stop offset="1" stopColor="#8912FF"/>
        </linearGradient>
        <linearGradient id="paint25_linear_3565_90887" x1="330.781" y1="180.311" x2="47.7708" y2="172.998" gradientUnits="userSpaceOnUse">
            <stop offset="0.35" stopColor="#07C3FE"/>
            <stop offset="0.96" stopColor="#07C3FE"/>
        </linearGradient>
    </defs>
</>;