import React, { useEffect, useRef } from 'react';
import { imagesTemplate } from './templates';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { RemakeDashboardState } from '../../../../../models/remake-dashboard-model';
// import { SMALL_PARTS } from './images/smallParts';
import { useStoreState } from 'easy-peasy';
// import { dashboardEffects } from '../actions/dashboardEffects';
import { isEmpty } from 'lodash';
import { remakeDashboardEffects } from '../../../actions/remakeDashboardEffects';

export const ImagesGenerator = props => {
    const { accs } = props;
    const totalRef = useRef();
    const ref = useRef();
    const walletRef = useRef();
    const state = RemakeDashboardState.useStoreState(state => state);
    const sizes = useStoreState(state => state.sizes.values);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);
    const usedExchanges = useStoreState(state => state?.coinData?.exchanges);

    useEffect(() => {
        drawHelper.drawWallets(d3.select(walletRef.current), state, accs, actions);
        drawHelper.drawTotalsCircle(d3.select(totalRef.current), state);
    }, );

    useEffect(() => {
        const { height } = ref?.current?.getBoundingClientRect();
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_SIZES_UPDATED]({ height: height });
    }, [sizes]);

    return <div ref={ref}>
        <svg ref={walletRef} viewBox="0 0 428 186" fill="none" xmlns="http://www.w3.org/2000/svg" className="image-totals-part">
            { imagesTemplate.walletsImg() }
        </svg>
        <svg ref={totalRef} viewBox="30 0 570 750" className="image-middle-part">
            { imagesTemplate.circleTotalsImg() }
        </svg>
    </div>;
};
