import { textwrap } from 'd3-textwrap';

const drawTableContent = (node, content) => {
    const { name, nickname, position, description } = content;
    
    node
        .selectAll('g.left-titles')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'left-titles member-description');

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 16)
        .attr('x', 220)
        .attr('text-anchor', 'start')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '14px')
        .attr('font-weight', 300)
        .attr('font-family', 'Inter')
        .text(`${position}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 45)
        .attr('x', 30)
        .attr('text-anchor', 'start')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '26px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 400)
        .text(`${name}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 76)
        .attr('x', 30)
        .attr('text-anchor', 'start')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '24px')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`THE "${nickname}"`);

    node
        .select('g.member-description')
        .append('text')
        .attr('y', 100)
        .attr('x', 30)
        .attr('text-anchor', 'start')
        .attr('fill', '#FFFFFF')
        .attr('font-weight', 400)
        .attr('font-family', 'Inter')
        .text(`${description}`)
        .call(wrap);
};

const wrap = textwrap().bounds({ height: 71, width: 480 });




export const drawHelper = {
    drawTableContent,
};