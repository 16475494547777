import { action, thunk } from "easy-peasy";
import { notifyEffects } from "../../../../../components/utility/notifications/notifyEffects";
import { careersFactory } from "./careersFactory";

const actionTypes = {
  CAREERS_APPLY_INVOKED: "CAREERS_APPLY#INVOKED",
  CAREERS_APPLY_SUCCEEDED: "CAREERS_APPLY#SUCCEEDED",
  CAREERS_APPLY_FAILED: "CAREERS_APPLY#FAILED",
  TOKEN_INVOKED: "TOKEN#INVOKED",
  TOKEN_SUCCEEDED: "TOKEN#SUCCEEDED",
  TOKEN_FAILED: "TOKEN#FAILED",
  CAREERS_MODAL_APPLY_OPENED: "CAREERS_MODAL_APPLY#OPENED",
  CAREERS_MODAL_APPLY_CLOSED: "CAREERS_MODAL_APPLY#CLOSED",
};

const invokeHandle = (actions, payload) => {
  careersFactory.postHandle(actions, payload);
};

const successHandle = (state, payload) => {
  state.successfullyApplied = true;
  console.log("successfullyApplied", state.successfullyApplied);
  //const notification = { type: "success", text: payload.response.message };
  //notifyEffects.notifyAction(notification);
};

const errorAction = (state, payload) => {
 
  const notification = {
    type: "danger",
    text: payload.error.response.data.message,
  };
  notifyEffects.notifyAction(notification);
};

const invokeToken = (actions, payload) => {
  careersFactory.postRecaptcha(actions, payload);
};

const successTokenHandle = (state, payload) => {
  state.recaptchaTokenValid = payload;
};

const errorTokenAction = () => {
  const notification = { type: "danger", text: "Something goes wrong" };
  notifyEffects.notifyAction(notification);
};

const modalHandle = (state, payload) => {
  state.isModalOpen = payload;
  state.successfullyApplied = false;
};

const actionHandlers = {
  [actionTypes.CAREERS_APPLY_INVOKED]: thunk(async (actions, payload) =>
    invokeHandle(actions, payload)
  ),
  [actionTypes.CAREERS_APPLY_SUCCEEDED]: action((state, payload) =>
    successHandle(state, payload)
  ),
  [actionTypes.CAREERS_APPLY_FAILED]: action((state, payload) =>
    errorAction(state, payload)
  ),
  [actionTypes.TOKEN_INVOKED]: thunk(async (actions, payload) =>
    invokeToken(actions, payload)
  ),
  [actionTypes.TOKEN_SUCCEEDED]: action((state, payload) =>
    successTokenHandle(state, payload)
  ),
  [actionTypes.TOKEN_FAILED]: action((state, payload) =>
    errorTokenAction(state, payload)
  ),
  [actionTypes.CAREERS_MODAL_APPLY_OPENED]: action((state, payload) =>
    modalHandle(state, payload)
  ),
  [actionTypes.CAREERS_MODAL_APPLY_CLOSED]: action((state, payload) =>
    modalHandle(state, payload)
  ),
};

export const careersEffects = {
  actionTypes,
  actionHandlers,
};
