import React from 'react';
import { action } from 'easy-peasy';
import { reactLocalStorage } from 'reactjs-localstorage';

const actionTypes = {
  PLANS_SUBSCRIPTIONS_INVOKED: 'PLANS_SUBSCRIPTIONS#INVOKED',
};

const plansSubscriptionHandle = (state, payload) => {
  const { type, history, isMonthly } = payload;

  const subscription = {
    name: type,
    type: isMonthly ? 'Monthly' : 'Annually'
  }
  reactLocalStorage.set('plans_subscription', JSON.stringify(subscription));
  if (history.location.pathname !== '/home') {
    history.push('/nft_generator?elem=nft_generator')
  }
}

const actionHandlers = {
  [actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]: action((state, payload) => plansSubscriptionHandle(state, payload)),
};

export const plansEffects = {
  actionTypes,
  actionHandlers
};
