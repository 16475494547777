import { TableWidget } from './widgets/table-widget/view';
import { BarChart } from './widgets/bar-chart/view';
import { Bots } from './widgets/bots-widget/view';
import { Infos } from './widgets/info-widget/view';
import React from 'react';

const renderGridDashboard = () => <>
    <div className="d-flex flex-row dashboard-row ps-3">
        <div className="dashboard-widget widget-table pe-2">
            <div className="dashboard-widget-body widget-table">
                <TableWidget/>
            </div>
        </div>
        <div className="dashboard-widget widget-bar-chart ps-1">
            <div className="dashboard-widget-body widget-bar-chart">
                <BarChart />
            </div>
        </div>
    </div>
    <div className="d-flex flex-row dashboard-row ps-3 pb-3">
        <div className="dashboard-widget pe-2">
            <div className="dashboard-widget-body widget-bots">
                <Bots title={'Bots Statistics'} />
            </div>
        </div>
        <div className="dashboard-widget ps-1">
            <div className="dashboard-widget-body widget-info">
                <Infos/>
            </div>
        </div>
    </div>
</>;

const renderColumnDashboard = () => <>
    <div className="d-flex flex-column ps-3">
        <div className="dashboard-widget widget-table pe-2 pb-3 w-100">
            <div className="dashboard-widget-body widget-table">
                <TableWidget/>
            </div>
        </div>
        <div className="dashboard-widget widget-bar-chart pe-2 pb-3 w-100">
            <div className="dashboard-widget-body widget-bar-chart">
                <BarChart/>
            </div>
        </div>
        <div className="dashboard-widget pe-2 pb-3 w-100">
            <div className="dashboard-widget-body widget-bots">
                <Bots title={'Bots Statistics'} />
            </div>
        </div>
        <div className="dashboard-widget pe-2 pb-3 w-100">
            <div className="dashboard-widget-body widget-info">
                <Infos/>
            </div>
        </div>
    </div>
</>;


const renderGrid = width => width >= 1200 ? renderGridDashboard() : renderColumnDashboard();

export const template = {
    renderGrid
};