import React from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';

export const Loader = props => {
    const { classOption, size } = props;
    return <div className={`component-loader d-flex flex-column justify-content-center h-100 w-100 ${classOption}`}>
        <div className="d-flex justify-content-center">
            <FontAwesomeIcon icon={faSync} spin size={size}/>
        </div>
    </div>;
};
