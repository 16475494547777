import React from 'react';
import './styles.scss';
import { InfoEmptyState } from '../info-widget/templates/emptyState';
import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { utils } from '../../../../utils/utils';
import { Loader } from '../../../../components/utility/loader/view';
import { NewsState } from '../../../../models/news-model';
import { newsPageEffects } from '../../../newsPage/actions/newsPageEffects';

const renderMessage = (channel, key) => {
  const { timestamp, title, url, content } = channel;
  const network = 'cryptopanic';
  const timeValue = moment(timestamp * 1000).format('DD/MM/YYYY');
  const actions = NewsState.useStoreActions((state) => state);
  const handleUrlClick = (e) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <div
      key={key}
      className="feed-message"
      onClick={() => {
        actions[newsPageEffects.actionTypes.NEWS_DATA_INVOKED](channel);
      }}
    >
      <div className="d-flex flex-row justify-content-start feed-title mb-1">
        <div className="feed-icon d-flex flex-column pe-1">
          <img
            className="rounded-start"
            src={`../info-channel-library/channel_${network}.svg`}
            onError={utils.imgErrorHandle}
          />
        </div>
        <div className="d-flex flex-column feed-label">
          <div className="font-size-13">{(title || '').toUpperCase()}</div>
          <div className="font-size-10">
            <a
              href={url}
              className="url-link"
              style={{ color: 'inherit', textDecoration: 'none' }}
              onMouseEnter={(e) => (e.target.style.color = 'blue')}
              onMouseLeave={(e) => (e.target.style.color = 'inherit')}
              onClick={handleUrlClick}
            >
              {url || ''}
            </a>
          </div>
          <div className="font-size-10 feed-time-stamp">{timeValue}</div>
        </div>
      </div>
      <div
        className="feed-message-text font-size-11 font-weight-light mb-3"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <div className="feed-divider mb-3"></div>
    </div>
  );
};

const renderFeed = (data) => {
  return data.map((el, key) => {
    return renderMessage(el, key);
  });
};

export const FeedWidget = ({ data }) => {
  const loaders = useStoreState((state) => state.loaders);
  const isLoading = loaders.feeds;
  return (
    <>
      {isEmpty(data) && !isLoading ? <InfoEmptyState type="FEED" /> : null}
      {isLoading ? <Loader size={'6x'} isLoading /> : <> {renderFeed(data)}</>}
    </>
  );
};
