import React from 'react';
import './styles.scss';

export const EmptySearchState = (props) => {
  const { text, title } = props

  return <div className={`component-empty-state d-flex flex-column justify-content-center h-100 w-100`}>
    <div className="d-flex justify-content-center">
        <div className="empty-search-state-icon"></div>
    </div>
    { title ?
      <div className="text-center pt-2 mx-auto font-weight-medium font-size-22 title">
        {title}
      </div> : null
    }
    { text ?
      <div className="text-center pt-2 mx-auto text">
        {text}
      </div> : null
    }
  </div>;
};
