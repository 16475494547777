import { createContextStore } from 'easy-peasy';
import { constants } from '../pages/defiMultiChainDashboard/constants';
import { uiActions } from '../pages/defiMultiChainDashboard/actions/uiActions';
import { defiMultichainEffects } from '../pages/defiMultiChainDashboard/actions/defiMultichainEffects';

const { TOP_CONTENT: { HEADER }, WALLET_MODAL_TABS } = constants;

const DEFI_MULTICHAIN_DASHBOARD_MODEL = {
    showMore: {
        poolsOpen: false,
        tokensOpen: false,
        chainpen: false
      },
      allData: [],
      filteredAllData: [],
    protocols: [
        {
            asset: 'Wallet',
            value: '',
            fraction: 100,
        }
    ],
    date: {
        currentDate: '',
        currentTime: ''
    },
    copy: {
        copied: false
    },
    tab: HEADER[0],
    requestBody: null,
    wallet: {
        totalBalance: 0,
        allTotalBalance: 0,
        tokenAllocation: [],
        available: '',
        change: '',
        invested: '',
        blackList: {
            modal: false,
            tempData: [],
            transportData: [],
            data: [],
            filteredData: []
        },
        open: false,
        isLoading: false,
        balance: null,
        claim: 0,
        pagination: {
            hasMore: false
        },
        totalUSD: 0,
        loader: false,
        data: [],
        filteredData: []
    },
    venus: {
        pagination: {
            hasMore: false
        },
        data: [],
        open: false
    },
    compound: {
        pagination: {
            hasMore: false
        },
        data: [],
        open: false
    },
    farms: {
        pagination: {
            hasMore: false
        },
        data: [],
        open: false,
        rewardedToken: {}
    },
    pools: {
        pagination: {
            hasMore: false
        },
        data: [],
        open: false
    },
    modalChart: {
        show: false
    },
    modalBlackList: {
        show: false
    },
    modalToken: {
        tab: WALLET_MODAL_TABS[0],
        token: {},
        show: false
    },
    addToken: {
        show: false,
        protocol: {},
        address: ''
    },
    chains: {
        picked: 'All',
        all: {
            symbol: '',
            chain: 'All',
        },
        others: {
            symbol: '',
            chain: 'Others'
        },
        list: [
            {
                request: 'ETH',
                symbol: 'eth',
                chain: 'Ethereum',
            },
            {
                request: 'BSC',
                symbol: 'bsc',
                chain: 'BSC',
            },
            {
                request: 'MATIC',
                symbol: 'matic',
                chain: 'Polygon',
            },
            {
                request: 'AVAXc',
                symbol: 'avax',
                chain: 'Avalanche',
            },
            {
                request: 'FTM',
                symbol: 'ftm',
                chain: 'Fantom',
            },
            {
                request: 'ARBITRUM',
                symbol: 'arbitrum',
                chain: 'Arbitrum',
            },
        ]
    },
    analytics: {
        analyticsData: {
            data: [],
            heading: ''
        },
        token: {
            list: [
                {
                    symbol: '1inch',
                    protocol: '1INCH',
                    value: 18,
                    color: '#1F55FE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'fcm',
                    protocol: 'Fulcrum',
                    value: 14,
                    color: '#4674B6',
                    amount: '1,415.49'
                },
                {
                    symbol: 'orion',
                    protocol: 'Orion Money',
                    value: 18,
                    color: '#00A859',
                    amount: '679.46'
                },
                {
                    symbol: 'mars',
                    protocol: 'Mars Ecosystem',
                    value: 18,
                    color: '#05CAFE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'knight',
                    protocol: 'KnightSwap',
                    value: 9,
                    color: '#ED32B5',
                    amount: '1,415.49'
                },
                {
                    symbol: 'spectrum',
                    protocol: 'Spectrum',
                    value: 9,
                    color: '#7828AD',
                    amount: '679.46',
                },
                {
                    symbol: 'others',
                    protocol: 'Others',
                    value: 14,
                    color: '#F7931A',
                    amount: '2,554.50'
                },
            ]
        },
        protocol: {
            list: [
                {
                    symbol: '1inch',
                    protocol: '1INCH',
                    value: 25,
                    color: '#1F55FE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'fcm',
                    protocol: 'Fulcrum',
                    value: 8,
                    color: '#4674B6',
                    amount: '1,415.49'
                },
                {
                    symbol: 'orion',
                    protocol: 'Orion Money',
                    value: 4,
                    color: '#00A859',
                    amount: '679.46'
                },
                {
                    symbol: 'mars',
                    protocol: 'Mars Ecosystem',
                    value: 23,
                    color: '#05CAFE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'knight',
                    protocol: 'KnightSwap',
                    value: 9,
                    color: '#ED32B5',
                    amount: '1,415.49'
                },
                {
                    symbol: 'spectrum',
                    protocol: 'Spectrum',
                    value: 9,
                    color: '#7828AD',
                    amount: '679.46'
                },
                {
                    symbol: 'others',
                    protocol: 'Others',
                    value: 22,
                    color: '#F7931A',
                    amount: '2,554.50'
                },
            ]
        },
        chain: {
            list: [
                {
                    symbol: '1inch',
                    protocol: '1INCH',
                    value: 25,
                    color: '#1F55FE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'fcm',
                    protocol: 'Fulcrum',
                    value: 12,
                    color: '#4674B6',
                    amount: '1,415.49'
                },
                {
                    symbol: 'orion',
                    protocol: 'Orion Money',
                    value: 8,
                    color: '#00A859',
                    amount: '679.46'
                },
                {
                    symbol: 'mars',
                    protocol: 'Mars Ecosystem',
                    value: 25,
                    color: '#05CAFE',
                    amount: '3,127.99'
                },
                {
                    symbol: 'knight',
                    protocol: 'KnightSwap',
                    value: 10,
                    color: '#ED32B5',
                    amount: '1,415.49'
                },
                {
                    symbol: 'spectrum',
                    protocol: 'Spectrum',
                    value: 5,
                    color: '#7828AD',
                    amount: '679.46'
                },
                {
                    symbol: 'others',
                    protocol: 'Others',
                    value: 15,
                    color: '#F7931A',
                    amount: '2,554.50'
                },
            ]
        },
    },
    ...uiActions.actionHandlers,
    ...defiMultichainEffects.actionHandlers
};

export const DefiMultiChainDashboardState = createContextStore(
    DEFI_MULTICHAIN_DASHBOARD_MODEL
);
