import React from 'react';
import { isEmpty, orderBy } from 'lodash';
import { Asset } from '../../../components/utility/asset-component/view';

const sortByValue = (arr, attr, orders) => {
  const key = attr;
  const newArr = arr.map(item => {
    item[key] = Number(item[attr]);

    return {
      ...item,
    };
  });
  return orderBy(newArr, attr, orders);
};

const parseExchanges = exchanges => {
  const keys = Object
    .keys(exchanges)
    .filter(k => k !== 'all_exchanges');
  return keys.map(key => ({
    label: exchanges[key].name,
    value: exchanges[key]
  }));
};

const generateMainCoinList = (data, api) => {

  if (isEmpty(data) || !api)
    return {
      list: [],
      pairs: {}
    };

  const {baseByQuoteAssetsSpot} = data.find(e => e.api === api) || {};

  if (isEmpty(baseByQuoteAssetsSpot))
    return {
      list: [],
      pairs: {}
    };

  return {
    list: Object.keys(baseByQuoteAssetsSpot).map(k => (
      {
        value: k,
        label: <div className="icons-select d-flex align-items-center">
          <Asset asset={k}/>
        </div>
      })),
    pairs: baseByQuoteAssetsSpot
  };
};

const generatePairCoinList = (asset, data) => {

  if (isEmpty(data) || !asset )
    return [];

  const list = data[asset];

  if (isEmpty(list))
    return [];

  return list.map(k => (
    {
      value: k,
      label: <div className="icons-select d-flex align-items-center">
        <Asset asset={k}/>
      </div>
    }));
};

const submitButtonGordonState = state =>
  !Boolean(state.forms.exchangeSelector.value) ||
  !Boolean(state.forms.quoteCurrencySelector.value) ||
  !Boolean(state.forms.inputForAmount) ||
  !Boolean(state.tabs.percentageTab) ||
  !Boolean(state.tabs.formTab);

const submitButtonMainSettingsState = state =>
  !Boolean(state.forms.mainSettings.name) ||
  !Boolean(state.forms.mainSettings.exchangeSelector.value)

const submitButtonPairsState = state =>
  !Boolean(state.forms.pairs.mostTradedPairs) ||
  !Boolean(state.forms.pairs.pairsSelector.value)

const submitButtonStrategyState = state =>
  !Boolean(state.forms.strategy.strategy) ||
  !Boolean(state.forms.strategy.profitCurrency) ||
  !Boolean(state.forms.strategy.baseOrderSizeSelector.value) ||
  !Boolean(state.forms.strategy.baseOrderSizeAmount) ||
  !Boolean(state.forms.strategy.startOrderType)

const submitButtonDealStartConditionState = state =>
  state.forms.dealStartCondition.conditionFields.some(item =>
    !Boolean(item.conditionsStartingBotSelector.value) ||
    item?.conditionsStartingBotSelector?.value?.value === 'RSI' ?
      !Boolean(item.rsiPeriodAmount) ||
      !Boolean(item.signalValueAmount) :
      item?.conditionsStartingBotSelector?.value?.value !== 'TA Presets' &&
      item?.conditionsStartingBotSelector?.value?.value !== 'QFL' &&
      item?.conditionsStartingBotSelector?.value?.value !== 'Trading View' ?
        !Boolean(item.signalValueAmount) :
        false
  )

const submitButtonSafetyOrdersState = state =>
  !Boolean(state.forms.safetyOrders.safetyOrdersSizeSelector.value) ||
  !Boolean(state.forms.safetyOrders.safetyOrdersSizeAmount) ||
  !Boolean(state.forms.safetyOrders.priceDeviationAmount) ||
  !Boolean(state.forms.safetyOrders.safetyOrdersCountAmount) ||
  !Boolean(state.forms.safetyOrders.activeSafetyOrdersCountAmount) ||
  !Boolean(state.forms.safetyOrders.safetyOrderVolumeScaleAmount) ||
  !Boolean(state.forms.safetyOrders.safetyOrderStepScaleAmount)

const submitButtonStopLossState = state =>
  (state.forms.stopLoss.toggleStopLoss ?
    !Boolean(state.forms.stopLoss.stopLossPercentage) ||
    !Boolean(state.forms.stopLoss.stopLossActionSelector.value) :
  false) ||
  (state.forms.stopLoss.toggleStopLossTimeout ?
    !Boolean(state.forms.stopLoss.stopLossTimeout) :
    false)

const submitButtonAdvancedSettingsState = state =>
  !Boolean(state.forms.advancedSettings.dailyVolumeLess) ||
  !Boolean(state.forms.advancedSettings.minimumPriceDeal) ||
  !Boolean(state.forms.advancedSettings.maximumPriceDeal) ||
  !Boolean(state.forms.advancedSettings.cooldownBetweenDeals) ||
  !Boolean(state.forms.advancedSettings.openDealsStop)

const submitButtonAdvancedSettingsWizardState = state =>
  state.forms.advancedSettings.toggleCloseDealAfterTimeout ?
    !Boolean(state.forms.advancedSettings.closeDealAfterTimeoutSelector.value) ||
    !Boolean(state.forms.advancedSettings.closeDealAfterTimeoutAmount) :
    false

const submitButtonTakeProfitPercentState = state =>
  (state.tabs.takeProfitTab === 'Percent %' ?
    !Boolean(state.forms.takeProfit.targetProfitPercentage) ||
    !Boolean(state.forms.takeProfit.takeProfitTypeSelector.value) :
    false) ||
  (state.forms.takeProfit.toggleTrailingPercent ?
    !Boolean(state.forms.takeProfit.trailingDeviationPercentage) :
    false)

const submitButtonTakeProfitConditionsState = state =>
  state.tabs.takeProfitTab === 'Conditions' ?
    (state.forms.takeProfit.toggleTrailingConditions ?
      !Boolean(state.forms.takeProfit.minimumProfitPercentage) ||
      !Boolean(state.forms.takeProfit.minimumProfitTypeSelector.value) :
    false) ||
  (state.forms.takeProfit.conditionFields.some(item =>
    !Boolean(item.conditionsClosingBotSelector.value) ||
    item?.conditionsClosingBotSelector?.value?.value === 'RSI' ?
      !Boolean(item.rsiPeriodAmount) ||
      !Boolean(item.signalValueAmount) :
      item?.conditionsClosingBotSelector?.value?.value !== 'TA Presets' &&
      item?.conditionsClosingBotSelector?.value?.value !== 'Trading View' ?
        !Boolean(item.signalValueAmount) :
        false)
  ) : false

const submitButtonWizardState = (state) =>
  submitButtonMainSettingsState(state) ||
  !Boolean(state.tabs.botTypeTab) ||
  submitButtonPairsState(state) ||
  submitButtonStrategyState(state) ||
  submitButtonDealStartConditionState(state) ||
  submitButtonSafetyOrdersState(state) ||
  submitButtonStopLossState(state) ||
  submitButtonAdvancedSettingsState(state) ||
  submitButtonAdvancedSettingsWizardState(state) ||
  submitButtonTakeProfitPercentState(state) ||
  submitButtonTakeProfitConditionsState(state)

export const dataHelper = {
  sortByValue,
  parseExchanges,
  submitButtonGordonState,
  submitButtonWizardState,
  generateMainCoinList,
  generatePairCoinList
};
