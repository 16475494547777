/* eslint-disable max-len */
import React from 'react';

export const TABLE_IMG = <>
    <g clipPath="url(#clip0_107_2684)">
        <path d="M603.945 855H925.169" stroke="#B8B6B6"/>
        <path d="M603.945 1282H925.169" stroke="#B8B6B6"/>
        <path d="M596 872.201V1262.77" stroke="#B8B6B6"/>
        <path d="M936.519 872.201V1262.77" stroke="#B8B6B6"/>
        <path d="M603.186 428H924.409" stroke="#B8B6B6"/>
        <path d="M603.186 855H924.409" stroke="#B8B6B6"/>
        <path d="M595.24 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M935.76 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M262.945 428H584.169" stroke="#B8B6B6"/>
        <path d="M262.945 855H584.169" stroke="#B8B6B6"/>
        <path d="M255 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M595.519 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M943.945 428H1265.17" stroke="#B8B6B6"/>
        <path d="M943.945 855H1265.17" stroke="#B8B6B6"/>
        <path d="M936 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M1276.52 445.201V835.775" stroke="#B8B6B6"/>
        <path d="M603.186 1H924.409" stroke="#B8B6B6"/>
        <path d="M603.186 428H924.409" stroke="#B8B6B6"/>
        <path d="M595.24 18.2012V408.775" stroke="#B8B6B6"/>
        <path d="M935.76 18.2012V408.775" stroke="#B8B6B6"/>
        <path d="M262.945 1H584.169" stroke="#B8B6B6"/>
        <path d="M262.945 428H584.169" stroke="#B8B6B6"/>
        <path d="M255 18.2012V408.775" stroke="#B8B6B6"/>
        <path d="M595.519 18.2012V408.775" stroke="#B8B6B6"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M1284.95 855H1606.17" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M1284.95 1282H1606.17" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M1277 872.201V1262.77" stroke="#B8B6B6"/>
        </g>
        <g opacity="0.5">
            <path opacity="0.5" d="M1283.95 1H1605.17" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M1283.95 428H1605.17" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M1276 18.2012V408.775" stroke="#B8B6B6"/>
        </g>
        <g opacity="0.5">
            <path opacity="0.5" d="M-78.0547 427H243.169" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M-78.0547 854H243.169" stroke="#B8B6B6"/>
            <path opacity="0.5" d="M254.519 444.201V834.775" stroke="#B8B6B6"/>
        </g>
        <path opacity="0.8" d="M1557 1H1297C1285.95 1 1277 9.9543 1277 21V408C1277 419.046 1285.95 428 1297 428H1557C1568.05 428 1577 419.046 1577 408V21C1577 9.9543 1568.05 1 1557 1Z" fill="url(#paint0_linear_107_2684)"/>
        <path opacity="0.8" d="M235 428H-25C-36.0457 428 -45 436.954 -45 448V835C-45 846.046 -36.0457 855 -25 855H235C246.046 855 255 846.046 255 835V448C255 436.954 246.046 428 235 428Z" fill="url(#paint1_linear_107_2684)"/>
        <path d="M460.116 1115.71C458.673 1117.45 456.201 1118.22 452.816 1117.96C449.44 1117.71 445.255 1116.45 440.53 1114.28C431.086 1109.94 419.594 1102.02 408.307 1091.53C397.019 1081.05 388.104 1070.01 382.81 1060.66C380.161 1055.98 378.434 1051.75 377.757 1048.24C377.077 1044.72 377.471 1042.02 378.914 1040.28C380.357 1038.53 382.828 1037.77 386.214 1038.02C389.59 1038.27 393.775 1039.54 398.499 1041.71C407.943 1046.04 419.436 1053.97 430.723 1064.45C442.011 1074.94 450.926 1085.97 456.22 1095.32C458.868 1100 460.595 1104.23 461.273 1107.75C461.952 1111.27 461.559 1113.96 460.116 1115.71Z" stroke="#62656A"/>
        <path d="M462.976 1090.08C461.978 1091.28 460.252 1091.83 457.833 1091.65C455.424 1091.47 452.426 1090.57 449.03 1089.01C442.245 1085.89 433.98 1080.2 425.858 1072.65C417.736 1065.11 411.326 1057.17 407.522 1050.45C405.619 1047.09 404.383 1044.06 403.899 1041.55C403.414 1039.03 403.703 1037.15 404.701 1035.94C405.699 1034.74 407.424 1034.19 409.844 1034.37C412.253 1034.55 415.251 1035.45 418.646 1037.01C425.432 1040.13 433.697 1045.82 441.819 1053.37C449.941 1060.91 456.351 1068.85 460.155 1075.57C462.058 1078.93 463.294 1081.96 463.777 1084.47C464.263 1086.99 463.973 1088.87 462.976 1090.08Z" stroke="#62656A"/>
        <path d="M325.728 1147.97L301.157 933.029L489.159 1017.96L325.728 1147.97Z" stroke="url(#paint2_linear_107_2684)" strokeWidth="2"/>
        <g opacity="0.8">
            <path opacity="0.8" d="M486.005 990.597C499.816 990.597 511.011 1002.45 511.011 1017.08C511.011 1031.71 499.816 1043.56 486.005 1043.56C472.195 1043.56 461 1031.71 461 1017.08C461 1002.45 472.195 990.597 486.005 990.597Z" fill="url(#paint3_linear_107_2684)"/>
        </g>
        <path opacity="0.3" d="M459.807 900.297L401.759 912.283L412.123 968.61L470.171 956.624L459.807 900.297Z" stroke="url(#paint4_linear_107_2684)" strokeWidth="3"/>
        <path d="M1264.14 820.246C1266.25 824.165 1265.98 829.493 1263.5 835.893C1261.02 842.28 1256.38 849.625 1249.92 857.462C1237.01 873.132 1216.93 890.68 1192.74 906.374C1168.55 922.069 1144.86 932.915 1126.13 937.773C1116.76 940.203 1108.66 941.128 1102.36 940.431C1096.06 939.733 1091.68 937.421 1089.57 933.502C1087.46 929.584 1087.72 924.256 1090.21 917.855C1092.68 911.469 1097.33 904.124 1103.79 896.286C1116.7 880.616 1136.78 863.068 1160.97 847.374C1185.16 831.68 1208.84 820.834 1227.58 815.975C1236.95 813.545 1245.05 812.62 1251.34 813.318C1257.65 814.017 1262.03 816.328 1264.14 820.246Z" stroke="url(#paint5_linear_107_2684)"/>
        <path d="M1286.72 854.031C1288.83 857.949 1288.56 863.277 1286.08 869.677C1283.6 876.065 1278.95 883.409 1272.5 891.247C1259.59 906.917 1239.51 924.464 1215.32 940.159C1191.13 955.852 1167.44 966.699 1148.7 971.558C1139.34 973.988 1131.23 974.913 1124.94 974.215C1118.64 973.516 1114.25 971.205 1112.14 967.287C1110.03 963.368 1110.3 958.04 1112.78 951.64C1115.26 945.252 1119.91 937.908 1126.36 930.071C1139.27 914.401 1159.35 896.853 1183.54 881.159C1207.74 865.465 1231.42 854.618 1250.16 849.76C1259.53 847.329 1267.63 846.405 1273.92 847.102C1280.23 847.801 1284.61 850.112 1286.72 854.031Z" stroke="url(#paint6_linear_107_2684)"/>
        <path d="M1318.43 889.713C1320.54 893.632 1320.28 898.96 1317.79 905.359C1315.32 911.747 1310.67 919.092 1304.21 926.929C1291.3 942.6 1271.22 960.147 1247.03 975.842C1222.84 991.535 1199.15 1002.38 1180.42 1007.24C1171.05 1009.67 1162.95 1010.6 1156.66 1009.9C1150.35 1009.2 1145.97 1006.89 1143.86 1002.97C1141.75 999.051 1142.02 993.722 1144.5 987.323C1146.98 980.935 1151.62 973.591 1158.08 965.754C1170.99 950.083 1191.07 932.535 1215.26 916.841C1239.45 901.147 1263.14 890.301 1281.87 885.442C1291.24 883.012 1299.34 882.087 1305.64 882.785C1311.94 883.483 1316.32 885.795 1318.43 889.713Z" stroke="url(#paint7_linear_107_2684)"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_107_2684" x1="1221" y1="169.038" x2="1782.7" y2="-90.4842" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0E3F97"/>
            <stop offset="0.389922" stopColor="#1876FF"/>
            <stop offset="0.70202" stopColor="#0BAFFF"/>
            <stop offset="0.776009" stopColor="#08BDFC"/>
            <stop offset="0.921176" stopColor="#05CDF9"/>
        </linearGradient>
        <linearGradient id="paint1_linear_107_2684" x1="-101" y1="596.038" x2="460.699" y2="336.516" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0E3F97"/>
            <stop offset="0.389922" stopColor="#1876FF"/>
            <stop offset="0.70202" stopColor="#0BAFFF"/>
            <stop offset="0.776009" stopColor="#08BDFC"/>
            <stop offset="0.921176" stopColor="#05CDF9"/>
        </linearGradient>
        <linearGradient id="paint2_linear_107_2684" x1="206.349" y1="956.002" x2="461.195" y2="801.647" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1876FF"/>
            <stop offset="0.286458" stopColor="#0BAFFF"/>
            <stop offset="0.604167" stopColor="#05CDF9"/>
            <stop offset="0.942708" stopColor="#01E9BB"/>
        </linearGradient>
        <linearGradient id="paint3_linear_107_2684" x1="444.116" y1="984.05" x2="554.194" y2="1061.45" gradientUnits="userSpaceOnUse">
            <stop offset="0.18" stopColor="#1F55FE"/>
            <stop offset="0.32" stopColor="#0F9AFF"/>
            <stop offset="0.44" stopColor="#05CAFE"/>
            <stop offset="0.56" stopColor="#00EBC1"/>
            <stop offset="0.78" stopColor="#01BF4A"/>
            <stop offset="1" stopColor="#01F2AE"/>
        </linearGradient>
        <linearGradient id="paint4_linear_107_2684" x1="393.234" y1="933.92" x2="486.546" y2="922.38" gradientUnits="userSpaceOnUse">
            <stop offset="0.146297" stopColor="#4C7ABB"/>
            <stop offset="0.36379" stopColor="#3EA8DF"/>
            <stop offset="0.588542" stopColor="#8CC2E6"/>
            <stop offset="0.781989" stopColor="#658FC6"/>
        </linearGradient>
        <linearGradient id="paint5_linear_107_2684" x1="1051.16" y1="917.982" x2="1333.78" y2="834.032" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint6_linear_107_2684" x1="1073.73" y1="951.765" x2="1356.36" y2="867.816" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint7_linear_107_2684" x1="1105.45" y1="987.448" x2="1388.08" y2="903.499" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <clipPath id="clip0_107_2684">
            <rect width="1512" height="1283" fill="white"/>
        </clipPath>
    </defs>
</>;