/* eslint-disable indent */
import React from 'react';

export const constants = {
  INITIAL_PATH: '/portfolio?elem=dashboard',
  NFT_GENERATOR_PATH: 'nft_generator?elem=nft_generator',
  AUTH_ERRORS: {
    COUNTER_ERROR: 'Too many tries of signIn! Please check your credentials',
    ACCOUNT_ERROR: 'Please check your account and reload this page',
  },
  PROJECT_NAME: 'CeDeFiAI',
  THEME: {
    DAY: 'day-theme',
    NIGHT: 'night-theme',
  },
  LANGUAGE_SELECTOR: {
    LIST: [
      {
        id: 'eng',
        label: 'English',
      },
      {
        id: 'ukr',
        label: 'Ukrainian',
      },
      {
        id: 'fra',
        label: 'France',
      },
    ],
  },
  ACCOUNT: {
    HEADING: 'ACCOUNT',
    SELECT_ACCOUNT: 'Select account',
    NET_APY: 'Net APY',
    DAILY_SUMMARY: 'Daily earnings',
    INVESTED_BALANCE: 'Invested balance',
    CUMULATIVE_APY: 'Cumulative apy',
    FARM_COLLECTION: 'to collect from 1 farm',
    HARVEST_BUTTON_DESCRIPTION: 'Harvest all',
  },
  TOKEN_EXPIRE_BEFORE: 60000, // if token is expired less them 1 min
  DONAT_CHART: {
    COLORS: ['#1F55FE', '#F7931A', '#00A859', '#ED32B5', '#05CAFE', '#7828AD'],
    LIMIT: 6,
  },
  TABLE_WIDGET: {
    currency: {
      labelLeft: 'USDT',
      labelRight: 'BTC',
      currencyId: ['USDT', 'BTC'],
    },
    volume: {
      labelRight: 'LOW VOLUME',
      labelLeft: '',
      volumeId: ['low', 'high'],
    },
    addExchange: {
      icon: 'icon-plus',
      label: 'Add exchange',
    },
    select_exchange: {
      title: 'Select exchange',
      defaultExchangeValue: { id: 'all_exchanges', label: 'All exchanges' },
    },
    columns: [
      {
        id: 'COIN',
        name: 'COIN',
      },
      {
        id: 'AMT',
        name: 'AMT',
      },
      {
        id: 'SHARE',
        name: 'SHARE',
      },
      {
        id: 'PRICE',
        name: 'PRICE',
      },
      {
        id: 'CHANGE',
        name: 'CHANGE',
      },
      {
        id: 'TOTAL',
        name: 'TOTAL',
      },
    ],
    initialSort: { column: 'TOTAL', dir: 'desc' },
    listOfAvailableExchanges: [
      {
        id: 'binance',
        label: 'Binance',
      },
      {
        id: 'exchange_2',
        label: 'Exchange#2',
      },
      {
        id: 'exchange_3',
        label: 'Exchange#3',
      },
      {
        id: 'exchange_4',
        label: 'Exchange#4',
      },
    ],
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Element #1' },
        { id: 'update', label: 'Element #2' },
        { id: 'edit', label: 'Element #3' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  BOTS_WIDGET: {
    // title: 'BotsStatistics',
    sortByTitle: 'By',
    findByTitle: 'By',
    addBotTitle: 'Add bot',
    filterList: [
      {
        id: 'reset',
        label: 'Reset',
      },
      {
        id: 'smart_trade',
        label: 'Smart trade',
      },
      {
        id: 'grid',
        label: 'GRID BOT',
      },
      {
        id: 'dca',
        label: 'DCA BOT',
      },
    ],
    sortingList: [
      {
        id: 'reset',
        label: 'Reset',
      },
      {
        id: 'smart_trade',
        label: 'Smart trade',
      },
      {
        id: 'grid',
        label: 'GRID BOT',
      },
      {
        id: 'dca',
        label: 'DCA BOT',
      },
      {
        id: 'startDate',
        label: 'Start date',
      },
    ],
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Element #1' },
        { id: 'update', label: 'Element #2' },
        { id: 'edit', label: 'Element #3' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  BALANCE: {
    TIMER_UPDATE_INTERVAL: 60000,
    LABEL: 'balance',
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Element #1' },
        { id: 'update', label: 'Element #2' },
        { id: 'edit', label: 'Element #3' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  FAVORITES: {
    LABEL: 'watch list',
    preinstalledCoins: ['BTC', 'ETH'],
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Add' },
        { id: 'update', label: 'Update' },
        { id: 'edit', label: 'Edit' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  BACKGROUNDS: ['back_1', 'back_2', 'back_3', 'back_4'],
  BAR_CHART: {
    FOOTER_LEGEND: ['BTC Bitcoin', 'USDT Tether'],
    WIDGET_TITLE: 'Progress',
    STANDARD_OPTIONS_SCHEMA: {
      dropdownClass: 'custom-dropdown',
      classes: '',
      list: [
        { id: 'add', label: 'Element #1' },
        { id: 'update', label: 'Element #2' },
        { id: 'edit', label: 'Element #3' },
      ],
      menuAlign: 'end',
      value: {},
      title: '',
      actionName: '',
      appendToPayload: '',
    },
  },
  EMPTY_STATE: {
    TITLE: 'No data to show!',
    BOTS_INFO: 'Please add bots first!',
    BAR_CHART_INFO: 'Please add exchange first!',
    FEED_INFO: 'Please add feeds first!',
    SIGNAL_INFO: 'Please add signals first!',
  },
  WIDGET_INFO: {
    FEED_LABEL: 'feed',
    SIGNAL_LABEL: 'signals',
    FEED: {
      STANDARD_OPTIONS_SCHEMA: {
        dropdownClass: 'custom-dropdown',
        classes: '',
        list: [
          { id: 'add', label: 'Element #1' },
          { id: 'update', label: 'Element #2' },
          { id: 'edit', label: 'Element #3' },
        ],
        menuAlign: 'end',
        value: {},
        title: '',
        actionName: '',
        appendToPayload: '',
      },
    },
    SIGNAL: {
      STANDARD_OPTIONS_SCHEMA: {
        dropdownClass: 'custom-dropdown',
        classes: '',
        list: [
          { id: 'add', label: 'Element #1' },
          { id: 'update', label: 'Element #2' },
          { id: 'edit', label: 'Element #3' },
        ],
        menuAlign: 'end',
        value: {},
        title: '',
        actionName: '',
        appendToPayload: '',
      },
    },
  },
  WEBSOCKET_PARAMS_LIMIT: 200,
  EXCHANGE_ICONS: [
    {
      icon: 'binance',
    },

    {
      icon: 'binance',
    },
    {
      icon: 'huobi',
    },
  ],
  REFRESH_API: {
    table: {
      url: '/exchanges',
      method: 'GET',
    },
    history: {
      url: '/exchanges',
      method: 'GET',
    },
    bots: {
      url: '/bots',
      method: 'GET',
    },
    favorites: {
      url: '/favorites',
      method: 'GET',
    },
    favorites_delete: {
      url: '/favorites',
      method: 'DELETE',
    },
    favorites_add: {
      url: '/favorites',
      method: 'PUT',
    },
    feeds: {
      url: '/feeds',
      method: 'GET',
    },
    signals: {
      url: '/signals',
      method: 'GET',
    },
    messages: {
      url: '/messages',
      method: 'GET',
    },
  },
  ANALYTICS: {
    SHOW_ALL: 'Show All',
    TOKEN: {
      HEADING: 'TOKEN ALLOCATION',
    },
    PROTOCOL: {
      HEADING: 'Protocols allocation',
    },
    CHAIN: {
      HEADING: 'CHAIN ALLOCATION',
    },
  },
  MESSAGES: {
    ACTIONS: {
      MARKALLREAD: 'mark all read',
      CLEARALL: 'clear all',
      SHOWALL: 'show all',
      HIDEARCHIVED: 'hide archived',
      ARCHIVE: 'ARCHIVE',
      DELETE: 'DELETE',
    },
  },
  POPPER_DATA: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [1, 1],
        },
      },
    ],
  },
  LANGUAGE_POPPER_DATA: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-18, -2],
        },
      },
    ],
  },
  MORE_POPPER_DATA: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  },
  NAV_POPPER_DATA: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -7],
        },
      },
      {
        name: 'arrow',
        options: {
          padding: 30,
        },
      },
    ],
  },
  SMART_TRADE_BOT: {
    TABS: {
      smart: 'SMART',
      active: 'ACTIVE',
      history: 'HISTORY',
    },
    FP_TABS: {
      favorites: 'FAVORITES',
      packs: 'PACKS',
    },
    FAVORITES: {
      LABEL: 'FAVORITES',
      STANDARD_OPTIONS_SCHEMA: {
        dropdownClass: 'custom-dropdown',
        classes: '',
        list: [
          { id: 'add', label: 'Add' },
          { id: 'update', label: 'Update' },
          { id: 'edit', label: 'Edit' },
        ],
        menuAlign: 'end',
        value: {},
        title: '',
        actionName: '',
        appendToPayload: '',
      },
    },
    PACKS: {
      LABEL: 'PACKS',
      STANDARD_OPTIONS_SCHEMA: {
        dropdownClass: 'custom-dropdown',
        classes: '',
        list: [
          { id: 'add', label: 'Add' },
          { id: 'update', label: 'Update' },
          { id: 'edit', label: 'Edit' },
        ],
        menuAlign: 'end',
        value: {},
        title: '',
        actionName: '',
        appendToPayload: '',
      },
    },
    ACCOUNT_SETTINGS: {
      ACCOUNT_EXCHANGE: {
        label: 'Account/exchange',
      },
      MAIN_COIN: {
        label: 'Main coin',
      },
      COIN_PAIR: {
        label: 'Coin pair',
      },
    },
  },
  CHAINS: [
    {
      oldchain: 'ETH',
      chain: 'ETH',
      chainId: 1,
      chainIdHex: '0x1',
      chainName: 'Ethereum',
      nativeTokenSymbol: 'ETH',
      nativeTokenName: 'Ethereum',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/eth',
        'https://mainnet.infura.io/v3/5d7f7dee4ba34447bcfae2d802f7b0b8',
        'https://cloudflare-eth.com',
        'https://main-light.eth.linkpool.io',
        'https://eth-mainnet.public.blastapi.io',
        'https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7',
        'https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79',
        'https://eth-rpc.gateway.pokt.network',
      ],
      blockExplorerUrls: ['https://etherscan.io/'],
      type: 'mainnet',
      rank: 0,
      extCoingecko: {
        tokenId: 'ethereum',
        platform: 'ethereum',
      },
      extAnkr: {
        chain: 'eth',
      },
      symbol: 'eth',
    },
    {
      oldchain: 'BSC',
      chain: 'BSC',
      chainId: 56,
      chainIdHex: '0x38',
      chainName: 'Binance Smart Chain',
      nativeTokenSymbol: 'BNB',
      nativeTokenName: 'BNB',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/bsc',
        'https://bsc-dataseed.binance.org',
        'https://bsc-dataseed2.binance.org',
        'https://bsc-dataseed3.binance.org',
        'https://bsc-dataseed4.binance.org',
        'https://bsc-dataseed2.ninicoin.io',
        'https://bsc-dataseed3.ninicoin.io',
        'https://bsc-dataseed2.defibit.io',
        'https://bsc-dataseed4.defibit.io',
      ],
      blockExplorerUrls: ['https://bscscan.com/'],
      type: 'mainnet',
      rank: 1,
      extCoingecko: {
        tokenId: 'binancecoin',
        platform: 'binance-smart-chain',
      },
      extAnkr: {
        chain: 'bsc',
      },
      symbol: 'bsc',
    },
    {
      oldchain: 'MATIC',
      chain: 'MATIC',
      chainId: 137,
      chainIdHex: '0x89',
      chainName: 'Polygon',
      nativeTokenSymbol: 'MATIC',
      nativeTokenName: 'MATIC',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/polygon',
        'https://matic-mainnet-full-rpc.bwarelabs.com',
        'https://matic-mainnet.chainstacklabs.com',
        'https://polygon-rpc.com',
        'https://poly-rpc.gateway.pokt.network',
        'https://polygon-mainnet.public.blastapi.io',
      ],
      blockExplorerUrls: ['https://polygonscan.com/'],
      type: 'mainnet',
      rank: 2,
      extCoingecko: {
        tokenId: 'matic-network',
        platform: 'polygon-pos',
      },
      extAnkr: {
        chain: 'polygon',
      },
      symbol: 'matic',
    },
    {
      oldchain: 'AVAXc',
      chain: 'AVAXc',
      chainId: 43114,
      chainIdHex: '0xA86A',
      chainName: 'Avalanche C-Chain',
      nativeTokenSymbol: 'AVAX',
      nativeTokenName: 'AVAX',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/avalanche',
        'https://api.avax.network/ext/bc/C/rpc',
        'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
      ],
      blockExplorerUrls: ['https://snowtrace.io/', 'https://avascan.info/'],
      type: 'mainnet',
      rank: 3,
      extCoingecko: {
        tokenId: 'avalanche-2',
        platform: 'avalanche',
      },
      extAnkr: {
        chain: 'avalanche',
      },
      symbol: 'avax',
    },
    {
      oldchain: 'FTM',
      chain: 'FTM',
      chainId: 250,
      chainIdHex: '0xFA',
      chainName: 'Fantom Opera',
      nativeTokenSymbol: 'FTM',
      nativeTokenName: 'Fantom',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/fantom',
        'https://fantom-mainnet.public.blastapi.io',
        'https://rpcapi.fantom.network',
      ],
      blockExplorerUrls: ['https://ftmscan.com/'],
      type: 'mainnet',
      rank: 4,
      extCoingecko: {
        tokenId: 'fantom',
        platform: 'fantom',
      },
      extAnkr: {
        chain: 'fantom',
      },
      symbol: 'ftm',
    },
    {
      oldchain: 'OPTIMISM',
      chain: 'OPTIMISM',
      chainId: 10,
      chainIdHex: '0xA',
      chainName: 'Optimism',
      nativeTokenSymbol: 'ETH',
      nativeTokenName: 'Ethereum',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://rpc.ankr.com/optimism',
        'https://optimism-mainnet.public.blastapi.io',
        'https://mainnet.optimism.io',
      ],
      blockExplorerUrls: ['https://optimistic.etherscan.io/'],
      type: 'mainnet',
      rank: 5,
      extCoingecko: {
        tokenId: 'optimism',
        platform: 'optimistic-ethereum',
      },
      extAnkr: {
        chain: 'optimism',
      },
      symbol: 'op',
    },
    {
      oldchain: 'ARBITRUM',
      chain: 'ARBITRUM',
      chainId: 42161,
      chainIdHex: '0xA4B1',
      chainName: 'Arbitrum One',
      nativeTokenSymbol: 'ETH',
      nativeTokenName: 'Ethereum',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://rpc.ankr.com/arbitrum', 'https://arb1.arbitrum.io/rpc'],
      blockExplorerUrls: ['https://arbiscan.io/'],
      type: 'mainnet',
      rank: 6,
      extCoingecko: {
        tokenId: 'ethereum',
        platform: 'arbitrum-one',
      },
      extAnkr: {
        chain: 'arbitrum',
      },
      symbol: 'arbitrum',
    },
    {
      oldchain: 'Moonbeam',
      chain: 'Moonbeam',
      chainId: 1284,
      chainIdHex: '0x504',
      chainName: 'Moonbeam',
      nativeTokenSymbol: 'GLMR',
      nativeTokenName: 'Glimmer',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://moonbeam.public.blastapi.io',
        'https://moonbeam.public.blastapi.io',
        'https://1rpc.io/glmr',
        'https://rpc.api.moonbeam.network/',
      ],
      blockExplorerUrls: ['https://moonscan.io/', 'https://moonbeam.moonscan.io'],
      type: 'mainnet',
      rank: 7,
      extCoingecko: {
        tokenId: 'moonbeam',
        platform: 'moonbeam',
      },
      symbol: 'moonbeam',
    },
    {
      oldchain: 'KAVA',
      chain: 'KAVA',
      chainId: 2222,
      chainIdHex: '0xa5',
      chainName: 'Kava',
      nativeTokenSymbol: 'KAVA',
      nativeTokenName: 'Kava',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://evm.kava.io', 'https://evm2.kava.io'],
      blockExplorerUrls: ['https://explorer.kava.io/'],
      type: 'mainnet',
      rank: 8,
      extCoingecko: {
        tokenId: 'kava',
        platform: 'kava',
      },
      symbol: 'kava',
    },

    {
      oldchain: 'ETH',
      chain: 'ETH',
      chainId: 5,
      chainIdHex: '0x5',
      chainName: 'Ethereum',
      nativeTokenSymbol: 'ETH',
      nativeTokenName: 'Ether',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://goerli.infura.io/v3/'],
      blockExplorerUrls: ['https://goerli.etherscan.io'],
      type: 'testnet',
      rank: 9,
      symbol: 'eth',
    },
    {
      oldchain: 'BSC',
      chain: 'BSC',
      chainId: 97,
      chainIdHex: '0x61',
      chainName: 'Binance Smart Chain',
      nativeTokenSymbol: 'tBNB',
      nativeTokenName: 'Binance Coin Testnet',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
      blockExplorerUrls: ['https://testnet.bscscan.com/'],
      type: 'testnet',
      rank: 10,
      symbol: 'bsc',
    },
    {
      oldchain: 'MATIC',
      chain: 'MATIC',
      chainId: 80001,
      chainIdHex: '0x13881',
      chainName: 'Polygon Mumbai',
      nativeTokenSymbol: 'MATIC',
      nativeTokenName: 'Matic Token',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      type: 'testnet',
      rank: 11,
      symbol: 'matic',
    },
    {
      oldchain: 'AVAX',
      chain: 'AVAX',
      chainId: 43113,
      chainIdHex: '0xa869',
      chainName: 'Avalanche',
      nativeTokenSymbol: 'AVAX',
      nativeTokenName: 'AVAX',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
      blockExplorerUrls: ['https://cchain.explorer.avax-test.network/'],
      type: 'testnet',
      rank: 12,
      symbol: 'avax',
    },
    {
      oldchain: 'FTM',
      chain: 'FTM',
      chainId: 4002,
      chainIdHex: '0xfa2',
      chainName: 'Fantom',
      nativeTokenSymbol: 'FTM',
      nativeTokenName: 'Fantom',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://rpc.testnet.fantom.network'],
      blockExplorerUrls: ['https://explorer.testnet.fantom.network/'],
      type: 'testnet',
      rank: 13,
      symbol: 'ftm',
    },
    // {
    //   "oldchain": "OPTIMISM",
    //   "chain": "OPTIMISM",
    //   "chainId": 420,
    //   "chainIdHex": "0x1a4",
    //   "chainName": "Optimism Testnet",
    //   "nativeTokenSymbol": "ETH",
    //   "nativeTokenName": "Ethereum",
    //   "nativeTokenDecimals": 18,
    //   "rpcUrls": [
    //     "https://kovan.optimism.io",
    //     "https://kovan-optimistic.etherscan.io"
    //   ],
    //   "blockExplorerUrls": [
    //     "https://kovan-optimistic.etherscan.io/"
    //   ],
    //   "type": "testnet",
    //   "rank": 14
    // },
    {
      oldchain: 'ARBITRUM',
      chain: 'ARBITRUM',
      chainId: 421613,
      chainIdHex: '0x40678D',
      chainName: 'Arbitrum',
      nativeTokenSymbol: 'ETH',
      nativeTokenName: 'Ethereum',
      nativeTokenDecimals: 18,
      rpcUrls: [
        'https://arbitrum-goerli.public.blastapi.io',
        'https://arb-goerli.g.alchemy.com/v2/demo',
        'https://endpoints.omniatech.io/v1/arbitrum/goerli/public',
        'https://goerli-rollup.arbitrum.io/rpc',
      ],
      blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'],
      type: 'testnet',
      rank: 15,
      symbol: 'arbitrum',
    },
    {
      oldchain: 'MOONBEAM',
      chain: 'MOONBEAM',
      chainId: 1287,
      chainIdHex: '0x507',
      chainName: 'Moonbeam TestNet',
      nativeTokenSymbol: 'DEV',
      nativeTokenName: 'Moonbeam',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://rpc.testnet.moonbeam.network'],
      blockExplorerUrls: ['https://moonbase.moonscan.io'],
      type: 'testnet',
      rank: 16,
      symbol: 'moonbeam',
    },
    {
      oldchain: 'KAVA',
      chain: 'KAVA',
      chainId: 2221,
      chainIdHex: '0x7a12',
      chainName: 'Kava',
      nativeTokenSymbol: 'KAVA',
      nativeTokenName: 'Kava',
      nativeTokenDecimals: 18,
      rpcUrls: ['https://evm.testnet.kava.io'],
      blockExplorerUrls: ['https://explorer.testnet.kava.io'],
      type: 'testnet',
      rank: 17,
      symbol: 'kava',
    },

    // {
    // 	"oldchain": "ETH_RINKEBY",
    // 	"chain": "ETH_RINKEBY",
    // 	"chainId": 4,
    //   "chainIdHex": "0x4",
    // 	"chainName": "Rinkeby",
    // 	"nativeTokenSymbol": "ETH",
    // 	"nativeTokenName": "Rinkeby Ethereum",
    // 	"nativeTokenDecimals": 18,
    // 	"rpcUrls": [
    // 		"https://rpc.ankr.com/eth_rinkeby",
    // 		"https://rinkeby.infura.io/v3/5d7f7dee4ba34447bcfae2d802f7b0b8"
    // 	],
    // 	"blockExplorerUrls": [
    // 		"https://rinkeby.etherscan.io/"
    // 	],
    // 	"type": "testnet",
    // 	"rank": 100
    // }
  ],
  ABI_ERC20: [
    'function balanceOf(address owner) view returns (uint256)',
    'function decimals() view returns (uint8)',
    'function symbol() view returns (string)',
    'function name() view returns (string)',
  ],
  COINGECKO: {
    API_KEY: 'CG-AvGdUs5VksNkvpFYvGkETRRm',
    API_SIMPLE_PRICE:
      'https://pro-api.coingecko.com/api/v3/simple/price?ids=%IDS%&vs_currencies=%VS_CURRENCIES%&x_cg_pro_api_key=%API_KEY%',
    API_SIMPLE_TOKEN_PRICE:
      'https://api.coingecko.com/api/v3/simple/token_price/%PLATFORM%?contract_addresses=%CONTRACT_ADDRESSES%&vs_currencies=%VS_CURRENCIES%&x_cg_pro_api_key=%API_KEY%',
  },
  P1INCH: {
    API_URL: 'https://api.1inch.io/v4.0/%CHAIN_ID%/%METHOD%',
  },
  ANKR: {
    API_URL: 'https://rpc.ankr.com/multichain',
  },
  NULL_ADDRESS_0XEE: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  NULL_ADDRESS_0X00: '0x0000000000000000000000000000000000000000',
  FARM_POOLS_SELECT: [
    { label: 'Farm', value: 'farm' },
    { label: 'Pools', value: 'pools' },
  ],
};
