import { action, thunk } from "easy-peasy";
import { utils } from "../../../utils/utils";
import { isEmpty } from "lodash";
import { dynamicSplitEffects } from "./dynamicSplitEffects";
const actionTypes = {
  DYNAMIC_SPLIT_LINK_MODAL_TOGGLED: 'DYNAMIC_SPLIT_LINK_MODAL#TOGGLED',
  DYNAMIC_SPLIT_MORE_CHANGED: 'DYNAMIC_SPLIT_MORE#CHANGED',
  DYNAMIC_SPLIT_URL_OPEN: 'DYNAMIC_SPLIT_URL#OPEN',
};
const modalInvoked = (actions, payload) => {
  const { name, modal, id } = payload;
  const { updateState } = actions;

  if(modal === 'change-link') {
    switch(name) {
      case 'open-modal': updateState({ path: 'workBoard.changeLink.show', value: id }); break;
      case 'close-modal': updateState({ path: 'workBoard.changeLink.show', value: -1 }); break;
    }
  }
};

const openInEmptyFrame = (state, payload) => {
  const { favoritesUrl } = payload;
  state.favoritesUrl = favoritesUrl;

};

const favoritesMenuHandle = (actions, payload) => {
  const { value, id, action, favoritesUrl} = payload;
  console.log('payload:', value.label)

  const { updateState } = actions;
  if (isEmpty(value))
    return;
  switch (value.id) {
    case 'edit':
      actions[actionTypes.DYNAMIC_SPLIT_LINK_MODAL_TOGGLED]({ name: 'open-modal', modal: 'change-link', id });
      break;
    case 'delete':
      action[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]({values: {id}, method: 'delete'})
      break;
      case 'first':
        updateState({ path: 'workBoard.clickedFavorites', value: 'first' });
    break;
    case 'second':
        updateState({ path: 'workBoard.clickedFavorites', value: 'second' });
    break;
    case 'third':
        updateState({ path: 'workBoard.clickedFavorites', value: 'third' });
    break;
    case 'fourth':
        updateState({ path: 'workBoard.clickedFavorites', value: 'fourth' });
    break;
    
  }
  updateState({ path: 'workBoard.clickedUrl', value: favoritesUrl });

};

const actionHandlers = {
  [actionTypes.DYNAMIC_SPLIT_LINK_MODAL_TOGGLED]: thunk((actions, payload) => modalInvoked(actions, payload)),
  [actionTypes.DYNAMIC_SPLIT_MORE_CHANGED]: thunk((actions, payload) => favoritesMenuHandle(actions, payload)),
  [actionTypes.DYNAMIC_SPLIT_URL_OPEN]: action((state, payload) => openInEmptyFrame(state, payload)),
  updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
  actionTypes,
  actionHandlers
};
