import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { liquidatorEffects } from './liquidatorEffects';

const actionTypes = {
    DEFI_LIQUIDATOR_MULTISELECT_SET: 'DEFI_LIQUIDATOR_MULTISELECT#SET',
    DEFI_LIQUIDATOR_SEARCH_SET: 'DEFI_LIQUIDATOR_SEARCH#SET',
    DEFI_LIQUIDATOR_SEARCH_INVOKED: 'DEFI_LIQUIDATOR_SEARCH#INVOKED',
    DEFI_LIQUIDATOR_LOAD_MORE_LOADING: 'DEFI_LIQUIDATOR_LOAD_MORE#LOADING',
    DEFI_LIQUIDATOR_REQUESTED: 'DEFI_LIQUIDATOR#REQUESTED',
    DEFI_LIQUIDATOR_TOGGLE: 'DEFI_LIQUIDATOR#TOGGLE',
    DEFI_LIQUIDATOR_CHOOSE: 'DEFI_LIQUIDATOR#CHOOSE',
    DEFI_LIQUIDATOR_REPAY_COINS_SELECT: 'DEFI_LIQUIDATOR_REPAY_COINS#SELECT',
    DEFI_LIQUIDATOR_SEIZE_COINS_SELECT: 'DEFI_LIQUIDATOR_SEIZE_COINS#SELECT',
    DEFI_LIQUIDATOR_SET_MAX: 'DEFI_LIQUIDATOR_SET#MAX',
    DEFI_LIQUIDATOR_SET_REPAY: 'DEFI_LIQUIDATOR_SET#REPAY',
    DEFI_LIQUIDATOR_PROTOCOL_PICK: 'DEFI_LIQUIDATOR_PROTOCOL#PICK',
    DEFI_LIQUIDATOR_PROTOCOL_CHECKED: 'DEFI_LIQUIDATOR_PROTOCOL#CHECKED',
    DEFI_LIQUIDATOR_BALANCE_GET: 'DEFI_LIQUIDATOR_BALANCE#GET'
};

const multiSelectHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value } = payload;
    const requestBody = {
        selector: value.value,
        page: 0,
        pageSize: 50
    };
    updateState([
        {
            path: 'selector.value',
            value
        },
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        },
        {
            path: 'repayAmount',
            value: 0,
        },
        {
            path: 'pickedAccount',
            value: null,
        }
    ]);
};

const searchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value } = payload;
    updateState({
        path: 'search.value',
        value
    });
};

const searchInvokeHandle = (actions, payload) => {
    const { updateState } = actions;
    const { userWallet, state: { search, tableData, selector } } = payload;
    const requestBody = {
        chain: selector.value.value,
        address: search.value || userWallet,
        page: tableData.pagination.page
    };
    updateState([
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'set'
        },
    ]);
};

const loadMoreHandle = (actions, payload) => {
    const { updateState } = actions;
    const { page, state: { selector, tableData } } = payload;

    if (!tableData.pagination.hasMore)
        return;


    const requestBody = {
        selector: selector.value.value || 'compound',
        page,
        pageSize: 50
    };

    updateState([
        {
            path: 'tableData.pagination.page',
            value: page
        },
        {
            path: 'tableData.pagination.pageSize',
            value: 50
        },
        {
            path: 'searchRequest',
            value: requestBody
        },
        {
            path: 'typeOfFetch',
            value: 'push'
        },
        {
            path: 'tableData.pagination.hasMore',
            value: false
        }
    ]);
};

const fetchHandle = (actions, payload) => {
    const { updateState } = actions;
    const { page, pageSize, state: { selector, searchRequest, typeOfFetch } } = payload;

    updateState({
        path: 'loader',
        value: typeOfFetch === 'set' ? true : false
    });

    if (searchRequest) {
        actions[liquidatorEffects.actionTypes.DEFI_LIQUIDATOR_FETCHED](searchRequest);
        return;
    }

    const requestBody = {
        selector: selector.value.value,
        page,
        pageSize
    };
    actions[liquidatorEffects.actionTypes.DEFI_LIQUIDATOR_FETCHED](requestBody);
};

const toggleHandler = (state, payload) => {
    const { tab } = payload;

    switch(tab) {
    case 'wallet': state.tab = 'wallet'; break;
    case 'protocols': state.tab = 'protocols'; break;
    default: state.tab = 'wallet';
    }
};

const getBalance = async (actions, payload) => {
    const { tokenAddress, tokenAbi } = payload;
    const { updateState } = actions;
    const tokenBalance = await utils.getMetaMaskBalance(tokenAddress, tokenAbi);
    updateState({
        path: 'tokenBalance',
        value: tokenBalance
    });
};

const chooseAccount = (state, payload) => {
    const { id } = payload;

    const account = state.tableData.filteredData.find(account => account.id === id);

    state.pickedAccount = account;
    state.assetsToRepay = utils.getBorrowedTokens(state.pickedAccount.tokens)[0];
    state.assetsToSeize = utils.getSuppliedTokens(state.pickedAccount.tokens)[0];
    state.seizeToken = utils.getSeizeToken(state.assetsToRepay.tokensSeize, state.assetsToSeize);
    state.repayAmount = 0;
};

const selectRepayCoins = (state, payload) => {
    const { value } = payload;
    state.assetsToRepay = value;
    state.seizeToken = utils.getSeizeToken(state.assetsToRepay.tokensSeize, state.assetsToSeize);
    state.repayAmount = 0;
};

const selectSeizeCoins = (state, payload) => {
    const { value } = payload;
    state.assetsToSeize = value;
    state.seizeToken = utils.getSeizeToken(state.assetsToRepay.tokensSeize, state.assetsToSeize);
};

const setMaxValue = (state, payload) => {
    const { max } = payload;
    state.repayAmount = max;
};

const setRepayAmount = (state, payload) => {
    const { value } = payload;
    state.repayAmount = value;
};

const pickProtocol = (state, payload) => {
    const { parentId, id } = payload;
    
    const chain = state.protocols.find(p => p.icon === parentId);
    const pickedProtocol = chain.protocols.find(protocol => protocol.icon === id);
    pickedProtocol.picked = !pickedProtocol.picked;

    const pickedProtocols = chain.protocols.filter(protocol => protocol.picked);

    if(chain.protocols.length !== pickedProtocols.length && chain.checked !== 2) thirdCBoxPosition(chain);
    else if(pickedProtocols.length === 0 && chain.indeterminate) allProtocolsExcluded(chain);
    else if(pickedProtocols.length === chain.protocols.length && chain.indeterminate) allProtocolsIncluded(chain);
    else return;
};

const protocolsChecked = (state, payload) => {
    const { id } = payload;

    const chain = state.protocols.find(protocol => protocol.icon === id);

    switch(chain.checked) {
    case 0: allProtocolsIncluded(chain); break;
    case 1: allProtocolsExcluded(chain); break;
    case 2: allProtocolsIncluded(chain); break;
    default: allProtocolsIncluded(chain); break;
    }
};

const thirdCBoxPosition = chain => {
    chain.indeterminate = true;
    chain.checked = 2;
};

const allProtocolsExcluded = chain => {
    chain.protocols = chain.protocols.map(protocol => {
        protocol.picked = false;
        return protocol;
    });
    chain.checked = 0;
    chain.indeterminate = false;
};

const allProtocolsIncluded = chain => {
    chain.protocols = chain.protocols.map(protocol => {
        protocol.picked = true;
        return protocol;
    });
    chain.checked = 1;
    chain.indeterminate = false;
};

const actionHandlers = {
    [actionTypes.DEFI_LIQUIDATOR_MULTISELECT_SET]: thunk((actions, payload) => multiSelectHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_SEARCH_SET]: thunk((actions, payload) => searchHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_SEARCH_INVOKED]: thunk((actions, payload) => searchInvokeHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_LOAD_MORE_LOADING]: thunk((actions, payload) => loadMoreHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_REQUESTED]: thunk( (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.DEFI_LIQUIDATOR_TOGGLE]: action((state, payload) => toggleHandler(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_CHOOSE]: action((state, payload) => chooseAccount(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_REPAY_COINS_SELECT]: action((state, payload) => selectRepayCoins(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_SEIZE_COINS_SELECT]: action((state, payload) => selectSeizeCoins(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_SET_MAX]: action((state, payload) => setMaxValue(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_SET_REPAY]: action((state, payload) => setRepayAmount(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_PROTOCOL_PICK]: action((state, payload) => pickProtocol(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_PROTOCOL_CHECKED]: action((state, payload) => protocolsChecked(state, payload)),
    [actionTypes.DEFI_LIQUIDATOR_BALANCE_GET]: thunk(async (actions, payload) => getBalance(actions, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};