import React from 'react';

export const constants = {
    PROTOCOLS_SELECT: [{ label: 'Pancake Swap', value: 'pancake' }, { label: 'Trader Joe', value: 'joe' }, { label: 'Quick Swap', value: 'quick' }, { label: 'Select All', value: 'all' }],
    COLORS: ['#1F55FE', '#F7931A', '#00A859', '#ED32B5', '#05CAFE', '#7828AD'],
    BSC_NETWORK: {
        symbol: 'BSC',
        id: 'Binance',
        jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
            <img src="../dashboard-library/nets/bsc.svg"/><div className="ms-2">Binance</div></div>,
        data: {
            'address': '0xbdaf439D9CCdDed8de7F13De8e89d9d9bB294163',
            'USDT_ADDRESS': '0x55d398326f99059ff775485246999027b3197955',
            'chainId': 56,
            'chainName': 'Binance Smart Chain',
            'rpcUrls': ['https://bsc-dataseed.binance.org/'],
            'blockExplorerUrls': ['https://bscscan.com'],
            'nativeCurrency': {
                'symbol': 'BNB',
                'decimals': 18
            }
        }
    },
    ACCOUNT: {
        HEADING: 'ACCOUNT',
        SELECT_ACCOUNT: 'Select account',
        NET_APY: 'Net APY',
        DAILY_SUMMARY: 'Daily earnings',
        INVESTED_BALANCE: 'Invested balance',
        CUMULATIVE_APY: 'Cumulative apy',
        FARM_COLLECTION: 'to collect from 1 farm',
        HARVEST_BUTTON_DESCRIPTION: 'Harvest all',

    },
    ANALYTICS: {
        SHOW_ALL: 'Show All',
        TOKEN: {
            HEADING: 'TOKEN ALLOCATION',
        },
        PROTOCOL: {
            HEADING_POOLS: 'Pools/Vaults allocation',
            HEADING_FARMS: 'Farms/Yields allocation'
        },
        CHAIN: {
            HEADING: 'CHAIN ALLOCATION',
        },
        
    },
    SORT_SELECT: [
        { value: 'Hot', label: 'Hot' },
        { value: 'APR', label: 'APR' },
        { value: 'Earned', label: 'Earned' },
        { value: 'Total staked', label: 'Total staked' },
        { value: 'Latest', label: 'Latest' },
    ],
    SCAN_TOKEN_LINK: 'https://bscscan.com/address/',
    TIPS: {
        REWARDS_TIP:
            'Rewards are distributed and included into your staking balance automatically. There’s no need to manually compound your rewards.',
        LIQUIDITY_TIP: 'Total value of the funds in this farm’s liquidity pool',
        MULTIPLIER_TIP: `The Multiplier represents the proportion of CAKE rewards each farm receives, as a proportion of the CAKE produced each block.
        For example, if a 1x farm received 1 CAKE per block, a 40x farm would receive 40 CAKE per block.
        This amount is already included in all APR calculations for the farm.`
    },
    DIGIT_FORMAT: '0,00.00',
    UNSTAKE_MODAL: {
        HEADER: 'Unstake LP tokens',
        UNSTAKE: 'Unstake',
        BALANCE: 'Balance',
    },
    STAKE_MODAL: {
        HEADER: 'Stake LP tokens',
        STAKE: 'Stake',
        BALANCE: 'Balance',
    },
    CANCEL_BTN: 'Cancel',
    CONFIRM_BTN: 'Confirm',
    ABI: [
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'owner',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'spender',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'from',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: 'to',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'function',
        },
    ],
};
