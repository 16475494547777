import React from 'react';
import './styles.scss';


const renderExample = () => {
    return <>
        <div className="example-container d-flex justify-content-center align-content-center">
            <div className="construction-sign"></div>
        </div>
    </>;
};

export const Page404 = () => {
    return <div className="p-4">
        <h1>404</h1>
        { renderExample() }
    </div>;
};