import React from 'react';
export const lendingConstants = {
    BSC_NETWORK: {
        symbol: 'BSC',
        id: 'Binance',
        jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
            <img src="../dashboard-library/nets/bsc.svg"/><div className="ms-2">Binance</div></div>,
        data: {
            'address': '0xbdaf439D9CCdDed8de7F13De8e89d9d9bB294163',
            'USDT_ADDRESS': '0x55d398326f99059ff775485246999027b3197955',
            'chainId': 56,
            'chainName': 'Binance Smart Chain',
            'rpcUrls': ['https://bsc-dataseed.binance.org/'],
            'blockExplorerUrls': ['https://bscscan.com'],
            'nativeCurrency': {
                'symbol': 'BNB',
                'decimals': 18
            }
        }
    },
    ABI_ERC20: [
	    'function balanceOf(address owner) view returns (uint256)',
	    'function decimals() view returns (uint8)',
	    'function symbol() view returns (string)',
	    'function name() view returns (string)'
    ],
    NULL_ADDRESS: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    ENABLE_BUTTON: 'Enable',
    NETWORKS_LIST: [
        { value: 'BSC', label: 'BSC', protocols: [{ label: 'Venus', value: 'venus' }] },
        { value: 'MATIC', label: 'MATIC', protocols: [{ label: 'Venus', value: 'venus' }] },
        { value: 'FTM', label: 'FTM', protocols: [{ label: 'Venus', value: 'venus' }] },
        { value: 'ETH', label: 'ETH', protocols: [{ label: 'Compound', value: 'compound' }] },
        { value: 'ARBITRUM', label: 'ARBITRUM', protocols: [{ label: 'Venus', value: 'venus' }] },
        { value: 'AVAXc', label: 'AVAXc', protocols: [{ label: 'Joe', value: 'joe' }] },
    ],
    PROTOCOLS_LIST: [
        { value: 'AAVE', label: 'AAVE' },
        { value: 'Venus', label: 'Venus' },
        { value: 'Compound', label: 'Compound' }
    ],
    SWITCH_NETWORK: 'Switch network',
    SWITCH_PROTOCOL: 'Switch protocol',
    ACCOUNT_DATA: {
        MY_ACCOUNT: 'MY ACCOUNT',
        APY: 'Net APY',
        NET_APY_TIP: 'Tip',
        DAILY: 'Daily Earnings',
        SUPPLY: 'Supply Balance',
        BORROW: 'Borrow Balance',
        BORROW_LIMIT: 'Borrow limit used',
        BORROW_LIMIT_TIP: 'Tip',
        LIMIT: 'Limit',
        SAFE_LIMIT: 'Your safe limit',
        SAFE_LIMIT_TIP: 'Tip'
    },
    PROTOCOLS_DATA: {
        SELECT_ASSET: 'Select asset',
        PLACEHOLDER: 'To mint **** with the ***** Protocol, you need to enable it first',
    },
    SUPPLIED_USER: 'SUPPLIED',
    SUPPLY_MARKET: 'SUPPLY MARKET',
    BORROWED_USER: 'BORROWED',
    BORROW_MARKET: 'BORROW MARKET',
    SUPPLIED_TABLE_HEADERS: ['Protocol', 'Assets', 'APY', 'Balance', 'Collateral'],
    SUPPLY_MARKET_TABLE_HEADERS: ['Protocol', 'Assets', 'APY', 'Wallet', 'Collateral'],
    BORROWED_TABLE_HEADER: ['Protocol', 'Assets', 'APY', 'Balance', '% of Limit'],
    BORROW_MARKET_TABLE_HEADERS: ['Protocol', 'Assets', 'APY', 'Wallet', 'Liquidity'],
    MODAL: {
        SUPPLY: 'Supply',
        WITHDRAW: 'Withdraw',
        BORROW: 'Borrow',
        REPAY: 'Repay',
        MAX: 'MAX',
        WITHDRAW_AMOUNT: 'Withdraw amount',
        BORROWABLE_AMOUNT: 'Borrowable amount',
        WALLET_BALANCE: 'Wallet balance',
        SUPPLY_APY: 'Supply APY',
        BORROW_APY: 'Borrow APY',
        DISTRIBUTION_APY: 'Distribution APY',
        CURRENT: 'Current',
        BORROW_LIMIT: 'Borrow Limit',
        BORROW_LIMIT_USED: 'Borrow limit used',
        BORROW_BALANCE: 'Borrow balance',
        DAILY_ERANINGS: 'Daily Earnings',
        SUPPLY_BALANCE: 'Supply Balance',
        BUTTON_WITHDRAW_DISABLED: 'Enter a valid amount to withdraw',
        BUTTON_SUPPLY_DISABLED: 'Enter a valid amount to supply',
        BUTTON_REPAY_DISABLED: 'Enter a valid amount to repay',
        BUTTON_BORROW_DISABLED: 'Enter a valid amount to borrow',
        PLACEHOLDER: 'Enter amount',
        CURRENTLY_BORROWING: 'Currently borrowing'
    }
};
