import React, { useEffect } from "react";
import { VestingState } from "../../../models/vesting-model";
import { vestingConstants } from "../constants";
import { vestingEffects } from "../actions/vestingEffects";
import { dataHelper } from "../dataHelpers/dataHelper";
import { ButtonCustom } from "../../../components/utility/custom-buttons/view";
import {
  ButtonGroup,
  InputGroup,
  FormControl,
  Form,
  Table,
} from "react-bootstrap";
import numeral from "numeral";
import { buyEffects } from "../actions/buyEffects";
import { Loader } from "../../../components/utility/loader/view";

const {
  BUY: { CONTENT, ADDRESS },
  FORMAT,
  USDT_ADDRESSES,
  MAX_SUPPLY
} = vestingConstants;

const BuyForm = (props) => {
  const { userWallet, contractAddress, chain } = props;
  const state = VestingState.useStoreState((state) => state.buy);
  const actions = VestingState.useStoreActions((actions) => actions);

  const changeHandle = (e) =>
    actions[buyEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
      name: e.target.name,
      value: e.target.value,
    });
  const onCancelHandle = () =>
    actions[buyEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
      name: "cancel-buy",
    });

  const submitHandle = (name) => {
    actions[buyEffects.actionTypes.BUY_INVOKED]({
      userWallet,
      contractAddress,
      chain,
      selling: state.selling,
    });
    actions[buyEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({ name });
  };

  return (
    <Form.Group className="w-75">
      <div className="form-item pb-3">
        <Form.Label className="font-weight-medium font-size-16">
          {CONTENT.BUYING}
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={state.buying}
            className="font-size-14 font-weight-regular"
            type="number"
            name="buying"
            size="lg"
            min={100}
            onChange={changeHandle}
          />
          <InputGroup.Text className="right-input-button">
            <img
              width={24}
              height={24}
              src="../coins-full-library/mcs.svg"
              className="token-icon me-2"
            />
            MCS
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="form-item pb-5">
        <Form.Label className="font-weight-medium font-size-16">
          {CONTENT.SELLING}
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={state.selling}
            className="font-size-14 font-weight-regular"
            name="selling"
            type="number"
            size="lg"
            min={10}
            onChange={changeHandle}
          />
          <InputGroup.Text className="right-input-button">
            <img
              width={24}
              height={24}
              src="../coins-full-library/usdt.svg"
              className="token-icon me-2"
            />
            USDT
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <ButtonCustom
          variant="default"
          label="Cancel"
          onClick={onCancelHandle}
          size="md"
          className="me-3"
        />
        {state.selling && state.buying ? (
          <ButtonCustom
            onClick={() => submitHandle("buy-action")}
            variant="primary"
            label={CONTENT.BUY}
            size="md"
          />
        ) : null}
      </div>
    </Form.Group>
  );
};

export const BuyContent = ({ userWallet, chain }) => {
  const state = VestingState.useStoreState((state) => state.buy);
  const actions = VestingState.useStoreActions((actions) => actions);

  const usdtAddress = USDT_ADDRESSES.find(
    (network) => network.chain === chain
  ).usdtAddress;
  const contractAddress = USDT_ADDRESSES.find(
    (network) => network.chain === chain
  ).contractAddress;
  const usdtEnabled = !state.approved.find((network) => network.chain === chain)
    ?.isApprove;

  const buyEnableHandle = () =>
    actions[buyEffects.actionTypes.BUY_ENABLE_INVOKED]({
      userWallet,
      usdtAddress,
      contractAddress,
      chain,
      usdtEnabled,
    });

  const { range, totalAvailable, maxSupply, sold } = state;

  const rangeStyle = { width: `${range}%` };

  return (
    <div className="buy-layout align-items-center mb-5">
      <div className="description-content">
        <div className="buy-heading font-weight-bold font-unbounded">
          {CONTENT.TITLE_1}
        </div>
        <p className="buy-heading font-weight-bold font-unbounded">
          {CONTENT.TITLE_2}
        </p>
        <p className="buy-disclaimer">{CONTENT.DISCLAIMER}</p>
        <div className="d-flex flex-column pb-3">
          <div>
            Contract address:{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://blockscan.com/address/${ADDRESS}`}
            >{`${ADDRESS}`}</a>
          </div>
          <div>Ticker: MCS</div>
          <div>Decimals: 18</div>
        </div>
        <div className="buy-description-info-grid">
          <div>
            <div className="sub-heading d-flex justify-content-start align-items-center pb-3">
              <div className="position-relative">1 USDT</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/usdt.svg"
                className="token-icon ms-2"
              />
              <div className="ms-2">= 10 MCS</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/mcs.svg"
                className="token-icon ms-2"
              />
            </div>
            <div className="sub-heading d-flex justify-content-start align-items-center pb-3">
              <div className="position-relative">Minimum buy = 10 USDT</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/usdt.svg"
                className="token-icon ms-2"
              />
            </div>
          </div>
          <div>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Token</th>
                  <th>Amount</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MCS</td>
                  <td>0 - 20 M</td>
                  <td>0.1$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>20 - 60 M</td>
                  <td>0.105$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>60 - 100 M</td>
                  <td>0.11$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>100 - 150 M</td>
                  <td>0.115$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>150 - 200 M</td>
                  <td>0.12$</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {maxSupply ? (
          <>
            <p className="sub-heading">
              {`MCS sold: ${numeral(maxSupply - totalAvailable).format(
                FORMAT
              )} / ${numeral(MAX_SUPPLY).format(FORMAT)}`}
            </p>
            <div className="buy-range">
              <div className="range-content" style={rangeStyle}></div>
            </div>
          </>
        ) : null}
      </div>
      <div className="buy-controls">
        <div className="buy-controls-section d-flex justify-content-center align-items-center">
          {state.loader ? (
            <Loader size="4x" />
          ) : state.buyFormInvoked ? (
            <BuyForm
              userWallet={userWallet}
              chain={chain}
              contractAddress={contractAddress}
            />
          ) : (
            <div>
              <ButtonCustom
                variant="primary"
                onClick={buyEnableHandle}
                label={usdtEnabled ? CONTENT.BUY : CONTENT.ENABLE}
                size="md"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
