import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { dynamicSplitEffects } from "../actions/dynamicSplitEffects";
import { constants } from "../constants";

const { MODALS: { CHANGE_LINK, CHANGE, CANCEL } } = constants;
const schema = yup.object().shape({
  url: yup.string().url().required()
});

export const ChangeLinkForm = props => {
  const { handleClose, actions, widget } = props;
  const submitHandler = (values) => {
    actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]({values: {...values, id: widget.id}, method: 'put'});
    handleClose();
  };
  return <Formik
    validationSchema={schema}
    initialValues={{
      url: widget.url,
    }}
    onSubmit={submitHandler}
  >{({
       handleSubmit,
       handleChange,
       handleBlur,
       values,
       touched,
       isValid,
       errors,
     }) =>
    <Form
      noValidate
      onSubmit={handleSubmit}
      className="font-inter">
      <Form.Group className="pb-3" controlId="controlSelect">
        {/*<Form.Label>{CHANGE_LINK}</Form.Label>*/}
        <Form.Control
          type="text"
          name="url"
          placeholder={CHANGE_LINK}
          value={values.url}
          isInvalid={!!errors.url}
          onChange={handleChange}
          required>
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.url}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <div className="submit-button text-end pt-3">
          <Button
            onClick={handleClose}
            type="button"
            size="lg"
            className="font-size-14 font-weight-medium">{CANCEL}</Button>
          <Button
            type="submit"
            size="lg"
            className="font-size-14 font-weight-medium">{CHANGE}</Button>
        </div>
      </Form.Group>
    </Form>
  }
  </Formik>;
};
