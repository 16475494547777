import { serviceUtils } from '../../../service/serviceUtils';
import { dashboardEffects } from './dashboardEffects';

const invokeHandle = (actions, payload) => {
    const {url, method, data} = payload;
    serviceUtils.HttpService({
        url,
        method,
        data,
        errorActionType: actions[dashboardEffects.actionTypes.DASHBOARD_DATA_FETCH_FAILED],
        successActionType: actions[dashboardEffects.actionTypes.DASHBOARD_DATA_FETCH_SUCCEEDED],
        ...payload
    });
};

export const dashboardFactory = {
    invokeHandle
};