import { action } from 'easy-peasy';
import { get, set } from 'lodash';

const actionTypes = {};

const setStateHandle = (state, payload, operation) => {
    const { path, value } = payload;
    let previousValue;
    switch (operation) {
    case 'set':
        set(state, path, value);
        break;
    case 'push':
        previousValue = get(state, path);
        set(state, path, [...previousValue, value]);
        break;
    default:
        set(state, path, value);
    }
};

const actionHandlers = {
    updateState: action((state, payload) => setStateHandle(state, payload))
};

export const rootContentEffects = {
    actionTypes,
    actionHandlers
};