import { action, thunk } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { constants } from '../constants';
import { dataHelper } from './dataHelpers/dataHelper';
import { notifyEffects } from '../../../../../components/utility/notifications/notifyEffects';
import { fetchEffects } from './fetchEffects';
import { ethers } from 'ethers';

const {
    NETWORKS,
    BUY_ERRORS: { ACCOUNT_ERROR, COUNTER_ERROR },
    CHANGE_RATE,
    CONTENT: { SIGN_MESSAGE },
} = constants;

const actionTypes = {
    BUY_SIGN_IN_INVOKED: 'BUY_SIGN_IN#INVOKED',
    BUY_SIGN_IN_SUCCEED: 'BUY_SIGN_IN#SUCCEED',
    BUY_STATE_UPDATED: 'BUY_STATE_UPDATED',
    BUY_ENABLE_ACTION_INVOKED: 'BUY_ENABLE_ACTION#INVOKED',
    BUY_UI_ACTIONS_INVOKED: 'BUY_UI_ACTIONS#INVOKED',
    BUY_INVOKED: 'BUY#INVOKED',
    BUY_APPROVE_ACTION_INVOKED: 'BUY_APPROVE_ACTION#INVOKED',
    BUY_TX_SUCCEEDED: 'BUY_TX#SUCCEEDED'
};

const stateUpdate = (state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach((k) => (state.buy[k] = payload[k]));
};

const uiHandle = (state, payload) => {
    const { name, value } = payload;
    switch (name) {
        case 'buying':
            state.buy.buying = value ? parseFloat(value) : '';
            state.buy.selling = value ? value / CHANGE_RATE : '';
            break;
        case 'selling':
            state.buy.selling = value ? parseFloat(value) : '';
            state.buy.buying = value ? value * CHANGE_RATE : '';
            break;
        case 'buy-action':
            state.buy.showModal = false;
            break;
        case 'close-modal':
            state.buy.showModal = false;
            break;
        case 'open-modal':
            state.buy.showModal = true;
            break;
        case 'modal-opened':
            state.buy.selling = '';
            state.buy.buying = '';
            break;
        default:
            return;
    }
};

const signInInvoke = async (actions, payload) => {
    const { counter } = payload;
    const updatedCounter = counter - 1;
    actions[actionTypes.BUY_STATE_UPDATED]({
        counter: updatedCounter,
        error: '',
        loader: true,
    });

    const accounts = await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .catch((err) => console.info(err.message));

    if (!updatedCounter || isEmpty(accounts)) {
        const message = !updatedCounter ? COUNTER_ERROR : ACCOUNT_ERROR;
        actions[actionTypes.BUY_SIGN_IN_INVOKED]({ error: message });
        return;
    }

    const address = dataHelper.createAddress(accounts[0]);
    actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({
        address: address.account,
    });
    actions[actionTypes.BUY_SIGN_IN_SUCCEED]({ address });
    actions[actionTypes.BUY_STATE_UPDATED]({ account: address });
};

const signInSuccessHandle = async (actions, payload) => {
    const { address } = payload;
    const messageToSign = SIGN_MESSAGE; //cognitoUser.challengeParam.message;
    const signature = await window.ethereum
        .request({
            method: 'personal_sign',
            params: [address.account, messageToSign],
        })
        .catch(() => {
            actions[actionTypes.BUY_STATE_UPDATED]({
                loader: false,
                isWalletLogged: false,
            });
        });

    if (!signature) return;

    actions[actionTypes.BUY_STATE_UPDATED]({
        loader: false,
        isWalletLogged: true,
        walletName: 'metamask',
        address: address.account,
    });
    actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({
        address: address.account,
    });
};

const enableHandle = async (actions, payload) => {
    const { data } = payload;
    const { chainId, chainName, rpcUrls, blockExplorerUrls, nativeCurrency } = data;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeCurrency };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        }).then(() => {
            actions[actionTypes.BUY_APPROVE_ACTION_INVOKED](payload);
        });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                }).then(() => {
                    actions[actionTypes.BUY_APPROVE_ACTION_INVOKED](payload);
                });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const txApproveSuccess = async (actions, payload) => {
    const { data, hash } = payload;

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const status = await provider.waitForTransaction(hash);
    if(status.status) {
        actions[actionTypes.BUY_STATE_UPDATED]({
            transactionData: data,
            loader: false
        });
        actions[actionTypes.BUY_UI_ACTIONS_INVOKED]({
            name: 'open-modal',
        });
        const notification = {
            type: 'success',
            text: 'Approve succeeded!',
        };
        notifyEffects.notifyAction(notification);
    }
    else {
        actions[actionTypes.BUY_STATE_UPDATED]({
            loader: false
        });
        const notification = {
            type: 'danger',
            text: 'Something went wrong.',
        };
        notifyEffects.notifyAction(notification);
    }
};

const approveHandle = async (actions, payload) => {
    const approveParameters = dataHelper.getApproveParameters(payload);
    const chainName = NETWORKS.find(
        (net) =>
            net.data.nativeCurrency.symbol ===
            payload.data.nativeCurrency.symbol
    ).symbol;
    const { isApprove } = payload.approved.find(
        (chain) => chain.chain === chainName
    );

    if (isApprove) {
        await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [approveParameters],
            }).then(result => {
                const notification = {
                    type: 'success',
                    text: 'Approve transaction has been sent!',
                };
                notifyEffects.notifyAction(notification);
                actions[actionTypes.BUY_TX_SUCCEEDED]({ hash: result, data: payload });
                actions[actionTypes.BUY_STATE_UPDATED]({
                    loader: true
                });
            })
            .catch((error) => {
                const notification = {
                    type: 'danger',
                    text: error.message || 'Error!',
                };
                notifyEffects.notifyAction(notification);
            });
    } else {
        actions[actionTypes.BUY_STATE_UPDATED]({ transactionData: payload });
        actions[actionTypes.BUY_UI_ACTIONS_INVOKED]({ name: 'open-modal' });
    }
};

const buyHandle = async (action, payload) => {
    const transactionParameters = dataHelper.getTransactionParameters(payload);
    console.info(transactionParameters);
    await window.ethereum
        .request({
            method: 'eth_sendTransaction',
            params: [transactionParameters],
        })
        .then((res) => {
            console.info(res);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = {
                type: 'danger',
                text: error.message || 'Error!',
            };
            notifyEffects.notifyAction(notification);
        });
};

const actionHandlers = {
    [actionTypes.BUY_TX_SUCCEEDED]: thunk(async (actions, payload) => 
        txApproveSuccess(actions, payload)
    ),
    [actionTypes.BUY_SIGN_IN_INVOKED]: thunk(async (action, payload) =>
        signInInvoke(action, payload)
    ),
    [actionTypes.BUY_SIGN_IN_SUCCEED]: thunk(async (action, payload) =>
        signInSuccessHandle(action, payload)
    ),
    [actionTypes.BUY_ENABLE_ACTION_INVOKED]: thunk(async (action, payload) =>
        enableHandle(action, payload)
    ),
    [actionTypes.BUY_INVOKED]: thunk(async (action, payload) =>
        buyHandle(action, payload)
    ),
    [actionTypes.BUY_APPROVE_ACTION_INVOKED]: thunk(async (action, payload) =>
        approveHandle(action, payload)
    ),
    [actionTypes.BUY_UI_ACTIONS_INVOKED]: action((state, payload) =>
        uiHandle(state, payload)
    ),
    [actionTypes.BUY_STATE_UPDATED]: action((state, payload) =>
        stateUpdate(state, payload)
    ),
};

export const connectionWalletEffects = {
    actionHandlers,
    actionTypes,
};
