import React from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import { constants } from '../constants';

import Button from './Button';

const {
  ARTICLES: {
    header: {
      mainTitle,
      updateArticle,
      newArticle,
      updateFilters,
      buttons: { btnBack },
    },
  },
} = constants;

const Header = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  const mode = state.admin.mode;

  const isArticles = mode === '';
  const isUpdateArticle = mode === 'update';
  const isCreateArticle = mode === 'create';
  const isUpdateFilters = mode === 'update-filters';

  const Icon = () => {
    return (
      <span style={{ marginRight: '10px', display: mode ? 'none' : 'block' }}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1353 7.99999C15.1353 8.31509 14.8775 8.5729 14.5624 8.5729H8.26034V14.875C8.26034 15.1917 8.0041 15.4483 7.68742 15.4483C7.37074 15.4483 7.1145 15.1901 7.1145 14.875V8.5729H0.812419C0.495739 8.5729 0.239502 8.31688 0.239502 8.00035C0.239502 7.68488 0.495882 7.42707 0.812419 7.42707H7.1145V1.12499C7.1145 0.808308 7.37074 0.552429 7.68742 0.552429C8.0041 0.552429 8.26034 0.808451 8.26034 1.12499V7.42707H14.5624C14.8775 7.42707 15.1353 7.68488 15.1353 7.99999Z"
            fill="black"
          />
        </svg>
      </span>
    );
  };

  return (
    <div className="header-left">
      <h2>
        {isArticles && mainTitle}
        {isUpdateArticle && updateArticle}
        {isCreateArticle && newArticle}
        {isUpdateFilters && updateFilters}
      </h2>

      <Button
        className="btn btn-light"
        padding="16px 20px"
        onClick={mode === '' ? actions.SHOW_PAGE_NEW_ARTICLE : actions.BACK_TO_ADMIN_ARTICLES}
      >
        {Icon()}
        {mode ? btnBack : newArticle}
      </Button>
    </div>
  );
};

export default Header;
