import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { dataHelper } from '../dataHelpers/dataHelper';
import { defiMultichainEffects } from './defiMultichainEffects';

const actionTypes = {
    DEFI_MULTICHAIN_DASHBOARD_TAB_TOGGLED: 'DEFI_MULTICHAIN_DASHBOARD_TAB#TOGGLED',
    DEFI_MULTICHAIN_DASHBOARD_PROTOCOL_TOGGLED: 'DEFI_MULTICHAIN_DASHBOARD_PROTOCOL#TOGGLED',
    DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED: 'DEFI_MULTICHAIN_DASHBOARD_MODAL#INVOKED',
    DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED: 'DEFI_MULTICHAIN_DASHBOARD_TABLE#COLLAPSED',
    DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING: 'DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE#LOADING',
    DEFI_MULTICHAIN_DASHBOARD_ADDRESS_COPIED: 'DEFI_MULTICHAIN_DASHBOARD_ADDRESS#COPIED',
    DEFI_MULTICHAIN_DASHBOARD_ADD_TOKEN_PROTOCOL_PICKED: 'DEFI_MULTICHAIN_DASHBOARD_ADD_TOKEN_PROTOCOL#PICKED',
    DEFI_MULTICHAIN_DASHBOARD_TOKEN_ADDRESS_SEARCH: 'DEFI_MULTICHAIN_DASHBOARD_TOKEN_ADDRESS#SEARCH',
    DEFI_MULTICHAIN_DASHBOARD_CHECKBOX_CHECKED: 'DEFI_MULTICHAIN_DASHBOARD_CHECKBOX#CHECKED',
    DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_ADD: 'DEFI_MULTICHAIN_DASHBOARD_BLACKLIST#ADD',
    DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_DELETE: 'DEFI_MULTICHAIN_DASHBOARD_BLACKLIST#DELETE'
};

const tabToggle = (actions, payload) => {
    const { tab, tabPlace } = payload;
    const { updateState } = actions;

    switch(tabPlace) {
    case 'top-content': updateState({ path: 'tab', value: tab }); break;
    case 'wallet-modal': updateState({ path: 'modalToken.tab', value: tab });
    }

    updateState({ path: 'tab', value: tab });
};

const protocolToggle = (actions, payload) => {
    const { chain, userWallet, state } = payload;
    const { updateState } = actions;

    updateState({ path: 'chains.picked', value: chain });

    const newChain = chain === 'All' || chain === 'Others' ? '' : `&chain=${chain}`;

    if(chain === 'All') {
        actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED]({ userWallet });
    }
    else if(chain === 'Others'){
        actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED]({ userWallet });
    }
    else {
        actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED]({ userWallet, chain: newChain });
    }
};

const modalInvoked = (actions, payload) => {
    const { name, modal, data, heading, state, id } = payload;
    const { updateState } = actions;

    if(modal === 'modalBlackList') {
        switch(name) {
        case 'open-modal': updateState({ path: 'modalBlackList.show', value: true }); break;
        case 'close-modal': updateState({ path: 'modalBlackList.show', value: false }); break;
        }
    }
    else if(modal === 'token') {
        switch(name) {
        case 'open-modal': updateState([{ path: 'modalToken.show', value: true }, { path: 'modalToken.token', value: dataHelper.getTokenById(id, state.wallet.filteredData) }]); break;
        case 'close-modal': updateState([{ path: 'modalToken.show', value: false }, { path: 'copy.copied', value: false }, { path: 'modalToken.tab', value: 'Details' }]); break;
        }
    }
    else if(modal === 'add-token') {
        switch(name) {
        case 'open-modal': updateState({ path: 'addToken.show', value: true }); break;
        case 'close-modal': updateState({ path: 'addToken.show', value: false }); break;
        }
    }
    else {
        switch(name) {
        case 'open-modal': updateState([{ path: 'modalChart.show', value: true }, { path: 'analytics.analyticsData', value: { data, heading } }]); break;
        case 'close-modal': updateState([{ path: 'modalChart.show', value: false }, { path: 'analytics.analyticsData', value: { data: [], heading: '' } }]); break;
        }
    }
};

const tableCollapse = (state, payload) => {
    const { table } = payload;
    
    switch(table) {
    case 'wallet': state.wallet.open = !state.wallet.open; break;
    case 'venus': state.venus.open = !state.venus.open; break;
    case 'compound': state.compound.open = !state.compound.open; break;
    case 'pools': state.pools.open = !state.pools.open; break;
    case 'farms': state.farms.open = !state.farms.open; break;
    case 'showAllVaults': state.showMore.open = !state.showMore.open; break;
    }
};

const loadMoreHandle = (actions, payload) => {
    const { updateState } = actions;
    const { page, state: { wallet: { data } } } = payload;

    if (!data.pagination.hasMore)
        return;

    updateState([
        {
            path: 'wallet.pagination.page',
            value: page
        },
        {
            path: 'typeOfFetch',
            value: 'push'
        },
        {
            path: 'wallet.pagination.hasMore',
            value: false
        }
    ]);
};

const copyToClipboardHandle = (actions, payload) => {
    const { updateState } = actions;
    updateState({ path: 'copy.copied', value: true });
};

const addTokenProtocolHandle = (state, payload) => {
    const { protocol } = payload;

    const pickedProtocol = state.analytics.token.list.find(p => p.symbol === protocol);

    state.addToken.protocol = pickedProtocol;
};

const searchHandle = (state, payload) => {
    const { value } = payload;

    state.addToken.address = value;
};

const checkboxChangeHandle = (actions, payload) => {
    const { updateState } = actions;
    const { value, state, userWallet } = payload;
    state.wallet.blackList.transportData = [...state.wallet.blackList.tempData, value];

    const { blockchainName, contractAddress } = value;

    if (state.wallet.blackList.tempData.some( token => token.contractAddress === contractAddress ))
        state.wallet.blackList.transportData = state.wallet.blackList.transportData.filter(token => token.contractAddress !== contractAddress);

    updateState([{
        path: 'wallet.blackList.transportData',
        value: state.wallet.blackList.transportData
    },
    {
        path: 'wallet.blackList.tempData',
        value: state.wallet.blackList.transportData
    }]);

    const requestBody = {
        chain: state.chains.picked === 'All' || state.chains.picked === 'Others' ? '' : `&chain=${state.chains.picked}`,
        userWallet,
        page: state.wallet.pagination.page,
        blackListTokens: [...state.wallet.blackList.data, ...state.wallet.blackList.transportData]
    };

    updateState({
        path: 'requestBody',
        value: requestBody
    });
};

const addToBlackList = (actions, payload) => {
    const { updateState } = actions;
    const { state } = payload;

    updateState([{
        path: 'wallet.blackList.data',
        value: state.requestBody.blackListTokens
      // value: state.wallet.blackList.transportData
    },
    {
        path: 'wallet.blackList.transportData',
        value: state.wallet.blackList.transportData
    },
    {
        path: 'wallet.blackList.tempData',
        value: []
    }]);

    
    makeRequests(actions, state.requestBody);
    updateState({

        path: 'wallet.blackList.transportData',
        value: []
    });
}
const deleteFromBlackList = (actions, payload) => {
    const { updateState } = actions;
    const { value: { contractAddress }, state, userWallet } = payload;

    state.wallet.blackList.transportData = state.wallet.blackList.data.filter(token => token.contractAddress !== contractAddress);

    updateState([{
        path: 'wallet.blackList.transportData',
        value: state.wallet.blackList.transportData
    },
    {
        path: 'wallet.blackList.data',
        value: state.wallet.blackList.transportData
    }]);

    const requestBody = {
        chain: state.chains.picked === 'All' || state.chains.picked === 'Others' ? '' : `&chain=${state.chains.picked}`,
        userWallet,
        page: state.wallet.pagination.page,
        blackListTokens: state.wallet.blackList.transportData
    };
    
    makeRequests(actions, requestBody);

    updateState({
        path: 'wallet.blackList.transportData',
        value: []
    });
};

const makeRequests = (actions, requestBody) => {
    actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST](requestBody);
    setTimeout(() => actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED](requestBody), 500);
};

const actionHandlers = {
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_TAB_TOGGLED]: thunk((actions, payload) => tabToggle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_PROTOCOL_TOGGLED]: thunk((actions, payload) => protocolToggle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]: thunk((actions, payload) => modalInvoked(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]: action((state, payload) => tableCollapse(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING]: thunk((actions, payload) => loadMoreHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_ADDRESS_COPIED]: thunk((actions, payload) => copyToClipboardHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_ADD_TOKEN_PROTOCOL_PICKED]: action((state, payload) => addTokenProtocolHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_TOKEN_ADDRESS_SEARCH]: action((state, payload) => searchHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_CHECKBOX_CHECKED]: thunk( (actions, payload) => checkboxChangeHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_ADD]: thunk( (actions, payload) => addToBlackList(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_DELETE]: thunk( (actions, payload) => deleteFromBlackList(actions, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};