import React from 'react'
import { useStoreActions } from 'easy-peasy';
import {CustomCheckbox} from '../../components/utility/custom-checkbox/view'

import {constants} from './constants'

const {CHECKBOX_TEXT_ONE_PART,CHECKBOX_TEXT_SECOND_PART} = constants.TERM_TEXT;

const termCheckbox = () => {
  const toggleTerms = useStoreActions(actions => actions.LOGIN_TERMS_TOGGLE);
  const setTermCookie = useStoreActions(actions => actions.SET_TERM_COOKIE);

  return (
    <div className = "checkboxTerm d-flex items-center">
        <CustomCheckbox onChangeHandle = {setTermCookie} />
        <p className = "ms-2" >{CHECKBOX_TEXT_ONE_PART} <span onClick={ toggleTerms }>{CHECKBOX_TEXT_SECOND_PART}</span> </p>
    </div>
  )
}

export default termCheckbox