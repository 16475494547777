import React from 'react'
import { useStoreState } from 'easy-peasy';
import { GridBotState } from '../../../models/grid-bot-model';
import {Link} from 'react-router-dom'

import {constants} from '../constants'

const {TRADING_VIEW: {SEARCH,EXCHANGE,DISPLAY_RESULTS:{MESSAGE},VALUES,LEFT_COLUMN,CENTER_COLUMN,RIGHT_COLUMN} } = constants;

const TradingView = () => {
  const theme = useStoreState(state => state.root.theme);
  const showBalances = GridBotState.useStoreState(state => state.showBalances);

  return (
    <div>
        <div className="search-by">
            <div className="links">
                <Link to="" className="selected-color">{SEARCH.LINKS.ALL}</Link>
                <Link to="">{SEARCH.LINKS.ENABLE}</Link>
                <Link to="">{SEARCH.LINKS.DISABLE}</Link>
            </div>
            <div className="fields">
                <input type="text" placeholder={SEARCH.PLACEHOLDER.SEARCH} />
                <input type="text" placeholder={SEARCH.PLACEHOLDER.EXCHANGE} />
                <input type="text" placeholder={SEARCH.PLACEHOLDER.EXCHANGE} />
                <button>{SEARCH.BUTTON_CLEAR}</button>
            </div>
        </div>
        <div className="display-results"> 
            <div className="d-none">
                <div className="empty-search-state-icon"></div>
                <p>{MESSAGE}</p>
            </div>

            <div className="results">
                <div className="exchange">
                    <div>
                        <Link to="" className="selected-color">
                            {EXCHANGE.LINKS.EXCHANGE}
                            <i className="fa-solid fa-arrow-down-wide-short fa-xs"></i>
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.BOT}
                            <i className="fa-solid fa-arrow-down-arrow-up fa-xs"></i>
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.PAIR}
                            <i className="fa-solid fa-arrow-down-arrow-up fa-xs"></i>
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.SAID}    
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.VALUE}
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.PROFIT}
                            <i className="fa-solid fa-arrow-down-arrow-up fa-xs"></i>
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.PROFIT_LOSS}
                        </Link>
                    </div>
                    <div>
                        <Link to="">
                            {EXCHANGE.LINKS.STATUS}
                        </Link>
                    </div>
                </div>
                <div className="values">
                    <div className={`left-side ${showBalances ? 'pt-3' : 'pt-4'}`}>
                        <div>
                            <Link to="" className="selected-color">BinanceV2 Futures USDT-M</Link>
                        </div>
                        <div>
                            <Link to="" className="selected-color">{VALUES.LINKS.MY_GRIT_BOT}</Link>
                        </div>
                        <div>
                            <Link to="" className="selected-color">DOTUSDT / USDT</Link>
                        </div>
                        <div>
                            <Link to="" className="down-limit-color">
                                {VALUES.LINKS.SHORT}
                                <i className="fa-light fa-arrow-down"></i>
                            </Link>
                        </div>
                        <div>
                            <Link to="" style={{ color: theme === 'day-theme' ? '#2B1A3D' : '#E6E6E6' }}>0 USDT</Link>
                        </div>
                        <div>
                            <Link to="" className="upper-limit-color">+ 3.5654181 USDT (0.00%)</Link>
                        </div>
                        <div>
                            <Link to="" className="upper-limit-color">+ 3.5654181 USDT (0.00%)</Link>
                        </div>
                        <div>
                            <Link to="" className="down-limit-color">{VALUES.LINKS.STOPPED}</Link>
                        </div>
                    </div>
                    <div className="groupButtons">
                        <Link to="">
                            <i className="fa-light fa-share-nodes fa-lg"></i>
                        </Link>
                        <Link to="">
                            <i className="fa-regular fa-trash-can fa-lg"></i>
                        </Link>
                        <Link to="">
                            <i className="fa-light fa-copy fa-lg"></i>
                        </Link>
                        <Link to="">
                            <i className="fa-thin fa-pen fa-lg"></i>
                        </Link>
                        <Link to="">
                            <i className="fa-solid fa-play fa-lg"></i>
                        </Link>
                    </div>
                </div>
                <div className={`display ${showBalances ? 'display-with' : 'display-with-full'}`}>
                    <div className="left-column">
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.CREATION_DATA}</h5>
                            <p>03/21/2022 2:05PM</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.UPPER_LIMIT_PRICE}</h5>
                            <p className="upper-limit-color">3.5654181 USDT</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.LOWER_LIMIT_PRICE}</h5>
                            <p className="down-limit-color">3.5654181 USDT</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.QUANTITY_PER_GRID}</h5>
                            <p>1.5000000 DOTUSDT</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.ACTIVE_ORDERS}</h5>
                            <p>20 of 20</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.GRID_WIDTH}</h5>
                            <p>0.20% ~ 0.23%</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.UPPER_STOP_LOSS}</h5>
                            <p>2.568 USDT<br /> (Close position and stop bot)</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.LOWER_STOP_LOSS}</h5>
                            <p>2.568 USDT<br/> (Close position and stop bot)</p>
                        </div>
                        <div>
                            <h5>{LEFT_COLUMN.TITLE.INVESTMENT}</h5>
                            <p>0 USDT</p>
                        </div>
                        <div>
                            <Link to="">{LEFT_COLUMN.LINK_NOTE}</Link>
                        </div>
                    </div>

                    <div className="center-column">
                        <div className="buttons">
                            <div>
                                {CENTER_COLUMN.BUTTONS.NOW}
                                <button className="showBtn">{CENTER_COLUMN.BUTTONS.NOW}</button>
                            </div>
                            <div>
                                {CENTER_COLUMN.BUTTONS.WEEK}
                                <button>{CENTER_COLUMN.BUTTONS.WEEK}</button>
                            </div>
                            <div>
                                {CENTER_COLUMN.BUTTONS.MONTH}
                                <button>{CENTER_COLUMN.BUTTONS.MONTH}</button>
                            </div>
                        </div>

                        <div className="current-line">
                            <div className="line">

                            </div>
                            
                            <div className="current">
                                <p className="text">{CENTER_COLUMN.LINE.CURRENT} <span>7.465</span></p>
                            </div>

                            <div className="sl">
                                <p className="text">SL <span>21.405</span></p>
                            </div>

                            <div className="tp">
                                <p className="text">TP <span>23.579</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="right-column">
                        <h5>{RIGHT_COLUMN.TITLE}</h5>

                        <div>
                            <p>
                                <span>03/31/2022 8:04 PM</span>
                                <span className="valute">+ 3.5654181 USDT</span>
                            </p>
                            <p>
                                <span>03/31/2022 8:04 PM</span>
                                <span className="valute">+ 3.5654181 USDT</span>
                            </p>
                            <p>
                                <span>03/31/2022 8:04 PM</span>
                                <span className="valute">+ 3.5654181 USDT</span>
                            </p>
                            <p>
                                <span>03/31/2022 8:04 PM</span>
                                <span className="valute">+ 3.5654181 USDT</span>
                            </p>

                            <button>{RIGHT_COLUMN.BUTTON}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TradingView
