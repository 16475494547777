import { orderBy } from 'lodash';
import Big from 'big.js';
import numeral from 'numeral';
import { debug } from 'easy-peasy';

const getTop4Assets = data => {
  const top = 4;
  const orderedTop = orderBy(data, 'TOTAL-USDT-N', 'desc').slice(0, top);
  return orderedTop.reduce((acc, b) => {
    return [...acc, {
      asset: b.COIN,
      fraction: b['AMT-N']
    }]
  }, []);
};

const changeCalculation = data => {
  const change = Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT']).eq('0') ? '100' : (Big(data['TOTAL-BALANCE-EXCHANGE-USDT']).minus(Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT']))).div(Big(data['OLD-TOTAL-BALANCE-EXCHANGE-USDT'])).times(100);
  return numeral(change).format('0.00');
};

const getRowData = data => {
  const assets = data.reduce((acc, b) => {
    return [...acc, {
      asset: b.COIN,
      amount: b.AMT,
      change: b['CHANGE-USDT'],
      sum: b['TOTAL-USDT'],
      order: b['TOTAL-N']
    }]
  }, []);
  return orderBy(assets, 'order', 'desc');
};

const investedAvailableCalculation = data => {
  const {balances = []} = data || {};
  return balances.reduce((acc, b) => {
    acc.invested = Big(acc.invested).plus(Big(b.locked).times(Big(b['PRICE-USDT']))).valueOf();
    acc.available = Big(acc.available).plus(Big(b.free).times(Big(b['PRICE-USDT']))).valueOf();
    return acc;
  }, {
    available: 0,
    invested: 0
  });
};

const balancesCalculation = data => {
  const {cefi, defi} = data;
  return {
    invested: Big(cefi.invested).plus(Big(defi.invested || 0)).valueOf(),
    available: Big(cefi.available).plus(Big(defi.available || 0)).valueOf(),
    total: Big(cefi.invested || 0).plus(Big(defi.invested || 0)).plus(Big(cefi.available || 0)).plus(Big(defi.available || 0)).valueOf()
  };
};

export const dataHelper = {
  getTop4Assets,
  changeCalculation,
  getRowData,
  investedAvailableCalculation,
  balancesCalculation
};