import React, { useRef } from 'react';
import { useStoreActions, useStoreState, debug } from 'easy-peasy';
import { walletConnectAuthorizationEffect } from './actions/authorizationEffect';
import { ButtonCustom } from '../utility/custom-buttons/view';
import { dataHelper } from './actions/dataHelpers/dataHelper';

const invokeAuth = (actions, counter) => {
    const connector = dataHelper.getConnector();
    connector ?
        actions[walletConnectAuthorizationEffect.actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_IN_INVOKED]({ counter, connector }) :
        connector.connect();
};

const label = state => {
  return <span>
      { state?.loaderWalletConnect ? <i className="fa-solid fa-circle-notch fa-spin"></i> : null }
      <span className="ps-3 pe-3">Connect</span>
  </span>
};

export const WalletConnectAuth = () => {
    const state = useStoreState(state => state.authentication);
    const actions = useStoreActions(actions => actions);
    const counter = state.counter;

    return state?.connectionButton && !state?.error ?
        <ButtonCustom onClick={() => invokeAuth(actions, counter)} disabled={state?.loader} variant="default" label={label(state)}/> : null;
};