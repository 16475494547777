import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import'./styles.scss';
import { useStoreActions } from 'easy-peasy';
import { dropDownEffects } from './dropDownEffects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

const _renderDropdownMenu = (list, value) => {
    
    const onClickItem = () =>
    {
        
    }
    return isEmpty(list) ? null : list.map((el, key) => <Dropdown.Item
        key={key}
        eventKey={el.id}
        className="font-size-12"
        active={value.id === el.id}
        onClick={onClickItem()}
        >
      {list[key].icon && <i className={`me-2 fa-regular fa-lg fa-fw ${list[key].icon}`}></i>}
      {list[key].label}
    </Dropdown.Item>);
};

const iconPosition = (iconPosition, titleClasses, isOpen, value) => {
    if(iconPosition === 'right') {
        return <>
            <span className={`${titleClasses} dropdown-selector-value font-size-12`}>{value}</span>
            <span className="dropdown-selector-icon"><FontAwesomeIcon icon={isOpen}/></span>
        </>;
    }
    else if (iconPosition === 'left') {
        return <>
            <span className="dropdown-selector-icon"><FontAwesomeIcon icon={isOpen}/></span>
            <span className={`${titleClasses} dropdown-selector-value ms-2 font-size-12`}>{value}</span>
        </>;
    }
    else {
        return <>
            <span className={`${titleClasses} dropdown-selector-value font-size-12`}>{value}</span>
        </>;
    }
};

const _renderValue = (v, title, titleClasses, list, iconData) => {
    const value = !title ? ((list || []).find(l => l.id === v.id) || {})['label'] || '' : title;
    const isOpen = iconData.isOpen ? faChevronUp : faChevronDown;

    return iconPosition(iconData.iconPosition, titleClasses, isOpen, value);
};

const _renderSelect = props => {
    const { value, title, titleClasses, icon, list, actionName, toggleActionName, iconPosition, menuAlign, appendToPayload, disabled, popperData } = props;
    const [iconDirection, setState] = useState(false);
    const iconData = {
        icon: icon,
        isOpen: iconDirection || false,
        iconPosition: iconPosition
    };
    const disabledClass = disabled ? 'disabled' : '';
    const action = useStoreActions(state => state);
    const toggle = isOpen => {
      setState(isOpen);
      toggleActionName ? dropDownEffects.isOpenAction(isOpen, action, toggleActionName) : '';
    };
    return <div className={`dropdown-selector ${disabledClass}`}>
        <Dropdown
            onSelect={e => actionName ? dropDownEffects.uiAction(e, list, action, actionName, appendToPayload) : ''}
            onToggle={(isOpen) => toggle(isOpen)}>
            <Dropdown.Toggle
                as="div"
                variant="link"
                size="sm"
                disabled={disabled || false}
                className={'d-flex cursor-pointer align-items-center dropdown-selector-toggle'}>
                { iconData.icon !== 'standard-options-more' ? _renderValue(value, title, titleClasses, list, iconData) : <FontAwesomeIcon icon={faEllipsisV}/> }
            </Dropdown.Toggle>
            { !isEmpty(list) && !disabled ? <Dropdown.Menu
                align={menuAlign || ''}
                popperConfig={popperData || {}}
                className="text-capitalize">
                {_renderDropdownMenu(list, value)}
            </Dropdown.Menu> : null }
        </Dropdown>
    </div>;
};

export const Selector = props => {
    return <>
        {_renderSelect(props)}
    </>;
};