import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { isEmpty } from 'lodash';

const actionTypes = {
    SMART_TRADE_TABLE_ROW_COLLAPSED: 'SMART_TRADE_TABLE#COLLAPSED',
    SMART_TRADE_TABLE_ROW_MORE_OPTIONS_CHANGED: 'SMART_TRADE_TABLE_ROW_MORE_OPTIONS#CHANGED',
    SMART_TRADE_TABLE_ROW_MODAL_TOGGLED: 'SMART_TRADE_TABLE_ROW_MODAL#TOGGLED',
    SMART_TRADE_TABLE_ROW_CONTROLS_TOGGLED: 'SMART_TRADE_TABLE_ROW_CONTROLS#TOGGLED',
    SMART_TRADE_TABLE_ROW_CHAIN_TOGGLED: 'SMART_TRADE_TABLE_ROW_CHAIN#TOGGLED',
    SMART_TRADE_TABLE_ROW_FORM_ELEMENTS_CHANGED: 'SMART_TRADE_TABLE_ROW_FORM_ELEMENTS#CHANGED',
    SMART_TRADE_TABLE_ROW_CLOSE_TRADE: 'SMART_TRADE_TABLE_ROW_CLOSE_TRADE',
    SMART_TRADE_TABLE_ROW_CLOSE_TRADE_SUCCEEDED: 'SMART_TRADE_TABLE_ROW_CLOSE_TRADE#SUCCEEDED',
    SMART_TRADE_TABLE_ROW_CLOSE_TRADE_FAILED: 'SMART_TRADE_TABLE_ROW_CLOSE_TRADE#FAILED',
};

const collapseRow = (state, payload) => {
    const { botId } = payload;

    if (!state.tableData.collapsed.includes(botId)) state.tableData.collapsed = [...state.tableData.collapsed, botId];
    else state.tableData.collapsed = state.tableData.collapsed.filter(row => row !== botId);
};

const tableMenuHandle = (actions, payload) => {
    const { value, id } = payload;

    if (isEmpty(value))
        return;

    switch (value.id) {
        case 'cancel':
            actions[actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'open-modal', modal: 'cancel', id });
            break;
        case 'close':
            actions[actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'open-modal', modal: 'close', id });
            break;
        case 'add-funds':
            actions[actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'open-modal', modal: 'add-funds', id, type: value.id });
            break;
        case 'reduce-funds':
            actions[actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'open-modal', modal: 'add-funds', id, type: value.id });
            break;
    }
};

const modalInvoked = (actions, payload) => {
    const { name, modal, id, type } = payload;
    const { updateState } = actions;

    if (modal === 'cancel') {
        switch (name) {
            case 'open-modal':
                updateState({ path: 'smartTrade.modalCancelTrade.show', value: id });
                break;
            case 'close-modal':
                updateState({ path: 'smartTrade.modalCancelTrade.show', value: -1 });
                break;
        }
    }
    if (modal === 'close') {
        switch (name) {
            case 'open-modal':
                updateState({ path: 'smartTrade.modalCloseTrade.show', value: id });
                break;
            case 'close-modal':
                updateState({ path: 'smartTrade.modalCloseTrade.show', value: -1 });
                break;
        }
    }
    if (modal === 'add-funds') {
        switch (name) {
            case 'open-modal':
                updateState([
                    { path: 'smartTrade.modalAddReduceFunds.show', value: id },
                    { path: 'smartTrade.modalAddReduceFunds.name', value: type }
                ])
                break;
            case 'close-modal':
                updateState({ path: 'smartTrade.modalAddReduceFunds.show', value: -1 });
                break;
        }
    }
    if (modal === 'reduce-funds') {
        switch (name) {
            case 'open-modal':
                updateState([
                    { path: 'smartTrade.modalAddReduceFunds.show', value: id },
                    { path: 'smartTrade.modalAddReduceFunds.name', value: type }
                ])
                break;
            case 'close-modal':
                updateState({ path: 'smartTrade.modalAddReduceFunds.show', value: -1 });
                break;
        }
    }
};

const controlsToggled = (state, payload) => {
    state.tableData.moreOptions.dontShowMessage = !state.tableData.moreOptions.dontShowMessage
};

const chainToggled = (state, payload) => {
    state.tableData.chainId = payload ? payload : window.ethereum.chainId;
};

const stateHandle = (state, payload) => {
    const { name, value } = payload
    let valueValidated;

    switch (name) {
        case 'slider_funds_volume_percent':
            state.tableData.input_funds_volume_percent = value;
            state.tableData.slider_funds_volume_percent = value;
            break;
        case 'input_funds_volume_percent':
            valueValidated = !isNaN(value) ? Math.min(Math.abs(value), 100) : '';
            state.tableData.input_funds_volume_percent = value;
            state.tableData.slider_funds_volume_percent = value;
            break;
        case 'input_funds_volume_value':
            state.tableData.input_funds_volume_value = value;
            break;
        case 'price_selected':
            state.tableData.select_price_value = value;
            break;
        case 'input_price_value':
            state.tableData.input_price_value = value;
            break;
    }
}

const closeTrade = async (actions, payload) => {
    const REQUEST_URL = '/binance_prod/panicsell';

    const { updateState } = actions;
    const { id, botId } = payload;
 
    // testing
    console.log('@closeTrade');
    console.log(payload);
    
    updateState({ path: 'smartTrade.modalCloseTrade.show', value: -1 });

    // load tokens list from 1inch API
    await serviceUtils.HttpService({
        url: `${REQUEST_URL}`,
        method: 'POST',
        data: { 
            e: 'binance-prod', 
            k: id,
            b: botId,
        },
        successActionType: actions[actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE_SUCCEEDED],
        errorActionType: actions[actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE_FAILED],
    });
    //"body": "{\"e\":\"binance-prod\",\"u\":\"5d401532-1825-4bcd-bd19-b8939b1a9ffb\",\"k\":\"01aa5202-09e1-5fbc-b51e-9e9ca1a5d8b1\"}"
}

const closeTradeSucceeded = (state, payload) => {
    const { response } = payload;

    // testing
    console.log('@closeTradeSucceeded');
    console.log(response);
}

const closeTradeFailed = (state, payload) => {
    const { response } = payload;

    // testing
    console.log('@closeTradeFailed');
    console.log(response);
}

const actionHandlers = {
    [actionTypes.SMART_TRADE_TABLE_ROW_COLLAPSED]: action((state, payload) => collapseRow(state, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_MORE_OPTIONS_CHANGED]: thunk((actions, payload) => tableMenuHandle(actions, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]: thunk((actions, payload) => modalInvoked(actions, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_CONTROLS_TOGGLED]: action((state, payload) => controlsToggled(state, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_CHAIN_TOGGLED]: action((state, payload) => chainToggled(state, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_FORM_ELEMENTS_CHANGED]: action((state, payload) => stateHandle(state, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE]: thunk(async (actions, payload) => closeTrade(actions, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE_SUCCEEDED]: action((state, payload) => closeTradeSucceeded(state, payload)),
    [actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE_FAILED]: action((state, payload) => closeTradeFailed(state, payload)),
};

export const tableEffects = {
    actionHandlers,
    actionTypes
};