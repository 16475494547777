import { action, thunk } from 'easy-peasy';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';
import { dynamicSplitFactory } from "./dynamicSplitFactory";

const actionTypes = {
  DYNAMIC_SPLIT_LINKS_INVOKED: 'DYNAMIC_SPLIT_LINKS#INVOKED',
  DYNAMIC_SPLIT_LINKS_SUCCEEDED: 'DYNAMIC_SPLIT_LINKS#SUCCEEDED',
  DYNAMIC_SPLIT_LINKS_FAILED: 'DYNAMIC_SPLIT_LINKS#FAILED'
};

const invokeHandle = (actions, payload) => {
  const { method, values } = payload
  switch (method) {
    case 'get':
      dynamicSplitFactory.fetchHandle(actions, payload)
      break;
    case 'post':
      dynamicSplitFactory.postLinkAction(actions, values)
      break;
    case 'put':
      dynamicSplitFactory.putLinkAction(actions, values)
      break;
    case 'delete':
      dynamicSplitFactory.deleteLinkAction(actions, values.id)
      break;
  }
}

const successHandle = (state, payload) => {
  const { options, response } = payload;
  state.widgets = response.widgets;
  state.favoritesSelector.list = state.widgets.map(({url, name}) => ({label: `${name}- (${url})`, value: url}));
  let text;
  switch (options.method) {
    case 'POST':
      text = 'created';
      break;
    case 'PUT':
      text = 'edited';
      break;
    case 'DELETE':
      text = 'deleted';
      break;
  }
  if (options.method) {
    const notification = { type: 'success', text: `Url was successfully ${text}` };
    notifyEffects.notifyAction(notification);
  }
};

const errorAction = (state, payload) => {
  const { error } = payload;
  const notification = { type: 'danger', text: error.response.data.message };
  notifyEffects.notifyAction(notification);
};

const actionHandlers = {
  [actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]: thunk( async (actions, payload) => invokeHandle(actions, payload)),
  [actionTypes.DYNAMIC_SPLIT_LINKS_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
  [actionTypes.DYNAMIC_SPLIT_LINKS_FAILED]: action((state, payload) => errorAction(state, payload)),
};

export const dynamicSplitEffects = {
  actionTypes,
  actionHandlers
};
