import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Modal } from 'react-bootstrap';
import { StakeContent } from './stakeContent';
import { UnstakeContent } from './unstakeContent';

export const StakeModal = (props) => {
  const { show, actions, state, data, cheff } = props;

  const handleClose = () =>
    actions[uiActions.actionTypes.FARMS_MODAL_INVOKED]({
      name: state.modal.name,
      modal: 'close-modal',
    });

  return (
    <Modal
      show={show}
      size="md"
      centered
      className="add-exchange stake-unstake-modal"
      backdrop="static"
      container={document.querySelector('.wrapper')}
      onHide={handleClose}
    >
      <Modal.Body>
        <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
          <i className="icon-cancel"></i>
        </div>
        {state.modal.name === 'unstake-modal' ? (
          <UnstakeContent cheff={cheff} chain={state.chain} data={data} />
        ) : null}
        {state.modal.name === 'stake-modal' ? (
          <StakeContent cheff={cheff} chain={state.chain} data={data} />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
