import React, { useRef } from 'react';
import './styles.scss';
import CustomScroll from 'react-custom-scroll';
import { cardTemplate } from './templates/cardTemplate';
import { renderControlPanel } from './templates/controlPanelTemplate';
import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { EmptyStateModal } from '../../../../components/utility/empty-state-modal/view';
import { emptyState } from './templates/emptyStateModalTemplate';
import { Loader } from '../../../../components/utility/loader/view';
import { dataHelper } from './dataHelper';


const renderBots = botsData => {
    return botsData.map((bot, key) => {
        return <div key={key} className="bots-grid-widget-container-element">
            {cardTemplate.renderBotCard(bot)}
        </div>;
    });
};

const renderContent = botsData => {
    return <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
        <div className="bots-grid-widget-container">
            {renderBots(botsData)}
        </div>
    </CustomScroll>;
};

export const Bots = props => {
    const {hideControlPanel, title} = props;
    const state = useStoreState(state => state.botsWidget);
    const loader = useStoreState(state => state.loaders);
    const botsContainer = useRef();
    const botsData = dataHelper.sorting(state);
    const emptyStateContent = emptyState.emptyStateContent();
    const isLoading = loader.bots;
    return	<div className="bots-grid-widget-element ps-3 pe-3 pt-3 pb-3">
        

        { !hideControlPanel ? <div className="bots-grid-widget-controls mb-3">
            {renderControlPanel(isLoading, title)}
        </div> : null
        }
        <div ref={botsContainer} className="bots-grid-widget-content position-relative">
            { isLoading ? <Loader size={'6x'} isLoading /> : renderContent(botsData) }
            { isEmpty(state.originalData) && !isLoading ? <EmptyStateModal ref={botsContainer} content={emptyStateContent} /> : null }
        </div>
    </div>;
};