import React from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import SelectedFilters from './SelectedFilters';

import { constants } from '../constants';

const {
  ADMIN_ARTICLES: {
    numArticles: { title },
  },
} = constants;

const NumArticlesAndTags = () => {
  let articles = AcademyState.useStoreState((state) => state.filterArticles);

  let numberOfArticles = articles.length;

  return (
    <div className="numArticles-and-tags">
      <div className="number-of-article">
        {numberOfArticles} {title}
      </div>
      <SelectedFilters />
    </div>
  );
};

export default NumArticlesAndTags;
