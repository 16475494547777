import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/homePage/templates/academyPage/actions/uiActions';
import { constants } from '../pages/homePage/templates/academyPage/constants';
import { firstArticle } from '../pages/homePage/templates/docsPage/dataHelpers/dataHelper';

const {
  ARTICLES: {
    articles,
    categories: { list },
  },
} = constants;

const ACADEMY_MODEL = {
  filters: {
    showFilters: false,
    tags: [],
    types: [],
    times: [],
    selectedFilters: [],
    sendFilters: {
      category: null,
      types: null,
      tags: null,
      timesToRead: null,
    },
    editFilter: null,
  },
  sort: {
    sortType: 'gallery',
  },
  articles: [],
  filterArticles: [],
  editArticle: null,
  fullArticle: null,
  selectedTag: null,
  nextPage: null,
  publicNextPage: 9,
  loadMore: false,
  categories: {
    list: [],
    selectedCategory: 'Academy',
  },
  newArticlePage: {
    showPage: false,
  },
  admin: {
    mode: '',
    filter: {
      search: '',
      published: null,
    },
    message: {
      text: '',
    },
    unpublishFilters: {
      counter: 0,
      filterName: '',
      filterType: '',
      filterValue: false,
    },
  },
  loader: true,
  offset: 0,
  docsFullArticle: firstArticle,

  ...uiActions.actionHandlers,
};

export const AcademyState = createContextStore(ACADEMY_MODEL);
