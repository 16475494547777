import { action, thunk } from 'easy-peasy';
import { utils } from '../../../../../utils/utils';

const actionTypes = {
    TEAM_DEPARTMENT_COLLAPSED: 'TEAM_DEPARTMENT#COLLAPSED',
};

const departmentCollapsed = (state, payload) => {
    const { id } = payload;
    
    if(!state.collapsed.includes(id) && id !== 'ETHERNAL') state.collapsed = [...state.collapsed, id];
    else if (id === 'ETHERNAL') state.collapsed = [...state.collapsed];
    else state.collapsed = state.collapsed.filter(dep => dep !== id);
};

const actionHandlers = {
    [actionTypes.TEAM_DEPARTMENT_COLLAPSED]: action((state, payload) => departmentCollapsed(state, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};