import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  SubTitle
} from 'chart.js';
import { constants } from '../../../constants';

const { TEXT } = constants;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  SubTitle
);

const labels = [0, 1, 2, 3, 4, 5];

const data = {
  labels,
  datasets: [
    {
      label: TEXT.USED_AMOUNT,
      data: [25, 50, 75, 100, 125, 150],
      borderColor: '#F7931A',
      tension: 0.4,
      borderWidth: 1
    },
    {
      label: TEXT.SAFETY_ORDER_NUMBER,
      data: [25, 25, 25, 25, 25, 25],
      borderColor: '#1F55FE',
      tension: 0.4,
      borderWidth: 1
    },
  ],
};

const textOptions = (position, text) => {
  return {
    display: true,
    position: position,
    text: text,
    color: '#707991',
    font: {
      size: 10,
      weight: 400,
      family: 'Inter'
    }
  }
}

const options = {
  responsive: true,
  plugins: {
    title: textOptions('left', TEXT.USED_AMOUNT),
    subtitle: textOptions('bottom', TEXT.SAFETY_ORDER_NUMBER)
  }
};

export const ChartContent = (props) => {
  // const { data } = props;

  // const values = data.map((value) => value.value);
  // const labels = data.map((label) => label.protocol);

  return (
    <Line
      // datasetIdKey='id'
      // updateMode='resize'
      options={options}
      data={data}
    />
  );
};
