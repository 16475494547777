

const getPairsForRequest = coins => {
    return coins.reduce((acc, c) => {
        const coin = c.toLowerCase();
        if (coin === 'usdt')
            return [...acc, `${coin}btc@ticker`];
        else if (coin === 'btc')
            return [...acc, `${coin}usdt@ticker`];
        else
            return [...acc, `${coin}usdt@ticker`, `${coin}btc@ticker`];
    }, []);
};

export const priceDataHelper = {
    getPairsForRequest,
};