/* eslint-disable max-len */
import React from 'react';

export const CREATE_PART = <>
    <g clipPath="url(#clip0_111_1188)">
        <path d="M48.0474 91.9494C53.4345 91.9494 57.8016 89.9514 57.8016 87.4869C57.8016 85.0223 53.4345 83.0244 48.0474 83.0244C42.6603 83.0244 38.2932 85.0223 38.2932 87.4869C38.2932 89.9514 42.6603 91.9494 48.0474 91.9494Z" stroke="url(#paint0_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M47.6262 96.8767C54.4429 96.8767 59.9688 93.9248 59.9688 90.2835C59.9688 86.6422 54.4429 83.6904 47.6262 83.6904C40.8094 83.6904 35.2834 86.6422 35.2834 90.2835C35.2834 93.9248 40.8094 96.8767 47.6262 96.8767Z" stroke="url(#paint1_linear_111_1188)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M68.0037 111.604C70.5865 108.219 70.408 104.339 67.6051 102.937C64.8021 101.534 60.4363 103.141 57.8535 106.526C55.2707 109.911 55.4492 113.791 58.2521 115.193C61.055 116.596 65.4209 114.989 68.0037 111.604Z" stroke="url(#paint2_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M64.9783 110.05C67.5609 106.665 67.3825 102.785 64.5797 101.383C61.7767 99.9806 57.4107 101.588 54.8281 104.972C52.2453 108.357 52.4238 112.237 55.2267 113.64C58.0295 115.042 62.3955 113.435 64.9783 110.05Z" stroke="url(#paint3_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M108.345 83.5736C112.355 81.9086 112.657 75.9027 109.019 70.1593C105.381 64.4158 99.1818 61.1097 95.1718 62.7748C91.1617 64.4399 90.8598 70.4456 94.4976 76.1891C98.1351 81.9325 104.335 85.2387 108.345 83.5736Z" stroke="url(#paint4_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M84.9305 98.5342C89.3494 96.7 89.7598 90.2028 85.847 84.0223C81.9341 77.8417 75.1797 74.3183 70.7607 76.1524C66.3417 77.9866 65.9313 84.4838 69.8442 90.6643C73.757 96.8448 80.5114 100.368 84.9305 98.5342Z" stroke="url(#paint5_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M92.0274 95.5038C96.4463 93.6696 96.8567 87.1726 92.9439 80.9921C89.0309 74.8114 82.2767 71.288 77.8576 73.1222C73.4386 74.9562 73.0282 81.4534 76.9412 87.6339C80.854 93.8144 87.6083 97.338 92.0274 95.5038Z" stroke="url(#paint6_linear_111_1188)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M72.5786 104.538L85.0572 98.5419L92.1543 95.523L129.578 68.7969" stroke="url(#paint7_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M72.1987 103.807C74.5173 102.954 74.1455 98.2511 71.3682 93.3029C68.5909 88.355 64.4599 85.0354 62.1414 85.8886C59.8228 86.7417 60.1945 91.4446 62.9718 96.3926C65.7491 101.341 69.8801 104.66 72.1987 103.807Z" stroke="url(#paint8_linear_111_1188)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M53.5663 74.4256C55.9426 74.4256 57.8691 71.8342 57.8691 68.6377C57.8691 65.441 55.9426 62.8496 53.5663 62.8496C51.1899 62.8496 49.2634 65.441 49.2634 68.6377C49.2634 71.8342 51.1899 74.4256 53.5663 74.4256Z" stroke="url(#paint9_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M53.293 71.2269C54.4764 71.2269 55.4358 69.984 55.4358 68.4509C55.4358 66.9177 54.4764 65.6748 53.293 65.6748C52.1094 65.6748 51.1501 66.9177 51.1501 68.4509C51.1501 69.984 52.1094 71.2269 53.293 71.2269Z" stroke="url(#paint10_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M34.7864 133.618L45.0721 122.868L63.0205 118.1C63.0205 118.1 73.1346 114.345 72.2775 103.817C71.4203 93.2887 57.8948 86.5916 57.8948 86.5916L63.0375 85.4118L70.8376 76.2022L77.9345 73.1833C77.9345 73.1833 132.827 39.3018 135.15 37.2197" stroke="url(#paint11_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M35.2923 92.4072C35.2923 92.4072 31.8638 92.9 26.3181 98.9934C20.7724 105.087 16.8381 104.997 16.8381 104.997" stroke="url(#paint12_linear_111_1188)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M31.4854 135.233C37.3839 132.559 37.6378 123.842 32.0525 115.761C26.4672 107.68 17.1578 103.296 11.2594 105.968C5.36101 108.641 5.1072 117.359 10.6925 125.44C16.2778 133.521 25.587 137.904 31.4854 135.233Z" stroke="url(#paint13_linear_111_1188)" strokeWidth="2.46825" strokeMiterlimit="10"/>
        <path d="M61.0049 56.5869L74.2904 64.5597L71.2905 83.606L67.4333 85.8207L69.5761 67.6604L61.0049 71.2038" stroke="url(#paint14_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M42.1479 102.209L34.0051 109.739L44.7193 120.369L53.7192 109.739L42.1479 102.209Z" stroke="url(#paint15_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M39.5763 107.524L36.5764 110.182L39.5763 112.84L42.5764 110.182L39.5763 107.524Z" stroke="url(#paint16_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M47.2906 108.854L45.5764 110.625L47.2906 112.397L49.0049 110.625L47.2906 108.854Z" stroke="url(#paint17_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M43.8619 112.396L42.1477 114.168L43.8619 115.94L45.5762 114.168L43.8619 112.396Z" stroke="url(#paint18_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M23.291 49.4987L60.5763 38.8682L89.719 65.0014" stroke="url(#paint19_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M24.1479 45.0708L61.4334 34.4404L93.1474 62.7883" stroke="url(#paint20_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M79.8621 108.41L150.575 175.736" stroke="url(#paint21_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M85.0046 106.639L153.575 173.522" stroke="url(#paint22_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M111.147 109.739L78.5762 140.744L137.29 189.025L164.718 163.778L111.147 109.739Z" stroke="url(#paint23_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M87.5759 147.389L117.576 116.384" stroke="url(#paint24_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M96.5762 154.918L125.29 123.912" stroke="url(#paint25_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M115.004 169.092L141.576 139.858" stroke="url(#paint26_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M105.576 162.005L133.861 131.886" stroke="url(#paint27_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M123.576 177.066L149.289 147.832" stroke="url(#paint28_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M132.147 184.152L157.004 156.69" stroke="url(#paint29_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M60.5762 33.9966L68.7191 3.87695" stroke="url(#paint30_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M56.7192 35.3262L65.2906 1.66309" stroke="url(#paint31_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M122.719 68.989C121.69 64.3825 120.004 60.8685 119.29 59.6873L139.432 41.084L142.861 49.9428L122.719 68.989Z" stroke="url(#paint32_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M123.576 64.5594L121.862 60.13L138.147 45.9561L139.862 49.0567L123.576 64.5594Z" stroke="url(#paint33_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M76.4333 48.6136L94.4332 23.3662" stroke="url(#paint34_linear_111_1188)" strokeWidth="1.23413"/>
        <path d="M74.7188 46.3997L92.7185 21.1523" stroke="url(#paint35_linear_111_1188)" strokeWidth="1.23413"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_111_1188" x1="35.8641" y1="83.0244" x2="63.8996" y2="87.2634" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_111_1188" x1="35.4034" y1="88.4305" x2="59.4303" y2="93.9847" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_111_1188" x1="52.0124" y1="114.181" x2="63.9507" y2="95.0349" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint3_linear_111_1188" x1="54.8461" y1="106.077" x2="62.7172" y2="114.502" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_111_1188" x1="86.1028" y1="66.5405" x2="105.99" y2="55.1338" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint5_linear_111_1188" x1="71.013" y1="79.1652" x2="88.1496" y2="71.9544" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint6_linear_111_1188" x1="78.2646" y1="76.073" x2="95.0865" y2="68.9872" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint7_linear_111_1188" x1="72.6118" y1="79.2207" x2="141.433" y2="95.1341" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint8_linear_111_1188" x1="56.8977" y1="87.8181" x2="68.4564" y2="81.7771" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint9_linear_111_1188" x1="48.7921" y1="67.9158" x2="58.173" y2="70.0895" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint10_linear_111_1188" x1="50.9327" y1="67.8783" x2="55.7174" y2="68.5759" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint11_linear_111_1188" x1="47.0778" y1="78.798" x2="117.876" y2="95.1791" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint12_linear_111_1188" x1="18.4925" y1="97.5638" x2="33.457" y2="101.027" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint13_linear_111_1188" x1="-2.08019" y1="112.013" x2="26.8713" y2="93.8791" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint14_linear_111_1188" x1="59.3505" y1="56.5869" x2="78.8605" y2="57.2003" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint15_linear_111_1188" x1="33.4147" y1="111.125" x2="54.2965" y2="111.125" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint16_linear_111_1188" x1="35.8293" y1="107.524" x2="44.5955" y2="108.209" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint17_linear_111_1188" x1="45.1495" y1="108.854" x2="50.1669" y2="109.189" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint18_linear_111_1188" x1="41.7208" y1="112.396" x2="46.738" y2="112.732" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint19_linear_111_1188" x1="15.0198" y1="38.8682" x2="109.737" y2="55.5228" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint20_linear_111_1188" x1="15.8768" y1="34.4404" x2="110.594" y2="51.095" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint21_linear_111_1188" x1="71.0573" y1="108.41" x2="174.457" y2="115.923" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint22_linear_111_1188" x1="76.4667" y1="106.639" x2="176.758" y2="113.751" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint23_linear_111_1188" x1="67.8503" y1="109.739" x2="193.723" y2="119.471" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint24_linear_111_1188" x1="83.8406" y1="116.384" x2="127.743" y2="119.322" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint25_linear_111_1188" x1="93.0008" y1="123.912" x2="135.036" y2="126.605" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint26_linear_111_1188" x1="111.696" y1="139.858" x2="150.6" y2="142.304" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint27_linear_111_1188" x1="102.054" y1="131.886" x2="143.458" y2="134.575" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint28_linear_111_1188" x1="120.374" y1="147.832" x2="158.033" y2="150.124" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint29_linear_111_1188" x1="129.052" y1="156.69" x2="165.447" y2="158.97" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint30_linear_111_1188" x1="59.7758" y1="4.31989" x2="69.2233" y2="4.46146" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint31_linear_111_1188" x1="55.652" y1="1.66309" x2="68.2476" y2="1.88493" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint32_linear_111_1188" x1="116.355" y1="41.084" x2="150.886" y2="43.1017" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint33_linear_111_1188" x1="119.62" y1="45.9561" x2="145.961" y2="47.7191" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint34_linear_111_1188" x1="74.1922" y1="23.3662" x2="100.587" y2="24.668" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint35_linear_111_1188" x1="72.4775" y1="21.1523" x2="98.8722" y2="22.4541" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <clipPath id="clip0_111_1188">
            <rect width="165.373" height="190.056" fill="white" transform="translate(0.579346 0.428711)"/>
        </clipPath>
    </defs>
</>;