import React from "react";
import { CustomTooltip } from "../custom-tooltip/view";
import { Selector } from "../dropdown-selector/view";
import './styles.scss';


function SupportButton(props) {

    const {active} = props;
    const iconLook = active ? 'iconDay' : 'iconNight';


    return (
        <span className="supportBtn">
           <CustomTooltip content={'Coming soon'} trigger={<i className={"fa-thin fa-user-headset"}></i>} />
        </span>
    )
}

export default SupportButton