import React from 'react';
import { POSITION_IMG } from './images/positionImg';
import { CONTENT_IMG } from './images/contentImg';

const positionTemplate = () => POSITION_IMG;

const contentTemplate = () => CONTENT_IMG;

export const templates = {
    contentTemplate,
    positionTemplate
};