export const constants_swap = {
    TAB_LIST: [
        { value: 'Swap', label: 'Swap' },
        { value: 'Limit', label: 'Limit' },
        { value: 'P2P', label: 'P2P' },
    ],
    INIT_DATA: [
        { chain: "ETH", oracle: "0x07D91f5fb9Bf7798734C3f606dB065549F6893bb", buy_token: { coingecko_id: "tether", address: "0xdac17f958d2ee523a2206206994597c13d831ec7" }, tokens: ["ETH", "WETH", "USDC", "DAI", "USDT", "WBTC", "MATH", "CHI"] },
        { chain: "BSC", oracle: "0xfbD61B037C325b959c0F6A7e69D8f37770C2c550", buy_token: { coingecko_id: "tether", address: "0x55d398326f99059ff775485246999027b3197955" }, tokens: ["BNB", "WBNB", "USDC", "DAI", "USDT", "BTCB", "1INCH"] },
        { chain: "POLYGON", oracle: "0x7F069df72b7A39bCE9806e3AfaF579E54D8CF2b9", buy_token: { coingecko_id: "tether", address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f" }, tokens: ["MATIC", "WMATIC", "$DG", "AAVE", "AGA", "ANY"] },
        { chain: "AVALANCHE", oracle: "0xBd0c7AaF0bF082712EbE919a9dD94b2d978f79A9", buy_token: { coingecko_id: "tether", address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7" }, tokens: ["AVAX", "WAVAX", "WETH.e", "WBTC.e", "USDT.e", "USDC.e"] },
        { chain: "FANTOM", oracle: "0xE8E598A1041b6fDB13999D275a202847D9b654ca", buy_token: { coingecko_id: "tether", address: "0x049d68029688eabf473097a2fc38ef61633a3c7a" }, tokens: ["FTM", "WFTM", "DAI", "BTC", "fUSDT", "USDC"] },
        { chain: "OPTIMISM", oracle: "0x11DEE30E710B8d4a8630392781Cc3c0046365d4c", buy_token: { coingecko_id: "tether", address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58" }, tokens: ["ETH", "WETH", "SNX", "DAI", "USDT", "WBTC", "LINK"] },
        { chain: "ARBITRUM", oracle: "0x735247fb0a604c0adC6cab38ACE16D0DbA31295F", buy_token: { coingecko_id: "tether", address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9" }, tokens: ["ETH", "WETH", "BAL", "WETH", "USDT", "WBTC"] },
    ],

    HEADERS : {
        SWAP: 'Swap',
        LIMIT: 'Limit', 
        P2P: 'P2P',
    }
};

