import React from 'react';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import '../styles.scss';
import { PopoverLayout } from '../../../components/utility/popover/view';
import Toggle from 'react-toggle';
import { ProgressBar } from 'react-bootstrap';
import { DashboardLendingState } from '../../../models/dashboard-lending-model';
import { uiActions } from '../actions/uiActions';
import { dataHelper } from '../dataHelpers/dataHelper';
import { useStoreState } from 'easy-peasy';
import { lendingEffects } from '../actions/lendingEffects';

// const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';


const renderRow = (data, index, type, chain, comptroller, protocol, actions, totalBorrowed, usedLimit, userWallet) => {
    const { borrow_amount, borrow_price, cToken, borrow_apy, supply_amount, supply_price, supply_apy, token: { symbol }, price, marketEntered } = data;

    const progressBarNow = dataHelper.percentOfLimit(borrow_price, totalBorrowed, usedLimit);

    const onModalOpen = type => actions[uiActions.actionTypes.LENDING_MODAL_INVOKED]({ name: 'open-modal', pickedAsset: data, type, userWallet, chain });

    const onEnterMarket = () => {
        marketEntered ? 
            null : 
            actions[lendingEffects.actionTypes.LENDING_MARKET_ENTERED]({ comptroller, cTokenAddress: cToken.address, userWallet, protocol: protocol.toLowerCase(), chain });
    };

    return <div className='custom-table-row table-value-row table-body font-size-14 font-weight-regular' onClick={() => onModalOpen(type)} key={`body-${index}`}>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            {/* <PopoverLayout icon={protocol.toLowerCase()} message={protocol} title={false} /> */}
            <img className="me-2" width={22} height={22} src={`../dashboard-library/protocols/${protocol.toLowerCase()}.svg`} />
            <div>{protocol}</div>
        </div>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            <PopoverLayout icon={symbol} message={symbol} title={false} />
            <div>{symbol}</div>
        </div>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            {type === 'supply' ? Number(supply_apy).toFixed(2) : Number(borrow_apy).toFixed(2)}%
        </div>
        <div className='custom-table-body-cell'>
            <div className="usd-price">${type === 'supply' ? supply_price : borrow_price}</div>
            <div className="token-amount">{type === 'supply' ? Number(supply_amount).toFixed(7) : Number(borrow_amount).toFixed(7)} {symbol}</div>
        </div>
        {type === 'supply' ? <div className='custom-table-body-cell d-inline-flex align-items-center'>
            <label className="customized-toggle cursor-pointer">
                <Toggle
                    onChange={onEnterMarket}
                    checked={marketEntered}
                    icons={false}
                    defaultChecked={marketEntered}
                />
            </label>
        </div> : <div className='custom-table-body-cell d-grid align-items-center'><ProgressBar now={progressBarNow} label={`${Number(progressBarNow).toFixed(0)}%`}/></div>}
    </div>;
};

const renderTableBody = (data, type, chain, comptroller, protocol, actions, totalBorrowed, usedLimit, userWallet) => 
    data.map((d, index) => renderRow(d, index, type, chain, comptroller, protocol, actions, totalBorrowed, usedLimit, userWallet));

export const UserTable = props => {
    const { type } = props;
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const state = DashboardLendingState.useStoreState(state => state);
    const totalBorrowed = state.borrowBalance;
    const usedLimit = state.borrowLimitUsed;
    const actions = DashboardLendingState.useStoreActions(actions => actions);
    const tableData = type === 'supply' ? dataHelper.getSortedMarket(state.tableData.supplyMarket, state.tableData.supplyUser).userData
        : dataHelper.getSortedMarket(state.tableData.borrowMarket, state.tableData.borrowUser).userData;
    const isLoading = state.loader;
    const chain = state.networkSelector.value.value;
    const protocol = state.protocolToChain.value.label;
    const comptroller = state.comptroller;
    // const loadFunc = value => actions[uiActions.actionTypes.FARMS_TABLE_LOAD_MORE_LOADING]({ page: value, state });
    
    return <div className="custom-table h-100">
        <div className="scroll-container">
            { isLoading ?
                <Loader size={'6x'}/>
                :
                !isEmpty(tableData) ? renderTableBody(tableData, type, chain, comptroller, protocol, actions, totalBorrowed, usedLimit, userWallet) : <EmptySearchState/> 
            }
        </div>
    </div>;
};