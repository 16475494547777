import React from 'react';
import styles from './styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Loader } from '../../components/utility/loader/view';
// import { LinkContainer } from 'react-router-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { constants } from './constants';

import TermCheckbox from './termCheckbox'

const { HEADER, WALLETS } = constants;

export const Login = () => {
    const state = useStoreState(state => state.authentication);
    const termsAccepted = useStoreState(state => state.loginModal.approveTerms);
    const loginModalToggle = useStoreActions(actions => actions.LOGIN_MODAL_TOGGLE);
    const isLoading = state.loaderLogin;

    return isLoading ?
        <Loader size="6x" classOption="transparent-loader" /> :
        <div className="App wrapper night-theme">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                {/* <LinkContainer to="/home">
                    <img className="logo-monoceros mb-3" src="../dashboard-library/home-page-images/nav_logo.svg" />
                </LinkContainer>
                <h2 className="mb-3">Monoceros Crypto Terminal</h2> */}
                <div className="login-content">
                    <div className="login-main-header login-block">
                        <div className="d-flex login-header align-items-center justify-content-between">
                            <div className="font-size-16 font-weight-medium">{HEADER}</div>
                            <CloseButton onClick={ loginModalToggle } variant="white" />
                        </div>
                        <div className="login-main-content">
                            {!termsAccepted && <TermCheckbox />}

                            {WALLETS.map((wallet, index) =>
                                <div key={index} className="wallet-row align-items-center">
                                    <div>
                                        <img className="wallet-icon" src={`../dashboard-library/home-page-images/${wallet.icon}`} />
                                        <div className="wallet-label font-size-16">{ wallet.wallet }</div>
                                        <div className="wallet-text">Connect your { wallet.wallet } wallet</div>
                                        <div>{ state.error ? <div className="font-size-12 error-message pt-2 text-nowrap">{state.error}</div> : null }</div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        { termsAccepted && wallet.action }
                                        <div className="ps-3 d-flex align-items-center">
                                            <i className="fa-regular fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                {/* <div className="w-50">
                    <h1>Monoceros Crypto Terminal</h1>
                    <p>Monoceros is a multifunctional all-in-one non custodial investing terminal.</p>
                    <p>Our goal is to increase capital efficiency by building a complete set of tools for small individual investors and large institutions as well. 
The terminal aggregates a variety of CeFi and DeFi products - CEX, DEX, AMM, LP’s, liquidation panels and NFT marketplaces allowing users to explore the most attractive investment opportunities. Track and manage funds by creating automated processes from one single UI.</p>
                    { state.error ? <p className="error-message">{state.error}</p> : null }
                    <MetaAuth/>
                </div> */}
            </div>
        </div>;
};
