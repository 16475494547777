import React from 'react';
import { vestingEffects } from '../actions/vestingEffects';
import { Modal } from 'react-bootstrap';
import { vestingConstants } from '../constants';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';

const { VEST: { CONTENT: { CANCEL, WARNING, USDT_TO_PAY } }, USDT_ADDRESSES } = vestingConstants;

export const CancelModal = props => {
    const { show, actions, state, userWallet, chain } = props;
    const contractAddress = USDT_ADDRESSES.find(network => network.chain === chain).contractAddress;
    const handleClose = () => actions[vestingEffects.actionTypes.VEST_MODAL_INVOKED]({ name: 'close-modal' });
    const onExitVesting = () => actions[vestingEffects.actionTypes.VEST_VESTING_EARLY_EXIT]({ userWallet, contractAddress, chain });
    const amountToRepay = Number(state.chainObject?.amountUSDTLocked) * 0.01;

    return <Modal
        show={show}
        size="md"
        centered
        className="add-exchange cancel-vesting-modal"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="font-weight-medium vesting-modal-main-header align-items-center">
                <div>{CANCEL.toUpperCase()}</div>
                <div className="text-end"><i onClick={handleClose} className="fa-light fa-xmark cancel-button"></i></div>
            </div>
            <div className="vesting-modal-main-content">
                <div className="font-size-16 warning-text pb-3">{WARNING}</div>
                <div className="usdt-to-pay pb-3 d-flex align-items-center">
                    <div className="me-5">{USDT_TO_PAY}</div>
                    <div className="usdt-to-pay-value">{amountToRepay} USDT</div>
                </div>
                <div className="cancel-investment-button"><ButtonCustom onClick={onExitVesting} variant="default" label={CANCEL} size="sm" /></div>
            </div>
        </Modal.Body>
    </Modal>;
};