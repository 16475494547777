import React from 'react';
import { useDropzone } from 'react-dropzone';
import { constants } from "../constants";

const { MODAL: { UPLOAD_CV, CHOOSE_FILE, RELEASE_TO_DROP } } = constants;

export const Dropzone = props => {
  const { onDrop, accept, handleRemove, images } = props;
  let { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept,
      onDrop
    });

  const files = acceptedFiles.map((file, index) => (
    <li key={file.path}>
      {file.path}
    </li>
  ));

  return (
    <div>
      { images.length === 0 ?
        <div {...getRootProps({ className: 'dropzone' })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
            {isDragActive ? (
              <p className="dropzone-content">
                {RELEASE_TO_DROP}
              </p>
            ) : (
              <p className="dropzone-content">
                {UPLOAD_CV}
              </p>
            )}
            <p className="dropzone-content">{CHOOSE_FILE}</p>
          </div>
        </div> :
        <div className="dropzone-accepted-files">
          <div className="d-flex align-items-center">
            <img className='img-fluid' src='../dashboard-library/home-page-images/file.svg' alt="File" /> {files}
          </div>
          <i onClick={handleRemove} className="icon-cancel cursor-pointer"></i>
        </div>
      }
    </div>
  );
};
