import { action, thunk } from 'easy-peasy';
import { constants } from '../constants';
import { serviceUtils } from '../../../service/serviceUtils';
import { utils } from '../../../utils/utils';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';

const { NFT_ITEMS } = constants;

const actionTypes = {
  NFT_GENERATOR_ELEMENTS_CHANGED: 'NFT_GENERATOR_ELEMENTS#CHANGED',
  NFT_GENERATOR_TAB_TOGGLE: 'NFT_GENERATOR_TAB#TOGGLE',
  NFT_GENERATOR_ACTIVE_NFT_TOGGLE: 'NFT_GENERATOR_ACTIVE_NFT#TOGGLE',
  NFT_GENERATOR_MODAL_TOGGLED: 'NFT_GENERATOR_MODAL#TOGGLED',
  NFT_GENERATOR_SELECTED_CHAIN_TOGGLE: 'NFT_GENERATOR_SELECTED_CHAIN#TOGGLED',
  NFT_GENERATOR_ITEMS_INIT: 'NFT_GENERATOR_ITEMS#INIT',
  NFT_GENERATOR_IMAGE_CHANGED: 'NFT_GENERATOR_IMAGE#CHANGED',
  NFT_GENERATOR_LOADER_TOGGLED: 'NFT_GENERATOR_LOADER#TOGGLED',
  NFT_GENERATOR_CHAIN_INITIALIZE: 'NFT_GENERATOR_CHAIN#INITIALIZE',
};

const stateHandle = (state, payload) => {
  const { name, value } = payload;

  switch (name) {
    case 'pick_your_avatars': state.togglePickYourAvatars = value; break;
    case 'pick_color': state.pickColor = value; break;
  }
}

const tabHandle = (state, payload) => {
  const { tab, name } = payload;

  state.tabs = tab;
  state.items = NFT_ITEMS[name][tab];
}

const activeNftHandle = (state, payload) => {
  const { item } = payload;

  state.activeItem = item.id;
  state.item = item;

  let index = state.images.findIndex(obj => obj.type === item.type);
  if (index !== -1) state.images[index] = item;
  else state.images.push(item)
}

const selectedChainHandle = async (actions, payload) => {
  const { updateState } = actions;

  // TODO remove type for prod
  const res = await serviceUtils.metamaskSwitchEthereumChain(payload.value, 'testnet');
  if (res.result) {
    updateState({
      path: 'selectChain.selectedChain',
      value: payload.label
    });
  } else {
    const notification = { type: 'danger', text: res.message || 'Error!' };
    notifyEffects.notifyAction(notification);
  }
}

const modalInvoked = (state, payload) => {
  const { name, modal } = payload;

  if(modal === 'select-chain') {
    switch(name) {
      case 'open-modal': state.selectChain.show = true; break;
      case 'close-modal': state.selectChain.show = false; break;
    }
  }
};

const itemsHandle = (state, payload) => {
  state.items = NFT_ITEMS[payload].body;
  state.item = NFT_ITEMS[payload].body[0];
  state.images = payload !== 'mintTrial' ?
    [NFT_ITEMS[payload].background[0], state.item] :
    [state.item];
}

const imageHandle = (state, payload) => {
  state.image = payload;
}

const loaderHandle = (state, payload) => {
  const { name } = payload;

  switch (name) {
    case 'start-loader': state.loader = true; break;
    case 'stop-loader': state.loader = false; break;
  }
}

const chainInitialize = (state, payload) => {
  let arrChains = [];
  // TODO change type to 'mainnet' for prod
  serviceUtils.getFilteredChains('testnet').forEach(ch => {
    arrChains.push({ value: ch.chain, label: ch.chainName, symbol: ch.symbol })
  });
  state.chains = arrChains;
}

const actionHandlers = {
  [actionTypes.NFT_GENERATOR_ELEMENTS_CHANGED]: action((state, payload) => stateHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_TAB_TOGGLE]: action((state, payload) => tabHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_ACTIVE_NFT_TOGGLE]: action((state, payload) => activeNftHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_SELECTED_CHAIN_TOGGLE]: thunk(async (action, payload) => selectedChainHandle(action, payload)),
  [actionTypes.NFT_GENERATOR_MODAL_TOGGLED]: action((state, payload) => modalInvoked(state, payload)),
  [actionTypes.NFT_GENERATOR_ITEMS_INIT]: action((state, payload) => itemsHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_IMAGE_CHANGED]: action((state, payload) => imageHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_LOADER_TOGGLED]: action((state, payload) => loaderHandle(state, payload)),
  [actionTypes.NFT_GENERATOR_CHAIN_INITIALIZE]: action((state, payload) => chainInitialize(state, payload)),

  updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const nftGeneratorEffects = {
  actionTypes,
  actionHandlers
};
