import React, { useRef, useEffect } from 'react';
import { DashboardLendingState } from '../../models/dashboard-lending-model';
import CustomScroll from 'react-custom-scroll';
import './styles.scss';
import { useStoreState } from 'easy-peasy';
import { lendingConstants } from './constants';
import { MultiplyCustomSelector } from '../../components/utility/multiply-selector/view';
import { factory } from './actions/factory';
import { SupplyContent } from './templates/supplyContent';
import { BorrowContent } from './templates/borrowContent';
import { uiActions } from './actions/uiActions';
import { ModalTemplate } from './templates/modals/modalTemplate';
import { isNull } from 'lodash';

import { AnalyticsContent } from './templates/lendingAnalyticsContent';
import { mockData } from '../poolsPage/mockData';

const { SWITCH_NETWORK, SWITCH_PROTOCOL } = lendingConstants;

const DashboardLendingLayout = () => {
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const state = DashboardLendingState.useStoreState(state => state);
    const actions = DashboardLendingState.useStoreActions(actions => actions);
    const ref = useRef();
    const pageId = 3;
    const onChangeHandle = value => actions[uiActions.actionTypes.LENDING_NETWORKS_SELECT_SET]({ userWallet, value });
    useEffect(() => {
        actions[factory.actionTypes.LENDING_USER_FETCHED]({ userWallet, protocol: state.protocol });
        actions[factory.actionTypes.LENDING_MARKET_FETCHED]({ protocol: state.protocol, userWallet });
        actions[factory.actionTypes.FETCH_ALL_LENDING_DATA]({ protocol: state.protocol, userWallet });
    }, []);
    
    
    return <div ref={ref} className="page-container">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
        <div className='defi-multichain-dashboard-layout font-inter content'>
            <AnalyticsContent actions = {actions} mockData ={mockData} state = {state} pageId={pageId}/> 
             </div>
            <div className="dashboard-lending-layout font-inter">
                <div className="dashboard-lending-layout-controls">
                    <div>{SWITCH_NETWORK}:</div>
                    <div className="multiple-selector me-3">
                        <MultiplyCustomSelector
                            options={state.networkSelector.list}
                            onChangeHandle={onChangeHandle}
                            closeMenuOnSelect={true}
                            isDisabled={state.networkSelector.isDisabled}
                            isMulti={false}
                            isLoading={state.networkSelector.isLoading}
                            value={state.networkSelector.value}
                        />
                    </div>
                    <div>{SWITCH_PROTOCOL}:</div>
                    <div className="multiple-selector">
                        <MultiplyCustomSelector
                            options={state.protocolToChain.list}
                            onChangeHandle={onChangeHandle}
                            closeMenuOnSelect={true}
                            isDisabled={state.networkSelector.isDisabled}
                            isMulti={false}
                            isLoading={state.networkSelector.isLoading}
                            value={state.protocolToChain.value}
                        />
                         
                    </div>
                   
                </div>
            
                <div className="dashboard-lending-layout-content">
                    <SupplyContent/>
                    <BorrowContent/>
                </div>
                
            </div>
        </CustomScroll>
        {!isNull(state.modal.pickedAsset) && <ModalTemplate chain={state.networkSelector.value.value} limit={state.limit} userWallet={userWallet} protocol={state.protocol}
            usedLimit={state.borrowLimitUsed} totalBorrowed={state.borrowBalance} show={state.modal.show} asset={state.modal.pickedAsset} />}
    </div>;
        
};


export const DashboardLendingPage = () => {
    return <DashboardLendingState.Provider>
        <DashboardLendingLayout />
    </DashboardLendingState.Provider>;
};
