import React from 'react';
import { BorderedButton } from '../../../../components/utility/bordered-button/view';
import { constants } from '../../constants';
import { TableImagesGenerator } from './imagesGenerator/tableImagesGenerator';
import { ImagesGenerator } from './imagesGenerator/imagesGenerator';
import './styles.scss';

const { BUILD_CONTENT: { START, BUILDING, LIQUID_LAUNCH } } = constants;

export const BuildPage = () => {
    return (
        <>
            <div className='build-page'>
                <div className='build-start-content'>
                    <div className='left-column font-inter'>
                        <p className='build-start-heading font-weight-bold'>
                            {START.LABEL}
                        </p>
                        <p className='build-start-description font-size-20'>
                            {START.DESCRIPTION}
                        </p>
                        <div className='d-inline-flex'>
                            <BorderedButton>
                                {START.BUTTON}
                            </BorderedButton>
                        </div>
                    </div>
                    <div className='circle-img d-flex'>
                        <img src='../dashboard-library/home-page-images/satellite.svg' />
                    </div>
                </div>
                <div className='building font-inter'>
                    <p className='building-heading d-flex justify-content-center font-weight-bold'>
                        {BUILDING.LABEL}
                    </p>
                    <p className='building-description text-center d-flex justify-content-center font-weight-regular'>
                        {BUILDING.DESCRIPTION}
                    </p>
                    <div className='building-content'>
                        <TableImagesGenerator />
                    </div>
                </div>
                <div className='liquid-launch'>
                    <p className='text-center font-size-20 d-flex justify-content-center font-weight-regular'>
                        {LIQUID_LAUNCH.TOPIC}
                    </p>
                    <p className='liquid-launch-heading d-flex justify-content-center font-weight-bold'>
                        {LIQUID_LAUNCH.LABEL}
                    </p>
                    <div className='liquid-launch-content'>
                        <ImagesGenerator />
                    </div>
                </div>
            </div>
        </>
    );
};
