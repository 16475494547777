import React from 'react';
import Button from 'react-bootstrap/Button';
import { InputGroup, FormControl } from 'react-bootstrap';

export const ChoiceSelector = () => {
  return (
    <div className="choice-selector">
      <div className="input-titles d-flex">
        <div className="date-title">
          Date
          <InputGroup className="input-group">
            <FormControl
              placeholder="Enter date"
              className="transparent-input"
              icon="fa fa-chevron-down"
            />
          </InputGroup>
        </div>
        <div className="keywords-title">
          Keywords
          <InputGroup className="input-group">
            <FormControl placeholder="-" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="coins-title">
          Coins
          <InputGroup className="input-group">
            <FormControl placeholder="Top 300 coins" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="exchanges-title">
          Exchanges
          <InputGroup className="input-group">
            <FormControl placeholder="All" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="categories-title">
          Categories
          <InputGroup className="input-group">
            <FormControl placeholder="All" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="sort-title">
          Sort-by
          <InputGroup className="input-group">
            <FormControl placeholder="Select" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="show-title">
          Show only
          <InputGroup className="input-group">
            <FormControl placeholder="Select" className="transparent-input" />
          </InputGroup>
        </div>
        <div className="buttons">
          <div className="button-clear">
            <Button variant="secondary" size="md">
              Clear
            </Button>
          </div>
          <div className="button-search">
            <Button variant="secondary" size="md">
              Search
            </Button>
          </div>
        </div>
      </div>

      <div className="horizontal-divider"></div>

      <div className="selector-wrapper d-flex">
        <div className="selector-value">Top 100 coins</div>
        <div className="selector-value">Top 300 coins</div>
        <div className="selector-value">Top 500 coins</div>
        <div className="selector-value">DeFi coins</div>
        <div className="selector-value">Major categories</div>
      </div>
    </div>
  );
};
