/* eslint-disable max-len */
import React from 'react';

export const LAUCH_PART = <>
    <g clipPath="url(#clip0_111_1147)">
        <path d="M68.0598 68.1126L45.3994 42.3862L51.8739 39.5498L74.1135 63.9241L84.6235 66.5998C84.6235 66.5998 69.29 72.509 68.0598 76.2058C66.8298 79.9025 68.0598 68.1126 68.0598 68.1126Z" stroke="url(#paint0_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M50.5349 39.0882C52.9599 34.7564 49.6257 28.9667 43.0874 26.1565C36.5491 23.3463 29.2828 24.5797 26.8578 28.9114C24.4326 33.2432 27.7668 39.0329 34.3051 41.8431C40.8434 44.6533 48.1097 43.42 50.5349 39.0882Z" stroke="url(#paint1_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M53.3281 35.0918C55.7436 30.7559 52.1058 24.8473 45.2026 21.8946C38.2994 18.9419 30.7449 20.0631 28.3293 24.3989C25.9137 28.7348 29.5515 34.6434 36.4547 37.5961C43.3579 40.5488 50.9124 39.4276 53.3281 35.0918Z" stroke="url(#paint2_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M42.9712 26.0747L55.1107 13.7929L59.9665 11.2969L57.9487 18.6906L47.0176 28.4573L42.9712 26.0747Z" stroke="url(#paint3_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M1.49194 3.43158L28.3284 24.459L4.98813 2.00391L1.49194 3.43158Z" stroke="url(#paint4_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M84.6233 66.6172L79.0662 40.957L87.4613 63.1285L84.6233 66.6172Z" stroke="url(#paint5_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M185.678 13.2363L145.062 26.7662L135.566 33.9895L104.035 50.6584L74.1129 63.9423L68.0486 76.2052L76.6272 74.4749L39.1404 93.1293" stroke="url(#paint6_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M115.525 47.1122L108.516 8.19727" stroke="url(#paint7_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M109.392 5.73615C106.982 5.32513 104.791 6.05977 104.499 7.37702C104.206 8.69428 105.923 10.0953 108.333 10.5064C110.743 10.9174 112.934 10.1827 113.227 8.86547C113.519 7.54821 111.803 6.14718 109.392 5.73615Z" stroke="url(#paint8_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M127.991 59.3291C131.084 59.3291 133.592 58.1311 133.592 56.6533C133.592 55.1755 131.084 53.9775 127.991 53.9775C124.898 53.9775 122.391 55.1755 122.391 56.6533C122.391 58.1311 124.898 59.3291 127.991 59.3291Z" stroke="url(#paint9_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M144.479 76.5086C147.429 76.5086 149.821 74.9508 149.821 73.0292C149.821 71.1075 147.429 69.5498 144.479 69.5498C141.53 69.5498 139.138 71.1075 139.138 73.0292C139.138 74.9508 141.53 76.5086 144.479 76.5086Z" stroke="url(#paint10_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M153.133 85.6502C154.969 85.6502 156.456 84.3379 156.456 82.7191C156.456 81.1005 154.969 79.7881 153.133 79.7881C151.298 79.7881 149.809 81.1005 149.809 82.7191C149.809 84.3379 151.298 85.6502 153.133 85.6502Z" stroke="url(#paint11_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M162.402 103.378C163.66 103.378 164.679 101.41 164.679 98.9814C164.679 96.5533 163.66 94.585 162.402 94.585C161.145 94.585 160.125 96.5533 160.125 98.9814C160.125 101.41 161.145 103.378 162.402 103.378Z" stroke="url(#paint12_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M160.125 104.078C161.299 104.078 162.252 101.796 162.252 98.9818C162.252 96.1673 161.299 93.8857 160.125 93.8857C158.952 93.8857 158 96.1673 158 98.9818C158 101.796 158.952 104.078 160.125 104.078Z" stroke="url(#paint13_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M201.065 87.2293C202.067 87.2293 202.878 86.0228 202.878 84.5346C202.878 83.0463 202.067 81.8398 201.065 81.8398C200.064 81.8398 199.252 83.0463 199.252 84.5346C199.252 86.0228 200.064 87.2293 201.065 87.2293Z" stroke="url(#paint14_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M186.077 97.1474C187.347 97.1474 188.376 96.2371 188.376 95.1143C188.376 93.9913 187.347 93.0811 186.077 93.0811C184.808 93.0811 183.779 93.9913 183.779 95.1143C183.779 96.2371 184.808 97.1474 186.077 97.1474Z" stroke="url(#paint15_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M79.1851 110.251C80.5378 110.251 81.6346 109.282 81.6346 108.086C81.6346 106.89 80.5378 105.921 79.1851 105.921C77.8322 105.921 76.7356 106.89 76.7356 108.086C76.7356 109.282 77.8322 110.251 79.1851 110.251Z" stroke="url(#paint16_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M114.017 63.9428L117.438 65.8243V47.1982H114.017V63.9428Z" stroke="url(#paint17_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M132.307 35.7095C132.307 35.7095 169.525 37.6477 185.289 74.3606L192.757 69.5386C192.757 69.5386 174.509 30.3202 138.21 31.9747L132.307 35.7095Z" stroke="url(#paint18_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M156.456 59.3283V56.5107L164.668 63.9422L161.895 65.5873" stroke="url(#paint19_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M162.532 53.1449V49.4482L171.347 58.2505L166.934 59.3283" stroke="url(#paint20_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M186.078 105.315C186.078 105.315 190.847 92.854 188.376 87.2284L194.948 82.709C194.948 82.709 194.753 93.3551 192.789 99.671L186.078 105.315Z" stroke="url(#paint21_linear_111_1147)" strokeWidth="1.23413" strokeMiterlimit="10"/>
        <path d="M102.59 127.885L137.455 104.324" stroke="url(#paint22_linear_111_1147)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M102.59 100.221L100.475 107.87L112.269 107.577L102.59 100.221Z" stroke="url(#paint23_linear_111_1147)" strokeWidth="0.617063" strokeMiterlimit="10"/>
        <path d="M114.017 94.0176L122.391 101.071L116.165 103.264L114.017 94.0176Z" stroke="url(#paint24_linear_111_1147)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_111_1147" x1="41.8061" y1="55.2257" x2="86.5817" y2="64.7952" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint1_linear_111_1147" x1="27.4751" y1="27.0387" x2="47.4536" y2="43.6103" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_111_1147" x1="22.8661" y1="34.2048" x2="34.3351" y2="10.4531" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint3_linear_111_1147" x1="40.855" y1="11.2969" x2="65.7206" y2="13.0004" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint4_linear_111_1147" x1="-1.84955" y1="2.00391" x2="37.3309" y2="5.243" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint5_linear_111_1147" x1="78.0209" y1="40.957" x2="90.355" y2="41.2362" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint6_linear_111_1147" x1="51.6684" y1="32.8834" x2="203.63" y2="65.3586" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint7_linear_111_1147" x1="117.404" y1="47.4326" x2="95.661" y2="41.9516" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint8_linear_111_1147" x1="113.784" y1="11.4359" x2="101.708" y2="7.13226" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint9_linear_111_1147" x1="121.851" y1="56.6532" x2="134.131" y2="56.6532" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint10_linear_111_1147" x1="137.808" y1="69.5498" x2="153.335" y2="71.1987" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint11_linear_111_1147" x1="149.281" y1="82.7191" x2="156.997" y2="82.7191" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint12_linear_111_1147" x1="159.586" y1="98.9814" x2="165.209" y2="98.9814" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint13_linear_111_1147" x1="157.46" y1="98.9818" x2="162.791" y2="98.9818" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint14_linear_111_1147" x1="198.713" y1="84.5346" x2="203.417" y2="84.5346" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint15_linear_111_1147" x1="183.239" y1="95.1143" x2="188.915" y2="95.1143" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint16_linear_111_1147" x1="76.2069" y1="108.086" x2="82.174" y2="108.086" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint17_linear_111_1147" x1="113.591" y1="47.1982" x2="118.619" y2="47.262" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint18_linear_111_1147" x1="124.78" y1="31.9239" x2="212.785" y2="40.5954" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint19_linear_111_1147" x1="155.434" y1="56.5107" x2="167.459" y2="57.2632" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint20_linear_111_1147" x1="161.434" y1="49.4482" x2="174.344" y2="50.245" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint21_linear_111_1147" x1="184.859" y1="94.3668" x2="195.509" y2="94.3668" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint22_linear_111_1147" x1="105.417" y1="113.703" x2="134.331" y2="119.884" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint23_linear_111_1147" x1="99.0066" y1="100.221" x2="116.148" y2="102.049" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint24_linear_111_1147" x1="112.561" y1="98.0925" x2="123.049" y2="100.336" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <clipPath id="clip0_111_1147">
            <rect width="203.631" height="128.349" fill="white" transform="translate(0.257812 0.769531)"/>
        </clipPath>
    </defs>
</>;