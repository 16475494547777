import { serviceUtils } from '../../../service/serviceUtils';
import { remakeDashboardEffects } from './remakeDashboardEffects';
import { action, thunk } from 'easy-peasy';
import { dashboardConstants } from '../dashboardConstants';
import { isEmpty } from 'lodash';
import { dataHelper } from '../dataHelpers/dataHelper';

const { URLS: { BALANCES, VAULTS, YIELDS, VENUS_LENDING } } = dashboardConstants;

const actionTypes = {
    REMAKE_DASHBOARD_FACTORY_BALANCES_FETCHED: 'REMAKE_DASHBOARD_FACTORY_BALANCES#FETCHED',
    REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_SUCCEEDED: 'REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH#SUCCEEDED',
    REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_FAILED: 'REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH#FAILED',
    REMAKE_DASHBOARD_FACTORY_VAULTS_FETCHED: 'REMAKE_DASHBOARD_FACTORY_VAULTS#FETCHED',
    REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_SUCCEEDED: 'REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH#SUCCEEDED',
    REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_FAILED: 'REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH#FAILED',
    REMAKE_DASHBOARD_FACTORY_YIELDS_FETCHED: 'REMAKE_DASHBOARD_FACTORY_YIELDS#FETCHED',
    REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_SUCCEEDED: 'REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH#SUCCEEDED',
    REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_FAILED: 'REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH#FAILED',
    REMAKE_DASHBOARD_FACTORY_LENDING_FETCHED: 'REMAKE_DASHBOARD_FACTORY_LENDING#FETCHED',
    REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_SUCCEEDED: 'REMAKE_DASHBOARD_FACTORY_LENDING_FETCH#SUCCEEDED',
    REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_FAILED: 'REMAKE_DASHBOARD_FACTORY_LENDING_FETCH#FAILED',
};

const balancesFetched = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${BALANCES}?address=${userWallet}`,
        errorActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_FAILED],
        successActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_SUCCEEDED]
    });
};

const vaultsFetched = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${VAULTS}?address=${userWallet}`,
        errorActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_FAILED],
        successActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_SUCCEEDED]
    });
};

const yieldsFetched = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${YIELDS}?address=${userWallet}`,
        errorActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_FAILED],
        successActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_SUCCEEDED]
    });
};

const lendingFetched = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${VENUS_LENDING}?address=${userWallet}`,
        errorActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_FAILED],
        successActionType: actions[actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_SUCCEEDED]
    });
};

const lendingSuccess = (state, payload) => {
    const { response: { borrowLimitUsed, maxAllowed, netApy, tokens, venusNetApy } } = payload;

    state.lending.borrowLimitUsed = borrowLimitUsed;
    state.lending.maxAllowed = maxAllowed;
    state.lending.netApy = netApy;
    state.lending.tokens = tokens;
    state.lending.venusNetApy = venusNetApy;
    state.loaders.lending = false;
    state.lending.supplied = dataHelper.getSupplied(tokens);
    state.lending.borrowed = dataHelper.getBorrowed(tokens);
    state.lending.suppliedBalance = dataHelper.getSupplyBalance(state.lending.supplied);
    state.lending.borrowedBalance = dataHelper.getBorrowBalance(state.lending.borrowed);
    state.defi.invested += Number(state.lending.suppliedBalance);
};

const vaultsSuccess = (state, payload) => {
    const { response: { pools } } = payload;

    state.loaders.vaults = false;
    state.vaults = pools;
    state.defi.invested += state.vaults.map(vault => Number(vault.staked_price) + Number(vault.reward_price)).reduce((curr, val) => curr + val, 0);
};

const yieldsSuccess = (state, payload) => {
    const { response: { farms } } = payload;

    state.loaders.yields = false;
    state.yields = farms;
    state.defi.invested += state.yields.map(farm => Number(farm.staked_price) + Number(farm.reward_price)).reduce((curr, val) => curr + val, 0);
};

const balancesSuccess = (state, payload) => {
    const { response: { balArray, blackList } } = payload;

    state.loaders.defi = false;
    if (state.typeOfFetch === 'push') {
        state.walletAssets.assets = [...state.walletAssets.assets, ...balArray];
    }
    else {
        state.walletAssets.assets = balArray;
    }

    if(!blackList) state.walletAssets.assets = balArray;
    else state.walletAssets.assets = dataHelper.filterAssets(balArray, blackList.blackListTokens);

    state.walletAssets.totalUSD = dataHelper.getBalancesSum(state.walletAssets.assets);
    state.walletAssets.totalBTC = state.walletAssets.totalUSD / state.btcPrice;
    state.defi.balance = Number(state.defi.invested) + Number(state.walletAssets.totalUSD);
    state.defi.available = Number(state.defi.balance) - Number(state.defi.invested);
    state.total = dataHelper.balancesCalculation(state);
    state.defi.top4assets = dataHelper.getDefiTop4Assets(state.walletAssets.assets, state.defi.balance);
};

const vaultsFail = (state, payload) => {};

const yieldsFail = (state, payload) => {};

const lendingFail = (state, payload) => {};

const balancesFail = (state, payload) => {};

const actionHandlers = {
    [actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCHED]: thunk(async (actions, payload) => balancesFetched(actions, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_SUCCEEDED]: action((state, payload) => balancesSuccess(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_BALANCES_FETCH_FAILED]: action((state, payload) => balancesFail(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCHED]: thunk(async (actions, payload) => vaultsFetched(actions, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_SUCCEEDED]: action((state, payload) => vaultsSuccess(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_VAULTS_FETCH_FAILED]: action((state, payload) => vaultsFail(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCHED]: thunk(async (actions, payload) => yieldsFetched(actions, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_SUCCEEDED]: action((state, payload) => yieldsSuccess(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_YIELDS_FETCH_FAILED]: action((state, payload) => yieldsFail(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCHED]: thunk(async (actions, payload) => lendingFetched(actions, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_SUCCEEDED]: action((state, payload) => lendingSuccess(state, payload)),
    [actionTypes.REMAKE_DASHBOARD_FACTORY_LENDING_FETCH_FAILED]: action((state, payload) => lendingFail(state, payload)),
};

export const remakeDashboardFactory = {
    actionTypes,
    actionHandlers
};