import React from "react";
import numeral from "numeral";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomTooltip } from "../../../../components/utility/custom-tooltip/view";
import { uiActions } from "../../actions/uiActions";
import { constants } from "../../constants";

const { EXPLORER } = constants;

export const Details = (props) => {
  const {
    data: { contractAddress, valueHuman, usdPrice, usdValue },
    state,
    actions,
  } = props;

  let scanIcon;
  let scanLink;
  let scanIndex;
  {
    switch (true) {
      case props.data.blockchainName === "BSC":
        scanIcon = EXPLORER.LIST[1].name;
        scanLink = EXPLORER.LIST[1].link;
        scanIndex = 0;
        break;
      case props.data.blockchainName === "ETH":
        scanIcon = EXPLORER.LIST[2].name;
        scanLink = EXPLORER.LIST[2].link;
        scanIndex = 2;
        break;
      case props.data.blockchainName === "GLMR" || props.data.blockchainName === "Moonbeam":
        scanIcon = EXPLORER.LIST[0].name;
        scanLink = EXPLORER.LIST[0].link;
        scanIndex = 1;
        break;
      case props.data.blockchainName === "AVAXc":
        scanLink = EXPLORER.LIST[3].link;
        scanIcon = EXPLORER.LIST[3].name;
        scanIndex = 3;
        break;
      case props.data.blockchainName === "FTM":
        scanLink = EXPLORER.LIST[4].link;
        scanIcon = EXPLORER.LIST[4].name;
        scanIndex = 4;
        break;
      case props.data.blockchainName === "MATIC":
        scanLink = EXPLORER.LIST[5].link;
        scanIcon = EXPLORER.LIST[5].name;
        scanIndex = 5;
        break;
      default:
        scanIcon = EXPLORER.LIST[0].name;
        scanLink = EXPLORER.LIST[0].link;
        scanIndex = 0;
        break;
    }
  }

  return (
    <>
      <div className="modal-token-info">
        <div className="number-item">
          <div className="number">{numeral(valueHuman).format("0,00.00")}</div>
          <div className="title">Balance</div>
        </div>
        <div className="number-item">
          <div className="number">${numeral(usdValue).format("0,00.00")}</div>
          <div className="title">Value</div>
        </div>
        <div className="number-item">
          <div className="number">${numeral(usdPrice).format("0,00.00")}</div>
          <div className="title">Price</div>
        </div>
        <div className="number-item">
          <div className="d-flex align-items-center">
            <div className="number">{contractAddress}</div>
            <CopyToClipboard
              text={contractAddress}
              onCopy={() =>
                actions[
                  uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_ADDRESS_COPIED
                ]()
              }
            >
              <div>
                <CustomTooltip
                  trigger={
                    <i className="fa-light font-size-18 fa-copy copy-button"></i>
                  }
                  content={
                    !state.copy.copied
                      ? "Copy to Clipboard"
                      : "Copied to Clipboard!"
                  }
                />
              </div>
            </CopyToClipboard>
          </div>
          <div className="title">Token Address</div>
        </div>
      </div>
      <div className="modal-token-explorers">
        <div className="modal-token-explorers-header d-flex">
          <div className="explorer-title">{EXPLORER.TITLE}</div>
        </div>
        <div className="explorer-content d-flex">
          <a
            rel="noreferrer"
            className="explorer-item me-5"
            target="_blank"
            key={scanIndex}
            href={`${scanLink}${contractAddress}`}
          >
            <div className="ps-2 pe-2 mb-2">
              <img
                width={50}
                height={50}
                src={`../dashboard-library/explorers/${scanIcon.toLowerCase()}.svg`}
              />
            </div>
            <div className="explorer-item-title">{scanIcon}</div>
          </a>
        </div>
      </div>
    </>
  );
};
