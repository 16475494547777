import React from 'react';

export const BLUE_GRAD_BG = <>
<path d="M550 0H20C8.9543 0 0 8.9543 0 20V352C0 363.046 8.9543 372 20 372H550C561.046 372 570 363.046 570 352V20C570 8.9543 561.046 0 550 0Z" fill="url(#paint0_linear_107_2258)"/>
<defs>
<linearGradient id="paint0_linear_107_2258" x1="-57.5" y1="143.5" x2="671.881" y2="205.839" gradientUnits="userSpaceOnUse">
<stop offset="0.03125" stopColor="#1583FF"/>
<stop offset="0.302083" stopColor="#115CCC"/>
<stop offset="0.697647" stopColor="#1047B1"/>
<stop offset="1" stopColor="#0F389F"/>
</linearGradient>
</defs>
</>;