import React, { useEffect, useRef } from 'react';
import { uiActions } from './actions/uiActions';
import { DeFiLiquidatorState } from '../../models/defi-liquidator-model';
import { useStoreState } from 'easy-peasy';
import CustomScroll from 'react-custom-scroll';
import { constants } from './constants';
import './styles.scss';
import { WalletTemplate } from './templates/walletTemplate';
import { ProtocolsTemplate } from './templates/protocolsTemplate';

const { TABS: { WALLET, PROTOCOLS } } = constants;

export const DeFiLiquidatorLayout = () => {
    const state = DeFiLiquidatorState.useStoreState(state => state);
    const actions = DeFiLiquidatorState.useStoreActions(actions => actions);
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const ref = useRef();
    const onTabsToggle = tab => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_TOGGLE]({ tab });

    const activeClass = tab => state.tab === tab ? 'active' : '';

    useEffect(() => {
        actions[uiActions.actionTypes.DEFI_LIQUIDATOR_REQUESTED]({ state, page: state.tableData.pagination.page, pageSize: 50 });
    }, [state.searchRequest]);

    return <div ref={ref} className="page-container">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
            <div className="defi-liquidator-layout content ps-3 pe-3">
                <div className="wallet-settings d-inline-flex">
                    <div className={`tab ${activeClass('protocols')}`} onClick={() => onTabsToggle('protocols')}>{PROTOCOLS}</div>
                    <div className={`tab ${activeClass('wallet')}`} onClick={() => onTabsToggle('wallet')}>{WALLET}</div>
                </div>
                { state.tab === 'wallet' ? <WalletTemplate userWallet={userWallet} state={state} actions={actions} /> : <ProtocolsTemplate state={state} actions={actions} />}
            </div>
        </CustomScroll>
    </div>;
};

export const DeFiLiquidatorPage = () => <DeFiLiquidatorState.Provider>
    <DeFiLiquidatorLayout/>
</DeFiLiquidatorState.Provider>;