import React from 'react';
import styles from './styles.scss';

export const SortSign = props => {
    const { sorting } = props;
    const active = !sorting ?
        {} :
        sorting === 'asc' ?
            { top: 'active', bottom: '' } :
            { top: '', bottom: 'active' };
    return <>
        <div className="utils-sign-container d-flex flex-column">
            <i className={`fa-solid fa-chevron-up icon sorting-asc ${active.top || ''}`}></i>
            <i className={`fa-solid fa-chevron-down icon sorting-desc ${active.bottom || ''}`}></i>
        </div>
    </>;
};
