import React from 'react';
import './styles.scss';
import { Asset } from '../asset-component/view';

const buttonHarvest = () => {
  return <div className="button-harvest cursor-pointer">
    <span className="font-size-10">Harvest</span>
    <img className="image-fluid" src="../dashboard-library/button-harvest.svg"/>
  </div>
};

const iconSet = (mainAsset, asset) => {
  return <div className="icons-set w-100">
    <Asset asset={(asset || '').toLowerCase()} ticker={true}/>
    <Asset asset={(mainAsset || '').toLowerCase()} ticker={true}/>
  </div>
};


export const YieldCard = props => {
  const {mainAsset = '', asset = '', apr, rewardPrice = 0, rewardAmount, stakedAmount, stakedPrice } = props;
  const headerTitle = `${mainAsset.toUpperCase()}-${asset.toUpperCase()}`;
  const sumAmount = (Number(rewardAmount) + Number(stakedAmount)).toFixed(2);
  const sumUSD = `${(Number(rewardPrice) + Number(stakedPrice)).toFixed(2)} USD`;
  return <div className="yield-card">
    <div className="yield-card-container">
      <div className="yield-card-content">
        <div className="yield-card-content-header">
          <div className="icons-block h-100 d-flex align-items-start">
              { iconSet(mainAsset, asset) }
            </div>
          <div className="text-block d-flex flex-column font-size-14 font-weight-medium">
            <div>{headerTitle}</div>
            <div>Pancake Swap</div>
          </div>
        </div>
        <div className="yield-card-content-horizontal-divider"></div>
        <div className="yield-card-content-body">
          <div className="details font-size-10">
            <div>APR</div>
            <div className="text-end">{apr}%</div>
            <div>Earn:</div>
            <div className="text-end">Cake + Fees</div>
          </div>
          <div className="font-size-12 font-weight-medium">Cake earned</div>
          <div className="d-flex justify-content-between h-100">
            <div className="value">
              <div className="font-size-18 font-weight-medium value-limitation">{sumAmount}</div>
              <div className="font-size-8">{sumUSD}</div>
            </div>
            <div className="action-harvest d-flex align-items-center">
              { buttonHarvest() }
            </div>
          </div>
          <div className="font-size-10">{`${mainAsset}-${asset} LP STAKED`}</div>
        </div>
      </div>
    </div>
  </div>
        };