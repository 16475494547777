import React from 'react';
import { TABLE_IMG } from './images/tableImg';
import { CHART_UP } from './images/chartUp';
import { TRADE_CIRCLE } from './images/tradeCircle';
import { TRADE_PLANET } from './images/tradePlanet';
import { TRADE_ATOM } from './images/tradeAtom';
import { TRADE_CELLS } from './images/tradeCells';
import { TRADE_DICTUM } from './images/tradeDictum';
import {WANT_TO_TRY} from "./images/wantToTry";

const tableTemplate = () => TABLE_IMG;

const chartUp = () => CHART_UP;

const tradeCircle = () => TRADE_CIRCLE;

const tradePlanet = () => TRADE_PLANET;

const tradeAtom = () => TRADE_ATOM;

const tradeCells = () => TRADE_CELLS;

const tradeDictum = () => TRADE_DICTUM;

const wantToTry = () => WANT_TO_TRY;

export const templates = {
    tableTemplate,
    chartUp,
    tradeCircle,
    tradePlanet,
    tradeAtom,
    tradeCells,
    tradeDictum,
    wantToTry
};