import { action } from 'easy-peasy';

const actionTypes = {
  PROFILE_NEW_PROFILE_INVOKED: 'PROFILE_NEW_PROFILE#INVOKED',
  PROFILE_NEW_PROFILE_SUCCEEDED: ' PROFILE_NEW_PROFILE#SUCCEEDED',
  PROFILE_NEW_PROFILE_FAILED: ' PROFILE_NEW_PROFILE#FAILED',
  PROFILE_EDIT_PROFILE_INVOKED: 'PROFILE_EDIT_PROFILE#INVOKED',
  WALLET_NEW_WALLET_INVOKED: 'WALLET_NEW_WALLET#INVOKED',
};

const invokeHandle = (state, payload) => {
  const { method, values } = payload;

  switch (method) {
    case 'add':
      state.cardsList.push(values);
      break;
    case 'delete':

      let walletDataPosition
      let cardPosition
      for(let j=0;j<state.cardsList.length;j++)
      {
        if(state.cardsList[j].id == values.cardId)
          cardPosition=j
        console.log(cardPosition)
      }
      state.cardsList.splice(cardPosition, 1)
      //state.cardsList = state.cardsList.filter(item => item.id !== values.cardId);
      for(let i=0;i<state.walletData.length;i++)
      {
        if(state.walletData[i].cardID == values.cardId)
          walletDataPosition = i;
          console.log(walletDataPosition)
      }
      //state.walletData = state.walletData.filter(item => item.cardID !== values.cardId);
      state.walletData.splice(walletDataPosition, 1)
      break;

    case 'edit':
      state.isEditModalOpen = true;

      console.log('values.id: ', values.id)
      state.counter = values.id
      break;

    case 'editName':
      state.cardsList[values.id].title = values.title;

      break;
  }
};

let walletsArray = [];

const walletInvokeHandle = (state, payload) => {
  const { method, values, id, cardID } = payload;
  switch (method) {
    case 'add':
      let flag = 0;
      const idx = state.cardsList.find((card) => card.id === state.indexForSubmit);

      const position = state.cardsList.findIndex(object =>{ return object.id == state.indexForSubmit})
      console.log('position in profileEffects: ', position)
        for(let i=0;i<state.walletData.length;i++)
        {
          console.log('state index: ',state.walletData[i].index.value)
        if (state.walletData[i].cardID == state.indexForSubmit){

          // walletsArray.push({walletAddress: values, id: id}); 
          // console.log('walletsArray: ', walletsArray)
        
        state.walletData[i].walletAdrese.push({walletAddress: values, id: id}) 
        //= walletsArray;
        
        flag=1;
      }
     
    }
       if(flag==0) {
        // walletsArray = [];
        // walletsArray.push({walletAddress: values, id: id});
      //  state.walletData.push({index: position, walletAdrese:walletsArray, id: id, cardID: cardID, isEdit: true});
      state.walletData.push({index: position, walletAdrese:[{walletAddress: values, id: id}], id: id, cardID: cardID, isEdit: true});
        console.log('state.walletData.walletAdrese: ', state.walletData)
       }
       
      break;

    case 'remove':
      state.walletData = state.walletData.filter((item) => item.id !== state.walletId);
  }
};

const successHandle = (state, payload) => {};

const errorAction = (state, payload) => {};

const actionHandlers = {
  [actionTypes.PROFILE_NEW_PROFILE_INVOKED]: action((state, payload) =>
    invokeHandle(state, payload)
  ),
  [actionTypes.PROFILE_NEW_PROFILE_SUCCEEDED]: action((state, payload) =>
    successHandle(state, payload)
  ),
  [actionTypes.PROFILE_NEW_PROFILE_FAILED]: action((state, payload) => errorAction(state, payload)),
  [actionTypes.WALLET_NEW_WALLET_INVOKED]: action((state, payload) =>
    walletInvokeHandle(state, payload)
  ),
  [actionTypes.PROFILE_EDIT_PROFILE_INVOKED]: action((state, payload) =>
    invokeHandle(state, payload)
  ),
};

export const profileEffects = {
  actionTypes,
  actionHandlers,
};
