import React, { useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { constants } from '../constants';
import { nftGeneratorEffects } from '../actions/nftGeneratorEffects';
import { SelectChainModal } from './selectChainModal';
import mergeImages from 'merge-images';

const { CONTROLS, COLOR, BUTTONS, DISCLAIMERS } = constants;

export const RightSideContent = props => {
  const { state, actions, name } = props;
  const randomNumber = Math.floor(Math.random() * 16) + 1;

  useEffect(() => {
    mergeImages(state.images, {
      // format: 'image/webp',
      // quality: 0.1
    })
      .then(b64 => {
        actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_IMAGE_CHANGED](b64)
      });
  }, [state.images]);

  const formControlChange = e => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_ELEMENTS_CHANGED]({name: e?.target?.name, value: e?.target?.value});
  const handleOpen = () => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_MODAL_TOGGLED]({modal: 'select-chain', name: 'open-modal'});

  return <div className="right-side-content">
    <div className="nft-wrapper panel-wrapper">
      <div className="font-weight-medium">THE NFT NAME {randomNumber}</div>
      <div className="nft-wrapper-image">
        <img src={state.image}/>
      </div>
      {/*<div className="nft-wrapper-color mt-3">*/}
      {/*  <div className="font-size-16 pb-1 font-weight-medium global-colors-font-2">{CONTROLS.PICK_COLOR}</div>*/}
      {/*  <InputGroup>*/}
      {/*    <Form.Control*/}
      {/*      name="pick_color"*/}
      {/*      value={state.pickColor}*/}
      {/*      onChange={formControlChange}/>*/}
      {/*    <div className="ps-3">*/}
      {/*      <div className="font-size-14 font-weight-medium global-colors-font-0">{COLOR}</div>*/}
      {/*      <div className="font-weight-medium">#FFFFFF</div>*/}
      {/*    </div>*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
    </div>
    <div className="buttons-wrapper font-inter mt-4">
      <Button
        size="lg"
        variant='primary'
        className="font-size-14 font-weight-medium btn-custom btn-random">{BUTTONS.RANDOM}</Button>
      <Button
        onClick={handleOpen}
        size="lg"
        variant='primary'
        className="font-size-14 font-weight-medium btn-custom btn-enable-nft">{BUTTONS.ENABLE_NFT}</Button>
    </div>
    <div className="disclaimer-wrapper mt-4">
      <div className="d-flex justify-content-between">
        <div className="font-size-14 font-weight-medium global-colors-font-2">{DISCLAIMERS.DRAWING_BOARD}</div>
        <div className="font-weight-medium global-colors-font-0">1</div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="font-size-14 font-weight-medium global-colors-font-2">{DISCLAIMERS.AVATAR_NFT}</div>
        <div className="font-weight-medium global-colors-font-0">0</div>
      </div>
    </div>
    <SelectChainModal show={state.selectChain.show} actions={actions} state={state} name={name} />
  </div>;
};
