/* eslint-disable max-len */
import React from 'react';
import { ethers } from 'ethers';
import { utils } from '../../../utils/utils';
import { lendingConstants } from '../constants';
import { constants } from '../../../constants/constants';
import { isEmpty } from 'lodash';
import Big from 'big.js';
import numeral from 'numeral';
const { ABI_ERC20, NULL_ADDRESS } = lendingConstants;
const { CHAINS } = constants;

const metamaskGetTokenBalance = async (tokenAddress, accountAddress, chain, symbol) => {
    const isNativeToken = CHAINS.find(c => c.oldchain === chain).nativeTokenSymbol === symbol;
    if (!window.ethereum) {
        return { err: 'metamask was not installed' };
    } else if (!tokenAddress || tokenAddress === '0x0' || isNativeToken || tokenAddress.toLowerCase() === NULL_ADDRESS || tokenAddress === '') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const balance = +await provider.getBalance(accountAddress);
        const decimals = 18; // same for all EVM tokens
        return { native: true, decimals, balance };
    } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const tokenContract = new ethers.Contract(tokenAddress, ABI_ERC20, provider);
        const balance = +await tokenContract.balanceOf(accountAddress);
        const decimals = +await tokenContract.decimals();
        const symbol = await tokenContract.symbol();
        const name = await tokenContract.name();
        return { native: false, symbol, name, decimals, balance };
    }
};
const getTotalBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+balance.supply_price));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};

const getTotalBorrowBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+balance.borrowBalance));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};
const getOthersFraction = array => {
    const allocation = array.map(item => item.fraction);
    const fractionSum = allocation.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), 0);
    const othersFraction = 100 - fractionSum;
    return othersFraction.toString();
};
const getTokensFraction = tokens => {
    const allTokens = tokens.map(token => token.token.symbol);
    const onePercent = getTotalBalance(tokens) / 100;
   
    const fractionsArray = tokens.map((token, index) => {
        if (token.supply_price === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => t.supply_price)[index] / onePercent).format('0,00'),
                value: numeral(token.supply_price).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const getBorrowTokensFraction = tokens => {
    const allTokens = tokens.map(token => token.token.symbol);
    const onePercent = getTotalBorrowTokenBalance(tokens) / 100;
   
    const fractionsArray = tokens.map((token, index) => {
        if (token.borrow_price === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => t.borrow_price)[index] / onePercent).format('0,00'),
                value: numeral(token.borrow_price).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const getBorrowedTokensFraction = tokens => {
    const allTokens = tokens.map(token => token.token.symbol);
    const onePercent = getTotalBorrowTokenBalance(tokens) / 100;
 
    const fractionsArray = tokens.map((token, index) => {
        if (token.borrow_price === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => t.borrow_price)[index] / onePercent).format('0,00'),
                value: numeral(token.borrow_price).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};
const getSupplied = array => array.filter(item => +item.supply_amount > 0);

const getBorrowed = array => array.filter(item => +item.borrow_amount > 0);

const getSortedMarket = (mainArray, arrayFilter) => {
    const user = mainArray.filter(el => arrayFilter.find(element => element.token.symbol === el.token.symbol));
    const marketData = mainArray.filter(el => !arrayFilter.find(element => element.token.symbol === el.token.symbol));
    const userData = includeData(user, arrayFilter, mainArray);

    return { userData, marketData };
};

const includeData = (data, propsFilter, propsMarket) => data.map(obj => {

    const prop = propsFilter.find(e => e.token.symbol === obj.token.symbol);
    const market = propsMarket.find(e => e.token.symbol === obj.token.symbol);
    const properties = { ...prop, ...market };

    return { ...obj, ...properties ? { cToken: properties.cToken, supply_apy: properties.supply_apy, borrow_apy: properties.borrow_apy, 
        supply_amount: properties.supply_amount, supply_price: properties.supply_price, borrow_amount: properties.borrow_amount, borrow_price: properties.borrow_price,
        price: properties.price, collateral: properties.collateral, marketEntered: properties.marketEntered } : {} };
});

const getFormattedAssetObject = pickedAsset => {
    const asset = {
        borrow_amount: pickedAsset?.borrow_amount,
        borrow_price: pickedAsset?.borrow_price,
        borrow_apy: pickedAsset?.borrow_apy || pickedAsset?.borrowApy,
        supply_amount: pickedAsset?.supply_amount,
        supply_price: pickedAsset?.supply_price,
        supply_apy: pickedAsset?.supply_apy || pickedAsset?.supplyApy,
        token: pickedAsset?.token,
        cToken: pickedAsset?.cToken,
        enabled: pickedAsset?.enabled,
        price: pickedAsset?.price,
        collateral: pickedAsset?.collateral,
        marketEntered: pickedAsset?.marketEntered,
        borrowEnabled: pickedAsset?.borrowEnabled,
        liquidity: pickedAsset?.liquidity,
        supplyVenusApy:pickedAsset?.supplyVenusApy,
        borrowVenusApy:pickedAsset?.borrowVenusApy
    };

    return asset;
};

const getFormattedAssetList = list => {
    const assets = list.map(item => {
        return {
            label: <div className='img-selector d-inline-flex align-items-center'>
                <img width={16} height={16} src={`../coins-full-library/${item.token.symbol.toLowerCase()}.svg`} onError={utils.imgErrorHandle} />
                <div className="ps-2">{item.token.symbol}</div>
            </div>,
            value: item.token.symbol,
            ...item
        };
    });

    return assets;
};

const getSupplyBalance = supplied => +supplied.reduce((accumulator, object) => Number(accumulator) + Number(object.supply_price), 0).toFixed(2);

const getBorrowBalance = borrowed => +borrowed.reduce((accumulator, object) => Number(accumulator) + Number(object.borrow_price), 0).toFixed(2);

const getDailyEarning = (netApy, supplyPrice) => Number(netApy / 365 * supplyPrice / 100).toFixed(2);

const percentOfLimit = (borrowedToken, totalBorrowed, usedLimit) => {
    const num1 = Number(borrowedToken) / Number(totalBorrowed) * 100;
    const num2 = Number(usedLimit) / 100 * Number(num1);

    return Number(num2).toFixed(2);
};

// Approve
const getApproveParams = (from, to, cTokenAddress) => {
    const method = '0x095ea7b3';
    const data = `${method}${utils.stringCreator('0', 24)}${cTokenAddress.slice(2)}${utils.stringCreator('f', 64)}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

// Enter Market
const getCollateralParams = (from, to, cTokenAddress) => {
    const method = '0xc2998238';
    const data = `${method}00000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000001${utils.stringCreator('0', 24)}${cTokenAddress.slice(2)}`;

    const params = {
        to,
        from,
        value: '0x0',
        gas: '0x23280',
        data,
    };

    return params;
};

const getExitMarketParams = (from, to, cTokenAddress) => {
    const method = '0xede4edd0';
    const data = `${method}${utils.stringCreator('0', 24)}${cTokenAddress.slice(2)}`;

    const params = {
        to,
        from,
        value: '0x0',
        gas: '0xaae60',
        data,
    };

    return params;
};
const mergedAllInvestmentsType = (supplyList, borrowedList)=> {
    if (typeof supplyList !== "undefined" && typeof borrowedList !== "undefined") 
      return  supplyList.concat( borrowedList);
    
}
const countBorrowableAmount = (max, totalBorrowed, tokenPrice) => (Number(max) - Number(totalBorrowed)) / Number(tokenPrice);

const countMaxAllowed = (collateral, amount, price) => amount * price * collateral;

const newLimit = (number, max) => (number / max * 100).toFixed(2);

const getWithdrawAmount = (limit, totalBorrowed, collateral, tokenPrice) => (Number(limit) - Number(totalBorrowed)) / Number(collateral) / Number(tokenPrice);

const getTxParams = (from, to, amount, method, isNative) => {
    const tokenAmount = (amount * 1e18).toString(16);

    let data = '';
    let gasLimit = null;

    switch(method) {
    case 'withdraw': 
        method = '0x852a12e3'; 
        data = `${method}${utils.stringCreator('0', 64 - tokenAmount.length)}${tokenAmount}`;
        break;
    case 'supply': 
        method = !isNative ? '0xa0712d68' : '0x1249c58b';
        gasLimit = '0x50910';
        data = isNative ? `${method}` : `${method}${utils.stringCreator('0', 64 - tokenAmount.length)}${tokenAmount}`;
        break;
    case 'borrow': 
        method = '0xc5ebeaec'; 
        data = `${method}${utils.stringCreator('0', 64 - tokenAmount.length)}${tokenAmount}`;
        break;
    case 'repay': 
        method = !isNative ? '0x0e752702' : '0x4e4d9fea'; 
        data = isNative ? `${method}` : `${method}${utils.stringCreator('0', 64 - tokenAmount.length)}${tokenAmount}`;
        break;
    }

    const params = {
        to,
        from,
        gasLimit: gasLimit,
        value: isNative ? `0x${tokenAmount}` : '0x0',
        data,
    };

    console.log(method, data, params);

    return params;
};
const parseList = exchanges => {
    const keys = Object
        .keys(exchanges)
       
    return keys.map(key => ({
        label: exchanges[key].title,
        value: exchanges[key],
        list: exchanges[0]
    }));
  };

  const setProtocols = (protocolsArr, protocol) => {
    for(const item of protocolsArr){
        item.protocolName = protocol;
    }
    return protocolsArr;
  };

  const getTotalSupplyBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+ balance.supplyBalance));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};

const getTotalBorrowTokenBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+ balance.borrow_price));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};

  const getProtocolsSupplyFraction = tokens => {
    const allTokens = tokens.map(token => token.protocolName);
    const onePercent = getTotalSupplyBalance(tokens) / 100;
    const fractionsArray = tokens.map((token, index) => {
        if (Number(token.supplyBalance)  === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => Number(t.supplyBalance))[index] / onePercent).format('0,00'),
                value: numeral(Number(token.supplyBalance)).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const getProtocolsBorrowFraction = tokens => {
    const allTokens = tokens.map(token => token.protocolName);
    const onePercent = getTotalBorrowBalance(tokens) / 100;
    const fractionsArray = tokens.map((token, index) => {
        if (Number(token.borrowBalance)  === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => Number(t.borrowBalance))[index] / onePercent).format('0,00'),
                value: numeral(Number(token.borrowBalance)).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

export const dataHelper = {
    getSupplied,
    getBorrowed,
    getFormattedAssetObject,
    getFormattedAssetList,
    getDailyEarning,
    getSupplyBalance,
    getBorrowBalance,
    percentOfLimit,
    getSortedMarket,
    getApproveParams,
    countBorrowableAmount,
    metamaskGetTokenBalance,
    countMaxAllowed,
    newLimit,
    getWithdrawAmount,
    getCollateralParams,
    getExitMarketParams,
    getTxParams,
    getTokensFraction,
    getOthersFraction,
    parseList,
    getBorrowedTokensFraction,
    mergedAllInvestmentsType,
    setProtocols,
    getProtocolsSupplyFraction,
    getProtocolsBorrowFraction,
    getBorrowTokensFraction
};