import React from 'react';
import Toggle from 'react-toggle';
import { constants } from '../../../constants';
import { MultiplyCustomSelector } from '../../../../../components/utility/multiply-selector/view';
import { customBotsEffects } from '../../../actions/customBotsEffects';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { PopoverLayout } from '../../../../../components/utility/popover/view';
import { customBotsActions } from '../../../actions/uiActions';

const {
  CONTROLS,
  TABS,
  BUTTONS,
  TITLES,
  MORE_INFO
} = constants;

export const StopLossForm = (props) => {
  const { state, actions } = props
  const action = actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]

  const changeHandle = (value) => action({...value});
  const formControlChange = e => action({name: e?.target?.name, value: e?.target?.value ? parseFloat(e?.target?.value) : 0});
  const toggleOnChange = e => action({name: e?.target?.name, value: e?.target?.checked});
  const nextStepHandler = () => actions[customBotsActions.actionTypes.CUSTOM_BOTS_TAB_TOGGLE]({tab: TABS.TAKE_PROFIT});
  const previousStepHandler = () => actions[customBotsActions.actionTypes.CUSTOM_BOTS_TAB_TOGGLE]({tab: TABS.SAFETY_ORDERS});

  return <div className="main-settings-form-content">
    <div className="form-content panel-wrapper">
      <label className="d-flex align-items-center customized-toggle cursor-pointer mb-3">
        <span className="font-weight-medium me-3">
          {TITLES.STOP_LOSS}
        </span>
        <Toggle
          name="toggle_stop_loss"
          icons={false}
          defaultChecked={state.forms.stopLoss.toggleStopLoss}
          onChange={toggleOnChange}
        />
      </label>
      <div className="font-inter">
        <div className="grid-rows grid-template-columns-50-50">
          <div className="form-item pb-3">
            <div className="d-flex">
              <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_STOP_LOSS}</div>
              <div className="more-info">
                <PopoverLayout
                  title={false}
                  width={247}
                  message={MORE_INFO.STOP_LOSS}
                  component={<i className="fa-light fa-circle-question"></i>}/>
              </div>
            </div>
            <InputGroup>
              <Form.Control
                name="stop_loss_percentage"
                className="border-right-0"
                value={state.forms.stopLoss.stopLossPercentage}
                onChange={formControlChange}
                disabled={!state.forms.stopLoss.toggleStopLoss}
                type="number"/>
              <InputGroup.Text className='right-input-button'>
                <i className="fa-solid fa-percent"></ i>
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="form-item pb-3">
            <div className="d-flex">
              <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_STOP_LOSS_ACTION}</div>
              <div className="more-info">
                <PopoverLayout
                  title={false}
                  width={247}
                  message={MORE_INFO.STOP_LOSS_ACTION}
                  component={<i className="fa-light fa-circle-question"></i>}/>
              </div>
            </div>
            <MultiplyCustomSelector
              onChangeHandle={changeHandle}
              isMulti={false}
              closeMenuOnSelect={true}
              isDisabled={!state.forms.stopLoss.toggleStopLoss}
              value={state.forms.stopLoss.stopLossActionSelector.value}
              options={state.forms.stopLoss.stopLossActionSelector.list}/>
          </div>
        </div>
        <div className="form-item pb-3">
          <label className="d-flex align-items-center customized-toggle cursor-pointer">
            <div className="font-size-14 font-weight-medium me-2">{CONTROLS.LABEL_STOP_LOSS_TIMEOUT}</div>
            <Toggle
              name="toggle_stop_loss_timeout"
              icons={false}
              defaultChecked={state.forms.stopLoss.toggleStopLossTimeout}
              disabled={!state.forms.stopLoss.toggleStopLoss}
              onChange={toggleOnChange}
            />
          </label>
        </div>
        { state.forms.stopLoss.toggleStopLossTimeout ?
          <div className="form-item pb-3 w-50-8px">
            <div className="d-flex">
              <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_STOP_LOSS_TIMEOUT}</div>
              <div className="more-info">
                <PopoverLayout
                  title={false}
                  width={247}
                  message={MORE_INFO.STOP_LOSS_TIMEOUT}
                  component={<i className="fa-light fa-circle-question"></i>}/>
              </div>
            </div>
            <InputGroup>
              <Form.Control
                name="stop_loss_timeout"
                className="border-right-0"
                value={state.forms.stopLoss.stopLossTimeout}
                onChange={formControlChange}
                type="number"/>
              <InputGroup.Text className='right-input-button'>Sec</InputGroup.Text>
            </InputGroup>
          </div> : null
        }
        { state.tabs.sideTab === TABS.WIZARD ?
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium btn-default btn-steps-sm"
              onClick={previousStepHandler}>
              {BUTTONS.PREVIOUS_STEP}
            </Button>
            <Button
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium btn-default btn-steps-sm"
              onClick={nextStepHandler}>
              {BUTTONS.NEXT_STEP}
            </Button>
          </div> : null
        }
      </div>
    </div>
  </div>
};