import { action } from 'easy-peasy';
import { orderBy } from 'lodash';
import { dataHelper } from './dataHelper';

const actionTypes = {
    BOTS_WIDGET_UI_ACTIONS_SORT_BY_CHANGED: 'BOTS_WIDGET_UI_ACTIONS_SORT_BY#CHANGED',
    BOTS_WIDGET_UI_ACTIONS_FILTER_BY_CHANGED: 'BOTS_WIDGET_UI_ACTIONS_FILTER_BY#CHANGED',
    BOTS_WIDGET_UI_ACTIONS_BOT_DATA_MODIFIED: 'BOTS_WIDGET_UI_ACTIONS_BOT_DATA#MODIFIED'

};

const filterBotsList = state => {
    const filterValue = state.filterBy;
    const data = state.originalData;
    const filteredData = filterValue.id ? data.filter(b => b.id === filterValue.id) : data;
    state.mutableData = filteredData;
    return filterValue.id ? orderBy(filteredData, ['startDate'], 'desc') : dataHelper.sorting(state);
};

const sortAction = (state, payload) => {
    const { value } = payload;
    state.botsWidget.sortBy = value.id === 'reset' ? { id: 'startDate', label: 'Start date' } : value;
    state.botsWidget.mutableData = dataHelper.sorting(state.botsWidget);
};

const filterAction = (state, payload) => {
    const { value } = payload;
    state.botsWidget.filterBy = value.id === 'reset' ?
        { id: '', label: '' } :
        value;
    state.botsWidget.mutableData = filterBotsList(state.botsWidget);
};

const actionHandlers = {
    [actionTypes.BOTS_WIDGET_UI_ACTIONS_SORT_BY_CHANGED]: action((state, payload) => sortAction(state, payload)),
    [actionTypes.BOTS_WIDGET_UI_ACTIONS_FILTER_BY_CHANGED]: action((state, payload, ) => filterAction(state, payload)),
};

export const botsEffects = {
    actionTypes,
    actionHandlers
};