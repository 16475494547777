import { createContextStore } from 'easy-peasy';
import { eventsSettingsEffects } from '../pages/eventsPage/actions/eventsEffects';

const EVENTS_MODEL = {
  titleData: {
    viewType: {
      highlights: true,
      upcoming_events: false,
      past_events: false,
    },
  },

  highlights_cards: [
    {
      cardType: 'Tranding',
      coinsList: [
        {
          cardData: {
            coinIcon: '../coins-library/eth.svg',
            titleCoin: 'ETHEREUM',
            date: '12 Jun 2023',
            cardText: 'Folks Finance V2',
            views: '+1869',
            votes: '+400',
          },
        },
      ],
    },
    {
      cardType: 'Significent',
      coinsList: [
        {
          cardData: {
            coinIcon: '../coins-library/sol.svg',
            titleCoin: 'SOLANA',
            date: '7 Nov 2022',
            cardText: 'Folks Finance V2',
            views: '+467',
            votes: '+99',
          },
        },
        {
          cardData: {
            coinIcon: '../coins-library/bnb.svg',
            titleCoin: 'BINANCE',
            date: '14 Feb 2023',
            cardText: 'Folks Finance V2',
            views: '+414',
            votes: '+78',
          },
        },
        {
          cardData: {
            coinIcon: '../coins-library/usdt.svg',
            titleCoin: 'USDTCoin',
            date: '7 Mar 2023',
            cardText: 'Folks Finance V2',
            views: '+387',
            votes: '+68',
          },
        },
      ],
    },
    {
      cardType: 'Hot',
      coinsList: [
        {
          cardData: {
            coinIcon: '../coins-library/eth.svg',
            titleCoin: 'ETHEREUM',
            date: '12 Dec 2022',
            cardText: 'Folks Finance V2',
            views: '+2206',
            votes: '+789',
          },
        },
        {
          cardData: {
            coinIcon: '../coins-library/ape.svg',
            titleCoin: 'APECOIN',
            date: '12 Dec 2022',
            cardText: 'Folks Finance V2',
            views: '+1987',
            votes: '+357',
          },
        },
      ],
    },
  ],
  upcoming_cards_title: [
    {
      cardType: 'Tranding',
      date: '19 Feb 2023',
      name: 'CryptoMarket',
      views: '+650',
      votes: '+75',
    },
    {
      cardType: 'Tranding',
      date: '20 Feb 2023',
      name: 'Blockchain Day',
      views: '+700',
      votes: '+80',
    },
    {
      cardType: 'Hot',
      date: '21 Feb 2023',
      name: 'NFT Showcase',
      views: '+550',
      votes: '+60',
    },
    {
      cardType: 'Hot',
      date: '22 Feb 2023',
      name: 'DeFi Gems',
      views: '+600',
      votes: '+70',
    },
  ],

  past_cards_title: [
    {
      cardType: 'Tranding',
      date: '1 Jan 2022',
      name: 'Bitcoin Halving',
      views: '+350',
      votes: '+30',
    },
    {
      cardType: 'Tranding',
      date: '2 Jan 2022',
      name: 'Ethereum London',
      views: '+400',
      votes: '+35',
    },
    {
      cardType: 'Hot',
      date: '3 Jan 2022',
      name: 'Altcoin Rally',
      views: '+300',
      votes: '+25',
    },
    {
      cardType: 'Hot',
      date: '4 Jan 2022',
      name: 'NFT Boom',
      views: '+320',
      votes: '+28',
    },
  ],

  upcoming_events_cards: [
    {
      networkName: 'Ethereum Network',
      cardDate: '20 Jan 2023',
      cardTitle: 'ETH 2.0 Upgrade',
      cardDescription:
        'Join us for the announcement of the upcoming Ethereum 2.0 upgrade. Learn about the new features and enhancements.',
      addedDate: '5 Jan 2023',
    },
    {
      networkName: 'Bitcoin Network',
      cardDate: '15 Feb 2023',
      cardTitle: 'Bitcoin Conference 2023',
      cardDescription:
        'Don’t miss the largest Bitcoin conference of the year. Explore the future of Bitcoin, blockchain technology, and decentralized finance.',
      addedDate: '1 Feb 2023',
    },
    {
      networkName: 'Cardano Network',
      cardDate: '10 Mar 2023',
      cardTitle: 'Cardano Smart Contracts',
      cardDescription:
        'Get hands-on experience with Cardano smart contracts. Attend our workshop and learn how to develop secure and scalable applications.',
      addedDate: '25 Feb 2023',
    },
    {
      networkName: 'Polygon Network',
      cardDate: '5 Apr 2023',
      cardTitle: 'Polygon Developer',
      cardDescription:
        'Calling all Polygon developers! Join us for a meetup focused on sharing knowledge, best practices, and networking opportunities.',
      addedDate: '20 Mar 2023',
    },
  ],

  past_events_cards: [
    {
      networkName: 'Minter Network (BP)',
      cardDate: '12 Dec 2022',
      cardTitle: 'Garden Airdrop',
      cardDescription:
        "We airdropped GARDEN tokens to BIP holders. The more BIP's you had in your address, the bigger your GARDEN share.",
      addedDate: '22 Nov 2022',
    },
    {
      networkName: 'Minter Network (BP)',
      cardDate: '12 Dec 2022',
      cardTitle: 'Community AMA Session',
      cardDescription:
        'We held an AMA session with the Minter Network community. We discussed upcoming developments',
      addedDate: '22 Nov 2022',
    },
    {
      networkName: 'Minter Network (BP)',
      cardDate: '12 Dec 2022',
      cardTitle: 'Governance Proposal Voting',
      cardDescription:
        'We conducted a voting session for governance proposals. Community members had the opportunity',
      addedDate: '22 Nov 2022',
    },
    {
      networkName: 'Minter Network (BP)',
      cardDate: '12 Dec 2022',
      cardTitle: 'Developer Workshop',
      cardDescription:
        'We organized a workshop for developers interested in building on the Minter Network. Participants learned about',
      addedDate: '22 Nov 2022',
    },
  ],

  upcoming_events_news: [
    {
      newsImage: require('/public/images/events/binance-founder.svg').default,
      newsDate: '10 July 2023',
      newsTitle:
        'Binance locks withdrawals for some accounts and what CEO calls just market behavior',
      newsDescription:
        'Abnormal price movements on Binance, the leading crypto exchange by volume, sparked an internal investigation that led to CEO Changpeng Zhao concluding the movements were just market behavior - despite temporarily freezing withdrawals for some profiting accounts. Binance official Twitter account...',
    },
    {
      newsImage: require('/public/images/events/england-bank.svg').default,
      newsDate: '10 July 2023',
      newsTitle: 'Bank of England opens bids for CBDC sample wallet proof of concept',
      newsDescription:
        'The Bank of England is seeking a proof of concept for a sample wallet for a Central Bank digital currency and will take applications through Dec. 23. While the bank said it will not develop a user wallet itself, it wants to explore the "end-to-end user journey as a way to sharpen functional requirem...',
    },
    {
      newsImage: require('/public/images/events/ftx-founder.svg').default,
      newsDate: '10 July 2023',
      newsTitle:
        'U.S. prosecutors probing FTX founder Sam Bankman-Fried in potential fraud case: Bloomberg',
      newsDescription:
        'U.S. prosecutors are probing Sam Bankman-Fried, the disgraced founder of now-bankrupt crypto group FTX, for a potential fraud case against him, Bloomberg reported Friday, citing a person familiar with the matter. Prosecutors are reportedly examining whether hundreds of millions of dollars were impro...',
    },
  ],
  ...eventsSettingsEffects.actionHandlers,
};

export const EventsState = createContextStore(EVENTS_MODEL);
