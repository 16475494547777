import { textwrap } from 'd3-textwrap';

const drawTableContent = (node, content) => {
    const { PRICE_CELL, TRANSACTIONS_CELL, DEFI_CELL, NOODES_CELL } = content;
    
    node
        .selectAll('g.left-titles')
        .remove();

    const el = node
        .append('g')
        .attr('class', 'left-titles');

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 46)
        .attr('x', 66)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-weight', 700)
        .attr('font-family', 'Inter')
        .text(`${PRICE_CELL.LABEL}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 100)
        .attr('x', 99)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '40px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 900)
        .text(`${PRICE_CELL.PRICE}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 46)
        .attr('x', 745)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-weight', 700)
        .attr('font-family', 'Inter')
        .text(`${TRANSACTIONS_CELL.LABEL}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 100)
        .attr('x', 700)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '40px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 900)
        .text(`${TRANSACTIONS_CELL.PRICE}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 420)
        .attr('x', 188)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 700)
        .text(`${DEFI_CELL.LABEL}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 578)
        .attr('x', 412)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 700)
        .text('$...');


    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 439)
        .attr('x', 30)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '18px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 300)
        .text(`${DEFI_CELL.TEXT}`)
        .call(wrap);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 420)
        .attr('x', 660)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '30px')
        .attr('font-weight', 700)
        .attr('font-family', 'Inter')
        .text(`${NOODES_CELL.LABEL}`);

    node
        .select('g.left-titles')
        .append('text')
        .attr('y', 470)
        .attr('x', 646)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFFFFF')
        .attr('font-size', '40px')
        .attr('font-family', 'Inter')
        .attr('font-weight', 900)
        .text(`${NOODES_CELL.AMOUNT}`);
};

const wrap = textwrap().bounds({ height: 300, width: 200 });




export const drawHelper = {
    drawTableContent,
};