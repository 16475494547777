import React, {useEffect, useRef} from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { constants } from '../../constants';
import './styles.scss';
import * as d3 from 'd3';
import { useStoreActions } from 'easy-peasy';
import { templates } from './imagesGenerator/tableTemplate';
import { drawWantToTryHelper } from './imagesGenerator/drawWantToTryHelper';


const { TRADE_CONTENT: { START, BARRIERS, AGGREGATED, AGGREGATED_PROTOCOLS, HOW_IT_WORKS } } = constants;

export const TradePage = () => {
  const loginModalToggle = useStoreActions(actions => actions.LOGIN_MODAL_TOGGLE);
  const ref = useRef();

  useEffect(() => {
    drawWantToTryHelper.drawWantToTryContent(d3.select(ref.current), loginModalToggle);
  });

  return (
    <>
    
      <div className='trade-page'>
        <div className='trade-start-content'>
          <div className='left-column'>
            <p className='trade-start-heading font-weight-bold font-unbounded'>
                {START.TOPIC}
            </p>
            <div className='trade-start-subheading font-size-24 font-unbounded'>{START.POST_TOPIC}</div>
            <p className='trade-start-description font-size-24 font-opensans'>
                {START.DESCRIPTION}
            </p>
            <div className='trade-start-buttons font-opensans'>
              {/* <LinkContainer to='/login'>
                <a className="trade-start-launch-app">
                  <Button
                    type="button"
                    className="trade-start-btn d-flex align-items-center"
                  >
                    <span className="pe-4">{START.BUTTON}</span>
                    <i className="fa-light fa-arrow-right"></i>
                  </Button>
                </a>
              </LinkContainer> */}
              <a href="https://www.youtube.com/watch?v=fwmGzJK_Tek&feature=youtu.be" target="_blank" className="trade-start-how-it-works">
                <div className="trade-start-how-it-works-play">
                  <img src='../dashboard-library/home-page-images/play.svg' />
                </div>
                <div className="trade-start-how-it-works-text">{START.HOW_IT_WORKS}</div>
                <i className="fa-regular fa-chevron-right"></i>
              </a>
            </div>
          </div>
          <div className='circle-img'>
            <img src='../dashboard-library/home-page-images/trade.svg' className="w-100" />
          </div>
        </div>
        <div className='trade-aggregated-chains-content'>
          <p className='trade-aggregated-chains-heading font-weight-bold font-unbounded'>
            {AGGREGATED.TITLE}
          </p>
          <div className="trade-aggregated-chains-list">
            {AGGREGATED.LIST.map(item => <div className="trade-aggregated-chains-list-item" key={item}>
              <div className='marquee-wrapper'>
                <div className='marquee'>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/1.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/2.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/3.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/4.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/5.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/6.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/7.svg" alt="aggregated chains  logo"></img>
              
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/1.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/2.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/3.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/4.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/5.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/6.svg" alt="aggregated chains  logo"></img>
                  <img className="marquee-item"  src="../dashboard-library/home-page-images/aggregated-chains/7.svg" alt="aggregated chains  logo"></img>
                </div>
              </div>
            </div>)}
          </div>
        </div>
        <div className='trade-aggregated-chains-content'>
          <p className='trade-aggregated-chains-heading font-weight-bold font-unbounded'>
            {AGGREGATED_PROTOCOLS.TITLE}
          </p>
          <div className="trade-aggregated-chains-list">
            {AGGREGATED_PROTOCOLS.LIST.map(item => <div className="trade-aggregated-chains-list-item" key={item}>
            <div className='marquee-wrapper'>
                <div className='marquee1'>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/1.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/2.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/3.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/4.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/5.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/6.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/7.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/8.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/9.svg" alt="aggregated chains list logo"></img>
                  
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/1.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/2.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/3.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/4.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/5.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/6.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/7.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/8.svg" alt="aggregated chains list logo"></img>
                  <img className="marquee-item1"  src="../dashboard-library/home-page-images/aggregated-protocols/9.svg" alt="aggregated chains list logo"></img>
                </div>
              </div>
            </div>)}
          </div>
          
          <div className="trade-aggregated-chains-want-to-try-mobile">
            <div className="trade-aggregated-chains-want-to-try-mobile-text font-unbounded">{AGGREGATED_PROTOCOLS.WANT_TO_TRY}?</div>
            <LinkContainer to='/login'>
              <a>
                <Button
                  type="button"
                  className="launch-btn d-flex align-items-center font-opensans"
                >
                  <span className="pe-4">{START.BUTTON}</span>
                  <i className="fa-light fa-arrow-right"></i>
                </Button>
              </a>
            </LinkContainer>
          </div>
        </div>
        <div className='trade-barriers-content'>
          <div className='font-unbounded'>
            <p className='trade-barriers-heading font-weight-bold'>
              {BARRIERS.LABEL}
            </p>
            <p className="trade-barriers-subheading">{BARRIERS.SUBTITLE}</p>
          </div>
          <div className="trade-barriers-list">
            {BARRIERS.LIST.map(item => <div className="trade-barriers-list-item font-opensans" key={item.text}>
              <img src={item.icon}/>
              <div className="item-text">{item.text}</div>
            </div>)}
          </div>
        </div>
        
        <div className="trade-how-it-works-content">
          <div className='trade-how-it-works-heading font-weight-bold font-unbounded'>
            {HOW_IT_WORKS.TITLE}
          </div>
          <Carousel
            indicators={false}
            //interval={3000}
            slide={true}>
            {HOW_IT_WORKS.ITEMS.map((item, i) => <Carousel.Item key={i}>
              <Carousel.Caption>
                <h3 className="font-unbounded">{item.label}</h3>
                <p className="font-opensans">{item.description}</p>
              </Carousel.Caption>
              <img
                className="w-70"
                src={item.image}
              />
            </Carousel.Item>)}
          </Carousel>
          <div className="trade-aggregated-chains-want-to-try">
            <svg ref={ref} viewBox="0 0 1920 300" fill="none">
              { templates.wantToTry() }
            </svg>
          </div>
        </div>
       
      </div>
      
    </>
  );
};
