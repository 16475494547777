import React, { Fragment } from 'react';
import { utils } from '../../../utils/utils';

export const Asset = props => {
    const {asset, ticker} = props;
  return <Fragment>
      <img width={24} height={24} src={`../coins-full-library/${asset?.toLowerCase()}.svg`}
           alt=""
           onError={utils.imgErrorHandle}
           className="img-fluid rounded-start h-100 pe-1 "/>
      { !ticker ? <span className="font-size-14">{asset}</span> : null }
  </Fragment>
};