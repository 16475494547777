import React from 'react';
import moment from 'moment';
import { isArray, set } from 'lodash';
import converter from 'hex2dec';
import convert from 'ethereum-unit-converter';
import { Asset } from '../components/utility/asset-component/view';
import { isEmpty } from 'lodash';
import { ethers } from 'ethers';

// const getAccountBalance = async (tContract, abi) => {
// 	const provider = new ethers.providers.Web3Provider(window.ethereum);
// 	const contract = new ethers.Contract(tContract, abi, provider);
// 	const balance = await provider.getSigner().getBalance();
// 	console.log(balance)
// };

const getMetaMaskBalance = async (address, abi) => {
    await window.ethereum.request({ method: 'eth_requestAccounts' });

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const signerAddress = await signer.getAddress();

	if(address === '0x0000000000000000000000000000000000000000') {
		const balance = await provider.getSigner().getBalance();
		return fromWei(hex2num(balance._hex));
	}
	else {
		const tokenContract = new ethers.Contract(address, abi, signer);
		const userTokenBalance = await tokenContract.balanceOf(signerAddress);
		return fromWei(hex2num(userTokenBalance._hex));
	}
};

const getExpensiveToken = (tokens, property) => tokens.filter(token => Number(token[property]) > 0)[0];

const getChain = chain => !chain ? '' : `&chain=${chain}`;

const getChainLiquidator = selector => selector === 'compound' ? 'eth' : 'bsc';

const health2Pers = health => (health * 100).toFixed(2);

const getSeizeToken = (tokens, token) => tokens.find(t => token.value === t.seizeSymbol);

const getSuppliedTokens = tokens => {
    const arr = tokens.filter(token => Number(token.supplyBalanceUnderlying) > 0);

	if(!isEmpty(arr)) {
		return arr.map(item => {
			return {
				label: <div className='img-selector'><Asset asset={item.symbol.substring(1)} /></div>,
				value: item.symbol,
				tokenBalance: item.tokenBalance,
				supplyBalanceUnderlying: item.supplyBalanceUnderlying,
				borrowBalanceUnderlying: item.borrowBalanceUnderlying,
			};
		});
	}
	else {
		return [{
			label: null,
			value: null,
			tokenBalance: 0,
			supplyBalanceUnderlying: 0,
			borrowBalanceUnderlying: 0,
		}];
	}
};

const getBorrowedTokens = tokens => {
    const arr = tokens.filter(token => Number(token.borrowBalanceUnderlying) > 0);
    if(!isEmpty(arr)) {
		return arr.map(item => {
			return {
				label: <div className='img-selector'><Asset asset={item.symbol.substring(1)} /></div>,
				value: item.symbol,
				tokenBalance: item.tokenBalance,
				supplyBalanceUnderlying: item.supplyBalanceUnderlying,
				borrowBalanceUnderlying: item.borrowBalanceUnderlying,
				maxSeizeAmountWithIncentiveInUSD: item.maxSeizeAmountWithIncentiveInUSD,
				tokensSeize: item.tokensSeize
			};
		});
	}
	else {
		return [{
			label: null,
			value: null,
			tokenBalance: 0,
			supplyBalanceUnderlying: 0,
			borrowBalanceUnderlying: 0,
			maxSeizeAmountWithIncentiveInUSD: 0,
			tokensSeize: null
		}];
	}
};

const getParams = (userId, contractAddress, assetAddress) => {
    const contractAddressFormatted = contractAddress.slice(2);
    const params = [
        {
            from: userId,
            to: assetAddress,
            value: '0x0',
            data: `0x095ea7b3000000000000000000000000${contractAddressFormatted}0000000000000000000000000000000000000000000000000000000000000000`
        }
    ];

    return params;
};

const stringCreator = (el, count) => new Array(count).fill(el).join('');

const fromWei = wei => !isNaN(convert(wei, 'wei', 'ether')) ? convert(wei, 'wei', 'ether') : <i className='fa-solid fa-infinity'></i>;
	
const toWei = eth => convert(eth, 'ether', 'wei');

const hex2num = hex => {
    switch (hex) {
		case 'ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff': return <i className='fa-solid fa-infinity'></i>;
		default: return converter.hexToDec(hex);
	}
};

const filteredData = (data, filterByData) => data.filter(token => !filterByData.find(blToken => blToken.contractAddress === token.contractAddress && blToken.valueSymbol === token.valueSymbol));

const filteredVenusData = (data) => data.filter((blToken => blToken.borrow_price > 0 || blToken.supply_price  > 0));
const filteredCompaundData = (data) => data.filter((blToken => blToken.collateral > 0));
const filteredPoolsFarmsData = (data) => data.filter((blToken => blToken.reward_price > 0));
const uniqueAllowance = data => {
	const uniqueArr = data.reduce((unique, o) => {
		if(!unique.some(obj => obj.contract.address === o.contract.address)) unique = [...unique, o];
		return unique;
	}, []);

	return uniqueArr;
};

const num2hex = num => converter.decToHex(num);

const getAddressLink = (chain, address) => {
	switch(chain) {
		case 'BSC': return `https://bscscan.com/address/${address}`;
		case 'ETH': return `https://etherscan.io/address/${address}`;
		case 'MATIC': return `https://polygonscan.com/address/${address}`;
		case 'FTM': return `https://ftmscan.com/address/${address}`;
		case 'ARBITRUM': return `https://arbiscan.io/address/${address}`;
		case 'AVAXc': return `https://snowtrace.io/address/${address}`;
		default: return `https://bscscan.com/address/${address}`;
	}
};

const getTxLink = (chain, transaction) => {
	switch(chain) {
		case 'BSC': return `https://bscscan.com/tx/${transaction}`;
		case 'ETH': return `https://etherscan.io/tx/${transaction}`;
		case 'MATIC': return `https://polygonscan.com/tx/${transaction}`;
		case 'FTM': return `https://ftmscan.com/tx/${transaction}`;
		case 'ARBITRUM': return `https://arbiscan.io/tx/${transaction}`;
		case 'AVAXc': return `https://snowtrace.io/tx/${transaction}`;
		default: return `https://bscscan.com/tx/${transaction}`;
	}
};

const dateGenerator = () => {
	const day = Math.round(Math.random()* 100 % 28) + 1;
	const month = Math.round(Math.random()*100 % 11) + 1;
	const year = 2021;
	const dateString = `${day}/${month}/${year}`;
	const timestamp = moment(dateString, 'DD/MM/YYYY').valueOf();
	return timestamp;
};

const valueGenerator = () => {
	const value = Math.round(Math.random() * 1000);
	return value;
};

const changeGenerator = () => {
	const change = Math.round(Math.random() * 10 % 2) ? 1 : -1;
	const value = Math.round(Math.random() * 1000000000 % 100) * change;
	return value;
};

const imgErrorHandle = e => {
	e.target.onerror = null;
	e.target.src='../coins-library/no_img.svg';
};

const getNowTimeStamp = () => moment().valueOf();


const symbolSplit = symbol => {
	if (symbol.endsWith('USDT'))
		return symbol.replace('USDT', '/USDT');
	else if (symbol.endsWith('BTC'))
		return symbol.replace('BTC', '/BTC');
	else if (symbol.endsWith('ETH'))
		return symbol.replace('ETH', '/ETH');
	else
		return symbol;
};

const getSymbol = symbol => {
	if (symbol.endsWith('USDT'))
		return symbol.replace('USDT', '');
	else if (symbol.endsWith('BTC'))
		return symbol.replace('BTC', '');
	else if (symbol.endsWith('ETH'))
		return symbol.replace('ETH', '');
	else
		return symbol;
};

const howSmall = value => {
	if (!value || value > 1)
		return 0;

	let v = value;
	let iterator = 0;

	while (v < 1) {
		iterator += 1;
		v = v * 10;
	}
	return iterator;
};

const stateHelper = (state, payload) => {
	if (!isArray(payload)) {
		const { path, value } = payload;
		set(state, path, value);
	}
	else {
		payload.forEach(p => {
			const { path, value } = p;
			set(state, path, value);
		});
	}
};

const roundValue = value => value < 1 ? value.toFixed(7) : value.toFixed(4);

const nullAddressHandler = data => {
	const array = data.map(token => {
		switch(token.valueSymbol) {
			case 'BNB': token.contractAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; break;
			case 'MATIC': token.contractAddress = '0x0000000000000000000000000000000000001010'; break;
			case 'AVAX': token.contractAddress = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'; break;
			case 'FTM': token.contractAddress = '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'; break;
		}
		return token;
	});

	return array;
}


export const utils = {
	// getAccountBalance,
	getMetaMaskBalance,
	getSeizeToken,
	getExpensiveToken,
	getChainLiquidator,
	health2Pers,
	getChain,
	getSuppliedTokens,
	getBorrowedTokens,
	nullAddressHandler,
	getParams,
	fromWei,
	toWei,
	roundValue,
	hex2num,
	num2hex,
	dateGenerator,
	valueGenerator,
	changeGenerator,
	imgErrorHandle,
	getNowTimeStamp,
	symbolSplit,
	getSymbol,
	howSmall,
	stateHelper,
	getAddressLink,
	getTxLink,
	filteredData,
	uniqueAllowance,
	stringCreator,
	filteredVenusData,
	filteredCompaundData,
	filteredPoolsFarmsData
};