import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import { HodlBotState } from '../../models/hodl-bot-model';
import './styles.scss';
import { BotSettings } from './templates/botSettingsTemplate';
import { BotSettingsRunning } from './templates/botSettingsRunningTemplate';
import { HowItWorks } from './templates/howItWorksTemplate';
import { constants } from './constants';
import { BotCreatedModal } from './templates/botCreatedModal';
import { TradingView } from './templates/tradingViewTemplate';
import { Button } from 'react-bootstrap';
import { uiActions } from './actions/uiActions';
import { ManageBotCards } from './templates/manageBotCardsTemplate';
import { Events } from './templates/eventsTemplate';
import { SinglePairPacks } from './templates/singlePairPacks/view';
import {hodlBotEffects} from "./actions/hodlBotEffects";

const {
  TITLES: {
    CREATE_HODL_BOT, HODL_BOT, HODL_BOT_NUMBER
  },
  BUTTONS: {
    STOP_BOT,
    DELETE_BOT
  }
} = constants;

export const HodlBotLayout = () => {
  const state = HodlBotState.useStoreState(state => state);
  const actions = HodlBotState.useStoreActions(actions => actions);
  const ref = useRef();
  const title = !state.isBotRunning ? CREATE_HODL_BOT : state.manageBot ? `${HODL_BOT_NUMBER} #16555` : HODL_BOT;

  const values = { botId: state.botId };
  const handleStopBot = () => actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_INVOKED]({
    values,
    method: 'stop-bot'
  })
  const handleDeleteBot = () => actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_INVOKED]({
    values,
    method: 'delete-bot'
  })

  return <div ref={ref} className="page-container">
    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
      <div className="hodl-bot-layout">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <p className="font-size-22 font-weight-medium m-0">{ title }</p>
          { state.manageBot &&
            <div className="font-inter">
              <Button
                type="button"
                size="lg"
                variant="warning"
                className="font-size-14 font-weight-medium warning-btn"
                onClick={handleStopBot}
              >{STOP_BOT}</Button>
              <Button
                type="button"
                size="lg"
                variant="danger"
                className="font-size-14 font-weight-medium ms-3"
                onClick={handleDeleteBot}
              >{DELETE_BOT}</Button>
            </div>
          }
        </div>
        { state.manageBot || state.isBotRunning ?
          <SinglePairPacks state={state} actions={actions}/> :
          null
        }
        { state.manageBot && <ManageBotCards state={state} actions={actions} /> }
        <div className="main-content-layout mb-4" style={state.manageBot ? { alignItems: 'unset' } : { alignItems: 'flex-start' } }>
          { !state.isBotRunning || state.manageBot ?
            <BotSettings state={state} actions={actions} /> :
            <BotSettingsRunning state={state} actions={actions} />
          }
          { !state.manageBot ?
            <HowItWorks state={state} actions={actions} /> :
            <TradingView state={state} actions={actions} />
          }
          <BotCreatedModal show={state.isModalOpen} state={state} actions={actions} />
        </div>
        { state.manageBot && <Events state={state} actions={actions} /> }
      </div>
    </CustomScroll>
  </div>;
};

export const HodlBotPage = () => <HodlBotState.Provider>
  <HodlBotLayout/>
</HodlBotState.Provider>;
