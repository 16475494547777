import React from 'react';
import { useStoreState } from 'easy-peasy';
import { StandardOptions } from '../../../../../components/utility/standard-options/view';
import { constants } from '../../../../../constants/constants';
import { Selector } from '../../../../../components/utility/dropdown-selector/view';
import { botsEffects } from '../botsEffects';
import { AddButton } from '../../../../../components/utility/add-button/view';
import { isEmpty, cloneDeep } from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';

const { BOTS_WIDGET, POPPER_DATA } = constants;
const { filterList, sortingList, sortByTitle, findByTitle } = BOTS_WIDGET;

const _renderTitle = (title) => {
    return <div className="bots-widget-title text-uppercase font-size-14 font-weight-medium">
        {title}
    </div>;
};

const _renderSortBy = () => {
    const state = useStoreState(state => state.botsWidget);
    const value = cloneDeep(state.sortBy);
    return <div className="sort-by">
        <Selector
            classes=""
            disabled={isEmpty(state.originalData)}
            list={sortingList}
            value={value}
            title={`${sortByTitle} ${value.label}`}
            titleClasses="text-capitalize me-2"
            iconPosition="right"
            menuAlign="end"
            popperData={POPPER_DATA}
            actionName={botsEffects.actionTypes.BOTS_WIDGET_UI_ACTIONS_SORT_BY_CHANGED}
        />
    </div>;
};

const _renderFilterBy = () => {
    const state = useStoreState(state => state.botsWidget);
    const value = state.filterBy.id === 'reset' ? '' : state.filterBy;
    return <div className="filter-by">
        <Selector
            classes=""
            disabled={isEmpty(state.originalData)}
            list={filterList}
            title={`${findByTitle} ${value.label ? value.label : 'All'}`}
            titleClasses="text-capitalize me-2"
            value={value}
            iconPosition="right"
            menuAlign="end"
            popperData={POPPER_DATA}
            actionName={botsEffects.actionTypes.BOTS_WIDGET_UI_ACTIONS_FILTER_BY_CHANGED}
        />
    </div>;
};


const _renderAddBot = () => {
    const state = useStoreState(state => state.botsWidget);
    return <LinkContainer to="CeFi?elem=protrade"><a className="a-link-container-wapper"><AddButton
        iconClass="icon-plus"
        size="ssm"
        label="Add bot"
        actionName={''}
        buttonClass={'add-bot'}
        appendToPayload={{ hi: 'mike' }}
        disabled={isEmpty(state.data)}
        labelClass={'ms-2 font-size-12'} />
    </a></LinkContainer>;

};

const _renderStandardOptions = loading => {
    return <div className="standard-options">
        <StandardOptions
            refresh = {{ type: 'bots' }}
            more={{ ...BOTS_WIDGET.STANDARD_OPTIONS_SCHEMA }}
            expand={{ type: 'expand' }}
            isLoading={loading}
        />
    </div>;
};


export const renderControlPanel = (isLoading, title) => {
    return <div className="bots-widget-controls-container d-flex justify-content-between align-items-top">
        <div className="d-flex justify-content-start">
            {_renderTitle(title)}
            <div className="ps-3">
                { _renderSortBy() }
            </div>
            <div className="ps-3">
                { _renderFilterBy() }
            </div>
        </div>
        <div className="d-flex justify-content-end">
            <div className="pe-3">
                { _renderAddBot() }
            </div>
            { _renderStandardOptions(isLoading) }
        </div>
    </div>;
};