import React, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, InputGroup, FormControl } from 'react-bootstrap';
import { MultiplyCustomSelector } from '../../components/utility/multiply-selector/view';
import { ButtonCustom } from '../../components/utility/custom-buttons/view';
import { PoolsState } from '../../models/pools-model';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CustomScroll from 'react-custom-scroll';
import './styles.scss';
import { CustomCollapsibleTableTemplate } from './templates/tableTemplate';
import { uiActions } from './actions/uiActions';
import { poolsEffects } from './actions/poolsEffects';
//import { AnalyticsContent } from '../defiMultiChainDashboard/templates/analyticsContent';
import { AnalyticsContent } from '../farmsPage/templates/farmsAnalyticsContent';
import { mockData } from './mockData';
import { InvestmentState } from '../../models/investment-model';
import { InvestmentLayout } from '../investmentPage/view';
import { investmentEffects } from '../investmentPage/actions/investmentEffects';

export const PoolsLayout = (props) => {
  const state = PoolsState.useStoreState((state) => state);
  const actions = PoolsState.useStoreActions((actions) => actions);
  const userWallet = useStoreState((state) => state.authentication.userData.userId);
  const protocol = state.protocolSelector.value.value;
  const ref = useRef();
  const pageId = 1;
  const [search, setSearch] = useState('');
  const actionsGlobal = useStoreActions((actions) => actions);
  const farmsPoolsSelector = useStoreState((state) => state.investment.farmsPoolsSelector);

  const onClickHandler = (tab) => actions[uiActions.actionTypes.POOLS_TABS_INVOKED]({ tab });
  const onChangeProtocolHandle = (value) =>
    actions[uiActions.actionTypes.POOLS_PROTOCOLS_SWITCHED]({ value, userWallet });
  const onChangeFarmsPoolsHandle = (value) =>
    actionsGlobal[investmentEffects.actionTypes.INVESTMENT_FARMS_POOLS_SWITCHER]({ value });

  useEffect(() => {
    actions[poolsEffects.actionTypes.POOLS_FETCHED]({ userWallet, value: protocol });
    let timer = setTimeout(
      () => actions[poolsEffects.actionTypes.POOLS_USER_FETCHED]({ userWallet, value: protocol }),
      500
    );

    return () => {
      clearTimeout(timer);
    };
  }, [state.currentTxStatus]);
  useEffect(() => {
    let timer = actions[poolsEffects.actionTypes.ALL_POOLS_FETCHED]({ userWallet });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div ref={ref} className="page-container">
      <CustomScroll
        heightRelativeToParent="100%"
        className="custom-scroll-bar main-content-scroll"
        addScrolledClass={true}
      >
        <div className="defi-multichain-dashboard-layout font-inter content">
          <AnalyticsContent actions={actions} mockData={mockData} state={state} pageId={pageId} />
        </div>
        <div className="pools-layout content">
          <div className="pools-layout controls">
            <div className="multiple-selector">
              <MultiplyCustomSelector
                options={state.protocolSelector.list}
                onChangeHandle={onChangeProtocolHandle}
                closeMenuOnSelect={true}
                isDisabled={state.protocolSelector.isDisabled}
                isMulti={false}
                isLoading={state.protocolSelector.isLoading}
                value={state.protocolSelector.value}
              />
            </div>
            <div className="live-finished">
              <ButtonGroup className="w-100 button-100">
                <ButtonCustom
                  label="Live"
                  onClick={() => onClickHandler('live')}
                  variant="default"
                  size="sm"
                  active={state.tab === 'live'}
                  name="button_live"
                />
                <ButtonCustom
                  label="Invested"
                  variant="default"
                  size="sm"
                  onClick={() => onClickHandler('invested')}
                  active={state.tab === 'invested'}
                  name="button_invested"
                />
                <ButtonCustom
                  label="Finished"
                  onClick={() => onClickHandler('finished')}
                  variant="default"
                  size="sm"
                  active={state.tab === 'finished'}
                  name="button_finished"
                />
              </ButtonGroup>
            </div>
            <div></div>

            {/*<div className="multiple-selector me-3">*/}
            {/*  <MultiplyCustomSelector*/}
            {/*    options={state.selector.list}*/}
            {/*    // onChangeHandle={onChangeHandle}*/}
            {/*    closeMenuOnSelect={true}*/}
            {/*    isDisabled={state.selector.isDisabled}*/}
            {/*    isMulti={false}*/}
            {/*    isLoading={state.selector.isLoading}*/}
            {/*    value={state.selector.value}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className="search-input">*/}
            {/*  <InputGroup>*/}
            {/*    <InputGroup.Text className="clear">*/}
            {/*      <i className="fa-thin fa-magnifying-glass"></i>*/}
            {/*    </InputGroup.Text>*/}
            {/*    <FormControl*/}
            {/*      onChange={(e) => setSearch(e.target.value)}*/}
            {/*      placeholder={'Wallet Address...'}*/}
            {/*    />*/}
            {/*    <InputGroup.Text className="custom-btn-search">Address</InputGroup.Text>*/}
            {/*  </InputGroup>*/}
            {/*</div>*/}
          </div>
          <div>
            <div className="multiple-selector">
              <MultiplyCustomSelector
                options={farmsPoolsSelector.list}
                onChangeHandle={onChangeFarmsPoolsHandle}
                closeMenuOnSelect={true}
                isMulti={false}
                value={farmsPoolsSelector.value}
              />
            </div>
          </div>
          <CustomCollapsibleTableTemplate
            state={state}
            actions={actions}
            toggleDetails={actions[uiActions.actionTypes.POOLS_TABLE_DETAILS_TOGGLE]}
            searchData={search}
          />
        </div>
      </CustomScroll>
    </div>
  );
};

export const PoolsPage = () => {
  const farmsPoolsValue = useStoreState((state) => state.investment.farmsPoolsSelector.value.value);

  return (
    <>
      {farmsPoolsValue === 'farm' ? (
        <InvestmentState.Provider>
          <InvestmentLayout />
        </InvestmentState.Provider>
      ) : (
        <PoolsState.Provider>
          <PoolsLayout />
        </PoolsState.Provider>
      )}
    </>
  );
};
