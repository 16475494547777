import React from 'react';
import { useStoreState } from 'easy-peasy';
import { isEmpty } from "lodash";
import { constants } from '../constants';
import { StandardOptions } from "../../../components/utility/standard-options/view";
import { ChangeLinkModal } from "./changeLinkModal";
import { uiActions } from '../actions/uiActions';
import { AddFavoriteForm } from "./addFavoriteForm";
import { EmptySearchState } from "../../../components/utility/empty-search-state/view";

const { FAVORITES: { FAVORITES, NO_FAVORITES }, STANDARD_OPTIONS_SCHEMA } = constants;

export const SideContent = props => {
  const { actions, state } = props;
  const handleOpenInEmptyFrame =(favoritesUrl)=> {
  
  
  actions[uiActions.actionTypes.DYNAMIC_SPLIT_URL_OPEN]({favoritesUrl:favoritesUrl});

  }
  
  const show = useStoreState(state => state.workBoard.changeLink.show);
  return <div className="favorites-content" >
      <div className="heading font-weight-medium mb-3">{FAVORITES}</div>
      <AddFavoriteForm actions={actions} />
      { !isEmpty(state.widgets) ?
        <div className="mt-4 favorites-content-items">
          {
            state.widgets.map((widget, index) => <div className="item" key={ widget.id }>
             
              <div className="d-flex mb-2 img-clickable"  >
                <img onClick={() => handleOpenInEmptyFrame(widget.url)} src={`https://www.google.com/s2/favicons?domain=${widget.url}&sz=64`} className="img-fluid rounded-start me-4" width={42} height={42}  alt="Favicon"/>
                <StandardOptions
                  more={{
                      ...STANDARD_OPTIONS_SCHEMA,
                      actionName: uiActions.actionTypes.DYNAMIC_SPLIT_MORE_CHANGED,
                      appendToPayload: {
                          id: widget.id,
                          action: actions,
                          favoritesUrl: widget.url
                      }
                }}
                />
              </div>
              <span className="font-size-12 link-text">{ widget.name }</span>
              <ChangeLinkModal state={state} actions={actions} widget={widget} show={show === widget.id} />
            </div>)
          }
        </div>
        :
        <div className="d-flex flex-column justify-content-center h-100 text-center mt-4">
          <EmptySearchState/>
          <p className="no-favorites">{NO_FAVORITES}</p>
        </div>
      }
  </div>;
};
