import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
const actionTypes = {
  EVENTS_BUTTON_ELEMENTS_CHANGED: 'EVENTS_BUTTON_ELEMENT#CHANGED',
  EVENTS_BUTTON_ELEMENTS_CLICKED: 'EVENTS_BUTTON_ELEMENT#CLICKED',
};

const clickActions = (actions, payload) => {
  const { name } = payload;
  const { updateState } = actions;
  switch (name) {
    case 'button_events_highlights':
      updateState({
        path: 'titleData.viewType',
        value: {
          highlights: true,
          upcoming_events: false,
          past_events: false,
        },
      });
      break;
    case 'button_events_upcoming':
      updateState({
        path: 'titleData.viewType',
        value: {
          highlights: false,
          upcoming_events: true,
          past_events: false,
        },
      });
      break;
    case 'button_events_past':
      updateState({
        path: 'titleData.viewType',
        value: {
          highlights: false,
          upcoming_events: false,
          past_events: true,
        },
      });
      break;
  }
};

const actionHandlers = {
  [actionTypes.EVENTS_BUTTON_ELEMENTS_CLICKED]: thunk((actions, payload) =>
    clickActions(actions, payload)
  ),
  updateState: action((state, payload) => utils.stateHelper(state, payload)),
};

export const eventsSettingsEffects = {
  actionTypes,
  actionHandlers,
};
