import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './modal.scss';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { messagesEffects } from './messagesEffects';
import { constants } from '../../constants/constants';

const { MESSAGES: { ACTIONS } } = constants;

const uiActions = (actions, type, data) => {
    switch (type) {
    case 'message_archive':
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type, data });
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MODAL_TOGGLED]({});
        break;
    case 'message_delete':
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type, data });
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MODAL_TOGGLED]({});
        break;
    case 'modal_close':
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MODAL_TOGGLED]({});
        break;
    }
};

export const MessagesModal = () => {
    const actions = useStoreActions(actions => actions);
    const data = useStoreState(state => state.messages.modalData);
    const handleClose = () => uiActions(actions, 'modal_close');
    const isArchiveDisabled = data.status === 'ARCHIVED' ? true : false;
    return <Modal
        size="md"
        show={Boolean(data.id)}
        centered
        backdrop="static"
        className="message-view"
        onHide={handleClose}
        autoFocus={true}
        enforceFocus={true}>
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>

            <div className="d-flex flex-column justify-content-center">
                <div className="title font-size-14 font-weight-bold mb-2">{data.title}
                    <span className="font-size-10 font-weight-thin ms-2">{data.timeStamp}</span>
                </div>
                <div className="content font-size-12 font-weight-regular">{data.content}</div>
            </div>
            <div className="controls d-flex justify-content-end pe-1 pt-2">
                <Button variant="link" size="sm" disabled={isArchiveDisabled}
                    onClick={() => uiActions(actions, 'message_archive', data)}>
                    <div className="font-size-16">{ACTIONS.ARCHIVE}</div></Button>
                <Button variant="link" size="sm"
                    onClick={() => uiActions(actions, 'message_delete', data)}>
                    <div className="font-size-16">{ACTIONS.DELETE}</div></Button>
            </div>

        </Modal.Body>
    </Modal>;
};