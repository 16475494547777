import React, { useRef } from 'react';
import { constants } from '../../constants';
import { DefiMultiChainDashboardState } from '../../../../models/defi-multichain-dashboard-model';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../../components/utility/empty-search-state/view';
import { Loader } from '../../../../components/utility/loader/view';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { PopoverLayout } from '../../../../components/utility/popover/view';
import { uiActions } from '../../actions/uiActions';
import numeral from 'numeral';
import './styles.scss';
import { dataHelper } from '../../dataHelpers/dataHelper';

const { COMPOUND: { TABLE_COMPOUND_KEYS } } = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderTableHeader = () => {
    return <div className="custom-table-row table-header">
        { TABLE_COMPOUND_KEYS.map((key, i) => <div className="custom-table-header-cell font-size-14 font-weight-medium" key={`header-${i}`}>{key}</div>) }
    </div>;
};

const renderRow = (data, actions, state, index) => {
    const { token: { symbol, address, name }, borrow_amount, borrow_price, supply_amount, supply_price } = data;

    const sumValue = Number(supply_price) + Number(borrow_price);

    return <div className="custom-table-row table-body font-size-14 font-weight-regular" key={`body-${index}-${address}-${symbol}`}>
        <div className="custom-table-body-cell d-flex align-items-center">
            <PopoverLayout icon={symbol} message={symbol} title={false} />
            <div className="token">
                <div>{name}</div>
                <div className="address-wrapped">{address}</div>
            </div>
        </div>
        <div className="custom-table-body-cell d-flex align-items-center">
            <div className="token">
                <div>{borrow_amount}</div>
                <div className="address-wrapped">${borrow_price}</div>
            </div>
        </div>
        <div className="custom-table-body-cell d-flex align-items-center">
            <div className="token">
                <div>{supply_amount}</div>
                <div className="address-wrapped">${supply_price}</div>
            </div>
        </div>
        <div className="custom-table-body-cell d-flex align-items-center">
            <div className="token">
                <div className="address-wrapped">${numeral(sumValue).format('0,00.00')}</div>
            </div>
        </div>
    </div>;
};

const renderLoader = () => {
    return <div className="loader text-center" key={0}>
        <span>
            <i className="fa fa-refresh fa-spin"></i>
            <span className="ms-3">Loading...</span>
        </span>
    </div>;
};

const renderTableBody = (data, actions, state) => data.map((d, i) => renderRow(d, actions, state, i));

export const TableCompoundTemplate = props => {
    const actions = DefiMultiChainDashboardState.useStoreActions(actions => actions);
    const state = DefiMultiChainDashboardState.useStoreState(state => state);
    const data = dataHelper.formatArrayObject(state.compound.data, 'supply_price', 'borrow_price');
    const tableData = dataHelper.sortByValue(data, 'sumValue');
    const ref = useRef();
    const loadFunc = value => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING]({ page: value, state });

    return <div className="custom-table-wrapper"><div className="custom-table h-100" ref={ref}>
        { renderTableHeader() }
        <div className="scroll-container">
            { state.wallet.loader ?

                <Loader size={'6x'}/>
                :
                !isEmpty(tableData) ?

                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={loadFunc}
                            hasMore={state.compound.pagination.hasMore}
                            loader={renderLoader()}
                            useWindow={false}
                            getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
                            { renderTableBody(tableData, actions, state) }
                        </InfiniteScroll>
                    </CustomScroll>
                    :
                    <EmptySearchState/> }
        </div>
    </div>
    </div>;
};