import React, { Fragment, useEffect, useRef } from 'react';
import { useStoreState } from 'easy-peasy';

import './styles.scss';
import { constants } from './constants';
import { HomeState } from '../../models/home-model';
import { HomePageNav } from './templates/navigation/view';
import CustomScroll from 'react-custom-scroll';
import { StaticFooter } from './templates/footer/view.js';
import { Route, Switch } from 'react-router-dom';
import { HomePagePlaceholder } from './templates/placeholder';
import { routing } from '../../routing/routing-mapping';
import { Notify } from '../../components/utility/notifications/view';
import { Scrollbar } from 'react-scrollbars-custom';
import LoginModal from '../../components/loginModal/view';
import { color } from 'd3';

const {
  NAV: { NAV_LINKS, NAV_LOGO },
  FOOTER,
} = constants;

const StaticLayout = () => {
  const { STATIC_MAPPING } = routing;
  const showModal = useStoreState((state) => state.loginModal.showModal);

  return (
    <div className="h-100 w-100 home-page wrapper night-theme">
      <Scrollbar style={{ backgroundColor: '#8A8D8F' }} noScrollX>
        <div className="navigation">
          <HomePageNav navLogo={NAV_LOGO} navLinks={STATIC_MAPPING} />
        </div>
        <Switch>
          <Route path="/:id" children={<HomePagePlaceholder />} />
        </Switch>
        <footer className="footer-wrapper">
          <StaticFooter footer={FOOTER} />
        </footer>
      </Scrollbar>
      <Notify />

      {showModal && <LoginModal />}
    </div>
  );
};

export const StaticPageLayout = () => {
  return (
    <HomeState.Provider>
      <StaticLayout />
    </HomeState.Provider>
  );
};
