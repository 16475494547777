import React from 'react';
import { EventsState } from '../../../models/events-model';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';

const NewsCardLayout = () => {
  const newsState = EventsState.useStoreState((state) => state.upcoming_events_news);
  return (
    <div className="news-body">
      {!isEmpty(newsState) ? (
        newsState.map((data, index) => {
          const imageStyle = {
            '--news-image-url': `url(${data.newsImage})`,
          };
          return (
            <div className="news-wrapper" key={index}>
              <div className="news-image" style={imageStyle}></div>
              <div className="news-container">
                <div className="container-title">News</div>
                <div className="news-title">{data.newsTitle}</div>
                <div className="news-description">{data.newsDescription}</div>
              </div>
              <div className="news-date">{data.newsDate}</div>
            </div>
          );
        })
      ) : (
        <EmptySearchState />
      )}
    </div>
  );
};

export const NewsPage = () => (
  <EventsState.Provider>
    <NewsCardLayout />
  </EventsState.Provider>
);
