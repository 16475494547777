import React from 'react';
import { SignalWidget } from '../../dashboard/widgets/signal-widget/view';
import { renderControlPanel } from '../../dashboard/widgets/info-widget/templates/controlPanel';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import CustomScroll from 'react-custom-scroll';
import { constants } from '../../../constants/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';

const { WIDGET_INFO } = constants;
const { SIGNAL_LABEL } = WIDGET_INFO;

const searchHandle = (e, _) => {
    const value = e.target.value;
    _.updateState({
        path: 'signal.searchValue',
        value: value,
        operation: 'set'
    });
};

const resetHandle = _ => {
    _.updateState({
        path: 'signal.searchValue',
        value: '',
        operation: 'set'
    });
};

export const RenderSignalWidget = () => {
    const loaders = useStoreState(state => state.loaders);
    const signalsState = useStoreState(state => state.signal);
    const actions = useStoreActions(actions => actions);
    const isLoadingSignals = loaders.signals;
    return <div className="signal-column">
        <div className="signal-control-panel">
            { renderControlPanel(SIGNAL_LABEL, 'signals', isLoadingSignals) }
        </div>
        <div className="signal-search-element">
            <InputGroup className="signal-search-component">
                <FormControl
                    className="signal-search"
                    onChange={(e) => searchHandle(e, actions)}
                    placeholder="Search..."
                    value={signalsState.searchValue}
                    size="sm"/>
                { signalsState.searchValue ? <InputGroup.Text>
                    <FontAwesomeIcon className="cursor-pointer reset-icon" icon={faTimes} onClick={() => resetHandle(actions)}/>
                </InputGroup.Text> : null}
            </InputGroup>
        </div>
        <div className="signal-content">
            <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                <SignalWidget/>
            </CustomScroll>
        </div>
    </div>;
};