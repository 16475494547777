import { isEmpty } from 'lodash';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';
import { constants } from '../constants';
import Big from 'big.js';
import numeral from 'numeral';

const { ABI } = constants;

const formatArrayObject = (array, cheffAddress) => {
    return array.map(item => {
        return {
            lptoken: item.pool.lptoken,
            reward_amount: item.reward_amount,
            reward_price: item.reward_price,
            staked_amount: item.staked_amount,
            staked_price: item.staked_price,
            token0_amount: item.token0_amount,
            token1_amount: item.token1_amount,
            rewardTokens: item.pool.rewardTokens,
            pid: item.pool.pid,
            cheffAddress: cheffAddress
        };
    });
};

const getOthersFraction = array => {
    const allocation = array.map(item => item.fraction);
    const fractionSum = allocation.reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue), 0);
    const othersFraction = 100 - fractionSum;
    return othersFraction.toString();
};
const getToken = async lpAddress => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const lpContract = new ethers.Contract(lpAddress, ABI, provider);
    const signer = provider.getSigner();
    const balance = await lpContract.balanceOf(signer.getAddress());

    return utils.fromWei(utils.hex2num(balance._hex));
};
const getPoolProtocolsFraction = tokens => {
    const allTokens = tokens.map(token => token.pool.reward_token.name);
    const onePercent = getTotalPoolBalance(tokens) / 100;
   
    const fractionsArray = tokens.map((token, index) => {
        if (Number(token.reward_amount)  === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => Number(t.reward_amount))[index] / onePercent).format('0,00'),
                value: numeral(Number(token.reward_amount)).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const getTokensPoolFraction = tokens => {
    const allTokens = tokens.map(token => token.pool.reward_token.symbol);
    const onePercent = getTotalPoolBalance(tokens) / 100;
    const fractionsArray = tokens.map((token, index) => {
        if (Number (token.reward_amount) === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => Number (t.reward_amount))[index] / onePercent).format('0,00'),
                value: numeral(Number (token.reward_amount)).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const mergedAllPoolExhanges = state=> {
    if (typeof state.tableData.pancakeSwapData !== 'undefined'){
        return state.tableData.pancakeSwapData.concat(state.tableData.quickSwapData);
    }
}

const getTotalPoolBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+ balance.reward_amount));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};
const getTotalBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+balance.reward_price));
    }
    
    return !isEmpty(balances) ? balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf()) : 0;
};

const getProtocolsFraction = tokens => {
    const allTokens = tokens.map(token => token.pool.rewardTokens.map(s => s.name));
    const onePercent = getTotalBalance(tokens) / 100;
   
    const fractionsArray = tokens.map((token, index) => {
        if (token.reward_price === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => t.reward_price)[index] / onePercent).format('0,00'),
                value: numeral(token.reward_price).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const getTokensFraction = tokens => {
    const allTokens = tokens.map(token => token.pool.rewardTokens.map(s => s.symbol));
    const onePercent = getTotalBalance(tokens) / 100;
   
    const fractionsArray = tokens.map((token, index) => {
        if (token.reward_price === 0) {
            return {
                asset: allTokens[index],
                fraction: 0,
                value: 0
            }; 
        } else {
            return {
                asset: allTokens[index],
                fraction: numeral(tokens.map(t => t.reward_price)[index] / onePercent).format('0,00'),
                value: numeral(token.reward_price).format('0,00.00')
            };
        }
      
    });

    return fractionsArray;
};

const mergedAllExhanges = state=> {
    if (typeof state.tableData.pancakeSwapData !== 'undefined'){
        return state.tableData.pancakeSwapData.concat(state.tableData.quickSwapData, state.tableData.traderJoeSwapData);
    }
}
const getFarm = (farms, lpAddress) => {
    const arr = farms.filter(farm => farm.lptoken.baseData.address === lpAddress);
    
    if(isEmpty(arr)) return { multiplier: '-' };
    else return arr.find(farm => farm.lptoken.baseData.address === lpAddress);
};

//  harvest
const getHarvestParams = (pid, from, to) => {
    const newPid = utils.num2hex(pid).slice(2);
    const newReward = utils.stringCreator('0', 64);
    const pidString = `${utils.stringCreator('0', 64 - newPid.length)}${newPid}`;
    const method = '0xe2bbb158';
    const data = `${method}${pidString}${newReward}`;

    const params = {
        to: to || '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
        from,
        value: '0x0',
        data,
    };
    return params;
};

//  UNstake
const getUnstakeParams = (pid, from, amount, to) => {
    amount = utils.toWei(amount);
    amount = utils.num2hex(amount);
    const newPid = utils.num2hex(pid).slice(2);
    const withdrawAmount = amount.toString().slice(2);
    const pidString = `${utils.stringCreator('0', 64 - newPid.length)}${newPid}`;
    const method = '0x441a3e70';
    const data = `${method}${pidString}${utils.stringCreator('0', 64 - withdrawAmount.length)}${withdrawAmount}`;

    const params = {
        to: to || '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
        from,
        value: '0x0',
        data,
    };

    return params;
};

//  Stake
const getStakeParams = (pid, from, to, amount) => {
    amount = utils.toWei(amount);
    amount = utils.num2hex(amount);
    const newPid = utils.num2hex(pid).slice(2);
    const depositAmount = amount.toString().slice(2);
    const pidString = `${utils.stringCreator('0', 64 - newPid.length)}${newPid}`;
    const method = '0xe2bbb158';
    const data = `${method}${pidString}${utils.stringCreator('0', 64 - depositAmount.length)}${depositAmount}`;

    const params = {
        to: to || '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
        from,
        value: '0x0',
        data,
    };

    return params;
};

// Approve
const getApproveParams = (from, to, contract) => {
    const method = '0x095ea7b3';
    const contractAddress = contract || 'a5f8C5Dbd5F286960b9d90548680aE5ebFf07652';
    const data = `${method}${utils.stringCreator('0', 24)}${contractAddress}${utils.stringCreator('f', 64)}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

export const dataHelper = {
    getApproveParams,
    formatArrayObject,
    getToken,
    getStakeParams,
    getUnstakeParams,
    getHarvestParams,
    getFarm,
    getOthersFraction,
    getTokensFraction,
    getTotalBalance,
    mergedAllExhanges,
    getProtocolsFraction,
    getPoolProtocolsFraction,
    getTokensPoolFraction,
    mergedAllPoolExhanges,
    getTotalPoolBalance
};