import { action, thunk } from 'easy-peasy';
import { refreshEffects } from '../priceFetcher/refreshEffects';
import { serviceUtils } from '../../service/serviceUtils';
import { isEmpty } from 'lodash';
import { notifyEffects } from '../utility/notifications/notifyEffects';

const URL = '/messages';

const actionTypes = {
    MESSAGES_ACTION_REFRESHED: 'MESSAGES_ACTION#REFRESHED',
    MESSAGES_ACTION_MUTATED: 'MESSAGES_ACTION#MUTATED',
    MESSAGES_ACTION_MODAL_TOGGLED: 'MESSAGES_ACTION_MODAL#TOGGLED',
    MESSAGES_MUTATION_FAILED: 'MESSAGES_MUTATION#FAILED',
    MESSAGES_MUTATION_SUCCEED: 'MESSAGES_MUTATION#SUCCEED',
    MESSAGES_MUTATION_INVOKED: 'MESSAGES_MUTATION_INVOKED',
    MESSAGES_SHOW_ALL_TOGGLED: 'MESSAGES_SHOW_ALL#TOGGLED'
};

const getMessagesForMarkRead = data => {
    return data
        .filter(m => m.status === 'NEW' || m.status === 'URGENT')
        .map(f => (
            {
                id: f.id,
                status: 'READ'
            }
        ));
};

const getMessagesForDelete = data => {
    return data
        .map(f => (
            {
                id: f.id
            }
        ));
};

const refreshHandle = (actions) => {
    actions[refreshEffects.actionTypes.REFRESH_DATA_INVOKED]({ type: 'messages' });
};

const toggeleShow = state => {
    state.messages.visibility = !state.messages.visibility;
};

const mutateHandle = (actions, payload) => {
    const { type, data } = payload;
    let requestData = [];
    switch (type) {
    case 'message_mark_read':
        actions[actionTypes.MESSAGES_MUTATION_INVOKED]({ method: 'POST', data: { messages: [{ id: data.id, status: 'READ' }] } });
        break;
    case 'message_archive':
        actions[actionTypes.MESSAGES_MUTATION_INVOKED]({ method: 'POST', data: { messages: [{ id: data.id, status: 'ARCHIVED' }] } });
        break;
    case 'message_delete':
        actions[actionTypes.MESSAGES_MUTATION_INVOKED]({ method: 'DELETE', data: { messages: [{ id: data.id, status: 'DELETED' }] } });
        break;
    case 'message_delete_all':
        requestData = getMessagesForDelete(data);

        if (isEmpty(requestData))
            return;

        actions[actionTypes.MESSAGES_MUTATION_INVOKED]({ method: 'DELETE', data: { messages: requestData } });
        break;
    case 'message_mark_all_read':
        requestData = getMessagesForMarkRead(data);

        if (isEmpty(requestData))
            return;

        actions[actionTypes.MESSAGES_MUTATION_INVOKED]({ method: 'POST', data: { messages: requestData } });
        break;

    case 'message_show_all':
        actions[actionTypes.MESSAGES_SHOW_ALL_TOGGLED]();
        break;
    }
};

const modalHandle = (state, payload) => {
    state.messages.modalData = payload;
};

const successAction = (state, payload) => {
    const { response } = payload;
    state.messages.messageData = response;
};

const errorAction = (state, payload) => {
    const { error } = payload;
    const notification = { type: 'danger', text: `Request failed, ${error}` };
    notifyEffects.notifyAction(notification);
};

const mutation = (action, payload) => {
    serviceUtils.HttpService({
        url: URL,
        method: payload.method,
        errorActionType: action[actionTypes.MESSAGES_MUTATION_FAILED],
        successActionType: action[actionTypes.MESSAGES_MUTATION_SUCCEED],
        ...payload
    });
};


const actionHandlers = {
    [actionTypes.MESSAGES_ACTION_REFRESHED]: thunk((actions, payload) => refreshHandle(actions, payload)),
    [actionTypes.MESSAGES_ACTION_MODAL_TOGGLED]: action((state, payload) => modalHandle(state, payload)),
    [actionTypes.MESSAGES_ACTION_MUTATED]: thunk((actions, payload) => mutateHandle(actions, payload)),
    [actionTypes.MESSAGES_MUTATION_SUCCEED]: action((state, payload) => successAction(state, payload)),
    [actionTypes.MESSAGES_MUTATION_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.MESSAGES_MUTATION_INVOKED]: thunk((actions, payload) => mutation(actions, payload)),
    [actionTypes.MESSAGES_SHOW_ALL_TOGGLED]: action((state, payload) => toggeleShow(state, payload))
};

export const messagesEffects = {
    actionTypes,
    actionHandlers
};