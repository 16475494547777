import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { dataHelper } from '../dataHelpers/dataHelper';
import { investmentEffects } from './investmentEffects';

const actionTypes = {
  FARMS_TABLE_DETAILS_TOGGLE: 'FARMS_TABLE_DETAILS#TOGGLE',
  FARMS_TABLE_LOAD_MORE_LOADING: 'FARMS_TABLE_LOAD_MORE#LOADING',
  FARMS_MODAL_INVOKED: 'FARMS_MODAL#INVOKED',
  FARMS_MODAL_INPUT_AMOUNT_SET: 'FARMS_MODAL_INPUT_AMOUNT#SET',
  FARMS_MODAL_MAX_AMOUNT_SET: 'FARMS_MODAL_MAX_AMOUNT#SET',
  FARMS_TABS_INVOKED: 'FARMS_TABS#INVOKED',
  FARMS_PROTOCOLS_SWITCHED: 'FARMS_PROTOCOLS#SWITCHED',
  ALL_FARMS_INVOKED: 'ALL_FARMS#INVOKED',
};

const toggleDetails = (state, payload) => {
  const { id } = payload;
  if (!state.tableData.toggled.includes(id))
    state.tableData.toggled = [...state.tableData.toggled, id];
  else state.tableData.toggled = state.tableData.toggled.filter((item) => item !== id);
};

const loadMoreHandle = (actions, payload) => {
  const { updateState } = actions;
  const {
    page,
    state: { tableData },
  } = payload;
  if (!tableData.pagination.hasMore) return;

  updateState([
    {
      path: 'tableData.pagination.page',
      value: page,
    },
    {
      path: 'typeOfFetch',
      value: 'push',
    },
    {
      path: 'tableData.pagination.hasMore',
      value: false,
    },
  ]);
};

const modalInvoke = async (actions, payload) => {
  const { name, modal, data } = payload;
  const { updateState } = actions;
  if (name === 'stake-modal' && modal === 'open-modal')
    updateState({ path: 'stakedBalance', value: await dataHelper.getToken(data.lpAddress) });

  switch (modal) {
    case 'open-modal':
      updateState([
        { path: 'modal.name', value: name },
        { path: 'modal.show', value: true },
        { path: 'modal.data', value: data },
      ]);
      break;
    case 'close-modal':
      updateState(
        [
          { path: 'modal.name', value: name },
          { path: 'modal.show', value: false },
          { path: 'modal.data', value: {} },
        ],
        { path: 'stakedBalance', value: 0 }
      );
      break;
  }
};

const amountHandle = (state, payload) => {
  const { amount, name } = payload;
  switch (name) {
    case 'unstake':
      state.unstakeAmount = amount;
      break;
    case 'stake':
      state.stakeAmount = amount;
      break;
  }
};

const setMaxAmountHandle = (state, payload) => {
  const { max, name } = payload;
  switch (name) {
    case 'unstake':
      state.unstakeAmount = max;
      break;
    case 'stake':
      state.stakeAmount = max;
      break;
  }
};

const tabInvoked = (state, payload) => {
  const { tab } = payload;
  state.tab = tab;
  switch (tab) {
    case 'live':
      state.tableData.filteredData = state.tableData.mergedFarms.filter(
        (farm) => !farm.reward_amount || farm.reward_amount <= 0
      );
      break;
    case 'invested':
      state.tableData.filteredData = state.tableData.mergedFarms.filter(
        (farm) => farm.staked_amount > 0
      );
      break;
    case 'finished':
      state.tableData.filteredData = state.tableData.mergedFarms.filter((farm) => farm.apr <= 0);

      break;
    default:
      state.tableData.filteredData = state.tableData.mergedFarms.filter(
        (farm) => !farm.reward_amount || farm.reward_amount <= 0
      );
  }
};

const protocolSwitched = (actions, payload) => {
  const { updateState } = actions;
  const { value, userWallet } = payload;
  switch (value.value) {
    case 'pancake':
      updateState({ path: 'chain', value: 'BSC' });
      break;
    case 'joe':
      updateState({ path: 'chain', value: 'AVAXc' });
      break;
    case 'quickswap':
      updateState({ path: 'chain', value: 'MATIC' });
      break;
    case 'all':
      updateState({ path: 'chain', value: 'ALL' });
      break;
  }

  updateState([
    {
      path: 'protocolSelector.value',
      value,
    },
    {
      path: 'tableData.toggled',
      value: [],
    },
    {
      path: 'loader',
      value: true,
    },
    {
      path: 'tableData.filteredData',
      value: [],
    },
    {
      path: 'tableData.lpFarms',
      value: [],
    },
    {
      path: 'tableData.mergedFarms',
      value: [],
    },
  ]);

  actions[investmentEffects.actionTypes.FARMS_FETCHED]({ userWallet, value: value.value });
  setTimeout(
    () =>
      actions[investmentEffects.actionTypes.FARMS_USER_FETCHED]({ userWallet, value: value.value }),
    2000
  );
};

const actionHandlers = {
  [actionTypes.FARMS_PROTOCOLS_SWITCHED]: thunk((actions, payload) =>
    protocolSwitched(actions, payload)
  ),
  [actionTypes.FARMS_TABS_INVOKED]: action((state, payload) => tabInvoked(state, payload)),
  [actionTypes.FARMS_MODAL_MAX_AMOUNT_SET]: action((state, payload) =>
    setMaxAmountHandle(state, payload)
  ),
  [actionTypes.FARMS_MODAL_INPUT_AMOUNT_SET]: action((state, payload) =>
    amountHandle(state, payload)
  ),
  [actionTypes.FARMS_MODAL_INVOKED]: thunk(async (actions, payload) =>
    modalInvoke(actions, payload)
  ),
  [actionTypes.FARMS_TABLE_DETAILS_TOGGLE]: action((state, payload) =>
    toggleDetails(state, payload)
  ),
  [actionTypes.FARMS_TABLE_LOAD_MORE_LOADING]: thunk((actions, payload) =>
    loadMoreHandle(actions, payload)
  ),

  updateState: action((state, payload) => utils.stateHelper(state, payload)),
};

export const uiActions = {
  actionTypes,
  actionHandlers,
};
