import React, { useEffect, useRef } from 'react';
import { templates } from './tableTemplate';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { constants } from '../../../constants';

const { BUILD_CONTENT: { TABLE } } = constants;

export const TableImagesGenerator = () => {
    const tableRef = useRef();
    const ref = useRef();
    useEffect(() => {
        drawHelper.drawTableContent(d3.select(tableRef.current), TABLE);
    }, );
    return <div ref={ref} className="images-background-grid">
        <div ref={ref}>
            <svg ref={tableRef} className='d-flex justify-content-center' viewBox="-100 0 1432 311" fill="none" xmlns="http://www.w3.org/2000/svg">
                { templates.tableImg() }
                <svg viewBox="-80 -50 1432 311" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.createPart() }</svg>
                <svg viewBox="-360 -60 1432 311" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.lauchPart() }</svg>
                <svg viewBox="-680 -45 1432 311" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.usePart() }</svg>
                <svg viewBox="-980 -45 1432 311" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.getPart() }</svg>
            </svg>
        </div>
    </div>;
};
