import React from 'react';
import { DashboardLendingState } from '../../../models/dashboard-lending-model';
import '../styles.scss';
import { lendingConstants } from '../constants';
import { isEmpty } from 'lodash';
import { UserTable } from './userTable';
import { MarketTable } from './marketTable';

const { SUPPLIED_USER, SUPPLIED_TABLE_HEADERS, SUPPLY_MARKET, SUPPLY_MARKET_TABLE_HEADERS } = lendingConstants;

export const SupplyContent = () => {
    const state = DashboardLendingState.useStoreState(state => state);

    return <div className="supply-layout">
        <div className="supply-layout-content">
            <div className="supply-user-table">
                {!isEmpty(state.tableData.supplyUser) && <><div className="supply-user-table-title">{SUPPLIED_USER}</div>
                    <div className="supply-user-table-headers">
                        {SUPPLIED_TABLE_HEADERS.map((header, index) => <div key={index} className="header-item">{header}</div>)}
                    </div>
                    <div className="supply-user-table-content">
                        <UserTable type="supply" />
                    </div></>}
                <div className="supply-market-table-title">{SUPPLY_MARKET}</div>
                <div className="supply-market-table-headers">
                    {SUPPLY_MARKET_TABLE_HEADERS.map((header, index) => <div key={index} className="header-item">{header}</div>)}
                </div>
                <div className="supply-market-table-content">
                    <MarketTable type="supply" />
                </div>
            </div>
        </div>
    </div>;
};