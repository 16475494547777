import React, { useState } from 'react';
import { constants } from '../constants';
import { useStoreState } from 'easy-peasy';
import Toggle from 'react-toggle';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { Button, Form, InputGroup } from 'react-bootstrap';
import '../styles/grid_bot_styles.scss';

const {
  DCA_BOT: { COST_AVEREAGE, TYPE, BOTT_WILL_BUY, FOR, EVERY, AT, NEXT_BUY_DATE, BY, BY_TYPES },
} = constants;
export const CostAverage = ({
  state,
  pairData,
  formControlChange,
  toggleOnChange,
  onClickHandler,
  theme,
  action,
}) => {
  return (
    <div className="dca-content">
      <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center">
        <span className="font-weight-medium font-size-16 me-3">{COST_AVEREAGE}</span>
        <Toggle
          icons={false}
          name="toggle_cost_average"
          onChange={toggleOnChange}
          checked={state.toggle_cost_average}
        />
      </label>
      {state.toggle_cost_average ? (
        <div className="cost-average-body">
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="dca-bot-left-title">{TYPE}</span>
          </div>
          {multiplyCustomSelector('By %', state.cost_average_by_types)}
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="dca-bot-left-title">{BOTT_WILL_BUY}</span>
          </div>
          {multiplyCustomSelector('USDT', state.cost_average_bot_will_buy)}
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="dca-bot-left-title">{FOR}</span>
            <span className="dca-bot-right-title">{FOR}</span>
          </div>
          <div className="custom-selectors">
            <div className={`form-input-shorter form-item pb-2`}>
              <InputGroup>
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  onChange={formControlChange}
                  name={'input_buy_for'}
                  type="number"
                  value={state.input_buy_for}
                />
                <InputGroup.Text className="right-input-button">USDT</InputGroup.Text>
              </InputGroup>
            </div>
            <div className="right-selector">{multiplyCustomSelector('USDT')}</div>
          </div>
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="dca-bot-left-title">{EVERY}</span>
            <span className="dca-bot-at-title">{AT}</span>
          </div>
          <div className="custom-selectors">
            <div className={`form-input-shorter form-item pb-2`}>
              <InputGroup>
                <InputGroup.Text className="left-input-button">
                  <i className="fa-light fa-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  className="font-size-14 font-weight-regular"
                  onChange={formControlChange}
                  name={'input_days_number'}
                  type="number"
                  value={state.input_days_number}
                />
                <InputGroup.Text className="right-input-button">Days</InputGroup.Text>
              </InputGroup>
            </div>
            <div className="right-selector">{multiplyCustomSelector('USDT')}</div>
          </div>
          <div className="customized-toggle cursor-pointer d-flex align-items-center">
            <span className="dca-bot-left-title">{NEXT_BUY_DATE}</span>
          </div>
          <div className="custom-selectors">
            <InputGroup className="with-left-button">
              <InputGroup.Text className="left-input-button">
                <i className="fa-light fa-calendar"></i>
              </InputGroup.Text>
              <Form.Control
                className="date-form"
                name="input_pick_date"
                style={theme === 'day-theme' ? { colorScheme: 'white' } : { colorScheme: 'dark' }}
                value={state.input_pick_date}
                onChange={(e) => action({ name: e?.target?.name, value: e?.target?.value })}
                type="date"
              />
              <InputGroup.Text
                onClick={(e) => action({ name: 'input_pick_date', value: '' })}
                className="right-input-button cursor-pointer"
              >
                <i className="icon-cancel cursor-pointer"></i>
              </InputGroup.Text>
            </InputGroup>
            <div className="day-buttons">
              <div className="button-today">
                <Button
                  variant="primary"
                  onClick={(e) => action({name: 'input_pick_date',value:  new Date().setDate(new Date().getDate())})}
                >
                  Today
                </Button>
              </div>
              <div className="button-tomorrow">
                <Button
                  variant="primary"
                  onClick={(e) =>
                    action({
                      name: 'input_pick_date',
                      value: new Date().setDate(new Date().getDate() + 1),
                    })
                  }
                >
                  Tomorrow
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const multiplyCustomSelector = (placeholder, options) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
  };

  return (
    <div>
      <MultiplyCustomSelector
        options={options}
        initialValue={null}
        onChangeHandle={handleChange}
        isLoading={false}
        isDisabled={false}
        value={selectedValue}
        closeMenuOnSelect={false}
        placeholder={placeholder}
      />
    </div>
  );
};

