import React from 'react';
import { ProfileState } from '../../../models/profile-model';
import { AddWalletModal } from '../modals/addWalletsModal';
import { profilePageActions } from '../actions/uiActions';
import { constants } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const {
  PROFILE_CARD: { CURRENT_WALLET, CURRENT_WALLET_NETWORK, COIN_TITLE, ADD_WALLET },
} = constants;

export const WalletList = ({idx, index}) => {
    let indexForSubmit=''
    const handleClick = event => {
        actions[profilePageActions.actionTypes.WALLET_MODAL_INVOKED]({name: 'open-modal', modal: 'ADD WALLET', id: index})
        indexForSubmit = index
      };
    const state = ProfileState.useStoreState(state => state);
    const actions = ProfileState.useStoreActions(actions => actions);
    // console.log('index: ', index)
    let walletData = state.walletData ? state.walletData.find((wallet) => wallet.cardID === index) : null
  //  console.log('wallet data u cardu: ', walletData)
     return <div className="body-wrapper">
     <div className="text-button" onClick={handleClick}>+ {ADD_WALLET}</div>
  
     
     {walletData && walletData.cardID === index ? walletData.walletAdrese.map((data, key) =>( <div key={idx}><div className="wallet-network">{CURRENT_WALLET} {idx} {CURRENT_WALLET_NETWORK}</div>
     <div  className="wallet-address-wrapper">
     <img src="../coins-full-library/bnb.svg" height="20" className="crypto-icon"></img>
     <div className="wallet-address">{data.walletAddress}</div>
     <div className="icon-delete" onClick={() => {console.log('data id u wallet listu: ', data.id) 
     actions[profilePageActions.actionTypes.PROFILE_MODAL_INVOKED]({name: 'open-modal', modal: 'DELETE', id: data.id, cardId: index})}}>
     <FontAwesomeIcon icon={faTrash}/></div>
     </div>
     <div className="networks-list">
     <img src="../coins-full-library/btc.svg" className="crypto-icon"></img>
        <div className="crypto-title">{COIN_TITLE}</div>
     </div>
     </div>)) : null
     }
     <AddWalletModal show={state.isWalletModalOpen} actions={actions} cardId={idx} index={index} indexSubmit={state.indexForSubmit} /*isOpenFromEdit ={false}*/ />
 </div>
 
}
