import React  from 'react';
import { useStoreState } from 'easy-peasy';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';

const { HOW_IT_WORKS: { HOW_IT_WORKS, TEXT, EXAMPLE, EXAMPLE_TEXT } } = constants;

export const HowItWorks = props => {
  const { actions, state } = props;
  const theme = useStoreState(state => state.root.theme);
  const imgPath = '../dashboard-library/'
  const img = theme === 'day-theme' ? 'graph-profit-light.svg' : 'graph-profit-dark.svg'

  const handleClick = () => actions[uiActions.actionTypes.HODL_BOT_TABLE_COLLAPSED]('howItWorks');

  return <div className="how-it-works-content">
    <div className="collapse-header">
      <div className="font-weight-medium me-4">{HOW_IT_WORKS}</div>
      <div></div>
      <div className="cursor-pointer">
        <i onClick={handleClick} className={`fa-solid fa-chevron-${ state.howItWorks.open ? 'up' : 'down' }`}></i>
      </div>
    </div>
    <Collapse in={state.howItWorks.open}>
      <div className="collapse-content">
        <div className="collapse-content-wrapper">
          <div className="collapse-content-wrapper-left">
            {TEXT.map((text) => <p key={text}>{text}</p>)}
            <p className="font-weight-bold">{EXAMPLE}</p>
            <p>{EXAMPLE_TEXT}</p>
          </div>
          <div className="collapse-content-wrapper-right">
            <img src={`${imgPath}${img}`} alt="Graph profit" />
          </div>
        </div>
      </div>
    </Collapse>
  </div>;
};
