import React from 'react';

export const constants = {
    VEST: {
        HEADER: {
            TITLE: 'SafeBuy',
            TIP: 'How it works'
        },
        CONTENT: {
            PERIOD: 'SafeBuy period',
            START: 'SafeBuy start',
            END: 'SafeBuy end',
            TOTAL_AMOUNT: 'Total amount',
            WITHDRAW: 'Withdraw',
            AVAILABLE: 'Available for withdraw',
            LEFT: 'Left',
            DAYS: 'days',
            ENTER_VALUE: 'Please enter value',
            CANCEL: 'Cancel investment',
            BUY: 'Buy',
            WARNING: `Do you really want to stop investment? 
            If you’ll stop invetment you will pay for part of token and fine 1% of lefted USDT`,
            USDT_TO_PAY: 'USDT to pay'
        },
        USDT_AMOUNT: {
            DAYS_180: 0.14,
            DAYS_90: 0.13
        }
    },
    BUY_ERRORS: {
        COUNTER_ERROR: 'Too many tries of signIn! Please check your credentials',
        ACCOUNT_ERROR: 'Please check your MetaMask account and reload this page',
    },
    PROJECT_NAME: 'monoceros',
    CONTENT: {
        TITLE_1: 'GET IN EARLY',
        TITLE_2: 'ON THE PRE-SALE',
        CONNECT_METAMASK_WALLET: 'Connect MetaMask',
        CONNECT_TRUST_WALLET: 'Wallet Connect',
        TRUST_WALLET: 'trustwallet',
        METAMASK: 'metamask',
        ENABLE: 'Enable',
        BUY: 'Buy',
        BUYING: 'Buying',
        SELLING: 'Selling',
        DISCLAIMER: 'MCS is the utility token of Monoceros Terminal which provides holders a list of benefits - discounts, governance right’s, income rewards and more to come.',
        SIGN_MESSAGE: 'Please sign to let us verify that you are the owner of this address'
    },
    CHANGE_RATE: 10,
    // MAX_SUPPLY: 20000000,
    MAX_SUPPLY: 400000000,
    TOTAL_AVAILABLE: 250000000,
    NETWORKS: [
        {
            symbol: 'Moonbeam',
            id: 'Moonbeam',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/moonbeam.svg"/><div className="ms-2">Moonbeam</div></div>,
            data: {
                'address': '0x4AfFE1AC6a1Df5932dFcF5782D55095ee62E6f1F',
                'USDT_ADDRESS': '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
                'chainId': 1284,
                'chainName': 'Moonbeam',
                'rpcUrls': ['https://rpc.api.moonbeam.network/'],
                'blockExplorerUrls': ['https://moonscan.io/'],
                'nativeCurrency': {
                    'symbol': 'GLMR',
                    'decimals': 18
                }
            }
        },
        {
            symbol: 'BSC',
            id: 'Binance',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/bsc.svg"/><div className="ms-2">Binance</div></div>,
            data: {
                'address': '0x1A4721fc27be2B9f2f9DeD865F6d47DB1291b499',
                'USDT_ADDRESS': '0x55d398326f99059ff775485246999027b3197955',
                'chainId': 56,
                'chainName': 'Binance Smart Chain',
                'rpcUrls': ['https://bsc-dataseed.binance.org/'],
                'blockExplorerUrls': ['https://bscscan.com'],
                'nativeCurrency': {
                    'symbol': 'BNB',
                    'decimals': 18
                }
            }
        },
        {
            id: 'Ethereum',
            symbol: 'ETH',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/eth.svg"/><div className="ms-2">Ethereum</div></div>,
            data: {
                'address': '0xb265D3778436Fc5c385AE10D72572f2106e28519',
                'USDT_ADDRESS': '0xdac17f958d2ee523a2206206994597c13d831ec7',
                'chainId': 1,
                'chainName': 'Ethereum Mainnet',
                'rpcUrls': ['https://rpc.ankr.com/eth/'],
                'blockExplorerUrls': ['https://etherscan.io/'],
                'nativeCurrency': {
                    'symbol': 'ETH',
                    'decimals': 18
                }
            }
        },
        {
            id: 'Avalanch',
            symbol: 'AVAXc',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/avax.svg"/><div className="ms-2">Avalanch</div></div>,
            data: {
                'address': '0x32862346217A4c1bBcEA72496CCC809Aee704B4b',
                'USDT_ADDRESS': '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
                'chainId': 43114,
                'chainName': 'Avalanche C-Chain',
                'rpcUrls': ['https://api.avax.network/ext/bc/C/rpc'],
                'blockExplorerUrls': ['https://snowtrace.io/'],
                'nativeCurrency': {
                    'symbol': 'AVAXc',
                    'decimals': 18
                }
            }
        },
        {
            id: 'Fantom',
            symbol: 'FTM',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/ftm.svg"/><div className="ms-2">Fantom</div></div>,
            data: {
                'address': '0x3B9F760BBE23510F84d6329dF06FF0e31582dD86',
                'USDT_ADDRESS': '0x049d68029688eabf473097a2fc38ef61633a3c7a',
                'chainId': 250,
                'chainName': 'Fantom Opera',
                'rpcUrls': ['https://rpc.ankr.com/fantom'],
                'blockExplorerUrls': ['https://ftmscan.com/'],
                'nativeCurrency': {
                    'symbol': 'FTM',
                    'decimals': 18
                }
            }
        },
        {
            id: 'Polygon',
            symbol: 'MATIC',
            jsx: <div className="d-flex justify-content-center align-items-center position-relative network-label">
                <img src="../dashboard-library/nets/matic.svg"/><div className="ms-2">Polygon</div></div>,
            data: {
                'address': '0x3e291c4d9d1970ECD1d2f04a62E5fDf65b5B8f87',
                'USDT_ADDRESS': '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
                'chainId': 137,
                'chainName': 'Polygon Mainnet',
                'rpcUrls': ['https://rpc.ankr.com/polygon'],
                'blockExplorerUrls': ['https://polygonscan.com/'],
                'nativeCurrency': {
                    'symbol': 'MATIC',
                    'decimals': 18
                }
            }
        }
    ],
    API: {
        method: 'GET',
        url: 'https://7al99cpzl1.execute-api.eu-west-1.amazonaws.com/Stage/web3/ico'
    },
    APPROVE_API: {
        method: 'GET',
        url: 'https://7al99cpzl1.execute-api.eu-west-1.amazonaws.com/Stage/web3/buyapproves?address='
    },
    ADDRESS: '0xDa5aC8F284537d6eaB198801127a9d49b0CbDff5',
    FORMAT: '0,0.[00]',
};
