import React, { useState } from 'react';
import {useStoreActions, useStoreState} from 'easy-peasy';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { tableEffects } from '../../actions/tableEffects';
import { constants } from '../../constants';
import { SmartTradeState } from '../../../../models/smart-trade-model';
import { MultiplyCustomSelector } from '../../../../components/utility/multiply-selector/view';
import Slider from 'rc-slider';

const {
  MODALS: {
    ADD_FUNDS,
    REDUCE_FUNDS,
    DISCARD,
    SAVE
  },
  FORM_LABELS: {
    VOLUME,
    AVAILABLE_AMOUNT,
    WITH_LEVERAGE,
    CURRENT_LEVERAGE,
    TOTAL_QUOTE_CURRENCY,
    TOTAL_BASE_CURRENCY,
    PRICE,
    TRADE_NOT_MEET_REQUIREMENTS
  },
  PRICE_LIST
} = constants;

export const AddReduceTradeModal = props => {
  const { show, state } = props;
  const actions = useStoreActions(actions => actions);
  const action = SmartTradeState.useStoreActions(action => action);
  const modalName = useStoreState(state => state.smartTrade.modalAddReduceFunds.name);

  const handleClose = () => actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({ name: 'close-modal', modal: 'add-funds' });
  const formControlChange = e => action[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_FORM_ELEMENTS_CHANGED]({name: e?.target?.name, value: parseFloat(e?.target?.value)});
  const sliderOnChange = value => action[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_FORM_ELEMENTS_CHANGED]({...value});

  const [flag, setFlag] = useState(true);
  const onBeforeHandle = () => setFlag(false);
  const onAfterChangeHandle = value => {
    sliderOnChange(value)
    setFlag(true);
  };
  const settings = {
    slider_funds_volume_percent: flag ? { value: state.input_funds_volume_percent || 0 } : {}
  };

  return <Modal
    show={show}
    size="md"
    centered
    className="trade-funds"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
  >
    <Modal.Header className="modal-header-border">
      <p className="title">{modalName === 'add-funds' ? ADD_FUNDS : REDUCE_FUNDS}</p>
      <div className="cursor-pointer cancel-icon" onClick={handleClose}>
        <i className="icon-cancel fa-lg"></i>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="font-inter">
        <Form.Group className="pb-4">
          <div className="form-item">
            <Form.Label className='font-weight-medium font-size-14'>{VOLUME}</Form.Label>
              <InputGroup>
                <Form.Control className='font-size-14 font-weight-regular' name="input_funds_volume_value" value={state.input_funds_volume_value} onChange={formControlChange} min={0} type="number"/>
                <InputGroup.Text className='right-input-button'>DOTUSD</InputGroup.Text>
              </InputGroup>
          </div>
        </Form.Group>

        <Form.Group className="pb-4">
          <div className="form-item">
            <div className="form-item d-flex w-100 align-items-center pb-2">
              <Slider className="slider -primary" {...settings.slider_funds_volume_percent} onBeforeChange={onBeforeHandle}
                      onAfterChange={value => onAfterChangeHandle({ name: 'slider_funds_volume_percent', value })}/>

              <InputGroup className="ms-3 slider-input-group">
                <Form.Control className='font-size-14 font-weight-regular' name="input_funds_volume_percent"
                              value={state.input_funds_volume_percent} onChange={formControlChange} max={100} min={0} type="number"/>
                <InputGroup.Text className='right-input-button'><i className="fa-solid fa-percent"></i></InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="pb-4">
          <Form.Label className="label label-wrapper">
            <div>{AVAILABLE_AMOUNT}:</div>
            <div>
              <span className="text">0.00952356</span>
              <span className="ms-1 text">LAZIO</span>
            </div>
          </Form.Label>
        </Form.Group>

        { modalName === 'add-funds' &&
          <>
            <Form.Group className="pb-4">
              <Form.Label className="label label-wrapper">
                <div>{WITH_LEVERAGE}:</div>
                <div>
                  <span className="text">4590.5689653</span>
                  <span className="ms-1 text">USDT</span>
                </div>
              </Form.Label>
            </Form.Group>

            <Form.Group className="pb-4">
              <Form.Label className="label label-wrapper">
                <div>{CURRENT_LEVERAGE}:</div>
                <div>
                  <span className="me-1 text">Isolated</span>
                  <span className="text">X</span>
                  <span className="ms-1 text">10.0</span>
                </div>
              </Form.Label>
            </Form.Group>
          </>
        }

        <Form.Group className="pb-4">
          <div className="form-item">
            <Form.Label className='label label-wrapper'>
              <div>{PRICE}:</div>
              <div className="d-flex">
                <div className="multiple-selector me-2 text">
                  <MultiplyCustomSelector
                    onChangeHandle={sliderOnChange}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    options={PRICE_LIST}
                    value={state.select_price_value}
                  />
                </div>
                <InputGroup className="form-item-input-group">
                  <Form.Control className='font-size-14 font-weight-regular' name="input_price_value" value={state.input_price_value} onChange={formControlChange} min={0} max={100} type="number"/>
                  <InputGroup.Text className='right-input-button'>USDT</InputGroup.Text>
                </InputGroup>
              </div>
            </Form.Label>
          </div>
        </Form.Group>

        <Form.Group className="pb-4">
          <Form.Group className="label label-wrapper">
            <div>{TOTAL_QUOTE_CURRENCY}:</div>
            <div>
              <span className="error">5.24556589</span>
              <span className="ms-1 error">USDT</span>
            </div>
          </Form.Group>
          <div className="label label-wrapper">
            <div></div>
            <div className="pt-1">
              <span className="error">{TRADE_NOT_MEET_REQUIREMENTS}:</span>
              <span className="error ps-1">15.0</span>
              <span className="error ps-1">USDT</span>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="pb-4">
          <Form.Group className="label label-wrapper">
            <div>{TOTAL_BASE_CURRENCY}:</div>
            <div>
              <span className="text">0.00090568</span>
              <span className="ms-1 text">LAZIO</span>
            </div>
          </Form.Group>
        </Form.Group>

        <Form.Group>
          <div className="submit-button pt-1">
            <Button
              onClick={handleClose}
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium discard">{DISCARD}</Button>
            <Button
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium confirm">{SAVE}</Button>
          </div>
        </Form.Group>
      </div>
    </Modal.Body>
  </Modal>;
};
