import React from 'react';
import { NewsState } from '../../models/news-model';
import moment from 'moment';

export const ShowData = () => {
  const state = NewsState.useStoreState((state) => state.data);
  const timeValue = moment(state.timestamp * 1000).format('DD/MM/YYYY');
  const handleUrlClick = (e) => {
    e.preventDefault();
    window.open(state.url, '_blank');
  };
  return (
    <div className="feed-message-show-data">
      <div className="d-flex flex-column feed-label">
        <div className="font-size-17">{(state.title || '').toUpperCase()}</div>
        <div className="font-size-10">
          {' '}
          <a
            href={state.url}
            className="url-link"
            style={{ color: 'inherit', textDecoration: 'none' }}
            onMouseEnter={(e) => (e.target.style.color = 'blue')}
            onMouseLeave={(e) => (e.target.style.color = 'inherit')}
            onClick={handleUrlClick}
          >
            {state.url || ''}
          </a>
        </div>
        <div className="font-size-13 feed-time-stamp">{timeValue}</div>
      </div>
      <div
        className="feed-message font-size-15 font-weight-light mb-3"
        dangerouslySetInnerHTML={{ __html: state.content }}
      ></div>
    </div>
  );
};
