import React, {useState} from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy';

import './style.scss'


function BugReportBtn(props) {
  
  const {active} = props;

  const iconLook = active ? 'iconDay' : 'iconNight'; 
  
  const showBugReportModal = useStoreActions(actions => actions.TOGGLE_BUG_REPORT_MODAL)

  const handleClick = () => {
    showBugReportModal()
  }
  
  return (
    <span className="bugReportBtn" onClick={ handleClick } >
        <i className={`fa-lg fa-thin fa-bug ${iconLook}`}></i>
    </span>
  )
}

export default BugReportBtn