import React from 'react';
import { DashboardLendingState } from '../../../models/dashboard-lending-model';
import '../styles.scss';
import { lendingConstants } from '../constants';
import { isEmpty } from 'lodash';
import { UserTable } from './userTable';
import { MarketTable } from './marketTable';

const { BORROWED_TABLE_HEADER, BORROWED_USER, BORROW_MARKET, BORROW_MARKET_TABLE_HEADERS } = lendingConstants;

export const BorrowContent = () => {
    const state = DashboardLendingState.useStoreState(state => state);

    return <div className="supply-layout">
        <div className="supply-layout-content">
            <div className="supply-user-table">
                {!isEmpty(state.tableData.borrowUser) && <><div className="supply-user-table-title">{BORROWED_USER}</div>
                    <div className="borrow-user-table-headers">
                        {BORROWED_TABLE_HEADER.map((header, index) => <div key={index} className="header-item">{header}</div>)}
                    </div>
                    <div className="borrow-user-table-content">
                        <UserTable type="borrow" />
                    </div></>}
                <div className="supply-market-table-title">{BORROW_MARKET}</div>
                <div className="borrow-market-table-headers">
                    {BORROW_MARKET_TABLE_HEADERS.map((header, index) => <div key={index} className="header-item">{header}</div>)}
                </div>
                <div className="borrow-market-table-content">
                    <MarketTable type="borrow" />
                </div>
            </div>
        </div>
    </div>;
};