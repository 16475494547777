import { useStoreActions } from 'easy-peasy';
import React from 'react'
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal'
import { Link  } from 'react-router-dom';
import { constants } from './constants';

import './styles.scss';

const { REPORT_ERROR: { TITLE, MESSAGE, CONTACT_MESSAGE, MAIL, REPEAT_BTN } } = constants;

const ErrorMessage = ({active}) => {

  const closeReportError = useStoreActions(actions => actions.TOGGLE_REPORT_ERROR)
  const showBugReportModal = useStoreActions(actions => actions.TOGGLE_BUG_REPORT_MODAL)

  const handleTryAgain = () => {
    closeReportError();
    showBugReportModal();
  }

  const handleSendMail = (e) => {
    e.preventDefault();

    window.location = 'mailto:help@monoceros.com'
  }

  return (
    <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={active ? 'DayErrorMessage' : 'NightErrorMessage'}
        centered
      >
        <Modal.Header>
          <Modal.Title className="fs-6">{TITLE}</Modal.Title>
          {
            !active ? 
               <CloseButton variant='white' className="fs-6" onClick={ closeReportError }  />
             : <CloseButton  className="fs-6" onClick={ closeReportError }  />
          }
        </Modal.Header>

        <Modal.Body>
           <div className="tick">
              <i className="fa-2x fa-sharp fa-solid fa-xmark"></i>
           </div>

           <p>{MESSAGE}</p>
           <p>
              { CONTACT_MESSAGE } <Link onClick={handleSendMail} to="" className="mail">{MAIL}</Link>
           </p>

            <div className="d-grid gap-2">
                <Button onClick={handleTryAgain} className="closeBtn"  size="lg">
                    {REPEAT_BTN}
                </Button>
            </div>
        </Modal.Body>
      </Modal>
  )
}

export default ErrorMessage

