import React from 'react';
import { utils } from '../../../utils/utils';
import { dataHelpers } from '../dataHelpers/dataHelper';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import numeral from 'numeral';
import { EnableContent } from './enableContent';

const { SCAN_TOKEN_LINK } = constants;

export const LockedPool = props => {
    const { token, end, stakedAmount, stakedPrice, state, index, clickHandle, stakedToken, address, rewardPrice, rewardToken, rewardAmount, onHarvest } = props;
    const { date, endsIn, timestamp } = dataHelpers.createDate(end);

    const onClick = id => clickHandle({ id });

    const symbol = token.symbol.replace('(L)', '');
    const isUnlocked = Date.now() > timestamp;

    const chevronPosition = id => state.tableData.toggled.includes(id) ? 
        { label: 'Hide Details', html: <i className="fa fa-chevron-up ms-2"></i> } : { label: 'Show Details', html: <i className="fa fa-chevron-down ms-2"></i> };

    return <><div className="collapse-header">
        <div className='custom-table-body-cell d-inline-flex align-items-center d-grid'>
            <div className="me-3"><img onError={utils.imgErrorHandle} src={`../coins-full-library/${symbol.toLowerCase()}.svg`} className="asset-icon"/></div>
            <div className="d-column-flex align-items-center">
                <div className="text-orange warning-color">LOCKED</div>
                <div className="text-orange">STAKE {symbol}</div>
            </div>
        </div>
        <div></div>
        <div className='custom-table-body-cell d-column-flex align-items-center'>
            <div className="text-orange">Unlock Date</div>
            <div className="font-weight-medium font-inter link-color">On {date}</div>
            <div className="text-orange font-size-12">{isUnlocked ? 'Unlocked' : `Unlocks in ${endsIn} days`}</div>
        </div>
        <div className='custom-table-body-cell d-column-flex align-items-center'>
            <div className="text-orange">{symbol} Locked </div>
            <div className="font-weight-medium font-inter link-color">{stakedAmount}</div>
            <div className="text-orange font-size-12">~ ${stakedPrice}</div>
        </div>
        <div></div>
        <div className='custom-table-body-cell d-column-flex align-items-center details'>
            <div className="d-inline-flex align-items-center details" onClick={() => onClick(index)}><div className="details-button">
                {chevronPosition(index).label} {chevronPosition(index).html}</div></div>
        </div>
    </div>{
        isUnlocked && <div className="collapse-row">
            <Collapse in={state.tableData.toggled.includes(index)}>
                <div className="collapse-content font-size-14 font-weight-regular">
                    <div className="collapse-content-wrapper">
                        <div className="content-column">
                            <div className="stats">
                                <div className="mb-2">Total locked:</div>
                                <div>{numeral(stakedAmount).format('0,00.000000')} {symbol}</div>
                                <div className="fee-text mb-2">Average lock duration:</div>
                                <div>40 weeks</div>
                                <div className="link mb-2"><a rel='noreferrer' target='_blank' href={`${SCAN_TOKEN_LINK}${rewardToken.address}`}>See Token Info</a></div>
                                <div></div>
                                <div className="link mb-2"><a rel='noreferrer' target='_blank' href={address ? `${SCAN_TOKEN_LINK}${address}` : SCAN_TOKEN_LINK}>View Contract</a></div>
                                <div></div>
                                <div className="link mb-2">Add to METAMASK <img width={26} height={26} src="../dashboard-library/home-page-images/metamask-fox.svg" /></div>
                                <div></div>
                                <div className="d-inline-flex align-items-center">
                                    <div className='auto-refresh font-size-12 me-4'><i className="fa-light fa-refresh"></i> Auto</div>
                                </div>
                            </div>
                        </div>
                        {stakedAmount > 0 ? <>
                            <div className="content-column d-flex-column">
                                <div className="position-card p-3 harvest-block align-items-start">
                                    <div className="profit">
                                        <div className="mb-3"><span className="warning-color">{stakedToken.symbol}</span> Staked</div>
                                    </div>
                                    <div></div>
                                    <div className="profit">
                                        <div className="font-weight-medium">{numeral(stakedAmount).format('0,00.000000')} {stakedToken.symbol}</div>
                                        <div className="font-weight-medium">~ ${numeral(stakedPrice).format('0,00.000000')}</div>
                                    </div>
                                    <div className="boost d-flex align-items-start">
                                        <div className="mb-3"></div>
                                        <div className="font-weight-medium"></div>
                                    </div>
                                    <div className='d-grid me-2 nowrap'><ButtonCustom variant="primary" label='Convert to Flexible' /></div>
                                    <div className='d-grid nowrap'><ButtonCustom variant="primary" label='Renew' /></div>
                                </div>
                            </div></> : <EnableContent 
                            poolAddress={address} 
                            stakedTokenAddress={stakedToken.address} 
                            rewardAmount={rewardAmount} 
                            rewardPrice={rewardPrice} 
                            rewardToken={rewardToken} />}
                    </div>
                </div>
            </Collapse>
        </div>
    }</>;
};