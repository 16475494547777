import React, { useEffect, forwardRef, useState } from 'react';
import './styles.scss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { messagePopoverTemplate } from './messagePopoverTemplate';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { messagesEffects } from './messagesEffects';
import { orderBy } from 'lodash';

const MESSAGE_FETCH_POLLING_PERIOD = 3600000; //(1 hour)

const renderPopover = (data, actions, visibility) => {
    return <Popover
        id="popover-messages"
        className="custom-messages-popover">
        <Popover.Body>
            {messagePopoverTemplate.renderMessages(data, actions, visibility)}
        </Popover.Body>
    </Popover>;
};

const visibilityCondition = (m, flag) => {
    return flag ? true : Boolean(m.status === 'NEW' || m.status === 'READ' || m.status === 'URGENT');
};

export const Messages = forwardRef((props, ref) => {
    const messages = useStoreState(state => state.messages);
    const actions = useStoreActions(actions => actions);
    const messageData = messages.messageData;
    const visibility = messages.visibility;
    const isNewMessage = messages.messageData.some(m => m.status === 'NEW' || m.status === 'URGENT');
    const data = messageData.filter(m => visibilityCondition(m, visibility));
    const orderedData = orderBy(data, 'time', 'desc');
    const newMessageClass = isNewMessage ? 'new' : '';
    const [isOpen, setState] = useState(false);
    const isActive = isOpen ? 'active' : '';

    useEffect(() => {
        const INTERVAL_ID = setInterval(() => {
            actions[messagesEffects.actionTypes.MESSAGES_ACTION_REFRESHED]();
        }, MESSAGE_FETCH_POLLING_PERIOD);

        return () => clearInterval(INTERVAL_ID);
    }, []);

    return <OverlayTrigger
        trigger={['click']}
        placement="bottom-end"
        rootClose={true}
        children={ref.current}
        onToggle={isOpen => setState(isOpen)}
        overlay={renderPopover(orderedData, actions, visibility)}>
        <div className="position-relative">
            <a className={`messages-fa-icon messages-component ${newMessageClass} ${isActive}`}>
                <span className="position-absolute translate-center rounded-circle"> </span>
            </a>
        </div>
    </OverlayTrigger>;
});