import React,  { useState } from 'react';
import { constants } from '../constants';
import { useStoreState } from 'easy-peasy';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { TableWalletTemplate } from './tables/tableWalletTemplate';
import { uiActions } from '../actions/uiActions';
import { Collapse } from 'react-bootstrap';
import numeral from 'numeral';
import { BlackListModal } from './blackListModal';
import { AddTokenModal } from './addTokenModal';
import { isEmpty } from 'lodash';
import { defiMultichainEffects } from '../actions/defiMultichainEffects';
import { dataHelper } from '../dataHelpers/dataHelper';
import { ButtonGroup, InputGroup, FormControl } from 'react-bootstrap';

const { WALLET: { WALLET, BLACK_LIST, ADD_TOKEN, ADD_TO_BLACK_LIST } } = constants;


export const WalletContent = props => {
    const { actions, state } = props;
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const filteredList = dataHelper.filterDataList(state.wallet.filteredData);
    const totalBalance = dataHelper.getWalletSum(filteredList);
    const [search, setSearch ] = useState('');
    
    
    const clickAddHandle = () => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_ADD]({ state });
    
    return <div className="wallet-content">
        <div className="wallet-section">
            <div className="wallet-section-header align-items-center">
                <div className="wallet-section-header-left d-flex align-items-center">
                    <i className="fa-light fa-wallet wallet-icon me-2"></i>
                    <div className="font-weight-medium me-4">{WALLET}</div>
                    <div className="me-3"><ButtonCustom 
                        label={ADD_TOKEN} 
                        onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ userWallet, name: 'open-modal', modal: 'add-token' })} 
                        variant='primary' 
                        size='sm' /></div>
                    <div className="me-3"><ButtonCustom label={BLACK_LIST} variant='danger' size='sm'
                        onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ userWallet, name: 'open-modal', modal: 'modalBlackList' })} /></div>
                    {
                        state.wallet.open ? 
                        <div className="search-input me-40">
                        <InputGroup>
                            <InputGroup.Text className="clear">
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </InputGroup.Text>
                            <FormControl
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder={'Wallet Address...'}/>
                            <InputGroup.Text className='custom-btn-search'>Address</InputGroup.Text>
                        </InputGroup>
                    </div> : null
                    }
                    <div>
                        {isEmpty(state.wallet.blackList.transportData) ? null : <ButtonCustom onClick={clickAddHandle} size="sm" variant="primary" label={ADD_TO_BLACK_LIST}/>}
                    </div>
                </div>
                <div></div>
                <div className="wallet-balance text-end me-3">
                    <div className="balance-value">${Number(totalBalance).toFixed(2)}</div>
                    {/* <div className="balance-claim">Available: ${numeral(state.wallet.available).format('0,00.00')}</div> */}
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <i onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'wallet' })}
                        className={`table-toggler fa-solid fa-chevron-${ state.wallet.open ? 'up' : 'down' }`}></i>
                </div>
            </div>
            <Collapse in={state.wallet.open}>
                <div className="collapse-content">
                    <div className="wallet-section-content">
                        <TableWalletTemplate searchData = { search } />
                    </div>
                </div>
            </Collapse>
        </div>
        <AddTokenModal state={state} actions={actions} show={state.addToken.show}/>
        <BlackListModal userWallet={userWallet}/>
    </div>;
};