import React from 'react';
import { Button } from 'react-bootstrap';
import { constants } from '../../../../constants';
import { customBotsActions } from '../../../../actions/uiActions';
import { TabWidget } from '../../../tabs/tabWidget';
import { PercentForm } from './percentForm';
import { ConditionsForm } from './conditionsForm';

const {
  TABS: {
    STOP_LOSS,
    ADVANCED_SETTINGS,
    FORM_TABS,
    WIZARD
  },
  BUTTONS,
  TITLES,
  TEXT
} = constants;

export const TakeProfitForm = (props) => {
  const { state, actions } = props

  const nextStepHandler = () => actions[customBotsActions.actionTypes.CUSTOM_BOTS_TAB_TOGGLE]({tab: ADVANCED_SETTINGS});
  const previousStepHandler = () => actions[customBotsActions.actionTypes.CUSTOM_BOTS_TAB_TOGGLE]({tab: STOP_LOSS});

  return <div className="main-settings-form-content">
    <div className="form-content panel-wrapper">
      <div className="font-weight-medium mb-3">{TITLES.TAKE_PROFIT}</div>
      <div className="font-inter">
        <div className="form-item pb-3">
          <div className="tabs d-flex">
            <TabWidget
              state={state.tabs.takeProfitTab}
              actions={actions}
              data={[FORM_TABS.PERCENT, FORM_TABS.CONDITIONS]}
              width={'w-100'}/>
          </div>
        </div>
        <div className="form-item pb-3 font-size-14">{TEXT.RIGHT_CONDITIONS_CLOSING_BOT}</div>
        { state.tabs.takeProfitTab === FORM_TABS.PERCENT ?
          <PercentForm state={state} actions={actions}/> :
          <ConditionsForm state={state} actions={actions}/>
        }
        { state.tabs.sideTab === WIZARD ?
          <div className="mt-4 d-flex justify-content-between">
            <Button
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium btn-default btn-steps-sm"
              onClick={previousStepHandler}>
              {BUTTONS.PREVIOUS_STEP}
            </Button>
            <Button
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium btn-default btn-steps-sm"
              onClick={nextStepHandler}>
              {BUTTONS.NEXT_STEP}
            </Button>
          </div> : null
        }
      </div>
    </div>
  </div>
};