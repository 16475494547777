import React from 'react';
import { VestingState } from '../../../models/vesting-model';
import { vestingConstants } from '../constants';
import { vestingEffects } from '../actions/vestingEffects';
import { dataHelper } from '../dataHelpers/dataHelper';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { ButtonGroup, InputGroup, FormControl } from 'react-bootstrap';
import Slider from 'rc-slider';
import { PopoverLayout } from '../../../components/utility/popover/view';
import { buyEffects } from '../actions/buyEffects';

const { USDT_ADDRESSES, BUY: { CONTENT }, 
    VEST: { HEADER: { TIP, TIP_TITLE, TITLE }, CONTENT: { PERIOD, START, END, TOTAL_AMOUNT, WITHDRAW, AVAILABLE, DAYS, LEFT, ENTER_VALUE, BUY, CANCEL } } } = vestingConstants;

export const SafeBuyContent = ({ userWallet, chain }) => {
    const state = VestingState.useStoreState(state => state.vesting);
    const actions = VestingState.useStoreActions(actions => actions);
    const usdtAddress = USDT_ADDRESSES.find(network => network.chain === chain).usdtAddress;
    const approvedState = VestingState.useStoreState(state => state.buy.approved);
    const contractAddress = USDT_ADDRESSES.find(network => network.chain === chain).contractAddress;
    const usdtEnabled = !approvedState.find(network => network.chain === chain)?.isApprove;
    const lockTime = state.invested.isInvested ? Number(state.invested.chainObject.lockTime) / 60 / 60 / 24 : Number(state.tab);
    const startDate = state.invested.isInvested ? dataHelper.getDateFromTimestamp(state.invested.chainObject.startLock) : state.startDate;
    const endDate = state.invested.isInvested ? dataHelper.getDateFromTimestamp(Number(state.invested.chainObject.startLock) + Number(state.invested.chainObject.lockTime)) : state.endDate;
    const { left, daysGone } = state.invested.isInvested ? dataHelper.getLeftDays(Number(state.invested.chainObject.startLock) + Number(state.invested.chainObject.lockTime), lockTime) : 0;

    const handleOpen = name => actions[vestingEffects.actionTypes.VEST_MODAL_INVOKED]({ name });
    const periodTabHandle = period => actions[vestingEffects.actionTypes.VEST_PERIOD_TAB_TOGGLED]({ period });
    const onChangeHandle = e => {
        actions[vestingEffects.actionTypes.VEST_FORM_VALIDATION]({ value: e.target.value });
        actions[vestingEffects.actionTypes.VEST_USDT_AMOUNT_ENTERED]({ usdtAmount: e.target.value, days: state.tab });
    };
    const onStartVesting = () => actions[vestingEffects.actionTypes.VEST_VESTING_STARTED]({ userWallet, chain, contractAddress, mcsAmount: state.form.mcsAmount, days: +state.tab });
    const onWithdrawMCS = () => actions[vestingEffects.actionTypes.VEST_VESTING_WITHDRAW]({ userWallet, chain, contractAddress });
    const onApprove = () => actions[vestingEffects.actionTypes.VEST_ENABLE_INVOKED]({ chain, userWallet, usdtAddress, contractAddress });

    const price = state.tab === '90' ? '90 days - 0.13$' : '180 days - 0.14$';

    return <div className="vesting-layout content">
        <div className="vesting-header">
            <div className="vesting-header-title font-unbounded font-size-20">{TITLE}</div>
            <div className="vesting-header-tip"><PopoverLayout title={false} message={TIP} component={<>{TIP_TITLE}<i className="fa-light fa-circle-question ps-2"></i></>}/></div>
        </div>
        <div className="vesting-period">
            <div className="period-item">
                <div className="heading">{PERIOD}</div>
                <ButtonGroup className="w-100 button-100">
                    <ButtonCustom active={state.tab === '90'} onClick={() => periodTabHandle('90')} label="90 days" variant="default" size="sm" name="button_90"/>
                    <ButtonCustom active={state.tab === '180'} onClick={() => periodTabHandle('180')} label="180 days" variant="default" size="sm" name="button_180"/>
                </ButtonGroup>
            </div>
            <div className="period-item">
                <div className="heading">{START}</div>
                <div className="value">{startDate}</div>
            </div>
            <div className="period-item">
                <div className="heading">{END}</div>
                <div className="value">{endDate}</div>
            </div>
            <div className="period-item">
                <div className="heading">Price</div>
                <div className="value">{price}</div>
            </div>
            {state.invested.isInvested ? <div className="period-item">
                <div className="slider-left-days">
                    <span className="font-size-12 pe-2">{LEFT}</span>
                    <span className="font-size-18 days">{left} {DAYS}</span>
                </div>
                <div className="slider">
                    <Slider className="slider -primary" defaultValue={0} min={0} max={Number(lockTime)} value={daysGone}/>
                </div>
            </div> : <div></div>}
        </div>
        <div className="vesting-controls">
            <div className="control-block">
                <div className="heading">{TOTAL_AMOUNT}</div>
                <div className="control-inner-grid">
                    <div className="value usdt-value">{state.balance} USDT</div>
                    <div>
                    </div>
                </div>
                <div className="control-amount align-items-center">
                    {!state.invested.isInvested ? <><div className="font-size-12 pe-2 enter-usdt-value">{ENTER_VALUE}</div>
                        <div><InputGroup className="pe-2 input-amount">
                            <FormControl
                                onChange={onChangeHandle}
                                placeholder={'Amount...'} />
                            <InputGroup.Text>
                                <i className="fa-thin fa-dollar-sign"></i>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                        <div className="total-buy-button ms-2">
                            <ButtonCustom variant="primary" size="sm" label="MAX" />
                        </div>
                        <div className="ps-2 text-end">
                            <ButtonCustom onClick={usdtEnabled ? onStartVesting : onApprove} variant="primary" 
                                disabled={usdtEnabled ? !state.form.validity : false} size="sm" label={usdtEnabled ? BUY : CONTENT.ENABLE} />
                        </div>
                    </> : <div>You have already invested your USDT</div>}
                </div>
            </div>
            <div className="control-block">
                <div className="heading">{WITHDRAW}</div>
                <div className="value d-flex align-items-center">{state.invested.isInvested ? state.invested.chainObject?.amountToClaim : 0} 
                    <img className="mcs-icon me-2 ms-2" src="../coins-full-library/mcs.svg" />Monoceros</div>
                {state.invested.isInvested && <div><ButtonCustom onClick={onWithdrawMCS} variant="primary" size="sm" label="Claim" /></div>}
            </div>
            <div className="control-block">
                <div className="heading">{AVAILABLE}</div>
                <div className="value withdraw-grid align-items-center">
                    <div>50/1</div>
                    <div className="invest-cancel-buttons d-flex justify-content-end">
                        {state.invested.isInvested && <div><ButtonCustom onClick={() => handleOpen('open-modal')} variant="default" size="sm" label={CANCEL} /></div>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};