import React from 'react';
import './styles.scss';
import { utils } from '../../../utils/utils';

const URL_SUFFIX = '../coins-full-library';

export const CryptoIcon = props => {
  const {id='', width=16, height=16} = props;
  return <img
      src={`${URL_SUFFIX}/${id.toLowerCase()}.svg`}
      width={width}
      height={height}
      onError={utils.imgErrorHandle}
      className="crypto-icon"/>
};