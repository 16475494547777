import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../service/serviceUtils';
// import { notifyEffects } from '../utility/notifications/notifyEffects';

const URL = '/settings';

const actionTypes = {
    HEADER_SETTINGS_CHANGED: 'HEADER_SETTINGS#CHANGED',
    HEADER_SETTINGS_UPDATED: 'HEADER_SETTINGS#UPDATED',
    HEADER_SETTINGS_SAVE_SUCCEED: 'HEADER_SETTINGS_SAVE#SUCCEED',
    HEADER_SETTINGS_SAVE_FAILED: 'HEADER_SETTINGS_SAVE#FAILED',
    HEADER_SETTINGS_LANGUAGE_CHANGED: 'HEADER_SETTINGS_LANGUAGE#CHANGED',
    HEADER_SETTINGS_LANGUAGE_PANEL_OPENED: 'HEADER_SETTINGS_LANGUAGE_PANEL_OPENED'
};

const themeHandle = (value, actions) => {
    const theme = value === 'day-theme' ? 'night-theme' : 'day-theme';
    actions.updateState({ value: theme, path: 'root.theme' });
    actions[actionTypes.HEADER_SETTINGS_UPDATED]({ settings: {theme} });
};

const languageHandle = (value, actions) => {
    actions.updateState({ value: value, path: 'root.language' });
    actions[actionTypes.HEADER_SETTINGS_UPDATED]({ settings: {language: value} });
};

const settingsHandle = (actions, payload) => {
    const {name, value} = payload;
    switch (name) {
    case 'theme': themeHandle(value, actions); break;
    case 'language': languageHandle(value, actions); break;
    default: return;
    }
};

const languageActionHandle = (actions, payload) => {
    const {value: {id}} = payload;
    actions[actionTypes.HEADER_SETTINGS_CHANGED]({value: id, name: 'language'});
};

const languagePanelState = (state, payload) => {
  const {isOpen} = payload;
  state.root.languagePanelState = isOpen;
};

const fetchAction = (action, payload) => {
    serviceUtils.HttpService({
        url: URL,
        method: 'PUT',
        data: payload,
        errorActionType: action[actionTypes.HEADER_SETTINGS_SAVE_FAILED],
        successActionType: action[actionTypes.HEADER_SETTINGS_SAVE_SUCCEED]
    });
};

const errorAction = () => {
    // const notification = { type: 'danger', text: 'Your theme choice not saved' };
    // notifyEffects.notifyAction(notification);
};

const successAction = () => {
    // const notification = { type: 'success', text: 'Your theme choice successfully saved in settings' };
    // notifyEffects.notifyAction(notification);
};

const actionHandlers = {
    [actionTypes.HEADER_SETTINGS_CHANGED]: thunk((actions, payload) => settingsHandle(actions, payload)),
    [actionTypes.HEADER_SETTINGS_UPDATED]: thunk(async (action, payload) => fetchAction(action, payload)),
    [actionTypes.HEADER_SETTINGS_SAVE_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.HEADER_SETTINGS_SAVE_SUCCEED]: action((state, payload) => successAction(state, payload)),
    [actionTypes.HEADER_SETTINGS_LANGUAGE_CHANGED]: thunk((action, payload) => languageActionHandle(action, payload)),
    [actionTypes.HEADER_SETTINGS_LANGUAGE_PANEL_OPENED]: action((state, payload) => languagePanelState(state, payload))
};

export const headerEffects = {
    actionTypes,
    actionHandlers
};