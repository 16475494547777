import { action, thunk } from 'easy-peasy';
import { packsFactory } from './packsFactory';
import { packHelper } from './dataHelpers/packHelper';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';
import {dataHelper} from "../../smartTradePage/actions/dataHelpers/dataHelper";

const actionTypes = {
  HODL_BOT_PACK_SELECTED: 'HODL_BOT_PACK#SELECTED',
  HODL_BOT_PACK_SAVE_INVOKED: 'HODL_BOT_PACK_SAVE#INVOKED',
  HODL_BOT_PACK_SAVE_SUCCEEDED: 'HODL_BOT_PACK_SAVE#SUCCEEDED',
  HODL_BOT_PACK_SAVE_FAILED: 'HODL_BOT_PACK_SAVE#FAILED',

  HODL_BOT_STATE_UPDATED: 'HODL_BOT_STATE#UPDATED',
};

const packStateUpdateHandle = (state, payload) => {
  const { id, allPackData } = payload;

  switch (id) {
    case 'load_packs':
      state.packs.allPackData = allPackData;
      state.packs.packsData = [...packHelper.populatePacksList(allPackData, state.packs.exchangeId)];
      break;
    case 'exchange_update':
      state.packs.packsData = [...packHelper.populatePacksList(state.packs.allPackData, state.packs.exchangeId)];
      break;
    case 'toggle':
      state.packs.show = !state.packs.show;
      break;
    case 'add':
      state.packs.addMode = true;
      state.packs.selectedId = null;
      state.packs.addPackMode = true;
      break;
    case 'close':
      state.packs.addPackMode = false;
      state.packs.createPackData = {};
      break;
    case 'save':
      state.packs.addPackMode = false;
      state.packs.createPackData = {};
      break;
    case 'pack_main_coin_value':
      state.packs.createPackData.mainCoin = payload.value.value;
      state.packs.pairCoinList = dataHelper.generatePairCoinList(payload.value.value, state.packs.coinData);
      break;
    case 'pack_pairs_value':
      state.packs.createPackData.pairs = payload.value;
      break;
    case 'pack_name_value':
      state.packs.createPackData.name = payload.value;
      break;
    case 'select_pack':
      state.packs.selectedId = payload.data.id;
      state.packs.selectedPack = payload.data;
      state.packs.editPackMode = false;
      break;
    default: return;
  }
};

const selectPackHandle = (actions, payload) => {
  const { id } = payload;
  switch (id) {
    case 'save':
      actions[actionTypes.HODL_BOT_STATE_UPDATED](payload);
      const requestData = packHelper.packAddRequest(payload);
      actions[actionTypes.HODL_BOT_PACK_SAVE_INVOKED]({ ...requestData });
      break;
    default:
      actions[actionTypes.HODL_BOT_STATE_UPDATED](payload);
      break;
  }
};

const successHandle = (state, payload) => {
  const { response } = payload;
  state.packs.allPackData = response;
  state.packs.packsData = packHelper.populatePacksList(response, state.packs.exchangeId);
  const notification = { type: 'success', text: 'Pack added' };
  notifyEffects.notifyAction(notification);
};

const errorHandle = (state, payload) => {
  console.info(payload);
};

const actionHandlers = {
  [actionTypes.HODL_BOT_PACK_SELECTED]: thunk((actions, payload) => selectPackHandle(actions, payload)),
  [actionTypes.HODL_BOT_PACK_SAVE_INVOKED]: thunk(async (actions, payload) => packsFactory.saveInvokeHandle(actions, payload)),
  [actionTypes.HODL_BOT_PACK_SAVE_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
  [actionTypes.HODL_BOT_PACK_SAVE_FAILED]: action((state, payload) => errorHandle(state, payload)),
  [actionTypes.HODL_BOT_STATE_UPDATED]: action((state, payload) => packStateUpdateHandle(state, payload)),
};

export const packsEffects = {
  actionTypes,
  actionHandlers
};
