import React from 'react';
import { constants } from '../constants';
import { InputGroup, FormControl } from 'react-bootstrap';
import { uiActions } from '../actions/uiActions';
import { PoolsState } from '../../../models/pools-model';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { poolsEffects } from '../actions/poolsEffects';
import { useStoreState } from 'easy-peasy';

const { UNSTAKE_MODAL: { HEADER, UNSTAKE, BALANCE }, CONFIRM_BTN, CANCEL_BTN } = constants;

export const UnstakeContent = () => {
    const actions = PoolsState.useStoreActions(actions => actions);
    const state = PoolsState.useStoreState(state => state);
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const onChangeHandle = e => actions[uiActions.actionTypes.POOLS_MODAL_INPUT_AMOUNT_SET]({ amount: e.target.value, name: 'unstake' });
    const onMaxUnstakeAmount = () => actions[uiActions.actionTypes.POOLS_MODAL_MAX_AMOUNT_SET]({ max: state.modal.data.unstakeAmount, name: 'unstake' });
    const handleClose = () => actions[uiActions.actionTypes.POOLS_MODAL_INVOKED]({ name: 'unstake-modal', modal: 'close-modal' });

    return <><div className="font-weight-medium text-start modal-main-header">{HEADER}</div>
        <div className="modal-unstake-main-content">
            <div className="input-heading">
                <div>{UNSTAKE}</div>
                <div className="balance">{BALANCE}: {state.modal.data.unstakeAmount}</div>
            </div>
            <div className="input-content">
                <InputGroup>
                    <FormControl
                        value={state.unstakeAmount}
                        type='number'
                        onChange={onChangeHandle}
                        placeholder="0"/>
                    <InputGroup.Text className='custom-btn-search' onClick={onMaxUnstakeAmount}>MAX</InputGroup.Text>
                </InputGroup>
            </div>
            <div className="confirmation-content">
                {<ButtonCustom disabled={state.unstakeAmount > state.modal.data.unstakeAmount || state.unstakeAmount <= 0}
                    variant='success' label={CONFIRM_BTN} 
                    onClick={() => actions[poolsEffects.actionTypes.POOLS_WITHDROW_INVOKED]({ to: state.modal.data.to, from: userWallet, amount: state.unstakeAmount })} />}
                <ButtonCustom variant='danger' onClick={handleClose} label={CANCEL_BTN} />
            </div>
        </div></>;
};