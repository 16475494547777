import { useStoreState, useStoreActions} from 'easy-peasy';
import React, { useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Thumbs from './thumbs'
import { Formik } from 'formik';
import * as yup from 'yup';
import { constants } from './constants';
import './styles.scss';


const { BUG_REPORT_MODAL: { NAME, EMAIL, DESCRIPTION, FILE, SUBMIT } } = constants;


const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email('Invalid email address').required('email is required field'),
  description: yup.string().required(),
});

const bugReportForm = ({active}) => {

    const state = useStoreState(state => state)
    const actions = useStoreActions(actions => actions)

    const inputFile = useRef(null);

    return (
        <Formik
                validationSchema={schema}
                initialValues={{
                    name: '',
                    email: '',
                    description: '',
                }}
                onSubmit={(values, {resetForm}) => {
                    const data = {name: values.name, email: values.email, description: values.description, attachments: state.report.files }

                    actions.SUBMIT_FORM(data)
                    resetForm({})
                    actions.TOGGLE_BUG_REPORT_MODAL()
                }}
            >{({
                    handleSubmit,
                    handleChange,
                    values,
                    isValid,
                    dirty,
                    errors,
                }) =>
                    <Form
                        className="form"
                        noValidate
                        onSubmit={handleSubmit}>
                        
                        <Form.Group className="pb-4">
                            <Form.Label>{NAME.LABEL}</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder={NAME.PLACEHOLDER}
                                value={values.name}
                                isInvalid={!!errors.name}
                                onChange={handleChange}
                                required>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="pb-3">
                            <Form.Label>{EMAIL.LABEL}</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder={EMAIL.PLACEHOLDER}
                                value={values.email}
                                isInvalid={!!errors.email}
                                onChange={handleChange}
                                required>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group className="pb-3" >
                            <Form.Label>{DESCRIPTION.LABEL}</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3}
                                name="description"
                                placeholder={DESCRIPTION.PLACEHOLDER}
                                value={values.description}
                                isInvalid={!!errors.description}
                                onChange={handleChange}
                                required
                            />
                            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="pb-3" >
                            <Thumbs active = {active} />
                        </Form.Group>
                        
                        <Form.Group className="pb-3">
                            <Form.Control 
                            onChange={(e) => actions.HANDLE_FILE_CHANGE(Array.prototype.slice.call(e.target.files))}
                                ref={inputFile} type="file" id="file" name="file" size="lg" style={{ display: 'none', marginBottom: '0px' }} multiple
                            />

                            <div 
                                className="customInputFile" 
                                onClick={() => inputFile.current.click()} 
                            >
                                
                                {FILE.TITLE}
                            </div>

                        </Form.Group>

                        <Form.Group>
                            <div className="d-grid gap-2">
                                <Button type="submit"  className={!(isValid && dirty) ? 'submitBtnDisabled' : 'submitBtnEnabled'} size="lg" disabled={!(isValid && dirty)}>
                                    {SUBMIT.TITLE}
                                </Button>
                            </div>
                        </Form.Group>
                    </Form>
                }
        </Formik>
    )
}

export default bugReportForm