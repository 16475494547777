export const metaMaskConfiguration = {
    config: {
      region: 'eu-west-1',
      userPoolWebClientId: '4gf153nhdk72l1vcm3taq2o5ff',
      userPoolId: 'eu-west-1_7jaLvgYNn',
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    API_BASE_URL: {
      api_base_url: 'https://api-gate.cdfi.ai/',
    },
  };
  