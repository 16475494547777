import React from 'react';
import './styles.scss';
import { InfoEmptyState } from '../info-widget/templates/emptyState';
import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { utils } from '../../../../utils/utils';
import { Loader } from '../../../../components/utility/loader/view';

const renderSignalCard = (coinLabel, coinId, side, timeValue, probability, change) => <div className="signal-card p-1">
    <div className="-column-1">
        <div className="-coin-grid">
            <img src={`../coins-library/${coinId}.svg`}
                className="img-fluid rounded-start"
                onError={utils.imgErrorHandle}/>
            <div className="signal-coin-title font-size-13 font-weight-medium">{coinLabel}</div>
        </div>
        <div className="font-size-10 signal-coin-label">{coinLabel}</div>
    </div>
    <div className="-column-2">
        <img
            src={`../signals-library/signal_${side.toLowerCase()}.svg`}
            className="img-fluid rounded-start"/>
        <div className="font-size-8 signal-time">{timeValue}</div>
    </div>
    <div className="-column-3">
        <div className="font-size-14 font-weight-medium">{`${probability}%`}</div>
        <div className="font-size-10 signal-profit positive">{change}</div>
    </div>

</div>;

const renderCard = el => {
    const { side, timestamp, pair, probability } = el;
    const timeValue = moment(timestamp * 1000).format('DD/MM/YYYY');
    const coin = utils.getSymbol(pair);
    const coinId = (coin || '').toLowerCase();
    const coinLabel = (coin || '').toUpperCase();
    const change = '';
    return renderSignalCard(coinLabel, coinId, side, timeValue, probability, change);
};

const renderSignal = data => {
    return data.map((el, key) => {
        const classValue = data.length - 1 === key ? '' : 'mb-2';
        return <div key={key} className={`${classValue}`}>{renderCard(el)}</div>;
    });
};

export const SignalWidget = () => {
    const state = useStoreState(state => state.signal);
    const loaders = useStoreState(state => state.loaders);
    const data = state.data;
    const isLoading = loaders.signals;
    const term = state.searchValue;
    const filteredData = !term || term.length === 1 ? data : data.filter(s => s.pair.toLowerCase().includes(term.toLowerCase()));
    return <>
        { isEmpty(data) && !isLoading ? <InfoEmptyState type="SIGNAL"/> : null }
        { isLoading ? <Loader size={'4x'} isLoading /> : renderSignal(filteredData) }
    </>;
};