
const uiAction = (actionName, append, _) => {
    const action = _[actionName];
    action ? action({ ...append }) : '';
};



export const addButtonEffects = {
    uiAction
};