import { serviceUtils } from '../../../service/serviceUtils';
import { packsEffects } from './packsEffects';

const saveInvokeHandle = (actions, payload) => {
  const {url, method, data} = payload;

  serviceUtils.HttpService({
    url,
    method,
    data,
    errorActionType: actions[packsEffects.actionTypes.HODL_BOT_PACK_SAVE_FAILED],
    successActionType: actions[packsEffects.actionTypes.HODL_BOT_PACK_SAVE_SUCCEEDED],
    ...payload
  });
};

export const packsFactory = {
  saveInvokeHandle
};