import { debug } from 'easy-peasy';
import moment from 'moment';
import { orderBy } from 'lodash';

const dataModify = (data, viewType) => {
    const {active, history} = viewType;
    const tableData = data.reduce((acc, b) => {
        const {pairLabel, status, balance, balance30d, startDate, botId, exchange, change, id} = b;
        const splittedPair = pairLabel.split('/');
        const mainCoin = splittedPair[1];
        const pair = splittedPair[0];
        const row = {mainCoin, pair, start: moment(startDate).format('DD/MM/YY HH:mm'), timestamp: startDate, volume: balance30d, status, pnl: {change, balance}, botId, exchange, id, fullModel: b};
        const isActive = Boolean(status === 'CREATED' || status === 'STARTING' || status === 'RUNNING' || status === 'ERROR')
        return history ? [...acc, row] : isActive ? [...acc, row] : acc ;
    }, []);
    return orderBy(tableData, 'timestamp', 'desc');
};

export const tableHelper = {
    dataModify
};