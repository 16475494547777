export const constants = {
  ARTICLES: {
    buttons: {
      btnNewArticle: 'New Article',
      btnFilters: 'Filters',
      btnClearAll: 'Clear All',
      btnLoadMore: 'Load More',
    },
    articles: [
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_1.png',
        signal: 'blue',
        type: 'Type2',
        category: 'category1',
        tags: ['#tag1', '#tag2', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_2.png',
        signal: 'orange',
        type: 'Type3',
        category: 'category1',
        tags: ['#tag1'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_3.png',
        signal: 'orange',
        type: 'Type3',
        category: 'category1',
        tags: ['#tag1', '#tag2', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_4.png',
        signal: 'orange',
        type: 'Type3',
        category: 'category2',
        tags: ['#tag1', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_5.png',
        signal: 'orange',
        type: 'Type3',
        category: 'category2',
        tags: ['#tag1', '#tag2', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_6.png',
        signal: 'blue',
        type: 'Type2',
        category: 'category3',
        tags: ['#tag1', '#tag2', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '15 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_7.png',
        signal: 'green',
        type: 'Type1',
        category: 'category3',
        tags: ['#tag2'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '15 min',
        isPublished: true,
      },
      {
        title: 'What Are Token Standards?',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_8.png',
        signal: 'green',
        type: 'Type1',
        category: 'category1',
        tags: ['#tag1', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: true,
      },
      {
        title: 'Article title',
        excerpt:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        img: 'img_9.png',
        signal: 'blue',
        type: 'Type2',
        category: 'category2',
        tags: ['#tag1', '#tag2', '#tag3'],
        content:
          'Token standards are sets of rules and conventions for governing how a crypto token works. Popular standards include ERC-20, BEP-20, ERC-721, and ERC-1155.',
        date: 'Jan 17, 2023',
        time: '5 min',
        isPublished: false,
      },
    ],
    categories: {
      list: [
        { value: '', label: 'Category', type: 'category' },
        { value: 'category1', label: 'Category 1', type: 'category' },
        { value: 'category2', label: 'Category 2', type: 'category' },
        { value: 'category3', label: 'Category 3', type: 'category' },
      ],
    },
    filterList: {
      titles: {
        filters: 'Filters',
        tag: 'Tag',
        type: 'Type',
        timeToRead: 'Time to read',
      },
      buttons: {
        clearAll: 'Clear All',
        apply: 'Apply',
      },
    },
    fullArticle: {
      btnBack: 'Back',
    },
    header: {
      mainTitle: 'Articles',
      updateArticle: 'Update Article',
      newArticle: 'New Article',
      updateFilters: 'Update Filters',
      buttons: {
        btnBack: 'Back',
      },
    },
  },
  ADMIN_ARTICLES: {
    buttons: {
      updateBtn: 'Update',
      deleteBtn: 'Delete',
      unpublish: 'Unpublish',
      publish: 'Publish',
      updateFilters: 'Update filters',
      list: 'List',
      gallery: 'Gallery',
    },
    filter: {
      search: {
        label: 'Search:',
        placeholder: 'Insert title',
      },
      publish: {
        label: 'Publis/Not publish',
      },
    },
    newArticle: {
      title: {
        label: 'Title:',
        placeholder: 'Title',
      },
      excerpt: {
        label: 'Excerpt:',
        placeholder: 'Excerpt',
      },
      category: {
        label: 'Category:',
      },
      type: {
        label: 'Type:',
      },
      tag: {
        label: 'Tags:',
      },
      content: {
        label: 'Content:',
      },
      time: {
        label: 'Time:',
      },
      submit: 'Submit',
    },
    numArticles: {
      title: 'articles',
    },
    updateFilters: {
      alert: {
        text: 'if you delete the filter you will also delete the articles related to the filter',
      },
      btnUpdate: 'Update',
      btnDelete: 'Delete',
    },
  },
};
