import { refreshEffects } from '../../priceFetcher/refreshEffects';
import { action } from 'easy-peasy';

const actionTypes = {
    LOADERS_STATE_UPDATED: 'LOADERS_STATE#UPDATED'
};

const uiAction = (_, options) => {
    const { action } = options;
    switch (action) {
    case 'expand': return null;
    case 'more': return null;
    case 'refresh':
        _[refreshEffects.actionTypes.REFRESH_DATA_INVOKED]({ ...options });
        _[actionTypes.LOADERS_STATE_UPDATED](options.type);
        break;
    }
};

const loaderHandler = (state, loaderType) => {
    state.loaders[loaderType] = true;
};

const actionHandlers = {
    [actionTypes.LOADERS_STATE_UPDATED]: action((state, payload) => loaderHandler(state, payload))
};

export const standardOptionsEffects = {
    actionTypes,
    actionHandlers,
    uiAction
};