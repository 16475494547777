import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import CustomScroll from 'react-custom-scroll';
import { constants } from '../../../../../constants/constants';
import { SortSign } from '../../../../../components/utility/sorting/view';
import { isEmpty, orderBy } from 'lodash';
import { Sign } from '../../../../../components/utility/sign/view';
import { smallValueTooltip } from './smallValueTooltip';

const { TABLE_WIDGET } = constants;

const _sortRows = (rows, sort, currency) => {
    let sortBy;
    switch (sort.column) {
    case 'CHANGE':
        sortBy = `CHANGE-TOTAL-${currency}.value`;
        break;
    case 'COIN':
        sortBy = 'COIN';
        break;
    default:
        sortBy = `${sort.column}-N`;
    }
    return isEmpty(sort) ? rows : orderBy(rows, sortBy, sort.dir);
};

const _setSorting = (column, value) =>
    isEmpty(value) ?
        { column: column, dir: 'desc' } :
        column === value.column ?
            { column: column, dir: value.dir === 'asc' ? 'desc' : 'asc' } :
            { column: column, dir: 'desc' }
;

const getChangesCell = (label, index, row, currency) => {
    const key = `CHANGE-TOTAL-${currency}`;
    return <td key={index} className={`font-size-12 font-weight-regular ${row[key].signClass}`}>{row[key].display}</td>;
};

const getCellClasses = (label, index, row, currency) => {
    const formattedLabel = `${label}-${currency}-F`;
    const hasSmallValue = (row[formattedLabel] || '').includes('...') ? 'added-tooltip cursor-pointer' : '';
    if (index === 0)
        return <td key={index} className="align-middle text-start"><Sign height='10' className="d-flex justify-content-start" color={row.COLOR}/></td>;
    else if (index === 1)
        return <td key={index} className="font-size-12 font-weight-bold">{row[formattedLabel]}</td>;
    else if (label === 'CHANGE')
        return getChangesCell(label, index, row, currency);
    else
        return <td key={index} className={`font-size-12 font-weight-regular ${hasSmallValue}`}>
            <span>
                { hasSmallValue ?
                    smallValueTooltip.formattedCell(label, row, formattedLabel, currency, index) :
                    row[formattedLabel]}
            </span>
        </td>;
};

const renderCells = (row, currency, labels) => {
    return 	labels.map((l, i) => {
        return getCellClasses(l.id, i, row, currency);
    });
};

const renderTableHeader = (labels, currency, state, setState) => {
    return labels.map((l, i) => {
        return !i ? <th key={i}></th> : <th key={i}
            onClick={() => setState( _setSorting(l.id, state) )}
        >
            <div className="d-flex flex-row justify-content-start align-items-center">
                <SortSign
                    sorting={l.id === state.column ? state.dir : ''}
                /> <span className="ms-1 font-size-10 font-weight-regular">{ l.id === 'TOTAL' || l.id === 'PRICE' ? `${l.name} (${currency})` : `${l.name}`}</span>
            </div>
        </th>;
    });
};

const renderTableBody = (rows, currency, labels) => {
    return rows.map((r, i) => <tr key={i}>
        { renderCells(r, currency, labels) }
    </tr>);
};

const renderTable = (labels, currency, sortedRows, size, sort, setState) => {
    return <Table striped hover size={size} responsive>
        <thead>
            <tr>
                {renderTableHeader(labels, currency, sort, setState)}
            </tr>
        </thead>
        <tbody>
            {renderTableBody(sortedRows, currency, labels)}
        </tbody>
    </Table>;
};

export const ChangesTable = props => {
    const { size, data, currency } = props;
    const { labels=[], rows=[] } = data;
    const [sort, setState] = useState(TABLE_WIDGET.initialSort);
    const sortedRows = _sortRows(rows, sort, currency);
    return <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
        <div className="table-container position-relative">
            { renderTable(labels, currency, sortedRows, size, sort, setState) }
        </div>
    </CustomScroll>;
};