import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/smartContractAllowancePage/actions/uiActions';
import { allowanceEffects } from '../pages/smartContractAllowancePage/actions/allowanceEffects';
import { constants_allowance } from '../pages/smartContractAllowancePage/constants';

const { DROPDOWN_LIST } = constants_allowance;

const SMART_CONTRACT_ALLOWANCE_MODEL = {
    selector: {
        value: DROPDOWN_LIST[0],
        list: DROPDOWN_LIST
    },
    search: {
        value: ''
    },
    tableData: {
        pagination: {
            hasMore: true,
            page: 0,
            pageSize: 50
        },
        data: [],
        filteredData: []
    },
    searchRequest: null,
    address: '',
    loader: false,
    typeOfFetch: 'set',
    buttonShowRevokeAll: false,

    ...uiActions.actionHandlers,
    ...allowanceEffects.actionHandlers

};

export const SmartContractAllowanceState = createContextStore(SMART_CONTRACT_ALLOWANCE_MODEL);