import React from 'react';
import './styles.scss';
import { useStoreState } from 'easy-peasy';
import { BalanceContent } from '../balance/view';
import { Favorites } from '../favorites/view';
import { withResizeDetector } from 'react-resize-detector';


const HeaderContent = props => {
    const { width=0 } = props;
    const sizes = useStoreState(state => state.sizes);
    sizes.headerWidth = width || 0;

    return <div className="header">
        <div className="header-container d-flex justify-content-between align-items-center pe-3 ps-3 h-100">
            <div className="balances-favorites-container h-100">
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between w-100 h-100">
                    <div className="d-flex flex-row balances mb-3 mb-md-0 justify-content-between justify-content-sm-start">
                        <BalanceContent/>
                    </div>
                    <div className="d-flex flex-row favorites">
                        <Favorites/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export const Header = withResizeDetector(HeaderContent);