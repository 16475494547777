import React from 'react';

export const constants = {
    TERMS_OF_USE: {
        TEXT: <>
                <article>
                    <h2>TERMS OF USE</h2>
                    <p>
                        Last updated: October, 2022
                    </p>
                    <p>
                        This Terms of Use (“Agreement“) is an electronic Agreement between you (“you“, “User“) and Monoceros (hereinafter referred to as “Monoceros “, “we“) that applies to your use of this Website, Platform, our mobile applications or any associated application programming interface (API) and all services, products, websites, and content provided by Monoceros (collectively “Services“).
                    </p>
                    <p>
                        Please read this Agreement carefully as it regulates your use of our Services. Digital currencies and tokens can be of high volatility and present a substantial risk as an investment and trading instrument. By using our Services, you acknowledge those risks, accept this Agreement, and will not hold Monoceros accountable for any financial losses associated with our Services.
                    </p>
                    <p>
                        By using the Services provided at this Website, you acknowledge and confirm that you have read, understood, and completely agree with all of the terms and conditions of this Agreement.  
                    </p> 
                </article>

                <article>
                    <h3>Services</h3>

                    <p>
                        Monoceros is a multifunctional all-in-one non custodial investing terminal. The terminal aggregates a variety of CeFi and DeFi Products — CEX, DEX, AMM, LP’s, liquidation panels, and NFT marketplaces allowing users to explore investment opportunities. Describing each Service provided by us is thereinafter. 
                    </p>
                </article>

                <article>
                    <h3>Interpretation and Definitions</h3>
                    <h4>Interpretation</h4>

                    <p>
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
                    </p>

                    <h4>Definitions</h4>

                    <p>
                        For the purposes of these Terms of Use:
                    </p>

                    <ul>
                        <li>
                            Account means functional part of the Platform that controls User Funds. For the purpose of this Agreement, the term Account shall include Personal Cabinet and all respective Personal Data and User Account Data.
                        </li>
                        <li>
                            Affiliate means with respect to any Person, an individual, corporation, partnership, firm, association, unincorporated organization, or other entity directly or indirectly controlling, controlled by, or under common control with such Person. 
                        </li>
                        <li>
                            Agreement means this Terms of Use with its essential and integral parts, may be amended and/or altered from time to time in accordance with its terms.
                        </li>
                        <li>
                            Automated Market Makers (AMM) means a system that automatically facilitates buy and sell orders on a Decentralized exchange.
                        </li>
                        <li>
                            Bot is a software program that operates via internet and performs repetitive tasks.
                        </li>
                        <li>
                            Centralized exchange (CEX) is an exchange platform that allows any Person with an internet connection to discover and transact with Cryptocurrencies. It is owned and operated by a private company and requires users to sign up and open an account in order to participate. CEXs facilitate transactions of Cryptocurrencies between buyers and sellers.
                        </li>
                        <li>
                            Company (referred to as either "Company", "We", "Us" or "Our" in this Agreement) refers to company_name, company_number, registration_address.
                        </li>
                        <li>
                            Copy Trading Bot is a software application that enables individuals in the financial markets to automatically copy positions opened and managed by another selected expert trader.
                        </li>
                        <li>
                            Country refers to the United Kingdom.
                        </li>
                        <li>
                            Cryptocurrency means a cryptographically secured digital representation of value or contractual rights that uses distributed ledger technology and can be transferred, stored or traded electronically.
                        </li>
                        <li>
                            Decentralized exchange (DEX) is an exchange platform that allows peer-to-peer Сryptocurrency transactions directly from user’s digital Cryptocurrency wallet without going through an intermediary.
                        </li>
                        <li>
                            Device means any device that can access the Service, such as a computer, a cellphone, or a digital tablet.
                        </li>
                        <li>
                            Dollar-Cost-Averaging (DCA) Bots are automated trading Bots that allow users to automatically buy and/or sell Cryptocurrency at regular intervals over a preset time frame.
                        </li>
                        <li>
                            GridBot means trading Bots that are automated programs designed to use the grid trading strategy, which involves buying Cryptocurrency low and selling Cryptocurrency high.
                        </li>
                        <li>
                            Lending means process during which Automated Market Makers (AMMs) let users leverage their investments without selling their specific Cryptocurrencies by using them as collateral.
                        </li>
                        <li>
                            Liquidity Pool is a crowdsourced pool of Cryptocurrencies that are locked in a smart contract and used to facilitate trades between those assets on a Decentralized exchange (DEX).
                        </li>
                        <li>
                            Non-fungible token (NFT) is a unique digital identifier that cannot be copied, substituted, or subdivided, that is recorded in a blockchain, and that is used to certify authenticity and ownership.
                        </li>
                        <li>
                            Paper Trading Account is simulated trading Account that allows investors to practice buying and selling of Cryptocurrency.
                        </li>
                        <li>
                            Person means an individual, partnership, limited partnership, corporation, limited liability company, joint-stock company, unincorporated organization or association, trust or joint venture, or other forms of a legal entity, or a Governmental Authority or political subdivision thereof. Person also means the Person’s Representatives, successors, or permitted assigns.
                        </li>
                        <li>
                            Platform means Monoceros’ non custodian investing terminal available at https://www.monoceros.app/.
                        </li>
                        <li>
                            Product means Monoceros’ terminal that aggregates CEX, DEX, AMM, LP’s, liquidation panels, and NFT marketplaces.
                        </li>
                        <li>
                            ProTrade means a functionality of the Platform that allows you to set your preferred parameters before launching a trade.
                        </li>
                        <li>
                            Service is any of the Products provided by Monoceros via Platform.
                        </li>
                        <li>
                            Subscription is a purchase by prepayment of a certain period of access to the Service. The fees and access policy are described here. 
                        </li>
                        <li>
                            Terms of Use (also referred to as Terms) mean these Terms of Use that form the entire Agreement between you and the Company regarding the use of the Service.
                        </li>
                        <li>
                            Third-party Social Media Service means any services or content (including data, information, products, or services) provided by a third party that may be displayed, included, or made available by the Service.
                        </li>
                        <li>
                            User means any Person who is using the Platform and has agreed to the terms and conditions of this Agreement.
                        </li>
                        <li>
                            You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                        </li>
                    </ul>

                    <p>
                        Other undefined terms and definitions that can be found in the text of this Agreement should be construed by the Parties in accordance with the laws of England and Wales. 
                    </p>
                    <p>
                        Headings are used in this Agreement exclusively for convenience and shall not affect the interpretation of the Agreement provisions. 
                    </p>
                    <p>
                        In this Agreement Monoceros and you are referred to cumulatively as the “Parties” and singularly as the “Party”. 
                    </p>
                    <p>
                        Reference to a Company shall include any company, corporation or other body corporate, wherever and however incorporated. 
                    </p>
                    <p>
                        Unless the context otherwise requires, words in the singular shall include the plural and, in the plural, shall include the singular.
                    </p>
                    <p>
                        Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders.
                    </p>
                    <p>
                        Where the words include(s), including or in particular are used in this Agreement, they are deemed to have the words “without limitation” following them. 
                    </p>
                    <p>
                        Where the context permits, other and otherwise are illustrative and shall not limit the sense of the words preceding them.
                    </p>
                </article>

                <article>
                    <h3>Acknowledgment</h3>

                    <p>
                        These are the Terms of Use governing the use of this Service and the Agreement that operates between you and the Company. These Terms of Use set out the rights and obligations of all users regarding the use of the Service.
                    </p>
                    <p>
                    Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms of Use. These Terms of Use apply to all visitors, users and others who access or use the Service.
                    </p>
                    <p>
                        By accessing or using the Service you agree to be bound by these Terms of Use. If you disagree with any part of these Terms of Use then you may not access the Service.
                    </p>
                    <p>
                        You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                    </p>
                    <p>
                        Your access to and use of the Service is also conditioned on your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes our policies and procedures on the collection, use and disclosure of your personal information when you use the Application or the Website and tells you about your privacy rights and how the law protects you. Please read our Privacy Policy carefully before using our Service.
                    </p>
                </article>

                <article>
                    <h3>Intellectual Property</h3>

                    <p>
                        You acknowledge and agree that Monoceros is the sole owner (except to the extent owned by third-party licensors, including any Third-Party Technology Providers), of all rights, titles and interests to the IP Rights. You shall not obtain any rights in or to the IP Rights, except for those limited rights licensed to you by Monoceros.
                    </p>
                    <p>
                        You shall take all steps necessary to maintain the confidentiality of all documents and material provided by Monoceros or any of its third-party providers (including any Third-Party Technology Providers) with respect to Website, Platform and each component thereof. You shall not:
                    </p>
                    <ul>
                        <li>
                            alter, maintain, enhance or otherwise modify Website and/or Platform;
                        </li>
                        <li>
                            disassemble, decompile, reverse-engineer, copy, bug fix, correct, update, transfer, broadcast or create derivative works based on Website and/or Platform; nor
                        </li>
                        <li>
                            otherwise take express action to discover any equivalent of Website or Platform.
                        </li>
                    </ul>
                </article>

                <article>
                    <h3>Limited Right of Use</h3>

                    <p>
                        Subject to your compliance with the terms and conditions of this Agreement, you are granted a limited, revocable, non-exclusive, royalty-free, and non-transferable license to access and use the Website and the Platform during the Term for the purposes set out in, and in a manner consistent with this Agreement.
                    </p>
                    <p>
                        All rights that are not expressly granted under this Agreement are reserved. Thus, you are prohibited from using Services in any manner that is not expressly and unambiguously authorized by this Agreement.
                    </p>
                    <p>
                        Unless otherwise specified, all materials on this Website are the property of Monoceros and are protected by copyright, trademark, and other applicable Laws. You may view, print, and/or download a copy of the materials from this Website solely for your personal, informational, and/or non-commercial use, provided you comply with all copyright and other proprietary notices.
                    </p>
                </article>

                <article>
                    <h3>Links to Other Websites</h3>

                    <p>
                        Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.
                    </p>
                    <p>
                        The Company has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services.
                    </p>
                    <p>
                        We strongly advise you to read the terms of use and privacy policies of any third-party websites or services that you visit.
                    </p>
                </article>

                <article>
                    <h3>Limitation of Liability</h3>
                    <h4>Limited Warranties</h4>

                    <p>
                        Notwithstanding any damages that you might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms of Use and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for Service to the Company.
                    </p>
                    <p>
                        To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
                    </p>

                    <h4>"AS IS" and "AS AVAILABLE" Disclaimer</h4>

                    <p>
                        The Service is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and Service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of a course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards or be error-free or that any errors or defects can or will be corrected.
                    </p>

                    <h4>No Advice</h4>

                    <p>
                        You acknowledge the substantial risks associated with Cryptocurrency trading markets. Monoceros is not authorized or regulated by any financial authority and does not give any financial, tax, employment, legal, or investment advice. You agree that the Monoceros shall not be or be deemed to be, your financial advisor or fiduciary. You understand that any of your decisions made is your sole and exclusive risk.
                    </p>

                    <h4>Exceptions to Limitation of Liability</h4>

                    <p>
                        Nothing in this Agreement shall or shall be construed to exclude or limit either Party’s liability for fraud or fraudulent misrepresentation or any other liability that cannot be excluded or limited by the applicable law.
                    </p>
                </article>

                <article>
                    <h3>Termination</h3>

                    <p>
                        We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach this Agreement.
                    </p>
                    <p>
                        Upon termination, your right to use the Service will cease immediately.
                    </p>
                </article>

                <article>
                    <h3>Governing Law</h3>

                    <p>
                        The laws of England and Wales, excluding its conflicts of law rules, shall govern this Agreement and your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
                    </p>
                </article>

                <article>
                    <h3>Indemnification</h3>

                    <p>
                        You agree to defend, hold harmless and hereby indemnify Monoceros from and against any loss, damage, cost, claim, proceeding, penalty, fine or expense, including legal fees, incurred by or suffered by Monoceros, which arises out of, or relates to, directly or indirectly:
                    </p>
                    <ul>
                        <li>
                        your use of Website and/or Platform;
                        </li>
                        <li>
                        your failure to fully and timely perform any of your obligations hereunder, including non-performance of your obligations with respect to any usage of Service;
                        </li>
                        <li>
                        any of your representations or warranties made hereunder being, at any time, untrue or incorrect;
                        </li>
                        <li>
                        any violation by you of any Law, rule, regulation, or the rights of any third party.
                        </li>
                    </ul>
                </article>
                
                <article>
                    <h3>Disputes Resolution</h3>

                    <p>
                        If you have any concern or dispute about the Service, you agree to try to resolve the dispute informally by contacting the Company. If the dispute will not be resolved informally within 30 calendar days, it should be resolved in London Court of International Arbitration (LCIA). The number of arbitrators shall be one. The language of the arbitration process shall be Enlgish. If any dispute occurs as to the performance or interpretation of this Agreement that cannot be resolved amicably or in arbitration, Monoceros is entitled to demand the dispute to be settled by the court in the location of its residence. Herewith, all legal fees and expenses of both Parties shall be borne by the losing Party.
                    </p>
                </article>

                <article>
                    <h3>The United States Legal Compliance</h3>

                    <p>
                        You represent and warrant that you are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and you are not listed on any United States government list of prohibited or restricted parties.
                    </p>
                </article>

                <article>
                    <h3>Severability and Waiver</h3>
                    <h4>Severability</h4>

                    <p>
                        If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                    </p>

                    <h4>Waiver</h4>

                    <p>
                        Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
                    </p>
                </article>

                <article>
                    <h3>Translation Interpretation</h3>
                    <p>
                        This Agreement may have been translated if we have made them available to you on our Platform. You agree that the original English text shall prevail in the case of a dispute.
                    </p>
                </article>
                
                <article>
                    <h3>Changes to These Terms of Use</h3>

                    <p>
                        We reserve the right, at our sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days’ notice prior to any new Terms taking effect. What constitutes a material change will be determined at our sole discretion.
                    </p>
                    <p>
                        By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, in whole or in part, please stop using the website and the Service.
                    </p>
                </article>

                <article>
                    <h3>Contact Us</h3>

                    <p>
                        If you have any questions about this Agreement, you can contact us:
                    </p>
                    <ul>
                        <li>
                            By email: contact@email.com
                        </li>
                    </ul>
                </article>
              </>,
        BACK: 'Back'
        
    },
};