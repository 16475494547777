import React, { useEffect, useRef, Fragment, useState } from "react";
import { BuyState } from "../../../../models/buy-model";
import "./styles.scss";
import { dataHelper } from "./actions/dataHelpers/dataHelper";
import MetaMaskOnboarding from "@metamask/onboarding";
import { connectionWalletEffects } from "./actions/connectWalletEffects";
import { trustConnectEffects } from "./actions/trustConnectEffects";
import { Loader } from "../../../../components/utility/loader/view";
import { constants } from "./constants";
import { BorderedButton } from "../../../../components/utility/bordered-button/view";
import { ButtonCustom } from "../../../../components/utility/custom-buttons/view";
import { Form, InputGroup, Modal,Table } from "react-bootstrap";
import { Image } from "../../../../components/utility/images/view";
import { fetchEffects } from "./actions/fetchEffects";
import numeral from "numeral";
import { LinkContainer } from "react-router-bootstrap";

const { CONTENT, NETWORKS, FORMAT, ADDRESS, MAX_SUPPLY, TOTAL_AVAILABLE } = constants;

const invokeBuy = (onboarding, actions, counter, state, wallet) => {
  const CONNECTOR = dataHelper.getConnector();
  if (wallet === "metamask") {
    MetaMaskOnboarding.isMetaMaskInstalled()
      ? actions[connectionWalletEffects.actionTypes.BUY_SIGN_IN_INVOKED]({
          counter,
        })
      : onboarding.current.startOnboarding();
  } else if (wallet === "trustwallet") {
    actions[trustConnectEffects.actionTypes.TRUST_SIGN_IN_INVOKED]({
      connector: CONNECTOR,
      state,
    });
  } else {
    MetaMaskOnboarding.isMetaMaskInstalled()
      ? actions[connectionWalletEffects.actionTypes.BUY_SIGN_IN_INVOKED]({
          counter,
        })
      : onboarding.current.startOnboarding();
  }
};

const BuyForm = () => {
  const CONNECTOR = dataHelper.getConnector();
  const state = BuyState.useStoreState((state) => state.buy);
  const actions = BuyState.useStoreActions((actions) => actions);

  const changeHandle = (e) =>
    actions[connectionWalletEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
      name: e.target.name,
      value: e.target.value,
    });
  const submitHandle = (name) => {
    if (state.walletName === "metamask") {
      actions[connectionWalletEffects.actionTypes.BUY_INVOKED](state);
      actions[connectionWalletEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
        name,
      });
    } else if (state.walletName === "trustwallet") {
      actions[trustConnectEffects.actionTypes.TRUST_BUY_INVOKED]({
        state,
        connector: CONNECTOR,
      });
      actions[trustConnectEffects.actionTypes.TRUST_UI_ACTIONS_INVOKED]({
        name,
      });
    } else {
      actions[connectionWalletEffects.actionTypes.BUY_INVOKED](state);
      actions[connectionWalletEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
        name,
      });
    }
  };
  return (
    <Form.Group className="w-75">
      <div className="form-item pb-3">
        <Form.Label className="font-weight-medium font-size-16">
          {CONTENT.BUYING}
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={state.buying}
            className="font-size-14 font-weight-regular"
            type="number"
            name="buying"
            size="lg"
            min={0}
            onChange={changeHandle}
          />
          <InputGroup.Text className="right-input-button">
            <img
              width={24}
              height={24}
              src="../coins-full-library/mcs.svg"
              className="token-icon pe-2"
            />
            MCS
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="form-item pb-5">
        <Form.Label className="font-weight-medium font-size-16">
          {CONTENT.SELLING}
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={state.selling}
            className="font-size-14 font-weight-regular"
            name="selling"
            type="number"
            size="lg"
            min={0}
            onChange={changeHandle}
          />
          <InputGroup.Text className="right-input-button">
            <img
              width={24}
              height={24}
              src="../coins-full-library/usdt.svg"
              className="token-icon pe-2"
            />
            USDT
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <a name="buy-action" onClick={() => submitHandle("buy-action")}>
          {state.selling && state.buying ? (
            <BorderedButton>{CONTENT.BUY}</BorderedButton>
          ) : null}
        </a>
      </div>
    </Form.Group>
  );
};

const BuyModal = (props) => {
  const { show } = props;
  const actions = BuyState.useStoreActions((actions) => actions);
  const handleClose = () =>
    actions[connectionWalletEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
      name: "close-modal",
    });
  const handleEnter = () =>
    actions[connectionWalletEffects.actionTypes.BUY_UI_ACTIONS_INVOKED]({
      name: "modal-opened",
    });
  return (
    <Modal
      show={show}
      size="lg"
      centered
      className="add-exchange buy-modal"
      backdrop="static"
      onEnter={handleEnter}
      container={document.querySelector(".wrapper")}
      onHide={handleClose}
    >
      <Modal.Body>
        <div
          className="text-end cursor-pointer cancel-icon"
          onClick={handleClose}
        >
          <i className="icon-cancel"></i>
        </div>
        <div className="font-size-24 font-weight-medium text-start form-title mb-2">
          {CONTENT.BUY}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <BuyForm />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Networks = () => {
  const [network, setNetwork] = useState(null);
  const [actives, setActive] = useState([false, false, false, false, false]);
  const actions = BuyState.useStoreActions((actions) => actions);
  const state = BuyState.useStoreState((state) => state.buy);
  const CONNECTOR = dataHelper.getConnector();
  const clickHandle = (network, index) => {
    const acc = actives.map((a, i) => (i === index ? true : false));
    setActive(acc);
    setNetwork(network);
  };
  const enableHandle = () => {
    const { data, id } = network;
    switch (state.walletName) {
      case "metamask":
        actions[connectionWalletEffects.actionTypes.BUY_ENABLE_ACTION_INVOKED]({
          data,
          id,
          account: state.account,
          approved: state.approved,
        });
        break;
      case "trustwallet":
        actions[trustConnectEffects.actionTypes.TRUST_ENABLE_ACTION_INVOKED]({
          data,
          id,
          account: state.account,
          connector: CONNECTOR,
        });
        break;
      default:
        actions[connectionWalletEffects.actionTypes.BUY_ENABLE_ACTION_INVOKED]({
          data,
          id,
          account: state.account,
        });
        break;
    }
  };
  return (
    <div className="d-flex flex-column w-50 pt-3">
      {NETWORKS.map((n, i) => {
        return (
          <div className="pb-3 w-100 networks" key={i}>
            <ButtonCustom
              active={actives[i]}
              size="sm"
              label={n.jsx}
              variant="default"
              onClick={() => clickHandle(n, i)}
            />
          </div>
        );
      })}
      {Boolean(network) ? (
        <div className="d-flex w-100 justify-content-center pb-3">
          <a onClick={enableHandle}>
            <BorderedButton>{CONTENT.ENABLE}</BorderedButton>
          </a>
        </div>
      ) : null}
      <BuyModal show={state.showModal} />
    </div>
  );
};

const BuyPageLayout = () => {
  const state = BuyState.useStoreState((state) => state.buy);
  const actions = BuyState.useStoreActions((actions) => actions);
  const onboarding = useRef(null);
  const counter = state.counter;
  const { range, totalAvailable, maxSupply, sold } = state;
  const rangeStyle = { width: `${(MAX_SUPPLY - TOTAL_AVAILABLE) / MAX_SUPPLY * 100}%` };
  // const rangeStyle = { width: `${range}%` };
  const CONNECTOR = dataHelper.getConnector();

  useEffect(() => {
    if (CONNECTOR.peerId) CONNECTOR.killSession();
    actions[fetchEffects.actionTypes.BUY_SOLD_DATA_FETCHED]();
    // state.address || CONNECTOR ?
    //     actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({ address: state.address || CONNECTOR.accounts[0] }) :
    //     actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({ address: '' });
  }, []);
  return (
    <Fragment>
      <div className="buy-wrapper" ref={onboarding}>
        <div className="buy-container align-items-center justify-content-between">
          {/* <img src="../dashboard-library/home-page-images/buyBackground.svg"/> */}
          <div className="left-column font-inter">
            <div className="buy-heading font-weight-bold font-unbounded">
              {CONTENT.TITLE_1}
            </div>
            <p className="buy-heading font-weight-bold font-unbounded">
              {CONTENT.TITLE_2}
            </p>
            <p className="buy-disclaimer">{CONTENT.DISCLAIMER}</p>
            <div className="d-flex flex-column pb-3">
              <div>
                Contract address:{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://blockscan.com/address/${ADDRESS}`}
                >{`${ADDRESS}`}</a>
              </div>
              <div>Ticker: MCS</div>
              <div>Decimals: 18</div>
            </div>
            <div className="buy-description-info-grid">
            <div>
            <div className="sub-heading d-flex justify-content-start align-items-center pb-3">
              <div className="position-relative">1 USDT</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/usdt.svg"
                className="token-icon ms-2"
              />
              <div className="ms-2">= 10 MCS</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/mcs.svg"
                className="token-icon ms-2"
              />
            </div>
            <div className="sub-heading d-flex justify-content-start align-items-center pb-3">
              <div className="position-relative">Minimum buy = 10 USDT</div>
              <img
                width={24}
                height={24}
                src="../coins-full-library/usdt.svg"
                className="token-icon ms-2"
              />
            </div>
            </div>
            <div>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th>Token</th>
                  <th>Amount</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>MCS</td>
                  <td>0 - 250 M</td>
                  <td>0.1$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>250 - 300 M</td>
                  <td>0.11$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>300 - 350 M</td>
                  <td>0.115$</td>
                </tr>
                <tr>
                  <td>MCS</td>
                  <td>350 - 400 M</td>
                  <td>0.12$</td>
                </tr>
              </tbody>
            </Table>
          </div>
          </div>
            {maxSupply ? (
              <>
                <p className="sub-heading">
                  {`MCS sold: ${numeral(MAX_SUPPLY - totalAvailable).format(
                    FORMAT
                  )} / ${numeral(TOTAL_AVAILABLE).format(FORMAT)}`}
                </p>
                {/*<p className="sub-heading">*/}
                {/*  {`MCS sold: ${numeral(maxSupply - totalAvailable).format(*/}
                {/*    FORMAT*/}
                {/*  )} / ${numeral(MAX_SUPPLY).format(FORMAT)}`}*/}
                {/*</p>*/}
                <div className="buy-range">
                  <div className="range-content" style={rangeStyle}></div>
                </div>
              </>
            ) : null}
            <div className="d-flex justify-content-between pt-5">
              <div>
                {/*<div className="pb-1">Instruction in English</div>*/}
                <LinkContainer to="/home?elem=tutorial#en">
                  <ButtonCustom variant="default" label="How to buy" />
                </LinkContainer>
              </div>
              <div>
                {/*<div className="pb-1">Instruction in Russian</div>*/}
                <LinkContainer to="/home?elem=tutorial#ru">
                  <ButtonCustom variant="default" label="Как купить" />
                </LinkContainer>
              </div>
            </div>
          </div>
          <div className="right-column font-inter text-center">
            <div className="placeholder d-flex justify-content-center align-items-center">
              {state.loader ? (
                <Loader size="4x" />
              ) : !state.isWalletLogged ? (
                <div className="wallets-grid">
                  <div>
                    <a
                      onClick={() =>
                        invokeBuy(
                          onboarding,
                          actions,
                          counter,
                          state,
                          CONTENT.METAMASK
                        )
                      }
                    >
                      <BorderedButton>
                        {CONTENT.CONNECT_METAMASK_WALLET}
                      </BorderedButton>
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() =>
                        invokeBuy(
                          onboarding,
                          actions,
                          counter,
                          state,
                          CONTENT.TRUST_WALLET
                        )
                      }
                    >
                      <BorderedButton>
                        {CONTENT.CONNECT_TRUST_WALLET}
                      </BorderedButton>
                    </a>
                  </div>
                </div>
              ) : state.walletName !== CONTENT.TRUST_WALLET ? (
                <Networks />
              ) : (
                <div>
                  <div className="mb-2">
                    You have connected to {state.network} Network
                  </div>
                  <div className="d-flex flex-column">
                    <a
                      className="d-flex justify-content-center"
                      onClick={() =>
                        actions[
                          trustConnectEffects.actionTypes
                            .TRUST_ENABLE_ACTION_INVOKED
                        ]({
                          state,
                          data: {
                            data: NETWORKS.find(
                              (net) => net.data.chainId === CONNECTOR.chainId
                            ).data,
                            id: NETWORKS.find(
                              (net) => net.data.chainId === CONNECTOR.chainId
                            ).id,
                            account: CONNECTOR.accounts[0],
                          },
                          connector: CONNECTOR,
                        })
                      }
                    >
                      <BorderedButton>ENABLE</BorderedButton>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BuyModal show={state.showModal} />
    </Fragment>
  );
};

export const BuyPage = () => (
  <BuyState.Provider>
    <BuyPageLayout />
  </BuyState.Provider>
);
