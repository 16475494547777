import { action } from 'easy-peasy';
import { constants } from '../../../../constants/constants';

const { TABLE_WIDGET: { currency } } = constants;

const actionTypes = {
    TABLE_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED: 'TABLE_WIDGET_UI_ACTIONS_EXCHANGE#CHANGED',
    TABLE_WIDGET_UI_ACTIONS_TOGGLE_CURRENCY_CHANGED: 'TABLE_WIDGET_UI_ACTIONS_TOGGLE_CURRENCY#CHANGED',
    TABLE_WIDGET_UI_ACTIONS_ADD_EXCHANGE_CLICKED: 'TABLE_WIDGET_UI_ACTIONS_ADD_EXCHANGE#CLICKED',
    TABLE_WIDGET_UI_ACTIONS_TOGGLE_VOLUME_CHANGED: 'TABLE_WIDGET_UI_ACTIONS_TOGGLE_VOLUME#CHANGED',
};

const uiAction = (type, value, _) => {
    let action;
    switch (type) {

    case 'toggle_currency':
        action = _[actionTypes.TABLE_WIDGET_UI_ACTIONS_TOGGLE_CURRENCY_CHANGED];
        action({ value: !value ? currency.currencyId[0] : currency.currencyId[1] });
        break;

    case 'toggle_volume':
        action = _[actionTypes.TABLE_WIDGET_UI_ACTIONS_TOGGLE_VOLUME_CHANGED];
        action(value);
        break;
    }
};

const exchangeAction = (state, payload) => {
    const { value } = payload;
    state.tableWidget.exchange = value;
};

const currencyAction = (state, payload) => {
    const { value } = payload;
    state.tableWidget.currency = value;
};

const volumeAction = (state, payload) => {
    state.tableWidget.volume = payload;
};

const addExchangeAction = state => {
    state.tableWidget.toggleAddExchangeModal = true;
};


const actionHandlers = {
    [actionTypes.TABLE_WIDGET_UI_ACTIONS_EXCHANGE_CHANGED]: action((state, payload) => exchangeAction(state, payload)),
    [actionTypes.TABLE_WIDGET_UI_ACTIONS_TOGGLE_CURRENCY_CHANGED]: action((state, payload) => currencyAction(state, payload)),
    [actionTypes.TABLE_WIDGET_UI_ACTIONS_ADD_EXCHANGE_CLICKED]: action((state) => addExchangeAction(state)),
    [actionTypes.TABLE_WIDGET_UI_ACTIONS_TOGGLE_VOLUME_CHANGED]: action((state, payload) => volumeAction(state, payload)),
};

export const tableWidgetEffects = {
    actionHandlers,
    actionTypes,
    uiAction
};