import React from 'react';
import { TeamState } from '../../../../models/team-model';
import './styles.scss';
import { constants } from './constants';
import { Collapse } from 'react-bootstrap';
import { uiActions } from './actions/uiActions';
import { ImagesGenerator } from './imagesGenerator/imagesGenerator';

const { HEADER, TEAM: { list } } = constants;

const TeamLayout = () => {
    const state = TeamState.useStoreState(state => state);
    const actions = TeamState.useStoreActions(actions => actions);
    const onDepartmentCollapse = id => actions[uiActions.actionTypes.TEAM_DEPARTMENT_COLLAPSED]({ id });
    const chevronPosition = id => state.collapsed.includes(id) ? 'fa-chevron-up' : 'fa-chevron-down';

    return <div className="team-page font-inter">
        <div className="header">
            {HEADER}
        </div>
        <div className="departments">
            {list.map(department => <div key={department.departmentName} className="department">
                <div className="department-header"><div onClick={() => onDepartmentCollapse(department.departmentName)} className="d-flex align-items-center">
                    <div className="label">{department.departmentName}</div>
                    { department.departmentName !== 'ETHERNAL' && <i className={`fa-light ${chevronPosition(department.departmentName)} up-down ps-4`}></i>}
                </div>
                </div>
                <Collapse in={state.collapsed.includes(department.departmentName)}>
                    <div className="collapse-content">
                        <div className="department-members">
                            {department.members.map(member => <div key={member.img} className="member-card">
                                <div className="member-card-img d-flex justify-content-center">
                                    <div className="avatar">
                                        <img width={280} height={280} src={`../dashboard-library/home-page-images/team/members/${member.img}.png`} />
                                    </div>
                                </div>
                                <div className="member-info">
                                    <ImagesGenerator member={member} />
                                </div>
                            </div>)}
                        </div>
                    </div>
                </Collapse>
            </div>)}
        </div>
    </div>;
};

export const TeamPage = () => {
    return <TeamState.Provider>
        <TeamLayout/>
    </TeamState.Provider>;
};