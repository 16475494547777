import React, { useEffect, useRef } from 'react';
import { imagesTemplate } from './templates';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { RemakeDashboardState } from '../../../../../models/remake-dashboard-model';
// import { SMALL_PARTS } from './images/smallParts';
import { useStoreState } from 'easy-peasy';
// import { dashboardEffects } from '../actions/dashboardEffects';
import { isEmpty } from 'lodash';
import { remakeDashboardEffects } from '../../../actions/remakeDashboardEffects';

export const ImagesGeneratorCeFi = props => {
    const cefiRef = useRef();
    const noCefiRef = useRef();
    const ref = useRef();
    const state = RemakeDashboardState.useStoreState(state => state);
    const sizes = useStoreState(state => state.sizes.values);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);
    const usedExchanges = useStoreState(state => state?.coinData?.exchanges);

    useEffect(() => {
        drawHelper.drawCeFiData(d3.select(cefiRef.current), state, actions);
        drawHelper.drawNoCeFiData(d3.select(noCefiRef.current), actions);
    }, );

    useEffect(() => {
        const { height } = ref?.current?.getBoundingClientRect();
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_SIZES_UPDATED]({ height: height });
    }, [sizes]);

    return <div ref={ref} className="images-background-grid">
        {
            !isEmpty(usedExchanges) ?
                <svg ref={cefiRef} viewBox="0 0 360 366" fill="none" className="image-right-part">
                    { imagesTemplate.cefiImg() }
                </svg> 
                :
                <svg ref={noCefiRef} viewBox="0 0 472 478" fill="none" className="image-right-part">
                    { imagesTemplate.noCefiImg() }
                </svg>
        }
    </div>;
};
