import React from 'react';
import Toggle from 'react-toggle';
import { Form, InputGroup } from 'react-bootstrap';
import { constants } from '../../../constants';
import { MultiplyCustomSelector } from '../../../../../components/utility/multiply-selector/view';
import { customBotsEffects } from '../../../actions/customBotsEffects';
import { PopoverLayout } from '../../../../../components/utility/popover/view';

const {
  CONTROLS,
  TITLES,
  MORE_INFO
} = constants;

export const CloseDealAfterTimeoutForm = (props) => {
  const { state, actions } = props
  const action = actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]

  const changeHandle = (value) => action({...value});
  const formControlChange = e => action({name: e?.target?.name, value: e?.target?.value ? parseFloat(e?.target?.value) : 0});
  const toggleOnChange = e => action({name: e?.target?.name, value: e?.target?.checked});

  return <div className="main-settings-form-content">
    <div className="form-content panel-wrapper">
      <label className="d-flex align-items-center customized-toggle cursor-pointer mb-3">
        <span className="font-weight-medium me-3">
          {TITLES.CLOSE_DEAL_AFTER_TIMEOUT}
        </span>
        <Toggle
          name="toggle_close_deal_after_timeout"
          icons={false}
          defaultChecked={state.forms.advancedSettings.toggleCloseDealAfterTimeout}
          onChange={toggleOnChange}
        />
      </label>
      <div className="font-inter">
        <div className="form-item pb-3">
          <div className="d-flex">
            <div className="font-size-14 pb-1 font-weight-medium me-1">
              {CONTROLS.LABEL_TIME_DEAL_CLOSED}
            </div>
            <div className="more-info">
              <PopoverLayout
                title={false}
                width={247}
                message={MORE_INFO.TIME_DEAL_CLOSED}
                component={<i className="fa-light fa-circle-question"></i>}/>
            </div>
          </div>
          <div className="d-flex">
            <div className="w-15 selector-btn-default-color">
              <MultiplyCustomSelector
                onChangeHandle={changeHandle}
                isMulti={false}
                isDisabled={!state.forms.advancedSettings.toggleCloseDealAfterTimeout}
                closeMenuOnSelect={true}
                options={state.forms.advancedSettings.closeDealAfterTimeoutSelector.list}/>
            </div>
            <InputGroup className="w-85 ms-2">
              <Form.Control
                name="close_deal_after_timeout_amount"
                value={state.forms.advancedSettings.closeDealAfterTimeoutAmount}
                disabled={!state.forms.advancedSettings.toggleCloseDealAfterTimeout}
                onChange={formControlChange}
                type="number"/>
            </InputGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
};