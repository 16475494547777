import React from 'react';
import { constants } from '../constants';
import { nftGeneratorEffects } from '../actions/nftGeneratorEffects';

const { LEFT_CONTENT, NFT_ITEMS } = constants;

export const LeftSideContent = props => {
  const { state, actions, name } = props;

  const onTabsToggle = tab => {
    actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_TAB_TOGGLE]({ tab, name })
  };
  const activeClass = tab => state.tabs === tab ? 'active' : '';

  return <div className="left-side-content panel-wrapper">
    <div className="left-side-content-title font-weight-medium mb-4">
      {LEFT_CONTENT.TITLE}
    </div>
    <div className="left-side-content-items">
      {LEFT_CONTENT.ITEMS.map((item, i) =>
        <div
          key={i}
          className={`item ${activeClass(item.value)}`}
          onClick={() => onTabsToggle(item.value)}>
        {item.name} ({NFT_ITEMS[name][item.value].length})
      </div>)}
    </div>
  </div>;
};
