import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { InputGroup, FormControl } from 'react-bootstrap';

const { WALLET: { MODAL_DESCRIPTION, PLACEHOLDER, ADD_TOKEN } } = constants;

export const AddTokenModal = props => {
    const { state, actions, show } = props;
    const PROTOCOLS = state.analytics.token.list.filter(protocol => protocol.symbol !== 'others');

    const handleClose = () => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ name: 'close-modal', modal: 'add-token' });
    const activeClass = protocol => state.addToken.protocol.symbol === protocol ? 'active' : '';
    const onCHangeHandle = e => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TOKEN_ADDRESS_SEARCH]({ value: e.target.value });
    
    return <Modal
        show={show}
        size="lg"
        centered
        className="add-exchange add-token-modal"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="font-weight-medium text-start modal-main-header">
                <div>{ADD_TOKEN.toUpperCase()}</div>
                <div className="font-size-14 font-weight-regular modal-description">{MODAL_DESCRIPTION}</div>
            </div>
            <div className="modal-token-main-content">
                <div className="add-token-input">
                    <InputGroup>
                        <FormControl
                            onChange={onCHangeHandle}
                            placeholder={PLACEHOLDER}/>
                    </InputGroup>
                </div>
                <div className="add-token-protocols justify-content-center">
                    { PROTOCOLS.map((protocol, index) => <div 
                        onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_ADD_TOKEN_PROTOCOL_PICKED]({ protocol: protocol.symbol })}
                        key={index}
                        className={`add-token-protocols-item ${activeClass(protocol.symbol)}`}>
                        <div className="d-flex justify-content-center mb-2"><img width={50} height={50} src={`../dashboard-library/protocols/${protocol.symbol}.svg`}/></div>
                        <div className="d-flex justify-content-center"><div className="protocol-title">{protocol.protocol}</div></div>
                    </div>) }
                </div>
            </div>
        </Modal.Body>
    </Modal>;
};