import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/hodlBotPage/actions/uiActions';
import { hodlBotEffects } from '../pages/hodlBotPage/actions/hodlBotEffects';
import { constants } from '../pages/hodlBotPage/constants';
import { controlsEffects } from '../pages/hodlBotPage/actions/controlsEffects';
import { packsEffects } from '../pages/hodlBotPage/actions/packsEffects';
import { selectorsEffects } from '../pages/hodlBotPage/actions/selectorsEffects';

const { SELECTORS : { TIME_LIST, FOR_LIST } } = constants;

const HODL_BOT_MODEL = {
  howItWorks: {
    open: false
  },
  exchangeSelector: {
    list: [],
    value: null
  },
  botWillBuyList: [],
  botWillBuySelector: '',
  forSelector: {
    list: FOR_LIST,
    value: FOR_LIST[0]
  },
  inputForValue: 0,
  inputEveryValue: 0,
  atSelector: {
    list: TIME_LIST,
    value: null,
  },
  inputNextBuyDateValue: '',
  isModalOpen: false,
  isBotRunning: false,
  manageBot: false,
  events: {
    open: false
  },
  controls: {
    packs: false
  },
  packs: {
    selectedId: null,
    selectedPack: null,
    show: false,
    packsData: [],
    addMode: false,
    addPackMode: false,
    createPackData: {},
    exchangeData: {},
    coinData: {},
    mainCoinList: [],
    pairCoinList: [],
    isSingle: true
  },
  singlePair: {
    mainCoin: '',
    pairCoin: '',
    pairData: {},
    isSingle: true
  },
  assetsData: {},
  botId: '',

  ...controlsEffects.actionHandlers,
  ...packsEffects.actionHandlers,
  ...selectorsEffects.actionHandlers,
  ...uiActions.actionHandlers,
  ...hodlBotEffects.actionHandlers
};

export const HodlBotState = createContextStore(HODL_BOT_MODEL);
