import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../components/utility/loader/view';
import { AcademyState } from '../../../../models/academy-model';

import './styles.scss';


const DocsLayout = () => {
  const actions = AcademyState.useStoreActions((actions) => actions);
  const state = AcademyState.useStoreState((state) => state);
  const isLoading = state.loader;

  const categoriesWithArticles = AcademyState.useStoreState((state) => state.categories.list)
  const fullArticle = AcademyState.useStoreState((state) => state.docsFullArticle)

  const [submenu, setShowSubMenu] = useState(null);
  const [prevArticle, setPrevArticle] = useState({});
  const [nextArticle, setNextArticle] = useState({});

  useEffect(() => {
    actions.GET_CATEGORIES_WITH_ARTICLES();
  }, []);

  const Menu = () => {
    return (
      <>
        <ul className="list">
          {categoriesWithArticles.map(({ category, articles }, i) => (
            <div onClick={() => setShowSubMenu(i)} key={i}>
              <li className="menu-item">{category}</li>

              {submenu === i && (
                <ul className="sub-list">
                  {articles.map((article, index) => (
                    <li
                      onClick={() => actions.GET_FULL_ARTICLE(article.articleId)}
                      key={index}
                      className="submenu-item"
                    >
                      {article.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </ul>
      </>
    );
  };

  const FullArticle = () => {
    useEffect(() => {
      handlePrevArticle(fullArticle);
      handleNextArticle(fullArticle);
    }, []);

    const handlePrevArticle = (selectedArticle) => {
      let prevArticle;

      categoriesWithArticles.map((category, categoryIndex) => {
        category.articles.map((article, index) => {
          if (selectedArticle.title === article.title) {
            let prevCategoryArticle = category.articles[index - 1];

            if (prevCategoryArticle !== undefined) {
              prevArticle = prevCategoryArticle;
            } else {
              let prevCategory = categoriesWithArticles[categoryIndex - 1];
              let lastCategory = categoriesWithArticles[categoriesWithArticles.length - 1];

              let lastArticleInLastCategory =
                lastCategory.articles[lastCategory.articles.length - 1];
              let previousArticleInCategory =
                prevCategory !== undefined &&
                prevCategory.articles[prevCategory.articles.length - 1];

              let article =
                prevCategory !== undefined ? previousArticleInCategory : lastArticleInLastCategory;

              prevArticle = article;
            }
          }
        });

        setPrevArticle(prevArticle);
      });
    };

    const handleNextArticle = (selectedArticle) => {
      let nextArticle;

      categoriesWithArticles.map((category, categoryIndex) => {
        category.articles.map((article, index) => {
          if (selectedArticle.title === article.title) {
            let nextCategoryArticle = category.articles[index + 1];

            if (nextCategoryArticle !== undefined) {
              nextArticle = nextCategoryArticle;
            } else {
              let nextCategory = categoriesWithArticles[categoryIndex + 1];

              let firstArticleInFirstCategory = categoriesWithArticles[0].articles[0];
              let firstArticleInNextCategory =
                nextCategory !== undefined && nextCategory.articles[0];

              let article =
                nextCategory === undefined
                  ? firstArticleInFirstCategory
                  : firstArticleInNextCategory;
              nextArticle = article;
            }
          }
        });

        setNextArticle(nextArticle);
      });
    };

    const handleFullArticle = (article) => {
      actions.GET_FULL_ARTICLE(article.articleId)
      handlePrevArticle(article);
      handleNextArticle(article);
    };



    const previousAndNextPaginate = () => {
      return (
        <>
          <div onClick={() => handleFullArticle(prevArticle)} className="btn-article">
            <span>Previous</span>

            <strong>{prevArticle.title}</strong>
          </div>

          <div onClick={() => handleFullArticle(nextArticle)} className="btn-article">
            <span>Next</span>

            <strong>{nextArticle.title}</strong>
          </div>
        </>
      );
    };

    let content = fullArticle.content;
    const theObj = { __html: content };

    return (
      <div className="full-article">
        <header>
          <h2>{fullArticle.title}</h2>
          <p>Created: {fullArticle.date}</p>
        </header>

        <main>
          <div dangerouslySetInnerHTML={theObj} />

          <div className="d-flex justify-content-around mt-2">{previousAndNextPaginate()}</div>
        </main>
      </div>
    );
  };

  return (
    <div className={`docs-page ${isLoading ? 'isLoading' : null}`}>
      {isLoading ? (
        <Loader size="4x" />
      ) : (
        <>
          <aside>
            <Menu />
          </aside>
          <main>{fullArticle && <FullArticle />}</main>
        </>
      )}

      {/* {window.open("https://monoceros.gitbook.io/monoceros-documentation/")}
       {window.location = '/'} */}
    </div>
  );
};

export const DocsPage = () => {
  return (
    <AcademyState.Provider>
      <DocsLayout />
    </AcademyState.Provider>
  );
};
