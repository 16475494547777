import React from 'react';

export const constants = {
  HEADER: 'PICK YOUR AVATARS',
  CONTROLS: {
    FEMALE: 'Female',
    MALE: 'Male',
    PICK_COLOR: 'Pick the color',
  },
  NFT_ITEMS: {
    mintTrial: {
      body: [
        { id: 1, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/1.svg' },
        { id: 2, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/2.svg' },
        { id: 3, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/3.svg' },
        { id: 4, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/4.svg' },
        { id: 5, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/5.svg' },
        { id: 6, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/6.svg' },
        { id: 7, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/7.svg' },
        { id: 8, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/8.svg' },
        { id: 9, type: 'body', src: '../dashboard-library/nft-generator/mintTrial/9.svg' },
      ],
      headdres: [],
      eyes: [],
      mouth: [],
      clothes: [],
      stuff: [],
      background: [],
    },
    beginner: {
      body: [
        { id: 1, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/BlueSpottyFur10.png' },
        { id: 2, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/BrightPinkFur5.png' },
        { id: 3, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/DarkBlueSpottyFur5.png' },
        { id: 4, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/DarkBrownSpottyFur5.png' },
        { id: 5, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/DarkPurpleSpottyFur5.png' },
        { id: 6, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/DeerFur10.png' },
        { id: 7, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/GreenSpottyFur10.png' },
        { id: 8, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/OrangeSpottyFur5.png' },
        { id: 9, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/PinkSpottyFur10.png' },
        { id: 10, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/RobotFur10.png' },
        { id: 11, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/WhiteSpottyFur15.png' },
        { id: 12, type: 'body', src: '../dashboard-library/nft-generator/beginner/body/YellowFur10.png' },
      ],
      headdres: [
        { id: 1, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/BlackDockerCap15.png' },
        { id: 2, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Crown10.png' },
        { id: 3, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/CrownRichBitch10.png' },
        { id: 4, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Fay10.png' },
        { id: 5, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/GreenHolidayCap10.png' },
        { id: 6, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Knittedhat10.png' },
        { id: 7, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/VioletDockerCap15.png' },
        { id: 8, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/VioletHolidayCap10.png' },
        { id: 9, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/YellowHolidayCap10.png' },
        { id: 10, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/BlueStars5.png' },
        { id: 11, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Candy10.png' },
        { id: 12, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Golden10.png' },
        { id: 13, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/IceCreamCone15.png' },
        { id: 14, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Indigo10.png' },
        { id: 15, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Lavender8.png' },
        { id: 16, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/McDonald’sCap5.png' },
        { id: 17, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/RainbowMarshmallow5.png' },
        { id: 18, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Rainbow8.png' },
        { id: 19, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Seafoam5.png' },
        { id: 20, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/Silver10.png' },
        { id: 21, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/SkyBlue5.png' },
        { id: 22, type: 'headdres', src: '../dashboard-library/nft-generator/beginner/headdres/White12.png' },
      ],
      eyes: [
        { id: 1, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/BlackEyes20.png' },
        { id: 2, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/BlueEyes20.png' },
        { id: 3, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/GreenEyes20.png' },
        { id: 4, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/PinkEyes15.png' },
        { id: 5, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/RobotEyes15.png' },
        { id: 6, type: 'eyes', src: '../dashboard-library/nft-generator/beginner/eyes/VioletEyes10.png' },
      ],
      mouth: [
        { id: 1, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/BigSmile10.png' },
        { id: 2, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/BlueBraces10.png' },
        { id: 3, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/BubbleGum15.png' },
        { id: 4, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/DarkVioletChains5.png' },
        { id: 5, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/GoldenChains5.png' },
        { id: 6, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/GrayChains5.png' },
        { id: 7, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/MintChains5.png' },
        { id: 8, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/PinkChains5.png' },
        { id: 9, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/RainbowChains10.png' },
        { id: 10, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/Smile25.png' },
        { id: 11, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/Steam5.png' },
        { id: 12, type: 'mouth', src: '../dashboard-library/nft-generator/beginner/mouth/VioletBraces10.png' },
      ],
      clothes: [],
      stuff: [
        { id: 1, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/BeadNecklace25.png' },
        { id: 2, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/BluePearls15.png' },
        { id: 3, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/DuckChains15.png' },
        { id: 4, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/GoldenChains15.png' },
        { id: 5, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/SilverChain15.png' },
        { id: 6, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/SilverChains15.png' },
        { id: 7, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/DiamondStud15.png' },
        { id: 8, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/Duck15.png' },
        { id: 9, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/GoldenHoop20.png' },
        { id: 10, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/GoldenStuds15.png' },
        { id: 11, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/SilverHoop20.png' },
        { id: 12, type: 'stuff', src: '../dashboard-library/nft-generator/beginner/stuff/SilverStuds15.png' },
      ],
      background: [
        { id: 1, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Beige5.png' },
        { id: 2, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/BlueViolet10.png' },
        { id: 3, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Blue5.png' },
        { id: 4, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/DarkGreen5.png' },
        { id: 5, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/DarkViolet5.png' },
        { id: 6, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/DodgerBlue5.png' },
        { id: 7, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Grey5.png' },
        { id: 8, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Jade5.png' },
        { id: 9, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Lavender10.png' },
        { id: 10, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/LightBlue5.png' },
        { id: 11, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Pink5.png' },
        { id: 12, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/PinkySky5.png' },
        { id: 13, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Purple5.png' },
        { id: 14, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/SunnyLime5.png' },
        { id: 15, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Turquoise5.png' },
        { id: 16, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Violet5.png' },
        { id: 17, type: 'background', src: '../dashboard-library/nft-generator/beginner/background/Yellow5.png' },
      ],
    },
    advanced: {
      body: [
        { id: 1, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/BrownConstellation5.png' },
        { id: 2, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/CoralConstellation5.png' },
        { id: 3, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/CorporealFur10.png' },
        { id: 4, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/DarkVioletFur10.png' },
        { id: 5, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/GrayFur10.png' },
        { id: 6, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/GreenFur10.png' },
        { id: 7, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/GreenFur10.png' },
        { id: 8, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/PinkFur10.png' },
        { id: 9, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/SpaceFur10.png' },
        { id: 10, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/StarryGrayFur10.png' },
        { id: 11, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/WhiteFur10.png' },
        { id: 12, type: 'body', src: '../dashboard-library/nft-generator/advanced/body/YellowSpotyFur10.png' },
      ],
      headdres: [
        { id: 1, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Afro10.png' },
        { id: 2, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Beret10.png' },
        { id: 3, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/BlondeHair10.png' },
        { id: 4, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/BlueHairTips10.png' },
        { id: 5, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/BlueHair10.png' },
        { id: 6, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Cap10.png' },
        { id: 7, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/NarutoGreen5.png' },
        { id: 8, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Naruto10.png' },
        { id: 9, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/PinkHair10.png' },
        { id: 10, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Rainbow10.png' },
        { id: 11, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/ShortAfro5.png' },
        { id: 12, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/DarkBlueConstellation10.png' },
        { id: 13, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/DarkBlue10.png' },
        { id: 14, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Garbage10.png' },
        { id: 15, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Golden20.png' },
        { id: 16, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Marshmallow10.png' },
        { id: 17, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Rainbow10(2).png' },
        { id: 18, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Robot10.png' },
        { id: 19, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Silver10.png' },
        { id: 20, type: 'headdres', src: '../dashboard-library/nft-generator/advanced/headdres/Sweets10.png' },
      ],
      eyes: [
        { id: 1, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/BlackEyes10.png' },
        { id: 2, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/BlueEyes5.png' },
        { id: 3, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/CalmEyes10.png' },
        { id: 4, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/GrayEyes10.png' },
        { id: 5, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/LightBlueEyes10.png' },
        { id: 6, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/PrettyEyes10.png' },
        { id: 7, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/RobotEyes10.png' },
        { id: 8, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/StrangeEyes10.png' },
        { id: 9, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/TiredEyes5.png' },
        { id: 10, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/VioletShokedEyes10.png' },
        { id: 11, type: 'eyes', src: '../dashboard-library/nft-generator/advanced/eyes/WhiteEyes10.png' },
      ],
      mouth: [
        { id: 1, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/BlueBraces10.png' },
        { id: 2, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Braces10.png' },
        { id: 3, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/CalmSmile10.png' },
        { id: 4, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Chicle11.png' },
        { id: 5, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Cigarette5.png' },
        { id: 6, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/GreenNose10.png' },
        { id: 7, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Piercing10.png' },
        { id: 8, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Shock10.png' },
        { id: 9, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Smile4.png' },
        { id: 10, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Smile10.png' },
        { id: 11, type: 'mouth', src: '../dashboard-library/nft-generator/advanced/mouth/Tongue10.png' },
      ],
      clothes: [
        { id: 1, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/BohoT-Shirt10.png' },
        { id: 2, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/OxfordStudent15.png' },
        { id: 3, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/Robot10.png' },
        { id: 4, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/RockersT-Shirt15.png' },
        { id: 5, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/Student15.png' },
        { id: 6, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/Top15.png' },
        { id: 7, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/WhiteSweatshirt10.png' },
        { id: 8, type: 'clothes', src: '../dashboard-library/nft-generator/advanced/clothes/YellowSweatshirt10.png' },
      ],
      stuff: [
        { id: 1, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/AirPods10.png' },
        { id: 2, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BlackHeadphones10.png' },
        { id: 3, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BlackLittleOldHeadphones10.png' },
        { id: 4, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/WhiteHeadphones10.png' },
        { id: 5, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/WhiteLittleOldHeadphones10.png' },
        { id: 6, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BlackСhoker10.png' },
        { id: 7, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/Multi-ColoredAcorns10.png' },
        { id: 8, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/PinkDiamondСhoker10.png' },
        { id: 9, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/PinkСhoker10.png' },
        { id: 10, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/Aviator10.png' },
        { id: 11, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BigDarkBlueGlasses10.png' },
        { id: 12, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/OrangeFire10.png' },
        { id: 13, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/PinkStylish15.png' },
        { id: 14, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/RainbowGradient10.png' },
        { id: 15, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/RoundBlackGlasses15.png' },
        { id: 16, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BrilliantEarring5.png' },
        { id: 17, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/BrilliantEarrings5.png' },
        { id: 18, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/CrystalandDiamondEarrings5.png' },
        { id: 19, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/CrystalEarring5.png' },
        { id: 20, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/DackEarring5.png' },
        { id: 21, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/GoldenEarrings10.png' },
        { id: 22, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/NoseRing5.png' },
        { id: 23, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/PearlEarring5.png' },
        { id: 24, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/RainbowRings15.png' },
        { id: 25, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/RockerEarrings15.png' },
        { id: 26, type: 'stuff', src: '../dashboard-library/nft-generator/advanced/stuff/SilverEarrings10.png' },
      ],
      background: [
        { id: 1, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/BlueSky10.png' },
        { id: 2, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/DarkGrey5.png' },
        { id: 3, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/DarkLime10.png' },
        { id: 4, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/DarkViolet10.png' },
        { id: 5, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Greeny5.png' },
        { id: 6, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Jade10.png' },
        { id: 7, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Peachy10.png' },
        { id: 8, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Pink10.png' },
        { id: 9, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/PinkySky5.png' },
        { id: 10, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/SunnyLime10.png' },
        { id: 11, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Violet10.png' },
        { id: 12, type: 'background', src: '../dashboard-library/nft-generator/advanced/background/Yellow5.png' },
      ]
    },
    expert: {
      body: [
        { id: 1, type: 'body', src: '../dashboard-library/nft-generator/expert/body/ArtistSpotty10.png' },
        { id: 2, type: 'body', src: '../dashboard-library/nft-generator/expert/body/DarkBlueSpottyFur5.png' },
        { id: 3, type: 'body', src: '../dashboard-library/nft-generator/expert/body/DarkBrown10.png' },
        { id: 4, type: 'body', src: '../dashboard-library/nft-generator/expert/body/DeerFur15.png' },
        { id: 5, type: 'body', src: '../dashboard-library/nft-generator/expert/body/GreenSpottyFur10.png' },
        { id: 6, type: 'body', src: '../dashboard-library/nft-generator/expert/body/OrangeSpotty10.png' },
        { id: 7, type: 'body', src: '../dashboard-library/nft-generator/expert/body/SkySkin15.png' },
        { id: 8, type: 'body', src: '../dashboard-library/nft-generator/expert/body/SunnySkin10.png' },
        { id: 9, type: 'body', src: '../dashboard-library/nft-generator/expert/body/VioletSkin5.png' },
        { id: 10, type: 'body', src: '../dashboard-library/nft-generator/expert/body/WhiteSkin10.png' },
      ],
      headdres: [
        { id: 1, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/AfroHair10.png' },
        { id: 2, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/BlondHair15.png' },
        { id: 3, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/BlueHair5.png' },
        { id: 4, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Pigtails15.png' },
        { id: 5, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/PinkBlond10.png' },
        { id: 6, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/PinwheelCap5.png' },
        { id: 7, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Rastaman10.png' },
        { id: 8, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/RoyalCrown10.png' },
        { id: 9, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/VioletBlond15.png' },
        { id: 10, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Сhef_sHat5.png' },
        { id: 11, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Black5.png' },
        { id: 12, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/ChipsAndCola10.png' },
        { id: 13, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/DuckCircle10.png' },
        { id: 14, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Garland10.png' },
        { id: 15, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/GoldenCoca-Cola10.png' },
        { id: 16, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Rainbow5.png' },
        { id: 17, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/RoyalHorn10.png' },
        { id: 18, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Snake10.png' },
        { id: 19, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/Violet5.png' },
        { id: 20, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/White10.png' },
        { id: 21, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/WhiteStars10.png' },
        { id: 22, type: 'headdres', src: '../dashboard-library/nft-generator/expert/headdres/СocktailUmbrella5.png' }
      ],
      eyes: [
        { id: 1, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/BeigeEyes10.png' },
        { id: 2, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/BlueEyes10.png' },
        { id: 3, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/BrownEyes10.png' },
        { id: 4, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/LaserEyes10.png' },
        { id: 5, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/LongEyelashes15.png' },
        { id: 6, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/OriginalEyes20.png' },
        { id: 7, type: 'eyes', src: '../dashboard-library/nft-generator/expert/eyes/TiredEyes25.png' },
      ],
      mouth: [
        { id: 1, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/BigSmile30.png' },
        { id: 2, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/BlueBraces10.png' },
        { id: 3, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/Dali10.png' },
        { id: 4, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/Shock10.png' },
        { id: 5, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/Smile30.png' },
        { id: 6, type: 'mouth', src: '../dashboard-library/nft-generator/expert/mouth/Cigarette10.png' },
      ],
      clothes: [
        { id: 1, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/BlueFurCoat10.png' },
        { id: 2, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/CreativeLeatherJacket10.png' },
        { id: 3, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/Indigenous10.png' },
        { id: 4, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/LeatherJacket10.png' },
        { id: 5, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/Monarch10.png' },
        { id: 6, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/Raincoat10.png' },
        { id: 7, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/Rastaman10.png' },
        { id: 8, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/SuperheroSuit10.png' },
        { id: 9, type: 'clothes', src: '../dashboard-library/nft-generator/expert/clothes/TropicalShirt10.png' },
      ],
      stuff: [
        { id: 1, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/BlueGemEarrings5.png' },
        { id: 2, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/FunnyEarrings5.png' },
        { id: 3, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/GoldenEarings5.png' },
        { id: 4, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/HempEarrings5.png' },
        { id: 5, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/JellyEarrings5.png' },
        { id: 6, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/PearlEarrings5.png' },
        { id: 7, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RainbowEarring5.png' },
        { id: 8, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RockerEarrings5.png' },
        { id: 9, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RostomanEarring5.png' },
        { id: 10, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RoyalPearlEarring5.png' },
        { id: 11, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/SmileEarring5.png' },
        { id: 12, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/SpiderEaring5.png' },
        { id: 13, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/СrossEarring5.png' },
        { id: 14, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/Aviator18.png' },
        { id: 15, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/BlueFire10.png' },
        { id: 16, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/BlueGradient10.png' },
        { id: 17, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/Eyepiece10.png' },
        { id: 18, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/PilotPoints10.png' },
        { id: 19, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RedStylish10.png' },
        { id: 20, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RoundBlueGlasses10.png' },
        { id: 21, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/SuperheroBlackGlasses7.png' },
        { id: 22, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/RoundRedGlasses5.png' },
        { id: 23, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/BlueGem10.png' },
        { id: 24, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/DiamondJewelry10.png' },
        { id: 25, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/GoldenChain10.png' },
        { id: 26, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/GoldenJewelry10.png' },
        { id: 27, type: 'stuff', src: '../dashboard-library/nft-generator/expert/stuff/YellowBandana10.png' },
      ],
      background: [
        { id: 1, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Blue10.png' },
        { id: 2, type: 'background', src: '../dashboard-library/nft-generator/expert/background/BlueViolett10.png' },
        { id: 3, type: 'background', src: '../dashboard-library/nft-generator/expert/background/BlueSky5.png' },
        { id: 4, type: 'background', src: '../dashboard-library/nft-generator/expert/background/DarkGreen5.png' },
        { id: 5, type: 'background', src: '../dashboard-library/nft-generator/expert/background/DarkGrey5.png' },
        { id: 6, type: 'background', src: '../dashboard-library/nft-generator/expert/background/DarkLime10.png' },
        { id: 7, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Greeny5.png' },
        { id: 8, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Jade5.png' },
        { id: 9, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Orrange10.png' },
        { id: 10, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Pink10.png' },
        { id: 11, type: 'background', src: '../dashboard-library/nft-generator/expert/background/PinkySky5.png' },
        { id: 12, type: 'background', src: '../dashboard-library/nft-generator/expert/background/SunnyLime5.png' },
        { id: 13, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Violet10.png' },
        { id: 14, type: 'background', src: '../dashboard-library/nft-generator/expert/background/Yellow5.png' },
      ]
    },
    legendary: {
      body: [
        { id: 1, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/BrownFur10.png' },
        { id: 2, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/CorporealFur10.png' },
        { id: 3, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/DeerSpottyFur10.png' },
        { id: 4, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/GrayFur10.png' },
        { id: 5, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/GreenFur10.png' },
        { id: 6, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/SpaceFur10.png' },
        { id: 7, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/VioletSpottyFur10.png' },
        { id: 8, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/WhiteAndVioletSpottyFur10.png' },
        { id: 9, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/YellowSpotyFur10.png' },
        { id: 10, type: 'body', src: '../dashboard-library/nft-generator/legendary/body/WhiteFur10.png' },
      ],
      headdres: [
        { id: 1, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Afro10.png' },
        { id: 2, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Beret10.png' },
        { id: 3, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/BlondeHair5.png' },
        { id: 4, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/BlueHair10.png' },
        { id: 5, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/CowboyHat10.png' },
        { id: 6, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Curlers10.png' },
        { id: 7, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/DandiHairstyle5.png' },
        { id: 8, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/HipsterHat8.png' },
        { id: 9, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/HolidayCap5.png' },
        { id: 10, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/PinkCowboyHat5.png' },
        { id: 11, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/ShortBlondeHair5.png' },
        { id: 12, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/ShortBlueHair5.png' },
        { id: 13, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/ShortGreenHair5.png' },
        { id: 14, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/TailHairstyle7.png' },
        { id: 15, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Black5.png' },
        { id: 16, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Fire12.png' },
        { id: 17, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Golden12.png' },
        { id: 18, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Gradient12.png' },
        { id: 19, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Green12.png' },
        { id: 20, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Grey12.png' },
        { id: 21, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/Robot12.png' },
        { id: 22, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/USD12.png' },
        { id: 23, type: 'headdres', src: '../dashboard-library/nft-generator/legendary/headdres/WhiteStars11.png' },
      ],
      eyes: [
        { id: 1, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/BigEyes15.png' },
        { id: 2, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/BlackEyes20.png' },
        { id: 3, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/BlueEyes10.png' },
        { id: 4, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/BrownEyes15.png' },
        { id: 5, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/GreenEyes25.png' },
        { id: 6, type: 'eyes', src: '../dashboard-library/nft-generator/legendary/eyes/RobotEyes15.png' },
      ],
      mouth: [
        { id: 1, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/BeardAndMustache5.png' },
        { id: 2, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/BlueVape5.png' },
        { id: 3, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/Braces10.png' },
        { id: 4, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/CalmSmile10.png' },
        { id: 5, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/Cigar5.png' },
        { id: 6, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/Cigarette5.png' },
        { id: 7, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/LegendSmile10.png' },
        { id: 8, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/Mustache5.png' },
        { id: 9, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/Pipe5.png' },
        { id: 10, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/PipeUnshaven5.png' },
        { id: 11, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/RedVape5.png' },
        { id: 12, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/TreeBranch5.png' },
        { id: 13, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/UnshavenBlueVape5.png' },
        { id: 14, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/UnshavenCigar5.png' },
        { id: 15, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/UnshavenCigarette5.png' },
        { id: 16, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/UnshavenRedVape5.png' },
        { id: 17, type: 'mouth', src: '../dashboard-library/nft-generator/legendary/mouth/UnshavenTreeBranch5.png' },
      ],
      clothes: [
        { id: 1, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/BaronSuit10.png' },
        { id: 2, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/BikerSuit10.png' },
        { id: 3, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/BlackT-Shirt10.png' },
        { id: 4, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/DiscoSuit10.png' },
        { id: 5, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/GreenCostume10.png' },
        { id: 6, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/Polo10.png' },
        { id: 7, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/RobotSuit10.png' },
        { id: 8, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/Shirt10.png' },
        { id: 9, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/SpaceSuit10.png' },
        { id: 10, type: 'clothes', src: '../dashboard-library/nft-generator/legendary/clothes/WhiteT-Shirt10.png' },
      ],
      stuff: [
        { id: 1, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Acorn10.png' },
        { id: 2, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/BlackStud10.png' },
        { id: 3, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Cheese10.png' },
        { id: 4, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/GoldenStud15.png' },
        { id: 5, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/GoldenStud_15.png' },
        { id: 6, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/NoseRing15.png' },
        { id: 7, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Rings10.png' },
        { id: 8, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/SilverRing10.png' },
        { id: 9, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/SilverRings10.png' },
        { id: 10, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Studs10.png' },
        { id: 11, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Aviator10.png' },
        { id: 12, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/BigHearts10.png' },
        { id: 13, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Black10.png' },
        { id: 14, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Blue10.png' },
        { id: 15, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/BlackStylish10.png' },
        { id: 16, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Green10.png' },
        { id: 17, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/GreenStylish10.png' },
        { id: 18, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Hearts10.png' },
        { id: 19, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Leon10.png' },
        { id: 20, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/Yellow10.png' },
        { id: 21, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/GoldenChains35.png' },
        { id: 22, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/SilverChainsDiamonds35.png' },
        { id: 23, type: 'stuff', src: '../dashboard-library/nft-generator/legendary/stuff/СhocerDiamonds30.png' },
      ],
      background: [
        { id: 1, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Beige10.png' },
        { id: 2, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/BlueViolet10.png' },
        { id: 3, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/DarkGrey10.png' },
        { id: 4, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/DarkViolet10.png' },
        { id: 5, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/GreenBlue10.png' },
        { id: 6, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Jade5.png' },
        { id: 7, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/LightPink10.png' },
        { id: 8, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Purple10.png' },
        { id: 9, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Sky5.png' },
        { id: 10, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Violet10.png' },
        { id: 11, type: 'background', src: '../dashboard-library/nft-generator/legendary/background/Yellow10.png' },
      ]
    },
  },
  LEFT_CONTENT: {
    TITLE: 'SYNTHESIZE',
    ITEMS: [
      { name: 'Body', value: 'body' },
      { name: 'Headdres', value: 'headdres' },
      { name: 'Eyes', value: 'eyes' },
      { name: 'Mouth', value: 'mouth' },
      { name: 'Clothes', value: 'clothes' },
      { name: 'Stuff', value: 'stuff' },
      { name: 'Background', value: 'background' },
    ]
  },
  SELECTED: 'Selected',
  COLOR: 'Color',
  BUTTONS: {
    RANDOM: 'Random',
    ENABLE_NFT: 'Enable NFT',
  },
  DISCLAIMERS: {
    DRAWING_BOARD: 'Currently holding Drawing board',
    AVATAR_NFT: 'Currently holding an Avatar NFT',
  },
  MODALS: {
    SELECT_CHAIN: 'SELECT CHAIN',
    SELECT_CHAINS: 'Select chains',
    SUBMIT: 'SUBMIT',
  },
  ABI: [
    'function mintWithMetadata(string memory tokenURI) returns (uint256)',
    'function getItems() view returns(string[] memory)',
  ],
  MESSAGES: {
    SOMETHING_WENT_WRONG: 'Something went wrong',
    NOT_ENOUGH_BALANCE: 'Not enough balance in your account to pay for transaction fees.',
    NFT_CREATED: 'Your NFT was successfully created',
  }
};
