import React from 'react';
import { IMAGE_LEFT_PART } from './images/leftPart';
import { IMAGE_MIDDLE_PART } from './images/middlePart';
import { IMAGE_RIGHT_PART } from './images/rightPart';
import { IMAGE_SUPPLY_PART } from './images/lendingSupplied';
import { NO_DATA_RIGHT_PART_IMG } from './images/rightImagePartNoData';

const leftImagePart = () => {
    return IMAGE_LEFT_PART;
};

const middleImagePart = () => {
    return IMAGE_MIDDLE_PART;
};

const rightImagePart = () => {
    return IMAGE_RIGHT_PART;
};

const supplyPart = () => {
    return IMAGE_SUPPLY_PART;
};

const rightImagePartNoData = () => {
    return NO_DATA_RIGHT_PART_IMG;
};

export const imagesTemplate = {
    leftImagePart,
    middleImagePart,
    rightImagePart,
    supplyPart,
    rightImagePartNoData
};
