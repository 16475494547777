import React from 'react';
import { constants } from '../../../../../constants/constants';

const { EMPTY_STATE } = constants;

export const InfoEmptyState = prop => {
    const { type } = prop;
    const title = EMPTY_STATE.TITLE;
    const key = `${type}_INFO`;
    const info = EMPTY_STATE[key];
    return <div className="widget-info-empty-state pt-3">
        <div className="widget-info-empty-state-text font-size-12 font-weight-medium">{title}</div>
        <div className="widget-info-empty-state-text font-size-12 font-weight-medium">{info}</div>
    </div>;
};
