/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Web3BalancesState } from '../../../models/web3-balances-model';
import { uiActions } from '../actions/uiActions';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { Asset } from '../../../components/utility/asset-component/view';
import { isEmpty } from 'lodash';
import { constants } from '../constants';

const { BLACK_LIST: { LABEL, BLACK_LIST_EMPTY, BLACK_LIST_MODAL_TABLE } } = constants;

const renderBlackListLayout = (list, actions, state, userWallet) => {
    const onClickHandle = (blockchainName, valueSymbol, contractAddress) => 
        actions[uiActions.actionTypes.WEB3_BALANCES_BLACKLIST_DELETE]({ value: {blockchainName, valueSymbol, contractAddress}, userWallet, state, list })

    return list.map(token => {
        const { blockchainName, contractAddress, valueSymbol } = token;
        return <div key={contractAddress} className="small-custom-table-row small-table-body font-size-14 font-weight-regular">
            <div className="custom-table-body-cell">{blockchainName}</div>
            <div className="custom-table-body-cell img-fluid rounded-start h-100 pe-1 d-flex align-items-center"><Asset asset={valueSymbol}/></div>
            <div className="custom-table-body-cell">{contractAddress}</div>
            <div className="custom-table-body-cell justify-content-end d-flex">
                <i onClick={() => onClickHandle(blockchainName, valueSymbol, contractAddress)} className="fa-light fa-xmark negative-color btn-close-cell"></i>
            </div>
        </div>;
    });
};

export const BlackListModal = forwardRef(({ userWallet }, reference) => {
    const state = Web3BalancesState.useStoreState(state => state);
    const actions = Web3BalancesState.useStoreActions(actions => actions);
    const handleClose = () => actions[uiActions.actionTypes.WEB3_BALANCES_MODAL_TOGGLED]({ type: 'close' });
    return <Modal
        size="lg"
        show={state.blackList.modal}
        centered
        container={reference}
        backdrop="static"
        className="blacklist"
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="font-size-16 mb-2">{LABEL}</div>
            {!isEmpty(state.blackList.filteredData) ? <div className='table'>
                <div className='small-custom-table'>
                    <div className="small-custom-table-row table-header">
                        {BLACK_LIST_MODAL_TABLE.map(heading => <div key={heading} className="custom-table-header-cell font-size-14 font-weight-medium">{heading}</div>)}
                    </div>
                    { renderBlackListLayout(state.blackList.filteredData, actions, state, userWallet) }
                </div>
            </div> : <h2>{BLACK_LIST_EMPTY}</h2>}

        </Modal.Body>
        <Modal.Footer>
            <ButtonCustom label='Close' size="sm" variant="default" onClick={handleClose}/>
        </Modal.Footer>
    </Modal>;
}); 