import { action, debug, thunk } from 'easy-peasy';
import { packHelper } from './dataHelpers/packHelper';
import { packsEffects } from './packsEffects';
import { v4 as uuidv4 } from 'uuid';
import Big from 'big.js';
import moment from 'moment';
import fromExponential from 'from-exponential';
import { isBoolean } from 'lodash';
import { tableHelper } from './dataHelpers/tableHelper';


const actionTypes = {
    SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED: 'SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS#CHANGED',
    SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CLICKED: 'SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS#CLICKED'
};

const setInitialState = (state, formBuy, formProfit, formLoss) => {

    if (formBuy) {
        state.input_amount_value = 0;
        state.input_trailingBy_percent = 0;
        state.input_buy_cond_limit = 0;
        state.input_total_value = 0;
        state.slider_total_value = 0;
        state.slider_trailingBy_percent = 0;
        state.toggle_trailing_by = true;
    }
    if (formProfit) {
        state.input_profit_value = 0;
        state.input_profit_percent = 0;
        state.input_profit_volume_percent = 0;
        state.input_profit_short_percent = 0;
        state.input_profit_max_price_percent = 0;
        state.slider_profit_max_price_percent = 0;
        state.slider_profit_percent = 0;
        state.slider_profit_volume_percent = 0;
        state.slider_profit_short_percent = 0;
        state.toggle_trailing_take_profit = true;
        state.tableData = [];
    }
    if (formLoss) {
        state.input_stop_loss_value = 0;
        state.input_sloss_cond_limit = 0;
        state.input_stop_loss_value_percent = 0;
        state.input_stop_loss_short_value_percent = 0;
        state.input_stop_loss_timeout_sec = 0;
        state.slider_stop_loss_value_percent = 0;
        state.slider_stop_loss_short_value_percent = 0;
        state.toggle_stop_loss_timeout = true;
        state.toggle_trailing_stop_loss = true;
        state.toggle_move_breakeven = true;
    }
};

const stateHandle = (state, payload) => {
    const { name, value } = payload;
    let valueValidated;
    const inputValue = isNaN(value) || isBoolean(value) || name === 'leverage_selected' ? 0 : Big(value).abs();

    switch (name) {
        //Sliders
    case 'slider_total_value':
        state.smartTradeBot.input_total_value = Big(state.smartTradeBot.balance_total_leveraged).times(Big(value || 0)).div('100').valueOf();
        state.smartTradeBot.input_amount_value = Big(state.smartTradeBot.input_total_value).div(Big(state.smartTradeBot.input_price_value)).valueOf();
        state.smartTradeBot.slider_total_value = value;
        break;
    case 'slider_trailingBy_percent':
        state.smartTradeBot.input_trailingBy_percent = value;
        state.smartTradeBot.slider_trailingBy_percent = value;
        break;
    case 'slider_profit_max_price_percent':
        state.smartTradeBot.input_profit_max_price_percent = value;
        state.smartTradeBot.slider_profit_max_price_percent = value;
        break;
    case 'slider_profit_percent':
        state.smartTradeBot.input_profit_percent = value * 3;
        state.smartTradeBot.slider_profit_percent = value;
        state.smartTradeBot.input_profit_value = Big(state.smartTradeBot.input_price_value).plus(Big(inputValue).times(Big('3')).times( Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'slider_profit_short_percent':
        state.smartTradeBot.input_profit_short_percent = -value;
        state.smartTradeBot.slider_profit_short_percent = value;
        state.smartTradeBot.input_profit_value = Big(state.smartTradeBot.input_price_value).minus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'slider_profit_volume_percent':
        state.smartTradeBot.input_profit_volume_percent = value;
        state.smartTradeBot.slider_profit_volume_percent = value;
        break;
    case 'slider_stop_loss_value_percent':
        state.smartTradeBot.input_stop_loss_value_percent = -value;
        state.smartTradeBot.slider_stop_loss_value_percent = value;
        state.smartTradeBot.input_stop_loss_value = Big(state.smartTradeBot.input_price_value).minus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'slider_stop_loss_short_value_percent':
        state.smartTradeBot.slider_stop_loss_short_value_percent = value;
        state.smartTradeBot.input_stop_loss_short_value_percent = value;
        state.smartTradeBot.input_stop_loss_value = Big(state.smartTradeBot.input_price_value).plus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'slider_stop_loss_timeout_sec':
        state.smartTradeBot.input_stop_loss_timeout_sec = (value || 0) * 60;
        state.smartTradeBot.slider_stop_loss_timeout_sec = value;
        break;
    case 'slider_leverage':
        valueValidated = packHelper.leverageCalculation(value);
        state.smartTradeBot.leverage = valueValidated.leverage;
        state.smartTradeBot.slider_leverage = valueValidated.value;
        state.smartTradeBot.balance_total_leveraged = Big(state.smartTradeBot.balance_total).times(Big(valueValidated.leverage)).valueOf();
        state.smartTradeBot.input_total_value = Big(state.smartTradeBot.balance_total_leveraged).times(Big(state.smartTradeBot.slider_total_value || 0)).div('100').valueOf();
        state.smartTradeBot.input_amount_value = Big(state.smartTradeBot.input_total_value).div(Big(state.smartTradeBot.input_price_value)).valueOf();
        break;
    //Sliders

    // Buy/Sell
    case 'input_amount_value':
        state.smartTradeBot.input_total_value = inputValue ? fromExponential(inputValue.times(Big(state.smartTradeBot.input_price_value)).valueOf()) : '';
        state.smartTradeBot.slider_total_value = Big(state.smartTradeBot.input_total_value || 0).div(Big(state.smartTradeBot.balance_total_leveraged)).times('100').valueOf();
        state.smartTradeBot.input_amount_value = !isNaN(value) ? Math.abs(value) : '';
        break;
    case 'input_total_value':
        state.smartTradeBot.input_amount_value = inputValue ? fromExponential(inputValue.div(Big(state.smartTradeBot.input_price_value || 1)).valueOf()) : '';
        state.smartTradeBot.slider_total_value = inputValue ? inputValue.div(Big(state.smartTradeBot.balance_total_leveraged)).times('100').valueOf() : '';
        state.smartTradeBot.input_total_value = !isNaN(value) ? Math.abs(value) : '';
        break;
    // Buy/Sell

    case 'input_profit_value':
        valueValidated = !isNaN(value) ? value : '';
        state.smartTradeBot.input_profit_value = valueValidated;
        state.smartTradeBot.input_profit_percent = (Big(valueValidated || 0).minus(Big(state.smartTradeBot.input_price_value))).div(Big(state.smartTradeBot.input_price_value)).times('100').valueOf();
        break;
    case 'input_profit_max_price_percent':
        valueValidated = !isNaN(value) ? Math.max(-Math.abs(value), -100) : '';
        state.smartTradeBot.input_profit_max_price_percent = valueValidated;
        state.smartTradeBot.slider_profit_max_price_percent = valueValidated;
        break;
    case 'input_profit_percent':
        valueValidated = !isNaN(value) ? Math.min(Math.abs(value), 300) : '';
        state.smartTradeBot.input_profit_percent = valueValidated;
        state.smartTradeBot.input_profit_value = Big(state.smartTradeBot.input_price_value).plus(Big(valueValidated || 0).times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'input_profit_short_percent':
        valueValidated = !isNaN(value) ? Math.max(-Math.abs(value), -100) : '';
        state.smartTradeBot.input_profit_short_percent = valueValidated;
        state.smartTradeBot.slider_profit_short_percent = valueValidated;
        state.smartTradeBot.input_profit_value = Big(state.smartTradeBot.input_price_value).minus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'input_profit_volume_percent':
        valueValidated = !isNaN(value) ? Math.min(Math.abs(value), state.smartTradeBot.percent_limit) : '';
        state.smartTradeBot.input_profit_volume_percent = valueValidated;
        break;
    case 'input_stop_loss_value':
        valueValidated = !isNaN(value) ? Math.min(value, state.smartTradeBot.input_price_value) : '';
        state.smartTradeBot.input_stop_loss_value = valueValidated;
        state.smartTradeBot.input_stop_loss_value_percent = (Big(valueValidated).minus(Big(state.smartTradeBot.input_price_value))).div(Big(state.smartTradeBot.input_price_value)).times('100').valueOf();
        break;
    case 'input_trailingBy_percent':
        valueValidated = !isNaN(value) ? Math.min(Math.abs(value), 100) : '';
        state.smartTradeBot.input_trailingBy_percent = valueValidated;
        state.smartTradeBot.slider_trailingBy_percent = valueValidated;
        break;

    case 'input_stop_loss_value_percent':
        valueValidated = !isNaN(value) ? Math.max(-Math.abs(value), -100) : '';
        state.smartTradeBot.input_stop_loss_value_percent = valueValidated;
        state.smartTradeBot.slider_stop_loss_value_percent = valueValidated;
        state.smartTradeBot.input_stop_loss_value = Big(state.smartTradeBot.input_price_value).minus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'input_stop_loss_short_value_percent':
        valueValidated = !isNaN(value) ? Math.min(Math.abs(value), 100) : '';
        state.smartTradeBot.input_stop_loss_short_value_percent = valueValidated;
        state.smartTradeBot.slider_stop_loss_short_value_percent = valueValidated;
        state.smartTradeBot.input_stop_loss_value = Big(state.smartTradeBot.input_price_value).plus(inputValue.times(Big(state.smartTradeBot.input_price_value)).div('100')).valueOf();
        break;
    case 'input_stop_loss_timeout_sec':
        valueValidated = !isNaN(value) ? Math.min(value, 6000) : '';
        state.smartTradeBot.input_stop_loss_timeout_sec = valueValidated;
        state.smartTradeBot.slider_stop_loss_timeout_sec = valueValidated || 0;
        break;
    case 'input_buy_cond_limit':
        valueValidated = !isNaN(value) ? value : '';
        state.smartTradeBot.input_buy_cond_limit = valueValidated;
        break;
    case 'input_sloss_cond_limit':
        valueValidated = !isNaN(value) ? value : '';
        state.smartTradeBot.input_sloss_cond_limit = valueValidated;
        break;
     //////grid profit//////
   case 'input_upper_limit_price':
        state.smartTradeBot.input_upper_limit_price = value;
        break;
    case 'input_upper_stop_lose':
        state.smartTradeBot.input_upper_stop_lose = value;
        break;
    case 'input_lower_limit_price':
        state.smartTradeBot.input_lower_limit_price = value;
        break;
    case 'input_lower_stop_lose':
        state.smartTradeBot.input_lower_stop_lose = value;
        break;
    case 'input_grid_quantity':
        state.smartTradeBot.input_grid_quantity = value;
        break;
    case 'input_active_orders':
        state.smartTradeBot.input_active_orders = value;
        break;
    case 'input_quantity_per_grid':
        state.smartTradeBot.input_quantity_per_grid = value;
        break;
    case 'input_amount_for_bot_usage':
        state.smartTradeBot.input_amount_for_bot_usage = value;
        break;
    /////cost average////////
    case 'input_buy_for':
        state.smartTradeBot.input_buy_for = value;
        break;
    case 'input_days_number':
        state.smartTradeBot.input_days_number = value;
        break;
    case 'input_pick_date':
         state.smartTradeBot.input_pick_date = value ? moment(value).format('YYYY-MM-DD') : '';
        break;

    case 'split-action':
        state.smartTradeBot.profit_form = !state.smartTradeBot.profit_form;
        break;
    case 'cancel-action':
        state.smartTradeBot.profit_form = false;
        state.smartTradeBot.takeProfitData = [];
        state.smartTradeBot.tableData = [];
        state.smartTradeBot.input_profit_percent = '';
        state.smartTradeBot.input_profit_volume_percent = '';
        state.smartTradeBot.input_profit_value = state.smartTradeBot.input_price_value;
        state.smartTradeBot.input_profit_max_price_percent = '';
        payload?.target?.attributes?.action?.value !== 'button-click' ? state.smartTradeBot.input_amount_value = '' : '';
        break;
    case 'add-tp-action':
        const uuid = uuidv4();
        const elm = packHelper.generateTPelement(state.smartTradeBot);
        state.smartTradeBot.takeProfitData = [...state.smartTradeBot.takeProfitData, elm];
        state.smartTradeBot.tableData = [...state.smartTradeBot.tableData, {...packHelper.tableRowGenerate(state.smartTradeBot), uuid}];
        const limit = Big(state.smartTradeBot.percent_limit).minus(Big(elm.quantityPercentage));
        state.smartTradeBot.percent_limit = limit.gt(0) ? limit.valueOf() : 0;
        break;

    case 'delete-row':
        state.smartTradeBot.tableData = state.smartTradeBot.tableData.filter( el => el.uuid !== payload.uuid);
        state.smartTradeBot.takeProfitData = state.smartTradeBot.takeProfitData.filter( el => el.uuid !== payload.uuid);
        break;
    case 'button_buy_limit':
        state.smartTradeBot.button_buy_limit = true;
        state.smartTradeBot.button_buy_market = false;
        state.smartTradeBot.button_buy_cond = false;
        break;
    case 'button_buy_market':
        state.smartTradeBot.button_buy_limit = false;
        state.smartTradeBot.button_buy_market = true;
        state.smartTradeBot.button_buy_cond = false;
        state.smartTradeBot.input_price_value = state.smartTradeBot.price_value;

        break;
    case 'button_buy_cond':
        state.smartTradeBot.button_buy_limit = false;
        state.smartTradeBot.button_buy_market = false;
        state.smartTradeBot.button_buy_cond = true;
        break;
    case 'button_buy_cond_limit':
        state.smartTradeBot.button_buy_cond_limit = true;
        state.smartTradeBot.button_buy_cond_market = false;
        state.smartTradeBot.input_buy_cond_limit = state.smartTradeBot.price_value;
        break;
    case 'button_buy_cond_market':
        state.smartTradeBot.button_buy_cond_limit = false;
        state.smartTradeBot.button_buy_cond_market = true;
        break;
    case 'button_ttp_limit':
        state.smartTradeBot.button_ttp_limit = true;
        state.smartTradeBot.button_ttp_market = false;
        break;
    case 'button_ttp_market':
        state.smartTradeBot.button_ttp_limit = false;
        state.smartTradeBot.button_ttp_market = true;
        state.smartTradeBot.input_profit_value = state.smartTradeBot.price_value;
        break;
    case 'button_sloss_limit':
        state.smartTradeBot.button_sloss_limit= true;
        state.smartTradeBot.button_sloss_market = false;
        state.smartTradeBot.input_sloss_cond_limit = state.smartTradeBot.price_value;
        break;
    case 'button_sloss_market':
        state.smartTradeBot.button_sloss_limit= false;
        state.smartTradeBot.button_sloss_market = true;
        break;

    case 'form_state_pro_trade':
        state.smartTradeBot.form_state_pro_trade = true;
        state.smartTradeBot.form_state_buy_sell = false;
        state.smartTradeBot.form_state_pro_short = false;
        state.smartTradeBot.formType = name;
        setInitialState(state.smartTradeBot, true, true, true);
        break;
    case 'form_state_buy_sell':
        state.smartTradeBot.form_state_pro_trade = false;
        state.smartTradeBot.form_state_buy_sell = true;
        state.smartTradeBot.form_state_pro_short = false;
        state.smartTradeBot.formType = name;
        setInitialState(state.smartTradeBot, true, true, true);
        break;
    case 'form_state_pro_short':
        state.smartTradeBot.form_state_pro_trade = false;
        state.smartTradeBot.form_state_buy_sell = false;
        state.smartTradeBot.form_state_pro_short = true;
        state.smartTradeBot.formType = name;
        setInitialState(state.smartTradeBot, true, true, true);
        break;
    case 'button_table_active':
        state.tableData.viewType = {
            active: true,
            history: false
        };
        break;
    case 'button_table_history':
        state.tableData.viewType = {
            active: false,
            history: true
        };
        break;
     case 'button_table_orders': 
         state.tableData.viewTypeOrders = {
            orders: true,
            positions: false,
         }
         break;
     case "button_table_positions":
          state.tableData.viewTypeOrders = {
            orders: false,
            positions: true
          }
          break;            
    case 'button_buy_sell_buy':
        state.smartTradeBot.buySellFormType = 'buy';
        break;
    case 'button_buy_sell_sell':
        state.smartTradeBot.buySellFormType = 'sell';
        break;
    case 'table_data_modify':
        state.tableData.rowsData = tableHelper.dataModify(payload?.botData, state.tableData.viewType);
        break;
    case 'leverage_selected':
        state.smartTradeBot.marginType = value;
        break;
    case 'toggle_trailing_by':
        state.smartTradeBot.input_trailingBy_percent = 0;
        state.smartTradeBot.toggle_trailing_by = value;
        break;
    case 'toggle_trailing_take_profit':
        state.smartTradeBot.toggle_trailing_take_profit = value;
        state.smartTradeBot.input_profit_max_price_percent = 0;
        break;
    case 'toggle_stop_loss_timeout':
        state.smartTradeBot.toggle_stop_loss_timeout = value;
        state.smartTradeBot.input_stop_loss_timeout_sec = 0;
        break;
    case 'toggle_take_profit':
        state.smartTradeBot.toggle_take_profit = !state.smartTradeBot.toggle_take_profit;
        state.smartTradeBot.toggle_stop_loss = !state.smartTradeBot.toggle_take_profit ? false : state.smartTradeBot.toggle_stop_loss;
        break;


    default:  state.smartTradeBot[name] = value;
    }

};

const clickActions = (actions, payload) => {
    const {name} = payload;
    switch (name) {
    case 'submit-action':
        const request = packHelper.botCreate(payload);
         actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SAVE_INVOKED]({ ...request });
        break;
    case 'split-action':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'cancel-action':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'add-tp-action':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_limit':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_market':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_cond':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_cond_limit':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_cond_market':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_ttp_limit':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_ttp_market':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_sloss_limit':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_sloss_market':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_sell_buy':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_buy_sell_sell':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'form_state_pro_trade':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'form_state_buy_sell':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'form_state_pro_short':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_table_active':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_table_history':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_table_orders':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    case 'button_table_positions':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED](payload);
        break;
    }


    switch (payload?.target?.attributes?.name?.value) {
    case 'delete-row':
        actions[actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED]({ ...payload, name: payload.target.attributes.name.value, uuid: payload.target.attributes.uuid.value });
        break;
    }

};

const actionHandlers = {
    [actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CHANGED]: action((state, payload) => stateHandle(state, payload)),
    [actionTypes.SMART_TRADE_BOT_SETTINGS_FORM_ELEMENTS_CLICKED]: thunk((actions, payload) => clickActions(actions, payload))
};

export const smartTradeBotSettingsEffects = {
    actionTypes,
    actionHandlers
};