import React from "react";
import "./styles.scss";
import { ConnectedPlatformsModel } from "../../models/connected-platforms-model";
import CustomScroll from "react-custom-scroll";
import { MyExchanges } from "./templates/exchangesTemplate";

const Layout = () => {
  return (
    <div className="profile-page ps-3 pe-3">
      <div className="exchanges-content p-3">
        <MyExchanges />
      </div>
    </div>
  );
};

export const ConnectedPlatformPage = () => {
  return (
    <ConnectedPlatformsModel.Provider>
      <CustomScroll
        heightRelativeToParent="100%"
        className="custom-scroll-bar main-content-scroll"
        addScrolledClass={true}
      >
        <Layout />
      </CustomScroll>
    </ConnectedPlatformsModel.Provider>
  );
};
