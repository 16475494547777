import { constants } from '../../constants/constants';
import { service } from '../../service/setInterceptor';
import { RootContent } from '../rootContent/view';
import { delay, isEmpty } from 'lodash';
import { Loader } from '../utility/loader/view';
import { StaticPageLayout } from '../../pages/homePage/view';
import { Login } from '../../pages/loginPage/view';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useRef } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';
import { authorizationEffect } from '../metaMaskAthorization/actions/authorizationEffect';
import { Route, Switch } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

const { INITIAL_PATH, NFT_GENERATOR_PATH } = constants;

const renderInnerContent = (user, isInterceptorSet) => {
    !isInterceptorSet ? service.setInterceptor(user) : '';
    return <RootContent />
};

const replaceActionDelay = (method, path) => {
    delay(method, 500, path, path);
    return <Loader size="6x" classOption="authorisation-loader" />;
};

const routeParser = (data, user, isUserLogged, isInterceptorSet) => {
    const { history: {replace}, match } = data;
    const { url } = match;
    const plansSubscription = reactLocalStorage.get('plans_subscription');
    switch(url) {
    case '/':  replaceActionDelay(replace, '/home'); break;
    // case '/home': return isUserLogged ? replaceActionDelay(replace, INITIAL_PATH) : <StaticPageLayout/>;
    case '/home': return isUserLogged ? replaceActionDelay(replace, !plansSubscription ? INITIAL_PATH : NFT_GENERATOR_PATH) : <StaticPageLayout/>;
    case '/login': return isUserLogged ? replaceActionDelay(replace, INITIAL_PATH) : <Login/>;
    default:  return isUserLogged ? renderInnerContent(user, isInterceptorSet) : replaceActionDelay(replace, '/home');
    }
}

export const Root = () => {
    const state = useStoreState(state => state.authentication);
    const isLoading = state.loader;
    const isUserLogged = Boolean(state.user && !isEmpty(state.userInfo));
    const onboarding = useRef(null);
    const actions = useStoreActions(actions => actions);
    const isInterceptorSet = state.interceptorIsSet;


    useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
        actions[authorizationEffect.actionTypes.AUTHORIZE_COGNITO_CONFIGURED]();
    }, [])

    return isLoading ?
        <Loader size="6x" classOption="authorisation-loader" /> :
        <Switch>
            <Route path="*" children={ data => routeParser(data, state.user, isUserLogged, isInterceptorSet) }/>
        </Switch>
};
