import React, { useRef, useEffect } from 'react';
import './styles.scss';
import { chartHelper } from './chartHelper';
import { renderControlPanel } from './templates/controlPanelTemplate';
import { EmptyStateModal } from '../../../../components/utility/empty-state-modal/view';
import { withResizeDetector } from 'react-resize-detector';
import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { emptyState } from './templates/emptyStateModalTemplate';
import { Loader } from '../../../../components/utility/loader/view';
import moment from 'moment';

const Content = () => {
    const svgRef = useRef();
    const barChartContainer = useRef();
    const state = useStoreState(state => state.barChartWidget);
    const loaders = useStoreState(state => state.loaders);

    const emptyStateContent = emptyState.emptyStateContent();
    const isLoading = loaders.history;
    const exchangesList = state.exchangesList;
    const currentExchangeId = state.currentExchange.id;
    const currentTypeId = state.currentType.id;
    const data = !isEmpty(state.data) && currentExchangeId && currentTypeId ?
        state.data[currentExchangeId][currentTypeId] :
        [];

    const correctedData = data.map(e => ({
        category: currentTypeId === 'daily' ? moment(e.category).format('D') : e.category,
        BTC: e.BTC || 0,
        USDT: e.USDT || 0,
    }));

    useEffect(() => chartHelper.drawChart(svgRef, correctedData));

    return <div className="bar-chart-widget-element">
        <div className="bar-chart-widget-controls pt-3 pb-3">
            { renderControlPanel(state, isLoading) }
        </div>
        <div ref={barChartContainer} className="bar-chart-widget-container position-relative">
            { isLoading ? <Loader size={'6x'} isLoading /> : <div ref={svgRef} className="position-relative h-100 w-100"></div> }
            { isEmpty(exchangesList) && !isLoading ? <EmptyStateModal ref={barChartContainer} content={emptyStateContent}/> : null}
        </div>
    </div>;
};

export const BarChart = withResizeDetector(Content);

