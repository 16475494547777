import React, { useState, useEffect } from "react";
//import { constants } from "../constants";
import { constants } from "../../../constants/constants";
import { DoughnutChart } from "../../../components/utility/chart-doughnut/view";
import { uiActions } from "../actions/uiActions";
import { AnalyticsModal } from "../../defiMultiChainDashboard/templates/analyticsModal";
import { dataHelper } from "../dataHelpers/dataHelper";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { ButtonCustom } from "../../../components/utility/custom-buttons/view";
import { useStoreState } from "easy-peasy";
import { lendingEffects } from "../../dashboardLending/actions/lendingEffects";
import { MultiplyCustomSelector } from "../../../components/utility/multiply-selector/view";

const {
  ANALYTICS: { SHOW_ALL, TOKEN, PROTOCOL, CHAIN },
  ACCOUNT,
  DONAT_CHART: { COLORS },
} = constants;

export const AnalyticsContent = (props) => {
  const { actions, mockData, state, pageId } = props;
  const [balance, setBalance] = useState();
  const [investmentType, setinvestmentType] = useState("Supplied");
  const location = useLocation();
  const venus = new Venus(window.ethereum);
  let venusBalance ;
  const setSupplyProtocols = () => {
    let protocolsArr = [
      { protocolName: "Venus", supplyBalance: state.venusSupplyBalance },
      { protocolName: "Compaund", supplyBalance: state.compaundSupplyBalance },
    ];

    return protocolsArr;
  };
  const setBorrowProtocols = () => {
    let protocolsArr = [
      { protocolName: "Venus", borrowBalance: state.venusBorrowBalance },
      { protocolName: "Compaund", borrowBalance: state.compaundBorrowBalance },
    ];

    return protocolsArr;
  };

  // get and format supply for first chart
  let tokenAllocation = dataHelper.getTokensFraction(
    //merged all supply lists for both protocols
    dataHelper.mergedAllInvestmentsType(
      state.venusTableData.supplyUser,
      state.compoundTableData.supplyUser
    )
  );

  let tokenBorrowAllocation = dataHelper.getBorrowTokensFraction(
    //merged all supply lists for both protocols
    dataHelper.mergedAllInvestmentsType(
      state.venusTableData.borrowUser,
      state.compoundTableData.borrowUser
    )
  );
  //starting supply values that is shown token allocation in first render
  let chartAllocationStartValue = [
    ...tokenAllocation.slice(0, 4),
    {
      asset: "Others",
      fraction: dataHelper.getOthersFraction(tokenAllocation),
    },
  ];
  //  get and format supply for second chart
  let protocolAllocation = dataHelper.getProtocolsSupplyFraction(
    setSupplyProtocols()
  );
  //get and format borrow for second chart
  let protocolBorrowAllocation = dataHelper.getProtocolsBorrowFraction(
    setBorrowProtocols()
  );
  //starting supply  values that is shown protocol allocation in first render
  let chartProtocolAllocationStartValue = [
    ...protocolAllocation.slice(0, 4),
    {
      asset: "Others",
      fraction: dataHelper.getOthersFraction(protocolAllocation),
    },
  ];
  //use state for first chart
  const [chartTokenAllocation, setChartTokenAllocation] = useState(
    chartAllocationStartValue
  );
  //use state for second chart
  const [chartProtocolAllocation, setchartProtocolAllocation] = useState(
    chartProtocolAllocationStartValue
  );
  const modalOpen = (name, data, heading) =>
    actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({
      name,
      data,
      heading,
    });
  const parsedInvestentTypeList = dataHelper.parseList(state.investmentType);

 
  const onChangeHandle = (v) => {
    if (v.label === "Supplied") {
      setinvestmentType("Supplied");
    } else {
      setinvestmentType("Borrowed");
    }
  };

  useEffect(() => {
    if (investmentType === "Supplied") {
      tokenAllocation = dataHelper.getTokensFraction(
        dataHelper.mergedAllInvestmentsType(
          state.venusTableData.supplyUser,
          state.compoundTableData.supplyUser
        )
      );
      protocolAllocation = dataHelper.getProtocolsSupplyFraction(
        setSupplyProtocols()
      );

      setChartTokenAllocation([
        ...tokenAllocation.slice(0, 4),
        {
          asset: "Others",
          fraction: dataHelper.getOthersFraction(tokenAllocation),
        },
      ]);

      setchartProtocolAllocation([
        ...protocolAllocation.slice(0, 4),
        {
          asset: "Others",
          fraction: dataHelper.getOthersFraction(protocolAllocation),
        },
      ]);
    } else {
      tokenBorrowAllocation = dataHelper.getBorrowedTokensFraction(
        dataHelper.mergedAllInvestmentsType(
          state.venusTableData.borrowUser,
          state.compoundTableData.borrowUser
        )
      );
      protocolBorrowAllocation = dataHelper.getProtocolsBorrowFraction(
        setBorrowProtocols()
      );
      setChartTokenAllocation([
        ...tokenBorrowAllocation.slice(0, 4),
        {
          asset: "Others",
          fraction: dataHelper.getOthersFraction(tokenBorrowAllocation),
        },
      ]);
      setchartProtocolAllocation([
        ...protocolBorrowAllocation.slice(0, 4),
        {
          asset: "Others",
          fraction: dataHelper.getOthersFraction(protocolBorrowAllocation),
        },
      ]);
    }
  }, [investmentType]);

  const calculateXVSBalanceToUsd = async (venusValue) => {
    const price = await venusValue.getPrice(Venus.XVS);
    let totalToCollect = Number(state.rewardXVS) * price;
    setBalance(totalToCollect.toFixed(2));
  };

  // console.log("state: ", state);
  calculateXVSBalanceToUsd(venus);
  venusBalance = <div className="value">~ ${balance}</div>;
  const handleHarvestAll = (venusValue) => {
    actions[lendingEffects.actionTypes.LENDING_COLLECT_INVOKED]({ venusValue });
  };

  const renderChartTokenAllocation = (list) => {
    return (
      <>
        <div className="analytics-chart d-flex align-items-center">
          <DoughnutChart data={list} />
        </div>
        <div className="analytics-chart-legend">
          {list.map((protocol, index) => (
            <div key={index} className="legend-item">
              <div className="d-flex align-items-center title-wrapper">
                <div
                  style={{ background: COLORS[index] }}
                  className="color-circle me-1"
                ></div>
                <div className="legend-value">{protocol.asset}</div>
              </div>
              <div className="protocol-value font-weight-medium">
                {protocol.fraction}%
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="analytics-content">
      <div className="analytics-item">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">{TOKEN.HEADING}</div>
          {!isEmpty(tokenAllocation) ? (
            <div
              className="show-all font-size-14"
              onClick={() =>
                modalOpen("open-modal", tokenAllocation, TOKEN.HEADING)
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {chartTokenAllocation[0].asset === "Others" ? (
            <>{renderChartTokenAllocation(chartAllocationStartValue)}</>
          ) : (
            <>{renderChartTokenAllocation(chartTokenAllocation)}</>
          )}
        </div>
        <div className="dashboard-lending-layout-analytics-controls">
          <div>{"Change Investment Type"}:</div>
          <div className="multiple-selector me-3">
            <MultiplyCustomSelector
              options={parsedInvestentTypeList}
              onChangeHandle={onChangeHandle}
              closeMenuOnSelect={true}
              isDisabled={parsedInvestentTypeList.isDisabled}
              isMulti={false}
              placeholder={"Supplied"}
              isLoading={parsedInvestentTypeList.isLoading}
              value={parsedInvestentTypeList.label}
            />
          </div>
        </div>
      </div>

      <div className="analytics-item align-items-center">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">
            {PROTOCOL.HEADING.toUpperCase()}
          </div>
          {!isEmpty(tokenAllocation) ? (
            <div
              className="show-all font-size-14"
              onClick={() =>
                modalOpen(
                  "open-modal",
                  mockData.protocolSelector.list,
                  PROTOCOL.HEADING
                )
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {chartTokenAllocation[0].asset === "Others" ? (
            <>{renderChartTokenAllocation(chartProtocolAllocationStartValue)}</>
          ) : (
            <>{renderChartTokenAllocation(chartProtocolAllocation)}</>
          )}
        </div>
      </div>

      <div className="analytics-item align-items-center ">
        <div className="header-screens">
          <div className="heading-wrapper">
            <div className="heading"> {ACCOUNT.HEADING} </div>
            <div className="select-account">Select account</div>
          </div>
          {location.search === "?elem=lending" ? (
            <div className="precent">{state.netApy}</div>
          ) : (
            <div className="precent">3.31%</div>
          )}
          <div className="precentDescription">{ACCOUNT.NET_APY}</div>
          <div className="values-wrapper">
            <div className="value-wrapper">
              {location.search === "?elem=lending" ? (
                <div className="value">${state.dailyEarnings}</div>
              ) : (
                <div className="value">3.31%</div>
              )}
              <div className="description">{ACCOUNT.DAILY_SUMMARY}</div>
            </div>
            <div className="value-wrapper">
              {location.search === "?elem=lending" ? (
                <div className="value"> ${state.supplyBalance} </div>
              ) : (
                showPage
              )}

              <div className="description">{ACCOUNT.INVESTED_BALANCE}</div>
            </div>
            {/* <div className="value-wrapper">
                  <div className="value">$5.51</div>
                  <div className="description">{ACCOUNT.CUMULATIVE_APY}</div>
                </div> */}
          </div>

          <div className="harvest-all-wrapper">
            <div className="value-wrapper">
              {venusBalance}
              <div className="description">to collect</div>
            </div>
            <div className="button-widget">
              {" "}
              <ButtonCustom
                disabled={!state.tableData.supplyUser.length}
                variant="primary"
                label="Harvest all"
                onClick={() => handleHarvestAll(venus)}
              />
            </div>
          </div>
        </div>
      </div>

      {location.search === "?elem=defidashboard" ? (
        <AnalyticsModal
          show={mockData.modalChart.show}
          actions={actions}
          data={mockData.analytics.analyticsData}
        />
      ) : (
        <AnalyticsModal
          show={mockData.modalChart.show}
          actions={actions}
          data={mockData.analytics.analyticsData}
        />
      )}
    </div>
  );
};
