import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const CustomTooltip = (props) => {
    const { trigger, content } = props;

    return (
        <>
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id='tooltip-top'>
                        {content}
                    </Tooltip>
                }
            >
                {trigger}
            </OverlayTrigger>
        </>
    );
};
