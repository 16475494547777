import React from 'react';
import './styles.scss';
import { renderControlPanel } from './templates/controlPanel';
import { constants } from '../../../../constants/constants';
import { FeedWidget } from '../feed-widget/view';
import { SignalWidget } from '../signal-widget/view';
import CustomScroll from 'react-custom-scroll';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Bots } from '../bots-widget/view';

const { WIDGET_INFO } = constants;
const { FEED_LABEL, SIGNAL_LABEL } = WIDGET_INFO;

const searchHandle = (e, _) => {
    const value = e.target.value;
    _.updateState({
        path: 'signal.searchValue',
        value: value,
        operation: 'set'
    });
};

const resetHandle = _ => {
    _.updateState({
        path: 'signal.searchValue',
        value: '',
        operation: 'set'
    });
};

export const Infos = () => {
    const loaders = useStoreState(state => state.loaders);
    const signalsState = useStoreState(state => state.signal);
    const actions = useStoreActions(actions => actions);
    const isLoadingFeed = loaders.feeds;
    const isLoadingSignals = loaders.signals;

   

    return <div className="widget-info-element h-100">
        <div className="grid pt-3 h-100 w-100">
            {/* <div className="feed-column pe-3 ps-3 h-100">
                {renderControlPanel(FEED_LABEL, 'feeds', isLoadingFeed)}
                <div className="feed-content pt-3 pb-3">
                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <FeedWidget/>
                    </CustomScroll>
                </div>
            </div> */}
            <Bots title={'Open Trades'}/>
            <div className="vertical-divider h-100"></div>
            <div className="signal-column pe-3 ps-2 h-100">

                {renderControlPanel(SIGNAL_LABEL, 'signals', isLoadingSignals)}
                <div className="signal-content pt-3 pb-3">
                    <InputGroup className="mb-3 signal-search-component">
                        <FormControl
                            className="signal-search"
                            onChange={(e) => searchHandle(e, actions)}
                            placeholder="Search..."
                            value={signalsState.searchValue}
                            size="sm"/>
                        { signalsState.searchValue ? <InputGroup.Text>
                            <FontAwesomeIcon className="cursor-pointer reset-icon" icon={faTimes} onClick={() => resetHandle(actions)}/>
                        </InputGroup.Text> : null}
                    </InputGroup>
                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <SignalWidget/>
                    </CustomScroll>
                </div>
            </div>
        </div>
    </div>;
};