import { action, debug, thunk } from 'easy-peasy';
import { isEmpty } from 'lodash';
import { dataHelper } from './dataHelpers/dataHelper';
import Amplify, { Auth } from 'aws-amplify';
import { reactLocalStorage } from 'reactjs-localstorage';
import { constants } from '../../../constants/constants';
import { metaMaskConfiguration } from '../../../service/metaMaskConfiguration';

const { config } = metaMaskConfiguration;
const { AUTH_ERRORS: { COUNTER_ERROR, ACCOUNT_ERROR } } = constants;

const actionTypes = {
    WALLET_CONNECT_AUTHORIZE_SIGN_IN_INVOKED: 'WALLET_CONNECT_AUTHORIZE_SIGN_IN#INVOKED',
    WALLET_CONNECT_AUTHORIZE_SIGN_UP_INVOKED: 'WALLET_CONNECT_AUTHORIZE_SIGN_UP#INVOKED',
    WALLET_CONNECT_AUTHORIZE_SIGN_IN_SUCCEED: 'WALLET_CONNECT_AUTHORIZE_SIGN_IN#SUCCEED',
    WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED: 'WALLET_CONNECT_AUTHORIZE_UI_ACTIONS#INVOKED',
    WALLET_CONNECT_AUTHORIZE_LOG_OUT_INVOKED: 'WALLET_CONNECT_AUTHORIZE_LOG_OUT#INVOKED',
    WALLET_CONNECT_AUTHORIZE_CHECK_USER_AUTHORIZED: 'WALLET_CONNECT_AUTHORIZE_CHECK_USER#AUTHORIZED',
    WALLET_CONNECT_AUTHORIZE_COGNITO_CONFIGURED: 'WALLET_CONNECT_AUTHORIZE_COGNITO#CONFIGURED',
    WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION_SAVED: 'WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION#SAVED',
    WALLET_CONNECT_AUTHORIZE_VISIBLE_USER_DATA_SAVED: 'WALLET_CONNECT_AUTHORIZE_VISIBLE_USER_DATA#SAVED'
};

const signInInvoke = async (actions, payload) => {
    const { counter, connector } = payload;
    const updatedCounter = counter - 1;
    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ counter: updatedCounter, error: '', loaderWalletConnect: true, authenticationMethod: 'walletConnect' });

    await connector.connect().catch(err => console.info(err.message));

    const accounts = connector.accounts;

    if (!updatedCounter || isEmpty(accounts)) {
        const message = !updatedCounter ? COUNTER_ERROR : ACCOUNT_ERROR;
        actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ error: message });
        return;
    }

    const address = dataHelper.createAddress(accounts[0].toLowerCase());

    await Auth.signIn(address)
        .then(cognitoUser => 
            cognitoUser ?
                actions[actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_IN_SUCCEED]({ cognitoUser, address, connector }) :
                actions[actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_UP_INVOKED]({ address, counter: updatedCounter, connector }))
        .catch(error => actions[actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_UP_INVOKED]({ error, address, counter: updatedCounter, connector }));
};

const signUpInvoke = async (actions, payload) => {
    //If signIn is failed we will try to signUp
    const { address, counter, connector } = payload;
    const data = dataHelper.createSignUpData(address);
    await Auth.signUp(data)
        .then(() => actions[actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_IN_INVOKED]({ counter, connector }))
        .catch(a => console.info(a.message)); //TODO add handler
};

const signInSuccessHandle = async (actions, payload) => {
    const { cognitoUser, address, connector } = payload;
    const messageToSign = cognitoUser.challengeParam.message;
    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ connectionButton: false });

    const signature = await connector
        .signPersonalMessage([messageToSign, address.account])
        .catch(() => {
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ connectionButton: true, loaderWalletConnect: false });
        });

    if (!signature)
        return;

    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ loaderLogin: true });
    await Auth.sendCustomChallengeAnswer(cognitoUser, signature)
        .then(user => {
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION_SAVED]({ user });
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_VISIBLE_USER_DATA_SAVED]({ userInfo: user });
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ user });
        })
        .catch(() => actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ connectionButton: true, loaderLogin: false }));

    const userInfo = await Auth.currentUserInfo();
    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ userInfo, loaderLogin: false });
};

const logOutHandle = async (actions, payload) => {
    const { reload } = payload;
    const connector = dataHelper.getConnector();
    connector.killSession();
    actions ? actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ loader: true }) : '';
    await Auth.signOut()
        .finally(() => reload ? location.reload() : '');
};

const uiActionsHandle = (state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach(k => state.authentication[k] = payload[k]);
};

const authorizationHandle = async (actions) => {
    // actions[actionTypes.WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION_SAVED]({loader: true})
    const userInfo = await Auth.currentUserInfo()
        .catch(error => console.info(error.message));


    if (isEmpty(userInfo)) {
        actions[actionTypes.WALLET_CONNECT_AUTHORIZE_LOG_OUT_INVOKED]({ reload: false }); //If user not exist clean all data in local storage
        actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ user: null, userInfo: {}, loader: false });
        return;
    }

    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ userInfo });
    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_VISIBLE_USER_DATA_SAVED]({ userInfo });

    await Auth.currentAuthenticatedUser()
        .then(response => {
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ user: response, loader: false });
            actions[actionTypes.WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION_SAVED]({ user: response });
        })
        .catch(() => actions[actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]({ user: null }));
};

const configurationHandle = (actions, payload) => {
    reactLocalStorage.remove('token_expired_time');
    Amplify.configure({ Auth: { ...config } });
    actions[actionTypes.WALLET_CONNECT_AUTHORIZE_CHECK_USER_AUTHORIZED]();
};

const tokenHandle = payload => {
    const { user } = payload;
    const expired_time = user?.signInUserSession?.idToken?.payload?.exp || 0;
    reactLocalStorage.set('token_expired_time', expired_time * 1000);
};

const createUserVisibleData = (state, payload) => {
    const { userInfo: { attributes } } = payload;
    const key = attributes['custom:web3address'];
    state.authentication.userData = dataHelper.createUserData(key || '');
};

const userInfo = async () => await Auth.currentUserInfo();

const actionHandlers = {
    [actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_IN_INVOKED]: thunk(async (action, payload) => signInInvoke(action, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_UP_INVOKED]: thunk(async (action, payload) => signUpInvoke(action, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_SIGN_IN_SUCCEED]: thunk(async (action, payload) => signInSuccessHandle(action, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_LOG_OUT_INVOKED]: thunk(async (action, payload) => logOutHandle(action, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_CHECK_USER_AUTHORIZED]: thunk(async (action, payload) => authorizationHandle(action, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_COGNITO_CONFIGURED]: thunk((action) => configurationHandle(action)),

    [actionTypes.WALLET_CONNECT_AUTHORIZE_UI_ACTIONS_INVOKED]: action((state, payload) => uiActionsHandle(state, payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_TOKEN_EXPIRATION_SAVED]: action((state, payload) => tokenHandle(payload)),
    [actionTypes.WALLET_CONNECT_AUTHORIZE_VISIBLE_USER_DATA_SAVED]: action((state, payload) => createUserVisibleData(state, payload)),

};

export const walletConnectAuthorizationEffect = {
    actionTypes,
    actionHandlers,
    logOutHandle,
    userInfo
};