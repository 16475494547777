/* eslint-disable max-len */
/* eslintDisable max-len */
/* eslintDisable maxLen */
import React from 'react';

export const IMAGE_SUPPLY_PART = <><g opacity="0.5" filter="url(#filter0_d_7054_95727)">
    <path d="M16 28C16 19.1634 23.1634 12 32 12H591C599.836 12 607 19.1634 607 28V205C607 213.837 599.837 221 591 221H32C23.1635 221 16 213.837 16 205V28Z" fill="#1E1F23"/>
</g>
<path d="M121.625 181.995C149.584 181.995 172.249 159.389 172.249 131.502C172.249 103.615 149.584 81.0078 121.625 81.0078C93.6655 81.0078 71 103.615 71 131.502C71 159.389 93.6655 181.995 121.625 181.995Z" stroke="url(#paint0_linear_7054_95727)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
<path d="M122.066 193.407C156.344 193.407 184.132 165.691 184.132 131.502C184.132 97.3123 156.344 69.5962 122.066 69.5962C87.7879 69.5962 60 97.3123 60 131.502C60 165.691 87.7879 193.407 122.066 193.407Z" stroke="url(#paint1_linear_7054_95727)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
<path d="M78.9675 204.851L108.278 162.332" stroke="url(#paint2_linear_7054_95727)" strokeMiterlimit="10"/>
<path d="M130.328 159.997L157.692 203.787H224.926" stroke="url(#paint3_linear_7054_95727)" strokeMiterlimit="10"/>
<path d="M474.43 181.995C502.344 181.995 524.973 159.389 524.973 131.502C524.973 103.615 502.344 81.0078 474.43 81.0078C446.516 81.0078 423.887 103.615 423.887 131.502C423.887 159.389 446.516 181.995 474.43 181.995Z" stroke="url(#paint4_linear_7054_95727)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
<path d="M415.039 148.845C424.49 181.79 458.747 200.843 491.554 191.401C524.36 181.959 543.294 147.597 533.842 114.652C524.391 81.7073 490.134 62.6543 457.328 72.0963C424.521 81.5384 405.588 115.9 415.039 148.845Z" stroke="url(#paint5_linear_7054_95727)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
<path d="M431.233 204.851L460.543 162.332" stroke="url(#paint6_linear_7054_95727)" strokeMiterlimit="10"/>
<path d="M482.594 159.997L509.957 203.787H577.192" stroke="url(#paint7_linear_7054_95727)" strokeMiterlimit="10"/>
<defs>
    <linearGradient id="paint0_linear_7054_95727" x1="98.6108" y1="84.7903" x2="144.548" y2="178.348" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#0FA0FF"/>
        <stop offset="0.22" stopColor="#0AB7FE"/>
        <stop offset="0.42" stopColor="#1F55FF"/>
        <stop offset="0.94" stopColor="#080808" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7054_95727" x1="58.3998" y1="131.502" x2="185.727" y2="131.502" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#0DACFF"/>
        <stop offset="0.145833" stopColor="#1D5AFF"/>
        <stop offset="0.63" stopColor="#0B0B0B" stopOpacity="0"/>
        <stop offset="0.96" stopColor="#0B0B0B" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7054_95727" x1="78.7488" y1="183.592" x2="108.496" y2="183.592" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#1F55FE"/>
        <stop offset="0.22" stopColor="#1D5DFE"/>
        <stop offset="0.47" stopColor="#0F9AFF"/>
        <stop offset="0.91" stopColor="#05CAFE"/>
    </linearGradient>
    <linearGradient id="paint3_linear_7054_95727" x1="130.099" y1="181.958" x2="224.926" y2="181.958" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#1F55FE"/>
        <stop offset="0.22" stopColor="#1D5DFE"/>
        <stop offset="0.47" stopColor="#0F9AFF"/>
        <stop offset="0.91" stopColor="#05CAFE"/>
    </linearGradient>
    <linearGradient id="paint4_linear_7054_95727" x1="451.451" y1="84.783" x2="497.434" y2="178.282" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#2CD9FF"/>
        <stop offset="0.255208" stopColor="#53BCDD"/>
        <stop offset="0.510417" stopColor="#3C6FF3"/>
        <stop offset="0.94" stopColor="#323137" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint5_linear_7054_95727" x1="456.887" y1="70.5584" x2="492.099" y2="192.903" gradientUnits="userSpaceOnUse">
        <stop offset="0.145833" stopColor="#86D1F5"/>
        <stop offset="0.609375" stopColor="#30C1EF"/>
        <stop offset="0.94" stopColor="#252529" stopOpacity="0"/>
    </linearGradient>
    <linearGradient id="paint6_linear_7054_95727" x1="431.014" y1="183.591" x2="460.762" y2="183.591" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#1F55FE"/>
        <stop offset="0.22" stopColor="#1D5DFE"/>
        <stop offset="0.47" stopColor="#0F9AFF"/>
        <stop offset="0.91" stopColor="#05CAFE"/>
    </linearGradient>
    <linearGradient id="paint7_linear_7054_95727" x1="482.364" y1="181.958" x2="577.192" y2="181.958" gradientUnits="userSpaceOnUse">
        <stop offset="0.01" stopColor="#1F55FE"/>
        <stop offset="0.22" stopColor="#1D5DFE"/>
        <stop offset="0.47" stopColor="#0F9AFF"/>
        <stop offset="0.91" stopColor="#05CAFE"/>
    </linearGradient>
</defs></>;