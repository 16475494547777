import React, {useEffect} from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import {ButtonCustom} from '../../components/utility/custom-buttons/view'



const invokeAuth = (actions, counter) => {
    
    if(BinanceChain.isConnected()){
        actions.BINANCE_AUTHORIZE_SIGN_IN_INVOKED({counter});
    }

}


const label = (state) => {
    return   <span>
      { state?.loaderBinance ? <i className="fa-solid fa-circle-notch fa-spin"></i> : null }
      <span className="ps-3 pe-3">Connect</span>
  </span>
}

export const BinanceAuth = () => {
    
    const state = useStoreState(state => state.authentication);
    const actions = useStoreActions(actions => actions);
    const counter = state.counter;

    return state?.connectionButton && !state?.error ?
        <ButtonCustom onClick={() => invokeAuth(actions, counter)} disabled={state?.loader} variant="default" label={label(state)}/> : null;

}