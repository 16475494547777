import React from 'react';
import Card from 'react-bootstrap/Card';
import { constants } from '../../../../constants';
import { BorderedButton } from '../../../../../../components/utility/bordered-button/view';
import { TrialNFT } from '../../../../../../components/trialNFTComponent/view';

const { PLANS_CONTENT: { TRIAL_CARD: { HEADER: { TOPIC, DESCRIPTION, PERIOD }, CONTENT, FOOTER } } } = constants;

export const TrialPlanCard = () => {
    return (
        <Card className='trial-plan-card font-inter'>
            <Card.Body>
                <Card.Header className='align-items-center'>
                    <div className='card-header-column'>
                        <p className='card-header-topic font-inter font-size-26'>{TOPIC}</p>
                        <p className='description font-weight-regular font-inter'>{DESCRIPTION}</p>
                    </div>
                    <div className='card-header-column d-flex justify-content-end align-items-center h-100'>
                        <p className='card-header-topic font-inter font-size-26'>{PERIOD}</p>
                    </div>
                </Card.Header>
                <Card.Text className='card-content'>
                    {CONTENT.map((item, index) => <span className='card-content-row d-flex align-items-center' key={index}>
                        <img className='me-3' src='../dashboard-library/home-page-images/cardIcon.svg'/>{item}
                    </span>)}
                </Card.Text>
                <div className='card-footer d-flex justify-content-center align-items-center'>
                    <BorderedButton class='font-size-25 font-inter position-relative'>
                        <TrialNFT/>
                    </BorderedButton>
                </div>
            </Card.Body>
        </Card>
    );
};
