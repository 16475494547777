/* eslint-disable react/display-name */
import React, { forwardRef, useEffect } from 'react';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { packsEffects } from '../actions/packsEffects';
import { Form, Modal } from 'react-bootstrap';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { constants } from '../constants';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { dataHelper } from '../actions/dataHelpers/dataHelper';
import { isEmpty } from 'lodash';
import { Asset } from '../../../components/utility/asset-component/view';
import { debug } from 'easy-peasy';

const { CONTROLS: { LABEL_ADD_NEW_PACK, LABEL_CANCEL, LABEL_ADD_PAIR, LABEL_SAVE }, LIST_ICONS, INPUT_LABELS: { PACK_NAME, PAIR_COINS, MAIN_COIN, PACK_NAME_PLACEHOLDER } } = constants;

const renderPackForm = (state, actions) => {
    const mainCoinHandle = value => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'pack_main_coin_value', value });
    const pairCoinHandle = value => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'pack_pairs_value', value });
    const nameHandle = e => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'pack_name_value', value: e.target.value });

    return <Form.Group>
        <div className="pack-create-form">
            <div className="pack-create-pack-name">
                <Form.Label>{PACK_NAME}</Form.Label>
                <Form.Control
                    placeholder={PACK_NAME_PLACEHOLDER}
                    onChange={nameHandle}>
                </Form.Control>
            </div>
            <div className="pack-create-main-coin">
                <Form.Label>{MAIN_COIN}</Form.Label>
                <MultiplyCustomSelector
                    onChangeHandle={mainCoinHandle}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    options={state.mainCoinList}/>
            </div>
            <div className="pack-create-pair-coins">
                <Form.Label>{PAIR_COINS}</Form.Label>
                <MultiplyCustomSelector
                    onChangeHandle={pairCoinHandle}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    options={state.pairCoinList}/>
            </div>
        </div>
    </Form.Group>;
};

const renderPairForm = (state, actions) => {

    if (isEmpty(state.selectedPack))
        return null;

    const {selectedPack: {name, mainCoin}} = state;
    const pairCoinHandle = value => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({id: 'pairs_add_value', value});
    return <Form.Group>
        <div className="pair-create-form">
            <div className="pair-create-pack-name">
                <Form.Label>{PACK_NAME}</Form.Label>
                <div>{name}</div>
            </div>
            <div className="pair-create-main-coin">
                <Form.Label>{MAIN_COIN}</Form.Label>
                <div className="main-coin">
                    <Asset asset={mainCoin}/>
                </div>
            </div>
            <div className="pair-create-pair-coins">
                <Form.Label>{PAIR_COINS}</Form.Label>
                <MultiplyCustomSelector
                    onChangeHandle={pairCoinHandle}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    options={state.pairCoinList}/>
            </div>
        </div>
    </Form.Group>;
};

export const AddNewPackModal = forwardRef(({}, reference) => {
    const state = SmartTradeState.useStoreState(state => state.packs);
    const actions = SmartTradeState.useStoreActions(actions => actions);
    const handleClose = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'close' });
    const saveHandle = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'save', ...state.createPackData, exchange: state.exchangeData });
    const isDisabled = isEmpty(state.createPackData) || isEmpty(state.createPackData.name) || isEmpty(state.createPackData.pairs) || isEmpty(state.createPackData.mainCoin);
    return <Modal
        size="md"
        show={state.addPackMode}
        centered
        container={reference}
        backdrop="static"
        className="add-pack"
        onHide={handleClose}>
        <Modal.Body>
            <div className="font-size-16 mb-2">{LABEL_ADD_NEW_PACK}</div>
            { renderPackForm(state, actions) }
        </Modal.Body>
        <Modal.Footer>
            <ButtonCustom label={LABEL_CANCEL} size="sm" variant="default" onClick={handleClose}/>
            <ButtonCustom label={LABEL_ADD_NEW_PACK} size="sm" variant="primary" disabled={isDisabled} onClick={saveHandle}/>
        </Modal.Footer>
    </Modal>;
});

export const AddNewPairModal = forwardRef(({}, reference) => {
    const state = SmartTradeState.useStoreState(state => state.packs);
    const actions = SmartTradeState.useStoreActions(actions => actions);
    const handleClose = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'close' });
    const saveHandle = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'save_pairs', ...state });
    const isDisabled = isEmpty(state.createPairsData);
    return <Modal
        size="md"
        show={state.addPairMode}
        centered
        container={reference}
        backdrop="static"
        className="add-pair"
        onHide={handleClose}>
        <Modal.Body>
            <div className="font-size-16 mb-2">{LABEL_ADD_PAIR}</div>
            { renderPairForm(state, actions) }
        </Modal.Body>
        <Modal.Footer>
            <ButtonCustom label={LABEL_CANCEL} size="sm" variant="default" onClick={handleClose}/>
            <ButtonCustom label={LABEL_ADD_PAIR} size="sm" variant="primary" disabled={isDisabled} onClick={saveHandle}/>
        </Modal.Footer>
    </Modal>;
});