import React, { useEffect, useRef } from 'react';
import { imagesTemplate } from './templates';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { RemakeDashboardState } from '../../../models/remake-dashboard-model';
// import { SMALL_PARTS } from './images/smallParts';
import { useStoreState } from 'easy-peasy';
// import { dashboardEffects } from '../actions/dashboardEffects';
import { isEmpty } from 'lodash';
import { remakeDashboardEffects } from '../actions/remakeDashboardEffects';

export const LendingImagesGenerator = () => {
    const ref = useRef();
    const supplyBorrowRef = useRef();
    const state = RemakeDashboardState.useStoreState(state => state);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);

    useEffect(() => {
        drawHelper.drawLendingTitles(d3.select(supplyBorrowRef.current), state, actions);
    }, );

    return <div ref={ref} className="images-background-grid">
        <div className="text-start">
            <svg ref={supplyBorrowRef} viewBox="0 50 623 241" fill="none" xmlns="http://www.w3.org/2000/svg">
                { imagesTemplate.supplyPart() }
            </svg>
        </div>
    </div>;
};
