import { createContextStore } from 'easy-peasy';
import { profilePageActions } from '../pages/profilePage/actions/uiActions';
import { profileEffects } from '../pages/profilePage/actions/profileEffects';


const PROFILE_MODEL = {
    isModalOpen: false,
    isWalletModalOpen: false,
    isEditModalOpen: false,
    profileName: '',
    modalTitle: '',
    walletTitle:'',
    walletData:[],
    id: '',
    walletId:'',
    cardsList: [],
    events: {
        open: false,
    },
    counter: 0,
    indexForSubmit: '',
    deleteId: '',

    ...profilePageActions.actionHandlers,
    ...profileEffects.actionHandlers,
};


export const ProfileState = createContextStore(PROFILE_MODEL);