import React from 'react';
import './styles.scss';
import { Asset } from '../asset-component/view';

const constants = {

};

const buttonHarvest = () => {
  return <div className="button-harvest cursor-pointer">
    <span className="font-size-10">Harvest</span>
    <img className="image-fluid" src="../dashboard-library/button-harvest.svg"/>
  </div>
};

const iconSet = (asset0, asset1) => {
  const stakedAsset = asset1 === 'cake(l)' ? 'cake' : asset1;

  return <div className="icons-set">
    <Asset asset={stakedAsset.toLowerCase()} ticker={true}/>
    <div className="logo-icon d-flex justify-content-center align-items-center">
      <img className="img-fluid img-rounded" src={`../coins-full-library/${asset0.toLowerCase()}.svg`}/>
    </div>
  </div>
};


export const VaultCard = props => {
  const { asset0, asset1, rewardAmount, rewardPrice, stakedAmount, stakedPrice } = props;
  const sumAmount = (Number(rewardAmount) + Number(stakedAmount)).toFixed(2);
  const sumUSD = `${(Number(rewardPrice) + Number(stakedPrice)).toFixed(2)} USD`;
  const headerTitle = `Earn ${asset1.toUpperCase()}`;
  return <div className="vault-card">
    <div className="vault-card-container">
      <div className="vault-card-content">
        <div className="vault-card-content-header">
            <div className="text-block d-flex flex-column font-size-14 font-weight-medium">
              <div>{headerTitle}</div>
              <div>Stake Cake</div>
            </div>
            <div className="icons-block">
              {iconSet(asset0, asset1)}
            </div>
        </div>
        <div className="vault-card-content-horizontal-divider"></div>
        <div className="vault-card-content-body">
          <div className="details">
            <div className="font-size-10 pb-1">APR:</div>
            <div className="font-weight-medium font-size-12">
              <span className="pe-1 styled">{asset1.toUpperCase()}</span>
              <span>earned</span>
            </div>
            <div className="value d-flex flex-column pb-2">
              <div className="font-weight-medium font-size-18 value-limitation">{sumAmount}</div>
              <div className="font-size-8">{sumUSD}</div>
            </div>
            {/*<div className="font-weight-medium font-size-10 pb-1">Stake Cake</div>*/}
            <div className="font-size-10">Manual</div>
          </div>
          <div className="action-harvest d-flex flex-column justify-content-center h-100">
            { buttonHarvest() }
          </div>
        </div>
      </div>
    </div>
  </div>
};