import { ethers } from 'ethers';
import { NFTStorage } from 'nft.storage';
import { constants } from '../constants';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';
import { nftGeneratorEffects } from '../actions/nftGeneratorEffects';

const { ABI, MESSAGES } = constants;
const NFT_STORAGE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGE3ZjE4QTA1Mzc2NEQ0MGRFODczMTQ4NTI2NjUxNEFDRjU2MjhDNTgiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3OTkxNzMyMjY1NywibmFtZSI6Ik1pbG9zIEdvamljIn0.Ugrh8NWmZecrzEHupxWs_teYKXiM1j-iv7QoYJkoFfE';
const NFT_COLLECTION_ADDRESS = '0x6f3B10f24c982a14457eEEb567C0279B641E6250';
const NFT_COLLECTION_ADDRESS_LEGENDARY = '0x60dF2500ba1b60c7A546b7503b99C6c64BC19B80';

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

const enableNFT = async (image, user, actions, plansSubscriptionName) => {
  if (!window.ethereum) {
    return { err: 'metamask was not installed' };
  }

  const address = plansSubscriptionName === 'legendary' ? NFT_COLLECTION_ADDRESS_LEGENDARY : NFT_COLLECTION_ADDRESS;

  const closeModal = () => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_MODAL_TOGGLED]({modal: 'select-chain', name: 'close-modal'});
  const stopLoader = () => actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_LOADER_TOGGLED]({ name: 'stop-loader' });

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const nftContract = new ethers.Contract(address, ABI, provider);
  const nftContractWithSigner = nftContract.connect(signer)

  const getGasPrice = await provider.getGasPrice();

  const functionGasFees = await nftContractWithSigner.estimateGas.getItems();
  const gasPrice = Number(ethers.utils.formatEther(getGasPrice * functionGasFees));
  const balance = Number(ethers.utils.formatEther(await provider.getBalance(user.userId)));

  if (balance > gasPrice) {
    actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_LOADER_TOGGLED]({ name: 'start-loader' });

    const client = new NFTStorage({ token: NFT_STORAGE_KEY })
    const file = dataURLtoFile(image, 'a.png');
    await client.store({
      name: 'Monoceros NFT',
      description: 'Description for NFT',
      image: file
    })
    .then((response) => {
      nftContractWithSigner.mintWithMetadata(response.url)
        .then(() => {
          notifyEffects.notifyAction({ type: 'success', text: MESSAGES.NFT_CREATED });
        })
        .catch((error) => {
          notifyEffects.notifyAction(
            {
              type: 'danger', text: error?.error?.data?.message || error?.message || MESSAGES.SOMETHING_WENT_WRONG
            });
          }
        )
        .finally(() => {
          closeModal();
          stopLoader();
        });
    })
    .catch(() => {
      notifyEffects.notifyAction({ type: 'danger', text: MESSAGES.SOMETHING_WENT_WRONG });
      closeModal();
      stopLoader();
    })
  } else {
    closeModal();
    notifyEffects.notifyAction({ type: 'danger', text: MESSAGES.NOT_ENOUGH_BALANCE });
  }
};

export const dataHelper = {
  enableNFT,
  dataURLtoFile
}
