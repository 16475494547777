import React, { useState, useEffect, useRef } from "react";
import { constants } from "../constants";
import { FormControl, InputGroup } from "react-bootstrap";
import { MultiplyCustomSelector } from "../../../components/utility/multiply-selector/view";
import { dynamicSplitEffects } from "../actions/dynamicSplitEffects";
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
const {
  PLACEHOLDERS: { TYPE_URL, FAVORITES },
} = constants;

export const IframesContent = (props) => {
  const { state , actions} = props;
  let obj = { first: "", second: "", third: "", fourth: "" };
  const [urls, setUrls] = useState(obj);
  const [text, setText] = useState(obj);
  const first = useRef(true);
  const [userInput, setUserInput] = useState();
  const clickedFavorites = useStoreState(state => state.workBoard.clickedFavorites);  
  const clickedUrl = useStoreState(state => state.workBoard.clickedUrl);  

  const updateValue = (e, name) => {
    let value = e?.target?.value || e.value;
    if (!value.match(/^[a-zA-Z]+:\/\//)) {
      value = "https://" + value;
    }
   
    setText({ ...text, [name]: value });
    
    setUserInput(value);
  };
  
  const handleSelect = (e, name) => {
    setUrls({ ...urls, [name]: e.value });
    setText({ ...text, [name]: e.value });
    
  };
const handleUserInput = () => {
  let inputName = userInput.toString().substring(8, userInput.toString().indexOf('.'));
    const values={
    url: userInput,
    name:inputName,
      }
  actions[dynamicSplitEffects.actionTypes.DYNAMIC_SPLIT_LINKS_INVOKED]({values: values, method: 'post'});
}
  const handleKeyDown = (e, name) => {
    if (e.key === "Enter") {

      setUrls({ ...urls, [name]: e.target.value });
    }
  };
 
  useEffect(() => {
  if (first.current) {
      first.current = true;
      return;
  }

  
      switch(true){
          case urls.first === '':
            passeditemOnEmptyFrame("first",state.favoritesUrl);
            break;
          case urls.second === '':
            passeditemOnEmptyFrame("second",state.favoritesUrl);
            break;
          case urls.third === '':
            passeditemOnEmptyFrame("third",state.favoritesUrl);
            break;
          case urls.fourth === '':
            passeditemOnEmptyFrame("fourth",state.favoritesUrl);
            break;
          default:
            passeditemOnEmptyFrame("first",state.favoritesUrl);
            break;
    
      }

  }, [state.favoritesUrl]);
  const passeditemOnEmptyFrame = (name,url) => {
    setUrls({ ...urls, [name]: url});
    setText({ ...text, [name]: url});
  }

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    setUrls({ ...urls, [clickedFavorites]: clickedUrl});
    setText({ ...text, [clickedFavorites]: clickedUrl});
  }, [clickedFavorites]);
 
  const handleGoButton = (name) => {
    setUrls({ ...urls, [name]: userInput });
    setText({ ...text, [name]: userInput});
  }
  return (
    <div className="iframes-content">
      {Object.keys(urls).map((item) => (
        <div key={item} className="iframes-item">
          <div className="iframes-item-header">
            <div className="cursor-pointer arrow-left-icon">
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="cursor-pointer arrow-right-icon">
              <i className="fa-solid fa-arrow-right"></i>
            </div>
            <div className="cursor-pointer rotate-right-icon">
              <i className="fa-solid fa-rotate-right"></i>
            </div>
            <div className="type-url-input">
              
              <InputGroup>
              
                <FormControl
                  value={text[item]}
                  onChange={(e) => updateValue(e, item)}
                  onKeyDown={(e) => handleKeyDown(e, item)}
                  placeholder={TYPE_URL}
                  
                />
                
                
              </InputGroup>
             
            </div>
            <div className="newbutton-grup">
            
                    <button className="newbutton-grup header-button" onClick= {()=>handleGoButton(item)}>{"GO"}  </button>
                    <button className="newbutton-grup header-button" onClick= {()=>handleUserInput()}><FontAwesomeIcon icon={faStar}/></button>
               
                </div>
            <div className="multiple-selector ms-4">
              <MultiplyCustomSelector
                onChangeHandle={(e) => handleSelect(e, item)}
                closeMenuOnSelect={true}
                placeholder={FAVORITES}
                isMulti={false}
                options={state.favoritesSelector.list}
             
              />
            </div>
          </div>

          {urls[item]?.length === 0 ? (
            <div className="iframes-item-logo align-items-center">
              <div className="logo"></div>
            </div>
          ) : (
            <div className="iframes-item-iframe">
              <iframe src={urls[item]} title={urls[item]}></iframe>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
