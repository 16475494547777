import Toggle from 'react-toggle';
import React from 'react';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { constants } from '../constants';
import { controlsEffects } from '../actions/controlsEffect';
import { packsEffects } from '../actions/packsEffects';
import { pairs } from './pairsTemplate';
import { Asset } from '../../../components/utility/asset-component/view';
import { debug } from 'easy-peasy';
import { dataHelper } from '../actions/dataHelpers/dataHelper';
import { packHelper } from '../actions/dataHelpers/packHelper';

const {
  CONTROLS: {
    LABEL_SINGLE_PAIR,
    LABEL_FAVORITES,
    LABEL_PACKS,
    LABEL_SIGNALS,
    LABEL_WEIGHT_EQUAL,
    LABEL_WEIGHT_CUSTOM,
    LABEL_CANCEL,
    LABEL_EDIT,
    LABEL_SAVE,
    LABEL_MAIN_COIN,
    LABEL_PAIRS_SIZE,
  },
  PACKS: { SHOW_LESS, SHOW_MORE, ADD_LABEL, PACKS_LIMIT },
} = constants;

const renderPacksToggle = (flag, value, actions) => {
  const clickHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'toggle' });
  return (
    <div
      key="toggle"
      className="pack-item -toggle font-size-14 align-items-center"
      onClick={clickHandle}
    >
      <div className="link-text d-flex">{flag ? SHOW_LESS : `${SHOW_MORE} (${value})`}</div>
    </div>
  );
};

const renderAddPack = (actions) => {
  const clickHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'add' });
  return (
    <div key="add" className="pack-item -add" onClick={clickHandle}>
      <div className="link-text d-flex flex-row font-size-14 align-items-center">
        <i className="fa-light fa-plus"></i>
        <span className="ps-2">{ADD_LABEL}</span>
      </div>
    </div>
  );
};

const renderPacksList = (state, actions) => {
  const { packsData, selectedId, exchangeData, exchangeId, show } = state;
  // console.info(debug(state))
  const packsSize = packsData.length;
  const packs = show ? packsData : packsData.slice(0, PACKS_LIMIT);
  const hiddenValue = packsSize - PACKS_LIMIT;
  const packsElements = packs.map((el, key) => {
    const isActive = el.id && el.id === selectedId ? true : false;
    const { mainCoin, name, pairs } = el;
    const pairsSize = pairs.length;
    const clickHandle = () =>
      actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({
        id: 'select_pack',
        data: el,
      });
    return (
      <div className={`pack-item ${isActive ? 'active' : ''}`} key={key} onClick={clickHandle}>
        <div className="pack-item-content">
          <div className="pack-item-name font-size-14">{name}</div>
          <div className="pack-item-grid">
            <div className="pack-item-main-coin d-flex align-items-center">
              <Asset asset={mainCoin} />
            </div>
            <div className="pack-item-size text-center font-weight-medium">{pairsSize}</div>
            <div className="pack-item-title font-size-12">{LABEL_MAIN_COIN}</div>
            <div className="pack-item-title font-size-12 text-center">{LABEL_PAIRS_SIZE}</div>
          </div>
        </div>
      </div>
    );
  });
  return [
    renderAddPack(actions),
    ...packsElements,
    packsSize > PACKS_LIMIT ? renderPacksToggle(show, hiddenValue, actions) : null,
  ];
};

const smartTradePackControls = (state, actions) => {
  const { selectedPack, editPackMode, exchangeId } = state;
  const changeHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]({
      actionName: LABEL_WEIGHT_EQUAL,
      actions,
    });
  const cancelHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]({
      actionName: LABEL_CANCEL,
      actions,
    });
  // const saveHandle = () => actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]({ actionName: LABEL_SAVE, actions });
  const editHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_EDIT_MODIFIED]({
      actionName: LABEL_EDIT,
      actions,
    });
  const deleteHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({
      id: 'delete_pack',
      packId: selectedPack.id,
      exchange: exchangeId,
    });
  const cancelSelectHandle = () =>
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'cancel_select_pack' });
  return (
    <div className="smart-trade-pack-controls d-flex flex-row justify-content-between pe-3 ps-3 align-items-center">
      <div className="d-flex flex-row">
        <div className="pack-name font-size-14 pe-5">{selectedPack.name}</div>
        {editPackMode ? (
          <label className="customized-toggle d-flex flex-row align-items-center">
            <span className="me-1 font-size-12">{LABEL_WEIGHT_EQUAL}</span>
          </label>
        ) : null}
      </div>
      <div className="pack-edit font-size-14">
        {!editPackMode ? (
          <span>
            <span className="cancel-select-pack pe-3" onClick={cancelSelectHandle}>
              Cancel
            </span>
            <span className="edit-mode-links cursor-pointer" onClick={editHandle}>
              {LABEL_EDIT}
            </span>
          </span>
        ) : (
          <span className="edit-mode-links -edit">
            <span className="pe-3" onClick={cancelHandle}>
              {LABEL_CANCEL}
            </span>
            {/*<span className="pe-3" onClick={saveHandle}>{LABEL_SAVE}</span>*/}
            <span onClick={deleteHandle}>Delete pack</span>
          </span>
        )}
      </div>
    </div>
  );
};

const smartTradePackBody = (state, actions) => {
  const { editPackMode } = state;
  const editClass = editPackMode ? '-edit' : '';
  return (
    <div className={`smart-trade-pack-body ${editClass}`}>{pairs.renderPairs(state, actions)}</div>
  );
};

const renderPack = (state, actions) => {
  return (
    <div className="smart-trade-pack">
      {smartTradePackControls(state, actions)}
      {smartTradePackBody(state, actions)}
    </div>
  );
};

export const SmartTradeControls = () => {
  const state = SmartTradeState.useStoreState((state) => state.controls);
  const action = SmartTradeState.useStoreActions((action) => action);
  const { packs, signals, favorites } = state;

  return (
    <div className="smart-trade-controls-container-content">
      <label className="customized-toggle d-flex flex-row">
        <span className="me-4 me-md-1 font-size-12">{LABEL_SINGLE_PAIR}</span>
        <Toggle
          icons={false}
          checked={state.packs}
          onChange={() =>
            action[controlsEffects.actionTypes.SMART_TRADE_PAGE_CONTROLS_TOGGLED]({
              name: LABEL_PACKS,
            })
          }
        />
        <span className="ms-1 font-size-12">{LABEL_PACKS}</span>
      </label>
      {/*{!packs ? <label className="customized-toggle d-flex flex-row">*/}
      {/*    <span className="me-1 font-size-12">{LABEL_SIGNALS}</span>*/}
      {/*    <Toggle*/}
      {/*        icons={false}*/}
      {/*        defaultChecked={false}*/}
      {/*        onChange={() => action[controlsEffects.actionTypes.SMART_TRADE_PAGE_CONTROLS_TOGGLED]({ name: LABEL_SIGNALS })}/>*/}
      {/*</label> : null}*/}
      {/*{!packs ? <label className="customized-toggle d-flex flex-row">*/}
      {/*    <span className="me-1 font-size-12">{LABEL_FAVORITES}</span>*/}
      {/*    <Toggle*/}
      {/*        icons={false}*/}
      {/*        defaultChecked={false}*/}
      {/*        onChange={() => action[controlsEffects.actionTypes.SMART_TRADE_PAGE_CONTROLS_TOGGLED]({ name: LABEL_FAVORITES })}/>*/}
      {/*</label> : null}*/}
    </div>
  );
};

export const SmartTradePacks = () => {
  const state = SmartTradeState.useStoreState((state) => state.packs);
  const actions = SmartTradeState.useStoreActions((actions) => actions);
  return renderPacksList(state, actions);
};

export const SmartTradePack = () => {
  const state = SmartTradeState.useStoreState((state) => state.packs);
  const actions = SmartTradeState.useStoreActions((actions) => actions);
  return state.selectedId ? renderPack(state, actions) : null;
};
