import React, { useEffect } from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import Articles from '../template/Articles';
import NewAndUpdateArticle from '../template/NewAndUpdateArticle';
import Header from '../template/Header';
import NumArticlesAndTags from '../template/NumArticlesAndTags';
import Sort from '../template/Sort';
import Filter from '../template/Filter';
import UpdateFilters from '../template/UpdateFilters';
import Message from '../template/Message';
import FullArticle from '../template/FullArticle';

import '../styles.scss';

const AcademyAdminLayout = () => {
  const state = AcademyState.useStoreState((state) => state);
  const mode = AcademyState.useStoreState((state) => state.admin.mode);
  const actions = AcademyState.useStoreActions((actions) => actions);

  const { search, published } = state.admin.filter;

  const isSetFullArticle = state.fullArticle;

  useEffect(() => {
    actions.GET_ARTICLES();
  }, []);

  useEffect(() => {
    actions.GET_FILTERS();
  }, []);

  useEffect(() => {
    actions.GET_ARTICLES();
  }, [search, published]);

  let section;

  if (mode === '') {
    section = <Articles />;
  }

  if (mode !== '' && mode !== 'update-filters') {
    section = <NewAndUpdateArticle />;
  }

  if (mode === 'update-filters') {
    section = <UpdateFilters />;
  }

  const showArticles = () => {
    return (
      <>
        <header>
          <Message />

          <div className="header-top">
            <Header />
            <Filter />
          </div>

          {mode === '' && (
            <div className="header-bottom">
              <NumArticlesAndTags />
              <Sort />
            </div>
          )}
        </header>
        <main>{section}</main>
      </>
    );
  };

  return <div className="academy-page">{!isSetFullArticle ? showArticles() : <FullArticle />}</div>;
};

export const AcademyAdmin = () => {
  return (
    <AcademyState.Provider>
      <AcademyAdminLayout />
    </AcademyState.Provider>
  );
};
