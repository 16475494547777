import { action, thunk, debug } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import Big from 'big.js';

const actionTypes = {
  ORDER_BOOK_SORT_TABLE_TOGGLE: 'ORDER_BOOK_SORT_PRICE_TABLE#TOGGLE',
  ORDER_BOOK_SELECTORS_CHANGED: 'ORDER_BOOK_SELECTORS#CHANGED',
  SET_SELECT_VALUES: 'SET_SELECT_VALUES',
  GET_ORDER_BOOK_STEPS: 'GET_ORDER_BOOK_STEPS',
  REPORT_SELECT_SUCCSES: 'REPORT_SELECT_SUCCSES',
  REPORT_SUCCSES: 'REPORT_SUCCSES',
  REPORT_SELECT_ERROR: 'REPORT_SELECT_ERROR',
  REPORT_ERROR: 'REPORT_ERROR',
  SET_ORDER_BOOK_DATES: 'SET_ORDER_BOOK_DATES'
};

const sortPriceTableHandle = (state, payload) => {
  state.orderBookData.sortPriceTable = payload;
};

const selectorHandle = (actions, payload) => {
  const { value: step, state } = payload;

  const mainCoin = state.singlePair.mainCoin;
  const pairCoin = state.singlePair.pairCoin;
  const symbol = pairCoin ? `${pairCoin}${mainCoin}` : 'BNBUSDT';

  state.orderBookData.lengthSelector.selectedStep = step;

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//binance-order-book?symbol=${symbol}&tickSize=${step.value}`,
    method: 'GET',
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const setSelectValues = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//binance-order-book?symbol=BNBUSDT`,
    method: 'GET',
    errorActionType: actions[actionTypes.REPORT_SELECT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
}

const reportSuccses = (state, payload) => {
  const { asks, bids } = payload.response;

  const maxAsksAmount = asks[0].amount;
  const maxBidAmount = bids[bids.length - 1].amount;

  state.orderBookData.askPrices = asks.map(ask => {
    let width = Number(ask.amount) / Number(maxAsksAmount) * 100;

    return { ...ask, width: `${width}%` }
  })

  state.orderBookData.bidPrices = bids.map(bid => {
    let width = Number(bid.amount) / Number(maxBidAmount) * 100;

    return { ...bid, width: `${width}%` }
  })

  let firstAskPrice = state.orderBookData.askPrices[0].price;
  let secondAskPrice = state.orderBookData.askPrices[1].price;

  let step1 = new Big(firstAskPrice).minus(new Big(secondAskPrice))
  let step2 = step1.times(10);
  let step3 = step1.times(100);
  let step4 = null;

  if (step1.lt(0.1)) {
    step4 = step1.times(1000);
  }

  if (state.orderBookData.lengthSelector.list.length === 0) {
    state.orderBookData.lengthSelector.list = [
      { label: step1.toString(), value: step1.toString() },
      { label: step2.toString(), value: step2.toString() },
      { label: step3.toString(), value: step3.toString() },
    ];

    if (step4 !== null) {
      state.orderBookData.lengthSelector.list.push(
        { label: step4.toString(), value: step4.toString() }
      )
    } 
    
    state.orderBookData.lengthSelector.selectedStep = state.orderBookData.lengthSelector.list[0];
  }

  
}


const reportError = (state, payload) => {
  console.log('error', payload)
}



const setOrderBookDates = (actions, payload) => {
  const state = payload;

  const mainCoin = state.singlePair.mainCoin;
  const pairCoin = state.singlePair.pairCoin;
  const symbol = pairCoin ? `${pairCoin}${mainCoin}` : 'BNBUSDT';

  state.orderBookData.lengthSelector.list = [];
  state.orderBookData.lengthSelector.selectedStep = {label: "", value: ""}

   serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//binance-order-book?symbol=${symbol}`,
    method: 'GET',
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
}

const actionHandlers = {
  [actionTypes.ORDER_BOOK_SORT_TABLE_TOGGLE]: action((state, payload) => sortPriceTableHandle(state, payload)),
  [actionTypes.ORDER_BOOK_SELECTORS_CHANGED]: thunk((actions, payload) => selectorHandle(actions, payload)),
  [actionTypes.SET_SELECT_VALUES]: thunk((actions, payload) => setSelectValues(actions, payload)),
  [actionTypes.REPORT_SUCCSES]: action((state, payload) => reportSuccses(state, payload)),
  [actionTypes.REPORT_ERROR]: action((state, payload) => reportError(state, payload)),
  [actionTypes.SET_ORDER_BOOK_DATES]: thunk((actions, payload) => setOrderBookDates(actions, payload)),
};

export const orderBookEffects = {
  actionHandlers,
  actionTypes,
};
