import React from 'react';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import Big from 'big.js';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';
import { InputGroup, FormControl } from 'react-bootstrap';

const { WALLET_CONTROLS_LIQUIDATOR: { NO_SELECTED_ACCOUNT, REPAY_IN, WALLET_BALANCE, BUTTON, UPDATE } } = constants;

export const RightWindow = props => {
    const { state, actions } = props;

    const noSelectedAccount = selected => !selected ? 'd-flex align-items-center justify-content-center' : '';
    const isLiquidateButtonShown = () => state.pickedAccount.shortfall !== 0 && Big(+state.seizeToken.maxRepayAmount) < +state.tokenBalance;

    return <div className={`wallet-controls-liquidator ${noSelectedAccount(state.pickedAccount)}`}>
        {state.pickedAccount ? <>
            <div className="repay-in d-inline-flex align-items-center"><span className="me-2">{REPAY_IN}</span> {state.assetsToRepay?.label}</div>
            <div className="repay-amount">
                <InputGroup className="mb-1">
                    <FormControl
                        value={state.repayAmount}
                        onChange={e => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SET_REPAY]({ value: e.target.value })}
                        placeholder={'...'}/>
                    <InputGroup.Text 
                        onClick={() => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SET_MAX]({ max: `${Big(+state.seizeToken.maxRepayAmount)}` })} className="max-btn">
                        MAX</InputGroup.Text>
                </InputGroup>
                <div className="wallet-balance d-inline-flex align-items-center font-size-14">
                    <span className="me-2">{WALLET_BALANCE}</span> <span className="me-2">{state.tokenBalance || 0}</span> {state.assetsToRepay?.label}</div>
            </div>
            <div className="button-row d-flex justify-content-end">
                {isLiquidateButtonShown() && <ButtonCustom className="w-100" variant="primary" size="sm" label={BUTTON.toUpperCase()} />}
            </div>
            <div className="account-update d-flex align-items-center justify-content-end">
                <div className="font-style-italic me-3">{UPDATE}</div>
                <i className="fa-light fa-sliders font-size-18"></i>
            </div>
        </> : <div>{NO_SELECTED_ACCOUNT}</div>}
    </div>;
};