import { createContextStore } from 'easy-peasy';
import { dashboardEffects } from '../pages/newDashboardPage/actions/dashboardEffects';

const DASHBOARD_MODEL = {
    defi: {
        balance: 0,
        invested: 0,
        available: 0,
        change: '',
        top4assets: [],
    },
    cefi: {
        balance: 0,
        invested: 0,
        available: 0,
        change: '',
        top4assets: [],
    },
    total: {
        invested: '',
        available: '',
        total: ''
    },
    accounts: [],
    yields: [],
    vaults: [],
    availableAssets: [],
    walletAssets: {
        totalUSD: '',
        totalBTC: '',
        assets: []
    },
    isLoaded: false,
    imageHeight: false,
    isModalOpen: false,
    ...dashboardEffects.actionHandlers,

};

export const DashboardState = createContextStore(DASHBOARD_MODEL);