import { action } from 'easy-peasy';
import { constants } from '../constants';
import { dataHelper } from './dataHelpers/dataHelper';
import { packHelper } from './dataHelpers/packHelper';

const {CONTROLS: {LABEL_EXCHANGES, LABEL_COIN_PAIR, LABEL_COIN_MAIN}} = constants;

const actionTypes = {
  HODL_BOT_SELECTORS_CHANGED: 'HODL_BOT_SELECTORS#CHANGED',
  HODL_BOT_SELECTORS_DATA_UPDATED: 'HODL_BOT_SELECTORS_DATA#UPDATED'
};

const selectorHandle = (state, payload) => {
  const {name} = payload;
  let listData;
  switch(name) {
    case 'init_exchange':
      const {exchange: {value: {api}}} = payload;
      state.packs.exchangeData = {value: payload?.exchange};
      state.packs.exchangeId = payload?.exchange?.value?.accountId;
      listData = dataHelper.generateMainCoinList(state.assetsData, api);
      state.packs.mainCoinList = listData.list;
      state.packs.coinData = listData.pairs;
      break;
    case LABEL_COIN_PAIR:
      state.singlePair.pairCoin = payload?.value?.value;
      state.singlePair.pairData = packHelper.createSelectedSinglePair({mainCoin: state.singlePair.mainCoin, pair: state.singlePair.pairCoin, exchangeData: state.packs.exchangeData});
      state.singlePair.pairCoinValue = payload?.value;
      break;
    case LABEL_COIN_MAIN:
      const {value: {value}} = payload;
      state.packs.pairCoinList = dataHelper.generatePairCoinList(value, state.packs.coinData);
      state.singlePair.mainCoin = value;
      state.singlePair.pairCoin = null;
      state.singlePair.pairData = {};
      state.singlePair.mainCoinValue = payload?.value;
      state.singlePair.pairCoinValue = null;
      break;
    case LABEL_EXCHANGES:
      state.packs.exchangeData = payload;
      state.packs.exchangeId = payload?.value?.value?.accountId;
      state.packs.selectedId = null;
      state.packs.selectedPack = null;
      listData = dataHelper.generateMainCoinList(state.assetsData, payload?.value?.value?.api);
      state.packs.mainCoinList = listData.list;
      state.packs.coinData = listData.pairs;
      state.packs.pairCoinList = [];
      state.singlePair = {};
      state.singlePair.mainCoinValue = null;
      state.singlePair.pairCoinValue = null;
      state.singlePair.isSingle = !state.controls.packs;
      break;
  }
};

const assetsDataHandle = (state, payload) => {
  state.assetsData = payload;
};

const actionHandlers = {
  [actionTypes.HODL_BOT_SELECTORS_CHANGED]: action((state, payload) => selectorHandle(state, payload)),
  [actionTypes.HODL_BOT_SELECTORS_DATA_UPDATED]: action((state, payload) => assetsDataHandle(state, payload))
};

export const selectorsEffects = {
  actionHandlers,
  actionTypes
};