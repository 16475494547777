import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import './styles.scss';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Header } from '../header/view';
import { NavigationBar } from '../navigationBar/view';
import { Placeholder } from '../placeholder/view';
import { withResizeDetector } from 'react-resize-detector';
import { Notify } from '../utility/notifications/view';
import { PriceFetcher } from '../priceFetcher/view';
import { Loader } from '../utility/loader/view';
import { breakPointEffects } from './breakPointEffects';
import { MessagesModal } from '../messages/messagesModal';

const routingExecutor = (initialPath, breakPointClass) => {
    return <div className={`main-container d-flex flex-column align-items-center ${breakPointClass}`}>
            <NavigationBar/>
            <div className="column">
                <Header/>
                <div className="inner-frame">
                    <Switch>
                        <Route path="/:id" children={<Placeholder/>}/>
                    </Switch>
                </div>
            </div>
    </div>;
};

const Content = props => {
    const { width, height } = props;
    const state = useStoreState(state => state.root);
    const sizes = useStoreState(state => state.sizes);
    const loaders = useStoreState(state => state.loaders);
    const actions = useStoreActions(actions => actions);

    sizes.values = {
        screenWidth: width || 0,
        screenHeight: height || 0,
    };
    const breakPointClass = sizes.breakPointClass;
    const verticalPointClass = sizes.verticalPointClass;
    const initialPath = state.initialPath;
    const isLoading = loaders.main;

    useEffect(() => {
        actions[breakPointEffects.actionTypes.PLACEHOLDER_BREAKPOINTS_CHANGED]()
    }, [sizes.values]);
    return <div id="wrapper" className={`wrapper d-flex ${state.theme} p-0 ${verticalPointClass}`}>
        <PriceFetcher/>
        { isLoading ? <Loader size="6x" classOption="authorisation-loader" /> : routingExecutor(initialPath, breakPointClass) }
        <MessagesModal/>
        <Notify/>
    </div>;
};



export const RootContent = withResizeDetector(Content);
