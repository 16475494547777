import React from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import './styles/styles.scss';

const { Range, Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} %`}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
};

const onChange = (a,b, c) => console.info(a,b,c);


export const CustomSliderRange = () => {
    return <div className="p-3">
        <p>Multi Range with custom track and handle style</p>
        <Range
            count={2}
            defaultValue={[20, 40, 60]}
            onChange={onChange}
            handle={handle}
            trackStyle={[{ backgroundColor: 'rgba(0, 168, 89, 1)' }, { backgroundColor: 'rgba(249, 57, 57, 1)' }]}
            handleStyle={[{ backgroundColor: 'rgba(255, 255, 255, 1)' } ]}
            railStyle={{ backgroundColor: 'rgba(249, 57, 57, 1)' }}
        />
    </div>;
};