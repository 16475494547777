import React, { useEffect } from 'react';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { InputGroup, FormControl } from 'react-bootstrap';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';
import { TableTemplate } from './tableTemplate';
import { LeftWindow } from './leftWindow';
import { RightWindow } from './rightWindow';

const { ERC20_ABI } = constants;

export const WalletTemplate = props => {
    const { state, actions, userWallet } = props;
    
    const onChangeHandle = value => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_MULTISELECT_SET]({ userWallet, value, state });
    const onSearchHandle = e => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SEARCH_SET]({ userWallet, value: e.target.value });
    const searchInvoked = () => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SEARCH_INVOKED]({ userWallet, state });
    const keyUpHandle = e => e.which === 13 ? actions[uiActions.actionTypes.DEFI_LIQUIDATOR_SEARCH_INVOKED]({ userWallet, state }) : '';

    const tokenAddress = state.pickedAccount ? state.config[state.assetsToRepay.value.substring(1)] : '';

    useEffect(() => {
        actions[uiActions.actionTypes.DEFI_LIQUIDATOR_BALANCE_GET]({ tokenAddress, tokenAbi: ERC20_ABI });
    }, [state.assetsToRepay, state.pickedAccount]);

    return <>
        <div className="defi-liquidator-layout controls">
            <div className="multiple-selector me-3">
                <MultiplyCustomSelector
                    options={state.selector.list}
                    closeMenuOnSelect={true}
                    isDisabled={state.selector.isDisabled}
                    isMulti={false}
                    onChangeHandle={onChangeHandle}
                    isLoading={state.selector.isLoading}
                    value={state.selector.value}
                />
            </div>
            <div></div>
            <div className="search-input">
                <InputGroup onKeyUp={keyUpHandle}>
                    <InputGroup.Text className="clear">
                        <i className="fa-thin fa-magnifying-glass"></i>
                    </InputGroup.Text>
                    <FormControl
                        onChange={onSearchHandle}
                        placeholder={'Wallet Address...'}/>
                    <InputGroup.Text className="custom-btn-search" onClick={searchInvoked}>Address</InputGroup.Text>
                </InputGroup>
            </div>
        </div>
        <div className="wallet-controls">
            <LeftWindow state={state} actions={actions} />
            <RightWindow state={state} actions={actions} />
        </div>
        <div className="defi-liquidator-layout table-content">
            <TableTemplate userWallet={userWallet}/>
        </div></>;
};