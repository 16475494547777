import { createContextStore } from 'easy-peasy';
import { trialNFTEffects } from '../components/trialNFTComponent/actions/trialNFTEffects';

const NFT_MODEL = {
    loader: false,

    ...trialNFTEffects.actionHandlers


};

export const NFTTrialModel = createContextStore(NFT_MODEL);