import { useStoreActions } from 'easy-peasy';
import React from 'react'
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal'
import { Link  } from 'react-router-dom';
import { constants } from './constants';

import './styles.scss'

const { REPORT_SUCCSESS: { TITLE, THANK_YOU_MESSAGE, THANK_YOU_DESCRIPTION, CLOSE_BTN, REPEAT_BTN } } = constants;

const SuccsessMessage = ({active}) => {

 
  const closeReportSucces = useStoreActions(actions => actions.TOGGLE_REPORT_SUCCSES);
  const openBugReportModal = useStoreActions(actions => actions.TOGGLE_BUG_REPORT_MODAL);

  const reportAnotherBug = (e) => {
    e.preventDefault();

    closeReportSucces()
    openBugReportModal();
  }

  return (
    <div>
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={active ? 'DaySuccsessMessage' : 'NightSuccsessMessage'}
        centered
      >
        <Modal.Header>
          <Modal.Title className="fs-6">{TITLE}</Modal.Title>
          {
            !active ? 
               <CloseButton variant='white' className="fs-6" onClick={ closeReportSucces }  />
             : <CloseButton  className="fs-6" onClick={ closeReportSucces }  />
          }
        </Modal.Header>

        <Modal.Body>
           <div className="tick">
              <i className="fa-lg fa-sharp fa-solid fa-check"></i>
           </div>

           
              <p>{THANK_YOU_MESSAGE}</p> 
              <p>{THANK_YOU_DESCRIPTION}</p> 
           

            <div className="d-grid gap-2">
                <Button  onClick={closeReportSucces} className="closeBtn"  size="lg">
                    {CLOSE_BTN}
                </Button>
            </div>
            
            <div className="repeatLink">
                <Link to="" onClick={reportAnotherBug}>{REPEAT_BTN}</Link>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SuccsessMessage