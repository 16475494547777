import { action, debug } from 'easy-peasy';
import { constants } from '../constants';

const { CONTROLS: { LABEL_PACKS, LABEL_SIGNALS, LABEL_FAVORITES, LABEL_WEIGHT_EQUAL, LABEL_MULTI_CHART } } = constants;

const actionTypes = {
    SMART_TRADE_PAGE_CONTROLS_TOGGLED: 'SMART_TRADE_PAGE_CONTROLS#TOGGLED'
};

const doUiAction = (state, payload) => {
    const { name } = payload;
    switch (name) {
    case LABEL_PACKS:
        state.controls.packs = !state.controls.packs;
        state.packs.selectedId = null;
        state.packs.selectedPack = null;
        state.packs.selectedPair = {};
        state.singlePair = {};
        state.smartTradeBot.isSingle =
            state.packs.isSingle =
                state.singlePair.isSingle = !state.controls.packs;
        break;
    case LABEL_FAVORITES:
        state.controls.favorites = !state.controls.favorites;

        break;
    case LABEL_SIGNALS:
        state.controls.signals = !state.controls.signals;
        break;
    case LABEL_WEIGHT_EQUAL:
        break;
     case  LABEL_MULTI_CHART:
        state.controls.isMultichart = !state.controls.isMultichart;
        break;   
    }
};

const actionHandlers = {
    [actionTypes.SMART_TRADE_PAGE_CONTROLS_TOGGLED]: action((state, payload) => doUiAction(state, payload))
};

export const controlsEffects = {
    actionHandlers,
    actionTypes
};
