import React, {useState} from 'react';
import { Button, Collapse, Form, InputGroup } from 'react-bootstrap';
import Slider, { Range } from 'rc-slider';
import { constants } from '../../constants';
import { customBotsActions } from "../../actions/uiActions";
import { MultiplyCustomSelector } from '../../../../components/utility/multiply-selector/view';
import { customBotsEffects } from '../../actions/customBotsEffects';
import { TabWidget } from '../tabs/tabWidget';

const { TITLES, BUTTONS, CONTROLS, TABS: { FORM_TABS } } = constants;

export const FiltersTemplate = (props) => {
  const { state, actions } = props
  const action = actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FILTERS_FORM_ELEMENTS_CHANGED]

  const handleClick = () => actions[customBotsActions.actionTypes.CUSTOM_BOTS_TABLE_COLLAPSED]('filters');
  const changeHandle = (value) => action({...value});
  const formControlChange = e => action({name: e?.target?.name, value: e?.target?.value ? parseFloat(e?.target?.value) : 0});

  const [flag, setFlag] = useState(true);
  const onBeforeHandle = () => setFlag(false);
  const onAfterChangeHandle = value => {
    changeHandle(value)
    setFlag(true);
  };
  const settings = {
    slider_days_running: flag ? { value: state.filters.sliderDaysRunning || [5, 200] } : {}
  };

  return <div className="filters-content panel-wrapper">
    <div className="collapse-header d-flex justify-content-between align-items-center">
      <div className="font-weight-medium me-4">{TITLES.FILTERS}</div>
      <div className="d-flex align-items-center">
        <Button
          type="button"
          size="lg"
          variant="danger"
          className="font-size-14 font-weight-medium me-4"
          onClick={() => {}}
        >{BUTTONS.CLEAR_FILTERS}</Button>
        <i onClick={handleClick} className={`cursor-pointer fa-solid fa-chevron-${state.filters.open ? 'up' : 'down' }`}></i>
      </div>
    </div>
    <Collapse in={state.filters.open}>
      <div className="collapse-content mt-2">
        <div className="grid-rows grid-template-columns-25-25-25-25">
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium">{CONTROLS.LABEL_EXCHANGE}</div>
            <MultiplyCustomSelector
              onChangeHandle={changeHandle}
              isMulti={true}
              closeMenuOnSelect={true}
              options={state.filters.exchangeSelector.list}/>
          </div>
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium">{CONTROLS.LABEL_MARKET}</div>
            <MultiplyCustomSelector
              onChangeHandle={changeHandle}
              isMulti={false}
              closeMenuOnSelect={true}
              options={state.filters.marketSelector.list}/>
          </div>
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium">{CONTROLS.LABEL_TRADING_PAIR}</div>
            <MultiplyCustomSelector
              onChangeHandle={changeHandle}
              isMulti={true}
              closeMenuOnSelect={true}
              options={state.filters.tradingPairSelector.list}/>
          </div>
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium">{CONTROLS.LABEL_DEAL_START_CONDITION}</div>
            <MultiplyCustomSelector
              onChangeHandle={changeHandle}
              isMulti={true}
              closeMenuOnSelect={true}
              options={state.filters.dealStartConditionSelector.list}/>
          </div>
        </div>
        <div className="collapse-content-wrapper grid-rows mt-1">
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_BOT_TYPE}</div>
            <div className="tabs d-flex">
              <TabWidget
                state={state.tabs.botTypeTab}
                actions={actions}
                data={[FORM_TABS.SINGLE_PAIR, FORM_TABS.MULTI_PAIR, FORM_TABS.ALL]}
                isFilters={true}
                width={'w-100'}/>
            </div>
          </div>
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_STRATEGY_TYPE}</div>
            <div className="tabs d-flex">
              <TabWidget
                state={state.tabs.strategyTab}
                actions={actions}
                data={[FORM_TABS.LONG, FORM_TABS.SHORT, FORM_TABS.ALLL]}
                isFilters={true}
                width={'w-100'}/>
            </div>
          </div>
          <div className="form-item pb-3">
            <div className="font-size-14 pb-1 font-weight-medium me-1">{CONTROLS.LABEL_DAYS_RUNNING}</div>
            <div className="d-flex align-items-center">
              <Range
                className="slider -success me-3"
                allowCross={false}
                {...settings.slider_days_running}
                draggableTrack
                max={365}
                min={1}
                onBeforeChange={onBeforeHandle}
                onAfterChange={value => onAfterChangeHandle({name: 'slider_days_running', value})}/>
              <InputGroup className="ms-2 me-2">
                <Form.Control
                  name="days_running_from"
                  value={state.filters.daysRunningFrom}
                  onChange={formControlChange}
                  type="number"/>
              </InputGroup>
              <i className="fa-light fa-minus ps-1 pe-1"></i>
              <InputGroup className="ms-2">
                <Form.Control
                  name="days_running_to"
                  value={state.filters.daysRunningTo}
                  onChange={formControlChange}
                  type="number"/>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  </div>
};