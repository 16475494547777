import { createContextStore } from 'easy-peasy';
import { investmentEffects } from '../pages/investmentPage/actions/investmentEffects';
import { uiActions } from '../pages/investmentPage/actions/uiActions';
import { constants } from '../pages/investmentPage/constants';

const { SORT_SELECT, PROTOCOLS_SELECT } = constants;

const INVESTMENT_MODEL = {
  protocolSelector: {
    list: PROTOCOLS_SELECT,
    value: PROTOCOLS_SELECT[0],
    isLoading: false,
    isDisabled: false,
  },
  chain: 'BSC',
  currentTxStatus: 0,
  tab: 'live',
  cheffAddress: '',
  selector: {
    list: SORT_SELECT,
    value: SORT_SELECT[0],
    isDisabled: false,
  },
  modal: {
    show: false,
    name: '',
    data: {},
  },
  allFarms: [],
  allPancakeFarms: [],
  allTraderJoeFarms: [],
  unstakeAmount: 0,
  stakeAmount: 0,
  stakedBalance: 0,
  tableData: {
    pagination: {
      hasMore: false,
      page: 0,
      pageSize: 20,
    },
    mergedFarms: [],
    rewardedToken: {},
    data: [],
    pancakeAllData: [],
    traderJoeAllData: [],
    quickAllData: [],
    allData: [],
    lpFarms: [],
    filteredData: [],
    toggled: [],
    pancakeSwapData: [],
    quickSwapData: [],
    traderJoeSwapData: [],
  },
  loader: false,
  ...uiActions.actionHandlers,
  ...investmentEffects.actionHandlers,
};

export const InvestmentState = createContextStore(INVESTMENT_MODEL);
