import React  from 'react';
import { constants } from '../constants';

const {
  TEXT: {
    HEALTHCHECK,
    TOTAL_PROFIT,
    NO_BTC_BOUGHT,
    BOUGHT_AMOUNT,
    AVERAGE_BUY_PRICE,
    CURRENT_MARKET_VALUE,
    NEXT_BUY_DATE,
    BUY_EVERY,
    DAYS
  }
} = constants;

export const ManageBotCards = props => {
  const { actions, state } = props;

  return <div className="manage-bot-cards-section mb-4">
    <div className="card-item">
      <div>{HEALTHCHECK}</div>
      <div className="font-size-18 font-weight-bold color-font-0">Normal</div>
    </div>
    <div className="card-item">
      <div>{TOTAL_PROFIT}</div>
      <div className="font-size-18 font-weight-bold color-font-0">{NO_BTC_BOUGHT}</div>
    </div>
    <div className="card-item">
      <div>{BOUGHT_AMOUNT}</div>
      <div className="font-size-18 font-weight-bold color-font-0">0 BTC</div>
      <div className="font-size-14">
        <span className="pe-1 color-font-2">{AVERAGE_BUY_PRICE}</span>
        <span className="font-weight-medium color-font-0"> 0 USDT</span>
      </div>
    </div>
    <div className="card-item">
      <div>{CURRENT_MARKET_VALUE}</div>
      <div className="font-size-18 font-weight-bold color-font-0">{NO_BTC_BOUGHT}</div>
    </div>
    <div className="card-item">
      <div>{NEXT_BUY_DATE}</div>
      <div className="font-size-18 font-weight-bold color-font-0">10/28/2022 10:00 AM</div>
      <div className="font-size-14">
        <span className="pe-1 color-font-2">{BUY_EVERY}</span>
        <span className="font-weight-medium color-font-0"> 7 {DAYS}</span>
      </div>
    </div>
  </div>
};
