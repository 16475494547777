import React from 'react';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { poolsEffects } from '../actions/poolsEffects';
import { PoolsState } from '../../../models/pools-model';
import { useStoreState } from 'easy-peasy';

export const EnableContent = props => {
    const { rewardAmount, rewardPrice, rewardToken, stakedTokenAddress, poolAddress } = props;
    const state = PoolsState.useStoreState(state => state);
    const actions = PoolsState.useStoreActions(actions => actions);
    const userWallet = useStoreState(state => state.authentication.userData.userId);

    const onEnableHandle = () => actions[poolsEffects.actionTypes.POOLS_APPROVE_INVOKED]({ to: stakedTokenAddress, from: userWallet, poolAddress });

    return <><div className="content-column me-5 d-flex-column">
        <div className="position-card p-3 harvest-block align-items-start">
            <div className="profit">
                <div className="mb-3"><span className="warning-color">{rewardToken.symbol}</span> Earned</div>
            </div>
            <div></div>
            <div className="profit">
                <div className="font-weight-medium">{rewardAmount} {rewardToken.symbol}</div>
                <div className="font-weight-medium">~ ${rewardPrice}</div>
            </div>
            <div className="boost d-flex align-items-start">
                <div className="mb-3"></div>
                <div className="font-weight-medium"></div>
                <div className='w-100 d-grid'><ButtonCustom disabled={rewardAmount <= 0} variant="primary" label='Harvest' /></div>
            </div>
        </div>
    </div>
    <div className="content-column d-flex-column">
        <div className="position-card p-3 harvest-block align-items-start">
            <div className="profit">
                <div className="mb-3">Enable Pool</div>
            </div>
            <div></div>
            <div className="boost d-flex align-items-start">
                <div className="mb-3"></div>
                <div className="font-weight-medium"></div>
                <ButtonCustom disabled={state.currentTxStatus} variant="primary" onClick={() => onEnableHandle()} label='Enable' />
            </div>
        </div>
    </div></>;
};