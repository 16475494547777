import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/homePage/templates/teamPage/actions/uiActions';
import { constants } from '../pages/homePage/templates/teamPage/constants';

const { TEAM: { departments } } = constants;

const TEAM_MODEL = {
    collapsed: departments,
    ...uiActions.actionHandlers
};

export const TeamState = createContextStore(TEAM_MODEL);