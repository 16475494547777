import React, { useEffect } from 'react';
import '../styles.scss';
import { AcademyState } from '../../../../../models/academy-model';
import Article from './Article';
import { constants } from '../constants';

const {
  ARTICLES: {
    buttons: { btnLoadMore },
  },
} = constants;

const Articles = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  let articles = AcademyState.useStoreState((state) => state.filterArticles);

  let location = window.location.search;
  let isPublic = location === '?elem=academy' ? true : false;

  const LoadMore = () => {
    if (isPublic && state.nextPage) {
      return (
        <div onClick={() => actions.PUBLIC_LOAD_MORE(state)} className="load-more">
          {btnLoadMore}
        </div>
      );
    }

    if (!isPublic && state.nextPage) {
      return (
        <div onClick={() => actions.ADMIN_LOAD_MORE()} className="load-more">
          {btnLoadMore}
        </div>
      );
    }
  };

  return (
    <>
      <div className={`articles sort-${state.sort.sortType}`}>
        {articles.map((article, index) => (
          <Article key={index} article={article} articleIndex={index} />
        ))}
      </div>

      {LoadMore()}
    </>
  );
};

export default Articles;
