import { action, thunk } from "easy-peasy";
import { serviceUtils } from "../../../service/serviceUtils";
import { notifyEffects } from "../../../components/utility/notifications/notifyEffects";
import { dataHelper } from "./dataHelper";
import { set } from "lodash";

const actionTypes = {
  MY_PROFILE_EXCHANGE_REMOVED: "MY_PROFILE_EXCHANGE#REMOVED",
  MY_PROFILE_EXCHANGE_REMOVE_SUCCEEDED: "MY_PROFILE_EXCHANGE_REMOVE#SUCCEEDED",
  MY_PROFILE_EXCHANGE_REMOVE_FAILED: "MY_PROFILE_EXCHANGE_REMOVE#FAILED",
  MY_PROFILE_EXCHANGES_LIST_UPDATED: "MY_PROFILE_EXCHANGES_LIST#UPDATED",
  MY_PROFILE_EXCHANGE_ADDED: "MY_PROFILE_EXCHANGE#ADDED",
};

const invokeHandle = (actions, payload) => {
  const { keyId } = payload;
  actions.updateState({ path: `loader.${keyId}`, value: true });
  serviceUtils.HttpService({
    url: "/uex",
    method: "DELETE",
    data: payload,
    errorActionType: actions[actionTypes.MY_PROFILE_EXCHANGE_REMOVE_FAILED],
    successActionType:
      actions[actionTypes.MY_PROFILE_EXCHANGE_REMOVE_SUCCEEDED],
  });
};

const successHandle = (state, payload) => {
  const { response } = payload;
  const notification = {
    type: "success",
    text: "You successfully remove exchange",
  };
  notifyEffects.notifyAction(notification);
  state.loader = {};
  state.exchangesList = dataHelper.parseExchanges(response);
  state.exchangeSynchronisation = new Date().getTime();
};

const errorHandle = (state, payload) => {
  const notification = { type: "danger", text: "Something goes wrong" };
  notifyEffects.notifyAction(notification);
  state.loader = {};
};

const exchangesHandle = (state, payload) => {
  state.exchangesList = dataHelper.parseExchanges(payload);
};

const updateStateHandle = (state, payload) => {
  const { path, value } = payload;
  set(state, path, value);
};

const exchangeAddHandle = (state, payload) => {
  state.isModalOpen = payload;
};

const actionHandlers = {
  [actionTypes.MY_PROFILE_EXCHANGE_REMOVED]: thunk(async (actions, payload) =>
    invokeHandle(actions, payload)
  ),
  [actionTypes.MY_PROFILE_EXCHANGE_REMOVE_SUCCEEDED]: action((state, payload) =>
    successHandle(state, payload)
  ),
  [actionTypes.MY_PROFILE_EXCHANGE_REMOVE_FAILED]: action((state, payload) =>
    errorHandle(state, payload)
  ),
  [actionTypes.MY_PROFILE_EXCHANGES_LIST_UPDATED]: action((state, payload) =>
    exchangesHandle(state, payload)
  ),
  [actionTypes.MY_PROFILE_EXCHANGE_ADDED]: action((state, payload) =>
    exchangeAddHandle(state, payload)
  ),
  updateState: action((state, payload) => updateStateHandle(state, payload)),
};

export const exchangeEffects = {
  actionTypes,
  actionHandlers,
};
