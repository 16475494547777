import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { constants } from '../constants';
import { ProfileState } from '../../../models/profile-model';
import { profilePageActions } from '../actions/uiActions';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { profileEffects } from '../actions/profileEffects';

const {
  ACTIONS: { POPUP_CREATE_PROFILE, POPUP_TYPE_NAME, DELETE_MESSAGE, POPUP_DELETE_PROFILE },
} = constants;

export const ProfileModal = (props) => {
  const { show, actions, title,index} = props;
  const handleClose = () =>
    actions[profilePageActions.actionTypes.PROFILE_EDIT_INVOKED]({
      name: 'close-modal',
      modal: title,
    });
  // const schema = yup.object().shape({
  //   profileName: yup.string().required
  // })
  //console.log('index: ', index)
  const state = ProfileState.useStoreState((state) => state);
  const handleAddProfile = (value) => {actions[profileEffects.actionTypes.PROFILE_NEW_PROFILE_INVOKED]({values: {title: value.profileName, id: uuidv4(), isWalletAddress: false}, method: 'add',})
    //console.log('state: ', state)
  }
  const handleDeleteWallet = ()=>{
  const walletList = state.walletData?.find(item => item.cardID === state.deleteId)?.walletAdrese
  let position
  // console.log('state counter: ', state.counter)
  // console.log('wallet list: ', walletList)
  for(let i=0;i<walletList?.length;i++)
  {
    if(walletList[i].id == state.walletId)
      position = i
    // console.log('position of wallet: ', position)
  }
  
  state.walletData?.find(item => item.cardID === state.deleteId).walletAdrese.splice(position, 1)
  


    for (let i = 0; i < walletList.length; i++) {
      if (walletList[i].id == state.walletId) position = i;
    }

    state.walletData?.find((item) => item.index == state.counter).walletAdrese.splice(position, 1);
  }

  return (
    <div className="popup-style">
      <Modal
        show={show}
        size="sm"
        centered
        className="profile-created"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className=".flex-container"></Modal.Body>
        <Modal.Footer>
          <Formik
            //  validationSchema={schema}
            initialValues={{
              profileName: '',
            }}
            onSubmit={(values) => {
              title === 'NEW PROFILE' ? handleAddProfile(values) : handleDeleteWallet();
              handleClose();
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form onSubmit={handleSubmit} className="w-100">
                {title === 'NEW PROFILE' ? (
                  <Form.Group className="mb-3" controlId="test">
                    <Form.Control
                      type="text"
                      name="profileName"
                      placeholder={POPUP_TYPE_NAME}
                      value={values.profileName}
                      onChange={handleChange}
                      size="lg"
                      required
                    ></Form.Control>
                  </Form.Group>
                ) : (
                  <div>{DELETE_MESSAGE}</div>
                )}
                <div className="create-button">
                  {title === 'NEW PROFILE' ? (
                    <Button
                      type="submit"
                      size="lg"
                      className="font-size-14 font-weight-medium w-100"
                    >
                      {POPUP_CREATE_PROFILE}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      size="lg"
                      className="font-size-14 font-weight-medium mt-2 w-100"
                    >
                      {POPUP_DELETE_PROFILE}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
