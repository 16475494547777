import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { constants } from '../../../constants/constants';
import { MockButtonsData } from '../../../components/utility/custom-buttons/mock-buttons-data';
import { CustomSliderRange } from '../../../components/utility/sliders/view';
import { CustomDropdown } from '../../../components/utility/custom-dropdown/view';

const { SMART_TRADE_BOT: { TABS } } = constants;

export const TabsWidget = () => {
    return <div className="h-100">
        <Tabs
            defaultActiveKey="smart"
            transition={false}
            id="tabs-widget">
            <Tab eventKey="smart" title={TABS.smart}>
                <MockButtonsData/>
            </Tab>
            <Tab eventKey="active" title={TABS.active}>
                <CustomSliderRange/>
            </Tab>
            <Tab eventKey="history" title={TABS.history}>
                <div className="" style={{ 'width': '300px' }}>
                    <CustomDropdown items={['1hgjhgjhgj', '2gfhgfhgfhfhg', '3jkhkjhkjhkjhkjh', '4hgjhgjhghjgjh', '5hgjhgjhgjhgjhg', '6', '7']} value={'Dropdown'}/>
                </div>
            </Tab>
        </Tabs>
    </div>;
};