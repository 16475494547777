import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { utils } from '../../../utils/utils';
import { dataHelper } from '../dataHelpers/dataHelper';

const actionTypes = {
    LENDING_USER_FETCHED: 'LENDING_USER#FETCHED',
    LENDING_USER_FETCH_SUCCEEDED: 'LENDING_USER_FETCH#SUCCEEDED',
    LENDING_MARKET_FETCHED: 'LENDING_MARKET#FETCHED',
    LENDING_MARKET_FETCH_SUCCEEDED: 'LENDING_MARKET_FETCH#SUCCEEDED',
    LENDING_USER_FETCH_FAILED: 'LENDING_USER_FETCH#FAILED',
    LENDING_COMPOUND_TABLEDATA: 'LENDING_COMPOUND#TABLEDATA',
    LENDING_VENUS_TABLEDATA: 'LENDING_VENUS#TABLEDATA',
    FETCH_ALL_LENDING_DATA: 'FETCH_ALL_LENDING#DATA'
};

const fetchUserHandle = (actions, payload) => {
    const { userWallet, protocol } = payload;

    if(protocol === 'joe') {
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/${protocol}lending?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_USER_FETCH_SUCCEEDED]
        });
    }
  
    else {
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/${protocol}?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_USER_FETCH_SUCCEEDED]
        });
    }
};

const fetchAllProtocolsHandle = (actions, payload) => {
    const { protocol ,userWallet } = payload;
    if(protocol === 'joe') {
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/venuslending?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_VENUS_TABLEDATA]
        });
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/compoundlending?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_COMPOUND_TABLEDATA]
        });

    }
    else {
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/venus?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_VENUS_TABLEDATA]
        });
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/compound?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_COMPOUND_TABLEDATA]
        });
    }
};

const fetchMarketHandle = (actions, payload) => {
    const { protocol, userWallet } = payload;
    
    if(protocol === 'joe') {
        serviceUtils.HttpService({
            url: `/web3/${protocol}LendingData?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_MARKET_FETCH_SUCCEEDED]
        });
    }
    else {
        serviceUtils.HttpService({
            url: `https://8goqgk0837.execute-api.eu-west-1.amazonaws.com/staging/web3/${protocol}data?address=${userWallet}`,
            errorActionType: actions[actionTypes.LENDING_USER_FETCH_FAILED],
            successActionType: actions[actionTypes.LENDING_MARKET_FETCH_SUCCEEDED]
        });
    }
};

const successMarketHandle = (state, payload) => {
    const { response: { markets, comptroller } } = payload;

    state.loader = false;
    if (state.typeOfFetch === 'push') {
        state.tableData.supplyMarket = [...state.tableData.supplyMarket, ...markets];
        state.tableData.borrowMarket = [...state.tableData.borrowMarket, ...markets];
    }
    else {
        state.tableData.supplyMarket = markets;
        state.tableData.borrowMarket = markets;
    }
       
    state.protocolSelector.list = dataHelper.getFormattedAssetList(markets);
    state.protocolSelector.value = state.protocolSelector.list[0];
    state.comptroller = comptroller;
};

const successUserHandle = (state, payload) => {
    const { response: { tokens, netApy, maxAllowed, borrowLimitUsed, rewardXVS } } = payload;
    const suppliedUser = dataHelper.getSupplied(tokens);
    const borrowedUser = dataHelper.getBorrowed(tokens);
    state.loader = false;
    if (state.typeOfFetch === 'push') {
        state.tableData.supplyUser = [...state.tableData.supplyUser, ...suppliedUser];
        state.tableData.borrowUser = [...state.tableData.borrowUser, ...borrowedUser];
    }
    else {
        state.tableData.supplyUser = suppliedUser;
        state.tableData.borrowUser = borrowedUser;
    }

    state.netApy = netApy;
    const safeLimit = maxAllowed * 0.8;
    state.safeLimit = safeLimit.toFixed(2);
    state.borrowLimitUsed = borrowLimitUsed;
    state.limit = maxAllowed;
    state.borrowBalance = dataHelper.getBorrowBalance(borrowedUser);
    const supplyPrice = dataHelper.getSupplyBalance(suppliedUser);
    state.supplyBalance = supplyPrice;
    state.dailyEarnings = dataHelper.getDailyEarning(netApy, supplyPrice);
    state.rewardXVS = rewardXVS;
};

const successVenusUserHandle = (state, payload) => {
    const { response: { tokens} } = payload;
    const suppliedUser = dataHelper.getSupplied(tokens);
    const borrowedUser = dataHelper.getBorrowed(tokens);
    state.venusTableData.supplyUser = suppliedUser;
    state.venusTableData.borrowUser = borrowedUser;

  
    state.venusBorrowBalance = dataHelper.getBorrowBalance(borrowedUser);
    state.venusSupplyBalance = dataHelper.getSupplyBalance(suppliedUser);
//    state.venusTableData.supplyUser = dataHelper.setProtocols(suppliedUser,'Venus');
  //  state.venusTableData.borrowUser = dataHelper.setProtocols(borrowedUser,'Venus');

};

const successCompoundUserHandle = (state, payload) => {
    const { response: { tokens} } = payload;
    const suppliedUser = dataHelper.getSupplied(tokens);
    const borrowedUser = dataHelper.getBorrowed(tokens);
    state.compoundTableData.supplyUser = suppliedUser;
    state.compoundTableData.borrowUser = borrowedUser;

    state.compaundBorrowBalance = dataHelper.getBorrowBalance(borrowedUser);
    state.compaundSupplyBalance = dataHelper.getSupplyBalance(suppliedUser);
   // state.compoundTableData.supplyUser = dataHelper.setProtocols(suppliedUser,'Compound');
    //state.compoundTableData.borrowUser = dataHelper.setProtocols(borrowedUser,'Compound');
};

const errorHandle = (state, payload) => {};



const actionHandlers = {
    [actionTypes.LENDING_MARKET_FETCHED]: thunk(async (actions, payload) => fetchMarketHandle(actions, payload)),
    [actionTypes.LENDING_USER_FETCHED]: thunk(async (actions, payload) => fetchUserHandle(actions, payload)),
    [actionTypes.LENDING_USER_FETCH_SUCCEEDED]: action((state, payload) => successUserHandle(state, payload)),
    [actionTypes.LENDING_MARKET_FETCH_SUCCEEDED]: action((state, payload) => successMarketHandle(state, payload)),
    [actionTypes.LENDING_USER_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.LENDING_VENUS_TABLEDATA]: action((state, payload) => successVenusUserHandle(state, payload)),
    [actionTypes.LENDING_COMPOUND_TABLEDATA]: action((state, payload) => successCompoundUserHandle(state, payload)),
    [actionTypes.FETCH_ALL_LENDING_DATA]: thunk(async (actions, payload) => fetchAllProtocolsHandle(actions, payload)),
    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const factory = {
    actionTypes,
    actionHandlers
};