import React, { useState } from "react";
import { constants } from "../constants";
import { DoughnutChart } from "../../../components/utility/chart-doughnut/view";
import { uiActions } from "../actions/uiActions";
import { AnalyticsModal } from "./analyticsModal";
import { dataHelper } from "../dataHelpers/dataHelper";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { ButtonCustom } from "../../../components/utility/custom-buttons/view";
import { farmsEffects } from "../../farmsPage/actions/farmsEffects";
import { poolsEffects } from "../../poolsPage/actions/poolsEffects";
import { useStoreState } from "easy-peasy";
import { lendingEffects } from "../../dashboardLending/actions/lendingEffects";
import { utils } from "../../../utils/utils";

const {
  ANALYTICS: { SHOW_ALL, TOKEN, PROTOCOL, CHAIN },
  ACCOUNT,
  COLORS,
} = constants;

export const AnalyticsContent = (props) => {
  const { actions, mockData, state, pageId } = props;
  const [balance, setBalance] = useState();
  const userWallet = useStoreState(
    (state) => state.authentication.userData.userId
  );
  const venusBalance = dataHelper.getVenusSum(state.venus.data);
  const compoundBalance = dataHelper.getCompoundSum(state.compound.data);
  const poolsBalance = dataHelper.getFarmsPoolsSum(state.pools.data);
  const farmsBalance = dataHelper.getFarmsPoolsSum(state.farms.data);
  const filteredList = dataHelper.filterDataList(state.wallet.filteredData);
  const walletBalance = dataHelper.getWalletSum(filteredList);
  let chartTokenAllocation= [];
  const tokenAllocation = state.chains.picked === "All" || state.chains.picked === "Others" ? dataHelper.getTokensFraction(state.wallet.filteredData) : dataHelper.getTokensFraction(state.wallet.filteredData);
  const modalOpen = (name, data, heading) =>
    actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({
      name,
      data,
      heading,
    });
   
  const totalLendingBalance = compoundBalance + venusBalance;
  const protocolArr = [
    { valueSymbol: "Wallet", usdValue: walletBalance },
    { valueSymbol: "Lending", usdValue: totalLendingBalance },
    { valueSymbol: "Pools", usdValue: poolsBalance },
    { valueSymbol: "Farms", usdValue: farmsBalance },
  ];

 if(tokenAllocation.length === 0){ 
  chartTokenAllocation = [];
 }
 else{
  chartTokenAllocation = [...tokenAllocation.slice(0, 4),{
    asset: "Others",
    fraction: dataHelper.getOthersFraction(tokenAllocation),
  },
];
 }
  

  const protocolAllocation = dataHelper.getTokensFraction(protocolArr);

  const location = useLocation();

  let pageContent;
  let pageContentTotalCollect;
  let totalToCollect;
  let buttonAvailability;
  const sumReward = (array) => {
    try {
      if (Array.isArray(array) && array.length) {
        if (array.length > 1) {
          array.reduce((rewardA, rewardB) => {
            totalToCollect =
              Number(rewardA.reward_price) + Number(rewardB.reward_price);
          });
          pageContentTotalCollect = (
            <div className="value">~ ${totalToCollect.toFixed(2)}</div>
          );
        } else {
          pageContentTotalCollect = (
            <div className="value">
              ~ ${Number(array[0].reward_price).toFixed(2)}
            </div>
          );
        }
      }
      // reward_price
    } catch (error) {
      console.log(error);
    }
  };

  let stakedValue;
  let showPage;
  const stakeReward = (array) => {
    try {
      if (Array.isArray(array) && array.length) {
        if (array.length > 1) {
          array.reduce((rewardA, rewardB) => {
            stakedValue =
              Number(rewardA.staked_price) + Number(rewardB.staked_price);
          });
          showPage = <div className="value">~ ${stakedValue.toFixed(2)}</div>;
        } else {
          showPage = (
            <div className="value">
              ~ ${Number(array[0].staked_price).toFixed(2)}
            </div>
          );
        }
      }
      // reward_price
    } catch (error) {
      console.log(error);
    }
  };

  const calculateXVSBalanceToUsd = async (venus) => {
    let price = await venus.getPrice(Venus.XVS);
    totalToCollect = Number(state.rewardXVS) * price;
    setBalance(totalToCollect.toFixed(2));
  };

  switch (pageId) {
    case 1:
      const onPoolsHarvest = (address) =>
        actions[poolsEffects.actionTypes.POOLS_REWARD_HARVEST_INVOKED]({
          from: userWallet,
          to: address,
        });
      const collectAllPools = () => {
        state.tableData.data.map((index) => {
          onPoolsHarvest(index.pid, state.chain, index.cheffAddress);
        });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.filteredData.length}
          variant="primary"
          label="Harvest all"
          onClick={collectAllPools}
        />
      );
      sumReward(state.tableData.data);
      stakeReward(state.tableData.data);
      pageContent = (
        <div className="description">
          to collect from {state.tableData.data.length} pools
        </div>
      );
      break;
    case 2:
      const onFarmHarvest = (pid, chain, contract) => {
        actions[farmsEffects.actionTypes.FARMS_REWARD_HARVEST_INVOKED]({
          pid,
          from: userWallet,
          chain,
          cheff: contract,
        });
      };
      const collectAllFarm = () => {
        state.tableData.data.map((index) => {
          onFarmHarvest(index.pid, state.chain, index.cheffAddress);
        });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.filteredData.length}
          variant="primary"
          label="Harvest all"
          onClick={collectAllFarm}
        />
      );

      sumReward(state.tableData.data);
      stakeReward(state.tableData.data);
      pageContent = (
        <div className="description">
          to collect from {state.tableData.data.length} farm
        </div>
      );

      break;
    case 3:
      const venus = new Venus(window.ethereum);

      calculateXVSBalanceToUsd(venus);
      pageContentTotalCollect = <div className="value">~ ${balance}</div>;
      const handleHarvestAll = (venus) => {
        actions[lendingEffects.actionTypes.LENDING_COLLECT_INVOKED]({ venus });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.supplyUser.length}
          variant="primary"
          label="Harvest all"
          onClick={() => handleHarvestAll(venus)}
        />
      );
      pageContent = <div className="description">to collect</div>;
      break;
  }

  return (
    <div className="analytics-content">
      <div className="analytics-item">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">
            {TOKEN.HEADING.toUpperCase()}
          </div>
          {!isEmpty(state.wallet.data) ? (
            <div
              className="show-all font-size-14"
              onClick={
                () =>
                  modalOpen(
                    "open-modal",
                    tokenAllocation,
                    TOKEN.HEADING.toUpperCase()
                  )
                //  actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'showAllVaults' })
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {!isEmpty(state.wallet.data) ? (
            <>
              <div className="analytics-chart d-flex align-items-center">
                <DoughnutChart data={chartTokenAllocation} />
              </div>
              <div className="analytics-chart-legend">
                {chartTokenAllocation.map((protocol, index) => (
                  <div key={index} className="legend-item">
                    <div className="d-flex align-items-center title-wrapper">
                      <div
                        style={{ background: COLORS[index] }}
                        className="color-circle me-1"
                      ></div>
                      <div className="legend-value">{protocol.asset}</div>
                    </div>
                    <div className="protocol-value font-weight-medium">
                      {protocol.fraction}%
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            "No Data"
          )}
        </div>
      </div>
      <div className="analytics-item align-items-center">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">
            {PROTOCOL.HEADING.toUpperCase()}
          </div>
          {!isEmpty(state.wallet.data) ? (
            <div
              className="show-all font-size-14"
              onClick={() =>
                modalOpen(
                  "open-modal",
                  state.protocols,
                  PROTOCOL.HEADING.toUpperCase()
                )
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {!isEmpty(state.wallet.data) ? (
            <>
              <div className="analytics-chart d-flex align-items-center">
                <DoughnutChart data={protocolAllocation} />
              </div>
              <div className="analytics-chart-legend">
                {protocolAllocation.map((protocol, index) => (
                  <div key={protocol.asset} className="legend-item">
                    <div className="d-flex align-items-center title-wrapper">
                      <div
                        style={{ background: COLORS[index] }}
                        className="color-circle me-1"
                      ></div>
                      <div className="legend-value">{protocol.asset}</div>
                    </div>
                    <div className="protocol-value font-weight-medium">
                      {protocol.fraction}%
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            "No Data"
          )}
        </div>
      </div>
      {location.search === "?elem=defidashboard" ? (
        <div className="analytics-item align-items-center">
          <div className="analytics-item-header">
            <div className="heading font-weight-medium">{CHAIN.HEADING}</div>
            {!isEmpty(state.wallet.data) ? (
              <div
                className="show-all font-size-14"
                onClick={() =>
                  modalOpen(
                    "open-modal",
                    dataHelper.getChainsFraction(state.wallet.filteredData),
                    CHAIN.HEADING
                  )
                }
              >
                {SHOW_ALL}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="analytics-item-content align-items-center">
            {!isEmpty(state.wallet.data) ? (
              <>
                <div className="analytics-chart d-flex align-items-center">
                  <DoughnutChart
                    data={dataHelper.getChainsFraction(
                      state.wallet.filteredData
                    )}
                  />
                </div>
                <div className="analytics-chart-legend">
                  {dataHelper
                    .getChainsFraction(state.wallet.filteredData)
                    .map((chain, index) => (
                      <div key={index} className="legend-item">
                        <div className="d-flex align-items-center title-wrapper">
                          <div
                            style={{ background: COLORS[index] }}
                            className="color-circle me-1"
                          ></div>
                          <div className="legend-value">{chain.asset}</div>
                        </div>
                        <div className="protocol-value font-weight-medium">
                          {chain.fraction}%
                        </div>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              "No Data"
            )}
          </div>{" "}
          
        </div>
      ) : (
        <>
          {" "}
          <div className="analytics-item align-items-center ">
            <div className="header-screens">
              <div className="heading-wrapper">
                <div className="heading"> {ACCOUNT.HEADING} </div>
                <div className="select-account">Select account</div>
              </div>
              {location.search === "?elem=lending" ? (
                <div className="precent">{state.netApy}</div>
              ) : (
                <div className="precent">3.31%</div>
              )}
              <div className="precentDescription">{ACCOUNT.NET_APY}</div>
              <div className="values-wrapper">
                <div className="value-wrapper">
                  {location.search === "?elem=lending" ? (
                    <div className="value">${state.dailyEarnings}</div>
                  ) : (
                    <div className="value">3.31%</div>
                  )}
                  <div className="description">{ACCOUNT.DAILY_SUMMARY}</div>
                </div>
                <div className="value-wrapper">
                  {location.search === "?elem=lending" ? (
                    <div className="value"> ${state.supplyBalance} </div>
                  ) : (
                    showPage
                  )}

                  <div className="description">{ACCOUNT.INVESTED_BALANCE}</div>
                </div>
                {/* <div className="value-wrapper">
                  <div className="value">$5.51</div>
                  <div className="description">{ACCOUNT.CUMULATIVE_APY}</div>
                </div> */}
              </div>

              <div className="harvest-all-wrapper">
                <div className="value-wrapper">
                  {pageContentTotalCollect}
                  {pageContent}
                </div>
                <div className="button-widget">{buttonAvailability}</div>
              </div>
            </div>
          </div>
        </>
      )}
      {location.search === "?elem=defidashboard" ? (
        <AnalyticsModal
          show={state.modalChart.show}
          actions={actions}
          data={state.analytics.analyticsData}
        />
      ) : (
        <AnalyticsModal
          show={state.modalChart.show}
          actions={actions}
          data={state.analytics.analyticsData}
        />
      )}
    </div>
  );
};
