/* eslint-disable max-len */
import React from 'react';

export const TRADE_PLANET = <>
    <path d="M85.2775 20.2849C89.6054 20.2849 93.1138 16.7765 93.1138 12.4486C93.1138 8.12074 89.6054 4.6123 85.2775 4.6123C80.9496 4.6123 77.4412 8.12074 77.4412 12.4486C77.4412 16.7765 80.9496 20.2849 85.2775 20.2849Z" fill="url(#paint0_radial_107_2615)"/>
    <path d="M73.3821 72.0078C98.7323 57.3718 115.632 39.1836 111.128 31.3833C106.625 23.583 82.4236 29.1244 57.0734 43.7604C31.7232 58.3963 14.8236 76.5845 19.3271 84.3848C23.8306 92.1851 48.0318 86.6437 73.3821 72.0078Z" stroke="#B8B6B6" strokeMiterlimit="10" strokeDasharray="15 15"/>
    <path d="M91.7325 55.3154C95.0427 55.3154 97.7261 57.9982 97.7261 61.3076C97.7261 64.6171 95.0427 67.2999 91.7325 67.2999C88.4224 67.2999 85.739 64.6171 85.739 61.3076C85.739 57.9982 88.4224 55.3154 91.7325 55.3154Z" fill="url(#paint1_linear_107_2615)"/>
    <path d="M127.609 47.4323C128.65 49.1941 128.542 51.631 127.261 54.6386C125.985 57.6348 123.586 61.0966 120.232 64.8052C113.529 72.2176 103.093 80.5291 90.51 87.9666C77.927 95.404 65.6135 100.539 55.8874 102.838C51.0214 103.988 46.8316 104.421 43.5916 104.093C40.3393 103.765 38.152 102.685 37.1107 100.923C36.0693 99.1612 36.178 96.7244 37.4586 93.7167C38.7342 90.7206 41.1337 87.2587 44.4874 83.5502C51.1906 76.1377 61.6266 67.8262 74.2097 60.3888C86.7927 52.9513 99.1062 47.8163 108.832 45.518C113.698 44.3681 117.888 43.9352 121.128 44.2623C124.38 44.5907 126.568 45.6705 127.609 47.4323Z" stroke="#B8B6B6"/>
    <path d="M98.9028 14.7277C99.8251 16.2881 99.7804 18.5359 98.5254 21.4838C97.2801 24.4086 94.9204 27.8233 91.5857 31.5108C84.9247 38.8766 74.5319 47.158 61.9799 54.5771C49.4278 61.9963 37.1615 67.108 27.4967 69.3918C22.6582 70.5352 18.529 70.9559 15.3661 70.6365C12.1784 70.3147 10.1877 69.27 9.26536 67.7096C8.34306 66.1492 8.38776 63.9015 9.64286 60.9536C10.8882 58.0287 13.2478 54.6141 16.5825 50.9265C23.2435 43.5608 33.6363 35.2793 46.1883 27.8602C58.7404 20.4411 71.0068 15.3294 80.6715 13.0456C85.51 11.9022 89.6392 11.4815 92.8021 11.8008C95.9898 12.1227 97.9805 13.1673 98.9028 14.7277Z" stroke="#B8B6B6" strokeWidth="2"/>
    <path d="M40.6988 17.5137C47.392 17.5137 52.818 22.9385 52.818 29.6304C52.818 36.3222 47.392 41.747 40.6988 41.747C34.0055 41.747 28.5796 36.3222 28.5796 29.6304C28.5796 22.9385 34.0055 17.5137 40.6988 17.5137Z" fill="url(#paint2_linear_107_2615)"/>
    <defs>
        <radialGradient id="paint0_radial_107_2615" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(85.2775 12.4486) rotate(90) scale(7.83632 7.83632)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint1_linear_107_2615" x1="81.6922" y1="53.8342" x2="107.051" y2="72.7227" gradientUnits="userSpaceOnUse">
            <stop offset="0.18" stopColor="#1F55FE"/>
            <stop offset="0.32" stopColor="#0F9AFF"/>
            <stop offset="0.44" stopColor="#05CAFE"/>
            <stop offset="0.56" stopColor="#00EBC1"/>
            <stop offset="0.78" stopColor="#01BF4A"/>
            <stop offset="1" stopColor="#01F2AE"/>
        </linearGradient>
        <linearGradient id="paint2_linear_107_2615" x1="20.3967" y1="14.5187" x2="71.6741" y2="52.7121" gradientUnits="userSpaceOnUse">
            <stop offset="0.18" stopColor="#1F55FE"/>
            <stop offset="0.32" stopColor="#0F9AFF"/>
            <stop offset="0.44" stopColor="#05CAFE"/>
            <stop offset="0.56" stopColor="#00EBC1"/>
            <stop offset="0.78" stopColor="#01BF4A"/>
            <stop offset="1" stopColor="#01F2AE"/>
        </linearGradient>
    </defs>
</>;