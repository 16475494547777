import React from 'react';
import { BuySellStepItem } from './buySellStepItem';
import { constants } from '../../constants';

const {
  TABLE_COLLAPSE_CONTENT: {
    STEPS: {
      BUY_STEPS_LABEL,
      SELL_STEPS_LABEL,
      STEPS_PRICE,
      STEPS_VOLUME,
      STEPS_STATUS
    }
  },
} = constants;

const tableTitles = () => {
  const arr = [STEPS_PRICE, STEPS_VOLUME, STEPS_STATUS]

  return (
    <div className="sell-steps">
      {arr.map((item) =>
        <div key={item} className="step-item">
          <span className="step-item-header">{item}</span>
        </div>
      )}
    </div>
  )
}

export const BuySellStepsTemplate = (props) => {
  const { data  } = props;
  const buySteps = data.filter(buy => buy.trade.side === 'BUY');
  const sellSteps = data.filter(sell => sell.trade.side === 'SELL');

  return (
    <>
      {buySteps.length > 0 &&
        <div className="sell-steps-wrapper">
          <span className="step-title">{BUY_STEPS_LABEL}:</span>
          {tableTitles()}
          {buySteps.map((item) => (
            <BuySellStepItem
              item={item}
              key={item.orderId}
            />
          ))}
        </div>
      }

      {sellSteps.length > 0 &&
        <div className="sell-steps-wrapper">
          <span className="step-title">{SELL_STEPS_LABEL}:</span>
          {tableTitles()}
          {sellSteps.map((item) => (
            <BuySellStepItem
              item={item}
              key={item.orderId}
            />
          ))}
        </div>
      }
    </>
  );
}