import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './styles.scss';


const renderExample = () => {
    return <>
        <div className="example-container d-flex justify-content-center align-content-center">
            <div className="construction-sign"></div>
        </div>
    </>;
};

export const EmptyContent = () => {
    const { id } = useParams();
    const { search } = useLocation();
    const menuId = search.replace('?elem=', '');
    const breadCrumbs = () => <div>
        <span className="pe-2">{`${id.toUpperCase()}`}</span>
        <i className="fa-regular fa-angle-right"></i>
        <span className="ps-2">{ `${menuId.toUpperCase()}`}</span>
    </div>;

    return <div className="p-4">
        <div>{ breadCrumbs() }</div>
        { renderExample() }
    </div>;
};