/* eslint-disable indent */
import { headerEffects } from '../components/header/headerEffects';
import { mainEffects } from '../components/priceFetcher/mainEffects';
import { constants } from '../constants/constants';
import { tableWidgetEffects } from '../pages/dashboard/widgets/table-widget/tableEffects';
import { barChartEffects } from '../pages/dashboard/widgets/bar-chart/barChartEffects';
import { balanceEffects } from '../components/balance/balanceEffects';
import { rootContentEffects } from '../components/rootContent/rootContentEffects';
import { exchangeModalEffects } from '../components/utility/add-exchange-modal/exchangeModalEffects';
import { botsEffects } from '../pages/dashboard/widgets/bots-widget/botsEffects';
import { websocketEffect } from '../components/priceFetcher/websocketEffects';
import { refreshEffects } from '../components/priceFetcher/refreshEffects';
import { standardOptionsEffects } from '../components/utility/standard-options/standardOptionsEffects';
import { favoritesEffects } from '../components/favorites/favoritesEffects';
import { breakPointEffects } from '../components/rootContent/breakPointEffects';
import { messagesEffects } from '../components/messages/messagesEffects';
import { predictionsEffect } from '../components/predictions/predictionsEffect';
import { authorizationEffect } from '../components/metaMaskAthorization/actions/authorizationEffect';
import { walletConnectAuthorizationEffect } from '../components/walletConnectAuthorization/actions/authorizationEffect';
import { uiActions } from '../pages/dynamicSplitPage/actions/uiActions';
import { bugReportActions } from '../components/bugReportModal/actions/uiActions';
import { loginModalActions } from '../components/loginModal/uiActions';
import { tableEffects } from '../pages/smartTradePage/actions/tableEffects';
import { customBotsActions } from '../pages/customBots/actions/uiActions';
import { binanceConnectAuthorizationEffect } from '../components/binanceConnectAuthorization/actions/authorizationEffect';
import { remakeDashboardEffects } from '../pages/remakeDashboardPage/actions/remakeDashboardEffects';
import { profilePageActions } from '../pages/profilePage/actions/uiActions';
import { investmentEffects } from '../pages/investmentPage/actions/investmentEffects';

const { THEME, FARM_POOLS_SELECT } = constants;

const MAIN_MODEL = {
  authentication: {
    user: null,
    connectionButton: true,
    loader: true,
    loaderMetamask: false,
    loaderWalletConnect: false,
    loaderBinance: false,
    loaderLogin: false,
    userData: {},
    userInfo: {},
    counter: 5,
    error: '',
    interceptorIsSet: false,
    authenticationMethod: '',
  },
  loaders: {
    main: true,
    table: true,
    bots: false,
    feeds: false,
    signals: false,
    history: false,
    login: true,
  },
  socket: {
    methods: {},
  },
  sizes: {
    values: {
      screenWidth: 0,
      screenHeight: 0,
    },
    breakPointClass: '',
    verticalPointClass: '',
    horizontalPointClass: '',
    headerWidth: 0,
  },
  root: {
    theme: THEME.NIGHT,
    initialPath: '/portfolio?elem=dashboard',
    isUserLogged: true,
    language: 'eng',
    languagePanelState: false,
  },
  messages: {
    messageData: [],
    modalData: {},
    visibility: false,
  },
  coinData: {
    usedCoins: [],
    requestParams: [],
    prices: [],
    exchanges: {},
    exchangesWithPrices: {},
    favorites: null,
    buffer: null,
    limitedExchangeUsedCoins: [],
    exchangesAllUsedCoins: [],
    timeStamp: 0,
    totalUSDT: 0,
  },
  dashboard: {
    expanded: false,
    usedCoins: [],
    message: {},
    expandedName: '',
    breakPointClass: '',
  },
  tableWidget: {
    exchange: { id: 'all_exchanges', label: 'All Exchanges' },
    currency: 'USDT',
    toggleAddExchangeModal: false,
    volume: true,
  },
  barChartWidget: {
    data: {},
    currentExchange: {},
    currentType: {},
    typeList: [],
    exchangesList: [],
  },
  botsWidget: {
    sortBy: { id: 'startDate', label: 'Start date' },
    filterBy: {},
    originalData: [],
    mutableData: [],
    data: {},
  },
  balance: {
    currentTime: '',
    currentDate: '',
  },
  feed: {
    data: [],
  },
  signal: {
    data: [],
    searchValue: '',
  },
  favoritesMenu: {
    value: {},
    collapseState: false,
    isEditMode: false,
    deletedBuffer: [],
    openAddModal: false,
    addValue: '',
  },
  assetsData: {},
  exchangeCoins: [],
  availableExchangesList: [],
  dropdown: {
    value: null,
  },
  predictions: [],
  workBoard: {
    changeLink: {
      show: -1,
    },
    clickedFavorites: '',
    clickedUrl: '',
  },
  cardsList: [],
  report: {
    showBugReportModal: false,
    showReportSuccses: false,
    showReportError: false,
    files: [],
  },
  loginModal: {
    showModal: false,
    showTerms: false,
    approveTerms: false,
  },
  smartTrade: {
    modalCancelTrade: {
      show: -1,
    },
    modalCloseTrade: {
      show: -1,
    },
    modalAddReduceFunds: {
      show: -1,
      name: '',
    },
  },
  investment: {
    farmsPoolsSelector: {
      list: FARM_POOLS_SELECT,
      value: FARM_POOLS_SELECT[0],
    },
  },

  ...authorizationEffect.actionHandlers,
  ...walletConnectAuthorizationEffect.actionHandlers,
  ...binanceConnectAuthorizationEffect.actionHandlers,
  ...headerEffects.actionHandlers,
  ...mainEffects.actionHandlers,
  ...tableWidgetEffects.actionHandlers,
  ...barChartEffects.actionHandlers,
  ...balanceEffects.actionHandlers,
  ...rootContentEffects.actionHandlers,
  ...botsEffects.actionHandlers,
  ...websocketEffect.actionHandlers,
  ...exchangeModalEffects.actionHandlers,
  ...refreshEffects.actionHandlers,
  ...standardOptionsEffects.actionHandlers,
  ...favoritesEffects.actionHandlers,
  ...breakPointEffects.actionHandlers,
  ...messagesEffects.actionHandlers,
  ...predictionsEffect.actionHandlers,
  ...uiActions.actionHandlers,
  ...bugReportActions.actionHandlers,
  ...loginModalActions.actionHandlers,
  ...tableEffects.actionHandlers,
  ...profilePageActions.actionHandlers,
  ...investmentEffects.actionHandlers,
};

export const mainModel = {
  MAIN_MODEL,
};
