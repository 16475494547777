import React from 'react';
import { Asset } from '../../../components/utility/asset-component/view';
import { uiActions } from '../actions/uiActions';
import Checkbox from 'react-three-state-checkbox';

export const ProtocolsTemplate = props => {
    const { state, actions } = props;
    const protocols = state.protocols;

    const onClickHandler = (parentId, id) => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_PROTOCOL_PICK]({ parentId, id });
    const isActive = isPicked => isPicked ? 'active' : '';

    return <div className="protocols-layout">
        {protocols.map((protocol, index) => 
            <div key={index} className="protocols mb-4">
                <div className="protocols-header d-flex align-items-center mb-3 mt-3">
                    <div className="me-1 font-size-12 d-inline-flex align-items-center">
                        <div className="me-1 chain-img-wrapper d-inline-flex align-items-center"><Asset asset={protocol.label}/></div>
                        <div className="d-inline-flex align-items-center font-size-15">{`(${protocol.protocols.length})`}</div>
                    </div>
                </div>
                <div className="protocols-row d-inline-flex align-items-center w-100">
                    <div className="d-inline-flex cbox-wrapper align-items-center me-5">
                        <Checkbox
                            checked={protocol.checked}
                            indeterminate={protocol.indeterminate}
                            onChange={() => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_PROTOCOL_CHECKED]({ id: protocol.icon })}
                            className="me-2 select-all-cbox"
                        />
                        <span className="font-size-14">Select All</span>
                    </div>
                    {protocol.protocols.map((p, index) => 
                        <div onClick={() => onClickHandler(protocol.icon, p.icon)} key={index} className={`${isActive(p.picked)} protocols-item me-3`}>
                            <div className="protocols-item-content d-flex align-items-center">
                                <Asset asset={p.label} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )}
    </div>;
};