import moment from 'moment';

const createEmptyDataSet = () => {
    const data = Array(12).fill({});
    return data.map((el, i, self) => {
        return {
            category: moment().subtract(self.length - i, 'months').format('MMM'),
            BTC: 3000,
            USD: 3000,
        };
    });
};

export const dataHelper = {
    createEmptyDataSet
};