import React from 'react';
import { IMG_WALLETS } from './images/walletsImg';
import { IMAGE_TOTALS } from './images/circleTotals';
import { DEFI_IMG } from './images/defiImg';
import { CEFI_IMG } from './images/cefiImg';
import { NO_CEFI_IMG } from './images/noCefiImg';
import { MOBILE_LENDING_IMG } from './images/mobileLendingImg';

const walletsImg = () => {
    return IMG_WALLETS;
};

const circleTotalsImg = () => {
    return IMAGE_TOTALS;
};

const defiImg = () => {
    return DEFI_IMG;
};

const cefiImg = () => {
    return CEFI_IMG;
};

const noCefiImg = () => {
    return NO_CEFI_IMG;
};

const lendingImg = () => {
    return MOBILE_LENDING_IMG;
};

export const imagesTemplate = {
    walletsImg,
    circleTotalsImg,
    defiImg,
    cefiImg,
    noCefiImg,
    lendingImg
};
