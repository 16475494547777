import { useEffect } from 'react';
import { serviceUtils } from '../../service/serviceUtils';
import { websocketEffect } from './websocketEffects';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { mainEffects } from './mainEffects';

const SOCKET_URL = 'wss://stream.binance.com:9443/stream';

export const PriceFetcher = () => {
    const state = useStoreState(state => state.socket);
    const actions = useStoreActions(actions => actions);
    state.methods = serviceUtils.websocketStream({
        url: SOCKET_URL,
        openActionType: actions[websocketEffect.actionTypes.WEBSOCKET_CONNECTION_OPENED],
        closeActionType: actions[websocketEffect.actionTypes.WEBSOCKET_CONNECTION_CLOSED],
        receivedActionType: actions[websocketEffect.actionTypes.WEBSOCKET_MESSAGE_RECEIVED]
    });
    useEffect(() => {
        actions[mainEffects.actionTypes.DASHBOARD_DATA_REQUESTED]({});
        actions[mainEffects.actionTypes.EXCHANGES_LIST_REQUESTED]({});
    }, []);
    return null;
};