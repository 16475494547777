import { action, thunk, debug } from 'easy-peasy';
import { serviceUtils } from '../../../../../service/serviceUtils';

const actionTypes = {
  FILTER_LIST_TOGGLE: 'FILTER_LIST_TOGGLE',
  SET_SORT_TYPE: 'SET_SORT_TYPE',
  SELECT_TAG: 'SELECT_TAG',
  SELECT_TYPE: 'SELECT_TYPE',
  SELECT_TIME: 'SELECT_TIME',
  REMOVE_SELECTED_TAG: 'REMOVE_SELECTED_TAG',
  FILTER_CLEAR_ALL: 'FILTER_CLEAR_ALL',
  FILTER_APPLY: 'FILTER_APPLY',
  FILTER_CATEGORY: 'FILTER_CATEGORY',
  CLEAR_ALL_SELECTED_TAGS: 'CLEAR_ALL_SELECTED_TAGS',
  SHOW_PAGE_NEW_ARTICLE: 'SHOW_PAGE_NEW_ARTICLE',
  CONVERT_TO_BASE64: 'CONVERT_TO_BASE64',
  CURRENT_DATA: 'CURRENT_DATA',
  CREATE_ARTICLE: 'CREATE_ARTICLE',
  TOGGLE_PUBLISH_ARTICLE: 'TOGGLE_PUBLISH_ARTICLE',
  FILTER_PUBLISH_UNPUBLISH: 'FILTER_PUBLISH_UNPUBLISH',
  FILTER_SEARCH: 'FILTER_SEARCH',
  REPORT_ERROR: 'REPORT_ERROR',
  REPORT_SUCCSES: 'REPORT_SUCCSES',
  PUBLIC_ARTICLES_RESPONSE: 'PUBLIC_ARTICLES_RESPONSE',
  EDIT_ARTICLE: 'EDIT_ARTICLE',
  UPDATE_ARTICLE: 'UPDATE_ARTICLE',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  GET_ARTICLES: 'GET_ARTICLES',
  GET_PUBLIC_ARTICLES: 'GET_PUBLIC_ARTICLES',
  SET_FULL_ARTICLE: 'SET_FULL_ARTICLE',
  BACK_TO_ADMIN_ARTICLES: 'BACK_TO_ADMIN_ARTICLES',
  BACK_TO_PUBLIC_ARTICLES: 'BACK_TO_PUBLIC_ARTICLES',
  ADMIN_LOAD_MORE: 'ADMIN_LOAD_MORE',
  ADMIN_LOAD_MORE_SUCCSES: 'ADMIN_LOAD_MORE_SUCCSES',
  FILTER_ADMIN_ARTICLES: 'FILTER_ADMIN_ARTICLES',
  UPDATE_FILTERS_TOGGLE: 'UPDATE_FILTERS_TOGGLE',
  GET_FILTERS: 'GET_FILTERS',
  DELETE_FILTER: 'DELETE_FILTER',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UNPUBLISH_FILTER: 'UNPUBLISH_FILTER',
  SET_MESSAGE: 'SET_MESSAGE',
  GET_ARTICLES_BY_TAG: 'GET_ARTICLES_BY_TAG',
  REMOVE_ARTICLES_BY_TAG: 'REMOVE_ARTICLES_BY_TAG',
  PUBLIC_LOAD_MORE: 'PUBLIC_LOAD_MORE',
  PUBLIC_LOAD_MORE_RESPONSE: 'PUBLIC_LOAD_MORE_RESPONSE',
  ORDER_CATEGORIES: 'ORDER_CATEGORIES',
  GET_CATEGORIES_WITH_ARTICLES: 'GET_CATEGORIES_WITH_ARTICLES',
  GET_FULL_ARTICLE: 'GET_FULL_ARTICLE',
  FULL_ARTICLE_RESPONSE: 'FULL_ARTICLE_RESPONSE',
};

const filterListToggle = (state) => {
  state.filters.showFilters = !state.filters.showFilters;
};

const setSortType = (state, payload) => {
  state.sort.sortType = payload;
};

const selectTag = (state, payload) => {
  state.filters.tags = state.filters.tags.map((tag) =>
    tag.label === payload ? { ...tag, checked: !tag.checked } : tag
  );
};

const selectType = (state, payload) => {
  const { value, checked } = payload;

  state.filters.types = state.filters.types.map((type) =>
    type.value === value ? { ...type, checked: checked } : type
  );
};

const selectTime = (state, payload) => {
  const { value, checked } = payload;

  state.filters.times = state.filters.times.map((time) =>
    time.value === value ? { ...time, checked: checked } : time
  );
};

const removeSelectedTag = (actions, payload, helpers) => {
  const state = helpers.getState();

  const { filter, index } = payload;

  if (filter.type === 'tag') {
    state.filters.tags = state.filters.tags.map((tag) =>
      tag.value === filter.value ? { ...tag, checked: false } : tag
    );
  }
  if (filter.type === 'type') {
    state.filters.types = state.filters.types.map((type) =>
      type.value === filter.value ? { ...type, checked: false } : type
    );
  }

  if (filter.type === 'time') {
    state.filters.times = state.filters.times.map((time) =>
      time.value === filter.value ? { ...time, checked: false } : time
    );
  }

  state.filters.selectedFilters = state.filters.selectedFilters.filter(
    (filter, filterIndex) => filterIndex !== index
  );

  state.filters.showFilters = false;
  state.offset = 0;

  actions.FILTER_APPLY(actions);
};

const filterClearAll = (state, payload) => {
  state.filters.tags = state.filters.tags.map((tag) => {
    return { ...tag, checked: false };
  });

  state.filters.types = state.filters.types.map((type) => {
    return { ...type, checked: false };
  });

  state.filters.times = state.filters.times.map((time) => {
    return { ...time, checked: false };
  });

  state.filters.showFilters = false;
};

const filterApply = (state, payload) => {
  const actions = payload;

  let activeTags = state.filters.tags.filter((tag) => tag.checked);
  let activeTypes = state.filters.types.filter((type) => type.checked);
  let activeTimes = state.filters.times.filter((time) => time.checked);

  let selectedFilters = state.filters.selectedFilters.map((filter) => filter.value);

  activeTags.map((tag) => {
    if (!selectedFilters.includes(tag.value)) {
      state.filters.selectedFilters.push(tag);
    }
  });

  activeTypes.map((type) => {
    if (!selectedFilters.includes(type.value)) {
      state.filters.selectedFilters.push(type);
    }
  });

  activeTimes.map((time) => {
    if (!selectedFilters.includes(time.value)) {
      state.filters.selectedFilters.push(time);
    }
  });

  activeTags = activeTags.map((tag) => tag.value);
  activeTypes = activeTypes.map((type) => type.value);
  activeTimes = activeTimes.map((time) => time.value);

  activeTags = activeTags.length > 0 ? activeTags : null;
  activeTypes = activeTypes.length > 0 ? activeTypes : null;
  activeTimes = activeTimes.length > 0 ? activeTimes : null;

  let data = {
    offset: 0,
    category: state.categories.selectedCategory,
    tags: activeTags,
    types: activeTypes,
    timesToRead: activeTimes,
  };

  state.filters.showFilters = false;

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/articles/filter`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.PUBLIC_ARTICLES_RESPONSE],
  });
};

const filterCategory = (state, payload) => {
  const category = payload;
  state.filterArticles = [];

  state.offset = 0;
  state.categories.selectedCategory = category;
};

const clearAllSelectedTags = (actions, payload, helpers) => {
  const state = helpers.getState();

  state.filters.selectedFilters = [];
  state.offset = 0;

  actions.FILTER_CLEAR_ALL();
  actions.GET_PUBLIC_ARTICLES();
};

const showPageNewArticle = (state) => {
  state.editArticle = null;
  state.admin.mode = 'create';
};

const convertToBase64 = (state, payload) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(payload);

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const current_data = (state) => {
  let month_names = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const current = new Date();

  let month = month_names[current.getMonth()];
  let date = current.getDate();
  let year = current.getFullYear();

  return `${month} ${date}, ${year}`;
};

const createArticle = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/article`,
    method: 'POST',
    data: payload,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const editArticle = (state, payload) => {
  let articleId = payload;

  let editArticle = state.filterArticles.find((article) => article.articleId === articleId);

  state.editArticle = editArticle;
  state.admin.mode = 'update';
};

const updateArticle = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/article`,
    method: 'PUT',
    data: payload,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const deleteArticle = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/article`,
    method: 'DELETE',
    data: { articleId: payload },
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const getArticles = (actions, payload, helpers) => {
  const state = helpers.getState();

  const { search, published } = state.admin.filter;

  const data = { search, published };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/articles/filter`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const filterSearch = (state, payload) => {
  const search = payload;

  if (search === '') {
    state.nextPage = null;
  }

  state.admin.filter.search = search;
};

const filterPublishUnpublish = (state, payload) => {
  state.admin.filter.published = payload;
};

const togglePublishArticle = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/article/publish`,
    method: 'POST',
    data: { articleId: payload },
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const reportError = (state, payload) => {
  console.log('error', payload);
};

const reportSuccses = (actions, payload, helpers) => {
  let method = payload.options.method;
  let url = payload.options.url;

  const state = helpers.getState();

  const filterUrl =
    'https://api-gate.cdfi.ai//admin/academy/articles/filter';
  const createUrl =
    'https://api-gate.cdfi.ai//admin/academy/article';
  const publishUrl =
    'https://api-gate.cdfi.ai//admin/academy/article/publish';
  const publicUrl =
    'https://api-gate.cdfi.ai//academy/articles/filter';
  const updateFilter =
    'https://api-gate.cdfi.ai//academy/article/update-filter-data';
  const deleteFilter =
    'https://api-gate.cdfi.ai//academy/article/delete-filter-data';
  const publishFilter =
    'https://api-gate.cdfi.ai//academy/article/publish-filter-data';
  const unpublishFilter =
    'https://api-gate.cdfi.ai//academy/article/unpublish-filter-data';
  const categoriesWithArticles =
    'https://api-gate.cdfi.ai//academy/articles/get-categories-with-articles';

  if (method === 'POST') {
    if (url === filterUrl) {
      const { articles, nextPage } = payload.response.data;
      state.nextPage = nextPage;
      state.filterArticles = articles;
    } else if (url === createUrl) {
      state.admin.mode = '';

      actions.GET_ARTICLES();
      actions.GET_FILTERS();
      actions.SET_MESSAGE('Article is created !');
    } else if (url === publishUrl) {
      actions.GET_ARTICLES();

      actions.SET_MESSAGE('The status of the article has been changed');
    } else if (url === publicUrl) {
      let { articles, nextPage } = payload.response.data;
      state.nextPage = nextPage;

      state.filterArticles = articles;
    } else if (url === updateFilter) {
      state.admin.mode = '';

      actions.GET_ARTICLES();
      actions.GET_FILTERS();
      actions.SET_MESSAGE('Filter is updated !');
    } else if (url === deleteFilter) {
      const { type, value } = payload.response.data;

      let articles;

      if (type === 'time') {
        articles = state.filterArticles.filter((article) => article.time === value);
      }
      if (type === 'category') {
        articles = state.filterArticles.filter((article) => article.category === value);
      }
      if (type === 'tag') {
        articles = state.filterArticles.filter((article) => {
          if (article.tags.includes(value)) {
            return article;
          }
        });
      }

      articles.forEach((article) => {
        actions.DELETE_ARTICLE(article.articleId);
      });

      actions.GET_FILTERS();
      actions.GET_ARTICLES();
      actions.SET_MESSAGE('Filter is deleted !');
    } else if (url === publishFilter || url === unpublishFilter) {
      const { value } = payload.options.data;

      state.admin.mode = '';

      actions.GET_FILTERS();
      actions.GET_ARTICLES();
      actions.SET_MESSAGE(`The status of all articles with ${value} has been changed`);
    } else if (url === categoriesWithArticles) {
      const { data: categoriesWithArticles } = payload.response;

      state.categories.list = categoriesWithArticles;
      state.loader = false;
    }
  } else if (method === 'DELETE') {
    const { articleId } = payload.response.data;

    actions.GET_ARTICLES();
    actions.SET_MESSAGE('Article is deleted');
  } else if (method === 'PUT') {
    state.admin.mode = '';

    actions.GET_ARTICLES();
    actions.SET_MESSAGE('Article is updated !');
  } else if (method === 'GET') {
    // list update filters
    const { tags, types, times, categories } = payload.response.data;

    state.filters.tags = tags;
    state.filters.types = types;
    state.filters.times = times;

    actions.ORDER_CATEGORIES(categories);
  }
};

const setFullArticle = (state, payload) => {
  let article = payload;

  state.fullArticle = article;
};

const backToPublicArticles = (state) => {
  state.fullArticle = null;
};

const backToAdminArticles = (state) => {
  state.admin.mode = '';
};

const adminLoadMore = (actions, payload, helpers) => {
  const state = helpers.getState();

  const { search, published } = state.admin.filter;
  const { nextPage } = state;

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//admin/academy/articles/filter`,
    method: 'POST',
    data: { search, published, nextPage },
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.ADMIN_LOAD_MORE_SUCCSES],
  });
};

const adminLoadMoreSuccses = (actions, payload, helpers) => {
  const state = helpers.getState();

  const { articles, nextPage } = payload.response.data;

  if (state.nextPage) {
    state.filterArticles = [...state.filterArticles, ...articles];
  }

  state.nextPage = nextPage;
};

const getPublicArticles = (actions, payload, helpers) => {
  // const state = payload;
  const state = helpers.getState();

  let data = { offset: state.offset, category: state.categories.selectedCategory };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/articles/filter`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.PUBLIC_ARTICLES_RESPONSE],
  });
};

const publicArticlesResponse = (state, payload) => {
  const { articles, hasNextPage } = payload.response.data;

  state.nextPage = hasNextPage;

  state.filterArticles = articles;
  state.loader = false;
};

const publicLoadMore = (actions, payload) => {
  const state = payload;

  let limit = 10;

  if (state.nextPage) {
    state.offset = state.offset + limit;
  }

  let data = { offset: state.offset, category: state.categories.selectedCategory };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/articles/filter`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.PUBLIC_LOAD_MORE_RESPONSE],
  });
};

const publicLoadMoreResponse = (state, payload) => {
  const { articles, hasNextPage } = payload.response.data;

  const limit = 10;
  state.nextPage = hasNextPage;

  if (hasNextPage) {
    state.offset = state.offset + limit;
  }

  state.filterArticles = [...state.filterArticles, ...articles];
  state.loader = false;
};

const updateFiltersToggle = (state, payload) => {
  state.admin.mode = 'update-filters';
  state.admin.showUpdateFilter = !state.admin.showUpdateFilter;
};

const getFilters = (actions, payload) => {
  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/article/get-filter-data`,
    method: 'GET',
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const deleteFilter = (actions, payload) => {
  const { type, value } = payload;

  let data = { type: type, value: value };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/article/delete-filter-data`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const updateFilter = (actions, payload) => {
  const { type, value: oldValue } = payload.oldValue;
  const { newValue } = payload;

  const data = { type: type, oldValue: oldValue, newValue: newValue };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/article/update-filter-data`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const setMessage = (state, payload) => {
  state.admin.message.text = payload;
};
const getArticlesByTag = (state, payload) => {
  state.selectedTag = payload;
  state.nextPage = null;

  state.filterArticles = state.filterArticles.filter((article) => article.tags.includes(payload));
};

const removeArticlesByTag = (actions, payload, helpers) => {
  const state = helpers.getState();

  const isPublicLocation = payload;

  state.selectedTag = null;
  state.filterArticles = [];

  if (isPublicLocation) {
    actions.GET_PUBLIC_ARTICLES();
  } else {
    actions.GET_ARTICLES();
  }
};

const unpublishFilter = (actions, payload, helpers) => {
  const { type, value, isPublished } = payload;

  let subUrl;

  if (isPublished) {
    subUrl = 'unpublish-filter-data';
  } else {
    subUrl = 'publish-filter-data';
  }

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/article/${subUrl}`,
    method: 'POST',
    data: { type: type, value: value },
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const orderCategories = (state, payload) => {
  let categories = payload;

  categories = categories.filter((category) => category.label !== 'category');

  let orderCategories = [];
  categories.forEach((category) => {
    if (category.value === 'White paper') {
      orderCategories[0] = category;
    } else if (category.value === 'CeFi') {
      orderCategories[1] = category;
    } else if (category.value === 'Defi') {
      orderCategories[2] = category;
    } else if (category.value === 'Academy') {
      orderCategories[3] = category;
    } else if (category.value === 'Tools') {
      orderCategories[4] = category;
    } else {
      orderCategories.push(category);
    }
  });

  state.categories.list = orderCategories;
};

const getCategoriesWithArticles = (actions, payload) => {
  const orderCategories = ['White paper', 'CeFi', 'Defi', 'Academy', 'Tools'];
  const data = { sortOrder: orderCategories };

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/articles/get-categories-with-articles`,
    method: 'POST',
    data: data,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.REPORT_SUCCSES],
  });
};

const getFullArticle = (actions, payload) => {
  const articleId = payload;

  serviceUtils.HttpService({
    url: `https://api-gate.cdfi.ai//academy/article?articleId=${articleId}`,
    errorActionType: actions[actionTypes.REPORT_ERROR],
    successActionType: actions[actionTypes.FULL_ARTICLE_RESPONSE],
  });
};

const fullArticleResponse = (state, payload) => {
  const { data: article } = payload.response;

  state.docsFullArticle = article;
};

const actionHandlers = {
  [actionTypes.FILTER_LIST_TOGGLE]: action((state) => filterListToggle(state)),
  [actionTypes.SET_SORT_TYPE]: action((state, payload) => setSortType(state, payload)),
  [actionTypes.SELECT_TAG]: action((state, payload) => selectTag(state, payload)),
  [actionTypes.SELECT_TYPE]: action((state, payload) => selectType(state, payload)),
  [actionTypes.SELECT_TIME]: action((state, payload) => selectTime(state, payload)),
  [actionTypes.REMOVE_SELECTED_TAG]: thunk((actions, payload, helpers) =>
    removeSelectedTag(actions, payload, helpers)
  ),
  [actionTypes.FILTER_CLEAR_ALL]: action((state, payload) => filterClearAll(state, payload)),
  [actionTypes.FILTER_APPLY]: action((state, payload) => filterApply(state, payload)),
  [actionTypes.FILTER_CATEGORY]: action((state, payload) => filterCategory(state, payload)),
  [actionTypes.CLEAR_ALL_SELECTED_TAGS]: thunk((actions, payload, helpers) =>
    clearAllSelectedTags(actions, payload, helpers)
  ),
  [actionTypes.SHOW_PAGE_NEW_ARTICLE]: action((state) => showPageNewArticle(state)),
  [actionTypes.CONVERT_TO_BASE64]: thunk((state, payload) => convertToBase64(state, payload)),
  [actionTypes.CURRENT_DATA]: thunk((state) => current_data(state)),
  [actionTypes.CREATE_ARTICLE]: thunk((actions, payload) => createArticle(actions, payload)),
  [actionTypes.TOGGLE_PUBLISH_ARTICLE]: thunk((actions, payload) =>
    togglePublishArticle(actions, payload)
  ),
  [actionTypes.FILTER_PUBLISH_UNPUBLISH]: action((state, payload) =>
    filterPublishUnpublish(state, payload)
  ),
  [actionTypes.FILTER_SEARCH]: action((state, payload) => filterSearch(state, payload)),
  [actionTypes.REPORT_ERROR]: action((state, payload) => reportError(state, payload)),
  [actionTypes.REPORT_SUCCSES]: thunk((actions, payload, helpers) =>
    reportSuccses(actions, payload, helpers)
  ),
  [actionTypes.EDIT_ARTICLE]: action((state, payload) => editArticle(state, payload)),
  [actionTypes.UPDATE_ARTICLE]: thunk((actions, payload) => updateArticle(actions, payload)),
  [actionTypes.DELETE_ARTICLE]: thunk((actions, payload) => deleteArticle(actions, payload)),
  [actionTypes.GET_ARTICLES]: thunk((actions, payload, helpers) =>
    getArticles(actions, payload, helpers)
  ),
  [actionTypes.SET_FULL_ARTICLE]: action((state, payload) => setFullArticle(state, payload)),
  [actionTypes.BACK_TO_PUBLIC_ARTICLES]: action((state) => backToPublicArticles(state)),
  [actionTypes.BACK_TO_ADMIN_ARTICLES]: action((state) => backToAdminArticles(state)),
  [actionTypes.ADMIN_LOAD_MORE]: thunk((actions, payload, helpers) =>
    adminLoadMore(actions, payload, helpers)
  ),
  [actionTypes.ADMIN_LOAD_MORE_SUCCSES]: thunk((actions, payload, helpers) =>
    adminLoadMoreSuccses(actions, payload, helpers)
  ),
  [actionTypes.FILTER_ADMIN_ARTICLES]: thunk((actions, payload, helpers) =>
    filterAdminArticles(actions, payload, helpers)
  ),
  [actionTypes.GET_PUBLIC_ARTICLES]: thunk((actions, payload, helpers) =>
    getPublicArticles(actions, payload, helpers)
  ),
  [actionTypes.PUBLIC_ARTICLES_RESPONSE]: action((state, payload) =>
    publicArticlesResponse(state, payload)
  ),
  [actionTypes.UPDATE_FILTERS_TOGGLE]: action((state, payload) =>
    updateFiltersToggle(state, payload)
  ),
  [actionTypes.GET_FILTERS]: thunk((actions, payload) => getFilters(actions, payload)),
  [actionTypes.DELETE_FILTER]: thunk((actions, payload) => deleteFilter(actions, payload)),
  [actionTypes.UPDATE_FILTER]: thunk((actions, payload) => updateFilter(actions, payload)),
  [actionTypes.UNPUBLISH_FILTER]: thunk((actions, payload, helpers) =>
    unpublishFilter(actions, payload, helpers)
  ),
  [actionTypes.SET_MESSAGE]: action((state, payload) => setMessage(state, payload)),
  [actionTypes.GET_ARTICLES_BY_TAG]: action((state, payload) => getArticlesByTag(state, payload)),
  [actionTypes.REMOVE_ARTICLES_BY_TAG]: thunk((actions, payload, helpers) =>
    removeArticlesByTag(actions, payload, helpers)
  ),
  [actionTypes.PUBLIC_LOAD_MORE]: thunk((actions, payload) => publicLoadMore(actions, payload)),
  [actionTypes.PUBLIC_LOAD_MORE_RESPONSE]: action((state, payload) =>
    publicLoadMoreResponse(state, payload)
  ),
  [actionTypes.ORDER_CATEGORIES]: action((state, payload) => orderCategories(state, payload)),
  [actionTypes.GET_CATEGORIES_WITH_ARTICLES]: thunk((actions, payload) =>
    getCategoriesWithArticles(actions, payload)
  ),
  [actionTypes.GET_FULL_ARTICLE]: thunk((actions, payload) => getFullArticle(actions, payload)),
  [actionTypes.FULL_ARTICLE_RESPONSE]: action((state, payload) =>
    fullArticleResponse(state, payload)
  ),
  // updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
  actionTypes,
  actionHandlers,
};
