import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const drawSign = (ref, color) => {
    const svg = d3.select(ref.current).html('');

    svg
        .append('g')
        .attr('transform', 'translate(-565.727 -177)')
        .append('rect')
        .attr('width', 13.273)
        .attr('height', 5)
        .attr('rx', 2.5)
        .attr('transform', 'translate(565.727 180)')
        .attr('fill', color);

    svg
        .selectAll('g')
        .append('circle')
        .attr('cx', 4.898)
        .attr('cy', 4.898)
        .attr('r', 4.898)
        .attr('transform', 'translate(573.821 177.5)')
        .attr('fill', '#433f48');

    svg
        .selectAll('g')
        .append('path')
        .attr('d', 'M4.9-.5A5.4,5.4,0,1,1-.5,4.9,5.4,5.4,0,0,1,4.9-.5Zm0,9.8A4.4,4.4,0,1,0,.5,4.9,4.4,4.4,0,0,0,4.9,9.3Z')
        .attr('transform', 'translate(573.821 177.5)')
        .attr('fill', color);




};

export const Sign = props => {
    const { height, color, className } = props;
    const svgRef = useRef();
    useEffect(() => drawSign(svgRef, color));
    return <i className={`icon-sign ${className || ''}`}>
        <svg ref={svgRef} height={height} viewBox="0 0 18.389 10.795"></svg>
    </i>;
};
