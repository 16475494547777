import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/swapPage/actions/uiActions';

const SWAP_MODEL_TOKEN = {
    balance: 0,
    value: 0,
    valueUSD: 0,
    rateUSD: 0,
    token: {
        address: '',
        symbol: '',
        name: '',
        decimals: 0,
        exchange: 0,
        coingeckoId: '',
    }    
};

const SWAP_MODEL = {
    // common
    initializeStep: 1, 
    initialized: false,
    formActive: 'swap',
    routerAddress: '',

    approve: {
        allowance: '0',
        transaction: {},
    },

    chain: {
        value: {},
        list: []
    },

    // swap form
    swap: {
        sell: SWAP_MODEL_TOKEN,
        buy: SWAP_MODEL_TOKEN,
        enabled: false,
        status: 'check',
        statusLabel: 'Initializing..',
        do: {},
    },

    // tokens form
    tokens: {
        initialized: false,
        type: '',
        search: '',
        list: [],
        filtered: [],
        import: [],
        balance: [],
        addressForImport: '',
    },

    // settings form
    settings: {
        gasPrice: 'market',
        gasPriceValue: 15,
        slippage: '1%',
        slippageValue: 1,
        allowPartialFill: false,
        routingPreset: false,
        compatibilityMode: false,
        liquiditySources: [],
        customTokens: [],
    },

    ...uiActions.actionHandlers,
};

export const SwapState = createContextStore(SWAP_MODEL);