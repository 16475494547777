import React from 'react';
import { Selector } from '../dropdown-selector/view';
import { useStoreActions } from 'easy-peasy';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { standardOptionsEffects } from './standardOptionsEffects';
import { isEmpty } from 'lodash';
import { constants } from '../../../constants/constants';

const { MORE_POPPER_DATA } = constants;

const renderExpander = (actions, options) => {
    return <div className="standard-options-expand me-2"
        onClick={() => standardOptionsEffects.uiAction(actions, { ...options, action: 'expand' })}>
        <i className="icon-expand standard-options-expand-icon"></i>
    </div>;
};

const renderRefresh = (actions, options) => {
    const { loading } = options;
    return <div className="standard-options-refresh me-2"
        onClick={() => standardOptionsEffects.uiAction(actions,{ ...options, action: 'refresh' })}
    >
        <FontAwesomeIcon icon={faSync} spin={loading}/>
    </div>;
};

const renderMore = (actions, options) => {
    const { dropdownClass = '', classes, list, menuAlign, value, title, icon, actionName, appendToPayload } = options;

    return 	<div className="standard-options-more">
        <div className={`standard-options-dropdown p-0 ${dropdownClass}`}
			 onClick={() => {standardOptionsEffects.uiAction(actions, { ...options, action: 'more' })

            }}
			 variant="link"
			 size="sm">
            <Selector
                classes={classes || ''}
                list={list || [] }
                value={value}
                icon={icon || 'standard-options-more'}
                menuAlign={menuAlign || 'right'}
                title={title || ''}
                actionName={actionName || ''}
                popperData={MORE_POPPER_DATA}
                appendToPayload={appendToPayload || {}}
            />
        </div>
    </div>;
};

export const StandardOptions = props => {
    const {
        className = '',
        isNotRefresh = false,
        isNotExpand = false,
        isNotMore = false,
        isLoading,
        refresh,
        expand,
        more
    } = props;
    const actions = useStoreActions(state => state);
    return <div className={`d-flex flex-row justify-content-between standard-options ${className}`}>
        { isNotRefresh || isEmpty(refresh) ? null : renderRefresh( actions, { ...refresh, loading: isLoading }) }
        { isNotExpand || isEmpty(expand) ? null : renderExpander(actions, { ...expand }) }
        { isNotMore || isEmpty(more) ? null : renderMore(actions, { ...more }) }
    </div>;
};