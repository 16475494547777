import React, { useRef } from 'react';
import { GridBotState } from '../../models/grid-bot-model';
import { useStoreState } from 'easy-peasy';
import CustomScroll from 'react-custom-scroll';
import TopControlsToggle from './tamplates/TopControlsToggle'
import Controls from './tamplates/Controls'
import Chart from './tamplates/Chart'
import TradingView from './tamplates/TradingView'
import Balanaces from './tamplates/Balanaces'
import {constants} from './constants'

import './styles.scss';

const { DEFAULT_PAIR } = constants;

const GridBotLayout = () => {
    const theme = useStoreState(state => state.root.theme);

    const showBalances = GridBotState.useStoreState(state => state.showBalances);
    const showTradings = GridBotState.useStoreState(state => state.tradingView);

    const ref = useRef();

    // const themeHandler = theme => theme === 'night-theme' ? 'gridbot_dark' : 'gridbot_light';


    return <div ref={ref} className="page-container">
        <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar main-content-scroll" addScrolledClass={true}>
            <div className={`grid-layout-wrapper ${theme}`}>
                {/* <img className="grid-page" src={`../dashboard-library/home-page-images/${themeHandler(theme)}.svg`} /> */}

                <TopControlsToggle />

                <div className="top-elements">
                    <div className="left-side">
                        <Controls />
                    </div>

                    <div className="right-side">
                        <Chart defaultPair={DEFAULT_PAIR} />
                    </div>
                </div>
                <div className={`bottom-elements ${showTradings && showBalances ? 'bottom-elements-height' : 'h-100'}`}>
                    <div className={`left-side ${!showTradings && 'd-none'} ${!showBalances && 'w-100'}`}>
                        <TradingView />
                    </div>
                    <div className={`right-side ${!showBalances && 'd-none'}`}>
                        <Balanaces />
                    </div>
                </div>
            </div>
        </CustomScroll>
    </div>;
        
};


export const GridPage = () => {
    return <GridBotState.Provider>
                <GridBotLayout />
    </GridBotState.Provider>
};
