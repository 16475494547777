import { createContextStore } from 'easy-peasy';
import { gridBotUiActions } from '../pages/gridBotPage/actions/uiActions'

const GRID_BOT_MODEL = {
    showBalances: true,
    tradingView: true,
    allStrategy: true,
    manuelStrategy: false,
    baseBTC: false,
    quoteBTC: true,
    assetsData: {},
    exchangeData: {},
    mainCoinList: [],
    coinData: {},
    pairCoinList: [],
    singlePair: {
        mainCoin: '',
        pairCoin: '',
        pairData: {},
        isSingle: true
    },
    ...gridBotUiActions.actionHandlers
};

export const GridBotState = createContextStore(GRID_BOT_MODEL);
