import React from 'react'

const StapleIcon = () => {
  return (
    <div className="StapleIcon" style={{ marginRight: '10px' }}>
        <i className="fa-light fa-paperclip"></i>
    </div>
  )
}

export default StapleIcon