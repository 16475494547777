import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { drawFrame } from './drawHelper';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 *
 * color : color of frame
 * fontSize : font size for label
 * fontWeight : font weight for label
 * content: React element which we want to framed
 * borderRadius: border radius of frame
 * label: text for label
 */


export const Frame = props => {
    const { content, label, fontSize, fontWeight, frameColor, labelColor, borderRadius } = props;
    const svgRef = useRef();
    useEffect(() => drawFrame(svgRef, label, fontSize, fontWeight, frameColor, labelColor, borderRadius));
    return <div className="frame-container p-0 m-0">
        <svg ref={svgRef}></svg>
        { content }
    </div>;
};