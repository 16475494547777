import React from 'react';
import { useStoreActions } from 'easy-peasy';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BurgerButton } from './templates/burger-button/view';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export const HomePageNav = props => {
    const loginModalToggle = useStoreActions(actions => actions.LOGIN_MODAL_TOGGLE);
    const { navLinks: {list}, navLogo } = props;
    const { search } = useLocation();
    const id = search.replace('?elem=', '');
    const isActive = link => id === link.id.toLowerCase() ? 'active-link' : '';
    
    const handleLoginModal = (e) => {
        e.preventDefault();

        loginModalToggle();
    }

    return <Navbar fixed="top" collapseOnSelect expand="xl" className='d-flex align-items-center'>
        <Navbar.Brand className="font-size-30 font-protosans d-flex align-items-center">
            <LinkContainer to="/home">
                <a>
                    <img src='../dashboard-library/home-page-images/nav_logo.svg' className='me-3' />
                    <span>{navLogo}</span>
                </a>
            </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <BurgerButton/>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
                { list.map(link => link.label ? <Nav.Link className="font-protosans font-weight-bold font-size-20" key={link.id}>
                        <LinkContainer  to={`/${link.parentId}?elem=${link.id}`}>
                            <div><span className={isActive(link)}>{link.label}</span></div>
                        </LinkContainer>
                    </Nav.Link> : null
                ) }
            </Nav>
            <Nav className="launch-app-button">
                <LinkContainer to='/login'>
                    <a onClick={(e) => handleLoginModal(e)} href="" >
                        <Button
                            type="button"
                            className="font-protosans font-weight-bold font-size-20 btn-custom btn-launch"
                        >
                        <div>
                          Launch app
                          </div>
                        <div className="free-beta">
                          Free beta
                        </div>
                        </Button>
                    </a>
                </LinkContainer>
            </Nav>
        </Navbar.Collapse>
    </Navbar>;
};
