import React from 'react';
import { dashboardConstants } from '../dashboardConstants';
import { VaultCard } from '../../../components/utility/vaults-card/view';
import CustomScroll from 'react-custom-scroll';
import { YieldCard } from '../../../components/utility/yield-card/view';
import { Bots } from '../../dashboard/widgets/bots-widget/view';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { useRef } from 'react';
import { useStoreState } from 'easy-peasy';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { LinkContainer } from 'react-router-bootstrap';

const { BOTTOM_CONTENT: { VAULTS, YIELDS, ORDERS } } = dashboardConstants;

const getCorrection = height => {
    const value = height * 40 / 100;
    return `-${value}px`;
};

export const bottomContent = (state, bots) => {
    const { imageHeight } = state;
    const botsAmount = bots.length;
    const style = { top: getCorrection(imageHeight) };

    return <div className="remake-dashboard-visual-content-bottom w-100 pt-4 pb-4 mb-5" style={style}>
        <div className="content-grid">
            <div className="vaults-block content-grid-item pt-3 pb-3 pe-3 ps-3">
                <div className="text-center">
                    <div className="title">{`${VAULTS} (${state.vaults.length})`}</div>
                    <LinkContainer to="DeFi?elem=pools"><i className="resize-icon fa-regular fa-up-right-and-down-left-from-center"></i></LinkContainer>
                </div>
                <div>
                    {!state.loaders.vaults ? !isEmpty(state.vaults) ? <CustomScroll heightRelativeToParent="calc(100%)" className="custom-scroll-bar">
                        <div className="vaults-grid">
                            { state.vaults.map((el, key) => 
                                <div key={key}>
                                    <VaultCard asset0={el.pool.staked_token.symbol} stakedAmount={el.staked_amount} stakedPrice={el.staked_price}
                                        asset1={el.pool.reward_token.symbol} rewardAmount={el.reward_amount} rewardPrice={el.reward_price} /></div>) }
                        </div>
                    </CustomScroll> : <EmptySearchState /> : <Loader size={'6x'} />}
                </div>
                <div className="harvest-section">
                    <div className="harvest-left">
                        <div className="harvest-left-header">~$0.01</div>
                        <div className="harvest-left-title">to collect from 1 farm</div>
                    </div>
                    <ButtonCustom className="harvest-section-button-color" label="Harvest all" size="sm" variant="primary" />
                </div>
            </div>
            <div className="vaults-block content-grid-item pt-3 pb-3 pe-3 ps-3">
                <div className="text-center">
                    <div className="title">{`${YIELDS} (${state.yields.length})`}</div>
                    <LinkContainer to="DeFi?elem=farm"><i className="resize-icon fa-regular fa-up-right-and-down-left-from-center"></i></LinkContainer>
                </div>
                {!state.loaders.yields ? !isEmpty(state.yields) ? <CustomScroll heightRelativeToParent="calc(100%)" className="custom-scroll-bar"><div className="vaults-grid">
                    { state.yields.map((el, key) => 
                        <div key={key}><YieldCard mainAsset={el.pool.lptoken.token0.symbol} stakedAmount={el.staked_amount} stakedPrice={el.staked_price}
                            apr={el.apr} asset={el.pool.lptoken.token1.symbol} rewardAmount={el.reward_amount} rewardPrice={el.reward_price} /></div>) }
                </div>
                </CustomScroll> : <EmptySearchState /> : <Loader size={'6x'} />}
                <div className="harvest-section">
                    <div className="harvest-left">
                        <div className="harvest-left-header">~$0.01</div>
                        <div className="harvest-left-title">to collect from 1 farm</div>
                    </div>
                    <ButtonCustom className="harvest-section-button-color" label="Harvest all" size="sm" variant="primary" />
                </div>
            </div>
            <div className="vaults-block pt-3 pb-3 d-none d-lg-block">
                <div className="text-center">
                    <div className="title">{`${ORDERS} (${botsAmount})`}</div>
                    <LinkContainer to="CeFi?elem=protrade"><i className="resize-icon fa-regular fa-up-right-and-down-left-from-center"></i></LinkContainer>
                </div>
                <div>
                    <Bots hideControlPanel={true}/>
                </div>
            </div>
        </div>
    </div>;
};