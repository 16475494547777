import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { exchangeModalEffects } from './exchangeModalEffects';
import { MultiplyCustomSelector } from '../multiply-selector/view';
import { constants } from '../../../pages/smartTradePage/constants';
import { serviceUtils } from '../../../service/serviceUtils';

const {
  CONTROLS: { LABEL_COIN_MAIN },
} = constants;
const renderSelectOptions = (list) => {
  return list.map((ex) => {
    return (
      <option key={ex.api} value={ex.api}>
        {ex.title}
      </option>
    );
  });
};

//{icon ? <img className='popover-icon-img' src={`../coins-full-library/${icon.toLowerCase()}.svg`} width={24} height={24} onError={utils.imgErrorHandle} /> : ''}
const schema = yup.object().shape({
  //type: yup.string().required(),
  exchange: yup.string().required(),
  key: yup.string().required(),
  secret: yup.string().required(),
  sumFlag: yup.bool(),
});
const parseExchanges = (exchanges) => {
  const keys = Object.keys(exchanges).filter((k) => k !== 'all_exchanges');
  return keys.map((key) => ({
    label: exchanges[key].api,
    value: exchanges[key],
  }));
};
export const AddExchangeForm = (props) => {
  const action = useStoreActions((actions) => actions);
  const state = useStoreState((state) => state.availableExchangesList);
  const exchanges = useStoreState((state) => state.coinData.exchanges);
  const { handleClose } = props;
  const assetsData = useStoreState((state) => state);
  const exchangesList = parseExchanges(state);

  const [type, setType] = useState(null);
  const submitHandler = (values, options) => {
    action[exchangeModalEffects.actionTypes.TABLE_WIDGET_EXCHANGE_MODAL_ADD_EXCHANGE_INVOKED]({
      ...values,
      type: type.value,
    });
    handleClose();
  };

  const mainCoinHandle = (value) => setType(value);

  useEffect(() => {
    action[exchangeModalEffects.actionTypes.SMART_TRADE_SELECTORS_CHANGED]({
      exchange: exchangesList,
    });
  }, [assetsData.assetsData]);
  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        //    type: "",
        exchange: '',
        key: '',
        secret: '',
        sumFlag: true,
      }}
      onSubmit={submitHandler}
    >
      {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="pb-3">
            <Form.Label>Exchange type</Form.Label>

            <div className="choice-selectors-item">
              <MultiplyCustomSelector
                onChangeHandle={mainCoinHandle}
                isMulti={false}
                closeMenuOnSelect={true}
                options={assetsData.exchangeCoins}
              />
            </div>

            <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="pb-3" controlId="controlSelect">
            <Form.Label>Exchange name</Form.Label>
            <Form.Control
              type="text"
              name="exchange"
              placeholder="Exchange name"
              value={values.exchange}
              isInvalid={!!errors.exchange}
              onChange={handleChange}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">{errors.exchange}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="pb-3" controlId="controlInputKey">
            <Form.Label>Paste API key</Form.Label>
            <Form.Control
              type="text"
              placeholder="API key"
              name="key"
              value={values.key}
              isInvalid={!!errors.key}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <Form.Control.Feedback type="invalid">{errors.key}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="pb-3" controlId="controlInputSecret">
            <Form.Label>Paste secret</Form.Label>
            <Form.Control
              type="password"
              placeholder="Secret key"
              name="secret"
              value={values.secret}
              isInvalid={!!errors.secret}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">{errors.secret}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="pb-3">
            <Form.Check
              type="checkbox"
              checked={values.sumFlag}
              onChange={handleChange}
              label="Sum it with other accounts"
              id="formCheckBox"
              name="sumFlag"
            />
          </Form.Group>
          <div class="ip-addres">
            While setting up your Binance API, please include our IP addresses: 52.17.182.0
            54.155.15.156
          </div>
          <Form.Group>
            <div className="submit-button text-center pt-3">
              <div className="button">
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="font-size-16 font-weight-medium"
                  // disabled={type===null}
                  // onClick={ async () => {
                  //         const responseType = 'code';
                  //         const clientId = '390768279';
                  //         const redirectUri = encodeURIComponent(
                  //           'https://www.monoceros.app/portfolio?elem=dashboard'
                  //         );
                  //         const scope = 'user:email,user:address';
                  //         const url = 'https://accounts.binance.com/en/oauth/authorize?';
                  //   await serviceUtils.HttpService({
                  //           url: url,
                  //           method: 'GET',
                  //           // headers: {"Access-Control-Allow-Origin" : "*" },
                  //           data : {
                  //            responseType: responseType,
                  //            clientId: clientId,
                  //            redirectUri: redirectUri,
                  //            scope: scope
                  //           },
                  //           errorActionType: () => console.log('There is an error'),
                  //           successActionType: () => console.log('It works'),
                  //         });
                  // }}
                >
                  DIRECT CONNECT
                </Button>
              </div>
              <div className="button">
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="font-size-16 font-weight-medium"
                  disabled={type === null}
                >
                  ADD EXCHANGE
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};
