/* eslint-disable max-len */
import React from 'react';

export const TRADE_ATOM = <>
    <path d="M60.0464 45.5485C61.386 44.209 61.386 42.0371 60.0464 40.6976C58.7069 39.358 56.535 39.358 55.1955 40.6976C53.8559 42.0371 53.8559 44.209 55.1955 45.5485C56.535 46.8881 58.7069 46.8881 60.0464 45.5485Z" fill="url(#paint0_radial_107_2622)"/>
    <path d="M115.016 47.4001C115.437 49.5802 114.436 52.0223 112.046 54.6104C109.664 57.1894 105.975 59.8314 101.232 62.3768C91.7503 67.4644 78.161 72.1105 62.7134 75.0929C47.2658 78.0754 32.9237 78.8221 22.23 77.6296C16.8801 77.033 12.4726 75.9542 9.30213 74.4471C6.12043 72.9347 4.2815 71.041 3.86059 68.861C3.43969 66.6809 4.44151 64.2388 6.83151 61.6507C9.21304 59.0717 12.9022 56.4296 17.6455 53.8843C27.1267 48.7967 40.716 44.1506 56.1636 41.1682C71.6111 38.1857 85.9533 37.439 96.647 38.6315C101.997 39.2281 106.405 40.3069 109.575 41.814C112.757 43.3264 114.596 45.2201 115.016 47.4001Z" stroke="#B8B6B6"/>
    <path d="M86.8782 110.375C84.8715 111.325 82.2569 110.963 79.1553 109.292C76.0647 107.628 72.5875 104.712 68.9415 100.752C61.6539 92.8356 53.7714 80.8306 47.0375 66.6117C40.3036 52.3928 36.0103 38.6879 34.5033 28.0341C33.7493 22.704 33.6971 18.1667 34.3675 14.7209C35.0403 11.2629 36.4166 9.01048 38.4232 8.06015C40.4299 7.10983 43.0445 7.47219 46.1461 9.14271C49.2367 10.8073 52.7139 13.7226 56.3599 17.683C63.6475 25.5993 71.53 37.6043 78.2639 51.8232C84.9978 66.0421 89.2911 79.747 90.7981 90.4009C91.5521 95.7309 91.6043 100.268 90.9339 103.714C90.2611 107.172 88.8848 109.424 86.8782 110.375Z" stroke="#B8B6B6"/>
    <path d="M60.6497 47.5547C66.927 47.5547 72.0158 52.6423 72.0158 58.9183C72.0158 65.1943 66.927 70.2819 60.6497 70.2819C54.3724 70.2819 49.2837 65.1943 49.2837 58.9183C49.2837 52.6423 54.3724 47.5547 60.6497 47.5547Z" fill="url(#paint1_linear_107_2622)"/>
    <path d="M56.633 11.2399C58.7818 11.2399 60.5238 12.9818 60.5238 15.1307C60.5238 17.2795 58.7818 19.0215 56.633 19.0215C54.4842 19.0215 52.7422 17.2795 52.7422 15.1307C52.7422 12.9818 54.4842 11.2399 56.633 11.2399Z" fill="url(#paint2_radial_107_2622)"/>
    <path d="M44.5698 57.5393C46.5671 55.542 46.5671 52.3037 44.5698 50.3064C42.5725 48.3091 39.3342 48.3091 37.3369 50.3064C35.3396 52.3037 35.3396 55.542 37.3369 57.5393C39.3342 59.5366 42.5725 59.5366 44.5698 57.5393Z" fill="url(#paint3_radial_107_2622)"/>
    <path d="M90.3535 31.1261C89.6372 31.1261 89.0565 30.5454 89.0565 29.8292C89.0565 29.1129 89.6372 28.5322 90.3535 28.5322C91.0697 28.5322 91.6504 29.1129 91.6504 29.8292C91.6504 30.5454 91.0697 31.1261 90.3535 31.1261Z" fill="url(#paint4_radial_107_2622)"/>
    <path d="M85.8083 89.9241C87.8056 87.9268 87.8056 84.6885 85.8083 82.6912C83.811 80.6939 80.5727 80.6939 78.5754 82.6912C76.5781 84.6885 76.5781 87.9268 78.5754 89.9241C80.5727 91.9214 83.811 91.9214 85.8083 89.9241Z" fill="url(#paint5_radial_107_2622)"/>
    <path d="M109.375 65.7113C113.434 65.7113 116.724 62.4209 116.724 58.362C116.724 54.3031 113.434 51.0127 109.375 51.0127C105.316 51.0127 102.026 54.3031 102.026 58.362C102.026 62.4209 105.316 65.7113 109.375 65.7113Z" fill="url(#paint6_radial_107_2622)"/>
    <path d="M7.34932 80.4096C11.4082 80.4096 14.6986 77.1192 14.6986 73.0603C14.6986 69.0013 11.4082 65.7109 7.34932 65.7109C3.2904 65.7109 0 69.0013 0 73.0603C0 77.1192 3.2904 80.4096 7.34932 80.4096Z" fill="url(#paint7_radial_107_2622)"/>
    <path d="M20.6194 85.7293C21.026 85.3228 21.026 84.6636 20.6194 84.257C20.2128 83.8504 19.5536 83.8504 19.1471 84.257C18.7405 84.6636 18.7405 85.3228 19.1471 85.7293C19.5536 86.1359 20.2128 86.1359 20.6194 85.7293Z" fill="url(#paint8_radial_107_2622)"/>
    <defs>
        <radialGradient id="paint0_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.621 43.123) rotate(45) scale(3.43014)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint1_linear_107_2622" x1="41.6094" y1="44.7457" x2="89.7" y2="80.5656" gradientUnits="userSpaceOnUse">
            <stop offset="0.18" stopColor="#1F55FE"/>
            <stop offset="0.32" stopColor="#0F9AFF"/>
            <stop offset="0.44" stopColor="#05CAFE"/>
            <stop offset="0.56" stopColor="#00EBC1"/>
            <stop offset="0.78" stopColor="#01BF4A"/>
            <stop offset="1" stopColor="#01F2AE"/>
        </linearGradient>
        <radialGradient id="paint2_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.5238 11.2399) rotate(-90) scale(3.89081)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint3_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.9533 53.9229) rotate(45) scale(5.11442)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint4_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.0565 31.1261) rotate(90) scale(1.29694)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint5_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.1919 86.3076) rotate(45) scale(5.11442)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint6_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.375 58.362) rotate(90) scale(7.34932)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint7_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.34932 73.0603) rotate(90) scale(7.34932)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
        <radialGradient id="paint8_radial_107_2622" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.8832 84.9932) rotate(45) scale(1.04111)">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
        </radialGradient>
    </defs>
</>;