import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { DeFiLiquidatorState } from '../../../models/defi-liquidator-model';
import { uiActions } from '../actions/uiActions';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { constants } from '../constants';
import { PopoverLayout } from '../../../components/utility/popover/view';
import { utils } from '../../../utils/utils';
import { Asset } from '../../../components/utility/asset-component/view';
import Big from 'big.js';

const { HEADER_KEYS } = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderTableHeader = () => {
    return <div className="custom-table-row table-header">
        { HEADER_KEYS.map((key, i) => <div className="custom-table-header-cell font-size-14 font-weight-medium" key={`header-${i}`}>{key}</div>) }
    </div>;
};

const renderRow = (data, index, actions, state) => {
    const { health, id, tokens, shortfall } = data;

    const idLink = <a rel='noreferrer' className="address-link" target='_blank' href={utils.getAddressLink('ETH', id)}>{id}</a>;

    const healthColor = health => health >= 1 ? 'positive-color' : 'negative-color';

    const clickHandle = id => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_CHOOSE]({ id });

    const activeClass = id => state.pickedAccount && state.pickedAccount.id === id ? 'active' : '';

    return <div className={`${activeClass(id)} custom-table-row table-value-row table-body font-size-14 font-weight-regular`} key={`body-${index}`} onClick={() => clickHandle(id)}>
        <div className={`custom-table-body-cell ${healthColor(health)}`}><i className="fa-solid fa-circle me-3"></i>
            <PopoverLayout message={`${utils.health2Pers(utils.roundValue(Number(health)))}%`} /></div>
        <div className="custom-table-body-cell"><PopoverLayout message={idLink} /></div>
        <div className="custom-table-body-cell d-flex align-items-center chain-img-wrapper"><Asset asset={utils.getExpensiveToken(tokens, 'borrowBalanceUnderlying').symbol.substring(1)} /></div>
        <div className="custom-table-body-cell">
            { shortfall !== 0 && <PopoverLayout message={`${Big(utils.getExpensiveToken(tokens, 'borrowBalanceUnderlying').tokensSeize[0].maxRepayAmount).toFixed(10)} 
            ${utils.getExpensiveToken(tokens, 'borrowBalanceUnderlying').symbol.substring(1)}`} />}</div>
        <div className="custom-table-body-cell d-flex align-items-center chain-img-wrapper"><Asset asset={utils.getExpensiveToken(tokens, 'supplyBalanceUnderlying').symbol.substring(1)} /></div>
        <div className="custom-table-body-cell">
            { shortfall !== 0 && <PopoverLayout message={`${Big(utils.getExpensiveToken(tokens, 'borrowBalanceUnderlying').tokensSeize[0].maxSeizeAmountWithIncentive).toFixed(10)}
              ${utils.getExpensiveToken(tokens, 'supplyBalanceUnderlying').symbol.substring(1)}`} />}</div>
    </div>;
};

const renderTableBody = (data, actions, state) => data.map((d, index) => renderRow(d, index, actions, state));

const renderLoader = () => {
    return <div className="loader text-center" key={0}>
        <span>
            <i className="fa fa-refresh fa-spin"></i>
            <span className="ms-3">Loading...</span>
        </span>
    </div>;
};

export const TableTemplate = props => {
    const { userWallet } = props;
    const actions = DeFiLiquidatorState.useStoreActions(actions => actions);
    const state = DeFiLiquidatorState.useStoreState(state => state);
    const tableData = state.tableData;
    const isLoading = state.loader;
    const loadFunc = value => actions[uiActions.actionTypes.DEFI_LIQUIDATOR_LOAD_MORE_LOADING]({ page: value, state });
    const ref = useRef();
    return <div className="custom-table h-100" ref={ref}>
        { renderTableHeader(tableData) }
        <div className="scroll-container">
            { isLoading ?

                <Loader size={'6x'}/>
                :
                !isEmpty(tableData.data) && !isEmpty(tableData.filteredData) ?

                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={loadFunc}
                            // hasMore={tableData.pagination.hasMore}
                            loader={renderLoader()}
                            useWindow={false}
                            getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
                            { renderTableBody(tableData.filteredData, actions, state, userWallet) }
                        </InfiniteScroll>
                    </CustomScroll>
                    :
                    <EmptySearchState/> }
        </div>
    </div>;
};