/* eslint-disable max-len */
/* eslintDisable maxLen */
import React from 'react';

export const MOBILE_LENDING_IMG = <>
    <g filter="url(#filter0_d_3554_87054)">
        <path d="M111.64 532.598C158.938 532.598 197.28 494.355 197.28 447.18C197.28 400.004 158.938 361.761 111.64 361.761C64.3424 361.761 26 400.004 26 447.18C26 494.355 64.3424 532.598 111.64 532.598Z" stroke="url(#paint0_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.64 532.598C158.938 532.598 197.28 494.355 197.28 447.18C197.28 400.004 158.938 361.761 111.64 361.761C64.3424 361.761 26 400.004 26 447.18C26 494.355 64.3424 532.598 111.64 532.598Z" stroke="url(#paint1_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.64 532.598C158.938 532.598 197.28 494.355 197.28 447.18C197.28 400.004 158.938 361.761 111.64 361.761C64.3424 361.761 26 400.004 26 447.18C26 494.355 64.3424 532.598 111.64 532.598Z" stroke="url(#paint2_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.995 551.903C169.982 551.903 216.99 505.017 216.99 447.18C216.99 389.342 169.982 342.456 111.995 342.456C54.0079 342.456 7 389.342 7 447.18C7 505.017 54.0079 551.903 111.995 551.903Z" stroke="url(#paint3_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.995 551.903C169.982 551.903 216.99 505.017 216.99 447.18C216.99 389.342 169.982 342.456 111.995 342.456C54.0079 342.456 7 389.342 7 447.18C7 505.017 54.0079 551.903 111.995 551.903Z" stroke="url(#paint4_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.995 551.903C169.982 551.903 216.99 505.017 216.99 447.18C216.99 389.342 169.982 342.456 111.995 342.456C54.0079 342.456 7 389.342 7 447.18C7 505.017 54.0079 551.903 111.995 551.903Z" stroke="url(#paint5_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M41 571.262L90.583 499.335" stroke="url(#paint6_linear_3554_87054)" strokeMiterlimit="10"/>
        <path d="M127.885 495.384L174.174 569.462H287.913" stroke="url(#paint7_linear_3554_87054)" strokeMiterlimit="10"/>
        <path d="M111.64 194.044C158.938 194.044 197.28 155.8 197.28 108.625C197.28 61.4493 158.938 23.2061 111.64 23.2061C64.3424 23.2061 26 61.4493 26 108.625C26 155.8 64.3424 194.044 111.64 194.044Z" stroke="url(#paint8_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M111.995 213.348C169.982 213.348 216.99 166.462 216.99 108.625C216.99 50.7875 169.982 3.90112 111.995 3.90112C54.0079 3.90112 7 50.7875 7 108.625C7 166.462 54.0079 213.348 111.995 213.348Z" stroke="url(#paint9_linear_3554_87054)" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="square" strokeDasharray="10 10"/>
        <path d="M41 232.707L90.583 160.78" stroke="url(#paint10_linear_3554_87054)" strokeMiterlimit="10"/>
        <path d="M127.885 156.829L174.174 230.907H287.913" stroke="url(#paint11_linear_3554_87054)" strokeMiterlimit="10"/>
    </g>
    <defs>
        <filter id="filter0_d_3554_87054" x="0" y="0.901123" width="291.913" height="578.645" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3554_87054"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3554_87054" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_3554_87054" x1="72.7082" y1="368.16" x2="150.419" y2="526.428" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#0FA0FF"/>
            <stop offset="0.22" stopColor="#0AB7FE"/>
            <stop offset="0.42" stopColor="#1F55FF"/>
            <stop offset="0.94" stopColor="#080808" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3554_87054" x1="72.7042" y1="368.147" x2="150.415" y2="526.416" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#2CD9FF"/>
            <stop offset="0.255208" stopColor="#53BCDD"/>
            <stop offset="0.510417" stopColor="#3C6FF3"/>
            <stop offset="0.94" stopColor="#323137" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3554_87054" x1="72.7038" y1="368.134" x2="110.7" y2="448.101" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#6D91ED"/>
            <stop offset="0.22" stopColor="#7B63ED"/>
            <stop offset="0.4375" stopColor="#7375EC"/>
            <stop offset="0.94" stopColor="#3769EA" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_3554_87054" x1="4.29301" y1="447.18" x2="219.688" y2="447.18" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#0DACFF"/>
            <stop offset="0.145833" stopColor="#1D5AFF"/>
            <stop offset="0.63" stopColor="#0B0B0B" stopOpacity="0"/>
            <stop offset="0.96" stopColor="#0B0B0B" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_3554_87054" x1="4.29301" y1="447.18" x2="219.688" y2="447.18" gradientUnits="userSpaceOnUse">
            <stop offset="0.145833" stopColor="#86D1F5"/>
            <stop offset="0.609375" stopColor="#30C1EF"/>
            <stop offset="0.94" stopColor="#252529" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint5_linear_3554_87054" x1="4.29301" y1="447.18" x2="219.688" y2="447.18" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#6C95ED"/>
            <stop offset="0.22" stopColor="#7471ED"/>
            <stop offset="0.42" stopColor="#834EED"/>
            <stop offset="0.94" stopColor="#3571F2" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint6_linear_3554_87054" x1="40.63" y1="535.299" x2="90.953" y2="535.299" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint7_linear_3554_87054" x1="127.497" y1="532.535" x2="287.913" y2="532.535" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint8_linear_3554_87054" x1="72.7082" y1="29.6048" x2="150.419" y2="187.873" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#0FA0FF"/>
            <stop offset="0.22" stopColor="#0AB7FE"/>
            <stop offset="0.42" stopColor="#1F55FF"/>
            <stop offset="0.94" stopColor="#080808" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint9_linear_3554_87054" x1="4.29301" y1="108.625" x2="219.688" y2="108.625" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#0DACFF"/>
            <stop offset="0.145833" stopColor="#1D5AFF"/>
            <stop offset="0.63" stopColor="#0B0B0B" stopOpacity="0"/>
            <stop offset="0.96" stopColor="#0B0B0B" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint10_linear_3554_87054" x1="40.63" y1="196.744" x2="90.953" y2="196.744" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint11_linear_3554_87054" x1="127.497" y1="193.981" x2="287.913" y2="193.981" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
    </defs>
</>;