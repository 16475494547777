export const vestingConstants = {
    DROPDOWN_LIST: [
        { value: 'BSC', label: 'BSC' },
        { value: 'Moonbeam', label: 'Moonbeam' },
        { value: 'MATIC', label: 'MATIC' },
        { value: 'FTM', label: 'FTM' },
        { value: 'ETH', label: 'ETH' },
        { value: 'AVAXc', label: 'AVAXc' },
    ],
    MAX_SUPPLY: 20000000,
    FORMAT: '0,0.[00]',
    USDT_ADDRESSES: [{
        chain: 'BSC',
        contractAddress: '0x1A4721fc27be2B9f2f9DeD865F6d47DB1291b499',
        usdtAddress: '0x55d398326f99059ff775485246999027b3197955',
        investor: '0x399b46063A079EfF11Bb63d55736B4379333AD8D'
    },
    {
        chain: 'Moonbeam',
        contractAddress: '0x4AfFE1AC6a1Df5932dFcF5782D55095ee62E6f1F',
        usdtAddress: '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
        investor: '0x57C7D81c1bED883258BbDe9BC9CBca3055a5C850'
    },
    {
        chain: 'MATIC',
        contractAddress: '0x3e291c4d9d1970ECD1d2f04a62E5fDf65b5B8f87',
        usdtAddress: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        investor: '0x5FA471898C8480d8d52086E8691f20951bbf301a'
    },
    {
        chain: 'FTM',
        contractAddress: '0x3B9F760BBE23510F84d6329dF06FF0e31582dD86',
        usdtAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        investor: '0xBc54e24978a34396fBbd3C0398DCC74D8eA3dE19'
    },
    {
        chain: 'ETH',
        contractAddress: '0xb265D3778436Fc5c385AE10D72572f2106e28519',
        usdtAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        investor: '0xb265D3778436Fc5c385AE10D72572f2106e28519'
    },
    {
        chain: 'AVAXc',
        contractAddress: '0x32862346217A4c1bBcEA72496CCC809Aee704B4b',
        usdtAddress: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        investor: '0xB6ea01c4d1369F6c089564A3c7aCC08D12e3b3c0'
    }
    ],
    INVESTORS: {
        TITLE: 'INVEST',
        TIP_TITLE: 'How it works',
        TIP: 'Tip',
        PERIOD: 'Invest period',
        SUM_DAYS: '545',
        INTEREST: 'Interest free',
        PART_DAYS: ['180', '365'],
        EVENT_INCOME: 'Even income',
        INVEST_START: 'Invest start',
        INVEST_END: 'Invest end',
        DAYS: 'DAYS',
        PURCHASE_AMOUNT: 'Purchase amount',
        MIN_PURCHASE: 'Minimum purchase',
        MAX_PURCHASE: 'Maximum purchase',
        INVEST: 'Invest'
    },
    VEST: {
        HEADER: {
            TITLE: 'SAFEBUY',
            TIP_TITLE: 'Safe buy - how it works and why it\'s safe?',
            TIP: `This is a two sided vesting smart contract where investor can cancel the investment in any moment. 
            The contract will lock the USDT from investors side and MCS from the teams side.
            Every day the team will claim 1/90 of the locked  in the contract $USDT, and you will claim 1/90 of $MCS. 
            If you don't like the progress of the project you can cancel the investment any time with a small cancelation fee of 1%. 
            Example: Invest 10,000 USDT , 90 days SafeBuy and canceling after 15 days - the contract will return you 8250 USDT and MCS claimed for 15 days. ((10,000*75/90) -1%))`
        },
        CONTENT: {
            PERIOD: 'SafeBuy period',
            START: 'SafeBuy start',
            END: 'SafeBuy end',
            TOTAL_AMOUNT: 'Total amount',
            WITHDRAW: 'Withdraw',
            AVAILABLE: 'Available for withdraw',
            LEFT: 'Left',
            DAYS: 'days',
            ENTER_VALUE: 'Please enter value',
            CANCEL: 'Cancel investment',
            BUY: 'Buy',
            WARNING: `Do you really want to stop investment? 
            If you’ll stop invetment you will pay for part of token and fine 1% of lefted USDT`,
            USDT_TO_PAY: 'USDT to pay'
        },
        USDT_AMOUNT: {
            DAYS_180: 0.14,
            DAYS_90: 0.13
        }
    },
    BUY: {
        BUY_ERRORS: {
            COUNTER_ERROR: 'Too many tries of signIn! Please check your credentials',
            ACCOUNT_ERROR: 'Please check your MetaMask account and reload this page',
        },
        CHANGE_RATE: 10,
        PROJECT_NAME: 'monoceros',
        CONTENT: {
            TITLE_1: 'GET IN EARLY',
            TITLE_2: 'ON THE PRE-SALE',
            CONNECT_METAMASK_WALLET: 'Connect MetaMask',
            CONNECT_TRUST_WALLET: 'Wallet Connect',
            TRUST_WALLET: 'trustwallet',
            METAMASK: 'metamask',
            ENABLE: 'Enable',
            BUY: 'Buy',
            BUYING: 'Buying',
            SELLING: 'Selling',
            DISCLAIMER: 'MCS is the utility token of Monoceros Terminal which provides holders a list of benefits - discounts, governance right’s, income rewards and more to come.',
            SIGN_MESSAGE: 'Please sign to let us verify that you are the owner of this address'
        },
        API: {
            method: 'GET',
            url: '/web3/ico'
        },
        APPROVE_API: {
            method: 'GET',
            url: '/web3/buyapproves?address='
        },
        ADDRESS: '0xDa5aC8F284537d6eaB198801127a9d49b0CbDff5',
    },
    ABI: [
        {
            'constant': true,
            'inputs': [],
            'name': 'name',
            'outputs': [
                {
                    'name': '',
                    'type': 'string'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'constant': false,
            'inputs': [
                {
                    'name': '_spender',
                    'type': 'address'
                },
                {
                    'name': '_value',
                    'type': 'uint256'
                }
            ],
            'name': 'approve',
            'outputs': [
                {
                    'name': '',
                    'type': 'bool'
                }
            ],
            'payable': false,
            'stateMutability': 'nonpayable',
            'type': 'function'
        },
        {
            'constant': true,
            'inputs': [],
            'name': 'totalSupply',
            'outputs': [
                {
                    'name': '',
                    'type': 'uint256'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'constant': false,
            'inputs': [
                {
                    'name': '_from',
                    'type': 'address'
                },
                {
                    'name': '_to',
                    'type': 'address'
                },
                {
                    'name': '_value',
                    'type': 'uint256'
                }
            ],
            'name': 'transferFrom',
            'outputs': [
                {
                    'name': '',
                    'type': 'bool'
                }
            ],
            'payable': false,
            'stateMutability': 'nonpayable',
            'type': 'function'
        },
        {
            'constant': true,
            'inputs': [],
            'name': 'decimals',
            'outputs': [
                {
                    'name': '',
                    'type': 'uint8'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'constant': true,
            'inputs': [
                {
                    'name': '_owner',
                    'type': 'address'
                }
            ],
            'name': 'balanceOf',
            'outputs': [
                {
                    'name': 'balance',
                    'type': 'uint256'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'constant': true,
            'inputs': [],
            'name': 'symbol',
            'outputs': [
                {
                    'name': '',
                    'type': 'string'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'constant': false,
            'inputs': [
                {
                    'name': '_to',
                    'type': 'address'
                },
                {
                    'name': '_value',
                    'type': 'uint256'
                }
            ],
            'name': 'transfer',
            'outputs': [
                {
                    'name': '',
                    'type': 'bool'
                }
            ],
            'payable': false,
            'stateMutability': 'nonpayable',
            'type': 'function'
        },
        {
            'constant': true,
            'inputs': [
                {
                    'name': '_owner',
                    'type': 'address'
                },
                {
                    'name': '_spender',
                    'type': 'address'
                }
            ],
            'name': 'allowance',
            'outputs': [
                {
                    'name': '',
                    'type': 'uint256'
                }
            ],
            'payable': false,
            'stateMutability': 'view',
            'type': 'function'
        },
        {
            'payable': true,
            'stateMutability': 'payable',
            'type': 'fallback'
        },
        {
            'anonymous': false,
            'inputs': [
                {
                    'indexed': true,
                    'name': 'owner',
                    'type': 'address'
                },
                {
                    'indexed': true,
                    'name': 'spender',
                    'type': 'address'
                },
                {
                    'indexed': false,
                    'name': 'value',
                    'type': 'uint256'
                }
            ],
            'name': 'Approval',
            'type': 'event'
        },
        {
            'anonymous': false,
            'inputs': [
                {
                    'indexed': true,
                    'name': 'from',
                    'type': 'address'
                },
                {
                    'indexed': true,
                    'name': 'to',
                    'type': 'address'
                },
                {
                    'indexed': false,
                    'name': 'value',
                    'type': 'uint256'
                }
            ],
            'name': 'Transfer',
            'type': 'event'
        }
    ]
};
