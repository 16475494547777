import React, { useEffect, useRef } from 'react';
import { imagesTemplate } from './templates';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { RemakeDashboardState } from '../../../models/remake-dashboard-model';
// import { SMALL_PARTS } from './images/smallParts';
import { useStoreState } from 'easy-peasy';
// import { dashboardEffects } from '../actions/dashboardEffects';
import { isEmpty } from 'lodash';
import { remakeDashboardEffects } from '../actions/remakeDashboardEffects';

export const ImagesGenerator = props => {
    const { accs } = props;
   
    const leftRef = useRef();
    const middleRef = useRef();
    const rightRef = useRef();
    const rightNoDataRef = useRef();
    const ref = useRef();
    const state = RemakeDashboardState.useStoreState(state => state);
    const sizes = useStoreState(state => state.sizes.values);
    const actions = RemakeDashboardState.useStoreActions(actions => actions);
    const usedExchanges = useStoreState(state => state?.coinData?.exchanges);
    useEffect(() => {
   
        drawHelper.drawLeftTitles(d3.select(leftRef.current), state, accs, actions);
        drawHelper.drawMiddleTitles(d3.select(middleRef.current), state.total);
        drawHelper.drawRightTitles(d3.select(rightRef.current), state, actions, usedExchanges);
        drawHelper.drawRightNoDataTitles(d3.select(rightNoDataRef.current), actions);
    }, );
    useEffect(() => {
        const { height } = ref?.current?.getBoundingClientRect();
        actions[remakeDashboardEffects.actionTypes.DASHBOARD_SIZES_UPDATED]({ height: height });
    }, [sizes]);
    return <div ref={ref} className="images-background-grid">
        <div className="text-start">
            <svg ref={leftRef} viewBox="-30 0 685 800" fill="none" className="image-left-part">
                { imagesTemplate.leftImagePart() }
            </svg>
        </div>
        <div className="text-center pt-4">
            <svg ref={middleRef} viewBox="0 0 611 658" fill="none" className="image-middle-part">
                { imagesTemplate.middleImagePart() }
            </svg>
        </div>
        <div className="text-end">
            {
                !isEmpty(usedExchanges) ?
                    <svg ref={rightRef} viewBox="-50 -20 678 800" fill="none" className="image-right-part">
                        { imagesTemplate.rightImagePart() }
                    </svg> 
                    :
                    <svg ref={rightNoDataRef} viewBox="-50 -135 678 800" fill="none" className="image-right-part">
                        { imagesTemplate.rightImagePartNoData() }
                    </svg>
            }
        </div>
    </div>;
};
