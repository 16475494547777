import React, { useState } from 'react';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { constants } from '../constants';
import '../styles/open_orders_styles.scss';
import { Button } from 'react-bootstrap';
import { Asset } from '../../../components/utility/asset-component/view';
import { FormControl, InputGroup } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { shareOrderResultEffects } from '../actions/shareOrderResultEffects';

const {
  TABLE_ORDERS_DATA,
  TABLE_POSITIONS_DATA,
  OPEN_ORDERS_DATA: { N0_ORDERS_TITLE, BUTTON_OPEN_ORDER, BUTTON_OPEN_POSITIONS },
} = constants;

const renderHeader = (headersList, className, actions) => {
  const headerData = [...headersList];

  return (
    <div className={`orders-table-row position-relative ${className}`}>
      {headerData.map((data, i) => (
        <div
          className="orders-table-header-cell font-size-14 font-weight-medium"
          key={`header-${i}`}
        >
          {data}
        </div>
      ))}
      {className === 'table-header-positions' ? (
        <>
          <div className="orders-table-header-cell font-size-14 font-weight-medium position-absolute end-0">
            <Button
              onClick={() => {
                actions[shareOrderResultEffects.actionTypes.SHARE_ORDER_RESULT_MODAL_INVOKED]({
                  name: 'open-modal',
                });
              }}
              variant="primary"
              size="sm"
            >
              Share Result
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

const renderOrders = (data, i) => {
  const { pair, volume, side, type, source, creationDate } = data;
  return (
    <div className="table-order-body" key={i}>
      <div className="pair">
        <Asset asset={pair} />
      </div>
      <div className="volume">{volume}</div>
      <div className="side">{side}</div>
      <div className="type">{type}</div>
      <div className="source">{source}</div>
      <div className="creationDate">{creationDate}</div>
      <div className="icon-refresh">
        <i className="fa-regular fa-refresh cursor-pointer"></i>
      </div>
      <div>Reverce Pack</div>
      <div className="actions-icon">X</div>
    </div>
  );
};

const renderPositions = (data, i) => {
  const { pair, side, leverage, size, source } = data;

  return (
    <div className="table-positions-body" key={i}>
      <div className="pair d-flex">
        <Asset asset={pair} />
      </div>
      <div className="side">{side}</div>
      <div className="leverage">{leverage}</div>
      <div className="size">{size}</div>
      <div className="source">{source}</div>
      <div className="actions-icon">X</div>
    </div>
  );
};

const renderOrdersBody = (data) => data.map((value, i) => renderOrders(value, i));
const renderPositionsBody = (data) => data.map((value, i) => renderPositions(value, i));

export const OpenOrderTemplate = (state, onClickHandler, actions) => {
  const ordersState = SmartTradeState.useStoreState((state) => state.ordersData);
  const positionsState = SmartTradeState.useStoreState((state) => state.positionsData);
  const [isClicked, setIsClicked] = useState(false);
  const ordersLength = ordersState.length;
  const toggleIcon = () => {
    setIsClicked((prevState) => !prevState);
  };
  return (
    <div className="open-order-controls">
      <div className="content-controls d-flex">
        {ordersLength > 0 ? `You have ${ordersLength} open orders` : 'There are no open orders'}
        <div className="ms-auto p-5">
          <i
            onClick={toggleIcon}
            className={`cursor-pointer table-toggler fa-solid fa-chevron-${
              isClicked ? 'up' : 'down'
            }`}
          ></i>
        </div>
      </div>
      {isClicked && (
        <div>
          <div className="buttons-controls">
            <Button
              className="btn-link"
              variant="link"
              size="sm"
              name="button_table_orders"
              active={state.viewTypeOrders.orders}
              onClick={onClickHandler}
            >
              {BUTTON_OPEN_ORDER}
            </Button>
            <Button
              className="btn-link"
              variant="link"
              size="sm"
              name="button_table_positions"
              active={state.viewTypeOrders.positions}
              onClick={onClickHandler}
            >
              {BUTTON_OPEN_POSITIONS}
            </Button>
            <div className="search-input ms-auto ">
              <InputGroup>
                <InputGroup.Text className="clear">
                  <i className="fa-thin fa-magnifying-glass"></i>
                </InputGroup.Text>
                <FormControl placeholder={'Type your search'} />
                <InputGroup.Text className="custom-btn-search">Search</InputGroup.Text>
              </InputGroup>
            </div>
            <div className="text-end">
              <i className="fa-regular fa-refresh cursor-pointer"></i>
            </div>
          </div>
          <div className="table-order">
            <div className="orders-table h-100">
              {state.viewTypeOrders.orders
                ? renderHeader(TABLE_ORDERS_DATA, 'table-header-order')
                : renderHeader(TABLE_POSITIONS_DATA, 'table-header-positions', actions)}
            </div>
            {state.viewTypeOrders.orders ? (
              !isEmpty(ordersState) ? (
                renderOrdersBody(ordersState)
              ) : (
                <EmptySearchState />
              )
            ) : !isEmpty(positionsState) ? (
              renderPositionsBody(positionsState)
            ) : (
              <EmptySearchState />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
