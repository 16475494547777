import React from 'react';
import { PlansPage } from '../plansPage/view';
import { BuyPage } from './templates/buyPage/view';
import { TeamPage } from './templates/teamPage/view';

export const constants = {
  NAV: {
    NAV_LOGO: ['Monoceros'],
  },
  FOOTER: {
    LABEL: 'Monoceros',
    LINKS: [
      { label: 'Trade app', link: 'trade' },
      { label: 'Documentation', link: 'docs' },
      { label: 'Grants', link: '/' },
      { label: 'Subscriptions', link: 'subscriptions', content: <PlansPage /> },
      { label: 'Team', link: 'team', content: <TeamPage /> },
      { label: 'Buy $MCS', link: 'buy', content: <BuyPage /> },
    ],
    FOOTER_MEDIA: {
      ITEMS: [
        {
          icon: '../dashboard-library/home-page-images/instagram.svg',
          link: 'https://www.instagram.com/monoceros.app/?igshid=YmMyMTA2M2Y%3D',
        },
        {
          icon: '../dashboard-library/home-page-images/telegram.svg',
          link: 'https://t.me/monoceros_news',
        },
        {
          icon: '../dashboard-library/home-page-images/twitter.svg',
          link: 'https://twitter.com/MonocerosApp',
        },
        {
          icon: '../dashboard-library/home-page-images/fb.svg',
          link: 'https://www.facebook.com/MonocerosApp',
        },
      ],
      TELEGRAM: { label: 'Telegram', link: 'https://t.me/monoceros_news' },
      TWITTER: { label: 'Twitter', link: 'https://twitter.com/MonocerosApp' },
    },
  },
  HOME_CONTENT: {
    START: {
      HEADING: 'Monoceros terminal',
      DESCRIPTION: "CeDeFi aggregator - track, trade and manage investment's  from a single UI.",
      // INVEST: 'Invest',
      TRADE: 'Trade',
      BUY: 'Buy',
    },
    FOR_INVESTORS: {
      TOPIC: 'Lorem Ipsum',
      HEADING: 'Lorem ipsum dolor sit amet, consectetur.',
      DESCRIPTION: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi porta quam in nulla pulvinar efficitur.
             Etiam vitae sem quis urna placerat pharetra quis ut ante. Nunc vel massa id metus consectetur aliquam id in tortor.`,
      LIST: [
        {
          icon: '../dashboard-library/home-page-images/shield.svg',
          label: 'Battle tested and secure',
        },
        {
          icon: '../dashboard-library/home-page-images/pack.svg',
          label: 'Capital and gas efficient',
        },
        // { icon: '../dashboard-library/home-page-images/dollar.svg', label: 'Earn $BAL token incentives' },
      ],
      START_INVEST: 'Start investing',
      INVEST_FEATURE: 'Invest features',
    },
    FOR_TRADERS: {
      TOPIC: 'Lorem Ipsum',
      HEADING: 'Lorem ipsum dolor sit amet, consectetur.',
      DESCRIPTION: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi porta quam in nulla pulvinar efficitur.
             Etiam vitae sem quis urna placerat pharetra quis ut ante. Nunc vel massa id metus consectetur aliquam id in tortor.`,
      LIST: [
        { icon: '../dashboard-library/home-page-images/price.svg', label: 'Intelligent pricing' },
        { icon: '../dashboard-library/home-page-images/mev.svg', label: 'MEV protection' },
        {
          icon: '../dashboard-library/home-page-images/gas.svg',
          label: 'Gas optimizations and subsidies',
        },
      ],
      START_TRADE: 'Start trading',
      TRADE_FEATURE: 'Trade features',
    },
    FOR_BUILDERS: {
      TOPIC: 'Lorem Ipsum',
      HEADING: 'Lorem ipsum dolor sit amet, consectetur.',
      DESCRIPTION: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi porta quam in nulla pulvinar efficitur.
             Etiam vitae sem quis urna placerat pharetra quis ut ante. Nunc vel massa id metus consectetur aliquam id in tortor.`,
      LIST: [
        {
          icon: '../dashboard-library/home-page-images/swap.svg',
          label: 'Custom token weights & swap fees',
        },
        {
          icon: '../dashboard-library/home-page-images/pools.svg',
          label: 'Liquidity pools can operate on any price curve',
        },
        {
          icon: '../dashboard-library/home-page-images/asset.svg',
          label: 'Asset managers re-utilize dormant liquidity (coming soon)',
        },
      ],
      START_BUILDING: 'Start building',
    },
    REVIEWS: {
      LABEL: 'Reviews',
    },
    FOOTER: {
      LABEL: 'Monoceros',
      MAIN_MENU: [
        { label: 'Home', link: '/' },
        { label: 'Invest', link: '/' },
        { label: 'Trade', link: '/' },
        { label: 'Build', link: '/' },
      ],
      PRODUCTS: {
        LABEL: 'Products',
        ITEMS: [
          { label: 'Invest app', link: '/' },
          { label: 'Trade app', link: '/' },
        ],
      },
      LEARN: {
        LABEL: 'Learn',
        ITEMS: [
          { label: 'Blog', link: '/' },
          { label: 'Documentation', link: '/' },
          { label: 'Whitepaper', link: '/' },
          { label: 'Github code', link: '/' },
          { label: 'Careers', link: '/' },
        ],
      },
      ECOSYSTEM: {
        LABEL: 'Ecosystem',
        ITEMS: [
          { label: 'Snapshot governance', link: '/' },
          { label: 'Dune analytics', link: '/' },
          { label: 'Forum', link: '/' },
          { label: 'Grants', link: '/' },
        ],
      },
    },
  },
  BUILD_CONTENT: {
    START: {
      LABEL: 'A core building block of DeFi',
      DESCRIPTION:
        'A reliable, open-source, permissionless protocol. Build on Balancer to create new innovative types of financial dapps.',
      BUTTON: 'Github code',
    },
    BUILDING: {
      LABEL: 'Start building',
      DESCRIPTION: 'Focus on new AMM logic rather than low-level accounting.',
    },
    TABLE: {
      CREATE: 'Create a smart pool',
      LAUNCH: 'Launch a token',
      USE: 'Use price oracles',
      GET: 'Get Monoceros data',
    },
    LIQUID_LAUNCH: {
      TOPIC: 'Launch a token',
      LABEL: 'LBPs for a fair and liquid launch',
      DESCRIPTION: `A Liquidity Bootstrapping Pool is an adjustable Balancer Smart Pool designed for initial distribution and price discovery of new assets.
             Typically, the weight of assets in an LBP are programmed to continually change during the token sale.`,
    },
  },
  INVEST_CONTENT: {
    START: {
      TOPIC: 'Monoceros',
      POST_TOPIC: 'a multifunctional all-in-one non custodial investing terminal',
      DESCRIPTION: `The terminal aggregates a variety of CeFi and DeFi products - CEX,
            DEX, AMM, LP’s, liquidation panels and NFT marketplaces allowing
            users to explore the most attractive investment opportunities. Track and manage funds by creating automated processes from one single UI.`,
      BUTTON: 'Launch invest app',
    },
    HOLD: {
      LABEL: 'Lorem ipsum',
      TOPIC: 'Lorem ipsum dolor',
      TEXT: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             Etiam eget commodo massa. Nullam pellentesque ultricies ultricies. Cras in metus non lacus pretium pretium.
             Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam viverra ante vel`,
    },
    STATISTICS: {
      STATISTICS_TOPIC: 'Lorem ipsum',
      CONTENT: {
        PRICE_CELL: {
          TEXT: `ProTrade – is a complete order execution method. Set your preferred parameters before launching a trade - send trailing buy/sell orders to enter 
                    a trade with the best possible price, split your take profit targets and activate stop losses according your risk management strategy.`,
        },
        TRANSACTIONS_CELL: {
          TEXT: `GridBot – the markets experiencing extreme volatility and you can’t decide to go long or short? 
                    Just pick lower and upper prices, price deviation and orders count and the bot will automatically buy and sell in the given ranges take a maximal advantage in a volatile markets.`,
        },
        DEFI_CELL: {
          TEXT: `Vaults/Farms – aggregating liquidity pools and vaults across a variety of DeFi protocols enables
                     the user to pick the most suitable yield opportunities and increase passive income - filtering by APR, liquidity or the preferred token.`,
        },
        NOODES_CELL: {
          TEXT: `PackTrade – create multiple portfolios, customize assets weight and execute ProTrade on the whole pack with one click. 
                    When markets experiencing a “flash-crash” it can provide you a good long/short opportunity, with PackTrade it’s possible to send multiple trades by pushing only one button.`,
        },
      },
    },
  },
  PLANS_CONTENT: {
    TRIAL_CARD: {
      HEADER: {
        TOPIC: 'Mint Trial',
        DESCRIPTION: 'Unlimited access to all features',
        PERIOD: '7 days',
      },
      CONTENT: [
        'ProTrades',
        'GridBots',
        'DCA Bots',
        'Packs',
        'CopyTrading Bots',
        'Paper trading account',
      ],
      FOOTER: 'Start Trial',
    },
    BEGINNER_CARD: {
      HEADER: {
        TOPIC: 'Beginner',
      },
      CONTENT: [
        { label: 'ProTrades', amount: '20' },
        { label: 'GridBots', amount: '5' },
        { label: 'DCA Bots', amount: '5' },
        { label: 'Packs', amount: '10' },
        { label: 'CopyTrading Bots', amount: '1' },
        { label: 'Paper trading account', amount: '' },
      ],
      FOOTER: 'Coming soon',
    },
    ADVANCED_CARD: {
      HEADER: {
        TOPIC: 'Advanced',
      },
      CONTENT: [
        { label: 'ProTrades', amount: '20' },
        { label: 'GridBots', amount: '10' },
        { label: 'DCA Bots', amount: '10' },
        { label: 'Packs', amount: '20' },
        { label: 'CopyTrading Bots', amount: '3' },
        { label: 'Paper trading account', amount: '' },
      ],
      FOOTER: 'Coming soon',
    },
    EXPERT_CARD: {
      HEADER: {
        TOPIC: 'Expert',
      },
      CONTENT: [
        { label: 'ProTrades', amount: 'Unlimited' },
        { label: 'GridBots', amount: 'Unlimited' },
        { label: 'DCA Bots', amount: 'Unlimited' },
        { label: 'Packs', amount: 'Unlimited' },
        { label: 'CopyTrading Bots', amount: 'Unlimited' },
        { label: 'Paper trading account', amount: '' },
      ],
      FOOTER: 'Coming soon',
    },
  },
  TRADE_CONTENT: {
    START: {
      TOPIC: 'Monoceros',
      POST_TOPIC: "World's first non-custodial CeDeFi aggregator.",
      DESCRIPTION: `The terminal aggregates a variety of CeFi and DeFi products - CEX,
            DEX, AMM, LP’s, liquidation panels and NFT marketplaces allowing
            users to explore the most attractive investment opportunities. Track and manage funds by creating automated processes from one single UI.`,
      BUTTON: 'Launch app',
      HOW_IT_WORKS: 'How it works',
    },
    BARRIERS: {
      LABEL: 'Why crypto needs Monoceros?',
      SUBTITLE: 'Web3 login & NFT cross-chain subscription API exchange connection',
      LIST: [
        {
          text: 'Securely monitor and manage all your assets in real time in one place.',
          icon: '../dashboard-library/home-page-images/shield-barriers.svg',
        },
        {
          text: 'Provide clarity, education, and the right tools for CeDeFi investments.',
          icon: '../dashboard-library/home-page-images/clarity.svg',
        },
        {
          text: 'Find investments from thousands of protocols, exchanges, and financial products.',
          icon: '../dashboard-library/home-page-images/investments.svg',
        },
      ],
    },
    AGGREGATED: {
      TITLE: 'Aggregated chains',
      LIST: [
        '../dashboard-library/home-page-images/aggregated-chains/1.svg',
        '../dashboard-library/home-page-images/aggregated-chains/2.svg',
        '../dashboard-library/home-page-images/aggregated-chains/3.svg',
        '../dashboard-library/home-page-images/aggregated-chains/4.svg',
        '../dashboard-library/home-page-images/aggregated-chains/5.svg',
        '../dashboard-library/home-page-images/aggregated-chains/6.svg',
        '../dashboard-library/home-page-images/aggregated-chains/7.svg',
      ],
    },
    AGGREGATED_PROTOCOLS: {
      TITLE: 'Aggregated protocols and exchanges',
      LIST: [
        '../dashboard-library/home-page-images/aggregated-protocols/1.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/2.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/3.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/4.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/5.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/6.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/7.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/8.svg',
        '../dashboard-library/home-page-images/aggregated-protocols/9.svg',
      ],
      WANT_TO_TRY: 'Want to try',
    },
    HOW_IT_WORKS: {
      TITLE: 'How it Works',
      ITEMS: [
        {
          label: 'Lending',
          description: `AMM’s let users to leverage their
                investment without selling their favorite assets by
                supplying them as a collateral, take a borrow or mint a
                synthetic stable coins. Practically acting like a
                decentralized banking system without a mediator, our
                dashboard provides users with the best lending rates
                across different chains and allows to monitor and
                manage risks.`,
          image: '../dashboard-library/home-page-images/how-it-works/1.svg',
        },
        {
          label: 'ProTrade',
          description: `A complete order execution
                method. Set your preferred parameters before
                launching a trade - send trailing buy/sell orders to
                enter a trade with the best possible price, split your
                take profit targets and activate stop losses according
                your risk management strategy.`,
          image: '../dashboard-library/home-page-images/how-it-works/2.svg',
        },
        {
          label: 'GridBot',
          description: `The markets experiencing extreme
                volatility and you can’t decide to go long or short?
                Just pick lower and upper prices, price deviation and
                orders count and the bot will automatically buy and
                sell in the given ranges take a maximal advantage in a
                volatile markets.`,
          image: '../dashboard-library/home-page-images/how-it-works/3.svg',
        },
        {
          label: 'Vaults/Farms',
          description: `Aggregating liquidity
                pools and vaults across a variety of DeFi protocols
                enables the user to pick the most suitable yield
                opportunities and increase passive income - filtering
                by APR, liquidity or the preferred token.`,
          image: '../dashboard-library/home-page-images/how-it-works/4.svg',
        },
        {
          label: 'PackTrade',
          description: `Create multiple portfolios,
                customize assets weight and execute ProTrade on the
                whole pack with one click.
                When markets experiencing a “flash-crash” it can
                provide you a good long/short opportunity, with
                PackTrade it’s possible to send multiple trades by
                pushing only one button.`,
          image: '../dashboard-library/home-page-images/how-it-works/5.svg',
        },
        {
          label: 'Liquidator dashboard',
          description: `Liquidating over-leveraged accounts is a highly
                profitable source of income - the liquidator repays the
                borrowed asset and receiving the supplied asset - the
                difference in the collateral value is distributed between
                the protocol and the liquidator. We provide a simple
                dashboard to track health status of accounts and
                execute liquidations simple and fast.`,
          image: '../dashboard-library/home-page-images/how-it-works/6.svg',
        },
      ],
    },
    TABLE: {
      ULTRICIES_1: {
        LABEL: 'PackTrade',
        DESCRIPTION: `Create multiple portfolios,
                customize assets weight and execute ProTrade on the
                whole pack with one click.
                When markets experiencing a “flash-crash” it can
                provide you a good long/short opportunity, with
                PackTrade it’s possible to send multiple trades by
                pushing only one button. `,
      },
      ULTRICIES_2: {
        LABEL: 'ProTrade',
        DESCRIPTION: `A complete order execution
                method. Set your preferred parameters before
                launching a trade - send trailing buy/sell orders to
                enter a trade with the best possible price, split your
                take profit targets and activate stop losses according
                your risk management strategy.`,
      },
      NULLAM: {
        LABEL: 'GridBot',
        DESCRIPTION: `The markets experiencing extreme
                volatility and you can’t decide to go long or short?
                Just pick lower and upper prices, price deviation and
                orders count and the bot will automatically buy and
                sell in the given ranges take a maximal advantage in a
                volatile markets.`,
      },
      NULLAM_DICTUM: {
        LABEL: 'Vaults/Farms',
        DESCRIPTION: `Aggregating liquidity
                pools and vaults across a variety of DeFi protocols
                enables the user to pick the most suitable yield
                opportunities and increase passive income - filtering
                by APR, liquidity or the preferred token.`,
      },
      ARINGILLA_VELL: {
        LABEL: 'Lending',
        DESCRIPTION: `AMM’s let users to leverage their
                investment without selling their favorite assets by
                supplying them as a collateral, take a borrow or mint a
                synthetic stable coins. Practically acting like a
                decentralized banking system without a mediator, our
                dashboard provides users with the best lending rates
                across different chains and allows to monitor and
                manage risks.`,
      },
      DICTUM: {
        LABEL: 'Liquidator dashboard',
        DESCRIPTION: `Liquidating over-leveraged accounts is a highly
                profitable source of income - the liquidator repays the
                borrowed asset and receiving the supplied asset - the
                difference in the collateral value is distributed between
                the protocol and the liquidator. We provide a simple
                dashboard to track health status of accounts and
                execute liquidations simple and fast.`,
      },
    },
  },
  CONTACTS_CONTENT: {
    TITLE: 'All-in-one crypto portfolio manager & analytics dashboard',
    CONTACTS: [
      {
        TITLE: 'Website',
        SML_TITLE: '',
        ICONS: 'fa-light fa-globe',
        LINK: 'https://www.monoceros.app/home',
        TARGET: '_self',
      },
      {
        TITLE: 'Twitter',
        SML_TITLE: '',
        ICONS: 'fa-brands fa-twitter',
        LINK: 'https://twitter.com/monocerosapp?s=21&t=ha45_Ls7t6r_MYY6dx1lmA',
        TARGET: '_blank',
      },
      {
        TITLE: 'Instagram',
        SML_TITLE: '',
        ICONS: 'fa-brands fa-instagram',
        LINK: 'https://www.instagram.com/monoceros.app/?igshid=YmMyMTA2M2Y%3D',
        TARGET: '_blank',
      },
      {
        TITLE: 'TikTok',
        SML_TITLE: '',
        ICONS: 'fa-brands fa-tiktok',
        LINK: 'https://www.tiktok.com/@monoceros.app?_t=8VLpRzbgUvV&_r=1',
        TARGET: '_blank',
      },
      {
        TITLE: 'Facebook',
        SML_TITLE: '',
        ICONS: 'fa-brands fa-facebook',
        LINK: 'https://www.facebook.com/MonocerosApp',
        TARGET: '_blank',
      },
      {
        TITLE: 'Youtube',
        SML_TITLE: '',
        ICONS: 'fa-brands fa-youtube',
        LINK: 'https://www.youtube.com/channel/UC0MjkG91TJwqJDI174Vp2aw/about',
        TARGET: '_blank',
      },
      {
        TITLE: 'Telegram',
        SML_TITLE: 'En News',
        ICONS: 'fa-brands fa-telegram',
        LINK: 'https://t.me/monoceros_news',
        TARGET: '_blank',
      },
      {
        TITLE: 'Telegram',
        SML_TITLE: 'En Chat',
        ICONS: 'fa-brands fa-telegram',
        LINK: 'https://t.me/monoceros_app',
        TARGET: '_blank',
      },
      {
        TITLE: 'Telegram',
        SML_TITLE: 'CIS News',
        ICONS: 'fa-brands fa-telegram',
        LINK: 'https://t.me/monoceros_news_cis',
        TARGET: '_blank',
      },
      {
        TITLE: 'Telegram',
        SML_TITLE: 'CIS Chat',
        ICONS: 'fa-brands fa-telegram',
        LINK: 'https://t.me/monoceros_CIS',
        TARGET: '_blank',
      },
    ],
  },
};
