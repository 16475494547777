/* eslint-disable max-len */
/* eslintDisable max-len */
import React from 'react';

export const CEFI_IMG = <>
    <g clipPath="url(#clip0_3565_92674)">
        <ellipse cx="178.5" cy="183.176" rx="165.75" ry="165.75" fill="#1E1F23"/>
        <path opacity="0.65" d="M181.567 0.176392C181.567 0.176392 228.696 1.28833 264.364 24.3847L257.312 37.6788C257.312 37.6788 221.988 19.1902 182.319 15.403L181.567 0.176392Z" fill="url(#paint0_linear_3565_92674)"/>
        <path opacity="0.3" d="M21.1035 91.6756C21.1035 91.6756 43.8995 50.4435 80.9153 29.5423L89.4463 41.9378C89.4463 41.9378 56.6817 64.6443 34.9536 98.0395L21.1035 91.6756Z" fill="url(#paint1_linear_3565_92674)"/>
        <path opacity="0.3" d="M148.688 10.6311C148.688 10.6311 195.22 3.0978 234.532 19.2476L230.014 33.6208C230.014 33.6208 191.934 21.9228 152.216 25.4515L148.688 10.6311Z" fill="url(#paint2_linear_3565_92674)"/>
        <path opacity="0.3" d="M7.47168 131.004C7.47168 131.004 22.3199 86.3128 54.8504 58.9903L65.5296 69.6132C65.5296 69.6132 37.4885 97.9287 22.2542 134.758L7.47168 131.004Z" fill="url(#paint3_linear_3565_92674)"/>
        <path opacity="0.65" d="M40.2524 306.572C40.2524 306.572 7.76296 269.541 0 224.92L15.0412 220.406C15.0412 220.406 26.0244 261.318 51.2314 294.631L40.2524 306.572Z" fill="url(#paint4_linear_3565_92674)"/>
        <path d="M323.251 72.4156C323.251 72.4156 354.208 110.521 359.109 154.096L343.25 157.325C343.25 157.325 334.752 117.074 311.064 83.0919L323.251 72.4156Z" fill="url(#paint5_linear_3565_92674)"/>
        <path opacity="0.3" d="M360 190.638C360 190.638 358.883 237.717 335.766 273.349L322.467 266.304C322.467 266.304 340.975 231.017 344.766 191.389L360 190.638Z" fill="url(#paint6_linear_3565_92674)"/>
        <path opacity="0.65" d="M276.197 335.605C276.197 335.605 237.678 362.751 195.257 365.426L192.998 350.553C192.998 350.553 232.38 344.398 266.52 323.829L276.197 335.605Z" fill="url(#paint7_linear_3565_92674)"/>
        <path d="M267.585 250.211C267.585 250.211 246.61 277.724 223.268 287.267L221.891 276.322C221.891 276.322 243.53 264.667 262.167 242.937L267.585 250.211Z" fill="url(#paint8_linear_3565_92674)"/>
        <path d="M60.75 146.978C60.75 146.978 67.9243 117.972 86.7343 99.1764L93.75 105.428C93.75 105.428 77.7543 124.651 69.9986 148.676L60.75 146.978Z" fill="url(#paint9_linear_3565_92674)" stroke="url(#paint10_linear_3565_92674)" strokeMiterlimit="10"/>
        <path d="M146.666 291.292C146.666 291.292 120.083 286.163 102.089 269.668L107.355 262.816C107.355 262.816 125.579 276.66 147.701 282.597L146.666 291.292Z" fill="url(#paint11_linear_3565_92674)"/>
        <path d="M265.312 105.926C265.312 105.926 284.833 127.743 289.5 153.716L280.489 156.176C280.489 156.176 273.874 132.326 258.75 112.738L265.312 105.926Z" fill="url(#paint12_linear_3565_92674)" stroke="url(#paint13_linear_3565_92674)" strokeMiterlimit="10"/>
        <path d="M202.199 65.3677C202.199 65.3677 231.374 71.4686 250.9 89.8355L244.987 97.245C244.987 97.245 225.162 81.7559 200.902 74.8575L202.199 65.3677Z" fill="url(#paint14_linear_3565_92674)"/>
        <path opacity="0.3" d="M84.961 342.141C84.961 342.141 43.9077 318.987 23.3174 281.859L35.7997 273.443C35.7997 273.443 58.2343 306.354 91.4712 328.359L84.961 342.141Z" fill="url(#paint15_linear_3565_92674)"/>
        <path d="M174 308.426C244.416 308.426 301.5 251.511 301.5 181.301C301.5 111.092 244.416 54.1764 174 54.1764C103.584 54.1764 46.5 111.092 46.5 181.301C46.5 251.511 103.584 308.426 174 308.426Z" stroke="url(#paint16_linear_3565_92674)" strokeMiterlimit="10"/>
        <path d="M209.197 47.0124L210.947 39.9264C210.947 39.9264 255.627 50.6683 278.674 78.5366L273.141 82.4756C273.141 82.4756 240.853 54.0821 209.197 47.0124Z" fill="url(#paint17_linear_3565_92674)"/>
        <path d="M299.429 114.808L305.721 111.115C305.721 111.115 329.31 150.505 325.515 186.461L318.811 185.258C318.811 185.258 316.528 142.352 299.429 114.808Z" fill="url(#paint18_linear_3565_92674)"/>
        <path d="M76.5325 283.382L71.9569 287.896C71.9569 287.896 25.3545 237.838 30.0615 178.318L36.81 179.208C36.81 179.208 31.84 231.388 76.5325 283.382Z" fill="url(#paint19_linear_3565_92674)"/>
        <path d="M294.91 261.08L300.356 264.493C300.356 264.493 262.33 321.313 203.266 330.451L202.58 323.685C202.58 323.685 254.551 316.492 294.91 261.08Z" fill="url(#paint20_linear_3565_92674)"/>
        <path d="M57.4047 109.832L50.7959 105.565C50.7959 105.565 76.1303 64.3571 113.138 51.3421L115.257 58.3173C115.257 58.3173 82.0942 69.843 57.4047 109.832Z" fill="url(#paint21_linear_3565_92674)"/>
        <path d="M206.001 39.9314L202.757 39.1271L199.692 41.3797L201.676 45.3762L204.482 46.0696L203.414 42.5491L206.001 39.9314Z" fill="url(#paint22_linear_3565_92674)"/>
        <path d="M197.114 38.0111L193.832 37.3997L190.899 39.8247L193.117 43.7021L195.956 44.2273L194.686 40.7766L197.114 38.0111Z" fill="url(#paint23_linear_3565_92674)"/>
        <path d="M187.223 36.616L183.924 36.0909L181.062 38.5937L183.378 42.4096L186.229 42.861L184.869 39.4431L187.223 36.616Z" fill="url(#paint24_linear_3565_92674)"/>
        <path d="M167.725 323.361L164.402 322.996L161.662 325.63L164.164 329.331L167.035 329.647L165.511 326.299L167.725 323.361Z" fill="url(#paint25_linear_3565_92674)"/>
        <path d="M158.664 322.635L155.329 322.463L152.745 325.253L155.452 328.802L158.339 328.95L156.623 325.696L158.664 322.635Z" fill="url(#paint26_linear_3565_92674)"/>
        <path d="M151.226 321.453L147.882 321.371L145.377 324.227L148.178 327.702L151.066 327.776L149.263 324.568L151.226 321.453Z" fill="url(#paint27_linear_3565_92674)"/>
        <path d="M76.3848 291.201L79.071 293.187L82.7635 292.289L82.4719 287.833L80.1471 286.118L79.7775 289.777L76.3848 291.201Z" fill="url(#paint28_linear_3565_92674)"/>
        <path d="M83.8486 296.383L86.6458 298.209L90.2808 297.097L89.7304 292.67L87.3112 291.091L87.1551 294.767L83.8486 296.383Z" fill="url(#paint29_linear_3565_92674)"/>
        <path d="M89.6807 301.147L92.5271 302.899L96.1334 301.689L95.4639 297.278L93.0035 295.764L92.946 299.44L89.6807 301.147Z" fill="url(#paint30_linear_3565_92674)"/>
        <path d="M117.048 56.6105L120.198 55.4903L121.517 51.9288L117.652 49.6844L114.933 50.6487L117.763 52.9997L117.048 56.6105Z" fill="url(#paint31_linear_3565_92674)"/>
        <path d="M125.522 53.3198L128.602 52.0232L129.707 48.3879L125.723 46.3732L123.062 47.4934L126.023 49.6762L125.522 53.3198Z" fill="url(#paint32_linear_3565_92674)"/>
        <path d="M134.546 49.0402L137.589 47.6616L138.6 44.0016L134.562 42.0896L131.934 43.2795L134.953 45.3844L134.546 49.0402Z" fill="url(#paint33_linear_3565_92674)"/>
        <path d="M177.924 350.472C270.195 350.472 344.996 275.749 344.996 183.574C344.996 91.3991 270.195 16.6764 177.924 16.6764C85.6522 16.6764 10.8516 91.3991 10.8516 183.574C10.8516 275.749 85.6522 350.472 177.924 350.472Z" stroke="#8BB8FF" strokeWidth="3" strokeMiterlimit="10"/>
        <path d="M174 290.426C234.475 290.426 283.5 241.402 283.5 180.926C283.5 120.451 234.475 71.4264 174 71.4264C113.525 71.4264 64.5 120.451 64.5 180.926C64.5 241.402 113.525 290.426 174 290.426Z" stroke="#8BB8FF" strokeMiterlimit="10"/>
        <g opacity="0.5">
            <path opacity="0.5" d="M177.472 59.4498L180.877 62.6913" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M173.139 59.1749L176.544 62.4164" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M169.323 59.3924L172.728 62.6338" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M165.326 59.4375L168.731 62.679" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M161.503 59.6468L164.908 62.8882" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M157.843 60.0078L161.244 63.2493" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M154.359 60.5453L157.764 63.7827" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M150.412 61.2059L153.817 64.4433" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M146.522 61.9158L149.927 65.1572" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M142.912 62.8964L146.313 66.1379" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M139.43 63.4339L142.835 66.6713" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M135.819 64.4105L139.224 67.6519" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M132.377 66.0968L135.782 69.3342" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M128.857 67.7011L132.262 70.9426" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M125.115 69.0962L128.52 72.3377" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M121.406 70.5241L124.811 73.7655" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M118.478 71.7755L121.883 75.017" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M115.118 73.7081L118.523 76.9495" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M111.643 75.534L115.048 78.7754" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M108.607 77.1342L112.008 80.3715" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M105.745 79.3088L109.15 82.5503" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M102.677 81.2824L106.078 84.5239" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M99.5264 83.2437L102.931 86.4851" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M96.4707 85.6276L99.8716 88.8691" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M93.4883 88.0813L96.8933 91.3227" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M90.876 90.2477L94.2769 93.4892" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M88.5098 93.0091L91.9148 96.2505" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M83.1914 98.0108L86.5964 101.252" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M81.0098 100.583L84.4148 103.825" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M78.4102 103.882L81.8152 107.124" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M76.2578 106.808L79.6628 110.049" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M74.1016 109.889L77.5066 113.131" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M72.0605 113.077L75.4656 116.319" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M70.2119 115.88L73.6169 119.121" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M66.5811 122.227L69.9861 125.465" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M64.9014 125.522L68.3023 128.764" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M62.8594 128.48L66.2644 131.722" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M61.7012 132.276L65.1062 135.517" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M60.1035 135.653L63.5085 138.894" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M59.2578 139.062L62.6587 142.304" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M58.0908 142.743L61.4958 145.984" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M56.834 146.329L60.239 149.57" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M56.4229 150.165L59.8279 153.407" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M55.4453 153.456L58.8503 156.697" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M55.0391 157.292L58.44 160.534" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M53.9707 161.141L57.3757 164.383" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M53.4492 164.818L56.8542 168.059" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M53.0674 168.621L56.4724 171.862" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.8086 172.548L56.2136 175.789" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.4268 176.355L55.8318 179.597" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.8379 180.274L56.2388 183.515" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.7764 183.745L56.1773 186.982" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.7227 188.025L56.1236 191.266" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M52.7393 191.41L56.1443 194.651" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M68.5068 119.158L71.9078 122.4" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
            <path opacity="0.5" d="M85.8975 95.1755L89.3025 98.417" stroke="#0068FF" strokeWidth="3" strokeMiterlimit="10" strokeDasharray="20 20"/>
        </g>
        <path d="M265.06 229.68H225.228L221.784 216.133V187.804H265.06V229.68Z" stroke="#8BB8FF" strokeMiterlimit="10"/>
        <path d="M218.535 229.68H178.707L175.264 216.133V187.804H218.535V229.68Z" stroke="#8BB8FF" strokeMiterlimit="10"/>
        <path d="M171.305 229.68H131.473L128.029 216.133V187.804H171.305V229.68Z" stroke="#8BB8FF" strokeMiterlimit="10"/>
        <path d="M124.78 229.68H84.9522L81.5088 216.133V187.804H124.78V229.68Z" stroke="#8BB8FF" strokeMiterlimit="10"/>
    </g>
    <path d="M152.692 109.106C153.748 110.114 155.164 110.618 156.94 110.618C158.644 110.618 160.012 110.162 161.02 109.25C162.028 108.338 162.532 107.114 162.532 105.554V105.458H160.828V105.554C160.828 106.634 160.468 107.474 159.796 108.098C159.1 108.722 158.164 109.01 156.964 109.01C154.228 109.01 152.86 107.474 152.86 104.378V100.058C152.86 98.3545 153.196 97.1305 153.892 96.3625C154.588 95.6185 155.596 95.2345 156.94 95.2345C158.14 95.2345 159.076 95.5465 159.772 96.1705C160.468 96.7945 160.828 97.6585 160.828 98.7145V98.7865H162.58V98.7145C162.58 97.7065 162.34 96.8185 161.884 96.0505C161.404 95.2825 160.756 94.7065 159.892 94.2745C159.028 93.8665 158.044 93.6505 156.94 93.6505C155.164 93.6505 153.748 94.1785 152.692 95.1865C151.636 96.1945 151.108 97.8025 151.108 99.9865V104.282C151.108 106.49 151.636 108.098 152.692 109.106ZM167.221 104.666H174.829V103.226C174.829 101.498 174.397 100.202 173.557 99.3625C172.717 98.5225 171.613 98.0905 170.221 98.0905C168.829 98.0905 167.701 98.5225 166.861 99.3625C165.997 100.202 165.589 101.498 165.589 103.226V105.338C165.589 107.138 165.997 108.458 166.861 109.298C167.701 110.162 168.829 110.57 170.269 110.57C171.661 110.57 172.741 110.234 173.533 109.538C174.325 108.866 174.733 107.954 174.733 106.802V106.682H173.149V106.754C173.149 107.498 172.885 108.074 172.405 108.482C171.925 108.914 171.253 109.106 170.389 109.106C169.381 109.106 168.613 108.842 168.061 108.29C167.485 107.738 167.221 106.778 167.221 105.386V104.666ZM168.037 100.394C168.565 99.8665 169.309 99.5785 170.221 99.5785C171.133 99.5785 171.877 99.8425 172.405 100.346C172.933 100.874 173.197 101.762 173.197 103.01V103.442H167.221V103.178C167.221 101.858 167.485 100.922 168.037 100.394ZM179.915 95.4505H188.219V93.8665H178.163V110.426H179.915V102.842H187.019V101.258H179.915V95.4505ZM189.86 98.2585V110.426H191.492V98.2585H189.86ZM189.812 94.1065V96.2665H191.54V94.1065H189.812Z" fill="white"/>
    <defs>
        <linearGradient id="paint0_linear_3565_92674" x1="180.348" y1="4.31217" x2="325.973" y2="48.0195" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3565_92674" x1="24.2056" y1="94.6696" x2="130.195" y2="-14.245" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3565_92674" x1="148.205" y1="14.946" x2="299.391" y2="31.1734" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint3_linear_3565_92674" x1="9.91114" y1="132.408" x2="93.4181" y2="6.25727" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint4_linear_3565_92674" x1="45.3559" y1="303.907" x2="-27.3034" y2="160.848" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint5_linear_3565_92674" x1="316.373" y1="77.0982" x2="377.632" y2="221.759" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint6_linear_3565_92674" x1="355.922" y1="189.377" x2="312.336" y2="334.9" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint7_linear_3565_92674" x1="274.667" y1="331.527" x2="131.699" y2="383.25" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint8_linear_3565_92674" x1="265.835" y1="254.927" x2="176.114" y2="281.9" gradientUnits="userSpaceOnUse">
            <stop stopColor="#08CAFF"/>
            <stop offset="0.4" stopColor="#0579E5" stopOpacity="0.2"/>
            <stop offset="1" stopColor="#0001BF" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint9_linear_3565_92674" x1="62.798" y1="141.505" x2="110.842" y2="58.7571" gradientUnits="userSpaceOnUse">
            <stop stopColor="#08CAFF"/>
            <stop offset="0.4" stopColor="#0579E5" stopOpacity="0.2"/>
            <stop offset="1" stopColor="#0001BF" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint10_linear_3565_92674" x1="64.6445" y1="149.452" x2="90.0253" y2="100.732" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint11_linear_3565_92674" x1="147.704" y1="289.023" x2="69.413" y2="250.34" gradientUnits="userSpaceOnUse">
            <stop stopColor="#08CAFF"/>
            <stop offset="0.4" stopColor="#0579E5" stopOpacity="0.2"/>
            <stop offset="1" stopColor="#0001BF" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint12_linear_3565_92674" x1="262.907" y1="107.122" x2="305.05" y2="191.607" gradientUnits="userSpaceOnUse">
            <stop stopColor="#08CAFF"/>
            <stop offset="0.4" stopColor="#0579E5" stopOpacity="0.2"/>
            <stop offset="1" stopColor="#0001BF" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint13_linear_3565_92674" x1="261.795" y1="110.023" x2="288.303" y2="157.047" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint14_linear_3565_92674" x1="199.604" y1="73.1941" x2="289.82" y2="103.164" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.91" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint15_linear_3565_92674" x1="88.0876" y1="339.13" x2="-19.7478" y2="232.046" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.47" stopColor="#0F9AFF"/>
            <stop offset="0.713542" stopColor="#05CAFE"/>
            <stop offset="0.989583" stopColor="#80BC5E"/>
        </linearGradient>
        <linearGradient id="paint16_linear_3565_92674" x1="174" y1="54.1764" x2="174" y2="308.426" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0ABDFF"/>
            <stop offset="0.447917" stopColor="#3560FF"/>
            <stop offset="0.953125" stopColor="#8318FF"/>
        </linearGradient>
        <linearGradient id="paint17_linear_3565_92674" x1="207.777" y1="52.7438" x2="292.848" y2="108.218" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#811AFF"/>
            <stop offset="0.432292" stopColor="#1D5DFE"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint18_linear_3565_92674" x1="294.337" y1="117.807" x2="338.568" y2="180.217" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#09BFFF"/>
            <stop offset="0.598958" stopColor="#2673FF"/>
            <stop offset="1" stopColor="#8318FF"/>
        </linearGradient>
        <linearGradient id="paint19_linear_3565_92674" x1="83.7997" y1="280.073" x2="21.0687" y2="184.11" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#09BFFF"/>
            <stop offset="0.4" stopColor="#2771FF"/>
            <stop offset="1" stopColor="#8318FF"/>
        </linearGradient>
        <linearGradient id="paint20_linear_3565_92674" x1="290.095" y1="254.714" x2="211.206" y2="337.933" gradientUnits="userSpaceOnUse">
            <stop offset="0.0260417" stopColor="#8318FF"/>
            <stop offset="0.390625" stopColor="#1F55FE"/>
            <stop offset="0.8125" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint21_linear_3565_92674" x1="63.5877" y1="110.535" x2="106.14" y2="43.7019" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#09BFFF"/>
            <stop offset="0.557292" stopColor="#1D5DFE"/>
            <stop offset="0.932292" stopColor="#8318FF"/>
        </linearGradient>
        <linearGradient id="paint22_linear_3565_92674" x1="205.835" y1="39.7765" x2="201.07" y2="44.8001" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint23_linear_3565_92674" x1="196.944" y1="37.8706" x2="192.477" y2="43.1646" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint24_linear_3565_92674" x1="187.047" y1="36.4736" x2="182.722" y2="41.8849" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint25_linear_3565_92674" x1="167.542" y1="323.227" x2="163.487" y2="328.841" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint26_linear_3565_92674" x1="158.485" y1="322.511" x2="154.758" y2="328.349" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint27_linear_3565_92674" x1="151.021" y1="321.341" x2="147.45" y2="327.275" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint28_linear_3565_92674" x1="69.6117" y1="297.524" x2="75.905" y2="294.723" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint29_linear_3565_92674" x1="88.1274" y1="292.073" x2="94.3139" y2="288.898" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint30_linear_3565_92674" x1="89.7311" y1="301.303" x2="95.806" y2="297.97" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint31_linear_3565_92674" x1="121.142" y1="61.9102" x2="122.371" y2="55.1224" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint32_linear_3565_92674" x1="125.772" y1="53.3502" x2="126.586" y2="46.4741" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <linearGradient id="paint33_linear_3565_92674" x1="134.664" y1="49.0543" x2="135.294" y2="42.1591" gradientUnits="userSpaceOnUse">
            <stop offset="0.01" stopColor="#1F55FE"/>
            <stop offset="0.22" stopColor="#1D5DFE"/>
            <stop offset="0.4" stopColor="#0F9AFF"/>
            <stop offset="0.68" stopColor="#05CAFE"/>
        </linearGradient>
        <clipPath id="clip0_3565_92674">
            <rect width="360" height="365.25" fill="white" transform="translate(0 0.176392)"/>
        </clipPath>
    </defs>
</>;