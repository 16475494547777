import React from 'react';
import { ToastContainer } from 'react-toastify';
import styles from './styles.scss';

/*
 notify class 4 classes
 1. notify-class-danger
 2. notify-class-warning
 3. notify-class-success
 4. notify-class-info
 */



export const Notify = () => {
    return <ToastContainer
        position="bottom-right"
        hideProgressBar={false}
        className="moneyr-notify"
        toastClassName="moneyr-notify-toast"
        bodyClassName="moneyr-notify-body font-size-14 font-weight-medium"
        draggable={false}
        autoClose={3000}
        pauseOnHover
        pauseOnFocusLoss={false}
        closeOnClick
        newestOnTop
    />;
};