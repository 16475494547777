import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { serviceUtils } from '../../../service/serviceUtils';
import { constants_swap } from "../constants";
import { constants } from "../../../constants/constants";
import { ethers } from 'ethers';

const { INIT_DATA } = constants_swap;
const { NULL_ADDRESS_0X00, NULL_ADDRESS_0XEE, COINGECKO, P1INCH, ANKR } = constants;

const actionTypes = {
    TAB_SELECT_SWAP: 'TAB#SELECT_SWAP',
    TAB_SELECT_SETTINGS: 'TAB#SELECT_SETTINGS',

    SWAP_INITIALIZE: 'SWAP#INITIALIZE',
    SWAP_UPDATE_PRICE_SELL_SUCCEEDED: 'SWAP#UPDATE_PRICE_SELL_SUCCEEDED',
    SWAP_UPDATE_PRICE_SELL_FAILED: 'SWAP#UPDATE_PRICE_SELL_FAILED',
    SWAP_UPDATE_PRICE_BUY_SUCCEEDED: 'SWAP#UPDATE_PRICE_BUY_SUCCEEDED',
    SWAP_UPDATE_PRICE_BUY_FAILED: 'SWAP#UPDATE_PRICE_BUY_FAILED',
    SWAP_TOKENS_LOAD_SUCCEEDED: 'SWAP#TOKENS_LOAD_SUCCEEDED',
    SWAP_TOKENS_LOAD_FAILED: 'SWAP#TOKENS_LOAD_FAILED',
    SWAP_APPROVE_SPENDER_SUCCEEDED: 'SWAP#APPROVE_SPENDER_SUCCEEDED',
    SWAP_APPROVE_SPENDER_FAILED: 'SWAP#APPROVE_SPENDER_FAILED',
    SWAP_APPROVE_ALLOWANCE_SUCCEEDED: 'SWAP#APPROVE_ALLOWANCE_SUCCEEDED',
    SWAP_APPROVE_ALLOWANCE_FAILED: 'SWAP#APPROVE_ALLOWANCE_FAILED',
    SWAP_APPROVE_TRANSACTION_SUCCEEDED: 'SWAP#APPROVE_TRANSACTION_SUCCEEDED',
    SWAP_APPROVE_TRANSACTION_FAILED: 'SWAP#APPROVE_TRANSACTION_FAILED',
    SWAP_SEND_TRANSACTION: 'SWAP#SEND_TRANSACTION',
    SWAP_SEND_TRANSACTION_SUCCEEDED: 'SWAP#SEND_TRANSACTION_SUCCEEDED',
    SWAP_SEND_TRANSACTION_FAILED: 'SWAP#SEND_TRANSACTION_FAILED',
    SWAP_BALANCE_LOAD_SUCCEEDED: 'SWAP#BALANCE_LOAD_SUCCEEDED',
    SWAP_BALANCE_LOAD_FAILED: 'SWAP#BALANCE_LOAD_FAILED',
    SWAP_FETCH_QUOTE: 'SWAP#FETCH_QUOTE',
    SWAP_QUOTE_LOAD_SUCCEEDED: 'SWAP#QUOTE_LOAD_SUCCEEDED',
    SWAP_QUOTE_LOAD_FAILED: 'SWAP#QUOTE_LOAD_FAILED',
    SWAP_CHAIN_CHANGED: 'SWAP#CHAIN_CHANGED',
    SWAP_SELL_VALUE_CHANGED: 'SWAP#SELL_VALUE_CHANGED',
    SWAP_SELL_MAX: 'SWAP#SELL_MAX',
    SWAP_STATUS: 'SWAP#STATUS',
    SWAP_SELECT_SELL_TOKEN: 'SWAP#SELECT_SELL_TOKEN',
    SWAP_SELECT_BUY_TOKEN: 'SWAP#SELECT_BUY_TOKEN',
    SWAP_PREPARE: 'SWAP#PREPARE',
    SWAP_DO: 'SWAP#DO',
    SWAP_DO_SUCCEEDED: 'SWAP#DO_SUCCEEDED',
    SWAP_DO_FAILED: 'SWAP#DO_FAILED',

    SWAP_SEND: 'SWAP#SEND',
    SWAP_SEND_SUCCEEDED: 'SWAP#SEND_SUCCEEDED',
    SWAP_SEND_FAILED: 'SWAP#SEND_FAILED',

    TOKENS_GO_BACK: 'TOKENS#GO_BACK',
    TOKENS_SEARCH_SET: 'TOKENS#SEARCH_SET',
    TOKENS_SEARCH_DO: 'TOKENS#SEARCH_DO',
    TOKENS_ADD_TOKEN_TO_METAMASK: 'TOKENS#ADD_TOKEN_TO_METAMASK',
    TOKENS_ADDRESS_FOR_IMPORT: 'TOKENS#ADDRESS_FOR_IMPORT',
    TOKENS_IMPORT_DO: 'TOKENS#IMPORT_DO',
    TOKENS_SELECT_TOKEN: 'TOKENS#SELECT_TOKEN',

    SETTINGS_GO_BACK: 'SETTINGS#GO_BACK',
    SETTINGS_TOGGLE_ALLOW_PARTIAL_FILL: 'SETTINGS#TOGGLE_ALLOW_PARTIAL_FILL',
    SETTINGS_TOGGLE_ROUTING_PRESET: 'SETTINGS#TOGGLE_ROUTING_PRESET',
    SETTINGS_TOGGLE_COMPATIBILITY_MODE: 'SETTINGS#TOGGLE_COMPATIBILITY_MODE',
    SETTINGS_SELECT_GAS: 'SETTINGS#SELECT_GAS',
    SETTINGS_SET_GAS_CUSTOM: 'SETTINGS#SET_GAS_CUSTOM',
    SETTINGS_SELECT_SLIPPAGE: 'SETTINGS#SELECT_SLIPPAGE',
    SETTINGS_SET_SLIPPAGE_CUSTOM: 'SETTINGS#SET_SLIPPAGE_CUSTOM',
};

const actionHandlers = {
    [actionTypes.TAB_SELECT_SWAP]: action((state, payload) => tabSelectSwap(state, payload)),
    [actionTypes.TAB_SELECT_SETTINGS]: action((state, payload) => tabSelectSettings(state, payload)),

    [actionTypes.SWAP_INITIALIZE]: thunk(async (actions, payload) => swapInitialize(actions, payload)),
    [actionTypes.SWAP_CHAIN_CHANGED]: thunk(async (actions, payload) => swapChainChanged(actions, payload)),
    [actionTypes.SWAP_UPDATE_PRICE_SELL_SUCCEEDED]: action((state, payload) => swapUpdatePriceSellSucceeded(state, payload)),
    [actionTypes.SWAP_UPDATE_PRICE_SELL_FAILED]: action((state, payload) => swapUpdatePriceSellFailed(state, payload)),
    [actionTypes.SWAP_UPDATE_PRICE_BUY_SUCCEEDED]: action((state, payload) => swapUpdatePriceBuySucceeded(state, payload)),
    [actionTypes.SWAP_UPDATE_PRICE_BUY_FAILED]: action((state, payload) => swapUpdatePriceBuyFailed(state, payload)),
    [actionTypes.SWAP_TOKENS_LOAD_SUCCEEDED]: action((state, payload) => swapTokensLoadSucceeded(state, payload)),
    [actionTypes.SWAP_TOKENS_LOAD_FAILED]: action((state, payload) => swapTokensLoadFailed(state, payload)),
    [actionTypes.SWAP_APPROVE_SPENDER_SUCCEEDED]: action((state, payload) => swapApproveSpenderSucceeded(state, payload)),
    [actionTypes.SWAP_APPROVE_SPENDER_FAILED]: action((state, payload) => swapApproveSpenderFailed(state, payload)),
    [actionTypes.SWAP_APPROVE_ALLOWANCE_SUCCEEDED]: action((state, payload) => swapApproveAllowanceSucceeded(state, payload)),
    [actionTypes.SWAP_APPROVE_ALLOWANCE_FAILED]: action((state, payload) => swapApproveAllowanceFailed(state, payload)),
    [actionTypes.SWAP_APPROVE_TRANSACTION_SUCCEEDED]: action((state, payload) => swapApproveTransactionSucceeded(state, payload)),
    [actionTypes.SWAP_APPROVE_TRANSACTION_FAILED]: action((state, payload) => swapApproveTransactionFailed(state, payload)),
    [actionTypes.SWAP_SEND_TRANSACTION]: thunk(async (actions, payload) => swapSendTransaction(actions, payload)),
    [actionTypes.SWAP_SEND_TRANSACTION_SUCCEEDED]: thunk(async (actions, payload) => swapSendTransactionSucceeded(actions, payload)),
    [actionTypes.SWAP_SEND_TRANSACTION_FAILED]: action((state, payload) => swapSendTransactionFailed(state, payload)),
    [actionTypes.SWAP_BALANCE_LOAD_SUCCEEDED]: action((state, payload) => swapBalanceLoadSucceeded(state, payload)),
    [actionTypes.SWAP_BALANCE_LOAD_FAILED]: action((state, payload) => swapBalanceLoadFailed(state, payload)),
    [actionTypes.SWAP_SELL_VALUE_CHANGED]: thunk((actions, payload) => swapSellValueChanged(actions, payload)),
    [actionTypes.SWAP_FETCH_QUOTE]: thunk(async (actions, payload) => swapFetchQuote(actions, payload)),
    [actionTypes.SWAP_QUOTE_LOAD_SUCCEEDED]: action((state, payload) => swapQuoteLoadSucceeded(state, payload)),
    [actionTypes.SWAP_QUOTE_LOAD_FAILED]: action((state, payload) => swapQuoteLoadFailed(state, payload)),
    [actionTypes.SWAP_SELL_MAX]: thunk((actions, payload) => swapSellMax(actions, payload)),
    [actionTypes.SWAP_STATUS]: action((state, payload) => swapCheckStatus(state, payload)),
    [actionTypes.SWAP_SELECT_SELL_TOKEN]: action((state, payload) => swapSelectSellToken(state, payload)),
    [actionTypes.SWAP_SELECT_BUY_TOKEN]: action((state, payload) => swapSelectBuyToken(state, payload)),
    [actionTypes.SWAP_PREPARE]: thunk(async (actions, payload) => swapPrepare(actions, payload)),
    [actionTypes.SWAP_DO]: thunk(async (actions, payload) => swapDo(actions, payload)),
    [actionTypes.SWAP_DO_SUCCEEDED]: action((state, payload) => swapDoSucceeded(state, payload)),
    [actionTypes.SWAP_DO_FAILED]: action((state, payload) => swapDoFailed(state, payload)),

    [actionTypes.SWAP_SEND]: thunk(async (actions, payload) => swapSend(actions, payload)),
    [actionTypes.SWAP_SEND_SUCCEEDED]: thunk(async (actions, payload) => swapSendSucceeded(actions, payload)),
    [actionTypes.SWAP_SEND_FAILED]: action((state, payload) => swapSendFailed(state, payload)),

    [actionTypes.TOKENS_GO_BACK]: action((state, payload) => tokensGoBack(state, payload)),
    [actionTypes.TOKENS_SEARCH_SET]: action((state, payload) => tokensSearchSet(state, payload)),
    [actionTypes.TOKENS_SEARCH_DO]: action((state, payload) => tokensSearchDo(state, payload)),
    [actionTypes.TOKENS_ADD_TOKEN_TO_METAMASK]: action((state, payload) => tokensAddTokenToMetamask(state, payload)),
    [actionTypes.TOKENS_ADDRESS_FOR_IMPORT]: thunk(async (actions, payload) => tokensCheckForImport(actions, payload)),
    [actionTypes.TOKENS_IMPORT_DO]: action((state, payload) => tokensImportDo(state, payload)),
    [actionTypes.TOKENS_SELECT_TOKEN]: action((state, payload) => tokensSelectToken(state, payload)),

    [actionTypes.SETTINGS_GO_BACK]: action((state, payload) => settingsGoBack(state, payload)),
    [actionTypes.SETTINGS_TOGGLE_ALLOW_PARTIAL_FILL]: action((state, payload) => settingsToggleAllowPartialFill(state, payload)),
    [actionTypes.SETTINGS_TOGGLE_ROUTING_PRESET]: action((state, payload) => settingsToggleRoutingPreset(state, payload)),
    [actionTypes.SETTINGS_TOGGLE_COMPATIBILITY_MODE]: action((state, payload) => settingsToggleCompatibilityMode(state, payload)),
    [actionTypes.SETTINGS_SELECT_GAS]: action((state, payload) => settingsSelectGas(state, payload)),
    [actionTypes.SETTINGS_SET_GAS_CUSTOM]: action((state, payload) => settingsSetGasCustom(state, payload)),
    [actionTypes.SETTINGS_SELECT_SLIPPAGE]: action((state, payload) => settingsSelectSlippage(state, payload)),
    [actionTypes.SETTINGS_SET_SLIPPAGE_CUSTOM]: action((state, payload) => settingsSetSlippageCustom(state, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

// ****************************************
// common

// convert address x00.. to xEE..
const swapConvertx00toxEE = (address) => {
    return address === NULL_ADDRESS_0X00 ? NULL_ADDRESS_0XEE : address;
};

// convert address xEE.. to x00..
const swapConvertxEEtox00 = (address) => {
    return address === NULL_ADDRESS_0XEE ? NULL_ADDRESS_0X00 : address;
};

// ****************************************
// TAB switch

const tabSelectSwap = (state, payload) => {
    console.log('@tabSelectSwap');

    state.formActive = 'swap';
}

const tabSelectSettings = (state, payload) => {
    console.log('@tabSelectSettings');

    state.formActive = 'settings';
}

// ****************************************
// FORM swap

// chain changed
const swapChainChanged = async (actions, payload) => {
    console.log('@chainChanged');

    const { value } = payload;
    const { updateState } = actions;

    updateState([
        {
            path: 'swap.statusLabel',
            value: 'Switching chain..'
        },
    ])

    let res = await serviceUtils.metamaskSwitchEthereumChain(value.value);
    // change chain
    if (res.result) {
        updateState([
            {
                path: 'chain.value',
                value: value
            },
            {
                path: 'initializeStep',
                value: 1
            },
        ])
    }
};

const swapInitialize = async (actions, payload) => {
    const { updateState } = actions;
    const { userId, state: { swap: { buy, sell }, chain, initializeStep } } = payload;

    // testing
    console.log('@swapInitialize', initializeStep);
    console.log(payload);

    switch (initializeStep) {
        // init chains
        case 1:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Initialize chains...'
                },
            ]);
            // filter chains
            let arrChains = [];
            serviceUtils.getFilteredChains('mainnet').forEach(ch => {
                arrChains.push({ value: ch.chain, label: ch.chainName, chain: ch })
            });
            // init chain
            if (arrChains.length > 0) {
                // 0 - ETH, 1 - BSC, ...
                let initChain = arrChains.find(ch => ch.value === chain.value.value || ch.value === 'BSC');
                // init sell token
                updateState([
                    // sell
                    {
                        path: 'swap.sell.token.address',
                        value: NULL_ADDRESS_0X00
                    },
                    {
                        path: 'swap.sell.token.coingeckoId',
                        value: initChain.chain.extCoingecko.tokenId
                    },
                ]);
                // init buy token
                let initBuyToken = INIT_DATA.find(tk => tk.chain === initChain.value);
                if (initBuyToken) {
                    updateState([
                        // sell
                        {
                            path: 'swap.buy.token.address',
                            value: initBuyToken.buy_token.address
                        },
                        {
                            path: 'swap.buy.token.coingeckoId',
                            value: initBuyToken.buy_token.coingecko_id
                        },
                    ]);
                } else {
                    console.error('error: init buy token', initBuyToken);
                };
                // update chain
                updateState([
                    {
                        path: 'chain.value',
                        value: initChain
                    },
                    {
                        path: 'chain.list',
                        value: arrChains
                    },
                ]);
                // switch / add chain
                let res = await serviceUtils.metamaskSwitchEthereumChain(initChain.value);
                if (res.result) {
                    updateState([
                        {
                            path: 'initializeStep',
                            value: 2
                        },
                    ]);
                } else {
                    console.error('error: switch chain', res);
                };
            } else {
                console.error('error: chains length');
            };
            break;

        // load tokens
        case 2:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Load tokens...'
                },
                {
                    path: 'tokens.initialized',
                    value: false
                },
            ]);
            // load tokens list from 1inch API
            await serviceUtils.HttpService({
                url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'tokens'),
                method: 'GET',
                successActionType: actions[actionTypes.SWAP_TOKENS_LOAD_SUCCEEDED],
                errorActionType: actions[actionTypes.SWAP_TOKENS_LOAD_FAILED],
            });
            break;

        // load balances
        case 3:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Load account balances...'
                },
            ]);
            //load balances from ANKR
            let requestGetAccountBalance = {
                "id": 1,
                "jsonrpc": "2.0",
                "method": "ankr_getAccountBalance",
                "params": {
                    "blockchain": [
                        chain.value.chain.extAnkr.chain
                    ],
                    "onlyWhitelisted": true,
                    "pageSize": 1000,
                    "walletAddress": userId
                }
            }
            await serviceUtils.HttpService({
                url: ANKR.API_URL,
                method: 'POST',
                data: requestGetAccountBalance,
                successActionType: actions[actionTypes.SWAP_BALANCE_LOAD_SUCCEEDED],
                errorActionType: actions[actionTypes.SWAP_BALANCE_LOAD_FAILED],
            });
            break;

        // init sell balances
        case 4:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Load sell section balances...'
                },
            ]);
            // init sell balances - native token
            const sellBalance = await serviceUtils.metamaskGetTokenBalance(sell.token.address, userId)
            if (sellBalance.result) {
                // token SELL
                updateState([
                    {
                        path: 'swap.sell.balance',
                        value: sellBalance.decimals ? sellBalance.balance / Math.pow(10, sellBalance.decimals) : sellBalance.balance
                    },
                    {
                        path: 'swap.sell.value',
                        value: 0
                    },
                ]);
                if (sell.token.address === NULL_ADDRESS_0X00) {
                    updateState([
                        {
                            path: 'swap.sell.token.symbol',
                            value: chain.value.chain.nativeTokenSymbol
                        },
                        {
                            path: 'swap.sell.token.name',
                            value: chain.value.chain.nativeTokenName
                        },
                        {
                            path: 'swap.sell.token.decimals',
                            value: chain.value.chain.nativeTokenDecimals
                        },
                        {
                            path: 'swap.sell.token.coingeckoId',
                            value: chain.value.chain.extCoingecko.tokenId
                        },
                    ])
                } else {
                    updateState([
                        {
                            path: 'swap.sell.token.symbol',
                            value: sellBalance.symbol
                        },
                        {
                            path: 'swap.sell.token.name',
                            value: sellBalance.name
                        },
                        {
                            path: 'swap.sell.token.decimals',
                            value: sellBalance.decimals
                        }])
                };
                if (sell.token.address === NULL_ADDRESS_0X00) {
                    // native token, load and update sell price in USD
                    await serviceUtils.HttpService({
                        url: COINGECKO.API_SIMPLE_PRICE.replace('%IDS%', chain.value.chain.extCoingecko.tokenId).replace('%VS_CURRENCIES%', 'usd').replace('%API_KEY%', COINGECKO.API_KEY),
                        method: 'GET',
                        successActionType: actions[actionTypes.SWAP_UPDATE_PRICE_SELL_SUCCEEDED],
                        errorActionType: actions[actionTypes.SWAP_UPDATE_PRICE_SELL_FAILED],
                    })
                } else {
                    // usual token, load and update sell price in USD
                    await serviceUtils.HttpService({
                        url: COINGECKO.API_SIMPLE_TOKEN_PRICE.replace('%PLATFORM%', chain.value.chain.extCoingecko.platform).replace('%CONTRACT_ADDRESSES%', sell.token.address).replace('%VS_CURRENCIES%', 'usd').replace('%API_KEY%', COINGECKO.API_KEY),
                        method: 'GET',
                        successActionType: actions[actionTypes.SWAP_UPDATE_PRICE_SELL_SUCCEEDED],
                        errorActionType: actions[actionTypes.SWAP_UPDATE_PRICE_SELL_FAILED],
                    })
                }
            } else {
                console.error('error: sell balance', sellBalance);
            };
            break;

        // init buy balances
        case 5:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Load buy section balances...'
                },
            ]);
            const buyBalance = await serviceUtils.metamaskGetTokenBalance(buy.token.address, userId)
            if (buyBalance.result) {
                updateState([
                    {
                        path: 'swap.buy.balance',
                        value: buyBalance.decimals ? buyBalance.balance / Math.pow(10, buyBalance.decimals) : buyBalance.balance
                    },
                    {
                        path: 'swap.buy.value',
                        value: 0
                    },
                ]);
                if (buy.token.address === NULL_ADDRESS_0X00) {
                    updateState([
                        {
                            path: 'swap.buy.token.symbol',
                            value: chain.value.chain.nativeTokenSymbol
                        },
                        {
                            path: 'swap.buy.token.name',
                            value: chain.value.chain.nativeTokenName
                        },
                        {
                            path: 'swap.buy.token.decimals',
                            value: chain.value.chain.nativeTokenDecimals
                        },
                        {
                            path: 'swap.buy.token.coingeckoId',
                            value: chain.value.chain.extCoingecko.tokenId
                        },
                    ])
                } else {
                    updateState([
                        {
                            path: 'swap.buy.token.symbol',
                            value: buyBalance.symbol
                        },
                        {
                            path: 'swap.buy.token.name',
                            value: buyBalance.name
                        },
                        {
                            path: 'swap.buy.token.decimals',
                            value: buyBalance.decimals
                        },
                    ])
                }
                if (buy.token.address === NULL_ADDRESS_0X00) {
                    // native token, load and update buy price in USD
                    await serviceUtils.HttpService({
                        url: COINGECKO.API_SIMPLE_PRICE.replace('%IDS%', chain.value.chain.extCoingecko.tokenId).replace('%VS_CURRENCIES%', 'usd').replace('%API_KEY%', COINGECKO.API_KEY),
                        method: 'GET',
                        successActionType: actions[actionTypes.SWAP_UPDATE_PRICE_BUY_SUCCEEDED],
                        errorActionType: actions[actionTypes.SWAP_UPDATE_PRICE_BUY_FAILED],
                    })
                } else {
                    // usual token, load and update buy price in USD
                    await serviceUtils.HttpService({
                        url: COINGECKO.API_SIMPLE_TOKEN_PRICE.replace('%PLATFORM%', chain.value.chain.extCoingecko.platform).replace('%CONTRACT_ADDRESSES%', buy.token.address).replace('%VS_CURRENCIES%', 'usd').replace('%API_KEY%', COINGECKO.API_KEY),
                        method: 'GET',
                        successActionType: actions[actionTypes.SWAP_UPDATE_PRICE_BUY_SUCCEEDED],
                        errorActionType: actions[actionTypes.SWAP_UPDATE_PRICE_BUY_FAILED],
                    })
                }
            } else {
                console.error('error: buy balance', buyBalance);
            };
            break;

        // router address
        case 6:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Init spender router address...'
                },
            ]);
            // load spender router address from 1inch API
            await serviceUtils.HttpService({
                url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'approve/spender'),
                method: 'GET',
                successActionType: actions[actionTypes.SWAP_APPROVE_SPENDER_SUCCEEDED],
                errorActionType: actions[actionTypes.SWAP_APPROVE_SPENDER_FAILED],
            });
            break;

        // check allowance
        case 7:
            updateState([
                {
                    path: 'swap.statusLabel',
                    value: 'Check allowance for sell...'
                },
            ]);
            // load allowance
            await serviceUtils.HttpService({
                url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'approve/allowance') + `?tokenAddress=${swapConvertx00toxEE(sell.token.address)}&walletAddress=${userId}`,
                method: 'GET',
                successActionType: actions[actionTypes.SWAP_APPROVE_ALLOWANCE_SUCCEEDED],
                errorActionType: actions[actionTypes.SWAP_APPROVE_ALLOWANCE_FAILED],
            });
            break;

        // initialized
        case 8:
            updateState([
                {
                    path: 'swap.status',
                    value: 'check'
                },
                {
                    path: 'initialized',
                    value: true
                },
            ]);
            break;
    }
};

// coingecko sell price load succeeded
const swapUpdatePriceSellSucceeded = (state, payload) => {
    console.log('@swapUpdatePriceSellSucceeded');

    const { response } = payload;
    const { swap: { sell: { token: { address, coingeckoId } } } } = state;
    console.log(address);
    console.log(coingeckoId);
    console.log(response);
    if (response[address] && response[address].usd) {
        // usual by address
        state.swap.sell.rateUSD = response[address].usd;
        state.initializeStep = 5;
    } else if (response[coingeckoId] && response[coingeckoId].usd) {
        // native by id
        state.swap.sell.rateUSD = response[coingeckoId].usd;
        state.initializeStep = 5;
    } else {
        state.swap.sell.rateUSD = 0;
        state.initializeStep = 5;
        console.error('error: sell rateUSD');
    };
};

// coingecko sell price load failed
const swapUpdatePriceSellFailed = (state, payload) => {
    console.log('@swapUpdatePriceSellFailed');

    state.swap.sell.rateUSD = 0;
    state.initializeStep = 5;
    console.error(payload);
};

// coingecko buy price load succeeded
const swapUpdatePriceBuySucceeded = (state, payload) => {
    console.log('@swapUpdatePriceBuySucceeded');

    const { response } = payload;
    let { swap: { buy: { token: { address, coingeckoId } } } } = state;
    console.log(address);
    console.log(coingeckoId);
    console.log(response);
    if (response[address] && response[address].usd) {
        // usual by address
        state.swap.buy.rateUSD = response[address].usd;
        state.initializeStep = 6;
    } else if (response[coingeckoId] && response[coingeckoId].usd) {
        // native by id
        state.swap.buy.rateUSD = response[coingeckoId].usd;
        state.initializeStep = 6;
    } else {
        state.swap.buy.rateUSD = 0;
        state.initializeStep = 6;
        console.error('error: buy rateUSD');
    };
};

// coingecko buy price load failed
const swapUpdatePriceBuyFailed = (state, payload) => {
    console.log('@swapUpdatePriceBuyFailed');

    state.swap.buy.rateUSD = 0;
    state.initializeStep = 6;
    console.error(payload);
};

// 1inch tokens load succeeded
const swapTokensLoadSucceeded = (state, payload) => {
    const { response } = payload;

    console.log('@tokensLoaded');

    if (response && response.tokens) {
        let arrTokens = [];
        Object.keys(response.tokens).map((key, i) => {
            arrTokens.push(response.tokens[key]);
        });
        state.tokens.list = arrTokens;
        state.initializeStep = 3;
    } else {
        console.error('error: tokens load', payload);
    };
};

// 1inch tokens load failed
const swapTokensLoadFailed = (state, payload) => {
    console.log('@swapTokensLoadedFailed');

    console.error(payload);
};

// 1inch spender router address succeeded
const swapApproveSpenderSucceeded = (state, payload) => {
    const { response } = payload;

    console.log('@swapApproveSpenderSucceeded');

    if (response && response.address) {
        state.routerAddress = response.address;
        state.initializeStep = 7;
    } else {
        console.error('error: router address', payload);
    };
};

// 1inch spender router address failed
const swapApproveSpenderFailed = (state, payload) => {
    console.log('@swapApproveSpenderFailed');

    console.error(payload);
};

// 1inch allowance succeeded
const swapApproveAllowanceSucceeded = (state, payload) => {
    const { response } = payload;

    console.log('@swapApproveAllowanceSucceeded');

    if (response && response.allowance) {
        state.approve.allowance = response.allowance;
        state.initializeStep = 8;
    } else {
        console.error('error: approve allowance', payload);
    };
};

// 1inch allowance failed
const swapApproveAllowanceFailed = (state, payload) => {
    console.log('@swapApproveAllowanceFailed');

    console.error(payload);
};

// 1inch approve transaction succeeded
const swapApproveTransactionSucceeded = (state, payload) => {
    console.log('@swapApproveTransactionSucceeded');

    const { response } = payload;

    if (response && response.data) {
        state.approve.transaction = response;
    } else {
        console.error('error: approve transaction', payload);
    };
};

// 1inch approve transaction failed
const swapApproveTransactionFailed = (state, payload) => {
    console.log('@swapApproveTransactionFailed');

    console.error(payload);
};

// 1inch send transaction
const swapSendTransaction = async (actions, payload) => {
    console.log('@swapSendTransaction');
    console.log(payload);

    const { updateState } = actions;
    const { userId, state, state: { approve } } = payload;

    if (approve.transaction.data) {
        let params = approve.transaction;
        params.from = userId;
        params.gasPrice = ethers.BigNumber.from(params.gasPrice).toHexString();
        params.value = ethers.BigNumber.from(params.value).toHexString();
        // send request
        await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [params]
        })
            .catch(value => actions[actionTypes.SWAP_SEND_TRANSACTION_FAILED]({ userId, value, state }))
            .then(value => actions[actionTypes.SWAP_SEND_TRANSACTION_SUCCEEDED]({ userId, value, state }));
    };
};

// 1inch send transaction succeeded
const swapSendTransactionSucceeded = async (actions, payload) => {
    console.log('@swapSendTransactionSucceeded');
    console.log(payload);

    const { updateState } = actions;
    const { userId, value, state } = payload;

    if (value) {
        // re-init state
        updateState([
            {
                path: 'approve.transaction',
                value: {}
            },
        ]);
        actions[actionTypes.SWAP_DO]({ userId, state })
    } else {
        console.error('error: send transaction', payload);
    };
};

// 1inch send transaction failed
const swapSendTransactionFailed = (state, payload) => {
    console.log('@swapSendTransactionFailed');

    // re-init state
    state.approve.transaction = {};

    console.error(payload);
};

// ankr tokens load succeeded
const swapBalanceLoadSucceeded = (state, payload) => {
    console.log('@swapBalanceLoadSucceeded');

    const { response } = payload;

    console.log(state);
    console.log(payload);

    if (response && response.result) {
        state.tokens.balance = response.result;
        state.initializeStep = 4;
    } else {
        console.error('error: balance load', payload);
    };
};

// ankr tokens load failed
const swapBalanceLoadFailed = (state, payload) => {
    console.log('@swapBalanceLoadFailed');

    console.error(payload);
};

// sell value changed
const swapSellValueChanged = async (actions, payload) => {
    console.log('@swapSellValueChanged');

    const { updateState } = actions;
    const { value, state, state: { swap: { sell: { rateUSD } } } } = payload;

    updateState([
        {
            path: 'swap.sell.value',
            value: value >= 0 ? value : ''
        },
        {
            path: 'swap.sell.valueUSD',
            value: value > 0 ? rateUSD * value : 0
        },
    ]);

};

// fetch quote
const swapFetchQuote = async (actions, payload) => {
    console.log('@swapFetchQuote');
    console.log(payload);

    const { updateState } = actions;
    const { state: { chain, swap: { sell, buy } } } = payload;

    // quote price
    if (sell.value > 0 && sell.token.address != buy.token.address) {
        await serviceUtils.HttpService({
            url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'quote') + `?fromTokenAddress=${swapConvertx00toxEE(sell.token.address)}&toTokenAddress=${swapConvertx00toxEE(buy.token.address)}&amount=${(sell.value * Math.pow(10, sell.token.decimals))}`,
            method: 'GET',
            successActionType: actions[actionTypes.SWAP_QUOTE_LOAD_SUCCEEDED],
            errorActionType: actions[actionTypes.SWAP_QUOTE_LOAD_FAILED]
        });
    } else {
        updateState([
            {
                path: 'swap.status',
                value: 'check'
            },
        ]);
    }
};

// 1inch quote load succeeded
const swapQuoteLoadSucceeded = (state, payload) => {
    console.log('@swapQuoteLoadSucceeded');

    const { response } = payload;
    const { swap: { buy } } = state

    if (response && response.toTokenAmount && response.toTokenAmount >= 0 && response.toToken.decimals && response.toToken.decimals >= 0) {
        state.swap.buy.value = response.toToken.decimals >= 0 ? response.toTokenAmount / Math.pow(10, response.toToken.decimals) : response.toTokenAmount;
        state.swap.buy.valueUSD = buy.rateUSD * buy.value;
        state.swap.status = 'check';
    } else {
        console.error('error: quote load', payload);
    };
};

// 1inch quote load failed
const swapQuoteLoadFailed = (state, payload) => {
    console.log('@swapQuoteLoadFailed');

    console.error(payload);
};

// sell max value
const swapSellMax = async (actions, payload) => {
    console.log('@swapSellMax');

    const { state, state: { swap: { sell } } } = payload;

    actions[actionTypes.SWAP_SELL_VALUE_CHANGED]({ value: sell.balance, state });
};

// check if swap allowed
const swapCheckStatus = (state, payload) => {
    console.log('@swapCheckStatus');

    const { swap: { sell, buy } } = state;

    if (state.swap.status === 'check') {
        if (sell.value === '' || parseFloat(sell.value) <= 0) {
            state.swap.statusLabel = 'Enter amount to swap';
            state.swap.enabled = false;
            state.swap.buy.value = 0;
            state.swap.buy.valueUSD = 0;
        } else if (sell.value > sell.balance) {
            state.swap.statusLabel = `Insufficient ${sell.token.symbol} balance`;
            state.swap.enabled = false;
        } else if (sell.token.address === buy.token.address) {
            state.swap.statusLabel = 'Can\'t swap for same token';
            state.swap.enabled = false;
        } else {
            state.swap.statusLabel = 'Swap';
            state.swap.enabled = true;
        };
        state.swap.status = '';
    };
}

// select select token
const swapSelectSellToken = (state, payload) => {
    console.log('@swapSelectSellToken');

    swapInitializeTokens(state);

    state.tokens.search = '';
    state.tokens.filtered = state.tokens.list;
    state.tokens.type = 'sell';
    state.formActive = 'tokens';
}

// select buy token
const swapSelectBuyToken = (state, payload) => {
    console.log('@selectBuyToken');

    swapInitializeTokens(state);

    state.tokens.search = '';
    state.tokens.filtered = state.tokens.list;
    state.tokens.type = 'buy';
    state.formActive = 'tokens';
}

// initialize tokens
const swapInitializeTokens = (state) => {
    console.log('@swapInitializeTokens');

    if (state.tokens && state.tokens.list && state.tokens.balance && !state.tokens.initialized) {
        // initialize balance
        state.tokens.balance.assets.forEach(bal => {
            let token = state.tokens.list.find(tk => (tk.address === bal.contractAddress) || (tk.address === NULL_ADDRESS_0XEE && bal.contractAddress === NULL_ADDRESS_0X00));
            if (token) {
                token.balance = bal;
                token.rank = parseFloat(bal.balanceUsd) > 0 ? parseFloat(bal.balanceUsd) : (parseFloat(bal.balance) > 0 ? 0.00001 : 0);
            }
        });
        // prepare for sort
        let rank = 0;
        state.tokens.list.filter(tk => !tk.rank).sort((a, b) => a.symbol.localeCompare(b.symbol)).forEach(tk => { rank--; tk.rank = rank });
        // sort and init filtered 
        state.tokens.list.sort((a, b) => b.rank - a.rank);
        state.tokens.filtered = state.tokens.list;
        // initialized
        state.tokens.initialized = true;
    };
}

const swapPrepare = async (actions, payload) => {
    console.log('@swapPrepare');

    const { updateState } = actions;
    const { userId, state, state: { approve, swap, swap: { buy, sell }, chain } } = payload;

    if (buy && buy.value > 0 && sell && sell.value > 0 && swap.enabled) {
        if (approve.allowance === '0') {
            await serviceUtils.HttpService({
                url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'approve/transaction') + `?tokenAddress=${swapConvertx00toxEE(sell.token.address)}`,
                method: 'GET',
                successActionType: actions[actionTypes.SWAP_APPROVE_TRANSACTION_SUCCEEDED],
                errorActionType: actions[actionTypes.SWAP_APPROVE_TRANSACTION_FAILED]
            })
        } else {
            actions[actionTypes.SWAP_DO]({ userId, state })
        }
    } else {
        console.error('error: swap prepare');
    };
}

const swapDo = async (actions, payload) => {
    console.log('@swapDo');

    const { updateState } = actions;
    const { userId, state, state: { approve, swap, swap: { buy, sell }, chain, settings } } = payload;

    if (buy && buy.value > 0 && sell && sell.value > 0 && swap.enabled) {
                //state.settings.allowPartialFill;
        //state.settings.compatibilityMode;
        let params = `?fromTokenAddress=${swapConvertx00toxEE(sell.token.address)}&toTokenAddress=${swapConvertx00toxEE(buy.token.address)}&amount=${(sell.value * Math.pow(10, sell.token.decimals)).toString()}&fromAddress=${userId}&slippage=${settings.slippageValue}` +
            `&allowPartialFill=${settings.allowPartialFill}&compatibilityMode=${settings.compatibilityMode}`;
        if (settings.gasPrice != 'market' && parseFloat(settings.gasPriceValue) > 0 ) {
            params += `&gasPrice=${settings.gasPriceValue * Math.pow(10, 9)}`; // in Gwei
        };
        console.log('@params:', params);
        await serviceUtils.HttpService({
            url: P1INCH.API_URL.replace('%CHAIN_ID%', chain.value.chain.chainId).replace('%METHOD%', 'swap') + params,
            method: 'GET',
            successActionType: actions[actionTypes.SWAP_DO_SUCCEEDED],
            errorActionType: actions[actionTypes.SWAP_DO_FAILED]
        })
    } else {
        console.error('error: swap do');
    };
}

// 1inch swap succeeded
const swapDoSucceeded = (state, payload) => {
    console.log('@swapDoSucceeded');
    console.log(state);
    console.log(payload);

    const { swap } = state;
    const { response } = payload;

    if (response && response.tx) {
        let res = response;
        res.tx.gas = res.tx.gas.toString();
        swap.do = res;
    } else {
        console.error('error: swap do response', payload);
    };
};

// 1inch swap failed
const swapDoFailed = (state, payload) => {
    console.log('@swapDoFailed');

    console.error(payload);
};

// 1inch send 
const swapSend = async (actions, payload) => {
    console.log('@swapSend');
    console.log(payload);

    const { updateState } = actions;
    const { userId, state, state: { swap } } = payload;

    if (swap.do.tx) {
        let params = swap.do.tx;
        params.value = ethers.BigNumber.from(params.value).toHexString();
        console.log('@gas');
        console.log(params.gasPrice);
        console.log(ethers.BigNumber.from(params.gasPrice).toHexString());
        console.log(params.gas);
        console.log(ethers.BigNumber.from(params.gas).toHexString());
        params.gasPrice = ethers.BigNumber.from(params.gasPrice).toHexString();
        params.gas = ethers.BigNumber.from(params.gas).toHexString();
        
        // send swap
        await window.ethereum.request({
            method: 'eth_sendTransaction',
            params: [params]
        })
            .catch(value => actions[actionTypes.SWAP_SEND_FAILED]({ userId, value, state }))
            .then(value => actions[actionTypes.SWAP_SEND_SUCCEEDED]({ userId, value, state }));
    };
};

// 1inch send swap succeeded
const swapSendSucceeded = async (actions, payload) => {
    console.log('@swapSendSucceeded');
    console.log(payload);

    const { updateState } = actions;
    const { userId, value, state } = payload;

    if (value) {
        // re-init state
        updateState([
            {
                path: 'swap.do',
                value: {}
            },
        ]);
        console.log('done: send swap', payload);
    } else {
        console.error('error: send swap', payload);
    };
};

// 1inch send swap failed
const swapSendFailed = (state, payload) => {
    console.log('@swapSendFailed');

    // re-init state
    state.swap.do = {};

    console.error(payload);
};

// ****************************************
// FORM tokens

// click go back
const tokensGoBack = (state, payload) => {
    console.log('@tokensGoBack');
    console.log(payload);

    state.formActive = 'swap';
}

// enter value for search
const tokensSearchSet = (state, payload) => {
    console.log('@tokensSearchSet');

    state.tokens.search = payload.value ? payload.value : '';
}

// do search
const tokensSearchDo = (state, payload) => {
    console.log('@tokensSearchDo');

    const { tokens, tokens: { search, list } } = state;

    // reset initial state
    state.tokens.addressForImport = '';

    // filter by search
    if (search && search.length > 0) {
        state.tokens.filtered = list.filter(tk => tk.symbol.toLowerCase().indexOf(search.toLowerCase()) >= 0 || tk.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 || tk.address.toLowerCase() === search.toLowerCase());
    } else {
        state.tokens.filtered = state.tokens.list;
    };

    // check for import if search for address and not found
    if (tokens && search && search.length > 0 && ethers.utils.isAddress(search) && list.length > 0 && state.tokens.filtered.length === 0) {
        state.tokens.addressForImport = search;
    }
}

// check if import possible
const tokensCheckForImport = async (actions, payload) => {
    console.log('@tokensCheckForImport');

    const { userId, state, state: { tokens: { addressForImport, balance: { assets } } } } = payload;
    const { updateState } = actions;

    if (addressForImport.length > 0) {
        const isContract = await serviceUtils.metamaskIsContract(addressForImport);
        if (isContract.result) {
            await serviceUtils.metamaskGetTokenBalance(addressForImport, userId)
                .then((res) => {
                    if (res.result) {
                        // add balance
                        let balance = assets.find(tk => tk.contractAddress === tokensCheckForImport);
                        // if no balance from ankr - add directly from token
                        if (!balance) {
                            balance = res.decimals > 0 ? { balance: res.balance / Math.pow(10, res.decimals) } : { balance: res.balance };
                        };
                        const token = { symbol: res.symbol, name: res.name, decimals: res.decimals, address: res.address, imported: true, balance: balance, rank: 0 };
                        // re-init
                        updateState([
                            {
                                path: 'tokens.import',
                                value: [token]
                            },
                        ]);
                    } else {
                        console.error('error: import balance fail', res);
                    };
                })
                .catch((err) => console.error(`error ${err.code}: ${err.name}, ${err.message}`));
        } else {
            console.error('error: is not contract', isContract);
        };
    }
}

// do import
const tokensImportDo = (state, payload) => {
    console.log('@tokensImportDo');

    if (state.tokens.import.length > 0) {
        state.tokens.initialized = false;
        state.tokens.list.push(state.tokens.import[0]);
        state.tokens.import = [];

        swapInitializeTokens(state);
        tokensSearchDo(state, payload);
    };
}

// select token
const tokensSelectToken = (state, payload) => {
    console.log('@tokensSelectToken');
    console.log(payload);

    const { symbol } = payload;

    // buy
    let tk = state.tokens.list.find(tk => tk.symbol === symbol);
    if (tk) {
        let token = {
            balance: 0,
            value: 0,
            valueUSD: 0,
            rateUSD: 0,
            token: {
                address: swapConvertxEEtox00(tk.address),
                symbol: tk.symbol,
                name: tk.name,
                decimals: tk.decimals,
                exchange: 0,
                coingeckoId: '',
            }
        };
        console.log('@token');
        console.log(token);
        if (state.tokens.type === 'buy') {
            state.swap.buy = token;
        } else { // sell
            state.swap.sell = token;
        };
        state.initializeStep = 4;
        state.formActive = 'swap';
    } else {
        console.error('error: token was not found', symbol);
    }
}

// add token to Metamask
const tokensAddTokenToMetamask = (state, payload) => {
    console.log('@tokensAddTokenToMetamask');

    const { tokens: { filtered } } = state;
    const { key } = payload;

    if (filtered.length > 0 && key.length > 0) {
        const token = filtered.find(tk => tk.address === key);
        if (token) {
            serviceUtils.metamaskWatchAsset('ERC20', token.address, token.symbol, token.decimals, token.logoURI)
                .then((res) => console.log(res))
                .catch((err) => console.error(`error ${err.code}: ${err.name}, ${err.message}`));
        } else {
            console.error('error: import token was not found', key);
        };
    }
}

// ****************************************
// FORM settings

const settingsSelectGas = (state, payload) => {
    console.log('@settingsSelectGas');
    console.log(payload);

    switch (payload.value) {
        case 'low':
            state.settings.gasPrice = payload.value;
            state.settings.gasPriceValue = 6;
            break;
        case 'market':
            state.settings.gasPrice = payload.value;
            state.settings.gasPriceValue = 15;
            break;
        case 'high':
            state.settings.gasPrice = payload.value;
            state.settings.gasPriceValue = 20;
            break;
    }
}

const settingsSetGasCustom = (state, payload) => {
    console.log('@settingsSetGasCustom');
    console.log(payload);

    state.settings.gasPrice = 'custom';
    if (payload && payload.value) {
        state.settings.gasPriceValue = payload.value;
    };
}

const settingsSelectSlippage = (state, payload) => {
    console.log('@settingsSelectSlippage');
    console.log(payload);

    switch (payload.value) {
        case '0.1%':
            state.settings.slippage = payload.value;
            state.settings.slippageValue = 0.1;
            break;
        case '0.5%':
            state.settings.slippage = payload.value;
            state.settings.slippageValue = 0.5;
            break;
        case '1%':
            state.settings.slippage = payload.value;
            state.settings.slippageValue = 1;
            break;
        case '3%':
            state.settings.slippage = payload.value;
            state.settings.slippageValue = 3;
            break;
    }
}

const settingsSetSlippageCustom = (state, payload) => {
    console.log('@settingsSelectSlippage');
    console.log(payload);

    state.settings.slippage = 'custom';
    if (payload && payload.value) {
        state.settings.slippageValue = payload.value;
    };
}

const settingsToggleAllowPartialFill = (state, payload) => {
    console.log('@settingsToggleAllowPartialFill');

    state.settings.allowPartialFill = !state.settings.allowPartialFill;
}

const settingsToggleRoutingPreset = (state, payload) => {
    console.log('@settingsToggleRoutingPreset');

    state.settings.routingPreset = !state.settings.routingPreset;
}

const settingsToggleCompatibilityMode = (state, payload) => {
    console.log('@settingsToggleCompatibilityMode');

    state.settings.compatibilityMode = !state.settings.compatibilityMode;
}

const settingsGoBack = (state, payload) => {
    console.log('@settingsGoBack');

    state.formActive = 'swap';
}

export const uiActions = {
    actionTypes,
    actionHandlers
};