import React, { useRef } from 'react';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { SmartContractAllowanceState } from '../../../models/smart-contract-allowance-model';
import { uiActions } from '../actions/uiActions';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { allowanceEffects } from '../actions/allowanceEffects';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import { constants_allowance } from '../constants';
import { PopoverLayout } from '../../../components/utility/popover/view';
import { utils } from '../../../utils/utils';

const { HEADER_KEYS } = constants_allowance;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderTableHeader = () => {
  return <div className="custom-table-row table-header">
    {HEADER_KEYS.map((key, i) => <div className="custom-table-header-cell font-size-14 font-weight-medium" key={`header-${i}`}>{key}</div>)}
  </div>
};

const renderRow = (data, actions, index, props) => {
  const { userId } = props;
  const { contract: { address }, asset: { symbol, name }, block, amount, chain, transaction } = data;
  const clickHandle = () => actions[allowanceEffects.actionTypes.SMART_CONTRACT_ALLOWANCE_REVOKED]({ data, userId, chain });
  const contractAddressLink = <a rel='noreferrer' target='_blank' href={utils.getAddressLink(chain, address)}>{address}</a>;
  const txLink = <a rel='noreferrer' target='_blank' href={utils.getTxLink(chain, transaction)}>{transaction}</a>;
  return <div className="custom-table-row table-body font-size-14 font-weight-regular" key={`body-${index}`}>
    <div className="custom-table-body-cell d-flex align-items-center"><PopoverLayout icon={symbol} message={symbol} /></div>
    <div className="custom-table-body-cell"><PopoverLayout message={name} /></div>
    <div className="custom-table-body-cell"><PopoverLayout message={txLink} /></div>
    <div className="custom-table-body-cell"><PopoverLayout message={contractAddressLink} /></div>
    <div className="custom-table-body-cell"><PopoverLayout message={block} /></div>
    <div className="custom-table-body-cell"><PopoverLayout message={amount} /></div>
    <div className="custom-table-body-cell">
      {+amount > 0 || amount === 'Infinity' ?
        <ButtonCustom
          onClick={clickHandle}
          className="w-100"
          variant="primary"
          size="sm"
          label="Revoke">
        </ButtonCustom> : null
      }
    </div>
  </div>
};

const renderTableBody = (data, actions, props) => data.map((d, i) => renderRow(d, actions, i, props));

const renderLoader = () => {
  return <div className="loader text-center" key={0}>
    <span>
      <i className="fa fa-refresh fa-spin"></i>
      <span className="ms-3">Loading...</span>
    </span>
  </div>;
}

export const TableTemplate = props => {
  const actions = SmartContractAllowanceState.useStoreActions(actions => actions);
  const state = SmartContractAllowanceState.useStoreState(state => state);
  const tableData = state.tableData;
  const isLoading = state.loader;
  const loadFunc = value => actions[uiActions.actionTypes.SMART_CONTRACT_ALLOWANCE_LOAD_MORE_LOADING]({ page: value, state });
  const ref = useRef();
  return <div className="custom-table h-100" ref={ref}>
    {renderTableHeader()}
    <div className="scroll-container">
      {isLoading ?

        <Loader size={'6x'} />
        :
        !isEmpty(tableData.data) ?

          <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={loadFunc}
              hasMore={tableData.pagination.hasMore}
              loader={renderLoader()}
              useWindow={false}
              getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
              {renderTableBody(tableData.filteredData, actions, props)}
            </InfiniteScroll>
          </CustomScroll>
          :
          <EmptySearchState />}
    </div>
  </div>;
};