import React, { useEffect, useRef } from 'react';
import { templates } from './tableTemplate';
import * as d3 from 'd3';
import { drawHelper } from './drawingHelper';
import { constants } from '../../../constants';

const { INVEST_CONTENT: { STATISTICS: { CONTENT } } } = constants;

export const ImagesGenerator = () => {
    const tableRef = useRef();
    const ref = useRef();
    useEffect(() => {
        drawHelper.drawTableContent(d3.select(tableRef.current), CONTENT);
    }, );
    return <div ref={ref} className="images-background-grid">
        <div ref={ref}>
            <svg ref={tableRef} viewBox="-85 0 1323 800" fill="none" className="d-flex justify-content-center image-stats-table">
                { templates.tableTemplate() }
                <svg viewBox="-10 -100 1323 800" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.leftTopChart() }</svg>
                <svg viewBox="-580 -190 1323 800" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.rightTopChart() }</svg>
                <svg viewBox="-270 -420 1323 800" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.lock() }</svg>
                <svg viewBox="-574 -375 1323 800" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.bluGradBg() }</svg>
                <svg viewBox="-680 -360 1323 800" fill="none" xmlns="http://www.w3.org/2000/svg">{ templates.starsBg() }</svg>
            </svg>
        </div>
    </div>;
};
