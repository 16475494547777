import { isEmpty } from 'lodash';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';
import { constants } from '../constants';

const { ABI } = constants;

const createDate = timestamp => {
    const date = new Date(timestamp * 1000);
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = '0' + date.getHours();
    const minutes = '0' + date.getMinutes();
    const todayDate = new Date();
    const today = todayDate.getDate();
    const formattedTime = `${month} ${day}, ${year}, ${hours.slice(-2)}:${minutes.slice(-2)}`;

    return { timestamp, date: formattedTime, endsIn: day - today };
};

const uniquePools = pools => {
    const unique = pools.filter(
        (pool, index) => index === pools.findIndex(
            other => pool.reward_token.symbol === other.reward_token.symbol
        ));
    
    return unique;
};



const formatArrayObject = array => {
    return array.map(item => {
        return {
            enabled: item.enabled,
            address: item.pool.address,
            reward_token: item.pool.reward_token,
            staked_token: item.pool.staked_token,
            reward_amount: item.reward_amount,
            reward_price: item.reward_price,
            staked_amount: item.staked_amount,
            staked_price: item.staked_price,
            start_block: item.pool.start_block,
            end_block: item.pool.end_block
        };
    });
};

const getPool = (pools, address) => {
    const arr = pools.filter(pool => pool.reward_token.address === address);

    if(isEmpty(arr)) return { end_block: 0 };
    else return arr.find(pool => pool.reward_token.address === address);
};

// const checkPoolAllowance = async (cakeAddress, pools) => {
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
    
//     const signer = provider.getSigner();
//     const signerAddress = signer.getAddress();
//     const cakeContract = new ethers.Contract(cakeAddress, ABI, provider);

//     const enabledArray = await pools.map(pool => {
//         if(!pool.address) return;
//         const allowance = cakeContract.allowance(signerAddress, pool.address);
//         const enabled = allowance._hex === '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff' ? true : false;
//         const params = {
//             enabled,
//             address: pool.address
//         };

//         return params;
//     });
//     console.log(enabledArray);
//     return enabledArray;
// };

const getToken = async cakeAddress => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const cakeContract = new ethers.Contract(cakeAddress, ABI, provider);
    const balance = await cakeContract.balanceOf(signer.getAddress());
    
    return utils.fromWei(utils.hex2num(balance._hex));
};

//  harvest
const getHarvestParams = (from, to) => {
    const endString = `${utils.stringCreator('0', 64)}`;
    const method = '0xb6b55f25';
    const data = `${method}${endString}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

//  UNstake
const getUnstakeParams = (from, amount, to) => {
    amount = utils.toWei(amount);
    amount = utils.num2hex(amount);
    const withdrawAmount = amount.toString().slice(2);
    const method = '0x2e1a7d4d';
    const data = `${method}${utils.stringCreator('0', 64 - withdrawAmount.length)}${withdrawAmount}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

//  Stake
const getStakeParams = (from, amount, to) => {
    amount = utils.toWei(amount);
    amount = utils.num2hex(amount);
    const depositAmount = amount.toString().slice(2);
    const method = '0xb6b55f25';
    const data = `${method}${utils.stringCreator('0', 64 - depositAmount.length)}${depositAmount}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

// Approve
const getApproveParams = (from, to, poolAddress) => {
    const method = '0x095ea7b3';
    const data = `${method}${utils.stringCreator('0', 24)}${poolAddress.slice(2)}${utils.stringCreator('f', 64)}`;

    const params = {
        to,
        from,
        value: '0x0',
        data,
    };

    return params;
};

export const dataHelpers = {
    // checkPoolAllowance,
    uniquePools,
    getApproveParams,
    createDate,
    formatArrayObject,
    getToken,
    getStakeParams,
    getUnstakeParams,
    getHarvestParams,
    getPool
};