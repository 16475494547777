import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { constants } from "../constants/constants";
import axios from "axios";
import { ethers } from 'ethers';
import useWebSocket from 'react-use-websocket';
import { authorizationEffect } from '../components/metaMaskAthorization/actions/authorizationEffect';
import { isEmpty } from 'lodash';

const { TOKEN_EXPIRE_BEFORE, CHAINS, ABI_ERC20, NULL_ADDRESS_0X00, NULL_ADDRESS_0XEE } = constants;

const metamaskGetAccounts = async () => {
	if (!window.ethereum) {
		return { result: false, message: `metamask was not installed` }
	} else {
		let result = { result: false };
		// login to metamask, enter pwd in extension
		await window.ethereum.request({ method: 'eth_requestAccounts' })
			.then((accounts) => result = { result: true, message: `accounts`, accounts: accounts })
			.catch((err) => {
				if (err.code === 4001) {
					result = { result: false, message: `please connect to MetaMask` };
				} else {
					result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` };
				};
			});
		return result;
	}
}

const metamaskSwitchEthereumChain = async (chain, type) => {
  const types = !type ? 'mainnet' : type
	const fchain =
    CHAINS.filter(ch => ch.type === types)
      .find(ch => ch.oldchain === chain);
	// const fchain = CHAINS.find(ch => ch.oldchain === chain);

	if (!window.ethereum || !fchain) {
		return window.ethereum ? { result: false, message: `${chain} chain was not found` } : { result: false, message: `metamask was not installed` }
	} else {
		let result = await metamaskGetAccounts();
		// try to switch chain
		if (result.result && result.accounts && result.accounts.length > 0) {
			result = { result: false };
			await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [{ chainId: `0x${fchain.chainId.toString(16)}` }],
			})
				.then(() => result = { result: true, message: `switched` })
				.catch((err) => {
					if (err.code === 4902) {
						result = metamaskAddEthereumChain(chain);
					} else if (err.code === 4001) {
						result = { result: false, message: `rejected switch` };
					} else {
						result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` };
					}
				});
		}
		return result;
	}
};

const metamaskAddEthereumChain = async (chain) => {
	const fchain = CHAINS.find(ch => ch.oldchain === chain);

	if (!window.ethereum || !fchain) {
		return window.ethereum ? { result: false, message: `${chain} chain was not found` } : { result: false, message: 'metamask was not installed' }
	} else {
		let result = await metamaskGetAccounts();
		// try to add chain
		if (result.result && result.accounts && result.accounts.length > 0) {
			result = { result: false };
			await window.ethereum.request({
				method: 'wallet_addEthereumChain',
				params: [
					{
						chainId: `0x${fchain.chainId.toString(16)}`,
						chainName: `${fchain.chainName}`,
						rpcUrls: fchain.rpcUrls,
						blockExplorerUrls: fchain.blockExplorerUrls,
						nativeCurrency: {
							name: fchain.nativeTokenSymbol,
							symbol: fchain.nativeTokenSymbol,
							decimals: fchain.nativeTokenDecimals
						}
					},
				],
			})
				.then(() => result = { result: true, message: `added` })
				.catch((err) => {
					if (err.code === 4001) {
						result = { result: false, message: `rejected add` }
					} else {
						result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` };
					};
				});
		}
		return result;
	}
}

const metamaskWatchAsset = async (tokenType, tokenAddress, tokenSymbol, tokenDecimals, tokenImage) => {
	if (!window.ethereum) {
		return { result: false, message: 'metamask was not installed' }
	} else {
		let result = await metamaskGetAccounts();
		// add / watch asset
		if (result.result && result.accounts && result.accounts.length > 0) {
			result = { result: false };
			await window.ethereum.request({
				method: 'wallet_watchAsset',
				params: {
					type: tokenType ? tokenType : 'ERC20',
					options: {
						address: tokenAddress,
						symbol: tokenSymbol,
						decimals: tokenDecimals,
						image: tokenImage,
					},
				},
			})
				.then((success) => success ? result = { result: true, message: `added` } : result = { result: false, message: `error` })
				.catch((err) => {
					if (err.code === 4001) {
						result = { result: false, message: `rejected add` };
					} else {
						result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` };
					};
				});
		}
		return result;
	}
}

const metamaskGetTokenBalance = async (tokenAddress, accountAddress) => {
	if (!window.ethereum) {
		return { result: false, message: 'metamask was not installed' }
	} else {
		let result = await metamaskGetAccounts();
		// try to get balance
		if (result.result && result.accounts && result.accounts.length > 0) {
			result = { result: false };
			if (!tokenAddress || tokenAddress === '0x0' || tokenAddress.toLowerCase() === NULL_ADDRESS_0X00 || tokenAddress.toLowerCase() === NULL_ADDRESS_0XEE || tokenAddress === '') {
				const provider = new ethers.providers.Web3Provider(window.ethereum);
				const balance = +await provider.getBalance(accountAddress);
				const decimals = 18; // same for all EVM native tokens
				result = { result: true, native: true, decimals, balance, address: NULL_ADDRESS_0X00 }
			} else {
				const provider = new ethers.providers.Web3Provider(window.ethereum);
				const tokenContract = new ethers.Contract(tokenAddress, ABI_ERC20, provider);
				await tokenContract.balanceOf(accountAddress)
					.then(async (res) => {
						const balance = +res;
						const decimals = +await tokenContract.decimals();
						const symbol = await tokenContract.symbol();
						const name = await tokenContract.name();
						result = { result: true, native: false, symbol, name, decimals, balance, address: tokenAddress }
					})
					.catch((err) => {
						result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` };
					});
			};
		};
		return result;
	}
};

const metamaskIsContract = async (contractAddress) => {
	if (!window.ethereum) {
		return { result: false, message: 'metamask was not installed' }
	} else if ((!contractAddress) || (contractAddress && !ethers.utils.isAddress(contractAddress))) {
		return { result: false, message: `${contractAddress} is not address` }
	} else {
		let result = { result: false };
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		await provider.getCode(contractAddress)
			.then((code) => result = { result: (code !== '0x') })
			.catch((err) => result = { result: false, message: `error ${err.code}: ${err.name}, ${err.message}` });
		return result;
	}
};

const getFilteredChains = (type, arrIDs) => {
	return CHAINS
		.filter(c => ((type && c.type === type) || (!type) || (type && type.length === 0)) &&
			((arrIDs && Array.isArray(arrIDs) && arrIDs.length > 0 && arrIDs.includes(c.chain)) || (!arrIDs) || (arrIDs && Array.isArray(arrIDs) && arrIDs.length === 0)))
		.sort((a, b) => (a.rank - b.rank));
}

const getIdToken = () => {
	const authorization = reactLocalStorage.getObject('authorization');
	const { id_token } = authorization;
	return id_token;
};

const getUserInfo = async () => {
	const user = await authorizationEffect.userInfo();
	return !isEmpty(user);
};

const clearCredentials = () => {
	authorizationEffect.logOutHandle(null, { reload: true }); //direct call to action
};

const isTokenValid = () => {
	const currentTimestamp = moment.now();
	const expire_after = parseInt(reactLocalStorage.get('token_expired_time')) || 0;
	return Boolean(!expire_after || expire_after > currentTimestamp + TOKEN_EXPIRE_BEFORE);
};

const fetchService = async options => {
	/**
	 * options structure {
	 *     url: some url string mandatory
	 *     data: requested data default {},
	 *     method: request method default 'get'
	 *     errorActionType: action if request failed optional  return error
	 *     successActionType: action if request finished with success return response
	 * }
	 */
	await axios({
		url: options.url, // mandatory,
		method: options.method || 'get',
		data: options.data || {},
	})
		.then(response => options.successActionType({ response, options }))
		.catch(e => options.errorActionType ? options.errorActionType({ error: e, options }) : null);

};

const websocketStream = options => {
	/**
	 * options structure {
	 *     url: some url string mandatory
	 *     receivedActionType: action if message delivered
	 *     openActionType: action if connection is opened
	 *     closeActionType: action if connection is closed
	 *     additional: other options by documentation
	 *
	 *     returns sendJsonMessage, sendMessage, readyState, lastJsonMessage, lastMessage ...
	 *     https://github.com/robtaussig/react-use-websocket#readme
	 * }
	 */
	return useWebSocket(
		options.url,
		{
			onOpen: e => options.openActionType(e),
			onMessage: e => options.receivedActionType(e),
			onClose: e => options.closeActionType(e),
			share: true,
			// filter: e => console.info(e),
			...options.additional || {}
		}
	)
}

export const serviceUtils = {
	getIdToken,
	clearCredentials,
	isTokenValid,
	HttpService: fetchService,
	websocketStream,
	getUserInfo,
	metamaskGetAccounts,
	metamaskSwitchEthereumChain,
	metamaskAddEthereumChain,
	metamaskGetTokenBalance,
	metamaskWatchAsset,
	metamaskIsContract,
	getFilteredChains,
}
