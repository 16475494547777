import createHash from 'hash-generator';
import { NIL, v5 as uuidv5 } from 'uuid';
import {constants} from '../../constants';
import {utils} from '../../../../../../utils/utils';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import { Buffer } from 'buffer';

window.Buffer = Buffer;

const {stringCreator} = utils;
const {PROJECT_NAME, VEST: { USDT_AMOUNT: { DAYS_180, DAYS_90 } } } = constants;

const createSignUpData = address => {
    return {...address, password: createHash(30)};
};

const createAddress = wallet => {
    const namespace = uuidv5(PROJECT_NAME, NIL);
    return {
        "username": uuidv5(wallet, namespace),
        "validationData": {
            "custom:web3address": wallet
        },
        "attributes": {
            "custom:web3address": wallet,
        },
        account: wallet,
    };
};

// const TransactionParameters = state => {
//   return  {
//       from: '0xb60e8dd61c5d32be8058bb8eb970870f07233155', //address.account
//       to: '0xd46e8dd67c5d32be8058bb8eb970870f07244567', // Roma dast  // is address from constants
//       value: '0x0', // 2441406250
//       data: `0x095ea7b3000000000000000000000000${contractAddressFormatted}0000000000000000000000000000000000000000000000000000000000000000`
// // address of contract without 0x  64f at end, at start 24 0 from constant
//       // second iteration
//       //0x94b918de   +  0 - formvalue * 10 in 6 stepeni toString(16) length 64 with contract
//     };
// };

const getConnector = () => {
    return new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
        qrcodeModal: QRCodeModal,
    });
};


const getTransactionParameters = state => {
    const {transactionData, selling} = state;
    const {account: {account}, data: {address}} = transactionData;
    const value = (selling * 10 * Math.pow(10, 18)).toString(16);
    const prefix = '0x94b918de';
    const formattedValue = `${stringCreator('0', 64 - value.length)}${value}`;
    return {
        from: account,
        to: address,
        value: '0x0',
        gas: '0x30d40',
        data: `${prefix}${formattedValue}`
    };
};

const getTrustTransactionParameters = state => {
    const value = (state.selling * 10 * Math.pow(10, 18)).toString(16);
    const prefix = '0x94b918de';
    const formattedValue = `${stringCreator('0', 64 - value.length)}${value}`;
    return {
        from: state.transactionData.account,
        to: state.transactionData.data.address,
        value: '0x0',
        gas: '0x30d40',
        data: `${prefix}${formattedValue}`
    };
};

const getTrustApproveParameters = payload => {
    const formattedAddress = payload.data.address.slice(2);
    return {
        from: payload.account,
        to: payload.data.USDT_ADDRESS,
        value: '0x0',
        gas: '0xea60',
        data: `0x095ea7b3${stringCreator("0", 24)}${formattedAddress}${stringCreator("f", 64)}`
    };
};

const getApproveParameters = payload => {
    const {data: {address, USDT_ADDRESS}, account: {account}} = payload;
    const formattedAddress = address.slice(2);
    return {
        from: account,
        to: USDT_ADDRESS,
        value: '0x0',
        gas: '0xea60',
        data: `0x095ea7b3${stringCreator("0", 24)}${formattedAddress}${stringCreator("f", 64)}`
    }
};

export const dataHelper = {
    getConnector,
    createAddress,
    createSignUpData,
    getTransactionParameters,
    getTrustTransactionParameters,
    getTrustApproveParameters,
    getApproveParameters
};