import React from 'react';
import { PopoverLayout } from '../../../components/utility/popover/view';
import { VestingState } from '../../../models/vesting-model';
import { FormControl, InputGroup } from 'react-bootstrap';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import { vestingConstants } from '../constants';
import { vestingEffects } from '../actions/vestingEffects';
import { investEffects } from '../actions/investEffects';
import Slider from 'rc-slider';
import { dataHelper } from '../dataHelpers/dataHelper';

const { USDT_ADDRESSES, INVESTORS: { TIP, TIP_TITLE, TITLE, PERIOD, SUM_DAYS, DAYS,
    PART_DAYS, EVENT_INCOME, INTEREST, INVEST_START, INVEST_END,
    PURCHASE_AMOUNT, MIN_PURCHASE, MAX_PURCHASE, INVEST }, VEST: { CONTENT, WITHDRAW, AVAILABLE } } = vestingConstants;

export const InvestorsBuy = ({ userWallet, chain, balance }) => {
    const state = VestingState.useStoreState(state => state.investors);
    const actions = VestingState.useStoreActions(actions => actions);

    const usdtAddress = USDT_ADDRESSES.find(network => network.chain === chain).usdtAddress;
    const contractAddress = USDT_ADDRESSES.find(network => network.chain === chain).investor;
    const usdtEnabled = !state.invested.chainObject?.isApprove;

    const lockTime = Number('545');
    const startDate = state.invested.isInvested ? dataHelper.getDateFromTimestamp(state.invested.chainObject.startLock) : state.startDate;
    const endDate = state.invested.isInvested ? dataHelper.getDateFromTimestamp(Number(state.invested.chainObject.startLock) + 47088000) : state.endDate;
    const { left, daysGone } = state.invested.isInvested ? dataHelper.getLeftDays(Number(state.invested.chainObject.startLock) + 47088000, lockTime) : 0;

    const onChangeHandle = e => {
        actions[investEffects.actionTypes.INVEST_FORM_VALIDATION]({ value: e.target.value });
        actions[investEffects.actionTypes.INVEST_USDT_AMOUNT_ENTERED]({ usdtAmount: e.target.value, days: '545' });
    };
    const onStartVesting = () => actions[investEffects.actionTypes.INVEST_INVESTOR_STARTED]({ userWallet, chain, contractAddress, mcsAmount: state.form.mcsAmount });
    const onWithdrawMCS = () => actions[vestingEffects.actionTypes.VEST_VESTING_WITHDRAW]({ userWallet, chain, contractAddress });
    const onApprove = () => actions[vestingEffects.actionTypes.VEST_ENABLE_INVOKED]({ chain, userWallet, usdtAddress, contractAddress });

    return <div className="investors-buy-layout">
        <div className="investors-buy-header">
            <div className="investors-buy-header-title font-unbounded font-size-20">{TITLE}</div>
            <div className="investors-buy-header-tip"><PopoverLayout title={false} message={TIP} component={<>{TIP_TITLE}<i className="fa-light fa-circle-question ps-2"></i></>}/></div>
        </div>
        <div className="investors-grid">
            <div className="investors-period wrapper">
                <div>
                    <div className="heading">{PERIOD}</div>
                    <div className="d-flex align-items-center">
                        <div className="value pe-3">{`${SUM_DAYS} ${DAYS}`}</div>
                        <div className="heading">{`( ${INTEREST} ${PART_DAYS[0]} days + ${EVENT_INCOME} ${PART_DAYS[1]} days )`}
                            <PopoverLayout title={false} message={TIP} component={<i className="fa-light fa-circle-question ps-2"></i>}/>
                        </div>
                    </div>
                </div>
                <div className="period-item">
                    <div className="heading">{INVEST_START}</div>
                    <div className="value">{startDate}</div>
                </div>
                <div className="period-item">
                    <div className="heading">{INVEST_END}</div>
                    <div className="value">{endDate}</div>
                </div>
                {state.invested.isInvested ? <div className="period-item">
                    <div className="slider-left-days">
                        <span className="font-size-12 pe-2">{CONTENT.LEFT}</span>
                        <span className="font-size-18 days">{left} {DAYS}</span>
                    </div>
                    <div className="slider">
                        <Slider className="slider -primary" defaultValue={0} min={0} max={Number(lockTime)} value={daysGone}/>
                    </div>
                </div> : <div></div>}
            </div>
            <div className="investors-purchase wrapper">
                <div className="mobile-grid">
                    <div className="purchase-item d-flex align-items-center">
                        <div className="heading pe-3">{PURCHASE_AMOUNT}</div>
                        <div className="value">{state.buyAmount} USDT</div>
                    </div>
                    <div className="purchase-item d-flex align-items-center">
                        <div className="heading pe-3">{MIN_PURCHASE}</div>
                        <div className="value">100.000,00 USDT</div>
                    </div>
                    <div className="purchase-item d-flex align-items-center">
                        <div className="heading pe-3">{MAX_PURCHASE}</div>
                        <div className="value">500.000,00 USDT</div>
                    </div>
                    <img className="img-fluid" src="../dashboard-library/vesting/dollar-img.svg" />
                </div>
            </div>
            <div className="vesting-controls">
                <div className="control-block">
                    <div className="heading">{CONTENT.TOTAL_AMOUNT}</div>
                    <div className="control-inner-grid">
                        <div className="value usdt-value">{balance} USDT</div>
                        <div>
                        </div>
                    </div>
                    <div className="control-amount align-items-center">
                        {!state.invested.isInvested ? <><div className="font-size-12 pe-2 enter-usdt-value">{CONTENT.ENTER_VALUE}</div>
                            <div><InputGroup className="pe-2 input-amount">
                                <FormControl
                                    onChange={onChangeHandle}
                                    placeholder={'Amount...'} />
                                <InputGroup.Text>
                                    <i className="fa-thin fa-dollar-sign"></i>
                                </InputGroup.Text>
                            </InputGroup>
                            </div>
                            <div className="total-buy-button ms-2">
                                <ButtonCustom variant="primary" size="sm" label="MAX" />
                            </div>
                            <div className="ps-2 text-end">
                                <ButtonCustom onClick={usdtEnabled ? onStartVesting : onApprove} variant="primary" 
                                    disabled={usdtEnabled ? !state.form.validity : false} size="sm" label={usdtEnabled ? INVEST : CONTENT.ENABLE} />
                            </div>
                        </> : <div>You have already invested your USDT</div>}
                    </div>
                </div>
                <div className="control-block">
                    <div className="heading">{WITHDRAW}</div>
                    <div className="value d-flex align-items-center">{state.invested.isInvested ? state.invested.chainObject?.amountToClaim : 0} 
                        <img className="mcs-icon me-2 ms-2" src="../coins-full-library/mcs.svg" />Monoceros</div>
                    {state.invested.isInvested && <div><ButtonCustom onClick={onWithdrawMCS} variant="primary" size="sm" label="Claim" /></div>}
                </div>
                <div className="control-block">
                    <div className="heading">{AVAILABLE}</div>
                    <div className="value withdraw-grid align-items-center">
                        <div>50/1</div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};