import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [15, 25, 5, 34, 44, 40, 50, 47, 32, 40, 30, 33, 25, 55],
            borderColor: '#18A0FB',
            backgroundColor: '#18A0FB',
            tension: 0.4
        },
    ],
};

const options = {
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'right',
        }
    },
    responsive: true,
};

export const LineChart = (props) => {
    // const { data } = props;

    // const values = data.map((value) => value.value);
    // const labels = data.map((label) => label.protocol);

    return (
        <Line
            // datasetIdKey='id'
            // updateMode='resize'
            options={options}
            data={data}
        />
    );
};
