/* eslint-disable max-len */
import React from 'react';

export const GET_PART = <>
    <path d="M41.2189 194.015C47.8962 188.456 46.9468 175.786 39.0983 165.715C31.2498 155.645 19.4742 151.987 12.7968 157.545C6.11941 163.104 7.06879 175.775 14.9173 185.845C22.7658 195.915 34.5414 199.573 41.2189 194.015Z" stroke="url(#paint0_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M43.5012 192.054C50.1786 186.495 49.2292 173.826 41.3806 163.754C33.5321 153.684 21.7565 150.026 15.0792 155.584C8.40175 161.144 9.35113 173.814 17.1997 183.884C25.0482 193.955 36.8238 197.612 43.5012 192.054Z" stroke="url(#paint1_linear_111_1096)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    <path d="M91.0464 156.963C96.9239 152.065 95.9472 140.734 88.8648 131.657C81.7824 122.579 71.2762 119.19 65.3986 124.088C59.5211 128.987 60.4979 140.316 67.5803 149.394C74.6627 158.472 85.1688 161.861 91.0464 156.963Z" stroke="url(#paint2_linear_111_1096)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    <path d="M99.2411 149.916C105.119 145.018 104.142 133.688 97.0594 124.609C89.977 115.532 79.4709 112.143 73.5934 117.041C67.7158 121.939 68.6925 133.269 75.7749 142.348C82.8574 151.426 93.3635 154.813 99.2411 149.916Z" stroke="url(#paint3_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M118.784 134.138C123.813 129.933 122.617 119.787 116.112 111.476C109.607 103.164 100.256 99.8349 95.227 104.039C90.1978 108.244 91.3943 118.39 97.8994 126.701C104.405 135.013 113.755 138.342 118.784 134.138Z" stroke="url(#paint4_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M112.984 138.612C118.013 134.407 116.817 124.262 110.312 115.95C103.806 107.639 94.4561 104.309 89.4269 108.514C84.3977 112.719 85.5942 122.865 92.0993 131.176C98.6045 139.487 107.955 142.817 112.984 138.612Z" stroke="url(#paint5_linear_111_1096)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    <path d="M156.838 129.137C165.555 121.817 161.535 101.787 147.864 84.3986C134.191 67.0106 116.041 58.8492 107.326 66.1697C98.6102 73.49 102.629 93.52 116.301 110.908C129.974 128.296 148.123 136.457 156.838 129.137Z" stroke="url(#paint6_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M79.6352 157.678C83.4855 154.452 82.235 146.264 76.8424 139.389C71.4496 132.513 63.9568 129.555 60.1065 132.781C56.2562 136.006 57.5066 144.196 62.8994 151.071C68.292 157.946 75.7849 160.904 79.6352 157.678Z" stroke="url(#paint7_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M47.3253 175.821L55.872 170.859L80.2571 145.175L94.0991 140.478L102.522 132.775L113.921 123.608L118.902 115.708" stroke="url(#paint8_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M41.2601 163.596L47.3255 157.485L74.5912 136.861" stroke="url(#paint9_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M24.3379 153.122L29.3291 147.462L59.9981 132.773" stroke="url(#paint10_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M80.7843 155.942L76.2158 164.847L41.5857 194.262" stroke="url(#paint11_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M65.2365 124.128L73.4218 117.083L89.3934 108.633L95.183 104.152L86.3608 97.6284L113.104 76.7198L107.19 66.2261L121.042 50.5055C121.042 50.5055 144.019 43.264 164.327 71.6695C184.633 100.075 178.235 113.123 178.235 113.123L177.009 119.097L156.892 128.992L150.37 121.927L125.852 142.914L118.903 134.071" stroke="url(#paint12_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M34.3297 32.3971L65.8285 1.68262L88.6449 14.947L120.968 21.8248L84.0981 60.9892L34.3297 32.3971Z" stroke="url(#paint13_linear_111_1096)" strokeWidth="0.617063" strokeMiterlimit="10"/>
    <path d="M95.6681 53.5709V49.7095L105.888 39.3927L110.898 38.0762L125.472 47.43L111.811 60.9892L95.6681 53.5709Z" stroke="url(#paint14_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M108.483 88.9819L122.211 96.3313L137.878 85.9163C133.286 82.0324 128.367 78.5798 123.181 75.5996L108.483 88.9819Z" stroke="url(#paint15_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M127.992 102.061L141.881 91.4785L154.706 109.872L140.284 119.137L127.992 102.061Z" stroke="url(#paint16_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M147.775 69.0062L152.29 63.6514L157.378 72.3764L152.29 77.3481L147.775 69.0062Z" stroke="url(#paint17_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M137.878 109.87L145.541 103.759L139.247 97.6182L132.041 103.739L137.878 109.87Z" stroke="url(#paint18_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M160.315 77.3485L162.453 81.1017L165.391 79.2251L162.853 75.5996L160.315 77.3485Z" stroke="url(#paint19_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M166.817 99.8397L173.139 96.332L174.832 100.921L168.861 103.74L166.817 99.8397Z" stroke="url(#paint20_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M170.82 112.524V115.707L174.832 114.116V109.871L170.82 112.524Z" stroke="url(#paint21_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M168.034 91.4779C169.494 91.4779 170.677 90.2418 170.677 88.7171C170.677 87.1922 169.494 85.9561 168.034 85.9561C166.574 85.9561 165.391 87.1922 165.391 88.7171C165.391 90.2418 166.574 91.4779 168.034 91.4779Z" stroke="url(#paint22_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M139.39 64.5569C140.387 64.5569 141.196 63.7165 141.196 62.6798C141.196 61.6432 140.387 60.8027 139.39 60.8027C138.392 60.8027 137.583 61.6432 137.583 62.6798C137.583 63.7165 138.392 64.5569 139.39 64.5569Z" stroke="url(#paint23_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M168.823 61.4426C169.931 61.4426 170.828 60.5053 170.828 59.3492C170.828 58.1931 169.931 57.2559 168.823 57.2559C167.715 57.2559 166.817 58.1931 166.817 59.3492C166.817 60.5053 167.715 61.4426 168.823 61.4426Z" stroke="url(#paint24_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M174.311 121.246C185.846 112.425 183.717 89.2402 169.557 69.4623C155.398 49.6846 134.567 40.8028 123.032 49.6244C111.497 58.4459 113.625 81.6302 127.786 101.408C141.946 121.186 162.776 130.068 174.311 121.246Z" stroke="url(#paint25_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M157.74 131.058C169.69 121.959 168.719 99.6302 155.571 81.1848C142.422 62.7396 122.076 55.1629 110.126 64.2621C98.1755 73.3612 99.1467 95.6904 112.295 114.136C125.443 132.581 145.79 140.157 157.74 131.058Z" stroke="url(#paint26_linear_111_1096)" strokeWidth="1.23413" strokeMiterlimit="10"/>
    <path d="M53.1736 13.5781L99.7608 43.8567" stroke="url(#paint27_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M44.1275 29.2577L53.1735 19.5254L64.4811 27.6357L56.792 38.9902L43.2229 29.2577" stroke="url(#paint28_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M66.115 6.00195L59.0535 13.7649L71.2656 21.3345L77.8393 13.7649L66.115 6.00195Z" stroke="url(#paint29_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M81.5417 15.2578L74.4319 22.9564L85.7394 30.526L92.0718 20.2529L81.5417 15.2578Z" stroke="url(#paint30_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M60.8628 41.1533L68.0997 29.7988L79.4073 37.3685L73.0751 49.2636L60.8628 41.1533Z" stroke="url(#paint31_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M95.7342 20.8115L89.358 32.6888L98.8564 38.6365L111.778 23.8968L95.7342 20.8115Z" stroke="url(#paint32_linear_111_1096)" strokeWidth="1.23413"/>
    <path d="M83.478 40.0723L76.6934 51.4267L84.8349 56.8337L92.0717 46.0199L83.478 40.0723Z" stroke="url(#paint33_linear_111_1096)" strokeWidth="1.23413"/>
    <defs>
        <linearGradient id="paint0_linear_111_1096" x1="-2.30455" y1="170.116" x2="35.3502" y2="143.513" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_111_1096" x1="10.5676" y1="175.219" x2="47.67" y2="173.531" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint2_linear_111_1096" x1="59.1084" y1="142.157" x2="92.0034" y2="140.376" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint3_linear_111_1096" x1="67.0637" y1="135.101" x2="100.399" y2="133.295" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint4_linear_111_1096" x1="92.4897" y1="128.34" x2="121.657" y2="125.916" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint5_linear_111_1096" x1="78.0531" y1="118.023" x2="106.348" y2="97.7701" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint6_linear_111_1096" x1="107.011" y1="91.1575" x2="159.231" y2="82.517" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint7_linear_111_1096" x1="51.3989" y1="140.076" x2="73.0194" y2="124.462" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint8_linear_111_1096" x1="53.4762" y1="140.124" x2="112.975" y2="150.91" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint9_linear_111_1096" x1="43.7889" y1="147.462" x2="72.0949" y2="152.588" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint10_linear_111_1096" x1="26.3343" y1="139.809" x2="57.9655" y2="145.543" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint11_linear_111_1096" x1="82.5644" y1="173.419" x2="39.9569" y2="176.426" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint12_linear_111_1096" x1="51.0476" y1="49.6797" x2="217.366" y2="63.7415" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint13_linear_111_1096" x1="23.5421" y1="1.68262" x2="149.609" y2="14.4221" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint14_linear_111_1096" x1="95.7347" y1="46.526" x2="125.412" y2="51.9004" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint15_linear_111_1096" x1="104.823" y1="75.5996" x2="147.621" y2="79.7972" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint16_linear_111_1096" x1="127.212" y1="102.611" x2="155.794" y2="107.782" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint17_linear_111_1096" x1="146.579" y1="63.6514" x2="160.66" y2="64.3342" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint18_linear_111_1096" x1="130.36" y1="97.6182" x2="150.09" y2="99.1219" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint19_linear_111_1096" x1="159.683" y1="75.5996" x2="167.115" y2="76.0739" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint20_linear_111_1096" x1="165.819" y1="96.332" x2="177.535" y2="97.2086" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint21_linear_111_1096" x1="170.32" y1="109.871" x2="176.204" y2="110.151" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint22_linear_111_1096" x1="164.733" y1="85.9561" x2="172.47" y2="86.4683" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint23_linear_111_1096" x1="137.134" y1="60.8027" x2="142.421" y2="61.1547" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint24_linear_111_1096" x1="166.318" y1="57.2559" x2="172.188" y2="57.645" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint25_linear_111_1096" x1="117.55" y1="92.907" x2="160.554" y2="61.4121" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint26_linear_111_1096" x1="104.31" y1="113.891" x2="149.079" y2="81.3495" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" stopColor="#4972B6"/>
            <stop offset="1" stopColor="#AADBF3"/>
        </linearGradient>
        <linearGradient id="paint27_linear_111_1096" x1="47.3728" y1="13.5781" x2="115.087" y2="20.7851" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint28_linear_111_1096" x1="40.5759" y1="19.5254" x2="71.6472" y2="21.8727" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint29_linear_111_1096" x1="56.6881" y1="3.49182" x2="84.4618" y2="5.53731" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint30_linear_111_1096" x1="72.2355" y1="13.224" x2="98.0369" y2="15.0436" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint31_linear_111_1096" x1="58.4412" y1="29.7988" x2="86.8945" y2="31.7654" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint32_linear_111_1096" x1="86.582" y1="22.4157" x2="119.061" y2="25.5039" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
        <linearGradient id="paint33_linear_111_1096" x1="74.6852" y1="42.3122" x2="98.231" y2="44.2668" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1877FF"/>
            <stop offset="0.697647" stopColor="#05D0F3"/>
            <stop offset="1" stopColor="#98E2FF"/>
        </linearGradient>
    </defs>
</>;