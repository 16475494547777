export const constants = {
    BUG_REPORT_MODAL: {
        TITLE: 'FEEDBACK',
        NAME: {
            LABEL: 'Name',
            PLACEHOLDER: 'Enter your name',
        },
        EMAIL: {
            LABEL: 'Email',
            PLACEHOLDER: 'Enter your email',
        },
        DESCRIPTION: {
            LABEL: 'Your feedback or bug report',
            PLACEHOLDER: 'Enter description',
        },
        FILE: {
            TITLE: 'Attach a screenshot'
        },
        SUBMIT: {
            TITLE: 'Submit'
        }
    },
    REPORT_SUCCSESS: {
        TITLE: 'FEEDBACK',
        THANK_YOU_MESSAGE: "Thank you for reporting bug.", 
        THANK_YOU_DESCRIPTION: "It’s very helpful for us",
        CLOSE_BTN: 'Close the window',
        REPEAT_BTN: 'Report another bug'
    },
    REPORT_ERROR: {
        TITLE: 'FEEDBACK',
        MESSAGE: "Something went wrong. Please try again", 
        CONTACT_MESSAGE: "or contact us at ",
        MAIL: 'help@monoceros.com',
        REPEAT_BTN: 'Try again'
    }
};
