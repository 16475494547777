import React from "react";
import { Button } from "react-bootstrap";
import { careersEffects } from "./actions/careersEffects";
import { CareersState } from "../../../../models/careers-model";
import "./styles.scss";
import { constants } from "./constants";
import { ApplyModal } from "./templates/applyModal";

const {
  HEADER,
  TITLE,
  POSITIONS: { list },
  APPLY_NOW,
  GOOD_TO_HAVE,
} = constants;

const CareersLayout = () => {
  const show = CareersState.useStoreState((state) => state.isModalOpen);
  const actions = CareersState.useStoreActions((actions) => actions);

  const handleOpen = () =>
    actions[careersEffects.actionTypes.CAREERS_MODAL_APPLY_OPENED](true);

  return (
    <div className="careers-page font-inter rc-anchor-normal-footer ">
      <div className="header">{HEADER}</div>
      <div className="title">{TITLE}</div>
      <div className="positions-list">
        {list.map((position, index) => (
          <div key={index} className="position-info">
            <div className="position-info-header">
              <div className="outer-borders">
                <div className="inside">
                  <div className="d-flex align-items-center">
                    <img
                      className="img-fluid"
                      src="../dashboard-library/home-page-images/file-code.svg"
                      alt="File"
                    />

                    <p>{position.positionName}</p>
                  </div>
                  <Button
                    type="button"
                    size="sm"
                    variant="primary"
                    className="font-size-14 font-weight-medium btn-custom"
                    onClick={handleOpen}
                  >
                    {APPLY_NOW}
                  </Button>
                </div>
              </div>
            </div>

            <div className="position-info-content">
              <p className="titles">{position.requirements}</p>
              <div className="description">
                {position.descriptions.map((description, i) => (
                  <p key={`description-${i}`}>{description}</p>
                ))}
                {position.goodToHave.length > 0 && (
                  <>
                    <p className="titles ps-0">{GOOD_TO_HAVE}:</p>
                    {position.goodToHave.map((goodToHave, i) => (
                      <p key={`good-to-have${i}`}>{goodToHave}</p>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
        <ApplyModal show={show} />
      </div>
    </div>
  );
};

export const CareersPage = () => {
  return (
    <CareersState.Provider>
      <CareersLayout />
    </CareersState.Provider>
  );
};
