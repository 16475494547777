import React, { useRef } from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import CreatableSelect from 'react-select/creatable';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { isArray } from 'lodash';
import { constants } from '../constants';
import { Editor } from '@tinymce/tinymce-react';
import { colorStyles, codesample_languages, schema } from '../helper';

const {
  ADMIN_ARTICLES: {
    newArticle: { title, excerpt, category, type, tag, content, time, submit },
  },
} = constants;

const NewAndUpdateArticle = () => {
  const state = AcademyState.useStoreState((state) => state);
  const convertToBase64 = AcademyState.useStoreActions((actions) => actions.CONVERT_TO_BASE64);
  const getCurrentData = AcademyState.useStoreActions((actions) => actions.CURRENT_DATA);
  const createArticle = AcademyState.useStoreActions((actions) => actions.CREATE_ARTICLE);
  const updateArticle = AcademyState.useStoreActions((actions) => actions.UPDATE_ARTICLE);
  const editArticle = AcademyState.useStoreState((state) => state.editArticle);
  let categories = AcademyState.useStoreState((state) => state.categories.list);
  const mode = AcademyState.useStoreState((state) => state.admin.mode);

  const { tags, types, times } = state.filters;
  categories = categories.slice(1, categories.length);

  const hiddenFileInput = useRef();
  const editorRef = useRef(null);

  let initialValues = {
    articleId: editArticle ? editArticle.articleId : null,
    title: editArticle ? editArticle.title : '',
    excerpt: editArticle ? editArticle.excerpt : '',
    category: editArticle ? editArticle.category : '',
    type: editArticle ? editArticle.type : '',
    tags: editArticle ? editArticle.tags : [],
    content: editArticle ? editArticle.content : '',
    time: editArticle ? editArticle.time : '',
    image: editArticle ? editArticle.img : '',
  };

  const onChangeTags = (tags) => {
    let insertTags = [];

    tags.forEach((tag) => {
      insertTags.push(tag.value);
    });

    return insertTags;
  };

  const onChangeImage = async (image) => {
    let file = image.target.files[0];

    let convertImage = await convertToBase64(file);

    return convertImage;
  };

  const setDefaultValues = (arrayOptions, optionValue) => {
    let findOption;

    if (isArray(optionValue)) {
      let editTagOptions = tags.filter((tag) => {
        return optionValue.some((option) => {
          if (tag.value === option) {
            return tag;
          }
        });
      });

      findOption = editTagOptions;
    } else {
      findOption = arrayOptions.find((option) => option.value === optionValue);
    }

    return findOption;
  };

  const filePickerCallback = (cb, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.addEventListener('change', (e) => {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const id = 'blobid' + new Date().getTime();
        const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
        const base64 = reader.result.split(',')[1];

        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        cb(blobInfo.blobUri(), { title: file.name });
      });
      reader.readAsDataURL(file);
    });

    input.click();
  };

  const onSubmit = (values) => {
    let currentData = getCurrentData();

    let data = { ...values, date: currentData };
    // createFilter(data);

    if (mode === 'create') {
      createArticle(data);
    } else {
      updateArticle(data);
    }
  };

  return (
    <div className="new-article">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="mb-4">
              <label htmlFor="title">{title.label}</label>
              <Field
                type="text"
                name="title"
                placeholder={`${title.placeholder}`}
                className="mb-1 p-2"
              />
              <ErrorMessage component="span" name="title" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="excerpt">{excerpt.label}</label>
              <Field
                name="excerpt"
                as="textarea"
                placeholder={`${excerpt.placeholder}`}
                className="excerpt mb-1 p-2"
              />
              <ErrorMessage component="span" name="excerpt" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="category">{category.label}</label>
              <CreatableSelect
                isClearable
                name="category"
                options={categories}
                defaultValue={() =>
                  editArticle && setDefaultValues(categories, editArticle.category)
                }
                onChange={(val) => val !== null && setFieldValue('category', val.value)}
                styles={colorStyles}
                className="mb-1 select"
                classNamePrefix="select"
              />
              <ErrorMessage component="span" name="category" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="type">{type.label}</label>
              <CreatableSelect
                isClearable
                name="type"
                options={types}
                defaultValue={() => editArticle && setDefaultValues(types, editArticle.type)}
                onChange={(val) => val !== null && setFieldValue('type', val.value)}
                styles={colorStyles}
                className="mb-1 select"
                classNamePrefix="select"
              />
              <ErrorMessage component="span" name="type" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="tags">{tag.label}</label>
              <CreatableSelect
                isMulti
                options={tags}
                defaultValue={() => editArticle && setDefaultValues(tags, editArticle.tags)}
                onChange={(val) => val !== null && setFieldValue('tags', onChangeTags(val))}
                styles={colorStyles}
                className="mb-1 select"
                classNamePrefix="select"
              />
              <ErrorMessage component="span" name="tags" className="error" />
            </div>
            <div className="mb-5 pb-3">
              <label htmlFor="content">{content.label}</label>
              <Editor
                apiKey={`0l9ca7pyz0qyliy0v9mmkfl2cz69uodvc8l6md8o4cnf6rnc`}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={editArticle && editArticle.content}
                init={{
                  height: '500',
                  plugins: 'image code preview media fullscreen codesample lists',
                  toolbar:
                    'undo redo | link image media fullscreen | code codesample | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lineheight | preview | fontsizeinput',
                  content_style:
                    ' body {line-height: 1.5; background-color: #060f19; color: white}',
                  image_title: true,
                  images_upload_credentials: true,
                  // images_file_types: 'jpg,svg,png,webp',
                  automatic_uploads: true,
                  file_picker_types: 'image',
                  media_live_embeds: true,

                  file_picker_callback: filePickerCallback,
                  codesample_languages: codesample_languages,
                }}
                onEditorChange={(newValue, editor) => {
                  setFieldValue('content', editor.getContent());
                }}
              />
              <ErrorMessage component="span" name="content" className="error" />
            </div>
            <div className="mb-4">
              <label htmlFor="time">{time.label}</label>
              <CreatableSelect
                isClearable
                name="time"
                options={times}
                defaultValue={() => editArticle && setDefaultValues(times, editArticle.time)}
                onChange={(val) => val !== null && setFieldValue('time', val.value)}
                styles={colorStyles}
                className="select"
                classNamePrefix="select"
              />
              <ErrorMessage component="span" name="time" className="error mb-2" />
            </div>
            <div className="my-4">
              <div>
                <label>{editArticle ? 'Edit article image' : 'Choose image for upload'}</label>
                {editArticle && (
                  <img
                    src={editArticle.image}
                    alt={editArticle.title}
                    style={{ height: '50px', marginBottom: '10px' }}
                  />
                )}
              </div>
              <div>
                <div
                  onClick={() => hiddenFileInput.current.click()}
                  htmlFor="image"
                  className="btn btn-outline-light"
                >
                  Browse
                </div>
                <input
                  ref={hiddenFileInput}
                  onChange={(val) => onChangeImage(val).then((img) => setFieldValue('image', img))}
                  type="file"
                  id="image"
                  name="img"
                  className="mb-1 d-none"
                />
                <ErrorMessage component="span" name="image" className="error" />
              </div>
            </div>
            <div>
              <button type="submit" className="w-100 btn btn-success">
                {submit}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewAndUpdateArticle;
