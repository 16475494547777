import { action, thunk } from 'easy-peasy';
import {constants} from '../constants';
import {dataHelper} from './dataHelpers/dataHelper';
import { notifyEffects } from '../../../../../components/utility/notifications/notifyEffects';
const utils = require('@walletconnect/utils');
import { fetchEffects } from './fetchEffects';


const { NETWORKS, CHANGE_RATE } = constants;

const actionTypes = {
    TRUST_SIGN_IN_INVOKED: 'TRUST_SIGN_IN#INVOKED',
    BUY_SIGN_IN_SUCCEED: 'BUY_SIGN_IN#SUCCEED',
    BUY_STATE_UPDATED: 'BUY_STATE_UPDATED',
    TRUST_ENABLE_ACTION_INVOKED: 'TRUST_ENABLE_ACTION#INVOKED',
    TRUST_UI_ACTIONS_INVOKED: 'TRUST_UI_ACTIONS#INVOKED',
    TRUST_BUY_INVOKED: 'TRUST_BUY#INVOKED',
    TRUST_APPROVE_ACTION_INVOKED: 'TRUST_APPROVE_ACTION#INVOKED'
};

const stateUpdate = (state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach(k => state.buy[k] = payload[k]);
};

const uiHandle = (state, payload) => {
    const { name, value } = payload;
    switch(name) {
    case 'buying':
        state.buy.buying = value ? parseFloat(value) : '';
        state.buy.selling = value ? value / CHANGE_RATE : '';
        break;
    case 'selling':
        state.buy.selling = value ? parseFloat(value) : '';
        state.buy.buying = value ? value * CHANGE_RATE : '';
        break;
    case 'buy-action':
        state.buy.showModal = false;
        break;
    case 'close-modal':
        state.buy.showModal = false;
        break;
    case 'open-modal':
        state.buy.showModal = true;
        break;
    case 'modal-opened':
        state.buy.selling = '';
        state.buy.buying = '';
        break;
    default:
        return;
    }
};

const signInInvoke = async (actions, payload) => {
    const { connector } = payload;
    if (!connector.connected) {
        await connector.connect();
        await actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({ address: connector.accounts[0].toLowerCase() });
    }
    else {
        const address = connector.accounts[0].toLowerCase();
        actions[actionTypes.BUY_STATE_UPDATED]({account: address});
        const params = [
            utils.convertUtf8ToHex('Connected from Monoceros'),
            address
        ];

        actions[actionTypes.BUY_STATE_UPDATED]({ loader: true });
        
        connector.signPersonalMessage(params).then((result) => {
            actions[actionTypes.BUY_STATE_UPDATED]({ loader: false });
            const chain = NETWORKS.find(net => net.data.chainId === connector.chainId).id;
            actions[actionTypes.BUY_STATE_UPDATED]({ isWalletLogged: true, walletName: 'trustwallet', network: chain, address });
            actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({ address });
        })
            .catch((err)=>{
                actions[actionTypes.BUY_STATE_UPDATED]({ loader: false });
                console.log(err);
                return;
            });
    }

    connector.on('connect', (error, payload) => {
        if (error) {
            throw error;
        }

        actions[fetchEffects.actionTypes.BUY_APPROVE_FETCHED]({ address: connector.accounts[0].toLowerCase() });
        connector.createSession();
    });

    connector.on('session_update', (error, payload) => {
        if (error) {
            throw error;
        }
    });

    connector.on('disconnect', (error, payload) => {
        if (error) {
            throw error;
        }

        connector.killSession();
        actions[actionTypes.BUY_STATE_UPDATED]({ loader: false, isWalletLogged: false, walletName: '', network: '' });
    });
};

const approveTrustHandle = async (actions, payload) => {
    const { connector, data, state } = payload;
    const approveParameters = dataHelper.getTrustApproveParameters(data);
    const chainName = NETWORKS.find(net => net.data.chainId === connector.chainId).symbol;
    const { isApprove } = state.approved.find(chain => chain.chain === chainName);

    if(isApprove) {
        actions[actionTypes.BUY_STATE_UPDATED]({loader: true});

        connector
            .sendTransaction(approveParameters)
            .then((result) => {
                actions[actionTypes.BUY_STATE_UPDATED]({transactionData: data, loader: false});
                actions[actionTypes.TRUST_UI_ACTIONS_INVOKED]({name: 'open-modal'});
            })
            .catch((error) => {
                actions[actionTypes.BUY_STATE_UPDATED]({loader: false});
                const notification = { type: 'danger', text: error.message || 'Error!' };
                notifyEffects.notifyAction(notification);
            });
    }
    else {
        actions[actionTypes.BUY_STATE_UPDATED]({transactionData: data});
        actions[actionTypes.TRUST_UI_ACTIONS_INVOKED]({name: 'open-modal'});
    }
    
};

const enableHandle = async (actions, payload) => {
    actions[actionTypes.TRUST_APPROVE_ACTION_INVOKED](payload);
};

const buyHandle = async (action, payload) => {
    const transactionParameters = dataHelper.getTrustTransactionParameters(payload.state);

    payload.connector
        .sendTransaction(transactionParameters)
        .then((result) => {
            console.info(result);
            const notification = { type: 'success', text: 'Success' };
            notifyEffects.notifyAction(notification);
        })
        .catch((error) => {
            console.info(error);
            const notification = { type: 'danger', text: error.message || 'Error!' };
            notifyEffects.notifyAction(notification);
        });
};

const actionHandlers = {
    [actionTypes.TRUST_SIGN_IN_INVOKED]: thunk(async (action, payload) => signInInvoke(action, payload)),
    [actionTypes.TRUST_ENABLE_ACTION_INVOKED]: thunk(async (action, payload) => enableHandle(action, payload)),
    [actionTypes.TRUST_BUY_INVOKED]: thunk(async (action, payload) => buyHandle(action, payload)),
    [actionTypes.TRUST_APPROVE_ACTION_INVOKED]: thunk(async (action, payload) => approveTrustHandle(action, payload)),
    [actionTypes.TRUST_UI_ACTIONS_INVOKED]: action((state, payload) => uiHandle(state, payload)),
    [actionTypes.BUY_STATE_UPDATED]: action((state, payload) => stateUpdate(state, payload)),
};

export const trustConnectEffects = {
    actionHandlers,
    actionTypes
};