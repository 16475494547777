import * as d3 from 'd3';
import { constants } from '../../../../constants/constants';
import { isEmpty } from 'lodash';
import { dataHelper } from './dataHelpers/dataHelper';

const { BAR_CHART } = constants;

const drawChart = (ref, data) => {

    if (!ref.current)
        return;

    const margin = { top: 10, right: 19, bottom: 80, left: 50 };
    const svg = d3.select(ref.current).html('').append('svg');
    const gap = 5;
    const valueMinHeight = 5;

    let { width, height } = ref.current.getBoundingClientRect();
    width = width - margin.left - margin.right;
    height = height - margin.top - margin.bottom;

    const getChartRange = (data, subCategories) => {
        const maxForSubcategories = d3.max(data, d => d3.max(subCategories, key => d[key]));
        const minForSubcategories = d3.min(data, d => d3.min(subCategories, key => d[key]));
        return { minimum: Math.min(-maxForSubcategories, minForSubcategories), maximum: Math.max(maxForSubcategories, -minForSubcategories) };
    };

    const format = d3.format('~s');

    const colorArray = isEmpty(data) ? ['transparent', 'transparent'] : ['#F7931A', '#50AF95'];

    const color = d3.scaleOrdinal().range(colorArray);

    data = isEmpty(data) ? 	dataHelper.createEmptyDataSet() : data;

    const subCategories = Object.keys(data[0]).slice(1);
    const { maximum, minimum } = getChartRange(data, subCategories);

    const y = d3.scaleLinear()
        .domain([minimum, maximum])
        .range([height, 0]);

    const x0 = d3.scaleBand() // domain defined below
        .rangeRound([0, width])
        .paddingInner(0.1)
        .paddingOuter(0.1);

    const x1 = d3.scaleBand() // domain and range defined below
        .rangeRound([0, width])
        .paddingInner(0.1)
        .paddingOuter(0.1);

    x0.domain(data.map(d => d.category));
    x1.domain(subCategories).range([0, 12]);

    const yAxis = d3
        .axisLeft(y)
        .ticks(8)
        .tickFormat(d => d);

    const calculateHeight = d => {
        const currentHeight = Math.abs(y(d.value) - y(0)) - gap;
        // Take gap into consideration when calculating height and set height to minimum value
        // If currentHeight is less than gap rect is not visible
        return currentHeight + gap > 0 ? currentHeight < 5 ? valueMinHeight : currentHeight : 0;
    };

    const chart = svg
        .attr('class', 'bar-chart')
        .append('g')
        .attr('transform', `translate(${margin.left} , ${margin.top})`);

    const selection = chart
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', d => `translate(${x0(d.category) + 5}, 0)`);

    selection
        .selectAll('rect')
        .data(d => subCategories.map(key => ({ key: key, value: d[key] || 0 })))
        .enter()
        .append('rect')
        .attr('x', d => x1(d.key) + (width - margin.left - margin.right)/data.length/2 - 2 * gap)
        // If the height is less than gap, put the top left corner of the rect bar on the zero line
        // Take gap into consideration when calculating y position
        .attr('y', (d) => calculateHeight(d) <= gap ? y(0) - valueMinHeight - gap : y(d.value))
        .attr('width', 4)
        .attr('height', d => calculateHeight(d))
        .attr('fill', d => color(d.key))
        .attr('rx', 4)
        .attr('ry', 4);

    selection
        .selectAll('rect')
        .on('mouseover', () => tooltip.style('opacity', 1))
        .on('mouseout', () => tooltip.style('opacity', 0))
        .on('mousemove', (e, d) => {
            const [x, y] = d3.pointer(e);
            const transformedX = d3.select(e.target.parentNode).node().transform.baseVal[0].matrix.e;
            tooltip
                .style('opacity', 1)
                .attr('transform', `translate(${transformedX + x + margin.right + margin.left}, ${y + margin.top - 2 * gap})`)
                .select('text').text(`${d.key}: ${d.value}`);
        });

    chart
        .append('g')
        .attr('class', 'x-tick')
        .attr('transform', `translate(3, ${height + 2 * gap})`)
        .call(d3.axisBottom(x0))
        .selectAll('.tick text');

    chart
        .append('g')
        .attr('class', 'y-tick')
        .call(yAxis);

    chart
        .selectAll('g.y-tick .tick')
        .attr('class', d => !d ? 'hidden-tick tick' : 'tick')
        .append('line')
        .attr('class', d => !d ? '' : 'dash-line')
        .attr('x1', 2 * gap)
        .attr('x2', width - gap)
        .attr('stroke', d => !d ? 'transparent' : '#C4C8D0')
        .attr('stroke-dasharray', '8');

    chart
        .selectAll('g.y-tick .tick')
        .append('circle')
        .attr('cx', 2 * gap)
        .attr('r', 2.5)
        .attr('fill', '#FFFFFF')
        .attr('stroke', '#C4C8D0');

    chart
        .selectAll('g.y-tick .tick')
        .append('circle')
        .attr('cx', width)
        .attr('r', 2.5)
        .attr('fill', '#FFFFFF');

    chart
        .append('g')
        .attr('class', 'zero-line')
        .append('line')
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('x1', 10)
        .attr('x2', width);

    chart
        .selectAll('g.zero-line')
        .append('circle')
        .attr('class', 'tick-circle')
        .attr('cx', 2 * gap)
        .attr('cy', y(0))
        .attr('r', 4.5)
        .attr('fill', '#FFFFFF');

    chart
        .selectAll('g.zero-line')
        .append('circle')
        .attr('class', 'tick-circle')
        .attr('cx', width)
        .attr('cy', y(0))
        .attr('r', 4.5)
        .attr('fill', '#FFFFFF');

    const footer = chart
        .append('g')
        .attr('class', 'footer');

    footer
        .append('line')
        .attr('class', 'footer-line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', height + 40)
        .attr('y2', height + 40)
        .attr('stroke', '#707991');

    const legend = footer
        .append('g')
        .attr('class', 'legend');

    legend
        .append('line')
        .attr('x1', 0)
        .attr('x2', 50)
        .attr('y1', height + 60)
        .attr('y2', height + 60)
        .attr('stroke', '#F7931A')
        .attr('stroke-width', 2);

    legend
        .append('text')
        .attr('class', 'legend-text')
        .attr('x', 60)
        .attr('y', height + 63)
        .attr('fill', '#2B1A3D')
        .attr('opacity', 0.8)
        .attr('font-size', '12px')
        .attr('font-weight', 400)
        .text(BAR_CHART.FOOTER_LEGEND[0]);

    legend
        .append('line')
        .attr('x1', 150)
        .attr('x2', 200)
        .attr('y1', height + 60)
        .attr('y2', height + 60)
        .attr('stroke', '#50AF95')
        .attr('stroke-width', 2);

    legend
        .append('text')
        .attr('class', 'legend-text')
        .attr('x', 210)
        .attr('y', height + 63)
        .attr('fill', '#2B1A3D')
        .attr('opacity', 0.8)
        .attr('font-size', '12px')
        .attr('font-weight', 400)
        .text(BAR_CHART.FOOTER_LEGEND[1]);

    const tooltip = svg
        .append('g')
        .attr('class', 'tooltip-container')
        .style('opacity', 0);

    tooltip
        .append('text')
        .attr('class', 'tooltip-value')
        .style('text-anchor', 'middle')
        .attr('font-size', '10px')
        .attr('font-weight', 'bold');
};

export const chartHelper = {
    drawChart
};
