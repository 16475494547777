import React from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import { constants } from '../constants';

const {
  ARTICLES: {
    filterList: { titles, buttons },
  },
} = constants;

const FilterList = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);

  const { tags, types, times } = state.filters;

  return (
    <div className="filter-list">
      <main>
        <h2>{titles.filters}</h2>

        <div className="tags">
          <h3>{titles.tag}</h3>

          <ul>
            {tags.map((tag, index) => (
              <li
                key={index}
                onClick={(e) => actions.SELECT_TAG(tag.label)}
                className={`tag ${tag.checked ? 'select-tag' : 'unselect-tag'}`}
              >
                {tag.value}
              </li>
            ))}
          </ul>
        </div>

        <div className="type">
          <h3>{titles.type}</h3>

          <div className="type-elements">
            {types.map((type, index) => (
              <div key={index}>
                <input
                  onChange={(e) =>
                    actions.SELECT_TYPE({ value: type.value, checked: e.target.checked })
                  }
                  type="checkbox"
                  value={type.checked}
                  checked={type.checked}
                />
                <div className="signal" style={{ backgroundColor: type.color }}></div>
                <span>{type.label}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="time">
          <h3>{titles.timeToRead}</h3>

          <div className="change-time">
            {times.map((time, index) => (
              <div key={index}>
                <input
                  onChange={(e) =>
                    actions.SELECT_TIME({ value: time.value, checked: e.target.checked })
                  }
                  type="checkbox"
                  value={time.checked}
                  checked={time.checked}
                />
                <span>{time.label}</span>
              </div>
            ))}
          </div>
        </div>
      </main>
      <footer>
        <div onClick={actions.FILTER_CLEAR_ALL}>{buttons.clearAll}</div>
        <div onClick={() => actions.FILTER_APPLY(actions)}>{buttons.apply}</div>
      </footer>
    </div>
  );
};

export default FilterList;
