import React from 'react';

export const LEFT_TOP_CHART = <><path d="M0 182H85.1718L125.165 172.95L161.522 162.09L206.969 182L234.237 113.22H252.416H292.409L323.313
 129.51L361.488 84.26L377.849 24.53H426.931L466.924 48.06L477.832 33.58L496.01 10.05L530.55 1H556" stroke="url(#paint0_linear_107_2223)" strokeWidth="2"/>
<path d="M160.5 167C162.433 167 164 165.657 164 164C164 162.343 162.433 161 160.5 161C158.567 161 157 162.343 157 164C157 165.657 158.567 167 160.5 167Z" fill="url(#paint1_radial_107_2223)"/>
<path d="M291 117C292.657 117 294 115.657 294 114C294 112.343 292.657 111 291 111C289.343 111 288 112.343 288 114C288 115.657 289.343 117 291 117Z" fill="url(#paint2_radial_107_2223)"/>
<path d="M323 132C324.657 132 326 130.657 326 129C326 127.343 324.657 126 323 126C321.343 126 320 127.343 320 129C320 130.657 321.343 132 323 132Z" fill="url(#paint3_radial_107_2223)"/>
<path d="M87 188C90.3137 188 93 185.314 93 182C93 178.686 90.3137 176 87 176C83.6863 176 81 178.686 81 182C81 185.314 83.6863 188 87 188Z" fill="url(#paint4_radial_107_2223)"/>
<path d="M207 188C210.314 188 213 185.314 213 182C213 178.686 210.314 176 207 176C203.686 176 201 178.686 201 182C201 185.314 203.686 188 207 188Z" fill="url(#paint5_radial_107_2223)"/>
<path d="M235 120C238.314 120 241 117.314 241 114C241 110.686 238.314 108 235 108C231.686 108 229 110.686 229 114C229 117.314 231.686 120 235 120Z" fill="url(#paint6_radial_107_2223)"/>
<path d="M376.5 32C380.642 32 384 28.4183 384 24C384 19.5817 380.642 16 376.5 16C372.358 16 369 19.5817 369 24C369 28.4183 372.358 32 376.5 32Z" fill="url(#paint7_radial_107_2223)"/>
<path d="M468 57C472.418 57 476 53.6421 476 49.5C476 45.3579 472.418 42 468 42C463.582 42 460 45.3579 460 49.5C460 53.6421 463.582 57 468 57Z" fill="url(#paint8_radial_107_2223)"/>
<defs>
<linearGradient id="paint0_linear_107_2223" x1="72.2028" y1="-27.3495" x2="719.97" y2="119.456" gradientUnits="userSpaceOnUse">
<stop stopColor="#1876FF"/>
<stop offset="0.286458" stopColor="#0BAFFF"/>
<stop offset="0.604167" stopColor="#05CDF9"/>
<stop offset="0.942708" stopColor="#01E9BB"/>
</linearGradient>
<radialGradient id="paint1_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.5 164) rotate(90) scale(3 3.5)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint2_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(291 114) rotate(90) scale(3)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(323 129) rotate(90) scale(3)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint4_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(87 182) rotate(90) scale(6)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint5_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(207 182) rotate(90) scale(6)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint6_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(235 114) rotate(90) scale(6)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint7_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(376.5 24) rotate(90) scale(8 7.5)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
<radialGradient id="paint8_radial_107_2223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(468 49.5) rotate(90) scale(7.5 8)">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</radialGradient>
</defs>
</>;