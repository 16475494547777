export const constants = {
    HEADER: 'THE TEAM',
    TEAM: {
        departments: ['ETHERNAL', 'MARKETING DEPARTMENT', 'HR DEPARTMENT', 'FINANCE DEPARTMENT', 'ANALYTICS DEPARTMENT', 'TECHNOLOGY DEPARTMENT'],
        list: [
            {
                departmentName: 'ETHERNAL',
                members: [
                    {
                        img: 'tony',
                        name: 'TONY',
                        nickname: 'STARGAZER',
                        description: 'Never regret, never forget, never give up.',
                        position: 'Founder & CEO',
                    },
                    {
                        img: 'igor',
                        name: 'IGOR',
                        nickname: 'CHIEF ROCKSTAR',
                        description: `Never give up! Never think that what they have is better! Believe in yourself! Believe in your dreams!
                         Always push it forward to success! We are the best crypto project in this category !
                        This is my Dream and that will happen!!!`,
                        position: 'Chief Operating Officer (COO)',
                    },
                    {
                        img: 'michael',
                        name: 'MICHAEL',
                        nickname: 'LORD OF ALL THINGS TECHNICAL',
                        description: `The opportunity to demonstrate experience and potential as a technology department leader in cutting-edge development projects.
                        It is the first and only CeFi-DeFi aggregator worldwide with a bot trading platform, aiming to become the world's leader in the crypto industry.`,
                        position: 'CTO, Senior full-stack developer',
                    },
                 
                    {
                        img: 'srdjan',
                        name: 'SRDJAN',
                        nickname: 'TECH-MASTERMIND',
                        description: `I like to make sure everything runs smoothly and securely, manage the development team, and implement new technology.`,
                        position: 'Tech Supervisor',
                    },
                   
                    {
                        img: 'katrin',
                        name: 'KATRIN',
                        nickname: 'MARKETING WIZARD',
                        description: `Every marketer is always a bit of a wizard,
                         in our project we make the magic of the future, which is coming today.`,
                        position: 'Chief Marketing Officer (CMO)'
                    },
                    {
                        img: 'Igor2',
                        name: 'IGOR',
                        nickname: 'CHART MASTER',
                        description: 'Everything that has been, will be. Everything that will be, has already been.',
                        position: 'Chief Business Development Officer (CBDO)'
                    },
              
                ],
            },
            {
                departmentName: 'TECHNOLOGY DEPARTMENT',
                members: [
                 
                    {
                        img: 'uri',
                        name: 'URI',
                        nickname: 'COMPUTATIONAL WIZARD',
                        description: `Provide technical infrastructure for building our project at large scale, compose teams that can cooperate at that scale.
                        Find solutions to a challenging problems that will make the world a better place.`,
                        position: 'Tech-Lead, Senior full-stack developer',
                    },
                    {
                        img: 'pavlo',
                        name: 'PAVLO',
                        nickname: 'FULL STACK MAGICIAN',
                        description: `It's really cool to build the future by participating in the development of progressive DeFi &
                         CeFi solutions and apply my experience and knowledge in web3 multi-chain technologies.
                         For me  interesting and important to learn new things, to be flexible and responsible in solving any complex tasks.`,
                        position: 'Senior Full-stack developer',
                    },
                    {
                        img: 'sergey',
                        name: 'SERGEY',
                        nickname: 'DESIGN OVERLORD',
                        description: `I'm looking for opportunities to improve UX and UI for crypto world and suppose the current project has all chances to be the
                         leader in this area.`,
                        position: 'UX/UI Designer',
                    },
                    {
                        img: 'roman',
                        name: 'ROMAN',
                        nickname: 'EMPEROR OF BLOCKCHAIN',
                        description: `I believe blockchain is the future of finance.
                         By working in this project, I can contribute to the development of this area.`,
                        position: 'Solidity & Back-End developer',
                    },
                    {
                        img: 'ed',
                        name: 'ED',
                        nickname: 'KID OF CODE',
                        description: `Monoceros project became my first professional experience in Front-End development.
                         Since I've got into the business, it helps me to become a better developer every day.
                         Monoceros project grows me as a professional. I am proud to be a part of this awesome project and I will never forget this adventure.`,
                        position: 'Junior Front-end developer',
                    },
        
                    {
                        img: 'radovan',
                        name: 'RADOVAN',
                        nickname: 'WEB3 ENTHUSIAST',
                        description: `I am dedicated to the future of finance through blockchain technology, and see Monoceros as a great opportunity to contribute to its development and growth.`,
                        position: 'Front-end & Solidity developer',
                    },
                    {
                        img: 'gojic',
                        name: 'MILOS',
                        nickname: 'BLOCKCHAIN ADVENTURER',
                        description: `With the belief that there is nothing I can't accomplish with enough hard work, I strive to achieve my goals and overcome any obstacles that stand in my way. My passion lies blockchain technology, and I am determined to excel in these fields.`,
                        position: 'Front-end & Solidity developer',
                    },
                    {
                        img: 'zoran',
                        name: 'ZORAN',
                        nickname: 'BACKEND MASTER',
                        description: `I am hard working and passionate about backend development. Hope to grow in blockchain field by working on Monoceros and also help make it better.`,
                        position: 'Back-end developer',
                    },
                    {
                        img: 'markovic',
                        name: 'MILOS',
                        nickname: 'WOLF OF WEB DEV',
                        description: `My career goal is to improve myself as a web developer by constantly working and learning. I am sure that working on Monoceros will help me achieve my goal.`,
                        position: 'Front-end developer',
                    },
                    {
                        img: 'miljko',
                        name: 'MILJKO',
                        nickname: 'WEB MASTER',
                        description: `It is incredibly satisfying to do something I love, combining my passion for coding with an exciting industry that is blockchain.`,
                        position: 'Front-end developer',
                    },
                ],
            },
            {
                departmentName: 'MARKETING DEPARTMENT',
                members: [
                 
                    {
                        img: 'alex',
                        name: 'ALEX',
                        nickname: 'ARCHITECT OF FUN',
                        description: `7 years of working in service sector and now I am ready for a new challenge - marketing.
                         Together with the project, I am developing my best skills, which once again proves that everything is not in vain.
                          Monoceros is love at first sight and mutual sympathy.
                         My goal is for you to come, and then leave or stay is up to you. And the choice seems obvious to me.`,
                        position: 'Marketing Manager',
                    },
                    {
                        img: 'alina',
                        name: 'ALINA',
                        nickname: 'ART FAIRY',
                        description: `I am a crypto artist. Also, I am a big fan of drawing. 
                        Thanks to the Monoceros project, I can implement my bold ideas. You can call me Art Fairy 🧚‍♀️`,
                        position: 'Graphic designer',
                    },
                    {
                        img: 'ruslana',
                        name: 'RUSLANA',
                        nickname: 'ENGINE OF TIKTOK',
                        description: `Dance like nobody's watching. 
                        Sing like nobody's listening. Invest like you’re SBF.`,
                        position: 'Junior Marketing Manager',
                    },
                    {
                        img: 'yael',
                        name: 'YAEL',
                        nickname: 'CHIEF HAPPINESS OFFICER',
                        description: `The project gives you an opportunity to break boundaries and do things you never thought you would be able to do.
                         Lets you develop, experience and learn. to show that it is also possible otherwise.
                        `,
                        position: 'Human resources manager',
                    },
                    {
                        img: 'denis',
                        name: 'DENIS',
                        nickname: 'ACCOUNTING NINJA',
                        description: `Finance is my life! I like what I'm doing! This project is the future of a training platform
                         in the crypto industry and I will make all I need to take it to happen, for making the future better.`,
                        position: 'Chief Financial Officer (CFO)',
                    },
                    {
                        img: 'slava',
                        name: 'SLAVA',
                        nickname: 'CAPTAIN OF MULTITASKING',
                        description: `Jumped on crypto bandwagon in 2021 and take the bull by the horns to make my analytics skill GOAT.
                         Fond of travelling, snowboarding & motorbikes. Inspired by Monoceros because have been on the other side used to manage my investments on many different platforms.`,
                        position: 'Junior business analyst',
                    },
                    // {
                    //     img: 'anna',
                    //     name: 'ANNA',
                    //     nickname: 'JEDI OF MONOCEROS',
                    //     description: `I create magic every day. With the Power of Monoceros, the possibilities become...
                    //     almost limitless. Look for my messages in social networks ;)`,
                    //     position: 'Social Marketing Manager (SMM)',
                    // },
                ],
            },
            // {
            //     departmentName: 'HR DEPARTMENT',
            //     members: [
            //         {
            //             img: 'yael',
            //             name: 'YAEL',
            //             nickname: 'CHIEF HAPPINESS OFFICER',
            //             description: `The project gives you an opportunity to break boundaries and do things you never thought you would be able to do.
            //              Lets you develop, experience and learn. to show that it is also possible otherwise.
            //             `,
            //             position: 'Human resources manager',
            //         },
            //     ],
            // },
            // {
            //     departmentName: 'FINANCE DEPARTMENT',
            //     members: [
            //         {
            //             img: 'denis',
            //             name: 'DENIS',
            //             nickname: 'ACCOUNTING NINJA',
            //             description: `Finance is my life! I like what I'm doing! This project is the future of a training platform
            //              in the crypto industry and I will make all I need to take it to happen, for making the future better.`,
            //             position: 'Chief Financial Officer (CFO)',
            //         },
            //     ],
            // },
            // {
            //     departmentName: 'ANALYTICS DEPARTMENT',
            //     members: [
            //         {
            //             img: 'slava',
            //             name: 'SLAVA',
            //             nickname: 'CAPTAIN OF MULTITASKING',
            //             description: `Jumped on crypto bandwagon in 2021 and take the bull by the horns to make my analytics skill GOAT.
            //              Fond of travelling, snowboarding & motorbikes. Inspired by Monoceros because have been on the other side used to manage my investments on many different platforms.`,
            //             position: 'Junior business analyst',
            //         },
            //     ],
            // },
        
        ],
    },
};
