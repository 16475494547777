import { createContextStore } from 'easy-peasy';
import { uiActions } from '../pages/plansPage/actions/uiActions';
import { plansEffects } from '../pages/plansPage/actions/plansEffects';


const PLANS_MODEL = {

  ...uiActions.actionHandlers,
  ...plansEffects.actionHandlers
};

export const PlansState = createContextStore(PLANS_MODEL);
