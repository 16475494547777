/* eslint-disable max-len */
import React from 'react';

export const TRADE_CIRCLE = <>
    <path d="M78.2696 52.0111C118.33 41.2768 149.596 28.0566 148.102 22.483C146.609 16.9094 112.922 21.093 72.8614 31.8273C32.8004 42.5616 1.53521 55.7818 3.02865 61.3554C4.52209 66.929 38.2086 62.7454 78.2696 52.0111Z" stroke="#B8B6B6" strokeMiterlimit="10" strokeDasharray="25 25"/>
    <path d="M80.2483 2L33.908 82.6042H128.353L80.2483 2Z" stroke="#B8B6B6" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M103.581 30.6094C109.873 30.6094 114.974 35.7094 114.974 42.0005C114.974 48.2916 109.873 53.3916 103.581 53.3916C97.2885 53.3916 92.1875 48.2916 92.1875 42.0005C92.1875 35.7094 97.2885 30.6094 103.581 30.6094Z" fill="url(#paint0_linear_107_2648)"/>
    <defs>
        <linearGradient id="paint0_linear_107_2648" x1="84.4946" y1="27.7937" x2="132.701" y2="63.7001" gradientUnits="userSpaceOnUse">
            <stop offset="0.18" stopColor="#1F55FE"/>
            <stop offset="0.32" stopColor="#0F9AFF"/>
            <stop offset="0.44" stopColor="#05CAFE"/>
            <stop offset="0.56" stopColor="#00EBC1"/>
            <stop offset="0.78" stopColor="#01BF4A"/>
            <stop offset="1" stopColor="#01F2AE"/>
        </linearGradient>
    </defs>
</>;