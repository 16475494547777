const activeTradeData = [
  {
    botId: 'b5bc20c5-fedb-48ad-a231-0c9526f70ee8',
    pair: 'XVS',
    mainCoin: 'USDT',
    exchange: 'binance-futures',
    start: '20/20/20 18:30',
    creationDate: ['20/20/20', '18:30', 'ID: 16123522'],
    currentPosition: ['Current Position', '0.00 XVS', '0.00 USDT'],
    profitLose: ['Warning', '-0.0956', '-0.09 $', '-0.18%'],
    volume: '248.64011200 USDT',
    status: 'Bought',
    pnl: { change: -15, balance: '-20 000 $' },
    fullModel: {
      model: {
        botTrades: [
          {
            orderId: 1,
            mainCoin: 'USDT',
            pairCoin: 'XVS',
            trade: {
              side: 'BUY',
              trade: {
                price: '2.33',
                qty: '22.56222222',
              },
            },
          },
        ],
        originalModel: {
          data: {
            order: {
              base: {
                priceBase: '310.3',
              },
              quantity: '0',
              side: 'buy',
            },
            leverage: 1,
            mainCoin: 'USDT',
            pairCoin: 'XVS',
          },
        },
        paramsAndProfits: {
          upperLimitPrice: '3.5654181 USDT',
          quantityPerGrid: '1.5000000 DOTUSDT',
          activeOrders: '20 of 20',
          lowerLimitPrice: '3.5654181 USDT',
          gridWidth: '0.20% ~ 0.23%',
          investment: '0 USDT',
          options: 'By %',
          condition: '-10%',
          profits: [
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
          ],
        },
      },
    },
  },
  {
    botId: '3005b22b-4926-4749-9fb1-5f9cca4442a1',
    pair: 'XVS',
    mainCoin: 'USDT',
    exchange: 'binance-futures',
    start: '20/20/20 18:30',
    creationDate: ['20/20/20', '18:30', 'ID: 16123522'],
    currentPosition: ['Current Position', '0.00 XVS', '0.00 USDT'],
    profitLose: ['Warning', '-0.0956', '-0.09 $', '-0.18%'],
    volume: '248.64011200 USDT',
    status: 'Bought',
    pnl: { change: -15, balance: '-20 000 $' },
    fullModel: {
      model: {
        botTrades: [
          {
            orderId: 1,
            mainCoin: 'USDT',
            pairCoin: 'XVS',
            trade: {
              side: 'SELL',
              trade: {
                price: '2.33',
                qty: '22.56222222',
              },
            },
          },
        ],
        originalModel: {
          data: {
            order: {
              base: {
                priceBase: '313.4',
              },
              quantity: '0',
              side: 'buy',
            },
            leverage: 1,
            mainCoin: 'USDT',
            pairCoin: 'XVS',
          },
        },
        paramsAndProfits: {
          upperLimitPrice: '3.5654181 USDT',
          quantityPerGrid: '1.5000000 DOTUSDT',
          activeOrders: '20 of 20',
          lowerLimitPrice: '3.5654181 USDT',
          gridWidth: '0.20% ~ 0.23%',
          investment: '0 USDT',
          options: 'By %',
          condition: '-10%',
          profits: [
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
            { time: '03/31/2022 8:04 PM', value: '+ 3.5654181 USDT' },
          ],
        },
      },
    },
  },
];

const historyTradeData = [
  {
    pair: 'XVS',
    mainCoin: 'USDT',
    side: 'Sell',
    size: {
      low: { value: '0.00059', icon: 'XVS' },
      height: { value: '10.25486', icon: 'USDT' },
      filled: { value: '100%', state: '' }
    },
    orderType: { value: 'Market', state: '' },
    orderPrice: '10.000000',
    trigerPrice: '-',
    orderOption: {
      timeout: '30sec',
      trailing: '15.00%'
    },
    creationDate: {
      today: '07:20:15 PM',
      id: '1512547'
    }
  },
  {
    pair: 'XVS',
    mainCoin: 'USDT',
    side: 'Buy',
    size: {
      low: { value: '0.00059', icon: 'XVS' },
      height: { value: '10.25486', icon: 'USDT' },
      filled: { value: '100%', state: 'Cancelled' }
    },
    orderType: { value: 'Market', state: 'Good-Till-Cancelled' },
    orderPrice: '10.000000',
    trigerPrice: '10.000000',
    orderOption: {
      timeout: '30sec',
      trailing: '15.00%'
    },
    creationDate: {
      today: '07:20:15 PM',
      id: '1512547'
    }
  },
  {
    pair: 'XVS',
    mainCoin: 'USDT',
    side: 'Sell',
    size: {
      low: { value: '0.00059', icon: 'XVS' },
      height: { value: '10.25486', icon: 'USDT' },
      filled: { value: '100%', state: '' }
    },
    orderType: { value: 'Market', state: '' },
    orderPrice: '10.000000',
    trigerPrice: '-',
    orderOption: {
      timeout: '30sec',
      trailing: '15.00%'
    },
    creationDate: {
      today: '07:20:15 PM',
      id: '1512547'
    }
  },
  {
    pair: 'XVS',
    mainCoin: 'USDT',
    side: 'Buy',
    size: {
      low: { value: '0.00059', icon: 'XVS' },
      height: { value: '10.25486', icon: 'USDT' },
      filled: { value: '100%', state: '' }
    },
    orderType: { value: 'Market', state: '' },
    orderPrice: '10.000000',
    trigerPrice: '-',
    orderOption: {
      timeout: '30sec',
      trailing: '15.00%'
    },
    creationDate: {
      today: '07:20:15 PM',
      id: '1512547'
    }
  },
  {
    pair: 'XVS',
    mainCoin: 'USDT',
    side: 'Sell',
    size: {
      low: { value: '0.00059', icon: 'XVS' },
      height: { value: '10.25486', icon: 'USDT' },
      filled: { value: '100%', state: '' }
    },
    orderType: { value: 'Market', state: '' },
    orderPrice: '10.000000',
    trigerPrice: '-',
    orderOption: {
      timeout: '30sec',
      trailing: '15.00%'
    },
    creationDate: {
      today: '07:20:15 PM',
      id: '1512547'
    }
  }
]

const mockupBotTrades = {
  Trigger_price: { icon: '', value: 2.22235 },
  Initia_Position: [
    { icon: '', value: '23.45 XVS' },
    { icon: '', value: '52.2365 USDT' },
  ],
  TTP_Condition: { icon: '' },
  TP_Condition: [
    {
      price: { value: 2.33, icon: '' },
      quantity: '0.85695874 XVS USDT (20%)',
      status: 'Status',
      actions: 'Buy at Market Price',
    },
    {
      price: { value: 2.33, icon: '' },
      quantity: '0.85695874 XVS USDT (20%)',
      status: 'Status',
      actions: 'Buy at Market Price',
    },
    {
      price: { value: 2.33, icon: '' },
      quantity: '0.85695874 XVS USDT (20%)',
      status: 'Status',
      actions: 'Buy at Market Price',
    },
    {
      price: { value: 2.33, icon: '' },
      quantity: '0.85695874 XVS USDT (20%)',
      status: 'Status',
      actions: 'Buy at Market Price',
    },
  ],
  Sell_Steps: [
    {
      price: { icon: '', value: 2.33 },
      volume: '22.56222222 XVS / 52.235652 USDT',
      status: 'Own funds',
      Actions: '',
    },
  ],
};

export const tradeMockData = {
  activeTradeData,
  historyTradeData
};
