import React, { useEffect } from 'react';
import './styles.scss';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { balanceEffects } from './balanceEffects';
import { dataHelper } from './dataHelper';
import { StandardOptions } from '../utility/standard-options/view';
import { constants } from '../../constants/constants';
import { Frame } from '../utility/frame/view';

let INTERVAL_ID = null;
const { BALANCE } = constants;

const renderCard = (state, sizes, totalBTC, totalUSDT, difUSDT, difBTC) => {
    const time = state.currentTime.split(' ');
    return <>
        {/* <div className="d-flex flex-row justify-content-start balance-column balance-container pt-1">
            <div className="pe-2 d-flex flex-column justify-content-center balance-column date-column">
                <div className="font-size-14 font-weight-bold">{time[0]} <span className="font-size-14 font-weight-light">{time[1]}</span></div>
                <div className="font-size-10">{state.currentDate}</div>
            </div>
            <div className="pe-2 d-flex flex-column justify-content-center balance-column">
                <img src="../coins-full-library/btc.svg" className="img-fluid rounded-start"/>
            </div>
            <div className="d-flex flex-column justify-content-center balance-column values-column">
                <div className="font-size-16 font-weight-black balance-value">{`${totalBTC} BTC`}</div>
                <div className="font-size-10 font-weight-medium">{`${difBTC}`}</div>
            </div>
            <div className={'balance-column vertical-divider'}></div>
            <div className={'me-2 ms-2 d-flex flex-column justify-content-center balance-column'}>
                <img src="../coins-library/usdt.svg" className="img-fluid rounded-start"/>
            </div>
            <div className={'d-flex flex-column justify-content-center balance-column values-column'}>
                <div className="font-size-16 font-weight-black balance-value">{`${totalUSDT} $`}</div>
                <div className="font-size-10 font-weight-medium">{`${difUSDT}`}</div>
            </div>
        </div>  */}

        <div className="d-flex flex-row justify-content-start balance-column balance-container pt-1">
            <div className="pe-2 d-flex flex-column justify-content-center balance-column date-column flex-shrink-0">
                <div className="font-size-14 font-weight-bold">{time[0]} <span className="font-size-14 font-weight-light">{time[1]}</span></div>
                <div className="font-size-10">{state.currentDate}</div>
            </div>
            <div className="pe-2 d-flex flex-column justify-content-center balance-column flex-shrink-0">
                <img src="../coins-full-library/btc.svg" className="img-fluid rounded-start"/>
            </div>
            <div className="d-flex flex-column justify-content-center balance-column values-column">
                <div className="font-size-16 font-weight-black balance-value">{`${totalBTC} BTC`}</div>
                <div className="font-size-10 font-weight-medium">{`${totalBTC}`}</div>
            </div>
            <div className={'balance-column vertical-divider'}></div>
            <div className={'me-2 ms-2 d-flex flex-column justify-content-center balance-column test'}>
                <img src="../coins-library/usdt.svg" className="img-fluid rounded-start"/>
            </div>
            <div className={'d-flex flex-column justify-content-center balance-column values-column test'}>
                <div className="font-size-16 font-weight-black balance-value">{`${totalUSDT} $`}</div>
                <div className="font-size-10 font-weight-medium">{`${totalUSDT} USDT`}</div>
            </div>
        </div> 
    </>;
};

const Balance = () => {
    const actions = useStoreActions(actions => actions);
    const state = useStoreState(state => state);
    const balance = useStoreState(state => state.balance);
    const coinData = useStoreState(state => state.coinData);
    const sizes = useStoreState(state => state.sizes);
    const width = sizes.headerWidth;
    const limit = Math.max(Math.round((width - 410) / 128) - 1, 1);
    const coins = dataHelper.getFavoritesData(state.coinData);
    const coinsVisiblePart = coins.slice(0, limit);
    const {difUSDT, difBTC } = dataHelper.getTotals(coinData);
    
const totalUSDT = state.coinData.totalUSDT.toFixed(0);
let totalBTC =  totalUSDT / Number(coinsVisiblePart[0].price);

    useEffect(() => { // Do it's only once on loading
        actions[balanceEffects.actionTypes.BALANCE_TIME_INTERVAL_UPDATED]();
        clearInterval(INTERVAL_ID);
        INTERVAL_ID = setInterval(() => actions[balanceEffects.actionTypes.BALANCE_TIME_INTERVAL_UPDATED](), 60000);
    }, []);
    return <div className="balance-card">
        {renderCard(balance, sizes, totalBTC.toFixed(2), totalUSDT, difUSDT, difBTC, coinsVisiblePart)}
    </div>;
};

export const BalanceContent = () => {

    return <>
        <Frame
            content={<Balance/>}
            label={BALANCE.LABEL}
            fontSize={12}
            fontWeight={500}
            borderRadius={4}
        />
        <div className="balance-controls d-flex flex-column justify-content-start align-items-center">
            <div className="standard-options-content">
                <StandardOptions
                    more={{ ...BALANCE.STANDARD_OPTIONS_SCHEMA }}
                />
            </div>
        </div>
    </>;
};