import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../service/serviceUtils';
import { notifyEffects } from '../utility/notifications/notifyEffects';
import { refreshHelper } from './dataHelpers/refreshHelper';
import { isEmpty } from 'lodash';

const actionTypes = {
    REFRESH_DATA_INVOKED: 'REFRESH_DATA#INVOKED',
    REFRESH_DATA_FETCH_SUCCEED: 'REFRESH_DATA_FETCH#SUCCEED',
    REFRESH_DATA_FETCH_FAILED: 'REFRESH_DATA_FETCH#FAILED'
};

const fetchAction = (action, payload) => {
    const options = refreshHelper.getRequestParams(payload);
    if (!options)
        return;

    serviceUtils.HttpService({
        url: options.url,
        method: options.method,
        errorActionType: action[actionTypes.REFRESH_DATA_FETCH_FAILED],
        successActionType: action[actionTypes.REFRESH_DATA_FETCH_SUCCEED],
        ...options
    });
};

const errorAction = (state, payload) => {
    const { options, error } = payload;
    state.loaders[options.type] = false;

    const notification = { type: 'danger', text: `Refresh data fetch failed, ${error}` };

    switch (options.type) {
    case 'messages': return;
    default: notifyEffects.notifyAction(notification);
    }
};

const successAction = (state, payload) => {
    const { options, response } = payload;

    state.loaders[options.type] ? state.loaders[options.type] = false : '';
    refreshHelper.refreshHandle(state, response, options);

    const notification = { type: 'success', text: `Refresh is successfully for ${options.type.toUpperCase()}` };

    switch (options.type) {
    case 'messages': return;
    default: notifyEffects.notifyAction(notification);
    }

};

const actionHandlers = {
    [actionTypes.REFRESH_DATA_INVOKED]: thunk( async (action, payload) => fetchAction(action, payload)),
    [actionTypes.REFRESH_DATA_FETCH_FAILED]: action((state, payload) => errorAction(state, payload)),
    [actionTypes.REFRESH_DATA_FETCH_SUCCEED]: action((state, payload) => successAction(state, payload))
};

export const refreshEffects = {
    actionTypes,
    actionHandlers
};