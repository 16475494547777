import { plansEffects } from '../actions/plansEffects';

const drawMintTrial = (node, actions, type, loginModalToggle, history, content) => {
  const onClick = () => {
    loginModalToggle();
    actions[plansEffects.actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]({type, history});
  }

  node
    .selectAll('g.action-button')
    .on('click', () => {
      onClick()
    });

  node
    .selectAll('g.mint-trial')
    .remove();

  node
    .append('g')
    .attr('class', 'mint-trial');

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 40)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '32px')
    .attr('font-weight', 600)
    .text(content.MINT_TRIAL)

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 135)
    .attr('x', 460)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '48px')
    .attr('font-weight', 400)
    .text(`7 ${content.DAYS}`);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 307)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PRO_TRADES);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 382)
    .attr('x', 125)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.GRID_BOTS);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 455)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.DCA_BOTS);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 530)
    .attr('x', 116)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PACKS);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 603)
    .attr('x', 170)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.COPY_TRADING_BOTS);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 678)
    .attr('x', 190)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PAPER_TRADING_ACCOUNT);

  node
    .select('g.mint-trial')
    .append('text')
    .attr('y', 825)
    .attr('x', 270)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '25px')
    .attr('font-weight', 400)
    .attr('font-family', 'Inter')
    .attr('cursor', 'pointer')
    .text(content.START_TRIAL)
    .on('click', () => {
      onClick()
    });
};

const drawBeginner = (node, actions, type, loginModalToggle, history, content, isMonthly) => {
  const onClick = () => {
    loginModalToggle();
    actions[plansEffects.actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]({type, history, isMonthly});
  }

  node
    .selectAll('g.action-button')
    .on('click', () => {
      onClick()
    });

  node
    .selectAll('g.beginner')
    .remove();

  node
    .append('g')
    .attr('class', 'beginner');

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 40)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '32px')
    .attr('font-weight', 600)
    .text(content.BEGINNER)

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 140)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '64px')
    .attr('font-weight', 400)
    .text(isMonthly ? '$39' : '$351');

  if (!isMonthly) {
    node
      .select('g.beginner')
      .append('path')
      .attr('transform', 'translate(400, 135)')
      .attr('d', 'M38.8666 1.65794C42.2962 -0.552643 46.7038 -0.552646 50.1334 1.65793L56.1717 5.55002C57.586 6.46163 59.2009 7.01637 60.8773 7.1665L67.9936 7.80379C72.241 8.18417 75.8246 11.1186 77.0314 15.2045L78.7196 20.9202C79.2349 22.6646 80.1999 24.2431 81.518 25.4976L85.7765 29.5507C89.0525 32.6686 89.9319 37.5458 87.9509 41.6097L85.5606 46.5134C84.7404 48.1958 84.3909 50.0685 84.5489 51.9331L85.0126 57.4046C85.3854 61.8044 82.9309 65.9583 78.8947 67.7582L72.9112 70.4265C71.3177 71.1371 69.9291 72.2371 68.8734 73.6251L64.8619 78.8994C62.3618 82.1864 58.1496 83.681 54.1341 82.7058L46.9545 80.9622C45.3417 80.5705 43.6583 80.5705 42.0455 80.9622L34.8659 82.7058C30.8505 83.681 26.6382 82.1864 24.1381 78.8994L20.1266 73.6251C19.0709 72.2371 17.6823 71.1371 16.0888 70.4265L10.1053 67.7582C6.06907 65.9583 3.6146 61.8044 3.98745 57.4047L4.45113 51.9331C4.60914 50.0685 4.25959 48.1958 3.43945 46.5134L1.04907 41.6097C-0.931917 37.5458 -0.0524743 32.6686 3.22349 29.5507L7.48197 25.4976C8.80011 24.2431 9.76513 22.6646 10.2804 20.9202L11.9686 15.2045C13.1754 11.1186 16.759 8.18417 21.0065 7.80379L28.1227 7.1665C29.7991 7.01637 31.414 6.46163 32.8283 5.55002L38.8666 1.65794Z')
      .attr('fill', '#05CFF4')

    node
      .select('g.beginner')
      .append('text')
      .attr('y', 182)
      .attr('x', 445)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1E2C4F')
      .attr('font-size', '14px')
      .attr('font-weight', 500)
      .text('-25% off')
  }

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 248)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PRO_TRADES);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 248)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(50);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 300)
    .attr('x', 125)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.GRID_BOTS);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 300)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(5);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 355)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.DCA_BOTS);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 355)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(5);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 407)
    .attr('x', 115)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PACKS);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 407)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(10);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 460)
    .attr('x', 170)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.COPY_TRADING_BOTS);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 460)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(1);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 514)
    .attr('x', 190)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PAPER_TRADING_ACCOUNT);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 635)
    .attr('x', 310)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.UNIQUE_NFT_AVATAR}*`);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 676)
    .attr('x', 242)
    .attr('text-anchor', 'middle')
    .attr('fill', '#05CAFE')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text('0.7%');

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 676)
    .attr('x', 340)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.INCOME_POOL}**`);

  node
    .select('g.beginner')
    .append('text')
    .attr('y', 825)
    .attr('x', 270)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '25px')
    .attr('font-weight', 400)
    .attr('font-family', 'Inter')
    .attr('cursor', 'pointer')
    .text(content.SUBSCRIBE)
    .on('click', () => {
      onClick()
    });
};

const drawAdvanced = (node, actions, type, loginModalToggle, history, content, isMonthly) => {
  const onClick = () => {
    loginModalToggle();
    actions[plansEffects.actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]({type, history, isMonthly});
  }

  node
    .selectAll('g.action-button')
    .on('click', () => {
      onClick()
    });

  node
    .selectAll('g.advanced')
    .remove();

  node
    .append('g')
    .attr('class', 'advanced');

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 40)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '32px')
    .attr('font-weight', 600)
    .text(content.ADVANCED)

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 140)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '64px')
    .attr('font-weight', 400)
    .text(isMonthly ? '$69' : '$579.6');

  if (!isMonthly) {
    node
      .select('g.advanced')
      .append('path')
      .attr('transform', 'translate(400, 135)')
      .attr('d', 'M38.8666 1.65794C42.2962 -0.552643 46.7038 -0.552646 50.1334 1.65793L56.1717 5.55002C57.586 6.46163 59.2009 7.01637 60.8773 7.1665L67.9936 7.80379C72.241 8.18417 75.8246 11.1186 77.0314 15.2045L78.7196 20.9202C79.2349 22.6646 80.1999 24.2431 81.518 25.4976L85.7765 29.5507C89.0525 32.6686 89.9319 37.5458 87.9509 41.6097L85.5606 46.5134C84.7404 48.1958 84.3909 50.0685 84.5489 51.9331L85.0126 57.4046C85.3854 61.8044 82.9309 65.9583 78.8947 67.7582L72.9112 70.4265C71.3177 71.1371 69.9291 72.2371 68.8734 73.6251L64.8619 78.8994C62.3618 82.1864 58.1496 83.681 54.1341 82.7058L46.9545 80.9622C45.3417 80.5705 43.6583 80.5705 42.0455 80.9622L34.8659 82.7058C30.8505 83.681 26.6382 82.1864 24.1381 78.8994L20.1266 73.6251C19.0709 72.2371 17.6823 71.1371 16.0888 70.4265L10.1053 67.7582C6.06907 65.9583 3.6146 61.8044 3.98745 57.4047L4.45113 51.9331C4.60914 50.0685 4.25959 48.1958 3.43945 46.5134L1.04907 41.6097C-0.931917 37.5458 -0.0524743 32.6686 3.22349 29.5507L7.48197 25.4976C8.80011 24.2431 9.76513 22.6646 10.2804 20.9202L11.9686 15.2045C13.1754 11.1186 16.759 8.18417 21.0065 7.80379L28.1227 7.1665C29.7991 7.01637 31.414 6.46163 32.8283 5.55002L38.8666 1.65794Z')
      .attr('fill', '#05CFF4')

    node
      .select('g.advanced')
      .append('text')
      .attr('y', 182)
      .attr('x', 445)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1E2C4F')
      .attr('font-size', '14px')
      .attr('font-weight', 500)
      .text('-30% off')
  }

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 248)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PRO_TRADES);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 248)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(50);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 300)
    .attr('x', 125)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.GRID_BOTS);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 300)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(10);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 355)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.DCA_BOTS);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 355)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(10);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 407)
    .attr('x', 115)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PACKS);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 407)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(20);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 460)
    .attr('x', 170)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.COPY_TRADING_BOTS);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 460)
    .attr('x', 500)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(3);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 514)
    .attr('x', 190)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PAPER_TRADING_ACCOUNT);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 635)
    .attr('x', 310)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.UNIQUE_NFT_AVATAR}*`);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 676)
    .attr('x', 242)
    .attr('text-anchor', 'middle')
    .attr('fill', '#05CAFE')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text('1.4%');

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 676)
    .attr('x', 340)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.INCOME_POOL}**`);

  node
    .select('g.advanced')
    .append('text')
    .attr('y', 825)
    .attr('x', 270)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '25px')
    .attr('font-weight', 400)
    .attr('font-family', 'Inter')
    .attr('cursor', 'pointer')
    .text(content.SUBSCRIBE)
    .on('click', () => {
      onClick()
    });
};

const drawExpert = (node, actions, type, loginModalToggle, history, content, isMonthly) => {
  const onClick = () => {
    loginModalToggle();
    actions[plansEffects.actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]({type, history, isMonthly});
  }

  node
    .selectAll('g.action-button')
    .on('click', () => {
      onClick()
    });

  node
    .selectAll('g.expert')
    .remove();

  node
    .append('g')
    .attr('class', 'expert');

  node
    .select('g.expert')
    .append('text')
    .attr('y', 40)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '32px')
    .attr('font-weight', 600)
    .text(content.EXPERT)

  node
    .select('g.expert')
    .append('text')
    .attr('y', 140)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '64px')
    .attr('font-weight', 400)
    .text(isMonthly ? '$149' : '$1072.8');

  if (!isMonthly) {
    node
      .select('g.expert')
      .append('path')
      .attr('transform', 'translate(400, 135)')
      .attr('d', 'M38.8666 1.65794C42.2962 -0.552643 46.7038 -0.552646 50.1334 1.65793L56.1717 5.55002C57.586 6.46163 59.2009 7.01637 60.8773 7.1665L67.9936 7.80379C72.241 8.18417 75.8246 11.1186 77.0314 15.2045L78.7196 20.9202C79.2349 22.6646 80.1999 24.2431 81.518 25.4976L85.7765 29.5507C89.0525 32.6686 89.9319 37.5458 87.9509 41.6097L85.5606 46.5134C84.7404 48.1958 84.3909 50.0685 84.5489 51.9331L85.0126 57.4046C85.3854 61.8044 82.9309 65.9583 78.8947 67.7582L72.9112 70.4265C71.3177 71.1371 69.9291 72.2371 68.8734 73.6251L64.8619 78.8994C62.3618 82.1864 58.1496 83.681 54.1341 82.7058L46.9545 80.9622C45.3417 80.5705 43.6583 80.5705 42.0455 80.9622L34.8659 82.7058C30.8505 83.681 26.6382 82.1864 24.1381 78.8994L20.1266 73.6251C19.0709 72.2371 17.6823 71.1371 16.0888 70.4265L10.1053 67.7582C6.06907 65.9583 3.6146 61.8044 3.98745 57.4047L4.45113 51.9331C4.60914 50.0685 4.25959 48.1958 3.43945 46.5134L1.04907 41.6097C-0.931917 37.5458 -0.0524743 32.6686 3.22349 29.5507L7.48197 25.4976C8.80011 24.2431 9.76513 22.6646 10.2804 20.9202L11.9686 15.2045C13.1754 11.1186 16.759 8.18417 21.0065 7.80379L28.1227 7.1665C29.7991 7.01637 31.414 6.46163 32.8283 5.55002L38.8666 1.65794Z')
      .attr('fill', '#05CFF4')

    node
      .select('g.expert')
      .append('text')
      .attr('y', 182)
      .attr('x', 445)
      .attr('text-anchor', 'middle')
      .attr('fill', '#1E2C4F')
      .attr('font-size', '14px')
      .attr('font-weight', 500)
      .text('-40% off')
  }

  node
    .select('g.expert')
    .append('text')
    .attr('y', 248)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PRO_TRADES);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 248)
    .attr('x', 462)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 292)
    .attr('x', 122)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.GRID_BOTS);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 292)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 340)
    .attr('x', 128)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.DCA_BOTS);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 338)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 385)
    .attr('x', 112)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PACKS);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 384)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 430)
    .attr('x', 166)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.COPY_TRADING_BOTS);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 430)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 476)
    .attr('x', 188)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PAPER_TRADING_ACCOUNT);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 522)
    .attr('x', 140)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.CUSTOM_API);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 635)
    .attr('x', 310)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.UNIQUE_NFT_AVATAR}*`);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 676)
    .attr('x', 242)
    .attr('text-anchor', 'middle')
    .attr('fill', '#05CAFE')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text('2.9%');

  node
    .select('g.expert')
    .append('text')
    .attr('y', 676)
    .attr('x', 340)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.INCOME_POOL}**`);

  node
    .select('g.expert')
    .append('text')
    .attr('y', 825)
    .attr('x', 270)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '25px')
    .attr('font-weight', 400)
    .attr('font-family', 'Inter')
    .attr('cursor', 'pointer')
    .text(content.SUBSCRIBE)
    .on('click', () => {
      onClick()
    });
};

const drawLegendary = (node, actions, type, loginModalToggle, history, content, isMonthly) => {
  const onClick = () => {
    loginModalToggle();
    actions[plansEffects.actionTypes.PLANS_SUBSCRIPTIONS_INVOKED]({type, history, isMonthly});
  }

  node
    .selectAll('g.action-button')
    .on('click', () => {
      onClick()
    });

  node
    .selectAll('g.legendary')
    .remove();

  node
    .append('g')
    .attr('class', 'legendary');

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 40)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '32px')
    .attr('font-weight', 600)
    .text(content.LEGENDARY)

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 115)
    .attr('x', 278)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '48px')
    .attr('font-weight', 400)
    .text(content.LIFETIME_SUBSCRIPTION);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 175)
    .attr('x', 122)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '24px')
    .attr('font-weight', 400)
    .text(content.LIMITED_EDITION);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 175)
    .attr('x', 460)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '24px')
    .attr('font-weight', 400)
    .text(`1000 ${content.PCS}`);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 248)
    .attr('x', 130)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PRO_TRADES);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 248)
    .attr('x', 462)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 292)
    .attr('x', 122)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.GRID_BOTS);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 292)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 340)
    .attr('x', 128)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.DCA_BOTS);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 338)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 385)
    .attr('x', 112)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PACKS);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 384)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 430)
    .attr('x', 166)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.COPY_TRADING_BOTS);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 430)
    .attr('x', 463)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .text(content.UNLIMITED);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 476)
    .attr('x', 188)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.PAPER_TRADING_ACCOUNT);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 522)
    .attr('x', 140)
    .attr('text-anchor', 'middle')
    .attr('fill', '#BCBCBC')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(content.CUSTOM_API);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 625)
    .attr('x', 327)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.UNIQUE_NFT_COLLECTION}*`);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 662)
    .attr('x', 320)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.VOTING_AMPLIFIER} x 1.1`);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 704)
    .attr('x', 240)
    .attr('text-anchor', 'middle')
    .attr('fill', '#05CAFE')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text('10%');

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 704)
    .attr('x', 334)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '22px')
    .attr('font-weight', 400)
    .attr('font-style', 'italic')
    .text(`${content.INCOME_POOL}**`);

  node
    .select('g.legendary')
    .append('text')
    .attr('y', 825)
    .attr('x', 270)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '25px')
    .attr('font-weight', 400)
    .attr('font-family', 'Inter')
    .attr('cursor', 'pointer')
    .text(content.SUBSCRIBE)
    .on('click', () => {
      onClick()
    });
};

export const drawHelper = {
  drawMintTrial,
  drawBeginner,
  drawAdvanced,
  drawExpert,
  drawLegendary
};
