import React from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import { BorderedButton } from '../../../../components/utility/bordered-button/view';
import { constants } from '../../constants';
import { useLocation } from 'react-router-dom';

const { HOME_CONTENT: { START, FOR_INVESTORS, FOR_TRADERS, FOR_BUILDERS } } = constants;

export const HomePageContent = () => {
    return <>
        <div className='get-started-wrapper d-flex justify-content-between align-items-center'>
                <div className='start-content font-inter'>
                    <p className='heading font-weight-bold'>{START.HEADING}</p>
                    <p className='description font-size-20'>{START.DESCRIPTION}</p>
                    <div className='d-inline-flex'>
                        <LinkContainer to='/home?elem=trade'>
                            <a>
                                <BorderedButton>{START.TRADE}</BorderedButton>
                            </a>
                        </LinkContainer>
                    </div>
                </div>
                <div className="buy-button">
                    <LinkContainer to='/home?elem=buy'>
                        <a>
                            <BorderedButton>{START.BUY}</BorderedButton>
                        </a>
                    </LinkContainer>
                </div>
            </div>
            {/* <div className='for-investors-wrapper'>
                <div className='invest-content font-inter '>
                    <p className='font-size-20'>{FOR_INVESTORS.TOPIC}</p>
                    <p className='heading font-weight-bold'>{FOR_INVESTORS.HEADING}</p>
                    <p className='description font-size-20'>{FOR_INVESTORS.DESCRIPTION}</p>
                    <div className='list'>
                        {FOR_INVESTORS.LIST.map((item, index) => <p className='font-size-20' key={index}>
                            <img width={32} className='me-4' src={item.icon} />{item.label}</p>)}</div>
                    <div className='d-inline-flex'>
                        <LinkContainer to='/home?elem=invest'><a><BorderedButton class='me-5'>{FOR_INVESTORS.START_INVEST}</BorderedButton></a></LinkContainer>
                        <LinkContainer to='/home?elem=invest'><a><BorderedButton>{FOR_INVESTORS.INVEST_FEATURE}</BorderedButton></a></LinkContainer>
                    </div>
                </div>
                <div className='content-img d-flex justify-content-end'>
                    <img src='../dashboard-library/home-page-images/investors.svg' />
                </div>
            </div>
            <div className='trade-wrapper'>
                <div className='trade-content font-inter'>
                    <p className='font-size-20'>{FOR_TRADERS.TOPIC}</p>
                    <p className='heading font-weight-bold'>{FOR_TRADERS.HEADING}</p>
                    <p className='description font-size-20'>{FOR_TRADERS.DESCRIPTION}</p>
                    <div className='list'>{FOR_TRADERS.LIST.map((item, index) => <p className='font-size-20' key={index}><img width={32} className='me-4' src={item.icon} />{item.label}</p>)}</div>
                    <div className='d-inline-flex'>
                        <LinkContainer to='/home?elem=trade'><a><BorderedButton class='me-5'>{FOR_TRADERS.START_TRADE}</BorderedButton></a></LinkContainer>
                        <LinkContainer to='/home?elem=trade'><a><BorderedButton>{FOR_TRADERS.TRADE_FEATURE}</BorderedButton></a></LinkContainer>
                    </div>
                </div>
                <div className='trade-img d-flex justify-content-end align-items-start'>
                    <img src='../dashboard-library/home-page-images/circle.svg' />
                </div>
            </div>
            <div className='build-wrapper'>
                <div className='build-content font-inter'>
                    <p className='font-size-20'>{FOR_BUILDERS.TOPIC}</p>
                    <p className='heading font-weight-bold'>{FOR_BUILDERS.HEADING}</p>
                    <p className='description font-size-20'>{FOR_BUILDERS.DESCRIPTION}</p>
                    <div className='list'>{FOR_BUILDERS.LIST.map((item, index) => <p className='font-size-20' key={index}><img width={32} className='me-4' src={item.icon} />{item.label}</p>)}</div>
                    <LinkContainer to='/home?elem=build'><a><BorderedButton>{FOR_BUILDERS.START_BUILDING}</BorderedButton></a></LinkContainer>
                </div>
                <div className='build-img d-flex justify-content-end'>
                    <img src='../dashboard-library/home-page-images/triangle.svg' />
                </div>
            </div> */}
    </>
};