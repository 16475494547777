import { Big } from 'big.js';

const sortHandler = (data, type) => data.sort((a, b) => Big(b[type]).minus(Big(a[type])).valueOf());

const getTotalBalance = data => {
    const balances = [];
    for (const balance of data) {
        balances.push(Big(+balance.usdValue));
    }
    return balances.reduce((acc, curr) => Big(acc).plus(Big(curr)).valueOf());
};

export const dataHelper = {
    sortHandler,
    getTotalBalance
};