
export  const constants = {
    TOP_CONTROLS: {
        TITLE: {
            TRADING_VIEW: 'TradingView & configuration',
            BALANCES: 'Balances'
        }
    },
    CONTROLS: {
        SUBTITLE: {
            CHOOSE_A_PAIR: 'CHOOSE A PAIR',
            GRID_TRADING_BOT: 'GRID TRADING BOT'
        },
        LABEL: {
            ACCOUNT: 'Account',
            MARKET: 'Market',
            PAIR: 'Pair',
            NAME: 'Name',
            LABEL_AMOUNT: 'Amount',
            UPPER_LIMIT_PRICE: 'Upper limit price',
            UPPER_STOP_LOSS: 'Upper Stop Loss',
            LOWER_LIMIT_PRICE: 'Lower limit price',
            LOWER_STOP_LOSS: 'Lower Stop Loss',
            GRID_QUANTITY: 'Grid quantity',
            ACTIVE_ORDERS: 'Active Orders',
            QUANTITY_PER_GRID: 'Quantity per grid',
            AMOUNT_FOR_BOT_USAGE: 'Amount for bot usage'
        },
        BUTTON: {
            AL_STRATEGY: 'Al strategy',
            MANUEL_STRATEGY: 'Manuel strategy',
            BASE: 'Base (BTC)',
            QUOTE: 'Quote (BTC)',
            CREATE: 'Create'
        },
        BALANCE: {
            BALANCE_QUOTE: 'Available balance (quote)',
            BALANCE_BASE: 'Available balance (base)',
        },
        SELL_ORDERS: {
            SUBTITLE: 'Sell orders required balance',
            ACTIVE_ORDERS: 'Active orders',
            ALL_ORDERS: 'All orders'
        },
        BUY_ORDERS: {
            SUBTITLE: 'Buy orders required balance',
            ACTIVE_ORDERS: 'Active orders',
            ALL_ORDERS: 'All orders',
            GRID_WIDTH: 'Grid width'
        }
    },
    TRADING_VIEW: {
        SEARCH: {
            LINKS: {
                ALL: 'All',
                ENABLE: 'Enable',
                DISABLE: 'Disable'
            },
            PLACEHOLDER: {
                SEARCH: "Search by the bot's name",
                EXCHANGE: 'Exchange'
            },
            BUTTON_CLEAR: 'Clear filters'
        },
        DISPLAY_RESULTS: {
            MESSAGE: 'You don’t have any bots here'
        },
        EXCHANGE: {
            LINKS: {
                EXCHANGE: 'Exchange',
                BOT: 'Bot',
                PAIR: 'Pair',
                SAID: 'Said',
                VALUE: 'Value',
                PROFIT: 'Profit',
                PROFIT_LOSS: 'Profit/ Loss',
                STATUS: 'Status'
            }
        },
        VALUES: {
            LINKS: {
                MY_GRIT_BOT: 'My Grid Bot',
                SHORT: 'SHORT',
                STOPPED: 'Stopped'
            }
        },
        LEFT_COLUMN: {
            TITLE: {
                CREATION_DATA: 'Creation Date',
                UPPER_LIMIT_PRICE: 'Upper Limit price',
                LOWER_LIMIT_PRICE: 'Lower Limit price',
                QUANTITY_PER_GRID: 'Quantity per grid',
                ACTIVE_ORDERS: 'Active Orders',
                GRID_WIDTH: 'Grid width',
                UPPER_STOP_LOSS: 'Upper Stop Loss',
                LOWER_STOP_LOSS: 'Lower Stop Loss',
                INVESTMENT: 'Investment'
            },
            LINK_NOTE: 'You can place a note here'
        },
        CENTER_COLUMN: {
            BUTTONS: {
                NOW: 'Now',
                WEEK: 'Week',
                MONTH: 'Month'
            },
            LINE: {
                CURRENT: 'Current'
            }
        },
        RIGHT_COLUMN: {
            TITLE: 'Profits',
            BUTTON: 'Recent transactions'
        }
    },
    BALANCES: {
        TITLE: 'Balances',
        MESSAGE: 'No data'
    },
    DEFAULT_PAIR: {
        'id': 'BTC',
        'mainCoin': 'USDT',
        'isSinglePair': true,
        'selectedPack': {
            'mainCoin': 'USDT',
            'weight': false
        },
        'exchangeData': {
            'value': {
                'label': 'Binance',
                'value': {
                    'accountId': '56988c21-b4c1-5ff3-9f1c-0394da7a2d92',
                    'name': 'Binance',
                    'api': 'binance_prod',
                    'type': 'SPOT'
                }
            }
        }
    },
};
