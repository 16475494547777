import React, { Fragment, useState } from "react";
import "./styles.scss";
import { routing } from "../../../routing/routing-mapping";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { CustomTooltip } from "../custom-tooltip/view";
const { NAVIGATION_MAPPING } = routing;

const renderNavigationMenu = (list, menuId) => {
  return list.map((el, key) => {
    const isDisabled = el.disabled === true ? "disabled" : "";
    const isActive = el.id === menuId ? "active" : "";
    return (
      <div
        key={key}
        className={`navigation-menu-item d-flex align-items-center ${isActive} `}
      >
        {!isDisabled ? (
          <LinkContainer key={key} to={`/${el.parentId}?elem=${el.id}`}>
            <div className="d-flex flex-row align-items-center w-100">
              <div className="icon-container d-flex align-items-center justify-content-center">
                <i className={`fa-light ${list[key].icon} font-size-20`}></i>
              </div>
              <div className="text-capitalize font-size-15 ps-2">
                {list[key].label}
              </div>
            </div>
          </LinkContainer>
        ) : (
          <div className="d-flex flex-row align-items-center w-100">
            <div className="icon-container d-flex align-items-center justify-content-center">
              <i className={`fa-light ${list[key].icon} font-size-20`}></i>
            </div>
            <div>
              <CustomTooltip
                trigger={
                  <i className="text-capitalize font-size-15 ps-2">
                    {list[key].label}
                  </i>
                }
                content={"Coming Soon"}
              />
            </div>
          </div>
        )}
      </div>
    );
  });
};

const renderNavSelector = (
  selector,
  key,
  setState,
  direction,
  selectorId,
  menuId
) => {
  const { icon, label, id, list } = NAVIGATION_MAPPING[selector];
  const selectorActivity = id === selectorId ? "active" : "";
  const iconDirection = direction[key];
  const iconCaret = iconDirection ? "up" : "down";
  const handle = () => {
    direction = [];
    direction[key] = !iconDirection;
    setState(direction);
  };

  return (
    <div key={id}>
      {id !== 'news' ?
      <div className="navigation-selector-container d-flex align-items-center">
        <div
          className={`d-flex align-items-center justify-content-start navigation-selector-item ${selectorActivity}`}
          onClick={handle}
        >
          <i className={`fa-light ${icon} font-size-20`}></i>
          <div className="text-capitalize font-size-15 pe-2 ps-2">{label}</div>
          <i className={`fa-solid fa-caret-${iconCaret}`}></i> 
        </div>
      </div> : <LinkContainer to={`/${id}?elem=${id}`}>
      <div className="navigation-selector-container d-flex align-items-center">
        <div  className={`d-flex align-items-center justify-content-start navigation-selector-item ${selectorActivity}`}>
        <i className={`fa-light ${icon} font-size-20`}></i>
        <div className="text-capitalize font-size-15 pe-2 ps-2">{label}</div>
        </div>
      </div>
      </LinkContainer> }
      <Collapse in={iconDirection} dimension="height">
        <div>{renderNavigationMenu(list, menuId)}</div>
      </Collapse>
    </div>
  );
};

export const VerticalNavigationSelector = () => {
  const [direction, setState] = useState([]);
  const { pathname, search } = useLocation();
  const selectorId = pathname.replace("/", "");
  const menuId = search.replace("?elem=", "");
  return (
    <Fragment>
      {Object.keys(NAVIGATION_MAPPING).map((el, i) =>
        renderNavSelector(el, i, setState, direction, selectorId, menuId)
      )}
    </Fragment>
  );
};
