import { createContextStore } from 'easy-peasy';
import { customBotsActions } from '../pages/customBots/actions/uiActions';
import { customBotsEffects } from '../pages/customBots/actions/customBotsEffects';
import { serviceEffects } from '../pages/customBots/actions/serviceEffects';
import { constants } from '../pages/customBots/constants';
import { defaultValueHelper } from '../pages/customBots/dataHelpers/defaultValueHelper';

const {
  SELECTORS: {
    EXCHANGE_LIST,
    QUOTE_CURRENCY_LIST,
    MAIN_SETTINGS_EXCHANGE_LIST,
    PAIRS_LIST,
    BASE_ORDER_SIZE_LIST,
    SAFETY_ORDERS_SIZE_LIST,
    STOP_LOSS_ACTION_LIST,
    CLOSE_DEAL_AFTER_TIMEOUT_LIST,
    PROFIT_TYPE_LIST,
    MINIMUM_PROFIT_TYPE_LIST,
    MARKET_LIST,
    TRADING_PAIR_LIST,
    CONDITIONS_CLOSING_BOT_LIST,
    PAIRS_BLACK_LIST,
    FILTERS_LIST
  },
  TABS
} = constants;

const CUSTOM_BOTS_MODEL = {
  tabs: {
    mainTab: '',
    sideTab: TABS.GORDON,
    stepTab: '',
    percentageTab: '',
    formTab: '',
    formTab1: '',
    wizardTab: TABS.MAIN_SETTINGS,
    botTypeTab: '',
    strategyTab: '',
    profitCurrencyTab: '',
    startOrderTypeTab: '',
    takeProfitTab: 'Percent %',
    pairsTab: '',
    sidebarTab: TABS.ASSISTANT,
    myBotsTab: 'All',
    statsTab: 'All',
  },
  forms: {
    exchangeSelector: {
      list: [],
      value: null
    },
    quoteCurrencySelector: {
      list: QUOTE_CURRENCY_LIST,
      value: null
    },
    inputForAmount: 0,
    radioForType: '',
    radioForBots: '',
    checkboxDontShowAgain: false,
    radioForPercentage: '',
    mainSettings: {
      name: '',
      botType: '',
      exchangeSelector: {
        list: [],
        value: null
      },
    },
    pairs: {
      mostTradedPairs: '',
      pairsSelector: {
        list: [],
        value: null
      }
    },
    strategy: {
      strategy: '',
      profitCurrency: '',
      startOrderType: '',
      baseOrderSizeSelector: {
        list: BASE_ORDER_SIZE_LIST,
        value: null
      },
      baseOrderSizeAmount: 0
    },
    dealStartCondition: {
      conditionFields: [defaultValueHelper.getDealStartConditionFields()]
    },
    safetyOrders: {
      safetyOrdersSizeSelector: {
        list: SAFETY_ORDERS_SIZE_LIST,
        value: null
      },
      safetyOrdersSizeAmount: 0,
      priceDeviationAmount: 0,
      sliderPriceDeviationAmount: 0,
      safetyOrdersCountAmount: 0,
      sliderSafetyOrdersCountAmount: 0,
      activeSafetyOrdersCountAmount: 0,
      sliderActiveSafetyOrdersCountAmount: 0,
      safetyOrderVolumeScaleAmount: 0,
      sliderSafetyOrderVolumeScaleAmount: 0,
      safetyOrderStepScaleAmount: 0,
      sliderSafetyOrderStepScaleAmount: 0,
    },
    stopLoss: {
      toggleStopLoss: false,
      stopLossPercentage: 0,
      stopLossActionSelector: {
        list: STOP_LOSS_ACTION_LIST,
        value: null
      },
      toggleStopLossTimeout: false,
      stopLossTimeout: 0
    },
    advancedSettings: {
      dailyVolumeLess: 0,
      minimumPriceDeal: 0,
      maximumPriceDeal: 0,
      cooldownBetweenDeals: 0,
      openDealsStop: 0,
      toggleDealsToSmartTrade: false,
      toggleCloseDealAfterTimeout: false,
      closeDealAfterTimeoutSelector: {
        list: CLOSE_DEAL_AFTER_TIMEOUT_LIST,
        value: null
      },
      closeDealAfterTimeoutAmount: 0
    },
    takeProfit: {
      targetProfitPercentage: 0,
      takeProfitTypeSelector: {
        list: PROFIT_TYPE_LIST,
        value: null
      },
      toggleTrailingPercent: false,
      trailingDeviationPercentage: 0,
      toggleTrailingConditions: false,
      minimumProfitPercentage: 0,
      minimumProfitTypeSelector: {
        list: MINIMUM_PROFIT_TYPE_LIST,
        value: null
      },
      conditionFields: [defaultValueHelper.getConditionsFields()]
    }
  },
  modals: {
    createBot: {
      show: false
    },
    usedAmountStep: {
      show: false
    },
    botUsedPaperAccount: {
      show: false
    },
    warning: {
      show: false
    }
  },
  filters: {
    open: false,
    exchangeSelector: {
      list: EXCHANGE_LIST,
      value: EXCHANGE_LIST[0]
    },
    marketSelector: {
      list: MARKET_LIST,
      value: MARKET_LIST[0]
    },
    tradingPairSelector: {
      list: TRADING_PAIR_LIST,
      value: TRADING_PAIR_LIST[0]
    },
    dealStartConditionSelector: {
      list: CONDITIONS_CLOSING_BOT_LIST,
      value: CONDITIONS_CLOSING_BOT_LIST[0]
    },
    botType: '',
    strategyType: '',
    sliderDaysRunning: [5, 200],
    daysRunningFrom: 5,
    daysRunningTo: 200,
  },
  pairsBlackList: {
    open: false,
    pairsBlackListSelector: {
      list: PAIRS_BLACK_LIST,
      value: PAIRS_BLACK_LIST[0]
    },
  },
  myBotsTable: {
    filtersSelector: {
      list: FILTERS_LIST,
      value: FILTERS_LIST[0]
    },
    list: []
  },
  sorting: {
    name: {
      isDesc: true
    },
    deals: {
      isDesc: true
    },
    takeProfit: {
      isDesc: true
    },
    avgDailyProfit: {
      isDesc: true
    },
    exchange: {
      isDesc: true
    },
    bot: {
      isDesc: true
    },
    pair: {
      isDesc: true
    },
    profit: {
      isDesc: true
    },
    volume: {
      isDesc: true
    },
    status: {
      isDesc: true
    },
    created: {
      isDesc: true
    },
  },
  applyBotConfiguration: {
    name: '',
    maxAmountBotUsage: 0,
    ignoreCopyBot: false,
    start: true
  },
  myDealsTable: {
    list: [],
    collapsed: []
  },
  assetsData: {},
  coinData: {},
  pairCoinList: [],

  ...customBotsActions.actionHandlers,
  ...customBotsEffects.actionHandlers,
  ...serviceEffects.actionHandlers
};

export const CustomBotsState = createContextStore(CUSTOM_BOTS_MODEL);
