import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';

const actionTypes = {
    REMAKE_DASHBOARD_TAB_TOGGLED: 'REMAKE_DASHBOARD_TAB#TOGGLED',
};

const toggleContent = (state, payload) => {
    const { id } = payload;
    
    switch(id) {
    case 'defi': state.defi.show = !state.defi.show; break;
    case 'cefi': state.cefi.show = !state.cefi.show; break;
    case 'lending': state.lending.show = !state.lending.show; break;
    case 'cefi_balances': state.cefi.cefi_balances = !state.cefi.cefi_balances; break;
    case 'vaults': state.showVaults = !state.showVaults; break;
    case 'yields': state.showYields = !state.showYields; break;
    case 'bots': state.showBots = !state.showBots; break;
    }
};

const actionHandlers = {
    [actionTypes.REMAKE_DASHBOARD_TAB_TOGGLED]: action((state, payload) => toggleContent(state, payload)),

    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const uiActions = {
    actionTypes,
    actionHandlers
};