import React from 'react';
import { CustomDropdown } from '../../../components/utility/custom-dropdown/view';
import '../styles/_account_settings.scss';
import { StandardOptions } from '../../../components/utility/standard-options/view';
import { constants } from '../../../constants/constants';

const { SMART_TRADE_BOT: { PACKS, ACCOUNT_SETTINGS } } = constants;

export const AccountSettings = () => {
    return <div className="account-settings">
        <div className="d-flex justify-content-end item-more pb-2">
            <StandardOptions more={{ ...PACKS.STANDARD_OPTIONS_SCHEMA }}/>
        </div>
        <div className="account-settings-items">
            <div className="item font-size-12">
                <div className="position-absolute item-label font-weight-medium">{ACCOUNT_SETTINGS.ACCOUNT_EXCHANGE.label}</div>
                <CustomDropdown/>
            </div>
            <div className="item font-size-12">
                <div className="position-absolute item-label font-weight-medium">{ACCOUNT_SETTINGS.MAIN_COIN.label}</div>
                <CustomDropdown/>
            </div>
            <div className="item font-size-12">
                <div className="position-absolute item-label font-weight-medium">{ACCOUNT_SETTINGS.COIN_PAIR.label}</div>
                <CustomDropdown/>
            </div>
        </div>
    </div>;
};