import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Collapse } from 'react-bootstrap';
import { constants } from '../constants';
import { TableCompoundTemplate } from './tables/tableCompoundTemplate';
import { ButtonCustom } from '../../../components/utility/custom-buttons/view';
import numeral from 'numeral';
import { dataHelper } from '../dataHelpers/dataHelper';

const { COMPOUND: { HEADING, GET_COVERED } } = constants;

export const CompoundContent = props => {
    const { actions, state } = props;

    const totalBalance = dataHelper.getCompoundSum(state.compound.data);
    
    return <div className="compound-content">
        <div className="compound-section">
            <div className="compound-section-header align-items-center">
                <div className="compound-section-header-left d-flex align-items-center">
                    <img className="me-2" width={22} height={22} src={`../dashboard-library/protocols/${HEADING.toLowerCase()}.svg`} />
                    <div className="font-weight-medium me-4">{HEADING} (ETHEREUM MAINNET)</div>
                    <div></div>
                </div>
                <div></div>
                <div className="compound-balance text-end me-3">
                    <div className="balance-value">${totalBalance}</div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <i onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TABLE_COLLAPSED]({ table: 'compound' })}
                        className={`table-toggler fa-solid fa-chevron-${ state.compound.open ? 'up' : 'down' }`}></i>
                </div>
            </div>
            <Collapse in={state.compound.open}>
                <div className="collapse-content">
                    <div className="compound-section-content">
                        <TableCompoundTemplate />
                    </div>
                </div>
            </Collapse>
        </div>
    </div>;
};