import React from 'react';
import { uiActions } from '../actions/uiActions';
import { Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { utils } from '../../../utils/utils';
import { Details } from './tokenInfoModalTabs/detailsContent';
import { PriceChart } from './tokenInfoModalTabs/priceChartContent';

const { WALLET_MODAL_TABS } = constants;

export const TokenInfoModal = props => {
    const { show, actions, state } = props;

    const handleClose = () => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ name: 'close-modal', modal: 'token' });
    const tabToggleHandle = (heading, tabPlace) => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TAB_TOGGLED]({ tab: heading, tabPlace });
    const activeTabClass = tab => tab === state.modalToken.tab ? 'active' : '';
    const img = state.modalToken.token.valueSymbol ? state.modalToken.token.valueSymbol.toLowerCase() : '';

    return <Modal
        show={show}
        size="lg"
        centered
        className="add-exchange token-info-modal"
        backdrop="static"
        container={document.querySelector('.wrapper')}
        onHide={handleClose}
    >
        <Modal.Body>
            <div className="text-end cursor-pointer cancel-icon" onClick={handleClose}>
                <i className="icon-cancel"></i>
            </div>
            <div className="font-weight-medium text-start modal-main-header d-flex align-items-center">
                <img onError={utils.imgErrorHandle} src={`../coins-full-library/${img}.svg`} className="asset-icon"/>
                <span>{state.modalToken.token.valueSymbol}</span>
            </div>
            <div className="modal-token-main-content">
                <div className="modal-token-tabs d-flex align-items-center">
                    { WALLET_MODAL_TABS.map((tab, index) => <div onClick={() => tabToggleHandle(tab, 'wallet-modal')} 
                        className={`modal-token-tab me-5 ${activeTabClass(tab)}`} key={index}>{tab}</div>) }
                </div>
                {state.modalToken.tab === 'Details' ? 
                    <Details state={state} data={state.modalToken.token} actions={actions} /> : 
                    <PriceChart state={state} data={state.modalToken.token} actions={actions}/>}
            </div>
        </Modal.Body>
    </Modal>;
};