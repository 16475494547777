import React from "react";


export const mockData = {
    showMore: {
        poolsOpen: false,
        tokensOpen: false,
        chainpen: false
      },
    "protocols": [
        {
            "asset": "Wallet",
            "value": "1.61",
            "fraction": 33
        },
        {
            "asset": "Wallet1",
            "value": "0.61",
            "fraction": 33
        },
        {
            "asset": "Wallet2",
            "value": "1.00",
            "fraction": 34
        }
    ],
    "date": {
        "currentDate": "",
        "currentTime": ""
    },
    "copy": {
        "copied": false
    },
    "tab": "Portfolio",
    "requestBody": null,
    "wallet": {
        "totalBalance": "0.614224134877430467",
        "allTotalBalance": "17.45",
        "tokenAllocation": [
            {
                "asset": "1INCH",
                "fraction": "2"
            },
            {
                "asset": "Fulcrum",
                "fraction": "1"
            },
            {
                "asset": "Orion Money",
                "fraction": "0"
            },
            {
                "asset": "Mars Eco",
                "fraction": "0"
            },
            {
                "asset": "Test1",
                "fraction": "2"
            },
            {
                "asset": "Test2",
                "fraction": "3"
            }
        ],
        "available": "0.61",
        "change": "25",
        "invested": "16.83",
        "blackList": {
            "modal": false,
            "tempData": [],
            "transportData": [],
            "data": [
                {
                    "contractAddress": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
                    "blockchainName": "BSC",
                    "valueSymbol": "SXP"
                },
                {
                    "contractAddress": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
                    "blockchainName": "BSC",
                    "valueSymbol": "BNB"
                },
                {
                    "contractAddress": "0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b",
                    "blockchainName": "BSC",
                    "valueSymbol": "XCN"
                },
                {
                    "contractAddress": "0x55d398326f99059ff775485246999027b3197955",
                    "blockchainName": "BSC",
                    "valueSymbol": "USDT"
                },
                {
                    "contractAddress": "0xa865197a84e780957422237b5d152772654341f3",
                    "blockchainName": "BSC",
                    "valueSymbol": "OLE"
                },
                {
                    "contractAddress": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
                    "blockchainName": "FTM",
                    "valueSymbol": "FTM"
                },
                {
                    "contractAddress": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    "blockchainName": "BSC",
                    "valueSymbol": "BUSD"
                },
                {
                    "contractAddress": "0x4f6a82bb5fd0996158c9ac6bd3605489cad82d8d",
                    "blockchainName": "BSC",
                    "valueSymbol": "RiftDao.net"
                },
                {
                    "contractAddress": "0xa07c5b74c9b40447a954e1466938b865b6bbea36",
                    "blockchainName": "BSC",
                    "valueSymbol": "vBNB"
                },
                {
                    "contractAddress": "0xfd5840cd36d94d7229439859c0112a4185bc0255",
                    "blockchainName": "BSC",
                    "valueSymbol": "vUSDT"
                },
                {
                    "contractAddress": "0x0000000000000000000000000000000000001010",
                    "blockchainName": "MATIC",
                    "valueSymbol": "MATIC"
                },
                {
                    "contractAddress": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
                    "blockchainName": "MATIC",
                    "valueSymbol": "USDC"
                },
                {
                    "contractAddress": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                    "blockchainName": "MATIC",
                    "valueSymbol": "USDT"
                },
                {
                    "contractAddress": "0x949e0a0672299e6fcd6bec3bd1735d6647b20618",
                    "blockchainName": "BSC",
                    "valueSymbol": "ART"
                },
                {
                    "contractAddress": "0xda2d21872999e700a715a1bda3153eb9079770bb",
                    "blockchainName": "BSC",
                    "valueSymbol": "APC"
                },
                {
                    "contractAddress": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                    "blockchainName": "BSC",
                    "valueSymbol": "Cake"
                },
                {
                    "contractAddress": "0xa02a0b2d67d4fa48677a79cadc483e114049916d",
                    "blockchainName": "BSC",
                    "valueSymbol": "rDRIP"
                },
                {
                    "contractAddress": "0xe3e1147acd39687a25ca7716227c604500f5c31a",
                    "blockchainName": "BSC",
                    "valueSymbol": "BSC-Coin"
                },
                {
                    "contractAddress": "0x4b97c9bee3677797034033337f32115115867a62",
                    "blockchainName": "BSC",
                    "valueSymbol": "EURC"
                },
                {
                    "contractAddress": "0x9fffe0385dc7584faeeac59f56eedfc442bba622",
                    "blockchainName": "BSC",
                    "valueSymbol": "USDD"
                },
                {
                    "contractAddress": "0x1385e68e3b5ea66fc50a221f8dcbabbfd3ee282b",
                    "blockchainName": "BSC",
                    "valueSymbol": "BTG"
                },
                {
                    "contractAddress": "0x725e02c7f9168f45b3699cfb7c262fb6dd355e84",
                    "blockchainName": "BSC",
                    "valueSymbol": "Atom"
                }
            ],
            "filteredData": [
                {
                    "contractAddress": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
                    "blockchainName": "BSC",
                    "valueSymbol": "SXP"
                },
                {
                    "contractAddress": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
                    "blockchainName": "BSC",
                    "valueSymbol": "BNB"
                },
                {
                    "contractAddress": "0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b",
                    "blockchainName": "BSC",
                    "valueSymbol": "XCN"
                },
                {
                    "contractAddress": "0x55d398326f99059ff775485246999027b3197955",
                    "blockchainName": "BSC",
                    "valueSymbol": "USDT"
                },
                {
                    "contractAddress": "0xa865197a84e780957422237b5d152772654341f3",
                    "blockchainName": "BSC",
                    "valueSymbol": "OLE"
                },
                {
                    "contractAddress": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
                    "blockchainName": "FTM",
                    "valueSymbol": "FTM"
                },
                {
                    "contractAddress": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    "blockchainName": "BSC",
                    "valueSymbol": "BUSD"
                },
                {
                    "contractAddress": "0x4f6a82bb5fd0996158c9ac6bd3605489cad82d8d",
                    "blockchainName": "BSC",
                    "valueSymbol": "RiftDao.net"
                },
                {
                    "contractAddress": "0xa07c5b74c9b40447a954e1466938b865b6bbea36",
                    "blockchainName": "BSC",
                    "valueSymbol": "vBNB"
                },
                {
                    "contractAddress": "0xfd5840cd36d94d7229439859c0112a4185bc0255",
                    "blockchainName": "BSC",
                    "valueSymbol": "vUSDT"
                },
                {
                    "contractAddress": "0x0000000000000000000000000000000000001010",
                    "blockchainName": "MATIC",
                    "valueSymbol": "MATIC"
                },
                {
                    "contractAddress": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
                    "blockchainName": "MATIC",
                    "valueSymbol": "USDC"
                },
                {
                    "contractAddress": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                    "blockchainName": "MATIC",
                    "valueSymbol": "USDT"
                },
                {
                    "contractAddress": "0x949e0a0672299e6fcd6bec3bd1735d6647b20618",
                    "blockchainName": "BSC",
                    "valueSymbol": "ART"
                },
                {
                    "contractAddress": "0xda2d21872999e700a715a1bda3153eb9079770bb",
                    "blockchainName": "BSC",
                    "valueSymbol": "APC"
                },
                {
                    "contractAddress": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                    "blockchainName": "BSC",
                    "valueSymbol": "Cake"
                },
                {
                    "contractAddress": "0xa02a0b2d67d4fa48677a79cadc483e114049916d",
                    "blockchainName": "BSC",
                    "valueSymbol": "rDRIP"
                },
                {
                    "contractAddress": "0xe3e1147acd39687a25ca7716227c604500f5c31a",
                    "blockchainName": "BSC",
                    "valueSymbol": "BSC-Coin"
                },
                {
                    "contractAddress": "0x4b97c9bee3677797034033337f32115115867a62",
                    "blockchainName": "BSC",
                    "valueSymbol": "EURC"
                },
                {
                    "contractAddress": "0x9fffe0385dc7584faeeac59f56eedfc442bba622",
                    "blockchainName": "BSC",
                    "valueSymbol": "USDD"
                },
                {
                    "contractAddress": "0x1385e68e3b5ea66fc50a221f8dcbabbfd3ee282b",
                    "blockchainName": "BSC",
                    "valueSymbol": "BTG"
                },
                {
                    "contractAddress": "0x725e02c7f9168f45b3699cfb7c262fb6dd355e84",
                    "blockchainName": "BSC",
                    "valueSymbol": "Atom"
                }
            ]
        },
        "open": false,
        "isLoading": false,
        "balance": null,
        "claim": 0,
        "pagination": {
            "hasMore": false
        },
        "totalUSD": "0.61",
        "loader": false,
        "data": [
            {
                "blockchainName": "FTM",
                "contractAddress": "0x049d68029688eabf473097a2fc38ef61633a3c7a",
                "valueSymbol": "fUSDT",
                "valueDecimals": 6,
                "value": "406979",
                "valueHuman": "0.406979",
                "usdValue": "0.406979",
                "usdPrice": "1"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
                "valueSymbol": "QUICK",
                "valueDecimals": 18,
                "value": "3000000000000000000",
                "valueHuman": "3",
                "usdValue": "0.183620541402676251",
                "usdPrice": "0.061206847134225417"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe",
                "valueSymbol": "CHR",
                "valueDecimals": 6,
                "value": "102050",
                "valueHuman": "0.10205",
                "usdValue": "0.01558873815573227",
                "usdPrice": "0.15275588589644557"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240",
                "valueSymbol": "SDAO",
                "valueDecimals": 18,
                "value": "16696388752505790",
                "valueHuman": "0.01669638875250579",
                "usdValue": "0.005316906770960252",
                "usdPrice": "0.31844651258268599"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
                "valueSymbol": "AVAX",
                "valueDecimals": 18,
                "value": "132171740864354",
                "valueHuman": "0.000132171740864354",
                "usdValue": "0.002521220782290166",
                "usdPrice": "19.075339144376251971"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xe5417af564e4bfda1c483642db72007871397896",
                "valueSymbol": "GNS",
                "valueDecimals": 18,
                "value": "53666848566910",
                "valueHuman": "0.00005366684856691",
                "usdValue": "0.000197727765771529",
                "usdPrice": "3.684355818378425487"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x0c5fd163d908ff566d9b2a11501d91bfdd02d1c2",
                "valueSymbol": "ANW",
                "valueDecimals": 18,
                "value": "2080666600000000000000",
                "valueHuman": "2080.6666",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xd3fdd06285d2649337800f00b41d07801c9f5715",
                "valueSymbol": "B-50WETH-50USDC-gauge",
                "valueDecimals": 18,
                "value": "55062570900214463",
                "valueHuman": "0.055062570900214463",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x4dbbfa7795d301bb12c2f9c03549bc7128bc9a12",
                "valueSymbol": "PancakeLPS.com",
                "valueDecimals": 18,
                "value": "400000000000000000000",
                "valueHuman": "400",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x4ae1ad0c980efbfbbdeb7fc9a4db89b8a173dd9f",
                "valueSymbol": "MCS",
                "valueDecimals": 18,
                "value": "99700000000000000000",
                "valueHuman": "99.7",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x6d0391d6df6d0db4b978f1687f3e4a6eab764516",
                "valueSymbol": "ETHW",
                "valueDecimals": 6,
                "value": "510000",
                "valueHuman": "0.51",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xb5dfba4c21a4052e5802988f8b9ce32211ecaf03",
                "valueSymbol": "MCS",
                "valueDecimals": 18,
                "value": "130000000000000000000",
                "valueHuman": "130",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xe32af68e3e23dd01441f598b1d967a0bf7c6b407",
                "valueSymbol": "B-POLYDEFI-gauge",
                "valueDecimals": 18,
                "value": "185037275807860075",
                "valueHuman": "0.185037275807860075",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x7e6202903275772044198d07b8a536cc064f8480",
                "valueSymbol": "CGB",
                "valueDecimals": 6,
                "value": "211000000",
                "valueHuman": "211",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x806ebed1d49f00efa09c9d2e0cbd9c53fb44d5e0",
                "valueSymbol": "AIV",
                "valueDecimals": 6,
                "value": "3142000",
                "valueHuman": "3.142",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1",
                "valueSymbol": "vDAI",
                "valueDecimals": 8,
                "value": "1860690042",
                "valueHuman": "18.60690042",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x5028497af0c9a54ea8c6d42a054c0341b9fc6168",
                "valueSymbol": "dUSD-LP",
                "valueDecimals": 18,
                "value": "1976509557259436962",
                "valueHuman": "1.976509557259436962",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x4e35b8b5deff8786aae88edd8875ee8aa1d5d7c4",
                "valueSymbol": "https://wincoin.win/",
                "valueDecimals": 18,
                "value": "1000000000000000000",
                "valueHuman": "1",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x0f09f70ed59202c77ac667f574a5f79bc65cea48",
                "valueSymbol": "BPTC-gauge",
                "valueDecimals": 18,
                "value": "9873526090215957",
                "valueHuman": "0.009873526090215957",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xa39af17ce4a8eb807e076805da1e2b8ea7d0755b",
                "valueSymbol": "Cake-LP",
                "valueDecimals": 18,
                "value": "402632423133014775",
                "valueHuman": "0.402632423133014775",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "AVAXc",
                "valueSymbol": "AVAX",
                "valueDecimals": 18,
                "value": "79329080414436960",
                "valueHuman": "0.07932908041443696",
                "usdValue": "0",
                "usdPrice": "0",
                "contractAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xc3238c3b7b8e32588a49c751aed808368e85122d",
                "valueSymbol": "3worlds.io",
                "valueDecimals": 9,
                "value": "12000000000000",
                "valueHuman": "12000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x068ff98072d3eb848d012e3390703bb507729ed6",
                "valueSymbol": "B-POLYBASE-gauge",
                "valueDecimals": 18,
                "value": "372953206061565478",
                "valueHuman": "0.372953206061565478",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "FTM",
                "contractAddress": "0xcaef42682040a19e09eb8a6773f17da81b0604c1",
                "valueSymbol": "2XCO",
                "valueDecimals": 8,
                "value": "9500000000000",
                "valueHuman": "95000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xf284121100433b49a04618978715fbf2049e1a4c",
                "valueSymbol": "Visit [ arcy.io ] and claim special rewards",
                "valueDecimals": 18,
                "value": "5681000000000000000000",
                "valueHuman": "5681",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "FTM",
                "contractAddress": "0x10657d9bb24b4cd808e42ef4ad4c0fd3b07ddb21",
                "valueSymbol": "2XBNB",
                "valueDecimals": 8,
                "value": "200000000000",
                "valueHuman": "2000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "valueSymbol": "BNB",
                "valueDecimals": 18,
                "value": "1103191364912815",
                "valueHuman": "0.001103191364912815",
                "usdValue": "0",
                "usdPrice": "0",
                "contractAddress": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
            },
            {
                "blockchainName": "MATIC",
                "valueSymbol": "MATIC",
                "valueDecimals": 18,
                "value": "4139876973867886239",
                "valueHuman": "4.139876973867886239",
                "usdValue": "0",
                "usdPrice": "0",
                "contractAddress": "0x0000000000000000000000000000000000001010"
            },
            {
                "blockchainName": "FTM",
                "valueSymbol": "FTM",
                "valueDecimals": 18,
                "value": "399330497344789955",
                "valueHuman": "0.399330497344789955",
                "usdValue": "0",
                "usdPrice": "0",
                "contractAddress": "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x2231e1c01056aebab3113d684b034b50a99a56c7",
                "valueSymbol": "0gas.io",
                "valueDecimals": 9,
                "value": "16000000000000",
                "valueHuman": "16000",
                "usdValue": "0",
                "usdPrice": "0"
            }
        ],
        "filteredData": [
            {
                "blockchainName": "FTM",
                "contractAddress": "0x049d68029688eabf473097a2fc38ef61633a3c7a",
                "valueSymbol": "fUSDT",
                "valueDecimals": 6,
                "value": "406979",
                "valueHuman": "0.406979",
                "usdValue": "0.406979",
                "usdPrice": "1"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
                "valueSymbol": "QUICK",
                "valueDecimals": 18,
                "value": "3000000000000000000",
                "valueHuman": "3",
                "usdValue": "0.183620541402676251",
                "usdPrice": "0.061206847134225417"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe",
                "valueSymbol": "CHR",
                "valueDecimals": 6,
                "value": "102050",
                "valueHuman": "0.10205",
                "usdValue": "0.01558873815573227",
                "usdPrice": "0.15275588589644557"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x90ed8f1dc86388f14b64ba8fb4bbd23099f18240",
                "valueSymbol": "SDAO",
                "valueDecimals": 18,
                "value": "16696388752505790",
                "valueHuman": "0.01669638875250579",
                "usdValue": "0.005316906770960252",
                "usdPrice": "0.31844651258268599"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
                "valueSymbol": "AVAX",
                "valueDecimals": 18,
                "value": "132171740864354",
                "valueHuman": "0.000132171740864354",
                "usdValue": "0.002521220782290166",
                "usdPrice": "19.075339144376251971"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xe5417af564e4bfda1c483642db72007871397896",
                "valueSymbol": "GNS",
                "valueDecimals": 18,
                "value": "53666848566910",
                "valueHuman": "0.00005366684856691",
                "usdValue": "0.000197727765771529",
                "usdPrice": "3.684355818378425487"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x0c5fd163d908ff566d9b2a11501d91bfdd02d1c2",
                "valueSymbol": "ANW",
                "valueDecimals": 18,
                "value": "2080666600000000000000",
                "valueHuman": "2080.6666",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xd3fdd06285d2649337800f00b41d07801c9f5715",
                "valueSymbol": "B-50WETH-50USDC-gauge",
                "valueDecimals": 18,
                "value": "55062570900214463",
                "valueHuman": "0.055062570900214463",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x4dbbfa7795d301bb12c2f9c03549bc7128bc9a12",
                "valueSymbol": "PancakeLPS.com",
                "valueDecimals": 18,
                "value": "400000000000000000000",
                "valueHuman": "400",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x4ae1ad0c980efbfbbdeb7fc9a4db89b8a173dd9f",
                "valueSymbol": "MCS",
                "valueDecimals": 18,
                "value": "99700000000000000000",
                "valueHuman": "99.7",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x6d0391d6df6d0db4b978f1687f3e4a6eab764516",
                "valueSymbol": "ETHW",
                "valueDecimals": 6,
                "value": "510000",
                "valueHuman": "0.51",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xb5dfba4c21a4052e5802988f8b9ce32211ecaf03",
                "valueSymbol": "MCS",
                "valueDecimals": 18,
                "value": "130000000000000000000",
                "valueHuman": "130",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xe32af68e3e23dd01441f598b1d967a0bf7c6b407",
                "valueSymbol": "B-POLYDEFI-gauge",
                "valueDecimals": 18,
                "value": "185037275807860075",
                "valueHuman": "0.185037275807860075",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x7e6202903275772044198d07b8a536cc064f8480",
                "valueSymbol": "CGB",
                "valueDecimals": 6,
                "value": "211000000",
                "valueHuman": "211",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x806ebed1d49f00efa09c9d2e0cbd9c53fb44d5e0",
                "valueSymbol": "AIV",
                "valueDecimals": 6,
                "value": "3142000",
                "valueHuman": "3.142",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1",
                "valueSymbol": "vDAI",
                "valueDecimals": 8,
                "value": "1860690042",
                "valueHuman": "18.60690042",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x5028497af0c9a54ea8c6d42a054c0341b9fc6168",
                "valueSymbol": "dUSD-LP",
                "valueDecimals": 18,
                "value": "1976509557259436962",
                "valueHuman": "1.976509557259436962",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x4e35b8b5deff8786aae88edd8875ee8aa1d5d7c4",
                "valueSymbol": "https://wincoin.win/",
                "valueDecimals": 18,
                "value": "1000000000000000000",
                "valueHuman": "1",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x0f09f70ed59202c77ac667f574a5f79bc65cea48",
                "valueSymbol": "BPTC-gauge",
                "valueDecimals": 18,
                "value": "9873526090215957",
                "valueHuman": "0.009873526090215957",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xa39af17ce4a8eb807e076805da1e2b8ea7d0755b",
                "valueSymbol": "Cake-LP",
                "valueDecimals": 18,
                "value": "402632423133014775",
                "valueHuman": "0.402632423133014775",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "AVAXc",
                "valueSymbol": "AVAX",
                "valueDecimals": 18,
                "value": "79329080414436960",
                "valueHuman": "0.07932908041443696",
                "usdValue": "0",
                "usdPrice": "0",
                "contractAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0xc3238c3b7b8e32588a49c751aed808368e85122d",
                "valueSymbol": "3worlds.io",
                "valueDecimals": 9,
                "value": "12000000000000",
                "valueHuman": "12000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0x068ff98072d3eb848d012e3390703bb507729ed6",
                "valueSymbol": "B-POLYBASE-gauge",
                "valueDecimals": 18,
                "value": "372953206061565478",
                "valueHuman": "0.372953206061565478",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "FTM",
                "contractAddress": "0xcaef42682040a19e09eb8a6773f17da81b0604c1",
                "valueSymbol": "2XCO",
                "valueDecimals": 8,
                "value": "9500000000000",
                "valueHuman": "95000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "MATIC",
                "contractAddress": "0xf284121100433b49a04618978715fbf2049e1a4c",
                "valueSymbol": "Visit [ arcy.io ] and claim special rewards",
                "valueDecimals": 18,
                "value": "5681000000000000000000",
                "valueHuman": "5681",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "FTM",
                "contractAddress": "0x10657d9bb24b4cd808e42ef4ad4c0fd3b07ddb21",
                "valueSymbol": "2XBNB",
                "valueDecimals": 8,
                "value": "200000000000",
                "valueHuman": "2000",
                "usdValue": "0",
                "usdPrice": "0"
            },
            {
                "blockchainName": "BSC",
                "contractAddress": "0x2231e1c01056aebab3113d684b034b50a99a56c7",
                "valueSymbol": "0gas.io",
                "valueDecimals": 9,
                "value": "16000000000000",
                "valueHuman": "16000",
                "usdValue": "0",
                "usdPrice": "0"
            }
        ]
    },
    "venus": {
        "pagination": {
            "hasMore": false
        },
        "data": [
            {
                "token": {
                    "name": "Binance Beacon ETH",
                    "symbol": "BETH",
                    "address": "0x250632378e573c6be1ac2f97fcdf00515d0aa91b",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.42",
                "borrow_apy": "-2.8394",
                "supplyVenusApy": "0.82",
                "borrowVenusApy": "4.46"
            },
            {
                "token": {
                    "name": "Tether USD",
                    "symbol": "USDT",
                    "address": "0x55d398326f99059ff775485246999027b3197955",
                    "decimals": 18
                },
                "supply_amount": "6.136633113362532",
                "borrow_amount": "0",
                "supply_price": "6.14",
                "borrow_price": "0.00",
                "supply_apy": "0.93",
                "borrow_apy": "-2.2903",
                "supplyVenusApy": "0.22",
                "borrowVenusApy": "0.49"
            },
            {
                "token": {
                    "name": "Polkadot Token",
                    "symbol": "DOT",
                    "address": "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "2.03",
                "borrow_apy": "-7.4748",
                "supplyVenusApy": "0.93",
                "borrowVenusApy": "2.70"
            },
            {
                "token": {
                    "name": "TRON",
                    "symbol": "TRX",
                    "address": "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "4.24",
                "borrow_apy": "-10.3675",
                "supplyVenusApy": "3.14",
                "borrowVenusApy": "6.04"
            },
            {
                "token": {
                    "name": "BUSD Token",
                    "symbol": "BUSD",
                    "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "1.00",
                "borrow_apy": "-2.3774",
                "supplyVenusApy": "0.23",
                "borrowVenusApy": "0.48"
            },
            {
                "token": {
                    "name": "Dogecoin",
                    "symbol": "DOGE",
                    "address": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
                    "decimals": 8
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.62",
                "borrow_apy": "-5.1704",
                "supplyVenusApy": "0.23",
                "borrowVenusApy": "1.52"
            },
            {
                "token": {
                    "name": "Venus",
                    "symbol": "XVS",
                    "address": "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.00",
                "borrow_apy": "-0.0006",
                "supplyVenusApy": "0.00",
                "borrowVenusApy": "0.00"
            },
            {
                "token": {
                    "name": "TrueUSD",
                    "symbol": "TUSD",
                    "address": "0x14016e85a25aeb13065688cafb43044c2ef86784",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "1.81",
                "borrow_apy": "-3.3971",
                "supplyVenusApy": "0.43",
                "borrowVenusApy": "0.65"
            },
            {
                "token": {
                    "name": "Cardano Token",
                    "symbol": "ADA",
                    "address": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "3.71",
                "borrow_apy": "-9.7566",
                "supplyVenusApy": "0.86",
                "borrowVenusApy": "1.76"
            },
            {
                "token": {
                    "name": "Matic Token",
                    "symbol": "MATIC",
                    "address": "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "2.39",
                "borrow_apy": "-8.0245",
                "supplyVenusApy": "1.31",
                "borrowVenusApy": "3.45"
            },
            {
                "token": {
                    "name": "Dai Token",
                    "symbol": "DAI",
                    "address": "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
                    "decimals": 18
                },
                "supply_amount": "0.4001149132699996",
                "borrow_amount": "0",
                "supply_price": "0.40",
                "borrow_price": "0.00",
                "supply_apy": "1.87",
                "borrow_apy": "-3.2527",
                "supplyVenusApy": "1.14",
                "borrowVenusApy": "1.78"
            },
            {
                "token": {
                    "name": "USD Coin",
                    "symbol": "USDC",
                    "address": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "1.31",
                "borrow_apy": "-2.7178",
                "supplyVenusApy": "0.45",
                "borrowVenusApy": "0.83"
            },
            {
                "token": {
                    "name": "Ethereum Token",
                    "symbol": "ETH",
                    "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.38",
                "borrow_apy": "-2.4863",
                "supplyVenusApy": "0.50",
                "borrowVenusApy": "2.60"
            },
            {
                "token": {
                    "name": "BTCB Token",
                    "symbol": "BTCB",
                    "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.25",
                "borrow_apy": "-1.9887",
                "supplyVenusApy": "0.42",
                "borrowVenusApy": "2.71"
            },
            {
                "token": {
                    "name": "UST",
                    "symbol": "UST",
                    "address": "0x3d4350cd54aef9f9b2c29435e0fa809957b3f30a",
                    "decimals": 6
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.59",
                "borrow_apy": "-53.1089",
                "supplyVenusApy": "0.00",
                "borrowVenusApy": "0.00"
            },
            {
                "token": {
                    "name": "Filecoin",
                    "symbol": "FIL",
                    "address": "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.93",
                "borrow_apy": "-6.0911",
                "supplyVenusApy": "1.03",
                "borrowVenusApy": "5.33"
            },
            {
                "token": {
                    "name": "Litecoin Token",
                    "symbol": "LTC",
                    "address": "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.49",
                "borrow_apy": "-4.2805",
                "supplyVenusApy": "0.66",
                "borrowVenusApy": "4.58"
            },
            {
                "token": {
                    "name": "PancakeSwap Token",
                    "symbol": "Cake",
                    "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "2.72",
                "borrow_apy": "-8.7355",
                "supplyVenusApy": "0.34",
                "borrowVenusApy": "0.80"
            },
            {
                "token": {
                    "name": "Bitcoin Cash Token",
                    "symbol": "BCH",
                    "address": "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "1.73",
                "borrow_apy": "-6.9742",
                "supplyVenusApy": "1.30",
                "borrowVenusApy": "4.14"
            },
            {
                "token": {
                    "name": "XRP Token",
                    "symbol": "XRP",
                    "address": "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.45",
                "borrow_apy": "-4.1457",
                "supplyVenusApy": "0.53",
                "borrowVenusApy": "3.89"
            },
            {
                "token": {
                    "name": "LUNA",
                    "symbol": "LUNA",
                    "address": "0x156ab3346823b651294766e23e6cf87254d68962",
                    "decimals": 6
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.00",
                "borrow_apy": "0.0000",
                "supplyVenusApy": "0.00",
                "borrowVenusApy": "0.00"
            },
            {
                "token": {
                    "name": "BNB",
                    "symbol": "BNB",
                    "address": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
                    "decimals": 18
                },
                "supply_amount": "0.005005446175181874",
                "borrow_amount": "0.005024579237707018",
                "supply_price": "1.61",
                "borrow_price": "1.61",
                "supply_apy": "4.52",
                "borrow_apy": "-8.4683",
                "supplyVenusApy": "0.33",
                "borrowVenusApy": "0.48"
            },
            {
                "token": {
                    "name": "Swipe",
                    "symbol": "SXP",
                    "address": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "3.005310620108486",
                "supply_price": "0.00",
                "borrow_price": "0.99",
                "supply_apy": "0.19",
                "borrow_apy": "-3.1640",
                "supplyVenusApy": "0.27",
                "borrowVenusApy": "3.65"
            },
            {
                "token": {
                    "name": "ChainLink Token",
                    "symbol": "LINK",
                    "address": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "0.36",
                "borrow_apy": "-3.8634",
                "supplyVenusApy": "0.64",
                "borrowVenusApy": "5.49"
            },
            {
                "token": {
                    "name": "Aave Token",
                    "symbol": "AAVE",
                    "address": "0xfb6115445bff7b52feb98650c87f44907e58f802",
                    "decimals": 18
                },
                "supply_amount": "0",
                "borrow_amount": "0",
                "supply_price": "0.00",
                "borrow_price": "0.00",
                "supply_apy": "2.98",
                "borrow_apy": "-9.2393",
                "supplyVenusApy": "2.75",
                "borrowVenusApy": "6.30"
            }
        ],
        "open": false,
        "loader": false
    },
    "compound": {
        "pagination": {
            "hasMore": false
        },
        "data": [],
        "open": false,
        "loader": false
    },
    "farms": {
        "pagination": {
            "hasMore": false
        },
        "data": [
            {
                "pool": {
                    "pid": "40",
                    "lptoken": {
                        "token1": {
                            "name": "Chroma",
                            "symbol": "CHR",
                            "address": "0xf9cec8d50f6c8ad3fb6dccec577e05aa32b224fe",
                            "decimals": 6
                        },
                        "price": 943533.4856264901,
                        "baseData": {
                            "name": "Pancake LPs",
                            "symbol": "Cake-LP",
                            "address": "0x6045931e511ef7e53a4a817f971e0ca28c758809",
                            "decimals": 18
                        },
                        "token0": {
                            "name": "BUSD Token",
                            "symbol": "BUSD",
                            "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                            "decimals": 18
                        }
                    },
                    "rewardTokens": [
                        {
                            "name": "PancakeSwap Token",
                            "symbol": "CAKE",
                            "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                            "price": 4.55,
                            "decimals": 18,
                            "tokenPerBlock": 2.03278
                        }
                    ]
                },
                "staked_amount": "0.000003600626842006",
                "reward_amount": "0.050756326638966032",
                "token0_amount": "1.6986559973391113",
                "token1_amount": "11.324373315594077",
                "staked_price": 3.3973119946782226,
                "reward_price": 0.23094128620729543,
                "apr": "0"
            }
        ],
        "open": false,
        "rewardedToken": {},
        "loader": false
    },
    "pools": {
        "pagination": {
            "hasMore": false
        },
        "data": [
            {
                "pool": {
                    "address": "0x86471019bf3f403083390ac47643062e15b0256e",
                    "staked_token": {
                        "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                        "symbol": "CAKE",
                        "name": "Pancake token"
                    },
                    "reward_token": {
                        "address": "0xb465f3cb6aba6ee375e12918387de1eac2301b05",
                        "symbol": "TRIVIA",
                        "name": "Trivian Token"
                    },
                    "end_block": "21069530"
                },
                "staked_amount": "0.746124719496150566",
                "reward_amount": "0.000000000000001729",
                "staked_price": "3.42471",
                "reward_price": "0.00000"
            },
            {
                "pool": {
                    "staked_token": {
                        "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                        "symbol": "CAKE",
                        "name": "Pancake token"
                    },
                    "reward_token": {
                        "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
                        "symbol": "CAKE(L)",
                        "name": "Pancake token"
                    },
                    "start_block": "1662717721",
                    "end_block": "1663322521"
                },
                "staked_amount": "0.355362454157415578",
                "reward_amount": "0",
                "staked_price": "1.63111",
                "reward_price": "0"
            }
        ],
        "open": false,
        "loader": false
    },
    "modalChart": {
        "show": false
    },
    "modalBlackList": {
        "show": false
    },
    "modalToken": {
        "tab": "Details",
        "token": {},
        "show": false
    },
    "addToken": {
        "show": false,
        "protocol": {},
        "address": ""
    },
    "chains": {
        "picked": "All",
        "all": {
            "symbol": "",
            "chain": "All"
        },
        "others": {
            "symbol": "",
            "chain": "Others"
        },
        "list": [
            {
                "request": "ETH",
                "symbol": "eth",
                "chain": "Ethereum"
            },
            {
                "request": "BSC",
                "symbol": "bsc",
                "chain": "BSC"
            },
            {
                "request": "MATIC",
                "symbol": "matic",
                "chain": "Polygon"
            },
            {
                "request": "AVAXc",
                "symbol": "avax",
                "chain": "Avalanche"
            },
            {
                "request": "FTM",
                "symbol": "ftm",
                "chain": "Fantom"
            },
            {
                "request": "ARBITRUM",
                "symbol": "arbitrum",
                "chain": "Arbitrum"
            }
        ]
    },
    "analytics": {
        "analyticsData": {
            "data": [],
            "heading": ""
        },
        "token": {
            "list": [
                {
                    "symbol": "1inch",
                    "protocol": "1INCH",
                    "value": 18,
                    "color": "#1F55FE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "fcm",
                    "protocol": "Fulcrum",
                    "value": 14,
                    "color": "#4674B6",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "orion",
                    "protocol": "Orion Money",
                    "value": 18,
                    "color": "#00A859",
                    "amount": "679.46"
                },
                {
                    "symbol": "mars",
                    "protocol": "Mars Ecosystem",
                    "value": 18,
                    "color": "#05CAFE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "knight",
                    "protocol": "KnightSwap",
                    "value": 9,
                    "color": "#ED32B5",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "spectrum",
                    "protocol": "Spectrum",
                    "value": 9,
                    "color": "#7828AD",
                    "amount": "679.46"
                },
                {
                    "symbol": "others",
                    "protocol": "Others",
                    "value": 14,
                    "color": "#F7931A",
                    "amount": "2,554.50"
                }
            ]
        },
        "protocol": {
            "list": [
                {
                    "symbol": "1inch",
                    "protocol": "1INCH",
                    "value": 25,
                    "color": "#1F55FE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "fcm",
                    "protocol": "Fulcrum",
                    "value": 8,
                    "color": "#4674B6",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "orion",
                    "protocol": "Orion Money",
                    "value": 4,
                    "color": "#00A859",
                    "amount": "679.46"
                },
                {
                    "symbol": "mars",
                    "protocol": "Mars Ecosystem",
                    "value": 23,
                    "color": "#05CAFE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "knight",
                    "protocol": "KnightSwap",
                    "value": 9,
                    "color": "#ED32B5",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "spectrum",
                    "protocol": "Spectrum",
                    "value": 9,
                    "color": "#7828AD",
                    "amount": "679.46"
                },
                {
                    "symbol": "others",
                    "protocol": "Others",
                    "value": 22,
                    "color": "#F7931A",
                    "amount": "2,554.50"
                }
            ]
        },
        "chain": {
            "list": [
                {
                    "symbol": "1inch",
                    "protocol": "1INCH",
                    "value": 25,
                    "color": "#1F55FE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "fcm",
                    "protocol": "Fulcrum",
                    "value": 12,
                    "color": "#4674B6",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "orion",
                    "protocol": "Orion Money",
                    "value": 8,
                    "color": "#00A859",
                    "amount": "679.46"
                },
                {
                    "symbol": "mars",
                    "protocol": "Mars Ecosystem",
                    "value": 25,
                    "color": "#05CAFE",
                    "amount": "3,127.99"
                },
                {
                    "symbol": "knight",
                    "protocol": "KnightSwap",
                    "value": 10,
                    "color": "#ED32B5",
                    "amount": "1,415.49"
                },
                {
                    "symbol": "spectrum",
                    "protocol": "Spectrum",
                    "value": 5,
                    "color": "#7828AD",
                    "amount": "679.46"
                },
                {
                    "symbol": "others",
                    "protocol": "Others",
                    "value": 15,
                    "color": "#F7931A",
                    "amount": "2,554.50"
                }
            ]
        }
    }
}