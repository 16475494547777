import numeral from 'numeral';

const getFavoritesData = state => {
    const coins = state.favorites;
    const prices = new Map(state.buffer);
    return (coins || []).reduce((acc, coin) => {
        const currentData = prices.get(`${coin}USDT`) || {};
        const { P, c } = currentData;
        const o = {
            label: coin,
            id: coin.toLowerCase(),
            price: numeral(c).format('0,0.00 $'),
            change: numeral(P).format('0.00')
        };
        return [...acc, o];
    }, []);
};

export const dataHelper = {
    getFavoritesData
};