import React from 'react';
import { constants } from '../constants';
import { nftGeneratorEffects } from '../actions/nftGeneratorEffects';

const { SELECTED } = constants;

export const MiddleContent = props => {
  const { state, actions } = props;

  const onItemToggle = item => {
    actions[nftGeneratorEffects.actionTypes.NFT_GENERATOR_ACTIVE_NFT_TOGGLE]({ item })
  };
  const activeClass = activeItem => state.activeItem === activeItem ? 'active' : '';

  return <div className="middle-content">
    <div className="middle-content-items">
      {state.items.map(item =>
        <div
          key={item.id}
          className={`item panel-wrapper ${activeClass(item.id)}`}
          onClick={() => onItemToggle(item)}>
          <img src={item.src} className="image"/>
          {item.id === state.activeItem ?
            <div className="selected-wrapper">
              <div className="selected">
                <div>
                  <img src="../dashboard-library/circle-check-white.svg"/>
                </div>
                <div className="font-size-12 font-weight-medium">{SELECTED}</div>
              </div>
            </div> : null
          }
        </div>)}
    </div>
  </div>;
};
