import React from 'react';
import CustomScroll from 'react-custom-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelopeOpen, faArchive, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { constants } from '../../constants/constants';
import { messagesEffects } from './messagesEffects';
import moment from 'moment';
import { isEmpty } from 'lodash';

const { MESSAGES: { ACTIONS } } = constants;

const renderControlButtons = (data, actions) => {
    const isReadDisabled = data.status !== 'NEW' && data.status !== 'URGENT' ? true : false;
    const isArchiveDisabled = data.status === 'ARCHIVED' ? true : false;
    return <div className="d-flex justify-content-end">
        <Button variant="link"
            size="sm"
            disabled={isReadDisabled}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_mark_read', data });
            }}>
            <FontAwesomeIcon icon={faEnvelopeOpen}/>
        </Button>
        <Button variant="link"
            size="sm"
            disabled={isArchiveDisabled}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_archive', data });
            }}>
            <FontAwesomeIcon icon={faArchive}/>
        </Button>
        <Button variant="link" size="sm"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_delete', data });
            }}>
            <FontAwesomeIcon icon={faTrash}/>
        </Button>
    </div>;
};

const renderHeader = (data, actions, visibility) => {
    const isMarkReadDisabled = isEmpty(data) || !data.some(d => d.status === 'NEW' || d.status === 'URGENT');
    const isClean = isEmpty(data);
    return <div className="d-flex flex-row justify-content-end message-control-buttons mb-2">
        <Button variant="link" size="sm"
            disabled={isMarkReadDisabled}
            onClick={() => actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_mark_all_read', data })}>
            <div className="font-size-10">{ACTIONS.MARKALLREAD}</div>
        </Button>
        <Button variant="link" size="sm" disabled={isClean}
            onClick={() => actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_delete_all', data })}>
            <div className="font-size-10">{ACTIONS.CLEARALL}</div>
        </Button>
        <Button variant="link" size="sm"
            onClick={() => actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_show_all', data })}>
            <div className="font-size-10">{!visibility ? ACTIONS.SHOWALL : ACTIONS.HIDEARCHIVED}</div>
        </Button>
    </div>;
};

const openModal = (e, data, actions) => {
    e.preventDefault();
    e.stopPropagation();
    actions[messagesEffects.actionTypes.MESSAGES_ACTION_MODAL_TOGGLED](data);

    if (data.status === 'NEW' || data.status === 'URGENT')
        actions[messagesEffects.actionTypes.MESSAGES_ACTION_MUTATED]({ type: 'message_mark_read', data });
};

const renderMessageCards = (data, actions) => {
    return data.map((d, key) => {
        const statusClass = d.status === 'NEW' ? 'active' : d.status === 'URGENT' ? 'u-active' : '';
        d.timeStamp = moment(d.time * 1000).format('DD/MM/YYYY');
        return <div key={key} className="message-card font-size-10 font-weight-light pe-2 ps-2">
            {key ? <div className="message-card-divider"></div> : null}
            <div className="d-flex flex-row justify-content-between mb-1 mt-3">
                <div className={`sign cursor-pointer pe-2 ${statusClass}`}
                    onClick={(event) => openModal(event, d, actions)}>
                    <FontAwesomeIcon icon={faCircle}/>
                </div>
                <div className="message-card-content d-flex flex-column flex-fill pe-2">
                    <div className="mb-1 cursor-pointer"
                        onClick={(event) => openModal(event, d, actions)}>
                        <div className="font-size-12 font-weight-bold">{d.title}
                            <span className="font-size-8 font-weight-thin ms-2">{d.timeStamp}</span>
                        </div>
                        <div className="">{d.content}</div>
                    </div>
                    <div className="message-card-controls">
                        { renderControlButtons(d, actions) }
                    </div>
                </div>
            </div>
        </div>;
    });
};

const renderMessages = (data, actions, visibility) => {
    return <div className="message-content">
        { renderHeader(data, actions, visibility) }
        <div className="message-scroll-container position-relative">
            <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar-basic">
                <div className="d-flex flex-column">
                    { renderMessageCards(data, actions) }
                </div>
            </CustomScroll>
        </div>
    </div>;
};

export const messagePopoverTemplate = {
    renderMessages
};