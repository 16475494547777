import React from 'react'

import StapleIcon from './icons/StapleIcon'
import CloseIcon from './icons/CloseIcon'

import { useStoreState } from 'easy-peasy';

import './styles.scss';

const Thumbs = ({active}) => {


  const fileNameText = active ? 'DayFileNameText' : 'NightFileNameText';
  const files = useStoreState(state => state.report.files);


  return (
    <div>      
      {
          files.map((file, index) => (
            <div key={index} className="Thumb">
              <div>
                <StapleIcon />

                <img width="30" height="30" src={file.base64} className="mx-2"/>
                <span className={`${fileNameText}`}>{ file.fileName }</span>
              </div>
        
              <CloseIcon fileIndex = {index} />
            </div>
          ))
      } 
    </div>
  )
}

export default Thumbs