import axios from "axios";
import { serviceUtils } from './serviceUtils';
import { metaMaskConfiguration } from './metaMaskConfiguration';

const {API_BASE_URL: {api_base_url}} = metaMaskConfiguration;

const setInterceptor = tokenData => {
	const idToken = tokenData?.signInUserSession?.idToken?.jwtToken || '';
	axios.interceptors.request.use( async req => {
		req.headers.Authorization = idToken;
		req.headers['Content-type'] = 'application/json';
		req.baseURL = api_base_url;
		const isTokenValid = serviceUtils.isTokenValid();
		return isTokenValid ? req : serviceUtils.clearCredentials();
	}, error => Promise.reject(error));

	axios.interceptors.response.use(res => {
		return (res && res.status === 401) ?
			serviceUtils.clearCredentials() :
			res && res.status === 200 ?
				res.data :
				null;
	}, error => Promise.reject(error));
};

export const service = {
	setInterceptor,
};