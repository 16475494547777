// You can obtain these values by running:
// aws cloudformation describe-stacks --stack-name <YOUR STACK NAME> --query "Stacks[0].Outputs[]"

export const config = {
  aws_user_pools_web_client_id: '6k5qg4vejq0r8r7dhp8mjhtnbc',
  api_base_url: 'https://api-gate.cdfi.ai/',
  cognito_hosted_domain: 'https://cedefi-cog.auth.eu-west-1.amazoncognito.com',
  redirect_url: 'https://app.cdfi.ai',
};

// export default config;
