import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { constants } from '../constants';
import { profilePageActions } from '../actions/uiActions';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { ProfileState } from '../../../models/profile-model';

const {
  WALLET_DATA: { MODAL_TITLE, SELECT_CHAINS, BUTTON_TITLE, FORM_TEXT },
} = constants;



export const AddWalletModal =  props  => {
    const {show, actions, cardId, isOpenFromEdit} = props;
    const handleClose = () => {actions[profilePageActions.actionTypes.WALLET_MODAL_INVOKED]({name:'close-modal', modal:'ADD WALLET'})
    
    // if(isOpenFromEdit)
    // {
    // actions[profilePageActions.actionTypes.PROFILE_EDIT_INVOKED]({name: 'open-edit-modal', modal: 'EDIT'})
    // }
  }
  const state = ProfileState.useStoreState(state => state);

  return (
    <div className="popup-style">
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik


          initialValues={{
            walletAddress: '',
          }}
          onSubmit={(values) => {
            actions[profilePageActions.actionTypes.ADD_WALLET_INVOKED]({ method:'post', data: values.walletAddress, action: actions, id : uuidv4(), cardId: cardId, cardID: state.indexForSubmit/*, openFromEdit: isOpenFromEdit*/  }
            )
            // console.log('values: ', values)
            // console.log('state: ', state)
          }}

          >
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>{FORM_TEXT}</Form.Label>
                  <Form.Control
                    type="text"
                    name="walletAddress"
                    placeholder="Wallet address"
                    autoFocus
                    value={values.walletAddress}
                    onChange={handleChange}
                  />
                </Form.Group>
                <p>{SELECT_CHAINS}</p>
                <div className="chain-modal-container">
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/acryptos.svg"
                      height="15"
                      width="15"
                    ></img>
                    Acryptos
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/ape-rocket.svg"
                      height="15"
                      width="15"
                    ></img>
                    ApeRocket
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/ape-swap.svg"
                      height="15"
                      width="15"
                    ></img>
                    ApeSwap
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/abracadabra.svg"
                      height="15"
                      width="15"
                    ></img>
                    Abracadabra
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/dodo.svg"
                      height="15"
                      width="15"
                    ></img>
                    DODO
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/definix.svg"
                      height="15"
                      width="15"
                    ></img>
                    Definix
                  </div>
                  <div className="chain-modal-icon">
                    <img
                      src="../dashboard-library/nft-generator/icons/ankr.svg"
                      height="15"
                      width="15"
                    ></img>
                    Ankr
                  </div>
                </div>
                <Modal.Footer>
                  <Button variant="primary" type="submit" onClick={handleClose}>
                    {BUTTON_TITLE}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
