import React, { useRef } from 'react';
import { constants } from '../../constants';
import { DefiMultiChainDashboardState } from '../../../../models/defi-multichain-dashboard-model';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../../components/utility/empty-search-state/view';
import { Loader } from '../../../../components/utility/loader/view';
import CustomScroll from 'react-custom-scroll';
import InfiniteScroll from 'react-infinite-scroller';
import { PopoverLayout } from '../../../../components/utility/popover/view';
import { uiActions } from '../../actions/uiActions';
import numeral from 'numeral';
import { TokenInfoModal } from '../tokenInfoModal';
import './styles.scss';
import { utils } from '../../../../utils/utils';
import { CustomCheckbox } from '../../../../components/utility/custom-checkbox/view';
import { useStoreState } from 'easy-peasy';
import { dataHelper } from '../../dataHelpers/dataHelper';

const { TABLE_WALELT_KEYS, WALLET: { ADD_TO_WALLET } } = constants;
const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';

const renderTableHeader = () => {
    return <div className="custom-table-row table-header">
        { TABLE_WALELT_KEYS.map((key, i) => <div className="custom-table-header-cell font-size-14 font-weight-medium" key={`header-${i}`}>{key}</div>) }
    </div>;
};

const renderRow = (data, actions, userWallet, state, index) => {
    const { contractAddress, usdPrice, valueSymbol, usdValue, valueHuman, blockchainName } = data;
    const onChangeHandle = value => 
        actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_CHECKBOX_CHECKED]({ value, state, userWallet });
    const value = {
        blockchainName,
        contractAddress,
        valueSymbol
    };
    let setIcon;

  switch (blockchainName) {
    case "BSC":
      setIcon = (
        <PopoverLayout icon={"BNB"} message={valueSymbol} title={false} />
      );

      break;
    case "AVAXc":
      setIcon = (
        <PopoverLayout icon={"AVAX"} message={valueSymbol} title={false} />
      );

      break;
    default:
      setIcon = (
        <PopoverLayout
          icon={blockchainName}
          message={valueSymbol}
          title={false}
        />
      );

      break;
  }
    return <div className="custom-table-row custom-inner-row table-body font-size-14 font-weight-regular" key={`body-${index}-${contractAddress}-${usdValue}`}>
        <div className="custom-table-body-cell d-flex align-items-center">{blockchainName}</div>
        <div className="custom-table-body-cell d-flex align-items-center">
           {setIcon} 
            <div className="token">
                <div>{valueSymbol}</div>
            </div>
        </div>
        <div className="custom-table-body-cell d-flex align-items-center">{valueHuman !== 0 ? Number(valueHuman).toFixed(2) : 0}</div>
        <div className="custom-table-body-cell d-flex align-items-center">${usdPrice !== 0 ? Number(usdPrice).toFixed(2) : 0}</div>
        <div className="custom-table-body-cell d-flex align-items-center">${usdValue !== 0 ? Number(usdValue).toFixed(2) : 0}</div>
        {/* <div className="custom-table-body-cell d-flex align-items-center"><ButtonCustom label={ADD_TO_WALLET} variant='primary' size='sm' /></div> */}
        <div className="custom-table-body-cell d-flex align-items-center">
            <i 
                onClick={() => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({ name: 'open-modal', modal: 'token', id: contractAddress, state })}
                className="fa-light fa-circle-info token-info"></i>
        </div>
        <div className="custom-table-body-cell d-flex align-items-center">{'/'}</div>
        <div className='custom-table-body-cell d-flex justify-content-center'>
            <CustomCheckbox onChangeHandle={() => onChangeHandle(value)} id={contractAddress}/>
        </div>
    </div>;
};

const renderLoader = () => {
    return <div className="loader text-center" key={0}>
        <span>
            <i className="fa fa-refresh fa-spin"></i>
            <span className="ms-3">Loading...</span>
        </span>
    </div>;
};

const renderTableBody = (data, actions, userWallet, state,  searchValue) => data.filter((item) => {
   return searchValue === '' ? item : item.contractAddress.includes(searchValue)
}
).map((d, i) => renderRow(d, actions, userWallet, state, i));

export const TableWalletTemplate = props => {
    const { searchData } = props;
    const actions = DefiMultiChainDashboardState.useStoreActions(actions => actions);
    const state = DefiMultiChainDashboardState.useStoreState(state => state);
    const tableData = dataHelper.sortByValue(state.wallet.filteredData, 'usdValue');
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const ref = useRef();
    const loadFunc = value => actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_LOAD_MORE_LOADING]({ page: value, state });
    const filteredList = dataHelper.filterDataList(tableData);

    return <div className="custom-table-wrapper"><div className="custom-table h-100" ref={ref}>
        { renderTableHeader() }
        <div className="scroll-container">
            { state.wallet.loader ?

                <Loader size={'6x'}/>
                :
                !isEmpty(filteredList) ?

                    <CustomScroll heightRelativeToParent="100%" className="custom-scroll-bar">
                        <InfiniteScroll
                            pageStart={0}
                            initialLoad={false}
                            loadMore={loadFunc}
                            hasMore={state.wallet.pagination.hasMore}
                            loader={renderLoader()}
                            useWindow={false}
                            getScrollParent={() => ref.current?.querySelector(SCROLL_CONTAINER)} >
                            { renderTableBody(filteredList, actions, userWallet, state, searchData) }

                        </InfiniteScroll>
                    </CustomScroll>
                    :
                    <EmptySearchState/> }
        </div>
    </div><TokenInfoModal show={state.modalToken.show} actions={actions} state={state} data={state.modalToken.token}/>
    </div>;
};