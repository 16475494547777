import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';

const actionTypes = {
  SHARE_ORDER_RESULT_MODAL_INVOKED: 'SHARE_ORDER_RESULT_MODAL#INVOKED',
};

const modalInvoked = (actions, payload) => {
  const { name } = payload;
  const { updateState } = actions;

  switch (name) {
    case 'open-modal':
      updateState({ path: 'shareOrderResult.show', value: true });
      break;
    case 'close-modal':
      updateState({ path: 'shareOrderResult.show', value: false });
      break;
  }
};

const actionHandlers = {
  [actionTypes.SHARE_ORDER_RESULT_MODAL_INVOKED]: thunk((actions, payload) =>
    modalInvoked(actions, payload)
  ),

  updateState: action((state, payload) => utils.stateHelper(state, payload)),
};

export const shareOrderResultEffects = {
  actionHandlers,
  actionTypes,
};
