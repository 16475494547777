import { createContextStore } from "easy-peasy";
import { factory } from "../pages/dashboardLending/actions/factory";
import { lendingEffects } from "../pages/dashboardLending/actions/lendingEffects";
import { uiActions } from "../pages/dashboardLending/actions/uiActions";
import { lendingConstants } from "../pages/dashboardLending/constants";

const { NETWORKS_LIST } = lendingConstants;

const DASHBOARD_LENDING_MODEL = {
  networkSelector: {
    list: NETWORKS_LIST,
    value: NETWORKS_LIST[0],
  },
  protocolSelector: {
    list: [],
    value: null,
  },
  venusSupplyBalance: 0,
  venusBorrowBalance: 0,
  compaundSupplyBalance: 0,
  compaundBorrowBalance: 0,
  protocolToChain: {
    list: NETWORKS_LIST[0].protocols,
    value: NETWORKS_LIST[0].protocols[0],
  },
  protocol: "venus",
  tableData: {
    supplyUser: [],
    supplyMarket: [],
    borrowUser: [],
    borrowMarket: [],
  },
  comptroller: "",
  netApy: "",
  safeLimit: "",
  borrowLimitUsed: "",
  limit: "",
  supplyBalance: 0,
  borrowBalance: 0,
  dailyEarnings: 0,
  rewardXVS: "",
  venusTableData: {
    supplyUser: [],
    borrowUser: [],
  },
  compoundTableData: {
    supplyUser: [],
    borrowUser: [],
  },
  mergedProtocolsData: [],
  modal: {
    token: null,
    isNative: false,
    currentTxStatus: 0,
    layout: "",
    type: "",
    loader: false,
    show: false,
    tab: "",
    pickedAsset: null,
    form: {
      valid: false,
      amount: 0,
    },
  },
  investmentType: [{ title: "Supplied" }, { title: "Borrowed" }],
  loader: false,
  typeOfFetch: "set",
  ...factory.actionHandlers,
  ...uiActions.actionHandlers,
  ...lendingEffects.actionHandlers,
};

export const DashboardLendingState = createContextStore(
  DASHBOARD_LENDING_MODEL
);
