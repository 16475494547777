import Big from 'big.js';
import React from 'react';
import { ActiveTradeItem } from './activeTradeItem';

const mockData = [
  {
    id: 1,
    label: 'label 1',
    mainValue: '6.00000',
    pairIcon: 'BNB',
    horizontal: true,
  },
  {
    id: 2,
    label: 'label 2',
    mainValue: '5.00000',
    horizontal: true,
  },
  {
    id: 3,
    label: 'label 3',
    mainValue: '2.00000',
    pairIcon: 'BNB',
    horizontal: true,
  },
  {
    id: 4,
    label: 'label 4',
    mainValue: '3.00000',
    pairValue: '3.00000',
    horizontal: true,
  },
  {
    id: 5,
    label: 'label 5',
    mainIcon: 'USDT',
    pairIcon: 'BNB',
    pairValue: '1.00000',
    mainValue: '1.00000',
  },
  {
    id: 6,
    label: 'label 6',
    mainValue: '4.00000',
    pairIcon: 'BNB',
  },
];

export const ActiveTradesTemplate = (props) => {
  const { data } = props;

  const dataToShow = [];

  let id = 1;
  const priceBase = data.fullModel?.model?.originalModel?.data?.order?.base?.priceBase;
  const mainCoin = data.fullModel?.model?.originalModel?.data?.mainCoin;
  const pairCoin = data.fullModel?.model?.originalModel?.data?.pairCoin;
  if (priceBase) {
    const side = data.fullModel.model.originalModel.data.order.side;
    const label = `Enter price(${side})`;
    dataToShow.push({
      id: id++,
      label,
      mainIcon: mainCoin,
      mainValue: priceBase,
      horizontal: true,
    });
  }
  const quantity = data.fullModel?.model?.originalModel?.data?.order?.quantity; //base?.quantityBase;

  if (priceBase && quantity) {
    dataToShow.push({
      id: id++,
      label: 'Initial Position',
      mainIcon: mainCoin,
      mainValue: Big(quantity).div(priceBase).toString(),
      pairIcon: pairCoin,
      pairValue: quantity,
      horizontal: true,
    });
  }

  if (data.fullModel?.model?.originalModel?.data?.leverage) {
    const margineType =
      data.fullModel.model.originalModel.data.marginType === 'ISOLATED' ? 'Isolated' : 'Cross';
    dataToShow.push({
      id: id++,
      label: 'Leverage',
      mainValue: margineType + ' x ' + data.fullModel.model.originalModel.data.leverage.toString(),
      horizontal: true,
    });
  }

  return (
    <div className="active-trades">
      {dataToShow.map((item) => (
        <ActiveTradeItem
          key={item.id}
          label={item.label}
          pairIcon1={item.pairIcon}
          string1={item.mainValue}
          pairIcon2={item.mainIcon}
          string2={item.pairValue}
          horizontal={item.horizontal}
        />
      ))}
    </div>
  );
};
