import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Button, Modal } from 'react-bootstrap';
import { tableEffects } from '../../actions/tableEffects';
import { constants } from '../../constants';
import Toggle from 'react-toggle';
import { SmartTradeState } from '../../../../models/smart-trade-model';

const {
  MODALS: {
    SURE_TO_CLOSE_TRADE,
    THIS_ACTION_WILL,
    CANCEL_TRADE,
    CANCEL_UNFILLED_ORDERS,
    CLOSE_CURRENT_POSITION,
    CONFIRM,
    GO_BACK,
    PROFIT,
    DONT_SHOW_AGAIN,
  },
} = constants;

export const CloseTradeModal = (props) => {
  const { show, state, botId, keyId } = props;
  const actions = useStoreActions((actions) => actions);
  const action = SmartTradeState.useStoreActions((action) => action);

  const handleClose = () =>
    actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_MODAL_TOGGLED]({
      name: 'close-modal',
      modal: 'close',
    });
  const handleChange = () =>
    action[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_CONTROLS_TOGGLED]();
  const handleConfirmCloseTrade = () =>
    actions[tableEffects.actionTypes.SMART_TRADE_TABLE_ROW_CLOSE_TRADE]({ id: keyId, botId });

  return (
    <Modal
      show={show}
      size="md"
      centered
      className="trade-funds"
      backdrop="static"
      container={document.querySelector('.wrapper')}
      onHide={handleClose}
    >
      <Modal.Header>
        <div className="cursor-pointer cancel-icon" onClick={handleClose}>
          <i className="icon-cancel fa-lg"></i>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="title">{SURE_TO_CLOSE_TRADE}?</p>
        <div className="font-inter">
          <p className="label pt-1 pb-1">
            {PROFIT}: <span className="ms-1 error">$-30.56</span>
          </p>
          <div>
            <p className="m-0">{THIS_ACTION_WILL}:</p>
            <ul>
              <li>{CANCEL_TRADE}</li>
              <li>{CANCEL_UNFILLED_ORDERS}</li>
              <li>{CLOSE_CURRENT_POSITION}!</li>
            </ul>
          </div>
          <label className="customized-toggle cursor-pointer d-flex flex-row align-items-center justify-content-between pt-3 pb-3">
            <div className="me-3 font-size-14 font-weight-regular">{DONT_SHOW_AGAIN}</div>
            <Toggle
              name="toggle_dont_show_again"
              icons={false}
              checked={state.moreOptions.dontShowMessage}
              onChange={handleChange}
            />
          </label>
          <div className="submit-button pt-3">
            <Button
              onClick={handleConfirmCloseTrade}
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium confirm"
            >
              {CONFIRM}
            </Button>
            <Button
              onClick={handleClose}
              type="button"
              size="lg"
              className="font-size-14 font-weight-medium back"
            >
              {GO_BACK}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
