import React, { useRef } from 'react';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { constants } from './constants';
import { ProfileModal } from './modals/profileModal';
import { profilePageActions } from './actions/uiActions';
import { ProfileState } from '../../models/profile-model';
import { ProfileCard } from './templates/profileCard';
import CustomScroll from 'react-custom-scroll';

export const ProfilePageBody = () => {
  const {
    TITLES: { CREATE_NEW_PROFILE },
  } = constants;
  const state = ProfileState.useStoreState((state) => state);
  const actions = ProfileState.useStoreActions((actions) => actions);

  let profileData = [];

  state.cardsList.forEach((item, index) => {
    profileData.push(<ProfileCard indexCard={index} data={item} />);
  });

  return (
    <>
      <CustomScroll heightRelativeToParent="calc(100% - 100px)" className="custom-scroll-bar-basic">
        <div className="profile-page">
          <div className="profile-page-body">
            <div
              className="create-profile"
              onClick={() => {
                actions[profilePageActions.actionTypes.PROFILE_MODAL_INVOKED]({
                  name: 'open-modal',
                  modal: 'NEW PROFILE',
                });
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faUserPlus} />
              </div>
              <div className="title">{CREATE_NEW_PROFILE}</div>
            </div>
            <div className="cards-wrapper">
              {/*  <ProfileCard /> */}
              {profileData}
            </div>
          </div>
          <ProfileModal show={state.isModalOpen} actions={actions} title={state.modalTitle} />
        </div>
      </CustomScroll>
    </>
  );
};

export const ProfilePage = () => (
  <ProfileState.Provider>
    <ProfilePageBody />
  </ProfileState.Provider>
);
