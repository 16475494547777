import React, { useEffect } from 'react';
import numeral from 'numeral';
import { defiMultichainEffects } from '../../actions/defiMultichainEffects';
import { LineChart } from '../../../../components/utility/chart-line/view';
import { ButtonGroup } from 'react-bootstrap';
import { ButtonCustom } from '../../../../components/utility/custom-buttons/view';

let INTERVAL_ID = null;

export const PriceChart = props => {
    const { data: { usdPrice }, actions, state } = props;

    useEffect(() => {
        actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TIME_INTERVAL_UPDATED]();
        clearInterval(INTERVAL_ID);
        INTERVAL_ID = setInterval(() => actions[defiMultichainEffects.actionTypes.DEFI_MULTICHAIN_DASHBOARD_TIME_INTERVAL_UPDATED]());
    }, []);

    return <div className="price-chart-content">
        <div className="price-chart-content-header">
            <div className="number-item"><div className="number">${numeral(usdPrice).format('0,00.00')}</div><div className="title">{state.date.currentDate}, {state.date.currentTime}</div></div>
        </div>
        <div className="price-chart-content-chart">
            <LineChart/>
        </div>
        <div className="price-chart-content-group">
            <ButtonGroup className="w-100 button-100">
                <ButtonCustom label="1H" variant="default" size="sm" active={state.buySellFormType === '1H'} name="button_buy_sell_buy"/>
                <ButtonCustom label="1D" variant="default" size="sm" active={state.buySellFormType === '1D'} name="button_buy_sell_sell"/>
                <ButtonCustom label="1W" variant="default" size="sm" active={state.buySellFormType === '1W'} name="button_buy_sell_sell"/>
                <ButtonCustom label="1M" variant="default" size="sm" active={state.buySellFormType === '1M'} name="button_buy_sell_sell"/>
                <ButtonCustom label="1Y" variant="default" size="sm" active={state.buySellFormType === '1Y'} name="button_buy_sell_sell"/>
            </ButtonGroup>
        </div>
    </div>;
};