import { serviceUtils } from '../../../service/serviceUtils';
import { openOrdersPositionsEffects } from './openOrdersPositionsEffects';

const url = 'binance/open-orders-and-open-positions';

const createExchanges = (actions, payload) => {
  const { method, keyId, exchange } = payload;
  serviceUtils.HttpService({
    url: url,
    method: method,
    data: {
      keyId: keyId,
      exchange: exchange,
      offset: 0,
      limit: 10,
    },
    errorActionType:
      actions[openOrdersPositionsEffects.actionTypes.OPEN_ORDERS_POSITIONS_REQUSETS_FAILED],
    successActionType:
      actions[openOrdersPositionsEffects.actionTypes.OPEN_ORDERS_POSITIONS_REQUESETS_SUCCEEDED],
  });
};

export const postPairs = {
  createExchanges,
};
