import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { utils } from '../../../utils/utils';
import moment from 'moment';
import { dataHelper } from '../dataHelpers/dataHelper';
import { isEmpty } from 'lodash';

const REQUEST_ALL_URL = '/all';
const REQUEST_BALANCES_URL = '/web3/getbalance';
const REQUEST_VENUS_URL = '/web3/venus';
const REQUEST_COMPOUND_URL = '/web3/compound';
const REQUEST_POOLS_URL = '/web3/cake/syrup';
const REQUEST_FARMS_URL = '/web3/pancake/farms';

const actionTypes = {
    DEFI_MULTICHAIN_DASHBOARD_FARMS_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_FARMS#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_FARMS_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_FARMS#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_POOLS_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_POOLS#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_POOLS_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_POOLS#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_COMPOUND_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_COMPOUND#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_COMPOUND_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_COMPOUND#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_ALL_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_ALL#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_BALANCES#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST: 'DEFI_MULTICHAIN_DASHBOARD_BLACKLIST#POST',
    DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST_FAILED: 'DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST#FAILED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_ALL_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_ALL#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_BALANCES_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_BALANCES#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH#FAILED',
    DEFI_MULTICHAIN_DASHBOARD_REVOKED: 'DEFI_MULTICHAIN_DASHBOARD#REVOKED',
    DEFI_MULTICHAIN_DASHBOARD_TIME_INTERVAL_UPDATED: 'DEFI_MULTICHAIN_DASHBOARD_TIME_INTERVAL#UPDATED',
    DEFI_MULTICHAIN_DASHBOARD_VENUS_FETCHED: 'DEFI_MULTICHAIN_DASHBOARD_VENUS#FETCHED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_VENUS_SUCCEEDED: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_VENUS#SUCCEEDED',
    DEFI_MULTICHAIN_DASHBOARD_FETCH_REQUEST: 'DEFI_MULTICHAIN_DASHBOARD_FETCH_REQUEST'
};

const fetchFarmsHandle = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${REQUEST_FARMS_URL}?address=${userWallet}`,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FARMS_SUCCEEDED]
    });
};

const fetchPoolsHandle = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${REQUEST_POOLS_URL}?address=${userWallet}`,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_POOLS_SUCCEEDED]
    });
};

const fetchCompoundHandle = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${REQUEST_COMPOUND_URL}?address=${userWallet}`,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_COMPOUND_SUCCEEDED]
    });
};

const fetchAllHandle = (actions, payload) => {
    serviceUtils.HttpService({
        url: REQUEST_ALL_URL,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_ALL_SUCCEEDED]
    });
};

const fetchBalancesHandle = (actions, payload) => {
    const { userWallet, chain } = payload;

 serviceUtils.HttpService({
        url: `${REQUEST_BALANCES_URL}?address=${userWallet}${chain || ''}`,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_BALANCES_SUCCEEDED]
    }); 
};

const fetchVenusHandle = (actions, payload) => {
    const { userWallet } = payload;

    serviceUtils.HttpService({
        url: `${REQUEST_VENUS_URL}?address=${userWallet}`,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_VENUS_SUCCEEDED]
    });
};

const successFarmsHandle = (state, payload) => {
    const { response: { farms } } = payload;

    state.farms.loader = false;
    if (state.typeOfFetch === 'push') {
        state.farms.data = [...state.farms.data, ...farms];
    }
    else {
        state.farms.data = farms;
    }
    
    state.farms.pagination.hasMore = farms.length < 50 ? false : true;
};

const successPoolsHandle = (state, payload) => {
    const { response: { pools } } = payload;

    state.pools.loader = false;
    if (state.typeOfFetch === 'push') {
        state.pools.data = [...state.pools.data, ...pools];
    }
    else {
        state.pools.data = pools;
    }
    
    state.pools.pagination.hasMore = pools.length < 50 ? false : true;
};

const successCompoundHandle = (state, payload) => {
    const { response: { tokens } } = payload;

    state.compound.loader = false;
    if (state.typeOfFetch === 'push') {
        state.compound.data = [...state.compound.data, ...tokens];
    }
    else {
        state.compound.data = tokens;
    }
    
    state.compound.pagination.hasMore = tokens.length < 50 ? false : true;
};

const successBalancesHandle = (state, payload) => {
    const { response: { balArray, blackList } } = payload;
    const fixedAddressesArray = utils.nullAddressHandler(balArray);
    const parseFarms = dataHelper.parseFarmsList(state.farms.data);
    const parsePools = dataHelper.parsePoolsList(state.pools.data);
    const parseVenus = dataHelper.parseVenusList(utils.filteredVenusData(state.venus.data))
 
    const allTokens = (list1,list2,list3,list4) => {
      const allTokensArr = [];
     
     return allTokensArr.concat(list1,list2,list3,list4)};
    
    const newArray = allTokens(fixedAddressesArray,parseFarms,parsePools,parseVenus).reduce((acc, current) => {
        const existing = acc.find(item => item.valueSymbol === current.valueSymbol);
        if (existing) {
          existing.usdValue += current.usdValue;
        } else {
          acc.push(current);
        }
        return acc;
      }, []);
    state.wallet.loader = false;
    if (state.typeOfFetch === 'push') {
        state.wallet.data = [...state.wallet.data, ...newArray];
       
    }
    else {
        state.wallet.data = newArray;
        state.wallet.blackList.data = blackList ? blackList.blackListTokens : [];
        
    }

    if(blackList !== undefined) {
        if(!blackList && !isEmpty(blackList.blackListTokens) && isEmpty(blackList.blackListTokens[0])) {
            state.wallet.blackList.data = [];
            state.wallet.filteredData = state.wallet.data;
        }
        else if(isEmpty(state.wallet.blackList.data)){state.wallet.filteredData = state.wallet.data; } 
        else {state.wallet.filteredData = utils.filteredData(state.wallet.data, state.wallet.blackList.data); } 
    
        if(state.chains.picked === 'All' || state.chains.picked === 'Others'){state.wallet.blackList.filteredData = state.wallet.blackList.data;} 
        else {state.wallet.filteredData = state.wallet.filteredData.filter(token => token.blockchainName === state.chains.picked); }
    }
    else {
        state.wallet.filteredData = balArray;
        
    }
    
    state.wallet.pagination.hasMore = fixedAddressesArray.length < 50 ? false : true;
    state.wallet.totalBalance = dataHelper.getTotalBalance(state.wallet.filteredData);
};

const successVenusHandle = (state, payload) => {
    const { response: { tokens } } = payload;

    state.venus.loader = false;
    if (state.typeOfFetch === 'push') {
        state.venus.data = [...state.venus.data, ...tokens];
    }
    else {
        state.venus.data = tokens;
    }
    
    state.venus.pagination.hasMore = tokens.length < 50 ? false : true;
};

const successAllHandle = (state, payload) => {
    const { response: { account, defi: { balance, change, available, invested, top4assets }, vaults, walletAssets: { assets, totalUSD }, yields } } = payload;

    state.wallet.loader = false;
    state.wallet.totalUSD = totalUSD;
    state.wallet.allTotalBalance = balance;
    state.wallet.available = available;
    state.wallet.change = change;
    state.wallet.invested = invested;
    state.wallet.tokenAllocation = top4assets;
    state.protocols[0].value = available;
};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.wallet.data = state.typeOfFetch === 'push' ? state.wallet.data : [];
    state.wallet.pagination.hasMore = true;
};

const revokeHandle = (actions, payload) => {
    console.info(payload);
};

const liveTimeUpdateAction = state => {
    state.date.currentDate = moment().format('DD/MM/YYYY');
    state.date.currentTime = moment().format('HH:mm A');
};

const postBlackList = (actions, payload) => {
    serviceUtils.HttpService({
        url: REQUEST_BALANCES_URL,
        method: 'post',
        data: payload,
        errorActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST_FAILED],
        successActionType: actions[actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST_SUCCEEDED]
    });
};

const successPostHandle = (actions, payload) => {
    const { response } = payload;
    // if(response) actions[actionTypes.WEB3_BALANCES_FETCHED];
};

const actionHandlers = {
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST]: thunk (async (actions, payload) => postBlackList(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_BLACKLIST_POST_SUCCEEDED]: thunk((actions, payload) => successPostHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FARMS_FETCHED]: thunk( async (actions, payload) => fetchFarmsHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FARMS_SUCCEEDED]: action((state, payload) => successFarmsHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_POOLS_FETCHED]: thunk( async (actions, payload) => fetchPoolsHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_POOLS_SUCCEEDED]: action((state, payload) => successPoolsHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_COMPOUND_FETCHED]: thunk( async (actions, payload) => fetchCompoundHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_COMPOUND_SUCCEEDED]: action((state, payload) => successCompoundHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_ALL_FETCHED]: thunk( async (actions, payload) => fetchAllHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_BALANCES_FETCHED]: thunk( async (actions, payload) => fetchBalancesHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_ALL_SUCCEEDED]: action((state, payload) => successAllHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_BALANCES_SUCCEEDED]: action((state, payload) => successBalancesHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_REVOKED]: thunk((actions, payload) => revokeHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_TIME_INTERVAL_UPDATED]: action((state, payload) => liveTimeUpdateAction(state, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_VENUS_FETCHED]: thunk( async (actions, payload) => fetchVenusHandle(actions, payload)),
    [actionTypes.DEFI_MULTICHAIN_DASHBOARD_FETCH_VENUS_SUCCEEDED]: action((state, payload) => successVenusHandle(state, payload)),
};

export const defiMultichainEffects = {
    actionTypes,
    actionHandlers
};