import { action } from 'easy-peasy';

const actionTypes = {
    PLACEHOLDER_BREAKPOINTS_CHANGED: 'PLACEHOLDER_BREAKPOINTS#CHANGED'
};

const getBreakPointClass = width => {
    if (width >= 2520)
        return 'screen-2520';
    else if (width >= 1920 && width < 2520)
        return 'screen-1920';
    else if (width >= 1800 && width < 1920)
        return 'screen-1800';
    else if (width >= 1680 && width < 1800)
        return 'screen-1680';
    else if (width >= 1600 && width < 1680)
        return 'screen-1600';
    else if (width >= 1536 && width < 1600)
        return 'screen-1536';
    else if (width >= 1500 && width < 1536)
        return 'screen-1500';
    else if (width >= 1400 && width < 1500)
        return 'screen-1400';
    else if (width >= 1368 && width < 1400)
        return 'screen-1368';
    else if (width >= 1366 && width < 1368)
        return 'screen-1366';
    else if (width >= 1280 && width < 1366)
        return 'screen-1280';
    else if (width >= 1200 && width < 1280)
        return 'screen-1200';
    else if (width >= 992 && width < 1200)
        return 'screen-992';
    else if (width < 992)
        return 'screen-0';
};

const getVerticalBreakPointClass = (height, breakPointClass) => {
    return (height < 820 || breakPointClass === 'screen-0' || breakPointClass === 'screen-992' ) ? 'vertical-scroll' : '';
};


const breakPointsHandle = state => {
    const screenWidth = state.sizes.values.screenWidth;
    const screenHeight = state.sizes.values.screenHeight;
    state.sizes.breakPointClass = getBreakPointClass(screenWidth);
    state.sizes.verticalPointClass = getVerticalBreakPointClass(screenHeight, state.sizes.breakPointClass);
    state.sizes.horizontalPointClass = state.sizes.breakPointClass === 'screen-0' ? 'horizontal-scroll' : '';
};


const actionHandlers = {
    [actionTypes.PLACEHOLDER_BREAKPOINTS_CHANGED]: action((state) => breakPointsHandle(state))
};

export const breakPointEffects = {
    actionTypes,
    actionHandlers
};