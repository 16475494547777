/* eslint-disable indent */
import React, { Fragment } from 'react';
import { Dashboard } from '../pages/dashboard/view';
import { EmptyContent } from '../components/emptyContent/view';
import { Platform } from '../components/platform/view';
import { LogOut } from '../pages/logOutPage/view';
import { SmartTradeBot } from '../pages/componentsTempPage/view';
import { Login } from '../pages/loginPage/view';
import { FontsDemo } from '../pages/temp_fonts_page/view';
import { StaticPageLayout } from '../pages/homePage/view';
import { SmartContractAllowancePage } from '../pages/smartContractAllowancePage/view';
import { SwapPage } from '../pages/swapPage/view';
import { SmartTradePage } from '../pages/smartTradePage/view';
import { Web3BalancesPage } from '../pages/web3BalancesPage/view';
import { NewDashboardPage } from '../pages/newDashboardPage/view';
import { Plans } from '../pages/homePage/templates/plansPage/view';
// import { PoolsPage } from '../pages/poolsPage/view';
import { DefiMultiChainDashboardPage } from '../pages/defiMultiChainDashboard/view';
import { InvestPage } from '../pages/homePage/templates/investPage/view';
import { TradePage } from '../pages/homePage/templates/tradePage/view';
import { DocsPage } from '../pages/homePage/templates/docsPage/view';
import { BuildPage } from '../pages/homePage/templates/buildPage/view';
import { DeFiLiquidatorPage } from '../pages/deFiLiquidatorPage/views';
import { ConnectedPlatformPage } from '../pages/connectedPlatformsPage/view';
import { DonutExample } from '../components/utility/donut-chart/view';
// import { FarmsPage } from '../pages/farmsPage/view';F
import { GridPage } from '../pages/gridBotPage/view';
import { DashboardLendingPage } from '../pages/dashboardLending/view';
import { BuyPage } from '../pages/homePage/templates/buyPage/view';
import { CareersPage } from '../pages/homePage/templates/careersPage/view';
import { Tutorial } from '../pages/homePage/templates/tutorialPage/view';
import { DynamicSplitPage } from '../pages/dynamicSplitPage/view';
// import { TeamPage } from '../pages/homePage/templates/teamPage/view';
import { VestingPage } from '../pages/vestingPage/view';
import MultiChart from '../pages/multichartPage/view';
import { NewsPage } from '../pages/newsPage/view';
import { ContactsPage } from '../pages/homePage/templates/contactsPage/view';
import { RemakeDashboardPage } from '../pages/remakeDashboardPage/view';
import { HodlBotPage } from '../pages/hodlBotPage/view';
import { ProfilePage } from '../pages/profilePage/view';
import { AcademyPage } from '../pages/homePage/templates/academyPage/view';
import { AcademyAdmin } from '../pages/homePage/templates/academyPage/admin/AcademyAdmin';
import { FaqPage } from '../pages/homePage/templates/faqPage/view';
import { CustomBotsPage } from '../pages/customBots/view';
import { PlansPage } from '../pages/plansPage/view';
import { NftGeneratorPage } from '../pages/nftGeneratorPage/view';
import { Page404 } from '../pages/404/view';
import { EventsPage } from '../pages/eventsPage/view';
import { InvestmentPage } from '../pages/investmentPage/view';

export const routing = {
  NAVIGATION_MAPPING: {
    portfolio: {
      id: 'portfolio',
      label: 'PORTFOLIO',
      icon: 'fa-briefcase',
      list: [
        {
          id: 'dashboard',
          label: 'Dashboard',
          icon: 'fa-house',
          parentId: 'portfolio',
          disabled: false,
          content: () => <RemakeDashboardPage />,
        },
        {
          id: 'profile',
          label: 'Profile',
          icon: 'fa-user',
          parentId: 'portfolio',
          disabled: 'false',
          content: () => <ProfilePage />,
        },
        // {
        // 	id: 'safebuy',
        // 	label: 'Buy $M.C.S',
        // 	icon: 'fa-money-bill-transfer',
        // 	parentId: 'portfolio',
        // 	disabled:false,
        // 	content: () => <VestingPage/>
        // },
        // {
        // 	id: 'exchange',
        // 	label: 'Exchange',
        // 	icon: 'fa-arrow-down-arrow-up',
        // 	parentId: 'portfolio',
        // 	content: () => <Fragment><VaultCard asset="ETH" value="1000"/><YieldCard mainAsset="ETH" asset="BTC" apr="12.96" value="1000"/></Fragment>
        // },
        // {
        // 	id: 'analytics',
        // 	label: 'Analytics',
        // 	icon: 'fa-square-poll-vertical',
        // 	parentId: 'portfolio',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        {
          id: 'history',
          label: 'History',
          icon: 'fa-clock-rotate-left',
          parentId: 'portfolio',
          disabled: true,
          content: () => <EmptyContent />,
        },
        // {
        // 	id: 'transactions',
        // 	label: 'Transactions',
        // 	icon: 'fa-retweet',
        // 	parentId: 'portfolio',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        {
          id: 'connected_platform',
          label: 'Connected platforms',
          icon: 'fa-file-magnifying-glass',
          parentId: 'portfolio',
          disabled: false,
          content: () => <ConnectedPlatformPage />,
        },
        {
          id: 'subscriptions',
          label: 'Subscriptions',
          parentId: 'portfolio',
          icon: 'fa-shopping-cart',
          content: () => <PlansPage />,
        },
        // {
        // 	id: 'connected_platforms',
        // 	label: '',
        // 	icon: '',
        // 	parentId: 'portfolio',
        // 	disabled:false,
        // 	content: () => <Platform/>
        // }
      ],
    },
    DeFi: {
      id: 'DeFi',
      label: 'DeFi',
      icon: 'fa-robot',
      list: [
        // {
        // 	id: 'web3balances',
        // 	label: 'Wallet balances',
        // 	icon: 'fa-wallet',
        // 	parentId: 'DeFi',
        // 	content: () => <Web3BalancesPage/>
        // },
        {
          id: 'defidashboard',
          label: 'DeFi Multichain Dashboard',
          icon: 'fa-square-poll-vertical',
          parentId: 'DeFi',
          disabled: false,
          content: () => <DefiMultiChainDashboardPage />,
        },
        {
          id: 'swap',
          label: 'Swap',
          icon: 'fa-arrows-repeat',
          parentId: 'DeFi',
          disabled: false,
          content: () => <SwapPage />,
        },
        {
          id: 'investment',
          label: 'Investment',
          icon: 'fa-filter-circle-dollar',
          parentId: 'DeFi',
          disabled: false,
          content: () => <InvestmentPage />,
        },
        // {
        // 	id: 'pools',
        // 	label: 'Pools',
        // 	icon: 'fa-filter-circle-dollar',
        // 	parentId: 'DeFi',
        // 	disabled: false,
        // 	content: () => <PoolsPage />
        // },
        // {
        // 	id: 'farm',
        // 	label: 'Farm',
        // 	icon: 'fa-coins',
        // 	parentId: 'DeFi',
        // 	disabled: false,
        // 	content: () => <FarmsPage />
        // },
        {
          id: 'Liquidator',
          label: 'Liquidator',
          icon: 'fa-circle-dollar-to-slot',
          parentId: 'DeFi',
          disabled: false,
          content: () => <DeFiLiquidatorPage />,
        },
        {
          id: 'smart_contract_allowance',
          label: 'Revoke access',
          icon: 'fa-file-contract',
          parentId: 'DeFi',
          disabled: false,
          content: () => <SmartContractAllowancePage />,
        },
        {
          id: 'lending',
          label: 'Lending',
          icon: 'fa-circle-dollar-to-slot',
          parentId: 'DeFi',
          disabled: false,
          content: () => <DashboardLendingPage />,
        },
        // {
        // 	id: 'bridge',
        // 	label: 'Bridge',
        // 	icon: 'fa-arrow-right-arrow-left',
        // 	parentId: 'DeFi',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        // {
        // 	id: 'automated_strategy',
        // 	label: 'Automated strategy',
        // 	icon: 'fa-diagram-project',
        // 	parentId: 'DeFi',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        // {
        // 	id: 'copy_farming',
        // 	label: 'Copy farming',
        // 	icon: 'fa-layer-group',
        // 	parentId: 'DeFi',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
      ],
    },
    CeFi: {
      id: 'CeFi',
      label: 'CeFi',
      icon: 'fa-chart-line',
      list: [
        {
          id: 'balances',
          label: 'Exchange Balances',
          icon: 'fa-wallet',
          parentId: 'CeFi',
          disabled: false,
          content: () => <Dashboard />,
        },
        {
          id: 'protrade',
          label: 'Protrade',
          parentId: 'CeFi',
          icon: 'fa-flag',
          disabled: false,
          content: () => <SmartTradePage />,
        },
        // {
        // 	id: 'grid',
        // 	label: 'Grid Bot',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-scale-unbalanced',
        // 	disabled: false,
        // 	content: () => <GridPage />
        // },
        // {
        // 	id: 'cost_average',
        // 	label: 'Cost average',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-chart-line',
        // 	disabled: true,
        // 	content: () => <EmptyContent />
        // },
        // {
        // 	id: 'dca',
        // 	label: 'DCA Custom Bots',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-scale-unbalanced',
        // 	content: () => <CustomBotsPage />
        // },
        // {
        // 	id: 'copy_trading',
        // 	label: 'Copy trading',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-clone',
        // 	disabled: true,
        // 	content: () => <EmptyContent />
        // },
        // {
        // 	id: 'hodl_bot',
        // 	label: 'Cost Average automation',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-shield',
        // 	content: () => <HodlBotPage />
        // }
      ],
    },
    tools: {
      id: 'tools',
      label: 'TOOLS',
      icon: 'fa-sliders-up',
      list: [
        {
          id: 'dynamic_split',
          label: 'Work Board',
          parentId: 'tools',
          icon: 'fa-percent',
          disabled: false,
          content: () => <DynamicSplitPage />,
        },
        {
          id: 'multichart',
          label: 'Multichart',
          parentId: 'tools',
          icon: 'fa-chart-pie',
          content: () => <MultiChart />,
        },
        // {
        // 	id: 'multichart',
        // 	label: 'Multichart',
        // 	parentId: 'tools',
        // 	icon: 'fa-chart-pie',
        // 	content: () => <MultiChart />
        // },
        // {
        // 	id: 'algo_market',
        // 	label: 'Algo market',
        // 	parentId: 'CeFi',
        // 	icon: 'fa-radar',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        {
          id: 'rooms',
          label: 'Rooms (chats)',
          parentId: 'tools',
          icon: 'fa-comments',
          disabled: true,
          content: () => <EmptyContent />,
        },
        // {
        // 	id: 'academy',
        // 	label: 'Academy',
        // 	parentId: 'tools',
        // 	icon: 'fa-graduation-cap',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        // {
        // 	id: 'social_ranking',
        // 	label: 'Social ranking',
        // 	parentId: 'tools',
        // 	icon: 'fa-chart-mixed',
        // 	disabled:true,
        // 	content: () => <EmptyContent/>
        // },
        {
          id: 'events_calendar',
          label: 'Events calendar',
          parentId: 'tools',
          icon: 'fa-calendar-clock',
          content: () => <EventsPage />,
        },
      ],
    },

    news: {
      id: 'news',
      label: 'NEWS',
      icon: 'fa-light fa-newspaper',
      list: [
        {
          id: 'news',
          label: 'News Feed',
          parentId: 'news',
          icon: 'fa-light fa-newspaper',
          content: () => <NewsPage />,
        },
      ],
    },
    nft_generator: {
      id: 'nft_generator',
      label: '',
      icon: '',
      list: [
        {
          id: 'nft_generator',
          label: '',
          parentId: 'nft_generator',
          icon: '',
          content: () => <NftGeneratorPage />,
        },
      ],
    },
    // info: {
    // 	id: 'info',
    // 	label: 'INFO',
    // 	icon: 'fa-circle-info',
    // 	list: [
    // 		{
    // 			id: 'my_profile',
    // 			label: 'My profile',
    // 			parentId: 'info',
    // 			icon: 'fa-user',
    // 			disabled:true,
    // 			content: () => <EmptyContent/>
    // 		},
    // 		{
    // 			id: 'F.A.Q.',
    // 			label: 'F.A.Q.',
    // 			parentId: 'info',
    // 			icon: 'fa-circle-question',
    // 			disabled:true,
    // 			content: () => <EmptyContent/>
    // 		},
    // 		{
    // 			id: 'docs',
    // 			label: 'Docs',
    // 			parentId: 'info',
    // 			icon: 'fa-file-lines',
    // 			content: () => <DocsPage />
    // 		},

    // 		{
    // 			id: 'support',
    // 			label: 'Support',
    // 			parentId: 'info',
    // 			icon: 'fa-user-headset',
    // 			disabled:true,
    // 			content: () => <EmptyContent />
    // 		},
    // 		{
    // 			id: 'contacts',
    // 			label: 'Contacts',
    // 			parentId: 'info',
    // 			icon: 'fa-address-book',
    // 			disabled:true,
    // 			content: () => <EmptyContent/>
    // 		},
    // 	]
    // }
  },
  STATIC_MAPPING: {
    id: 'home',
    icon: '',
    label: 'Home',
    list: [
      // {
      // 	id: 'academy',
      // 	label: 'Academy',
      // 	parentId: 'home',
      // 	// icon: 'fa-graduation-cap',
      // 	content: <AcademyPage/>
      // },
      //     {
      //       id: 'subscriptions',
      //       label: 'Subscriptions',
      //       parentId: 'home',
      //       icon: '',
      //       content : <PlansPage/>
      //    },
      {
        id: 'docs',
        label: 'Docs',
        parentId: 'home',
        icon: '',
        content: <DocsPage />,
      },
      // {
      //   id: 'team',
      //   label: 'Team',
      //   parentId: 'home',
      //   icon: '',
      //   content: <TeamPage />,
      // },
      {
        // id: 'buy',
        // label: 'Buy $MCS',
        // parentId: 'home',
        // icon: '',
        // content: <BuyPage/>
      },
      {
        id: 'contacts',
        // label: 'Contacts',
        parentId: 'home',
        icon: '',
        content: <ContactsPage />,
      },
      {
        id: 'academy',
        label: 'Academy',
        parentId: 'home',
        icon: '',
        content: <AcademyPage />,
      },
      {
        id: 'careers',
        label: 'Careers',
        parentId: 'home',
        icon: '',
        content: <CareersPage />,
      },
      {
        id: 'academy/admin',
        label: '',
        parentId: 'home',
        icon: '',
        content: <AcademyAdmin />, //Page404
      },
      // {
      // 	id: 'f.a.q.',
      // 	// label: 'F.A.Q.',
      // 	parentId: 'home',
      // 	icon: 'fa-circle-question',
      // 	content: <FaqPage/>
      // },

      //  {
      // 	id: 'contacts',
      // 	label: 'Contacts',
      // 	parentId: 'home',
      // 	icon: '',
      // 	content: <ContactsPage/>
      // },
      //  {
      //  	id: 'tutorial',
      //  	parentId: 'home',
      //  	label: '',
      //  	icon: '',
      //  	content: <Tutorial/>
      //  },
      // {
      // 	id: 'subscription',
      // 	parentId: 'home',
      // 	label: 'Subscription',
      // 	icon: '',
      // 	content: <Plans/>
      // },
    ],
  },
  FOOTER_MAPPING: {
    id: 'home',
    icon: '',
    label: 'Footer',
    list: [
      {
        id: 'home',
        label: 'Home',
        parentId: 'home',
        icon: '',
        content: <StaticPageLayout />,
      },
      {
        id: 'subscriptions',
        label: 'Subscriptions',
        parentId: 'home',
        icon: '',
        content: <PlansPage />,
      },

      {
        id: 'contacts',
        label: 'Contacts',
        parentId: 'home',
        icon: '',
        content: <ContactsPage />,
      },

      // {
      // 	id: 'f.a.q.',
      // 	label: 'F.A.Q.',
      // 	parentId: 'home',
      // 	icon: 'fa-circle-question',
      // 	// disabled:true,
      // 	content: <FaqPage/>
      // },
      // {
      // 	id: 'invest',
      // 	label: 'Invest',
      // 	parentId: 'home',
      // 	icon: '',
      // 	content: <InvestPage />
      // },
      // {
      // 	id: 'trade',
      // 	label: 'Trade',
      // 	parentId: 'home',
      // 	icon: '',
      // 	content: <TradePage />

      // },
      // {
      // 	id: 'build',
      // 	parentId: 'home',
      // 	label: 'Build',
      // 	icon: '',
      // 	content: <BuildPage />
      // },
    ],
  },
  LOG_OUT: {
    id: 'logout',
    parentId: 'logout',
    label: 'Logout',
    icon: 'fa-arrow-right-from-bracket',
    content: () => <LogOut />,
  },
  LOG_IN: {
    id: 'login',
    parentId: 'login',
    label: 'LogIn',
    icon: '',
    content: () => <Login />,
  },
  LANDING: {
    id: '',
    parentId: '',
    label: '',
    icon: '',
    content: () => <StaticPageLayout />,
  },
};
