import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import CustomScroll from 'react-custom-scroll';
import { useHistory } from 'react-router-dom';
import { PlansState } from '../../models/plans-model';
import './styles.scss';
import { imagesTemplate } from './imagesGenerator/templates';
import { drawHelper } from './imagesGenerator/drawingHelper';
import { constants } from './constants';

const { DISCLAIMER, TABLE_CONTENT, HEADER } = constants;

export const PlansLayout = () => {
  const ref = useRef();
  const mintTrialRef = useRef();
  const beginnerRef = useRef();
  const advancedRef = useRef();
  const expertRef = useRef();
  const legendaryRef = useRef();
  const actions = PlansState.useStoreActions((actions) => actions);
  const loginModalToggle = useStoreActions((actions) => actions.LOGIN_MODAL_TOGGLE);
  const authentication = useStoreState((state) => state.authentication);
  const isUserLogged = Boolean(authentication.user && !isEmpty(authentication.userInfo));
  const history = useHistory();
  const [isMonthly, setIsMonthly] = useState(true);
  const activeClass = (item) => (isMonthly === item ? 'active' : '');

  useEffect(() => {
    drawHelper.drawMintTrial(
      d3.select(mintTrialRef.current),
      actions,
      'mintTrial',
      loginModalToggle,
      history,
      TABLE_CONTENT
    );
    drawHelper.drawBeginner(
      d3.select(beginnerRef.current),
      actions,
      'beginner',
      loginModalToggle,
      history,
      TABLE_CONTENT,
      isMonthly
    );
    drawHelper.drawAdvanced(
      d3.select(advancedRef.current),
      actions,
      'advanced',
      loginModalToggle,
      history,
      TABLE_CONTENT,
      isMonthly
    );
    drawHelper.drawExpert(
      d3.select(expertRef.current),
      actions,
      'expert',
      loginModalToggle,
      history,
      TABLE_CONTENT,
      isMonthly
    );
    drawHelper.drawLegendary(
      d3.select(legendaryRef.current),
      actions,
      'legendary',
      loginModalToggle,
      history,
      TABLE_CONTENT,
      isMonthly
    );
  });

  const renderMainLayout = () => {
    return (
      <div
        className="plans-page-layout"
        style={
          !isUserLogged
            ? { padding: '170px 90px 60px', backgroundColor: '#0f131c' }
            : { padding: '0px 90px 60px 74px' }
        }
      >
        <div className="plans-page-layout-heading">
          <div className="title">{HEADER.TITLE}</div>
          <div className="switchers">
            <div onClick={() => setIsMonthly(true)} className={activeClass(true)}>
              {HEADER.MONTHLY}
            </div>
            <div onClick={() => setIsMonthly(false)} className={activeClass(false)}>
              {HEADER.ANNUALLY}
            </div>
          </div>
        </div>
        <div className="plans-page-layout-cards">
          <div className="plans-page-layout-cards-first-row">
            <div className="image-mint-trial">
              <svg ref={mintTrialRef} viewBox="0 0 553 869" fill="none">
                {imagesTemplate.mintTrialImage()}
              </svg>
            </div>
            <div className="image-beginner">
              <svg ref={beginnerRef} viewBox="0 0 553 869" fill="none">
                {imagesTemplate.beginnerImage()}
              </svg>
            </div>
            <div className="image-advanced">
              <svg ref={advancedRef} viewBox="0 0 553 869" fill="none">
                {imagesTemplate.advancedImage()}
              </svg>
            </div>
          </div>
          <div className="plans-page-layout-cards-second-row">
            <div className="image-expert">
              <svg ref={expertRef} viewBox="0 0 553 869" fill="none">
                {imagesTemplate.expertImage()}
              </svg>
            </div>
            <div className="image-legendary">
              <svg ref={legendaryRef} viewBox="0 0 553 869" fill="none">
                {imagesTemplate.legendaryImage()}
              </svg>
            </div>
          </div>
        </div>
        <div className="plans-page-layout-disclaimer">
          <div>* - {DISCLAIMER.VALID_FOR_ANNUALLY}</div>
          <div>** - {DISCLAIMER.ANNUALLY_WILL_PARTICIPATE}</div>
        </div>
      </div>
    );
  };

  return (
    <div ref={ref} className={`${isUserLogged ? 'page-container' : ''}`}>
      {isUserLogged ? (
        <CustomScroll
          heightRelativeToParent="100%"
          className="custom-scroll-bar main-content-scroll"
          addScrolledClass={true}
        >
          {renderMainLayout()}
        </CustomScroll>
      ) : (
        renderMainLayout()
      )}
    </div>
  );
};

export const PlansPage = () => (
  <PlansState.Provider>
    <PlansLayout />
  </PlansState.Provider>
);
