import React from 'react';
import { Loader } from '../../../components/utility/loader/view';
import { isEmpty } from 'lodash';
import { EmptySearchState } from '../../../components/utility/empty-search-state/view';
import '../styles.scss';
import Toggle from 'react-toggle';
import { PopoverLayout } from '../../../components/utility/popover/view';
import { DashboardLendingState } from '../../../models/dashboard-lending-model';
import { uiActions } from '../actions/uiActions';
import { dataHelper } from '../dataHelpers/dataHelper';
import { useStoreState } from 'easy-peasy';
import { lendingEffects } from '../actions/lendingEffects';

// const SCROLL_CONTAINER = '.custom-scroll-bar .rcs-inner-container';


const renderRow = (data, index, type, protocol, actions, chain, userWallet, comptroller, borrowUser) => {
    const { supplyApy, borrowApy, token, cToken, marketEntered, price, borrowEnabled, liquidity, collateral } = data;
    const showToggle = collateral > 0;
    const disabled = borrowUser.find(element => element.token.symbol === token.symbol) ? true : false;
    
    const onModalOpen = (type, e) => {
        actions[uiActions.actionTypes.LENDING_MODAL_INVOKED]({ name: 'open-modal', pickedAsset: data, type, chain, userWallet });
    };

    const onEnterMarket = () => {
        marketEntered ? 
            actions[lendingEffects.actionTypes.LENDING_MARKET_LEFT]({ comptroller, cTokenAddress: cToken.address, userWallet, protocol: protocol.toLowerCase(), chain }) : 
            actions[lendingEffects.actionTypes.LENDING_MARKET_ENTERED]({ comptroller, cTokenAddress: cToken.address, userWallet, protocol: protocol.toLowerCase(), chain });
    };

    return <div className='custom-table-row table-value-row table-body font-size-14 font-weight-regular' onClick={() => onModalOpen(type)} key={`body-${index}`}>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            {/* <PopoverLayout icon={protocol.toLowerCase()} message={protocol} title={false} /> */}
            <img className="me-2" width={22} height={22} src={`../dashboard-library/protocols/${protocol.toLowerCase()}.svg`} />
            <div>{protocol}</div>
        </div>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            <PopoverLayout icon={token.symbol} message={token.symbol} title={false} />
            <div>{token.symbol}</div>
        </div>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            {type === 'supply' ? Number(supplyApy).toFixed(2) : Number(borrowApy).toFixed(2)}%
        </div>
        <div className='custom-table-body-cell'>
            <div className="token-amount">0 {token.symbol}</div>
        </div>
        <div className='custom-table-body-cell d-inline-flex align-items-center'>
            {type === 'supply' ? showToggle ? <label className="customized-toggle cursor-pointer">
                <Toggle
                    onChange={onEnterMarket}
                    checked={marketEntered}
                    icons={false}
                    defaultChecked={marketEntered}
                    disabled={disabled}
                />
            </label> : null : `$${liquidity}`}
        </div>
    </div>;
};

const renderTableBody = (data, type, protocol, actions, chain, userWallet, comptroller, borrowUser) => 
    data.map((d, index) => renderRow(d, index, type, protocol, actions, chain, userWallet, comptroller, borrowUser));

export const MarketTable = props => {
    const { type } = props;
    const userWallet = useStoreState(state => state.authentication.userData.userId);
    const state = DashboardLendingState.useStoreState(state => state);
    const actions = DashboardLendingState.useStoreActions(actions => actions);
    const tableData = type === 'supply' ? dataHelper.getSortedMarket(state.tableData.supplyMarket, state.tableData.supplyUser).marketData
        : dataHelper.getSortedMarket(state.tableData.borrowMarket, state.tableData.borrowUser).marketData;
    const isLoading = state.loader;
    const protocol = state.protocolToChain.value.label;
    const chain = state.networkSelector.value.value;
    const comptroller = state.comptroller;
    const borrowUser = state.tableData.borrowUser;
    // const loadFunc = value => actions[uiActions.actionTypes.FARMS_TABLE_LOAD_MORE_LOADING]({ page: value, state });
    
    return <div className="custom-table h-100">
        <div className="scroll-container">
            { isLoading ?
                <Loader size={'6x'}/>
                :
                !isEmpty(tableData) ? renderTableBody(tableData, type, protocol, actions, chain, userWallet, comptroller, borrowUser) : <EmptySearchState/> 
            }
        </div>
    </div>;
};