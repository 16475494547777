import * as Yup from 'yup';

export const colorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: '#060f19',
    };
  },
  input: (styles) => ({ ...styles, color: 'white' }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: '#fff' }),
  option: (base, state) => {
    return {
      ...base,
      color: 'black',
      position: 'relative',
      zIndex: 100,
    };
  },
};

export const codesample_languages = [
  { text: 'HTML/XML', value: 'markup' },
  { text: 'JavaScript', value: 'javascript' },
  { text: 'CSS', value: 'css' },
  { text: 'PHP', value: 'php' },
  { text: 'Ruby', value: 'ruby' },
  { text: 'Python', value: 'python' },
  { text: 'Java', value: 'java' },
  { text: 'C', value: 'c' },
  { text: 'C#', value: 'csharp' },
  { text: 'C++', value: 'cpp' },
  { text: 'React', value: 'react' },
];

export const schema = Yup.object().shape({
  title: Yup.string().required(),
  excerpt: Yup.string().required(),
  category: Yup.string().required(),
  type: Yup.string().required(),
  tags: Yup.array().min(1),
  content: Yup.string().required(),
  time: Yup.string().required(),
});
