import { action, thunk } from 'easy-peasy';
import { constants } from '../constants';
import { utils } from '../../../utils/utils';
import { isEmpty } from 'lodash';

const { TABS } = constants;

const actionTypes = {
  CUSTOM_BOTS_TAB_TOGGLE: 'CUSTOM_BOTS_TAB#TOGGLE',
  CUSTOM_BOTS_MODAL_INVOKED: 'CUSTOM_BOTS_MODAL#INVOKED',
  CUSTOM_BOTS_TABLE_COLLAPSED: 'CUSTOM_BOTS_TABLE#COLLAPSED',
  CUSTOM_BOTS_MY_BOTS_TAB_TOGGLE: 'CUSTOM_BOTS_MY_BOTS_TAB#TOGGLE',
  CUSTOM_BOTS_MORE_CHANGED: 'CUSTOM_BOTS_MORE#CHANGED',
  CUSTOM_BOTS_SORTING_DESC_ASC_TOGGLE: 'CUSTOM_BOTS_SORTING_DESC_ASC#TOGGLE',
  CUSTOM_BOTS_START_BOT_TOGGLE: 'CUSTOM_BOTS_START_BOT#TOGGLE',
  CUSTOM_BOTS_TABLE_ROW_COLLAPSED: 'CUSTOM_BOTS_TABLE_ROW#COLLAPSED',
};

const tabHandler = (state, payload) => {
  const { tab } = payload;

  switch(tab) {
    case TABS.DISCOVER_BOT:
    case TABS.MY_BOTS:
    case TABS.MY_DEALS:
    case TABS.BOT_ANALYTICS:
      state.tabs.mainTab = tab; break;

    case TABS.GORDON:
    case TABS.WIZARD:
    case TABS.ADVANCED:
      state.tabs.mainTab = '';
      state.tabs.sideTab = tab;
      break;

    case TABS.FORM_TABS.PERCENTAGE_10:
    case TABS.FORM_TABS.PERCENTAGE_25:
    case TABS.FORM_TABS.PERCENTAGE_50:
    case TABS.FORM_TABS.PERCENTAGE_75:
    case TABS.FORM_TABS.PERCENTAGE_100:
      state.tabs.percentageTab = tab; break;

    case TABS.FORM_TABS.SAFE_SLOW.VALUE:
    case TABS.FORM_TABS.MODERATE.VALUE:
    case TABS.FORM_TABS.RISKIER_FAST.VALUE:
      state.tabs.formTab = tab; break;

    case TABS.FORM_TABS.GORDON.VALUE:
    case TABS.FORM_TABS.WIZARD.VALUE:
    case TABS.FORM_TABS.ADVANCED.VALUE:
      state.tabs.formTab1 = tab; break;

    case TABS.MAIN_SETTINGS:
    case TABS.PAIRS:
    case TABS.STRATEGY:
    case TABS.DEAL_START_CONDITION:
    case TABS.SAFETY_ORDERS:
    case TABS.STOP_LOSS:
    case TABS.TAKE_PROFIT:
    case TABS.ADVANCED_SETTINGS:
      state.tabs.wizardTab = tab; break;

    case TABS.FORM_TABS.SINGLE_PAIR:
    case TABS.FORM_TABS.MULTI_PAIR:
    case TABS.FORM_TABS.ALL:
      state.tabs.botTypeTab = tab; break;

    case TABS.FORM_TABS.LONG:
    case TABS.FORM_TABS.SHORT:
    case TABS.FORM_TABS.ALLL:
      state.tabs.strategyTab = tab; break;

    case TABS.FORM_TABS.QUOTE:
    case TABS.FORM_TABS.BASE:
      state.tabs.profitCurrencyTab = tab; break;

    case TABS.FORM_TABS.MARKET:
    case TABS.FORM_TABS.LIMIT:
      state.tabs.startOrderTypeTab = tab; break;

    case TABS.FORM_TABS.PERCENT:
    case TABS.FORM_TABS.CONDITIONS:
      state.tabs.takeProfitTab = tab; break;

    case 'USDT':
    case 'BUSD':
      state.tabs.pairsTab = tab; break;

    case TABS.ASSISTANT:
    case TABS.CHART:
    case TABS.TABLE:
      state.tabs.sidebarTab = tab; break;

    case TABS.FORM_TABS.SUMMARY_PROFIT:
    case TABS.FORM_TABS.PROFIT_BY_DAY:
    case TABS.FORM_TABS.PROFIT_BY_PAIR:
      state.tabs.statsTab = tab; break;
  }
};

const modalInvoked = (actions, payload) => {
  const { name, modal } = payload;
  const { updateState } = actions;

  if(modal === 'modalCreateBot') {
    switch(name) {
      case 'open-modal': updateState({ path: 'modals.createBot.show', value: true }); break;
      case 'close-modal': updateState({ path: 'modals.createBot.show', value: false }); break;
    }
  }
  if(modal === 'modalUsedAmountStep') {
    switch(name) {
      case 'open-modal': updateState({ path: 'modals.usedAmountStep.show', value: true }); break;
      case 'close-modal': updateState({ path: 'modals.usedAmountStep.show', value: false }); break;
    }
  }
  if(modal === 'modalBotUsedPaperAccount') {
    switch(name) {
      case 'open-modal': updateState({ path: 'modals.botUsedPaperAccount.show', value: true }); break;
      case 'close-modal': updateState([
        { path: 'modals.botUsedPaperAccount.show', value: false },
        { path: 'applyBotConfiguration.ignoreCopyBot', value: true },
      ]);
      break;
    }
  }
  if(modal === 'modalWarning') {
    switch(name) {
      case 'open-modal': updateState({ path: 'modals.warning.show', value: true }); break;
      case 'close-modal': updateState({ path: 'modals.warning.show', value: false }); break;
    }
  }
};

const tableCollapse = (state, payload) => {
  switch (payload) {
    case 'filters': state.filters.open = !state.filters.open; break;
    case 'pairsBlackList': state.pairsBlackList.open = !state.pairsBlackList.open; break;
  }
};

const myBotsTabHandler = (state, payload) => {
  const { tab } = payload;
  state.tabs.myBotsTab = tab;
};

const tableMenuHandle = (state, payload) => {
  const { value, id, action } = payload;

  if (isEmpty(value))
    return;

  switch (value.id) {
    case 'add-funds': break;
    case 'reverse-bot': break;
    case 'close': break;
    case 'edit': break;
    case 'convert': break;
    case 'refresh': break;
    case 'cancel': break;
  }
};

const sortingDescAscHandle = (state, payload) => {
  switch (payload) {
    case 'name': state.sorting.name.isDesc = !state.sorting.name.isDesc; break;
    case 'deals': state.sorting.deals.isDesc = !state.sorting.deals.isDesc; break;
    case 'takeProfit': state.sorting.takeProfit.isDesc = !state.sorting.takeProfit.isDesc; break;
    case 'avgDailyProfit': state.sorting.avgDailyProfit.isDesc = !state.sorting.avgDailyProfit.isDesc; break;
    case 'exchange': state.sorting.exchange.isDesc = !state.sorting.exchange.isDesc; break;
    case 'bot': state.sorting.bot.isDesc = !state.sorting.bot.isDesc; break;
    case 'pair': state.sorting.pair.isDesc = !state.sorting.pair.isDesc; break;
    case 'profit': state.sorting.profit.isDesc = !state.sorting.profit.isDesc; break;
    case 'volume': state.sorting.volume.isDesc = !state.sorting.volume.isDesc; break;
    case 'status': state.sorting.status.isDesc = !state.sorting.status.isDesc; break;
    case 'created': state.sorting.created.isDesc = !state.sorting.created.isDesc; break;
  }
};

const startBotHandle = (state, payload) => {
  state.applyBotConfiguration.start = payload;
}

const collapseRow = (state, payload) => {
  const { id } = payload;

  if (!state.myDealsTable.collapsed.includes(id)) state.myDealsTable.collapsed = [...state.myDealsTable.collapsed, id];
  else state.myDealsTable.collapsed = state.myDealsTable.collapsed.filter(row => row !== id);
};

const actionHandlers = {
  [actionTypes.CUSTOM_BOTS_TAB_TOGGLE]: action((state, payload) => tabHandler(state, payload)),
  [actionTypes.CUSTOM_BOTS_MODAL_INVOKED]: thunk((actions, payload) => modalInvoked(actions, payload)),
  [actionTypes.CUSTOM_BOTS_TABLE_COLLAPSED]: action((state, payload) => tableCollapse(state, payload)),
  [actionTypes.CUSTOM_BOTS_MY_BOTS_TAB_TOGGLE]: action((state, payload) => myBotsTabHandler(state, payload)),
  [actionTypes.CUSTOM_BOTS_MORE_CHANGED]: thunk((actions, payload) => tableMenuHandle(actions, payload)),
  [actionTypes.CUSTOM_BOTS_SORTING_DESC_ASC_TOGGLE]: action((state, payload) => sortingDescAscHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_START_BOT_TOGGLE]: action((state, payload) => startBotHandle(state, payload)),
  [actionTypes.CUSTOM_BOTS_TABLE_ROW_COLLAPSED]: action((state, payload) => collapseRow(state, payload)),

  updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const customBotsActions = {
  actionTypes,
  actionHandlers
};