import { action, thunk } from 'easy-peasy';
import { serviceUtils } from '../../../service/serviceUtils';
import { dataHelper } from '../dataHelpers/dataHelper';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';
import { constants } from '../../../constants/constants';

const { CHAINS } = constants;

const actionTypes = {
    FARMS_FETCHED: 'FARMS#FETCHED',
    FARMS_FETCH_SUCCEEDED: 'FARMS_FETCH#SUCCEEDED',
    FARMS_USER_FETCHED: 'FARMS_USER#FETCHED',
    FARMS_FETCH_USER_SUCCEEDED: 'FARMS_FETCH_USER#SUCCEEDED',
    FARMS_FETCH_FAILED: 'FARMS_FETCH#FAILED',
    FARMS_REWARD_HARVEST_INVOKED: 'FARMS_REWARD_HARVEST#INVOKED',
    FARMS_WITHDROW_INVOKED: 'FARMS_WITHDROW#INVOKED',
    FARMS_DEPOSIT_INVOKED: 'FARMS_DEPOSIT#INVOKED',
    FARMS_APPROVE_INVOKED: 'FARMS_APPROVE#INVOKED',
    FARMS_TX_SUCCEEDED: 'FARMS_TX#SUCCEEDED',
    HARVEST_ALL_INVOKED: 'HARVEST_ALL#INVOKED',
    ALL_FARMS_FETCHED:'ALL_FARMS#FETCHED',
    ALL_FARMS_FETCH_SUCCEEDED: 'ALL_FARMS_FETCH#SUCCEEDED',
    PANCAKE_FARM_SUCCEEDED: 'PANCAKE_FARM#SUCCEEDED',
    TRADER_JOE_FARM_SUCCEEDED: 'TRADER_JOE_FARM#SUCCEEDED',
    QUICK_SWAP_FARM_SUCCEEDED: 'QUICK_SWAP_FARM#SUCCEEDED',
    ALL_PANCAKE_FARMS_FETCH: 'ALL_PANCAKE_FARMS#FETCH',
    ALL_JOE_FARMS_FETCH: 'ALL_JOE_FARMS#FETCH',
    ALL_QUICK_FARMS_FETCH: 'ALL_QUICK_FARMS#FETCH',
    USER_PANCAKE_FARMS_FETCHED: 'USER_PANCAKE_FARMS#FETCHED',
    USER_TRADER_JOE_FARMS_FETCHED: 'USER_TRADER_JOE_FARMS#FETCHED',
    USER_QUICK_FARMS_FETCHED: 'USER_QUICK_FARMS#FETCHED'
};

const fetchHandle = (actions, payload) => {
    const { userWallet, value } = payload;
    
    if(value === "all"){
        
        serviceUtils.HttpService({
            url: `/web3/joeFarmData?address=${userWallet}&data=farms`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.ALL_JOE_FARMS_FETCH]
        });  
        serviceUtils.HttpService({
            url: `/web3/pancakedata?data=farms&address=${userWallet}`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.ALL_PANCAKE_FARMS_FETCH]
        }); 
    }
    else{
        const url = value === 'joe' ? `/web3/${value}FarmData?address=${userWallet}&data=farms` : `/web3/${value}data?data=farms&address=${userWallet}`;
        serviceUtils.HttpService({
            url,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.FARMS_FETCH_SUCCEEDED]
        });  
        
    }
   
};
const successAllPancakeFarmsHandle = (state, payload) =>{
    const { response: { lpFarms } } = payload;
    state.loader = false;
    state.allPancakeFarms = lpFarms;
    state.allFarms = state.allPancakeFarms.concat(state.allTraderJoeFarms);
    if (state.typeOfFetch === 'push')
        state.tableData.lpFarms = [...state.tableData.lpFarms, ...state.allFarms];
    else
        state.tableData.lpFarms = state.allFarms;
            
        state.cheffAddress =  state.tableData.lpFarms[0].cheffAddress;
       
    state.tableData.pagination.hasMore = state.allFarms.length < 50 ? false : true;
    
}

const successAllJoeFarmsHandle = (state, payload) =>{
    const { response: { lpFarms } } = payload;
    state.loader = false;
    state.allTraderJoeFarms = lpFarms;
    
}

const successAllQuickFarmsHandle = (state, payload) =>{
    const { response: { lpFarms } } = payload;
    state.loader = false;
}
    const fetchAllProtocols= (actions, payload) => {
        const { userWallet } = payload;

      serviceUtils.HttpService({
           url: `/web3/pancake/farms?address=${userWallet}`,
             errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
             successActionType: actions[actionTypes.PANCAKE_FARM_SUCCEEDED]
         });  
         
         serviceUtils.HttpService({
            url: `/web3/joe/farms?address=${userWallet}`,
             errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
             successActionType: actions[actionTypes.TRADER_JOE_FARM_SUCCEEDED]
         }); 
         serviceUtils.HttpService({
            url: `/web3/quick/farms?address=${userWallet}`,
             errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
             successActionType: actions[actionTypes.QUICK_SWAP_FARM_SUCCEEDED]
         }); 

    }

const fetchUserHandle = (actions, payload) => {
    
    const { userWallet, value } = payload;
    if (value !== 'all') {
        serviceUtils.HttpService({
            url: `/web3/${value}/farms?address=${userWallet}`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.FARMS_FETCH_USER_SUCCEEDED]
        }); 
    }
    else{
        serviceUtils.HttpService({
            url: `/web3/joe/farms?address=${userWallet}`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.USER_TRADER_JOE_FARMS_FETCHED]
        });

        serviceUtils.HttpService({
            url: `/web3/pancake/farms?address=${userWallet}`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.USER_PANCAKE_FARMS_FETCHED]
        });
        setTimeout(() =>  serviceUtils.HttpService({
            url: `/web3/quick/farms?address=${userWallet}`,
            errorActionType: actions[actionTypes.FARMS_FETCH_FAILED],
            successActionType: actions[actionTypes.USER_QUICK_FARMS_FETCHED]
        }), 2000);
      
    }
      
    
};

const successHandle = (state, payload) => {
    const { response: { lpFarms } } = payload;
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.lpFarms = [...state.tableData.lpFarms, ...lpFarms];
    else
        state.tableData.lpFarms = lpFarms;
            
        state.cheffAddress =  state.tableData.lpFarms[0].cheffAddress;
       
    state.tableData.pagination.hasMore = lpFarms.length < 50 ? false : true;
  
   // state.allFarms = [...lpFarms];
};

const successAllFarmsHandle = (state, payload) => {
    const { response: { lpFarms } } = payload;
    state.loader = false;
   
 // state.allFarms = [lpFarms];
};
const successPacakeSwapHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.loader = false;
    state.tableData.pancakeSwapData = farms;
    
};

const successTraderJoeHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.loader = false;
    state.tableData.traderJoeSwapData = farms;
  
};

const successQuickSwapHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.loader = false;
    state.tableData.quickSwapData = farms;
  
};

const successPancakeUserHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.tableData.pancakeAllData = farms;
    state.loader = false;

};
const successTraderJoeUserHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.tableData.traderJoeAllData = farms;
    state.loader = false;
 
};
const successQuickUserHandle = (state, payload) => {
    const { response: { farms } } = payload;
    state.tableData.quickSwapAllData = farms;
    state.tableData.allData = state.tableData.quickSwapAllData.concat(state.tableData.traderJoeAllData,state.tableData.pancakeAllData)
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.data = [...state.tableData.data, ...state.tableData.allData];
    else
        state.tableData.data = state.tableData.allData;
    state.tableData.data = dataHelper.formatArrayObject(state.tableData.data, state.cheffAddress);
    
    state.tableData.pagination.hasMore = state.tableData.allData.length < 50 ? false : true;
    state.tableData.mergedFarms = [...state.tableData.data, ...state.tableData.lpFarms];
    state.tableData.filteredData = state.tableData.mergedFarms.filter(farm => !farm.reward_amount || farm.reward_amount <= 0);

};
const successUserHandle = (state, payload) => {
    const { response: { farms } } = payload;
   
    state.loader = false;
    if (state.typeOfFetch === 'push')
        state.tableData.data = [...state.tableData.data, ...farms];
    else
        state.tableData.data = farms;
    state.tableData.data = dataHelper.formatArrayObject(state.tableData.data, state.cheffAddress);
    
    state.tableData.pagination.hasMore = farms.length < 50 ? false : true;
    state.tableData.mergedFarms = [...state.tableData.data, ...state.tableData.lpFarms];
    state.tableData.filteredData = state.tableData.mergedFarms.filter(farm => !farm.reward_amount || farm.reward_amount <= 0);

};

const errorHandle = (state, payload) => {
    state.loader = false;
    state.tableData.data = state.typeOfFetch === 'push' ? state.tableData.data : [];
    state.tableData.pagination.hasMore = true;
};


const harvestHandle = async (actions, payload) => {
    const { pid, from, chain, cheff } = payload;
    const { updateState } = actions;
    const network = CHAINS.find(c => c.oldchain === chain);
    const { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol, chainId } = network;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
       
        const params = dataHelper.getHarvestParams(pid, from, cheff);
            
        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
       
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelper.getHarvestParams(pid, from, cheff);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
                
            }
        }
        // handle other "switch" errors
    }
};

const withdrawHandle = async (actions, payload) => {
    const { pid, from, amount, chain, cheff } = payload;
    const { updateState } = actions;
    const network = CHAINS.find(c => c.oldchain === chain);
    const { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol, chainId } = network;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelper.getUnstakeParams(pid, from, amount, cheff);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelper.getUnstakeParams(pid, from, amount, cheff);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const depositHandle = async (actions, payload) => {
    const { pid, from, amount, chain, cheff } = payload;
    const { updateState } = actions;
    const network = CHAINS.find(c => c.oldchain === chain);
    const { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol, chainId } = network;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelper.getStakeParams(pid, from, cheff, amount);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelper.getStakeParams(pid, from, cheff, amount);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const txSuccess = async (actions, payload) => {
    const { hash, from } = payload;
    const { updateState } = actions;
    
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const status = await provider.waitForTransaction(hash);
    
    if(status.status) {
        updateState({ path: 'currentTxStatus', value: 0 });
        actions[actionTypes.FARMS_FETCHED]({ userWallet: from });
        actions[actionTypes.FARMS_USER_FETCHED]({ userWallet: from });
        //actions[actionTypes.ALL_FARMS_FETCHED]({ userWallet: from });
    }
};

const approveInvoked = async (actions, payload) => {
    const { from, to, chain, cheff } = payload;
    const { updateState } = actions;
    const network = CHAINS.find(c => c.oldchain === chain);
    const { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol, chainId } = network;
    const options = { chainName, rpcUrls, blockExplorerUrls, nativeTokenSymbol };

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${chainId.toString(16)}` }],
        });

        const params = dataHelper.getApproveParams(from, to, cheff);

        const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
        actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
        updateState({ path: 'currentTxStatus', value: 1 });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: `0x${chainId.toString(16)}`,
                            ...options
                        },
                    ],
                });

                const params = dataHelper.getApproveParams(from, to, cheff);

                const result = await window.ethereum.request({ method: 'eth_sendTransaction', params: [params] });
                actions[actionTypes.FARMS_TX_SUCCEEDED]({ hash: result, from });
                updateState({ path: 'currentTxStatus', value: 1 });
            } catch (addError) {
                // handle "add" error
            }
        }
        // handle other "switch" errors
    }
};

const actionHandlers = {
    [actionTypes.FARMS_TX_SUCCEEDED]: thunk(async (actions, payload) => txSuccess(actions, payload)),
    [actionTypes.FARMS_APPROVE_INVOKED]: thunk(async (actions, payload) => approveInvoked(actions, payload)),
    [actionTypes.FARMS_DEPOSIT_INVOKED]: thunk(async (actions, payload) => depositHandle(actions, payload)),
    [actionTypes.FARMS_WITHDROW_INVOKED]: thunk(async (actions, payload) => withdrawHandle(actions, payload)),
    [actionTypes.FARMS_FETCHED]: thunk( async (actions, payload) => fetchHandle(actions, payload)),
    [actionTypes.FARMS_FETCH_SUCCEEDED]: action((state, payload) => successHandle(state, payload)),
    [actionTypes.FARMS_USER_FETCHED]: thunk( async (actions, payload) => fetchUserHandle(actions, payload)),
    [actionTypes.FARMS_FETCH_USER_SUCCEEDED]: action((state, payload) => successUserHandle(state, payload)),
    [actionTypes.FARMS_FETCH_FAILED]: action((state, payload) => errorHandle(state, payload)),
    [actionTypes.FARMS_REWARD_HARVEST_INVOKED]: thunk(async (actions, payload) => harvestHandle(actions, payload)),
    [actionTypes.HARVEST_ALL_INVOKED]: thunk(async (actions, payload) => collectAllHandle(actions, payload)),
    [actionTypes.ALL_FARMS_FETCHED]: thunk( async (actions, payload) => fetchAllProtocols(actions, payload)),
    [actionTypes.ALL_FARMS_FETCH_SUCCEEDED]: action((state, payload) => successAllFarmsHandle(state, payload)),
    [actionTypes.PANCAKE_FARM_SUCCEEDED]: action((state, payload) => successPacakeSwapHandle(state, payload)),
    [actionTypes.TRADER_JOE_FARM_SUCCEEDED]: action((state, payload) => successTraderJoeHandle(state, payload)),
    [actionTypes.QUICK_SWAP_FARM_SUCCEEDED]: action((state, payload) => successQuickSwapHandle(state, payload)),
    [actionTypes.ALL_PANCAKE_FARMS_FETCH]: action((state, payload) => successAllPancakeFarmsHandle(state, payload)),
    [actionTypes.ALL_JOE_FARMS_FETCH]: action((state, payload) => successAllJoeFarmsHandle(state, payload)),
    [actionTypes.ALL_QUICK_FARMS_FETCH]: action((state, payload) => successAllQuickFarmsHandle(state, payload)),
    [actionTypes.USER_PANCAKE_FARMS_FETCHED]: action((state, payload) => successPancakeUserHandle(state, payload)),
    [actionTypes.USER_TRADER_JOE_FARMS_FETCHED]: action((state, payload) => successTraderJoeUserHandle(state, payload)),
    [actionTypes.USER_QUICK_FARMS_FETCHED]: action((state, payload) => successQuickUserHandle(state, payload)),
    updateState: action((state, payload) => utils.stateHelper(state, payload))
};

export const farmsEffects = {
    actionTypes,
    actionHandlers
};