import React, { useEffect } from 'react';
import { AcademyState } from '../../../../../models/academy-model';
import { constants } from '../constants';
import CloseButton from 'react-bootstrap/CloseButton';

const {
  ARTICLES: {
    buttons: { btnClearAll },
  },
} = constants;

const SelectedFilters = () => {
  const state = AcademyState.useStoreState((state) => state);
  const actions = AcademyState.useStoreActions((actions) => actions);
  const selectedFilters = AcademyState.useStoreState((state) => state.filters.selectedFilters);
  const selectedTag = state.selectedTag;

  let location = window.location.search;
  let isPublic = location === '?elem=academy' ? true : false;

  const hasSelectedFilters = selectedFilters.length > 0;
  const hasSelectedTag = selectedTag !== null;

  const SelectedFilter = (filter, index) => {
    return (
      <div key={index} className="selected-tag">
        {filter.hasOwnProperty('signal') ? <div className={`signal ${filter.signal}`}></div> : null}
        <span>{filter.value}</span>
        <span onClick={() => actions.REMOVE_SELECTED_TAG({ filter, index })} className="icon">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4566 11.5608C11.2327 11.7847 10.8705 11.7847 10.6463 11.5608L5.89577 6.8092L1.14414 11.5608C0.920237 11.7847 0.557974 11.7847 0.333821 11.5608C0.109918 11.3369 0.109918 10.9747 0.333821 10.7505L5.08652 5.99995L0.334537 1.24833C0.110634 1.02442 0.110634 0.662161 0.334537 0.438008C0.55844 0.214105 0.920702 0.214105 1.14486 0.438008L5.89577 5.19071L10.6474 0.439082C10.8713 0.215179 11.2336 0.215179 11.4577 0.439082C11.6816 0.662985 11.6816 1.02525 11.4577 1.2494L6.70501 5.99995L11.4566 10.7516C11.6822 10.9736 11.6822 11.3388 11.4566 11.5608Z"
              fill="#302F35"
            />
          </svg>
        </span>
      </div>
    );
  };

  return (
    <div className="selected-tags">
      {hasSelectedFilters && selectedFilters.map((filter, index) => SelectedFilter(filter, index))}

      {hasSelectedFilters && (
        <div onClick={() => actions.CLEAR_ALL_SELECTED_TAGS()} className="btn-clear">
          {btnClearAll}
        </div>
      )}

      {hasSelectedTag && (
        <div className="selectedTag">
          <div className="badge">{selectedTag}</div>
          <CloseButton
            onClick={() => actions.REMOVE_ARTICLES_BY_TAG(isPublic)}
            className="ms-2 close"
          />
        </div>
      )}
    </div>
  );
};

export default SelectedFilters;
