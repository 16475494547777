import { vestingConstants } from '../constants';
import { utils } from '../../../utils/utils';
import { ethers } from 'ethers';

const { VEST: { USDT_AMOUNT: { DAYS_180, DAYS_90 } }, ABI } = vestingConstants;


const countMCSAmount = (dayCost, usdtAmount) => {
    const result = usdtAmount / dayCost;
    return result.toFixed(3);
};

const getExpectedMCSAmount = (usdtAmount, days) => {
    switch(days) {
    case 90: return countMCSAmount(DAYS_90, usdtAmount);
    case 180: return countMCSAmount(DAYS_180, usdtAmount);
    case 545: return countMCSAmount(0.05, usdtAmount);
    }
};

const getUsdtBalance = async usdtAddress => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const usdtContract = new ethers.Contract(usdtAddress, ABI, provider);
    const balance = await usdtContract.balanceOf(address);

    const result = +utils.fromWei(utils.hex2num(balance._hex));
    const num = result > 0 ? result.toFixed(6) : result;
        
    return num;
};

const getLeftDays = (end, period) => {
    const today = Math.ceil(Date.now() / 1000);
    const leftSeconds = end - today;
    const result = Math.ceil(leftSeconds / 3600 / 24);
    
    return { left: result, daysGone: period - result };
};

const countFutureDate = days => {
    const today = Math.ceil(Date.now() / 1000);
    const nextTimestamp = today + days * 24 * 3600;
    
    return getDateFromTimestamp(nextTimestamp);
};

const getDateFromTimestamp = timestamp => {
    const date = new Date(timestamp * 1000);
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedTime = `${day} ${month.toUpperCase()} ${year}`;
    
    return formattedTime;
};

const getApproveParameters = (userWallet, usdtAddress, contractAddress) => {
    return {
        from: userWallet,
        to: usdtAddress,
        value: '0x0',
        gas: '0xea60',
        data: `0x095ea7b3${utils.stringCreator('0', 24)}${contractAddress.slice(2)}${utils.stringCreator('f', 64)}`
    };
};

const getBuyParameters = (userWallet, contractAddress, selling) => {
    const value = (selling * 10 * Math.pow(10, 18)).toString(16);
    const prefix = '0x94b918de';
    const formattedValue = `${utils.stringCreator('0', 64 - value.length)}${value}`;

    return {
        from: userWallet,
        to: contractAddress,
        value: '0x0',
        gas: '0x30d40',
        data: `${prefix}${formattedValue}`
    };
};

const startSafeBuy = (userWallet, contractAddress, days, mcsAmount) => {
    const mcsSumHex = (mcsAmount * 1e18).toString(16);
    const daysHex = days.toString(16);
    const data = `0xfe3484f6${utils.stringCreator('0', 64 - mcsSumHex.length)}${mcsSumHex}${utils.stringCreator('0', 64 - daysHex.length)}${daysHex}`;

    return {
        from: userWallet,
        to: contractAddress,
        value: '0x0',
        gas: '0x55730',
        data
    };
};

const earlyExitSafeBuy = (userWallet, contractAddress) => {
    return {
        from: userWallet,
        to: contractAddress,
        value: '0x0',
        gas: '0x249f0',
        data: '0xa4dee155'
    };
};

const withdrawAvailable = (userWallet, contractAddress) => {
    return {
        from: userWallet,
        to: contractAddress,
        value: '0x0',
        gas: '0x249f0',
        data: `0xb2a25b52${utils.stringCreator('0', 64 - userWallet.slice(2).length)}${userWallet.slice(2)}`
    };
};

const startVesting = (userWallet, contractAddress, mcsAmount) => {
    const mcsSumHex = (mcsAmount * 1e18).toString(16);
    const data = `0xbf85e628${utils.stringCreator('0', 64 - mcsSumHex.length)}${mcsSumHex}${utils.stringCreator('0', 63)}1`;

    return {
        from: userWallet,
        to: contractAddress,
        value: '0x0',
        gas: '0x55730',
        data
    };
};

export const dataHelper = {
    countFutureDate,
    getLeftDays,
    getUsdtBalance,
    getExpectedMCSAmount,
    getApproveParameters,
    getBuyParameters,
    startSafeBuy,
    earlyExitSafeBuy,
    withdrawAvailable,
    getDateFromTimestamp,
    startVesting
};