import React from 'react';
import { ProfileState } from '../../../models/profile-model';
import { ProfileModal } from '../modals/profileModal';
import { EditModal } from '../modals/editModal';
import { constants } from '../constants';
import { isEmpty } from 'lodash';
import { WalletList } from './walletsList';
import { StandardOptions } from '../../../components/utility/standard-options/view';
import { profilePageActions } from '../actions/uiActions';

const {
  PROFILE_CARD: { WALLETS, WALLETS_NUMBER, PROFILE_STATUS },
  STANDARD_OPTIONS_SCHEMA,
} = constants;

export const ProfileCard = ({ indexCard, data }) => {
  const state = ProfileState.useStoreState((state) => state);
  const actions = ProfileState.useStoreActions((actions) => actions);
  const wallet = state.walletData.find((wallet) => wallet.index == indexCard);
  indexCard == wallet?.index ? (data.isWalletAddress = true) : (data.isWalletAddress = false);
  const handleClick = (event) => {
    state.isEditModalOpen
      ? actions[profilePageActions.actionTypes.PROFILE_EDIT_INVOKED]({
          name: 'close-edit-modal',
          modal: 'EDIT',
        })
      : actions[profilePageActions.actionTypes.PROFILE_EDIT_INVOKED]({
          name: 'open-edit-modal',
          modal: 'EDIT',
        });
  };
  // console.log('state: ', state)
  // console.log('index card, data.id : ', indexCard, data.id)
  return (
    <>
      <div key={indexCard} className="profile-card">
        <div className="header-wrapper">
          <div className="image-wrapper"></div>
          <div className="user-data">
            <div className="profile-name"> {data.title}</div>
            <div className="profile-status-wrapper">
              <div className="title" onClick={() => console.log(data.id)}>
                {' '}
                {PROFILE_STATUS}{' '}
              </div>
            
                    </div>
                    {data.isWalletAddress ? <div className="wallet-container">
                      {WALLETS}: {WALLETS_NUMBER}
                    </div> : null}
                  </div>
               
                  <div className="selector" >
                  
                  <StandardOptions
                   more={{ 
                    ...STANDARD_OPTIONS_SCHEMA, 
                    actionName: profilePageActions.actionTypes.MENU_HANDLE_INVOKED, 
                    appendToPayload: { id: indexCard, action: actions, cardId: data.id } }}
                    />
                  </div>
             
                </div>
                
                <EditModal state={state} actions={actions} show={state.isEditModalOpen} idx={indexCard} index={data.id}/>
                  {<WalletList idx={indexCard} index={data.id}/> }
                <ProfileModal
                  show={state.isModalOpen}
                  actions={actions}
                  title={state.modalTitle}
                  index={data.id}
                />
              </div>  
    </>
  );
};
