import { action } from 'easy-peasy';
import moment from 'moment';

const actionTypes = {
    BALANCE_TIME_INTERVAL_UPDATED: 'BALANCE_TIME_INTERVAL#UPDATED',
};

const liveTimeUpdateAction = state => {
    state.balance.currentDate = moment().format('DD/MM/YYYY');
    state.balance.currentTime = moment().format('HH:mm A');
};

const actionHandlers = {
    [actionTypes.BALANCE_TIME_INTERVAL_UPDATED]: action((state, value) => liveTimeUpdateAction(state, value)),

};

export const balanceEffects = {
    actionTypes,
    actionHandlers
};