import React from 'react';
import { constants } from '../../../constants/constants';
import { useStoreState } from 'easy-peasy';
import { headerEffects } from '../../header/headerEffects';
import { Selector } from '../dropdown-selector/view';



const { LANGUAGE_SELECTOR, LANGUAGE_POPPER_DATA } = constants;
const titleClasses='';
const iconPosition='';
const menuAlign='end';

export const LanguageSelector = () => {
    const state = useStoreState(state => state.root);
    const languagePanelState = state.languagePanelState ? 'active' : '';
    const title= <i className={`fa-thin fa-globe ${languagePanelState}`}></i>;
    return <Selector
        list={LANGUAGE_SELECTOR.LIST}
        value={{id: state.language}}
        title={title}
        titleClasses={titleClasses}
        iconPosition={iconPosition}
        menuAlign={menuAlign}
        actionName={headerEffects.actionTypes.HEADER_SETTINGS_LANGUAGE_CHANGED}
        toggleActionName={headerEffects.actionTypes.HEADER_SETTINGS_LANGUAGE_PANEL_OPENED}
        popperData={LANGUAGE_POPPER_DATA}
    />
    
};