import moment from 'moment';
import { serviceUtils } from '../../../service/serviceUtils';
import { hodlBotEffects } from './hodlBotEffects';

const REQUEST_URL = '/hodl-bot/';

const createUpdateBotAction = (actions, payload, method) => {
  const API = {
    ...payload,
    'botFirstPurchaseDate': moment(payload.botFirstPurchaseDate).format('MM/DD/YYYY')
  };
  const url = method === 'create-bot' ? 'create' : 'update'
  const options = { actions, url: `${REQUEST_URL}${url}`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: method === 'create-bot' ? 'POST' : 'PUT',
    data: options.API,
    errorActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_FAILED],
    successActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_SUCCEEDED],
    ...options
  });
};

const startBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}start`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_FAILED],
    successActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_SUCCEEDED],
    ...options
  });
};

const stopBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}stop`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'POST',
    data: options.API,
    errorActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_FAILED],
    successActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_SUCCEEDED],
    ...options
  });
};

const deleteBotAction = (actions, payload) => {
  const API = {
    ...payload,
  };
  const options = { actions, url: `${REQUEST_URL}delete`, API, payload };

  serviceUtils.HttpService({
    url: options.url,
    method: 'DELETE',
    data: options.API,
    errorActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_FAILED],
    successActionType: actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_SUCCEEDED],
    ...options
  });
};

export const hodlBotFactory = {
  createUpdateBotAction,
  startBotAction,
  stopBotAction,
  deleteBotAction
};
