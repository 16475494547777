import { action, thunk } from 'easy-peasy';
import { notifyEffects } from '../../../components/utility/notifications/notifyEffects';
import { serviceFactory } from './serviceFactory';

const actionTypes = {
  CUSTOM_BOTS_REQUESTS_INVOKED: 'CUSTOM_BOTS_REQUESTS#INVOKED',
  CUSTOM_BOTS_REQUESTS_SUCCEEDED: 'CUSTOM_BOTS_REQUESTS#SUCCEEDED',
  CUSTOM_BOTS_REQUESTS_FAILED: 'CUSTOM_BOTS_REQUESTS#FAILED'
};

const invokeHandle = (actions, payload) => {
  const { method, values } = payload;

  switch (method) {
    case 'create-dca-bot': serviceFactory.createDCABotAction(actions, values); break;
    case 'create-gordon-bot': serviceFactory.createGordonBotAction(actions, values); break;
    case 'create-wizard-bot': serviceFactory.createWizardBotAction(actions, values); break;
    case 'create-advanced-bot': serviceFactory.createAdvancedBotAction(actions, values); break;
  }
}

const successHandle = (actions, payload) => {
  const { options, response } = payload;

  switch (options.url) {
    case '/custom-bots/create-dca': console.log('/custom-bots/create-dca'); break;
    case '/custom-bots/create-gordon': console.log('/custom-bots/create-gordon'); break;
    case '/custom-bots/create-wizard': console.log('/custom-bots/create-wizard'); break;
    case '/custom-bots/create-advanced': console.log('/custom-bots/create-advanced'); break;
  }
}

const errorAction = () => {
  const notification = { type: 'danger', text: 'Something goes wrong' };
  notifyEffects.notifyAction(notification);
};

const actionHandlers = {
  [actionTypes.CUSTOM_BOTS_REQUESTS_INVOKED]: thunk( async (actions, payload) => invokeHandle(actions, payload)),
  [actionTypes.CUSTOM_BOTS_REQUESTS_SUCCEEDED]: thunk( (actions, payload) => successHandle(actions, payload)),
  [actionTypes.CUSTOM_BOTS_REQUESTS_FAILED]: action( (state, payload) => errorAction(state, payload))
};

export const serviceEffects = {
  actionTypes,
  actionHandlers
};
