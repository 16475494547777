import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { constants } from '../constants';
import { uiActions } from '../actions/uiActions';
import {hodlBotEffects} from "../actions/hodlBotEffects";

const {
  MODALS: { BOT_CREATED, START_BOT },
  BUTTONS: { NOT_NOW, START }
} = constants;

export const BotCreatedModal = props => {
  const { show, actions, state } = props;

  const handleClose = () => actions[uiActions.actionTypes.HODL_BOT_MODAL_INVOKED](false);
  const handleStartBot = () => actions[hodlBotEffects.actionTypes.HODL_BOT_REQUESTS_INVOKED]({
    values: { botId: state.botId },
    method: 'start-bot'
  })

  return <Modal
    show={show}
    size="md"
    centered
    className="bot-created"
    backdrop="static"
    container={document.querySelector('.wrapper')}
    onHide={handleClose}
  >
    <Modal.Header>
      <div className="cursor-pointer cancel-icon" onClick={handleClose}>
        <i className="icon-cancel fa-lg"></i>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className="font-inter text-center">
        <img className='img-fluid mb-3' src='../dashboard-library/success.svg' alt="Success" />
        <p className="font-size-24 font-weight-bold">{BOT_CREATED}</p>
        <p className="mb-4">{START_BOT}?</p>
        <div className="submit-button mb-2">
          <Button
            type="button"
            size="lg"
            className="font-size-14 font-weight-medium not-now"
            onClick={handleClose}>{NOT_NOW}</Button>
          <Button
            type="button"
            size="lg"
            className="font-size-14 font-weight-medium start"
            onClick={handleStartBot}
            >{START}</Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>;
};
