import React, { useState} from "react";
import { constants } from "../constants";
import { DoughnutChart } from "../../../components/utility/chart-doughnut/view";
import { uiActions } from "../actions/uiActions";
import { AnalyticsModal } from "../../defiMultiChainDashboard/templates/analyticsModal";
import { dataHelper } from "../dataHelpers/dataHelper";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { ButtonCustom } from "../../../components/utility/custom-buttons/view";
import { farmsEffects } from "../actions/farmsEffects";
import { poolsEffects } from "../../poolsPage/actions/poolsEffects";
import { useStoreState } from "easy-peasy";
import { lendingEffects } from "../../dashboardLending/actions/lendingEffects";

const {
  ANALYTICS: { SHOW_ALL, TOKEN, PROTOCOL, CHAIN },
  ACCOUNT,
  COLORS,
} = constants;

export const AnalyticsContent = (props) => {
  const { actions,mockData, state, pageId } = props;
  const [balance, setBalance] = useState();
  let protocolHeading;
  let tokenAllocation;
  let protocolAllocation;
  let chartTokenAllocation;
  let pageContent;
  let pageContentTotalCollect;
  let totalToCollect;
  let buttonAvailability;

  let stakedValue;
  let showPage;
  const userWallet = useStoreState(
    (state) => state.authentication.userData.userId
  );
  const modalOpen = (name, data, heading) =>
    actions[uiActions.actionTypes.DEFI_MULTICHAIN_DASHBOARD_MODAL_INVOKED]({
      name,
      data,
      heading,
    });
    

  const location = useLocation();

  const sumReward = (array) => {
    try {
      if (Array.isArray(array) && array.length) {
        if (array.length > 1) {
          array.reduce((rewardA, rewardB) => {
            totalToCollect =
              Number(rewardA.reward_price) + Number(rewardB.reward_price);
          });
          pageContentTotalCollect = (
            <div className="value">~ ${totalToCollect.toFixed(2)}</div>
          );
        } else {
          pageContentTotalCollect = (
            <div className="value">
              ~ ${Number(array[0].reward_price).toFixed(2)}
            </div>
          );
        }
      }
      // reward_price
    } catch (error) {
      console.log(error);
    }
  };


  const stakeReward = (array) => {
    try {
      if (Array.isArray(array) && array.length) {
        if (array.length > 1) {
          array.reduce((rewardA, rewardB) => {
            stakedValue =
              Number(rewardA.staked_price) + Number(rewardB.staked_price);
          });
          showPage = <div className="value">~ ${stakedValue.toFixed(2)}</div>;
        } else {
          showPage = (
            <div className="value">
              ~ ${Number(array[0].staked_price).toFixed(2)}
            </div>
          );
        }
      }
      // reward_price
    } catch (error) {
      console.log(error);
    }
  };

  const calculateXVSBalanceToUsd = async (venus) => {
    let price = await venus.getPrice(Venus.XVS);
    totalToCollect = Number(state.rewardXVS) * price;
    setBalance(totalToCollect.toFixed(2));
  };

  switch (pageId) {
    case 1:
      protocolHeading = PROTOCOL.HEADING_POOLS;
      const onPoolsHarvest = (address) =>
        actions[poolsEffects.actionTypes.POOLS_REWARD_HARVEST_INVOKED]({
          from: userWallet,
          to: address,
        });
      const collectAllPools = () => {
        state.tableData.data.map((index) => {
          onPoolsHarvest(index.pid, state.chain, index.cheffAddress);
        });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.filteredData.length}
          variant="primary"
          label="Harvest all"
          onClick={collectAllPools}
        />
      );
      sumReward(state.tableData.data);
      stakeReward(state.tableData.data);
      pageContent = (
        <div className="description">
          to collect from {state.tableData.data.length} pools
        </div>
      );

     tokenAllocation = dataHelper.getTokensPoolFraction(dataHelper.mergedAllPoolExhanges(state));
 protocolAllocation = dataHelper.getPoolProtocolsFraction(dataHelper.mergedAllPoolExhanges(state));
  chartTokenAllocation = [
    ...tokenAllocation.slice(0, 4),
    {
      asset: "Others",
      fraction: dataHelper.getOthersFraction(tokenAllocation),
    },
  ]
      break;
    case 2:
      protocolHeading = PROTOCOL.HEADING_FARMS;
      const onFarmHarvest = (pid, chain, contract) => {
        actions[farmsEffects.actionTypes.FARMS_REWARD_HARVEST_INVOKED]({
          pid,
          from: userWallet,
          chain,
          cheff: contract,
        });
      };
      const collectAllFarm = () => {
        state.tableData.data.map((index) => {
          onFarmHarvest(index.pid, state.chain, index.cheffAddress);
        });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.filteredData.length}
          variant="primary"
          label="Harvest all"
          onClick={collectAllFarm}
        />
      );

      sumReward(state.tableData.data);
      stakeReward(state.tableData.data);
      pageContent = (
        <div className="description">
          to collect from {state.tableData.data.length} farm
        </div>
      );
      tokenAllocation = dataHelper.getTokensFraction(dataHelper.mergedAllExhanges(state));
      protocolAllocation = dataHelper.getProtocolsFraction(dataHelper.mergedAllExhanges(state));
       chartTokenAllocation = [
         ...tokenAllocation.slice(0, 4),
         {
           asset: "Others",
           fraction: dataHelper.getOthersFraction(tokenAllocation),
         },
       ]
      break;
    case 3:
      const venus = new Venus(window.ethereum);

      calculateXVSBalanceToUsd(venus);
      pageContentTotalCollect = <div className="value">~ ${balance}</div>;
      const handleHarvestAll = (venus) => {
        actions[lendingEffects.actionTypes.LENDING_COLLECT_INVOKED]({ venus });
      };
      buttonAvailability = (
        <ButtonCustom
          disabled={!state.tableData.supplyUser.length}
          variant="primary"
          label="Harvest all"
          onClick={() => handleHarvestAll(venus)}
        />
      );
      pageContent = <div className="description">to collect</div>;
      break;
  }

  return (
    <div className="analytics-content">
      <div className="analytics-item">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">{TOKEN.HEADING}</div>
          {!isEmpty(tokenAllocation) ? (
            <div
              className="show-all font-size-14"
              onClick={() =>
                modalOpen("open-modal", tokenAllocation, TOKEN.HEADING)
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {!isEmpty(tokenAllocation) ? (
            <>
              <div className="analytics-chart d-flex align-items-center">
                <DoughnutChart data={chartTokenAllocation} />
              </div>
              <div className="analytics-chart-legend">
                {chartTokenAllocation.map((protocol, index) => (
                  <div key={index} className="legend-item">
                    <div className="d-flex align-items-center title-wrapper">
                      <div
                        style={{ background: COLORS[index] }}
                        className="color-circle me-1"
                      ></div>
                      <div className="legend-value">{protocol.asset}</div>
                    </div>
                    <div className="protocol-value font-weight-medium">
                      {protocol.fraction}%
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            "No Data"
          )}
        </div>
      </div>
      <div className="analytics-item align-items-center">
        <div className="analytics-item-header">
          <div className="heading font-weight-medium">{protocolHeading.toUpperCase()}</div>
          {!isEmpty(state.tableData.data) ? (
            <div
              className="show-all font-size-14"
              onClick={() =>
                modalOpen(
                  "open-modal",
                  state.protocolSelector.list,
                  protocolHeading
                )
              }
            >
              {SHOW_ALL}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="analytics-item-content align-items-center">
          {!isEmpty(protocolAllocation) ? (
            <>
              <div className="analytics-chart d-flex align-items-center">
                <DoughnutChart data={protocolAllocation} />
              </div>
              <div className="analytics-chart-legend">
                {protocolAllocation.map((protocol, index) => (
                  <div key={index} className="legend-item">
                    <div className="d-flex align-items-center title-wrapper">
                      <div
                        className="color-circle me-1"
                        style={{ background: COLORS[index] }}
                      ></div>
                      <div className="legend-value">{protocol.asset}</div>
                    </div>
                    <div className="protocol-value font-weight-medium">
                      {protocol.fraction}%
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            "No Data"
          )}
        </div>
      </div>
      
          <div className="analytics-item align-items-center ">
            <div className="header-screens">
              <div className="heading-wrapper">
                <div className="heading"> {ACCOUNT.HEADING} </div>
                <div className="select-account">Select account</div>
              </div>
              {location.search === "?elem=lending" ? (
                <div className="precent">{state.netApy}</div>
              ) : (
                <div className="precent">3.31%</div>
              )}
              <div className="precentDescription">{ACCOUNT.NET_APY}</div>
              <div className="values-wrapper">
                <div className="value-wrapper">
                  {location.search === "?elem=lending" ? (
                    <div className="value">${state.dailyEarnings}</div>
                  ) : (
                    <div className="value">3.31%</div>
                  )}
                  <div className="description">{ACCOUNT.DAILY_SUMMARY}</div>
                </div>
                <div className="value-wrapper">
                  {location.search === "?elem=lending" ? (
                    <div className="value"> ${state.supplyBalance} </div>
                  ) : (
                    showPage
                  )}

                  <div className="description">{ACCOUNT.INVESTED_BALANCE}</div>
                </div>
                {/* <div className="value-wrapper">
                  <div className="value">$5.51</div>
                  <div className="description">{ACCOUNT.CUMULATIVE_APY}</div>
                </div> */}
              </div>

              <div className="harvest-all-wrapper">
                <div className="value-wrapper">
                  {pageContentTotalCollect}
                  {pageContent}
                </div>
                <div className="button-widget">{buttonAvailability}</div>
              </div>
            </div>
          </div>
       
      
      {location.search === "?elem=defidashboard" ? (
        <AnalyticsModal
          show={state.modalChart.show}
          actions={actions}
          data={state.analytics.analyticsData}
        />
      ) : (
        <AnalyticsModal
          show={mockData.modalChart.show}
          actions={actions}
          data={mockData.analytics.analyticsData}
        />
      )}
    </div>
  );
};
