import React, { useEffect, useRef } from 'react';
import './styles/styles.scss';
import CustomScroll from 'react-custom-scroll';
import { SmartTradeState } from '../../models/smart-trade-model';
import { SmartTradePacks, SmartTradeControls, SmartTradePack } from './templates/packsTemplates';
import { AddNewPackModal, AddNewPairModal } from './templates/modalTemplates';
import { SelectorsSetTemplate } from './templates/choiseSelectorsTemplate';
import { debug, useStoreState } from 'easy-peasy';
import { packsEffects } from './actions/packsEffects';
import { isEmpty } from 'lodash';
import { ChartWidget } from './templates/widgetTemplate';
import { TradeBotTemplate } from './templates/tradeBotTemplate';
import { TableTemplate } from './templates/tableTemplate';
import { selectorsEffects } from './actions/selectorsEffects';
import { constants } from './constants';
import { useLocation } from 'react-router-dom';
import { OrderBook } from './templates/orderBookTemplate';
import { openOrdersPositionsEffects } from './actions/openOrdersPositionsEffects';
import { dataHelper } from './actions/dataHelpers/dataHelper';
import { ShareOrderResultModal } from './templates/shareOrderResultModal';
import { MultichartControls } from './templates/multichartTemplate';

const { DEFAULT_PAIR } = constants;

const SmartTradePageLayout = () => {
  const location = useLocation();
  const assets = useStoreState((state) => state.availableExchangesList);
  const allPackData = useStoreState((state) => state.packs);
  const exchanges = useStoreState((state) => state.coinData.exchanges);
  const actions = SmartTradeState.useStoreActions((actions) => actions);
  const controls = SmartTradeState.useStoreState((state) => state.controls);
  const packsState = SmartTradeState.useStoreState((state) => state.packs);
  const singlePair = SmartTradeState.useStoreState((state) => state.singlePair);
  const state = SmartTradeState.useStoreState((state) => state);
  const isMultichart = SmartTradeState.useStoreState((state) => state.controls.isMultichart);
  const ref = useRef();
  const { isSingle } = singlePair;
  const isContentAvailable = !isSingle
    ? Boolean(
        !isEmpty(packsState.selectedPair) &&
          !isEmpty(exchanges) &&
          !isEmpty(packsState.exchangeData)
      )
    : Boolean(!isEmpty(exchanges) && singlePair.mainCoin && singlePair.pairCoin);
  const exchangesViewList = dataHelper.parseExchanges(exchanges);
  const initialId = exchangesViewList[0]?.value?.accountId;
  const initialApi = exchangesViewList[0]?.value?.api;
  const exchange = packsState.api == null ? initialApi : packsState.api;
  const keyId = packsState.exchangeId == null ? initialId : packsState.exchangeId;
  
  console.log('isMultichart', isMultichart);
  
  useEffect(() => {
    actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({ id: 'exchange_update' });
  }, [packsState.exchangeId]);

  useEffect(() => {
    !isEmpty(allPackData)
      ? actions[packsEffects.actionTypes.SMART_TRADE_PAGE_PACK_SELECTED]({
          id: 'load_packs',
          allPackData,
        })
      : '';
  }, [allPackData]);

  useEffect(() => {
    actions[openOrdersPositionsEffects.actionTypes.OPEN_ORDERS_POSITIONS_REQUSETS_INVOKED]({
      actions: actions,
      method: 'POST',
      keyId: keyId,
      exchange: exchange,
    });
  }, [keyId]);

  useEffect(() => {
    actions[selectorsEffects.actionTypes.SMART_TRADE_SELECTORS_DATA_UPDATED](assets);
  }, [assets]);

  return (
    <div ref={ref} className="page-container">
      <CustomScroll
        heightRelativeToParent="100%"
        className="custom-scroll-bar main-content-scroll"
        addScrolledClass={true}
      >
        <div className="smart-trade pt-4 pt-md-0">
          <div className="smart-trade-controls-container">
            <SmartTradeControls />
          </div>
          <div className="smart-trade-choice-container">
            {!isEmpty(exchanges) ? (
              <SelectorsSetTemplate exchanges={exchanges} mode={controls.packs} />
            ) : (
              <div></div>
            )}
          </div>
          <div className="smart-trade-packs-container">
            {controls.packs ? <SmartTradePacks /> : null}
          </div>
          <div className="smart-trade-chosen-pack-container">
            {controls.packs ? <SmartTradePack /> : null}
          </div>
           <div className="smart-trade-controls-container">
            <MultichartControls state = { state } actions = { actions} />
          </div>
          {!isContentAvailable ? (
            <>
              <div className="smart-trade-chart-container">
                <ChartWidget defaultPair={DEFAULT_PAIR} />
                <OrderBook />
              </div>
            </>
          ) : (
            <>
              <div className="smart-trade-chart-container">
                <ChartWidget />
                <OrderBook />
              </div>
              <div className="smart-trade-bot-settings-container">
                <TradeBotTemplate />
              </div>
            </>
          )}
          {location.search === '?elem=protrade' ? (
            <div className="smart-trade-info-container">
              <TableTemplate />
            </div>
          ) : null}
        </div>
        {controls.packs && ref.current ? <AddNewPackModal ref={ref.current} /> : null}
        {controls.packs && ref.current ? <AddNewPairModal ref={ref.current} /> : null}
        {state.shareOrderResult.show ? (
          <ShareOrderResultModal
            show={state.shareOrderResult.show}
            actions={actions}
            positive={true}
          />
        ) : null}
      </CustomScroll>
    </div>
  );
};

export const SmartTradePage = () => (
  <SmartTradeState.Provider>
    <SmartTradePageLayout />
  </SmartTradeState.Provider>
);
