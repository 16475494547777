import { constants } from '../../constants';
import { isEmpty } from 'lodash';
import Big from 'big.js';
import { debug } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';

const { PACKS: { PACKS_FACTORY: { PACK_ADD, PACK_DELETE, PAIR_UPDATE, PAIR_DELETE, BOT_CREATE, TICKER } } } = constants;

const _reduceData = data => data.reduce((acc, p) => {
    const obj = {};
    obj[p.value] = 1;
    return {...acc, ...obj};
},{});

const packAddRequest = payload => {
    const {mainCoin, pairs, name } = payload;
    const assets = _reduceData(pairs);
    return {...PACK_ADD, data: {name, main: mainCoin, assets, exchange: payload?.exchange?.value?.value?.accountId}, operation: 'add_pack'};
};

const packDeleteRequest = payload => {
    const {packId, id, exchange} = payload;
    return {...PACK_DELETE, data: {packId, exchange}, operation: id};
};

const pairsAddRequest = payload => {
    const {selectedId, createPairsData, id, exchangeId} = payload;
    const assets = _reduceData(createPairsData);
    return {...PAIR_UPDATE, data: {packId: selectedId, assets, exchange: exchangeId}, operation: id};
};

const pairsDeleteRequest = payload => {
    const {selectedId, id, pair, exchangeId} = payload;
    return {...PAIR_DELETE, data: {packId: selectedId, assets: [pair], exchange: exchangeId}, operation: id};
};

const populatePacksList = (payload, exchangeId) => {

    if (isEmpty(payload) || isEmpty(exchangeId))
        return [];

    const currentPacks = payload[exchangeId] || {};
    const ids = Object.keys(currentPacks);
    return ids.reduce((acc, id) => {
        const { main, name, assets } = currentPacks[id];
        const obj = {
            id,
            mainCoin: main,
            weight: false,
            pairs: Object.keys(assets || {}),
            name
        };
        return [...acc, obj];
    }, []);
};

const createSelectedPair = payload => {
    const { pair, selectedId, selectedPack: { mainCoin, weight }, exchangeData } = payload;
    return { id: pair, packId: selectedId, isSinglePair: false, mainCoin, weight, exchangeData };
};

const createSelectedSinglePair = payload => {
    const { pair, mainCoin, exchangeData} = payload;
    return {id: pair, mainCoin, isSinglePair: true, selectedPack: {mainCoin, weight: false}, exchangeData};
};

const chartOptions = (pairsData, theme, Themes, style) => {
    const { exchangeData: { value: {value} }, mainCoin, id } = pairsData;
    const exchange = value.api.startsWith('binance') ? 'BINANCE' : value.api.toUpperCase();
    const symbol = `${exchange}:${id}${mainCoin}`;
    const widgetTheme = theme === 'day-theme' ? Themes.LIGHT : Themes.DARK;
    return {
        symbol,
        locale: 'en',
        autosize: true,
        theme: widgetTheme,
        timezone: 'Etc/UTC',
        style: style.toString(),
        range: 'all',
        save_image: false,
        allow_symbol_change: true,
        enable_publishing: false,
        withdateranges: true,
    };
};

const getSelectedPack = (packsData, exchangeId) => {

    if (isEmpty(packsData) || isEmpty(exchangeId))
      return [];

  return packsData.filter(p => p.id === exchangeId);
};

const generateTPelement = state => {
    const {input_profit_volume_percent, input_profit_percent, input_profit_max_price_percent, input_profit_short_percent, takeProfitData, formType} = state;
    const uuid = uuidv4();
    return {
        quantityPercentage: input_profit_volume_percent ? Big(input_profit_volume_percent || 0).div('100').valueOf() : "1",
        priceMultiplier: formType !== 'form_state_pro_short' ? Big(input_profit_percent || 0).div('100').plus(1).valueOf() : Big(input_profit_short_percent || 0).div(100).plus(1).abs().valueOf(),
        trailing: Big(input_profit_max_price_percent || 0).div('100').valueOf(),
        uuid
    };
};

const generateTP = state => {
    const {takeProfitData} = state;
    let tp = {};
    if (isEmpty(takeProfitData)) {
        tp = [generateTPelement(state)];
        delete tp[0].uuid;
    }
    else {
        tp = takeProfitData;
    }
    return tp;
};

const botCreate = payload => {
    const { pairData: {exchangeData, id, mainCoin, packId = null, isSinglePair}, state, selectedPack} = payload;
    const {value: {value: {api, accountId}}} = exchangeData;
    const side = state.formType === 'form_state_pro_short' ? 'sell' :
        state.formType !== 'form_state_buy_sell' ? 'buy' : state.buySellFormType === 'buy' ? 'buy' : 'sell';
    const formType = state.formType === 'form_state_pro_short' ? 'SHORT' :
        state.formType !== 'form_state_buy_sell' ? 'LONG' : state.buySellFormType === 'buy' ? 'LONG' : 'SHORT';

    const type = exchangeData?.value?.value?.type;
    const isLeveraged = type === 'FUTURES' ? true : false;

    const data = {
        symbol: `${id}${mainCoin}`,
        mainCoin,
        pairCoin: id,
        formType,
        typeOfExchange: type,
        isLeveraged,
        leverage: state.leverage,
        marginType: state.marginType,
        packId: packId,
        isSinglePair,
        pack: isSinglePair ? null : selectedPack,

        order: {
            trailing: Big(parseFloat(state.input_trailingBy_percent || 0) / 100).valueOf(),
            quantity: Big(state.input_total_value).valueOf(),
            base: isSinglePair ? {
                quantityBase: Big(state.input_amount_value || 0).valueOf(),
                priceBase: Big(state.input_price_value || 0).valueOf(),
            } : null,
            conditionOrderPriceLimit: state.button_buy_cond && state.button_buy_cond_limit ? state.input_buy_cond_limit : null,
            conditionOrderPriceMarket: state.button_buy_cond && state.button_buy_cond_market ? true : false,
            side
        },
        takeProfit: state.toggle_take_profit ? generateTP(state) : null,
        stopLoss: state.toggle_stop_loss ? {
            delta: Big(Math.abs(state.input_stop_loss_value_percent || 0) / 100).valueOf(),
            delay: Big(state.input_stop_loss_timeout_sec || 0).valueOf(),
            trailing: state.toggle_trailing_stop_loss,
            breakeven: state.toggle_move_breakeven,
            parameter: 'l',
            conditionOrderPriceLimit: state.button_sloss_limit ? state.input_sloss_cond_limit : null,
            conditionOrderPriceMarket: state.button_sloss_market ? true : false
        } : null
    };

  return {
      ...BOT_CREATE,
      data: {
          keyId: accountId,
          exchange: api,
          botType: 'SMART_TRADE',
          data: {
              ...data
          }
      },
      operation: 'bot-create'
  };
};

const submitButtonState = state => {
    return !Boolean(state.input_amount_value);
};

const tableRowGenerate = state => {
    const {input_profit_value, input_profit_volume_percent, input_profit_short_percent, input_profit_percent, formType} = state;
    return {
        price: input_profit_value || 0,
        quantity: input_profit_volume_percent || 0,
        tp: formType === 'form_state_pro_short' ? input_profit_short_percent : input_profit_percent
    };
};

const fetchPrices = payload => {
    const {data, id } = payload;
    return {
        ...TICKER,
        data: {
            symbol: `${data.id}${data.mainCoin}`
        },
        operation: id
    };
};

const leverageCalculation = value => {
    if (value <= 50)
        return {
            leverage: Math.max(Math.round(value / 5), 1),
            value: Math.round(value / 5) * 5 >=  10 ? Math.round(value / 5) * 5 : 0
        };
    else if (value > 50 && value < 60)
        return {
            leverage: Math.ceil(value * 15/10 - 65),
            value: Math.ceil(value)
        };
    else if (value >= 60)
        return {
            leverage: Math.ceil(value * 25 / 10 - 125),
            value: Math.ceil(value)
        };
};

export const packHelper = {
    packAddRequest,
    packDeleteRequest,
    pairsAddRequest,
    pairsDeleteRequest,
    populatePacksList,
    createSelectedPair,
    chartOptions,
    getSelectedPack,
    botCreate,
    tableRowGenerate,
    submitButtonState,
    fetchPrices,
    leverageCalculation,
    createSelectedSinglePair,
    generateTPelement
};