import React from 'react';
import { EventsState } from '../../models/events-model';
import { renderEventsControlContent } from './templates/eventsTemplate';
import { eventsSettingsEffects } from './actions/eventsEffects';
import { HighlightsPage } from './pages/highlights';
import CustomScroll from 'react-custom-scroll';
import { UpcomingEventsPage } from './pages/upcoming_events';
import { PastEventsPage } from './pages/past_events';

export const EventsLayout = () => {
  const state = EventsState.useStoreState((state) => state.titleData);
  const actions = EventsState.useStoreActions((actions) => actions);
  const onClickAction = actions[eventsSettingsEffects.actionTypes.EVENTS_BUTTON_ELEMENTS_CLICKED];
  const onClickHandler = (e) =>
    onClickAction({ actions, name: e.target.name, target: e.target, state });

  return (
    <CustomScroll heightRelativeToParent="100%">
      <div className="events-control-content">
        {renderEventsControlContent(state, onClickHandler)}
      </div>
      <div className="events-pages">
        {state.viewType.highlights ? (
          <div className="highlights-page">
            <HighlightsPage />
          </div>
        ) : state.viewType.upcoming_events ? (
          <div className="upcoming-events-page">
            <UpcomingEventsPage />
          </div>
        ) : (
          <div className="upcoming-events-page">
            <PastEventsPage />
          </div>
        )}
      </div>
    </CustomScroll>
  );
};

export const EventsPage = () => (
  <EventsState.Provider>
    <EventsLayout />
  </EventsState.Provider>
);
