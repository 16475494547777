import { NIL, v5 as uuidv5 } from 'uuid';
import { constants } from '../../../../constants/constants';
import createHash from 'hash-generator';
import { Buffer } from 'buffer';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';

window.Buffer = Buffer;

const {PROJECT_NAME} = constants;

const getConnector = () => {
    return new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
        qrcodeModal: QRCodeModal,
    });
};

const createAddress = wallet => {
    const namespace = uuidv5(PROJECT_NAME, NIL);
    return {
        "username": uuidv5(wallet, namespace),
        "validationData": {
            "custom:web3address": wallet
        },
        "attributes": {
            "custom:web3address": wallet,
        },
        account: wallet,
    };
};

const createSignUpData = address => {
  return {...address, password: createHash(30)};
};

const createUserData = key => {
  const badge = key.slice(2).slice(0, 1);
  const user = `${key.slice(0, 7)}...${key.slice(-7)}`;
  return {badge, user, userId: key};
};

export const dataHelper = {
    getConnector,
    createAddress,
    createSignUpData,
    createUserData
};