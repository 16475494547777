import React, { forwardRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { HodlBotState } from '../../../../models/hodl-bot-model';
import { packsEffects } from '../../actions/packsEffects';
import { ButtonCustom } from '../../../../components/utility/custom-buttons/view';
import { constants } from '../../constants';
import { MultiplyCustomSelector } from '../../../../components/utility/multiply-selector/view';

const {
  CONTROLS: { LABEL_ADD_NEW_PACK, LABEL_CANCEL, LABEL_PACK_NAME, LABEL_PAIR_COINS, LABEL_MAIN_COIN, LABEL_PACK_NAME_PLACEHOLDER }
} = constants;

const renderPackForm = (state, actions) => {
  const mainCoinHandle = value => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'pack_main_coin_value', value });
  const pairCoinHandle = value => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'pack_pairs_value', value });
  const nameHandle = e => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'pack_name_value', value: e.target.value });

  return <Form.Group>
    <div className="pack-create-form">
      <div className="pack-create-pack-name">
        <Form.Label>{LABEL_PACK_NAME}</Form.Label>
        <Form.Control
          placeholder={LABEL_PACK_NAME_PLACEHOLDER}
          onChange={nameHandle}>
        </Form.Control>
      </div>
      <div className="pack-create-main-coin text-capitalize">
        <Form.Label className="">{LABEL_MAIN_COIN}</Form.Label>
        <MultiplyCustomSelector
          onChangeHandle={mainCoinHandle}
          isMulti={false}
          closeMenuOnSelect={true}
          options={state.mainCoinList}/>
      </div>
      <div className="pack-create-pair-coins">
        <Form.Label>{LABEL_PAIR_COINS}</Form.Label>
        <MultiplyCustomSelector
          onChangeHandle={pairCoinHandle}
          isMulti={true}
          closeMenuOnSelect={false}
          options={state.pairCoinList}/>
      </div>
    </div>
  </Form.Group>;
};

export const AddNewPackModal = forwardRef(({}, reference) => {
  const state = HodlBotState.useStoreState(state => state.packs);
  const actions = HodlBotState.useStoreActions(actions => actions);
  const handleClose = () => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'close' });
  const saveHandle = () => actions[packsEffects.actionTypes.HODL_BOT_PACK_SELECTED]({ id: 'save', ...state.createPackData, exchange: state.exchangeData });
  const isDisabled = isEmpty(state.createPackData) || isEmpty(state.createPackData.name) || isEmpty(state.createPackData.pairs) || isEmpty(state.createPackData.mainCoin);
  return <Modal
    size="md"
    show={state.addPackMode}
    centered
    container={reference}
    backdrop="static"
    className="add-pack"
    onHide={handleClose}>
    <Modal.Body>
      <div className="font-size-16 mb-2">{LABEL_ADD_NEW_PACK}</div>
      { renderPackForm(state, actions) }
    </Modal.Body>
    <Modal.Footer>
      <ButtonCustom label={LABEL_CANCEL} size="sm" variant="default" onClick={handleClose}/>
      <ButtonCustom label={LABEL_ADD_NEW_PACK} size="sm" variant="primary" disabled={isDisabled} onClick={saveHandle}/>
    </Modal.Footer>
  </Modal>;
});
