import React from 'react';
import { customBotsActions } from '../../actions/uiActions';
import { customBotsEffects } from '../../actions/customBotsEffects';
import { constants } from '../../constants';
import { Badge } from 'react-bootstrap';

const {
  TABS: {
    FORM_TABS: {
      WIZARD
    }
  }
} = constants;

export const TabFormWidget = (props) => {
  const { state, actions, data, isModal } = props

  const onTabsToggle = tab => {
    actions[customBotsActions.actionTypes.CUSTOM_BOTS_TAB_TOGGLE]({ tab })
    actions[customBotsEffects.actionTypes.CUSTOM_BOTS_FORM_ELEMENTS_CHANGED]({ name: tab, value: tab })
  };
  const activeClass = tab => state === tab ? 'active' : '';

  return <>
    { data.map(item =>
      <div
        key={item.VALUE}
        className={`tab ${activeClass(item.VALUE)} ${!isModal ? 'align-items-center' : ''}`}
        onClick={() => onTabsToggle(item.VALUE)}>
        { !isModal &&
          <div className="pe-3">
            { state === item.VALUE ?
              <img src="../dashboard-library/circle-check.svg" alt="Circle" width={22} height={22} /> :
              <img src="../dashboard-library/circle-check-gray.svg" alt="Circle" width={22} height={22} />
            }
          </div>
        }
        <div>
          <div className={`${isModal && item.VALUE === WIZARD.VALUE ? 'd-flex justify-content-between' : ''}`}>
            <p className="m-0 font-weight-bold">{item.TITLE}</p>
            { isModal && item.VALUE === WIZARD.VALUE && <Badge bsPrefix="new">NEW</Badge> }
          </div>
          { isModal && <p className="mt-2 mb-2 font-size-14 global-colors-font-2">{item.SUBTITLE}</p> }
          <p className={`${!isModal ? 'font-size-14' : 'font-size-10'} m-0 global-colors-font-2`}>{item.TEXT}</p>
        </div>
      </div>
      )}
  </>;
};