import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { constants } from '../../constants';

const { NAV, FOOTER } = constants;

export const StaticFooter = () => {

  return <div className='footer'>
    <div className="font-size-30 font-protosans d-flex align-items-center footer-brand">
      <LinkContainer to="/home">
        <a>
          <img src='../dashboard-library/home-page-images/nav_logo.svg' className='me-3' />
          <span>{NAV.NAV_LOGO}</span>
        </a>
      </LinkContainer>
      
    </div>
    <div className="footer-links-wrapper">
      <div className="footer-links font-protosans">
        {FOOTER.LINKS.map((item, index) => <div key={index} className='footer-links-item'>
          <LinkContainer to={`/home?elem=${item.link}`}>
            <a>{item.label}</a>
          </LinkContainer>
        </div>)}
      </div>
      <div className="footer-social">
        {FOOTER.FOOTER_MEDIA.ITEMS.map((item, index) => <div key={index} className='footer-social-item'>
          <a rel='noreferrer' target='_blank' href={item.link}>
            <img className='icon' src={item.icon} />
          </a>
        </div>)}
      </div>
    </div>
    <div className="footer-copyright font-qanelas">
      <small>&copy; {NAV.NAV_LOGO} 2021-{new Date().getFullYear()}</small>
    </div>
  </div>;
};