import React from 'react';
import { cloneDeep } from 'lodash';
import { dashboardConstants } from '../dashboardConstants';
import numeral from 'numeral';
import { utils } from '../../../utils/utils';
import * as d3 from 'd3';
import { remakeDashboardEffects } from '../actions/remakeDashboardEffects';
import { Asset } from '../../../components/utility/asset-component/view';
const { DATA_CEFI, DATA_DEFI } = dashboardConstants;
const FORMAT = '0, 00';

const dataHelper = (assets, mask) => {
  return assets
    .reduce((acc, coin, i) => {
      acc[i].value = coin.asset.toLowerCase();
      acc[i].fraction = coin.fraction;
      return acc;
    }, mask)
    .filter((e) => e.value);
};

const drawLendingTitles = (node, state, actions) => {
  const { suppliedBalance, borrowedBalance, venusNetApy } = state.lending;

  node.selectAll('g.lending-titles').remove();

  const el = node.append('g').attr('class', 'lending-titles');

  el.append('text')
    .attr('y', 140)
    .attr('x', 120)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFF')
    .attr('font-size', '18px')
    .attr('font-weight', 300)
    .text(`$ ${suppliedBalance}`);

  el.append('text')
    .attr('y', 200)
    .attr('x', 185)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFF')
    .attr('font-size', '16px')
    .attr('font-weight', 300)
    .text(`% ${venusNetApy}`);

  el.append('text')
    .attr('y', 135)
    .attr('x', 470)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFF')
    .attr('font-size', '18px')
    .attr('font-weight', 300)
    .text(`$ ${borrowedBalance}`);
};

const drawLeftTitles = (node, state, accs, actions) => {
  // const { accounts } = state;
  const { balance, change, top4assets } = state.defi;
  const assets = dataHelper(cloneDeep(top4assets), cloneDeep(DATA_DEFI));
  node.selectAll('g.left-titles').remove();
  node.selectAll('path.action-button');
  // .on('click', () => actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true));

  const el = node.append('g').attr('class', 'left-titles');

  el.append('text')
    .attr('y', 100)
    .attr('x', 381)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '15px')
    .attr('font-weight', 500)
    .text(`Connected`);

  el.append('text')
    .attr('y', 340)
    .attr('x', 380)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 500)
    .text(`$ ${Number(balance).toFixed(0)}`);

  el.append('g')
    .attr('class', 'wallet-block')
    .selectAll('g.wallet-block')
    .data(accs.slice(0, 4))
    .enter()
    .append('svg:image')
    .attr('y', (_, i) => 6 + 34 * i)
    .attr('x', 472)
    .attr('height', 24)
    .attr('width', 24)
    .attr('href', (d) =>
      d ? `../dashboard-library/home-page-images/${d?.icon?.toLowerCase()}.svg` : ''
    );

  el.append('g')
    .attr('class', 'wallet-block')
    .attr('class', 'cursor-pointer')
    .selectAll('g.wallet-block')
    .data(accs.slice(0, 4))
    .enter()
    .append('text')
    .attr('y', (_, i) => 23 + 34 * i)
    .attr('x', 502)
    .attr('fill', '#FFFFFF')
    .attr('font-size', '16px')
    .attr('font-weight', 300)
    .text((d) => d.name || '')
    .on('click', (d, i) => {
      const url = `${window.location.origin}/CeFi?elem=balances`;
      window.location.href = url;
    });

  el.append('g')
    .attr('class', 'defi-assets')
    .selectAll('g.defi-assets')
    .data(assets)
    .enter()
    .append('svg:image')
    .attr('class', 'cursor-pointer')
    .attr('y', (d) => d.y)
    .attr('x', (d) => d.x)
    .attr('height', 34)
    .attr('width', 34)
    .attr('href', (d) => `../coins-library/${d.value}.svg`)
    .on('error', function () {
      this.onerror = null;
      this.href.baseVal = '../coins-library/no_img.svg';
    })
    .on('mouseover', (_, d) => {
      d3.select('g.defi-assets')
        .append('text')
        .attr('class', 'icon-tooltip')
        .attr('text-anchor', 'middle')
        .attr('x', d.x + 17)
        .attr('y', d.y - 12)
        .attr('fill', '#FFF')
        .text(d.value.toUpperCase());
    })
    .on('mouseleave', () => {
      d3.selectAll('text.icon-tooltip').remove();
    });

  el.append('g')
    .attr('class', 'defi-fraction')
    .selectAll('g.defi-fractions')
    .data(assets)
    .enter()
    .append('text')
    .attr('y', (d) => d.y + 55)
    .attr('x', (d) => d.x + 17)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '16px')
    .attr('font-weight', 300)
    .text((d) => 
    `${Number(d.fraction).toFixed(0)}%`);


    el
  .append('text')
  .attr('y', 590)
  .attr('x', 255)
  .attr('text-anchor', 'middle')
  .attr('fill', '#FFFFFF')
  .attr('font-size', '24px')
  .attr('font-weight', 400)
  .text('Daily');

el
.append('text')
  .attr('y', 620)
  .attr('x', 255)
  .attr('text-anchor', 'middle')
  .attr('fill', '#FFFFFF')
  .attr('font-size', '24px')
  .attr('font-weight', 400)
  .text('change');

el
  .append('text')
  .attr('y', 670)
  .attr('x', 255)
  .attr('text-anchor', 'middle')
  .attr('fill', '#FFFFFF')
  .attr('font-size', '24px')
  .attr('font-weight', 500)
  .text('N/I');

};
const drawMiddleTitles = (node, state) => {
  const { total, invested, available } = state;
  node.selectAll('g.middle-titles').remove();
  node.append('g').attr('class', 'middle-titles');

  node
    .select('g.middle-titles')
    .append('g')
    .append('text')
    .attr('y', 40)
    .attr('x', 310)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '40px')
    .attr('font-weight', 300)
    .text('Aggregated balances');

  node
    .select('g.middle-titles')
    .append('g')
    .append('text')
    .attr('y', 320)
    .attr('x', 315)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 500)
    .text(`$ ${Number(available).toFixed(0)}`);

  node
    .select('g.middle-titles')
    .append('g')
    .append('text')
    .attr('y', 160)
    .attr('x', 310)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 500)
    .text(`$ ${Number(total).toFixed(0)}`);

  node
    .select('g.middle-titles')
    .append('g')
    .append('text')
    .attr('y', 435)
    .attr('x', 315)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 500)
    .text(`$ ${Number(invested).toFixed(0)}`);
};
const drawRightTitles = (node, state, actions, usedExchanges) => {
  const { balance, change, top4assets } = state.cefi;
  const assets = dataHelper(top4assets, DATA_CEFI);
  let binanceProd
  for (const [key, value] of Object.entries(usedExchanges)) {
    if(value.name === "Binancefutures")
        binanceProd=value.history

  }//exchanges.exchanges.find((exchange)=> exchange.accountId==="6a8df539-d0f7-57ac-b787-8c4b928fe393")
const binanceProdHistory = binanceProd?.daily
let lastValues
if(binanceProdHistory && binanceProdHistory.length>0)
{
  lastValues = [binanceProdHistory[binanceProdHistory?.length-2]?.BTC, binanceProdHistory[binanceProdHistory?.length-3]?.BTC]
}
const dailyChange = (lastValues?.length<=1 || !lastValues) ? 'N/I' : (lastValues[0] == lastValues[1] ? 0 : ((lastValues[0] - lastValues[1])/lastValues[1])*100)

  node.selectAll('g.right-titles').remove();

  node.append('g').attr('class', 'right-titles');

  const buttons = node.selectAll('path.action-button');

  buttons.on('mouseenter', function () {
    d3.select(this).attr('fill', 'green').style('cursor', 'pointer');
  });

  buttons.on('mouseleave', function () {
    d3.select(this).attr('fill', '#28262E').style('cursor', 'default');
  });

  buttons.on('click', () =>
    actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true)
  );

  node
    .select('g.right-titles')
    .append('text')
    .attr('y', 260)
    .attr('x', 225)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 300)
    .text('CeFi');

  node
    .select('g.right-titles')
    .append('text')
    .attr('y', 325)
    .attr('x', 220)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '30px')
    .attr('font-weight', 500)
    .text(`$ ${Number(balance).toFixed(0)}`);

  node
    .select('g.right-titles')
    .append('text')
    .attr('y', 540)
    .attr('x', 405)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '24px')
    .attr('font-weight', 400)
    .text('Daily');

  node
    .select('g.right-titles')
    .append('text')
    .attr('y', 570)
    .attr('x', 405)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '24px')
    .attr('font-weight', 400)
    .text('change');

  node
    .select('g.right-titles')
    .append('text')
    .attr('y', 620)
    .attr('x', 405)
    .attr('text-anchor', 'middle')
    .attr('fill', '#FFFFFF')
    .attr('font-size', '24px')
    .attr('font-weight', 500)
    .text(`${dailyChange !== 'N/I' && dailyChange!=0 ? dailyChange.toFixed(2) : dailyChange}${dailyChange!=='N/I'?'%' : ''}`);

  node
    .select('g.right-titles')
    .append('g')
    .attr('class', 'most-active-assets')
    .selectAll('g.most-active-assets')
    .data(assets)
    .enter()
    .append('svg:image')
    .attr('class', 'cursor-pointer')
    .attr('y', (d) => d.y)
    .attr('x', (d) => d.x)
    .attr('height', 35)
    .attr('width', 35)
    .attr('href', (d) => `../coins-library/${d.value}.svg`)
    .on('error', function () {
      this.onerror = null;
      this.href.baseVal = '../coins-library/no_img.svg';
    })
    .on('mouseover', (_, d) => {
      d3.select('g.most-active-assets')
        .append('text')
        .attr('class', 'icon-tooltip')
        .attr('x', d.x + 15)
        .attr('y', d.y - 18)
        .attr('text-anchor', 'middle')
        .attr('fill', '#FFF')
        .text(d.value.toUpperCase());
    })
    .on('mouseleave', () => {
      d3.selectAll('text.icon-tooltip').remove();
    });
};
const drawRightNoDataTitles = (node, actions) => {
  node
    .selectAll('path.action-button')
    .on('click', () =>
      actions[remakeDashboardEffects.actionTypes.DASHBOARD_MODAL_ADD_EXCHANGE_OPENED](true)
    );
};

export const drawHelper = {
  drawLendingTitles,
  drawRightTitles,
  drawLeftTitles,
  drawMiddleTitles,
  drawRightNoDataTitles,
};
