import { action, thunk } from 'easy-peasy';
import { utils } from '../../../utils/utils';
import { dataHelper } from '../dataHelpers/dataHelper';
import { factory } from './factory';

const actionTypes = {
    LENDING_NETWORKS_SELECT_SET: 'LENDING_NETWORKS_SELECT#SET',
    LENDING_MODAL_INVOKED: 'LEDNING_MODAL#INVOKED',
    LENDING_MODAL_TAB_SWITCHED: 'LENDING_MODAL_TAB#SWITCHED',
    LENDING_MODAL_FORM_CHANGED: 'LENDING_MODAL_FORM#CHANGED',
    LENDING_ASSETS_SELECT_SET: 'LENDING_ASSETS_SELECT#SET',
    LENDING_MODAL_TOKEN_BALANCE_GOT: 'LENDING_MODAL_TOKEN_BALANCE#GOT',
    LENDING_INVESTMENT_TYPE: ' LENDING_INVESTMENT#TYPE'
};

const assetsSelectSet = (state, payload) => {
    const { value } = payload;

    state.protocolSelector.value = value;
};

const networkSelectSet = (actions, payload) => {
    const { updateState } = actions;
    const { value, userWallet } = payload;
    if(value.protocols) {
        updateState([
            {
                path: 'networkSelector.value',
                value
            },
            {
                path: 'protocol',
                value: value.protocols[0].value
            },
            {
                path: 'protocolToChain.value',
                value: value.protocols[0]
            },
            {
                path: 'protocolToChain.list',
                value: value.protocols
            },
        ]);

        actions[factory.actionTypes.LENDING_MARKET_FETCHED]({ protocol: value.protocols[0].value, userWallet });
        actions[factory.actionTypes.LENDING_USER_FETCHED]({ userWallet, protocol: value.protocols[0].value });
    }
    else {
        updateState([
            {
                path: 'protocol',
                value: value.value
            },
            {
                path: 'protocolToChain.value',
                value
            },
        ]);

        actions[factory.actionTypes.LENDING_MARKET_FETCHED]({ protocol: value.value, userWallet });
        actions[factory.actionTypes.LENDING_USER_FETCHED]({ userWallet, protocol: value.value });
    }

    updateState([
        {
            path: 'loader',
            value: true
        },
        {
            path: 'tableData.borrowMarket',
            value: []
        },
        {
            path: 'tableData.supplyMarket',
            value: []
        },
        {
            path: 'tableData.supplyUser',
            value: []
        },
        {
            path: 'tableData.borrowUser',
            value: []
        },
    ]);
};

const investmentsTypeSelectSet = (actions, payload) => {
    const { value, userWallet } = payload;
  
        actions[factory.actionTypes.LENDING_MARKET_FETCHED]({ protocol: value.value, userWallet });
        actions[factory.actionTypes.LENDING_USER_FETCHED]({ userWallet, protocol: value.value });
  
};

const modalInvoked = (actions, payload) => {
    const { name, pickedAsset, type, chain, userWallet } = payload;
    const { updateState } = actions;

    const asset = dataHelper.getFormattedAssetObject(pickedAsset);

    switch(name) {
    case 'close-modal': 
        updateState([
            {
                path: 'modal.show',
                value: false
            },
            {
                path: 'modal.pickedAsset',
                value: null
            },
            {
                path: 'modal.type',
                value: ''
            },
            {
                path: 'modal.tab',
                value: ''
            },
            {
                path: 'modal.form.valid',
                value: false
            },
            {
                path: 'modal.form.amount',
                value: 0
            },
        ]);
        break;
    case 'open-modal': 
        actions[actionTypes.LENDING_MODAL_TAB_SWITCHED]({ tab: type, userWallet, tokenAddress: asset.token.address, chain, symbol: asset.token.symbol });
        updateState([
            {
                path: 'modal.show',
                value: true
            },
            {
                path: 'modal.pickedAsset',
                value: asset
            },
            {
                path: 'modal.type',
                value: type
            },
            {
                path: 'modal.tab',
                value: type
            },
        ]);
        break;
    }
};

const modalTabSwitched = (actions, payload) => {
    const { tab, userWallet, tokenAddress, chain, symbol } = payload;
    const { updateState } = actions;

    actions[actionTypes.LENDING_MODAL_TOKEN_BALANCE_GOT]({ userWallet, tokenAddress, chain, symbol });

    if(tab === 'repay') {
        actions[actionTypes.LENDING_MODAL_TOKEN_BALANCE_GOT]({ userWallet, tokenAddress, chain, symbol });
        updateState({ path: 'modal.tab', value: tab });
    }
    else if(tab === 'supply') {
        actions[actionTypes.LENDING_MODAL_TOKEN_BALANCE_GOT]({ userWallet, tokenAddress, chain, symbol });
        updateState({ path: 'modal.tab', value: tab });
    }
    else {
        updateState([
            { 
                path: 'modal.tab',
                value: tab
            },
            { 
                path: 'modal.form.valid',
                value: false
            },
            { 
                path: 'modal.form.amount',
                value: 0
            },
        ]);
    }

    updateState([
        { 
            path: 'modal.form.valid',
            value: false
        },
        { 
            path: 'modal.form.amount',
            value: 0
        },]);
};

const modalFormChanged = (state, payload) => {
    const { value } = payload;
    
    state.modal.form.amount = Number(value) < 0 ? '' : value;
};

const getTokenBalance = async (actions, payload) => {
    const { updateState } = actions;
    const { userWallet, tokenAddress, chain, symbol } = payload;
    
    const token = await dataHelper.metamaskGetTokenBalance(tokenAddress, userWallet, chain, symbol);
    updateState([{ path: 'modal.token', value: token.balance / Math.pow(10, token.decimals) }, { path: 'modal.isNative', value: token.native }]);
};

const actionHandlers = {
    [actionTypes.LENDING_MODAL_TOKEN_BALANCE_GOT]: thunk(async (actions, payload) => getTokenBalance(actions, payload)),
    [actionTypes.LENDING_ASSETS_SELECT_SET]: action((state, payload) => assetsSelectSet(state, payload)),
    [actionTypes.LENDING_MODAL_FORM_CHANGED]: action((state, payload) => modalFormChanged(state, payload)),
    [actionTypes.LENDING_MODAL_TAB_SWITCHED]: thunk((actions, payload) => modalTabSwitched(actions, payload)),
    [actionTypes.LENDING_MODAL_INVOKED]: thunk((actions, payload) => modalInvoked(actions, payload)),
    [actionTypes.LENDING_NETWORKS_SELECT_SET]: thunk((actions, payload) => networkSelectSet(actions, payload)),
    [actionTypes.LENDING_INVESTMENT_TYPE]: thunk((actions, payload) => investmentsTypeSelectSet(actions, payload)),
    updateState: action((state, payload) => utils.stateHelper(state, payload))
};
export const uiActions = {
    actionTypes,
    actionHandlers
};