import React from 'react';
import { StandardOptions } from '../../../../../components/utility/standard-options/view';
import { constants } from '../../../../../constants/constants';

const { WIDGET_INFO } = constants;


export const renderControlPanel = (label, id, loader) => {
    const refresh = id === 'feeds' ? 'feeds' : 'signals';
    const schema = id === 'feeds' ? WIDGET_INFO.FEED.STANDARD_OPTIONS_SCHEMA : WIDGET_INFO.SIGNAL.STANDARD_OPTIONS_SCHEMA;
    return <div className="widget-info-control-panel">
        <div className="d-flex justify-content-between">
            <div className="widget-info-control-panel-label font-size-14 font-weight-medium text-uppercase">{label}</div>
            <div className="standard-options-selector">
                <StandardOptions
                    refresh = {{ type: refresh }}
                    more={{ ...schema }}
                    expand={{ type: 'expand' }}
                    isLoading={loader}
                />
            </div>
        </div>
    </div>;
};