import React from 'react';
import './styles.scss';
import { Modal } from 'react-bootstrap';


const renderIcon = () => {
    return <i className="icon-exclamation modal-badge position-absolute font-size-18"></i>;
};

export const EmptyStateModal = React.forwardRef((props, ref) => {
    const { content } = props;
    return <Modal
        size="sm"
        show={true}
        centered
        autoFocus={false}
        backdrop="static"
        bsPrefix={'empty-state-modal'}
        enforceFocus={false}
        container={ref}
        aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body>
            { renderIcon() }
            { content }
        </Modal.Body>
    </Modal>;
});
