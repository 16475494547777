import React, { useEffect } from 'react';
import { MultiplyCustomSelector } from '../../../components/utility/multiply-selector/view';
import { SmartTradeState } from '../../../models/smart-trade-model';
import { orderBookEffects } from '../actions/orderBookEffect';
import { constants } from '../constants';


const {
  ORDER_BOOK: { PRICE, TOTAL, AMOUNT },
} = constants;

export const OrderBook = () => {
  const actions = SmartTradeState.useStoreActions((actions) => actions);
  const state = SmartTradeState.useStoreState(state => state);
  let initialStep = SmartTradeState.useStoreState(state => state.orderBookData.lengthSelector.selectedStep);


  const askPrices = state.orderBookData.askPrices;
  const bidPrices = state.orderBookData.bidPrices;
  const sort = state.orderBookData.sortPriceTable;

  const showAskPrices = sort === 'All' || sort === 'Ask';
  const showBidPrices = sort === 'All' || sort === 'Bid';

  const changeHandle = (value) =>
    actions[orderBookEffects.actionTypes.ORDER_BOOK_SELECTORS_CHANGED]({value, state});

  const setSortTableHandle = (value) =>
    actions[orderBookEffects.actionTypes.ORDER_BOOK_SORT_TABLE_TOGGLE](value);


  useEffect(() => {
    actions.SET_SELECT_VALUES();
  }, [])

  useEffect(() => {
    if(state.singlePair.pairCoin){
      actions.SET_ORDER_BOOK_DATES(state);
    }
  }, [state.singlePair.pairCoin])




  const TableRow = ({ price, total, amount, width }) => {
    return (
      <div className="line">
        <div className="column">{price}</div>
        <div className="column">{total}</div>
        <div className="column">{amount}</div>

        <div className="background-line" style={{ width: `${width}` }}></div>
      </div>
    );
  };

  const PriceTable = ({ prices, priceName }) => {
    return (
      <>
        <div className="header">
          <div>
            {priceName} {PRICE}
          </div>
          <div>{TOTAL}</div>
          <div>{AMOUNT}</div>
        </div>
        <div className="body">
          {prices.map((price, index) => (
            <TableRow
              key={index}
              price={price.price}
              total={price.total}
              amount={price.amount}
              width={price.width}
            />
          ))}
        </div>
      </>
    );
  };

  const SortBtns = () => {
    return (
      <div className="sort-buttons">
        <div
          onClick={() => setSortTableHandle('All')}
          className={`sort left ${sort === 'All' ? 'active' : 'inactive'}`}
        >
          <div className="right-side">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="left-side">
            <div className="red"></div>
            <div className="green"></div>
          </div>
        </div>

        <div
          onClick={() => setSortTableHandle('Ask')}
          className={`sort center ${sort === 'Ask' ? 'active' : 'inactive'}`}
        >
          <div className="left-side red"></div>
          <div className="right-side">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div
          onClick={() => setSortTableHandle('Bid')}
          className={`sort right ${sort === 'Bid' ? 'active' : 'inactive'}`}
        >
          <div className="left-side green"></div>
          <div className="right-side">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="order-book">
      <header>
        <SortBtns />
        <div className="select">
          <MultiplyCustomSelector
            options={state.orderBookData.lengthSelector.list}
            onChangeHandle={changeHandle}
            closeMenuOnSelect={true}
            isMulti={false}
            value={initialStep.value === undefined ? {} : initialStep}
          />
        </div>
      </header>
      <main>
        <>
          {showAskPrices && (
            <div className="ask-price">
              <PriceTable prices={askPrices} priceName={'ASK'} />
            </div>
          )}

          {showBidPrices && (
            <div className="bid-price mt-3">
              <PriceTable prices={bidPrices} priceName={'BID'} />
            </div>
          )}
        </>
      </main>
    </div>
  );
};
