import React from 'react';
import { constants } from '../../../../../constants/constants';

const { EMPTY_STATE } = constants;

const emptyStateContent = () => {
    return <div className="info-container">
        <div className="font-size-12">{EMPTY_STATE.TITLE}</div>
        <div className="font-size-12">{EMPTY_STATE.BAR_CHART_INFO}</div>
    </div>;
};

export const emptyState = {
    emptyStateContent
};